<template>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-9">
            <div class="row">
                <!--Property Type-->
                <div class="col-12 col-sm-4 form-group">
                    <label class="form-control-label" for="drp_property_propertyTypes">Type</label>
                    <dx-select-box ref="propertyTypeSelectBox"
                        :input-attr="{ automation_id: 'drp_property_propertyTypes' }"
                        :data-source="propertyTypes"
                        value-expr="id"
                        display-expr="name"
                        v-model:display-value="localProperty.propertyTypeName"
                        v-model="localProperty.propertyTypeID"
                        :disabled="readOnly"
                    />
                </div>

                <!--Address 1-->
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                    <div class="row">
                        <label for="txt_company_address1" class="col col-form-label">Address 1</label>
                        <div class="col-auto form-group-action ms-auto">
                            <b-form-checkbox
                                automation_id="btn_property_manual_override"
                                v-model="localProperty.isAddressOverridden"
                                :disabled="readOnly">Manual Override
                            </b-form-checkbox>
                        </div>
                        <div v-rq-tooltip.hover.top="addressValidateHoverMessage"
                            class="col-auto form-group-action ms-auto">
                            <b-btn
                                automation_id="btn_validate_address"
                                variant="link"
                                class="btn-theme"
                                :disabled="readOnly || !isProperAddress || localProperty.isValidatedAddress || isProcessingAddress || localProperty.isAddressOverridden || localProperty.isForeignAddress"
                                @click="onValidateAddress">Validate</b-btn>
                        </div>
                    </div>
                    <dx-autocomplete
                        ref="address1Autocomplete"
                        class="form-control"
                        :dataSource="addressDataSource"
                        item-template="item-template"
                        :min-search-length="3"
                        :input-attr="addressInputAttr"
                        :disabled="readOnly"
                        value-expr="text"
                        v-model="localProperty.address1"
                        @initialized="onAddressContentReady"
                        @focus-out="onAddress1FocusOut"
                        @focus-in="onAddress1FocusIn"
                        @opened="onAddress1Opened"
                        @selectionChanged="onAutoCompleteSelectionChanged"
                        @value-changed="onAutoCompleteValueChanged"
                    >
                        <template #item-template="{ data }">
                            <div>{{data ? data.text : ""}}</div>
                        </template>
                    </dx-autocomplete>
                </div>

                <!--Address 2-->
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                    <label class="form-control-label" for="txt_property_address2">Address 2</label>
                    <input automation_id="txt_property_address2"
                        class="form-control"
                        type="text"
                        v-model="localProperty.address2"
                        grid-field="address2"
                    />
                </div>

                <!--City-->
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                    <label class="form-control-label" for="txt_property_city">City</label>
                    <input automation_id="txt_property_city"
                        class="form-control"
                        type="text"
                        v-model="localProperty.city"
                        grid-field="city"
                        @input="onPropertyFieldChange"
                    />
                </div>

                <!--State-->
                <div v-if="!localProperty.isForeignAddress" class="col-12 col-sm-12 col-md-6 col-lg-4 form-group form-required" :class="{'has-error' : v$.localProperty.state.$error}">
                    <label class="form-control-label" for="drp_property_state">State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_property_state' }"
                        :items="usStatesLookup"
                        value-expr="id"
                        display-expr="id"
                        :disabled="readOnly"
                        v-model="v$.localProperty.state.$model"
                        @value-changed="onPropertyStateChange"
                    />
                    <rq-validation-feedback>State is required</rq-validation-feedback>
                </div>

                <div v-if="localProperty.isForeignAddress" class="col-3 form-group" >
                    <label for="txt_company_regionTerritory">Region/Territory</label>
                    <input id="txt_company_regionTerritory"
                        automation_id="txt_company_regionTerritory"
                        type="text"
                        class="form-control"
                        placeholder="Region/Territory"
                        maxlength="150"
                        v-model="localProperty.regionTerritory"
                    >
                </div>

                <!--County-->
                <div v-if="!localProperty.isForeignAddress" class="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                    <label class="form-control-label" for="drp_property_county">County</label>
                    <dx-select-box ref="countySelectBox"
                        :input-attr="{ automation_id: 'drp_property_county' }"
                        value-expr="id"
                        display-expr="name"
                        :items="stateCounties"
                        :disabled="readOnly || countyFieldDisabled"
                        :search-enabled="true"
                        v-model:display-value="localProperty.countyName"
                        v-model="localProperty.countyID"
                        @value-changed="onDxValueChanged"
                    />
                </div>

                <div v-if="localProperty.isForeignAddress" class="col-3 form-group">
                <label for="txt_company_country">Country</label>
                <input id="txt_company_country"
                    automation_id="txt_company_country"
                    type="text"
                    class="form-control"
                    placeholder="Country"
                    v-model="localProperty.country"
                />
                </div>

                <!--Zip-->
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group" :class="{'has-error' : v$.localProperty.zip.$error}">
                    <label class="form-control-label" for="txt_property_zip">Zip</label>
                    <input v-if="localProperty.isForeignAddress"
                        ref="zipRef"
                        id="txt_property_zip"
                        class="form-control"
                        grid-field="zip"
                        v-model="localProperty.zip"
                        @input="onPropertyFieldChange"
                        always-refresh
                        maxlength="10"
                    />

                    <rq-masked-input v-else
                        ref="zipRef"
                        id="txt_property_zip"
                        maskType="zip"
                        grid-field="zip"
                        v-model="localProperty.zip"
                        is-masked
                        @input="onPropertyFieldChange"
                        always-refresh
                    />
                    <rq-validation-feedback>Zip code format is invalid.</rq-validation-feedback>
                </div>

                <slot></slot>
                
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                    <rq-action-form-group
                        label="Estimated Value"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="estimatedValueOverriden"
                        @action-click="onRevertEstimatedValueOverride">
                        <rqInputNumber
                            :id="txt_estimated_value"
                            decimals="2"
                            input-group
                            allowNull
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            :disabled="readOnly"
                            v-model="localProperty.formEstimatedValue" />
                    </rq-action-form-group>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-12 col-md-3">
            <div ref="imgContainerElement" class="img-thumbnail-container" @click="getPropertyImageForModal">
                <img v-if="imageThumbnail" class="img-thumbnail" :src="imageThumbnail"/>
                <span v-if="imageThumbnail" class="text-center">click image to expand</span>
                <rq-no-data v-else :text="isProcessingAddress ? 'Loading...' : 'No Image Available'" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { h } from "vue";
import { AddressHelper } from '@/shared/utilities';

export default {
    name: 'PropertyInformation',
    props: {
        modelValue: { type: Object, default: () => {}},
        userInitiatedSave: { type: Boolean, default: () => false},
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            addressInputAttr: {},
            addressDataSource: {},
            addressContentReady: false,
            isProcessingAddress: false,
            isAddress1Focused: false,
            clickedImageAddress: "",
            imageThumbnail: "",
            imageModal: "",
            currentAddress: "",
            stateChangeCancelClicked: false,
            forceAddressValidation: false
        }
    },
    computed: {
        localProperty: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        ...mapState({
            orderId: state => state.orders.orderId,
            order: state => state.orders.order,
            orderSummary: state => state.orders.orderSummary,
            loans: state => state.orders.loans,
            properties: state => state.properties.properties,
            user: state => state.authentication.session.user,
            readOnly: state => state.isPageReadOnly
        }),
        ...mapGetters([
            "lookupHelpers",
            "lookupItems",
            "usStatesLookup"
        ]),
        isNewProperty() { return _.parseNumber(this.localProperty.propertyID, 0) === 0; },
        propertyTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.PROPERTY_TYPES, null, this.localProperty.propertyTypeID); },
        stateCounties() { return this.lookupHelpers.getCountiesByState(_.get(this, "localProperty.state", '')); },
        allCounties() { return this.lookupHelpers.getLookupItems('counties'); },
        address() {
            let addressParts = [
                _.trim(this.localProperty.address1),
                _.trim(this.localProperty.city),
                _.trim(this.localProperty.state),
                _.trim(this.localProperty.zip),
            ];

            if (_.some(addressParts, (pt) => !_.isEmpty(pt))) {
                return _.join(addressParts, ' ');
            }
            else {
                return '';
            }
        },
        addressValidateHoverMessage() {
            let message = '';
            if(this.localProperty.isValidatedAddress)
                message = 'Address is already Validated';
            if(!this.isProperAddress)
                message = 'Validation requires address, city and state';
            if(this.localProperty.isAddressOverridden)
                message = 'Cannot validate a manual override'
            return {
                title: message
            }
        },
        isProperAddress() {
            return !(_.isNil(this.localProperty.address1) || this.localProperty.address1.length < 4 ||
                    _.isNil(this.localProperty.state) || this.localProperty.state.length < 2 ||
                    _.isNil(this.localProperty.city) || this.localProperty.city.length < 2);
        },
        countyFieldDisabled() { return _.isEmpty(this.localProperty.state); },
        needsNewImageForModal() {
            return (this.address !== '' && this.clickedImageAddress.toLowerCase() != this.address.toLowerCase()) || this.propertyImageForModal === ''
        },
        estimatedValueOverriden(){
            return this.localProperty.estimatedValueOverride != null;
        }
    },
    watch: {
        localProperty: {
            handler(newValue, oldValue) {
                this.$emit('input', newValue);
            },
            deep: true,
        },

        "localProperty.isValidatedAddress": {
            handler: function(newValue, oldValue) {
                const self = this;

                if (newValue) {
                    self.getThumbnail();
                }
                else if (!newValue) {
                    self.clearThumbnail();
                }
            },
            immediate: true,
        },
        modelValue: {
            handler(newValue, oldValue) {
                const self = this;
                if (newValue.isValidatedAddress) {
                    self.getThumbnail();
                }
            }
        },
    },

    validations() {
        return {
            localProperty: {
                state: {
                    stateValid(value, vm) {
                        return !_.isEmpty(value) || this.localProperty.isForeignAddress || (
                            this.userInitiatedSave === false
                            && this.properties.length === 1
                            && _.isEmpty(this.localProperty.address1)
                            && _.isEmpty(this.localProperty.address2)
                            && _.isEmpty(this.localProperty.city)
                            && _.isEmpty(this.localProperty.zip)
                            && _.isEmpty(this.localProperty.countyID)
                            && !this.isNewProperty);
                    }
                },
                zip: {
                    zipFormatValid: value => _.isEmpty(value) || value.length === 5 || value.length === 10 || this.localProperty.isForeignAddress
                }
            },
        };
    },

    created() {
        const self = this;
        self.initAddressAutocomplete();
    },
    methods: {
        getThumbnail() {
            let self = this;
            if((self.address && self.currentAddress != self.address) || self.imageThumbnail === '') {
                self.isProcessingAddress = true;
                let apiPromise = self.$api.PropertiesApi.getStreetViewImage(self.address, '600x400');
                self.$rqBusy.wait(apiPromise, { targetElement: _.get(self, "$refs.imgContainerElement", null) })
                    .then((image) => {
                        self.imageThumbnail = image ? `data:image/jpg;base64,${image}` : '';
                        self.currentAddress = self.address;
                        self.isProcessingAddress = false;
                    });
            }
        },

        clearThumbnail() {
            const self = this;
            self.imageThumbnail = '';
        },

        updateAddress(property, isAddressValidated) {
            const self = this;

            self.localProperty.address1 = property.address1;
            self.localProperty.address2 = property.address2;
            self.localProperty.city = property.city;
            self.localProperty.state = property.state;
            self.localProperty.zip = property.zip;
            self.localProperty.countyID = property.countyId;
            self.localProperty.countyName = property.county;

            self.updateCounty();

            self.localProperty.isValidatedAddress = isAddressValidated;
        },

        updateCounty() {
            
            let self = this;
            let county = null;
            if (!_.isNil(self.localProperty.countyID)) {
                county = _.find(self.allCounties, { id: self.localProperty.countyID });
            }
            else if (!_.isNil(self.localProperty.countyName)) {
                county = _.find(self.allCounties, (county) => 
                    AddressHelper.isCountyNameSame(county.name, self.localProperty.countyName) &&
                    county.data.toLowerCase().trim() === self.localProperty.state.toLowerCase().trim()
                );
            }
            if (!county) {
                self.localProperty.countyName = "";
            }
            else {
                self.localProperty.countyName = county.name;
                self.localProperty.countyID = county.id;
            }
        },

        onValidateAddress() {
            const self = this;

            self.$rqBusy.wait(self.validateAddress(), { targetElement: _.get(self, "$refs.imgContainerElement", null) });
        },

        mapAddressForValidation() {
            const self = this;

            return {
                address1: self.localProperty.address1,
                address2: self.localProperty.address2,
                city: self.localProperty.city,
                state: self.localProperty.state,
                zip: self.localProperty.zip
            }
        },

        validateAddressOnSave() {
            let self = this;
            if(self.localProperty.isValidatedAddress || _.isEmpty(self.localProperty.address1) || self.localProperty.isAddressOverridden || self.localProperty.isForeignAddress) return Promise.resolve(true);
            return self.validateAddress();
        },

        validateAddress(property={}, showToast=true) {
            const self =  this;

            let entity = !_.isEmpty(property) ? property : self.mapAddressForValidation();

            if (self.localProperty.isForeignAddress) return;

            self.isProcessingAddress = true;
            return self.$api.UtilitiesApi
                .verifyAddress(entity)
                .then((response) => {
                    if(!response.isVerified) {
                        self.localProperty.isValidatedAddress = false;
                        if(showToast)
                        {
                            self.$toast.error("Invalid Address");
                        }
                        return false;
                    }

                    self.updateAddress(response, true);
                    return true;
                })
                .catch((error) => {
                    self.localProperty.isValidatedAddress = false;
                    if(showToast)
                    {
                        self.$toast.error("Invalid Address");
                    }
                    return false;
                })
                .finally(() => {
                    self.isProcessingAddress = false;
                })
        },

        onAutoCompleteSelectionChanged(e) {
            const self = this;

            let selectedItem = e.component.option("selectedItem");

            if(!_.isObject(selectedItem)) return;

            let previousState = self.localProperty.state;
            let newState  = selectedItem.state;

            self.updateAddress({
                address1: selectedItem.address1,
                address2: selectedItem.address2,
                city: selectedItem.city,
                state: selectedItem.state,
                zip: selectedItem.zip
            }, false);

            if(previousState === newState) {
                self.validateAddress();
            }
            else {
                self.forceAddressValidation = true;
            }
        },

        onAutoCompleteValueChanged(e) {
            if(_.isNil(e.event)) return;
            const self = this;

            self.resetValidatedAddress();
        },

        onPropertyStateChange(e) {
            const self = this;

            if(self.stateChangeCancelClicked) {
                self.stateChangeCancelClicked = false;
                return;
            }

            let currentValidatedValue = self.localProperty.isValidatedAddress;
            self.localProperty.isValidatedAddress = false;

            if(self.isNewProperty) {
                if(self.forceAddressValidation) {
                    self.forceAddressValidation = false;
                    self.validateAddress();
                }
                return;
            }

            let newValue = _.get(e, 'value');
            let oldValue = _.get(e, 'previousValue');
            let isAlta = _.get(self.orderSummary, "isAlta", false);
            let altaValue = newValue !== "TX";

            if(isAlta === altaValue && oldValue !== "OH") {
                if(self.forceAddressValidation) {
                    self.forceAddressValidation = false;
                    self.validateAddress();
                }
                return;
            }

            let okHandler = () => {
                self.localProperty.isValidatedAddress = false;
                self.$emit('state-change', e);
                if(self.forceAddressValidation) {
                    self.forceAddressValidation = false;
                    self.validateAddress();
                }
                return true;
            };

            let cancelHandler = () => {
                self.localProperty.state = oldValue;
                self.localProperty.isValidatedAddress = currentValidatedValue;
                self.stateChangeCancelClicked = true;
            };

            let messages = {
                fromOhio: "Changing the State from Ohio to any other State will cause the loss of any State of Ohio specific tax information that has been saved.",
                fromTexas: "Title Production/Policy will be changed to ALTA.",
                toTexas: "Title Production/Policy will be changed to TX."
            };

            let alertMessage = "";

            if (!altaValue) alertMessage = `<p>${messages.toTexas}</p>`;
            if (!isAlta) alertMessage += `<p>${messages.fromTexas}</p>`;
            if (oldValue === "OH") alertMessage += `<p>${messages.fromOhio}</p>`;

            self.$dialog.confirm(
                "WARNING",
                `${alertMessage}<p>Are you sure you want to continue?</p>`,
                okHandler,
                cancelHandler,
                { width: 600 }
            );
        },

        initAddressAutocomplete() {
            const self = this;
            self.addressDataSource = {
                key: "text",
                load(loadOptions){
                    return self.localProperty.isForeignAddress ? [] : self.$api.UtilitiesApi.addressAutoComplete(loadOptions.searchValue, self.localProperty.state || "");
                }
            };
            self.addressInputAttr = {
                autocomplete: Math.random(),
                automation_id: "txt_property_address1",
            };
        },

        onAddressContentReady(e) {
            const self = this;
            if (self.addressContentReady) return;
            self.addressContentReady = true;
            self.$nextTick(() => {
                e.component.focus();
            });
        },

        onAddress1FocusIn(e){
            this.isAddress1Focused = true;
        },

        onAddress1FocusOut(e){
            this.isAddress1Focused = false;
        },

        onAddress1Opened(e){
            if(this.isAddress1Focused || !this.$refs.address1Autocomplete) return;
            this.setAddressAutocompleteOption("opened", false);
        },

        setAddressAutocompleteOption(optionName, val) {
            _.invoke(this, "$refs.address1Autocomplete.instance.option", optionName, val);
        },

        validate() {
            const self = this;

            // For some reason this value mysteriously gets lost.
            self.updateCounty();

            self.v$.$touch();

            return !self.v$.$error;
        },

        getPropertyImageForModal(){
            let self = this;
            if(self.needsNewImageForModal) {
                self.$api.PropertiesApi.getStreetViewImage(self.address, '1200x800')
                .then((image) => {
                    if(image) {
                        self.imageModal = `data:image/jpg;base64,${image}`;
                        self.openPropertyImageModal();
                        self.clickedImageAddress = self.address;
                    }
                });
            }
            else {
                self.openPropertyImageModal();
            }
        },

        openPropertyImageModal(){
            let self = this;
            self.$dialog.open({
                title: "Property Image",
                height: "auto",
                width: 700,
                resizable: true,
                scrollable: false,
                adaptive: true,
                okOnly: true,
                component: {
                    name: "PropertyImageBox",
                    render() {
                        return h("img", { class: "w-100", src: this.imgSrc })
                    },
                    data() {
                        return {
                            imgSrc: self.imageModal
                        }
                    }
                },
                onOk: ()=>{}
            });
        },

        onDxValueChanged(e) {
            const self = this;

            if(_.isNil(e.event)) return;

            self.resetValidatedAddress();
        },

        onPropertyFieldChange(e) {
            const self = this;

            self.resetValidatedAddress();
        },

        resetValidatedAddress(e) {
            const self = this;

            self.localProperty.isValidatedAddress = false;
        },

        resetValidation() {
            const self = this;
            if(!_.isFunction(self.v$.$reset)) return;
            self.v$.$reset();
        },

        onRevertEstimatedValueOverride(){
            this.localProperty.formEstimatedValue = null;
        }
    }
}
</script>
