<template>
    <div class="content-wrapper">
        <RqPageSection
            title="Policy"
            header-size="lg"
            class="mb-2"
            body-class="p-0">
            <template #header-secondary>
                <slot name="loan-list-link"></slot>
                <button
                    automation_id="btn_view_premiums"
                    type="button"
                    class="btn btn-link btn-theme"
                    @click="onShowPremiums"
                    v-if="premiumsSecurity.PremiumsAccessible">View Premiums
                </button>
            </template>
            <ul class="nav nav-tabs rq-nav-tabs border-bottom-0">
                <li v-for="tab in tabItems"
                    :key="tab.name"
                    class="nav-item">
                    <RouterLink
                        class="nav-link rq-tab"
                        :to="tab.route">{{ tab.title }}
                    </RouterLink>
                </li>
            </ul>
        </RqPageSection>
        <RqScrollContainer>
            <RouterView v-slot="{ Component }">
                <component
                    :is="Component"
                    ref="routeComponent"
                    @policy-voided="onPolicyVoided"
                />
            </RouterView>
        </RqScrollContainer>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { OrderServiceType } from "@/shared/models/enums";
    import { COMMITMENT_ACTIONS } from "@/store/actions";
    import PremiumsMain from '@settlement/components/premiums/PremiumsMain';
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        name: "PolicyMain",

        props: { },
        components: {  },
        data() {
            return {
                tabItems: [],
                showGenerateEzJacket: false
            }
        },
        computed: {
            ...mapState({
                commitment: state => state.orders.commitment || {},
                orderId: state => state.orders.order.ordersID,
                orderSummary: state => state.orders.orderSummary || {},
                serviceId: state => _.parseNumber(state.orders.order.service, -1),
                readOnly: state => _.parseBool(state.isPageReadOnly),
                loans: state => state.orders.loans || [],
                systemDefaults: state => state.system.systemDefaults,
                orderBranch: state => state.orders.branch || {},
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            hasExJacketUnderwriter() { return _.isEqual(this.orderSummary.underwriterID, this.systemDefaults.ezJacketUnderwriterCompanyID)},
            hasVoidedPolicies() { return _.parseBool(this.orderSummary.hasVoidedPolicies)},
            localSecurity() { return this.securitySettings.findValues([ "CanVoidPolicies", "CanUnlockPolicyRemitDates"]); },
            premiumsSecurity() { return { PremiumsAccessible: this.securitySettings.hasAccess("Premiums") }; },
        },

        watch: {
        },

        created () {
            const self = this;
            self.initListeners();
            const licenseStore = useLicenseStore();
            self.showGenerateEzJacket = licenseStore.checkFeature('ezJacket') && self.hasExJacketUnderwriter;
            self.initTabs();
        },
        beforeUnmount () {
            this.$store.dispatch(COMMITMENT_ACTIONS.CLEAR_STATE);
            this.$events.off("add-jacket", this.onAddJacket);
            this.$events.off("schedule-upload", this.onScheduleUpload);
            this.$events.off("refresh-jackets", this.onRefreshJackets);
        },

        methods: {
            initTabs() {
                const self = this;

                let isOwner = OrderServiceType.isOwnerSpecific(self.serviceId);
                let isLoan = OrderServiceType.isLoanSpecific(self.serviceId);
                let isSimultaneousEligible = OrderServiceType.isSimultaneousEligible(self.serviceId);

                self.tabItems = [{ title: "Policies", name: "policy-list", route: { name: "policy:list" } }];
                if(isSimultaneousEligible || isOwner) {
                    self.tabItems.push({ title: "Owner", name: "owner-policy", route: { name: "policy:owner:form" } });
                }

                let isFileNonTexasAndServiceSetToTitleGuarantee = self.orderSummary.isAlta && self.serviceId == OrderServiceType.AltaTitleGuard;

                if((isSimultaneousEligible || isLoan) && !isFileNonTexasAndServiceSetToTitleGuarantee) {
                    let loanTabs = _.map(self.loans, (loan, index) => ({
                        title: _.isNil(loan.lender) ? 'Loan': `Loan (${loan.lender})`,
                        name: `loan-policy-${index}`,
                        route: {name : 'policy:loan:form', params: { loanId: loan.loanID } }
                    }));

                    self.tabItems.push(...loanTabs);
                }
                if (self.showGenerateEzJacket) {
                    self.tabItems.push({
                        title: "Policy Jackets",
                        name: "policy-jackets",
                        route: {name : "policy:jackets:form" }
                    });
                }
                if (self.hasVoidedPolicies) {
                    self.tabItems.push({
                        title: "Voided Policies",
                        name: "voided-policy",
                        route: {name : "policy:voided:form" }
                    });
                }
                if (!_.some(self.tabItems, i => i.route.name === self.$route.name)) {
                    self.$router.push({ name: "policy:list" });
                }
            },
            initListeners(){
                this.$events.on("add-jacket", this.onAddJacket);
                this.$events.on("schedule-upload", this.onScheduleUpload);
                this.$events.on("refresh-jackets", this.onRefreshJackets);
            },
            onAddJacket() {
                const self = this;
                if(_.some(self.tabItems, { name: "generate-jacket" })) self.$router.push({ name: "policy:ez-jacket:form" });
                self.tabItems.push({ title: "Generate Jacket", name: "generate-jacket", route: {name : "policy:ez-jacket:form" }});
                self.$router.push({ name: "policy:ez-jacket:form" });
            },
            onScheduleUpload(id) {
                const self = this;
                if(_.some(self.tabItems, { name: "schedule-upload" })) self.$router.push({ name: "policy:schedule-upload:form", params: { id } });
                self.tabItems.push({ title: "Schedule Upload", name: "schedule-upload", route: {name : "policy:schedule-upload:form", params: { id } }});
                self.$router.push({ name: "policy:schedule-upload:form", params: { id } });
            },
            onShowPremiums(){
                const self = this;

                let okHandler = function (e) {
                    return e.component.save(true).then(() => {
                        self.$refs?.routeComponent?.fetchData?.();
                        return true;
                    });
                };

                self.$dialog.open({
                    title: "Premium Rates",
                    width: "95%",
                    minWidth: 1200,
                    height: "95%",
                    minHeight: 700,
                    component: PremiumsMain,
                    props: { noTitle: true, showHeaderCalculate: true },
                    onOk: okHandler
                });
            },
            onPolicyVoided() {
                const self = this;
                if(_.some(self.tabItems, { name: "voided-policy" })) return;
                self.tabItems.push({
                    title: "Voided Policies",
                    name: "voided-policy",
                    route: {name : "policy:voided:form" }
                });
            },
            onRefreshJackets(e) {
                const self = this;
                _.remove(self.tabItems, { name: "generate-jacket" });
                if (e) self.$router.push({ name: "policy:jackets:form" });
            }
        }
    };
</script>