import { LoansViewActions } from "@/router/actions/order-entry";
import OrderLoansMain from "./OrderLoansMain";
import ManageLoansPage from "./OrderLoans";
import LoanPage from "./LoanForm";
import Store from "@/store";

const noAccessMsg = "Access to orders is not authorized.  Please contact your administrator.";

export default {
    path: "loans",
    component: OrderLoansMain,
    meta: {
        label: "Loans",
        include: true
    },
    children: [
        {
            name: "oe:ol:loans",
            path: "",
            component: ManageLoansPage,
            beforeEnter: (to, from, next) => {
                let orderLoans = _.get(Store, "state.orders.loans", null) || [];
                next(orderLoans.length !== 1 || {
                    name: "oe:ol:loan",
                    params: {
                        orderId: from.params.orderId,
                        loanId: orderLoans[0].loanID
                    },
                    hash: to.hash || ""
                });
            },
            meta: {
                include: false,
                entity: "Loan",
                extension: false,
                label: "Loans",
                logRouteAccess: true,
                addEnabled: true,
                deleteEnabled: true,
                reorderEnabled: true,
                actions: LoansViewActions.LIST,
                accessKey: "OrderEntry",
                unauthorizedMessage: noAccessMsg,
                skipSaveOnLeave: true,
                disableBackToTop: true,
                templateCategory: "ol"
            }
        },
        {
            name: "oe:ol:loan",
            path: "loan/:loanId?",
            component: LoanPage,
            meta: {
                entity: "Loan",
                extension: false,
                label: "Loan",
                logRouteAccess: true,
                actions: LoansViewActions.FORM,
                skipSaveOnLeave: false,
                accessKey: "OrderEntry",
                unauthorizedMessage: noAccessMsg,
                templateCategory: "ol"
            }
        }
    ]
};
