<template>
    <rq-list-selection-layout
        automation-id-append="consolidated-checks"
        :actions="assignmentActions"
        @transfer-all-right="onAddAllAvailableItems"
        @transfer-right="onAddSelectedAvailableItems"
        @transfer-left="onRemoveSelectedItems"
        @transfer-all-left="onRemoveAllSelectedItems">
        <template #left-section>
            <rqdx-action-data-grid ref="availableItemsDataGrid"
                :title="availableItemTitle"
                title-size="sm"
                automation_id="dg_availableItemsDataGrid"
                :dataSource="availableItemsGridDataSource"
                :config="availableItemsGridConfig"
                :actions="selectionActions"
                :strikethrough-if-false="['isAvailableForTransferSlip', 'isValidDate']"
                :readOnly="readOnly"
                @enterKeyDown="onAddSelectedAvailableItems"
                @rowDoubleClick="onAvailableItemsGridRowDoubleClick"
                @selectionChanged="onAvailableItemsSelectionChanged"
                @set-available="onSetAvailability($event, true)"
                @set-unavailable="onSetAvailability($event, false)"
                rq-filters
                hide-search
            />
        </template>
        <template #right-section>
            <rqdx-action-data-grid ref="selectedItemsDataGrid"
                :title="selectedItemTitle"
                title-size="sm"
                automation_id="dg_selectedItemsDataGrid"
                :dataSource="selectedItemsGridDataSource"
                :config="selectedItemsGridConfig"
                :readOnly="readOnly"
                @enterKeyDown="onRemoveSelectedItems"
                @rowDoubleClick="onSelectedItemsGridRowDoubleClick"
                @selectionChanged="onSelectedItemsSelectionChanged"
                hide-search
            />
        </template>
    </rq-list-selection-layout>
</template>

<script>
    import { ref } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import { DepositShortDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { useDualGridSelection } from "@/shared/composables/useDualGridSelection";

    export default {
        name: 'TransferSelector',
        props: {
            transferSlip: {type: Object, default: () => ({})},
            unavailableMessage: { type: String, default: "" }
        },
        setup(props) {
            const constants = {
                itemTypeName: "Deposit",
                itemTypeNamePlural: "Deposits",
                itemKey: "depositID"
            };

            const items = ref([]);
            const tooltipMessage = ref("");

            const {
                availableItemsDataGrid,
                selectedItemsDataGrid,
                availableItemsGridDataSource,
                selectedItemsGridDataSource,
                hasAvailableItems,
                hasSelectedItems,
                hasAvailableItemsSelected,
                hasSelectedItemsSelected,
                selectedIDs,
                getItemData,
                invokeAvailableItemsGridMethod,
                invokeSelectedItemsGridMethod,
                refreshGrids,
                refreshAvailableGrid,
            } = useDualGridSelection({
                items,
                itemKey: constants.itemKey
            });

            return {
                ...constants,
                items,
                tooltipMessage,

                //from "useDualGridSelection"
                selectedIDs,
                availableItemsDataGrid,
                selectedItemsDataGrid,
                availableItemsGridDataSource,
                selectedItemsGridDataSource,
                hasAvailableItems,
                hasSelectedItems,
                hasAvailableItemsSelected,
                hasSelectedItemsSelected,
                getItemData,
                invokeAvailableItemsGridMethod,
                invokeSelectedItemsGridMethod,
                refreshGrids,
                refreshAvailableGrid,
            };
        },
        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },
        watch: {
            transferSlip() {
                this.fetchData();
            },
            selectedIDs: {
                handler(newValue, oldValue) {
                    //commenting out for now...newValue/oldValue is always equal for some reason
                    // if(!_.differs(newValue, oldValue)) return;
                    this.$emit("update-transfer-ids", newValue);
                    this.refreshGrids();
                },
                deep: true
            },
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                allBranches: state => _.get(state, "system.lookups.branches", []),
                systemDefaults: state => state.system.systemDefaults,
            }),
            unavailableMessageValue: {
                get() { return this.unavailableMessage; },
                set(val) { return this.$emit("update:unavailableMessage", val); },
            },
            availableItemTitle() { return `Available Deposits for ${this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, _.get(this, 'transferSlip.bankCompanyID'))}:`; },
            selectedItemTitle() { return `Assigned Deposits:`; },
            selectedItems() { return _.get(this, "items.length", 0) != 0; },
            localSecurity(){ return this.securitySettings.findValues(["AllowDepositSlipMaintenance","DepositSlip_ScreenAccess"]); },
            readOnly() { return !_.isNil(_.get(this.transferSlip, "dateVerified", null)) || !this.localSecurity.AllowDepositSlipMaintenance || this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read },
            transferSlipID() {
                return _.getNumber(this, "transferSlip.transferSlipID", 0);
            },
            assignmentActions() {
                let self = this;
                return [
                    { name: "transfer-all-right", tooltip: () => `Assign all <br />${this.assignAllToolTipMessage}`, disabled: () => !this.hasAvailableItems || this.readOnly },
                    { name: "transfer-right", tooltip: () => `Assign Selected <br />${this.tooltipMessage}`, disabled: () => !this.hasAvailableItemsSelected || this.readOnly },
                    { name: "transfer-left", tooltip: () => `Unassign Selected${!this.hasSelectedItemsSelected ? "<br />(none selected)" : ""}`, disabled: () => !this.hasSelectedItemsSelected || this.readOnly },
                    { name: "transfer-all-left", tooltip: () => "Unassign All", disabled: () => !this.selectedItems || this.readOnly }
                ];
            },
            assignAllToolTipMessage() {
                let availableItems = this.getItemData("available");
                if(_.some(availableItems, item => !item.isValidDate))
                    return this.getInvalidMessage(true);
                else if(_.some(availableItems, item => !item.isAvailableForTransferSlip))
                    return this.getUnavailableMessage(true);
                else
                    return "";
            },
        },
        methods: {
            getInvalidMessage(all=false) {
                return `One or more ${all ? "" : "selected "}Deposits is unavailable since it is dated after Deposit Slip Date`;
            },
            getUnavailableMessage(all=false) {
                return `One or more of the ${all ? "" : "selected "}Deposits is unavailable`;
            },
            elementName(prefix="", item="", suffix="") { return _.snakeCase(`${prefix} ${item} ${suffix}`); },
            fetchData() {
                const self = this;
                if (self.transferSlipID == 0) return;
                let apiPromise = self.$api.TransferSlipsApi.getDetail(self.transferSlipID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result.availableDeposits, c => new DepositShortDto(c));
                        if(self.systemDefaults.enforceDateValidationsOfDepositSlips) {
                            _.updateAll(self.items, "isValidDate", item => DateTime.fromISO(item.depositDate) <= DateTime.fromISO(self.transferSlip.transferDate));
                        }
                        else {
                             _.updateAll(self.items, "isValidDate", true);
                        }
                        self.selectedIDs = result.depositIDs;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Transfer Slip Data.` });
                    })
                    .finally(() => {
                        self.refreshGrids();
                    });
            },
            initGridConfig(){
                const self = this;
                self.availableItemsGridConfig = {
                    allowColumnReordering: false,
                    scrolling: { useNative: true },
                    columns: [
                        {
                            dataField: "regionID",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            caption: "Region",
                            lookup: {
                                dataSource: self.regions,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            minWidth: 80
                        },
                        {
                            dataField: "branchID",
                            caption: "Branch",
                            lookup: {
                                dataSource: self.branches,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            minWidth: 80,
                        },
                        {
                            dataField: "transactionNumber",
                            caption: "File#",
                            cellTemplate: function(cellElement, cellInfo) {
                                let parts = _.split(cellInfo.value, '-').slice(0, -1);
                                let displayText = _.join(parts, "-");
                                $("<span />")
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                            minWidth: 80,
                        },
                        DxGridUtils.dateColumn({
                            dataField: "depositDate",
                            caption: "Date",
                        }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            minWidth: 90,
                        },
                        {
                            dataField: "payor",
                            //cellTemplate: DxGridUtils.truncateCellTemplate,
                            minWidth: 100,
                            wordWrapEnabled: true
                        },
                        {
                            dataField: "description",
                            //cellTemplate: DxGridUtils.truncateCellTemplate,
                            minWidth: 100,
                            wordWrapEnabled: true
                        },
                        {
                            dataField: "isAvailableForTransferSlip",
                            dataType: "boolean",
                            caption: "Available",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            minWidth: 100
                        }
                    ],
                    onRowPrepared (e) {
                        if (e.rowType !== "data" || e.data.isAvailableForTransferSlip == true) return;
                        e.columns[0].visible = false;
                    },
                };
                self.selectedItemsGridConfig = {
                    allowColumnReordering: false,
                    scrolling: { useNative: true },
                    columns: [
                        {
                            dataField: "transactionNumber",
                            caption: "File#",
                            cellTemplate: function(cellElement, cellInfo) {
                                let parts = _.split(cellInfo.value, '-').slice(0, -1);
                                let displayText = _.join(parts, "-");
                                $("<span />")
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                        },
                        DxGridUtils.dateColumn({
                            dataField: "depositDate",
                            caption: "Date",
                        }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "payor",
                            //cellTemplate: DxGridUtils.truncateCellTemplate,
                            minWidth: 100,
                            wordWrapEnabled: true
                        },
                        {
                            dataField: "description",
                            //cellTemplate: DxGridUtils.truncateCellTemplate,
                            minWidth: 100,
                            wordWrapEnabled: true
                        }
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "Total",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                };
            },
            initNonReactiveVariables() {
                const self = this;
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                self.regions = self.lookupHelpers.getLookupItems(self.lookupItems.REGIONS, 1);
                self.branches = self.allBranches;
                self.readOnlyColumns = ["dateVerified", "bankCompanyID", "amount", "branchID", "reconciliationID", "verifiedByName", "verifiedByUsersID"];
                self.selectionActions = [
                    {
                        name: "set-available",
                        text: "Set Available",
                        eventName: "set-available",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Set this Deposit as Available`,
                        disabled: function(e) { return self.readOnly || _.some(e.data, ["isAvailableForTransferSlip", true]);}
                    },
                    {
                        name: "set-unavailable",
                        text: "Set Unavailable",
                        eventName: "set-unavailable",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Set this Deposit as Unavailable`,
                        disabled: function(e) { return self.readOnly || _.some(e.data, ["isAvailableForTransferSlip", false]);}
                    }
                ];
            },
            getAvailableItems(items) {
                const self = this;
                self.validateAvailability(items);
                return _.filter(items, item => {
                                    return item.isAvailableForTransferSlip
                                        && item.isValidDate
                                        && (!self.systemDefaults.allowNegatibeDepositsOnDepositsSlips ? item.amount >= 0 : true)
                                })
                        || [];
            },
            validateAvailability(itemOrItems) {
                const self = this;
                let hasUnavailable = _.isArray(itemOrItems) ? _.some(itemOrItems, item => !item.isAvailableForTransferSlip) : !itemOrItems.isAvailableForTransferSlip;
                let hasInvalidDates = _.isArray(itemOrItems) ? _.some(itemOrItems, item => !item.isValidDate) : !itemOrItems.isValidDate;
                let allNegativeAmount = false;
                if(!self.systemDefaults.allowNegatibeDepositsOnDepositsSlips) {
                    //if all items has negative amount value then disable button
                    allNegativeAmount = _.isArray(itemOrItems) ? _.every(itemOrItems, x => x.amount < 0) : itemOrItems.amount < 0;
                }
                let hasEmpty= _.isEmpty(itemOrItems)
                self.tooltipMessage = hasInvalidDates
                    ? this.getInvalidMessage() : (hasUnavailable || allNegativeAmount)
                        ? this.getUnavailableMessage() : hasEmpty
                            ? "(none selected)" : "";
            },
            onAddAllAvailableItems(e) {
                if (this.readOnly) return;
                this.$dialog.confirm("Confirm Add All", "Are you sure?  This will add all items.", () => {
                    let availableItemsData = this.getItemData("available");
                    let availableItems = this.getAvailableItems(availableItemsData);
                    this.addSelectedItems(availableItems);
                });
            },
            onAddSelectedAvailableItems(e) {
                if (this.readOnly) return;
                let selectedItems = this.invokeAvailableItemsGridMethod("getSelectedRowsData");
                let availableItems = this.getAvailableItems(selectedItems);
                this.addSelectedItems(availableItems);
            },
            onAvailableItemsSelectionChanged(e) {
                if (this.readOnly) return;
                let availableItems = this.getAvailableItems(e.selectedRowsData);
                this.hasAvailableItemsSelected = (availableItems.length > 0);
            },
            onAvailableItemsGridRowDoubleClick(e) {
                if (this.readOnly) return;
                let invalidToastMessage = !e.data.isAvailableForTransferSlip
                    ? "This deposit is not available for deposit slip"
                    : !e.data.isValidDate
                        ? "This deposit is dated after the deposit slip date"
                        : "";
                if (_.isEmpty(invalidToastMessage)) {
                    this.unavailableMessageValue = "";
                    let deposit = _.get(e, "data");
                    this.addSelectedItems([deposit]);
                    return;
                }
                this.$toast.error(invalidToastMessage);
                e.component.deselectRows([e.key]);
            },
            onSetAvailability(e, available) {
                if(!e || !e.data) return;
                if (this.readOnly) return;
                const self = this;
                let depositIDs = _.map(e.data, self.itemKey);
                let noun = depositIDs.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                let verb = available ? 'Available' : 'Unavailable'
                let ok = function (args) {
                    self.setAvailability(depositIDs)
                        .then(result => {
                            self.toggleAvailability(depositIDs);
                            self.$toast.success({ message: `${depositIDs.length} ${noun} set to ${verb}.` });
                        }).catch(error => {
                            self.$toast.error({ message: `${error.errorMessage}` });
                        })
                        .finally(() => {
                            self.refreshAvailableGrid();
                        });
                }
                self.$dialog.confirm("Confirm Availability", `Are you sure you want to set the selected ${noun} to ${verb}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onSelectedItemsGridRowDoubleClick(e) {
                if (this.readOnly) return;
                let itemIndex = _.indexOf(this.selectedIDs, e.key);
                this.selectedIDs.splice(itemIndex, 1);
            },
            onSelectedItemsSelectionChanged(e) {
                if (this.readOnly) return;
                this.hasSelectedItemsSelected = (e.selectedRowKeys.length > 0);
            },
            onRemoveAllSelectedItems(e) {
                if (this.readOnly) return;
                this.$dialog.confirm("Confirm Remove All", "Are you sure?  This will remove all items.", () => {
                    this.selectedIDs = [];
                });
            },
            onRemoveSelectedItems(e) {
                if (this.readOnly) return;
                let allKeys = this.invokeSelectedItemsGridMethod("getSelectedRowKeys") || [];
                this.selectedIDs = _.pull(this.selectedIDs, ...allKeys);
            },
            setAvailability(depositIDs){
                const self = this;
                let apiPromise = self.$api.TransferSlipsApi.depositAvailability(depositIDs);
                return self.$rqBusy.wait(apiPromise);
            },
            toggleAvailability(keys) {
                _.each(keys, k => {
                    let e = _.find(this.items, [this.itemKey, k]);
                    e.isAvailableForTransferSlip = !e.isAvailableForTransferSlip;
                });
            },
            addSelectedItems(items) {
                let toBeAddedList = items;
                if(!this.systemDefaults.allowNegatibeDepositsOnDepositsSlips) {
                    // only items with none negative values can be added;
                    toBeAddedList = _.filter(items, x => x.amount >= 0);
                }

                let allKeys = _.map(toBeAddedList, this.itemKey);
                this.selectedIDs.push(...allKeys);
            }
        }
    }
</script>