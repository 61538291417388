<template>
    <div :id="componentId" class="col col-4">
        <b-tooltip
            v-if="tooltipText"
            :target="componentId"
            :placement="tooltipPlacement"
            triggers="hover"
            container="body"
            boundary="window"
            :boundary-padding="0"
            v-model:show="tooltipVisible"><span v-html="tooltipText"></span>
        </b-tooltip>
        <div v-if="isDropdown" class="dropdown">
            <button
                class="btn btn-sm btn-action dropdown-toggle"
                :automation_id="action.automation_id"
                type="button"
                data-bs-toggle="dropdown"
                :disabled="isDisabled">
                <div class="button-content" v-html="action.text"></div>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
                <li
                    v-for="childAction in action.children"
                    :key="childAction.key">
                    <hr v-if="childAction.key === 'action-divider'" :key="`${childAction.key}_${index}_divider`" class="dropdown-divider">
                    <div v-else-if="childAction.children">
                        <h5 class="dropdown-header"><span>{{childAction.text}}</span></h5>
                        <ul style="padding:0;">
                            <li
                                v-for="submenuAction in childAction.children"
                                :key="submenuAction.key">
                                <button
                                    :automation_id="submenuAction.automation_id"
                                    type="button"
                                    class="dropdown-item"
                                    :disabled="!canExecuteAction(submenuAction)"
                                    @click="onActionClick(submenuAction)">
                                    <span>{{submenuAction.text}}</span>
                                    <span v-if="!canExecuteAction(submenuAction)" class="text-muted font-italic ms-2">(Access Restricted)</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <button
                        v-else
                        :automation_id="childAction.automation_id"
                        type="button"
                        class="dropdown-item"
                        :disabled="!canExecuteAction(childAction)"
                        @click="onActionClick(childAction)">
                        <span>{{childAction.text}}</span>
                        <span v-if="!canExecuteAction(childAction)" class="text-muted font-italic ms-2">(Access Restricted)</span>
                    </button>
                </li>
            </ul>
        </div>
        <b-btn v-else variant="action" size="sm" :disabled="isDisabled" @click="onActionClick()" :automation_id="action.automation_id" block>
            <span :class="{ 'multiline-text': action.multiline }" v-html="action.text"></span>
        </b-btn>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    export default {
        name: "OrderSummaryAction",
        props: {
            action: { type: Object, default: ()=>({}) },
            tooltipPlacement: { type: String, default: "top" }
        },

        data() {
            return {
                tooltipVisible: false
            };
        },

        computed: {
            ...mapState({
                readOnly: state => _.parseBool(state.isPageReadOnly)
            }),
            ...mapGetters([
                "executionService"
            ]),
            componentId() { return _.uniqueId("rq-order-summary-action-"); },
            isDropdown() { return !_.isEmpty(this.action.children); },
            isDisabled() { return !this.canExecuteAction(); },
            tooltipText() {
                if (_.isEmpty(this.action.tooltip)) return this.isDisabled ? "(Access Restricted)" : "";
                let tooltip = _.replace(this.action.tooltip, "[entity]", this.$route.meta.entity);
                return this.isDisabled ? `${tooltip}<br />(Access Restricted)` : tooltip;
            }
        },

        methods: {
            onActionClick(action) {
                let targetAction = action || this.action;
                this.tooltipVisible = false;
                GlobalEventManager.fireEvent({ ...targetAction, userInitiated: true });
            },
            canExecuteAction(action) {
                let targetAction = action || this.action;
                let disabledByPageReadOnly = this.readOnly && _.parseBool(targetAction.disableWhenReadOnly);
                let permissionKeys = targetAction.permissionKeys || [];
                let accessKey = targetAction.accessKey || "";

                if (_.isEmpty(permissionKeys) && _.isEmpty(accessKey)) return !disabledByPageReadOnly;

                return this.securitySettings.hasPermission(permissionKeys)
                    && this.securitySettings.hasAccess(accessKey)
                    && ((_.isNil(action) && this.isDropdown) || !this.securitySettings.isReadOnly(accessKey))
                    && !disabledByPageReadOnly;
            }
        }
    }

</script>