<template>
    <div>
        <div>
            <div class="card mb-1" style="min-height:251px;">
                <div class="card-header">Holder Information</div>
                <div class="row m-0 px-1 pt-1" v-if="bankCompanyID === null && (earnestMoneyHeldBy === 0 || earnestMoneyHeldBy === null)">
                    <div class="col-12 alert alert-warning mb-0 py-1 text-center" role="alert">
                        <span>No Escrow Bank is selected - No Deposit will be Recorded</span>
                    </div>
                </div>
                <div class="card-body p-2">
                    <fieldset>
                        <div class="row m-0">
                            
                            <!--Earnest Money Holder -->
                            <fieldset class="col-12 col-md-6 col-lg-6 col-xl-12 form-group mb-0 px-1">
                                <label class="form-control-label" for="drp_earnest_money_holder">Earnest Money Holder</label>
                                <rqSelectBox 
                                    id="drp_earnest_money_holder"
                                    automation_id="drp_earnest_money_holder" 
                                    ref="drp_earnest_money_holder"
                                    :items="earnestMoneyHolderOptions" 
                                    v-model="earnestMoneyHeldBy">
                                </rqSelectBox>
                            </fieldset>

                            <!--Earnest Money Fund Type -->
                            <fieldset class="col-12 col-md-6 col-lg-6 col-xl-12 form-group mb-0 px-1" v-if="earnestMoneyHeldBy === 0 || earnestMoneyHeldBy === null">
                                <label class="form-control-label" for="drp_escrow_fund_type">Type Of Funds</label>
                                <rqSelectBox 
                                    id="drp_escrow_fund_type"
                                    automation_id="drp_escrow_fund_type" 
                                    :items="escrowDepositFundOptions" 
                                    v-model="typeFundID"
                                    :disabled="!canEditFundType">
                                </rqSelectBox>
                            </fieldset>

                            <fieldset class="col-12 form-group mb-0" v-if="earnestMoneyHeldBy === 2 || earnestMoneyHeldBy === 3">                                    
                                <b-form-checkbox automation_id="chk_deductAmount" id="deductAmount" v-model="deductAmount" class="mt-1">
                                    Deduct amount from realtor commission check
                                </b-form-checkbox>
                            </fieldset>
                        </div>
                    </fieldset>
                </div>
            </div>
         </div>
    </div>
</template>

<script>

    export default {
        props:{ args:{} },
        data(){
            const self = this;
            return{
                earnestMoneyHolderOptions:[],
                escrowDepositFundOptions:[],
                typeFundID:0,
                earnestMoneyHeldBy:null,
                deductAmount: false,
                bankCompanyID: null,
                canEditFundType: true
            }
        },
        mounted(){
            const self = this;
            self.typeFundID = _.isNil(self.args.typeFundID) ? 4 : self.args.typeFundID; //default to check
            self.canEditFundType = _.isNil(self.args.typeFundID);
            self.earnestMoneyHeldBy = self.args.order.earnestMoneyHeldBy;
            self.earnestMoneyHolderOptions = self.args.earnestMoneyHolderOptions || [];
            self.escrowDepositFundOptions = self.args.escrowDepositFundOptions || [];
            self.bankCompanyID = self.args.order.bankCompanyID;
            self.$refs.drp_earnest_money_holder.setFocus();
        }

    }

</script>