import { EndorsementPolicy, EndorsementYesNo } from ".";

export default class OrderEndorsement {
	constructor(options) {
		options = options || {};
		this.isSelected = _.parseBool(options.isSelected);
		this.orderEndorsementID = _.parseNumber(options.orderEndorsementID, 0);
		this.ordersID = _.parseNumber(options.ordersID, 0);
		this.orderRateCalculationID = _.parseNumber(options.orderRateCalculationID, 0);
		this.isLoanHolder =  _.parseBool(options.isLoanHolder);

		this.addtlRemitDate = options.addtlRemitDate || null;
		this.overrideRemitDate = _.parseBool(options.overrideRemitDate);
		this.breakoutOverride =  _.parseNumber(options.breakoutOverride, null);
		this.buyerSellerAmountApplyOverride =  _.parseBool(options.buyerSellerAmountApplyOverride, null);
		this.overrideDate = options.overrideDate || null;
		this.overrideDefaultSplitRulesYN =  _.parseBool(options.overrideDefaultSplitRulesYN, null);
		this.overrideUnderwriter =  _.parseBool(options.overrideUnderwriter, null);
		this.overrideUnderwriterAmount =  _.parseNumber(options.overrideUnderwriterAmount, 0);
		this.ownerOverride =  _.parseNumber(options.ownerOverride, null);
		this.loanOverride =  _.parseNumber(options.loanOverride, null);
		this.splitOverridePCT =  _.parseNumber(options.splitOverridePCT, null);
		this.underwriterSplitPercent = _.parseNumber(options.splitOverridePCT, 0);

		this.accountingCodeID =  _.parseNumber(options.accountingCodeID, null);
		this.alwaysApplyEndorsement =  _.parseBool(options.alwaysApplyEndorsement, null);
		this.basedOnAmount =  _.parseNumber(options.basedOnAmount, null);
		this.basedOnMaximum =  _.parseNumber(options.basedOnMaximum, null);
		this.basedOnMinimum =  _.parseNumber(options.basedOnMinimum, null);
		this.basedOnPer =  _.parseNumber(options.basedOnPer, null);
		this.breakoutEndorsementOption =  _.parseNumber(options.breakoutEndorsementOption, null);
		this.buyerPayLoanFeePCT =  _.parseNumber(options.buyerPayLoanFeePCT, 0);
		this.buyerToPay =  _.parseNumber(options.buyerToPay, 0);
		this.cdfLine =  _.parseNumber(options.cdfLine, 0);
		this.chargePercentageBasisID =  _.parseNumber(options.chargePercentageBasisID, null);
		this.code = options.code || "";
		this.comment = options.comment || "";
		this.countyID =  _.parseNumber(options.countyID, null);
		this.coverageRoundingMethod =  _.parseNumber(options.coverageRoundingMethod, null);
		this.deletedYN =  _.parseBool(options.deletedYN, null);
		this.description = options.description || "";
		this.endorsementBasedOnID =  _.parseNumber(options.endorsementBasedOnID, null);
		this.endorsementChargeTypeID =  _.parseNumber(options.endorsementChargeTypeID, null);
		this.endorsementChargesSettlementFeeRoundingMethod =  _.parseNumber(options.endorsementChargesSettlementFeeRoundingMethod, null);
		this.endorsementHasDocumentTemplatesYN =  _.parseBool(options.endorsementHasDocumentTemplatesYN, null);
		this.endorsementID =  _.parseNumber(options.endorsementID, null);
		this.endorsementPolicyTypeID =  _.parseNumber(options.endorsementPolicyTypeID, null);
		this.hudLineType =  _.parseNumber(options.hudLineType, null);
		this.importDate = options.importDate || null;
		this.isSystemRecordYN =  _.parseBool(options.isSystemRecordYN, null);
		this.lastModified = options.lastModified || null;
		this.lastModifiedBy = options.lastModifiedBy || null;
		this.lastRamQuestUpdate = options.lastRamQuestUpdate || null;
		this.loan =  _.parseNumber(options.loan, null);
		this.loanConcurrentPolicy =  _.parseNumber(options.loanConcurrentPolicy, null);
		this.loanFlatExcessAmount =  _.parseNumber(options.loanFlatExcessAmount, null);
		this.loanFlatExcessCalcMethodID =  _.parseNumber(options.loanFlatExcessCalcMethodID, null);
		this.loanFlatExcessCalcPctDiff =  _.parseNumber(options.loanFlatExcessCalcPctDiff, null);
		this.loanFlatExcessPolicy =  _.parseNumber(options.loanFlatExcessPolicy, null);
		this.loanFlatFeeAmount =  _.parseNumber(options.loanFlatFeeAmount, null);
		this.loanFlatFeeDifferenceAmount =  _.parseNumber(options.loanFlatFeeDifferenceAmount, null);
		this.loanFlatFeeDifferenceOwnersMin =  _.parseNumber(options.loanFlatFeeDifferenceOwnersMin, null);
		this.loanFlatFeePercentageAmount =  _.parseNumber(options.loanFlatFeePercentageAmount, null);
		this.loanFlatFeePercentageExcessAmount =  _.parseNumber(options.loanFlatFeePercentageExcessAmount, null);
		this.loanFlatFeePercentageExcessCalcMethodID =  _.parseNumber(options.loanFlatFeePercentageExcessCalcMethodID, null);
		this.loanFlatFeePercentageExcessCalcPctDiff =  _.parseNumber(options.loanFlatFeePercentageExcessCalcPctDiff, null);
		this.loanFlatFeePercentageExcessPct =  _.parseNumber(options.loanFlatFeePercentageExcessPct, null);
		this.loanFlatFeePercentageExcessPolicy =  _.parseNumber(options.loanFlatFeePercentageExcessPolicy, null);
		this.loanFlatFeePercentagePct =  _.parseNumber(options.loanFlatFeePercentagePct, null);
		this.loanPercentageExcessCalcMethodID =  _.parseNumber(options.loanPercentageExcessCalcMethodID, null);
		this.loanPercentageExcessCalcPctDiff =  _.parseNumber(options.loanPercentageExcessCalcPctDiff, null);
		this.loanPercentageExcessPct =  _.parseNumber(options.loanPercentageExcessPct, null);
		this.loanPercentageExcessPolicy =  _.parseNumber(options.loanPercentageExcessPolicy, null);
		this.loanPercentagePct =  _.parseNumber(options.loanPercentagePct, null);
		this.loanPolicyCode = options.loanPolicyCode || "";
		this.loanPolicyID =  _.parseNumber(options.loanPolicyID, null);
		this.loanTaxAmount =  _.parseNumber(options.loanTaxAmount, null);
		this.loanTotal =  _.parseNumber(options.loanTotal, null);
		this.loanUsePremiumRounding =  _.parseBool(options.loanUsePremiumRounding, null);
		this.owner =  _.parseNumber(options.owner, null);
		this.ownerTaxAmount =  _.parseNumber(options.ownerTaxAmount, null);
		this.ownerTotal =  _.parseNumber(options.ownerTotal, null);
		this.poC_Amount =  _.parseNumber(options.poC_Amount, null);
		this.poC_NF =  _.parseNumber(options.poC_NF, null);
		this.poC_Who =  _.parseNumber(options.poC_Who, null);
		this.payPercent =  _.parseNumber(options.payPercent, null);
		this.payeeCompanyID =  _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyName =  options.payeeCompanyName || "";
		this.percentageMaximum =  _.parseNumber(options.percentageMaximum, null);
		this.percentageMinimum =  _.parseNumber(options.percentageMinimum, null);
		this.percentagePercent =  _.parseNumber(options.percentagePercent, null);
		this.percentageRateID =  _.parseNumber(options.percentageRateID, null);
		this.rateEngineRoundingRuleID =  _.parseNumber(options.rateEngineRoundingRuleID, null);
		this.rateEngineSplitRulesID =  _.parseNumber(options.rateEngineSplitRulesID, null);
		this.rateRuleReference = options.rateRuleReference || null;
		this.regionID =  _.parseNumber(options.regionID, null);
		this.salesConcurrentPolicy =  _.parseNumber(options.salesConcurrentPolicy, null);
		this.salesFlatExcessAmount =  _.parseNumber(options.salesFlatExcessAmount, null);
		this.salesFlatExcessCalcMethodID =  _.parseNumber(options.salesFlatExcessCalcMethodID, null);
		this.salesFlatExcessCalcPctDiff =  _.parseNumber(options.salesFlatExcessCalcPctDiff, null);
		this.salesFlatExcessPolicy =  _.parseNumber(options.salesFlatExcessPolicy, null);
		this.salesFlatFeeAmount =  _.parseNumber(options.salesFlatFeeAmount, null);
		this.salesFlatFeeDifferenceAmount =  _.parseNumber(options.salesFlatFeeDifferenceAmount, null);
		this.salesFlatFeeDifferenceOwnersMin =  _.parseNumber(options.salesFlatFeeDifferenceOwnersMin, null);
		this.salesFlatFeePercentageAmount =  _.parseNumber(options.salesFlatFeePercentageAmount, null);
		this.salesFlatFeePercentageExcessAmount =  _.parseNumber(options.salesFlatFeePercentageExcessAmount, null);
		this.salesFlatFeePercentageExcessCalcMethodID =  _.parseNumber(options.salesFlatFeePercentageExcessCalcMethodID, null);
		this.salesFlatFeePercentageExcessCalcPctDiff =  _.parseNumber(options.salesFlatFeePercentageExcessCalcPctDiff, null);
		this.salesFlatFeePercentageExcessPct =  _.parseNumber(options.salesFlatFeePercentageExcessPct, null);
		this.salesFlatFeePercentageExcessPolicy =  _.parseNumber(options.salesFlatFeePercentageExcessPolicy, null);
		this.salesFlatFeePercentagePct =  _.parseNumber(options.salesFlatFeePercentagePct, null);
		this.salesPercentageExcessCalcMethodID =  _.parseNumber(options.salesPercentageExcessCalcMethodID, null);
		this.salesPercentageExcessCalcPctDiff =  _.parseNumber(options.salesPercentageExcessCalcPctDiff, null);
		this.salesPercentageExcessPct =  _.parseNumber(options.salesPercentageExcessPct, null);
		this.salesPercentageExcessPolicy =  _.parseNumber(options.salesPercentageExcessPolicy, null);
		this.salesPercentagePct =  _.parseNumber(options.salesPercentagePct, null);
		this.salesPolicyCode = options.salesPolicyCode || "";
		this.salesPolicyID =  _.parseNumber(options.salesPolicyID, null);
		this.salesUsePremiumRounding =  _.parseBool(options.salesUsePremiumRounding, null);
		this.sectionID =  _.parseNumber(options.sectionID, null);
		this.sectionNumber =  _.parseNumber(options.sectionNumber, null);
		this.sellerPayOwnerFeePCT =  _.parseNumber(options.sellerPayOwnerFeePCT, 0);
		this.sellerToPay =  _.parseNumber(options.sellerToPay, null);
		this.showOutsideOfPremiums =  _.parseNumber(options.showOutsideOfPremiums, EndorsementYesNo.No);
		this.state = options.state || null;
		this.taxRate =  _.parseNumber(options.taxRate, null);
		this.underwriterID =  _.parseNumber(options.underwriterID, null);
		this.underwriterSplit =  _.parseNumber(options.underwriterSplit, null);
		this.whoPays =  _.parseNumber(options.whoPays, null);
		this.isGfe = _.parseBool(options.isGfe, null); // this defaults to "blank" (null)
	}

	get amount() { return this.isLoanHolder ? _.parseNumber(this.ownerOverride, 0) : _.parseNumber(this.loanOverride, 0); }
	set amount(val) {
		if(this.isLoanHolder)
			this.ownerOverride = _.parseNumber(val, 0);
		else
			this.loanOverride = _.parseNumber(val, 0);
	}

	get remitDate() { return this.overrideRemitDate ? this.overrideDate : this.addtlRemitDate; }
	set remitDate(val) {
		this.overrideDate = val;
		this.overrideRemitDate = _.parseDate(this.overrideDate) !== _.parseDate(this.addtlRemitDate);
	}

	get calculatedAmount() { return this.isLoanHolder ? _.parseNumber(this.owner, 0) : _.parseNumber(this.loan, 0); }

	get taxAmount() { return this.isLoanHolder ? _.parseNumber(this.ownerTaxAmount, 0) : _.parseNumber(this.loanTaxAmount, 0); }
	get totalAmount() { return this.isLoanHolder ? _.parseNumber(this.ownerTotal, 0) : _.parseNumber(this.loanTotal, 0); }

	get amountOverridden() {
		return this.isLoanHolder
			? (_.parseNumber(this.ownerOverride, 0) !== _.parseNumber(this.owner, 0))
			: (_.parseNumber(this.loanOverride, 0) !== _.parseNumber(this.loan, 0));
	}

	get overridden() {
		return  this.overrideUnderwriter || this.buyerSellerAmountApplyOverride;
	}
	get policyTypeName() {
		let typeName = EndorsementPolicy.displayValue(this.endorsementPolicyTypeID);
		return typeName || "Invalid Type";
	}

	toDataObject() { return _.pickBy(this, (v,k) => { return !_.includes(["isLoanHolder"], k); }); }
}