<template>
    <router-view></router-view>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { SETTLEMENT_ACTIONS } from '@/store/actions';
export default {
    computed: {
        ...mapState({
            loans: state => state.orders.loans
        }),
        ...mapGetters([
            "defaultLoanId"
        ]),        
    },
    created(){
        this.fetchData();
    },
    methods: {
        fetchData() {
            const self = this;
            let loanId = self.defaultLoanId;
            let storePromise = self.$store.dispatch(SETTLEMENT_ACTIONS.GET_PAYOFF_DATA, { loanId: loanId })
            return self.$rqBusy.wait(storePromise)
                .catch((err) => {
                    let message = 'Error retrieving payoff information';
                    self.$toast.error({ message: message });
                });
        },
    }
}
</script>