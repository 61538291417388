<template>
    <div :class="{'p-3': isModal, 'content-wrapper': true }">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="isModal && errorMessage.length > 0"
        />
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <b-form-checkbox automation_id="chk_1099_use_forwarding_address" v-model="buyerSeller.useForwardingAddressfor1099">Use Forwarding Address</b-form-checkbox>
                    </div>
                    <div class="form-group">
                        <b-form-checkbox automation_id="chk_1099_do_not_generate_report" v-model="buyerSeller.ten99DoNotGenerateReport">Do NOT generate 1099</b-form-checkbox>
                    </div>
                    <div class="form-group">
                        <b-form-checkbox automation_id="chk_1099_property_or_services_received" v-model="buyerSeller.ten99Box4">Property or Services Received</b-form-checkbox>
                    </div>
                    <div class="form-group">
                        <b-form-checkbox automation_id="chk_1099_is_trasferor_foreign" v-model="buyerSeller.isTransferorForeign">Transferor is a Foreign Person</b-form-checkbox>
                    </div>
                </div>

                <div class="col-3 form-group">
                    <label for="txt_1099_allocation_percent">Allocation %</label>
                    <div class="input-group">
                        <rqInputNumber id="txt_1099_allocation_percent" defaultValue="0" :decimals="percentPrecision" v-model="buyerSeller.ten99AllocationPercent" readonly />
                        <span class="input-group-text">%</span>
                    </div>
                </div>

                <div class="col-3 form-group">
                    <label for="txt_1099_allocation_amount">Allocation Amount</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rqInputNumber id="txt_1099_allocation_amount" defaultValue="0" decimals="2" v-model="buyerSeller.ten99AllocationAmount" readonly />
                    </div>
                </div>

                <div class="col-3 form-group">
                    <label for="txt_1099_tax_name">Override Tax Reporting Name</label>
                    <div class="input-group">
                        <input id="txt_1099_tax_name_text" type="text" class="form-control" maxlength="1000" v-model="buyerSeller.taxNameText" />
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    export default {
        name: "Seller1099Details",
        props: {
            buyerSeller: { type: Object, default: () => ({}) },
            readOnly: { type: Boolean, default: false },
            isModal: { type: Boolean, default: false },
        },
        data () {
            return {
                percentPrecision: 8,
                errorMessage: "",
                originalBuyerSeller: {}
            };
        },
        created (){
            if (this.isModal) this.originalBuyerSeller = _.clone(this.buyerSeller);
        },
        computed: {
            hasChanges() { return _.size(this.getAuditChanges(_.toPlainObject(this.buyerSeller), _.toPlainObject(this.originalBuyerSeller))) > 0; },
        },
        methods: {
            save(){
                const self = this;
                self.errorMessage = "";
                let item = _.toPlainObject(self.buyerSeller);
                let originalBuyerSeller = _.toPlainObject(self.originalBuyerSeller);
                let changes = self.getAuditChanges(originalBuyerSeller, item);

                if (changes.length == 0) {
                    self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve(false);
                }
                let apiPromise = self.$api.OrdersApi.saveSeller(item, changes);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    };
</script>