<template>
    <tr>
        <td class="rq-col-checkbox col-checkbox">
            <b-form-checkbox
                automation_id="chk_item_select"
                v-model="orderEndorsement.isSelected"
                name="isSelected"
            />
        </td>
        <td class="col-description">
            <div class="cell-content">
                <span class="cell-text">{{orderEndorsement.description}}</span>
                <span v-if="isFee" class="badge badge-pill text-bg-secondary">FEE</span>
            </div>
        </td>
        <td class="rq-col-input col-payee">
            <company-picker
                ref="payeeCompanyPicker"
                automation_id="pic_payee_company"
                dialogTitle="Select a Endorsement Payee"
                :disabled="readOnly"
                no-drop-down
                v-model="payeeCompany"
                small
            />
        </td>
        <td v-rq-tooltip.hover.top :title="isFee ? 'Fees are always broken out' : ''" :class="{'rq-col-input': true, 'col-breakout': true }">
            <dx-select-box
                :input-attr="{ automation_id: `drp_breakout_option${orderEndorsement.orderEndorsementID}` }"
                :element-attr="{ class: 'form-control form-control-sm' }"
                value-expr="id"
                display-expr="name"
                :items="breakoutEndorsementOptions"
                :disabled="readOnly || isFee"
                v-model="orderEndorsement.breakoutOverride"
                @value-changed="onBreakoutOverrideChange"
            />
        </td>
        <td :class="getInputCellClassAttr('amount')">
            <rq-input-number :id="`txt_amount${orderEndorsement.orderEndorsementID}`"
                :automation_id="`txt_amount${orderEndorsement.orderEndorsementID}`"
                :disabled="readOnly"
                format-type="money"
                default-value="0"
                decimals="2"
                value-event="input"
                prepend-icon="fas fa-dollar-sign"
                text-align="right"
                size="sm"
                v-model="orderEndorsement.amount"
                @change="onAmountChange"
            />
        </td>
        <template v-if="!noTax">
            <td class="col-percent">
                <span>{{orderEndorsement.taxRate}}%</span>
            </td>
            <td class="col-amount">
                <span>{{formatMoney(calculatedTaxAmount)}}</span>
            </td>
            <td class="col-amount">
                <span>{{formatMoney(calculatedTotalAmount)}}</span>
            </td>
        </template>
        <td :class="getInputCellClassAttr('buyerPercent')">
            <rq-input-number
                :id="`txt_buyer_percent${orderEndorsement.orderEndorsementID}`"
                :disabled="readOnly"
                suffix="%"
                default-value="0"
                min-value="0"
                max-value="100"
                decimals="2"
                value-event="input"
                text-align="right"
                size="sm"
                v-model="buyerPercent"
                @change="onBuyerPercentChange"
            />
        </td>
        <td :class="getInputCellClassAttr('buyerAmount')">
            <rq-input-number
                :id="`txt_buyer_amount${orderEndorsement.orderEndorsementID}`"
                :disabled="readOnly"
                format-type="money"
                default-value="0"
                decimals="2"
                value-event="input"
                prepend-icon="fas fa-dollar-sign"
                text-align="right"
                size="sm"
                v-model="orderEndorsement.buyerToPay"
                @change="onBuyerAmountChange"
            />
        </td>
        <td :class="getInputCellClassAttr('sellerPercent')">
            <rq-input-number
                :id="`txt_seller_percent${orderEndorsement.orderEndorsementID}`"
                :disabled="readOnly"
                suffix="%"
                default-value="0"
                min-value="0"
                max-value="100"
                decimals="2"
                value-event="input"
                text-align="right"
                size="sm"
                v-model="sellerPercent"
                @change="onSellerPercentChange"
            />
        </td>
        <td :class="getInputCellClassAttr('sellerAmount')">
            <rq-input-number
                :id="`txt_seller_amount${orderEndorsement.orderEndorsementID}`"
                :disabled="readOnly"
                format-type="money"
                default-value="0"
                decimals="2"
                value-event="input"
                prepend-icon="fas fa-dollar-sign"
                text-align="right"
                size="sm"
                v-model="orderEndorsement.sellerToPay"
                @change="onSellerAmountChange"
            />
        </td>
        <td v-rq-tooltip.top.hover title="Configured Split %" class="rq-col-input col-percent">
            <input
                class="form-control form-control-sm"
                :value="`${orderEndorsement.underwriterSplitPercent}%`"
                readonly :disabled="readOnly"
            />
        </td>
        <td :class="getInputCellClassAttr('underwriterAmount')">
            <rq-input-number
                :id="`txt_underwriter_amount${orderEndorsement.orderEndorsementID}`"
                disabled
                format-type="money"
                default-value="0"
                decimals="2"
                :min-value="0"
                :max-value="calculatedTotalAmount"
                value-event="input"
                prepend-icon="fas fa-dollar-sign"
                text-align="right"
                size="sm"
                v-model="orderEndorsement.overrideUnderwriterAmount"
                @change="onUnderwriterAmountChange"
            />
        </td>
        <td v-if="isCdf" class="rq-col-input col-cdf-section">
            <dx-select-box
                :input-attr="{ automation_id: `drp_cdf_section${orderEndorsement.orderEndorsementID}` }"
                :element-attr="{ class: 'form-control form-control-sm' }"
                value-expr="id"
                display-expr="name"
                :items="cdfSections"
                :disabled="cdfSectionsEmpty || readOnly"
                v-model="orderEndorsement.sectionID"
            />
        </td>
        <td v-if="is3PageHud" class="rq-col-input col-cdf-section">
            <dx-select-box v-if="is3PageHud"
                :input-attr="{ automation_id: `drp_gfe${orderEndorsement.orderEndorsementID}` }"
                :element-attr="{ class: 'form-control form-control-sm' }"
                value-expr="value"
                display-expr="text"
                :items="[
                    { text: '', value: null},
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                ]"
                :disabled="readOnly"
                v-model="orderEndorsement.isGfe"
            />
        </td>
        <td :class="{'rq-col-input': true, 'col-amount': true }">
            <rq-input-number
                :id="`txt_poc_amount${orderEndorsement.orderEndorsementID}`"
                :disabled="readOnly"
                format-type="money"
                default-value="0"
                decimals="2"
                value-event="input"
                text-align="right"
                prepend-icon="fas fa-dollar-sign"
                size="sm"
                v-model="orderEndorsement.poC_Amount"
            />
        </td>
        <td class="rq-col-input col-poc-who">
            <dx-select-box
                :input-attr="{ automation_id: `drp_poc_who${orderEndorsement.orderEndorsementID}` }"
                :element-attr="{ class: 'form-control form-control-sm' }"
                value-expr="id"
                display-expr="name"
                :items="pocWhoOptions"
                :disabled="readOnly"
                v-model="orderEndorsement.poC_Who"
            />
        </td>
        <td class="rq-col-input col-net-fund">
            <dx-select-box
                :input-attr="{ automation_id: `drp_net_fund${orderEndorsement.orderEndorsementID}` }"
                :element-attr="{ class: 'form-control form-control-sm' }"
                value-expr="id"
                display-expr="name"
                :items="netFundOptions"
                :disabled="readOnly"
                v-model="orderEndorsement.poC_NF"
            />
        </td>
        <td :class="getInputCellClassAttr('remitDate')">
            <rqdx-date-box
                automation_id="dtp_addtl_remit_date"
                class="form-control-sm"
                :disabled="readOnly"
                v-model="orderEndorsement.remitDate"
                default-button-display
            />
        </td>
    </tr>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import RateBalancer from "@settlement/util/RateBalancer";
    import OrderEndorsement from "@settlement/models/OrderEndorsement";
    import { BreakoutEndorsementOption, EndorsementPolicy, POCWhoIntOptions, POCNetFundOptions, SettlementTypeOption } from '@settlement/models';
    import { RATE_MUTATIONS } from '@/store/mutations';
    import { RATE_ACTIONS } from '@/store/actions';
    import { ClosingParties } from "@/modules/configuration/enums";
    import { CompanyPicker } from '@/shared/components/rq';

    export default {
        name: "EndorsementRow",
        components: { CompanyPicker },
        props: {
            orderEndorsement: { default: {} },
            premium: { default: {} },
            noTax: { default:false },
            noType: { default:false },
            readOnly: { default:false }
        },

        data() {
            return {
                buyerFocused: false,
                sellerFocused: false,
                originalOrderEndorsement: _.clone(this.orderEndorsement),
                calculatedTaxAmount: this.orderEndorsement.taxAmount,
                calculatedTotalAmount: this.orderEndorsement.totalAmount
            };
        },

        computed: {
            ...mapState({
                cdfSections: state => _.mapLookup(state.orders.orderLookups.cdfSections),
                rateOrderInfo: state => state.rateEngine.rateOrderInfo,
                settlementStatementType: state => state.orders.orderSummary.settlementStatementType
            }),
            cdfSectionsEmpty() { return _.isEmpty(this.cdfSections); },
            is3PageHud() { return this.settlementStatementType === SettlementTypeOption.HUD_2010; },
            isCdf() { return this.settlementStatementType === SettlementTypeOption.CDF; },
            isFee() { return this.orderEndorsement.endorsementPolicyTypeID === EndorsementPolicy.Fee; },
            buyerPercent: {
                get() {
                    return this.premium.isLoanHolder
                        ? 100 - this.orderEndorsement.sellerPayOwnerFeePCT
                        : this.orderEndorsement.buyerPayLoanFeePCT;
                },
                set(val) {
                    if(this.premium.isLoanHolder)
                        this.orderEndorsement.sellerPayOwnerFeePCT = 100 - val;
                    else
                        this.orderEndorsement.buyerPayLoanFeePCT = val;
                }
            },
            sellerPercent: {
                get() {
                    return this.premium.isLoanHolder
                        ? this.orderEndorsement.sellerPayOwnerFeePCT
                        : 100 - this.orderEndorsement.buyerPayLoanFeePCT;
                },
                set(val) {
                    if(this.premium.isLoanHolder)
                        this.orderEndorsement.sellerPayOwnerFeePCT = val;
                    else
                        this.orderEndorsement.buyerPayLoanFeePCT = 100 - val;
                }
            },
            payeeCompany: {
                get() {
                    return {
                        companyID: this.orderEndorsement.payeeCompanyID,
                        companyName: this.orderEndorsement.payeeCompanyName,
                        contactID: this.orderEndorsement.payeeContactID
                    };
                },
                set(val) {
                    this.orderEndorsement.payeeCompanyID = _.get(val, "companyID", null);
                    this.orderEndorsement.payeeCompanyName = _.get(val, "companyName", null);
                    this.orderEndorsement.payeeContactID = _.get(val, "contactID", null);
                }
            },
        },

        watch: {
            "orderEndorsement.isSelected": function(newValue, oldValue) {
                this.$emit("item-selected", {
                    isSelected: newValue,
                    data: this.orderEndorsement
                });
            }
        },

        created() {
            this.balancer = new RateBalancer({
                amount: this.orderEndorsement.amount,
                taxRate: this.orderEndorsement.taxRate,
                buyerPercent: this.buyerPercent,
                buyerAmount: this.orderEndorsement.buyerToPay,
                sellerPercent: this.sellerPercent,
                sellerAmount: this.orderEndorsement.sellerToPay,
                underwriterSplit: this.orderEndorsement.underwriterSplit,
		        underwriterSplitPercent: this.orderEndorsement.underwriterSplitPercent,
		        overrideUnderwriter: this.overrideUnderwriter,
            });
            this.breakoutEndorsementOptions = BreakoutEndorsementOption.lookupItems;
            this.endorsementTypes = EndorsementPolicy.lookupItems;
            this.pocWhoOptions = POCWhoIntOptions.lookupItems;
            this.netFundOptions = POCNetFundOptions.lookupItems;
        },

        methods:{

            onBreakoutOverrideChange() {
                this.$emit("change", this.orderEndorsement);
            },

            onUnderwriterAmountChange(item) {
                this.orderEndorsement.overrideUnderwriter = this.orderEndorsement.underwriterSplit !== item.value;
                this.$emit("change");
            },

            onAmountChange(e) {
                this.balancer.amount = this.orderEndorsement.amount;
                this.balancer.balanceByAmount();
                this.mergeBalancerValues();
            },

            onBuyerPercentChange(e) {
                this.orderEndorsement.buyerSellerAmountApplyOverride = true;
                this.balancer.buyerPercent = this.buyerPercent;
                this.balancer.balanceByBuyerPercent();
                this.mergeBalancerValues();
            },

            onSellerPercentChange(e) {
                this.orderEndorsement.buyerSellerAmountApplyOverride = true;
                this.balancer.sellerPercent = this.sellerPercent;
                this.balancer.balanceBySellerPercent();
                this.mergeBalancerValues();
            },

            onBuyerAmountChange(e) {
                this.orderEndorsement.buyerSellerAmountApplyOverride = true;
                this.balancer.buyerAmount = this.orderEndorsement.buyerToPay;
                this.balancer.balanceByBuyerAmount();
                this.mergeBalancerValues();
            },

            onSellerAmountChange(e) {
                this.orderEndorsement.buyerSellerAmountApplyOverride = true;
                this.balancer.sellerAmount = this.orderEndorsement.sellerToPay;
                this.balancer.balanceBySellerAmount();
                this.mergeBalancerValues();
            },

            getInputCellClassAttr(field) {
                let lowField = _.toLower(field);
                let overridden = (lowField === "amount"
                        && this.orderEndorsement.amountOverridden) ||
                    ((_.includes(lowField, "buyer") || _.includes(lowField, "seller"))
                        && (this.orderEndorsement.amountOverridden || this.orderEndorsement.buyerSellerAmountApplyOverride)) ||
                    (field === "underwriteramount"
                        && (this.orderEndorsement.amountOverridden || this.orderEndorsement.overrideUnderwriter)) ||
                    (field === "remitdate"
                        && this.orderEndorsement.overrideRemitDate);

                return {
                    "rq-col-input":true,
                    "col-amount": _.includes(lowField, "amount"),
                    "col-percent": _.includes(lowField, "percent"),
                    overridden
                };
            },

            mergeBalancerValues(setOverride=true) {
                this.orderEndorsement.amount = this.balancer.amount;
                this.orderEndorsement.buyerPayLoanFeePCT = this.balancer.buyerPercent;
                this.orderEndorsement.buyerToPay = this.balancer.buyerAmount;
                this.orderEndorsement.sellerPayOwnerFeePCT = this.balancer.sellerPercent;
                this.orderEndorsement.sellerToPay = this.balancer.sellerAmount;
                this.orderEndorsement.overrideUnderwriterAmount = this.balancer.uwSplitAmount;
                this.calculatedTaxAmount = this.balancer.taxAmount;
                this.calculatedTotalAmount = this.balancer.taxTotal;

                if(this.orderEndorsement.amountOverridden === true) {
                    this.orderEndorsement.overrideUnderwriter = true;
                    this.orderEndorsement.buyerSellerAmountApplyOverride = true;
                }

                this.$emit("change");
            },

            revertOverrides() {
                this.orderEndorsement.overrideUnderwriter = false;
                this.orderEndorsement.buyerSellerAmountApplyOverride = false;
                this.orderEndorsement.overrideUnderwriterAmount = this.orderEndorsement.underwriterSplit;
                this.orderEndorsement.overrideDate = this.orderEndorsement.addtlRemitDate;

                this.orderEndorsement.breakoutOverride = this.orderEndorsement.breakoutEndorsementOption;
                this.balancer.amount = this.orderEndorsement.calculatedAmount;
                if(this.orderEndorsement.whoPays === ClosingParties.Buyer){
                    this.balancer.buyerPercent = this.orderEndorsement.payPercent;
                    this.balancer.balanceByBuyerPercent();
                }
                else if(this.orderEndorsement.whoPays === ClosingParties.Seller){
                    this.balancer.sellerPercent = this.orderEndorsement.payPercent;
                    this.balancer.balanceBySellerPercent();
                }
                else{
                    if(this.premium.isLoanHolder){
                        this.balancer.sellerPercent = this.rateOrderInfo.region.sellerPaysPercentageOwnersPremium;
                        this.balancer.balanceBySellerPercent();
                    }
                    else{
                        this.balancer.buyerPercent = this.rateOrderInfo.region.buyerPaysPercentageMortgagePremium;
                        this.balancer.balanceByBuyerPercent();
                    }
                }
                this.mergeBalancerValues(false);

                this.$emit("reverted");
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"--" } }); },
        }
    };
</script>
