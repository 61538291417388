<template>
    <router-view
        @updateHiddenActions="$emit('updateHiddenActions', $event)"
        @company-update-success="goToContactCards"
        @company-cancel-add="goToContactCards"
        @view-order-contacts="goToContactCards"
        @view-company-detail="goToCompanyDetail"
        @view-company-contacts="goToCompanyContacts"
        @contact-update-success="goBack"
        @view-contact-detail="goToContactDetail"
        @add-contact="goToContactDetail"
        @contact-cancel-add="goToCompanyContacts">
    </router-view>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        name: "OrderContactsMain",
        data() {
            return {
                lastRouteName: null
            };
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId
            })
        },
        beforeRouteUpdate(to, from, next) {
            this.lastRouteName = from.name;
            _.invoke(this, "$parent.scrollToTop");
            next();
        },
        methods: {
            goBack(args) {
                if(_.endsWith(this.lastRouteName, "company-contacts"))
                    this.goToCompanyContacts(args);
                else
                    this.goToContactCards();
            },
            goToContactCards() { this.goToRoute("oe:oc:contacts"); },
            goToCompanyContacts(args) { this.goToRoute("oe:oc:company-contacts", args); },
            goToCompanyDetail(args) { this.goToRoute("oe:oc:company", args); },
            goToContactDetail(args) { this.goToRoute("oe:oc:contact", args); },
            goToRoute(name, args) { this.$router.push({ name, params: { orderId: this.orderId, ...args } }); }
        }
    };
</script>