<template>
    <rq-page-section title="Schedule D" class="sched-d" flex-full flex-body borderless>
        <div class="form-group">
            <label for="txt_underwriter">Underwriter <span class="read-only-msg">(read-only)</span></label>
            <rq-rtf-display
                id="txt_underwriter"
                height="calc(100% - 25px)"
                :min-height="165"
                v-model="scheduleD.underWriter"
                no-hover
            />
        </div>
        <div class="form-group">
            <label for="txt_titleCompany">Title Company <span class="read-only-msg">(read-only)</span></label>
            <rq-rtf-display
                id="txt_titleCompany"
                height="calc(100% - 25px)"
                :min-height="165"
                v-model="scheduleD.titleCompany"
                no-hover
            />
        </div>
    </rq-page-section>
</template>

<script>
    import { mapState } from "vuex";
    import { COMMITMENT_ACTIONS } from "@/store/actions";
    export default {
        name: "ScheduleD",
        computed: {
            ...mapState({
                scheduleD: state => _.get(state, "orders.scheduleD", null) || {}
            }),
            hasUnderwriterContent() { return !_.isEmpty(this.scheduleD) && !_.isEmpty(this.scheduleD.underWriter); },
            hasTitleCompanyContent() { return !_.isEmpty(this.scheduleD) && !_.isEmpty(this.scheduleD.titleCompany); }
        },
        created() {
            this.$store.dispatch(COMMITMENT_ACTIONS.GET_SCHEDULE_D)
        }
    };
</script>