<template>
  <div class="content-wrapper">
    <rq-banner
      ref="errorBanner"
      :message="errorMessage"
      variant="error"
      icon="fas fa-exclamation-triangle"
      :visible="errorMessage.length > 0"
    />
    <rq-page-section borderless headerSize="md">
      <div class="row">
        <div class="col col-6 form-group">
          <label for="pcl_RolesID">Role</label>
          <dx-select-box
            :input-attr="{ automation_id: 'pcl_RolesID' }"
            :items="roleList"
            display-expr="name"
            value-expr="id"
            :search-enabled="false"
            :show-clear-button="true"
            :disabled="false"
            @value-changed="onRoleSelectionChange"
            v-model="selectedRoleTypeID"
          >
          </dx-select-box>
        </div>
        <div class="col col-6 form-group">
          <label for="pcl_payoffID">Payoff Description</label>
          <dx-select-box
            :input-attr="{ automation_id: 'pcl_payoffID' }"
            :items="payoffDescriptionList"
            display-expr="name"
            value-expr="id"
            :search-enabled="false"
            :show-clear-button="true"
            :disabled="false"
            v-model="selectedPayoffDescriptionID"
            @value-changed="onPayoffDescriptionSelectionChange"
          >
          </dx-select-box>
        </div>
      </div>
      <div class="row">
        <div
          :class="{
            'col col-6 form-required form-group': true,
            'has-error': v$.selectedCoverLetterID.$error,
          }"
        >
          <label for="pcl_coverLetterID">Cover Letters</label>
          <dx-select-box
            :input-attr="{
              automation_id: 'pcl_coverLetterID',
              id: 'pcl_coverLetterID',
            }"
            :items="filteredCoverLetters"
            value-expr="checkCoverLetterID"
            display-expr="coverLetterName"
            :search-enabled="false"
            :show-clear-button="true"
            v-model="v$.selectedCoverLetterID.$model"
            @value-changed="onCoverLetterChange"
          />
        </div>
      </div>
    </rq-page-section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { DOCUMENT_ACTIONS } from "@/store/actions";
import DocumentViewer from "@documents/views/DocumentViewer";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import useDocumentGeneration from "@/shared/composables/useDocumentGeneration";

export default {
  name: "CheckWritingPrintCoverLetterForm",
  props: {
    check: { type: Object, required: true },
    coverLetters: { type: [], required: true },
    ordersID: { type: Number, required: true },
  },

  setup() {
    const v$ = useVuelidate();
    const { generateDocuments } = useDocumentGeneration();
     return {
        v$,
        generateDocuments
      };
  },
  data() {
    return {
      errorMessage: "",
      selectedRoleTypeID: null,
      selectedPayoffDescriptionID: null,
      selectedCoverLetterID: null,
      filteredCoverLetters: [],
    };
  },
  watch: {
    "v$.$error": {
      handler: function (newValue, oldValue) {
        this.$emit(`${newValue ? "disable" : "enable"}-ok`);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["lookupHelpers", "lookupItems"]),
    ...mapState({
      isReadOnly: (state) => _.parseBool(state.isPageReadOnly),
      user: (state) => state.authentication.session.user,
    }),
    readOnly() {
      return false;
    },
    roleList() {
      let self = this;
      return _.filter(
        self.lookupHelpers.getLookupItems(self.lookupItems.ROLES),
        (roleItem) =>
          _.some(self.coverLetters, (item) => item.roleTypeID === roleItem.id)
      );
    },
    payoffDescriptionList() {
      let self = this;
      return _.filter(
        self.lookupHelpers.getLookupItems(self.lookupItems.PAYOFF_DESCRIPTIONS),
        (payoffItem) =>
          _.some(
            self.coverLetters,
            (item) => item.payoffDescriptionID === payoffItem.id
          )
      );
    },
  },
  created() {
    this.init();
    this.fetchData();
  },
  validations() {
    return {
      selectedCoverLetterID: {
        required,
      },
    };
  },
  methods: {
    fetchData() {
      var self = this;
      let roleApiPromise = self.$api.CheckWritingApi.getRoleData(
        self.check.checksID
      );

      this.resetModels();

      self.$rqBusy
        .wait(roleApiPromise)
        .then((data) => {
          if (data && data > 0) {
            let coverLetter = _.find(
              this.coverLetters,
              (item) => item.roleTypeID === data
            );
            self.selectedRoleTypeID = _.isNil(coverLetter)
              ? null
              : coverLetter.roleTypeID;
            this.updateFilteredCoverLetters();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.$emit("disable-ok");
        });
    },

    init() {
      this.$emit("disable-ok");
    },

    launchDocumentViewer(title) {
      const self = this;
      self.$dialog.open({
        title: title,
        height: "95%",
        width: "95%",
        resizable: false,
        component: DocumentViewer,
        closeOnly: true,
        closeTitle: "Close",
      });
    },

    async printCoverLetter() {
      const self = this;

      if (_.isNil(self.selectedCoverLetterID)) {
        self.errorMessage = "Please provide cover letter.";
        return;
      }

      var selectedCoverLetter = _.find(
        self.coverLetters,
        (i) => i.checkCoverLetterID === self.selectedCoverLetterID
      );
      var documentID = selectedCoverLetter.documentTemplateID;

      let storePromise = self.$store.dispatch(
        DOCUMENT_ACTIONS.ADD_CHECK_COVER_LETTER_TO_QUEUE,
        {
          ordersID: self.ordersID,
          documentTemplateID: documentID,
          checkID: this.check?.checksID
        }
      );

      await self.$rqBusy.wait(storePromise, { topLevel: true });
      await self.generateDocuments({skipQueueUpdate: true});
    },

    onCoverLetterChange(e) {
      if (e.value == null) {
        this.resetModels();
      } else {
        this.$emit("enable-ok");
      }
    },

    onRoleSelectionChange(e) {
      this.updateFilteredCoverLetters();
    },

    onPayoffDescriptionSelectionChange(e) {
      this.updateFilteredCoverLetters();
    },

    resetModels() {
      this.filteredCoverLetters = this.coverLetters;
      this.selectedCoverLetterID = null;
      this.selectedRoleTypeID = null;
      this.selectedPayoffDescriptionID = null;
      this.$emit("disable-ok");
    },

    updateFilteredCoverLetters() {
      this.filteredCoverLetters = _.filter(this.coverLetters, (item) => {
        return (
          (this.selectedRoleTypeID === null ||
            item.roleTypeID === this.selectedRoleTypeID) &&
          (this.selectedPayoffDescriptionID === null ||
            item.payoffDescriptionID === this.selectedPayoffDescriptionID)
        );
      });
    },
  },
};
</script>
