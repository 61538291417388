import SettlementDashboard from "./components/dashboard/SettlementDashboard";
import ProrationsCalculator from "./components/prorations/ProrationCalculator";
import PremiumsPage from "./components/premiums/PremiumsMain.vue";
import SettlementFeesPage from "./components/fees/SettlementFeeList";
import RecordingFeesAndTaxesPage from "./components/recording-fees-and-taxes/RecordingFeesAndTaxes";
import RealtorCommissionPage from "./components/realtor-commission/RealtorCommission";
import Addendums from "./components/addendums/Addendums";
import HudAddendums from "./components/addendums/HudAddendums";
import AggregateAdjustment from "./components/aggregate-adjustment/AggregateAdjustment";
import GoodFaithEstimate from "./components/gfe-comparison/GoodFaithEstimate";
import Escrows from "./components/escrow-section/Escrows";
import OriginationCharges from "./components/loancost-section/OriginationCharges";
import LoanTermsMain from "./components/loanterm-section/LoanTermsMain";
import LoanCalculation from "./components/loan-calculation/LoanCalculation";
import DebitCredits from "./components/debitcredits-section/DebitCredits";
import ProjectedPayment from "./components/projectedpayment-section/ProjectedPayment";
import Prepaids from "./components/prepaid-section/Prepaids";
import NetDisbursement from "./components/loancost-section/NetDisbursement";
import TitleCharges from "./components/loancost-section/TitleCharges";
import OtherCharges from "./components/othercost-section/OtherCharges";
import Worksheet801 from "./components/worksheet801/Worksheet801";
import AdditionalTitleServices from "./components/additional-title-services/AdditionalTitleServices";
import PaidByOthers from "./components/paid-by-others/PaidByOthers";
import SettlementOrderSummary from "./components/order-summary/SettlementOrderSummary";
import LoanDisclosure from "./components/loan-disclosure-section/LoanDisclosure";
import ViewActions from "@/router/actions/settlement";
import PayoffsModule from "./components/payoffs";
import CdfPayoffPayments from "./components/payoffs/CdfPayoffPayments";
import SettlementContactModule from "./components/contacts";
import CashToClose from "./components/cashtoclose-section/CashToClose";

import { OrderNoteCategory } from "@/shared/models/enums";

const noPremiumAccessMsg = "Access to settlement premiums is not authorized.  Please contact your administrator.";
const noSettlementStatementAccessMsg = "Access to the settlement statement and its related sections is not authorized.  Please contact your administrator.";

let settlementRoutes = [
    {
        name: "settlement:settlement-dashboard",
        path: "settlement-dashboard",
        component: SettlementDashboard,
        meta: {
            label: "Settlement Statement Dashboard",
            actions: ViewActions.SETTLEMENT_DASHBOARD,
            accessKey: "GenericSettlementStatement",
            logRouteAccess: true,
            skipSaveOnLeave: true,
            templateCategory: "os",
        }
    },
    PayoffsModule,
    {
        name: "settlement:prorations",
        path: "prorations",
        component: ProrationsCalculator,
        meta: {
            entity: "Proration",
            extension: false,
            label: "Prorations",
            actions: ViewActions.PRORATIONS,
            accessKey: "GenericSettlementStatement",
            skipSaveOnLeave: true,
        }
    },
    {
        name: "premiums",
        path: "premiums",
        component: PremiumsPage,
        meta: {
            entity: "Premium",
            extension: false,
            label: "Premium Rates",
            actions: ViewActions.PREMIUMS,
            accessKey: "Premiums",
            unauthorizedMessage: noPremiumAccessMsg
        }
    },
    {
        name: "settlement:recording-fees-and-taxes",
        path: "recording-fees-and-taxes",
        component: RecordingFeesAndTaxesPage,
        meta: {
            entity: "Current",
            extension: false,
            label: "Recording Fees & Taxes",
            actions: ViewActions.RECORDING_FEES_TAXES,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:fees",
        path: "fees",
        component: SettlementFeesPage,
        meta: {
            entity: "SettlementFee",
            extension: false,
            label: "Settlement Fees",
            actions: ViewActions.SETTLEMENT_FEES,
            accessKey: "RateRules",
            unauthorizedMessage: noSettlementStatementAccessMsg
        }
    },
    {
        name: "settlement:realtor-commission",
        path: "realtor-commission",
        component: RealtorCommissionPage,
        meta: {
            entity: "Real Estate Commissions",
            extension: false,
            label: "Commissions",
            actions: ViewActions.REALTOR_COMMISSION,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:addendums",
        path: "addendums",
        component: Addendums,
        meta: {
            entity: "Addendums",
            extension: false,
            label: "Addendums",
            actions: ViewActions.ADDENDUMS,
            accessKey: "GenericSettlementStatement",
            skipSaveOnLeave: true,
            requireSSMapping: true
        }
    },
    {
        name: "settlement:hudAddendums",
        path: "hudAddendums",
        component: HudAddendums,
        meta: {
            entity: "HudAddendums",
            extension: false,
            label: "Addendums",
            actions: ViewActions.HUD_ADDENDUMS,
            accessKey: "GenericSettlementStatement",
            skipSaveOnLeave: true,
            requireSSMapping: true
        }
    },
    {
        name: "settlement:cdfPayoffPayments",
        path: "cdfPayoffPayments",
        component: CdfPayoffPayments,
        meta: {
            entity: "CdfPayoffPayments",
            extension: false,
            label: "Payoffs & Payments",
            actions: ViewActions.CDF_PAYOFF_PAYMENTS,
            accessKey: "Payoff"
        }
    },
    {
        name: "settlement:aggregate-adjustment",
        path: "aggregate-adjustment",
        component: AggregateAdjustment,
        meta: {
            entity: "Aggregate Adjustment",
            extension: false,
            label: "Aggregate Adjustment",
            actions: ViewActions.AGGREGATE_ADJUSTMENT,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:worksheet801",
        path: "worksheet801",
        component: Worksheet801,
        meta: {
            entity: "801 Breakout Charges",
            extension: false,
            label: "801 Breakout Charges",
            actions: ViewActions.WORKSHEET_801,
            accessKey: "GenericSettlementStatement",
            requireSSMapping: true
        }
    },
    {
        name: "settlement:additional-title-services",
        path: "additional-title-services",
        component: AdditionalTitleServices,
        meta: {
            entity: "Additional Title Services",
            extension: false,
            label: "Additional Title Services",
            actions: ViewActions.ADDITIONAL_TITLE_SERVICES,
            accessKey: "GenericSettlementStatement",
            requireSSMapping: true
        }
    },
    {
        name: "settlement:loanterm-section",
        path: "loanterm-section",
        component: LoanTermsMain,
        meta: {
            entity: "Loan Terms",
            extension: false,
            label: "Loan Terms",
            actions: ViewActions.LOANTERMS,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:cashtoclose-section",
        path: "cashtoclose-section",
        component: CashToClose,
        meta: {
            entity: "Cash To Close",
            extension: false,
            label: "Cash To Close",
            actions: ViewActions.CASH_TO_CLOSE,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:debitcredits-section",
        path: "debitcredits-section",
        component: DebitCredits,
        meta: {
            entity: "Debits/Credits",
            extension: false,
            label: "Debits/Credits",
            actions: ViewActions.DEBIT_CREDITS,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:projectedpayment-section",
        path: "projectedpayment-section",
        component: ProjectedPayment,
        meta: {
            entity: "Projected Payment",
            extension: false,
            label: "Projected Payments",
            actions: ViewActions.PROJECTED_PAYMENT,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:origination-section",
        path: "origination-section",
        component: OriginationCharges,
        meta: {
            entity: "Loan Charges",
            extension: false,
            label: "Loan Charges",
            actions: ViewActions.LOANCOST_CHARGES,
            accessKey: "GenericSettlementStatement",
            useScrollLayout: false
        }
    },
    {
        name: "settlement:escrow-section",
        path: "escrow-section",
        component: Escrows,
        meta: {
            entity: "Escrow Charges",
            extension: false,
            label: "Escrows/Impounds",
            actions: ViewActions.ESCROWS,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:gfe-comparison",
        path: "gfe-comparison",
        component: GoodFaithEstimate,
        meta: {
            entity: "GFE Comparison",
            extension: false,
            label: "GFE Comparison",
            actions: ViewActions.GFE_COMPARISON,
            accessKey: "GenericSettlementStatement",
            requireSSMapping: true
        }
    },
    {
        name: "settlement:net-disbursement-disb",
        path: "net-disbursement-disb",
        component: NetDisbursement,
        props: {
            activeTabIndex: 0
        },
        meta: {
            entity: "NetDisbursement",
            extension: false,
            label: "Disbursements / Net Settlement",
            actions: ViewActions.LOANCOST_CHARGES,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:net-disbursement-net",
        path: "net-disbursement-net",
        component: NetDisbursement,
        props: {
            activeTabIndex: 1
        },
        meta: {
            entity: "NetDisbursement",
            extension: false,
            label: "Disbursements / Net Settlement",
            actions: ViewActions.LOANCOST_CHARGES,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:title-section",
        path: "title-section",
        component: TitleCharges,
        meta: {
            entity: "Title Charges",
            extension: false,
            label: "Title Charges",
            actions: ViewActions.LOANCOST_CHARGES,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:othercost-section",
        path: "othercost-section",
        component: OtherCharges,
        meta: {
            entity: "Other Charges",
            extension: false,
            label: "Other Charges",
            actions: ViewActions.LOANCOST_CHARGES,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:prepaid-section",
        path: "prepaid-section",
        component: Prepaids,
        meta: {
            entity: "Prepaids",
            extension: false,
            label: "Prepaids",
            actions: ViewActions.PREPAIDS,
            accessKey: "GenericSettlementStatement",
            useScrollLayout: false
        }
    },
    {
        name: "settlement:paid-by-others",
        path: "paid-by-others",
        component: PaidByOthers,
        meta: {
            entity: "PaidByOthers",
            extension: false,
            label: "Paid By Others",
            actions: ViewActions.PAID_BY_OTHERS,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:loan-disclosure-section",
        path: "loan-disclosure-section",
        component: LoanDisclosure,
        meta: {
            entity: "Loan Disclosures",
            extension: false,
            label: "Loan Disclosures",
            actions: ViewActions.LOAN_DISCLOSURES,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:settlement-order-summary",
        path: "settlement-order-summary",
        component: SettlementOrderSummary,
        meta: {
            entity: "SettlementOrderSummary",
            extension: false,
            label: "File Summary",
            actions: ViewActions.SETTLEMENT_ORDER_SUMMARY,
            accessKey: "GenericSettlementStatement"
        }
    },
    {
        name: "settlement:loan-calculation",
        path: "loan-calculation",
        component: LoanCalculation,
        meta: {
            entity: "Loan Calculation",
            extension: false,
            label: "Loan Calculations",
            actions: ViewActions.LOAN_CALCULATION,
            accessKey: "GenericSettlementStatement"
        }
    },
    SettlementContactModule
];

let category = OrderNoteCategory.SettlementStatementHUD;
let setNoteCategory = routes => {
    _.forEach(routes, r => {
        r.meta.noteCategory = category;
        if(_.isEmpty(r.children)) return;
        setNoteCategory(r.children);
    });
};
setNoteCategory(settlementRoutes);

export default settlementRoutes;
