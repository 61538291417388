<template>
    <rq-page-section title="Balloon Payment" collapsible>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <label for="rb_loan_has_balloon_payment">Does the loan have a balloon payment?</label>
                    <rq-radio-group 
                        id="rb_loan_has_balloon_payment"
                        v-model="loanTerm.isBalloonPaymentIncrease"
                        inline
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_balloon_payment">Balloon Payment</label>
                    <rqInputNumber
                        id="txt_balloon_payment"
                        automation_id="txt_balloon_payment"
                        defaultValue="0"
                        decimals="2"
                        input-group
                        :disabled="!loanTerm.isBalloonPaymentIncrease"
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="loanTerm.balloonPaymentAmount"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="txt_loan_balloon_payment_due_in_years">Number of years when due</label>
                    <div class="input-group">
                        <rq-input-number
                            id="txt_loan_balloon_payment_due_in_years"
                            automation_id="txt_loan_balloon_payment_due_in_years"
                            defaultValue="0"
                            minValue="0"
                            value-event="input"
                            v-focus-select-all
                            :disabled="!loanTerm.isBalloonPaymentIncrease"
                            v-model="loanTerm.balloonPaymentYears"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="dtp_first_change_date">Due Date</label>
                    <div class="input-group">
                        <rqdx-date-box
                            id="dtp_first_change_date"
                            :config="{
                                showClearButton: true,
                                displayFormat: 'MM/dd/yyyy',
                                useMaskBehavior: true,
                            }"
                            :disabled="!loanTerm.isBalloonPaymentIncrease"
                            v-model="loanTerm.hudLoanBalloonPaymentDueDate"
                        />
                    </div>
                </div>
            </div>
        </fieldset>
    </rq-page-section>
</template>
<script>
import { LoanTermSectionHud2010DataModel } from '@settlement/models';
export default {
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        loanTerm: {
            type: Object,
            default: () => {
                return {}
            }
        },
        originalLoanTerm: {
            type: Object,
            default: () => {
                return new LoanTermSectionHud2010DataModel()
            }
        }
    },
}
</script>