
<template>
    <div class="content-wrapper addendums">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="hasErrors"
            dismissable
        />
        <rq-page-section title="Addendums" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_addendum"
                            variant="theme"
                            @click="onAddAction"
                            :disabled="readOnly"
                            v-focus>Add</b-btn>
                    </li>
                    <li class="nav-item">
                        <rq-report-button
                            text="Print Report"
                            :tooltip="printReportTooltip"
                            :disabled="readOnly || !canPrintReport"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                    <li class="nav-item ms-2">
                        <div class="section-code-select">
                            <dx-select-box
                                :input-attr="$utils.idAttrs('drp_filters')"
                                :element-attr="{ class: 'form-control form-control-sm' }"
                                :items="cdfSectionOptions"
                                value-expr="id"
                                display-expr="name"
                                :show-clear-button="true"
                                v-model="selectedSectionCode"
                            />
                        </div>
                    </li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                        />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dg_available"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="addendum-data"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            focus-after-insert="new-row"
            :rq-editable="!readOnly"
            @delete="onDeleteItem"
            hide-search
        />
    </div>
</template>

<script>
    import { GlobalEventManager } from "@/app.events";
    import { AddendumDto } from "./models";
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { SettlementReport } from '@settlement/components/dashboard/models';
    import { useGridInvokerMethods } from "@/shared/composables/";

    export default {
        name: "Addendums",
        mixins: [BaseSettlementMixin],
        setup() {
            const {
                dataGrid,
                invokeGridMethod
            } = useGridInvokerMethods();

            return {
                dataGrid,
                invokeGridMethod
            };
        },
        data(){
            return {
                cdfLines: [],
                mainID: 0,
                items: [],
                selectedSectionCode: 0,
                cdfSectionOptions:[
                    {id:9, name: "A - Loan Charges", section: "A" },
                    {id:10, name: "B - Services Borrower Did Not Shop For", section: "B"},
                    {id:11, name: "C - Services Borrower Did Shop For", section: "C"},
                    {id:13, name: "E - Taxes and Other Government Fees", section: "E"},
                    {id:14, name: "F - Prepaids", section: "F"},
                    {id:15, name: "G - Initial Escrow Payment at Closing", section: "G"},
                    {id:16, name: "H - Other", section: "H"},
                ],
                loadedLoanId: 0
            };
        },
        props: {
            displayMode: {type: String, default: "route"}
        },

        computed: {
            canPrintReport() { return this.items.length > 0; },
            printReportTooltip() { return !this.mainID ? "Settlement Information must be mapped" : "" },
            reportOptions() { return SettlementReport.reportOptionsCDFAddendums(this.mainID); },
            selectionActions() {
                return this.readOnly
                    ? []
                    : [{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: "Delete Addendum" }];
            },
            cdfLineValues() { return _.sortBy(_.uniq(_.map(this.cdfLines, "cdfLineNo"))); },
            filteredItems() {
                let sectionCode = _.parseNumber(this.selectedSectionCode, 0);
                return this.selectedLoanId > 0
                    ? _.filter(this.items, { sectionCode }) //If we decide to show all when there's no filter -- sectionCode > 0 ? _.filter(this.items, { sectionCode }) : this.items
                    : [];
            },
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },

        watch: {
            selectedLoanId(newVal, oldVal) {
                if (newVal === oldVal || newVal === this.loadedLoanId) return;
                this.mapSettlementStatementOverride()
                    .then(result => {
                        if(!result.success) return;
                        this.fetchData();
                    });
            },
            selectedSectionCode(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.cancelRowEdit();
                this.refreshGrid();
            }
        },

        created() {
            const self = this;
            self.baseInit(false);
            self.initGridConfig();
            self.fetchData();
        },

        methods:{
            initGridConfig() {
                const self = this;
                const setLineNoValue = (newData, cdfLineNo) => {
                    newData.cdfLineNo = cdfLineNo;

                    let matchingLine = _.find(self.cdfLines, { cdfLineNo });
                    if (!matchingLine) return;

                    let description = matchingLine.overrideDescription || matchingLine.description;
                    let borrowerAmount = matchingLine.overrideAmountOfPaidForBorrowerAtClosing || matchingLine.paidForBorrowerAtClosing;
                    let sellerAmount = matchingLine.overrideAmountOfPaidForSellerAtClosing || matchingLine.paidForSellerAtClosing;
                    newData.cdfLineId = matchingLine.lineID;
                    newData.description = description;
                    newData.amount = _.parseNumber(borrowerAmount, 0) + _.parseNumber(sellerAmount, 0);
                };
                const currencyFormat = { type: "currency", precision: 2 };

                self.gridConfig = {
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        {
                            dataField: "orderNumber",
                            dataType: "number",
                            caption: "No.",
                            width: 50,
                            allowEditing: false
                        },
                        {
                            dataField: "sectionName",
                            dataType: "string",
                            caption: "Section",
                            width: 50,
                            visible: false,
                            allowEditing: false
                        },

                        //SELECTBOX
                        // {
                        //     dataField: "cdfLineNo",
                        //     dataType: "string",
                        //     caption: "CDF Line",
                        //     width: 100,
                        //     lookup: {
                        //         allowClearing: true,
                        //         dataSource: ({ data, key }) => _.isEmpty(data?.cdfLineNo) || _.includes(self.cdfLineValues, data?.cdfLineNo)
                        //             ? self.cdfLineValues
                        //             : [data?.cdfLineNo, ...self.cdfLineValues]
                        //     },
                        //     setCellValue: setLineNoValue
                        // },

                        //AUTOCOMPLETE:
                        {
                            dataField: "cdfLineNo",
                            dataType: "string",
                            caption: "CDF Line",
                            width: 100,
                            editCellTemplate(cellElement, cellInfo) {
                                $("<div />").dxAutocomplete({
                                    dataSource: self.cdfLineValues,
                                    showClearButton: true,
                                    value: cellInfo.value,
                                    valueChangeEvent: "blur change focusout",
                                    onValueChanged(e) {
                                        cellInfo.setValue(_.toUpper(e.value.substring(0,3)));
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue: setLineNoValue
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: { maxLength: 150 },
                            validationRules: [{
                                type: "required",
                                message: "Description is required"
                            }]
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: currencyFormat,
                            editorOptions: {
                                format: currencyFormat,
                                showClearButton: true
                            },
                            validationRules: [{
                                type: "required",
                                message: "Amount is required"
                            }],
                        }
                    ],
                    summary: {
                        totalItems: [
                            {
                                column: "description",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: () => "Total Amount:"
                            },
                            {
                                column: "Amount",
                                valueFormat: "currency",
                                summaryType: "sum",
                                customizeText: data => _.formatMoney(data.value)
                            }
                        ]
                    },
                    onInitNewRow: e => {
                        e.data = { sectionCode: _.parseNumber(self.selectedSectionCode, 0), amount: 0 };
                    }
                };

                self.gridDataSource = {
                    key: "addendumID",
                    load: async () => self.filteredItems,
                    insert: self.insertItem,
                    update: self.updateItem
                };
            },

            refreshGrid() {
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("refresh");
            },

            cancelRowEdit() {
                if(!this.invokeGridMethod("hasEditData")) return;
                this.invokeGridMethod("cancelEditData");
            },

            async fetchData() {
                const self = this;
                if(self.selectedLoanId === 0) return true;
                let refreshCdfLines = _.isEmpty(self.cdfLines) || self.loadedLoanId !== self.selectedLoanId;
                self.loadedLoanId = self.selectedLoanId;
                let apiPromises = refreshCdfLines
                    ? Promise.all([
                        self.fetchAddendums(),
                        self.fetchCdfLines()
                    ])
                    : self.fetchAddendums();
                await self.$rqBusy.wait(apiPromises);
                self.refreshGrid();
            },

            async fetchAddendums() {
                const self = this;
                let results = await self.$api.AddendumsApi.getAddendums(self.selectedLoanId);
                self.items = _.map(results, addendumLine => new AddendumDto(addendumLine));
            },

            async fetchCdfLines() {
                const self = this;
                const upperTrim = val => _.toUpper(_.trim(val));
                const padLineNo = num => _.isNil(num) ? "" : num >= 10 ? num : _.padStart(num, 2, "0");
                const mapCdfLine = l => ({ ...l, cdfLineNo: `${upperTrim(l.label)}${padLineNo(l.line)}` });

                let results = await self.$api.AddendumsApi.getCdfLines(self.selectedLoanId);
                let relevantSections = _.map(self.cdfSectionOptions, "section");
                let relevantResults = _.filter(results, l => _.includes(relevantSections, upperTrim(l.label)));

                self.cdfLines = _.map(relevantResults, mapCdfLine);
                self.mainID = self.cdfLines?.[0]?.mainID || 0;
            },

            onAddAction(e) {
                this.invokeGridMethod("addRow");
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let okHandler = function (args) {
                    let keys = _.map(items, "addendumID");
                    self.deleteItem(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected addendum${items.length > 1 ? "s" : ""}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            async insertItem(values) {
                const self = this;
                let dto = new AddendumDto(values);
                dto.loanID = self.selectedLoanId;
                dto.orderNumber = _.filterSize(self.items, { sectionCode: dto.sectionCode }) + 1; // increment add new line
                dto.mainID = self.mainID;

                try {
                    let promise = self.$api.AddendumsApi.addAddendum(self.selectedLoanId, dto);
                    let result = await self.$rqBusy.wait(promise);
                    self.items.push(new AddendumDto(result));
                    self.refreshGrid();
                    self.$toast.success("Addendum added successfully");
                }
                catch(err) {
                    GlobalEventManager.saveCompleted({success: false});
                    self.$toast.error("An issue occurred while adding the entered addendum.");
                    console.error(err);
                }
            },

            async updateItem(key, values) {
                const self = this;
                let dataItem = await self.invokeGridMethod("byKey", key);
                try {
                    let promise = self.$api.AddendumsApi.updateAddendum(new AddendumDto(dataItem));
                    await self.$rqBusy.wait(promise);

                    self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    self.$toast.success("Addendum saved successfully");
                }
                catch(err) {
                    GlobalEventManager.saveCompleted({success: false});
                    self.$toast.error("An issue occurred while updating the selected addendum.");
                    console.error(err);
                }

            },

            async deleteItem(keys) {
                const self = this;
                try {
                    let apiPromise = self.$api.AddendumsApi.deleteAddendums(keys, self.selectedLoanId);
                    await self.$rqBusy.wait(apiPromise);
                    self.$toast.success(`${keys.length} addendum${keys.length > 1 ? "s were" : " was"} deleted.`);
                    self.fetchData();
                    return true;
                }
                catch(error) {
                    //TODO: HANDLE THIS SERVER SIDE
                    if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                        self.$dialog.messageBox("Delete Error", "One or more of the selected addendums are currently being used and could not be deleted.");
                    } else {
                        self.$toast.error("Error deleting addendum.");
                        console.error(error);
                    }
                    return false;
                }
            },
        }
    };
</script>