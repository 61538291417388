<template>
    <div class="allocation-distribution">
        <rq-banner title="ALERT:" message="Allocated Percentage Exceeds 100%" variant="error" :visible="isOverAllocated" dismissable />
        <div class="allocation-distribution-body">
            <fieldset>
                <div class="row tax-info-labels-row">
                    <div class="col-3 form-group">
                        <span>Sales Price</span>
                    </div>
                    <div class="col-6 form-group">
                        <span>Amount Allocated</span>
                    </div>
                    <div class="col-3 form-group">
                        <span>Balance Remaining</span>
                    </div>
                </div>
                <div class="row tax-info-row">
                    <div class="col-3 form-group">
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber v-model="salesPrice" defaultValue="0" decimals="2" automation_id="txt_sales_price" disabled></rqInputNumber>
                        </div>
                    </div>
                    <div class="col-6 form-group">
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber v-model="amountAllocated" defaultValue="0" decimals="2" automation_id="txt_amt_allocated" disabled></rqInputNumber>
                        </div>
                    </div>
                    <div class="col-3 form-group">
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber v-model="balanceRemaining" defaultValue="0" decimals="2" automation_id="txt_balance_remaining" :class="{ 'negative-balance':isOverAllocated, 'positive-balance':balanceRemaining > 0 }" disabled></rqInputNumber>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset class="tax-information">
                <div class="row">
                    <table class="seller-allocation-items">
                        <thead>
                            <tr>
                                <th class="seller-name">Seller</th>
                                <th class="allocation-pct">1099 %</th>
                                <th class="allocation-amt">1099 Amount</th>
                                <th class="remainder">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <AllocationItem
                                v-for="item in dsItems"
                                :key="item.id"
                                :item="item"
                                :canEdit="override"
                                :showApplyRemainder="balanceRemaining > 0"
                                :balanceRemaining="balanceRemaining"
                                @overrideChanged="onOverrideChanged"
                            />
                            <tr>
                                <td></td>
                                <td colspan="3">
                                    <b-form-checkbox automation_id="chk_override_1099_proceeds" :class="{ 'allocation-overridden':override }" v-model="override">Override 1099 Proceeds</b-form-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script>

    import AllocationItem from "./components/AllocationItem";

    export default {

        props: { args: {} },

        components: {AllocationItem},

        data () {
            const self = this;
            return {
                hasChanges: false,
                dsItems: [],
                salesPrice: 0,
                override: false,
                testData: [
                    { id: 1, salesPrice: 100000, sequence: 1, seller: 'Seller 1', percent: 0.25, amount: 25000 },
                    { id: 2, salesPrice: 100000, sequence: 2, seller: 'Seller 2', percent: 0.25, amount: 25000 },
                    { id: 3, salesPrice: 100000, sequence: 3, seller: 'Seller 3', percent: 0.50, amount: 50000 }
                ]
            };
        },

        computed: {
            amountAllocated(){
                const self = this;
                return _.sumBy(self.dsItems, i => { return i.amount;});
            },
            balanceRemaining(){
                const self = this;
                return self.salesPrice - self.amountAllocated;
            },
            isOverAllocated(){
                const self = this;
                let value = (self.balanceRemaining < 0);
                if(value){
                    self.$emit("disable-ok");
                }
                else{
                    self.$emit("enable-ok");
                }

                return value;
            },
            balanceStyle(){
                const self = this;
                return self.isOverAllocated ? {color:"red"} : {color:"green"};
            }
        },
        created() {

        },

        mounted () {
            this.dsItems = this.args.items || this.testData;
            if(this.dsItems.length > 0)
                this.salesPrice = this.dsItems[0].salesPrice;
            this.override = this.args.override || false;
        },

        methods: {

            isTotalPercentValid (options) {
                const self = this;
                var totalPercent = _.sumBy(self.dsItems, item => {return item.percent;});
                console.log(totalPercent);
                // when override option is checked totalPercent equals 100.00001 when totalPercent is 100
                return parseInt(totalPercent) === 100;
            },

            onOverrideChanged () {
                const self = this;
                self.hasChanges = true;
            },

            onOk () {
                const self = this;
                if (!self.override) return true;
                self.validationErrors = [];
                var isValid = self.isTotalPercentValid();
                if (!isValid) self.$toast.error({ message: 'Total Percentage must equal 100%' });
                return isValid;
            },

            dialogResult () {
                const self = this;
                return { hasChanges: this.hasChanges, override: this.override, sellers: this.dsItems };
            }
        }
    };
</script>
