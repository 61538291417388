//import BeforeEnterRouteGuards from "./route-guards";
import { MainLayout } from "@/layouts";
import FileMain from "./FileMain";
import OrderEntryModule from "./order-entry";
import CommitmentModule from "./title-policy";
import OrderWorkflowModule from "./workflow";
import SearchModule from "./search";
import SettlementModule from "./settlement";
import DocumentModule from "../documents";
import DocumentMgmtModule from "../document-mgmt";
import CheckWritingModule from "./check-writing";
import ClosingMarketModule from "./closing-market";
import InvoiceModule from "./invoice";
import FileAlertModule from "./file-alerts";
import CSSMainModule from "./commercial-settlement-statement";
import ClosingModule from "./closing";
import FileAuditLogModule from "./audit-log";
import RecordingModule from "./recording";

let OrderModule = {
    path: "/order/:orderId?",
    redirect: "/order/:orderId?/oe",
    meta: { include: false },
    component: MainLayout,
    children: [{
        name: "Order",
        path: "",
        component: FileMain,
        meta: { label: "File", include: true },
        children: [
            OrderEntryModule,
            CommitmentModule,
            OrderWorkflowModule,
            SearchModule,
            SettlementModule,
            RecordingModule,
            InvoiceModule,
            DocumentModule,
            DocumentMgmtModule,
            CheckWritingModule,
            ClosingMarketModule,
            FileAlertModule,
            CSSMainModule,
            ClosingModule,
            FileAuditLogModule
        ]
    }]
};

export default OrderModule;