<template>
    <div id="credit-memo-dialog" class="content-wrapper" v-if="!propIsNull">
        <rq-page-section>
            <fieldset :disabled="disableFieldSet">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_credit_memo_date">Credit Memo Date</label>
                        <rqdx-date-box
                            id="dtp_credit_memo_date"
                            v-model="editedCreditMemo.dateTimeRecorded"
                            :disabled="true"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="credit_memo_id">Credit Memo ID</label>
                        <input automation_id="credit_memo_id" v-model="editedCreditMemo.creditMemoID" type="text" class="form-control" id="credit_memo_id" placeholder="Optionial Invoice Number" :disabled="true"/>
                    </div>

                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_tax_rate">Tax Rate</label>
                        <div class="input-group">
                            <rqInputNumber v-model="invoiceDetail.taxRate" defaultValue="0" decimals="3" minValue="0" maxValue="100" cssClass="form-control" id="txt_tax_rate" automation_id="txt_tax_rate" :disabled="true"></rqInputNumber>
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_credit_memo_amount">Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rqInputNumber v-model="total" defaultValue="0" decimals="2" cssClass="form-control" id="txt_credit_memo_amount" automation_id="txt_credit_memo_amount" :disabled="true"></rqInputNumber>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label for="txt_note">Note</label>
                        <input automation_id="txt_note" v-model="editedCreditMemo.note" type="text" class="form-control" id="txt_note"/>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="creditMemoDetailGrid"
            automation_id="tbl_credit_memo_details"
            title="Credit Memo Lines"
            title-size="sm"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            export-file-name="credit-memo-detail-data"
            @delete="onDeleteAction"
            hide-search
            rq-editable
            hide-show-column-chooser>
            <template #toolbar>
                <ul class="nav ms-2 me-auto">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_invoice_line" variant="theme" :disabled="disableFieldSet" @click="onAddCreditMemoDetail">Add</b-btn>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { CreditMemoModel, CreditMemoDetailModel } from "../models";
    import { CreditMemoEdit} from "@config/enums";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";


    export default {
        name: "CreditMemoDialog",
        mixins: [GridSystemLookupMixin],
        props:{
            invoiceDetail:{type: Object},
            creditMemo:{type: Object},
            addMode:{type: Boolean, default: false}
        },
        data(){
            return {
                editedCreditMemo: null
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                systemDefaults: state => state.system.systemDefaults,
                order: state => state.orders.order
            }),
            propIsNull(){return _.isNil(this.invoiceDetail) || _.isNil(this.creditMemo)},
            creditMemoDetailGrid(){ return _.get(this, "$refs.creditMemoDetailGrid.gridInstance", null) || {}; },
            localSecurity(){
                return this.securitySettings.findValues(["CreditMemoEditing"]);
            },
            disableFieldSet(){
                if(this.isOrderLocked) return;
                if((this.localSecurity.CreditMemoEditing === CreditMemoEdit.Create
                    || this.localSecurity.CreditMemoEditing === CreditMemoEdit.Unrestricted)
                    && this.addMode)
                    return false;
                else if(this.localSecurity.CreditMemoEditing === CreditMemoEdit.Create
                    && !this.addMode)
                    return true;
                else if(this.disableAddCreditMemo)
                    return true;
                else
                    return false;

            },
            disableAddCreditMemo(){
                return this.localSecurity.CreditMemoEditing === CreditMemoEdit.Restricted || this.isOrderLocked;
            },
            canEditCreditMemos(){
                return this.localSecurity.CreditMemoEditing === CreditMemoEdit.Unrestricted && !this.isOrderLocked;
            },
            readOnly() {
                return this.disableAddCreditMemo || this.isOrderLocked;
            },
            selectionActions() {
                return [{ name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, disabled: this.disableFieldSet }];
            },
            taxableTotal(){
                const self = this;
                let taxableTotal = 0;

                _.forEach (self.editedCreditMemo.details, detail => {
                    if (detail.taxable === true)
                        if (detail.quantity > 0)
                            taxableTotal += detail.amount * detail.quantity;
                        else
                            taxableTotal += detail.amount;
                });
                return taxableTotal;
            },
            nonTaxableTotal(){
                const self = this;
                let nonTaxableTotal = 0;

                _.forEach (self.editedCreditMemo.details, detail => {
                    if (detail.taxable === false)
                        if (detail.quantity > 0)
                            nonTaxableTotal += detail.amount * detail.quantity;
                        else
                            nonTaxableTotal += detail.amount;
                });
                return nonTaxableTotal;
            },
            tax(){
                const self = this;
                return self.taxableTotal * (self.invoiceDetail.taxRate / 100);
            },
            total(){
                const self = this;

                var total = self.nonTaxableTotal + self.taxableTotal + self.tax;

                return total;
            },
            isNotEmpty() {
                const self = this;

                return _.some(self.editedCreditMemo.details, function(item) {
                    return item.amount || item.description
                });
            }
        },
        created() {
            const self = this;
            self.editedCreditMemo = _.cloneDeep(self.creditMemo);
            self.loadGridConfig();
        },
        methods:{
            loadGridConfig(){
                const self = this;
                self.gridConfig = {
                    onEditorPreparing: self.onEditorPreparing,
                    onInitNewRow: self.initNewRow,
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            caption: "Description",
                            allowEditing: true,
                            editorOptions:{ inputAttr: {automation_id: "txt_description"} },
                        },
                        {
                            dataField: "amount",
                            caption: "Amount",
                            allowEditing: true,
                            alignment: "right",
                            dataType: "number",
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            format: { type: "currency", precision: 2 },
                            editorOptions: { format: {type: "currency", precision: 2}, showClearButton: true, inputAttr: {automation_id: "txt_amount"}},
                            precision: 2
                        },
                        {
                            dataField: "quantity",
                            caption: "Qty",
                            allowEditing: true,
                            alignment: "right",
                            dataType: "number",
                            editorOptions:{
                                format: "#",
                                inputAttr: {automation_id: "txt_quanitity"}
                            }
                        },
                        {
                            dataField: "taxable",
                            caption: "Taxable",
                            dataType: "boolean",
                            allowEditing: true,
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            editorOptions:{ elementAttr: {automation_id: "chk_taxable"} },
                        },
                        {
                            dataField: "total",
                            dataType: "number",
                            caption: "Total",
                            allowEditing: false,
                            alignment: "right",
                            editorOptions:{ inputAttr: {automation_id: "txt_total"} },
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            dataField: "accountCode",
                            dataType: "number",
                            caption: "Account Code",
                            alignment: "right",
                            allowEditing: true,
                            editorOptions: { inputAttr: {automation_id: "drp_account_code"} },
                            lookup: {
                                   dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES),
                                   displayExpr: "name",
                                   valueExpr: "id"
                            }
                        },
                    ],
                    summary:{
                        totalItems:[{
                            name: "taxableSummary",
                            showInColumn: "quantity",
                            displayFormat: "Taxable: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        },
                        {
                            name: "nonTaxableSummary",
                            showInColumn: "amount",
                            displayFormat: "Non-Taxable: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        },
                        {
                            name: "taxSummary",
                            showInColumn: "taxable",
                            displayFormat: "Tax: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        },
                        {
                            name: "totalSummary",
                            showInColumn: "total",
                            displayFormat: "Total: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        }],
                        calculateCustomSummary: function (options) {
                            switch(options.name){
                                case "taxableSummary":
                                    options.totalValue = self.taxableTotal;
                                    break;
                                case "nonTaxableSummary":
                                    options.totalValue = self.nonTaxableTotal;
                                    break;
                                case "taxSummary":
                                    options.totalValue = self.tax;
                                    break;
                                case "totalSummary":
                                    options.totalValue = self.total;
                                    break;
                            }
                        }
                    }
                }
                self.gridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.editedCreditMemo.details);
                    },
                    update: self.onGridUpdate,
                    insert: self.onGridInsert
                }
            },

            initNewRow(e){
                e.data = {
                    isNew: true,
                    quantity: 1,
                    taxable: false
                };
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                e.editorOptions.disabled = this.disableFieldSet;
            },

            onGridUpdate(key, values) {
                if(this.disableFieldSet) return;
                const self = this;
                let item = _.find(self.editedCreditMemo.details, d => d.clientKey === key);
                _.assign(item, values);
                item.total = item.quantity * item.amount
                return Promise.resolve(item);
            },

            onGridInsert(values){
                const self = this;
                if(self.disableFieldSet) return;
                let newItem = new CreditMemoDetailModel({creditMemoID: self.editedCreditMemo.creditMemoID});
                _.assign(newItem, values);
                newItem.total = newItem.quantity * newItem.amount
                self.editedCreditMemo.details.push(newItem);
                return Promise.resolve(newItem);
            },

            onAddCreditMemoDetail(){
                this.creditMemoDetailGrid.addRow();
            },

            onDeleteAction(e){
                if(!e || !e.data) return;
                if(!this.disableFieldSet) return;
                const self = this;
                let items = e.data;

                let confirmMessage = items.length === 1 ? "Are you sure you want to delete this credit memo line?" : "Are you sure you want to delete these credit memo lines?";

                let okHandler = function () {

                    _.forEach(items, i => {
                        let index = _.findIndex(self.editedCreditMemo.details, d => {
                            return d.clientKey === i.clientKey;
                        });

                        self.editedCreditMemo.details.splice(index, 1);

                        if(!i.isNew){
                            self.editedCreditMemo.deletedDetails.push(i);
                        }
                    });

                    self.creditMemoDetailGrid.refresh();
                    return true;
                };
                self.$dialog.confirm( "Delete Credit Memo Line", confirmMessage, okHandler, null, { cancelTitle: 'No', okTitle: 'Yes'});
            }
        }
    }
</script>