<template>
<div class="rq-container content-wrapper settlement-missing-section">
    <rq-page-section title="VIEW MISSING ITEMS" headerSize="sm" section-group="settlement-dashboard" :theme="missingSettlementItems.length > 0 ? 'red' : null" collapsible>
        <template #header-actions>
            <ul class="nav">
                <li class="nav-item">
                    <b-btn automation_id="btn_missing_items" variant="theme" @click="onUpdateMissingItems" :disabled="isFileLocked">Validate</b-btn>
                </li>
            </ul>
        </template>
        <div v-if="missingSettlementItems.length > 0" class="table-responsive">
            <table class="table table-fixed table-sm">
                <thead>
                    <tr>
                        <th scope="col" class="col-2 missingSection-text">{{lineHeader}}</th>
                        <th scope="col" class="col-5 missingSection-text">Description</th>
                        <th scope="col" class="col-3 missingSection-text">Missing Value</th>
                        <th scope="col" class="col-2 missingSection-text">Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in missingSettlementItems" :key="item.key">
                        <td class="col-2 missingSection-text"><span>{{item.getLineNumber(selectedView)}}</span></td>
                        <td class="col-5 missingSection-text"><span>{{item.description}}</span></td>
                        <td class="col-3 missingSection-text"><span>{{item.missingData}}</span></td>
                        <td class="col-2 missingSection-text">
                            <!-- <b-btn variant="link" class="btn-theme text-align-left" :to="{ name: 'settlement:origination-section'}"></b-btn>                             -->
                            <router-link ref="routerLink" class="btn-theme text-align-left" :to="{ name: item.routeName(selectedView)}" :disabled="isFileLocked">
                                <a :class="{ 'nav-link':true, 'disabled':isFileLocked }">
                                    <FontAwesomeIcon icon="fa fa-sign-out-alt" class="missing-link-icon" />
                                </a>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </rq-page-section>
</div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { SETTLEMENT_TYPE } from '@settlement/models';
    import { required } from '@vuelidate/validators';
    import { SettlementMissingItemDto } from './models';

    export default {
        components: { },
        props: {
            validateSection: { type: Boolean,  default: false },
            isMissingItems: { type: Boolean, default: false}
        },
        data: function(){
            return {
                missingItems: []
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "propertyList"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                isFileLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                loans: state => state.orders.loans || [],
            }),
            lineHeader() { return this.isCdf ? "Line Section" : "Line Number"; },
            missingSettlementItems() { return this.missingItems; },
            selectedView() { return _.parseNumber(this.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF); },
            settlementTypeItems() { return this.lookupHelpers.getLookupItems(this.lookupItems.SETTLEMENT_TYPES); },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
        },

        watch: {
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                // this.onUpdateMissingItems();
            },
        },

        created() {
            if (this.validateSection) {
                this.onUpdateMissingItems();
            }
        },

        methods: {
            onUpdateMissingItems(e) {
                this.fetchMissingItems().then(() => {
                    this.$emit("update:isMissingItems", this.missingItems.length > 0);
                });
            },
            fetchMissingItems() {
                const self = this;
                let defaultLoanId = self.orderSummary.defaultLoanID;
                let apiPromise = self.$api.SettlementDashboardApi.getMissingSettlementItems(defaultLoanId, self.selectedView);
                return self.$rqBusy.wait(apiPromise, { topLevel: true })
                    .then(result => {
                        let items = _.map(result, i => new SettlementMissingItemDto(i));
                        self.missingItems = self.filterMissingItems(items);
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error loading missing items.` });
                    });
            },
            filterMissingItems(items) {
                const self = this;

                if (self.isCdf) {
                    return _.sortBy(_.filter(items, i => i.validate && (i.defaultedSettlementType === null || i.defaultedSettlementType === self.selectedView)
                                    && !_.isNullOrEmpty(i.cdfSection)), "cdfSection");
                }

                if (self.isHud) {
                    return _.sortBy(_.filter(items, i => i.validate && (i.defaultedSettlementType === null || i.defaultedSettlementType === self.selectedView)
                                    && !_.isNullOrEmpty(i.hudSection)), "hudLine");
                }

                return _.filter(items, i => i.validate && (i.defaultedSettlementType === null || i.defaultedSettlementType === self.selectedView));
            },
            onNavigateToLink() {

            }
        }
    }
</script>