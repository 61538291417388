<template>
    <rq-page-section
        title="Legal Description"
        class="tp-legal-desc-section"
        flex-full
        flex-body
        borderless>
        <template #header-actions>
            <ul class="nav">
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_edit_legal_description"
                        variant="theme"
                        size="sm"
                        :disabled="readOnly"
                        @click="onEditLegalDescription">
                        <span>Edit</span>
                    </b-btn>
                </li>
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_append_legal_description"
                        variant="theme"
                        size="sm"
                        :disabled="readOnly"
                        @click="onAppendLegalDescription">
                        <span>Append From Other File</span>
                    </b-btn>
                </li>
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_append_brief_legal"
                        variant="theme"
                        size="sm"
                        :disabled="readOnly"
                        @click="onAppendBriefLegal">
                        <span>Append Brief Legal</span>
                    </b-btn>
                </li>
            </ul>
        </template>
        <div class="rq-rtf-display rq-no-hover">
            <rq-html-editor
                ref="htmlEditor"
                placeholder="Customize clause content here or click the edit icon for advanced editing features..."
                :inline-auto-resize="false"
                v-model="editorContent"
                @focus="onHtmlEditorFocus"
                @blur="onHtmlEditorBlur"
                show-insert-doc-link
                inline
            />
        </div>
    </rq-page-section>
</template>

<script>
    import { computed } from "vue";
    import { mapGetters, mapState } from "vuex";
    import { mapObjPaths } from "@/shared/utilities/VueHelpers";
    import { MODULE_EVENTS } from "../../enums";
    import { COMMITMENT_MUTATIONS } from "@/store/mutations";
    import { CommitmentDataDto } from "../../models";
    import SelectExistingOrder from "../../../shared/components/SelectExistingOrder";
    import { useVModel } from "@vueuse/core";

    const LEGAL_DESC_SECTION_NAME = "ScheduleALegalDescription";

    export default {
        name: "LegalDescription",
        props: {
            commitmentData: { type: Object, default: () => new CommitmentDataDto() },
        },
        setup(props, { emit }) {
            const commitmentDataValue = useVModel(props, "commitmentData", emit);
            const detailId = computed(() => _.parseNumber(commitmentDataValue.value?.detail?.commitmentPolicyDetailID));
            const htmlText = computed({
                get() { return commitmentDataValue.value?.detail?.legalDescriptionHtml; },
                set(val) { commitmentDataValue.value.detail.legalDescriptionHtml = val; }
            });
            const rtfText = computed({
                get() { return commitmentDataValue.value?.detail?.legalDescription; },
                set(val) { commitmentDataValue.value.detail.legalDescription = val; }
            });
            return {
                commitmentDataValue,
                detailId,
                htmlText,
                rtfText
            };
        },
        data() {
            return {
                editorContent: "",
                localChangePending: false
            }
        },
        computed: {
            ...mapGetters([
                "getDefaultSectionCategoryId",
                "getSectionCategories"
            ]),
            ...mapState({
                readOnly: state => _.parseBool(state.isPageReadOnly),
                orderId: state => state.orders.orderId,
            })
        },
        watch: {
            htmlText: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.updateEditorContent();
                },
                immediate: true
            }
        },
        methods: {
            updateEditorContent() {
                const self = this;
                if(_.isEmpty(self.htmlText)) self.editorContent = "";
                let parser = new DOMParser();
                let dom = parser.parseFromString(self.htmlText, "text/html");
                let links = dom.getElementsByTagName("a");
                _.forEach(links, lnk => {
                    if(!_.endsWith(lnk.href, "/C") && !_.endsWith(lnk.href, "/U")) return;
                    lnk.href = "#";
                });
                let parsedContent = dom.body.innerHTML;
                if(parsedContent === self.editorContent) return;
                self.editorContent = parsedContent;
            },

            setChangePending(val) {
                this.localChangePending = val;
                this.$store.commit(COMMITMENT_MUTATIONS.SET_CONTENT_CHANGE_PENDING, val);
            },

            onHtmlEditorFocus(e) {
                this.setChangePending(true);

            },

            onHtmlEditorBlur(e) {
                const self = this;
                if(e.content === self.htmlText) {
                    self.setChangePending(false);
                    return;
                }
                self.$api.UtilitiesApi.htmlToRtf(e.content)
                    .then(resultRtf => {
                        self.rtfText = resultRtf;
                        self.htmlText = e.content;
                        self.hasChanges = true;
                        self.setChangePending(false);
                    });
            },

            onEditLegalDescription(e) {
                const self = this;
                self.launchChangeDependentDialog("editor");
            },

            onAppendLegalDescription() {
                const self = this;
                self.launchChangeDependentDialog("append");
            },
            onAppendBriefLegal() {
                const self = this;
                self.launchChangeDependentDialog("appendBriefLegal");
            },

            launchChangeDependentDialog(name) {
                const self = this;
                if(!self.localChangePending) {
                    self.launchDialog(name);
                    return;
                }
                let unwatchValue = self.$watch("localChangePending", function(newValue) {
                    if(_.parseBool(newValue)) return;
                    unwatchValue();
                    self.launchDialog(name);
                });
            },

            launchDialog(name) {
                const self = this;
                switch(name) {
                    case "editor":
                        self.$events.emit(MODULE_EVENTS.EDIT_RTF_CONTENT, {
                            title: "Edit Legal Description",
                            documentName: "Legal_Description.rtf",
                            documentContent: self.rtfText,
                            showInsertClauses: true,
                            enableClausePromptEntry: true,
                            defaultCategoryId: self.getDefaultSectionCategoryId(LEGAL_DESC_SECTION_NAME),
                            categories: self.getSectionCategories(LEGAL_DESC_SECTION_NAME),
                            onComplete(contentResult) {
                                self.rtfText = contentResult.rtf;
                                self.htmlText = contentResult.html;
                                self.hasChanges = true;
                            }
                        });
                        break;
                    case "append":
                        self.$dialog.open({
                            title: "Copy Legal Description",
                            height: "80%",
                            minHeight: 700,
                            width: "80%",
                            minWidth: 1200,
                            closeOnEsc: true,
                            component: SelectExistingOrder,
                            onOk(e) {
                                let result = e.component.selectedOrder;
                                let targetOrderId = _.getNumber(result, "ordersID", 0);
                                if(targetOrderId === 0) return true;
                                let apiPromise = self.$api.CommitmentsApi.appendLegalDescription(self.detailId, targetOrderId, self.rtfText);
                                return self.$rqBusy.wait(apiPromise)
                                    .then(contentResult => {
                                        if(!contentResult.isValid) {
                                            e.component.errorMessage = contentResult.validationMessage;
                                            return false;
                                        }
                                        self.rtfText = contentResult.rtfText;
                                        self.htmlText = contentResult.htmlText;
                                        self.$toast.success("Successfully copied data.");
                                        return true;
                                    })
                                    .catch(err => err);
                            }
                        });
                        break;
                        case "appendBriefLegal": {
                            let apiPromise = self.$api.CommitmentsApi.appendBriefLegalDescription(self.orderId, self.rtfText);
                            self.$rqBusy.wait(apiPromise)
                                .then(contentResult => {
                                    self.rtfText = contentResult.rtfText;
                                    self.htmlText = contentResult.htmlText;
                                    self.$toast.success("Successfully copied data.");
                                    return true;
                                })
                                .catch(err => err);
                            break;
                        }
                }
            },
        }
    };
</script>