<template>
    <div class="rq-container content-wrapper">
        <rq-banner
        variant="error"
                title="Please correct the highlighted errors on screen to continue."
                icon="fas fa-exclamation-triangle"
                :visible="v$.$error"
        />
        <fieldset :disabled="readOnly">
            <div class="row">
                <!--Payee Number-->
                <div class="col-3 form-group" v-if="readOnly && item.payeeCompanyID > 0">
                    <label class="form-control-label" for="txt_payee_number">Payee Number</label>
                    <input
                        id="txt_payee_number"
                        automation_id="txt_payee_number"
                        class="form-control"
                        type="text"
                        v-model="item.payeeCompanyID"
                    />
                </div>
                <!--Payee Name-->
                <div :class="[readOnly && item.payeeCompanyID > 0 ? 'col-9 form-group form-required' : 'col-12 form-group form-required', {'has-error' : v$.payeeName.$error}]" >
                    <label class="form-control-label" for="txt_payee_name">Payee Name</label>
                    <input
                        id="txt_payee_name"
                        automation_id="txt_payee_name"
                        class="form-control"
                        type="text"
                        maxlength="200"
                        rows="1"
                        v-model="v$.payeeName.$model"
                    />
                    <rq-validation-feedback>Payee Name is Required.</rq-validation-feedback>
                </div>
            </div>
            <div class="row">
                <!--Address 1-->
                <div class="col-6 form-group">
                    <label class="form-control-label" for="txt_payee_address1">Address 1</label>
                    <input
                        id="txt_payee_address1"
                        automation_id="txt_payee_address1"
                        class="form-control"
                        type="text"
                        maxlength="100"
                        v-model="item.payeeAddress1"
                    />
                </div>
                <!--Address 2-->
                <div class="col-6 form-group">
                    <label class="form-control-label" for="txt_payee_address2">Address 2</label>
                    <input automation_id="txt_payee_address2"
                        class="form-control"
                        type="text"
                        v-model="item.payeeAddress2"
                        maxlength="100"
                    />
                </div>
            </div>
            <div class="row">
                <!--City-->
                <div class="col-6 form-group">
                    <label class="form-control-label" for="txt_payee_city">City</label>
                    <input
                        id="txt_payee_city"
                        automation_id="txt_payee_city"
                        class="form-control"
                        type="text"
                        v-model="item.payeeCity"
                        maxlength="50"
                    />
                </div>

                <!--State-->
                <div class="col-3 form-group">
                    <label class="form-control-label" for="drp_payee_state">State</label>
                    <dx-select-box
                        :input-attr="{ id: 'drp_payee_state', automation_id: 'drp_payee_state' }"
                        class="form-control"
                        value-expr="id"
                        display-expr="id"
                        :items="usStatesLookup"
                        :search-enabled="true"
                        :disabled="readOnly"
                        v-model="item.payeeState"
                    />
                </div>
                <!--Zip-->
                <div class="col-3 form-group">
                    <label class="form-control-label" for="txt_payee_zip">Zip</label>
                    <rq-masked-input
                        id="txt_payee_zip"
                        maskType="zip"
                        maxlength="10"
                        v-model="item.payeeZip"
                        is-masked
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    export default {
        name: "CheckWritingManualPayee",
        props: {
            item: { type: Object, default:null },
            readOnly: {type: Boolean, default: false }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {

        },
        computed: {
            ...mapGetters([ "usStatesLookup" ]),
            payeeName: {
                get() { return this.item.payee || this.item.payeeName; },
                set(val) {
                    this.item.payee = val;
                    this.item.payeeName = val;
                }
            },
        },
        methods: {
            validate() {
            const self = this;
            self.v$.$touch();
            return !self.v$.$error;
            },
        },
        validations: () => ({
            payeeName: { required }
        }),
    }
</script>