import SuperSearchPage from './components/SuperSearch';
import * as Actions from './actions';

const noAccessMsg = "Access to order search is not authorized.  Please contact your administrator.";

let pages = [
    { 
        name: 'search-default',
        path: '',
        component: SuperSearchPage,
        meta: {
            label: 'Search',
            include: false,
                actions: [
                Actions.SEARCH,
                Actions.CLEAR,
                Actions.RESET,
                Actions.FIELDS
            ],
            accessKey: 'SuperSearch',
            unauthorizedMessage: noAccessMsg 
        }
    }
];

export default pages;
