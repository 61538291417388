<template>
    <div class="rq-container content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="(v$.$error && showValidationBanner) || !hasPools"
        />
        <div class="row">
            <div :class="{ 'col col-6 form-group form-required':true, 'has-error':v$.selectedPoolID.$error }">
                <label for="drp_poolNames">Pool</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_poolNames', id: 'drp_poolNames' }"
                    :items="pools"
                    value-expr="poolId"
                    display-expr="pool"
                    v-model="v$.selectedPoolID.$model"
                    :wrap-item-text="true"
                    :search-enabled="true"
                    :disabled="!hasPools"
                    @value-changed="onPolicyPoolChange"
                />
                <rq-validation-feedback>Pool# is required</rq-validation-feedback>
            </div>
            <div :class="{ 'col col-6 form-group form-required':true, 'has-error':v$.poolNumber.$error }">
                <label for="drp_poolNames">{{policyLabel}} Policy Number</label>
                <input
                    v-if="autoGenerate"
                    automation_id="txt_policy"
                    v-model="v$.poolNumber.$model"
                    :disabled="true"
                    type="text"
                    class="form-control"
                    maxlength="50"
                >
                <div class="input-group" v-else>
                    <span v-if="currentPrefix.length > 0" class="input-group-text">{{currentPrefix}}</span>
                    <input
                        id="txt_poolNumber"
                        automation_id="txt_poolNumber"
                        type="text"
                        class="form-control"
                        :maxlength="currentPool.numChars"
                        v-model="v$.poolNumber.$model"
                        @keypress="onKeyPress"
                    >
                    <span id="poolsuffix" v-if="currentSuffix.length > 0" class="input-group-text">{{currentSuffix}}</span>
                </div>
                    <!-- <span class="rq-clear-value-icon dx-icon dx-icon-clear" v-show="clearButtonVisible" @click="onClearPolicyNumber"></span> -->
                <rq-validation-feedback :offset="suffixOffset"
                    :messages="{
                        'Policy Number Required': v$.poolNumber.required.$invalid,
                        'Policy Number is Out of Range': v$.poolNumber.isInRange.$invalid
                    }"
                />
            </div>
        </div>
        <div class="row" v-if="!autoGenerate">
            <div class="col col-6 form-group">
                <label for="txt_available">Available</label>
                <div id="txt_available">{{poolRange}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { PolicyNumberDto } from "../../models";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";

    export default {
        name: "EditPolicyNumber",
        props: {
            loanId: { type: Number, default: 0 },
            policyEditType: { type: Number, default: 0 },
            autoGenerate: {type: Boolean, default: false},
            pools: { type: Array, default: () => [] },
        },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                selectedPoolID: null,
                poolNumber: null,
                suffixOffset: 10,
                showValidationBanner: false,
                poolSuffix: null
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
            }),
            poolRange () {return this.currentPool.minPolicyNumber + ' - ' + this.currentPool.maxPolicyNumber;},
            currentPool () {
                let pool = _.find(this.pools, ["poolId", _.getNumber(this, "selectedPoolID", 0)]);
                return pool || new PolicyNumberDto() ;
            },
            policyLabel() { return this.policyEditType === 1 ? "owner" : "loan"; },
            currentPrefix() { return _.get(this, "currentPool.prefix", ""); },
            currentSuffix() { return _.get(this, "currentPool.suffix", ""); },
            currentMinPolicyNumber() { return _.trimSuffix(_.trimPrefix(_.get(this, "currentPool.minPolicyNumber", ""), this.currentPrefix), this.currentSuffix); },
            currentMaxPolicyNumber() { return _.trimSuffix(_.trimPrefix(_.get(this, "currentPool.maxPolicyNumber", ""), this.currentPrefix), this.currentSuffix); },
            currentPolicyNumber() {
                if (this.autoGenerate) {
                    return _.get(this, "currentPool.minPolicyNumber", null) || "";
                } else {
                    let poolNum = this.poolNumber;
                    let numChars = _.getNumber(this.currentPool, "numChars", 0);
                    if (_.size(poolNum) != numChars) {
                        poolNum = _.padStart(poolNum, numChars, '0')
                    }
                    return `${this.currentPrefix}${poolNum}${this.currentSuffix}`;
                }
            },
            clearButtonVisible() { return _.size(this.poolNumber) > 0; },
            hasPools() { return !_.isEmpty(this.pools); },
            errorMessage() {
                return this.hasPools
                    ? "Please correct the highlighted errors on screen to continue."
                    : `Please define some additional ${this.policyLabel} policy pools for this underwriter and region.`;
            }
        },

        created () {
            this.init();
        },

        mounted() {
        },

        validations: function() {
            var result = {
                selectedPoolID: { required, },
                poolNumber: {
                    required,
                    isInRange(val) {
                        var currentValue = _.clone(val);
                        if(this.autoGenerate){
                            currentValue = _.trimSuffix(_.trimPrefix(currentValue, this.currentPrefix), this.currentSuffix);
                        }
                        return _.inRange(_.parseNumber(currentValue, 0), _.getNumber(this, "currentMinPolicyNumber", 0), (_.getNumber(this, "currentMaxPolicyNumber", 0) + 1));
                    }
                },
            }
            return result;
        },

        methods: {
            init () {
                const self = this;
                if (self.hasPools) {
                    self.selectedPoolID = self.pools[0].poolId;
                    self.poolNumber = self.autoGenerate ? self.currentPool.minPolicyNumber : "";
                }
                else {
                    self.$emit("disable-ok");
                }
            },

            validate() {
                const self = this;
                self.v$.$touch();
                self.showValidationBanner = true;
                return !self.v$.$error;
            },

            resetValidation() {
                const self = this;
                self.v$.$reset();
                self.showValidationBanner = false;
            },

            save() {
                const self = this;
                
                if(_.isNil(self.poolSuffix)) {
                    self.poolSuffix = _.parseNumber($('#poolsuffix').outerWidth(true), 0);
                    if (!_.isNil(self.poolSuffix)) self.suffixOffset = self.poolSuffix + 10;
                }

                if(!self.validate()) return Promise.resolve(false);

                let updatePolicyNumberArgs = {
                    orderId: self.orderId,
                    policyType: self.policyEditType,
                    regionId: self.orderSummary.regionID,
                    underWriterId: self.orderSummary.underwriterID,
                    loanId: self.loanId,
                    poolId: self.selectedPoolID,
                    policyNumber: self.currentPolicyNumber
                };
                let apiPromise = self.$api.CommitmentsApi.updatePolicyNumber(updatePolicyNumberArgs);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        if (response.code !== 0) return true;
                        self.errorMessage = response.message;
                        //self.updatePolicyNumber(response.poolId, response.policyNumber);
                        self.resetValidation();
                        return true;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("An issue occurred while saving policy number changes.");
                        return false;
                    });
            },

            onClearPolicyNumber(e) {
                this.poolNumber = null;
            },

            onKeyPress(e) {
                let validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Tab"];
                let isValid = _.includes(validKeys, e.key);

                if(isValid) return;
                e.preventDefault();
            },

            onPolicyPoolChange (e) {
                const self = this;
                self.selectedPoolID = e.value;
                self.poolNumber = this.autoGenerate ? self.currentPool.minPolicyNumber : "";
                self.$nextTick(() => {
                    self.poolSuffix = _.parseNumber($('#poolsuffix').outerWidth(true), 0);
                    if (!_.isNil(self.poolSuffix)) self.suffixOffset = self.poolSuffix + 10;
                });
            },

            updatePolicyNumber (poolId, policyNumber) {
                const self = this;
                self.selectedPoolID = poolId;
                self.currentPool.minPolicyNumber = policyNumber;
            },
        }
    };
</script>