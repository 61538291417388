<template>
    <div class="content-wrapper loancost-section">
        <rq-page-section title="Loan Charges" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li v-if="isHud2010" class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly" v-focus>Add</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_disclosure_line" @click="onAddLoanCostLine(LoanCostTypes.AdditionalLoanCharge, HudLineTypes.Disclosure)">Disclosure</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_general_line" @click="onAddLoanCostLine(LoanCostTypes.AdditionalLoanCharge, HudLineTypes.General)">General</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_gfe1_line" @click="onAddLoanCostLine(LoanCostTypes.AdditionalLoanCharge, HudLineTypes.GFE_1)">GFE #1</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_gfe3_line" @click="onAddLoanCostLine(LoanCostTypes.AdditionalLoanCharge, HudLineTypes.GFE_3)">GFE #3</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li v-else class="nav-item">
                        <b-button automation_id="btn_add_charge"
                            ref="btn_add_charge"
                            variant="theme"
                            :disabled="readOnly"
                            @click="onAddLoanCostLine(LoanCostTypes.AdditionalLoanCharge, null)"
                            v-rq-tooltip.hover.top="{ ttile: `Add Charge` }"
                            v-focus>
                            Add
                        </b-button>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>

        <rq-no-data
            v-if="noData"
            class="h-50"
            text="No charges found for the selected loan."
        />
        <origination-section
            v-else
            ref="originationSection"
            title="Originations"
            :section-data="items"
            :settlementType="selectedView"
            @update803="onUpdate803"
            @revert="onRevertItems"
            @delete="onDeleteItems"
            @netfund="onChangeNetFundItems"
            @moveSection="onMoveSection"
            @setAddendum="onSetAddendumItem"
        />
    </div>
</template>

<script>
    import { GlobalEventManager } from '@/app.events';
    import { OriginationChargeModel, LoanCostTypes, SETTLEMENT_TYPE, SettlementTypeOption, HudLineTypes } from '@settlement/models';
    import OriginationSection from './components/OriginationSection';
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        name: "OriginationCharges",
        mixins: [BaseSettlementMixin],
        components: {
            OriginationSection
        },
        data(){
            return {
                items: [],
                itemKey: "loanCostSectionDataID",
                deleteItems: [],
                movedItems: [],
                originalData: [],
                selected:{},
                LoanCostTypes: LoanCostTypes,
                HudLineTypes: HudLineTypes,
            };
        },

        computed:{
            selectedItems() { return _.filter(this.items, "isSelected"); },
            itemsSelected() { return !_.isEmpty(this.selectedItems) },
            canDelete() { return !_.isEmpty(this.selectedItems) && !this.readOnly && _.every(this.selectedItems, item => !item.required && !item.isPaidByOther); },
            canRevert() { return !_.isEmpty(this.selectedItems) && !this.readOnly && _.some(this.selectedItems, item => item.isOverridableLine && (item.isBorrowerAtClosingOverridden || item.isBorrowerOutsideOverridden)); },
            noData() { return _.isEmpty(this.items); }
        },

        watch:{
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                self.save();
            },
            "item.loanID": {
                handler: function(newVal, oldValue) {
                    const self = this;
                    if (newVal === oldValue) return;

                    if (self.item.payeeCompanyID == null && self.item.isDefaulted === false) {
                        let selectedLoan = _.find(self.loans, function(l) { return l.loanID == self.item.loanID; });
                        self.item.payeeCompanyID = selectedLoan.lenderID;
                        self.item.payeeCompanyName = selectedLoan.lender;
                        self.item.isDefaulted = true;
                    }
                },
                immediate: true,
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                let selectedViewIsHud = _.includes([SETTLEMENT_TYPE.HUD_2010, SETTLEMENT_TYPE.HUD_1974], newValue);
                // If settlement type is switching to a Hud version we refresh data to ensure
                // UI model is cleared of hudLineIDs from previous data model for
                // proper Hud mapping sake in data integrity. Also perform a reorder considering
                // that user may be switching between HUD versions to address gaps
                if (selectedViewIsHud)
                    this.fetchData().then(() => {
                        this.reorderHudItems();
                    });
                else
                    this.onChangeSSViewSelected(newValue);
            }
        },

        created() {
            const self = this;
            self.baseInit();
            self.fetchData();
        },

        methods:{
            getNextAvailableHudLine() {
                const self = this;
                let hudData = self.isHud ? self.items : self.originalData;
                let hudItems = _.filter(hudData, i => i.hudSection === 800);
                let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                let nextAvailableLineNumber = _.parseNumber(maxHudLineItem.hudLine, 0) + 1;

                return nextAvailableLineNumber;
            },
            onUpdate803() {
                //Specific updates that only apply to HUD 2010 for line 803 summing of 801 and 802 borrowerOutside (misc_Dollar) values
                const self = this;

                if (self.isHud2010) {
                    let line801 = _.find(self.items, function(i) { return i.hudLine === 801 && i.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010; });
                    let line801_borrowerValue = line801.isBorrowerOutsideOverridden ? line801.overrideBorrowerOutside : line801.misc_Dollar;

                    let line802 = _.find(self.items, function(i) { return i.hudLine === 802 && i.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010; });
                    let line802_borrowerValue = line802.isBorrowerOutsideOverridden ? line802.overrideBorrowerOutside : line802.misc_Dollar;

                    let borrowerAtClosing = _.parseNumber(line801_borrowerValue, 0) + _.parseNumber(line802_borrowerValue, 0);
                    let itemIndex = _.findIndex(self.items, item => item.hudLine === 803 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                    if(itemIndex < 0) return;
                    self.items[itemIndex].borrowerAtClosing = borrowerAtClosing;
                }
            },
            sortItems(collection, sortBy) {
                const self = this;
                if (_.isNil(sortBy))
                    sortBy = self.isCdf ? 'cdfSection' : 'hudLine';

                return sortBy === 'cdfSection' ? _.sortBy(_.sortBy(collection, sortBy), 'line') : _.sortBy(collection, sortBy);
            },
            onChangeSSViewSelected(e) {
                const self = this;

                // Operations that might occur when you switch view context (CDF, HUD)
                self.items.length = 0; // reset
                let originalSectionsForSettlementType = _.filter(self.originalData, p => p.defaultedSettlementType === e || _.isNil(p.defaultedSettlementType));
                let allowCdfOptions = _.parseBool(e === SETTLEMENT_TYPE.CDF);
                let viewItems = _.filter(originalSectionsForSettlementType, function(r) {
                    return allowCdfOptions ? r.cdfSection === "A" : r.hudSection === 800;
                });

                let sortBy = (allowCdfOptions) ? 'cdfSection' : 'hudLine';
                self.items = self.sortItems(_.map(viewItems, result => new OriginationChargeModel(result)), sortBy); // origination charges related to the context of CDF or HUD
            },

            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onAddLoanCostLine(lineType, hudLineType) {
                const self = this;
                let newItem = new OriginationChargeModel( {lineType: lineType, hudLineType: _.parseNumber(hudLineType, HudLineTypes.General) });
                newItem.loanCostSectionDataID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.required = false;

                // We set hudLine values to push to server side versus cdf line (line) which is determined during mapping
                // let hudItems = _.filter(self.originalData, i => i.hudLine > 1100 && i.hudLine < 1200);
                // let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                // newItem.hudLine = _.parseNumber(maxHudLineItem.hudLine, 0) + 1;
                newItem.hudLine = self.getNextAvailableHudLine();

                // New lines entered within the UI application is considered common among all settlement statements so
                // set all the sections
                newItem.hudSection = 800;
                newItem.cdfSection = "A";

                // By setting the default to null we know that this is common among all settlement statements versus being specifically initialized to CDF / HUD
                // as they can be different based on defaulted lines
                newItem.defaultedSettlementType = null;

                let selectedLoan = _.find(self.loans, function(l) { return l.loanID == self.selectedLoanId; });
                newItem.payeeCompanyID = selectedLoan.lenderID;
                newItem.payeeCompanyName = selectedLoan.lender;
                newItem.isDefaulted = true;

                self.items.push(newItem);

                // Focus on description on newly added line
                let newDescriptionRefId = "#txt_orig_line_description_" + newItem.loanCostSectionDataID;
                this.$nextTick().then( () => {
                    // _.invoke(this, "$refs.originationSection.focusLastDescription");
                    $(newDescriptionRefId).focus();
                });
            },

            onDeleteItems() {
                const self = this;
                let confirmMessage = 'Are you sure you wish to delete selected charge(s)?';

                let ok = function() {
                    _.forEach(self.selectedItems, (item) => {
                        let index = self.items.indexOf(item);

                        if(item.loanCostSectionDataID > 0) {
                            // Track deleted items as these are persisted and need to be actually deleted on server side
                            self.deleteItems.push(item);
                        }

                        // Remove associated PBO line if it exists
                        if (self.isHud && item.paidByCompanyID > 0) {
                            let pboItemIndex =  _.findIndex(self.items, i => i.paidBySource === item.hudLine);
                            self.items.splice(pboItemIndex, 1);
                        }

                        // Keep all items synced when deleting
                        self.items.splice(index,1);
                    });

                    // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                    if (self.isHud) {
                        self.reorderHudItems();
                    }
                }
                self.$dialog.confirm('Confirm', confirmMessage, ok);
            },

            reorderHudItems() {
                const self = this;
                let hudItems = _.filter(self.items, i => i.hudSection === 800);
                if(hudItems.length === 0) return; // Nothing to reorder

                let minLine = _.minBy(hudItems, function(i) { return i.hudLine; });
                let maxLine = _.maxBy(hudItems, function(i) { return i.hudLine; });

                let lineNumber = _.parseNumber(minLine.hudLine, 0);
                _.forEach(_.sortBy(hudItems, h => h.hudLine), (hudItem) => {
                    if (hudItem.hudLine !== lineNumber) {
                        let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === hudItem.loanCostSectionDataID);
                        self.items[itemIndex].hudLine = lineNumber;
                        hudItem.hudLine = lineNumber;
                    }
                    lineNumber = lineNumber + 1;
                });
            },
            onRevertItems() {
                const self = this;

                // Hide any displayed tooltips after the Revert button is de-pressed
                this.$root.$emit('bv::hide::tooltip', 'btn_revert');

                _.forEach(self.selectedItems, (item) => {
                    if (item.isOverridableLine) {
                        item.revertOverrides();
                        item.isSelected = false;
                    }
                });
            },
            onChangeNetFundItems(e) {
                const self = this;
                if (!e) return;

                _.forEach(self.selectedItems, (item) => {
                    item.netFund = _.getNumber(e, "key", 0);
                    item.isSelected = false;
                });
            },
            onSetAddendumItem(e) {
                const self = this;
                if (!e) return;

                let lineItem = self.selectedItems[0];
                let isSetAddendum = !_.parseBool(lineItem.isAddendum); //if isAddendum is not true then we know we're setting an addendum line

                // When setting Addendum, since there can only be one addendum per section, wit's implied that the any
                // other lines (should just be one if any) that is an addendum line needs to be cleared from being one
                if (isSetAddendum) {
                    _.forEach(self.items, (item) => {
                        if (lineItem.loanCostSectionDataID === item.loanCostSectionDataID)
                            item.isAddendum = true;
                        else
                            item.isAddendum = false;
                    });
                }
                else // remove a specific addendum flag on a specific line
                    lineItem.isAddendum = !_.parseBool(lineItem.isAddendum);

                lineItem.isSelected = false;
            },
            onMoveSection(e) {
                const self = this;
                if (!e) return;
                let targetSection = _.get(e, "key");
                let selectedItems = self.selectedItems;
                let okHandler = function (args) {
                    _.forEach(selectedItems, item => {
                        self.updateMovedItem(item, targetSection);
                        self.movedItems.push(item);
                        self.removeItems(item.loanCostSectionDataID);
                    });
                    return true;
                }
                let cancelHandler = function (args) {
                    _.forEach(selectedItems, item => { item.isSelected = false; });
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Move",
                    `Are you sure you want to move the selected lines to Section ${targetSection}?`,
                    okHandler,
                    cancelHandler, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            updateMovedItem(item, targetSection) {
                item.cdfSection = targetSection;
                item.hudSection = 1100;
                item.hudLine = 1199;    //for correct sorting on the HUD Mapper
                item.lineType = LoanCostTypes.AdditionalTitleCharge;
                item.cdfLineID = null;
                item.line = null;
                item.hudLineID = null;
            },
            removeItems(key) {
                const self = this;

                let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                if (itemIndex >= 0)
                    self.items.splice(itemIndex, 1);
            },
            fetchData() {
                const self = this;
                self.deleteItems = [];
                self.movedItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);

                let getPromise = self.$api.LoanCostChargesApi.getRecordsByLoan(self.selectedLoanId);
                return self.$rqBusy.wait(getPromise).then(results => {

                    let originationCharges = _.filter(results, function(r) {
                        return r.defaultedSettlementType === self.selectedView || _.isNil(r.defaultedSettlementType);
                    });

                    let viewItems = _.filter(originationCharges, function(r) {
                        return self.isCdf ? r.cdfSection === "A" : r.hudSection === 800;
                    });

                    self.items = self.sortItems(_.map(viewItems, result => new OriginationChargeModel(result))); // origination charges related to the context of CDF or HUD
                    self.originalData = _.map(results, result => new OriginationChargeModel(result)); // all origination charges between CDF and HUD
                });
            },

            save(refreshData=true) {
                const self = this;
                if(!self.hasChanges()){
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let saveItems = self.items;
                if(self.movedItems.length > 0) saveItems.push.apply(saveItems, self.movedItems);

                let savePromise = self.$api.LoanCostChargesApi.saveDeleteRecords(saveItems, self.deleteItems);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Loan Charges Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Loan Charges");
                        console.error(err);
                    });
            },

            hasChanges(){
                const self = this;
                let isCdfView = self.isCdf;

                // Further filter out the original data for items we are viewing in CDF/HUD context
                let originalItems = _.filter(self.originalData, function(r) {
                    let isMatchingVersion = r.defaultedSettlementType === self.selectedView || _.isNil(r.defaultedSettlementType);
                    return (isCdfView) ? r.cdfSection === "A" : r.hudSection === 800 && isMatchingVersion;
                });

                //original data was empty and user added something
                if(originalItems.length === 0 && self.items.length > 0){
                    return true;
                }

                //if the arrays are different length, then there are changes.  Deletes have already been saved
                if(self.items.length !== originalItems.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = self.getAuditChanges(originalItems, self.items);
                return changes.length > 0;
            }
        }
    }
</script>
