import { mapState, mapGetters } from "vuex";
import { GlobalEventManager } from '@/app.events';
import { SETTLEMENT_TYPE, SettlementTypeOption } from '@settlement/models';
import { UserSecuritySettings } from "@/shared/models/models";

export default {
    data(){
        return {
            selectedLoanId: null,
            validationErrors: [],
            showErrorBanner: false,
            baseHandlersRegistered: false
        };
    },

    computed: {
        ...mapState({
            orderId: state => state.orders.orderId,
            orderSummary: state => state.orders.orderSummary,
            order: state => state.orders.order,
            loans: state => state.orders.loans || [],
            payoffs: state => state.settlement.payoffs,
            readOnly: state => state.isPageReadOnly,
            selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
            isWithOutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
            includeAltaValue: state => _.getBool(state, "orders.orderSummary.includeAlta"),
            cdfSections: state => state.orders.orderLookups.cdfSections,
            systemDefaults: state => state.system.systemDefaults,
            authSettings: state => state.authentication.session.settings
        }),
        ...mapGetters([
            "lookupHelpers",
            "lookupItems",
            "defaultLoanId"
        ]),
        isSysAdmin(){
            let settings = new UserSecuritySettings(this.authSettings);
            return settings.findValue("IsSysAdmin");
        },
        SettlementTypes() { return SETTLEMENT_TYPE; },
        includeAlta() { return this.isCdf && this.includeAltaValue; },
        isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
        isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
        isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
        isHud() { return this.isHud1974 || this.isHud2010; },
        hasErrors() { return !_.isEmpty(this.validationErrors); }
    },

    watch: {
        loans: {
            handler(newValue) {
                if(_.isEmpty(newValue)) return;

                let selectedId = _.parseNumber(this.selectedLoanId, 0);
                let defaultId = _.parseNumber(this.defaultLoanId, 0);

                if(selectedId === 0) {
                    if(defaultId > 0)
                        this.selectedLoanId = this.defaultLoanId;
                    return;
                }

                const idExists = id => _.some(newValue, l => l.loanID === id);

                if(selectedId === defaultId || idExists(selectedId)) return;

                this.selectedLoanId = idExists(defaultId)
                    ? defaultId
                    : newValue[0]?.loanID;
                //console.log("selectedLoanId assigned, before: %s, after: %s, default: %s", selectedId, this.selectedLoanId, defaultId);
            },
            immediate: true
        }
    },

    beforeUnmount () {
        if(!this.baseHandlersRegistered) return;
        GlobalEventManager.unregister(this);
    },

    methods:{
        baseInit(registerHandlers=true) {
            const self = this;
            if (self.loans.length > 0){
                self.selectedLoanId = _.gt(self.defaultLoanId, 0) ? self.defaultLoanId : self.loans[0].loanID;
            }
            if(!registerHandlers) return;
            GlobalEventManager.onSave(self, self.onSave);
            GlobalEventManager.onCancel(self, self.onCancel);
            self.baseHandlersRegistered = true;
        },
        async mapSettlementStatement(type=null, userInitiated=false, useLoanId=0) {
            const self = this;
            let ssType = _.isNumber(type) ? type : self.selectedView;
            let ssTypeName = SettlementTypeOption.displayValue(self.selectedView);
            let loanId = useLoanId > 0 ? useLoanId : self.selectedLoanId;
            if(!_.isNil(self.save)) {
                await self.save();
            }

            let mapPromise = self.$api.SettlementDashboardApi.mapSettlementData(ssType, loanId);
            try {
                await self.$rqBusy.wait(mapPromise);
                if (userInitiated) {
                    self.onMapSSComplete();
                    self.$toast.success(`${ssTypeName} updated`);
                }
                return { success: true };
            }
            catch(err) {
                self.$toast.error(`${ssTypeName} failed to update`);
                console.error(err);
                return { success: false };
            }
        },
        async mapSettlementStatementOverride(type=null, userInitiated=false, useLoanId=0) {
            const self = this;
            let ssType = _.isNumber(type) ? type : self.selectedView;
            let ssTypeName = SettlementTypeOption.displayValue(self.selectedView);
            let loanId = useLoanId > 0 ? useLoanId : self.selectedLoanId;
            if(!_.isNil(self.save)) {
                await self.save();
            }

            let mapPromise = self.$api.SettlementDashboardApi.mapSettlementDataOverride(ssType, loanId);
            try {
                await self.$rqBusy.wait(mapPromise);
                if (userInitiated) {
                    self.onMapSSComplete();
                    self.$toast.success(`${ssTypeName} updated`);
                }
                return { success: true };
            }
            catch(err) {
                self.$toast.error(`${ssTypeName} failed to update`);
                console.error(err);
                return { success: false };
            }
        },
        onMapSSComplete() {
            const self = this;
            _.invoke(self, "fetchData");
        },
        parseFixed(v, d) { return accounting.parse(accounting.toFixed(v, d)); },
        formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },
    }
}