<template>
    <div class="cust-company-contact-footer">
        <section class="left-footer-section"></section>
        <section class="right-footer-section">
            <b-button
                v-for="action in visibleActions"
                :key="action.name"
                :automation_id="action.automation_id"
                :variant="action.variant"
                class="ms-1"
                :disabled="action.disabled"
                @click="onActionClick(action)">
                <span>{{action.text}}</span>
            </b-button>
        </section>
    </div>
</template>
<script setup>
    import { computed, reactive, onMounted, toRaw } from "vue";
    import { useRqEvent, GlobalEventManager } from "@/app.events";

    const props = defineProps({
        setStateEvent: { type: String, default: "company-contact-footer:set-state" },
        actionEvent: { type: String, default: "company-contact-footer:action" },
        initialState: {
            type: Object,
            default: () => ({
                visible: ["cancel","assign-company"],
                disabled: ["assign-company"]
            })
        }
    });

    const actions = reactive([
        { name: "add-another", text: "Add Another", automation_id: "btn_co_lookup_add_another", variant: "secondary", visible: true, disabled: true },
        { name: "cancel", text: "Cancel", automation_id: "btn_co_lookup_cancel", variant: "secondary", visible: true, disabled: false },
        { name: "assign-company", text: "Assign Company", automation_id: "btn_co_lookup_assign_company", variant: "theme", visible: true, disabled: true },
        { name: "assign-contact", text: "Assign Contact", automation_id: "btn_co_lookup_assign_contact", variant: "theme", visible: false, disabled: true },
        { name: "save", text: "Save", automation_id: "btn_co_lookup_save", variant: "theme", visible: false, disabled: true }
    ]);

    const visibleActions = computed(() => _.filter(actions, "visible"));

    function onActionClick(action) {
        GlobalEventManager.emit(props.actionEvent, { action: toRaw(action) });
    }

    function setState(actionState) {
        _.each(actions, a => {
            a.visible = _.includes(actionState.visible, a.name);
            a.disabled = _.includes(actionState.disabled, a.name);
        });
    }

    const sourceKey = _.uniqueId("company-contact-footer-");
    useRqEvent(props.setStateEvent, setState, sourceKey);

    onMounted(() => {
        setState(props.initialState);
    })
</script>