<template>
    <div :class="{ 'content-wrapper debit-credit-section':true, 'debit-credit-modal': expandDetail }">
        <rq-section-card :title="title" :collapsible="!expandDetail" collaps-icon-left no-wrap-title>
            <template v-if="!expandDetail" #card-actions>
                <b-btn variant="link" class="btn-section-action text-nowrap" @click="openDetail">Add Detail</b-btn>
            </template>
            <div class="rq-table-container">
                <table :class="{ 'table rq-table': true, 'rq-table-show-border': expandDetail }">
                    <thead v-if="expandDetail">
                        <tr>
                            <td>No</td>
                            <td>Description</td>
                            <td v-if="expandDetail">Payee</td>
                            <td v-if="expandDetail">D/C</td>
                            <td>Amount</td>
                            <td v-if="expandDetail">POC Whom</td>
                            <td v-if="expandDetail">POC Amount</td>
                            <td v-if="expandDetail">Net Fund</td>
                            <td v-if="expandDetail">Acct. Code</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="line in localSectionData" :key="line.debitCreditSectionDataID">
                            <debit-credit-section-hud-line
                                :line-data="line"
                                :read-only="readOnly"
                                :expand-detail="expandDetail"
                            />
                            <tr
                                v-if="showExtraRow(line)"
                                class="extra-row">
                                <td :colspan="rowSpan">
                                    <label>{{getExtra(line)}}</label>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </rq-section-card>
        <div :class="classAttr" v-if="!expandDetail">
            <div class="row">
                <div class="col-9">
                    <label>Total</label>
                </div>
                <div class="col-3">
                    <label>{{sectionTotalResults}}</label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import DebitCreditSectionHudLine from "./DebitCreditSectionHudLine";
    import { SETTLEMENT_TYPE } from '@settlement/models';

    export default {
        components: {
            DebitCreditSectionHudLine
        },
        props: {
            title: { type: String, default: "" },
            sectionTotal: { type: Number, default: 0 },
            expandDetail: { type: Boolean, default: false },
            sectionData: { type: Array, default: () => [] }
        },
        data() {
            return {
                localSectionData: this.sectionData
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            readOnly(){
                const self = this;
                return (self.$store.state.isPageReadOnly || self.$store.state.orders.orderSummary.isLocked)
            },
            classAttr() {
                let theme = _.get(this, "$route.meta.theme", "default") || "default";
                return `section-total theme-${theme}`;
            },
            rowSpan() {
                return this.expandDetail ? "9" : "3";
            },
            sectionTotalResults() {
                return accounting.formatMoney(_.parseNumber(this.sectionTotal, 0));
            },
            selectedView() { return _.parseNumber(this.$store.state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.HUD_1974); },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
        },
        watch: {
            sectionData: {
                handler: function(newValue, oldValue) {
                    this.localSectionData = newValue;
                }
            }
        },
        created() {
        },
        methods: {
            openDetail(e){
                e.stopPropagation();
                this.$emit('open-detail', this.localSectionData[0].hudSection);
            },
            isListEqual(list1, list2) {
                return _.isEqual(_.sortBy(list1), _.sortBy(list2))
            },
            extraRowKey(id) {
                return `extra_row_${id}`;
            },
            showExtraRow(lineData) {
                return lineData.hudLine === 105
                    || lineData.hudLine === 209
                    || lineData.hudLine === 405
                    || lineData.hudLine === 509;
            },
            getExtra(lineData) {
                if(lineData.hudLine === 105) {
                    return "Adjustments for items paid by seller in advance";
                }

                if(lineData.hudLine === 209) {
                    return "Adjustments for items unpaid by seller";
                }

                if(lineData.hudLine === 405) {
                    return "Adjustments for items paid by seller in advance";
                }

                if(lineData.hudLine === 509) {
                    return "Adjustments for items unpaid by seller";
                }
            }
        }
    };
</script>

