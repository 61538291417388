<template>
    <div class="content-wrapper" id="cpl-grid">
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Closing Protection Letter"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            :rq-editable="!readOnly"
            @view-edit="onEdit"
            hide-settings
            hide-search>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";

export default {
    name: "CplPage",
    components: {},
    mixins: [GridSystemLookupMixin],
    data: function () {
        return {
            gridDataSource: [],
            gridConfig: {},
            items: [
                {
                    id: 1,
                    addresseeName: "Test",
                    transactionType: "Single Transaction",
                    referenceNumber: "9876",
                    issueDate: "04/14/2023",
                    fundsAmount: "3000",
                    status: "Ready",
                    covered: "",
                },
            ],
        }
    },
    computed: {
        ...mapState({
            orderId: state => state.orders.orderId,
            orderSummary: state => state.orders.orderSummary,
            readOnly: state => state.isPageReadOnly,
        }),
        itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
        gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
        selectionActions() {
            return [
                {
                    name: "void",
                    text: "Void"
                },
                {
                    name: "update",
                    text: "Update"
                },
                {
                    name: "manage cpl",
                    text: "Manage CPL"
                }
            ];
        },
    },
    created() {
        this.initGridConfig();
        this.fetchData();
    },
    methods: {
        elementName(prefix = "", suffix = "") {
            return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
        },
        initGridConfig() {
            const self = this;
            self.gridConfig = {
                columns: [
                    {
                        dataField: "addresseeName",
                        dataType: "string",
                        caption: "Addressee Name",
                    },
                    {
                        dataField: "transactionType",
                        dataType: "string",
                        caption: "Transaction Type",
                    },
                    {
                        dataField: "referenceNumber",
                        dataType: "string",
                        caption: "Reference No.",
                    },
                    {
                        dataField: "issueDate",
                        dataType: "date",
                        caption: "Issue Date",
                    },
                    {
                        dataField: "fundsAmount",
                        dataType: "number",
                        caption: "Funds Amount",
                        format: {
                            type: "currency",
                            precision: 2
                        },
                    },
                    {
                        dataField: "status",
                        dataType: "string",
                        caption: "Status",
                    },
                    {
                        dataField: "covered",
                        dataType: "string",
                        caption: "Covered", 
                    }
                ],
            };
            self.gridDataSource = {
                key: "id",
                load(loadOptions) {
                    return Promise.resolve(self.items);
                },
            };
        },
        fetchData() {

        },
        refresh() {
            if (_.isNull(this.gridInstance)) return;
            this.gridInstance.clearSelection();
            this.gridInstance.refresh();
        },
        onEdit() {

        },
    }
}
</script>