export class OrderSearchResultModel {
    constructor (options) {
        options = options || {};
        this.ordersID = options.ordersID || null;
        this.isGlobal = options.isGlobal || null;
        this.loanOrder = options.loanOrder || null;
        this.regionID = options.regionID || null;
        this.branchID = options.branchID || null;
        this.duration = options.duration || null;
        this.gfNo = options.gfNo || null;
        this.openDate = options.openDate || null;
        this.closeDate = options.closeDate || null;
        this.userDefinedDate1 = options.userDefinedDate1 || null;
        this.address1 = options.address1 || null;
        this.status = options.status || null;
        this.salesPrice = options.salesPrice || null;
        this.loanAmount = options.loanAmount || null;
        this.buyer = options.buyer || null;
        this.seller = options.seller || null;
        this.legalDescription = options.legalDescription || null;
        this.lender = options.lender || null;
        this.earnestMoney = options.earnestMoney || null;
        this.titleCompanyCompanyID = options.titleCompanyCompanyID || null;
        this.underwriterCompanyID = options.underwriterCompanyID || null;
        this.closeAgentStaffID = options.closeAgentStaffID || null;
        this.closeAgent = options.closeAgent || null;
        this.bankCompanyID = options.bankCompanyID || null;
        this.salesRepresentativeStaffID = options.salesRepresentativeStaffID || null;
        this.otherCompaniesFileNumber = options.otherCompaniesFileNumber || null;
        this.ownerPolicyNumber = options.ownerPolicyNumber || null;
        this.mortgagePolicyNumber = options.mortgagePolicyNumber || null;
        this.createdByUsersID = options.createdByUsersID || null;
        this.publishOrderToWeb = options.publishOrderToWeb || null;
        this.underwriter = options.underwriter || null;
        this.escrowBank = options.escrowBank || null;
        this.salesRep = options.salesRep || null;
        this.listingAgent = options.listingAgent || null;
        this.sellingAgent = options.sellingAgent || null;
        this.clickable = options.clickable || null;
        this.orderRowNumber = options.orderRowNumber || null;
        this.propertyTaxId = options.propertyTaxId || null;
    }
}

export class SearchFieldSet {
    constructor (options) {
        options = options || {};
        this.id = options.id || 0;
        this.label = options.label || '';
        this.isRequired = _.parseBool(options.isRequired);
        this.viewExpanded = true;

        const searchSubsets = options.subsets || [];
        this.subsets = searchSubsets.map(s => new SearchFieldSet(s));

        const searchFields = options.fields || [];
        this.fields = searchFields.map(f => new SearchField(f));
    }

    clearFields () {
        _.forEach(this.fields, f => {
            f.fieldValue = null;
            f.clearValidation();
        });
        _.forEach(this.subsets, ss => { ss.clearFields(); });
    }
    clearFieldsByFieldSet(fieldSetId) {
        _.forEach(_.filter(this.fields, t => t.fieldSetId === fieldSetId), f => {
            f.fieldValue = null;
            f.clearValidation();
        });
        _.forEach(_.filter(this.subsets, t => t.id === fieldSetId), ss => { ss.clearFields(); });
    }
    hideFields () {
        _.forEach(this.fields, f => { f.visible = false; });
        _.forEach(this.subsets, ss => { ss.hideFields(); });
    }

    resetVisibleToDefault () {
        _.forEach(this.fields, f => { f.visible = f.isDefault; });
        _.forEach(this.subsets, ss => { ss.resetVisibleToDefault(); });
    }

    get visibleSubsets () { return _.filter(this.subsets, s => s.visibleFields.length > 0); }

    get visibleFields () { return _.filter(this.fields, "visible"); }

    get defaultFields () { return _.filter(this.fields, "isDefault"); }

    get hiddenFields () { return _.filter(this.fields, f => !f.visible); }

    get changedFields () {  return _.filter(this.fields, "isDirty");}

    get fieldWithValues () {  return _.filter(this.fields, "hasValue"); }

    get allVisibleFields () { return _.concat([], this.visibleFields, _.flatMap(this.subsets, s => s.visibleFields)); }

    get allDefaultFields () { return _.concat([], this.defaultFields, _.flatMap(this.subsets, s => s.defaultFields)); }

    get allChangedFields () { return _.concat([], this.changedFields, _.flatMap(this.subsets, s => s.changedFields)); }

    get allFieldWithValues () { return _.concat([], this.fieldWithValues, _.flatMap(this.subsets, s => s.fieldWithValues));  }

    get hasRequiredFields () { return _.some(this.allVisibleFields, f => f.isRequired); }

    get isValid () { return _.every(this.fields, f => f.isValid) && _.every(this.subsets, s => s.isValid); }

    get invalidFields() {
        let subsetFields = _.flatMap(this.subsets, s => s.invalidFields);
        let fields = _.filter(this.fields, f => !f.isValid);
        return _.concat(subsetFields, fields);
    }

    get validationMessages () { return _.map(this.invalidFields, 'validationMessage'); }

    validate () {
        let allValid = true;
        _.forEach(this.fields, f => {
            let fieldIsValid = f.validate();
            if(allValid) allValid = fieldIsValid;
        });
        _.forEach(this.subsets, s => {
            let subsetIsValid = s.validate();
            if(allValid) allValid = subsetIsValid;
       });
       return allValid;
    }

    clearValidation() {
        _.forEach(this.fields, f => { f.clearValidation(); });
        _.forEach(this.subsets, s => { s.clearValidation(); });
    }

    getGroupFields (groupId) { return this.fields.filter(f => f.fieldGroupId === groupId); }

    getVisibleGroupFields (groupId) { return this.fields.filter(f => f.visible && f.fieldGroupId === groupId); }

    setFieldOptions (lookups) {
        if(!_.isEmpty(this.fields)){
            _.forEach(this.fields, function (f) {
                if (f.fieldType !== "select" || _.isEmpty(f.lookupKey)) return;
                f.fieldOptions = lookups[f.lookupKey];
            });
        }

        if (!_.isEmpty(this.subsets)) {
            _.forEach(this.subsets, function (ss) {
                ss.setFieldOptions(lookups);
            });
        }
    }

    updateFieldOptions (fieldName, fieldOptions) {
        let done = false;

        _.forEach(this.fields, function (f) {
            if (f.fieldName === fieldName) {
                f.fieldOptions = fieldOptions;
                done = true;
            }
            // returning false exits iteration
            return !done;
        });

        if (!done) {
            // returning false exits iteration
            _.forEach(this.subsets, function (ss) {
                done = ss.updateFieldOptions(fieldName, fieldOptions);
                // returning false exits iteration
                return !done;
            });
        }

        return done;
    }
}

export class SearchField {
    constructor (options) {
        options = options || {};
        this.fieldId = options.fieldId || null;
        this.fieldSetId = options.fieldSetId || null;
        this.fieldLabel = options.fieldLabel || '[MISSING FIELD LABEL]';
        this.fieldName = options.fieldName || '[MISSING FIELD NAME]';
        this.fieldType = options.fieldType || 'text';
        this.dataType = options.dataType || 'string';
        this.prefix = options.prefix || '';
        this.sequence = _.parseNumber(options.sequence, 0);
        this.decimals = _.parseNumber(options.decimals, 0);
        // this.decimals = options.decimals || 0;
        this.commas = _.parseBool(options.commas);
        this.isRange = _.parseBool(options.isRange);
        this.isRequired = _.parseBool(options.isRequired);
        this._fieldValue = options._fieldValue || null;
        this.originalValue = this._fieldValue || null;
        this.lookupKey = options.lookupKey || null;
        this.pickerDialogTitle = options.pickerDialogTitle || null;
        this.pickerRoleId = options.pickerRoleId || null;
        this.pickerRoleName = options.pickerRoleName || null;

        const fOpts = options.fieldOptions || [];
        this.fieldOptions = _.map(fOpts, fo => new SearchFieldOption(fo));

        this.visible = _.parseBool(options.visible);
        this.isDefault = _.parseBool(options.isDefault);
        this.disabled = _.parseBool(options.disabled);

        this.isValid = true;
        this.validationMessage = '';
        this.validated = false;
    }

    get fieldPrefix () { return this.getFieldPrefix(); }

    getFieldPrefix () {
        const prefixes = {
            select:"drp",
            checkbox:"chk",
            textarea:"ta",
            date:"dte_rng",
            default:"txt"
        };
        if(!_.hasIn(prefixes, this.fieldType)) return prefixes.default;
        return prefixes[this.fieldType];
    }

    get searchParameters () { return this.getSearchParameters(); }

    /*
        Super Search range parameter names are reversed, which is confusing when read:
            Range is from [FIELD_NAME]Greater to [FIELD_NAME]Less
        To make things less confusing in the duplicate order search implementation, we changed it to read better:
            Range is from [FIELD_NAME]Less to [FIELD_NAME]Greater

        So for Super Search "reverseRange = true", Dup. Order Search "reverseRange = false"
    */
    getSearchParameters (reverseRange=true) {
        const searchParams = [];
        if (this.fieldValue !== null) {
            if (this.isRange) {
                const values = this.fieldValue.split('|');
                let greaterIndex = reverseRange ? 0 : 1;
                let lessIndex = reverseRange ? 1 : 0;
                if (values[greaterIndex]) {searchParams.push({ key: `${this.fieldName}Greater`, value: values[greaterIndex] });}
                if (values[lessIndex]) {searchParams.push({ key: `${this.fieldName}Less`, value: values[lessIndex] });}
            } else {
                searchParams.push({ key: this.fieldName, value: this.fieldValue });
            }
        }
        return searchParams;
    }

    get fieldValue () {return this._fieldValue;}
    set fieldValue (v) { if (v !== this._fieldValue) this._fieldValue = v; }

    get isDirty () { return this._fieldValue !== this.originalValue; }

    get hasValue() {
        return (this.dataType === "string" && !_.isEmpty(this._fieldValue))
            || (this.dataType !== "string" && !_.isNil(this._fieldValue));
    }

    get automationId () { return `${this.getFieldPrefix()}_dynamic_${this.fieldName.toLowerCase()}`; }

    validate() {
        this.validated = true;
        this.isValid = true;

        if(!this.isRequired) return this.isValid;

        if (!this.hasValue) {
            this.validationMessage = `The field "${this.fieldLabel}" is required.`;
            this.isValid = false;
        }
        return this.isValid;
     }

    clearValidation() {
        this.isValid = true;
        this.validationError = "";
        this.validated = false;
    }
}

export class SearchFieldOption {
    constructor (options) {
        options = options || {};
        const sfoId = options.id || 0;
        this.id = isNaN(sfoId) ? sfoId : Number(sfoId);
        this.name = options.name || '[EMPTY]';
        this.data = options.data || null;
    }
}

export class SearchLookupsModel {
    constructor (options) {
        options = options || {};
        this.escrowOfficers = options.escrowOfficers || [];
        this.escrowProcessors = options.escrowProcessors || [];
        this.titleOfficers = options.titleOfficers || [];
        this.titleProcessors = options.titleProcessors || [];
        this.salesReps = options.salesReps || [];
        this.closingAgents = options.closingAgents || [];
        this.notaries = options.notaries || [];
        this.statuses = options.statuses || [];
        this.workflowProcessTemplates = options.workflowProcessTemplates || [];
        this.titleCompanies = options.titleCompanies || [];
        this.escrowUnits = options.escrowUnits || [];
        this.titleUnits = options.titleUnits || [];
        this.orderCategories = options.orderCategories || [];
        this.underwriters = options.underwriters || [];
        this.sourceOfBusinesses = options.sourceOfBusinesses || [];
        this.locations = options.locations || [];
        this.orderServices = options.orderServices || [];
        this.buyerSellerRelationshipTypes = options.buyerSellerRelationshipTypes || [];
        this.vestingInformationTypes = options.vestingInformationTypes || [];
        this.roles = options.roles || [];
        this.regions = options.regions || [];
        this.escrowAccounts = options.escrowAccounts || [];
        this.durations = options.durations || [];
        this.orderKinds = options.orderKinds || [];
        this.branches = options.branches || [];
        this.propertyTypes = options.propertyTypes || [];
        this.propertyLayouts = options.propertyLayouts || [];
        this.surveys = options.surveys || [];
        this.subdivisions = options.subdivisions || [];
        this.settlementAgents = options.settlementAgents || [];
        this.counties = options.counties || [];
        this.states = options.states || [];
    }
}