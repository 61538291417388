<template>
    <div :class="classAttr">
        <div class="row">
            <div class="col-6">
                <b-btn variant="icon" @click="escrowItem.sectionExpanded=!escrowItem.sectionExpanded">
                    <FontAwesomeIcon icon="fas fa-caret-right" :rotation="iconRotation" />
                    {{ escrowItem.description}}
                </b-btn>
            </div>
            <div class="col-6">
                <div class="input-group">
                    <span class="input-group-text">$</span>
                    <rqInputNumber
                        id="txt_monthly_sum"
                        automation_id="txt_monthly_sum"
                        type="text"
                        v-model="escrowItem.sum"
                        defaultValue="0"
                        decimals="2"
                        :disabled="disabled || escrowItem.disabled || readOnly"
                        v-focus-select-all
                        cssClass="form-control">
                    </rqInputNumber>
                </div>
            </div>
        </div>
        <div class="row p-1">
            <div class="col-6">&nbsp;</div>
            <div class="col-6 ps-5 mb-3">
                <b-collapse id="detail-collapse" class="card-collapse" :visible="escrowItem.sectionExpanded">
                    <table class="aal-table">
                        <tr class="aal-header-footer">
                            <th class="aal-due"></th>
                            <th class="aal-month">Month Due</th>
                            <th class="aal-amount">Amount</th>
                        </tr>
                        <agg-escrow-item-detail
                            v-for="eItemDetail in escrowItem.escrowItemDetails"
                            :key="eItemDetail.AggEscrowItemDetailID"
                            :escrowItemDetail="eItemDetail"
                            :disabled="disabled"
                            @docalculate="calculateOption"
                        />
                    </table>
                </b-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import AggEscrowItemDetail from './AggEscrowItemDetail';
export default {
    components: {
        AggEscrowItemDetail
    },
    props: {
        escrowItem: { type: Object },
        index: { type: Number, default: 0 },
        disabled: { type: Boolean, default: false }
    },
    computed: {
        classAttr() {
            let theme = _.get(this, "$route.meta.theme", "default") || "default";
            return `agg-escrow-item theme-${theme}`;
        },
        readOnly() {
            return this.$store.state.isPageReadOnly;
        },
        total() {
            return _.some(this.escrowItem.escrowItemDetails, "due") ? this.escrowItem.sum : 0;
        },
        iconRotation() {
            if(!this.escrowItem.sectionExpanded){return 0;}
            return 90;
        }
    },
    watch: {
        "escrowItem.sum"(newVal, oldVal) {
            if (newVal === oldVal) return;
            this.calculateOption();
        }
    },
    methods: {
        formatMoney(value){
            return accounting.formatMoney(value);
        },
        calculateOption() {
            let dueCount = _.reduce(this.escrowItem.escrowItemDetails, (c , a) => {return a.due ? c + 1 : c;}, 0);
            _.each(this.escrowItem.escrowItemDetails, a => {a.paymentAmountFromEscrow = a.due ? this.escrowItem.sum / dueCount : 0; });
            this.$emit('docalculate');
        }
    }
}
</script>

