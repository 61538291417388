<template>
    <div :class="{ 'content-wrapper debit-credit-section':true, 'debit-credit-modal': expandDetail }">
        <rq-section-card :title="title" :collapsible="!expandDetail" collaps-icon-left no-wrap-title>
            <template v-if="!expandDetail" #card-actions>
                <b-btn variant="link" class="btn-section-action text-nowrap" @click="openDetail">Add Detail</b-btn>
            </template>
            <div class="rq-table-container">
                <table :class="{ 'table rq-table': true, 'rq-table-show-border': expandDetail }">
                    <thead v-if="expandDetail">
                        <tr>
                            <td>No</td>
                            <td v-if="expandDetail && includeAlta">Category</td>
                            <td>Description</td>
                            <td v-if="expandDetail">Payee</td>
                            <td v-if="expandDetail">D/C</td>
                            <td>Amount</td>
                            <td v-if="expandDetail">Adjustment/Credit</td>
                            <td v-if="expandDetail">Net Fund</td>
                            <td v-if="expandDetail">Acct. Code</td>
                            <td v-if="expandDetail && includeAlta">Do Not Print</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="line in localSectionData" :key="line.debitCreditSectionDataID">
                            <DebitCreditSectionLine
                                :line-data="line"
                                :read-only="readOnly"
                                :expand-detail="expandDetail"
                                :isCdf="isCdf"
                            />
                            <tr v-if="showExtraRow(line)" class="extra-row">
                                <td :colspan="rowSpan">
                                    <label>{{getExtra(line)}}</label>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </rq-section-card>
        <div :class="classAttr" v-if="!expandDetail">
            <div class="row">
                <div class="col-9">
                    <label>Total</label>
                </div>
                <div class="col-3">
                    <label>{{sectionTotalResults}}</label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import DebitCreditSectionLine from "./DebitCreditSectionLine";
    import { CdfSection } from '../models';

    export default {
        name: "DebitCreditSection",
        components: { DebitCreditSectionLine },
        props: {
            title: { type: String, default: "" },
            sectionTotal: { type: Number, default: 0 },
            expandDetail: { type: Boolean, default: false },
            sectionData: { type: Array, default: () => [] },
            isCdf: { type: Boolean, default: false }
        },
        data() {
            return {
                localSectionData: this.sectionData
            };
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            classAttr() {
                let theme = _.get(this, "$route.meta.theme", "default") || "default";
                return `section-total theme-${theme}`;
            },
            rowSpan() {
                return this.expandDetail ? (this.includeAlta ? "10" : "8") : "3";
            },
            sectionTotalResults() {
                return accounting.formatMoney(_.parseNumber(this.sectionTotal, 0));
            },
            includeAlta() { return this.isCdf && _.parseBool(this.$store.state.orders.orderSummary.includeAlta); },
        },
        watch: {
            sectionData: {
                handler: function(newValue, oldValue) {
                    this.localSectionData = newValue;
                }
            }
        },
        methods: {
            openDetail(e){
                e.stopPropagation();
                this.$emit('open-detail', this.localSectionData[0].cdfSection);
            },
            isListEqual(list1, list2) {
                return _.isEqual(_.sortBy(list1), _.sortBy(list2))
            },
            extraRowKey(id) {
                return `extra_row_${id}`;
            },
            showExtraRow(lineData) {
                return (lineData.line === 4 && lineData.cdfSection === CdfSection.K)
                    || (lineData.line === 7 && lineData.cdfSection === CdfSection.K)
                    || (lineData.line === 8 && lineData.cdfSection === CdfSection.M)
                    || (lineData.line === 5 && lineData.cdfSection === CdfSection.L)
                    || (lineData.line === 7 && lineData.cdfSection === CdfSection.L)
                    || (lineData.line === 11 && lineData.cdfSection === CdfSection.L)
                    || (lineData.line === 13 && lineData.cdfSection === CdfSection.N);
            },
            getExtra(lineData) {
                if(lineData.line === 4 && lineData.cdfSection === CdfSection.K) {
                    return "Adjustments";
                }
                if(lineData.line === 7 && lineData.cdfSection === CdfSection.K) {
                    return "Adjustments for Items Paid by Seller in Advance";
                }

                if(lineData.line === 8 && lineData.cdfSection === CdfSection.M) {
                    return "Adjustments for Items Paid by Seller in Advance";
                }

                if(lineData.line === 5 && lineData.cdfSection === CdfSection.L) {
                    return "Other Credits";
                }
                if(lineData.line === 7 && lineData.cdfSection === CdfSection.L)
                {
                    return "Adjustments";
                }
                if(lineData.line === 11 && lineData.cdfSection === CdfSection.L)
                {
                    return "Adjustments for Items Unpaid by Seller";
                }

                if(lineData.line === 13 && lineData.cdfSection === CdfSection.N) {
                    return "Adjustments for Items Unpaid by Seller";
                }
            }
        }
    }
</script>

