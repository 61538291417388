<template>
    <rq-page-section :title="premium.description" headerSize="lg" borderless>
        <template #header-actions>
            <ul class="nav">
                <li v-rq-tooltip="{ title: 'Select a Premium Name', placement: 'top', triggers: 'hover', boundary: 'window', disabled: rateSelected }" class="nav-item">
                    <b-btn
                        automation_id="btn_view_detail"
                        variant="theme"
                        :disabled="readOnly || !rateSelected"
                        @click="onViewDetailClick">View Calculation Details
                    </b-btn>
                </li>
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_reset_calculator"
                        variant="theme"
                        :disabled="readOnly"
                        @click="onResetPremium">Reset
                    </b-btn>
                </li>
            </ul>
        </template>
        <rq-page-section title="Premium" collapsible>
            <div class="row flex-row mt-2">
                <div class="col-12 col-md-6 col-lg-9 col-pc-6 form-group">
                    <label :for="`drp_premium_name_${premium.orderRateCalculationID}`">Premium Name</label>
                    <span :id="`drp_premium_name_${premium.orderRateCalculationID}`">
                        <dx-select-box
                            ref="premiumSelectBox"
                            :input-attr="{ automation_id: 'drp_premium_name' }"
                            value-expr="id"
                            display-expr="name"
                            :items="premiumNames"
                            :disabled="premiumReadOnly || readOnly"
                            v-model="premium.rateID"
                            @value-changed="onRateSelectionChange"
                            @initialized="onRateSelectInitialized"
                        />
                    </span>
                    <b-tooltip
                        v-model:show="showSimulPremNameTooltip"
                        :target="`drp_premium_name_${premium.orderRateCalculationID}`"
                        title="Please select a rate for this simultaneous policy"
                        triggers=""
                        placement="top"
                        boundary="window"
                    />
                </div>

                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_policy_lib_override">{{premium.policyLiabilityOverrideLabel}}</label>
                    <rqInputNumber
                        automation_id="txt_policy_lib_override"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        prependIcon="fas fa-dollar-sign"
                        v-model="premium.policyLiabilityOverride"
                    />
                </div>
            </div>
            <div class="row" v-if="showPremiumCDFView">
                <div class="col-auto">
                    <button
                        type="button"
                        class="btn btn-link btn-theme btn-premium-view"
                        @click="onToggleView('premium_view')"
                        :class="{'active': !isCdfView}"
                    >
                        Premium View
                    </button>
                    <span class="link-divider"> | </span>
                    <button
                        type="button"
                        class="btn btn-link btn-theme btn-premium-view"
                        :class="{'active': isCdfView}"
                        @click="onToggleView('cdf_view')"
                    >
                        CDF View
                    </button>
                </div>
            </div>
            <premium-table
                ref="premiumTableComponent"
                :premium="premium"
                :cdf-premium="cdfPremium"
                :has-reissue-credit="hasReissueCredit"
                :has-endorsements="hasEndorsements"
                v-model:premium-total="premiumTotal"
                v-model:reissue-credit-total="reissueCreditTotal"
                :is-cdf-view="isCdfView"
                :is-cdf-simultaneous="isCdfSimultaneous"
                :read-only="readOnly"
                @reverted="onOverrideReverted"
                @changed="onCalculatedValuesChanged"
            />
        </rq-page-section>
        <rq-page-section title="Endorsements/Fees" :expanded="hasEndorsements" collapsible>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_endorsement" variant="theme" :disabled="readOnly" @click="openAddEndorsementDialog">Add </b-btn>
                    </li>
                </ul>
            </template>
            <endorsement-table
                v-show="hasEndorsements"
                ref="endorsementTableComponent"
                :premium="premium"
                :noTax="premium.noTax"
                :read-only="readOnly"
                @changed="onCalculatedValuesChanged"
                @reverted="onOverrideReverted"
                @delete="onDeleteEndorsement"
            />
        </rq-page-section>
        <total-premium :premium="premium" />
        <premium-comments
            :orderRateCalculationID="orderRateCalculationID"
            :readOnly="readOnly"
            expand
        />
    </rq-page-section>
</template>

<script>
    import { mapState } from "vuex";
    import { RATE_ACTIONS } from '@/store/actions';
    import { SimultaneousOptions } from "@settlement/models/OrderRateCalculation";
    import { PremiumEndorsementTotal, SettlementTypeOption } from "@settlement/models";
    import { OrderServiceType } from '@/shared/models/enums';

    import PremiumComments from "./PremiumComments";
    import PremiumDetail from "./PremiumDetail";
    import EndorsementSelection from "@settlement/components/shared/EndorsementSelection";
    import EndorsementTable from "./EndorsementTable";
    import PremiumTable from "./PremiumTable";
    import TotalPremium from "./TotalPremium";

    export default {
        props: {
            premium: {},
            cdfPremium: {},
            simultaneousOption: { default: 0 },
            isActive: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false }
        },
        components: {
            PremiumTable,
            PremiumComments,
            EndorsementTable,
            TotalPremium
        },
        data(){
            return {
                showSimulPremNameTooltip: false,
                isCdfView: false,
                premiumTotal: new PremiumEndorsementTotal(),
                reissueCreditTotal: new PremiumEndorsementTotal()
            };
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                currentFileNo: state => state.orders.orderSummary.fileNumber,
                storedEndorsements: state => state.rateEngine.endorsements,
                calculationDetails: state => state.rateEngine.calculationDetails,
                premiumNameCollections: state => state.rateEngine.premiumNameCollections,
                storedRateOrderInfo: state => state.rateEngine.rateOrderInfo,
                systemDefaults: state => state.system.systemDefaults,
                storedPremiums: state => state.rateEngine.premiums,
                settlementStatementType: state => state.orders.orderSummary.settlementStatementType
            }),
            hasReissueCredit() { return !this.premium.noRic; },
            hasEndorsements() {
                let premiumEndorsements = _.filter(this.storedEndorsements, e => e.orderRateCalculationID === this.orderRateCalculationID)
                return premiumEndorsements.length > 0;
            },
            hasCalculationDetail() { return _.some(this.calculationDetails, d => d.trackingId === this.orderRateCalculationID); },
            isCdfSimultaneous(){ return this.simultaneousOption === SimultaneousOptions.OwnerWithSimultLoan; },
            isCdf() { return this.settlementStatementType === SettlementTypeOption.CDF; },
            orderRateCalculationID() { return this.premium.orderRateCalculationID; },
            endorsementUnderwriterSplitTotal(){
                let endorsements = _.filter(this.storedEndorsements, item => item.orderRateCalculationID === this.orderRateCalculationID);
                let result = 0;
                _.each(endorsements, oe => {
                        result += oe.overrideUnderwriterAmount;
                    });
                return result;
            },
            underwriterSplitTotal(){
                return this.endorsementUnderwriterSplitTotal + this.premium.underwriterSplit;
            },
            rateSelected() { return _.parseNumber(this.premium.rateID, 0) > 0; },
            originalData() { return _.find(this.storedPremiums, p => p.orderRateCalculationID === this.orderRateCalculationID); },
            premiumReadOnly() {
                const self = this;
                let rateId = _.getNumber(self, "premium.rateID", 0);
                if(_.eq(rateId, 0)) return false;
                if(self.simultaneousOption === SimultaneousOptions.None) return false;
                if(self.simultaneousOption === SimultaneousOptions.LoanWithSimultOwner) {
                    if(self.premium.isLoanHolder && self.premium.simultaneousCalculation) return true;
                    return false;
                }
                if(self.simultaneousOption === SimultaneousOptions.OwnerWithSimultLoan) {
                    if(!self.premium.isLoanHolder && self.premium.simultaneousCalculation) return true;
                    return false;
                }
                return false;
            },
            // Only display CDF view for Owner w/Simultaneous Loan and when Loan is selected as simultaneous.
            showPremiumCDFView() {
                return this.isCdf && this.isCdfSimultaneous && (this.premium.isLoanHolder || this.premium.simultaneousCalculation);
            },
            premiumNames() {
                let rateCollection = _.find(this.premiumNameCollections, c => c.orderRateCalculationID === this.orderRateCalculationID);
                return _.isNil(rateCollection) ? [] : _.sortBy(_.mapLookup(rateCollection.premiumNames), "name");
            }
        },

        watch: {
            simultaneousOption() {
                this.isCdfView = false;
            }
        },

        methods:{
            onRateSelectInitialized(e) {
                if(!this.isActive) return;
                _.delay(() => { e.component.focus(); }, 500);
            },

            onViewDetailClick() {
                const self = this;
                if(self.hasCalculationDetail && self.premium.rateID === self.originalData.rateID) {
                    self.openCalculationDetailDialog();
                    return;
                }

                self.$emit("save", { callback: () => self.openCalculationDetailDialog() });
            },

            onOverrideReverted(e) {
                this.$emit("overrideChange", { overridden: false });
            },

            onCalculatedValuesChanged(e) {
                if (e && e.orderEndorsementID > 0) {
                    this.saveEndorsements();
                    return;
                }
                if(e && e.target.name==="isSelected"){
                    return
                }
                this.$emit("overrideChange", { overridden: true });
            },

            onRateSelectionChange(e) {
                this.showSimulPremNameTooltip = false;
                this.$emit("rateChange", e);
            },

            onResetPremium(e) {
                const self = this;
                let okHandler = () => {
                    let simultaneousOrderRateCalculationIDs = [];
                    if(_.includes(OrderServiceType.simultaneousEligibleTypes, self.storedRateOrderInfo.service)) {
                        _.each(self.storedPremiums, p => {
                            if (p.simultaneousCalculation)
                                simultaneousOrderRateCalculationIDs.push(p.orderRateCalculationID);
                        });
                    }

                    let storePromise = self.$store.dispatch(RATE_ACTIONS.RESET_RATE, {
                        orderRateCalculationID: self.orderRateCalculationID,
                        simultaneousOrderRateCalculationIDs
                    });

                    self.$rqBusy.wait(storePromise, { topLevel: true })
                        .then(() => {
                            self.$toast.success("Premium reset successful.");
                            self.$store.dispatch(RATE_ACTIONS.GET_RATE_ORDER_INFO);
                        })
                        .then(e => {
                            self.$emit("reset", e);
                        })
                        .catch(error => error)
                };
                self.$dialog.confirm(
                    "Reset Premium",
                    "Are you sure you want to reset this premium calculation?  All endorsements, fees, and reissue credit information will be removed in the process. (where applicable)",
                    okHandler
                );
            },

            addEndorsements(endorsementIds) {
                const self = this;
                let data = {
                    orderRateCalculationId:self.orderRateCalculationID,
                    endorsementIds
                };
                let storePromise = self.$store.dispatch(RATE_ACTIONS.ADD_ORDER_ENDORSEMENTS, data);
                return self.$rqBusy.wait(storePromise, { topLevel: true })
                    .then((result) => {
                        self.refreshEndorsementTableScrollbar();
                        return true;
                    })
                    .catch((error) => {
                        console.error(error);
                        self.$toast.error("Endorsement creation failed.");
                    });
            },

            saveEndorsements(showLoading = true) {
                const self = this;
                let changedEndorsements = self.getChangedEndorsements();
                if(changedEndorsements.length === 0) return Promise.resolve(true);
                return self.saveChangedEndorsements({ endorsements: changedEndorsements, showLoading });
            },

            saveChangedEndorsements({ endorsements, showLoading = true }) {
                const self = this;

                if(endorsements.length === 0) return Promise.resolve(true);

                let storePromise = self.$store.dispatch(RATE_ACTIONS.UPDATE_ORDER_ENDORSEMENTS, endorsements);

                let resultPromise = showLoading
                    ? self.$rqBusy.wait(storePromise, { topLevel: true })
                    : storePromise;

                return resultPromise
                    .then(() => true)
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Endorsement update failed.");
                    });
            },

            getChangedEndorsements() {
                let endTableComponent = this.getEndorsementTableComponent();
                if(_.isNil(endTableComponent)) return [];
                return endTableComponent.getChangedEndorsements();
            },

            hasEndorsementChanges() {
                const self = this;
                let changedEndorsements = self.getChangedEndorsements();
                return _.gt(changedEndorsements.length, 0);
            },

            openAddEndorsementDialog(){
                const self = this;

                return new Promise((resolve, reject) => {

                    function processAddingEndorsements(newEndorsementIds) {
                        self.saveEndorsements(false)
                            .then(() => {
                                return self.addEndorsements(newEndorsementIds);
                            })
                            .then(() => {
                                self.$emit("calculate");
                                return true;
                            })
                            .catch(reject);
                    }

                    self.$dialog.open({
                        title: "Add Endorsements/Fees",
                        height: "95%",
                        width: "95%",
                        minHeight: 600,
                        minWidth: 1200,
                        scrollable: false,
                        component: EndorsementSelection,
                        props: {
                            orderId: self.orderId || 0,
                            loanId: self.premium.loanID || 0
                        },
                        onOk (e) {
                            return processAddingEndorsements(e.component.selectedEndorsementIds);
                        },
                        onCancel () {
                            resolve(false);
                            return true;
                        },
                        onComponentAction (e) {
                            let action = e.originalEvent;
                            if (action.name === "selection" && action.data) {
                                return processAddingEndorsements([action.data.endorsementID]);
                            }
                            return false;
                        }
                    });

                });
            },

            openCalculationDetailDialog() {
                const self = this;
                self.$dialog.open({
                    title: "Calculation Details",
                    height: "85%",
                    width: "85%",
                    component: PremiumDetail,
                    props: { orderRateCalculationID: self.orderRateCalculationID },
                    okOnly: true,
                    okTitle: "Close"
                });
            },
            reset() {
                let endTableComponent = this.getEndorsementTableComponent();
                if(_.isEmpty(this.storedEndorsements) || _.isNil(endTableComponent)) return;
                endTableComponent.reset();
            },
            focus() {
                _.invoke(this, "$refs.premiumSelectBox.instance.focus");
            },
            onToggleView(e) {
                if(this.isCdfView && e === 'cdf_view' || !this.isCdfView && e === 'premium_view') return;
                this.isCdfView = e === 'cdf_view';
            },
            onDeleteEndorsement(ids) {
                const self = this;
                self.saveEndorsements(false)
                    .then(() => {
                        return self.deleteEndorsements(ids);
                    })
                    .then((result) => {
                        if(result === true) self.$emit("save");
                    });
            },
            deleteEndorsements(ids) {
                const self = this;
                return new Promise((resolve, reject) => {
                    let okHandler = function (e) {
                        let storePromise = self.$store.dispatch(RATE_ACTIONS.DELETE_ORDER_ENDORSEMENT, ids);
                        self.$rqBusy.wait(storePromise, { topLevel: true })
                            .then(() => {
                                resolve(true)
                            })
                            .catch((error) => {
                                console.error(error);
                                self.$toast.error("Endorsement(s) deletion failed.");
                                reject(error);
                            });
                        return true;
                    };
                    let cancelHandler = function(e) {
                        resolve(false);
                        return true;
                    };
                    self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete selected endorsement(s)?", okHandler, cancelHandler);
                });
            },
            getEndorsementTableComponent() {
                return _.get(this, "$refs.endorsementTableComponent", null);
            },
            refreshScrollbars() {
                this.refreshPremiumTableScrollbar();
                this.refreshEndorsementTableScrollbar();
            },
            refreshPremiumTableScrollbar() {
                _.invoke(this, "$refs.premiumTableComponent.refreshScrollbar");
            },
            refreshEndorsementTableScrollbar() {
                if(!this.hasEndorsements) return;
                _.invoke(this, "$refs.endorsementTableComponent.refreshScrollbar");
            },
        }
    };
</script>
