<template>
    <div class="content-wrapper">
        <rq-page-section title="Loan Calculations" header-size="lg">
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="form-group col col-12 col-md-lg col-xl-3">
                    <label>Total Of Payments</label>
                    <rqInputNumber id="txt_total_payments"
                        automation_id="txt_total_payments"
                        ref="totalPaymentsInput"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedLoanCalculation.totalPaymentAmount"
                        :disabled="readOnly"/>
                </div>
                <div class="form-group col col-12 col-md-lg col-xl-3">
                    <label>Annual Percentage Rate (APR)</label>
                    <rqInputNumber id="txt_apr"
                        automation_id="txt_apr"
                        v-model="selectedLoanCalculation.annualPercentageRate"
                        defaultValue="0"
                        decimals="3"
                        minValue="0"
                        maxValue="100"
                        input-group
                        v-focus-select-all
                        appendText="%"
                        :disabled="readOnly"
                    />
                </div>
            </div>
            <div class="row">
                <div class="form-group col col-12 col-md-lg col-xl-3">
                    <label>Finance Charge</label>
                    <rqInputNumber id="txt_finance_charge"
                        automation_id="txt_finance_charge"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedLoanCalculation.financeChargeAmount"
                        :disabled="readOnly"/>
                </div>
                <div class="form-group col col-12 col-md-lg col-xl-3">
                    <label>Total Interest Percentage (TIP)</label>
                    <rqInputNumber id="txt_tip"
                        automation_id="txt_tip"
                        v-model="selectedLoanCalculation.totalInterestPercentage"
                        defaultValue="0"
                        decimals="3"
                        minValue="0"
                        input-group
                        v-focus-select-all
                        appendText="%"
                        :disabled="readOnly"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-md-lg col-xl-3">
                    <rq-action-form-group
                        label="Amount Financed"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="isFinancedAmountOverridden"
                        @action-click="onRevertFinancedAmountOverride">
                        <rqInputNumber id="txt_finance_amt"
                            automation_id="txt_finance_amt"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanCalculation.financedAmountValue"
                            :disabled="readOnly"
                        />
                    </rq-action-form-group>
                </div>
            </div>
        </rq-page-section>

        <rq-page-section title="Other Disclosures" header-size="lg" borderless>
            <div class="row">
                <div class="col-12 col-lg-6 form-group label-lg">
                    <div class="rq-content-description font-italic font-lg mb-2">Liability after Foreclosure</div>
                    <label class="no-truncate">If your lender forecloses on this property and the foreclosure does not cover the amount of unpaid balance on this loan</label>
                    <rq-radio-group
                        name="rdo_has_demand"
                        class="check-radios"
                        :options="[
                            { automation_id: 'chk_protect_foreclosure', value: true, text: 'state law may protect you from liability for the unpaid balance.' },
                            { automation_id: 'chk_no_protect_foreclosure', value: false, text: 'state law does not protect you from liability for the unpaid balance.' }
                        ]"
                        :disabled="readOnly"
                        v-model="isLiabilityAfterForeclosure"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { LoanCalculationModel } from '@settlement/models';
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {

        name: 'LoanCalculation',
        mixins: [BaseSettlementMixin],
        data(){
            return{
                selectedLoanCalculation: {},
                originalLoanCalculation: null,
            }
        },
        computed:{
            isLiabilityAfterForeclosure:{
                get(){ return _.getBool(this, "selectedLoanCalculation.isLiabilityAfterForeclosure1"); },
                set(val){
                    if(!this.selectedLoanCalculation) return;
                    this.selectedLoanCalculation.isLiabilityAfterForeclosure1 = val;
                    this.selectedLoanCalculation.isLiabilityAfterForeclosure2 = !val;
                }
            },
            isFinancedAmountOverridden() {
                return !_.isEmpty(this.selectedLoanCalculation)
                    && this.selectedLoanCalculation.financedAmountValue != this.selectedLoanCalculation.financedAmount;
            }
        },
        watch:{
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (oldValue === newValue || _.parseNumber(oldValue, 0) === 0) return;
                self.save().then(()=>{
                    self.fetchData();
                });
            },
        },
        mounted() {
            this.resetFocus();
        },
        created(){
            this.baseInit();
            this.fetchData();
        },
        methods:{

            selectedLoan(loanId){
                const self = this;
                let selected = _.find(self.loans, l => l.loanID === loanId);
                return selected;
            },

            onSave(e){
                let userInitiated = _.get(e, "userInitiated", false);
                this.save(userInitiated);
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            save(toastIfNoChanges=false){
                const self = this;
                if(_.isNil(self.originalLoanCalculation)) return Promise.resolve(false);
                if(!self.hasChanges()){
                    if(toastIfNoChanges) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let promise = self.$api.LoanCalculationApi.update(self.selectedLoanCalculation);
                return self.$rqBusy.wait(promise)
                    .then(()=>{
                        self.$toast.success("Loan Calculation Saved.");
                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    }).catch(err =>{
                        self.$toast.error("Loan Calculation failed to save.");
                        console.error(err);
                        GlobalEventManager.saveCompleted({success: false});
                        return false;
                    });
            },

            fetchData(){
                const self = this;
                let promise = self.$api.LoanCalculationApi.getByLoanId(self.selectedLoanId);
                self.$rqBusy.wait(promise)
                    .then(result => {
                        self.selectedLoanCalculation = new LoanCalculationModel(result);
                        self.originalLoanCalculation = new LoanCalculationModel(result);
                    })
                    .catch(err =>{
                        self.$toast.error(err.errorMessage);
                    });
            },

            hasChanges() {
                const self = this;
                let changes = self.getAuditChanges(self.selectedLoanCalculation, self.originalLoanCalculation);
                return changes.length > 0;
            },

            onRevertFinancedAmountOverride(e){
                if(_.isEmpty(this.selectedLoanCalculation)) return;
                this.selectedLoanCalculation.financedOverrideAmount = null;
            },

            resetFocus() {
                if (this.loans.length === 1)
                    this.$refs.totalPaymentsInput.focus();
            }
        }
    }
</script>