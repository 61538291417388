
let oeBeforeRouteEnter = function (to, from, next) {

    //console.log("/order-entry beforeRouteEnter: ", {to, from, next});

    if(!_.isEmpty(to.params.ext) || !_.isEmpty(to.query.ext)){
        let routeTo = _.pick(to, ["name", "params", "query", "hash"]);
        to.params.ext = _.isNil(to.params.ext) ? to.query.ext || 'n' : to.params.ext;
        next(routeTo);
    }
    else
        next();
};

export default oeBeforeRouteEnter;