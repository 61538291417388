
<template>
    <div class="content-wrapper premiums">
        <rq-banner
            v-for="(alert,index) in activeAlerts"
            :key="index"
            :message="alert.message"
            :variant="alert.severity === 1 ? 'warn' : 'error'"
            icon="fas fa-exclamation-triangle"
            :visible="showAlerts"
            dismissable
            sticky
        />
        <rq-page-section :title="noTitle ? '' : 'Premium Rates'" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_manage_reissue_credit"
                            variant="theme"
                            :class="{ 'ms-0': noTitle }"
                            :disabled="readOnly"
                            @click="openReissueCreditDialog">Manage Reissue Credits
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_manage_outside_parties"
                            variant="theme"
                            :disabled="readOnly"
                            @click="openOutsidePartiesDialog">Manage Outside Parties
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <rq-report-button
                            name="Premium Report"
                            text="Print Report"
                            :disabled="readOnly"
                            path="System Reports/File Specific/Premium Report"
                            :parameters="{ p_OrdersID: orderId }"
                        />
                    </li>
                </ul>
                <ul v-if="showHeaderCalculate" class="nav">
                    <li class="nav-item ms-auto">
                        <b-btn
                            automation_id="btn_calculate"
                            variant="dark"
                            :disabled="readOnly"
                            hover="Calculate Premium"
                            @click="save(true)">Calculate
                        </b-btn>
                    </li>
                </ul>
            </template>
            <modify-file-info
                ref="modifyFileInfo"
                :premiums="premiums"
                v-model:simultaneous-option="selectedSimultaneousOption"
                :read-only="noData || readOnly"
                @simultaneous-loan-change="onSimultaneousLoansChange"
                @simultaneous-change="onSimultaneousChange"
                @changed="onFileInfoChange"
            />
        </rq-page-section>
        <RqTabs
            :tabs="tabItems"
            v-model="currentTabIndex"
            @before-activate-tab="onBeforeActivateTab"
            @activate-tab="onActivateTab"
            @changed="onTabsChanged"
            scrollable>
            <template
                v-for="(rate, index) in premiums"
                :key="`${rate.clientKey}_tab`"
                #[`premium-tab-${index}`]>
                <premium-calculator
                    :ref="`premiumCalculator${rate.orderRateCalculationID}`"
                    :id="rate.clientKey"
                    :key="rate.clientKey"
                    :premium="rate"
                    :cdf-premium="cdfPremiums[index]"
                    :simultaneous-option="selectedSimultaneousOption"
                    :read-only="readOnly"
                    :is-active="currentTabIndex === index"
                    @overrideChange="onOverrideChange"
                    @rateChange="onRateSelectionChange"
                    @reload="onReloadRateInfo"
                    @calculate="onRateInitiatedCalc"
                    @save="onRateInitiatedSave"
                    @reset="onResetRate"
                />
            </template>
            <template #summary>
                <premiums-header
                    :premiums="premiums"
                    :agentTotal="agentTotal"
                    :underwriterTotal="underwriterTotal"
                    :outsidePartyTotal="outsidePartyTotal"
                />
                <premium-summary
                    ref="summary"
                    :premiums="premiums"
                    :cdfPremiums="cdfPremiums"
                    @setSummary="setSummary"
                />
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { ORDER_ACTIONS, RATE_ACTIONS } from "@/store/actions";
    import { RATE_MUTATIONS } from "@/store/mutations";
    import { OrderServiceType, AlertSeverity, LoanHolderType } from "@/shared/models/enums";
    import { RateEngineAlert, RateOrderInfoDto, SETTLEMENT_TYPE, DebitCreditOverrideStatus } from "@settlement/models";
    import OrderRateCalculation, { SimultaneousOptions } from "../../models/OrderRateCalculation";

    import ModifyFileInfo from "./components/ModifyFileInfo";
    import PremiumsHeader from "./components/PremiumsHeader";
    import PremiumCalculator from "./components/PremiumCalculator";
    import PremiumSummary from "./components/PremiumSummary";
    import OutsidePartyAllocations from "./components/dialogs/OutsidePartyAllocations";
    import ReissueCreditManager from "./components/dialogs/ReissueCreditManager";
    import { BreakoutEndorsementOption } from '../../models';

    export default {
        name: "PremiumsMain",
        props: {
            showHeaderCalculate: { type: Boolean, default: false },
            noTitle: { type: Boolean, default: false }
        },
        components: {
            PremiumsHeader,
            PremiumCalculator,
            PremiumSummary,
            ModifyFileInfo
        },
        data(){
            return {
                premiums: [],
                cdfPremiums: [],
                rateOrderInfo: new RateOrderInfoDto(),
                selectedSimultaneousOption: SimultaneousOptions.OwnerWithSimultLoan,
                validationMessage: "",
                currentTabIndex: 0,
                showAlerts: true,
                overrideStatus: [],
                summary: {}
            };
        },

        computed: {
            ...mapState({
                storedOrderId: state => state.orders.orderId,
                isSettlementLocked: state => state.orders.orderSummary.isSettlementLocked,
                currentStoreState: state => state.rateEngine,
                alerts: state => _.map(state.rateEngine.alerts, a => new RateEngineAlert(a)),
                loans: state => state.rateEngine.loans,
                storedRateOrderInfo: state => state.rateEngine.rateOrderInfo,
                storedPremiums: state => state.rateEngine.premiums,
                storedCdfPremiums: state => state.rateEngine.cdfPremiums,
                selectedView: state => _.parseNumber(state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF),
                systemDefaults: state => state.system.systemDefaults
            }),
            ...mapGetters(["premiumNames"]),
            activeAlerts() {
                if(_.isNil(this.currentPremium) || _.isEmpty(this.alerts)) return [];
                return _.filter(this.alerts.slice(), a => _.parseNumber(a.orderRateCalculationID, 0) === 0 || a.orderRateCalculationID === this.currentPremium.orderRateCalculationID);
            },
            currentAlertInfo() { return this.$store.getters.alertInfo; },
            currentPremium() { return this.premiums[this.currentTabIndex]; },
            noData() { return this.premiums.length === 0; },
            agentTotal() { return this.summary.totalPremium - this.summary.underwriterSplitTotal },
            agentPremiumCalculated() { return this.rateOrderInfo.agentPremiumCalculated; },
            underwriterTotal() { return this.summary.underwriterSplitTotal },
            outsidePartyTotal() { return _.reduce(this.currentStoreState.outsideParties, (total,op) => { return total + _.parseNumber(op.orderOutsidePartyToPayTotal, 0); }, 0); },
            endorsementUnderwriterSplitTotal(){
                let Endorsements = this.currentStoreState.endorsements;
                let result = 0;
                _.each(Endorsements, oe => {
                        result += oe.overrideUnderwriterAmount;
                    });
                return result;
            },
            underwriterSplitTotal(){
                let premiums = _.reduce(this.premiums, (total, item) => (_.parseNumber(item.underwriterSplit, 0) + total), 0);
                return accounting.formatMoney(this.endorsementUnderwriterSplitTotal + premiums)
            },
            hasErrorAlert() { return _.some(this.alerts, a => a.severity === AlertSeverity.Error); },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            isCreateAutoDebitCredit() { return _.parseBool(this.systemDefaults.isCreateAutoDebitCreditOnCDFForSellerPaidOTP); },
            readOnly() { return this.securitySettings.isReadOnly("Premiums") || this.$store.state.isPageReadOnly; },
            tabItems() {
                let rateTabs = _.map(this.premiums, (p, index) => {
                    let titleIcon = p.simultaneousCalculation ? "<svg class='rq-icon-symbol ms-1'><use href='#rq-fas-asterisk'></use></svg>" : "";
                    return {
                        automation_id: `btn_premium_rate_tab_${index}`,
                        title: `${p.description}${titleIcon}`,
                        name: `premium-tab-${index}`,
                        tooltip: p.simultaneousCalculation ? "Simultaneous Calculation" : "",
                        alertCount: p.alerts.length,
                        alertVariant: p.alerts?.[0]?.rqVariant || "",
                        alertMessages: _.map(p.alerts, "message")
                    };
                });
                return [
                    ...rateTabs,
                    { title: "Summary" }
                ]
            },
            calculators() {
                return _.map(this.premiums, p => _.get(this, `$refs.premiumCalculator${p.orderRateCalculationID}`));
            },
            routeOrderId() { return _.getNumber(this, "$route.params.orderId", 0); },
            orderId() { return this.routeOrderId > 0 ? this.routeOrderId : this.storedOrderId; },
        },

        watch: {
            storedRateOrderInfo(newValue, oldValue) {
                if(_.isNil(newValue) || newValue === oldValue) return;
                this.rateOrderInfo = new RateOrderInfoDto(newValue);
            },
            storedPremiums(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.loadPremiums();
            },
            orderId(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.fetchData(true);
            }
        },

        created() {
            const self = this;
            if (!self.readOnly) {
                GlobalEventManager.onSave(self, self.onSave);
                GlobalEventManager.onCancel(self, self.onCancelClick);
                // GlobalEventManager.onFireAction(self, self.handleAction);
            }
            else {
                GlobalEventManager.onSave(self, () => {
                    GlobalEventManager.saveCompleted({ success: true });
                });
            }
            self.fetchData();
            window.addEventListener("resize", self.onWindowResize);
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
            window.removeEventListener("resize", self.onWindowResize);
        },

        methods:{
            async fetchData(resetTabIndex=false) {
                const self = this;
                try {
                    let ratePromises = [
                        self.$store.dispatch(RATE_ACTIONS.GET_RATE_ORDER_INFO),
                        self.$store.dispatch(RATE_ACTIONS.GET_RATES)
                    ];

                    let result = await self.$rqBusy.wait(Promise.all(ratePromises));
                    if(resetTabIndex) self.currentTabIndex = 0;
                    return result;
                }
                catch(error) {
                    self.$toast.error("Premium retrieval request failed.");
                    console.error(error);
                    return error;
                }
            },

            fetchDebitCreditData() {
                const self = this;
                return self.$rqBusy.wait(self.$api.DebitCreditsApi.getOverrideStatus(self.orderId), {topLevel: true})
                    .then(result => {
                        self.overrideStatus = _.map(result, r => new DebitCreditOverrideStatus(r));
                        _.each(self.premiums, p => {
                            let status = p.isLoanHolder ? self.overrideStatus[0] : _.find(self.overrideStatus, o => o.loanID === p.loanID);
                            p.isOverrideCDFAdjustment = !status.isCdfInUse;
                            p.isOverrideHUDAdjustment = p.isLoanHolder ? !status.isHudOwnerInUse : !status.isHudLenderInUse;
                         });
                    });
            },

            loadPremiums(persistChanges=false){
                const self = this;

                if(_.isEmpty(self.storedPremiums)) {
                    self.premiums = [];
                    self.cdfPremiums = [];
                    return;
                }

                let premiums = [];
                let cdfPremiums = [];

                let ownerOnly = _.includes(OrderServiceType.ownerSpecificTypes, this.rateOrderInfo.service);
                let loanOnly = _.includes(OrderServiceType.loanSpecificTypes, this.rateOrderInfo.service);

                _.each(self.storedPremiums, p => {
                    if((p.loanHolder === LoanHolderType.Owner && loanOnly) || (p.loanHolder === LoanHolderType.Loan && ownerOnly)) return;
                    let premium = persistChanges ? _.find(self.premiums, cp => cp.orderRateCalculationID === p.orderRateCalculationID) : null;
                    let cdfPremium = persistChanges ? _.find(self.cdfPremiums, cp => cp.cdfOrderRateCalculationID === p.orderRateCalculationID) : null;

                    if(_.isNil(premium)) {
                        premium = new OrderRateCalculation(p);
                        premium.premiumName = self.getPremiumName(premium.rateID, premium.orderRateCalculationID);
                        if(!premium.isLoanHolder) {
                            let rateLoan = _.find(self.loans, l => l.loanID === premium.loanID);
                            premium.lenderName = _.get(rateLoan, "lender", "");
                            premium.description = _.isEmpty(premium.lenderName) ? "Loan" : `Loan (${premium.lenderName})`;
                        }
                    }

                    if(_.isNil(cdfPremium)) {
                        cdfPremium = new OrderRateCalculation(_.find(self.storedCdfPremiums, c => c.cdfOrderRateCalculationID === p.orderRateCalculationID));
                        cdfPremium.simultaneousCalculation = premium.simultaneousCalculation;
                        cdfPremium.premiumName = premium.premiumName;
                        cdfPremium.isCdfPremium = true;
                    }

                    premiums.push(premium);
                    cdfPremiums.push(cdfPremium);
                });

                self.premiums = premiums;
                self.cdfPremiums = cdfPremiums;

                if(!persistChanges) self.mapAlerts();
                self.checkSimultaneousSelections();
            },

            mapAlerts() {
                const self = this;
                _.each(self.premiums, (p,i) => {
                    p.alerts = _.filter(self.alerts, a => a.orderRateCalculationID === p.orderRateCalculationID);
                });
            },

            getPremiumName(rateId, calcId) {
                let defaultValue = "[Select a premium name]";
                let rateIdNum = _.parseNumber(rateId, 0);
                let calcIdNum = _.parseNumber(calcId, 0);

                if(rateIdNum === 0) return defaultValue;

                let nameCollections = this.currentStoreState.premiumNameCollections;
                let nameCollection = _.find(nameCollections, c => Number(c.orderRateCalculationID) === calcIdNum);
                let selectedPremium = _.find(nameCollection.premiumNames, pn => Number(pn.id) === rateIdNum);

                let premiumName = _.get(selectedPremium, "name", null);

                if(_.isNil(premiumName)) return defaultValue;

                return premiumName;
            },

            onSave({ userInitiated=false }={ userInitiated: false }) {
                this.save(userInitiated)
            },

            async onRateInitiatedCalc({ callback=()=>{} }={ callback: ()=>{} }) {
                let success = await this.$rqBusy.wait(this.calculate(true));
                if(!success || !_.isFunction(callback)) return;
                callback();
            },

            async onRateInitiatedSave({ callback=()=>{} }={ callback: ()=>{} }) {
                let success = await this.save(true);
                if(!success || !_.isFunction(callback)) return;
                callback();
            },

            onCancelClick(e) {
                const self = this;
                let currentChanges = self.getCurrentChanges();
                let hasEndorsementChanges = self.hasEndorsementChanges();
                if(currentChanges.length === 0 && !hasEndorsementChanges) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                let okHandler = () => { self.reset(); };
                self.$dialog.confirm("Cancel Changes", "Are you sure you want to cancel and undo the current rate calculation changes?", okHandler);
            },

            handleSimultaneousChange() {
                const self = this;
                let newIndex = 0;
                self.setSimultaneousCalculation();
                self.refreshRecalculateAlert();
                self.removeAlertsByTarget("RateID");

                if(self.selectedSimultaneousOption === SimultaneousOptions.LoanWithSimultOwner) newIndex = 1;
                if(self.currentTabIndex !== newIndex) self.currentTabIndex = newIndex;
                _.each(self.premiums, (p,i) => {
                    if(p.simultaneousCalculation && _.parseNumber(p.rateID, 0) === 0) {
                        self.$nextTick(() => {
                            self.$root.$emit("bv::show::tooltip", "drp_premium_name");
                        });
                        return false;
                    }
                });
            },

            onSimultaneousChange(e) {
                const self = this;
                self.handleSimultaneousChange()
            },

            onFileInfoChange(e) {
                const self = this;
                self.validationErrors = [];
                if(_.isNil(e.alert)) return;
                self.addAlert(e.alert);
                if(e.alert.target !== "Service") return;
                self.loadPremiums(true);
                self.handleSimultaneousChange();
            },

            /**
             * Fires when the selected rate on the premium-calculator component changes.  This method determines whether the premium
             * changed on a simultaneous calculation and, if so, ensures that the rate on the assocaited premium is updated to match.
             * @param e event information from the premium selection drop down
             */
            onRateSelectionChange(e){
                const self = this;
                if(_.isNil(e.event)) return;

                self.removeAlert({ orderRateCalculationID: self.premiums[this.currentTabIndex].orderRateCalculationID, target: "RateID", remap: false });

                let premiumsToRefreshEndorsements = [self.premiums[this.currentTabIndex].orderRateCalculationID];
                var rateId = _.parseNumber(e.value, 0);
                // Only auto-sync the premiums if the calculation is simultaneous and user is currently on one of the first two premiums
                if(self.selectedSimultaneousOption !== SimultaneousOptions.None && self.currentTabIndex < 2 && rateId !== 0) {
                    // set for each rate on a multi-loan order
                    _.each(self.premiums, p => {
                        if (p.simultaneousCalculation) {
                            p.rateID = rateId;
                            premiumsToRefreshEndorsements.push(p.orderRateCalculationID);
                            self.removeAlert({ orderRateCalculationID: p.orderRateCalculationID, target: "RateID" });
                        }
                    });
                }
                else {
                    self.mapAlerts();
                }

                self.syncPremiumNames();

                self.refreshAlwaysApplyEndorsements(premiumsToRefreshEndorsements);
            },

            onReloadRateInfo() {
                this.fetchData();
            },

            onResetRate(){
                // Updates to reconcile data on debits and credits premium lines if any
                this.createAutoDebitCredit();
            },

            openOutsidePartiesDialog() {
                const self = this;

                self.$dialog.open({
                    title: "Manage Outside Parties",
                    height: 500,
                    width: 1200,
                    component: OutsidePartyAllocations,
                    args: { agentTotal: self.agentPremiumCalculated },
                    okOnly: true,
                    onOk (e) {
                        return Promise.resolve(true);
                    }
                });
            },

            openReissueCreditDialog() {
                const self = this;

                self.$dialog.open({
                    title: "Reissue Credit",
                    height: "auto",
                    width: 900,
                    adaptive: true,
                    component: ReissueCreditManager,
                    props: {
                        rateOrderInfo: self.rateOrderInfo.toDataObject(),
                        premiums: self.premiums,
                        readOnly: self.readOnly
                    },
                    onOk (e) {
                        let resultRateOrderInfo = e.component.viewModel && e.component.viewModel.toDataObject();
                        self.refreshForReissueCreditChanges(resultRateOrderInfo, e.component.policyExcessValues);
                        return true;
                    }
                });
            },

            refreshForReissueCreditChanges(rateOrderInfo, excessValues) {
                const self = this;
                self.rateOrderInfo = new RateOrderInfoDto(rateOrderInfo);
                _.each(self.premiums, p => {
                    let excessValueObj = _.find(excessValues, v => v.id === p.orderRateCalculationID);
                    p.previousAmount = excessValueObj.excessAmount;
                });
                return new Promise((resolve, reject) => {
                    let onCatch = error => {
                        self.$toast.error("Premium calculation after reissue credit update failed.");
                        console.error(error);
                        reject(error);
                    };
                    self.$store.dispatch(RATE_ACTIONS.SAVE_RATE_ORDER_INFO, rateOrderInfo)
                        .then(() => {
                            self.save(true)
                                .then(resolve)
                                .catch(onCatch);
                        })
                        .catch(onCatch);
                });
            },
            async refreshAlwaysApplyEndorsements(orderRateCalculationIds) {
                const self = this;

                let storePromise = self.$store.dispatch(RATE_ACTIONS.REFRESH_ALWAYS_APPLY_ENDORSEMENTS, orderRateCalculationIds);
                await self.$rqBusy.wait(storePromise);
            },
            getCurrentChanges() {
                const self = this;
                let componentData = _.map(self.premiums, item => item.toDataObject());
                let premiumIds = _.map(self.premiums, item => item.orderRateCalculationID);
                let rawStoreData = _.filter(self.currentStoreState.premiums, item => _.includes(premiumIds, item.orderRateCalculationID));
                let originalStoreData = _.map(rawStoreData, item => new OrderRateCalculation(item).toDataObject());
                return self.getAuditChanges(originalStoreData, componentData);
            },

            hasEndorsementChanges() {
                const self = this;
                if(!self.calculators) return false;
                return _.some(self.calculators, c => c.hasEndorsementChanges());
            },

            validate() {
                const self = this;
                let result = true;
                _.forEach(self.premiums, (p,i) => {
                    if(p.rateID > 0) return;

                    let isRequired = self.isRequiredPremium(i);

                    let message = isRequired
                        ? "Premium name must be selected for first policy before calculating."
                        : "Premiums will not be calculated on policies that do not contain a Premium Name.";

                    let severity = isRequired
                        ? AlertSeverity.Error
                        : AlertSeverity.Warning;

                    self.addAlert({ orderRateCalculationID: p.orderRateCalculationID, target: "RateID", message, severity });

                    result = !isRequired;
                    return result;
                });

                return result;
            },

            allNonRequiredPolicyHasPremiumNames() {
                let nonRequiredLoans = _.filter(this.premiums, (x, index) => index > 0);
                return _.every(nonRequiredLoans, item => { return _.parseNumber(item.rateID, 0) > 0; });
            },

            refreshRecalculateAlert() {
                const self = this;

                let changes = self.getCurrentChanges();
                if(_.isEmpty(changes)) {
                    //clear the current recalculate alert if nothing's changed
                    self.removeAlertsByTarget("recalculate");
                    return;
                }

                //all alerts prompt to recalculate, so no need to check anything if another alert is already displayed
                if(self.activeAlerts.length > 0) return;

                //set recalculate alert
                this.addAlert({ target: "recalculate", message:"Calculations on the order may need to be updated to reflect policy changes." });
            },

            onOverrideChange(e) {
                let currentChanges = this.getCurrentChanges();
                if(currentChanges.length === 0) {
                    this.removeAlertsByTarget("override");
                    return;
                }
                let message = _.getBool(e, "overridden")
                    ? "Values have been overridden.  Calculations on the order may need to be updated."
                    : "Overridden values have been reverted.  Calculations on the order may need to be updated.";

                this.addAlert({ target:"override", message });
            },

            addAlert({ orderRateCalculationID=0, target, message, severity=AlertSeverity.Warning, remap=true }) {
                this.$store.commit(RATE_MUTATIONS.ADD_ALERT, { orderRateCalculationID, target, message, severity });
                if(!remap) return;
                this.$nextTick(() => { this.mapAlerts(); });
            },

            removeAlert({ orderRateCalculationID=0, target=null, remap=true }) {
                this.$store.commit(RATE_MUTATIONS.REMOVE_ALERT, { orderRateCalculationID, target });
                if(!remap) return;
                this.$nextTick(() => { this.mapAlerts(); });
            },

            removeAlertsByTarget(target, remap=true) {
                this.$store.commit(RATE_MUTATIONS.REMOVE_ALERTS_BY_TARGET, target);
                if(!remap) return;
                this.$nextTick(() => { this.mapAlerts(); });
            },

            clearAlerts() {
                this.$store.commit(RATE_MUTATIONS.SET_ALERTS, []);
            },

            checkSimultaneousSelections(){
                const self = this;

                if(self.premiums.length > 0 && _.parseBool(self.premiums[0].simultaneousCalculation, false))
                    self.selectedSimultaneousOption = SimultaneousOptions.LoanWithSimultOwner;
                else if(_.some(self.premiums, item => !item.isLoanHolder && item.simultaneousCalculation)) {
                    if(!_.some(self.premiums, item => item.simultaneousCalculation)) {
                        self.addAlert({ target:"recalculate", message:"The loan order has been altered and calculations on the order may need to be updated." });
                    }
                    self.selectedSimultaneousOption = SimultaneousOptions.OwnerWithSimultLoan;
                }
                else
                    self.selectedSimultaneousOption = SimultaneousOptions.OwnerWithSimultLoan;
            },

            createAutoDebitCredit() {
                const self = this;
                let data = {
                    ordersID: self.orderId,
                    premiums: _.map(self.premiums, item => item.toDataObject()),
                    cdfPremiums: _.map(self.cdfPremiums, item => item.toDataObject()),
                    endorsements: _.cloneDeep(this.currentStoreState.endorsements)
                };

                return self.$rqBusy.wait(self.$api.DebitCreditsApi.createAutoDebitCredit(self.orderId, data), { topLevel: true} );
            },

            async save(userInitiated=false) {
                const self = this;
                if(self.readOnly) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return true;
                }

                const processSave = async () => {
                    self.clearAlerts();
                    if(!_.isEmpty(self.calculators)) {
                        await Promise.all(_.map(self.calculators, c => c.saveEndorsements(false)));
                    }

                    let result = await self.calculate(userInitiated);

                    if(result) {
                        self.$events.$emit("refresh-time-tracking-data");

                        await self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { orderId: self.orderId, refresh: true });
                        if (self.isCreateAutoDebitCredit && userInitiated)
                            result = await self.saveDebitCreditOverrides(userInitiated);
                    }

                    return result;
                };

                let success = await userInitiated
                    ? self.$rqBusy.wait(processSave())
                    : processSave();

                GlobalEventManager.saveCompleted({ success });

                return success;
            },

            async calculate(userInitiated=false){
                const self = this;
                self.showAlerts = false;

                if(self.noData){
                    self.mapAlerts();
                    if(userInitiated) self.showAlerts = true;
                    GlobalEventManager.saveCompleted({ success: true });
                    return true;
                }

                // TG - 6/14/2018 - Calculations are driven in large by configuration changes
                //  (ie. no client changes can still yield different results) so disabling change
                //  detection until deemed necessary
                //**************************************************/
                // if(self.alertTarget !== "SalesOverrideAmount" && self.alertTarget !== "LoanOverrideAmount") {
                //     let currentChanges = self.getCurrentChanges();
                //     if(currentChanges.length === 0) {
                //         if(!userInitiated) return;
                //         self.$toast.info("No changes detected.");
                //         return;
                //     }
                // }

                if(!self.validate()){
                    self.mapAlerts();
                    if(userInitiated) self.showAlerts = true;
                    GlobalEventManager.saveCompleted({ success: false });
                    return false;
                }

                let calcRequest = {
                    ordersID: self.orderId,
                    premiums: _.map(self.premiums, item => item.toDataObject()),
                    cdfPremiums: _.map(self.cdfPremiums, item => item.toDataObject()),
                    endorsements: _.cloneDeep(this.currentStoreState.endorsements),
                    outsideParties: _.map(this.currentStoreState.outsideParties, item => _.toPlainObject(item))
                };

                //the rate engine the owner policy OrderRateCalculation in the request even though it's not displayed (or potentially used)
                let loanOnly = _.includes(OrderServiceType.loanSpecificTypes, this.rateOrderInfo.service);
                if(loanOnly) {
                    let ownerPolicy = _.find(self.currentStoreState.premiums, p => p.loanHolder === 1);
                    if(!_.isNil(ownerPolicy)) calcRequest.premiums.unshift(ownerPolicy);
                }
                let success = true;
                try {
                    let result = await self.$store.dispatch(RATE_ACTIONS.CALCULATE_RATES, calcRequest);
                    await self.$store.dispatch(RATE_ACTIONS.GET_RATE_ORDER_INFO);
                    await self.$store.dispatch(RATE_ACTIONS.GET_OUTSIDE_PARTY, self.orderId);
                    success = !_.some(result.alerts, a => a.severity === AlertSeverity.Error);
                }
                catch(error) {
                    success = false;
                    console.error(error);
                }
                finally {
                    if(success)
                        self.$toast.success("Premiums calculated successfully.");
                    else
                        self.$toast.error("Premiums calculation failed.");
                    self.mapAlerts();
                    if(userInitiated)
                        self.showAlerts = true;
                }

                return success;
            },

            reset() {
                const self = this;
                _.each(self.calculators, calc => { calc.reset(); });
                self.loadPremiums();
            },

            async saveDebitCreditOverrides(userInitiated = false) {
                const self = this;
                await self.fetchDebitCreditData();
                if (self.isCdf) return await self.promptCDF();
                if (self.isHud2010)return await self.promptHUD();
                return true;
            },

            promptCDF() {
                const self = this;
                // Do not prompt for Loan/Owner only service types.
                if (self.premiums.length < 2) return self.createAutoDebitCredit();

                // CDF criteria
                var status = self.overrideStatus[0]; // first loan data
                let ownerPremium = self.premiums[0]; // owners premium
                let lenderPremium = self.premiums[1]; // first loan premium
                let lenderSimultaneous = _.parseBool(lenderPremium.simultaneousCalculation);
                let isSellerPct = ownerPremium.sellerPayPctForOwner_Basic !== 100 || lenderPremium.sellerPayPctForOwner_Simultaneous !== 100;

                // If we're overriding a user defined line for with premium data then prompt whether to replace it
                if (lenderSimultaneous && isSellerPct && status.isCdfInUse && !self.isSettlementLocked)
                    return self.displayCdfPrompt(ownerPremium, status);
                else
                    return self.createAutoDebitCredit();
            },

            displayCdfPrompt(premium, status) {
                const self = this;
                let prompt = false;
                let descriptionMsg = ""
                let promptMsg = "";
                let overrideLines = [];

                let onYes = () => {
                    premium.isOverrideCDFAdjustment = true;
                    self.createAutoDebitCredit();
                };
                let onNo = () => {
                    premium.isOverrideCDFAdjustment = false;
                };

                if (status.isL08InUse) {
                    overrideLines.push("L08");
                    descriptionMsg += `<br>L08: ${status.cdfL08Description}`;
                }
                if (status.isN10InUse) {
                    overrideLines.push("N10");
                    descriptionMsg += `<br>N10: ${status.cdfN10Description}`;
                }

                if (overrideLines.length === 1)
                    promptMsg += `Line ${overrideLines[0]} is currently being used. Do you want to overwrite this line? ${descriptionMsg}`;
                if (overrideLines.length > 1)
                    promptMsg += `Lines ${_.join(overrideLines, "/")} are currently being used. Do you want to overwrite these lines? ${descriptionMsg}`;

                self.$dialog.confirm("Confirm", promptMsg, onYes, onNo, { cancelTitle: "No", okTitle: "Yes"});

                return Promise.resolve(true);
            },

            promptHUD() {
                const self = this;
                let ownerPremium = {};
                let loanPremium = {};
                let statuses = []; // list of status related to each loan
                let overrideOwner = false;
                let overrideLender = false;

                let eligiblePremiums = _.filter(self.premiums, item => !item.nonGFE_Basic && !item.nonGFE_Simultaneous);
                _.each(eligiblePremiums, (p,i) => {
                    if (p.isLoanHolder) {
                        let status = self.overrideStatus[0]; // first loan data
                        // RQO-23173 Only update descriptions for each loan status related to debit credits
                        //statuses.push(status);
                        if (p.sellerToPayAdjustment > 0 && status.isHudOwnerInUse){
                            overrideOwner = true;
                            ownerPremium = p;
                            return;
                        }
                        let eligibleEndorsements = _.filter(self.endorsements, item => item.orderRateCalculationID === p.orderRateCalculationID &&
                         item.breakoutOverride === BreakoutEndorsementOption.No);

                        _.each(eligibleEndorsements, (e,i) =>{
                            if (e.sellerToPay > 0) {
                                overrideOwner = true;
                                ownerPremium = p;
                                return;
                            }
                        });
                    }
                    else {
                        let status = _.find(self.overrideStatus, o => o.loanID === p.loanID);
                        statuses.push(status);
                        if (p.sellerToPayAdjustment > 0 && status.isHudLenderInUse){
                            overrideLender = true;
                            loanPremium = p;
                            return;
                        }
                        let eligibleEndorsements = _.filter(self.endorsements, item => item.orderRateCalculationID === p.orderRateCalculationID &&
                         item.breakoutOverride === BreakoutEndorsementOption.No);

                        _.each(eligibleEndorsements, (e,i) =>{
                            if (e.sellerToPay > 0) {
                                overrideLender = true;
                                loanPremium = p;
                                return;
                            }
                        });
                    }
                });

                // If we're overriding a user defined line for with premium data then prompt whether to replace it
                if (!self.isSettlementLocked && (overrideOwner || overrideLender))
                    return self.displayHudPrompt(ownerPremium, loanPremium, statuses, overrideOwner, overrideLender);
                else
                    return self.createAutoDebitCredit();
            },

            displayHudPrompt(ownerPremium, loanPremium, statuses, isOwner, isLender){
                const self = this;
                let prompt = false;
                let descriptionMsg = ""
                let promptMsg = "";
                let overrideLines = [];

                let onYes = () => {
                    ownerPremium.isOverrideHUDAdjustment = isOwner;
                    loanPremium.isOverrideHUDAdjustment = isLender;
                    self.createAutoDebitCredit();
                };
                let onNo = () => {
                    ownerPremium.isOverrideHUDAdjustment = loanPremium.isOverrideHUDAdjustment = false;
                };

                _.each(statuses, (status, i) => {
                    if (isOwner) {
                        if (status.is208InUse) {

                            if (!_.includes(overrideLines, 208))
                                overrideLines.push(208);

                            descriptionMsg += `<br>208: ${status.hud208Description}`;
                        }
                        if (status.is508InUse) {
                            if (!_.includes(overrideLines, 508))
                                overrideLines.push(508);

                            descriptionMsg += `<br>508: ${status.hud508Description}`;
                        }
                        prompt = true;
                    }

                    if (isLender) {
                        if (status.is209InUse) {

                            if (!_.includes(overrideLines, 209))
                                overrideLines.push(209);

                            descriptionMsg += `<br>209: ${status.hud209Description}`;
                        }
                        if (status.is509InUse) {
                            if (!_.includes(overrideLines, 509))
                                overrideLines.push(509);

                            descriptionMsg += `<br>509: ${status.hud509Description}`;
                        }
                        prompt = true;
                    }
                });

                if (overrideLines.length === 1)
                        promptMsg += `Line ${overrideLines[0]} is currently being used. Do you want to overwrite this line? ${descriptionMsg}`;
                    if (overrideLines.length > 1)
                        promptMsg += `Lines ${_.join(overrideLines, "/")} are currently being used. Do you want to overwrite these lines? ${descriptionMsg}`;

                    if (prompt)
                        self.$dialog.confirm("Confirm", promptMsg, onYes, onNo, { cancelTitle: "No", okTitle: "Yes"});
                return Promise.resolve(true);
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } }); },

            parseFixed(v, d) { return accounting.parse(accounting.toFixed(v, d)); },

            syncPremiumNames(){
                const self = this;
                _.each(self.premiums, (p,i) => {
                    p.premiumName = self.getPremiumName(p.rateID, p.orderRateCalculationID);
                    self.cdfPremiums[i].premiumName = p.premiumName;
                });
            },

            isRequiredPremium(premiumIndex) {
                let isLoanOnlyService = _.includes(OrderServiceType.loanSpecificTypes, this.rateOrderInfo.service);
                return (premiumIndex < 2 && this.selectedSimultaneousOption !== SimultaneousOptions.None)
                    || (!this.premiums[premiumIndex].isLoanHolder && isLoanOnlyService && premiumIndex === 1)
                    || premiumIndex === 0;
            },

            setSimultaneousCalculation() {
                const self = this;

                _.each(self.premiums, item => {
                    if(item.simultaneousCalculation)
                        item.applyOverride_Basic = item.applyOverride_Simultaneous;
                    item.simultaneousCalculation = false;
                });

                if(self.selectedSimultaneousOption === SimultaneousOptions.None) {
                    // close/hide tooltip on premium name dropdown
                    self.$root.$emit("bv::hide::tooltip", "drp_premium_name");
                    return;
                }

                let isSimultaneous = self.premiums.length > 1;
                let ownerPremium = self.premiums[0];
                let loanPremium = isSimultaneous ? self.premiums[1] : null;

                let ownerRateId = _.parseNumber(self.premiums[0].rateID, 0);
                let loanRateId = isSimultaneous ? _.parseNumber(self.premiums[1].rateID, 0) : 0;

                if(self.selectedSimultaneousOption === SimultaneousOptions.LoanWithSimultOwner && ownerPremium.isLoanHolder) {
                    ownerPremium.applyOverride_Simultaneous = _.parseBool(ownerPremium.applyOverride_Basic);
                    ownerPremium.simultaneousCalculation = true;
                    if(_.gt(loanRateId, 0))
                        ownerPremium.rateID = loanRateId;
                    else
                        loanPremium.rateID = ownerRateId;
                }

                if(self.selectedSimultaneousOption === SimultaneousOptions.OwnerWithSimultLoan && isSimultaneous && !self.premiums[1].isLoanHolder) {
                    self.premiums[1].applyOverride_Simultaneous = _.parseBool(self.premiums[1].applyOverride_Basic);
                    self.premiums[1].simultaneousCalculation = true;
                    if(_.gt(ownerRateId, 0))
                        loanPremium.rateID = ownerRateId;
                    else
                        ownerPremium.rateID = loanRateId;
                }

                if(!ownerPremium.isLoanHolder || (isSimultaneous && loanPremium.isLoanHolder)) {
                    console.error("Rate calculation data invalid -- LoanHolder mismatch");
                }

                self.syncPremiumNames();
            },

            onSimultaneousLoansChange(e) {
                const self = this;
                let ownerPremium = self.premiums[0];

                if (e.length !== undefined) {
                    self.removeAlertsByTarget("simultaneous_calculation");
                    if (e.length === 0) {
                        self.addAlert({target: "simultaneous_calculation", message: "Must select at least one Simultaneous Loan Policy.", severity: AlertSeverity.Warning});
                        return;
                    }
                }

                _.each(self.premiums, p => {
                    if (_.includes(e.value, p.loanID)) {
                        p.simultaneousCalculation = true;
                        p.applyOverride_Simultaneous = _.parseBool(p.applyOverride_Basic);
                        p.rateID = ownerPremium.rateID;
                    }
                    else if (!_.isNil(p.loanID) && p.simultaneousCalculation) {
                        p.simultaneousCalculation = false;
                        p.applyOverride_Basic = _.parseBool(p.applyOverride_Simultaneous);
                    }
                });

                self.syncPremiumNames();
            },

            onTabsChanged(e,b) {
                const self = this;
                let newIndex = 0;
                if(self.rateOrderInfo.simultaneousOption === SimultaneousOptions.LoanWithSimultOwner)
                    newIndex = 1;
                if(self.currentTabIndex !== newIndex)
                {
                    self.currentTabIndex = newIndex;
                }//this.currentTabIndex = 0;
            },

            async onBeforeActivateTab({ index, prevIndex, abort }) {
                let isSummaryTab = index === this.premiums.length;
                let hasEndChanges = this.hasEndorsementChanges();
                if(!isSummaryTab || !hasEndChanges) return;
                abort();
                await this.$rqBusy.wait(this.save());
                this.currentTabIndex = index;
            },

            async onActivateTab({ index }) {
                await this.$nextTick()
                this.refreshScrollbars(index);
            },

            onWindowResize: _.debounce(function(e) {
                this.refreshScrollbars();
            }, 300, { "leading": false, "trailing": true }),

            refreshScrollbars(tabIndex=null) {
                let idx = _.isNil(tabIndex) ? this.currentTabIndex : tabIndex;
                _.invoke(this, `calculators[${idx}].refreshScrollbars`);
            },

            setSummary(summary) {
                this.summary = summary;
            }
        }
    };
</script>
