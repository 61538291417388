<template>
    <rqdx-action-data-grid v-if="!propIsNull"
        ref="creditMemoDataGrid"
        title="Credit Memos"
        automation_id="tbl_credit_memos"
        :actions="selectionActions"
        :data-source="gridDataSource"
        :config="gridConfig"
        export-file-name="credit-memo-data"
        @select="onEdit"
        @delete="onDelete"
        hide-search
        rq-editable
        hide-show-column-chooser
    >
    <template #toolbar>
        <ul class="nav navbar-nav me-auto">
            <li class="nav-item">
                <b-btn
                    automation_id="btn_add_credit_memo"
                    variant="theme"
                    size="sm"
                    :disabled="disableAddCreditMemo"
                    @click="onAddCreditMemo">Add
                </b-btn>
            </li>
        </ul>
    </template>
    </rqdx-action-data-grid>
</template>

<script>
    import { mapState } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { CreditMemoModel } from "../models";
    import CreditMemoDialog from "./CreditMemoDialog";
    import { CreditMemoEdit} from "@config/enums";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";

    export default {
        name:"CreditMemoSection",
        mixins: [GridInvokerMixin({ grid: "creditMemoDataGrid" })],
        props:{ invoiceDetail:{type: Object} },
        created(){
            this.loadGridConfig();
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                systemDefaults: state => state.system.systemDefaults,
                order: state => state.orders.order
            }),
            propIsNull(){ return _.isNil(this.invoiceDetail); },
            localSecurity(){ return this.securitySettings.findValues(["CreditMemoEditing"]); },
            disableAddCreditMemo(){ return this.localSecurity.CreditMemoEditing === CreditMemoEdit.Restricted || this.readOnly || this.isOrderLocked; },
            canEditCreditMemos(){ return this.localSecurity.CreditMemoEditing === CreditMemoEdit.Unrestricted && !this.readOnly && !this.isOrderLocked; },
            selectionActions() {
                const self = this;
                return [
                    {
                        name: "view-edit",
                        text: self.canEditCreditMemos ? "Edit" : "View",
                        eventName: "select",
                        requireSelection: true ,
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: () => !self.canEditCreditMemos
                    }
                ];
            }
        },
        watch: {
            isOrderLocked (newValue) {
                if(_.isNull(newValue)) return;
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("refresh");
            }
        },
        methods:{
            loadGridConfig(){
                const self = this;
                self.gridConfig = {
                    onEditorPreparing: self.onGridEditorPreparing,
                    columns: [
                        {
                            dataField: "creditMemoID",
                            caption: "Credit Memo ID",
                            dataType: "number",
                            alignment: "right",
                            width: 150,
                            minWidth: 150,
                            allowEditing: false,
                            visible: false
                        },
                        DxGridUtils.dateColumn({
                            dataField: "dateTimeRecorded",
                            caption: "Date",
                            allowEditing: false,
                            width: 85,
                            minWidth: 85,
                            editorOptions:{ inputAttr: {automation_id: "txt_date"} },
                        }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            caption: "Amount",
                            allowEditing: false,
                            alignment: "right",
                            width: 150,
                            minWidth: 150,
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            editorOptions:{ inputAttr: {automation_id: "txt_amount"} },
                        },
                        {
                            dataField: "note",
                            dataType: "string",
                            caption: "Note",
                            width: "auto",
                            editorOptions:{ inputAttr: {automation_id: "txt_note"} },
                        }
                    ],
                    summary:{
                        totalItems:[{
                            column: "amount",
                            summaryType: "sum",
                            displayFormat: "{0}",
                            valueFormat: { type:"currency", precision:2 }
                        }]
                    }
                }
                self.gridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.invoiceDetail.creditMemos);
                    },
                    update: self.onGridUpdate
                }
            },
            onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.invoiceDetail.creditMemos, cm => cm.clientKey === key);
                _.assign(item, values);

                return Promise.resolve(item);
            },
            onGridEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                let isNew = _.get(e, "row.data.isNew", false);
                if(isNew) return;
                if(e.dataField === "note") {e.editorOptions.disabled = this.readOnly || !this.canEditCreditMemos; return;}
            },
            onAddCreditMemo(){
                const self = this;
                let creditMemo = new CreditMemoModel({creditMemoID: 0, number: null, psLockSource: null, psLockFlag: false, dateTimeRecorded: new Date(), psLockDate: null, amount: 0, invoiceID: self.invoiceDetail.invoiceDetail, sequence: null, note: null, isNew: true, details: []});
                self.showCreditMemoDialog(creditMemo, true);
            },
            onEdit(e){
                if(!e || !e.data) return;
                const self = this;
                //e.data may be stale
                let creditMemo = _.find(self.invoiceDetail.creditMemos, cm => { return cm.clientKey == e.data.clientKey;});
                this.showCreditMemoDialog(creditMemo, false);
            },
            onDelete(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;

                let message = items.length === 1 ? "Are you sure you want to delete the selected Credit Memo?" : "Are you sure you want to delete the selected Credit Memos?";

                let okHandler = function (args) {
                    _.forEach(items, i => {
                        let index = _.findIndex(self.invoiceDetail.creditMemos, cm => { return cm.clientKey === i.clientKey});
                        let removedItems = self.invoiceDetail.creditMemos.splice(index, 1);
                        if(!removedItems[0].isNew)
                            self.invoiceDetail.deletedCreditMemos.push(i);
                    });

                    self.invokeGridMethod("refresh");
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    message,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            showCreditMemoDialog(creditMemo, canAdd){
                const self = this;
                self.$dialog.open({
                    title: "Credit Memo Details",
                    width: 1000,
                    height: 825,
                    component: CreditMemoDialog,
                    props:{
                        invoiceDetail:self.invoiceDetail,
                        creditMemo:creditMemo,
                        addMode: canAdd
                    },
                    onOk: function(e) {
                        let dialogGridCommit = e.component.creditMemoDetailGrid.saveEditData().then(() => {
                            let editedCreditMemo = e.component.editedCreditMemo;
                            editedCreditMemo.amount = e.component.total;
                            _.assign(creditMemo, editedCreditMemo);

                            // In case of adding new, do not add the credit memo if it does not contain any valued line
                            if((editedCreditMemo.creditMemoID === 0 || editedCreditMemo.creditMemoID === null) && e.component.isNotEmpty && !_.some(self.invoiceDetail.creditMemos, x => x.clientKey === editedCreditMemo.clientKey))
                                self.invoiceDetail.creditMemos.push(creditMemo);

                            self.invokeGridMethod("refresh");
                            return true;
                        });
                        return dialogGridCommit;
                    },
                });
            },
            refresh(){
                const self = this;
                if(self.propIsNull) return;
                self.invokeGridMethod("refresh");
            },
            onSave(e){
                return this.invokeGridMethod("saveEditData");
            },
            updateDimensions(){
                this.invokeGridMethod("updateDimensions");
                this.invokeGridMethod("repaint");
            }
        }
    }
</script>