<template>
    <rq-page-section title="Escrow Account Payments" collapsible>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <label for="rb_loan_have_escrow_payment">Does loan have escrow payments?</label>
                    <rq-radio-group 
                        id="rb_loan_have_escrow_payment"
                        v-model="loanTerm.hudNoMonthlyEscrowPaymentForItems"
                        @change="onNoMonthlyEscrowPaymentForItemsChanged"
                        inline
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_additional_monthly_escrow">Additional Monthly Escrow</label>
                    <rqInputNumber
                        id="txt_additional_monthly_escrow"
                        automation_id="txt_additional_monthly_escrow"
                        defaultValue="0"
                        decimals="2"
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        :disabled="!loanTerm.hudNoMonthlyEscrowPaymentForItems"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowPaymentAmount"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <rq-action-form-group
                        label="Total PIMI & Escrow Payment"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="loanTerm.hudTotalMonthlyAmoutOwedOverride"
                        @action-click="onRevertTotalInitialMonthlyAmountOverride">
                        <rqInputNumber
                            id="txt_escrow_initial_monthly_amount"
                            automation_id="txt_escrow_initial_monthly_amount"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="loanTerm.hudTotalInitialMonthlyAmountOwed"
                        />
                    </rq-action-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <label for="txt_additional_monthly_escrow">This Estimate Includes</label>
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <b-form-checkbox
                        automation_id="chk_additional_monthly_escrow_for_property_taxes"
                        id="chk_additional_monthly_escrow_for_property_taxes"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForPropertyTaxes">
                        Property Taxes
                    </b-form-checkbox>
                </div>
                <div class="col col-auto pt-1">
                    <input
                        automation_id="chk_additional_monthly_escrow_for_custom1"
                        id="chk_additional_monthly_escrow_for_custom1"
                        name="chk_additional_monthly_escrow_for_custom1"
                        type="checkbox"
                        class="form-check-input mt-2"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForCustom1"
                    >
                </div>
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <input
                        automation_id="txt_additional_monthly_escrow_for_custom1_desc"
                        type="text"
                        class="form-control"
                        maxlength="150"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForCustom1Desc"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <b-form-checkbox
                        automation_id="chk_additional_monthly_escrow_for_homeowners_insurance"
                        id="chk_additional_monthly_escrow_for_homeowners_insurance"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForHomeOwnersInsurance">
                        Homeowner's Insurance
                    </b-form-checkbox>
                </div>
                <div class="col col-auto pt-1">
                    <input
                        automation_id="chk_additional_monthly_escrow_for_custom2"
                        id="chk_additional_monthly_escrow_for_custom2"
                        name="chk_additional_monthly_escrow_for_custom2"
                        type="checkbox"
                        class="form-check-input mt-2"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForCustom2"
                    >
                </div>
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <input
                        automation_id="txt_additional_monthly_escrow_for_custom2_desc"
                        type="text"
                        class="form-control"
                        maxlength="150"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForCustom2Desc"
                    >
                </div>
            </div>
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <b-form-checkbox
                        automation_id="chk_additional_monthly_escrow_for_flood_insurance"
                        id="chk_additional_monthly_escrow_for_flood_insurance"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForFloodInsurance">
                        Flood Insurance
                    </b-form-checkbox>
                </div>
                <div class="col col-auto pt-1">
                    <input
                        automation_id="chk_additional_monthly_escrow_for_custom3"
                        id="chk_additional_monthly_escrow_for_custom3"
                        name="chk_additional_monthly_escrow_for_custom3"
                        type="checkbox"
                        class="form-check-input mt-2"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForCustom3"
                    >
                </div>
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <input
                        automation_id="txt_additional_monthly_escrow_for_custom3_desc"
                        type="text"
                        class="form-control"
                        maxlength="150"
                        v-model="loanTerm.hudAdditionalMonthlyEscrowForCustom3Desc"
                    >
                </div>
            </div>
        </fieldset>
    </rq-page-section>
</template>
<script>
    import { LoanTermSectionHud2010DataModel } from '@settlement/models';
    export default {
        props: {
            readOnly: { type: Boolean, default: false },
            loanTerm: { type: Object, default: () => { return {} } },
            originalLoanTerm: { type: Object, default: () => { return new LoanTermSectionHud2010DataModel() } }
        },
        computed: {
            totalMonthlyOwed() {
                return _.parseNumber(this.loanTerm.hudAdditionalMonthlyEscrowPaymentAmount, 0) + _.parseNumber(this.loanTerm.hudInitialMonthlyAmount, 0);
            }
        },
        watch: {
            "loanTerm.hudInitialMonthlyAmount": {
                immediate: false,
                handler: function(newVal, oldVal) {
                    if(oldVal === undefined || newVal === oldVal) return;
                    this.loanTerm.hudTotalInitialMonthlyAmountOwed = this.totalMonthlyOwed;
                }
            },
            "loanTerm.hudAdditionalMonthlyEscrowPaymentAmount": {
                immediate: false,
                handler: function(newVal, oldVal) {
                    if(oldVal === undefined || newVal === oldVal) return;
                    this.loanTerm.hudTotalInitialMonthlyAmountOwed = this.totalMonthlyOwed;
                }
            },
            "loanTerm.hudTotalInitialMonthlyAmountOwed": {
                immediate: false,
                handler: function(newVal, oldVal) {
                    if(oldVal === undefined || newVal === oldVal) return;
                    this.loanTerm.hudTotalMonthlyAmoutOwedOverride = this.loanTerm.hudTotalInitialMonthlyAmountOwed !== (this.loanTerm.hudInitialMonthlyAmount + this.loanTerm.hudAdditionalMonthlyEscrowPaymentAmount);
                }
            }
        },
        methods: {
            onRevertTotalInitialMonthlyAmountOverride() {
                this.loanTerm.hudTotalMonthlyAmoutOwedOverride = false;
                this.loanTerm.hudTotalInitialMonthlyAmountOwed = this.loanTerm.hudInitialMonthlyAmount + this.loanTerm.hudAdditionalMonthlyEscrowPaymentAmount;
            },
            onNoMonthlyEscrowPaymentForItemsChanged(value) {
                if(value === false)
                    this.loanTerm.hudAdditionalMonthlyEscrowPaymentAmount = null;
            }
        }
    }
</script>
<style lang="scss" scoped>
.rq-custom-control {
    display: flex;
}
</style>