<template>
    <div class="rq-container content-wrapper">
        <rq-banner
            message="Please correct the highlighted error to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
            dismissable
        />
        <div :class="{ 'form-group form-required mt-3':true, 'has-error':v$.selectedValue.$error }">
            <label for="drp_role">Role</label>
            <dx-select-box
                :input-attr="{ id: 'drp_role', automation_id: 'drp_role' }"
                value-expr="id"
                display-expr="name"
                :items="roleItems"
                :search-enabled="true"
                class="form-control"
                v-model="v$.selectedValue.$model"
                v-focus
            />
            <rq-validation-feedback>Role is required.</rq-validation-feedback>
        </div>
        <div class="form-group">
            <b-form-checkbox
                automation_id="chk_no_company_contact"
                v-model="noCompanyContact">No Company/Contact
            </b-form-checkbox>
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    export default {
        name: "RoleList",

        props: {
            roles: { type: Array, default: () => [] }
        },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                selectedValue: null,
                noCompanyContact: false,
                roleItems: _.map(this.roles, item => ({ id: _.parseNumber(item.id), name: item.name })),
                showBanner: false
            };
        },

        computed: {
            selectedItem() { return  _.find(this.roles, { id: this.selectedValue }); }
        },

        validations() {
            return {
                selectedValue: { required }
            }
        },

        methods: {
            isValid() {
                this.v$.$touch();
                this.showBanner = true;
                return !this.v$.$error;
            },

            dialogResult (e) {
                return { data: this.selectedItem, dialogMode: "selection", isValid: this.isValid() };
            }
        }
    };
</script>
