import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
import { CheckStatus } from './enums';

export class CheckWritingLedgerSummaryDto {
	constructor(options) {
        options = options || {};
        this.balanceStatus = _.parseNumber(options.balanceStatus, 0);
        this.anticipatedDeposits = _.parseNumber(options.anticipatedDeposits, 0);
        this.anticipatedDisbursements = _.parseNumber(options.anticipatedDisbursements, 0);
        this.anticipatedBalance = _.parseNumber(options.anticipatedBalance, 0);
        this.goodFundDeposits = _.parseNumber(options.goodFundDeposits, 0);
        this.receipts = _.parseNumber(options.receipts, 0);
        this.disbursements = _.parseNumber(options.disbursements, 0);
        this.preDisbursementBalance = _.parseNumber(options.preDisbursementBalance, 0);
        this.fileBalance = _.parseNumber(options.fileBalance, 0);
        this.fileInSync = _.parseBool(options.fileInSync, false);
        this.multipleBankAccount = _.parseBool(options.multipleBankAccount, false);
        this.autoRecreateSource = _.parseNumber(options.autoRecreateSource, null);
        this.readOnly = _.parseBool(options.readOnly, false);
    }
}

export class CheckPrintDataDto {
	constructor(options) {
        options = options || {};
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
        this.nextCheckNumber = _.parseNumber(options.nextCheckNumber, 0);
        this.checkStart = _.parseNumber(options.checkStart, 0);
        this.checkEnd = _.parseNumber(options.checkEnd, 0);
        this.checkStartSimulated = _.parseNumber(options.checkStartSimulated, 0);
        this.checkEndSimulated = _.parseNumber(options.checkEndSimulated, 0);
        this.checkDocumentTemplateID = _.parseNumber(options.checkDocumentTemplateID, 0);
        this.rangeStart = _.parseNumber(options.rangeStart, 0);
        this.rangeEnd = _.parseNumber(options.rangeEnd, 0);
    }
}

export class CheckRequestDto {
	constructor(options) {
        options = options || {};
        this.checksID = _.parseNumber(options.checksID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
        this.payeeCompanyID = _.has(options, "companyID") ? _.parseNumber(options.companyID, null) : _.parseNumber(options.payeeCompanyID, null);
        this.payee = options.payee || null;
        this.payeeAddress1 = options.payeeAddress1 || null;
        this.payeeAddress2 = options.payeeAddress2 || null;
        this.payeeCity = options.payeeCity || null;
        this.payeeState = options.payeeState || null;
        this.payeeZip = options.payeeZip || null;
        this.useForwardingAddress = _.parseBool(options.useForwardingAddress);
    }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.ordersID == 0) {
            errorList.push("Order ID is required.");
        }

        return errorList;
    }
}

export class CheckPrintRequestDto {
	constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.initialCheckNumber = _.parseNumber(options.initialCheckNumber, -1);
        this.isManualCheckNumber = false;
        this.checkDate = options.checkDate || DateTimeHelper.nowTenantStartOfDay();
        //this.printAll = _.parseBool(options.printAll);
        this.simulatePrint = _.parseBool(options.simulatePrint);
        this.noSignaturePrint = options.noSignaturePrint || null;
        this.securityPin = options.securityPin || null;
        this.checksIDs = options.checksIDs || [];
		this.checkDocumentTemplateID = options.CheckDocumentTemplateID || 0;
    }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.ordersID == 0) {
            errorList.push("Order ID is required.");
        }
        if (this.initialCheckNumber == -1) {
            errorList.push("Check Number is required.");
        }
        if (this.checkDate == null) {
            errorList.push("Check Date is required.");
        }
        if (this.printAll == false && this.checksIDs.length == 0) {
            errorList.push("No Check IDs have been provided.");
        }

        return errorList;
    }
}

export class EscrowBankChangeRequestDto {
	constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.gfNo = options.gfNo || null;
        this.primaryBankCompanyID = _.parseNumber(options.primaryBankCompanyID, null);
        this.primaryBankCompanyName = options.primaryBankCompanyName || null;
        this.newBankCompanyID = _.parseNumber(options.newBankCompanyID, null);
        this.accountBalance = _.parseNumber(options.accountBalance, 0);
        this.multipleBankAccount = _.parseBool(options.multipleBankAccount, false);
        this.keepLedgerWithOriginal = _.parseBool(options.keepLedgerWithOriginal, true);
        this.createDepositInNew = _.parseBool(options.createDepositInNew, true);
        this.createTransferDebit = _.parseBool(options.createTransferDebit, true);
        this.isLocked = _.parseBool(options.isLocked, true);
        this.hasDepositSlips = _.parseBool(options.hasDepositSlips, false);
        this.hasUnprintedChecks = _.parseBool(options.hasUnprintedChecks, false);
        this.hasReconciledItems = _.parseBool(options.hasReconciledItems, false);
        this.totalCheckAmount = _.parseNumber(options.totalCheckAmount, 0);
        this.totalCheckCount = _.parseNumber(options.totalCheckCount, 0);
        this.totalDepositAmount = _.parseNumber(options.totalDepositAmount, 0);
        this.totalDepositCount = _.parseNumber(options.totalDepositCount, 0);
    }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.ordersID == 0) {
            errorList.push("Order ID is required.");
        }
        if (this.newBankCompanyID == 0) {
            errorList.push("New Escrow Bank is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class ChecksDto {
	constructor(options) {
        options = options || {};
        this.checksID = _.parseNumber(options.checksID, 0);
        this.clearedTemp = _.parseBool(options.clearedTemp, false);
        this.memo = options.memo || null;
        this.payee = options.payee || null;
        this.payeeAddress1 = options.payeeAddress1 || null;
        this.payeeAddress2 = options.payeeAddress2 || null;
        this.payeeCity = options.payeeCity || null;
        this.payeeName = options.payeeName || null;
        this.payeeState = options.payeeState || null;
        this.payeeZip = options.payeeZip || null;
        this.wireNumber = options.wireNumber || null;
        this.fedRefNumber = options.fedRefNumber || null;
        this.checkDate = options.checkDate || null;
        this.issueTime = options.issueTime || null;
        this.voidDate = options.voidDate || null;
        this.amount = _.parseNumber(options.amount, null);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
        this.checkNumber = _.parseNumber(options.checkNumber, null);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.reconTempID = _.parseNumber(options.reconTempID, null);
        this.sequence = _.parseNumber(options.sequence, null);
        this.usersID = _.parseNumber(options.usersID, null);
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
        this.checkStatus = _.parseNumber(options.checkStatus, 0);
        this.createSource = _.parseNumber(options.createSource, null);
        this.isManualPayee = _.parseBool(options.isManualPayee, false);
        this.isTransfer = _.parseBool(options.isTransfer, false);
        this.isWire = _.parseBool(options.isWire, false);
        this.isEFER = _.parseBool(options.isEFER, false);
        this.isConsolidated = _.parseBool(options.isConsolidated, false);
        this.consolidatedCheckID = _.parseNumber(options.consolidatedCheckID, null);
        this.reference = options.reference || null;
        this.receivingBankName = options.receivingBankName || null;
        this.receivingABA = options.receivingABA || null;
        this.receivingAccountNumber = options.receivingAccountNumber || null;
        this.receivingBankAvsResultDocumentId = options.receivingBankAvsResultDocumentId || null;
        this.receivingBankAvsResultDocumentLink = options.receivingBankAvsResultDocumentLink || null;
        this.receivingBankVerificationStatus = _.parseNumber(options.receivingBankVerificationStatus, null);
        this.receivingBankVerificationOrderNoteID = _.parseNumber(options.receivingBankVerificationOrderNoteID, null);
        this.receivingCustomerName = options.receivingCustomerName || null;
        this.receivingWireInstructions = options.receivingWireInstructions || null;
        this.offlineBankName = options.offlineBankName || null;
        this.offlineABA = options.offlineABA || null;
        this.offlineAccountNumber = options.offlineAccountNumber || null;
        this.offlineBankAvsResultDocumentId = options.offlineBankAvsResultDocumentId || null;
        this.offlineBankAvsResultDocumentLink = options.offlineBankAvsResultDocumentLink || null;
        this.offlineBankVerificationStatus = _.parseNumber(options.offlineBankVerificationStatus, null);
        this.offlineBankVerificationOrderNoteID = _.parseNumber(options.offlineBankVerificationOrderNoteID, null);
        this.offlineFurtherCredit = options.offlineFurtherCredit || null;
        this.offlineNotes = options.offlineNotes || null;
        this.wireDateOfEntry = options.wireDateOfEntry || null;
        this.transferredToOrdersID = _.parseNumber(options.transferredToOrdersID, null);
        this.wireBankAddress = options.wireBankAddress || null;
        this.wireBankCity = options.wireBankCity || null;
        this.wireBankState = options.wireBankState || null;
        this.wireBankZip = options.wireBankZip || null;
        this.offlineBankAddress = options.offlineBankAddress || null;
        this.offlineBankCity = options.offlineBankCity || null;
        this.offlineBankState = options.offlineBankState || null;
        this.offlineBankZip = options.offlineBankZip || null;
        this.previousCheckStatus = _.parseNumber(options.previousCheckStatus, null);
        this.expectedWireOutDate = options.expectedWireOutDate || null;
        this.isCashiersCheck = _.parseBool(options.isCashiersCheck, false);
        this.wireOutStatus = _.parseNumber(options.wireOutStatus, null);
        this.cashiersCheckStatus = _.parseNumber(options.cashiersCheckStatus, null);
        this.cashierCheckNumber = options.cashierCheckNumber || null;
        this.wireOutProcessorUsersID = _.parseNumber(options.wireOutProcessorUsersID, null);
        this.wireOutProcessorDate = options.wireOutProcessorDate || null;
        this.wireOutApprovalRequestorUsersID = _.parseNumber(options.wireOutApprovalRequestorUsersID, null);
        this.wireOutApprovalRequestorDate = options.wireOutApprovalRequestorDate || null;
        this.wireOutApproverUsersID = _.parseNumber(options.wireOutApproverUsersID, null);
        this.wireOutApproverDate = options.wireOutApproverDate || null;
        this.requestorUsersID = _.parseNumber(options.requestorUsersID, null);
        this.requestedDate = options.requestedDate || null;
        this.isCFER = _.parseBool(options.isCFER, false);
        this.isClearing = _.parseBool(options.isClearing, false);
        this.cFERSourceID = _.parseNumber(options.cFERSourceID, null);
        this.isInternationalTransfer = _.parseBool(options.isInternationalTransfer, false);
    }

    get isNew() { return this.checksID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        let validABARegEx = /^\d{9}$/;
        if (this.ordersID == 0) {
            errorList.push("Order ID is required.");
        }
        if (!_.isEmpty(_.trim(this.receivingABA)) && !validABARegEx.test(this.receivingABA)) {
            errorList.push("Invalid Receiving ABA.");
        }
        if (!_.isEmpty(_.trim(this.offlineABA)) && !validABARegEx.test(this.offlineABA)) {
            errorList.push("Invalid Offline ABA.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class CheckShortDto {
	constructor(options) {
        options = options || {};
        this.checksID = _.parseNumber(options.checksID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.checkNumber = _.parseNumber(options.checkNumber, null);
        this.numberDisplay = options.numberDisplay || null;
        this.fedRefNumber = options.fedRefNumber || null;
        this.description = options.description || "";
        this.checkDate = options.checkDate || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.expectedWireOutDate = options.expectedWireOutDate || null;
        this.checkStatus = _.parseNumber(options.checkStatus, 0);
        this.sequence = _.parseNumber(options.sequence, null);
        this.reconciliationDate = options.reconciliationDate || null;
        this.voidDate = options.voidDate || null;
        this.typeOfFundsDisplay = options.typeOfFundsDisplay || null;
        this.reference = options.reference || null;
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
        this.payee = options.payee || null;
        this.payeeAddress1 = options.payeeAddress1 || null;
        this.payeeAddress2 = options.payeeAddress2 || null;
        this.payeeCity = options.payeeCity || null;
        this.payeeState = options.payeeState || null;
        this.payeeZip = options.payeeZip || null;
        this.createSource = _.parseNumber(options.createSource, 0);
        this.memo = options.memo || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.isManualPayee = _.parseBool(options.isManualPayee, false);
        this.isTransfer = _.parseBool(options.isTransfer, false);
        this.isWire = _.parseBool(options.isWire, false);
        this.isEFER = _.parseBool(options.isEFER, false);
        this.isConsolidated = _.parseBool(options.isConsolidated, false);
        this.usersDisplayName = options.usersDisplayName || null;
        this.lineCount = _.parseNumber(options.lineCount, 0);
        this.useForwardingAddress = _.parseBool(options.useForwardingAddress, false);//Only used or check create
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);//Only used or check create
        this.consolidatedCheckStatus = _.parseNumber(options.consolidatedCheckStatus, null);
        this.isReconFinalized = _.parseBool(options.isReconFinalized, false);
        this.moneyMovementApprovalID = _.parseNumber(options.moneyMovementApprovalID, null);
        this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, null);
        this.useRowRedStrikeThrough = this.checkStatus == CheckStatus.StopPay || this.checkStatus == CheckStatus.Void;
        this.isRecoverable = _.parseBool(options.isRecoverable, false);
        this.isRecovered = _.parseBool(options.isRecovered, false);
        this.totalAdjustedAmount = _.parseNumber(options.totalAdjustedAmount, 0);
        this.hasValidAccountCode = _.parseBool(options.hasValidAccountCode, 0);
    }

    get inactive() { return this.checkStatus === 3 || this.checkStatus === 4 || !_.isNil(this.voidDate); }
    get isReconciled() { return !_.isNil(this.reconciliationDate); }
    get hasApprovalInfo() { return !_.isNil(this.moneyMovementApprovalID); }

    toDataObject() { return _.toPlainObject(this); }
}
export class CheckLineAdjustmentDto {
	constructor(options) {
        options = options || {};
        this.checkLineID = _.parseNumber(options.checkLineID, 0);
        this.description = options.description || null;
        this.amount = _.parseNumber(options.amount - options.totalAdjustedAmount, null);
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
        this.newAmount = _.parseNumber(options.newAmount, null) || _.parseNumber(options.amount - options.totalAdjustedAmount, null);
        this.totalAdjustedAmount = _.parseNumber(options.totalAdjustedAmount, null);
        this.newAccountingCodeID = _.parseNumber(options.newAccountingCodeID, null) || _.parseNumber(options.accountingCodeID, null);
        this.checksID = _.parseNumber(options.checksID, null);
        this.lineNumber = _.parseNumber(options.lineNumber, null);
        this.lineNumberStr = options.lineNumberStr || _.toString(this.lineNumber) || null;
        this.sequence = _.parseNumber(options.sequence, null);
        this.hasChanges = false;
    }
}

export class ConsolidatedCheckAdjustmentRequestDto {
	constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.checksID = _.parseNumber(options.checksID, 0);
        this.amount = _.parseNumber(options.amount, 0);
        this.adjustedAmount = _.parseNumber(options.adjustedAmount, 0);
        this.fullReversal = _.parseBool(options.fullReversal, false);
        this.requestorComment = options.requestorComment || null;
        this.checkLines = options.checkLines;
    }
}

export class CheckLineDto {
	constructor(options) {
        options = options || {};
        this.checkLineID = _.parseNumber(options.checkLineID, 0);
        this.description = options.description || null;
        this.amount = _.parseNumber(options.amount, null);
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
        this.checksID = _.parseNumber(options.checksID, null);
        this.lineNumber = _.parseNumber(options.lineNumber, null);
        this.lineNumberStr = options.lineNumberStr || _.toString(this.lineNumber) || null;
        this.sequence = _.parseNumber(options.sequence, null);
        this.createSource = _.parseNumber(options.createSource, null);
        this.hudMainID = _.parseNumber(options.hudMainID, null);
        this.cssMainID = _.parseNumber(options.cssMainID, null);
        this.syncSequence = _.parseNumber(options.syncSequence, null);
        this.cdfMainID = _.parseNumber(options.cdfMainID, null);
        this.realtorCommissionID = _.parseNumber(options.realtorCommissionID, null);
        this.paidByOtherPayeeID = _.parseNumber(options.paidByOtherPayeeID, null);
        this.recordingItemizationID = _.parseNumber(options.recordingItemizationID, null);
        this.totalAdjustedAmount = _.parseNumber(options.totalAdjustedAmount, 0);
        this.isSettlementStatementLine = _.parseBool(options.isSettlementStatementLine, false);
    }

    get isNew() { return this.checkLineID === 0; }

    get id() { return this.checkLineID; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.checksID) || _.parseNumber(this.checksID, -1) == -1) {
            errorList.push("Checks ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class CheckDepositLineLookupDto {
	constructor(options) {
        options = options || {};
        this.lineNumber = options.lineNumber || null;
        this.seedValue = _.parseNumber(options.seedValue, 0);
        this.lineDescription = options.lineDescription || null;
        this.amount = _.parseNumber(options.amount, 0);
    }
}

export class DepositDto {
	constructor(options) {
        options = options || {};
        this.depositID = _.parseNumber(options.depositID, 0);
        this.description = options.description || null;
        this.payor = options.payor || null;
        this.referenceNumber = options.referenceNumber || null;
        this.selected = options.selected || null;
        this.wireNumber = options.wireNumber || null;
        this.fedRefNumber = options.fedRefNumber || null;
        this.depositDate = options.depositDate || null;
        this.depositVerified = options.depositVerified || null;
        this.expectedWireDate = options.expectedWireDate || null;
        this.amount = _.parseNumber(options.amount, null);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.depositSlipID = _.parseNumber(options.depositSlipID, null);
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.reconTempID = _.parseNumber(options.reconTempID, null);
        this.sequence = _.parseNumber(options.sequence, null);
        this.transferCheckID = _.parseNumber(options.transferCheckID, null);
        this.transnumber = _.parseNumber(options.transnumber, null);
        this.usersID = _.parseNumber(options.usersID, null);
        this.typeFundID = _.parseNumber(options.typeFundID, 0);
        this.clearedTemp = _.parseBool(options.clearedTemp, false);
        this.createSource = _.parseNumber(options.createSource, 0);
        //this.memo = options.memo || null;
        this.isAvailableForDepositSlip = _.parseBool(options.isAvailableForDepositSlip, false);
        this.transferSlipID = _.parseNumber(options.transferSlipID, null);
        this.isAvailableForTransferSlip = _.parseBool(options.isAvailableForTransferSlip, false);
        this.isAvailable = _.parseBool(options.isAvailable, false);
        this.receiptPrinted = _.parseBool(options.receiptPrinted, false);
        this.hUDMainID = _.parseNumber(options.hUDMainID, null);
        this.cssMainID = _.parseNumber(options.cssMainID, null);
        this.reversalOriginatingDepositID = _.parseNumber(options.reversalOriginatingDepositID, null);
        this.depositStatus = _.parseNumber(options.depositStatus, 51);
        this.isAllowInterCompanyTransfer = _.parseBool(options.isAllowInterCompanyTransfer, false);
        this.isInterCompanyTransfer = _.parseBool(options.isInterCompanyTransfer, false);
        this.isClearing = _.parseBool(options.isClearing, false);
        this.originalLossRecovery = _.parseNumber(options.originalLossRecovery, null);
        this.receiptPrintedByUsersID = _.parseNumber(options.receiptPrintedByUsersID, null);
        this.receiptID = _.parseNumber(options.receiptID, null);
    }

    get isNew() { return this.depositID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get isReconciled() { return _.parseNumber(this.reconciliationID, 0) + _.parseNumber(this.reconTempID, 0) > 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.ordersID) || _.parseNumber(this.ordersID, -1) == -1) {
            errorList.push("Orders ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DepositShortDto {
	constructor(options) {
        options = options || {};
        this.depositID = _.parseNumber(options.depositID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.branchID = _.parseNumber(options.branchID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.transnumber = _.parseNumber(options.transnumber, null);
        this.transactionNumber = options.transactionNumber || null;
        this.numberDisplay = options.numberDisplay || null;
        this.description = options.description || null;
        this.depositDate = options.depositDate || null;
        this.payor = options.payor || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.expectedWireDate = options.expectedWireDate || null;
        this.depositStatus = _.parseNumber(options.depositStatus, 51);
        this.sequence = _.parseNumber(options.sequence, null);
        this.reconciliationDate = options.reconciliationDate || null;
        this.typeOfFundsDisplay = options.typeOfFundsDisplay || null;
        this.typeFundID = _.parseNumber(options.typeFundID, 0);
        this.depositVerified = options.depositVerified || null;
        this.referenceNumber = options.referenceNumber || null;
        this.receiptDisplay = options.receiptDisplay || null;
        this.createSource = _.parseNumber(options.createSource, 0);
        // this.memo = options.memo || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.depositSlipID = _.parseNumber(options.depositSlipID, null);
        this.isAvailableForDepositSlip = _.parseBool(options.isAvailableForDepositSlip, true);
        this.transferSlipID = _.parseNumber(options.transferSlipID, null);
        this.isAvailableForTransferSlip = _.parseBool(options.isAvailableForTransferSlip, true);
        this.isAvailable = _.parseBool(options.isAvailable, true);
        this.receiptPrinted = _.parseBool(options.receiptPrinted, false);
        this.wireNumber = options.wireNumber || null;
        this.fedRefNumber = options.fedRefNumber || null;
        this.receiptID = _.parseNumber(options.receiptID, null);
        this.lineCount = _.parseNumber(options.lineCount, 0);
        this.isReconFinalized = _.parseBool(options.isReconFinalized, false);
        this.usersDisplayName = options.usersDisplayName || null;
        this.originalGFNo = options.originalGFNo || null;
    }
    get isReconciled() { return !_.isNil(this.reconciliationDate); }
    get isVerified() { return !_.isNil(this.depositVerified); }

    get receiptOfDepositID (){
        if (_.parseNumber(this.receiptID, 0) == 0){ return null; }
        return this.bankCompanyID + '-' + this.receiptID
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class DepositLineDto {
	constructor(options) {
        options = options || {};
        this.depositLineID = _.parseNumber(options.depositLineID, 0);
        this.description = options.description || null;
        this.amount = _.parseNumber(options.amount, null);
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
        this.depositID = _.parseNumber(options.depositID, null);
        this.lineNumber = _.parseNumber(options.lineNumber, null);
        this.lineNumberStr = options.lineNumberStr || _.toString(this.lineNumber) || null;
        this.sequence = _.parseNumber(options.sequence, null);
        this.createSource = _.parseNumber(options.createSource, null);
        this.hUDMainID = _.parseNumber(options.hUDMainID, null);
        this.cssMainID = _.parseNumber(options.cssMainID, null);
        this.syncSequence = _.parseNumber(options.syncSequence, null);
        this.cdfMainID = _.parseNumber(options.cdfMainID, null);
        this.isSettlementStatementLine = _.parseBool(options.isSettlementStatementLine, false);
    }

    get isNew() { return this.depositLineID === 0; }

    get id() { return this.depositLineID; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.depositID) || _.parseNumber(this.depositID, -1) == -1) {
            errorList.push("Deposit ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DepositSlipDto {
	constructor(options) {
        options = options || {};
        this.depositSlipID = _.parseNumber(options.depositSlipID, 0);
        this.clearedTemp = _.parseBool(options.clearedTemp, false);
        this.dateVerified = options.dateVerified || null;
        this.depositDate = options.depositDate || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, 0);
        this.branchID = _.parseNumber(options.branchID, 1);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.reconciliationIDTemp = _.parseNumber(options.reconciliationIDTemp, null);
        this.verifiedByUsersID = _.parseNumber(options.verifiedByUsersID, null);
        this.verifiedByName = options.verifiedByName || null;
    }

    get isNew() { return this.depositSlipID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get reconID() {
        if (_.parseNumber(this.reconciliationIDTemp, -1) > 0 && _.parseBool(this.clearedTemp, false)) {
            return this.reconciliationIDTemp;
        }
        if (_.parseNumber(this.reconciliationID, -1) > 0) {
            return this.reconciliationID;
        }
        return null;
    }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.bankCompanyID) || _.parseNumber(this.bankCompanyID, -1) == -1) {
            errorList.push("Bank is required.");
        }
        if (_.isNil(this.branchID) || _.parseNumber(this.branchID, -1) == -1) {
            errorList.push("Branch ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class LedgerShortDto {
	constructor(options, showAnticipatedDeposits = true) {
        options = options || {};
        if (_.has(options, 'checksID')) {
            this.isCheck = true;
            this.id = `C${_.parseNumber(options.checksID, 0)}`;
            this.status = _.parseNumber(options.checkStatus, 0);
            this.datePrinted = options.checkDate || null;
            this.referenceNumber = options.reference || null;
            this.expectedWireDate = options.expectedWireOutDate || null;
            this.sortOrder = 1;
            this.payor = null;
            this.payee = options.payee || null;
            this.checksID = options.checksID || null;
            this.useRowRedStrikeThrough = this.status == CheckStatus.StopPay || this.status == CheckStatus.Void;
            this.hasValidAccountCode = _.parseBool(options.hasValidAccountCode, 0);
        } else {
            this.isCheck = false;
            this.id = `D${_.parseNumber(options.depositID, 0)}`;
            let depositStatus =  _.parseNumber(options.depositStatus, 51);
            this.status = showAnticipatedDeposits === false && options.depositStatus === 51 ? 0 : depositStatus;
            this.datePrinted = options.depositDate || null;
            this.referenceNumber = options.referenceNumber || null;
            this.expectedWireDate = options.expectedWireDate || null;
            this.sortOrder = 0;
            this.payee = null;
            this.payor = options.payor || null;
            this.depositID = options.depositID || null;
        }
        this.amount = _.parseNumber(options.amount, 0);
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
        this.numberDisplay = options.numberDisplay || null;
        this.fedRefNumber = options.fedRefNumber || null;
        this.description = options.description || null;
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
        this.payeeAddress1 = options.payeeAddress1 || null;
        this.payeeAddress2 = options.payeeAddress2 || null;
        this.payeeCity = options.payeeCity || null;
        this.payeeState = options.payeeState || null;
        this.payeeZip = options.payeeZip || null;
        this.sequence = _.parseNumber(options.sequence, null);
        this.reconciliationDate = options.reconciliationDate || null;
        this.typeOfFundsDisplay = options.typeOfFundsDisplay || null;
        this.depositVerified = options.depositVerified || null;
        this.receiptDisplay = options.receiptDisplay || null;
        this.memo = options.memo || null;
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.isWire = _.parseBool(options.isWire, false);
        this.isTransfer = _.parseBool(options.isTransfer, false);
        this.lineCount = _.parseNumber(options.lineCount, 0);
        this.isReconFinalized = _.parseBool(options.isReconFinalized, false);
        this.moneyMovementApprovalID = _.parseNumber(options.moneyMovementApprovalID, null);
        this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, null);
        this.isRecoverable = _.parseBool(options.isRecoverable, false);
        this.isRecovered = _.parseBool(options.isRecovered, false);
        this.totalAdjustedAmount = _.parseNumber(options.totalAdjustedAmount, 0);
        this.originalGFNo = options.originalGFNo || null;
    }

    get inactive() { return this.status === 3 || this.status === 4; }
    get payorPayee() { return this.payor || this.payee || null; }
    get isReconciled() { return !_.isNil(this.reconciliationDate); }
    get isVerified() { return !_.isNil(this.depositVerified); }
    get hasApprovalInfo() { return !_.isNil(this.moneyMovementApprovalID); }

    toDataObject() { return _.toPlainObject(this); }
}
export class LedgerShortLineDto {
	constructor(options) {
        options = options || {};
        if (_.has(options, 'checkLineID')) {
            this.checkLineID = _.parseNumber(options.checkLineID, 0);
            this.depositLineID = null;
            this.checksID = _.parseNumber(options.checksID, null);
            this.depositID = null;
        } else {
            this.checkLineID = null;
            this.depositLineID = _.parseNumber(options.depositLineID, 0);
            this.checksID = null;
            this.depositID = _.parseNumber(options.depositID, null);
        }
        this.description = options.description || null;
        this.amount = _.parseNumber(options.amount, null);
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
        this.lineNumber = _.parseNumber(options.lineNumber, null);
        this.lineNumberStr = options.lineNumberStr || _.toString(this.lineNumber) || null;
    }
    get id() { return _.parseNumber(this.depositLineID, 0) + _.parseNumber(this.checkLineID, 0); }
}

export class MoneyMovementApprovalDto {
	constructor(options) {
        options = options || {};
        this.moneyMovementApprovalID = _.parseNumber(options.moneyMovementApprovalID, 0);
        this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, null);
        this.entityID = _.parseNumber(options.entityID, null);
        this.requestedOn = options.requestedOn || null;
        this.requestorUsersID = _.parseNumber(options.requestorUsersID, null);
        this.requestorName = options.requestorName || null;
        this.approvedOn = options.approvedOn || null;
        this.approverUsersID = _.parseNumber(options.approverUsersID, null);
        this.approverName = options.approverName || null;
        this.approversComment = options.approversComment || null;
        this.rejectedOn = options.rejectedOn || null;
        this.requestedAmount = _.parseNumber(options.requestedAmount, 0);
        this.requestorComment = options.requestorComment || null;
        this.approverUsersID = _.parseNumber(options.approverUsersID, null);
        this.approvalStatusID = _.parseNumber(options.approvalStatusID, 0);
        this.debtorRolesID = _.parseNumber(options.debtorRolesID, null);
        this.lossAdvanceReasonID = _.parseNumber(options.lossAdvanceReasonID, null);
        this.lossAdvanceReasonCategoryID = _.parseNumber(options.lossAdvanceReasonCategoryID, null);
        this.autoConsolidatePayeeID = _.parseNumber(options.autoConsolidatePayeeID, null);
        this.lossAdvanceReasonCategory = options.lossAdvanceReasonCategory || null;
        this.lossAdvanceReason = options.lossAdvanceReason || null;
        this.isRecoverable = _.parseBool(options.isRecoverable, true);
        this.isRecovered = _.parseBool(options.isRecovered, false);
    }

    get isApproved() { return _.isEqual(this.approvalStatusID, 1) || _.isEqual(this.approvalStatusID, 2); }
    get actionDate() { return this.approvedOn || this.rejectedOn; }

    toDataObject() { return _.toPlainObject(this); }
}
export class LossAdvanceDebtorInformation {
    constructor (options) {
        options = options || {};
        this.lossAdvanceDebtorInformationID = _.parseNumber(options.lossAdvanceDebtorInformationID, 0);
        this.moneyMovementApprovalID = _.parseNumber(options.moneyMovementApprovalID, 0);
        this.company = options.company || null;
        this.name = options.name || null;
        this.address = options.address || null;
        this.city = options.city || null;
        this.zip = options.zip || null;
        this.state = options.state || null;
        this.phone = options.phone || null;
        this.workPhone = options.workPhone || null;
        this.cellPhone = options.cellPhone || null;
        this.emailAddress = options.emailAddress || null;
        this.realtorCompanyName = options.realtorCompanyName || null;
        this.realtorCompanyPhone = options.realtorCompanyPhone || null;
        this.realtorContactName = options.realtorContactName || null;
        this.realtorContactHomePhone = options.realtorContactHomePhone || null;
        this.realtorContactCellPhone = options.realtorContactCellPhone || null;
    }
}

export class InvestmentDto {
	constructor(options) {
        options = options || {};
        this.investmentID = _.parseNumber(options.investmentID, 0);
        this.accountNumber = options.accountNumber || null;
        this.bankName = options.bankName || null;
        this.lastActivity = options.lastActivity || null;
        this.accountBalance = _.parseNumber(options.accountBalance, 0);
        this.disbursements = _.parseNumber(options.disbursements, 0);
        this.receipts = _.parseNumber(options.receipts, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.sequence = _.parseNumber(options.sequence, null);
        this.escrowMemo = options.escrowMemo || null;
    }

    get isNew() { return this.investmentID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.ordersID == 0) {
            errorList.push("Order ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class InvestmentCheckDto {
	constructor(options) {
        options = options || {};
        this.investmentCheckID = _.parseNumber(options.investmentCheckID, 0);
        this.investmentID = _.parseNumber(options.investmentID, 0);
        this.sequence = _.parseNumber(options.sequence, null);
        this.checkDate = options.checkDate || null;
        this.description = options.description || null;
        this.amount = _.parseNumber(options.amount, null);
        this.sourceChecksID = _.parseNumber(options.sourceChecksID, null);
    }

    get isNew() { return this.investmentCheckID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.investmentID == 0) {
            errorList.push("Investment ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class InvestmentDepositDto {
	constructor(options) {
        options = options || {};
        this.investmentDepositID = _.parseNumber(options.investmentDepositID, 0);
        this.investmentID = _.parseNumber(options.investmentID, null);
        this.sequence = _.parseNumber(options.sequence, null);
        this.depositDate = options.depositDate || null;
        this.description = options.description || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.sourceDepositID = _.parseNumber(options.sourceDepositID, null);
    }

    get isNew() { return this.investmentDepositID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.investmentID == 0) {
            errorList.push("InvestmentID ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}
export class InvestmentLedgerDto {
	constructor(options) {
        options = options || {};
        if (_.has(options, 'investmentCheckID') && !_.isNull(_.get(options, 'investmentCheckID'))) {
            this.isCheck = true;
            this.investmentLedgerID = `C${_.parseNumber(options.investmentCheckID, 0)}`;
            this.investmentCheckID = _.parseNumber(options.investmentCheckID, null);
            this.ledgerDate = options.ledgerDate || options.checkDate || null;
            this.sourceChecksID = _.parseNumber(options.sourceChecksID, null);
            this.sortOrder = 1;
        } else {
            this.isCheck = false;
            this.investmentLedgerID = `D${_.parseNumber(options.investmentDepositID, 0)}`;
            this.investmentDepositID = _.parseNumber(options.investmentDepositID, null);
            this.ledgerDate = options.ledgerDate || options.depositDate || null;
            this.sourceDepositID = _.parseNumber(options.sourceDepositID, null);
            this.sortOrder = 0;
        }
        this.investmentID = _.parseNumber(options.investmentID, 0);
        this.sequence = _.parseNumber(options.sequence, null);
        this.amount = _.parseNumber(options.amount, null);
        this.description = options.description || null;
    }
    get isNew() { return (this.isCheck === true && this.investmentCheckID === 0)
                      || (this.isCheck === false && this.investmentDepositID === 0); }

    toDataObject() { return _.toPlainObject(this); }
}

export class InvestmentDepositRequestDto {
	constructor(options) {
        options = options || {};
        this.investmentID = _.parseNumber(options.investmentID, 0);
        this.accountNumber = options.accountNumber || null;
        this.bankName = options.bankName || null;
        this.depositID = _.parseNumber(options.depositID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.amount = _.parseNumber(options.amount, 0);
        this.useCheck = _.parseBool(options.useCheck);
    }
}

export class TransferSlipDto {
	constructor(options) {
        options = options || {};
        this.transferSlipID = _.parseNumber(options.transferSlipID, 0);
        this.clearedTemp = _.parseBool(options.clearedTemp, false);
        this.dateVerified = options.dateVerified || null;
        this.transferDate = options.transferDate || null;
        this.amount = _.parseNumber(options.amount, null);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.branchID = _.parseNumber(options.branchID, null);
        this.reconciliationID = _.parseNumber(options.reconciliationID, null);
        this.reconciliationIDTemp = _.parseNumber(options.reconciliationIDTemp, null);
        this.verifiedByUsersID = _.parseNumber(options.verifiedByUsersID, null);
    }

    get isNew() { return this.transferSlipID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.bankCompanyID == 0) {
            errorList.push("Bank Company ID is required.");
        }
        if (this.branchID == 0) {
            errorList.push("Branch ID is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class SettlementStatementSelection{
    constructor(options){
        this.description = options.description;
        this.isHUD = _.parseBool(options.isHUD, false);
        this.isCDF = _.parseBool(options.isCDF, false);
        this.isCSS = _.parseBool(options.isCSS, false);
        this.combineFunds = false;
    }

    get key(){
        switch(this.description){
            case "CDF":
                return 1;
            case "HUD":
                return 2;
            case "CSS":
                return 3;
        }
        return 0;
    }

    get value(){
        return this.description;
    }
}

export class SyncCheckDepositDto{
    constructor(options){
        this.clientKey = _.uniqueId("sync-check-deposit-");
        this.existingItemID = _.parseNumber(options.existingItemID, null);
        this.isCheck = _.parseBool(options.isCheck);
        this.canAutoFix = _.parseBool(options.canAutoFix);
        this.line = _.parseNumber(options.line, null);
        this.lineDescription = options.lineDescription || "";
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
        this.payeePayor = options.payeePayor || "";
        this.description = options.description || "";
        this.action = options.action || "";
        this.amount = _.parseNumber(options.amount, 0);
        this.accountingCodeID = _.parseNumber(options.AccountingCodeID, null);
        this.sourceDocument = _.parseNumber(options.sourceDocument, 0);
        this.settlementID = _.parseNumber(options.settlementID, null);
        this.skipCombiningChecks = _.parseBool(options.skipCombiningChecks);
        this.realtorCommissionID = _.parseNumber(options.realtorCommissionID, null);
        this.settlementDescription = options.settlementDescription || "";
        this.syncType = _.parseNumber(options.syncType, 0);
        this.date = options.date || null;
    }
}

export class CanCreateReceiptsAndDisbursementsResult{
    constructor(options){
        this.canCreate = _.parseBool(options.canCreate);
        this.canSync = _.parseBool(options.canSync);
        this.settlementStatementSelections = _.map(options.settlementStatementSelections, s => new SettlementStatementSelection(s));
    }
}
