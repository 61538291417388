<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
            dismissable
        />
        <rq-banner
            variant="warn"
            :message="maxLineWarningMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showMaxItemsBanner"
            dismissable
        />
        <rq-banner
            variant="warn"
            message="All auto-calculations are disabled."
            icon="fas fa-exclamation-triangle"
            :visible="!localDetail.enableCalculation"
            dismissable
        />
        <div class="rq-container my-2">
            <rq-page-section class="mb-3">
                <div class="row">
                    <div class="col-4 form-group" :class="{'has-error' : showValidationBanner }">
                        <label for="drp_taxCategory">Tax Category</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_taxCategory')"
                            display-expr="display"
                            value-expr="taxCategory"
                            :items="prorationDefaults"
                            v-model="localDetail.taxCategory"
                            @value-changed="onSourceChanged"
                            :disabled="isEditMode"
                        />
                        <span v-if="showValidationBanner" class="has-error">{{validationBannerMessage}}</span>
                    </div>
                    <div class="col-4">
                        <rq-action-form-group
                            label="Description"
                            action-automation-id="btn_restore_description"
                            action-label="Revert"
                            action-class="overridden"
                            :has-error="v$.localDetail.description.$error"
                            :show-action="showDescriptionRevert"
                            @action-click="overrideDescription(false)"
                            required>
                            <input
                                id="txt_description"
                                automation_id="txt_description"
                                type="text"
                                class="form-control"
                                maxlength="40"
                                placeholder="Description"
                                v-model="v$.localDetail.description.$model"
                                @change="overrideDescription(true)"
                                :disabled="disableControl"
                            >
                            <rq-validation-feedback>Description is required.</rq-validation-feedback>
                        </rq-action-form-group>
                    </div>
                    <div class="col-4 form-group">
                        <label></label>
                        <b-form-checkbox
                            automation_id="chk_per_diem"
                            v-model="localDetail.rent"
                            :disabled="(!paidInAdvance && !notYetDue) || disableControl">Per Diem Amount
                        </b-form-checkbox>
                    </div>
                </div>
            </rq-page-section>

            <div class="row">
                <div class="col-8">
                    <rq-action-form-group
                        label="Payment Due"
                        labelFor="drp_payment_due"
                        :show-action="paidInAdvance">
                        <template #action>
                            <b-form-checkbox
                                :disabled="disableControl"
                                automation_id="chk_include_in_tax_proration_credit"
                                v-model="localDetail.includeIn1099">Include in Tax Proration Credit
                            </b-form-checkbox>
                        </template>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_payment_due')"
                            :items="paymentDueTypes"
                            value-expr="id"
                            display-expr="name"
                            :disabled="disableControl"
                            v-model.number="localDetail.paymentDue"
                            @value-changed="onPaymentDueChanged"
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-4 form-group" v-if="!isEditMode">
                    <label for="drp_new_proration_method">Proration Method</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_new_proration_method')"
                        :items="prorationMethods"
                        :disabled="disableControl"
                        v-model.number="localDetail.prorationMethod"
                        value-expr="id"
                        display-expr="name"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-4 form-group">
                    <label for="drp_billing_period"># of Billing Periods Per Year</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_billing_period')"
                        :items="billingPeriods"
                        :disabled="localDetail.rent || disableControl"
                        v-model.number="localDetail.periodsPerYear"
                        value-expr="id"
                        display-expr="name"
                        @value-changed="periodsPerYearChanged"
                    />
                </div>
                <div class="col-4 form-group">
                    <label for="drp_proration_type">Proration Type</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_proration_type')"
                        :items="prorationTypes"
                        :disabled="localDetail.rent || billingDaily || disableControl"
                        v-model.number="localDetail.daysInYear"
                        value-expr="id"
                        display-expr="name"
                        @change="daysInYearChanged"
                    />
                </div>
                <div class="col-4 form-group">
                    <rq-action-form-group
                        label="Tax Amount"
                        action-automation-id="btn_restore_current_amount"
                        action-label="Revert to Tax Amount"
                        action-class="overridden"
                        :show-action="showCurrentAmountRevert"
                        @action-click="overrideAmount(false)">
                        <rq-input-number
                            id="txt_amount"
                            defaultValue="0"
                            decimals="2"
                            prefix="$"
                            :class="{'overridden' : showCurrentAmountRevert}"
                            :disabled="disableTaxAmount"
                            v-model="amount"
                        />
                    </rq-action-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-4 form-group">
                    <label for="txt_cd_seller">C/D Seller</label>
                    <rq-input-number
                        id="txt_cd_seller"
                        defaultValue="0"
                        decimals="2"
                        prefix="$"
                        :disabled="this.localDetail.enableCalculation"
                        v-model="this.localDetail.sellerAmount"
                    />
                </div>
                <div class="col-4 form-group">
                    <label for="txt_cd_buyer">C/D Buyer</label>
                    <rq-input-number
                        id="txt_cd_buyer"
                        defaultValue="0"
                        decimals="2"
                        prefix="$"
                        :disabled="this.localDetail.enableCalculation"
                        v-model="this.localDetail.buyerAmount"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <rq-action-form-group
                        label="From Date"
                        action-automation-id="btn_restore_from_date"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="showFromDateRevert"
                        @action-click="restoreFromDate">
                        <rqdx-date-box
                            id="dtp_from_date"
                            :max="localDetail.toDate"
                            :disabled="disableControl"
                            v-model="localDetail.fromDate"
                            @valueChanged="onUserEditingDate"
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-4">
                    <rq-action-form-group
                        label="To Date"
                        action-automation-id="btn_restore_to_date"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="showToDateRevert"
                        @action-click="restoreToDate">
                        <rqdx-date-box
                            id="dtp_to_date"
                            :min="localDetail.fromDate"
                            :disabled="disableControl"
                            v-model="localDetail.toDate"
                            @valueChanged="onUserEditingDate"
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-4 form-group">
                        <label>Enable Calculation</label>
                        <div>
                            <rqSwitch
                                automation_id="chk_enable_calculation"
                                type="text"
                                variant="primary"
                                on="Yes"
                                off="No"
                                size="lg"
                                :disabled="readOnly"
                                v-model="localDetail.enableCalculation"
                                @change="onChangeCalculationEnabled"
                            />
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import { ProrateDto, PaymentDue, SETTLEMENT_TYPE } from '@settlement/models';
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";

    export default {
        props: {
            detail: { type: Object },
            prorations: { type: Array },
            taxCategories: [],
            isEditMode: { type: Boolean, default: false },
            commonProrationData: { type: Object },
            readOnly: { type: Boolean, default: false },
            propertyId: { type: Number, default: 0 },
            settlementType: { type: Number, default: SETTLEMENT_TYPE.CDF }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                localDetail: new ProrateDto(),
                selectedSourceId: 0,
                sourceAlreadyExist: false,
                showBanner: false,
                canCheckDate: true,
                isDatesCalculated: false,
            }
        },
        validations() {
            return {
                localDetail: {
                    description: { required }
                }
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "propertyList"
            ]),
            isCdf() { return this.settlementType === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.settlementType === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.settlementType === SETTLEMENT_TYPE.HUD_2010; },
            showDescriptionRevert() {
                if (this.readOnly) return false;
                if (!this.localDetail.enableCalculation) return false;

                return this.localDetail.description !== this.localDetail.originalDescription
                        && _.parseNumber(this.localDetail.taxCategory, 0) > 0;
            },
            prorationDefaults() {
                if (this.taxCategories && this.taxCategories.length > 0)
                    return this.taxCategories;
                else
                    return this.lookupHelpers.getProrationDefaults();
            },
            prorationRegionDefault() { return _.find(this.lookupHelpers.getProrationDefaults(), ["taxCategory", 0]) || {}; },
            paidInAdvance() {
                return this.localDetail.paymentDue === PaymentDue.PaidInAdvance
                    || this.localDetail.paymentDue === PaymentDue.PaidInAdvance_BuyerOnly
                    || this.localDetail.paymentDue === PaymentDue.PaidInAdvance_SellerOnly;
            },
            notYetDue() {
                return this.localDetail.paymentDue === PaymentDue.NotYetDone
                    || this.localDetail.paymentDue === PaymentDue.Due_Debit_BuyerSeller
                    || this.localDetail.paymentDue === PaymentDue.Due_DebitCredit_Seller
                    || this.localDetail.paymentDue === PaymentDue.Due_DebitBuyerOnly
                    || this.localDetail.paymentDue === PaymentDue.Due_DebitSellerOnly;
            },
            paymentDueTypes() {
                let items = this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_PAYMENT_DUE_TYPES);
                if (this.rent || this.billingDaily) {
                    return _.filter(items, function(item) { return !item.name.startsWith("Due - "); });
                }
                return items;
            },
            rent() {
                return _.parseBool(_.get(this.localDetail, "rent"));
            },
            billingDaily() {
                return this.periodsPerYear == 0;
            },
            periodsPerYear() {
                return _.parseNumber(_.get(this.localDetail, "periodsPerYear"), 0);
            },
            prorationMethods() {
                return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_METHODS);
            },
            billingPeriods() {
                return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_BILLING_PERIODS);
            },
            prorationTypes() {
                return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_TYPES);
            },
            showCurrentAmountRevert() {
                if (this.readOnly || !_.parseBool(this.localDetail.enableCalculation)) return false;

                return _.parseNumber(this.localDetail.taxCategory, 0) > 0 && this.localDetail.overrideAmount === true;
            },
            showFromDateRevert() {
                if (this.readOnly || !_.parseBool(this.localDetail.enableCalculation)) return false;

                return this.canCheckDate ? this.paidInAdvance && this.commonProrationData.prorationDate !== this.localDetail.fromDate : false;

            },
            showToDateRevert() {
                if (this.readOnly || !_.parseBool(this.localDetail.enableCalculation)) return false;

                return this.canCheckDate ? !this.paidInAdvance && this.commonProrationData.prorationDate !== this.localDetail.toDate : false;
            },
        showValidationBanner() {
            return this.sourceAlreadyExist;
        },
        showMaxItemsBanner() {
            let propertyProrations = _.filter(this.prorations, p => p.propertyID === this.propertyId);
            if (this.isHud)
                return propertyProrations.length >= 9 && this.detail.prorateID === 0;
            if (this.isCdf)
                return propertyProrations.length >= 6 && this.detail.prorateID === 0;
            else return false;
        },
        amount: {
            get() {
                return this.localDetail.overrideAmount === true || !this.localDetail.enableCalculation ? this.localDetail.amount : this.localDetail.currentTaxes;
            },
            set(newVal) {
                this.localDetail.amount = newVal;
                if (this.localDetail.taxCategory !== 0) {
                    this.localDetail.overrideAmount = this.localDetail.amount !== this.localDetail.currentTaxes;
                    this.updateFullOverride();
                }
            }
        },
        //RQO-31880 not used so commented out
        // formattedToDate() {
        //     return moment(this.localDetail.toDate).format('MM/DD/YYYY');
        // },
        disableControl() {
            return this.readOnly || this.sourceAlreadyExist;
        },
        isFullOverride(){
            return this.localDetail.overrideAmount && this.localDetail.overrideProrationDate;
        },
        isProrationDue(){
            if (this.localDetail.taxCategory == 0)
                return false;
            else
                switch(this.localDetail.paymentDue){
                    case 1://DueDebitBoth
                    case 2://DueDebitCredit
                    case 4://DueDebitBuyer
                    case 5://DueDebitSeller
                        return true;
                    default:
                        return false;
            }
        },
        disableTaxAmount(){
            if (!this.localDetail.enableCalculation) return false;
            if (this.disableControl) return true;
            return this.isProrationDue;
        },
        showInvalidFromDate() {
            return this.$v.localDetail.fromDate.$dirty && !this.$v.localDetail.fromDate.validFromDate;
        },
        maxLineWarningMessage() {
            if (this.isHud)
                return "The maximum number of Prorations for HUD is 10.";
            if (this.isCdf)
                return "The maximum number of Prorations for CDF/ALTA is 6.  Any additional Prorations will not be included on the CDF/ALTA.";
            else return "";
        }
    },
    watch: {
        "localDetail.fromDate":function(newValue){
            if (!this.isDatesCalculated) return;
            if(this.showFromDateRevert){
                this.localDetail.prorationDate = newValue;
            }
            else{
                if(!this.showToDateRevert){
                    this.localDetail.prorationDate = this.commonProrationData.prorationDate;
                }
            }
            this.localDetail.overrideProrationDate = this.showToDateRevert || this.showFromDateRevert;
            this.updateFullOverride();
        },
        "localDetail.toDate":function(newValue){
            if (!this.isDatesCalculated) return;
            if(this.showToDateRevert){
                this.localDetail.overrideProrationDate = true;
                this.localDetail.prorationDate = newValue;
            }
            else{
                if(!this.showFromDateRevert){
                    this.localDetail.prorationDate = this.commonProrationData.prorationDate;
                }
            }
            this.localDetail.overrideProrationDate = this.showToDateRevert || this.showFromDateRevert;
            this.updateFullOverride();
        },
        "localDetail.paymentDue":function(newValue){
            if (!this.localDetail.enableCalculation) return;
            if(this.isProrationDue){
                this.localDetail.overrideAmount = false;
            }
            // RQO-18126 - UI updates to paymentDue initiates a calculateDates() which may take a bit before we can check dates
            // Set flag to ensure we check and compare dates after calculateDates() has finished
            this.canCheckDate = false;
        }
    },

        created() {
            this.initializeLocalDetail();
            this.initializeNonReactiveVariables();
            this.calculateDates();
        },
        methods: {
            onChangeCalculationEnabled(e) {
                this.overrideDescription(false);
                this.calculateDates(true);
            },
            initializeLocalDetail() {
                this.localDetail = _.cloneDeep(this.detail);
                this.localDetail.prorationMethod = this.commonProrationData.prorationMethod;
                this.localDetail.prorationDate = this.commonProrationData.prorationDate;
                this.localDetail.propertyID = this.propertyId;
                if(!this.isEditMode){
                    this.localDetail.enableCalculation = true;
                }
                if (!this.isEditMode && !_.isEmpty(this.prorationRegionDefault)) {
                    this.localDetail.daysInYear = this.prorationRegionDefault.daysInYear;
                    this.localDetail.paymentDue = this.prorationRegionDefault.paymentDue;
                    this.localDetail.periodsPerYear = this.prorationRegionDefault.periodsPerYear;
                    this.localDetail.prorationMethod = this.prorationRegionDefault.prorationMethod;
                }
                this.localDetail.daysInYear = this.billingDaily ? 0 : this.localDetail.daysInYear;
                this.originalData = _.cloneDeep(this.detail);
            },
            initializeNonReactiveVariables() {
                this.validationBannerMessage = "Proration with the selected Tax Category already exists"
            },
            onUserEditingDate(e){
                let codeTriggered = e.event === undefined; // dev-ex change value is identified as undefined if data is programatically updated

                if (!codeTriggered) // We want to identify when user initiates a date change on a non overridable date field
                    this.setIsDateEdited(true);
            },
            messageBox (message, title) {
                this.$dialog.messageBox(title || 'Info', message);
            },
            overrideDescription(override){
                if (!this.localDetail.enableCalculation) return;
                if (this.localDetail.taxCategory === 0) return;
                this.localDetail.overrideDescription = override;
                if (!override) {
                    this.localDetail.description = this.localDetail.originalDescription;
                }
            },
            overrideAmount(override) {
                if (!this.localDetail.enableCalculation) {
                    this.localDetail.overrideAmount = false;
                }
                if (this.localDetail.taxCategory === 0) return;
                this.localDetail.overrideAmount = override;

                this.updateFullOverride();

                if (override) return;
                this.localDetail.amount = null;
            },
            updateFullOverride() {
                this.localDetail.fullOverride = this.localDetail.overrideAmount && this.localDetail.overrideProrationDate;
            },
            setIsDateEdited(value) {
                this.localDetail.isDateEdited = value;
            },
            calculateDates(revertAll=false) {
                let self = this;
                self.isDatesCalculated = true;
                if (revertAll) {
                    // To minimize confusion when user adjusts certain field values we may want to revert all the dates from it's previous
                    // state to observe and respect the newly calculated value over the overridden ones that were there prior
                    self.localDetail.overrideProrationDate = false; // reset override flag
                    self.localDetail.amount = null;
                    this.setIsDateEdited(false); // reset IsDateEdited flag
                }
                let apiPromise = self.$api.ProrationsApi.calculateDates(self.localDetail);
                return self.$rqBusy.wait(apiPromise)
                    .then( (proration) => {
                        // In scenario where we want to revert dates to intialized derived from / to date data
                        if (revertAll) {
                            if (this.paidInAdvance) {
                                this.localDetail.fromDate = this.commonProrationData.prorationDate;
                                this.localDetail.prorationDate = this.commonProrationData.prorationDate;
                                this.localDetail.toDate = proration.toDate;
                            }

                            if (this.notYetDue) {
                                this.localDetail.toDate = this.commonProrationData.prorationDate;
                                this.localDetail.prorationDate = this.commonProrationData.prorationDate;
                                this.localDetail.fromDate = proration.fromDate;
                            }
                        }
                        else {
                            self.localDetail.fromDate = proration.fromDate;
                            self.localDetail.toDate = proration.toDate;
                        }
                        this.canCheckDate = true;
                        self.localDetail.overrideProrationDate = this.showToDateRevert || this.showFromDateRevert;
                    })
                    .catch(error => {
                        let message = 'Error calculating proration data';
                        self.$log.fatal(`ProrationDetail - ${message}`, error);
                        self.$toast.error({ message: message });
                    });
            },
            daysInYearChanged() {
                if(!this.localDetail.enableCalculation) return;
                if (this.paidInAdvance && this.localDetail.daysInYear === 1) {
                    this.localDetail.fromDate = this.localDetail.toDate = this.commonProrationData.prorationDate;
                } else {
                    this.calculateDates();
                }
            },
            restoreFromDate() {
                this.localDetail.fromDate = this.commonProrationData.prorationDate;
            },
            restoreToDate() {
                this.localDetail.toDate = this.commonProrationData.prorationDate;
            },
            onPaymentDueChanged(e) {
                if(_.isNil(e.event) || !this.localDetail.enableCalculation) return;
                if (!this.paidInAdvance && this.localDetail.includeIn1099) {
                    this.messageBox(`Changing <b>Payment Due</b> from <b>Paid In Advance</b> will uncheck <b>Include in Tax Proration Credit</b>`);
                    this.localDetail.includeIn1099 = false;
                }
                this.canCheckDate = false;
                let revertAll=true; // For payment due change there is a different set of calculated dates so we ensure we revert the overrides in favor for them
                this.calculateDates(revertAll);
            },
            onSourceChanged(e) {
                if(_.isNil(e.event)) return;
                const self = this;
                let selectedSource = e.component.option("selectedItem");

                self.sourceAlreadyExist = _.some(self.prorations, item => item.taxCategory === selectedSource.taxCategory && item.propertyID === self.propertyId);

                let newData = {
                    propertyID: self.propertyId,
                    prorationDate: self.commonProrationData.prorationDate,
                    prorationMethod: self.commonProrationData.prorationMethod,
                    originalDescription: selectedSource.description
                };
                self.localDetail = new ProrateDto({ ...newData, ...selectedSource });

                self.calculateDates();
            },
            isValid() {
                this.v$.$touch();
                this.showBanner = true;
                return !this.v$.$error;
            },
            periodsPerYearChanged() {
                if(!this.localDetail.enableCalculation) return;
                this.localDetail.daysInYear = this.billingDaily ? 0 : this.localDetail.daysInYear;
                this.calculateDates();
            }

        }
    }
</script>
