import MainPage from "./OrderMain";
import LoansModule from "./loans/";
import PropertyModule from "./property/";
import ContactsModule from "./contacts";
import NotesPage from "../notes/OrderNotes";
import CplPage from "../cpl/CplPage";
import ExtensionPage from "./OrderExtension";
import { ViewActions } from "@/router/actions/order-entry";
import CustomPage from "./custom-data/CustomPage";

const noAccessMsg = "Access to orders is not authorized.  Please contact your administrator.";

let pages = [{
        name: "oe:main",
        path: "",
        component: MainPage,
        props: route => ({
            orderId: _.getNumber(route, "params.orderId", 0)
        }),
        meta: {
            entity: "File",
            extension: false,
            label: "Main",
            logRouteAccess: true,
            actions: ViewActions.MAIN,
            accessKey: "OrderEntry",
            unauthorizedMessage: noAccessMsg
        }
    },
    LoansModule,
    ContactsModule,
    {
        name: "oe:cpl",
        path: "cpl",
        component: CplPage,
        meta: {
            entity: "Cpl",
            extension: false,
            label: "CPL",
            logRouteAccess: true,
            actions: ViewActions.CPL,
            accessKey: "OrderEntry",
            skipSaveOnLeave: true,
            disableBackToTop: true,
            featureFlag: "cpl"
        }
    },
    PropertyModule,
    {
        name: "oe:notes",
        path: "notes",
        component: NotesPage,
        meta: {
            entity: "Note",
            extension: false,
            label: "Notes",
            logRouteAccess: true,
            actions: ViewActions.NOTES,
            accessKey: "Notes",
            skipSaveOnLeave: true,
            disableBackToTop: true
        }
    },
    {
        name: "oe:ext1",
        path: "ext1",
        component: ExtensionPage,
        meta: {
            entity: null,
            extension: true,
            label: "Order Extension",
            logRouteAccess: true,
            actions: ViewActions.EXT,
            skipSaveOnLeave: true
        }
    },
    {
        name: "oe:custom",
        path: "custom",
        component: CustomPage,
        meta: {
            entity: "Custom Data",
            extension: true,
            label: "Custom Page",
            actions: ViewActions.CUSTOM,
            skipSaveOnLeave: true,
            accessKey: "AdditionalFields"
        },
        el:'#toggleButton',
        props:{showButton:false}
    }
];

export default pages;
