<template>
    <div class="form-group container" v-for="(value, key) in groupedQuestions" :key="key">
        <div class="section-label-name smkt-question-header">
            <span class="smkt-purple-tic">▶ </span>
            {{ key }}
        </div>
        <div v-for="(question, index) in value" :key="index">
            <StandardQuestionItem :question="question" @answerChanged="handleAnswerChanged" />
        </div>
    </div>
</template>
<script>
import StandardQuestionItem from "./StandardQuestionItem.vue";

export default {
    props: {
        questions: {type:Array, required: true},
        loanId: {type: Number, required: true}
    },
    components:{
        StandardQuestionItem
    },
    created() {
        this.$emit("disable-ok");
        this.setGroupedQuestions();
    },
    methods: {
        setGroupedQuestions() {
            const dictionary = {};
            this.questions.forEach(obj => {
                obj.document_types.forEach(type => {
                    if (!dictionary[type]) {
                        dictionary[type] = [];
                    }
                    dictionary[type].push(obj);
                });
            });
            this.groupedQuestions = dictionary;
        },
        handleAnswerChanged() {
            this.$emit("enable-ok");
        }
    }
}
</script>