<template>
    <rq-section-card
        :title="sectionTitle"
        :section-group="sectionGroup"
        class="tp-clause-section"
        v-model:expanded="section.sectionExpanded"
        title-focus-enabled
        collapsible>
        <div class="tp-clause-actions">
            <span
                v-for="(action, index) in sectionActions"
                :key="action.key"
                class="tp-clause-action"
                :title="readOnly ? '(Access Restricted)' : reorderEnabled ? '(Inaccessible While Reordering)' : ''"
                v-rq-tooltip.hover.top>
                <b-btn
                    ref="sectionActions"
                    :automation_id="action.automation_id"
                    :disabled="action.disabled || reorderEnabled"
                    variant="link"
                    class="btn-theme"
                    @click="onSectionAction(action, index)">
                    {{action.text}}
                </b-btn>
            </span>
            <rq-expand-collapse-all
                v-if="totalItemCount > 1"
                class="ms-3"
                :all-expanded="allItemsExpanded"
                :all-collapsed="allItemsCollapsed"
                expand-all-tooltip="Expand All Clauses"
                collapse-all-tooltip="Collapse All Clauses"
                @expand-all="setAllIsCollapsed(false)"
                @collapse-all="setAllIsCollapsed(true)"
                inline
            />
        </div>
        <ClauseList
            v-if="hasItems"
            ref="clauseList"
            :item-actions="clauseActions"
            :sort-enabled="reorderEnabled"
            v-model:items="items"
            @item-action="onItemAction"
            @list-changed="onListChanged"
            @item-changed="onItemChanged"
            @change-pending="onChangePending"
            sorting-hides-actions
            inline-editable
        />
    </rq-section-card>
</template>

<script>
    import { mapState } from "vuex";
    import { MODULE_EVENTS, AssignedLanguageListType, AppliesToType } from "../../enums";
    import { DocumentFileType } from '@/modules/documents/enums';
    import { IntentionallyDeletedClausesOptions } from "@config/enums";
    import { COMMITMENT_ACTIONS } from "@/store/actions";
    import { COMMITMENT_MUTATIONS } from "@/store/mutations";
    import { WrapRtf } from "@/shared/models/models";
    import { AssignedLanguage, AssignedLanguageSection, AssignedLanguagePackage } from "../../models";
    import { AssignedLanguageItemModel } from "@/shared/models/clause-management";
    import { useClauseDragAndDrop } from "@/shared/composables/clause-management";

    import { ClauseList, ClauseListFormat } from "@/shared/components/rq/";
    import StandardLanguageDialog from "@documents/components/prompts/StandardLanguageDialog";
    import SelectExistingOrder from "@file-shared/components/SelectExistingOrder";

    export default {
        name: "AssignedLanguageList",

        props: {
            loanId: { type: Number, default: 0 },
            section: { type: AssignedLanguageSection, required: true, default: new AssignedLanguageSection() },
            sectionGroup: { type: String, required: true },
            reorderEnabled: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false }
        },

        emits: ["changed"],

        components: {
            ClauseList
        },

        provide() {
            return {
                groupId: this.section.listType
            };
        },

        setup(props, context) {

            const emitChanged = () => {
                context.emit("changed", { section: props.section });
            };

            const {
                items,
                deletedItems,
                totalItemCount,
                setItemList,
                appendListItems,
                updateListItem,
                deleteListItem,
                deleteAllItems,
                refreshListValues,
                setItemContent,
                getFlattenedList
            } = useClauseDragAndDrop([], AssignedLanguageItemModel, emitChanged);

            return {
                items,
                deletedItems,
                totalItemCount,
                setItemList,
                appendListItems,
                updateListItem,
                deleteListItem,
                deleteAllItems,
                refreshListValues,
                setItemContent,
                getFlattenedList,
                emitChanged
            };
        },

        data() {
            return {
                rootItemCount: 0,
                lastClickedActionIndex: null,
                localChangePending: false
            };
        },

        computed: {
            ...mapState({
                assignedLanguages: state => state.orders.assignedLanguages,
                orderId: state => state.orders.orderId,
                commitment: state => state.orders.commitment,
                loans: state => state.orders.loans || [],
                systemDefaults: state => state.system.systemDefaults || {},
                standardLanguageCategories: state => state.documents.standardLanguageCategories
            }),
            localSecurity(){ return this.securitySettings.findValues(["CanBuildCommitPolicyPrompts", "IntentionallyDeletedClauses"]); },
            commitmentPolicyHeaderID() { return _.getNumber(this, "section.commitmentPolicyHeaderID", 0); },
            commitmentPolicyDetailID() { return _.getNumber(this, "section.commitmentPolicyDetailID", 0); },
            allItemsExpanded() { return _.every(this.items, l => !l.isCollapsed); },
            allItemsCollapsed() { return _.every(this.items, l => l.isCollapsed); },
            isUnderPolicy() { return _.includes(AssignedLanguageListType.policyTypes, this.section.listType); },
            isCustomSection() { return this.section.listType === AssignedLanguageListType.CustomCategory; },
            sectionTitle() { return `${this.section.sectionLabel} (${this.items.length})`; },
            sectionExpanded(){ return this.section.sectionExpanded; },
            sectionActions() {
                const self = this;
                let actions = [
                    { automation_id: "btn_add_standard_language", text: "Add Clause", disabled: self.readOnly },
                    { automation_id: "btn_add_blank_standard_language", text: "Add Blank Clause", disabled: self.readOnly },
                    { automation_id: "btn_add_from_other_file", text: "Add Clauses From Other File", disabled: self.readOnly },
                    { automation_id: "btn_delete_all", text: "Delete All", disabled: self.readOnly || _.isEmpty(self.items) }
                ];
                return _.map(actions, a => {
                    a.key = _.uniqueId("clause-action-");
                    a.name = _.kebabCase(a.text);
                    return a;
                });
            },
            sectionDefaults() {
                return {
                    ordersID: this.orderId,
                    loanID: this.loanId,
                    commitmentPolicyHeaderID: this.commitmentPolicyHeaderID,
                    commitmentPolicyDetailID: this.commitmentPolicyDetailID,
                    listType: this.section.listType,
                    standardLanguageSectionID: this.section.standardLanguageSectionID,
                    applyPolicyOverride: this.isUnderPolicy
                };
            },
            hasItems() { return !_.isEmpty(this.items); },
            clauseActions() {
                const self = this;
                let intentionallyDeletedVisible = self.localSecurity.IntentionallyDeletedClauses === IntentionallyDeletedClausesOptions.PrintStrikethrough
                    || self.localSecurity.IntentionallyDeletedClauses === IntentionallyDeletedClausesOptions.DoNotPrint
                return [
                    { key:"edit", title:"Edit Clause", icon:"fas fa-edit" },
                    { key:"intentionally-deleted", title:"Intentionally Deleted", icon:"fas fa-strikethrough", visible: intentionallyDeletedVisible },
                    // {
                    //     key:"applies-to",
                    //     title:"Applies To...",
                    //     icon:"fas fa-tag",
                    //     visible: item => item.isEligibleForPolicyExclusions,
                    //     options: AppliesToType.lookupItems("value", "text")
                    // },
                    { key:"adv-opts", title:"Advanced Options", icon:"fas fa-cog" },
                    { key:"add-blank-std-lang", title:"Add Subordinate<br/>Blank Clause", icon:"far fa-file-plus" },
                    { key:"add-std-lang", title:"Add Subordinate<br/>Clause", icon:"fas fa-circle-plus" },
                    { key:"delete-item", title: "Delete Clause(s)", icon:"fas fa-times-circle", showAlways: true },
                ];
            }
        },

        watch: {
            reorderEnabled(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.setAllIsCollapsed(newValue);
            }
        },

        created() {
            this.fetchData();
        },

        methods: {

            fetchData(checkState=false) {
                const self = this;
                if(self.commitmentPolicyDetailID === 0) {
                    self.initItems();
                    return Promise.resolve(true);
                }
                let storePromise = self.$store.dispatch(COMMITMENT_ACTIONS.GET_ASSIGNED_LANGUAGES, {
                    commitmentPolicyDetailID: self.commitmentPolicyDetailID,
                    listType: self.section.listType,
                    sectionId: self.section.standardLanguageSectionID,
                    checkState: self.section.checkState
                });
                return self.$rqBusy.wait(storePromise, true)
                    .then(() => {
                        self.initItems();
                        return true;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Failed to retrieve Clause Data");
                    });
            },

            initItems() {
                const self = this;
                let commonFilter = item => item.commitmentPolicyDetailID === self.commitmentPolicyDetailID && item.listType === self.section.listType;
                let sectionItems = self.section.listType === AssignedLanguageListType.CustomCategory
                    ? _.filter(self.assignedLanguages, item => commonFilter(item) && item.standardLanguageSectionID === self.section.standardLanguageSectionID)
                    : _.filter(self.assignedLanguages, item => commonFilter(item) && _.includes(self.section.appliesToTypes, _.parseNumber(item.appliesToType)));

                self.setItemList(sectionItems);
            },

            onSectionAction(action, index) {
                this.lastClickedActionIndex = index;
                switch(action.name) {
                    case "add-clause": this.openStandardLanguageDialog(); break;
                    case "add-blank-clause":
                        this.addAssignedLanguageRange([AssignedLanguageItemModel.userDefined()]);
                        break;
                    case "add-clauses-from-other-file": this.openFileSelectionDialog(); break;
                    case "delete-all": this.deleteAllClauses(); break;
                }
            },

            onItemAction({ actionKey, item }) {
                const self = this;
                switch(actionKey) {
                    case "edit":
                        self.onEditItemAction(item);
                        break;
                    case "intentionally-deleted":
                        self.onIntentionallyDeletedAction(item);
                        break;
                    case "adv-opts":
                        self.onAdvancedOptionsAction(item);
                        break;
                    case "add-std-lang":
                        self.onAddChildItem(item);
                        break;
                    case "add-blank-std-lang":
                        self.onAddChildItem(item, true);
                        break;
                    case "delete-item":
                        self.onDeleteItemAction(item);
                        break;
                }

            },

            onChangePending(e) {
                const self = this;
                self.setChangePending(e?.value);
            },

            onItemChanged({ item, html=null, isContentChange=false }) {
                const self = this;
                if(!isContentChange) {
                    self.emitChanged();
                    return;
                }
                self.setItemContent({ item, html })
                    .then(() => {
                        self.setChangePending(false);
                    });
            },

            onListChanged() {
                const self = this;
                self.refreshListValues();
            },

            onDeleteItemAction(item) {
                const self = this;
                let okHandler = () => {
                    self.deleteListItem(item.id);
                };
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete this item?", okHandler);
            },

            onEditItemAction(item) {
                const self = this;
                if(!self.localChangePending) {
                    self.launchAdvancedEditor(item);
                    return;
                }
                let unwatchValue = self.$watch("localChangePending", function(newValue) {
                    if(_.parseBool(newValue)) return;
                    unwatchValue();
                    self.$nextTick(() => {
                        self.launchAdvancedEditor(item);
                    });
                });
            },

            onAddChildItem(parentItem, userDefined=false){
                if(userDefined){
                    let newItem = AssignedLanguageItemModel.userDefined();
                    this.addAssignedLanguageRange([newItem], parentItem);
                }
                else {
                    this.openStandardLanguageDialog(parentItem);
                }
            },

            onIntentionallyDeletedAction(item) {
                const self = this;
                item.intentionallyDeleted = !item.intentionallyDeleted;
                self.updateListItem(item);
            },

            onAdvancedOptionsAction(item){
                const self = this;
                const commitResult = ({ listFormat, isOverridden, appliesToType }) => {
                    item.keepCurrentLevel = listFormat.level !== item.level;
                    item.setListFormat(listFormat);
                    item.overrideListFormat = isOverridden;

                    if(item.appliesToType === appliesToType) {
                        self.updateListItem(item);
                    }
                    else {
                        self.setAppliesToType({ item, appliesToType });
                    }
                    return true;
                };
                const onOk = e => {
                    let result = e.component.getResult();
                    if(item.appliesToType === result.appliesToType || result.appliesToType === AppliesToType.All || !item.anyHasInlinePolicyExclusions) return commitResult(result);
                    let message = item.hasInlinePolicyExclusions
                        ? "<p>This clause content contains policy exclusions.  This action will result in the removal of all inline policy exclusions within the content of this clause.</p><p>Do you wish to continue?</p>"
                        : "<p>The clause content of one or more descendents of this clause contain policy exclusions.  This action will result in the removal of all inline policy exclusions within the clause content of those descendents.</p><p>Do you wish to continue?</p>";
                    return new Promise(resolve => {
                        self.$dialog.confirm(
                            "Confirm",
                            message,
                            () => resolve(commitResult(result)), //onOk
                            () => resolve(false), //onCancel
                            { okTitle: "YES", cancelTitle: "NO" }
                        );
                    });
                };
                self.$dialog.open({
                    title: `Advanced Options: ${item.code}`,
                    width: 800,
                    adaptive: true,
                    props: {
                        listFormat: item.getListFormat(),
                        appliesToEnabled: item.appliesToEditable,
                        appliesToType: item.appliesToType
                    },
                    component: ClauseListFormat,
                    onOk
                });
            },

            getRootAppliesToTypeParent(item, appliesToType=AppliesToType.All) {
                const self = this;
                const isRootParent = node => {
                    if(node.id === item.parentID) return true;
                    let result = false;
                    _.forEach(node.children, child => {
                        result = isRootParent(child);
                        return !result;
                    });
                    return result;
                }
                let parentItem = null;
                _.forEach(self.items, rootItem => {
                    if(!_.isNil(parentItem)) return false;
                    if(!isRootParent(rootItem)) return
                    parentItem = rootItem;
                });
                return parentItem;
            },

            setAppliesToType({ item, appliesToType=AppliesToType.All, applyToParent=false, ignoreInlineExclusions=false }) {
                const self = this;
                const setAppliesTo = (targetItem, stripInline=false) => {
                    targetItem.appliesToType = appliesToType;
                    let stripInlineVal = stripInline || (!ignoreInlineExclusions && targetItem.id === item.id);
                    if(targetItem.hasChildren) {
                        _.forEach(targetItem.children, child => {
                            setAppliesTo(child, stripInlineVal);
                        });
                    }
                    if(!stripInlineVal) return;
                    self.stripPolicyExclusions(targetItem);
                };
                let startItem = applyToParent
                    ? self.getRootAppliesToTypeParent(item)
                    : item;
                setAppliesTo(startItem);
                self.updateListItem(startItem);
            },

            deleteAllClauses() {
                const self = this;
                let okHandler = function (e) {
                    self.deleteAllItems();
                };
                self.$dialog.confirm("Confirm", `Are you sure you want to delete all items under <strong>${self.section.sectionLabel}</strong>?`, okHandler);
            },

            launchAdvancedEditor(targetItem) {
                const self = this;
                let removeAppliesToType = false;
                self.$events.emit(MODULE_EVENTS.EDIT_RTF_CONTENT, {
                    title: `Edit Clause: ${targetItem.code} - ${targetItem.description}`,
                    documentName: `${targetItem.code}.rtf`,
                    documentContent: btoa(targetItem.rtfText || WrapRtf()),
                    fileType: DocumentFileType.RTF,
                    defaultCategoryId: self.defaultCategoryId,
                    showAppliesTo: targetItem.isEligibleForPolicyExclusions,
                    showPrompt: self.localSecurity.CanBuildCommitPolicyPrompts,
                    showPromptValues: self.localSecurity.CanBuildCommitPolicyPrompts,
                    onComplete(contentResult) {
                        self.setItemContent({ item: targetItem, ...contentResult, emitChanged: !removeAppliesToType });
                        if(!removeAppliesToType) return true;

                        return new Promise(resolve => {
                            self.$dialog.confirm("Confirm",
                                `<p>This clause is already set to apply to ${AppliesToType.displayValue(targetItem.appliesToType)}.  Adding inline policy exclusions will result removing the policy exclusion at the clause level for this clause and parent clause(s) (if the policy exclusion is inherited).</p><p>Do you wish to continue?</p>`,
                                () => {
                                    self.setAppliesToType({ item: targetItem, applyToParent: true, ignoreInlineExclusions: true });
                                    resolve(true);
                                },
                                () => resolve(false),
                                { okTitle: "YES", cancelTitle: "NO" });
                        });
                    },
                    onAction(e) {
                        if(e.originalEvent.name === "applies-to" && targetItem.appliesToType !== AppliesToType.All) removeAppliesToType = true;
                        return false;
                    }
                });
            },

            setAllIsCollapsed(val) {
                _.invoke(this, "$refs.clauseList.setAllCollapsedValue", val);
            },

            openStandardLanguageDialog(parentItem){
                const self = this;
                if (self.readOnly) return;
                if(self.$rqBusy.isBusy()) return;
                let okHandler = e => {
                    return e.component.validate()
                        .then(isValid => {
                            let selections = isValid ? e.component.dialogResult() : [];

                            if(!isValid) return false;

                            if(selections.packages.length > 0)
                                self.addStandardLanguagePackages(selections.packages, parentItem);
                            if(selections.items.length > 0)
                                self.addStandardLanguages(selections.items, parentItem);

                            return true;
                        })
                };
                let cancelHandler = function(e) {
                    self.focusLastClickedAction();
                    return true;
                };

                self.$dialog.open({
                    title: `Add Clause(s): ${self.section.sectionLabel}`,
                    height: "85%",
                    width: "80%",
                    autoFocusFirstInput: true,
                    component: StandardLanguageDialog,
                    customFooter: true,
                    props: {
                        categories: self.section.categories,
                    },
                    onOk: okHandler,
                    onCancel: cancelHandler
                });
            },

            openFileSelectionDialog() {
                const self = this;
                self.$dialog.open({
                    title: "Add Clauses From File",
                    height: "80%",
                    minHeight: 700,
                    width: "80%",
                    minWidth: 1200,
                    closeOnEsc: true,
                    component: SelectExistingOrder,
                    onOk(e) {
                        let result = e.component.selectedOrder;
                        let targetOrderId = _.getNumber(result, "ordersID", 0);

                        if(targetOrderId === 0) return true;

                        let apiPromise = self.isCustomSection
                            ? self.$api.CommitmentsApi.getCustomAssignedLanguageCopies(self.commitmentPolicyDetailID, self.section.standardLanguageSectionID, targetOrderId)
                            : self.$api.CommitmentsApi.getAssignedLanguageCopies(self.commitmentPolicyDetailID, self.section.listType, targetOrderId);
                        return self.$rqBusy.wait(apiPromise)
                            .then(result => {
                                if(!result.isValid) {
                                    e.component.errorMessage = result.validationMessage;
                                    return false;
                                }
                                self.appendListItems(result.assignedLanguages);
                                self.emitChanged();
                                self.$toast.success("Successfully copied data.");
                                return true;
                            })
                            .catch(err => err);
                    }
                });
            },

            addStandardLanguages(standardLanguages, parentItem){
                const self = this;
                let newItems = _.map(standardLanguages, slItem => _.gt(slItem.standardLanguageID, 0)
                    ? AssignedLanguageItemModel.fromStandardLanguage(slItem)
                    : AssignedLanguageItemModel.userDefined());

                _.updateAll(newItems, "ordersID", self.orderId);
                _.updateAll(newItems, "loanID", self.loanId);

                let apiPromise = self.$api.CommitmentsApi.mergeAssignedLanguageRange(newItems);
                return self.$parent.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.addAssignedLanguageRange(result, parentItem);
                    });
            },

            addStandardLanguagePackages(packages, parentItem){
                const self = this;
                if (self.readOnly) return;
                let parentID = 0;
                let ordinal = self.rootItemCount + 1;
                let level = 1;
                let appliesToType = 0;
                if(parentItem) {
                    parentID = parentItem.assignedLanguageID;
                    ordinal = parentItem.childCount + 1;
                    level = parentItem.level + 1;
                    appliesToType = parentItem.appliesToType;
                }
                let packageOrdinal = ordinal;
                let requestItems = _.map(packages, p => {
                    let pkg = new AssignedLanguagePackage({
                        ...p,
                        parentID,
                        level,
                        ordinal,
                        appliesToType,
                        ordersID: self.orderId,
                        loanID: self.loanID,
                        ...self.sectionDefaults
                    });
                    packageOrdinal = packageOrdinal + pkg.packageItems.length;
                    return pkg;
                });

                let apiPromise = self.$api.CommitmentsApi.mergeAssignedLanguagePackageRange(requestItems);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.appendListItems(result, parentID);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("An issue occurred while adding the selected clause packages.");
                    });
            },

            addAssignedLanguageRange(items, parentItem){
                const self = this;
                if (self.readOnly) return;
                let parentID = 0;
                let ordinal = self.rootItemCount + 1;
                let level = 1;
                let appliesToType = null;
                let slpdID = [];
                let alID = [];
                if(parentItem) {
                    parentID = parentItem.assignedLanguageID;
                    appliesToType = parentItem.appliesToType;
                    ordinal = parentItem.childCount + 1;
                    level = parentItem.level + 1;
                }
                let adjustedItems = _.map(items, item => {
                    let index = _.findIndex(slpdID, n => n === item.parentID);
                    let parent = index >= 0 ? alID[index] : parentID;
                    let al = new AssignedLanguage({
                        ...item,
                        parentID: parent,
                        level: _.parseNumber(item.level, 0) == 0 ? level : level + item.level -1,
                        ordinal,
                        appliesToType: appliesToType || self.getDefaultAppliesToType(item),
                        ...self.sectionDefaults
                    });
                    ordinal++;
                    if (_.parseNumber(item.standardLanguagePackageDetailID, 0) >  0) {
                        slpdID.push(item.standardLanguagePackageDetailID);
                        alID.push(al.assignedLanguageID);
                    }
                    return al;
                });

                self.appendListItems(adjustedItems, parentID, true);
            },

            focusLastClickedAction() {
                if(_.parseNumber(this.lastClickedActionIndex, -1) < 0) return;
                _.invoke(this, `$refs.sectionActions[${this.lastClickedActionIndex}].focus`);
            },

            getDefaultAppliesToType(item) {
                if(item.hasInlinePolicyExclusions) return AssignedLanguageListType.All;
                return item.standardLanguageID === 1 && this.section.listType === AssignedLanguageListType.Exceptions
                    ? this.systemDefaults.defaultAppliesToType
                    : item.appliesToType;
            },

            getItemData() {
                return this.getFlattenedList();
            },

            stripPolicyExclusions(item) {
                let html = item.htmlText;
                let rtf = item.rtfText;
                _.each(["A","O","M","N"], tag => {
                    html = _.replaceAll(html, `[${tag}]`, "");
                    html = _.replaceAll(html, `[/${tag}]`, "");
                    rtf = _.replaceAll(rtf, `[${tag}]`, "");
                    rtf = _.replaceAll(rtf, `[/${tag}]`, "");
                });
                item.htmlText = html;
                item.rtfText = rtf;
            },

            setChangePending(val) {
                this.localChangePending = val;
                this.$store.commit(COMMITMENT_MUTATIONS.SET_CONTENT_CHANGE_PENDING, val);
            },

            refreshEditors() {
                // RQO-14447 - this is a workaround to an existing bug with the TinyMCE vue component: https://github.com/tinymce/tinymce-vue/issues/230
                _.invoke(this, "$refs.clauseList.refreshAllEditors");
            },
        }
    };
</script>