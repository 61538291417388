<template>
    <div class="container-fluid overflow-visible p-1">        
        <div class="row m-0">    
            <div class="col-12 ps-0 pe-0">
                <div class="card mb-0">
                    <div class="card-header">
                        <span v-if="extension">{{extension.description}} (url : {{url}})</span>                        
                    </div>
                    <div class="card-body p-0" :style="mainStyle">
                        <iframe :src="url" class="w-100 h-100 border-0"></iframe>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
    export default {
        data () {
            return {
                fiddleId: 'wo2djqks',
                mainStyle: 'overflow:auto;height:200px',
                extension: null
            };
        },
    
        mounted () {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            this.fetchData();
    },

        computed: {

            url () {
            // return `https://jsfiddle.net/gvdonovan/${this.fiddleId}/`;
            // return 'https://localhost:44379/Home/Index?orderId=' + this.$route.orderId;

                let result = `${this.extension.url}` + `?orderId=${this.$route.params.orderId}`;
                console.log({ extensionUrl: result});
                return result;
            }
        },

        methods: {
            handleResize (event) {
                let height = document.documentElement.clientHeight - (50 + 46 + 30 + 44 + 31 + 36 + 15);
                this.mainStyle = `overflow:auto;height:${height}px`;
            },

            fetchData () {
                this.$api.SystemsApi.getExtensions().then(result => {
                    this.extension = result[0];
                    console.log(this.extension);
                });
            }
        }
    };
</script>
