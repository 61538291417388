<template>
    <div class="content-wrapper recording-fees-and-taxes">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && hasError"
            dismissable
            sticky
        />
        <rq-banner
            v-for="(warning) in rateAndFeeWarnings"
            :key="warning.id"
            :message="warning.message.warning"
            variant="warn"
            icon="fas fa-exclamation-triangle"
            :visible="rateAndFeeWarnings.length > 0"
            dismissable
            sticky
        />
      
        <rq-page-section title="Recording Fees &amp; Taxes" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav config-actions ms-auto" style="width:100%">
                    <li class="nav-item" v-show="isCalculator">
                        <button
                            automation_id="btn_pull"
                            type="button"
                            class="btn btn-theme"
                            @click="onPullClicked"
                            :disabled="readOnly"
                            v-focus>Pull
                        </button>
                    </li>
                    <li class="nav-item" v-show="showAdd && this.activeTabIndex === 0">
                        <div class="dropdown rq-section-dropdown" :v-if="showAddFirstTab">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly">Add</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_add_recording_fee" @click="onAddRecordingFeeClicked" >Recording Fee</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_add_transfer_tax" @click="onAddTransferTaxClicked" >Transfer Tax</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item" v-show="showAdd && this.activeTabIndex !== 0">
                        <button
                            automation_id="btn_add"
                            type="button"
                            class="btn btn-theme"
                            @click="onAddClicked"
                            :disabled="readOnly">Add
                        </button>
                    </li>
                    <li class="nav-item" v-show="!isLoading && isCalculator && showCalculateButton && !showQuestionsButton && !areThereSmktWarnings" v-rq-tooltip.hover.top="{ title: calculateDisabled ? 'Property Address 1, City, State, Zip Code, and County are Required' : '' }">
                        <button
                            automation_id="btn_calculate"
                            type="button"
                            class="btn btn-theme"
                            @click="onCalculateClicked"
                            :disabled="readOnly || calculateDisabled"
                            >Calculate  <FontAwesomeIcon :icon="['far', 'circle-exclamation']" v-show="areThereSmktWarnings" />
                        </button>
                    </li>
                    <li class="nav-item" v-show="!isLoading && isCalculator && showCalculateButton && !showQuestionsButton && areThereSmktWarnings" v-rq-tooltip.hover.top="{ title: calculateDisabled ? 'Property Address 1, City, State, Zip Code, and County are Required' : '' }">
                        <button
                            automation_id="btn_calculate"
                            type="button"
                            class="btn btn-theme"
                            @click="onCalculateClicked"
                            :disabled="readOnly || calculateDisabled"
                            >Recalculate  <FontAwesomeIcon :icon="['far', 'circle-exclamation']" v-show="areThereSmktWarnings" />
                        </button>
                    </li>
                     <li class="nav-item" v-show="isModifyCalculationVisible || showQuestionsButton">
                        <button
                            automation_id="btn_questions"
                            type="button"
                            class="btn btn-theme"
                            @click="onQuestionsClicked"
                            >Modify Calculation  <FontAwesomeIcon :icon="['far', 'circle-exclamation']" v-show="!haveQuestionsBeenViewed" />
                        </button>
                    </li>
                    <li class="nav-item" v-if="isCdf && canEditRecordingItems">
                        <rq-report-button
                            text="Print Report"
                            :disabled="readOnly"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul> 
            </template>                
        </rq-page-section>
        <rq-tabs
            :tabs="tabItems"
            v-model="activeTabIndex"
            @activate-tab="onActivateTab">
            <template #recording-fees>
                <recording-fee-grid-calculator
                    :ref="tabItems[0].ref"
                    :loan-id="selectedLoanId"
                    :display-mode="displayMode"
                    v-model:validation-errors="recordingFeesValidationErrors"
                    @initialized="onRecordingFeesItemEvent('initialized', $event)"
                    @updated="onRecordingFeesItemEvent('updated', $event)"
                    @updateRateAndFeeWarnings="onUpdateRateAndFeeWarnings($event)"
                    @clearQuestions="onClearQuestions()"
                />
            </template>
            <template #recording-itemizations>
                <recording-item-grid-calculator
                    :ref="tabItems[1].ref"
                    :loan-id="selectedLoanId"
                    :is-without-seller="isWithOutSeller"
                    :display-mode="displayMode"
                />
            </template>
            <template #recording-information>
                <recording-information-grid-calculator
                    :ref="tabItems[2].ref"
                    :loan-id="selectedLoanId"
                    :display-mode="displayMode"
                />
            </template>
        </rq-tabs>
        <!-- TG - To be enabled/fine-tuned with standard fees functionality -->
        <!-- <StandardFeeGridPopover
            v-if="standardFeesEnabled"
            :title="popoverInfo.title"
            :target="popoverInfo.target"
            :detail="popoverInfo.detail"
            v-model:visible="popoverInfo.visible"
        /> --> 
    </div> 
</template>

<script>
    import { ref, computed } from "vue";
    import { useStore } from "vuex";
    import { ORDER_ACTIONS } from "@/store/actions";  
    import { GlobalEventManager } from '@/app.events';
    import {
        RecordingFeeGridCalculator,
        RecordingItemGridCalculator,
        RecordingInformationGridCalculator,
        StandardQuestionsDialog
        // StandardFeeGridPopover
    } from "./components/";
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { useLicenseStore } from "@/store/modules/license";
    import { SettlementReport } from '@settlement/components/dashboard/models';
    import {
        useRqTabAlerts,
        useSecuritySettings,
        // useGridIconPopover
    } from "@/shared/composables/";
    import { RecordingFeeGridType } from "@/modules/file/settlement/models/enums";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import { RateAndFeeWarningType } from "@/shared/models/enums";

    export default {
        name: "RecordingFeesAndTaxes",
        mixins: [BaseSettlementMixin],
        components: {
            RecordingFeeGridCalculator,
            RecordingInformationGridCalculator,
            RecordingItemGridCalculator,
            FontAwesomeIcon
        },
        data () {
            return {
                questions: [],
                isLoading: true,
                questionStatus: Object,
                isModifyCalculationVisible: false
            };
        },
        setup(props) {

            const activeTabIndex = ref(0);
            const showBanner = ref(false);
            const recordingFeesUpdated = ref(false);
            const recordingFees = ref([]);
            let activeAlerts = [];

            const recordingFeesValidationErrors = ref([]);
            const hasError = computed(() => recordingFeesValidationErrors.value.length > 0);
            const alertMapping = {
                recordingFeesTabAlert: { type: "list-ref", list: recordingFeesValidationErrors },
            };

            const{
                recordingFeesTabAlert
            } = useRqTabAlerts({ alertMapping});

            const rateAndFeeWarnings = ref([]);

            const { localSecurity } = useSecuritySettings(["AllowEditRecordingInformation"]);

            const canEditRecordingItems = computed(() => _.some(recordingFees.value, item => item.cdfLineType === 10 || item.cdfLineType ===11));
            const recordingItemizationTooltip = computed(() => canEditRecordingItems.value ? "" : "A Deed and/or Mortgage must exist on the Recording Fees tab to access Recording itemization");

            /* Preliminary standard fee features/toggle */
            const licenseStore = useLicenseStore();
            // const { popoverInfo } = useGridIconPopover("standard-fees");
            const standardFeesEnabled = computed(() => licenseStore.features?.standardFees);
            const showCalculateButton = computed(() => standardFeesEnabled.value);
            const recordingDocumentsEnabled = computed(() => licenseStore.features?.recordingDocuments);
            /*
                If the calculate button needs to be displayed in "modal" mode prior to full standard fees implementation,
                use the following for the "showCalculateButton" computed:

                const showCalculateButton = computed(() => standardFeesEnabled.value || props.displayMode === "modal");
            */
            /********************************************/

            const store = useStore();

            const properties = computed(() => store.state.properties.properties);
            const property1 = computed(() => properties.value.length > 0 ? properties.value[0].property : null);
            const calculateDisabled = computed(() => property1.value == null || _.isEmpty(property1.value.address1) || _.isEmpty(property1.value.city) || _.isEmpty(property1.value.zip) || _.isEmpty(property1.value.state) || _.isEmpty(property1.value.countyName));
            return {
                activeTabIndex,
                showBanner,
                recordingFeesUpdated,
                recordingFees,
                recordingFeesValidationErrors,
                hasError,
                recordingFeesTabAlert,
                localSecurity,
                canEditRecordingItems,
                recordingItemizationTooltip,

                /* Preliminary standard fee features/toggle */
                // popoverInfo,
                standardFeesEnabled,
                showCalculateButton,
                /********************************************/
                calculateDisabled,
                rateAndFeeWarnings,
                recordingDocumentsEnabled,
            };
        },
        props: {
            displayMode: {type: String, default: "route"}
        },
        computed: {
            tabItems() {
                const self = this;
                return [
                    { name: "recording-fees", title: "Recording Fees & Taxes", ref: "recordingFeeComponent", visible: true, alertCount: this.recordingFeesTabAlert.alertCount },
                    { name: "recording-itemizations", title: "Recording Itemizations", ref: "recordingItemComponent", visible: self.isCdf, disabled: !self.canEditRecordingItems, lazy: true },
                    { name: "recording-information", title:"Recording Information", ref: "recordingInformationComponent", visible: !this.recordingDocumentsEnabled, lazy: true }
                ];
            },
            visibleTabItems() { return _.filter(this.tabItems, "visible"); },
            activeTab() { return this.visibleTabItems[this.activeTabIndex]; },
            activeComponentRef() { return this.activeTab?.ref; },
            isCalculator() { return _.includes(["recording-fees","transfer-taxes"], this.activeTab?.name); },
            showAdd() { return this.activeTab?.name !== "recording-information" || this.localSecurity.AllowEditRecordingInformation; },
            reportOptions() { return SettlementReport.reportOptionsRecordingItems(this.orderId, this.selectedLoanId); },
            showQuestionsButton() {
                return this.questionStatus.questionsExist;
            },
            haveQuestionsBeenViewed() {
                return this.questionStatus.questionsViewed;
            },
            areThereSmktWarnings() {
                return _.filter(this.rateAndFeeWarnings, item => item.message.warningType === RateAndFeeWarningType.Smkt).length > 0
            },
            calculateString() {return this.areThereSmktWarnings ? "Recalculate" : "Calculate";}
        },
        watch:{
            async selectedLoanId(newValue, oldValue){
                if (newValue === oldValue || _.isNil(oldValue)) return;
                this.activeTabIndex = 0;
                await this.refreshItems();
                await this.getQuestionStatus();
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.activeTabIndex = 0;
            },
            isWithOutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.activeTabIndex = 0;
            },
            async rateAndFeeWarnings(newValue, oldValue) {
                if (this.areThereSmktWarnings){
                    var promise = this.$api.RecordingFeesApi.clearQuestions(this.selectedLoanId);
                    await this.$rqBusy.wait(promise).then(x => {
                        this.questions = [];
                        this.questionStatus.questionsExist = false;
                        this.questionStatus.questionsViewed = false;
                    });
                }
            }
        },

        async created() {
            this.isLoading = true;
            await this.getQuestionStatus();
            this.baseInit();
            this.isLoading = false;
        },

        methods:{
            onRecordingFeesItemEvent(name, items) {
                this.recordingFeesUpdated = this.recordingFeesUpdated
                    || (name === "updated");
                this.recordingFees = items;
            },
            
            setGroupedQuestions() {
                const dictionary = {};
                this.questions.forEach(obj => {
                    obj.document_types.forEach(type => {
                        if (!dictionary[type]) {
                            dictionary[type] = [];
                        }
                        dictionary[type].push(obj);
                    });
                });
                this.groupedQuestions = dictionary;
            },

            async onUpdateRateAndFeeWarnings(warningsArray) {
                const newRateAndFeeWarnings = [];
                for (const warningMsg of await warningsArray) {
                    const warningObj = {
                        id: _.createUuid(),
                        message: warningMsg,
                    }
                    newRateAndFeeWarnings.push(warningObj);
                }
                this.rateAndFeeWarnings = newRateAndFeeWarnings;
            },

            async onActivateTab({ index, prevIndex }) {
                await this.save(prevIndex);
                _.delay(() => { this.performCurrentGridAction("updateDimensions"); }, 200);

                // RQO-14991: Only fetch data here if navigating to the Recording Items tab and
                // changes have been made to Recording Fees
                if(index !== 1 || !this.recordingFeesUpdated) return;
                this.recordingFeesUpdated = false;
                this.performComponentGridAction(1, "fetchData");
            },

            onSave(e){
                let userInitiated = _.get(e, "userInitiated", false);
                this.save(this.activeTabIndex, userInitiated);
            },

            async onCancel(e){
                await this.performCurrentGridAction('cancel')
                this.showBanner = false;
            },

            async onAddClicked(e){
                await this.performCurrentGridAction('add');
            },

            async onAddRecordingFeeClicked(e){
                await this.performCurrentGridAction('add', RecordingFeeGridType.Fee);
            },

            async onAddTransferTaxClicked(e){
                await this.performCurrentGridAction('add', RecordingFeeGridType.Tax);
            },

            async onPullClicked(e){
                await this.performCurrentGridAction('pull');
            },

            performComponentGridAction(index, action, ...rest) {
                return _.invoke(this, `$refs.${this.tabItems[index].ref}.${action}`, ...rest);
            },

            performCurrentGridAction(action, ...rest) {
                return _.invoke(this, `$refs.${this.activeComponentRef}.${action}`, ...rest);
            },

            async onCalculateClicked(e){
                this.isModifyCalculationVisible = true;
                this.save(this.activeTabIndex, true, true);
                this.isLoading = true;
                await this.getSmktQuestions();
                var promise = this.$api.RecordingFeesApi.answerQuestions(this.selectedLoanId, this.questions);
                await this.$rqBusy.wait(promise);
                await this.getQuestionStatus();
                await this.refreshItems(true);

                await this.$store.dispatch(ORDER_ACTIONS.GET_LOANS, false);
                this.isLoading = false;
                this.isModifyCalculationVisible = false;
            },
            async getQuestionStatus()
            {
                let promise = this.$api.RecordingFeesApi.getQuestionsStatus(this.selectedLoanId);
                await this.$rqBusy.wait(promise).then(status =>{
                    this.questionStatus = status;
                });
            },
            async onQuestionsClicked() {
                const self = this;

                await self.getSmktQuestions();
                await self.markQuestionsViewed();
                await self.getQuestionStatus();

                if (this.groupedQuestions != null || this.groupedQuestions.length > 0){

                    self.$dialog.open({
                        title: "Recording Details",
                        width: 800, //number values are in px, also accepts string values e.g. "80%"
                        height: 800,  //number values are in px, also accepts string values e.g. "80%"	
                        resizable: true, // optional, this is the default
                        scrollable: true, // optional, this is the default
                        adaptive: true, // optional, this is the default
                        component: StandardQuestionsDialog,
                        props: { 
                            loanId: self.selectedLoanId,
                            questions: self.questions			
                        },
                        onOk: async e => {			
                            let promise = self.$api.RecordingFeesApi.answerQuestions(self.selectedLoanId, self.questions);
                            await self.$rqBusy.wait(promise);
                            await self.refreshItems();
                            await this.$store.dispatch(ORDER_ACTIONS.GET_LOANS, false)

                            return true; //returning false will prevent the dialog from closing
                        },
                        onCancel() {
                            return true;
                        }
                    });
                }   
                else {
                    await self.calculateRatesAndFees();
                    await this.refreshItems();
                }
            },
            async markQuestionsViewed()
            {
                let promise = this.$api.RecordingFeesApi.markQuestionsViewed(this.selectedLoanId, this.questions);
                await this.$rqBusy.wait(promise);
            },
            async getSmktQuestions() {
                const self = this;
                let promise = self.$api.RecordingFeesApi.getQuestions(this.selectedLoanId);
                await self.$rqBusy.wait(promise).then(questions => {
                    this.questions = questions;
                    if (questions && questions.length > 0) {
                        this.setGroupedQuestions();
                    }
                });
            },
            async calculateRatesAndFees(){
                const self = this;
                if(self.standardFeesEnabled){
                    let componentRef = await _.get(self.$refs, this.visibleTabItems[this.activeTabIndex].ref, null);
                    self.showBanner = false;
                    if(!componentRef){
                        GlobalEventManager.saveCompleted({success: false});
                        return Promise.resolve(true);
                    }
                    self.rateAndFeeWarnings = []; // reset warnings array that prompts for recalculation - we will know if the warnings are still relevant in the request for data after this calc
                    return await componentRef.calculateRatesAndFees()
                        .then(async result => {
                            await this.$store.dispatch(ORDER_ACTIONS.GET_LOANS, false);
                            self.showBanner = true;
                            GlobalEventManager.saveCompleted({success: result});
                            return result;
                        });
                        
                }
            },

            save(tabIndexToSave, userInitiated=false, refreshData=true){
                const self = this;
                if (self.readOnly) return;

                let componentRef = _.get(self.$refs, this.visibleTabItems[tabIndexToSave].ref, null);

                self.showBanner = false;
                if(!componentRef){
                    GlobalEventManager.saveCompleted({success: false});
                    return Promise.resolve(true);
                }
                return componentRef.save(userInitiated, refreshData)
                    .then(result => {
                        self.showBanner = true;
                        GlobalEventManager.saveCompleted({success: result, abort: !result});
                        return result;
                    });
            },

            async refreshItems(autoGenerateRecordings = true){
                const self = this;
                await self.performCurrentGridAction('save', false, true, autoGenerateRecordings)
                    .then(()=>{
                        self.performCurrentGridAction('fetchData');
                    });
            },

            setCurrentView(index) {
                const self = this;
                self.activeTabIndex = index;
            },

            isValid(validationResult) {
                this.validationErrors = _.get(validationResult, "errors", []);
                return _.isEmpty(this.validationErrors);
            },
            clearBanner() {
                this.showBanner = false;
            },
            onClearQuestions(){
                this.isModifyCalculationVisible = false;
                
                this.questions = [];
                this.questionStatus.questionsExist = false;
                this.questionStatus.questionsViewed = false;
            }
        }
    };
</script>