<template>
    <ClauseManagement
        ref="clauseManagement"
        title="Schedule B"
        title-production-tab="B"
        v-model:commitment-data="commitmentDataValue"
        v-model:has-changes="hasChangesValue">
        <template #actions-start>
            <ul v-if="hasBinder" class="nav mx-2">
                <li class="nav-item">
                    <b-form-checkbox
                        automation_id="chk_strike1A"
                        id="chk_strike1A"
                        v-model="binderStrike1A"
                        :disabled="readOnly">Strike 1a, Schedule C of Binder</b-form-checkbox>
                </li>
                <li class="nav-item mx-2">
                    <b-form-checkbox
                        automation_id="chk_strikeBinderB13"
                        id="chk_strikeBinderB13"
                        v-model="binderB13Strike"
                        :disabled="readOnly">Strike Binder B1-3</b-form-checkbox>
                </li>
                <li class="nav-item">
                    <b-form-checkbox
                        automation_id="chk_taxLanguageB13"
                        id="chk_taxLanguageB13"
                        v-model="binderB13TaxLanguage"
                        :disabled="readOnly">Add Tax Language B1-3</b-form-checkbox>
                </li>
            </ul>
        </template>
        <template #before-sections>
            <div class="row mb-3">
                <div class="col-12 col-lg-3 form-group">
                    <label for="drp_signatory">Signatory</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_signatory')"
                        value-expr="value"
                        display-expr="description"
                        :items="signatures"
                        :disabled="readOnly"
                        v-model="signatory"
                    />
                </div>
                <div class="col-12 col-lg-3 form-group">
                    <rq-action-form-group
                        label="Standby Fees and Tax Year"
                        action-label="Revert"
                        action-class="overridden"
                        class="label-lg"
                        :show-action="standbyYearOverridden"
                        @action-click="onRevertStandbyYear">
                        <rq-input-number
                            id="txt_stdby_fees_tax_year"
                            format-type="basic"
                            max-value="3000"
                            decimals="0"
                            :disabled="readOnly"
                            v-model="standbyYear"
                            allow-null
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-lg-3 form-group" v-if="!order.isAlta">
                    <label for="drp_schedule_b_item_2">Schedule B Item 2</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_schedule_b_item_2')"
                        value-expr="id"
                        display-expr="name"
                        :items="scheduleB2Items"
                        :disabled="readOnly"
                        v-model="scheduleBItem2"
                    />
                </div>
            </div>
        </template>
    </ClauseManagement>
</template>

<script setup>
    import { computed, ref } from "vue";
    import { useVModel } from "@vueuse/core";
    import { useStore } from "vuex";
    import { OrderServiceType } from "@/shared/models/enums";
    import { StandbyFeesAndTaxYearType } from "@config/enums";
    import ClauseManagement from "../assignedlanguages/ClauseManagement";
    import { DateTime } from "luxon";

    const props = defineProps({
        commitmentData: { default: () => {} },
        hasChanges: { type: Boolean, default: false }
    });

    const emit = defineEmits(["update:commitmentData", "update:hasChanges"]);

    const vuexStore = useStore();

    const commitmentDataValue = useVModel(props, "commitmentData", emit);
    const hasChangesValue = useVModel(props, "hasChanges", emit);

    const clauseManagement = ref(null);
    const scheduleB2Items = ref([
        { id: 0, name: "None" },
        { id: 1, name: "Survey Complete" },
        { id: 2, name: "Survey Needed" }
    ]);

    const order = computed(() => vuexStore.state.orders.order);
    const signatures = computed(() => vuexStore.state.orders.usersWithSignatures || []);
    const readOnly = computed(() => _.parseBool(vuexStore.state.isPageReadOnly));
    const orderBranch = computed(() => vuexStore.state.orders.branch);

    const getComputedField = expr => computed({
        get() { return _.get(commitmentDataValue.value, expr, null); },
        set(val) { _.set(commitmentDataValue.value, expr, val); }
    });

    const signatory = getComputedField("header.signatory");
    const standbyYear = getComputedField("header.standbyYear");
    const binderStrike1A = getComputedField("detail.binderStrike1A");
    const binderB13Strike = getComputedField("detail.binderB13Strike");
    const binderB13TaxLanguage = getComputedField("detail.binderB13TaxLanguage");
    const scheduleBItem2 = getComputedField("detail.scheduleBItem2");

    const hasBinder = computed(() => order.value.service === OrderServiceType.AltaConstructionLoan || order.value.service === OrderServiceType.TxBinder);
    const standbyFeesAndTaxYear = computed(() => StandbyFeesAndTaxYearType.getYear(orderBranch.value?.standbyFeesAndTaxYear));
    const standbyYearOverridden = computed(() => standbyYear.value !== standbyFeesAndTaxYear.value);

    const getListData = () => clauseManagement.value?.getListData() || [];

    const onRevertStandbyYear = () => { 
        standbyYear.value = standbyFeesAndTaxYear.value; 
    }

    defineExpose({
        getListData,
        onRevertStandbyYear
    });
</script>