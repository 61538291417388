import OrderWorkflowPage from "./OrderWorkflow";
import Routes from "./routes";

let OrderWorkflowModule = {
    meta: { label: "Workflow and Alarms", include: true },
    path: "workflow",
    component: OrderWorkflowPage,
    children: Routes
};

export default OrderWorkflowModule;
