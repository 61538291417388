<template>
    <div class="content-wrapper">
        <rq-page-section title="Loan Terms" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                        />                        
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <loan-section
            :loan-term="selectedLoanTerm"
            :original-loan-term="originalLoanTerm"
            :read-only="readOnly"
            :loan="currentLoan"
        />
        <interest-rate-section
            :loan-term="selectedLoanTerm"
            :original-loan-term="originalLoanTerm"
            :read-only="readOnly"
            :loan="currentLoan"
        />
        <monthly-principal-section
            :loan-term="selectedLoanTerm"
            :monthly-principal="monthlyPrincipal"
            :monthly-interest="monthlyInterest"
            :mortgage-insurance="mortgageInsurance"
            :original-loan-term="originalLoanTerm"
            :read-only="readOnly"
        />
        <prepayment-penalty-section
            :loan-term="selectedLoanTerm"
            :read-only="readOnly"
        />
        <balloon-payment-section
            :loan-term="selectedLoanTerm"
            :read-only="readOnly"
        />
        <escrow-account-payment-section
            :loan-term="selectedLoanTerm"
            :read-only="readOnly"
        />
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { ORDER_ACTIONS } from "@/store/actions";
    import LoanSection from "./LoanSection";
    import InterestRateSection from "./InterestRateSection";
    import MonthlyPrincipalSection from "./MonthlyPrincipalSection";
    import PrepaymentPenaltySection from "./PrepaymentPenaltySection";
    import BalloonPaymentSection from "./BalloonPaymentSection";
    import EscrowAccountPaymentSection from "./EscrowAccountPaymentSection";
    import { LoanTermSectionHud2010DataModel } from '@settlement/models';
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        name: "LoanTermsHud2010",
        mixins: [BaseSettlementMixin],
        components: {
            LoanSection, InterestRateSection,
            MonthlyPrincipalSection, PrepaymentPenaltySection,
            BalloonPaymentSection, EscrowAccountPaymentSection
        },

        data(){
            return{
                originalLoanTerms: [],
                selectedLoanTerm: {},
                originalLoanTerm: {},
            }
        },
        computed:{
            monthlyPrincipal() {
                return this.selectedLoan(this.selectedLoanId).monthlyPrincipal;
            },
            monthlyInterest() {
                return this.selectedLoan(this.selectedLoanId).monthlyInterest;
            },
            mortgageInsurance() {
                return this.selectedLoan(this.selectedLoanId).mortgageInsurance;
            },
            currentLoan() {
                return _.find(this.loans, x => x.loanID === this.selectedLoanId);
            }
        },
        watch:{
            selectedLoanId(newVal, oldVal){
                const self = this;
                if (newVal === oldVal || _.parseNumber(oldVal, 0) === 0 || !this.isHud) return;
                self.fetchData();
            },
        },
        created() {
            this.baseInit();
            this.fetchData();
        },
        methods: {
            fetchData(){
                const self = this;
                let promise = self.$api.LoanTermApi.getByOrderId(self.orderId);
                self.$rqBusy.wait(promise).then(result => {
                    self.originalLoanTerms = _.map(result, i => new LoanTermSectionHud2010DataModel(i));
                    self.selectedLoanTerm = _.cloneDeep(_.find(self.originalLoanTerms, s => s.loanID === self.selectedLoanId));
                    self.originalLoanTerm = _.cloneDeep(self.selectedLoanTerm);

                    // Calculated Monthly Principal & Interest value
                    let loan = self.selectedLoan(self.selectedLoanId);
                    let calculatedMPI = loan.monthlyPrincipal + loan.monthlyInterest;

                    self.selectedLoanTerm.monthlyPrincipalInterest = calculatedMPI;
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });
            },
            onSave(e){
                let userInitiated = _.get(e, "userInitiated", false);
                this.save(userInitiated);
            },

            onCancel(e){
                const self = this;
                let original = _.find(self.originalLoanTerms, s => s.loanTermSectionDataID === self.selectedLoanTerm.loanTermSectionDataID);
                if(_.isNil(original)) return Promise.resolve(false);
                let changes = self.getChanges(original);
                if(_.isEmpty(changes)) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            save(toastIfNoChanges=false){
                const self = this;
                let original = _.find(self.originalLoanTerms, s => s.loanTermSectionDataID === self.selectedLoanTerm.loanTermSectionDataID);
                if(_.isNil(original)) return Promise.resolve(false);
                let changes = self.getChanges(original);

                if(_.isEmpty(changes)){
                    if(toastIfNoChanges)
                        self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let promise = self.$api.LoanTermApi.update(self.selectedLoanTerm, changes).then(()=>{
                    self.syncUpdates().then(() => {
                        self.$toast.success("Loan Terms Saved.");
                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    });
                }).catch(err =>{
                    self.$toast.error(err.message);
                    GlobalEventManager.saveCompleted({success: false});
                    return false;
                });

                self.$rqBusy.wait(promise);
                return promise;
            },

            getChanges(original=null) {
                const self = this;
                let originalTerms = _.isNil(original) ? _.find(self.originalLoanTerms, s => s.loanTermSectionDataID === self.selectedLoanTerm.loanTermSectionDataID) : original;
                return self.getAuditChanges(originalTerms, self.selectedLoanTerm);
            },

            syncUpdates(){
                const self = this;

                //refresh the store
                let promise = self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, {orderId: self.orderId, refresh:true});

                self.$rqBusy
                    .wait(promise)
                    .then(() => {
                        // Re-query to enforce new values.
                        self.fetchData();
                    });
                return promise;
            },
            selectedLoan(loanId){
                const self = this;
                let selected = _.find(self.loans, l => l.loanID === loanId);
                return selected;
            },
        }
    }
</script>