<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="Additional Title Services" header-size="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_item" variant="theme" @click="onAddItemClicked" :disabled="readOnly">Add</b-btn>
                        <rq-report-button
                            text="Print Report"
                            v-if="isHud2010"
                            :disabled="readOnly"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                            map-ss-on-change
                            map-override
                        />
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="col-3 form-group mb-0">
                    <label class="form-control-label"
                        for="payee_company">Payee</label>
                    <company-picker
                        ref="refPayeeCompany"
                        automation_id="pic_Payee_company"
                        dialogTitle="Select Payee"
                        :disabled=true
                        v-model="defaultPayee"
                        no-drop-down
                        >
                    </company-picker>
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="titleServiceGrid"
            automation_id="dg_available"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="addendum-data"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            focus-after-insert="first-row"
            @delete="onDeleteItems"
            @rowPrepared="onRowPrepared"
            hide-search
            rq-editable
        />
    </div>
</template>

<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import { CompanyPicker } from '@/shared/components/rq';
    import { GlobalEventManager } from '@/app.events';
    import { AdditionalTitleServiceModel, SETTLEMENT_TYPE } from '@settlement/models'
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { SettlementReport } from '@settlement/components/dashboard/models';

    export default {
        name: "AdditionalTitleServices",
        mixins: [BaseSettlementMixin, GridSystemLookupMixin, GridCompanyPickerMixin],
        components: { CompanyPicker },
        props: {
            loanId: { type: Number, default: 0 },
            settlementType: { default:  SETTLEMENT_TYPE.HUD_2010}
        },

        data(){
            return {
                itemTypeNamePlural: "Title Services" ,
                itemKey: "additionalTitleServicesID",
                itemTypeName: "Title Service",
                items: [],
                deletedItems: [],
                originalData: [],
                selectionActions: [],
                defaultPayee: {},
                grandTotal: 0,
                HUDMainID: 0
            };
        },

        computed:{
            gridInstance () { return _.get(this, "$refs.titleServiceGrid.gridInstance", null) || {}; },
            hasDefaultPayee() { return !_.isNil(this.defaultPayee) && this.defaultPayee.companyID > 0; },
            reportOptions() { return SettlementReport.reportOptionsAdditionalTitleServices(this.HUDMainID); },
        },

        watch:{
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                self.save(true);
            }
        },

        created() {
            const self = this;
            self.baseInit();

            self.selectionActions = [{
                name: "delete",
                text: "Delete",
                eventName: "delete",
                requireSelection: true,
                allowMultiSelection: true,
                tooltip: `Delete ${self.itemTypeName}`,
                disabled: () => self.readOnly
            }];

            self.initGridConfig();
            self.fetchData();
        },

        methods:{
            initGridConfig() {
                const self = this;

                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyName",
                    contactIDExpr: "payeeContactID",
                    contactNameExpr: "payeeContactName",
                    showContactPicker: false
                };

                self.gridConfig = {
                    focusedRowEnabled: false,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: { maxLength: 40 },
                            validationRules: [
                                { type: "required", message: "Description is required" }
                            ]
                        },
                        //from GridCompanyPickerMixin
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyName",
                                dataType: "string",
                                caption: "Payee",
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "borrowerCharges",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "sellerCharges",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        })
                    ],

                    summary:
                    {
                        totalItems: [
                            {
                                column: "description",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return "TOTALS";
                                }
                            },

                            {
                                column: "borrowerCharges",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },

                            {
                                column: "sellerCharges",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "accountingCodeID",
                                summaryType: "sum",
                                valueFormat: "currency",
                                alignment: "right",
                                customizeText: function(data){
                                    return "Grand Total: " + self.formatMoney(self.grandTotal);
                                }
                            }
                        ]
                    },
                }

                self.initGridDataSource();
            },
            initGridDataSource() {
                const self = this;
                self.gridDataSource = {
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    },
                    insert(values){
                        self.onGridInsert(values);
                    },
                    update(key, values) {
                        self.onGridUpdate(key, values);
                    },
                };
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new AdditionalTitleServiceModel(values);
                newItem.additionalTitleServicesID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.hudMainID = self.HUDMainID;
                self.items.push(newItem);
                self.updateGrandTotal();

                return Promise.resolve(newItem);
                // return self.save(self.items);
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.additionalTitleServicesID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new AdditionalTitleServiceModel(_.assign({}, self.items[itemIndex], values));
                self.items[itemIndex] = updatedItem;
                self.updateGrandTotal();

                return Promise.resolve(updatedItem);
                // return self.save(self.items);
            },
            updateGrandTotal() {
                this.grandTotal = _.sumBy(this.items, "borrowerCharges") + _.sumBy(this.items, "sellerCharges");
            },

            onSave(e){
                this.gridInstance.saveEditData();
                let userInitiated = _.getBool(e, "userInitiated");
                this.mapSettlementStatement(); // by virtue of mapping, embedded logic will also save the data on this page
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onRowPrepared(e){
                const self = this;
                if (e.rowType !== "data" || e.key > 0) return;
                if (self.hasDefaultPayee && _.isNil(e.data.payeeCompanyID)) {
                    e.data.payeeCompanyID = self.defaultPayee.companyID;
                    e.data.payeeCompanyName = self.defaultPayee.companyName;
                }
            },
            onDeleteItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteItems(selectedItems);
                    self.updateGrandTotal();
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected charge(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            deleteItems(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.itemKey);
                    self.removeItems(key);

                    if(key > 0){
                        self.deletedItems.push(item);
                    }
                });
            },
            removeItems(key) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                self.gridInstance.refresh();
            },
            onAddItemClicked(){
                const self = this;
                if(!self.gridInstance) return;
                self.gridInstance.addRow();
            },
            fetchHudMainId() {
                const self = this;
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getPromise = self.$api.HudAddendumsApi.getHudMainId(self.selectedLoanId);

                return self.$rqBusy.wait(getPromise).then(result => {
                    self.HUDMainID = result || 0;
                });
            },
            fetchAdditionalTitleServices() {
                const self = this;
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getPromise = self.$api.AdditionalTitleServicesApi.getRecordsByLoan(self.selectedLoanId);
                return self.$rqBusy.wait(getPromise).then(result=>{
                    self.defaultPayee = {
                        companyID: result.defaultPayeeCompany.companyID,
                        companyName: result.defaultPayeeCompany.name
                    };
                    self.items = _.map(result.items, item => new AdditionalTitleServiceModel(item));
                    self.originalData = _.map(result.items, item => new AdditionalTitleServiceModel(item));
                    self.gridInstance.refresh();
                    self.updateGrandTotal();
                    return { data: self.items, totalCount: self.items.length };
                });
            },
            fetchData(){
                const self = this;
                self.deletedItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                self.fetchAdditionalTitleServices();
                self.fetchHudMainId();
            },
            save(refreshData = true) {
                const self = this;
                if(!self.hasChanges()){
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                let defPayeeCompId = !_.isNil(self.defaultPayee) ? self.defaultPayee.companyID : null;
                let saveData = {
                    loanID: self.selectedLoanId,
                    defaultPayeeCompanyID: defPayeeCompId,
                    items: self.items,
                    deletedItems: self.deletedItems
                };
                let savePromise = self.$api.AdditionalTitleServicesApi.saveDeleteRecords(saveData);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Additional Title Services Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Additional Title Services.");
                        console.error(err);
                    });

            },
            hasChanges(){
                //original data was empty and user added something
                if(this.originalData.length === 0 && this.items.length > 0){
                    return true;
                }
                //if the arrays are different length, then there are changes. Items have been deleted and need to save
                if(this.items.length !== this.originalData.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(this.originalData, this.items);
                return changes.length > 0;
            },
        }
    }
</script>