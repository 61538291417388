<template>
    <div class="content-wrapper">
        <div class="row">
            <div class="col-2 col-sm-12">
                <rq-page-section 
                    :title="`Commitment: Loan #${currentLoan.loanOrder}`" headerSize="sm" borderless>
                    <div class="row">
                        <div class="col">Strike Requirements 1A</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="detail_policy_commStrike1A"
                                v-model="localCommitment.detail.commStrike1A"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6 form-group">
                            <label>Schedule B Item 2</label>
                            <dx-select-box
                                value-expr="id"
                                display-expr="name"
                                :items="scheduleB2Items"
                                v-model="localCommitment.detail.scheduleBItem2"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-2 col-sm-12" v-show="settings.isSimultaneousOrOwner">
                <rq-page-section  title="Owner's Policy" headerSize="sm" borderless>
                    <div class="row">
                        <div class="col">Strike Owner's Exception 2</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_ownerStrikeException2"
                                v-model="ownerStrikeException2"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-2 col-sm-12" v-show="settings.isSimultaneousOrLoan">
                <rq-page-section  title="Loan Policy" headerSize="sm" borderless>
                    <div class="row">
                        <div class="col">Include Shortages in Area</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgageB2"
                                v-model="firstLoanCommitmentPolicyDetail.strikeMortgageB2"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Include Item 3 of Schedule B</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeEOMB3"
                                v-model="firstLoanCommitmentPolicyDetail.strikeEOMB3"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Add Standby Clause to Exception 3</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_addStandyB3"
                                v-model="firstLoanCommitmentPolicyDetail.addStandyB3"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike Exception 4</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgageB4"
                                v-model="firstLoanCommitmentPolicyDetail.strikeMortgageB4"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Include 2nd Lien Information</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_noSecondLien"
                                v-model="firstLoanCommitmentPolicyDetail.noSecondLien"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-2 col-sm-12" v-show="settings.isSimultaneousOrLoan">
                <rq-page-section v-for="item in additionalCommitmentPolicyDetails"
                    :key="item.commitmentPolicyDetailID"
                    :title="`Additional Loan Policy: Loan #${getLoanOrder(item.loanID)}`"
                    borderless
                    headerSize="sm">
                    <div class="row">
                        <div class="col">Include Item 3 of Schedule B</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                :automation_id="`loan_policy_${item.commitmentPolicyDetailID}_strikeEOMB3`"
                                v-model="item.strikeEOMB3"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Add Standby Clause to Exception 3</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                :automation_id="`loan_policy_${item.commitmentPolicyDetailID}_addStandyB3`"
                                v-model="item.addStandyB3"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike Exception 4</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                :automation_id="`loan_policy_${item.commitmentPolicyDetailID}_strikeMortgageB4`"
                                v-model="item.strikeMortgageB4"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
        </div>
    </div>
</template>
<script>
import { GlobalEventManager } from "@/app.events";
import { COMMITMENT_ACTIONS } from "@/store/actions";
import { CommitmentDataDto } from '@title-policy/models';
import { ScheduleB2Items } from "@/shared/models/enums";
export default {
    props: {
        commitmentData: { type: Object, default: () => new CommitmentDataDto() },
        loans: { type: Array, default: () => [] },
        settings: { type: Object, default: () => {} }
    },
    data() {
        return {
            switchOn: "Yes",
            switchOff: "No",
            scheduleB2Items: ScheduleB2Items.lookupItems,
            localCommitment: _.cloneDeep(this.commitmentData),
            originalAdditionalCommitmentPolicyDetails: [],
            localAdditionalCommitmentPolicyDetails: [],
            firstLoanCommitmentPolicyDetail: {},
            additionalCommitmentPolicyDetails: [],
            ownerStrikeException2: false
        }
    },
    computed: {
        currentLoan() {
            let self = this;
            return _.find(self.loans, x => x.loanID === self.commitmentData.detail.loanID);
        }
    },
    watch: {
        ownerStrikeException2: {
            handler(newValue, oldValue){
                if(newValue === oldValue) return;
                this.localCommitment.header.ownerStrikeException2 = newValue ? 1 : 0;
            },
            immediate: false
        }
    },
    created() {
        this.fetchCommitmentDetails();
        this.ownerStrikeException2 = this.localCommitment.header.ownerStrikeException2 === 1 ? true : false;
    },
    methods: {
        save() {
            const self = this;
            let commitmentDataChanges = self.getAuditChanges(self.commitmentData, self.localCommitment);
            let detailChanges = self.getAuditChanges(self.originalAdditionalCommitmentPolicyDetails, self.localAdditionalCommitmentPolicyDetails);
            if (commitmentDataChanges.length === 0 && detailChanges.length === 0) {
                self.$toast.info("No changes detected");
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(true);
            }

            //get the additional details that has changes here and send to request
            let indexWithChanges = _.uniq(_.map(detailChanges, x => {
                                        let items = _.split(x.name, '.');
                                        return _.parseInt(items[0]);
                                    }));

            let detailsWithChanges = _.filter(self.localAdditionalCommitmentPolicyDetails, (x,index) => {
                                        return _.includes(indexWithChanges, index);
                                    });

            
            let request = {
                data: {
                        ...self.localCommitment,
                        additionalPolicyDetailsWithChanges: detailsWithChanges
                    },
                changes: commitmentDataChanges
            };
            let success = true;
            let storePromise = self.$store.dispatch(COMMITMENT_ACTIONS.UPDATE_POLICY_OPTIONS, { request });
            return self.$rqBusy.wait(storePromise)
                .then(commitment => {
                    self.$toast.success(`Texas Policy Options Saved Successfully`);
                    return success;
                })
                .catch(error => {
                    self.$toast.error(`Failed to save Texas Policy Options: ${error.errorMessage}`);
                    success = false;
                    return success;
                })
                .finally(() => {
                    GlobalEventManager.saveCompleted({ success });
                });
        },
        fetchCommitmentDetails() {
            const self = this;
            let storePromise = self.$api.CommitmentsApi.getCommitmentDetailList(self.commitmentData.header.commitmentPolicyHeaderID);
            self.$rqBusy.wait(storePromise)
                .then(data => {
                    let firstOrderLoan = _.find(self.loans, x => x.loanOrder === 1);
                    self.originalAdditionalCommitmentPolicyDetails = data;
                    self.localAdditionalCommitmentPolicyDetails = _.cloneDeep(data);
                    self.firstLoanCommitmentPolicyDetail = _.find(self.localAdditionalCommitmentPolicyDetails, x => x.loanID === firstOrderLoan.loanID);
                    self.additionalCommitmentPolicyDetails = _.filter(self.localAdditionalCommitmentPolicyDetails, x => x.loanID !== firstOrderLoan.loanID);
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: "Failed to retrieve Commitment Data" });
                });
        },
        getLoanOrder(loanId){
            let self = this;
            return _.find(self.loans, x => x.loanID === loanId).loanOrder
        }
    }
}
</script>