import { UserSecuritySettings } from "@/shared/models/models";
import { OrderNoteCategory } from "@/shared/models/enums";
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

export class OrderMainDto {
    constructor (options) {
        options = options || {};
        this.statusName = options.statusName || null;
        this.escrowUnitName = options.escrowUnitName || null;
        this.serviceName = options.serviceName || null;
        this.workflowProcessTemplateName = options.workflowProcessTemplateName || null;
        this.titleUnitName = options.titleUnitName || null;
        this.titleCompanyName = options.titleCompanyName || null;
        this.orderCategoryName = options.orderCategoryName || null;
        this.sourceOfBusinessName = options.sourceOfBusinessName || null;
        this.bankCompanyName = options.bankCompanyName || null;
        this.underwriterCompanyName = options.underwriterCompanyName || null;
        this.salesRepresentativeStaffName = options.salesRepresentativeStaffName || null;
        this.closeAgentStaffName = options.closeAgentStaffName || null;
        this.escrowOfficerStaffName = options.escrowOfficerStaffName || null;
        this.escrowProcessorStaffName = options.escrowProcessorStaffName || null;
        this.titleOfficerStaffName = options.titleOfficerStaffName || null;
        this.titleProcessorStaffName = options.titleProcessorStaffName || null;
        this.placeOfClosingCompanyId = options.placeOfClosingCompanyId || null;
        this.placeOfClosingCompanyName = options.placeOfClosingCompanyName || null;
        this.placeOfClosingContactId = options.placeOfClosingContactId || null;
        this.placeOfClosingContactName = options.placeOfClosingContactName || null;
        this.settlementAgentCompanyId = options.settlementAgentCompanyId || null;
        this.settlementAgentCompanyName = options.settlementAgentCompanyName || null;
        this.settlementAgentContactId = options.settlementAgentContactId || null;
        this.settlementAgentContactName = options.settlementAgentContactName || null;
        this.ordersID = options.ordersID || null;
        this.status = _.parseNumber(options.status, null);
        this.escrowUnitID = options.escrowUnitID || null;
        this.service = _.parseNumber(options.service, null);
        this.workflowProcessTemplateID = options.workflowProcessTemplateID || null;
        this.titleUnitID = options.titleUnitID || null;
        this.kindOfOrder = options.kindOfOrder || null;
        this.titleCompanyCompanyID = options.titleCompanyCompanyID || null;
        this.orderCategoryID = options.orderCategoryID || null;
        this.sourceOfBusinessID = options.sourceOfBusinessID || null;
        this.bankCompanyID = options.bankCompanyID || null;
        this.underwriterCompanyID = options.underwriterCompanyID || null;
        this.locationID = options.locationID || null;
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID, null);
        this.otherCompaniesFileNumber = options.otherCompaniesFileNumber || null;
        this.friendlyName = options.friendlyName || null;
        this.closeDate = options.closeDate || null;
        this.closeTime = options.closeTime || null;
        this.openDate = options.openDate || null;
        this.duration = options.duration || null;
        this.userDefinedDate1 = options.userDefinedDate1 || null;
        this.fundDate = options.fundDate || null;
        this.salesRepresentativeStaffID = options.salesRepresentativeStaffID || null;
        this.closeAgentStaffID = options.closeAgentStaffID || null;
        this.escrowOfficerStaffID = options.escrowOfficerStaffID || null;
        this.escrowProcessorStaffID = options.escrowProcessorStaffID || null;
        this.titleOfficerStaffID = options.titleOfficerStaffID || null;
        this.titleProcessorStaffID = options.titleProcessorStaffID || null;
        this.salesPrice = _.parseNumber(options.salesPrice, null);
        this.downPayment = _.parseNumber(options.downPayment, null);
        this.ownerPolicyLiability = _.parseNumber(options.ownerPolicyLiability, null);
        this.earnestMoney = _.parseNumber(options.earnestMoney, null);
        this.earnestMoneyHeldBy = (options.earnestMoneyHeldBy === null) ? null : options.earnestMoneyHeldBy;
        this.additionalDeposit = _.parseNumber(options.additionalDeposit, null);
        this.optionFeePayableTo = (options.optionFeePayableTo === null) ? null : options.optionFeePayableTo;
        this.deductOptionFeeFromCommission = options.deductOptionFeeFromCommission || null;
        this.isAlta = _.isBoolean(options.isAlta) ? options.isAlta : null;
        this.hasPolicyNumber = _.isBoolean(options.hasPolicyNumber) ? options.hasPolicyNumber : null;
        this.resetToAutoCalculateDownPayment = _.isBoolean(options.resetToAutoCalculateDownPayment) ? options.resetToAutoCalculateDownPayment : false;
        this.autoCalculatedDownPayment = _.isBoolean(options.autoCalculatedDownPayment) ? options.autoCalculatedDownPayment : true;
        this.updateTaskAssignments = _.isBoolean(options.updateTaskAssignments) ? options.updateTaskAssignments : null;
        this.multipleBankAccount = _.isBoolean(options.multipleBankAccount) ? options.multipleBankAccount : null;
        this.typeFundID = options.typeFundID || null;
        this.optionFeeTypeFundID = options.optionFeeTypeFundID || null;
        this.lockEarnestMoneyTypeFund = _.parseBool(options.lockEarnestMoneyTypeFund, false);
        this.lockOptionFeeTypeFund = _.parseBool(options.lockOptionFeeTypeFund, false);
        this.deductEarnestMoneyFromCommission = options.deductEarnestMoneyFromCommission || null;
        this.lastFinalizedReconDate = options.lastFinalizedReconDate || '1/1/2000T00:00:00';
        this.enableMultipleBankAccount = _.parseBool(options.enableMultipleBankAccount, false);
        this.transactionTypeID = options.transactionTypeID || null;
        this.closingTypeID = options.closingTypeID || null;
        this.ready2CloseEnabled = _.parseBool(options.ready2CloseEnabled, false);
        this.isReady2Close = _.parseBool(options.isReady2Close, false);
        this.isPrimaryFile = _.parseBool(options.isPrimaryFile, false);
        this.primaryOrdersID = _.parseNumber(options.primaryOrdersID, null);
        this.lastSalesPriceChange = options.lastSalesPriceChange || null;
        this.pavasoOrderGUID = options.pavasoOrderGUID || null;
    }

    get placeOfClosingCompany() {
        return {
            companyID: this.placeOfClosingCompanyId,
            companyName: this.placeOfClosingCompanyName,
            contactID: this.placeOfClosingContactId,
            contactName: this.placeOfClosingContactName
        };
    }

    set placeOfClosingCompany(val) {
        this.placeOfClosingCompanyId = _.get(val, "companyID", null);
        this.placeOfClosingCompanyName = _.get(val, "companyName", null);
        this.placeOfClosingContactId = _.get(val, "contactID", null);
        this.placeOfClosingContactName = _.get(val, "contactName", null);
    }

    get settlementAgentCompany() {
        return {
            companyID: this.settlementAgentCompanyId,
            companyName: this.settlementAgentCompanyName,
            contactID: this.settlementAgentContactId,
            contactName: this.settlementAgentContactName
        };
    }

    set settlementAgentCompany(val) {
        this.settlementAgentCompanyId = _.get(val, "companyID", null);
        this.settlementAgentCompanyName = _.get(val, "companyName", null);
        this.settlementAgentContactId = _.get(val, "contactID", null);
        this.settlementAgentContactName = _.get(val, "contactName", null);
    }

    get hasPlaceOfClosingCompany() { return _.gt(_.parseNumber(this.placeOfClosingCompanyId, 0), 0); }

    get hasSettlementAgentCompany() { return _.gt(_.parseNumber(this.settlementAgentCompanyId, 0), 0); }

	toEntity() { return _.omit(this, ["statusName", "escrowUnitName", "serviceName", "workflowProcessTemplateName", "titleUnitName", "titleCompanyName", "orderCategoryName", "sourceOfBusinessName", "bankCompanyName", "underwriterCompanyName", "salesRepresentativeStaffName", "closeAgentStaffName", "escrowOfficerStaffName", "escrowProcessorStaffName", "titleOfficerStaffName", "titleProcessorStaffName", "placeOfClosingCompanyName", "placeOfClosingContactName", "settlementAgentCompanyName", "settlementAgentContactName"]); }
}

export class OrderMainPermissions {
    constructor (options) {
        options = options || {};
        this.AllowEditSalesRep = _.parseBool(options.AllowEditSalesRep, false);
        this.CanChangeEscrowAcct = _.parseBool(options.CanChangeEscrowAcct, false);
    }

    static createFromSecuritySettings (settings) {
        const userSecuritySettings = new UserSecuritySettings(settings);
        const settingsList = _.map(new OrderMainPermissions(), (v, k) => k);
        const permissions = userSecuritySettings.findValues(settingsList);
        return new OrderMainPermissions(permissions);
    }
}

export class OrderNote {
    constructor (options) {
        options = options || {};
        this.orderNoteID = _.parseNumber(options.orderNoteID, 0);
        this.idType = options.idType || null;
        this.source = options.source || null;
        this.publish = _.parseBool(options.publish);
        this.canEdit = _.parseBool(options.canEdit);
        this.canPublish = _.parseBool(options.canPublish);
        this.nDate = options.nDate || DateTimeHelper.now();
        this.idNum = options.idNum || null;
        this.ordersID = options.ordersID || null;
        this.usersID = _.parseNumber(options.usersID, null);
        this.category = _.parseNumber(options.category, 0);
        this.flag = _.parseNumber(options.flag, 0);
        this.priority = _.parseNumber(options.priority, 2);
        this.isCriticalNote = _.parseBool(options.isCriticalNote, false);
        this.contactID = options.contactID || null;
        this.notes = options.notes || "";
        this.paperlessCloserAccessID = options.paperlessCloserAccessID || null;
        this.isSystemGen = _.parseBool(options.isSystemGen);
        this.more = _.parseBool(options.more);
        this.categoryName = options.categoryName || OrderNoteCategory.displayValue(this.category);
        this.fullName = options.fullName || null;
        this.contentContainerHeight = options.contentContainerHeight || null;
        this.resolvedByUsersID = _.parseNumber(options.resolvedByUsersID, null);
        this.resolvedByFullName = options.resolvedByFullName || null;
        this.resolvedDate = options.resolvedDate || null;
    }
    get isNew() { return this.orderNoteID === 0; }
    get notesDisplay() {return _.escape(this.notes);}
    set notesDisplay(val) {this.notes = _.unescape(val);}
}

export class LoanModel {
    constructor (options) {
        options = options || {};
        this.downPayment = _.parseNumber(options.downPayment, 0);
        this.salesPrice = _.parseNumber(options.salesPrice, 0);
        this.ordersID = options.ordersID || null;
        this.loanID = _.parseNumber(options.loanID, 0);
        this.loanOrder = options.loanOrder || null;
        this.lenderID = _.parseNumber(options.lenderID, null);
        this.lender = options.lender || null;
        this.lenderContactID = _.parseNumber(options.lenderContactID, null);
        this.lenderContact = options.lenderContact || null;
        this.number = options.number || null;
        this.mortInsCaseNumber = options.mortInsCaseNumber || null;
        this.amount = _.parseNumber(options.amount, 0);
        this.loanPolicyLiability = _.parseNumber(options.loanPolicyLiability, 0);
        this.monthlyPrincipal = _.parseNumber(options.monthlyPrincipal, 0);
        this.monthlyInterest = _.parseNumber(options.monthlyInterest, 0);
        this.interestRate = _.parseNumber(options.interestRate, 0);
        this.term = _.parseNumber(options.term, 0);
        this.loanTermPeriod = _.parseNumber(options.loanTermPeriod, 3);
        this.loanTermPeriodName = this.getLoanTermPeriodName(this.loanTermPeriod);
        this.loanMonths = _.parseNumber(options.loanMonths, 0);
        this.mortgageInsurance = _.parseNumber(options.mortgageInsurance, 0);
        this.isPCUser = _.parseBool(options.isPCUser, null);
        this.lastChanged = options.lastChanged || null;
        this.lenderCreditAmount = _.parseNumber(options.lenderCreditAmount, 0);
        this.lenderCreditsText = options.lenderCreditsText || null;
        this.mortgageTypeID = options.mortgageTypeID || null;
        this.lastFeeCalcDate = options.lastFeeCalcDate || null; // not changed in the UI but tied to the Loan model server side so must be accounted for here to not be overwritten
        this.lastAmountChangeDate = options.lastAmountChangeDate || null; //RQO-25434 For Adding Banner on loan amount change.
        this.lastSalesPriceChange = options.lastSalesPriceChange || null;
        this.mortgageClauseTypeID = options.mortgageClauseTypeID || null;
        this.lastFeePullDate = options.lastFeePullDate || null;
    }

    get lenderCompany() {
        return {
            companyID: this.lenderID,
            companyName: this.lender,
            contactID: this.lenderContactID,
            contactName: this.lenderContact
        };
    }

    set lenderCompany(val) {
        this.lenderID = _.get(val, "companyID", null);
        this.lender = _.get(val, "companyName", null);
        this.lenderContactID = _.get(val, "contactID", null);
        this.lenderContact = _.get(val, "contactName", null);
    }

    getLoanTermPeriodName (termPeriod) {
        return _.get(_.find(this.termPeriods, p => p.id === _.parseNumber(termPeriod, null)), "name", null);
    }

    get termPeriods() {
        return [
            { id: 0, name: "Days", l1: "Days", l2: "" },
            { id: 1, name: "Months", l1: "Months", l2: "" },
            { id: 2, name: "Years", l1: "Years", l2: "" },
            { id: 3, name: "Years & Months", l1: "Years", l2: "Months" }
        ]
    }

    get termLengthDisplay () {
        if(this.term <= 0 && this.loanMonths<=0) return '';

        return this.loanTermPeriod != 3 ? `${this.term} ${this.loanTermPeriodName}` : `${this.term} Years, ${this.loanMonths} Months`;
    }

    get displayText () {
        if (this.lender || this.number) {
            let textArray = [];
            if (this.lender) textArray.push(this.lender);
            if (this.number) textArray.push(this.number);
            return textArray.join(" - ");
        }
        return "<Enter Loan Information>";
    }

	toDto() { return _.omit(this, ["lender", "lenderContact", "loanTermPeriodName"]); }
}

export class SwapSequenceModel
{
    constructor (options) {
        options = options || {};
        this.from = options.from || null;
        this.fromSequence = options.fromSequence || null;
        this.to = options.to || null;
        this.toSequence = options.toSequence || null;
    }
}

export class TitleCompanyLookupItem {
	constructor(options) {
		this.id = _.parseNumber(options.id, null);
		this.name = options.name || null;
        this.address1 = options.address1 || null;
		this.address2 = options.address2 || null;
		this.city = options.city || null;
		this.state = options.state || null;
		this.zip = options.zip || null;
		this.phone = options.phone || null;
        this.fax = options.fax || null;
        this.inactive = _.parseBool(options.inactive);
	}
}

export class BiRoleLookupItem {
	constructor(options) {
		this.id = _.parseNumber(options.id, null);
		this.name = options.name || null;
        this.biRoleName = options.biRoleName || null;
		this.description = options.description || null;
	}
}

// export const termPeriods = [
//     { id: 0, name: "Days", l1: "Days", l2: "" },
//     { id: 1, name: "Months", l1: "Months", l2: "" },
//     { id: 2, name: "Years", l1: "Years", l2: "" },
//     { id: 3, name: "Years & Months", l1: "Years", l2: "Months" }
// ]

export class LoanTermPeriod {
    get Days() { return 0 }
    get Months() { return 1 }
    get Years() { return 2 }
    get YearsAndMonths() { return 3 }
}

export class PrimaryOrderDto {
    constructor (options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, null);
		this.gfNo = options.gfNo || null;
        this.statusID = _.parseNumber(options.statusID, null);
        this.buyer = options.buyer || null;
        this.seller = options.seller || null;
        this.fundDate = options.fundDate || null;
        this.openDate = options.openDate || null;
        this.closeDate = options.closeDate || null;
        this.orderCategoryID = _.parseNumber(options.orderCategoryID, null);
        this.kindOfOrder = options.kindOfOrder || null;
        this.regionDisplay = options.regionDisplay || null;
        this.regID = _.parseNumber(options.regID, null);
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID, null);
        this.branch = options.branch || null;
        this.isGlobal = _.parseBool(options.isGlobal);
        this.propertyAddress = options.propertyAddress || null;
        this.escrowUnit = options.escrowUnit || null;
        this.escrowOfficer = options.escrowOfficer || null;
        this.escrowProcessor = options.escrowProcessor || null;
        this.titleUnit = options.titleUnit || null;
        this.titleOfficer = options.titleOfficer || null;
        this.titleProcessor = options.titleProcessor || null;
        this.salesRepresentative = options.salesRepresentative || null;
        this.closingAgent = options.closingAgent || null;
        this.secondaryCount = _.parseNumber(options.secondaryCount, 0);
    }
}
export class SecondaryOrderDto {
    constructor (options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, null);
		this.gfNo = options.gfNo || null;
		this.friendlyName = options.friendlyName || null;
		this.otherCompaniesFileNumber = options.otherCompaniesFileNumber || null;
        this.statusID = _.parseNumber(options.statusID, null);
        this.buyer = options.buyer || null;
        this.seller = options.seller || null;
        this.fundDate = options.fundDate || null;
        this.openDate = options.openDate || null;
        this.closeDate = options.closeDate || null;
        this.orderCategoryID = _.parseNumber(options.orderCategoryID, null);
        this.kindOfOrder = options.kindOfOrder || null;
        this.regionDisplay = options.regionDisplay || null;
        this.regID = _.parseNumber(options.regID, null);
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID, null);
        this.branch = options.branch || null;
        this.isGlobal = _.parseBool(options.isGlobal);
        this.escrowUnit = options.escrowUnit || null;
        this.escrowOfficer = options.escrowOfficer || null;
        this.escrowProcessor = options.escrowProcessor || null;
        this.titleUnit = options.titleUnit || null;
        this.titleOfficer = options.titleOfficer || null;
        this.titleProcessor = options.titleProcessor || null;
        this.salesRepresentative = options.salesRepresentative || null;
        this.closingAgent = options.closingAgent || null;
        this.address1 = options.address1 || null;
        this.countyName = options.countyName || null;
        this.city = options.city || null;
        this.state = options.state || null;
    }
    get fileNumber() { let s = _.size(_.split(this.gfNo, '-'))-1; return _.parseNumber(_.split(this.gfNo, '-')[s]); }
}

export class SecondaryFileMergeDto {
	constructor(options) {
		this.ordersID = _.parseNumber(options.ordersID, 0);
		this.gfNo = options.gfNo || null;
        this.settingsJSON = options.settingsJSON || null;
		this.mode = options.mode || null;
		this.existingFileCount = _.parseNumber(options.existingFileCount, 0);
		this.newFileCount = _.parseNumber(options.newFileCount, 0);
		this.updateIDList = options.updateIDList || null;
		this.setConfigOnly = _.parseBool(options.setConfigOnly, false);
	}
}

export class SecondaryFileListDto {
	constructor(options) {
		this.ordersID = _.parseNumber(options.ordersID, 0);
		this.gfNo = options.gfNo || null;
        this.friendlyName = options.friendlyName || null;
		this.isPrimary = _.parseBool(options.isPrimary, true);
	}
    get fileNumber() { let s = _.size(_.split(this.gfNo, '-'))-1; return this.isPrimary ? 0 : _.parseNumber(_.split(this.gfNo, '-')[s]); }
}

export class OrderTimeTracker {
	constructor(options) {
		this.ordersID = _.parseNumber(options.ordersID, 0);
		this.orderTimeTrackingCategoryID = _.parseNumber(options.orderTimeTrackingCategoryID, 0);
        this.startTime = options.startTime || null;
		this.durationInSeconds = _.parseNumber(options.durationInSeconds, 0);
		this.status = options.status || "Stopped";
		this.usersID = _.parseNumber(options.usersID, 0);
		this.display = options.display || "00:00";
	}
}

export class OrderTimeTrackingDto {
	constructor(options) {
		this.orderTimeTrackingID = _.parseNumber(options.orderTimeTrackingID, 0);
		this.orderTimeTrackingCategoryID = _.parseNumber(options.orderTimeTrackingCategoryID, null);
		this.ordersID = _.parseNumber(options.ordersID, 0);
        this.startTime = options.startTime || null;
		this.durationInMins = _.parseNumber(options.durationInMins, 0);
		this.usersID = _.parseNumber(options.usersID, 0);
        this.userDisplayName = options.userDisplayName || null;
        this.modifiedBy = options.modifiedBy || null;
        this.modifiedDate = options.modifiedDate || null;
	}
}

export class OrderTimeTrackingLossDetailDto {
	constructor(options) {
		this.orderTimeTrackingLossDetailID = _.parseNumber(options.orderTimeTrackingLossDetailID, 0);
		this.orderTimeTrackingAccountCodeID = _.parseNumber(options.orderTimeTrackingAccountCodeID, null);
		this.ordersID = _.parseNumber(options.ordersID, 0);
		this.amount = _.parseNumber(options.amount, 0);
        this.modifiedBy = options.modifiedBy || null;
        this.modifiedByName = options.modifiedByName || null;
        this.modifiedDate = options.modifiedDate || null;
	}
}

export class OrderTimeTrackingLossInfoDto {
	constructor(options) {
		this.orderTimeTrackingLossInfoID = _.parseNumber(options.orderTimeTrackingLossInfoID, 0);
		this.ordersID = _.parseNumber(options.ordersID, 0);
		this.loanAmount = _.parseNumber(options.loanAmount, 0);
		this.loanRateDescription = options.loanRateDescription || null;
		this.loanRebateAmount = _.parseNumber(options.loanRebateAmount, 0);
		this.loanAgencyLossesPaid = _.parseNumber(options.loanAgencyLossesPaid, 0);
		this.loanFraudLosses = _.parseNumber(options.loanFraudLosses, 0);
		this.ownerAmount = _.parseNumber(options.ownerAmount, 0);
		this.ownerRateDescription = options.ownerRateDescription || null;
		this.ownerRebateAmount = _.parseNumber(options.ownerRebateAmount, 0);
		this.ownerAgencyLossesPaid = _.parseNumber(options.ownerAgencyLossesPaid, 0);
		this.ownerFraudLosses = _.parseNumber(options.ownerFraudLosses, 0);
        this.modifiedBy = options.modifiedBy || null;
        this.modifiedByName = options.modifiedByName || null;
        this.modifiedDate = options.modifiedDate || null;
	}
}

export class EzJacketOrder
{
    constructor (options) {
        options = options || {};
        this.agencyNumber = options.agencyNumber || null;
        this.fileNumber = options.fileNumber || null;
        this.propertyType = _.parseNumber(options.propertyType, null);
        this.effectiveDate = options.effectiveDate || null;
        this.address = options.address || {};
        this.countyCode = options.countyCode || null;
        this.comments = options.comments || null;
        this.endorsementOnly = _.parseBool(options.endorsementOnly, false);
        this.allowEndorsementOnlyToggle = _.parseBool(options.allowEndorsementOnlyToggle, true);
        this.showAllFields = _.parseBool(options.showAllFields, true);
        this.allowShowAllFieldsToggle = _.parseBool(options.allowShowAllFieldsToggle, false);
    }
}