<template>
    <div class="content-wrapper">
        <rq-page-section title="Properties" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <add-property-dropdown ref="btn_add_property" automation_id="btn_add_property" :disabled="readOnly" @add-property="onAddProperty" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dg_properties"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            :export-file-name="exportFileName"
            @select="onSelectAction"
            @delete="onDeleteAction"
            @rowDoubleClick="onGridRowDoubleClick"
            hide-search
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { GET_LOANS, PROPERTY_ACTIONS, ORDER_ACTIONS } from "@/store/actions";
    import { GlobalEventManager } from "@/app.events";
    import AddPropertyDropdown from "../components/AddPropertyDropdown";

    export default {
        name: "ManageProperties",

        components: { AddPropertyDropdown },

        data() {
            const self = this;
            return {
                gridDataSource: [],
                copyPropertyOptions: [
                    { id: 1, name: "Buyer's Primary Address" },
                    { id: 2, name: "Buyer's Forwarding Address" },
                    { id: 3, name: "Seller's Primary Address" },
                    { id: 4, name: "Seller's Forwarding Address" }
                ],
                selectedIndex: 0,
                selectedCopySrcId: 0,
                selectedProperty: {},
                isLoaded: false,
                gridConfig: {},
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                properties: state => state.properties.properties,
                loans: state => state.orders.loans,
                readOnly: state => state.isPageReadOnly
            }),
            isOrderLocked() { return _.parseBool(this.orderSummary.isLocked); },
            multipleProperties() { return this.properties.length > 1; },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            exportFileName() { return `${this.orderSummary.fileNumber}_properties` },
            selectionActions() {
                const self = this;
                return [
                    { name: "select", text: self.readOnly ? "View" : "Edit", eventName: "select", requireSelection: true },
                    { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, disabled: self.readOnly }
                ];
            }
        },

        watch: {
            properties: {
                handler (newValue, oldValue) {
                    this.loadProperties();
                }
            },
            isOrderLocked(newVal, oldVal){
                this.gridInstance.repaint();
            }
        },

        created() {
            this.initGrid();
            this.loadProperties();
        },

        mounted() {
            this.$refs.btn_add_property.$el.firstElementChild.focus();
        },

        methods: {
            loadProperties(){
                const self = this;
                // if(!_.isEmpty(self.properties) && self.properties.length === 1) {
                //     self.navigateToProperty(self.properties[0].property.propertyID);
                //     return;
                // }
                if(self.isLoaded)
                    self.gridInstance.refresh();
            },

            initGrid(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "sequence", dataType: "number", caption: "No.", width:100, alignment: "left" },
                        { dataField: "address1", dataType: "string" },
                        { dataField: "address2", dataType: "string" },
                        { dataField: "city", dataType: "string" },
                        { dataField: "state", dataType: "string" },
                        { dataField: "countyName", dataType: "string", caption: "County" },
                        { dataField: "zip", dataType: "string" },
                        { dataField: "firstTaxIdentification", dataType: "string", caption: "Tax ID", visible: false }
                    ],
                };
                self.gridDataSource = {
                    key: "propertyID",
                    load: self.fetchStoreData
                };
            },

            onGridRowDoubleClick(e) {
                this.$emit("property-selected", e);
            },

            onSelectAction(e) {
                this.$emit("property-selected", e);
            },

            onDeleteAction(e) {
                if(!e || !e.data) return;
                let properties = [];
                for(let x = 0; x < e.data.length; x++)
                {
                    properties.push(e.data[x].propertyID);
                }
                this.onDelete(properties);
            },

            onAddProperty(e) {
                let args = e || {};
                args.loanID = this.loans[0].loanID;
                args.sequence = this.properties.length + 1;
                this.$emit("add-property", args);
            },

            onDelete(ids) {
                const self = this;
                let okHandler = () => {
                    self.$emit("delete-property", ids);
                };
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete a property?", okHandler);
            },

            fetchStoreData() {
                const self = this;
                self.selectedIndex = 0;
                self.selectedCopySrcId = 0;
                self.selectedProperty = {};
                self.validationErrors = [];
                self.isLoaded = true;
                return _.map(self.properties, item => {
                    const p = _.cloneDeep(item);
                    p.property.firstTaxIdentification = p.taxIdentification[0]?.taxIdentification;
                    return p.property;
                });
            },

            navigateToProperty(propertyId) {
                this.$router.push({ name:"oe:op:property", params: { orderId: this.orderId, propertyId } });
            }

        }
    };

</script>
