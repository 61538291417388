<template>
    <rq-page-section
        :title="title"
        class="tp-clause-management"
        header-class="rq-sticky-top bg-white"
        borderless>
        <template #header-actions>
            <slot name="actions-start"></slot>
            <ul class="nav ms-auto">
                <li v-if="isOwnerPolicyView || isLoanPolicyView" class="nav-item pt-tiny" v-rq-tooltip.hover.top="{ title: 'Do not update from title production' }">
                    <b-form-checkbox
                        automation_id="chk_override_clauses"
                        id="chk_override_clauses"
                        :disabled="readOnly"
                        :checked="overrideFlag"
                        @change="onOverrideFlagChange"
                        switch>Override
                    </b-form-checkbox>

                </li>
                <li class="nav-item pt-tiny">
                    <RqCheckbox
                        automation_id="chk_reorder_clauses"
                        :disabled="readOnly"
                        v-model="reorderEnabled"
                        switch>Reorder Clauses
                    </RqCheckbox>
                </li>
                <li v-if="assignedLanguageSections.length > 1" class="nav-item ms-4">
                    <rq-section-expand-collapse-all
                        :section-group="sectionGroup"
                        inline
                    />
                </li>
            </ul>
            <slot name="actions-end"></slot>
        </template>
        <slot name="before-sections"></slot>
        <assigned-language-list
            v-for="section in assignedLanguageSections"
            ref="listComponents"
            :key="section.clientKey"
            :loan-id="loanId"
            :section="section"
            :section-group="sectionGroup"
            :reorder-enabled="reorderEnabled"
            :readOnly="readOnly"
            @changed="onListChanged"
        />
        <slot name="after-sections"></slot>
    </rq-page-section>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { AssignedLanguageSection, CommitmentDataDto } from '../../models';
    import { AssignedLanguageListType, PolicyType, AppliesToType } from '../../enums';
    import AssignedLanguageList from './AssignedLanguageList';
    import { COMMITMENT_ACTIONS } from '@/store/actions';
    import { useVModel } from "@vueuse/core";

    export default {
        name: "ClauseManagement",

        props:{
            title: { type: String, default: "" },
            commitmentData: { type: CommitmentDataDto, default: new CommitmentDataDto() },
            policyType: { type: Number, default: null },
            titleProductionTab: { type: String, default: "" },
            titleProdCustomTabId: { type: Number, default: 0 },
            hasChanges: { type: Boolean, default: false }
        },

        emits: ["update:commitmentData","update:hasChanges"],

        setup(props, { emit }) {
            const commitmentDataValue = useVModel(props, "commitmentData", emit);
            const hasChangesValue = useVModel(props, "hasChanges", emit);
            return {
                commitmentDataValue,
                hasChangesValue
            };
        },

        components: { AssignedLanguageList },

        data() {
            return {
                assignedLanguageSections: [],
                activeSectionLayout: {},
                overrideSaved: false,
                reorderEnabled: false
            };
        },

        computed: {
            ...mapGetters([
                "getDefaultSectionCategoryId",
                "getSectionCategories"
            ]),
            ...mapState({
                assignedLanguages: state => state.orders.assignedLanguages,
                orderId: state => state.orders.orderId,
                commitment: state => state.orders.commitment,
                loans: state => state.orders.loans || [],
                orderSummary: state => state.orders.orderSummary,
                standardLanguageSections: state => state.orders.standardLanguageSections || [],
                isAlta: state => _.getBool(state, "orders.orderSummary.isAlta"),
                isPageReadOnly: state => _.parseBool(state.isPageReadOnly)
            }),
            readOnly() { return this.isPageReadOnly; },
            allSectionsExpanded() { return _.every(this.assignedLanguageSections, l => l.sectionExpanded); },
            allSectionsCollapsed() { return _.every(this.assignedLanguageSections, l => !l.sectionExpanded); },
            customSections() { return _.sortBy(_.filter(this.standardLanguageSections, { titleProdCustomTabID: this.titleProdCustomTabId }), ["displayOrder"]); },
            isCustomTab() { return _.gt(this.titleProdCustomTabId, 0); },
            isOwnerPolicyView() { return this.policyType === PolicyType.Owner; },
            isLoanPolicyView() { return this.policyType === PolicyType.Loan; },
            isPolicyView() { return this.isOwnerPolicyView || this.isLoanPolicyView; },
            policyTypeName() { return this.isPolicyView ? PolicyType.displayValue(this.policyType) : ""; },
            loanId() { return _.getNumber(this, "commitmentData.detail.loanID", 0); },
            headerId() { return _.getNumber(this, "commitmentData.detail.commitmentPolicyHeaderID", 0); },
            detailId() { return _.getNumber(this, "commitmentData.detail.commitmentPolicyDetailID", 0); },
            isOverridden() {
                return (this.isOwnerPolicyView && _.getBool(this, "commitmentData.header.ownerScheduleBOverride"))
                    || (this.isLoanPolicyView && (_.getBool(this, "commitmentData.detail.mortgageScheduleBOverride") || _.getBool(this, "commitmentData.detail.mortgageScheduleB2Override")));
            },
            overrideFlag() { return this.isOverridden; },
            sectionGroup() {
                return _.joinParts([
                    "clause_mgmt",
                    this.isPolicyView ? "policy" : "title",
                    _.toLower(this.policyTypeName),
                    this.titleProductionTab,
                    this.titleProdCustomTabId
                ], "_");
            }
        },

        watch: {
            detailId: {
                handler(newValue, oldValue) {
                    if(newValue === 0 || newValue === oldValue) return;
                    this.setAssignedLanguageSections(oldValue > 0);
                },
                immediate: true
            },
            policyType(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.setAssignedLanguageSections();
            },
            hasChangesValue(newValue, oldValue) {
                if(newValue || newValue === oldValue) return;
                this.setAssignedLanguageSections(true);
            }
        },

        created() {
            const self = this;
            if(self.titleProductionTab || self.isPolicyView || self.isCustomTab) return;
            console.error("Invalid clause management tab property.");
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {
            onListChanged(e) {
                this.hasChangesValue = true;
                this.setOverrideValue(true, _.getNumber(e, "section.listType"));
            },

            onRemovePolicyOverride(e) {
                const self = this;
                let policyKey = self.isOwnerPolicyView ? self.headerId : self.isLoanPolicyView ? self.detailId : 0;

                if((!self.isOwnerPolicyView && !self.isLoanPolicyView) || policyKey === 0) return;

                if(!self.overrideSaved){
                    self.removeUncommittedOverride();
                    return;
                }

                let policyType = self.isOwnerPolicyView ? PolicyType.Owner : PolicyType.Loan;
                let okHandler = function (e) {
                    let storePromise = self.$store.dispatch(COMMITMENT_ACTIONS.REMOVE_POLICY_OVERRIDE, { policyKey, policyType })
                    self.$rqBusy.wait(storePromise)
                        .then(() => {
                            self.setAssignedLanguageSections();
                            self.setSpecificOverrideFlags(false);
                            self.overrideSaved = false;
                            self.overrideFlag = false;
                            self.$toast.success(`${self.policyTypeName} Policy Data restored successfully`);
                        })
                        .catch(error => {
                            console.error(error);
                            self.$toast.error({ message: "Failed to remove policy override" });
                            self.cancelHandler();
                        });
                };

                let cancelHandler = function(e) {
                    self.overrideFlag = true;
                    const checkbox = document.getElementById('chk_override_clauses');
                    checkbox.checked = true;
                };

                self.$dialog.confirm("Confirm", `Are you sure?  This will remove all clauses modified under ${self.policyTypeName} policy and restore the default items.`, okHandler, cancelHandler);
            },

            removeUncommittedOverride() {
                this.setAllOverrideValues(false);
                this.hasChangesValue = false;
            },

            getSectionCategoryInfo(listType) {
                let standLangSectionName = AssignedLanguageListType.getSectionName(listType, this.titleProductionTab);
                return {
                    defaultCategoryId: this.getDefaultSectionCategoryId(standLangSectionName),
                    categories: this.getSectionCategories(standLangSectionName)
                };
            },

            getActiveSectionLayout(listType){
                const self = this;
                let layout = _.find(self.activeSectionLayout, { listType });
                return layout ? _.parseBool(layout.sectionExpanded, true) : true;
            },

            setActiveSectionLayout(){
                const self = this;
                self.activeSectionLayout = _.map(self.assignedLanguageSections, s => ({ listType: s.listType, sectionExpanded:  s.sectionExpanded }));
            },

            setAssignedLanguageSections(checkState=false){
                const self = this;
                self.overrideSaved = self.isOverridden;

                self.setActiveSectionLayout();

                if(self.isCustomTab){
                    self.assignedLanguageSections = _.map(self.customSections, s=>{
                        return new AssignedLanguageSection({
                            standardLanguageSectionID: s.standardLanguageSectionID,
                            listType: AssignedLanguageListType.CustomCategory,
                            sectionLabel: s.sectionLabel,
                            displayOrder: s.displayOrder,
                            sectionExpanded: self.getActiveSectionLayout(AssignedLanguageListType.CustomCategory),
                            commitmentPolicyHeaderID: self.headerId,
                            commitmentPolicyDetailID: self.detailId,
                            standardLanguageCategoryID: s.regionDefaultCategoryID || s.globalDefaultCategoryID,
                            categories: s.categories,
                            checkState
                        }, self.orderId, self.loanId);
                    });
                }
                else {
                    let listTypes = [];
                    let appliesToTypes = AppliesToType.getPolicyAppliesToTypes();
                    if(self.titleProductionTab)
                        listTypes = AssignedLanguageListType.getTitleProductionLookupItems(self.isAlta, self.titleProductionTab);
                    else if(self.isPolicyView) {
                        listTypes = AssignedLanguageListType.getPolicyLookupItems(self.isAlta, self.policyType);
                        //filter out only those that are applied to what you are looking for All, Loan, Policy
                        appliesToTypes = AppliesToType.getPolicyAppliesToTypes(self.policyType);
                    }

                    self.assignedLanguageSections = _.map(listTypes, t=>{
                        let categoryInfo = self.getSectionCategoryInfo(t.id);
                        return new AssignedLanguageSection({
                            commitmentPolicyHeaderID: self.headerId,
                            commitmentPolicyDetailID: self.detailId,
                            listType: t.id,
                            sectionLabel: t.name,
                            sectionExpanded: self.getActiveSectionLayout(t.id),
                            appliesToTypes,
                            standardLanguageCategoryID: categoryInfo.defaultCategoryId,
                            categories: categoryInfo.categories,
                            checkState
                        }, self.orderId, self.loanId);
                    });
                }

                self.reorderEnabled = false;
            },

            setAllSectionExpandedState(expanded){
                _.forEach(this.assignedLanguageSections, l => { l.sectionExpanded = expanded; });
            },

            setOverrideValue(val, type) {
                if(!this.isPolicyView) return;

                //only OwnerSchedB (4), MortSchedBResCov (5), and MortSchedBExceptions (6) list types are synced
                //InsuredMortgage (8) has an override value column in CommitmentPolicyDetail, so setting it accordingly to future-proof
                let expr = "";
                switch(type) {
                    case AssignedLanguageListType.OwnerSchedB:
                        expr = "header.ownerScheduleBOverride";
                        break;
                    case AssignedLanguageListType.MortSchedBExceptions:
                        expr = "detail.mortgageScheduleBOverride";
                        break;
                    case AssignedLanguageListType.MortSchedBResCov:
                        expr = "detail.mortgageScheduleB2Override";
                        break;
                    case AssignedLanguageListType.InsuredMortgage:
                        expr = "detail.insuredMortgageOverride";
                        break;
                }
                if(_.isEmpty(expr)) return;
                _.set(this.commitmentDataValue, expr, val);
            },

            setAllOverrideValues(val) {
                _.set(this.commitmentDataValue, "header.ownerScheduleBOverride", val);
                _.set(this.commitmentDataValue, "detail.mortgageScheduleBOverride", val);
                _.set(this.commitmentDataValue, "detail.mortgageScheduleB2Override", val);
                _.set(this.commitmentDataValue, "detail.insuredMortgageOverride", val);
            },

            getListData() {
                let listComponents = this.$refs?.listComponents || [];
                let dataLists = _.map(listComponents, c => c.getItemData());
                let dataResult = _.flatten(dataLists);
                _.forEach(dataResult, item => {
                    item.ordersID = this.orderId;
                    item.loanID = this.loanId;
                });
                return dataResult;
            },

            reset() {
                this.setAssignedLanguageSections();
            },

            onOverrideFlagChange(e) {
                const self = this;
                if(e == false) {
                    self.onRemovePolicyOverride();
                }
                else {
                    self.setSpecificOverrideFlags(true);
                    self.overrideFlag = e;
                }
            },

            setSpecificOverrideFlags(val) {
                const self = this;
                _.forEach(self.assignedLanguageSections, languageSection => {
                    let expr = '';
                    if(self.isOwnerPolicyView && languageSection.listType == AssignedLanguageListType.OwnerSchedB)
                        expr = "header.ownerScheduleBOverride";
                    else if(self.isLoanPolicyView) {
                        if(languageSection.listType == AssignedLanguageListType.MortSchedBExceptions)
                            expr = "detail.mortgageScheduleBOverride";
                        else if(languageSection.listType == AssignedLanguageListType.MortSchedBResCov)
                            expr = "detail.mortgageScheduleB2Override";
                    }

                    if(!_.isEmpty(expr))
                        _.set(self.commitmentDataValue, expr, val);
                });
            },

            onSave() {
                const self = this;
                self.overrideSaved = true;
            }
        }
    };
</script>
<style lang="scss">
    .pt-tiny { padding-top: 1px; }
</style>
