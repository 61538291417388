<template>
    <div class="content-wrapper rq-container">
        <rq-banner
            ref="warnBanner"
            :message="warnMessage"
            variant="warning"
            icon="fas fa-exclamation-triangle"
            :visible="warnMessage.length > 0 && errorMessage.length == 0"
        />
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <rqdx-action-data-grid
            ref="dataGrid"
            title-size="md"
            automation_id="tbl_consolidated_check_lines"
            class="rq-modal-datagrid"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :persist-state="false"
            export-file-name="consolidated_check_lines_data"
            fixed-header
            hide-search
            hide-settings
            rq-editable
            rq-filters>
        </rqdx-action-data-grid>
        <fieldset class="py-3" :disabled="readOnly">
            <div class="row mb-1">
                <div class="col col-3 form-group">
                    <label class="form-control-label" for="txt_original_amount">{{ amountLabel }} Amount</label>
                    <span id="txt_original_amount" class="form-control text-muted text-end">{{ originalAmount }}</span>
                </div>
                <div class="col col-3 form-group">
                    <label class="form-control-label" for="txt_adjusted_amount">Adjusted Amount</label>
                    <span id="txt_adjusted_amount" class="form-control text-muted text-end">{{ adjustedAmount }}</span>
                </div>
                <div class="col col-6 form-group mt-4">
                    <b-form-checkbox
                        automation_id="chk_full_reversal"
                        id="chk_full_reversal"
                        @change="onFullReversal"
                        v-model="request.fullReversal">Full Reversal
                    </b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-12 form-required form-group':true, 'has-error': v$.request.requestorComment.$error }">
                    <label for="txt_comment">Comment</label>
                    <textarea
                        rows="2"
                        automation_id="txt_comment"
                        v-model="v$.request.requestorComment.$model"
                        type="text"
                        class="form-control"
                        id="txt_comment"
                        placeholder="Comment"
                        maxlength="1000">
                    </textarea>
                    <rq-validation-feedback
                        :messages="{ 'Comment is required': v$.request.requestorComment.required.$invalid }"
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { CheckShortDto, CheckLineAdjustmentDto, ConsolidatedCheckAdjustmentRequestDto }  from "../models";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";


    export default {
        name: "ConsolidatedCheckAdjustmentForm",
        mixins: [GridSystemLookupMixin],
        props: {
            check: {type: Object, required: true},
            checkLines: {type: Array, required: true, default: () => []}
        },
        components: {  },

        inject: ["dialogId"],

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                errorMessage: "",
                warnMessage: "",
                request: new ConsolidatedCheckAdjustmentRequestDto(),
                item: {},
                itemDetail: [],
                selectionActions: []
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            accountingCodes() { return this.lookupHelpers.getAllLookupItems(this.lookupItems.ACCOUNTING_CODES); },
            totalAdjustedAmount() { return _.sumBy(this.itemDetail, "totalAdjustedAmount"); },
            hasChanges() { return _.some(this.request.checkLines, ["hasChanges", true]); },
            adjustedAmount() { return accounting.formatMoney(this.request.adjustedAmount); },
            amountLabel() { return this.totalAdjustedAmount > 0 ? "Effective" : "Original"; },
            originalAmount() { return accounting.formatMoney(this.request.amount); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "AllowConsolidatedDisbursementAdjustment",
                    ]);
            },
            isCdf() { return _.isEqual(_.get(this, "summary.autoRecreateSource", -1), 3); },
            readOnly() { return this.isFileLocked; }
        },
        created(){
            this.init();
            this.initNonReactiveVariables();
            this.initGridConfig();
        },
        validations() {
            var result = {
                request: {
                    requestorComment: {
                        required
                    },
                }
            }
            return result;
        },
        methods: {
            init() {
                this.item = new CheckShortDto(this.check);
                this.itemDetail = _.map(this.checkLines, i => new CheckLineAdjustmentDto(i));
                let effectiveAmount = this.item.amount - this.totalAdjustedAmount;
                this.request = new ConsolidatedCheckAdjustmentRequestDto({ordersID: this.item.ordersID, regionID: this.order.regionID, checksID: this.item.checksID, amount: effectiveAmount, adjustedAmount: effectiveAmount, checkLines: this.itemDetail});
                this.warnMessage = this.totalAdjustedAmount > 0 ? `Lines have been adjusted to reflect prior adjustments totaling ${accounting.formatMoney(this.totalAdjustedAmount)}.` : ""
                this.errorMessage = effectiveAmount <= 0 ? `Prior adjustments totaling ${accounting.formatMoney(this.totalAdjustedAmount)} prohibit more adjustments.` : ""
                this.$emit(`${(this.readOnly || _.size(this.errorMessage) > 0) ? "disable" : "enable"}-ok`);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    allowColumnReordering: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    selection: { mode: "none", showCheckBoxesMode: "never" },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        {
                            dataField: "lineNumberStr",
                            caption: "Line",
                            dataType: "string",
                            readOnly: true,
                            width: 100,
                            visible: self.isCdf,
                            calculateCellValue: function(rowData){
                                return DxGridUtils.lineNumberStrDisplay(rowData)
                            },
                            editorOptions: { disabled: true },
                        },
                        {
                            dataField: "lineNumber",
                            caption: "Line",
                            dataType: "number",
                            readOnly: true,
                            width: 100,
                            visible: !self.isCdf,
                            editorOptions: { disabled: true },
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: { disabled: true },
                        },
                        {
                            dataField: "newAmount",
                            caption: "Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let currentData = cellInfo.data;
                                let tooltipText = null;

                                if(!_.isEqual(currentData.amount, currentData.newAmount)) {
                                    cellElement.addClass("rq-overridden");
                                    tooltipText = `Original Value: $${currentData.amount}`;
                                }
                                if(!_.isEmpty(tooltipText)) {
                                    let $tooltip = $("<span/>")
                                        .append(tooltipText)
                                        .dxTooltip({
                                            target: cellElement,
                                            wrapperAttr: { class: "rq-dx-tooltip" },
                                            position: "bottom",
                                            showEvent: "mouseenter",
                                            hideEvent: "mouseleave"
                                        });
                                    cellElement.append($tooltip)
                                }
                                $("<span />")
                                    .append(accounting.formatMoney(currentData.newAmount))
                                    .appendTo(cellElement);
                            },
                            setCellValue(rowData, value) {
                                rowData.newAmount = value;
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    message: "Amount must be less than original amount.",
                                    validationCallback(e){ let isValid = _.lte(e.data.newAmount, e.data.amount);self.$emit(`${isValid ? "enable" : "disable"}-ok`);return isValid; }
                                }
                            ],
                            editorOptions: { format: {type: "currency", precision: 2}, showClearButton: false },
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalLabel",
                                column: "description",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "CheckTotal",
                                column: "newAmount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                };

                self.gridDataSource = {
                    key: self.detailKey,
                    load (loadOptions) {
                        return Promise.resolve(self.request.checkLines);
                    },
                    update: self.onDetailGridUpdate
                };
            },

            initNonReactiveVariables() {
                this.detailKey = "checkLineID";
            },

            onFullReversal(checked) {
                if (checked) {
                    _.each(this.request.checkLines, l => {
                        l.newAmount = 0;
                        l.hasChanges = true;
                    });
                } else {
                    _.each(this.request.checkLines, l => {
                        l.newAmount = _.getNumber(l, "amount", 0);
                        l.hasChanges = false;
                    });
                }
                this.request.adjustedAmount = _.sumBy(this.request.checkLines, "newAmount");
                this.refresh();
            },

            onDetailGridUpdate(key, values) {
                const self = this;
                self.errorMessage = "";
                let detailIndex = _.findIndex(self.request.checkLines, [self.detailKey, key]);
                if(detailIndex < 0) return;
                let updatedItem = _.assign({}, self.request.checkLines[detailIndex], values);
                updatedItem.hasChanges = true;
                self.request.checkLines[detailIndex] = updatedItem;
                self.request.adjustedAmount = _.sumBy(self.request.checkLines, "newAmount");
                self.refresh();
            },

            refresh() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            save(){
                const self = this;
                if(self.readOnly) return;
                self.errorMessage = "";
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }
                self.request.adjustedAmount += self.totalAdjustedAmount;//add back prior adjusted amount
                self.request.amount += self.totalAdjustedAmount;//add back prior adjusted amount
                if (!self.hasChanges || _.isEqual(self.request.amount, self.request.adjustedAmount)) {
                    self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve({});
                }
                //only push lines with changes
                self.request.checkLines = _.filter(_.clone(self.request.checkLines), ["hasChanges", true]);

                let apiPromise = self.$api.CheckWritingApi.saveConsolidatedAdjustment(self.request, null);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    }
</script>