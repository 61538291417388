<template>
    <tr :class="{ 'total-row':true, 'total-row-collapsible':!showCollapseToggle, 'total-row-collapsed': !showCollapseToggle && !expanded }">
        <td>
            <div class="cell-content collapse-toggle" @click="totalsExpanded = !totalsExpanded">
                <FontAwesomeIcon v-if="showCollapseToggle" icon="fas fa-caret-right" :rotation="iconRotation" />
            </div>
        </td>
        <td class="total-description text-left font-weight-bold" :colspan="noTax ? 3 : 5">
            <div class="cell-content">{{totals.label}}:</div>
        </td>
        <td class="text-right" v-if="!noTax">
            <div class="cell-content">{{formatMoney(totals.tax)}}</div>
        </td>
        <td class="text-right">
            <div class="cell-content">{{formatMoney(totals.total)}}</div>
        </td>
        <td class="text-right" colspan="2">
            <div class="cell-content">{{formatMoney(totals.buyer)}}</div>
        </td>
        <td class="text-right" colspan="2">
            <div class="cell-content">{{formatMoney(totals.seller)}}</div>
        </td>
        <td class="text-right" colspan="2">
            <div class="cell-content">{{formatMoney(totals.uwSplit)}}</div>
        </td>
        <td colspan="5">
            <div class="cell-content">&nbsp;</div>
        </td>
    </tr>
</template>
<script>
    export default {
        props: {
            totals: { type: Object, default: () => ({}) },
            showCollapseToggle: { type: Boolean, default: false },
            expanded: { type: Boolean, default: false },
            noTax: { type: Boolean, default: false }
        },
        computed: {
            totalsExpanded: {
                get() { return this.expanded; },
                set(val) { this.$emit("update:expanded", val); }
            },
            iconRotation() {
                if(this.totalsExpanded) return 90;
                return 0;
            }
        },
        methods: {
            formatMoney(v) {
                return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } });
            }
        }
    }
</script>