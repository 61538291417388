<template>    
    <rq-banner
        v-for="(warning, index) in doNotDoBusinessWithWarnings"
        :key="index"
        :message="warning"
        variant="error"
        icon="fas fa-exclamation-triangle"
        :visible="doNotDoBusinessWithWarnings.length > 0"
        dismissable
        sticky
    />
    <router-view
        @property-selected="onPropertySelected"
        @add-property="onAddProperty"
        @delete-property="onDeleteProperty">
    </router-view>
</template>

<script>
    import { mapState } from "vuex"
    import { ORDER_ACTIONS, PROPERTY_ACTIONS } from "@/store/actions";
    import { PropertyEntityModel, PropertyViewModel } from "./models";

    const DNDB_WARNING_MESSAGE = "Property is on the do not do business with list";

    export default {
        name: "PropertiesMain",

        data() {
            const self = this;
            return {
                doNotDoBusinessWithWarnings: []
            }},

        beforeMount() {
            this.checkForDNDBProperties();
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                loans: state => state.orders.loans,
                properties: state => state.properties.properties
            }),
            isOrderLocked() { return _.parseBool(this.orderSummary.isLocked); }
        },

        watch: {
            properties()
            {
                this.checkForDNDBProperties();
            }
        },

        methods: {
            onPropertySelected(e) {
                if(!e || !e.data) return;
                this.navigateToProperty({ orderId: e.data.ordersID, propertyId: e.data.propertyID });
            },

            onAddProperty (e) {
                const self = this;
                this.doNotDoBusinessWithWarnings = [];
                // Detect whether copy property info from buyer, seller address
                let addressOptions = e || {};
                if(!_.isNil(addressOptions.buyerSellerType) && !_.isNil(addressOptions.addressType)) {
                    let storePromise = self.$store.dispatch(PROPERTY_ACTIONS.COPY_BUYER_SELLER_ADDRESS, addressOptions)
                    self.$rqBusy.wait(storePromise)
                        .then(newProperty => {
                            self.$router.push({ name:"oe:op:property", params: { propertyId: null, copyData: JSON.stringify(newProperty), action: "copyProperty" } });
                        });
                }
                // Detect whether copy property info from current property address selected
                else if (!_.isNil(e.action) && e.action === 'copyProperty') {
                    self.$router.push({ name:"oe:op:property", params: { propertyId: null, copyData: JSON.stringify(e.copyData), action: e.action } });
                }
                // All else create empty property to be added
                else {
                    self.$router.push({ name:"oe:op:property", params: { propertyId: null } });
                }
            },

            onDeleteProperty (propertyIds) {
                const self = this;

                if(propertyIds.length === 0) {
                    self.$toast.error("Invalid Property ID.  Contact your administrator.");
                    return;
                }

                let targetNoun = propertyIds.length === 1 ? 'property' : 'properties';

                let storePromise = self.$store.dispatch(PROPERTY_ACTIONS.DELETE_PROPERTY, propertyIds);
                return self.$rqBusy.wait(storePromise)
                    .then(() => {
                        return self.$store.dispatch(PROPERTY_ACTIONS.GET_PROPERTIES, false);
                    })
                    .then(() => {
                        if (self.properties.length == 1) {
                            self.$router.push({ name:"oe:op:property", params: { propertyId: self.properties[0].property.propertyID, skipSave: true } });
                        }
                        else if (self.properties.length < 1) {
                            // There are no properties on this order, so take them to the add new property screen, with skipSave = true
                            // in case they choose not to add a new property at this time.
                            self.$router.push({ name:"oe:op:property", params: { propertyId: 0, skipSave: true}});
                        }
                        else {
                            self.$router.push({ name:"oe:op:properties" });
                        }

                        self.$toast.success(`${_.startCase(targetNoun)} deleted successfully.`);
                    })
                    .catch(err => {
                        console.error(`Delete ${targetNoun} failed`, err);
                        return err;
                    });
            },

            navigateToProperty(params) {
                this.$router.push({ name:"oe:op:property", params });
            },

            checkForDNDBProperties()
            {
                let self = this;

                // Check if Property is on DNDB list
                let isWarning = false;
                let now = new Date();
                self.properties.forEach(x => {
                    let address = `${x.property.address1} ${x.property.city} ${x.property.state} ${x.property.zip}`;
                    self.$api.DoNotDoBusinessWithApi
                    .getByPropertyName(address)                    
                    .then(x => {
                        if (x.filter(x => new Date(x.effectiveDate) <= now && (!x.expirationDate || new Date(x.expirationDate) >= now)).length > 0) {
                            isWarning = true;
                            this.doNotDoBusinessWithWarnings.push(DNDB_WARNING_MESSAGE);
                        }
                    });
                });

                if (isWarning == false) {
                    this.doNotDoBusinessWithWarnings = [];    
                }
            },
        }
    };

</script>