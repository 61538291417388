<template>
    <div class="row">
        <div v-for="(taxInfo, index) in taxInformation" :key="index" class="col-6">
            <div :class="{ 'row form-group':true, 'form-group-focused':focusedTaxInfoId === index }">
                <div class="col-6">
                    <label :for="`drp_tax_category_${taxInfo.taxCategory}_payee`">{{taxInfo.taxCategoryName}}</label>
                    <company-picker ref="taxInfoCompanyPickers"
                        :automation_id="`drp_tax_category_${taxInfo.taxCategory}_payee`"
                        companyRoleName="None"
                        :companyRoleId="0"
                        dialogTitle="Select a Company"
                        :disabled="readOnly"
                        :fieldName="taxInfo.taxCategoryName"
                        v-model="taxInfo.payeeCompany"
                        @focusIn="focusedTaxInfoId = index"
                        @focusOut="focusedTaxInfoId = -1" />
                </div>
                <div class="col-6">
                    <label :for="`txt_tax_category_${taxInfo.taxCategory}_amount`">Amount</label>
                    <rqInputNumber
                        :id="`txt_tax_category_${taxInfo.taxCategory}_amount`"
                        defaultValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        :minValue="0"
                        :disabled="readOnly"
                        v-model="taxInfo.currentTaxes"
                        @focus="focusedTaxInfoId = index"
                        @blur="focusedTaxInfoId = -1"
                        @change="onCurrentTaxesChanged(taxInfo, $event)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import CompanyPicker from '@/shared/components/rq/CompanyPicker';
    export default {

        components: { CompanyPicker },

        props: {
            taxInformation: { default: [] }
        },

        data() {
            const self = this;
            return {
                validationErrors: [],
                focusedTaxInfoId: -1
            };
        },

        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly
            })
        },

        methods: {
            onCurrentTaxesChanged(info, e) { this.$emit("change", info); },

            validate() {
                this.validationErrors = [];
                _.forEach(this.$refs.taxInfoCompanyPickers, cp => {
                    if(cp.isValid) return;
                    this.validationErrors.push(`Invalid ${cp.fieldName} Payee`);
                });
                return this.validationErrors;
            }
        }

    };

</script>
