export const CdfSection = {
    K:"K",
    L:"L",
    M:"M",
    N:"N"
};

export const CdfSectionTitle = {
    K: "K. Due from Borrower at Closing",
    L: "L. Paid Already by or on Behalf of Borrower at Closing",
    M: "M. Due to Seller at Closing",
    N: "N. Due from Seller at Closing"
}

export const HudSectionTitle = {
    BorrowerDebits: "100. Gross Amount Due From Borrower",
    BorrowerCredits: "200. Amounts Paid By Or in Behalf Of Borrower",
    SellerCredits: "400. Gross Amount Due to Seller",
    SellerDebits: "500. Reductions in Amount Due to Seller"
}