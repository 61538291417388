<template>
    <div :class="classAttr">
        <rq-section-card :title="titleOptions.sectionTitle" collapseButtonAlign="left">
            <div class="debit-credit-card-container">
                <!-- Loan Amount renders only for Without Seller mode -->
                <div v-if="isWithOutSellerView" class="row">
                    <div class="col-8 col-xl-9 dc-total-title">
                        <label>Loan Amount</label>
                    </div>
                    <div class="col-4 col-xl-3 dc-total-amount">
                        <label>{{loanAmountResult}}</label>
                    </div>
                </div>
                <!-- Cash/Check From Borrower renders only for HUD Without Seller mode -->
                <div v-if="isWithOutSellerView && !isCdf" class="row">
                    <div class="col-8 col-xl-9 dc-total-title">
                        <label>Plus Cash/Check From Borrower </label>
                    </div>
                    <div class="col-4 col-xl-3 dc-total-amount">
                        <label>{{totalPaidBeforeClosingResult}}</label>
                    </div>
                </div>   
                <div class="row">
                    <div class="col-8 col-xl-9 dc-total-title">
                        <label>{{titleOptions.topTitle}}</label>
                    </div>
                    <div class="col-4 col-xl-3 dc-total-amount">
                        <label>{{prefixTotalUpperSectionResult}}{{totalUpperSectionResult}}</label>
                    </div>
                </div>
                <!-- Closing Costs Paid Before Closing renders only for CDF Without Seller mode -->
                <div v-if="isWithOutSellerView && isCdf" class="row">
                    <div class="col-8 col-xl-9 dc-total-title">
                        <label>Closing Costs Paid Before Closing </label>
                    </div>
                    <div class="col-4 col-xl-3 dc-total-amount">
                        <label>{{totalPaidBeforeClosingResult}}</label>
                    </div>
                </div>                
                <div class="row">
                    <div class="col-8 col-xl-9 dc-total-title">
                        <label>{{titleOptions.bottomTitle}}</label>
                    </div>
                    <div class="col-4 col-xl-3 dc-total-amount">
                        <label>{{prefixTotalBottomSectionResult}}{{totalBottomSectionResult}}</label>
                    </div>
                </div>
            </div>
        </rq-section-card>
        <div class="calculations-total">
            <div class="row">
                <div class="col-8 dc-total-title">
                    <label>{{titleOptions.totalTitle}}</label>
                </div>
                <div class="col-4 dc-total-amount">
                    <label>{{netTotalResult}}</label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    props: {
        titleOptions: { type: Object },
        totalUpperSection: { type: Number },
        totalBottomSection: { type: Number },
        netTotal: { type: Number },
        isWithOutSellerView: { type: Boolean, default: false },
        isCdf: { type: Boolean, default: false },
        loanAmountValue: { type: Number, default: 0 },
        totalPaidBeforeClosingValue: { type: Number, default: 0 }
    },
    computed: {
        ...mapState({
            orderSummary: state => state.orders.orderSummary,
        }),        
        classAttr() {
            let theme = _.get(this, "$route.meta.theme", "default") || "default";
            return `debit-credit-calculations theme-${theme}`;
        },
        loanAmountResult() {
            return this.formatMoney(this.loanAmountValue);
        },
        totalPaidBeforeClosingResult() {
            return this.formatMoney(this.totalPaidBeforeClosingValue);
        },
        prefixTotalUpperSectionResult() {
            return this.isCdf && this.isWithOutSellerView ? "-" : "";
        },
        totalUpperSectionResult() {
            return this.formatMoney(this.totalUpperSection);
        },
        prefixTotalBottomSectionResult() {
            return this.isCdf ? "-" : "";
        },
        totalBottomSectionResult() {
            return this.formatMoney(this.totalBottomSection);
        },
        netTotalResult() {
            // "Without Seller" calculations require a different calculation versus "with seller"
            // If CDF and CashToClosePopulateFrom is set to 1 (get from cash to close table) then use netTotal as it reflects cash to close amount
            // Note: "Without Seller" visually means only borrower section / calculations would display

            let showCashToCloseFromTable = this.isCdf && this.orderSummary.cashToClosePopulateFrom === 1;
            return this.isWithOutSellerView || showCashToCloseFromTable ? this.formatMoney(this.netTotal) : this.formatMoney(Math.abs(_.parseNumber(this.totalUpperSection) - _.parseNumber(this.totalBottomSection)));
        }
    },
    methods: {
        formatMoney(v) {
            return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero: "%s0"} });
        },
    }
}
</script>
