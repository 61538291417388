export default {
    name: "LoanTermLengthMixin",
    data: function() {
        return {
            termsLengthYears: 0,
            termsLengthMonths: 0,
            termsLengthDays: 0,
            currentLoanTermLength: {
                loanTermPeriod: 0,
                term: 0,
                loanMonths: 0
            }
        }
    },
    watch: {
    },
    methods: {
        loanTermLengthMixin_clearTermLength() {
            this.termsLengthYears = 0;
            this.termsLengthMonths = 0;
            this.termsLengthDays = 0;
            this.currentLoanTermLength = {
                loanTermPeriod: 0,
                term: 0,
                loanMonths: 0
            };
        },
        loanTermLengthMixin_getTermPeriod(){
            // TODO: Look into refactoring for reactivity

            this.currentLoanTermLength.loanMonths = 0;

            if(this.termsLengthDays === 0 && this.termsLengthYears === 0 && this.termsLengthMonths === 0)
            {
                this.currentLoanTermLength.loanTermPeriod = 2;
                this.currentLoanTermLength.term = 0;
                this.currentLoanTermLength.loanMonths = 0;
                return this.currentLoanTermLength;
            }

            // days
            if(this.termsLengthDays > 0) {
                this.currentLoanTermLength.loanTermPeriod = 0;
                this.currentLoanTermLength.term = this.termsLengthDays;
                return this.currentLoanTermLength;
            }

            // years and months
            if(this.termsLengthYears > 0 && this.termsLengthMonths > 0)
            {
                this.currentLoanTermLength.loanTermPeriod = 3;
                this.currentLoanTermLength.term = this.termsLengthYears;
                this.currentLoanTermLength.loanMonths = this.termsLengthMonths;
                return this.currentLoanTermLength;
            }

            // years
            if(this.termsLengthYears > 0) {
                this.currentLoanTermLength.loanTermPeriod = 2;
                this.currentLoanTermLength.term = this.termsLengthYears;
            }

            // months
            if(this.termsLengthMonths > 0) {
                this.currentLoanTermLength.loanTermPeriod = 1;
                this.currentLoanTermLength.term = this.termsLengthMonths;
            }

            return this.currentLoanTermLength;
        },
        loanTermLengthMixin_setTimePeriod(loanData) {
            let lengthTermsData = this.loanTermLengthMixin_getTermsData(loanData);
            this.termsLengthYears = lengthTermsData.termsLengthYears;
            this.termsLengthMonths = lengthTermsData.termsLengthMonths;
            this.termsLengthDays = lengthTermsData.termsLengthDays;
        },
        loanTermLengthMixin_getTermsData(loanData) {
            let lengthTermsData = {
                termsLengthYears: 0,
                termsLengthMonths: 0,
                termsLengthDays: 0
            };
            if(loanData.loanTermPeriod === 2 || loanData.loanTermPeriod === 3){
                lengthTermsData.termsLengthYears = loanData.term ;
            }

            if(loanData.loanTermPeriod === 1){
                lengthTermsData.termsLengthMonths = loanData.term;
            }
            else if(loanData.loanTermPeriod === 3){
                lengthTermsData.termsLengthMonths = loanData.loanMonths;
            }

            if(loanData.loanTermPeriod === 0){
                lengthTermsData.termsLengthDays = loanData.term;
            }

            return lengthTermsData;
        }
    }
}