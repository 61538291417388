<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="row ms-3 me-3">
            <div class="col col-4 form-group">
                <label for="drp_escrow_acct">Escrow Account</label>
                <dx-select-box
                    automation_id="drp_escrow_acct"
                    value-expr="id"
                    display-expr="name"
                    :items="escrowBanks"
                    :disabled="readOnly"
                    v-model="selectedBankCompanyID"
                    @value-changed="onEscrowAccountChange"
                />
            </div>
            <div class="col col-3 form-group">
                <label for="chk_unverified_only">&nbsp;</label>
                <b-form-checkbox
                    automation_id="chk_unverified_only"
                    @change="onUnverifiedOnlyChange"
                    v-model="unVerifiedOnly"
                    :disabled="readOnly">Unverified Only</b-form-checkbox>
            </div>
        </div>
        <div class="rq-master-detail-container">
            <section>
                <rqdx-action-data-grid
                    ref="dataGrid"
                    title="Deposit Slips"
                    automation_id="tbl_deposit_slips"
                    :actions="selectionActions"
                    :config="gridConfig"
                    search-mode="field"
                    title-size="sm"
                    :data-source="gridDataSource"
                    @delete="onDeleteItem"
                    @print="onPrintItem"
                    @micr-slip="onPrintMicrDeposit"
                    @selectionChanged="onGridSelectionChanged"
                    export-file-name="deposit_slips_data"
                    integrated-search
                    :rq-editable="!readOnly"
                    rq-filters>
                    <template #toolbar>
                        <ul class="nav me-auto">
                            <li class="nav-item" v-rq-tooltip.top.hover :title="readOnly ? 'Access Restricted' : 'Add Deposit Slip'">
                                <b-btn automation_id="btn_add" variant="theme" @click="onAddItem" :disabled="readOnly">Add</b-btn>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
            <section v-if="showGridSelector">
                <deposit-selector id="depositSelector"
                    ref="depositSelector"
                    :deposit-slip="selectedItem"
                    @update-deposit-ids="onUpdateDepositIDs"
                    :disabled="readOnly"
                />
            </section>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DepositSlipDto }  from "../models";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import DepositSelector from "../components/DepositSelector";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";

    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import DocumentViewer from "@documents/views/DocumentViewer";

    export default {
        name:"DepositSlipMaintenance",
        mixins: [GridSystemLookupMixin],
        components: { DepositSelector },
        data () {
            return {
                addEventName: "",
                errorMessage: "",
                items: [],
                unassignedDeposits: [],
                selectedBankCompanyID: 0,
                depositIDs: [],
                selectedItem: {},
                selectionActions: [],
                unVerifiedOnly: true,
                showGridSelector: false,
                bankDataList: [],
                bankDocTemplates: []
            };
        },

        watch: {
            selectedItem(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit(`${this.isSelectedItemReadOnly ? "disable" : "enable"}-ok`);
            },
        },

        created() {
            this.selectedBankCompanyID = this.bankCompanyID;
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                allBranches: state => _.get(state, "system.lookups.branches", []),
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            bankCompanyID() { return _.get(this, "order.bankCompanyID", 0); },
            hasEscrowAccount() { return _.get(this, "bankCompanyID", 0) > 0; },
            isSelectedItemReadOnly() { return !_.isNil(_.get(this.selectedItem, "dateVerified", null)); },
            localSecurity(){ return this.securitySettings.findValues(["AllowDepositSlipMaintenance", "DepositSlip_ScreenAccess"]); },
            selectedKey() {
                if (_.isEmpty(this.selectedItem)) return 0;
                return _.get(this.selectedItem, this.itemKey, 0);
            },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() { return !this.localSecurity.AllowDepositSlipMaintenance || this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read ; },
            selectedBankData() { return _.find(this.bankDataList, b => b.companyID === this.selectedBankCompanyID); },
            isDepositSlipTemplateConfigured() { return !_.isNullOrEmpty(this.selectedBankData?.depositSlipTemplateFile); },
            actionDisabled(){ return !this.localSecurity.AllowDepositSlipMaintenance || this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read;},
            micrSlipDisabled(){
                if(this.actionDisabled) return true;
                return this.isDepositSlipTemplateConfigured
                    ? false
                    : "No deposit slip template configured for this escrow account.";
            }
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData(clear=false) {
                const self = this;
                if (!self.hasEscrowAccount) return;
                let apiPromises = [
                    self.$api.DepositSlipsApi.get(self.selectedBankCompanyID, self.unVerifiedOnly),
                    _.isNil(self.selectedBankData) ? self.$api.CompaniesApi.getEscrowAccount(self.selectedBankCompanyID) : Promise.resolve(null)
                ];
                self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(results => {
                        self.items = _.map(results[0], ds => new DepositSlipDto(ds));
                        if(_.isNil(results?.[1])) return;
                        self.bankDataList.push(results[1]);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Deposit Slips.` });
                    })
                    .finally(() => {
                        self.refresh(clear);
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    onEditorPreparing: self.onEditorPreparing,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    scrolling: { useNative: true },
                    columns: [
                        {
                            dataField: self.itemKey,
                            dataType: "number",
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                validationRules: [{ type: "required" }],
                            },
                            lookupKey: self.lookupItems.ESCROW_ACCOUNTS,
                            regionId: self.order.regionID
                        }),
                        DxGridUtils.dateColumn({
                            dataField: "depositDate",
                            sortIndex: 0,
                            sortOrder: "desc",
                        }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        DxGridUtils.dateColumn({
                            dataField: "dateVerified",
                        }),
                        {
                            dataField: "verifiedByName",
                            dataType: "string",
                        },
                        {
                            dataField: "reconID",
                            dataType: "number",
                            caption: "Recon ID",
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "Total",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "Total: {0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                    onInitNewRow(e) {
                        e.data.bankCompanyID = self.selectedBankCompanyID;
                        e.data.isNew = true;
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    update: self.onGridUpdate
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Deposit Slip";
                self.itemTypeNamePlural = "Deposit Slips";
                self.itemKey = "depositSlipID";
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                self.readOnlyColumns = ["dateVerified", "bankCompanyID", "amount", "branchID", "reconID", "verifiedByName", "verifiedByUsersID"];

                self.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled(){ return self.actionDisabled; }
                    },
                    {
                        name: "print",
                        text: "Print",
                        eventName: "print",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Print ${self.itemTypeName}`,
                        disabled(){ return self.actionDisabled; }
                    },
                    {
                        name: "micr-slip",
                        text: "MICR Slip",
                        eventName: "micr-slip",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: "Print MICR Deposit Slip",
                        disabled() {
                            return self.micrSlipDisabled;
                        }
                    }
                ];
            },

            onAddItem(e) {
                const self = this;
                let values = {depositDate: DateTimeHelper.nowTenant('MM/dd/yyyy'), bankCompanyID: self.selectedBankCompanyID};
                let newItem = new DepositSlipDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                self.save(newItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} was created.` });
                        self.items.push(new DepositSlipDto(result));
                        let depositSlipID = _.get(result, self.itemKey);
                        self.onSelectItem({depositSlipID});
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.itemTypeName}.` });
                    });
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if(self.actionDisabled) return;
                let items = e.data;
                let ok = function (args) {
                    let ids = _.map(items, self.itemKey);
                    let apiPromise = self.$api.DepositSlipsApi.delete(self.selectedBankCompanyID, self.unVerifiedOnly, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.items = _.map(result, c => new DepositSlipDto(c));
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh(true);
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), 0) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new DepositSlipDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                        self.items[itemIndex] = updatedItem;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh(false);
                    });
            },

            onGridSelectionChanged(e) {
                this.showGridSelector = false;
                this.selectedItem = (_.get(e, "selectedRowsData.length", 0) == 1) ? e.selectedRowsData[0] : {};
                this.showGridSelector = !_.isEmpty(this.selectedItem);
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(_.indexOf(this.readOnlyColumns, e.dataField) >= 0) {e.editorOptions.readOnly = true; return;}
            },

            onEscrowAccountChange(e) {
                if(_.isNil(e?.event)) return;
                this.fetchData(true);
            },

            onPrintItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if(self.actionDisabled) return;
                let depositSlipIDs = _.map(e.data, x => x.depositSlipID);

                let reportOptions = new ReportOptionsDto({
                    title: 'Deposit Slip',
                    reportPath: 'System Reports/File Specific/Deposit Slip',
                    parameters: {
                        p_DepositIDs: depositSlipIDs,
                    },
                    immediate: true,
                });

                _.invoke(this, "$rq.showReport", reportOptions.reportPath, reportOptions);
            },

            onPrintMicrDeposit(e) {
                if(!e || !e.data) return;
                const self = this;
                if(self.micrSlipDisabled) return;
                let depositSlipIDs = _.map(e.data, d => d.depositSlipID);
                self.printDepositSlips(depositSlipIDs);
            },

            onSelectItem(data) {
                const self = this;
                self.refresh(true);
                self.$nextTick().then(() => {
                    self.gridInstance.selectRows([data.depositSlipID], false);
                    self.gridInstance.navigateToRow(data.depositSlipID);
                });
            },

            onUpdateDepositIDs(e) {
                this.depositIDs = e || [];
                this.saveDetail();
            },

            onUnverifiedOnlyChange(e) {
                this.unVerifiedOnly = e;
                this.fetchData(true);
            },

            printDepositSlips(depositSlipIDs) {
                const self = this;
                let apiPromise = self.getActiveDepositSlipDocTemplateId()
                    .then(docTemplateId => {
                        if(docTemplateId <= 0) {
                            self.$dialog.messageBox("Deposit Slip Error", "<p>The deposit slip document template associated with the current escrow account could not be found or is not assigned.</p><p>Please contact your administrator.</p>");
                            return Promise.resolve(false);
                        }
                        return self.$store.dispatch(DOCUMENT_ACTIONS.ADD_DEPOSIT_SLIPS_TO_QUEUE, {
                            orderId: self.orderId,
                            depositSlipIDs,
                            docTemplateId
                        }).then(() => true);
                    });
                return self.$rqBusy.wait(apiPromise)
                    .then(docReady => {
                        if(!docReady) return;
                        self.launchDocumentViewer();
                    });
            },

            getActiveDepositSlipDocTemplateId() {
                const self = this;
                let docTemplateInfo = _.find(self.bankDocTemplates, item => item.companyId === self.selectedBankCompanyID);
                let docTemplateId = _.getNumber(docTemplateInfo, "data.documentTemplateID", 0);
                if(docTemplateId > 0) return Promise.resolve(docTemplateId);
                return self.$api.CompaniesApi.getDepositSlipTemplateByCompanyId(self.selectedBankCompanyID)
                    .then(docTemplate => {
                        docTemplateId = _.getNumber(docTemplate, "documentTemplateID", 0);
                        if(docTemplateId <= 0) return 0;
                        self.bankDocTemplates.push({
                            companyId: self.selectedBankCompanyID,
                            data: docTemplate
                        })
                        return docTemplateId;
                    })
            },

            launchDocumentViewer() {
                const self = this;
                self.$dialog.open({
                    title: "Preview Deposit Slips",
                    height: "95%",
                    width: "95%",
                    resizable: false,
                    component: DocumentViewer,
                    closeOnly: true,
                    closeTitle: "Close"
                });
            },

            refresh(clear=false) {
                if(!this.gridInstance) return;
                if (clear) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                }
                this.gridInstance.refresh();
            },

            save(item, changes){
                const self = this;
                if(self.readOnly) return Promise.resolve(false);
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.DepositSlipsApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise);
            },

            saveDetail(){
                const self = this;

                let apiPromise = self.$api.DepositSlipsApi.saveDetail(self.selectedKey, self.depositIDs);
                return self.$rqBusy.wait(apiPromise).then(() => {
                        self.fetchData();
                    });
            },
        }
    }
</script>
