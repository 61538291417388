import InternetAlarms from "@/modules/home/views/InternetAlarms";

export default {
    name: "file-alerts",
    path: "file-alerts",
    component: InternetAlarms,
    meta: {
        entity: "File Alerts",
        label: "File Alerts",
        logRouteAccess: true,
        skipSaveOnLeave: true,
        include: true
    },
    props:{ currentFileOnly: true }
};