<template>
    <tfoot class="endorsement-totals">
        <EndorsementTotalRow
            :totals="totals[0]"
            :no-tax="noTax"
            v-model:expanded="totalsExpanded"
            show-collapse-toggle
        />
        <EndorsementTotalRow
            v-for="(item, index) in collapsibleTotals"
            :key="index"
            ref="totalRows"
            :expanded="totalsExpanded"
            :totals="item"
            :no-tax="noTax"
        />
    </tfoot>
</template>
<script>
    import EndorsementTotalRow from "./EndorsementTotalRow";
    export default {
        props: {
            totals: { type: Array, default: () => [] },
            noTax: { type: Boolean, default: false }
        },
        components: { EndorsementTotalRow },
        data() {
            return { totalsExpanded: false };
        },
        computed: {
            collapsibleTotals() { return _.drop(this.totals, 1); }
        },
        methods: {
            formatMoney(v) {
                return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } });
            }
        }
    }
</script>