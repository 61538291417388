<template>
    <div id="order-contact-manager" class="content-wrapper">
        <rq-banner
            v-for="(warning, index) in doNotDoBusinessWithWarnings"
            :key="index"
            :message="warning.message"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="doNotDoBusinessWithWarnings.length > 0"
            dismissable
            sticky
        />
        <rq-banner
            :message="dndbCompanyWarningMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="!!dndbCompanyWarningMessage"
            dismissable
            sticky
        />
        <rq-page-section title="File Contacts" headerSize="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button v-focus
                                class="btn btn-theme dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                automation_id="btn_add_contact"
                                :disabled="readOnly">Add
                            </button>
                            <ul class="dropdown-menu">
                                <li><button type="button" class="dropdown-item" automation_id="btn_add_buyer" @click="onAddBuyer">Buyer</button></li>
                                <li><button type="button" class="dropdown-item" automation_id="btn_add_seller" @click="onAddSeller">Seller</button></li>
                                <li><button type="button" class="dropdown-item" automation_id="btn_add_other" @click="onAddContact">Other Role</button></li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_card_view"
                            variant="theme"
                            :class="['btn-link', isCardView ? 'active' : '']"
                            @click="onChangeContactView('card')">
                            Card View
                        </b-button>
                        <span>|</span>
                        <b-button
                            automation_id="btn_grid_view"
                            variant="theme"
                            :class="['btn-link', !isCardView ? 'active' : '']"
                            @click="onChangeContactView('grid')">
                            Grid View
                        </b-button>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rq-page-section v-show="isCardView" no-header borderless>
            <div class="row">
                <div class="col col-2 form-group ms-auto">
                    <rq-search-input-group
                        automation_id="txt_search_file_contacts"
                        id="txt_search_file_contacts"
                        placeholder="Search File Contacts..."
                        tabindex="0"
                        size="sm"
                        input-css-class="rq-placeholder-visible"
                        v-model="searchText"
                    />
                </div>
            </div>
            <div class="row rq-card-list">
                <div v-for="contact in contactCardItems" :key="contact.rolesID"
                    class="col-12 col-lg-6 col-xl-4 col-pc-3 rq-card-col">
                    <contact-card
                        :contact="contact"
                        @detail-click="onCardDetailClick"
                        @assign="onAssignContact"
                        @delete="onDeleteContactClick"
                        show-assign
                        show-delete
                        :showWarning="hasCompanyWarning(contact.name)"
                    />
                </div>
            </div>
        </rq-page-section>
        <order-contact-grid
            ref="orderContactsGrid"
            :grid-data="contactItems"
            :shown="!isCardView"
            :read-only="readOnly"
            @delete-contact="onDeleteContactClick"
            @assign="onAssignContact"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { FILE_INFORMATION_ACTIONS } from '@/store/modules/ui/actions';
    import { CONTACT_ACTIONS, ORDER_ACTIONS } from '@/store/actions';
    import { RoleType } from "@/shared/models/enums";
    import { ContactCardDto } from "./models";
    import { RoleList, CompanyContactLookup, ContactCard, OrderContactGrid } from "./components";

    const FILTER_ROLE_TYPE_IDS = [15,28,2,24,19,16,10,6];
    const CONTACT_VIEW = {
        CARD_VIEW: 'card',
        GRID_VIEW: 'grid'
    };
    const ENTITY_WARNING_MESSAGE = "Entity is on the do not do business with list";
    const COMPANY_WARNING_MESSAGE = "Company is on the do not do business with list";
    const MULTI_COMPANY_WARNING_MESSAGE = "Multiple companies are on the do not do business with list";

    export default {
        name: "OrderContacts",
        components: { ContactCard, OrderContactGrid },
        data () {
            return {
                filtersExpanded: false,
                doNotDoBusinessWithWarnings: [],
                dndbCompanyWarningMessage: "",
                dndbCompanyNames: [],
                roleFilter: [],
                tooltipOptions: {
                    container: "#order-contact-manager",
                    boundary: "window"
                },
                currentView: CONTACT_VIEW.CARD_VIEW,
                searchText: ""
            };
        },

        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                orderId: state => state.orders.orderId,
                currentFileNo: state => state.orders.orderSummary.fileNumber,
                contacts: state => state.orders.contacts.contacts
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "defaultContactView"
            ]),
            contactItems() {
                let items = _.sortBy(_.map(this.contacts, c => new ContactCardDto(c)), "orderContactSequence");
                this.updateRoleNumbers(items, "Buyer");
                this.updateRoleNumbers(items, "Seller");
                return items;
            },
            contactCardItems() { return _.isNil(this.searchText) || this.searchText.length < 3 ? this.contactItems : _.search(this.contactItems, this.searchText); },
            isCardView() { return this.currentView === CONTACT_VIEW.CARD_VIEW; },
            viewTitle() { return this.isCardView ? "Grid View" : "Card View" }
        },

        created () {
            this.fetchData();
            this.currentView = this.defaultContactView;
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },
        watch: {
            contactItems() {
                const self = this;
                self.doNotDoBusinessWithWarnings = [];
                let now = new Date();
                const nameList = [];
                self.contacts.forEach(x => {
                    nameList[nameList.length] = x.name;
                });
                self.$api.DoNotDoBusinessWithApi
                    .getByEntityList(nameList)
                    .then(results => {
                        var filterResults = results.filter(result => new Date(result?.effectiveDate) <= now && (!result?.expirationDate || new Date(result?.expirationDate) >= now));
                        if (filterResults && filterResults.length > 0) {
                            filterResults.forEach(result => {
                                if (!this.doNotDoBusinessWithWarnings.some(warning => warning.type === 'entity')) {
                                    this.doNotDoBusinessWithWarnings.push({
                                        message: ENTITY_WARNING_MESSAGE,
                                        name: result.name,
                                        type: 'entity',
                                    });
                                    removeDuplicateWarnings();
                                }
                            });
                        }
                });
                self.$api.DoNotDoBusinessWithApi
                    .getByCompanyList(nameList)
                    .then(results => {
                        var filteredResults = results.filter(result => new Date(result?.effectiveDate) <= now && (!result?.expirationDate || new Date(result?.expirationDate) >= now)) ?? [];
                        this.dndbCompanyNames = filteredResults.map(result => result.company).filter(name => !!name);
                        this.dndbCompanyWarningMessage = this.getCompanyWarningMessage(filteredResults.length);
                    });

                self.$api.DoNotDoBusinessWithApi.getByIndividualName(self.orderId).then((results) => {
                    results.forEach(result => {
                        this.doNotDoBusinessWithWarnings.push({
                            message: result,
                            name: result.substring(0, result.indexOf(' is on the do not do business with list')),
                            type: 'individual',
                        });
                        removeDuplicateWarnings();
                    })
                });

                const removeDuplicateWarnings = () => {
                    this.doNotDoBusinessWithWarnings = Object.values(this.doNotDoBusinessWithWarnings.reduce((acc, obj) => {
                        acc[obj.name] = acc[obj.name] || obj;
                        if (obj.type === 'individual') {
                            acc[obj.name] = obj;
                        }
                        return acc;
                    }, {}));
                }
            }
        },

        methods: {
            fetchData () {
                const self = this;
                let storePromise = self.$store.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS);
                return self.$rqBusy.wait(storePromise);
            },
            updateRoleNumbers(items, role) {
                let roleItems = _.filter(items, { role });
                if(roleItems.length < 2) return;
                _.updateAll(roleItems, "roleNumber", (item, index) => index + 1);
            },
            onChangeContactView(view) {
                if(view === this.currentView) return;
                this.$store.dispatch(FILE_INFORMATION_ACTIONS.SET_DEFAULT_FILE_CONTACT_VIEW, view);
                this.currentView = this.isCardView ? CONTACT_VIEW.GRID_VIEW : CONTACT_VIEW.CARD_VIEW;
            },
            onDeleteContactClick (e) {
                const self = this;
                if (!e || !e.data || self.readOnly) return;

                if(_.isArray(e.data)) {
                    if(e.data.length === 1) {
                        self.deleteSingleContact(e.data[0]);
                    }
                    else if(e.data.length > 1) {
                        self.deleteMultipleContacts(e.data);
                    }
                }
                else {
                    self.deleteSingleContact(e.data);
                }
            },

            onAddBuyer () {
                this.addBuyerSeller(RoleType.Buyer);
            },
            onAddSeller () {
                this.addBuyerSeller(RoleType.Seller);
            },

            onAddContact () {
                const self = this;
                if (self.readOnly) return;
                let roles = self.lookupHelpers.getRoles([], 'canAdd', true);
                self.$dialog.open({
                    title: "Contact Role",
                    height: 250,
                    width: 500,
                    scrollable: false,
                    component: RoleList,
                    props: { roles },
                    closeOnEsc: true,
                    onOk (e) {
                        if(!e.component.isValid()) return false;
                        let roleTypeId = _.parseNumber(e.component.selectedValue, null);
                        let roleTypeName = e.component.selectedItem.name;
                        let noCompanyContact = e.component.noCompanyContact;

                        if(noCompanyContact) {
                            self.addRole({
                                roleTypeId,
                                orderId: self.orderId
                            });
                        }
                        else if(self.isBuyerSeller(roleTypeId))
                            self.addBuyerSeller(roleTypeId);
                        else
                        {
                            self.showAddCompanyLookup(roleTypeId, roleTypeName);
                        }
                        return true;
                    }
                });
            },

            onCardDetailClick(e) {
                if(!e || !e.type || !e.data) return;
                switch(e.type) {
                    case "buyer-seller":
                        this.navigateToBuyerSeller(e.data);
                        break;
                    case "company":
                        this.navigateToCompany(e.data);
                        break;
                    case "contact":
                        this.navigateToContact(e.data);
                        break;
                }
            },

            onAssignContact(e) {
                const self = this;
                let contact = e.data;
                let roleId = contact.rolesID;
                let handleCancel = function(e) {
                    _.invoke(self, "$refs.orderContactsGrid.clearGrid");
                };
                let handleOk = function(e) {
                    let result = _.get(e, "originalEvent.data", e.data);
                    let originalRole = {
                        roleId,
                        companyId: contact.companyID,
                        contactId: contact.contactID
                    };
                    _.invoke(self, "$refs.orderContactsGrid.clearGrid");
                    return new Promise((resolve,reject) => {
                        let newRole = {
                            roleId,
                            companyId: result.companyID || null,
                            contactId: result.contactID || null
                        };
                        self.updateRole(originalRole, newRole)
                            .then(resolve)
                            .catch(reject);
                    });
                }
                let props = {
                    roleTypeId: contact.roleTypeID,
                    roleTypeName: contact.role,
                    selectedCompanyId: contact.companyID,
                    selectedContactId: contact.contactID,
                    targetCompany: {
                        companyId: contact.companyID,
                        name: contact.companyName,
                        regionId: contact.regionID
                    }
                }
                self.showCompanyLookup(props, handleOk, handleCancel);
            },

            showAddCompanyLookup(roleTypeId, roleTypeName) {
                const self = this;
                let role = { orderId: self.orderId, roleTypeId, roleTypeName };
                let handleOk = function(e) {
                    return new Promise((resolve,reject) => {
                        let result = _.get(e, "originalEvent.data", e.data);
                        role.companyId = result.companyID;
                        role.contactId = result.contactID;
                        //role.roleTypeId = result.roleTypeID;
                        self.addRole(role)
                            .then(resolve)
                            .catch(reject);
                    });
                };

                let handleCancel = function(e) {
                    return;
                };
                self.showCompanyLookup({ roleTypeId, roleTypeName }, handleOk, handleCancel);
            },

            showCompanyLookup(args, handleOk, handleCancel=()=>true) {
                const self = this;
                self.$dialog.open({
                    title: `Add Contact: ${args.roleTypeName}`,
                    height:"90%",
                    width:"85%",
                    component: CompanyContactLookup,
                    props: {
                        roleTypeId: args.roleTypeId,
                        selectedCompanyId: args.selectedCompanyId,
                        selectedContactId: args.selectedContactId,
                        targetCompany: args.targetCompany
                    },
                    onOk: handleOk,
                    onCancel: handleCancel,
                    onComponentAction(e) {
                        if(e.originalEvent.name !== "selection") return;
                        return handleOk(e);
                    }
                });
            },

            addRole(role) {
                const self = this;
                let storePromise = self.$store.dispatch(CONTACT_ACTIONS.ADD_ORDER_CONTACT, { role });
                return self.$rqBusy.wait(storePromise, { topLevel: true })
                    .then(newRole => {
                        self.$toast.success("Contact Created");
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error("An issue occurred while creating the selected contact.");
                        console.error(error);
                        return error;
                    });
            },

            updateRole(originalRole, updatedRole) {
                const self = this;
                let changes = self.getAuditChanges(originalRole, updatedRole);
                _.forEach(changes, c => {
                    if(c.name === "companyId") c.name = "companyID";
                    if(c.name === "contactId") c.name = "contactID";
                });
                let storePromise = self.$store.dispatch(CONTACT_ACTIONS.UPDATE_ORDER_CONTACT, { data: updatedRole, changes });
                return self.$rqBusy.wait(storePromise)
                    .then(() => {
                        self.$toast.success("Contact Updated");
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error("An issue occurred while updating the selected contact.");
                        console.error(error);
                        return error;
                    });
            },

            deleteMultipleContacts(contacts) {
                let self = this;
                let message = '';
                let okHandler = function () {
                    let roleIds = _.map(_.filter(contacts, x => !x.isBuyerSeller), x => x.rolesID);
                    let buyerSellerIds = _.map(_.filter(contacts, x => x.isBuyerSeller), x => {
                        return { buyerSellerID: x.buyerSellerID, buyerSellerType: x.isSeller ? 1 : 0 }
                    });

                    let storePromise = self.$store.dispatch(CONTACT_ACTIONS.DELETE_ORDER_CONTACTS, {
                        roleIds: roleIds,
                        buyerSellerIds: buyerSellerIds
                    });

                    return self.$rqBusy.wait(storePromise)
                        .then(r => {
                            if(buyerSellerIds.some(s => s.buyerSellerType == 1)){
                                self.$store.dispatch(ORDER_ACTIONS.GET_LOANS);
                            }
                            self.$toast.success("Successfully Deleted File Contacts");
                            return true;
                        })
                        .catch(errorInfo => {
                            self.$toast.error(errorInfo.errorMessage);
                            console.error(errorInfo);
                            return errorInfo;
                        });
                };
                let confirmMessage = "Are you sure you want to delete the selected Contact(s)?";
                self.$dialog.confirm("Confirm", confirmMessage, okHandler);
            },

            deleteSingleContact(contactData) {
                let self = this;
                let okHandler = function () {
                    let storePromise = null;
                    let buyerSellerId = _.parseNumber(contactData.buyerSellerID, 0);
                    let roleId = _.parseNumber(contactData.rolesID, 0);

                    if(contactData.isBuyerSeller && buyerSellerId === 0) {
                        self.$toast.error("Invalid buyer or seller contact data.");
                        console.error("Role type is buyer or seller but buyerSellerID not provided.");
                        return;
                    }

                    if(contactData.isBuyer)
                        storePromise = self.$store.dispatch(CONTACT_ACTIONS.DELETE_BUYER, { buyerSellerId, roleId });
                    else if(contactData.isSeller)
                        storePromise = self.$store.dispatch(CONTACT_ACTIONS.DELETE_SELLER, { buyerSellerId, roleId });
                    else
                        storePromise = self.$store.dispatch(CONTACT_ACTIONS.DELETE_ORDER_CONTACT, roleId);

                    if(!storePromise) return true;

                    return self.$rqBusy.wait(storePromise)
                        .then(r => {
                            if(contactData.isSeller){
                                self.$store.dispatch(ORDER_ACTIONS.GET_LOANS);
                            }
                            self.$toast.success(`${_.startCase(contactData.role)} successfully deleted.`);
                            return true;
                        })
                        .catch(errorInfo => {
                            self.$toast.error(`Error deleting ${_.startCase(contactData.role)}`);
                            console.error(errorInfo);
                            return errorInfo;
                        });
                };
                let confirmMessage = `Are you sure you want to remove the ${contactData.role}?`;
                self.$dialog.confirm("Confirm", confirmMessage, okHandler);
            },

            addBuyerSeller(roleTypeId) {
                const self = this;
                let isSeller = roleTypeId === RoleType.Seller;
                let name = isSeller ? "oe:oc:seller" : "oe:oc:buyer";
                self.$router.push({ name, params: { buyerSellerId: 0 } });
            },

            isBuyerSeller(roleTypeIdVal) {
                let roleTypeId = _.parseNumber(roleTypeIdVal);
                return (roleTypeId === RoleType.Buyer || roleTypeId === RoleType.Seller)
            },

            isLastBuyerSeller(contact) {
                const self = this;
                let buyerCount = self.getContactCount(RoleType.Buyer);
                let sellerCount = self.getContactCount(RoleType.Seller);
                return (contact.isBuyer && buyerCount === 1)
                    || (contact.isSeller && sellerCount === 1);
            },

            // getNextBuyerSellerSequence(roleTypeId){
            //     let buyerSellerContacts = _.filter(this.contactItems, c => c.roleTypeID === roleTypeId) || [];
            //     return buyerSellerContacts.length + 1;
            // },

            getContactCount(typeId) {
                return _.reduce(this.contacts, (num, c) => {
                    return c.roleTypeID === typeId
                        ? num + 1
                        : num;
                }, 0);
            },

            navigate(contact, { isCompany=false, isContact=false, isBuyerSeller=false }) {
                if(isBuyerSeller) this.navigateToBuyerSeller(contact);
                if(isCompany) this.navigateToCompany(contact);
                if(isContact) this.navigateToContact(contact);
            },

            navigateToBuyerSeller(contact){
                if(contact.roleTypeID === RoleType.Buyer) {
                    this.$router.push({ name:"oe:oc:buyer", params: { orderId: this.orderId, buyerSellerId: contact.buyerSellerID } });
                }
                else if(contact.roleTypeID === RoleType.Seller) {
                    this.$router.push({ name:"oe:oc:seller", params: { orderId: this.orderId, buyerSellerId: contact.buyerSellerID } });
                }
            },

            navigateToCompany(contact) {
                if(!contact || _.parseNumber(contact.companyID, 0) === 0) return;
                this.$router.push({ name:"oe:oc:company", params: { orderId: this.orderId, companyId: contact.companyID, rolesId: contact.rolesID } });
            },

            navigateToContact(contact) {
                if(!contact || _.parseNumber(contact.companyID, 0) === 0 || _.parseNumber(contact.contactID, 0) === 0) return;
                this.$router.push({ name:"oe:oc:contact", params: { orderId: this.orderId, companyId: contact.companyID, contactId: contact.contactID, rolesId: contact.rolesID } });
            },

            onSearchClear() {
                this.searchText = "";
                this.cardSearch(this.searchText);
            },

            onUserSearch: _.debounce(function (e) {
            this.userGridInstance.searchByText(this.searchText);
            }, 300, {trailing: true, leading: false}),

            hasCompanyWarning(company) {
                return this.dndbCompanyNames.some(name => name === company);
            },

            getCompanyWarningMessage(length) {
                switch (length) {
                    case 0: return "";
                    case 1: return COMPANY_WARNING_MESSAGE;
                    default: return MULTI_COMPANY_WARNING_MESSAGE;
                }
            },
        }
    };
</script>