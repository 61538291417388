<template>
    <router-view
        @loan-selected="onLoanSelected"
        @add-loan="onAddLoan"
        @delete-loan="onDeleteLoan">
    </router-view>
</template>


<script>
    import { mapState } from "vuex";
    import { ORDER_ACTIONS, LOAN_ACTIONS } from "@/store/actions";
    import { ORDER_MUTATIONS } from "@/store/mutations";
    import { LoanModel, OrderMainDto, LoanTermPeriod} from "../models";

    export default {
        name: "OrderLoansMain",
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                isOrderLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                loans: state => _.map(state.orders.loans, l => new LoanModel(l)),
                order: state => new OrderMainDto(state.orders.order),
                orderSummary: state => state.orders.orderSummary
            }),
        },

        methods: {

            onLoanSelected(e){
                if(!e || !e.data) return;
                this.navigateToLoan({ orderId: e.data.ordersID, loanId: e.data.loanID });
            },

            onAddLoan () {
                const self = this;
                if (self.isOrderLocked) return;
                this.navigateToLoan({ orderId: self.orderId });
            },

            onDeleteLoan (deleteIds) {
                const self = this;

                if (self.isOrderLocked || self.loans.length === 0) return;
                //deleting the first loan will recalc the DownPayment, so update the order with the new value
                let okDeleteHandler = function() {
                let deletePromise = self.$store.dispatch(LOAN_ACTIONS.DELETE_LOAN, deleteIds);
                self.$rqBusy.wait(deletePromise)
                    .then(loans => {
                        let refreshOrder = _.parseNumber(loans[0].loanOrder, 0) === 1;
                        if (refreshOrder) {
                            let newOrder = self.order;
                            newOrder.salesPrice = loans[0].salesPrice;
                            newOrder.downPayment = loans[0].downPayment;
                            self.$store.commit(ORDER_MUTATIONS.SET_ORDER, newOrder);
                        }
                        let msg = "Loan Deleted.";
                        if(deleteIds.length > 1)
                            msg ="Loans Deleted.";
                        self.$toast.success({ message: msg });
                        if (self.loans.length == 1){                            
                            self.navigateToLoan({ loanId: self.loans[0].loanID} );
                        }else{
                            self.$router.push({ name:"oe:ol:loans", params: { orderId: self.orderId } });
                        }
                        return true;
                    })
                    .then(() => {
                        let isValidDefaultLoan = _.find(self.loans, l => l.loanID === self.orderSummary.defaultLoanID);
                        if (_.isEmpty(isValidDefaultLoan)) {
                            let updatedOrderSummary = _.clone(self.orderSummary);
                            updatedOrderSummary.defaultLoanID = null;
                            self.$store.commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, updatedOrderSummary);
                        }
                    })
                    .catch(error => {
                        self.$toast.error(`${error.errorMessage}`);
                        console.error(error);
                        return false;
                    });

                }

                self.$dialog.confirm( "Delete Loan(s)",
                    self.createConfirmMessage(),
                    okDeleteHandler,
                    null,
                    { height: 325, width: 500 }
                );
            },

            createConfirmMessage() {
                return `<p>Deleting a loan will delete any associated commitments, title production, and policy areas as well as settlement statements if there are no associated checks that have been cut.</p>
                        <p>This will also delete any pending CDF imports associated with this loan that have not been processed.  Deleted data cannot be retrieved.</p>`;
            },

            getStoredLoan(id) {
                return _.find(this.loans, l => l.loanID === id);
            },

            navigateToLoan(params) {
                this.$router.push({ name:"oe:ol:loan", params });
            }
        }
    };

</script>