import { LookupItem } from '../../../../shared/models/models';

export class PropertyTaxInformation {
    constructor (options) {
        options = options || {};
        this.taxInformationID = _.parseNumber(options.taxInformationID, 0);
        this.taxCategoryName = options.taxCategoryName || null;
        this.currentTaxes = options.currentTaxes || null;
        this.line = options.line || null;
        this.hud2010Line = options.hud2010Line || null;
        this.propertyID = _.parseNumber(options.propertyID, null);
        this.payeeCompanyID = options.payeeCompanyID || null;
        this.payeeCompanyName = options.payeeCompanyName || null;
        this.payeeContactID = options.payeeContactID || null;
        this.payeeContactName = options.payeeContactName || null;
        this.taxCategory = _.parseNumber(options.taxCategory, null);
    }

    get payeeCompany() {
        return {
            companyID: this.payeeCompanyID,
            companyName: this.payeeCompanyName,
            contactID: this.payeeContactID,
            contactName: this.payeeContactName
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
        this.payeeContactID = _.get(val, "contactID", null);
        this.payeeContactName = _.get(val, "contactName", null);
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class PropertyTaxIdentification {
    constructor (options) {
        this.propertyTaxIdentificationID = _.parseNumber(options.propertyTaxIdentificationID, 0);
        this.propertyID = options.propertyID || null;
        this.taxIdentification = options.taxIdentification || null;
        // this.isDirty = options.isDirty || false; //never used
    }

    get isNew() { return _.lte(this.propertyTaxIdentificationID, 0); }

    toDataObject() { return _.toPlainObject(this); }
}

export class TaxInquiry {
    constructor (options) {
        options = options || {};
        this.taxInquiryID = options.taxInquiryID;
        this.propID = options.propID;
		this.inquiryDate = options.inquiryDate;
		this.recDate1 = options.recDate1;
		this.recFees1 = options.recFees1;
		this.recVolPage1 = options.recVolPage1;
		this.recDate2 = options.recDate2;
		this.recFees2 = options.recFees2;
		this.recVolPage2 = options.recVolPage2;
		this.salesPrice = options.salesPrice;
		this.lotSize = options.lotSize;
		this.pointOfSale = options.pointOfSale;
		this.originalGF = options.originalGF;
		this.listedInName = options.listedInName;
		this.whichHalf = options.whichHalf;         // cant find in ui
		this.ofYear = options.ofYear;               // cant find in ui
		this.landValue = options.landValue;
		this.improvedValue = options.improvedValue;
        this.homeStead = options.homeStead;
        this.totalValue = options.totalValue;
        this.currentTax = options.currentTax;
        this.hbCredit = options.hbCredit;
        this.homeSteadExemption = options.homeSteadExemption;
        this.rollBack1 = options.rollBack1;
        this.rollBack2 = options.rollBack2;
        this.assess1 = options.assess1;
        this.assess2 = options.assess2;
        this.assess3 = options.assess3;
        this.other = options.other;
        this.otherAmount = options.otherAmount;
        this.delinquentTax = options.delinquentTax;
        this.initials = options.initials;
        this.total = options.total;
        this.landNotes = options.landNotes;
        this.fund1 = options.fund1;
        this.fund2 = options.fund2;
        this.fund3 = options.fund3;
        this.fund4 = options.fund4;
    }
}

export class AutoLegalDescriptionPart {
    constructor (tag, val) {
        this.tag = tag || '[NONE]';
        this.value = val || '';
    }
    static get fieldTags () {
        return {
            acreage: 'ACE',
            address: 'ADD',
            addressl: 'AD1',
            address2: 'AD2',
            arbNumber: 'ARB',
            block: 'BL',
            boatSlip: 'BTS',
            book: 'BK',
            building: 'BLD',
            carport: 'CAR',
            city: 'C',
            countyName: 'CTY',
            district: 'DIS',
            incorporated: 'INC',
            instrumentNumber: 'INO',
            garage: 'GAR',
            lot: 'LOT',
            maps: 'MAP',
            municipality: 'HUN',
            surveylD: 'NUM',
            outLot: 'OUT',
            page: 'PAG',
            parcel: 'PCL',
            part: 'PRT',
            phase: 'PH',
            platName: 'PTN',
            propertyLayout: 'LAY',
            qtr1: 'QT1',
            qtr2: 'QT2',
            qtr3: 'QT3',
            range: 'LING',
            recordOfSurvey: 'REC',
            section: 'SEC',
            state: 'ST',
            storage: 'STG',
            streetNumber: 'STN',
            subdivision: 'SDC',
            survey: 'SUR',
            township: 'TOW',
            tract: 'TRT',
            unit: 'UT',
            zip: 'ZIP'
        };
    }
}

export class AutoLegalDescriptionModel {
    constructor () {
        this.acreage = 'ACE';
        this.address = 'ADD';
        this.addressl = 'AD1';
        this.address2 = 'AD2';
        this.arbNumber = 'ARB';
        this.block = 'BL';
        this.boatSlip = 'BTS';
        this.book = 'BK';
        this.building = 'BLD';
        this.carport = 'CAR';
        this.city = 'C';
        this.countyName = 'CTY';
        this.district = 'DIS';
        this.incorporated = 'INC';
        this.instrumentNumber = 'INO';
        this.garage = 'GAR';
        this.lot = 'LOT';
        this.maps = 'MAP';
        this.municipality = 'HUN';
        this.surveylD = 'NUM';
        this.outLot = 'OUT';
        this.page = 'PAG';
        this.parcel = 'PCL';
        this.part = 'PRT';
        this.phase = 'PH';
        this.platName = 'PTN';
        this.propertyLayout = 'LAY';
        this.qtr1 = 'QT1';
        this.qtr2 = 'QT2';
        this.qtr3 = 'QT3';
        this.range = 'LING';
        this.recordOfSurvey = 'REC';
        this.section = 'SEC';
        this.state = 'ST';
        this.storage = 'STG';
        this.streetNumber = 'STN';
        this.subdivisionName = 'SDC';
        this.surveyName = 'SUR';
        this.township = 'TOW';
        this.tract = 'TRT';
        this.unit = 'UT';
        this.zip = 'ZIP'
    }
}

export class AutoLegalDescription {
    constructor (property, template) {
        this.parts = [];
        this.template = template || '';
        this.refresh(property, template);

    /*
        Regex for replacing template items:
        (\{TAG\})?\s*\[TAG\](\{\*\})
        where TAG equals one of the fieldTags listed in AutoLegalDescriptionPart
        */
    }

    /*
        TODO: found in the PropertyLayout configuration instructions in RQO
    */
}

export class PropertyEntityModel {
    constructor (options) {
        options = options || {};
        const defaultValue = (val,defVal) => { return _.isNil(val) ? defVal : val; }
        this.propertyID = _.parseNumber(options.propertyID, 0);
        this.address = options.address || null;
        this.address1 = options.address1 || null;
        this.arbNumber = options.arbNumber || null;
        this.block = options.block || null;
        this.book = options.book || null;
        this.building = options.building || null;
        this.city = options.city || null;
        this.district = options.district || null;
        this.instrumentNumber = options.instrumentNumber || null;
        this.legalDescription = defaultValue(options.legalDescription, null);
        this.lot = options.lot || null;
        this.municipality = options.municipality || null;
        this.noAutoLegal = options.noAutoLegal || false;
        this.outLot = options.outLot || null;
        this.page = options.page || null;
        this.parcel = options.parcel || null;
        this.part = options.part || null;
        this.phase = options.phase || null;
        this.platname = options.platname || null;
        this.qtr1 = options.qtr1 || null;
        this.qtr2 = options.qtr2 || null;
        this.qtr3 = options.qtr3 || null;
        this.range = options.range || null;
        this.section = options.section || null;
        this.state = options.state || "";
        this.streetNumber = options.streetNumber || null;
        this.taxIdentification = options.taxIdentification || null;
        this.township = options.township || null;
        this.tract = options.tract || null;
        this.unit = options.unit || null;
        this.zip = options.zip || '';
        this.acreage = options.acreage || null;
        this.isForeignAddress = _.parseBool(options.isForeignAddress, false);
        this.countyName = defaultValue(options.countyName, null);
        this.countyID = _.parseNumber(options.countyID, null);
        this.regionTerritory = options.regionTerritory || null;
        this.country = options.country || null;
        this.surveyID = _.parseNumber(options.surveyID, null);
        this.surveyName = options.surveyName || null;
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.propertyLayoutID = _.parseNumber(options.propertyLayoutID, 1);
        this.propertyLayoutName = options.propertyLayoutName || null;
        this.propertyTypeID = _.parseNumber(options.propertyTypeID, null);
        this.propertyTypeName = options.propertyTypeName || null;
        this.sequence = _.parseNumber(options.sequence, null);
        this.subdivisionID = _.parseNumber(options.subdivisionID, null);
        this.subdivisionName = options.subdivisionName || null;
        this.address2 = options.address2 || null;
        this.deedWanted = options.deedWanted || false;
        this.mortWanted = options.mortWanted || false;
        this.modificationWanted = options.modificationWanted || false;
        this.mortRefiWanted = options.mortRefiWanted || false;
        this.assignmentWanted = options.assignmentWanted || false;
        this.titleWanted = options.titleWanted || false;
        this.mortgageNewDebt = options.mortgageNewDebt || null;
        this.mortgageUnpaidPrincipal = options.mortgageUnpaidPrincipal || null;
        this.mortgageOriginalDebt = options.mortgageOriginalDebt || null;
        this.mortgagePages = options.mortgagePages || null;
        this.deed1Pages = options.deed1Pages || null;
        this.deed1EstimatedValue = options.deed1EstimatedValue || null;
        this.deed2Pages = options.deed2Pages || null;
        this.deed2EstimatedValue = options.deed2EstimatedValue || null;
        this.assignmentPages = options.assignmentPages || null;
        this.assignmentNumberofAssignments = options.assignmentNumberofAssignments || null;
        this.releasePages = options.releasePages || null;
        this.releaseNumberOfReleases = options.releaseNumberOfReleases || null;
        this.loanID = _.parseNumber(options.loanID, null);
        this.isAllHUDs = options.isAllHUDs || false;
        this.carport = options.carport || null;
        this.garage = options.garage || null;
        this.userDefined4 = options.userDefined4 || null;
        this.boatSlip = options.boatSlip || null;
        this.storage = options.storage || null;
        this.maps = options.maps || null;
        this.recordOfSurvey = options.recordOfSurvey || null;
        this.recordingOfficeID = _.parseNumber(options.recordingOfficeID, null);
        this.incorporated = options.incorporated || null;
        this.isValidatedAddress = _.parseBool(options.isValidatedAddress);
        this.isAddressOverridden = _.parseBool(options.isAddressOverridden);
        this.estimatedValue = options.estimatedValue || null;
        this.estimatedValueOverride = options.estimatedValueOverride || null;
    }

    get formEstimatedValue (){
        return this.estimatedValueOverride != null ? this.estimatedValueOverride : this.estimatedValue;
    }

    set formEstimatedValue (val){
        if(val == this.estimatedValue){
            this.estimatedValueOverride = null;
        }
        else{
            this.estimatedValueOverride = val;
        }
    }

    get displayText () {
        var displayText = null;
        if (!this.fullAddress || this.fullAddress === '') {
            return '<Enter Property Information>';
        }
        return this.fullAddress;
    }

    get fullAddress () {
        const addressParts = [];
        if (this.address1) {addressParts.push(this.address1);}
        if (this.address2) {addressParts.push(this.address2);}
        if (this.city) {addressParts.push(this.city);}
        if (this.state) {addressParts.push(this.state);}
        if (this.zip) {addressParts.push(this.zip);}
        return addressParts.join(', ') || null;
    }

    updateLegalDescription () {
        if (!this.noAutoLegal) {
            this.legalDescription = this.fullAddress;
        }
    }

    isEqual (compareTarget) {
        let result = true;
        for (let prop in this) {
            if (_.hasIn(compareTarget, prop) && _.has(this, prop) && compareTarget[prop] !== this[prop]) {
                result = false;
                break;
            }
        }
        return result;
    }

    get propertyInfo() {
        let propertyData = _.clone(this);
        propertyData.propertyID = null; // Retrieve property related data, we don't want propertyID so setting it to null

        return propertyData;
    }

    toDataObject() { return _.pickBy(this, (v,k) => !_.includes(["propertyTypeName"], k)); }
}

export class PropertyViewModel {
    constructor (options) {
        options = options || {};

        const prop = options.property || {};
        this.property = new PropertyEntityModel(prop);

        this.taxIdentification = options.taxIdentification ? _.map(options.taxIdentification, ti => new PropertyTaxIdentification(ti)) : [];
        this.taxInformation = options.taxInformation ? _.map(options.taxInformation, ti => new PropertyTaxInformation(ti)) : [];
        this.taxInquiry = new TaxInquiry(options.taxInquiry);
    }

    get propertyID () {
        return this.property.propertyID;
    }

    get displayText () {
        return this.property.displayText;
    }

    get fullAddress () {
        return this.property.fullAddress;
    }

    update (data) {
        for (let prop in data) {
            if (_.hasIn(this, prop) && _.hasIn(data, prop) && this[prop] !== data[prop]) {
                this[prop] = data[prop];
                this.isDirty = true;
            }
        }
    }

    toDataObject() {
        return {
            property: this.property.toDataObject(),
            taxInformation: _.map(this.taxInformation, ti => ti.toDataObject()),
            taxIdentification: _.map(this.taxIdentification, tid => tid.toDataObject()),
            taxInquiry: this.taxInquiry
        };
    }
    //taxIdentification is excluded from the changes method since it breaks the changes object
    toChangesDataObject() {
        return {
            property: this.property.toDataObject(),
            taxInformation: _.map(this.taxInformation, ti => ti.toDataObject()),
            taxInquiry: this.taxInquiry
        };
    }
}

export class PropertyLayoutModel {
    constructor (options) {
        options = options || {};
        this.propertyLayoutID = _.parseNumber(options.propertyLayoutID, null);
        this.briefLegal = options.briefLegal || null;
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, null);
        this.hideAcreage = _.parseBool(options.hideAcreage, false);
        this.hideARBNum = _.parseBool(options.hideARBNum, false);
        this.hideBlock = _.parseBool(options.hideBlock, false);
        this.hideBook = _.parseBool(options.hideBook, false);
        this.hideBuilding = _.parseBool(options.hideBuilding, false);
        this.hideDistrict = _.parseBool(options.hideDistrict, false);
        this.hideInstrNum = _.parseBool(options.hideInstrNum, false);
        this.hideLot = _.parseBool(options.hideLot, false);
        this.hideMunicipality = _.parseBool(options.hideMunicipality, false);
        this.hideNumber = _.parseBool(options.hideNumber, false);
        this.hideOutLot = _.parseBool(options.hideOutLot, false);
        this.hidePage = _.parseBool(options.hidePage, false);
        this.hideParcel = _.parseBool(options.hideParcel, false);
        this.hidePart = _.parseBool(options.hidePart, false);
        this.hidePhase = _.parseBool(options.hidePhase, false);
        this.hidePlatname = _.parseBool(options.hidePlatname, false);
        this.hideQtr1 = _.parseBool(options.hideQtr1, false);
        this.hideQtr2 = _.parseBool(options.hideQtr2, false);
        this.hideQtr3 = _.parseBool(options.hideQtr3, false);
        this.hideRange = _.parseBool(options.hideRange, false);
        this.hideSection = _.parseBool(options.hideSection, false);
        this.hideSectionGroup = _.parseBool(options.hideSectionGroup, false);
        this.hideSubDivCode = _.parseBool(options.hideSubDivCode, false);
        this.hideTownship = _.parseBool(options.hideTownship, false);
        this.hideTract = _.parseBool(options.hideTract, false);
        this.hideUnit = _.parseBool(options.hideUnit, false);
        this.hideGarage = _.parseBool(options.hideGarage, false);
        this.hideCarport = _.parseBool(options.hideCarport, false);
        this.hideBoatSlip = _.parseBool(options.hideBoatSlip, false);
        this.hideStorage = _.parseBool(options.hideStorage, false);
        this.hideRecordOfSurvey = _.parseBool(options.hideRecordOfSurvey, false);
        this.hideMaps = _.parseBool(options.hideMaps, false);
        this.hideIncorporated = _.parseBool(options.hideIncorporated, false);

        this.propertyLayoutSequence = options.propertyLayoutSequence || new PropertyLayoutSequence();
    }
}

export class PropertyLayoutSequence {
    constructor (options) {
        options = options || {};
        this.lot = options.lot || 1;
        this.block = options.block || 2;
        this.subDivCode = options.subDivCode || 3;
        this.platname = options.platname || 4;
        this.phase = options.phase || 5;
        this.section = options.section || 6;
        this.sectionGroup = options.sectionGroup || 7;
        this.township = options.township || 8;
        this.arbNum = options.arbNum || 9;
        this.acreage = options.acreage || 10;
        this.tract = options.tract || 11;
        this.number = options.number || 12;
        this.parcel = options.parcel || 13;
        this.range = options.range || 14;
        this.part = options.part || 15;
        this.unit = options.unit || 16;
        this.building = options.building || 17;
        this.outLot = options.outLot || 18;
        this.book = options.book || 19;
        this.page = options.page || 20;
        this.municipality = options.municipality || 21;
        this.qtr1 = options.qtr1 || 22;
        this.qtr2 = options.qtr2 || 23;
        this.qtr3 = options.qtr3 || 24;
        this.instrNum = options.instrNum || 25;
        this.district = options.district || 26;
        this.carport = options.carport || 27;
        this.garage = options.garage || 28;
        this.boatSlip = options.boatSlip || 29;
        this.storage = options.storage || 30;
        this.recordOfSurvey = options.recordOfSurvey || 31;
        this.maps = options.maps || 32;
        this.incorporated = options.incorporated || 33;
    }
}

export class BriefLegalDetailModel {
    constructor (options) {
        options = options || {};
        this.selectedPropertyLayoutId = options.selectedPropertyLayoutId || null;
        this.selectedPropertyLayout = options.selectedPropertyLayout || new PropertyLayoutModel();
        this.propertyLayouts = options.propertyLayouts || [];
    }

    setSelectedPropertyLayout (id) {
        for (let pl of this.propertyLayouts) {
            if (Number(pl.propertyLayoutID) === Number(id)) {
                this.selectedPropertyLayoutId = Number(id);
                this.selectedPropertyLayout = pl;
                break;
            }
        }
    }
}