<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
            dismissable
        />
        <div class="rq-container my-2">
            <div class="row">
                <div class="col-6 col-md-6">
                    <div class="form-group">
                        <label for="drp_settlement_type">Settlement Type</label>
                        <span v-rq-tooltip.hover.top="{ title: this.settlementTypeHoverTitle }">
                            <dx-select-box
                                :input-attr="{ id: 'drp_settlement_type', automation_id: 'drp_settlement_type' }"
                                class="form-control"
                                :items="settlementTypeItems"
                                value-expr="id"
                                display-expr="name"
                                :search-enabled="true"
                                :disabled="isSettlementTypeDisabled"
                                v-model="detail.settlementStatementType"
                                @value-changed="onChangeSettlementType"
                            />
                        </span>
                    </div>

                    <div class="form-group">
                        <label for="drp_loans">Default Loan</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_loans', automation_id: 'drp_loans' }"
                            class="form-control"
                            :items="loanOptions"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            :disabled="readOnly"
                            v-model="detail.defaultLoanID"
                        />
                    </div>

                    <div class="form-group">
                        <label for="watermarkStatus">Status</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_watermarkStatus', id: 'drp_watermarkStatus' }"
                            :items="watermarkOptions"
                            value-expr="id"
                            display-expr="name"
                            :disabled="readOnly"
                            v-model="detail.watermarkStatus"
                            :search-enabled="true"
                        />
                    </div>

                    <div v-if="isCdf" class="form-group">
                        <label for="cashToCloseFrom">CASH TO CLOSE</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_cashToCloseFrom', id: 'drp_cashToCloseFrom' }"
                            :items="cashToCloseFromOptions"
                            value-expr="id"
                            display-expr="name"
                            :disabled="readOnly"
                            v-model="detail.cashToClosePopulateFrom"
                            @value-changed="onChangedCashToClose"
                            :search-enabled="true"
                        />
                    </div>                    
                </div>
                <!-- <div class="col-6 col-md-6">
                    <div class="form-group"> -->
                <div class="col-6 col-md-6">
                    <div class="row">
                        <div v-if="isCdf" class="col-4 form-group ms-4">
                            <label>Include ALTA</label>
                            <div>
                                <rqSwitch
                                    automation_id="chk_include_alta"
                                    type="text"
                                    variant="primary"
                                    on="Yes"
                                    off="No"
                                    size="lg"
                                    :disabled="readOnly"
                                    v-model="detail.includeAlta"
                                    @change="onChangeIncludeAlta"
                                />
                            </div>
                        </div>

                        <div class="col-4 form-group ms-4">
                            <label>{{withOutSellerLabel}}</label>
                            <div>
                                <rqSwitch
                                    automation_id="chk_without_seller"
                                    type="text"
                                    variant="primary"
                                    on="Yes"
                                    off="No"
                                    size="lg"
                                    :disabled="readOnly"
                                    v-model="detail.isWithOutSeller"
                                    @change="onChangeIsWithOutSeller"
                                />
                            </div>
                        </div>

                        <div v-if="isCdf" class="col-4 form-group ms-4">
                            <label>Use Page 2A/2B</label>
                            <div>
                                <rqSwitch
                                    automation_id="chk_use_page2a2b"
                                    type="text"
                                    variant="primary"
                                    on="Yes"
                                    off="No"
                                    size="lg"
                                    :disabled="readOnly"
                                    v-model="detail.useTemplatePage2A2B"
                                    @change="onChangeUseTemplatePage2a2b"
                                />
                            </div>
                        </div>

                        <div class="col-4 form-group ms-4">
                            <label>SS LOCK</label>
                            <div>
                                <rqSwitch
                                    automation_id="chk_ss_lock"
                                    type="lock"
                                    size="lg"
                                    :disabled="isFileInUse || isFileLocked"
                                    v-model="detail.isSettlementLocked"
                                    @change="onChangeSSLock"
                                />
                            </div>
                        </div>

                        <div v-if="isHud2010" class="col-4 form-group ms-4">
                            <label>Reverse Mortgage</label>
                            <div>
                                <rqSwitch
                                    automation_id="chk_reverse_mortgage"
                                    type="text"
                                    variant="primary"
                                    on="Yes"
                                    off="No"
                                    size="lg"
                                    :disabled="readOnly"
                                    v-model="detail.isReverseMortgage"
                                    @change="onChangeIsReverseMortgage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { SETTLEMENT_TYPE } from '@settlement/models';

export default {
    props: {
        detail: { type: Object },
        isEditMode: { type: Boolean, default: false },
        readOnly: { type: Boolean, default: false },
        settlementType: { type: Number, default: SETTLEMENT_TYPE.CDF },
        watermarkOptions: { type: Array, default: () => [] },
        cashToCloseFromOptions: { type: Array, default: () => [] },
    },
    data: function(){
        return {
            showBanner: false,
            selectedWatermarkStatus: this.detail.watermarkStatus,
            selectedView: this.settlementType,
            isWithOutSellerView: this.detail.isWithOutSeller,
            includeAlta: this.detail.includeAlta,
            usePage2a2b: this.detail.useTemplatePage2A2B
        }
    },
    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems",
            "propertyList"
        ]),
        ...mapState({
            isFileInUse: state => _.parseBool(state.orders.orderSummary.isFileInUse),
            isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
            loans: state => state.orders.loans || [],
            order: state => state.orders.order,
            systemDefaults: state => state.system.systemDefaults,
            cdfSystemDefaults: state => state.system.systemDefaults.cdfSystemDefaults,

        }),
        settlementTypeItems() { 
            return _.filter(this.lookupHelpers.getLookupItems(this.lookupItems.SETTLEMENT_TYPES), i => i.id !== SETTLEMENT_TYPE.CSS); // RQO-25287 Trim out of CSS option for now
        },
        isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
        isHud() { return this.isHud1974 || this.isHud2010; },
        isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
        isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
        withOutSellerLabel() { return this.isCdf ? "WITHOUT SELLER:" : "HUD-1A"; },
        isSettlementTypeDisabled(){
            if(this.readOnly) return true;
            return this.order.receiptsOrDisbursementsPosted;
        },
        settlementTypeHoverTitle(){
            return this.order.receiptsOrDisbursementsPosted ? "Settlement Statement cannot be changed after disbursements are issued or receipts are booked" : "";
        },
        loanOptions() { return this.lookupHelpers.getLoanOptions(); }
    },
    watch: {},

    created() {
    },
    methods: {
        isValid() {
            this.v$.$touch();
            this.showBanner = true;
            return !this.v$.$error;
        },
        onChangedCashToClose (e) {
            if(_.isNil(e.value))
                this.detail.cashToClosePopulateFrom = 0;
        },
        onChangeSettlementType (e) {
            const self = this;
            if(_.isNil(e.event)) return;
            let currentType = self.settlementTypeItems[e.previousValue].name || "";
            let selectedType = self.settlementTypeItems[e.value].name || "";
            let rndWarning = "Receipts & Disbursements have been created, changing the Default Settlement Statement requires the statement to be changed in Check Writing.";
            let msg = self.order.hasReceiptsAndDisbursements ? `<p>${rndWarning}  Are you sure you want to change from ${currentType} to ${selectedType}?</p>` : `<p>Are you sure you want to change from ${currentType} to ${selectedType}?</p>`
            self.confirmSettlementStatementChange(
                msg,
                e.value,
                e.previousValue
            );
        },
        confirmSettlementStatementChange(message, newValue, prevValue) {
            const self = this;
            if(prevValue === newValue) return;
            let onYes = () => { true; self.selectedView = newValue; }
            let onNo = () => { self.detail.settlementStatementType = self.selectedView = prevValue; };
            self.$dialog.confirm("Confirm", message, onYes, onNo, { cancelTitle: "No", okTitle: "Yes"});
        },
        onChangeIncludeAlta(newValue) {
            const self = this;
            self.includeAlta = newValue;
        },
        onChangeIsWithOutSeller(newValue) {
            const self = this;
            let cdfVerbiage = self.detail.isWithOutSeller ? "Are you sure you want to change to a CDF Without Seller?" : "Are you sure you want to change to a CDF?";
            let hudVerbiage = self.detail.isWithOutSeller ? "Are you sure you want to change to a HUD-1A?" : "Are you sure you want to change to a HUD-1?";
            let confirmMessage = this.isCdf ? cdfVerbiage : hudVerbiage;
            self.confirmWithOutSellerChange(
                confirmMessage,
                newValue,
                !newValue
            );
        },
        confirmWithOutSellerChange(message, newValue, prevValue) {
            const self = this;
            if(prevValue === newValue) return;
            let onYes = () => { true; self.isWithOutSellerView = newValue; }
            let onNo = () => { self.detail.isWithOutSeller = self.isWithOutSellerView = prevValue;};
            self.$dialog.confirm("Confirm", message, onYes, onNo, { cancelTitle: "No", okTitle: "Yes"});
        },
        onChangeUseTemplatePage2a2b(newValue) {
            const self = this;
            self.useTemplatePage2A2B = newValue;
        },
        onChangeIsReverseMortgage(newValue) {
            const self = this;
            self.isReverseMortgage = newValue;
        },
        onChangeSSLock (newValue) {
            const self = this;
            self.confirmSSLockChange(
                `Settlement Statement is currently ${newValue ? "Unlocked" : "Locked"}.  Are you sure you want to <strong>${newValue ? "Lock" : "Unlock"}</strong> the Settlement Statement?`,
                newValue,
                !newValue
            );
        },
        confirmSSLockChange(message, newValue, prevValue) {
            const self = this;
            if(prevValue === newValue) return;
            let onYes = () => true;
            let onNo = () => { self.detail.isSettlementLocked = prevValue; };
            self.$dialog.confirm("Confirm", message, onYes, onNo, { cancelTitle: "No", okTitle: "Yes"});
        },
    }
}
</script>
