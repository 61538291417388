<template>
    <div class="content-wrapper prorations">
        <rq-banner
            :message="prorationWarningMessage"
            :variant="alertSeverity"
            icon="fas fa-exclamation-triangle"
            :visible="!canCalculate"
            dismissable
        />
        <rq-page-section title="Prorations" header-size="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover.right="{ title: getMaxProrationToolTip }">
                        <button automation_id="btn_add_proration"
                            type="button"
                            class="btn btn-default btn-theme"
                            v-focus
                            @click="onAddProrationClicked"
                            :disabled="readOnly || reachedMaxItems">
                            Add
                        </button>
                        <rq-report-button
                            text="Print Report"
                            :disabled="readOnly || noProrationItems"
                            :path="reportOptions.path"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
                <ul v-if="propertyDropdownData.length > 1" class="nav ms-auto">
                    <li class="nav-item">
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_property')"
                            :element-attr="{ class: 'form-control form-control-sm' }"
                            value-expr="propertyID"
                            display-expr="name"
                            :items="propertyDropdownData"
                            v-model="selectedPropertyId"
                        />
                    </li>
                </ul>
            </template>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_proration_method">Proration Method</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_proration_method')"
                            value-expr="id"
                            display-expr="name"
                            :items="prorationMethods"
                            :disabled="readOnly"
                            v-model.number="commonProrationData.prorationMethod"
                            @valueChanged="onProrationMethodChanged"
                        />
                    </div>
                    <div class="col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_proration_method">Due Date</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_date_to_use')"
                            value-expr="id"
                            display-expr="name"
                            :items="dateToUseSelection"
                            :disabled="readOnly"
                            v-model.number="commonProrationData.dateToUseType"
                            @valueChanged="onDateToUseTypeChanged"
                        />
                    </div>
                    <div class="col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_proration_date">Proration Date for this Order</label>
                        <rqdx-date-box
                            id="dtp_proration_date"
                            v-model="commonProrationData.prorationDate"
                            :disabled="readOnly || disableProrationDate"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="prorations_tbl"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            @rowDoubleClick="onRowDoubleClick"
            @selectionChanged="onGridSelectionChanged"
            @edit="onEditProration"
            @delete="onDeleteProration"
            @edit-detail="onEditDetail"
            @revert="onRevert"
            @contentReady="onContentReady"
            export-file-name="prorations_data"
            hide-search
        />

        <rq-page-section v-if="canCalculate" title="Results" header-size="lg" :collapsible="showResults" borderless>
            <ul>
                <proration-result v-for="item in prorationResults" v-bind:key="item.prorateID" :data="item"/>
            </ul>
        </rq-page-section>
    </div>
</template>
<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { GlobalEventManager } from '@/app.events';
    import { ProrateDto, ProrateResult, PaymentDue, ProrationMethod, SETTLEMENT_TYPE, SettlementTypeOption } from '@settlement/models';
    import { mapState, mapGetters } from "vuex";
    import ProrationDetail from "./components/ProrationDetail";
    import ChangeAllProrations from "./components/ChangeAllProrations";
    import ProrationResult from "./components/ProrationResult";
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { SettlementReport } from '@settlement/components/dashboard/models';

    const DateToUse = {
        CloseDate: 1,
        FundDisbursementDate: 2,
        Custom: 3
    }

    export default {
        name: "ProrationCalculator",
        mixins: [BaseSettlementMixin],
        components: {
            ProrationResult
        },
        data: function() {
            return {
                selectedPropertyId: 0,
                properties: [],
                property: null,
                prorations: [],
                prorationResults: [],
                showResults: false,
                commonProrationData: {
                    prorationMethod: 0,
                    dateToUseType: null,
                    prorationDate: null,
                    prorationOtherDate: null
                },
                alertSeverity: "warn",
            }
        },
        props: {
            displayMode: {type: String, default: "route"}
        },
        computed: {
            ...mapState({
                closeDate: state => state.orders.order.closeDate,
                fundDate: state => state.orders.order.fundDate,
            }),
            ...mapGetters([
                "propertyList"
            ]),
            toDateLabel() {
                if (this.commonProrationData.prorationMethod === ProrationMethod.ToDayOf || this.commonProrationData.prorationMethod === ProrationMethod.ToDayAfter) return "To Date";
                if (this.commonProrationData.prorationMethod === ProrationMethod.ThruDayBefore || this.commonProrationData.prorationMethod === ProrationMethod.ThruDayOf) return "Thru Date";
                return "To Date"; // default
            },
            prorationDefaults() { return this.lookupHelpers.getProrationDefaults() },
            billingPeriods() { return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_BILLING_PERIODS); },
            prorationMethods() { return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_METHODS); },
            prorationTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_TYPES); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            disableProrationDate() {
                return this.commonProrationData.dateToUseType === DateToUse.CloseDate
                        || this.commonProrationData.dateToUseType === DateToUse.FundDisbursementDate;
            },
            requiredMessage() { return "Proration Date required to "},
            canCalculate() {
                return !_.isNil(this.commonProrationData.prorationDate);
            },
            reachedMaxItems() {
                const self = this;
                let currentProrations = _.filter(self.prorations, p => p.propertyID === self.selectedPropertyId);
                    return currentProrations.length >= 10;
            },
            getMaxProrationToolTip() {
                if (this.reachedMaxItems) {
                    return 'You have reached the maximum number of Prorations allowed for the Settlement Statement.';
                }
                return '';
            },
            noProrationItems() {
                const self = this;
                let currentProrations = _.filter(self.prorations, p => p.propertyID === self.selectedPropertyId);
                return currentProrations.length == 0;
            },
            selectionActions() {
                const self = this;
                let actions = [
                    {
                        name: "edit", text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        tooltip: `Edit ${this.itemName}`,
                        disabled: () => {
                            if(!self.canCalculate)
                                return `${this.requiredMessage} Edit`;
                            else return self.readOnly
                        }
                    },
                    {
                        name: "delete", text: "Delete",
                        eventName: "delete",
                        tooltip: `Delete ${this.itemName}`,
                        allowMultiSelection: true,
                        disabled: () => {
                            if(!self.canCalculate)
                                return `${this.requiredMessage} Delete`;
                            else return self.readOnly
                        }
                    },
                    {
                        name: "edit-detail", text: 'Edit Details',
                        eventName: "edit-detail",
                        tooltip: `Edit ${this.itemName} Detail`,
                        disabled: () => {
                            if(!self.canCalculate)
                                return `${this.requiredMessage} Edit Detail`;
                            else return self.readOnly
                        }
                    },
                    {
                        name: "revert", text: 'Revert',
                        eventName: "revert",
                        requireSelection: true,
                        tooltip: `Revert Overrides`,
                        allowMultiSelection: true,
                        disabled: (e) => {
                            if(!self.canCalculate)
                                return `${this.requiredMessage} Revert`;
                            else return _.some(e.data, i => !self.canRowRevert(i)) || self.readOnly
                        }
                    }
                ];
                return actions;
            },
            paymentDueTypes() {
                let items = this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_PAYMENT_DUE_TYPES);
                return this.rent || this.billingDaily
                    ? _.filter(items, item => !_.startsWith(item.name, "Due - "))
                    : items;
            },
            prorationWarningMessage(){
                if (this.alertSeverity === "error")
                    return "You must specify a default proration date before you can do this.";
                switch(this.commonProrationData.dateToUseType){
                    case DateToUse.CloseDate:
                        return "To calculate prorations, Close Date must be entered in File Main";
                    case DateToUse.FundDisbursementDate:
                        return "To calculate prorations, Fund/Disbursement Date must be entered in File Main";
                    case DateToUse.Custom:
                        return "To calculate prorations, enter a Custom Date";
                    default:
                        return "To calculate prorations, a Proration Date must be entered";
                }
            },
            propertyDropdownData() {
                return _.map(this.propertyList, (item, index) => {
                    let address = _.getWithNullCheck(item, "address1", "");
                    let city = _.getWithNullCheck(item, "city", "");
                    let state = _.getWithNullCheck(item, "state", "");
                    let truncateObj = { length: 25 };
                    let addressDescription = `${address.length > 0 ? `${_.truncate(address, truncateObj)}, `: ''}${city.length > 0 ? `${_.truncate(city, truncateObj)}, `: ''}${state}`;
                    return {
                        propertyID: item.propertyID,
                        name: `Property ${index + 1} of ${this.propertyList.length}: ${addressDescription}`
                    }
                });
            },
            reportOptions() {
                const self = this;
                return SettlementReport.reportOptionsProrationAddendum(self.$route.params.orderId);
                },
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },
        watch: {
            propertyList:{
                handler(newValue, oldValue) {
                    if(_.isEmpty(newValue) || this.selectedPropertyId > 0) return;
                    this.selectedPropertyId = this.propertyList[0].propertyID;
                },
                immediate: true
            },
            selectedPropertyId(newValue, oldValue) {
                if(this.gridInstance === null) return;
                this.gridInstance.refresh();
            },
            "commonProrationData.prorationDate": function(newValue, oldValue) {
                if(typeof(newValue) == "object") {
                     this.commonProrationData.prorationDate = newValue = null;
                }
                this.updateProrationDate(newValue);
            }
        },
        created() {
            this.fetchData()
                .then(() => {
                    if (!this.canCalculate) return;
                    if (_.every(this.prorations, p => p.prorationDate !== null)) return;
                    this.calculateAll();
                });
            this.initializeNonReactiveVariables();
            this.initGridConfig();
        },
        methods: {
            initializeNonReactiveVariables() {
                this.itemKey = "prorateID";
                this.itemName = "Proration";
                this.dateToUseSelection = [
                    { id: DateToUse.CloseDate, name: "Close Date" },
                    { id: DateToUse.FundDisbursementDate, name: "Fund/Disbursement Date" },
                    { id: DateToUse.Custom, name: "Custom Date" }
                ];
            },

            initGridConfig() {
                const self = this;
                const totalSummaryItem = (column, isLabel=false) => ({});
                self.gridConfig = {
                    height: 300,
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            cellTemplate(cellElement, cellInfo){
                                if(!cellInfo.value) return;
                                if(self.showDescriptionRevert(cellInfo.row.data)) cellElement.addClass("rq-overridden");
                                $("<span />")
                                    .text(cellInfo.value)
                                    .appendTo(cellElement);
                            }
                        },
                        {
                            caption: "Total Amount",
                            dataField: "totalAmountDisplay",
                            dataType: "number",
                            alignment: "right",
                            cellTemplate(cellElement, cellInfo){
                                if(self.showCurrentAmountRevert(cellInfo.row.data)) {
                                    cellElement.addClass("rq-overridden");
                                }else{
                                    if(!cellInfo.value) return;
                                }

                                $("<span />")
                                    .text(accounting.formatMoney(cellInfo.value))
                                    .appendTo(cellElement);
                            }
                        },
                        {
                            dataField: "overrideAmount",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            caption: "Amount Override"
                        },
                        DxGridUtils.dateColumn({
                            dataField: "fromDate",
                            minWidth: 100,
                            cellTemplate(cellElement, cellInfo){
                                if(self.showDateRevert(cellInfo.row.data, "fromDate")) cellElement.addClass("rq-overridden");
                                if(!cellInfo.value) return;
                                $("<span />")
                                    .text(DateTimeHelper.toFormat(cellInfo.value, 'MM/dd/yyyy'))
                                    .appendTo(cellElement);
                            }
                        }),
                        DxGridUtils.dateColumn({
                            caption: self.toDateLabel,
                            dataField: "toDate",
                            minWidth: 100,
                            cellTemplate(cellElement, cellInfo){
                                if(self.showDateRevert(cellInfo.row.data, "toDate")) cellElement.addClass("rq-overridden");
                                if(!cellInfo.value) return;
                                $("<span />")
                                    .text(DateTimeHelper.toFormat(cellInfo.value, 'MM/dd/yyyy'))
                                    .appendTo(cellElement);
                            }
                        }),
                        {
                            caption: "Payment Due",
                            dataField: "paymentDue",
                            dataType: "string",
                            lookup: {
                                dataSource: self.paymentDueTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            caption: "Periods Per Year",
                            dataField: "periodsPerYear",
                            dataType: "string",
                            width: 120,
                            lookup: {
                                dataSource: self.billingPeriods,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            caption: "Type",
                            dataField: "daysInYear",
                            dataType: "string",
                            lookup: {
                                dataSource: self.prorationTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            caption: "C/D Seller",
                            dataField: "sellerAmountDisplay",
                            dataType: "number",
                            alignment: "right",
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            caption: "C/D Buyer",
                            dataField: "buyerAmountDisplay",
                            dataType: "number",
                            alignment: "right",
                            cellTemplate: DxGridUtils.moneyCellTemplate
                        },
                        {
                            dataField: "fullOverride",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            caption: "Full Override"
                        },
                        {
                            dataField: "overrideProrationDate",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            caption: "Date Override"
                        },
                        {
                            dataField: "enableCalculation",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            caption: "Enable Calculation"
                        }
                    ],
                    summary:
                    {
                        totalItems: [
                            totalSummaryItem("description"),
                            totalSummaryItem("currentTaxes"),
                            totalSummaryItem("amount"),
                            totalSummaryItem("cdSellerAmount"),
                            totalSummaryItem("cdBuyerAmount")
                        ]
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load(loadOptions) {
                        if(_.isEmpty(self.prorations)) return [];
                        return _.sortBy(_.filter(self.prorations, p => p.propertyID === self.selectedPropertyId), "sequence");
                    }
                };
            },
            fetchData() {
                const self = this;
                let promise = self.$api.ProrationsApi.getProrationData(self.$route.params.orderId);
                return self.$rqBusy.wait(promise)
                    .then((response) => {
                        self.commonProrationData.prorationMethod = response.prorationMethod;
                        self.commonProrationData.dateToUseType = response.dueDateType;
                        if (response.prorations){
                            self.initializedData(response);
                            self.refreshGrid();
                        }
                    })
                    .catch(error => {
                        let message = 'Error retrieving Proration information';
                        self.$log.fatal(`ProrationCalculator - ${message}`, error);
                        self.$toast.error({ message: message });
                    });
            },
            initializedData(response) {
                const self = this;

                let mappedProrations = _.map(response.prorations, p => new ProrateDto(p));

                self.prorations = mappedProrations;

                self.commonProrationData.prorationMethod = response.prorationMethod;
                self.commonProrationData.prorationOtherDate = response.prorationOtherDate;
                self.commonProrationData.dateToUseType = response.dueDateType;

                // set dates for calculations
                let initialDate = self.commonProrationData.prorationOtherDate;
                let dateType = self.commonProrationData.dateToUseType;

                if (dateType === DateToUse.CloseDate)
                    initialDate = self.closeDate;
                if (dateType === DateToUse.FundDisbursementDate)
                    initialDate = self.fundDate;

                self.commonProrationData.prorationDate = initialDate;

                self.createResultData();
                if (!_.every(self.prorations, p => p.prorationDate !== null) && this.canCalculate) self.calculateAll();
            },
            add(data) {
                let self = this;
                let request = { orderId: self.$route.params.orderId, prorate: data };
                let apiPromise = self.$api.ProrationsApi.addProration(request);
                self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        //self.$toast.success({message: 'Proration Added Succesfully'});
                        GlobalEventManager.saveCompleted({success: true});
                        self.calculateAll();
                    })
                    .catch(error => {
                        let message = 'Error adding Proration';
                        self.$log.fatal(`ProrationCalculator - ${message}`, error);
                        self.$toast.error({ message: message });
                    });
            },
            save(data) {
                const self = this;

                if (self.readOnly){
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let request = {
                    data: {
                        orderId: self.$route.params.orderId,
                        prorate: data
                    },
                    changes: []
                };
                let promise = self.$api.ProrationsApi.saveProration(request);

                return self.$rqBusy.wait(promise)
                    .then((response) => {
                        //self.$toast.success({message: 'Proration Saved Successfully'});
                        GlobalEventManager.saveCompleted({success: true});
                        self.calculateAll();
                        return true;
                    })
                    .catch(error => {
                        let message = 'Error saving Proration information';
                        self.$log.fatal(`ProrationCalculator - ${message}`, error);
                        self.$toast.error({ message: message });
                        GlobalEventManager.saveCompleted({success: false});
                        return error;
                    });
            },
            updateSelected(data) {
                let self = this;
                let apiPromise = self.$api.ProrationsApi.updateSelected(data);
                self.$rqBusy.wait(apiPromise)
                    .then( (response) => {
                        //self.$toast.success('Prorations updated');
                        self.calculateAll();
                    })
                    .catch(error => {
                        let message = 'Error calculating proration data';
                        self.$log.fatal(`ProrationCalculator - ${message}`, error);
                        self.$toast.error({ message: message });
                    });
            },
            calculateAll(overrideProrationDateReset = false) {
                const self = this;
                // using the .NET system min-time here to avoid model state validation errors and calculate an empty date
                let minDate = "0001-1-1 12:00:00 AM";
                let request = {
                    orderId: self.$route.params.orderId,
                    propertyId: self.selectedPropertyId,
                    prorationMethod: self.commonProrationData.prorationMethod ?? 0,
                    dueDateType: self.commonProrationData.dateToUseType,
                    prorationDate: self.commonProrationData.prorationDate || minDate,
                    overrideProrationDateReset: overrideProrationDateReset
                };
                self.$toast.warn("Calculating Prorations.  One moment...");
                let apiPromise = self.$api.ProrationsApi.calculateAll(request);
                return self.$rqBusy.wait(apiPromise)
                    .then( (response) => {
                        self.$toast.success({message: self.prorations.length > 0 ? 'Prorations updated' : 'Prorations created'});
                        self.fetchData();
                    })
                    .catch(error => {
                        let message = 'Error calculating proration data';
                        self.$log.fatal(`ProrationCalculator - ${message}`, error);
                        self.$toast.error({ message: message });
                    });
            },
            updateProrationDate(customDate) {
                if(customDate !== null
                    && customDate !== this.commonProrationData.prorationOtherDate
                    && this.commonProrationData.dateToUseType === DateToUse.Custom) {
                    this.calculateAll();
                }
            },
            createResultData() {
                const self = this;
                self.prorationResults.length = 0;
                self.showResults = true;
                let selectedItems = self.gridInstance.getSelectedRowsData();
                let results = [];
                //need to pull from self.prorations because the gridInstance may have stale data
                _.forEach(selectedItems, i => {
                    let item = _.find(self.prorations, p => {return p.prorateID === i.prorateID;});
                    results.push(item);
                });
                if(results.length === 0)
                    self.showResults = false;
                self.prorationResults = results;
            },
            initResultData() {
                const self = this;
                // RQO-21042: fixed dates server side so no longer formatting display text here
                // _.forEach(self.prorations, item => {
                //     self.formatDisplayText(item)
                // });
            },
            formatMoney(v) {
                return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"--" } });
            },
            formatDate(v) { return DateTimeHelper.toFormat(v, "MM/dd/yyyy"); },
            onDeleteProration(e) {
                const self = this;

                let toBeDeletedKeys = this.gridInstance.getSelectedRowKeys();
                if (toBeDeletedKeys.length > 0) {
                    var ok = function () {
                        let apiPromise = self.$api.ProrationsApi.deleteProrations(toBeDeletedKeys, self.orderId);
                        return self.$rqBusy.wait(apiPromise)
                            .then(() => {
                                self.fetchData();
                                self.$toast.success({message: 'Proration(s) deleted'});
                                return true;
                            })
                            .catch(error => {
                                let message = 'Error deleting Proration(s)';
                                self.$log.fatal(`ProrationCalculator - ${message}`, error);
                                self.$toast.error({ message: message });
                                return error;
                            });
                    };
                    self.$dialog.confirm("Confirm", "Delete Selected Proration(s)?", ok);
                }
            },
            onContentReady() {
                // RQO-21042: fixed dates server side so no longer formatting display text here
                //this.initResultData();
                this.createResultData();
            },
            onRowDoubleClick(e) {
                if(!this.canCalculate) return
                this.onEditProration(e);
            },
            onDateToUseTypeChanged(e) {
                if(_.isNil(e.event)) return;
                switch(this.commonProrationData.dateToUseType) {
                    case DateToUse.CloseDate:
                        this.commonProrationData.prorationDate = this.closeDate;
                        break;
                    case DateToUse.FundDisbursementDate:
                        this.commonProrationData.prorationDate = this.fundDate;
                        break;
                    case DateToUse.Custom:
                        this.commonProrationData.prorationDate = this.commonProrationData.prorationOtherDate;
                        break;
                }
                this.calculateAll(true);
            },
            onProrationMethodChanged(e) {
                if(_.isNil(e.event)) return;
                this.calculateAll();
            },
            onGridSelectionChanged() {
                if(!this.canCalculate) return;
                this.createResultData();
            },
            onEditDetail() {
                let self = this;
                let selectedRowsDesc = new Array;
                _.forEach(this.gridInstance.getSelectedRowsData(), i => {
                    selectedRowsDesc.push(i.description);
                });
                let editDetailsTitle = "Edit Details - " + selectedRowsDesc.join(', ');
                let okHandler = function(e) {
                    let toBeUpdatedKeys = self.gridInstance.getSelectedRowKeys();

                    let data = {
                        orderId: self.$route.params.orderId,
                        daysInYear: e.component.daysInYear,
                        periodsPerYear: e.component.periodsPerYear,
                        paymentDue: e.component.paymentDue,
                        ids: toBeUpdatedKeys
                    }

                    self.updateSelected(data);
                };
                self.$dialog.open({
                    title: editDetailsTitle,
                    height: 300,
                    width: 850,
                    component: ChangeAllProrations,
                    onOk: okHandler,
                    okTitle: "Save"
                });
            },
            onAddProrationClicked() {
                let self = this;
                if (!self.canCalculate) {
                    self.alertSeverity = "error";
                    return;
                }

                let availableTaxCategories = _.filter(self.prorationDefaults, d => d.taxCategory === 0 || !_.some(self.prorations, p => p.taxCategory === d.taxCategory))

                let options = {
                    title: `Add Prorations`,
                    data: new ProrateDto({
                        taxCategory: null
                    }),
                    prorations: self.prorations,
                    isEditMode: false,
                    readOnly: self.readOnly,
                    propertyId: self.selectedPropertyId,
                    availableTaxCategories: availableTaxCategories
                };
                this.showEditDialog(options);
            },
            onEditProration(e) {
                let self = this;
                let selectedData =  e.data || this.gridInstance.getSelectedRowsData()[0];
                let options = {
                    title: `Edit Prorations: ${selectedData.description !== null ? selectedData.description : 'No Description'}`,
                    data: _.toPlainObject(selectedData),
                    prorations: self.prorations,
                    isEditMode: true,
                    readOnly: self.readOnly,
                    propertyId: self.selectedPropertyId
                };
                this.showEditDialog(options);
            },
            showEditDialog(options) {
                let self = this;

                let okHandler = function(e) {
                    let isValid = e.component.isValid();
                    if(!isValid){
                        e.component.validationError = true;
                        return false;
                    }

                    let data = e.component.localDetail;
                    let originalData = e.component.originalData;

                    let changes = self.getAuditChanges(originalData, data);

                    if(changes.length === 0) {
                        self.$toast.info({ message: "No changes detected" });
                        return false;
                    }

                    if(data.prorateID === 0)
                    {
                        self.add(data);
                    }
                    else {
                        self.save(data);
                    }
                };
                self.$dialog.open({
                    title: options.title,
                    width: 900,
                    component: ProrationDetail,
                    adaptive: true,
                    props: {
                        detail: options.data,
                        prorations: options.prorations,
                        taxCategories: options.availableTaxCategories,
                        isEditMode: options.isEditMode,
                        commonProrationData: self.commonProrationData,
                        readOnly: options.readOnly,
                        propertyId: options.propertyId,
                        settlementType: self.selectedView
                    },
                    onOk: okHandler,
                    okTitle: "Save"
                });
            },
            paidInAdvance(paymentDue) {
                return paymentDue === PaymentDue.PaidInAdvance
                    || paymentDue === PaymentDue.PaidInAdvance_BuyerOnly
                    || paymentDue === PaymentDue.PaidInAdvance_SellerOnly;
            },
            showDescriptionRevert(itemData) {
                if(!itemData.enableCalculation) return false;
                return itemData.description !== itemData.originalDescription && _.parseNumber(itemData.taxCategory, 0) > 0;
            },
            showDateRevert(itemData, field){
                if (!itemData.enableCalculation) return false;
                if (field === "fromDate")
                    return this.paidInAdvance(itemData.paymentDue) && itemData.prorationDate !== itemData[field];
                if (field === "toDate")
                    return !this.paidInAdvance(itemData.paymentDue) && itemData.prorationDate !== itemData[field];
            },
            showCurrentAmountRevert(itemData) {
                if (!itemData.enableCalculation) return false;
                return _.parseNumber(itemData.taxCategory, 0) > 0 && itemData.overrideAmount === true;
            },
            canRowRevert(itemData){
                if (!itemData.enableCalculation) return false;
                const self = this;
                return (self.showDescriptionRevert(itemData) ||
                    self.showDateRevert(itemData, "fromDate") ||
                    self.showDateRevert(itemData, "toDate") ||
                    self.showCurrentAmountRevert(itemData));
            },
            onRevert(e){
                const self = this;
                let selectedData =  e.data || self.gridInstance.getSelectedRowsData()[0];
                _.forEach(selectedData, i => {
                    i.description = i.originalDescription;
                    i.overrideDescription = false;
                    i.amount = i.currentTaxes;
                    i.overrideAmount = false;
                    if(self.paidInAdvance(i)){
                        i.fromDate = i.prorationDate;
                    }
                    else{
                        i.toDate = i.prorationDate;
                    }
                    i.overrideProrationDate = false;
                    i.fullOverride = false;
                    i.isDateEdited = false;
                });
                self.gridInstance.refresh();
                self.updateAndCalcAll(selectedData);
            },
            updateAndCalcAll(items){
                const self = this;
                if(!self.canCalculate) return;
                let request = {
                    orderId: self.$route.params.orderId,
                    prorationMethod: self.commonProrationData.prorationMethod,
                    prorationDate: self.commonProrationData.prorationDate,
                    prorationDateDueType: self.commonProrationData.dateToUseType,
                    prorations: items
                };
                self.$toast.warn("Calculating Prorations.  One moment...");
                let apiPromise = self.$api.ProrationsApi.updateAndCalcAll(request);
                return self.$rqBusy.wait(apiPromise)
                    .then( (response) => {
                        self.$toast.success({message: self.prorations.length > 0 ? 'Prorations updated' : 'Prorations created'});
                        self.fetchData();
                    })
                    .catch(error => {
                        let message = 'Error calculating proration data';
                        self.$log.fatal(`ProrationCalculator - ${message}`, error);
                        self.$toast.error({ message: message });
                    });
            },
            refreshGrid() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            }
        }
    }
</script>
