
export default class RateBalancer {
	constructor(options) {
		options = options || {};
		this._amount = _.parseNumber(options.amount, 0);
		this.taxRate = _.parseNumber(options.taxRate, 0);
		this._buyerPercent = this.parsePercent(options.buyerPercent);
		this._buyerAmount = this.parseAmount(options.buyerAmount);
		this._sellerPercent = this.parsePercent(options.sellerPercent);
		this._sellerAmount = this.parseAmount(options.sellerAmount);
		this._uwsplitAmount = this.parseAmount(options.underwriterSplit);
		this._uwsplitPercent = this.parsePercent(options.underwriterSplitPercent);
		this._overrideUnderwriter = options.overrideUnderwriter;
	}

	get amount() { return this._amount; }
	set amount(val) {
		this._amount = this.parseFixed(val, 2);
	}

	get taxAmount() { return _.eq(this.taxRate, 0) ? 0 : this._amount * (this.taxRate/100); }
	get taxTotal() { return this._amount + this.taxAmount; }

	get buyerPercent() { return this._buyerPercent; }
	set buyerPercent(val) {
		this._buyerPercent = this.parsePercent(val);
	}

	get buyerAmount() { return this._buyerAmount; }
	set buyerAmount(val) {
		this._buyerAmount = this.parseFixed(val, 2);
	}

	get sellerPercent() { return this._sellerPercent; }
	set sellerPercent(val) {
		this._sellerPercent = this.parsePercent(val);
	}

	get sellerAmount() { return this._sellerAmount; }
	set sellerAmount(val) {
		this._sellerAmount = this.parseFixed(val, 2);
	}

	get uwSplitAmount() {
		return this._uwsplitAmount;
	}
	set uwSplitAmount(val) {
		this._uwsplitAmount = this.parseFixed(val, 2);
	}

	get overrideUnderwriter() {
		return this._overrideUnderwriter;
	}
	set overrideUnderwriter(val) {
		this._overrideUnderwriter = val;
	}

	get uwSplitPercent() {
		return this._uwsplitPercent;
	}

	balanceByAmount() {
		this._buyerAmount = this.taxTotal * (this._buyerPercent / 100);
		this.balanceByBuyerAmount();
		this._uwsplitAmount = this.taxTotal * (this._uwsplitPercent / 100);
	}

	balanceByBuyerPercent() {
		this._buyerAmount = this.getAmount(this._buyerPercent);
		this.setSellerFromBuyer();
	}

	balanceByBuyerAmount() {
		this._buyerPercent = this.getPercent(this._buyerAmount);
		this.setSellerFromBuyer();
	}

	balanceBySellerPercent() {
		this._sellerAmount = this.getAmount(this._sellerPercent);
		this.setBuyerFromSeller();
	}

	balanceBySellerAmount() {
		this._sellerPercent = this.getPercent(this._sellerAmount);
		this.setBuyerFromSeller();
	}

	setBuyerFromSeller() {
		this._buyerAmount = this.taxTotal - this._sellerAmount;
		this._buyerPercent = 100 - this._sellerPercent;
	}

	setSellerFromBuyer() {
		this._sellerAmount = this.taxTotal - this._buyerAmount;
		this._sellerPercent = 100 - this._buyerPercent;
	}

	getAmount(pct) { return this.parseFixed(this.taxTotal * (pct/100), 2); }
	getPercent(amt) { return this.parseFixed((amt / this.taxTotal) * 100, 3); }

	parseFixed(v, d) { return accounting.parse(accounting.toFixed(v, d)); }
	parseAmount(amt) {
		let val = this.parseFixed(amt, 2);
		return val > this.taxTotal ? this.taxTotal : val;
	}
	parsePercent(pct) {
		let val = this.parseFixed(pct, 3);
		return val > 100 ? 100 : val < 0 ? 0 : val;
	}
}