import { InvoicesViewActions } from "@/router/actions/order-entry";
import InvoiceMain from './InvoiceMain';
import InvoiceList from './InvoiceList';
import InvoiceDetail from './InvoiceDetail';
import { OrderNoteCategory } from "@/shared/models/enums";

const noAccessMsg = "Access to orders is not authorized.  Please contact your administrator.";

export default {
    path: "invoices",
    component: InvoiceMain,
    meta: {
        label: "Invoices",
        include: true
    },
    children: [{
            name: "o-inv:invoices",
            path: "",
            component: InvoiceList,
            meta: {
                include: false,
                entity: "Invoice",
                extension: false,
                label: "Invoices",
                addEnabled: true,
                deleteEnabled: true,
                reorderEnabled: true,
                noteCategory: OrderNoteCategory.Invoice,
                actions: InvoicesViewActions.LIST,
                accessKey: "Invoices",
                unauthorizedMessage: noAccessMsg,
                templateCategory: "oi",
                skipSaveOnLeave: true,
                disableBackToTop: true
            }
        },
        {
            name: "o-inv:invoice",
            path: "invoice/:invoiceId?",
            props: route => ({
                invoiceId: _.getNumber(route, "params.invoiceId", 0)
            }),
            component: InvoiceDetail,
            meta: {
                entity: "Invoice",
                extension: false,
                label: "Invoice",
                noteCategory: OrderNoteCategory.Invoice,
                actions: InvoicesViewActions.FORM,
                accessKey: "Invoices",
                unauthorizedMessage: noAccessMsg,
                templateCategory: "oi",
                bypassPermissionCheck: true
            }
        }
    ]
};