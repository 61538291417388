import RecordingMain from "./RecordingMain.vue";
import RecordingDocs from "./views/RecordingDocs.vue";
import { ADD_FILE_GROUP, CANCEL, SAVE, SPACER, VIEW_DOCS } from "@/router/actions/global";

const noAccessMsg = "Access to this feature is not enabled.  Please contact your administrator.";

export default {
    path: "recording",
    component: RecordingMain,
    meta: {
        label: "Recording",
        include: true,
        featureFlag: "recordingDocuments"
    },
    children: [{
            name: "o-rec:recording",
            path: "",
            component: RecordingDocs,
            meta: {
                actions: [
                    SPACER, ADD_FILE_GROUP, VIEW_DOCS,
                    SPACER, SAVE, CANCEL
                ],
                include: false,
                extension: false,
                label: "Recording",
                unauthorizedMessage: noAccessMsg,
                skipSaveOnLeave: false,
                disableBackToTop: true,
                featureFlag: "recordingDocuments"
            }
        }
    ]
};