<template>
    <div :class="classAttr" v-click-away="onClickAway">
        <div class="tag-item-container" @click.self="activateAddItem">
            <tagbox-item
                ref="tagboxItems"
                v-for="(item, index) in modelValue"
                :key="`rq-tbx-item-${index}`"
                :index="index"
                :validRegex="validRegex"
                :deleteTooltip="deleteTooltip"
                v-model="item[displayAttr]"
                @change="onChangeItem(item, $event)"
                @remove="onRemoveItem(item)"
                :disabled="disabled"
            />
            <div v-if="addActive && !disabled" class="rq-tbx-item rq-focused">
                <div class="rq-tbx-input rq-input-only rq-tbx-with-cancel">
                    <input ref="addItemInput"
                        type="text"
                        v-autowidth="{ minWidth:'100px', comfortZone: 0 }"
                        v-model="addValue"
                        maxlength="50"
                        @blur="onAddItemInputBlur"
                        @keydown="onAddItemKeyDown"
                    >
                    <div
                        v-rq-tooltip.hover
                        :title="`Cancel ${addTooltip}`"
                        class="rq-tbx-action"
                        @click="onAddItemCancel">
                        <FontAwesomeIcon icon="fas fa-times" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!disabled"
            v-rq-tooltip.hover
            :title="addTooltip"
            tabindex="0"
            class="rq-tbx-action"
            @click="activateAddItem"
            @focus="activateAddItem">
            <FontAwesomeIcon icon="fas fa-plus" />
        </div>
    </div>
</template>

<script>
    import { directive as VueInputAutowidth } from "vue-input-autowidth";
    import { mixin as VueClickAway } from "vue3-click-away";
    import TagboxItem from "./TagboxItem";
    import RegEx from "@/shared/utilities/RegEx";
    export default {
        directives: { autowidth: VueInputAutowidth },
        mixins: [ VueClickAway ],
        props: {
            modelValue: { type: Array, default: () => [] },
            displayAttr: { default: null },
            valueAttr: { default: null },
            addTooltip: { type: String, default: "Add Item" },
            deleteTooltip: { type: String, default: "Delete Item" },
            showAddButton: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        components: { TagboxItem },
        data() {
            return {
                addActive: false,
                addValue: "",
                originalItems: [],
                processAddItem: true
            }
        },
        computed: {
            validRegex() { return RegEx.PropertyTaxID; },
            classAttr() {
                return {
                    "rq-tbx": true,
                    "rq-tbx-add": this.addActive,
                    "rq-tbx-with-action": this.showAddButton,
                    "rq-tbx-disabled": this.disabled
                };
            }
        },
        methods: {

            activateAddItem() {
                if(this.addActive || this.disabled) return;
                this.addActive = true;
                this.processAddItem = true;
                this.focusAddInput();
            },

            completeAddItem(cancelled=false, inactivate=true) {
                if(!cancelled && !this.processAddItem) return false;

                this.addActive = !inactivate;
                if(_.isEmpty(_.trim(this.addValue)) || this.valueExists(this.addValue) || cancelled) {
                    this.addValue = "";
                    return false;
                }
                this.$emit("add-item", { value: this.addValue });
                this.addValue = "";
                return true;
            },

            removeItem(item) {
                if(this.disabled) return;
                let newItems = this.modelValue.slice();
                _.remove(newItems, i => i[this.valueAttr] === item[this.valueAttr]);
                this.$emit("update:modelValue", newItems);
            },

            valueExists(val) {
                return _.some(this.modelValue, item => item[this.displayAttr] === val);
            },

            reset() {
                this.addActive = false;
                this.addValue = "";
            },

            focusAddInput() {
                this.$nextTick()
                    .then(() => {
                        if(!this.$refs.addItemInput) return;
                        this.$refs.addItemInput.focus();
                    });
            },

            onClickAway() {
                if(!this.addActive || this.disabled) return;
                this.completeAddItem();
            },

            onChangeItem(item, taxIdentification) {
                if (_.isNil(item)) return;
                let editItem = _.find(this.modelValue, i => i.propertyTaxIdentificationID === item.propertyTaxIdentificationID);
                editItem.taxIdentification = taxIdentification;
                this.$emit("update:modelValue", this.modelValue);
            },

            onAddItemCancel() {
                this.processAddItem = false;
                this.completeAddItem(true);
            },

            onAddItemInputBlur() {
                //RQO-6107, removing delay, doesn't play well if you start typing an ID and then click on save, the save event fires before the delay
                // _.delay(() => {
                if(!this.addActive) return;
                this.completeAddItem();
                // }, 200);
            },

            onAddItemKeyDown(e) {
                let tabKey = e.key === "Tab";
                let enterKey = e.key === "Enter";
                let escKey = e.key === "Escape";

                if(enterKey || escKey) {
                    this.completeAddItem(escKey);
                }
                else if(tabKey) {
                    if(this.completeAddItem(false, false)) {
                        e.preventDefault();
                        this.activateAddItem();
                    }
                }
            },

            onRemoveItem(item){
                this.removeItem(item);
            }
        }
    }
</script>
