<template>
    <div class="input-group">
        <input id="policy_number"
            automation_id="txt_policy_number"
            type="text"
            placeholder="Policy Number"
            class="form-control"
            maxlength="50"
            :disabled="!allowChanges || mustGenerate"
            v-model="policyNumber"
        >
        <div class="btn-group" v-if="showCreateOptions" v-rq-tooltip.hover.top :title="allowChanges ? '' : 'Access Restricted'">
            <button class="btn btn-theme dropdown-toggle" automation_id="drp_policy_number" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!allowChanges">Assign</button>
            <ul class="dropdown-menu">
                <li>
                    <button type="button" class="dropdown-item"
                        automation_id="btn_policy_number_create"
                        @click="onAssignPolicyNumber(true)"
                        :disabled="!allowGenerate"
                        >Generate Policy Number
                    </button>
                </li>
                <li>
                    <button type="button" class="dropdown-item"
                        automation_id="btn_policy_number_manual"
                        @click="onAssignPolicyNumber(false)"
                        :disabled="!allowManual"
                        >Manual Policy Number
                    </button>
                </li>
                <li>
                    <button type="button" class="dropdown-item"
                        automation_id="btn_policy_number_pro_forma"
                        @click="onSetProForma()"
                        >PRO FORMA
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapState} from "vuex";
    import { PolicyCreation, PolicyEditing } from "@config/enums";
    import { PolicyNumberDto } from "../../models";
    import { LoanHolderType } from "../../enums";
    import EditPolicyNumber from "./EditPolicyNumber";

    export default {
        name: "PolicyNumberInput",

        props: {
            loanId: { type: Number, default: 0 },
            modelValue: { type: String, default: null },
            policyType: { type: Number, default: null, required: true },
            init: { type: Boolean, default: false, required: true },
            disabled: { type: Boolean, default: false }
        },

        data () {
            return {
                originalValue: null
            };
        },

        watch: {
            init:{
                handler: function(newValue) {
                    if(_.isEqual(newValue, true)) {
                        this.originalValue = this.modelValue;
                    } else {
                        this.originalValue = null;
                    }
                },
                immediate: true
            },
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.order.ordersID,
                orderSummary: state => state.orders.orderSummary || {}
            }),
            localSecurity() { return this.securitySettings.findValues([ "PolicyNumberEditing", "PolicyNumberCreation" ]); },
            allowChanges() { return (!this.hasPolicyNumber && this.allowCreate) || (this.hasPolicyNumber && this.allowEdit); },
            allowCreate() { return !this.disabled && !this.hasPolicyNumber && this.localSecurity.PolicyNumberCreation !== PolicyCreation.CannotGeneratePolicyNumbers; },
            allowEdit() { return !this.disabled && this.hasPolicyNumber && this.localSecurity.PolicyNumberEditing !== PolicyEditing.CannotEdit; },
            allowGenerate() { return !this.disabled && this.allowChanges && (this.mustGenerate || this.localSecurity.PolicyNumberCreation === PolicyCreation.NoRestriction); },
            allowManual() { return !this.disabled && this.allowChanges && (this.localSecurity.PolicyNumberCreation === PolicyCreation.CanManuallyEnterValidated || this.localSecurity.PolicyNumberCreation === PolicyCreation.NoRestriction) && !this.mustGenerate; },
            mustGenerate() { return this.localSecurity.PolicyNumberCreation === PolicyCreation.MustGeneratePolicyNumber; },
            showCreateOptions () { return _.parseNumber(this.orderSummary.underwriterID, 0) > 0; },
            hasPolicyNumber() { return !_.isNullOrEmpty(this.originalValue); },
            policyNumber: {
                get() { return this.modelValue; },
                set(val) { this.$emit("update:modelValue", val); }
            },
            policyTypeName() { return this.policyType === LoanHolderType.Owner ? "Owner" : "Loan"; }
        },
        methods: {
            launchPolicyNumberDialog(pools, autoGenerate) {
                const self = this;
                if(self.disabled) return;
                let title = autoGenerate
                    ? `Generate ${self.policyTypeName} Policy Number`
                    : `Manual ${self.policyTypeName} Policy Number`;
                self.$dialog.open({
                    title,
                    width: 750,
                    resizable: false,
                    adaptive: true,
                    component: EditPolicyNumber,
                    props: {
                        loanId: self.loanId,
                        policyEditType: self.policyType,
                        pools,
                        autoGenerate
                    },
                    onOk (e) {
                        return e.component.save()
                            .then(result => {
                                if(!result) return false;
                                self.$emit("assign", e.component.currentPolicyNumber);
                                return true;
                            });
                    }
                });
            },

            onAssignPolicyNumber(autoGenerate=false) {
                const self = this;
                if(self.disabled) return;
                if (!self.allowChanges) return;
                let apiPromise = self.$api.CommitmentsApi.getPolicyNumberRanges(self.orderSummary.regionID, self.orderSummary.underwriterID, self.policyType);
                self.$rqBusy.wait(apiPromise, true)
                    .then(response => {
                        let pools = _.map(response, i => new PolicyNumberDto(i));
                        if (autoGenerate && pools.length === 1) {
                            let pool = pools[0];
                            self.policyNumber = pool.minPolicyNumber;
                            let updatePolicyNumberArgs = {
                                orderId: self.orderId,
                                policyType: self.policyType,
                                regionId: self.orderSummary.regionID,
                                underWriterId: self.orderSummary.underwriterID,
                                loanId: self.loanId,
                                poolId: pool.poolId,
                                policyNumber: pool.minPolicyNumber
                            };

                            let updatePromise = self.$api.CommitmentsApi.updatePolicyNumber(updatePolicyNumberArgs);
                            self.$rqBusy.wait(updatePromise)
                                .then(response => {
                                    self.onSave(self.policyNumber);
                                    if (response.code !== 0) return true;
                                    self.errorMessage = response.message;
                                    self.poolNumber = _.getNumber(self.currentPool, "startNumber", 0);
                                    //self.updatePolicyNumber(response.poolId, response.policyNumber);
                                    return false;
                                })
                                .catch(error => {
                                    console.error(error);
                                    return true;
                                });
                        }
                        else {
                            self.launchPolicyNumberDialog(pools, autoGenerate);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        self.$toast.error("Error loading policy data");
                    });
            },

            onSetProForma() {
                this.policyNumber = "PRO FORMA";
            },

            onSave(policyNumber, resetPolicy=false) {
                var self = this;
                if (resetPolicy) {
                    self.originalValue = null;
                } else if (!self.hasPolicyNumber) {
                    self.originalValue = policyNumber;
                }
            }
        }
    };
</script>
