<template>
    <rq-page-section title="Total Premium" header-class="pb-0" collapsible>
        <rq-scroll-container ps-class-attr="premium-scroll-area" perfect-scrollbar ps-default-x>
            <table class="rq-table no-border total-premium">
                <tbody>
                    <tr>
                        <th class="rq-col-selection">&nbsp;</th>
                        <th class="col-description">&nbsp;</th>
                        <th class="col-payee">&nbsp;</th>
                        <th class="col-breakout" v-if="hasReissueCredit || hasEndorsements">&nbsp;</th>
                        <template v-if="!premium.noTax">
                            <td class="col-amount">&nbsp;</td>
                            <td class="col-percent">&nbsp;</td>
                            <td class="col-amount">&nbsp;</td>
                        </template>
                        <th class="col-amount">Total</th>
                        <th class="col-percent">&nbsp;</th>
                        <th class="col-amount">Buyer</th>
                        <th class="col-percent">&nbsp;</th>
                        <th class="col-amount">Seller</th>
                        <th class="col-percent"></th>
                        <th class="col-amount">Underwriter</th>
                        <th class="col-cdf-section">&nbsp;</th>
                        <th class="col-amount">&nbsp;</th>
                        <th class="col-poc-who">&nbsp;</th>
                        <th class="col-net-fund">&nbsp;</th>
                        <th class="col-addition-remit-date">&nbsp;</th>
                    </tr>
                    <tr v-for="(totals, index) in totalRows" :key="index" :class="{ 'no-alternate':true, 'grand-total': index===2 }">
                        <td class="rq-col-selection">&nbsp;</td>
                        <td class="col-description"><label>{{totals.description}}</label></td>
                        <td class="col-payee">&nbsp;</td>
                        <td class="col-breakout" v-if="hasReissueCredit || hasEndorsements">&nbsp;</td>
                        <template v-if="!premium.noTax">
                            <td class="col-amount">&nbsp;</td>
                            <td class="col-percent">&nbsp;</td>
                            <td class="col-amount">&nbsp;</td>
                        </template>
                        <td class="col-amount">{{formatMoney(totals.amount)}}</td>
                        <td class="col-percent">&nbsp;</td>
                        <td class="col-amount">{{formatMoney(totals.buyer)}}</td>
                        <td class="col-percent">&nbsp;</td>
                        <td class="col-amount">{{formatMoney(totals.seller)}}</td>
                        <td class="col-percent">&nbsp;</td>
                        <td class="col-amount">{{formatMoney(totals.uwSplit)}}</td>
                        <td class="col-cdf-section">&nbsp;</td>
                        <template v-if="totals.isGrandTotal">
                            <td class="col-total-label text-right">Agent Total:</td>
                            <td class="col-total-value" colspan="2">{{formatMoney(agentTotal)}}</td>
                        </template>
                        <template v-else>
                            <td class="col-amount">&nbsp;</td>
                            <td class="col-poc-who">&nbsp;</td>
                            <td class="col-net-fund">&nbsp;</td>
                        </template>
                        <td class="col-addition-remit-date">&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </rq-scroll-container>
    </rq-page-section>
</template>
<script>
    import { mapState } from "vuex";
    import { BreakoutEndorsementOption, EndorsementYesNo } from "@settlement/models/enums";
    import { EndorsementPolicy } from "@settlement/models";
    export default {
        props: {
            premium: { type: Object, default: () => ({}) }
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                rateOrderInfo: state => state.rateEngine.rateOrderInfo,
                storedEndorsements: state => state.rateEngine.endorsements
            }),
            premiumEndorsements() { return _.filter(this.storedEndorsements, item => item.orderRateCalculationID === this.premium.orderRateCalculationID); },
            hasPremiumEndorsements() { return this.premiumEndorsements.length > 0; },
            endorsements() { return _.filter(this.premiumEndorsements, item => item.endorsementPolicyTypeID !== EndorsementPolicy.Fee && item.showOutsideOfPremiums === EndorsementYesNo.No)},
            feeOtherEndorsements() { return _.filter(this.premiumEndorsements, item => item.endorsementPolicyTypeID === EndorsementPolicy.Fee || item.showOutsideOfPremiums === EndorsementYesNo.Yes)},
            hasFeeOtherEndorsements() { return this.feeOtherEndorsements.length > 0; },
            hasEndorsements() { return this.endorsements.length > 0; },
            hasReissueCredit() { return !this.premium.noRic; },
            premiumTotal() { return this.premium.taxTotal + this.premium.creditTotal; /*+ this.premium.taxTotalAssociated;*/ },
            feeOtherTotal() {
                return this.feeOtherBuyerTotal + this.feeOtherSellerTotal;
            },
            feeOtherBuyerTotal() {
                return this.hasFeeOtherEndorsements
                    ? _.sumBy(this.feeOtherEndorsements, (e) => e.buyerToPay)
                    : 0;
            },
            feeOtherSellerTotal() {
                return this.hasFeeOtherEndorsements
                    ? _.sumBy(this.feeOtherEndorsements, (e) => e.sellerToPay)
                    : 0;
            },
            feeOtherUWSplitTotal() {
                return this.hasFeeOtherEndorsements
                    ? _.sumBy(this.feeOtherEndorsements, (e) => e.overrideUnderwriterAmount)
                    : 0;
            },
            endorsementFeeTotal() {
                return this.endorsementBuyerTotal + this.endorsementSellerTotal;
            },
            endorsementBuyerTotal() {
                return this.hasEndorsements
                    ? _.sumBy(this.endorsements, (e) => e.buyerToPay)
                    : 0;
            },
            endorsementSellerTotal() {
                return this.hasEndorsements
                    ? _.sumBy(this.endorsements, (e) => e.sellerToPay)
                    : 0;
            },
            endorsementUWSplitTotal() {
                return this.hasEndorsements
                    ? _.sumBy(this.endorsements, (e) => e.overrideUnderwriterAmount)
                    : 0;
            },
            premiumEndorsementTotal() {
                return this.premiumTotal + this.endorsementFeeTotal + this.feeOtherTotal;
            },
            uwSplitTotal() {
                return this.premium.underwriterSplit + this.endorsementUWSplitTotal + this.feeOtherUWSplitTotal;
            },
            agentTotal() {
                return this.premiumEndorsementTotal - this.feeOtherTotal - this.uwSplitTotal;
            },
            totalRows() {
                let rows = [
                    {
                        description: "Premiums",
                        amount: this.premiumTotal,
                        buyer: this.premium.buyerToPay + this.premium.buyerToPay_Ric,
                        seller: this.premium.sellerToPay + this.premium.sellerToPay_Ric,
                        uwSplit: this.premium.underwriterSplit,
                        isGrandTotal: false
                    },
                    {
                        description: "Total",
                        amount: this.premiumEndorsementTotal,
                        buyer: this.premium.buyerToPay + this.premium.buyerToPay_Ric + this.endorsementBuyerTotal + this.feeOtherBuyerTotal,
                        seller: this.premium.sellerToPay + this.premium.sellerToPay_Ric + this.endorsementSellerTotal + this.feeOtherSellerTotal,
                        uwSplit: this.uwSplitTotal,
                        isGrandTotal: true
                    }
                ];

                if(this.hasEndorsements) {
                    rows.splice(1, 0, {
                        description: "Endorsements",
                        amount: this.endorsementFeeTotal,
                        buyer: this.endorsementBuyerTotal,
                        seller: this.endorsementSellerTotal,
                        uwSplit: this.endorsementUWSplitTotal,
                        isGrandTotal: false
                    });
                }

                if(this.hasFeeOtherEndorsements) {
                    rows.splice(rows.length - 1, 0, {
                        description: "Fees/Other",
                        amount: this.feeOtherTotal,
                        buyer: this.feeOtherBuyerTotal,
                        seller: this.feeOtherSellerTotal,
                        uwSplit: this.feeOtherUWSplitTotal,
                        isGrandTotal: false
                    });
                }

                return rows;
            },
        },
        methods: {
            formatMoney(v) {
                return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } });
            },
        }
    }
</script>