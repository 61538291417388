<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="Title Charges" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li v-if="isHud2010" class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly" v-focus>Add</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_disclosure_line" @click="onAddLoanCostLine(HudLineTypes.Disclosure)">Disclosure</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_general_line" @click="onAddLoanCostLine(HudLineTypes.General)">General</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_gfe4_line" @click="onAddLoanCostLine(HudLineTypes.GFE_4_Outside)">GFE #4</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_gfe5_line" @click="onAddLoanCostLine(HudLineTypes.GFE_5_Outside)">GFE #5</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li v-else class="nav-item">
                        <b-button automation_id="btn_add_title_charge"
                            ref="btn_add_title_charge"
                            variant="theme"
                            @click="onAddLoanCostLine(HudLineTypes.General)"
                            v-rq-tooltip.hover.top="{ title: `Add ${itemTypeName}` }"
                            v-focus
                            :disabled="readOnly">
                            Add
                        </b-button>
                    </li>
                    <li><button automation_id="btn_view_premiums" type="button" class="btn btn-link btn-theme" @click="onShowPremiums" v-if="localSecurity.PremiumsAccessible">View Premiums</button></li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dg_available"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="addendum-data"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            :rq-editable="!readOnly"
            @delete="onDeleteItems"
            @revert="onRevertItems"
            @netfund="onChangeNetFundItems"
            @setAddendum="onSetAddendumItem"
            @moveSection="onMoveSection"
            @editorPreparing="onEditorPreparing"
            focus-after-insert="first-row"
            hide-search
        />
    </div>
</template>

<script>
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin"
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import PremiumsMain from "../premiums/PremiumsMain";
    import { GlobalEventManager } from '@/app.events';
    import { AltaCategories, TitleChargeModel, AdditionalTitleServiceModel, LoanCostTypes, POCWhoOptions, SETTLEMENT_TYPE, SettlementTypeOption, HudLineTypes, SsGridActions } from '@settlement/models';
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { useCommonSettlementSecurity } from "@settlement/helpers";

    export default {
        name: "TitleCharges",
        mixins: [BaseSettlementMixin, GridCompanyPickerMixin],
        setup() {
            return useCommonSettlementSecurity();
        },
        data(){
            return {
                itemTypeNamePlural: "Charges" ,
                itemKey: "loanCostSectionDataID",
                itemTypeName: "Charge",
                selectionActions: [],
                items: [],
                deletedItems: [],
                movedItems: [],
                originalData: [],
                additionalTitleServices: [],
                validationErrors: [],
                titleCdfSections: [
                    { id: 'B', name: 'B' },
                    { id: 'C', name: 'C' }
                ],
                LoanCostTypes: LoanCostTypes,
                selectedLineType: HudLineTypes.General,
                cachedPremium1103BorrowerAmount: 0,
            };
        },

        computed:{
            gridInstance () { return this.$refs.dataGrid.gridInstance; },
            includeAlta() { return this.isCdf && _.parseBool(this.$store.state.orders.orderSummary.includeAlta); },
            altaCategories() { return AltaCategories.lookupItems; },
            pocWhoOptions() { return POCWhoOptions.lookupItems; },
            accountsCodes(){return this.lookupHelpers.getAllLookupItems(this.lookupItems.ACCOUNTING_CODES, this.order.regionID)},
            gridStorageKey() { return `settlement_title_charges_${this.selectedView}_${this.isWithOutSeller}_${this.includeAlta}`; },
            HudLineTypes() { return HudLineTypes; },
            localSecurity() { return { PremiumsAccessible: this.securitySettings.hasAccess("Premiums") }; },
        },

        watch:{
            selectedLoanId(newValue, oldValue){
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                this.save();
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeSSViewSelected(newValue);
                this.fetchData();
            },
            isWithOutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeWithOutSeller(newValue);
            },
        },

        created() {
            const self = this;
            self.baseInit();
            self.selectionActions = [
                {
                    name: "Delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(_.some(e.data, item => item.required === true || item.premiumType > 0 ||
                             item.paidBySource > 0 || !_.isNil(item.orderSettlementFeeID))) {
                            return "Unselect required lines to delete";
                        } else {
                            return false;
                        }
                    },
                    tooltip: `Delete ${self.itemTypeName}`
                },
                {
                    name: "Revert",
                    text: "Revert",
                    eventName: "revert",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(_.some(e.data, item => !item.canRevert || !item.isOverridableLine)) {
                            return "Unselect lines that are overridden";
                        }
                        else
                            return false;
                    },
                    tooltip: `Revert ${self.itemTypeName}`
                },
                {
                    name: "Apply-Net-Fund",
                    text: "Apply Net Fund",
                    eventName: "netfund",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if (_.some(e.data, item => item.isPremiumOrEndorsement)) return "Unselect lines that are premium";

                        if (self.isHud && _.some(e.data, item => item.isPaidByOther)) return true;

                        if (self.isHud2010) {
                            if (_.find(e.data, item => item.hudLine === 1101 || item.isDisclosureLine)) return true;

                            if (self.isWithOutSeller && _.some(e.data, item => _.includes([1105, 1106, 1107, 1108], item.hudLine))) return true;
                        }

                        if (self.isHud1974) {
                            if (_.some(e.data, item => _.includes([1109, 1110], item.hudLine))) return true;
                        }

                        return false;
                    },
                    children: [
                        SsGridActions.NETFUND_NONE,
                        SsGridActions.NETFUND_POSITIVE,
                        SsGridActions.NETFUND_NEGATIVE
                    ]
                },
                {
                    name: "Move-To-Section",
                    text: "Move to Section",
                    eventName: "moveSection",
                    requireSelection: true,
                    allowMultiSelection: true,
                    visible: self.isCdf,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if (_.some(e.data, item => item.isPremiumOrEndorsement || item.isSettlementFeeLine || item.required || item.isAddendum)) return true;

                        return false;
                    },
                    children: [
                        SsGridActions.SECTION_A,
                        SsGridActions.SECTION_B,
                        SsGridActions.SECTION_C,
                        SsGridActions.SECTION_H
                    ]
                },
                {
                    name: "Set-Addendum",
                    text: `Set as Addendum Line`,
                    eventName: "setAddendum",
                    requireSelection: true,
                    visible: function(e) {
                        if (!e || !e.data) return false;
                        let item = e.data;
                        if (_.parseBool(item.required)
                            || item.premiumType > 0
                            || item.paidBySource > 0
                            || !_.isNil(item.orderSettlementFeeID)) return false;

                        return (self.isCdf && !_.parseBool(item.isAddendum)); // isCdf and elgible addendum item
                    },
                    tooltip: "Set as Addendum Line",
                    disabled(e) {
                        let item = e.data;
                        if (self.readOnly) return true;

                        return false;
                    }
                },
                {
                    name: "Remove-Addendum",
                    text: `Remove Addendum Line`,
                    eventName: "setAddendum",
                    requireSelection: true,
                    visible: function(e) {
                        if (!e || !e.data) return false;
                        let item = e.data;
                        if (_.parseBool(item.required)
                            || item.premiumType > 0
                            || item.paidBySource > 0
                            || !_.isNil(item.orderSettlementFeeID)) return false;

                        return (self.isCdf && _.parseBool(item.isAddendum)); // isCdf and elgible addendum item
                    },
                    tooltip: "Remove Addendum Line",
                    disabled(e) {
                        let item = e.data;
                        if (self.readOnly) return true;
                        return false;
                    }
                },
            ];
            self.initGridConfig();
            self.initGridDataSource();
            self.fetchData();
        },

        methods:{
            initNewRow(e){
                e.data = {
                    hudLineType: this.selectedLineType,
                    cdfSection: "B",
                    description: ""
                };
            },
            sortItems(collection, sortBy) {
                const self = this;
                if (_.isNil(sortBy))
                    sortBy = (self.selectedView === SETTLEMENT_TYPE.CDF) ? 'cdfSection' : 'hudLine';

                return _.sortBy(collection, sortBy);
            },
            getNextAvailableHudLine() {
                const self = this;
                let hudData = self.isHud ? self.items : self.originalData;
                let hudItems = _.filter(hudData, i => i.hudLine > 1100 && i.hudLine < 1200);
                let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                let nextAvailableLineNumber = _.parseNumber(maxHudLineItem.hudLine, 0) + 1;

                // Hud premium lines are reserved so we need to increment to find the next suitable line
                while (self.isHudPremiumLine(nextAvailableLineNumber)) {
                    nextAvailableLineNumber++;

                    if (nextAvailableLineNumber >= 1200) break; // For safety measures
                }

                return nextAvailableLineNumber;
            },
            isHudPremiumLine(hudLine) {
                if (this.isHud1974)
                    return hudLine === 1108 || hudLine === 1109 || hudLine === 1110;

                if (this.isHud2010)
                    return hudLine >= 1101 && hudLine <= 1108;

                return false;
            },
            disableDescription(data) {
                let dataField = "description";
                return this.isColumnDisabled(dataField, data);
            },
            getLineDescription(rowData) {
                const self = this;

                let result = rowData.description || '';

                let loan = _.find(self.loans, l => l.loanID === rowData.loanID);

                if (!self.isHud || !_.parseBool(rowData.required, false) || !loan) // if not hud specific and not a required line then just return back current description result
                    return result;

                let salesPrice = self.order.salesPrice;
                let loanAmount = loan?.amount > 0 ? loan?.amount : 0;
                let loanPolicyLiability = loan.loanPolicyLiability;
                let ownerPolicyLiability = self.order.ownerPolicyLiability;

                let displayTitlePremium = _.formatMoney(rowData.coverageAmount2);
                let displayLoanAmount = loanPolicyLiability > 0 ? _.formatMoney(loanPolicyLiability) : _.formatMoney(loanAmount);
                let displaySalesPrice = ownerPolicyLiability > 0 ? _.formatMoney(ownerPolicyLiability) : _.formatMoney(salesPrice);

                // 2010 HUD
                if (self.isHud2010) {
                    if (rowData.hudLine === 1105) {
                        result = `${result} - ${displayLoanAmount}/${displayTitlePremium}`;
                    }
                    if (rowData.hudLine === 1106) {
                        result = `${result} - ${displaySalesPrice}/${displayTitlePremium}`;
                    }
                }

                // 1974 HUD
                if (self.isHud1974) {
                    if (rowData.hudLine === 1109) {
                        result = `${result} - ${displayLoanAmount}/${displayTitlePremium}`;
                    }
                    if (rowData.hudLine === 1110) {
                        result = `${result} - ${displaySalesPrice}/${displayTitlePremium}`;
                    }
                }

                return result;
            },
            initGridConfig() {
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyName",
                    contactIDExpr: "payeeContactID",
                    contactNameExpr: "payeeContactName",
                    showContactPicker: true,
                };

                self.gridConfig = {
                    onInitNewRow: self.initNewRow,
                    focusedRowEnabled: false,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            dataField: "category",
                            dataType: "string",
                            caption: 'Category',
                            width: 150,
                            lookup: {
                                dataSource: self.altaCategories,
                                displayExpr: "name",
                                valueExpr: "name"
                            },
                            visible: self.includeAlta,
                            showInColumnChooser: self.includeAlta,
                        },
                        {
                            dataField: "hudLine",
                            dataType: "string",
                            allowEditing: false,
                            caption: "HUD Line",
                            visible: self.isHud,
                            width: 60
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: {maxLength: 150},
                            cellTemplate(cellElement, cellInfo) {
                                cellElement.append(self.getLineDescription(cellInfo.data));
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTextBox({
                                    value: self.getLineDescription(cellInfo.data),
                                    disabled: self.disableDescription(cellInfo.data),
                                    onValueChanged: function(e) {
                                        cellInfo.data.description = e.value;
                                        let lineDescription = self.getLineDescription(cellInfo.data);
                                        cellInfo.setValue(lineDescription);
                                    },
                                }).appendTo(cellElement);
                            },
                        },
                        //from GridCompanyPickerMixin
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyName",
                                dataType: "string",
                                caption: "Payee",
                                width: "20%"
                            },
                            disabled: cellInfo => {
                                return self.isPayeeColumnDisabled(cellInfo.data);
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "borrowerOutsideValue",
                            caption: "Borrower Outside",
                            width: 90,
                            dataType: "number",
                            format: { type: "currency", precision: 2 },
                            editorOptions: {
                                format: { type: "currency", precision: 2 },
                                disabled: true
                            },
                            cellTemplate(cellElement, cellInfo) {
                                if(cellInfo.data.isBorrowerOutsideOverridden)
                                    cellElement.addClass("rq-overridden");

                                cellElement.append(accounting.formatMoney(cellInfo.text));
                            },
                            visible: self.isHud2010
                        },
                        {
                            caption: "Borrower",
                            alignment: "center",
                            columns: [
                            {
                                dataField: "borrowerAtClosingValue",
                                caption: "At Closing",
                                width: 90,
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },

                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;
                                    // May not require this - commented out for reference for now
                                    // 2010 HUD - Hide values from UI for borrowerAtClosing from user suspecting there is values here
                                    // however there really is so that we don't lose data in case user switches between CDF, 1974 HUD
                                    // if (self.isHud2010) {
                                    //     let isOverridableField = _.parseBool(_.includes([1101, 1103], cellInfo.data.hudLine), false);
                                    //     displayText = isOverridableField || cellInfo.data.isGeneralLine ? cellInfo.text : "";
                                    // }
                                    if(cellInfo.data.isBorrowerAtClosingOverridden)
                                            cellElement.addClass("rq-overridden");

                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            },
                            {
                                dataField: "borrowerBeforeClosing",
                                caption: "Before Closing",
                                width: 105,
                                dataType: "number",
                                showInColumnChooser: self.isCdf,
                                visible: self.isCdf,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            }]
                        },
                        {
                            caption: "Seller",
                            alignment: "center",
                            visible: !self.isWithOutSeller,
                            dataField: "",
                            columns: [{
                                dataField: "sellerAtClosingValue",
                                width: 90,
                                caption: "At Closing",
                                dataType: "number",
                                showInColumnChooser: !self.isWithOutSeller,
                                visible: !self.isWithOutSeller,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;
                                    if(cellInfo.data.isSellerAtClosingOverridden)
                                        cellElement.addClass("rq-overridden");

                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            },
                            {
                                dataField: "sellerBeforeClosing",
                                caption: "Before Closing",
                                dataType: "number",
                                showInColumnChooser: self.isCdf && !self.isWithOutSeller,
                                visible: self.isCdf && !self.isWithOutSeller,
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: { type: "currency", precision: 2 }
                                },
                                cellTemplate: DxGridUtils.moneyCellTemplate,
                            }]

                        },
                        {
                            dataField: "hudLineTypeDescription",
                            dataType: "string",
                            allowEditing: false,
                            caption: "Line Type",
                            visible: self.isHud2010,
                            showInColumnChooser: self.isHud2010
                        },
                        {
                            dataField: "paidByOthers",
                            caption: "Paid By Others",
                            width: 90,
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf
                        },
                        {
                            dataField: "poc",
                            dataType: "number",
                            caption: 'POC Whom',
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.pocWhoOptions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            visible: self.isHud,
                            width: 75
                        },
                        {
                            dataField: "pocAmount",
                            caption: "POC Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            visible: self.isHud,
                            width: 75
                        },
                        {
                            dataField: "netFund",
                            dataType: "number",
                            caption: 'Net Fund',
                            width: 60,
                            lookup: {
                                dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.NET_FUND),
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            dataField: "accountCodeID",
                            dataType: "number",
                            caption: 'Account Code',
                            width: 140,
                            lookup: {
                                dataSource(options) {
                                    if(_.isEmpty(options?.data)) return self.accountsCodes;
                                    let selectedValue = options?.data.accountCodeID;
                                    return _.filter(self.accountsCodes, item => !item.inactive || item.id === selectedValue);
                                },
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            dataField: "cdfSection",
                            dataType: "number",
                            caption: 'CDF Section',
                            width: 60,
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.titleCdfSections,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            visible: self.isCdf
                        },
                        {
                            dataField: "doNotPrint",
                            caption: "Do Not Print",
                            width: 100,
                            dataType: "boolean",
                            visible: self.includeAlta,
                            showInColumnChooser: self.includeAlta,
                        }
                    ],

                    storageKey: self.gridStorageKey,

                    summary:
                    {
                        totalItems: [
                            {
                                column: "description",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return "TOTALS";
                                }
                            },

                            {
                                column: "borrowerOutsideValue",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },

                            {
                                column: "borrowerAtClosingValue",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },

                            {
                                column: "borrowerBeforeClosing",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },

                            {
                                column: "sellerAtClosingValue",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },


                            {
                                column: "sellerBeforeClosing",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "paidByOthers",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                },
                                visible: self.isCdf
                            },
                            {
                                column: "pocAmount",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            }
                        ]
                    }
                };
            },
            initGridDataSource() {
                const self = this;
                self.gridDataSource = {
                    key: self.itemKey,
                    load(){
                        if(self.systemDefaults.showPremiumSplitsOnHUDCSS==false){
                        let hudItems = _.filter(self.items, i => (i.orderOutsidePartyToPayID == null));
                        return Promise.resolve(hudItems)
                        }
                        else{
                        return Promise.resolve(self.items);
                        }
                    },
                    insert(values){
                        self.onGridInsert(values);
                    },
                    update(key, values) {
                        self.onGridUpdate(key, values);
                    }
                };
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new TitleChargeModel(values);
                newItem.loanCostSectionDataID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.lineType = LoanCostTypes.AdditionalTitleCharge;
                if(!newItem.category){
                   newItem.category = newItem.description.toLowerCase().startsWith("title - ")? AltaCategories.TitleCharges : AltaCategories.OtherLoanCharges; 
                }
                newItem.required = false;

                if (_.has(values, "borrowerAtClosingValue")) {
                    newItem.borrowerAtClosingValue = values.borrowerAtClosingValue;
                }

                if(_.has(values, "sellerAtClosingValue")) {
                    newItem.sellerAtClosingValue = values.sellerAtClosingValue; // Update using Setter
                }

                if (_.has(values, "borrowerOutsideValue")) {
                    newItem.misc_Dollar = values.borrowerOutsideValue;
                }

                // We set hudLine values to push to server side versus cdf line (line) which is determined during mapping
                // let hudItems = _.filter(self.originalData, i => i.hudLine > 1100 && i.hudLine < 1200);
                // let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                // newItem.hudLine = _.parseNumber(maxHudLineItem.hudLine, 0) + 1;
                newItem.hudLine = self.getNextAvailableHudLine();

                // New lines entered within the UI application is considered common among all settlement statements so
                // set all the sections
                newItem.hudSection = 1100;
                // newItem.cdfSection = "B";

                // By setting the default to null we know that this is common among all settlement statements versus being specifically initialized to CDF / HUD
                // as they can be different based on defaulted lines
                newItem.defaultedSettlementType = null;

                // Perform updates of specific fields for common settlement lines
                if (_.has(values, "pocAmount") || _.has(values, "poc")) {
                    self.updateCdfFieldsFromHudField(newItem);
                }

                // Perform updates of specific fields for common settlement lines
                if (_.has(values, "borrowerBeforeClosing") || _.has(values, "sellerBeforeClosing")) {
                    self.updateHudFieldsFromCdfField(newItem);
                }

                self.items.push(newItem);

                if (self.isHud2010)
                    self.updateRollUpLines();

                return Promise.resolve(newItem);
                // return self.save(self.items);
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.loanCostSectionDataID === key);
                if (itemIndex < 0) return self.onGridInsert(values);

                let newUpdateItem = new TitleChargeModel((_.assign({}, self.items[itemIndex], values)));

                if(_.has(values, "borrowerAtClosingValue")) {
                    newUpdateItem.borrowerAtClosingValue = values.borrowerAtClosingValue; // Update using Setter
                }

                if(_.has(values, "borrowerOutsideValue")) {
                        newUpdateItem.overrideBorrowerOutside = values.borrowerOutsideValue;
                }

                if(_.has(values, "sellerAtClosingValue")) {
                    newUpdateItem.sellerAtClosingValue = values.sellerAtClosingValue; // Update using Setter
                }

                // Perform updates of specific fields for common settlement lines
                if (newUpdateItem.defaultedSettlementType === null) {
                    if (_.has(values, "pocAmount") || _.has(values, "poc")) {
                        self.updateCdfFieldsFromHudField(newUpdateItem);
                    }

                    if (_.has(values, "borrowerBeforeClosing") || _.has(values, "sellerBeforeClosing")) {
                        self.updateHudFieldsFromCdfField(newUpdateItem);
                    }
                }

                self.items[itemIndex] = newUpdateItem;

                if (self.isHud2010)
                    self.updateRollUpLines();

                return Promise.resolve(newUpdateItem);
            },
            updateCdfFieldsFromHudField(item) {
                // A Hud field being update here so we want to update corresponding appropriate Cdf fields on the same record to attempt to keep
                // business data in-sync between settelement types.
                // Note: These are fields that don't conflict or overlap between settlement types so we are able to adjust from UI since
                // they are hidden from one another. (borrowerAtClosing for instance can't be adjusted because all settlements commonly used that property and is handled
                // through switching settlement type on server side logic.)
                if (item.poc === 'B') {
                    item.borrowerBeforeClosing = item.pocAmount;
                }

                if (item.poc === 'S') {
                    item.sellerBeforeClosing = item.pocAmount;
                }
            },
            updateHudFieldsFromCdfField(item) {
                // A Cdf field being update here so we want to update corresponding appropriate Hud fields on the same record to attempt to keep
                // business data in-sync between settelement types.
                // Note: These are fields that don't conflict or overlap between settlement types so we are able to adjust from UI since
                // they are hidden from one another. (borrowerAtClosing for instance can't be adjusted because all settlements commonly used that property and is handled
                // through switching settlement type on server side logic.)
                if (item.poc === 'B') {
                    item.pocAmount = item.borrowerBeforeClosing;
                }

                if (item.poc === 'S') {
                    item.pocAmount = item.sellerBeforeClosing;
                }
            },
            updateRollUpLines() {
                const self = this;
                if (!self.isHud2010) return; // Roll up lines only apply to 2010 HUD

                // Line 1101 GFE4 line type
                let item1101 = _.find(self.items, item => item.hudLine === 1101 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                let item1101Index = _.findIndex(self.items, item => item.hudLine === 1101 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                if (item1101Index < 0) return;

                if (!item1101.isBorrowerAtClosingOverridden) {
                    let additionalTitleServicesTotal = _.sumBy(self.additionalTitleServices, item => item.borrowerCharges);
                    let line1101total = _.sumBy(_.filter(self.items, l => l.isGFE4OutsideLine), item => item.borrowerOutsideValue);
                    self.items[item1101Index].borrowerAtClosing = line1101total + additionalTitleServicesTotal;
                }

                // Line 1103 GFE5 line type
                let item1103 = _.find(self.items, item => item.hudLine === 1103 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                let item1103Index = _.findIndex(self.items, item => item.hudLine === 1103 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                if (item1103Index < 0) return;

                if (!item1103.isBorrowerAtClosingOverridden) {
                    let line1103total = _.sumBy(_.filter(self.items, l => l.isGFE5OutsideLine), item => item.borrowerOutsideValue);
                    self.items[item1103Index].borrowerAtClosing = self.cachedPremium1103BorrowerAmount + line1103total;
                }
            },
            onChangeWithOutSeller(e) {
                const self = this;
                self.initGridConfig();
                self.$nextTick().then(() => {
                    _.invoke(self, "$refs.dataGrid.loadGrid");
                });
            },
            onChangeSSViewSelected(e) {
                const self = this;
                // Following will set the proper columns per settlement view context
                let allowCdfOptions = _.parseBool(e === SETTLEMENT_TYPE.CDF);
                let allowHudOptions = !allowCdfOptions;

                // Operations that might occur when you switch view context (CDF, HUD)
                // Update and filter data in respect to the view we are changing to below
                self.items.length = 0; // reset items
                let titleChargesForSettlementType = _.filter(self.originalData, p => p.defaultedSettlementType === e || _.isNil(p.defaultedSettlementType));
                let viewItems = _.filter(titleChargesForSettlementType, function(r) {
                    return (e === SETTLEMENT_TYPE.CDF) ? (r.cdfSection === "B" || r.cdfSection === "C") : (r.hudSection === 1100);
                });
                let sortBy = (allowCdfOptions) ? 'cdfSection' : 'hudLine';
                self.items = self.sortItems(_.map(viewItems, result => new TitleChargeModel(result)), sortBy); // title charges related to the context of CDF or HUD

                // CDF fields
                // self.gridInstance.columnOption("cdfSection", "visible", self.allowCdfOptions);
                // self.gridInstance.columnOption("cdfSection", "showInColumnChooser", self.allowCdfOptions);

                // self.gridInstance.columnOption("borrowerBeforeClosing", "visible", allowCdfOptions);
                // self.gridInstance.columnOption("borrowerBeforeClosing", "showInColumnChooser", allowCdfOptions);

                // self.gridInstance.columnOption("sellerBeforeClosing", "visible", allowCdfOptions);
                // self.gridInstance.columnOption("sellerBeforeClosing", "showInColumnChooser", allowCdfOptions);

                // self.gridInstance.columnOption("paidByOthers", "visible", allowCdfOptions);
                // self.gridInstance.columnOption("paidByOthers", "showInColumnChooser", allowCdfOptions);

                // HUD fields
                // self.gridInstance.columnOption("poc", "visible", allowHudOptions);
                // self.gridInstance.columnOption("poc", "showInColumnChooser", allowHudOptions);

                // self.gridInstance.columnOption("pocAmount", "visible", allowHudOptions);
                // self.gridInstance.columnOption("pocAmount", "showInColumnChooser", allowHudOptions);

                // self.gridInstance.columnOption("hudLine", "visible", allowHudOptions);
                // self.gridInstance.columnOption("hudLine", "showInColumnChooser", allowHudOptions);

                // self.gridRefresh();

                self.initGridConfig();
                self.$nextTick().then(() => {
                    _.invoke(self, "$refs.dataGrid.loadGrid");
                });
            },

            gridRefresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            onEditorPreparing(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;

                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },
            isPayeeColumnDisabled(lineData) {
                const self = this;

                if (this.readOnly) {
                    return true;
                }

                if (lineData.isSettlementFeeLine) return true;

                if (lineData.isPremiumOrEndorsement) return true;

                if (self.isHud1974) {
                    if (lineData.isPaidByOther) return true;
                    if (_.includes([1109, 1110], lineData.hudLine)) return true;
                }

                if (self.isHud2010) {
                    if (lineData.isPaidByOther) return true;
                    if (lineData.hudLineType === HudLineTypes.Disclosure) return true;
                    if (_.includes([1105, 1106, 1108], lineData.hudLine)) return true;
                }

                return false;
            },
            isColumnDisabled(dataField, data) {
                if (this.readOnly) {
                    return true;
                }

                // Alta columns are editable
                if (dataField === "category" || dataField === "doNotPrint")
                    return false;

                // Check account code configuration permission
                // If onlySysAdminCanEditAccountCode is set and if default line, only System Admin allowed permission to edit account code
                if (dataField === "accountCodeID" && !_.isNil(data.defaultedSettlementType) && !this.allowEditAccountCode)
                    return true;

                // CDF
                if (this.isCdf)
                    return this.isCdfColumnDisabled(dataField, data);

                // 1974 HUD
                if (this.isHud1974)
                    return this.is1974HudColumnDisabled(dataField, data);

                // 2010 HUD has additional columns that disable that differs from CDF, HUD 1974
                if (this.isHud2010)
                    return this.is2010HudColumnDisabled(dataField, data);

                return false;
            },
            isCdfColumnDisabled(dataField, data) {
                if (data.isPremiumOrEndorsement || data.isSettlementFeeLine) {
                    switch (dataField) {
                        case 'accountCodeID':
                        case 'borrowerBeforeClosing' :
                        case 'borrowerAtClosingValue':
                        case 'sellerBeforeClosing' :
                        case 'sellerAtClosingValue':
                            return false;
                        default:
                            return true;
                    }
                }

                return false;
            },
            is1974HudColumnDisabled(dataField, data) {
                if (_.includes([1109, 1110], data.hudLine)) return true; // specific premium line not editable 1109, 1110

                // Conditions to disable certain columns
                // 1108 premium line to allow certain columns editable and overridable
                if (_.includes([1108], data.hudLine) || data.hudLineType !== HudLineTypes.Disclosure) {
                    switch (dataField) {
                        case 'accountCodeID':
                        case 'borrowerAtClosingValue':
                        case 'sellerAtClosingValue':
                            return false;
                        default:
                            break;
                    }
                }

                // Premiums should've been captured from above, below we check for endorsement and settlement fee
                return data.isPremiumOrEndorsement || data.isSettlementFeeLine || data.isPaidByOther;
            },
            is2010HudColumnDisabled(dataField, data) {
                if(data.isPaidByOther) return true;
                // Conditions to disable certain columns
                switch (dataField) {
                    case 'description':
                        return _.includes([1105, 1106, 1107, 1108], data.hudLine);
                    case 'borrowerAtClosingValue':
                        return !(_.includes([1101, 1103], data.hudLine) || data.hudLineType === HudLineTypes.General);
                    case 'borrowerOutsideValue': {
                        // Exception to disclosure rule where disclosure lines only allow description edits
                        // For lack of better line type specifically 1107 and 1108 we will allow edits/overrides for borrower outside despite being a disclosure linetype
                        if (_.includes([1107, 1108], data.hudLine))
                            return false;

                        // General disabling rules below
                        return _.includes([1101, 1103, 1105, 1106], data.hudLine) || _.includes([HudLineTypes.General, HudLineTypes.Disclosure], data.hudLineType);
                    }
                    case 'netFund': {
                        if (this.isWithOutSeller && _.includes([1105, 1106, 1107, 1108], data.hudLine))
                            return true;

                        return data.hudLine === 1101 || data.hudLineType === HudLineTypes.Disclosure;
                    }
                    case 'poc':
                    case 'pocAmount':
                        return data.hudLine === 1101 || data.hudLineType === HudLineTypes.Disclosure;
                    case 'accountCodeID': {
                        if (_.includes([1103, 1104], data.hudLine)) return false; // allow edit for premium lines
                        return data.hudLineType === HudLineTypes.Disclosure || _.includes([1105, 1106, 1107, 1108], data.hudLine);
                    }
                    case 'sellerAtClosingValue':
                        return data.hudLineType === HudLineTypes.Disclosure || _.includes([1105, 1106, 1107, 1108], data.hudLine);
                    default:
                        return false;
                }
            },

            onSave(e){
                this.gridInstance.saveEditData();
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onAddLoanCostLine(lineType) {
                const self = this;
                if(!self.gridInstance) return;
                self.selectedLineType = lineType;
                self.gridInstance.addRow();
            },

            onRevertItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    if (item.canRevert) {
                        item.revertOverrides();
                    }
                });

                // On reverting we need to take care to update borrower at closing for 2010 Hud calculated values
                if (self.isHud2010)
                    self.updateRollUpLines();

                self.gridRefresh();
            },

            onDeleteItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteItems(selectedItems);

                    // On reverting we need to take care to update borrower at closing for 2010 Hud calculated values
                    if (self.isHud2010)
                        self.updateRollUpLines();

                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected charge(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onSetAddendumItem(e) {
                const self = this;
                if (!e || !e.data) return;
                let lineItem = e.data;
                let isSetAddendum = !_.parseBool(lineItem.isAddendum); //if isAddendum is not true then we know we're setting an addendum line

                // When setting Addendum, since there can only be one addendum per section, wit's implied that the any
                // other lines (should just be one if any) that is an addendum line needs to be cleared from being one
                if (isSetAddendum) {
                    _.forEach(self.items, (item) => {
                        if (lineItem.loanCostSectionDataID === item.loanCostSectionDataID)
                            item.isAddendum = true;
                        else
                            item.isAddendum = false;
                    });
                }
                else // remove a specific addendum flag on a specific line
                    lineItem.isAddendum = !_.parseBool(lineItem.isAddendum);

                self.gridRefresh();
            },

            onChangeNetFundItems(e) {
                const self = this;
                if (!e || !e.data) return;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    item.netFund = _.getNumber(e.action, "key", 0);
                });

                self.gridRefresh();
            },

            onMoveSection(e) {
                const self = this;
                if (!e || !e.data) return;
                let targetSection = _.get(e.action, "key");

                let okHandler = function (args) {
                    let selectedKeys = _.mapValues(e.data, self.itemKey);
                    let selectedItems = _.filter(self.items, item => _.includes(selectedKeys, item.loanCostSectionDataID));
                    _.forEach(selectedItems, item => {
                        if (targetSection === "B" || targetSection === "C") {
                            item.cdfSection = targetSection;
                            self.gridRefresh();
                            return true;
                        }

                        self.updateMovedItem(item, targetSection);
                        self.movedItems.push(item);
                        self.removeItems(item.loanCostSectionDataID);
                    });
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Move",
                    `Are you sure you want to move the selected lines to Section ${targetSection}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});

                self.gridRefresh();
            },

            deleteItems(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.itemKey);
                    self.removeItems(key);

                    // Remove associated PBO line if it exists
                    if (item.paidByCompanyID > 0) {
                        let pboItem =  _.find(self.items, i => i.paidBySource === item.hudLine);
                        if (pboItem) self.removeItems(pboItem.loanCostSectionDataID);
                    }

                    if(key > 0){
                        self.deletedItems.push(item);
                    }
                });

                // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                self.reorderHudItems();
            },

            removeItems(key) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                self.gridRefresh();
            },

            updateMovedItem(item, targetSection) {
                item.cdfSection = targetSection;
                item.cdfLineID = null;
                item.line = null;
                item.hudLineID = null;
                switch (targetSection) {
                    case "A":
                        item.hudSection = 800;
                        item.hudLine = 899;     //for correct sorting on the HUD Mapper
                        item.lineType = LoanCostTypes.AdditionalLoanCharge;
                        break;
                    case "H":
                        item.hudSection = 1300;
                        item.hudLine = 1399;    //for correct sorting on the HUD Mapper
                        item.lineType = LoanCostTypes.AdditionalOtherCost;
                        break;
                }
            },

            reorderHudItems() {
                const self = this;
                let hudItems = _.filter(self.items, i => i.hudLine > 1100 && i.hudLine < 1200);

                if (hudItems.length === 0) return; // no hud items to reorder
                let minLine = _.minBy(hudItems, function(i) { return i.hudLine; });
                let maxLine = _.maxBy(hudItems, function(i) { return i.hudLine; });

                let lineNumber = _.parseNumber(minLine.hudLine, 0);
                _.forEach(_.sortBy(hudItems, h => h.hudLine), (hudItem) => {
                    if (hudItem.hudLine != lineNumber && !self.isHudPremiumLine(hudItem.hudLine)) {
                        let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === hudItem.loanCostSectionDataID);
                        self.items[itemIndex].hudLine = lineNumber;
                        hudItem.hudLine = lineNumber;
                    }
                    lineNumber = lineNumber + 1;
                });
            },

            fetchData() {
                const self = this;
                // RQO-5222
                // Fetch AdditionalTitleServices for HUD 2010 for rollup to line 1101.
                if (self.isHud2010){
                    return self.fetchSettlementData(false)
                        .then(() => {
                            self.UpdatePremiumCachedAmounts();
                            return self.fetchAdditionalTitleServicesData(true);
                        });
                }
                else {
                    return self.fetchSettlementData();
                }
            },
            UpdatePremiumCachedAmounts() {
                const self = this;
                // Line 1103 GFE5 line type
                let item1103 = _.find(self.items, item => item.hudLine === 1103 && item.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010);
                let lineGFE5Total = _.sumBy(_.filter(self.items, l => l.isGFE5OutsideLine), item => item.borrowerOutsideValue);
                self.cachedPremium1103BorrowerAmount = (item1103.borrowerAtClosing > lineGFE5Total) ? (item1103.borrowerAtClosing - lineGFE5Total) : 0; // this is the true premium value from calculator
            },

            mapData(items, type=null) {
                let ssType = _.isNil(type) ? this.selectedView : type;
                let isCdf = ssType === SETTLEMENT_TYPE.CDF;
                let sortBy = isCdf ? "cdfSection" : "hudLine";
                let viewItems = _.filter(items, item =>
                    (item.defaultedSettlementType === ssType || _.isNil(item.defaultedSettlementType))
                        && (isCdf
                            ? (item.cdfSection === "B" || item.cdfSection === "C")
                            : item.hudSection === 1100)
                );
                return this.sortItems(_.map(viewItems, item => new TitleChargeModel(item)), sortBy);
            },

            fetchSettlementData(refreshData=true) {
                const self = this;
                self.deletedItems = [];
                self.movedItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getPromise = self.$api.LoanCostChargesApi.getRecordsByLoan(self.selectedLoanId);

                return self.$rqBusy.wait(getPromise)
                    .then(results => {
                        self.items = self.mapData(results); // other charges related to the context of CDF or HUD
                        self.originalData = results; // all other charges between CDF and HUD
                        if (refreshData)
                            self.gridRefresh();
                    });
            },
            fetchAdditionalTitleServicesData(refreshData=false) {
                const self = this;
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getPromise = self.$api.AdditionalTitleServicesApi.getRecordsByLoan(self.selectedLoanId);
                return self.$rqBusy.wait(getPromise).then(result=>{
                    self.additionalTitleServices = _.map(result.items, item => new AdditionalTitleServiceModel(item));
                    self.updateRollUpLines();
                    if (refreshData)
                        self.gridRefresh();
                });
            },
            save(refreshData=true){
                const self = this;
                if (self.readOnly) return Promise.resolve(true);
                if(!self.hasChanges()){
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                let saveItems = self.items;
                if(self.movedItems.length > 0) saveItems.push.apply(saveItems, self.movedItems);

                let savePromise = self.$api.LoanCostChargesApi.saveDeleteRecords(saveItems, self.deletedItems);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Title Charges Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Title Charges");
                        console.error(err);
                    });
            },

            hasChanges(){
                const self = this;

                // Further filter out the original data for items we are viewing in CDF/HUD context for title charges
                let originalItems = self.mapData(self.originalData);

                //original data was empty and user added something
                if(originalItems.length === 0 && self.items.length > 0){
                    return true;
                }
                //if the arrays are different length, then there are changes.  Items have been deleted and need to save
                if(self.items.length !== originalItems.length){
                    return true;
                }
                //need to compare the two arrays for changes
                //sort by id on both list to compare properly since getAuditChanges will detect change if different order between list
                let sortedOrigItems = _.sortBy(originalItems, "loanCostSectionDataID");
                let sortedItems = _.sortBy(self.items, "loanCostSectionDataID");
                let changes = self.getAuditChanges(sortedOrigItems, sortedItems);
                return changes.length > 0;
            },
            onShowPremiums(){
                const self = this;
                self.gridInstance.saveEditData(); // auto commit grid data
                self.save().then(() => {
                    self.$dialog.open({
                        title: "Premium Rates",
                        width: "95%",
                        minWidth: 1200,
                        height: "95%",
                        minHeight: 700,
                        component: PremiumsMain,
                        props: { noTitle: true, showHeaderCalculate: true },
                        onOk: e => e.component.save(true)
                            .then(() => {
                                self.fetchData();
                                return true;
                            })
                    });
                });
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); }
        }
    }
</script>

