<template>
    <rq-page-section title="Interest Rate" collapsible>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <rq-action-form-group
                        label="Interest Rate"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="false"
                        @action-click="onRevertInterestRateOverride">
                        <div class="input-group">
                            <rqInputNumber
                                id="txt_loan_interest_rate"
                                automation_id="txt_loan_interest_rate"
                                formatType="money"
                                defaultValue="0"
                                :minValue="0"
                                decimals="3"
                                input-group
                                no-prefix
                                v-focus-select-all
                                @blur="onEditLoanInterestRate"
                                v-model="loanTerm.loanInterestRate"
                            />
                            <span class="input-group-text">%</span>
                        </div>
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="txt_max_rate">Maximum Rate</label>
                    <div class="input-group">
                        <rq-input-number
                            id="txt_max_rate"
                            automation_id="txt_max_rate"
                            suffix="%"
                            defaultValue="0"
                            minValue="0"
                            maxValue="100"
                            decimals="4"
                            value-event="input"
                            v-focus-select-all
                            v-model="loanTerm.monthlyPrincipalInterestHigh"
                            :disabled="!loanTerm.isInterestRateIncrease"
                        />
                        <span class="input-group-text">%</span>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="dtp_first_change_date">First Change Date</label>
                    <div class="input-group">
                        <rqdx-date-box
                            id="dtp_first_change_date"
                            :config="{
                                showClearButton: true,
                                displayFormat: 'MM/dd/yyyy',
                                useMaskBehavior: true,
                            }"
                            :disabled="!loanTerm.isInterestRateIncrease"
                            v-model="loanTerm.hudFirstInterestRateChangeDate"
                        />
                    </div>
                </div>
                <div class="col col-6 col-sm-12 col-md-12 col-lg-3 form-group">
                    <label class="form-control-label" for="txt_frequency_of_changes">Frequency of changes</label>
                    <div class="input-group">
                        <!-- <rq-input-number
                            id="txt_frequency_of_changes"
                            automation_id="txt_frequency_of_changes"
                            defaultValue="0"
                            minValue="0"
                            value-event="input"
                            v-focus-select-all
                            :disabled="!loanTerm.isInterestRateIncrease"
                            v-model="loanTerm.hudInterestRateChangePeriod"
                        /> -->
                        <input
                            automation_id="txt_frequency_of_changes"
                            type="text"
                            class="form-control"
                            maxlength="150"
                            :disabled="!loanTerm.isInterestRateIncrease"
                            v-model="loanTerm.hudInterestRateChangePeriod"
                        />
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="dtp_change_date">Change Date</label>
                    <div class="input-group">
                        <rqdx-date-box
                            id="dtp_change_date"
                            :config="{
                                showClearButton: true,
                                displayFormat: 'MM/dd/yyyy',
                                useMaskBehavior: true,
                            }"
                            :disabled="!loanTerm.isInterestRateIncrease"
                            v-model="loanTerm.hudWhenInterestRateCanChange"
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="txt_periodic_rate_cap">Periodic Rate Cap</label>
                    <div class="input-group">
                        <rq-input-number
                            id="txt_periodic_rate_cap"
                            automation_id="txt_periodic_rate_cap"
                            suffix="%"
                            defaultValue="0"
                            minValue="0"
                            maxValue="100"
                            decimals="4"
                            value-event="input"
                            v-focus-select-all
                            :disabled="!loanTerm.isInterestRateIncrease"
                            v-model="loanTerm.hudInterestRateChangeAmount"
                        />
                        <span class="input-group-text">%</span>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="txt_rate_floor">Rate Floor</label>
                    <div class="input-group">
                        <rq-input-number
                            id="txt_rate_floor"
                            automation_id="txt_rate_floor"
                            suffix="%"
                            defaultValue="0"
                            minValue="0"
                            maxValue="100"
                            decimals="4"
                            value-event="input"
                            v-focus-select-all
                            :disabled="!loanTerm.isInterestRateIncrease"
                            v-model="loanTerm.hudInterestRateLowestAmount"
                        />
                        <span class="input-group-text">%</span>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="txt_life_of_loan_rate_cap">Life of Loan Rate Cap</label>
                    <div class="input-group">
                        <rq-input-number
                            id="txt_life_of_loan_rate_cap"
                            automation_id="txt_life_of_loan_rate_cap"
                            suffix="%"
                            defaultValue="0"
                            minValue="0"
                            maxValue="100"
                            decimals="4"
                            value-event="input"
                            v-focus-select-all
                            :disabled="!loanTerm.isInterestRateIncrease"
                            v-model="loanTerm.hudInterestRateHighestAmount"
                        />
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <label for="txt_increase_amount">Can Interest Rate Increase?</label>
                    <rq-radio-group 
                        v-model="loanTerm.isInterestRateIncrease"
                        inline
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
        </fieldset>
    </rq-page-section>
</template>
<script>
import { LoanTermSectionHud2010DataModel } from '@settlement/models';
export default {
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        loanTerm: {
            type: Object,
            default: () => {
                return {}
            }
        },
        originalLoanTerm: {
            type: Object,
            default: () => {
                return new LoanTermSectionHud2010DataModel()
            }
        },
        loan: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    watch: {
        // "loanTerm.loanInterestRate": {
        //     immediate: false,
        //     handler: function(newVal, oldVal) {
        //         if(oldVal === undefined || newVal === oldVal) return;
        //         this.loanTerm.hudInitialInterestRateOverride = this.loanTerm.loanInterestRate !== this.loan.interestRate;
        //     }
        // }
    },
    methods: {
        onRevertInterestRateOverride() {
            this.loanTerm.loanInterestRate = this.loan.interestRate;
            this.loanTerm.hudInitialInterestRateOverride = false;
        },
        onEditLoanInterestRate(e) {
            const self = this;
            let newLoanInterestRate = e.value;
            let origLoanInterestRate = self.loan.interestRate;

            let handleOk = function(e) {
                return true;
            }

            let handleCancel = function(e) {
                self.loanTerm.loanInterestRate = origLoanInterestRate;
                return true;
            }

            // values match so no need to prompt with confirmation dialog
            if (newLoanInterestRate !== origLoanInterestRate) {
                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Interest Rate for loan #${self.loan.loanOrder} for this file.`,
                    handleOk, // handleOk
                    handleCancel, // handleCancel
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            }
        }
    }
}
</script>