<template>
    <tr>
        <td class="seller-name">{{item.seller}}</td>
        <td class="allocation-pct">
            <div class="input-group">
                <rqInputNumber v-model="item.percent" defaultValue="0" decimals="8" cssClass="form-control" automation_id="txt_allocation_pct" :readonly="!canEdit"></rqInputNumber>
                <span class="input-group-text">%</span>
            </div>
        </td>
        <td class="allocation-amt">
            <div class="input-group">
                <span class="input-group-text">$</span>
                <rqInputNumber v-model="item.amount" defaultValue="0" decimals="2" cssClass="form-control" automation_id="txt_allocation_amt" :readonly="!canEdit"></rqInputNumber>
            </div>
        </td>
        <td class="reamainder">
            <button automation_id="btn_apply_remainder" type="button" class="btn btn-link btn-theme" @click="onApplyRemainder" v-if="showApplyRemainder">Apply Remainder</button>
        </td>
    </tr>
</template>
<script>

    export default {

        props:{
            item: { type:Object, default:() => ({}) },
            canEdit: { type:Boolean, default:false },
            showApplyRemainder: { type:Boolean, default:false },
            balanceRemaining: { type: Number, default: 0 }
        },

        watch:{
            "item.amount":function(newVal, oldVal){
                const self = this;
                let pct = (newVal/self.item.salesPrice) * 100;
                if(self.item.percent !== pct){
                    self.item.percent = pct;
                    self.$emit("overrideChanged", self.item);
                }
            },
            "item.percent":function(newVal, oldVal){
                const self = this;
                let amt = self.item.salesPrice * (newVal/100);
                if(self.item.amount !== amt){
                    self.item.amount = amt;
                    self.$emit("overrideChanged", self.item);
                }
            }
        },

        methods:{
            onApplyRemainder(){
                const self = this;
                self.item.amount += self.balanceRemaining;
            }
        }
    }
</script>
