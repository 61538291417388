<template>
    <div :class="classAttr">
        <rq-grid-action-bar
            class="rq-table-selection"
            :actions="actionList"
            :selected-items="rqtableMxn_selectedItems"
            :read-only="readOnly"
            @action-click="emitActionEvent"
        />
        <rq-scroll-container
            ref="scrollContainer"
            ps-class-attr="premium-scroll-area"
            perfect-scrollbar
            ps-default-x
            always-visible
            always-wide>
            <table class="rq-table rq-table-bordered rq-table-endorsements">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th v-if="!noTax" colspan="3" class="rq-col-bottom-border">Tax</th>
                        <th colspan="2" class="rq-col-bottom-border">Buyer Pays</th>
                        <th colspan="2" class="rq-col-bottom-border">Seller Pays</th>
                        <th colspan="2" class="rq-col-bottom-border">UW Split</th>
                        <th v-if="isCdf"></th>
                        <th v-if="is3PageHud"></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th class="rq-col-selection">
                            <rq-select-all-toggle
                                :all-selected="allSelected"
                                :some-selected="someSelected"
                                @toggle="onSelectAllToggle"
                            />
                        </th>
                        <th class="col-description">Description</th>
                        <th class="col-payee">Payee</th>
                        <th class="col-breakout">Breakout</th>
                        <th class="col-amount">Amount</th>
                        <template v-if="!noTax">
                            <th class="col-percent">Rate</th>
                            <th class="col-amount">Amount</th>
                            <th class="col-amount">Total</th>
                        </template>
                        <th class="col-percent">%</th>
                        <th class="col-amount">Amount</th>
                        <th class="col-percent">%</th>
                        <th class="col-amount">Amount</th>
                        <th class="col-percent">%</th>
                        <th class="col-amount">Amount</th>
                        <th v-if="isCdf" class="col-cdf-section">CDF Section</th>
                        <th v-if="is3PageHud" class="col-cdf-section">Included on GFE</th>
                        <th class="col-amount">POC Amount</th>
                        <th class="col-poc-who">POC Whom</th>
                        <th class="col-net-fund">Net Fund</th>
                        <th class="col-addition-remit-date">Additional Remit Date</th>
                    </tr>
                </thead>
                <tbody>
                    <EndorsementRow v-for="item in endorsements"
                        ref="endorsementsRowsRef"
                        :key="item.orderEndorsementID"
                        :orderEndorsement="item"
                        :premium="premium"
                        :readOnly="readOnly"
                        :noTax="noTax"
                        @item-selected="rqtableMxn_OnItemSelected"
                        @change="onValueChange"
                        @reverted="onOverrideReverted"
                        @delete="$emit('delete', $event)"
                    />
                </tbody>
                <EndorsementTotals
                    :totals="endorsementTotals"
                    :no-tax="noTax"
                />
            </table>
        </rq-scroll-container>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import EndorsementRow from "./EndorsementRow";
    import EndorsementTotals from "./EndorsementTotals";
    import OrderEndorsement from "@settlement/models/OrderEndorsement";
    import { BreakoutEndorsementOption, EndorsementPolicy, SettlementTypeOption } from '@settlement/models';
    import GridAction from "@/shared/models/GridAction";
    import rqTableMixins from "@/shared/mixins/rq-tableMixins";
    import RqSelectAllToggle from "@/shared/components/rq/RqSelectAllToggle";

    const ENDORSEMENT_TABLE_ACTION = {
        DELETE: "delete",
        REVERT: "revert",
        SAVE_ENDORSEMENT: "save"
    }

    export default {
        mixins: [rqTableMixins],
        components:{
            EndorsementRow,
            EndorsementTotals,
            RqSelectAllToggle
        },
        props: {
            premium: { default: () => {} },
            noTax: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false }
        },
        data() {
            return {
                endorsements: [],
                overridenEndorsements: [],
                totalsExpanded: false
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                rateOrderInfo: state => state.rateEngine.rateOrderInfo,
                storedEndorsements: state => state.rateEngine.endorsements,
                settlementStatementType: state => state.orders.orderSummary.settlementStatementType
            }),
            orderRateCalculationId() { return _.get(this, "premium.orderRateCalculationID", 0); },
            is3PageHud() { return this.settlementStatementType === SettlementTypeOption.HUD_2010; },
            isCdf() { return this.settlementStatementType === SettlementTypeOption.CDF; },
            endorsementTotals() { return this.calculateTotals(); },
            selectionActions() {
                const self = this;
                return [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: "Delete Endorsements",
                        disabled(e) {
                            if (self.readOnly) return true;
                        }
                    },
                    {
                        name: "revert",
                        text: "Revert",
                        eventName: "revert",
                        allowMultiSelection: false,
                        tooltip: "Revert Overrides",
                        disabled(e) {
                            if (self.readOnly) return true;

                            let selectedItems = _.get(e, "data", null) || [];
                            return !_.some(selectedItems, x => x.overridden === true);
                        }
                    }
                ];
            },
            classAttr() {
                return {
                    "rq-table-container": true,
                    "rq-grid-multi-select": true,
                    [`theme-${ _.get(this, "$route.meta.theme", "default")}`]: true
                };
            },
            allSelected() { return _.every(this.endorsements, line => line.isSelected); },
            noneSelected() { return _.every(this.endorsements, line => !line.isSelected); },
            someSelected() { return !this.allSelected && !this.noneSelected; }
        },
        watch: {
            storedEndorsements: {
                handler(val) {
                    this.rqtableMxn_ClearSelection();
                    this.mapStoredEndorsements();
                },
                immediate: true
            }
        },
        created() {
            this.breakoutEndorsementOptions = BreakoutEndorsementOption.lookupItems;
            this.endorsementTypes = EndorsementPolicy.lookupItems;
            this.initializeNonReactiveVariables();
            this.initializeTable();
        },
        mounted() {
            this.$nextTick(() => {
                _.invoke(this, "$refs.scrollContainer.update");
            });
        },
        methods:{
            initializeNonReactiveVariables() {
                this.itemKey = "orderEndorsementID";
            },
            onValueChange(e) {
                this.calculateTotals();
                this.$emit("changed", e);
            },
            revertOverrides() {
                if(this.$refs.endorsementsRowsRef) {
                    let items = _.filter(this.rqtableMxn_selectedItems, x => x.overridden === true);

                    let selectedComponents = _.intersectionWith(this.$refs.endorsementsRowsRef, items, (x, y) => x.orderEndorsement.orderEndorsementID === y.orderEndorsementID);
                    selectedComponents.forEach(x => {
                        x.revertOverrides();
                    });
                    this.rqtableMxn_ClearSelection();
                    this.clearSelection();
                }
            },
            onOverrideReverted() {
                this.calculateTotals();
                this.$emit("reverted");
            },
            mapStoredEndorsements() {
                const self = this;
                if(_.isEmpty(self.storedEndorsements)) {
                    self.endorsements = [];
                    return;
                }

                let premiumEndorsements = _.filter(self.storedEndorsements, item => item.orderRateCalculationID === self.premium.orderRateCalculationID);
                self.mapEndorsements(premiumEndorsements);
            },
            mapEndorsements(items) {
                const self = this;
                self.endorsements = _.map(items, item => {
                    let result = new OrderEndorsement(item);
                    result.isLoanHolder = self.premium.isLoanHolder;
                    return result;
                });
            },
            calculateTotals() {
                let overall = { label: "Total Endorsements and Fees", tax: 0, total: 0, uwSplit: 0, buyer: 0, seller: 0 };
                let breakoutAmount = { label: "Breakout Endorsements", tax: 0, total: 0, uwSplit: 0, buyer: 0, seller: 0 };
                let combined = { label: "Combined Endorsements", tax: 0, total: 0, uwSplit: 0, buyer: 0, seller: 0 };
                let included = { label: "Included in Premium", tax: 0, total: 0, uwSplit: 0, buyer: 0, seller: 0 };
                let fees = { label: "Fees", tax: 0, total: 0, uwSplit: 0, buyer: 0, seller: 0 };

                let customizer = (v1,v2) => { return _.parseNumber(v1, 0) + _.parseNumber(v2, 0); };
                _.each(this.endorsements, oe => {
                    let currentValues = {
                        tax: oe.taxAmount,
                        total: oe.totalAmount,
                        uwSplit: oe.overrideUnderwriterAmount,
                        buyer: oe.buyerToPay,
                        seller: oe.sellerToPay
                    };
                    if (oe.endorsementPolicyTypeID === EndorsementPolicy.Fee) {
                    _.mergeWith(fees, currentValues, customizer);
                    } else {

                        let defaultBreakout = BreakoutEndorsementOption.No;
                        if (this.rateOrderInfo?.region?.isCombineEndorsements) {
                            defaultBreakout = BreakoutEndorsementOption.CombineEndorsements;
                        } else if (this.rateOrderInfo?.region?.breakoutEndorsementHUD) {
                            defaultBreakout = BreakoutEndorsementOption.Yes;
                        }

                       let breakout = oe.breakoutOverride === BreakoutEndorsementOption.UseDefault ? defaultBreakout : oe.breakoutOverride;

                        switch (breakout) {
                            case BreakoutEndorsementOption.Yes:
                                _.mergeWith(breakoutAmount, currentValues, customizer);
                                break;
                            case BreakoutEndorsementOption.No:
                                _.mergeWith(included, currentValues, customizer);
                                break;
                            case BreakoutEndorsementOption.CombineEndorsements:
                                _.mergeWith(combined, currentValues, customizer);
                                break;
                        }
                    }
                    _.mergeWith(overall, currentValues, customizer);
                });

                return [overall, breakoutAmount, combined, included, fees];
            },
            getChangedEndorsements() {
                const self = this;
                let componentData = _.map(self.endorsements, item => item.toDataObject());
                let premiumEndorsements = _.filter(self.storedEndorsements, item => item.orderRateCalculationID === self.premium.orderRateCalculationID);
                let storeData = _.map(premiumEndorsements, item => new OrderEndorsement(item).toDataObject());
                return _.filter(componentData, item => {
                    let storeItem = _.find(storeData, si => si.orderEndorsementID === item.orderEndorsementID);
                    let changes = self.getAuditChanges(storeItem, item, ["isSelected"]);
                    return _.isNil(storeItem) || _.gt(changes.length, 0);
                });
            },
            reset() {
                this.mapStoredEndorsements();
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"--" } }); },
            deleteEndorsements() {
                let self = this;
                let ids = _.map(this.rqtableMxn_selectedItems, x => x[self.itemKey]);
                this.$emit("delete", ids);
            },
            emitActionEvent(action) {
                switch(action.name) {
                    case ENDORSEMENT_TABLE_ACTION.DELETE:
                        this.deleteEndorsements();
                        break;
                    case ENDORSEMENT_TABLE_ACTION.REVERT:
                        this.revertOverrides();
                        break;
                }
            },
            initializeTable() {
                let self = this;
                this.rqtableMxn_options = {
                    itemKey: self.itemKey
                };
                let mappedActions = _.map(this.selectionActions, a => new GridAction(a));
                this.toolbarActionList = _.filter(mappedActions, a => a.isToolbarAction);
                this.toolbarSettingsList = _.filter(mappedActions, a => a.isToolbarSetting);
                this.actionList = _.filter(mappedActions, a => !a.isToolbarAction && !a.isToolbarSetting);
            },
            clearSelection() {
                this.endorsements = _.map(this.endorsements, x => {
                    x.isSelected = false;
                    return x;
                });
            },
            onSelectAllToggle(newValue) {
                _.forEach(this.endorsements, line => { line.isSelected = newValue; });
            },
            refreshScrollbar() {
                this.$nextTick(() => {
                    _.invoke(this, "$refs.scrollContainer.update");
                });
            },
        }
    };
</script>