<template>
    <div class="content-wrapper loan-disclosure-section">
        <rq-page-section title="Loan Disclosures" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rq-page-section no-header>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="rq-title mb-2">Assumption</div>
                    <div class="form-group label-lg">
                        <label>If you sell or transfer this property to another person, your lender</label>
                        <rq-radio-group
                            id="rdo_allow_assumption"
                            name="rdo_allow_assumption"
                            automation_id="rdo_allow_assumption"
                            :options="[
                                { value: true, text: 'will allow assumption.' },
                                { value: false, text: 'will not allow assumption.' }
                            ]"
                            :disabled="readOnly"
                            v-model="selectedLoanDisclosure.allowAssumption"
                            auto-focus
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="rq-title mb-2">Demand Feature</div>
                    <div class="form-group label-lg">
                        <label>Your loan</label>
                        <rq-radio-group
                            id="rdo_has_demand"
                            name="rdo_has_demand"
                            automation_id="rdo_has_demand"
                            :options="[
                                { value: true, text: 'has a demand feature.' },
                                { value: false, text: 'does not have a demand feature.' }
                            ]"
                            :disabled="readOnly"
                            v-model="selectedLoanDisclosure.hasDemandFeature"
                        />
                    </div>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section no-header>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="rq-title mb-2">Late Payment</div>
                    <div class="form-group">
                        <p class="form-sentence">If your payment is more than
                            <rqInputNumber
                                automation_id="txt_late_payment_days"
                                defaultValue="0"
                                decimals="0"
                                maxValue="9999"
                                cssClass="form-control"
                                class="small-inline-block"
                                size="sm"
                                v-model="selectedLoanDisclosure.latePaymentDays"
                                :disabled="readOnly">
                            </rqInputNumber> days late, <br /><br/>
                            your lender will charge a late fee of
                            <input
                                type="text"
                                class="form-control form-control-sm medium-inline-block"
                                v-model="selectedLoanDisclosure.latePaymentDescription"
                                maxlength="150"
                                :disabled="readOnly"
                            >
                        </p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="rq-title mb-2">Negative Amortization</div>
                    <div class="form-group label-lg">
                        <label>Under your loan terms, you </label>
                        <rq-radio-group
                            id="rdo_negative_amortization"
                            name="rdo_negative_amortization"
                            automation_id="rdo_negative_amortization"
                            :options="[
                                { value: 1, text: 'have negative amortization as a feature.' },
                                { value: 2, text: 'may have negative amortization.' },
                                { value: 0, text: 'do not have a negative amortization feature.' }
                            ]"
                            :disabled="readOnly"
                            v-model="selectedLoanDisclosure.negativeAmortization"
                        />
                    </div>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section class="pb-3" no-header>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="rq-title mb-2">Partial Payments</div>
                    <div class="form-group label-lg">
                        <label>Your lender </label>
                        <rq-radio-group
                            id="rdo_partial_payments"
                            name="rdo_partial_payments"
                            automation_id="rdo_partial_payments"
                            :options="[
                                { value: 1, text: 'may accept partial payments.' },
                                { value: 2, text: 'may hold them in a separate account.' },
                                { value: 0, text: 'does not accept any partial payments.' }
                            ]"
                            :disabled="readOnly"
                            v-model="selectedLoanDisclosure.partialPayments"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="rq-title mb-2">Security Interest</div>
                    <rq-action-form-group
                        label="You are granting security interest in"
                        action-label="Revert"
                        action-class="overridden"
                        class="label-lg"
                        :show-action="selectedLoanDisclosure.isSecurityInterestTextOverridden"
                        @action-click="onRevertSecurityInterestTextOverride">
                        <textarea
                            automation_id="txt_security_interest_text"
                            class="form-control"
                            v-model="selectedLoanDisclosure.securityInterestTextValue"
                            v-auto-resize
                            :disabled="readOnly"
                        ></textarea>
                    </rq-action-form-group>
                    <p class="instruction-text">You may lose this property if you do not make your payments or satisfy other obligations for this loan.</p>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Escrow Account" class="pb-3">
            <div class="row">
                <div class="col-12 col-lg-6 form-group label-lg">
                    <label>Your lender </label>
                    <rq-radio-group
                        id="rdo_has_escrow"
                        name="rdo_has_escrow"
                        automation_id="rdo_has_escrow"
                        @change="onEscrowPossesionChange"
                        :options="[
                            { value: true, text: 'will have an escrow account.' },
                            { value: false, text: 'will not have an escrow account.' }
                        ]"
                        :disabled="readOnly"
                        v-model="selectedLoanDisclosure.hasEscrowAccount"
                    />
                </div>
                <div class="col-12 col-lg-6 form-group label-lg" v-if="!selectedLoanDisclosure.hasEscrowAccount">
                    <label>Because </label>
                    <rq-radio-group
                        id="rdo_escrow_reason"
                        name="rdo_escrow_reason"
                        automation_id="rdo_escrow_reason"
                        :options="[
                            { value: false, text: 'you declined it.' },
                            { value: true, text: 'your lender does not offer escrow.' }
                        ]"
                        :disabled="readOnly"
                        v-model="selectedLoanDisclosure.doesNotHaveEscrowReason"
                    />
                </div>
            </div>
        </rq-page-section>

        <rq-page-section title="Escrow" class="pb-3">
            <div class="row">
                <div class="col-12 col-lg-4 col-pc-3">
                    <rq-action-form-group
                        label="Escrowed Property Costs Over Year 1"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="selectedLoanDisclosure.isEscrowAmountOverridden"
                        @action-click="onRevertEscrowOverride">
                        <rqInputNumber
                            id="txt_escrow_amount"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            value-event="input"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanDisclosure.escrowAmountValue"
                            :disabled="readOnly"
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-lg-8 col-pc-9">
                    <rq-action-form-group
                        label="Estimated total amount over year 1 for your escrowed property costs"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="selectedLoanDisclosure.isEscrowDescriptionOverridden"
                        @action-click="onRevertEscrowDescriptionOverride">
                        <textarea
                            automation_id="txt_escrow_description"
                            class="form-control"
                            v-model="selectedLoanDisclosure.escrowDescriptionValue"
                            v-auto-resize
                            :disabled="readOnly"
                        ></textarea>
                    </rq-action-form-group>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-12 col-lg-4 col-pc-3 form-group">
                    <label>Non-Escrowed Property Costs over Year 1</label>
                    <rqInputNumber id="txt_non_escrow_amount"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        value-event="input"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedLoanDisclosure.nonEscrowAmount"
                        :disabled="readOnly"/>
                </div>
                <div class="col-12 col-lg-8 col-pc-9 form-group">
                    <label>Estimated total amount for your non-escrowed property costs.</label>
                    <textarea
                        automation_id="txt_nonescrow_description"
                        class="form-control"
                        maxlength="250"
                        v-model="selectedLoanDisclosure.nonEscrowAmountDescription"
                        v-auto-resize
                        :disabled="readOnly"
                    ></textarea>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-12 col-lg-4 col-pc-3">
                    <rq-action-form-group
                        label="Initial Escrow Payment"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="selectedLoanDisclosure.isInitialEscrowPaymentOverridden"
                        @action-click="onRevertInitialEscrowPayment">
                        <rqInputNumber
                            id="txt_initial_escrow_payment"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            value-event="input"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanDisclosure.initialEscrowPaymentValue"
                            :disabled="readOnly"
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-lg-8 col-pc-9 form-group">
                    <label></label>
                    <textarea
                        automation_id="txt_initial_escrow_payment_description"
                        class="form-control"
                        maxlength="250"
                        v-model="selectedLoanDisclosure.initialEscrowPaymentDescription"
                        v-auto-resize
                        :disabled="readOnly"
                    ></textarea>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-12 col-lg-4 col-pc-3">
                    <rq-action-form-group
                        label="Monthly Escrow Payment"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="selectedLoanDisclosure.isMonthlyEscrowPaymentOverridden"
                        @action-click="onRevertMonthlyEscrowOverride">
                        <rqInputNumber
                            id="txt_monthly_escrow_payment"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            value-event="input"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedLoanDisclosure.monthlyEscrowPaymentValue"
                            :disabled="readOnly"
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-lg-8 col-pc-9 form-group">
                    <label></label>
                    <textarea
                        automation_id="txt_monthly_escrow_payment_description"
                        class="form-control"
                        maxlength="250"
                        v-model="selectedLoanDisclosure.monthlyEscrowPaymentDescription"
                        v-auto-resize
                        disabled
                    ></textarea>
                </div>
            </div>
        </rq-page-section>

        <rq-page-section title="No Escrow" class="pb-3">
            <div class="row">
                <div class="col-12 col-lg-4 col-pc-3 form-group">
                    <label>Estimated Property Costs Over Year 1</label>
                    <rqInputNumber
                        id="txt_no_escrow_amount"
                        automation_id="txt_no_escrow_amount"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedLoanDisclosure.noEscrowAmount"
                        :disabled="readOnly"
                    />
                </div>
                <div class="col-12 col-lg-8 col-pc-9 form-group">
                    <label></label>
                    <textarea
                        automation_id="txt_no_escrow_amount_description"
                        class="form-control"
                        maxlength="250"
                        v-model="selectedLoanDisclosure.noEscrowAmountDescription"
                        v-auto-resize
                        :disabled="readOnly"
                    ></textarea>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-12 col-lg-4 col-pc-3 form-group">
                    <label>Escrow Waiver Fee</label>
                    <rqInputNumber
                        id="txt_escrow_waiver_fee"
                        automation_id="txt_escrow_waiver_fee"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedLoanDisclosure.escrowWaiverFee"
                        :disabled="readOnly"
                    />
                </div>
                <div class="col-12 col-lg-8 col-pc-9 form-group">
                    <label></label>
                    <textarea
                        automation_id="txt_escrow_waiver_fee_description"
                        class="form-control"
                        maxlength="250"
                        v-model="selectedLoanDisclosure.escrowWaiverFeeDescription"
                        v-auto-resize
                        :disabled="readOnly"
                    ></textarea>
                </div>
            </div>
        </rq-page-section>

        <rq-page-section v-if="selectedLoanDisclosure.hasAP" title="Adjustable Payment (AP)">
            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">Interest Only Payments?</div>
                <div class="col col-auto pt-2 form-group label-lg">
                    <rq-radio-group
                        name="rdo_interest_only_payments"
                        :options="[
                            { automation_id: 'radio_interest_only_no', value: false, text: 'NO' },
                            { automation_id: 'radio_interest_only_yes', value: true, text: 'YES' }
                        ]"
                        :disabled="readOnly"
                        v-model="selectedLoanDisclosure.hasInterestOnlyPayments"
                    />
                </div>
                <div :class="{ 'col col-auto':true, 'invisible': !selectedLoanDisclosure.hasInterestOnlyPayments }">
                    <label class="me-2 adj-payment-text">For your first </label>
                    <rqInputNumber
                        automation_id="txt_interest_only_payment_count"
                        defaultValue="0"
                        decimals="0"
                        class="small-inline-block"
                        v-focus-select-all
                        v-model="selectedLoanDisclosure.interestOnlyPaymentCount"/>
                    <label class="ms-2 adj-payment-text">payments</label>
                </div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">Optional Payments?</div>

                <div class="col col-auto pt-2 form-group label-lg">
                    <rq-radio-group
                        name="rdo_optional_payments"
                        :options="[
                            { automation_id: 'radio_optional_payment_no', value: false, text: 'NO' },
                            { automation_id: 'radio_optional_payment_yes', value: true, text: 'YES' }
                        ]"
                        :disabled="readOnly"
                        v-model="selectedLoanDisclosure.hasOptionalPayments"
                    />
                </div>

                <div :class="{ 'col col-auto':true, 'invisible': !selectedLoanDisclosure.hasOptionalPayments }">
                    <label class="me-2 adj-payment-text">For your first </label>
                    <rqInputNumber
                        automation_id="txt_optional_payment_count"
                        defaultValue="0"
                        decimals="0"
                        cssClass="form-control"
                        class="small-inline-block"
                        v-focus-select-all
                    v-model="selectedLoanDisclosure.optionalPaymentCount"/>
                    <label class="ms-2 adj-payment-text">payments</label>
                </div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">Step Payments?</div>
                <div class="col col-auto pt-2 form-group label-lg">
                    <rq-radio-group
                        name="rdo_step_payments"
                        :options="[
                            { automation_id: 'radio_step_payment_no', value: false, text: 'NO' },
                            { automation_id: 'radio_step_payment_yes', value: true, text: 'YES' }
                        ]"
                        :disabled="readOnly"
                        v-model="selectedLoanDisclosure.hasStepPayments"
                    />
                </div>

                <div :class="{ 'col col-auto':true, 'invisible': !selectedLoanDisclosure.hasStepPayments }">
                    <label class="me-2 adj-payment-text">For your first</label>
                    <rqInputNumber
                        automation_id="txt_step_payment_count"
                        defaultValue="0"
                        decimals="0"
                        cssClass="form-control"
                        class="small-inline-block"
                        v-focus-select-all
                        v-model="selectedLoanDisclosure.stepPaymentCount"/>
                    <label class="ms-2 adj-payment-text">payments</label>
                </div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">Seasonal Payments?</div>

                <div class="col col-auto pt-2 form-group label-lg">
                    <rq-radio-group
                        name="rdo_seasonal_payments"
                        :options="[
                            { automation_id: 'radio_seasonal_payment_no', value: false, text: 'NO' },
                            { automation_id: 'radio_seasonal_payment_yes', value: true, text: 'YES' }
                        ]"
                        :disabled="readOnly"
                        v-model="selectedLoanDisclosure.hasSeasonalPayments"
                    />
                </div>

                <div :class="{ 'col col-auto':true, 'invisible': !selectedLoanDisclosure.hasSeasonalPayments }">
                    <label class="me-2 adj-payment-text">From</label>
                    <rqSelectBox
                        id="drp_seasonal_payment_from"
                        automation_id="drp_seasonal_payment_from"
                        :items="monthOptions"
                        v-model="selectedLoanDisclosure.seasonalPaymentFromMonth"
                        class="inline-select rq-placeholder-visible"/>
                    <label class="mx-2 adj-payment-text">To</label>
                    <rqSelectBox
                        id="drp_seasonal_payment_from"
                        automation_id="drp_seasonal_payment_from"
                        :items="monthOptions"
                        v-model="selectedLoanDisclosure.seasonalPaymentThroughMonth"
                        class="inline-select rq-placeholder-visible"/>
                </div>
            </div>

            <div>&nbsp;</div>
            <div class="row">
                <div class="col">
                    <h6 class="text-dark">Monthly Principal and Interest Payments</h6>
                </div>
            </div>
            <div>&nbsp;</div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">First Change/Amount</div>

                <div class="col col-auto">
                    <div class="row">
                        <div class="col col-auto pe-0">
                            <rqInputNumber
                                id="txt_first_change_min_amount"
                                automation_id="txt_first_change_min_amount"
                                defaultValue="0"
                                format-type="money"
                                decimals="2"
                                no-prefix
                                input-group
                                v-focus-select-all
                                prependIcon="fas fa-dollar-sign"
                                css-class="input-w-100"
                                v-model="selectedLoanDisclosure.firstChangeMinAmount"
                            />
                        </div>
                        <div class="col col-auto p-2">
                            <FontAwesomeIcon icon="fas fa-minus" class="text-dark" />
                        </div>
                        <div class="col-auto ps-0">
                            <rqInputNumber
                                id="txt_first_change_max_amount"
                                automation_id="txt_first_change_max_amount"
                                defaultValue="0"
                                format-type="money"
                                decimals="2"
                                no-prefix
                                input-group
                                v-focus-select-all
                                prependIcon="fas fa-dollar-sign"
                                css-class="input-w-100"
                                v-model="selectedLoanDisclosure.firstChangeMaxAmount"
                            />
                        </div>
                    </div>
                </div>

                <div class="col col-auto p-2 adj-payment-text">at</div>

                <div class="col col-auto">
                    <rqInputNumber
                        automation_id="txt_first_change_payment_number"
                        defaultValue="0"
                        decimals="0"
                        :append-text="firstChangePaymentNumberSuffix"
                        value-event="input"
                        input-group
                        css-class="input-w-50"
                        v-focus-select-all
                        v-model="selectedLoanDisclosure.firstChangePaymentNumber"
                    />
                </div>

                <div class="col col-auto p-2 adj-payment-text">payment</div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 pe-2 adj-payment-text">Subsequent Changes Every</div>
                <div class="col col-auto">
                    <rqInputNumber
                        automation_id="txt_subsequent_changes_number_of_years"
                        defaultValue="0"
                        decimals="0"
                        css-class="input-w-50"
                        v-focus-select-all
                        v-model="selectedLoanDisclosure.subsequentChangesNumberOfYears"/>
                </div>
                <div class="col col-auto pt-2 adj-payment-text">years.</div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 pe-2 adj-payment-text">Maximum Payment</div>

                <div class="col col-auto">
                    <rqInputNumber
                        id="txt_maximum_payment_amount"
                        automation_id="txt_maximum_payment_amount"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        css-class="input-w-100"
                        v-model="selectedLoanDisclosure.maximumPaymentAmount"/>
                </div>

                <div class="col col-auto p-2 adj-payment-text">at</div>

                <div class="col col-auto">
                    <rqInputNumber
                        automation_id="txt_maximum_payment_number"
                        defaultValue="0"
                        decimals="0"
                        input-group
                        :append-text="maximumPaymentNumberSuffix"
                        value-event="input"
                        css-class="input-w-50"
                        v-focus-select-all
                        v-model="selectedLoanDisclosure.maximumPaymentNumber"/>
                </div>

                <div class="col col-auto p-2 adj-payment-text">payment</div>
            </div>
        </rq-page-section>

        <rq-page-section v-if="selectedLoanDisclosure.hasAIR" title="Adjustable Interest Rate (AIR)">
            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">Margin Index</div>

                <div class="col col-auto">
                    <div class="row">
                        <div class="col col-auto pe-0">
                            <rq-select-box
                                id="drp_margin_options"
                                data-text-field="name"
                                data-value-field="id"
                                :items="interestRateOptions"
                                class="medium-inline-block"
                                v-model="selectedLoanDisclosure.marginIndex"
                            />
                        </div>
                        <div class="col-auto ps-1">
                            <rq-select-box v-if="selectedLoanDisclosure.marginIndex == 0"
                                id="drp_margin_options"
                                data-text-field="name"
                                data-value-field="id"
                                class="medium-inline-block inline-select rq-placeholder-visible"
                                :items="marginIndexOptions"
                                v-model="selectedLoanDisclosure.airIndexName"
                            />
                            <input v-if="selectedLoanDisclosure.marginIndex == 1"
                                id="txt_numof_frequency_change"
                                automation_id="txt_numof_frequency_change"
                                type="text"
                                class="form-control"
                                style="width:375px"
                                maxlength="250"
                                v-model="selectedLoanDisclosure.airIndexDescription"
                            >
                        </div>
                    </div>
                </div>

                <div class="col col-auto p-2">
                    <FontAwesomeIcon v-if="selectedLoanDisclosure.marginIndex == 0" icon="fas fa-plus" class="text-dark" />
                </div>

                <div class="col col-auto">
                    <rqInputNumber
                        v-if="selectedLoanDisclosure.marginIndex == 0"
                        v-model="selectedLoanDisclosure.airIndexPercentage"
                        defaultValue="0"
                        decimals="3"
                        minValue="0"
                        maxValue="100"
                        css-class="input-w-100"
                        input-group
                        v-focus-select-all
                        appendText="%"
                        automation_id="txt_air_index_percentage"
                    />
                </div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">Initial Interest Rate</div>
                <div class="col col-auto">
                    <div class="row">
                        <div :class="{'col col-auto pe-0 form-group':true, 'overridden': selectedLoanDisclosure.isAirInitialRateInterestOverridden}">
                            <div class="col col-auto form-group-action ms-auto">
                                <button id="btn_revert_air_initial_rate_override"
                                    automation_id="btn_revert_air_initial_rate_override"
                                    class="btn btn-sm btn-link btn-revert"
                                    @click="onRevertAirInitialInterestRateOverride">Revert</button>
                            </div>
                            <rqInputNumber
                                v-model="selectedLoanDisclosure.airInitialInterestRateValue"
                                defaultValue="0"
                                decimals="3"
                                minValue="0"
                                maxValue="100"
                                css-class="input-w-100"
                                input-group
                                appendText="%"
                                v-focus-select-all
                                automation_id="txt_air_interest_rate"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 adj-payment-text">Min/Max Interest Rate</div>

                <div class="col col-auto">
                    <div class="row">
                        <div class="col col-auto pe-0">
                            <rqInputNumber
                                v-model="selectedLoanDisclosure.airMinInterestRate"
                                defaultValue="0"
                                decimals="3"
                                minValue="0"
                                maxValue="100"
                                css-class="input-w-100"
                                input-group
                                appendText="%"
                                v-focus-select-all
                                automation_id="txt_air_index_percentage"/>
                        </div>
                        <div class="col col-auto p-2">/</div>
                        <div class="col-auto ps-1">
                            <rqInputNumber
                                v-model="selectedLoanDisclosure.airMaxInterestRate"
                                defaultValue="0"
                                decimals="3"
                                minValue="0"
                                maxValue="100"
                                css-class="input-w-100"
                                input-group
                                appendText="%"
                                v-focus-select-all
                                automation_id="txt_air_index_percentage"/>
                        </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>
            <div class="row">
                <div class="col">
                    <h6 class="text-dark">Change Frequency</h6>
                </div>
            </div>
            <div>&nbsp;</div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 pe-2 adj-payment-text">First Change</div>
                <div class="col col-auto pt-2 adj-payment-text">Beginning of</div>
                <div class="col col-auto">
                    <rqInputNumber
                        automation_id="txt_air_first_change_month_number"
                        defaultValue="0"
                        decimals="0"
                        :append-text="airFirstChangeMonthNumberSuffix"
                        value-event="input"
                        input-group
                        css-class="input-w-50"
                        v-focus-select-all
                        v-model="selectedLoanDisclosure.airFirstChangeMonthNumber"
                    />
                </div>
                <div class="col col-auto pt-2 adj-payment-text">month.</div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 pe-2 adj-payment-text">Subsequent Changes Every</div>
                <div class="col col-auto">
                    <rqInputNumber
                        automation_id="txt_air_first_change_month_number"
                        defaultValue="0"
                        decimals="0"
                        :append-text="airSubsequentChangesMonthNumberSuffix"
                        value-event="input"
                        input-group
                        css-class="input-w-50"
                        v-focus-select-all
                        v-model="selectedLoanDisclosure.airSubsequentChangesMonthNumber"
                    />
                </div>
                <div class="col col-auto pt-2 adj-payment-text">month after first change.</div>
            </div>

            <div>&nbsp;</div>
            <div class="row">
                <div class="col">
                    <h6 class="text-dark">Limits On Interest Rate Changes</h6>
                </div>
            </div>
            <div>&nbsp;</div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 pe-2 adj-payment-text">First Change</div>
                <div class="col col-auto">
                    <rqInputNumber
                        v-model="selectedLoanDisclosure.airFirstChangeLimitPct"
                        defaultValue="0"
                        decimals="3"
                        minValue="0"
                        maxValue="100"
                        input-group
                        css-class="input-w-100"
                        appendText="%"
                        v-focus-select-all
                        automation_id="txt_air_first_change_limit_percentage"/>
                </div>
            </div>

            <div class="row adj-payment-row">
                <div class="col col-auto pt-2 pe-2 adj-payment-text">Subsequent Changes</div>
                <div class="col col-auto">
                    <rqInputNumber
                    v-model="selectedLoanDisclosure.airSubsequentChangeLimitPct"
                        defaultValue="0"
                        decimals="3"
                        minValue="0"
                        maxValue="100"
                        input-group
                        css-class="input-w-100"
                        appendText="%"
                        v-focus-select-all
                        automation_id="txt_air_subsequent_changes_limit_percentage"/>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { LoanDisclosureModel, SETTLEMENT_TYPE } from "@settlement/models";
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { DateTime } from "luxon";

    export default{
        name: "LoanDisclosure",
        mixins: [BaseSettlementMixin],
        data() {
            return {
                selectedLoanDisclosure: {},
                loanDisclosures:[],
                originalLoanDisclosures:[],
                interestRateOptions: [
                    { id: 0, name: 'Index + Margin' },
                    { id: 1, name: 'Number and Frequency of Changes' }
                ],
                marginIndexOptions: [
                    { id: "Bank Prime Loan", name: "Bank Prime Loan"},
                    { id: "Certificate of Deposit Index", name: "Certificate of Deposit Index"},
                    { id: "Constant Maturity Treasury", name: "Constant Maturity Treasury"},
                    { id: "Cost of Savings Index", name: "Cost of Savings Index"},
                    { id: "Eleventh District Cost of Funds Index", name: "Eleventh District Cost of Funds Index"},
                    { id: "LIBOR", name: "LIBOR" },
                    { id: "Other", name: "Other" },
                    { id: "Treasury Bill", name: "Treasury Bill" },
                    { id: "Twelve Month Treasury Average", name: "Twelve Month Treasury Average" },
                    { id: "Wall Street Journal Prime", name: "Wall Street Journal Prime" }
                ],
            }
        },
        computed: {
            currentYear() {
                return DateTime.now().year;
            },
            monthOptions(){
                return [
                    {id:1, name: "January"},
                    {id:2, name: "February"},
                    {id:3, name: "March"},
                    {id:4, name: "April"},
                    {id:5, name: "May"},
                    {id:6, name: "June"},
                    {id:7, name: "July"},
                    {id:8, name: "August"},
                    {id:9, name: "September"},
                    {id:10, name: "October"},
                    {id:11, name: "November"},
                    {id:12, name: "December"}
                ]
            },
            firstChangePaymentNumberSuffix(){
                if(this.selectedLoanDisclosure){
                    let number = _.parseNumber(this.selectedLoanDisclosure.firstChangePaymentNumber,0);
                    return this.getNumberSuffix(number);
                }
                return "";
            },
            maximumPaymentNumberSuffix(){
                if(this.selectedLoanDisclosure){
                    let number = _.parseNumber(this.selectedLoanDisclosure.maximumPaymentNumber,0);
                    return this.getNumberSuffix(number);
                }
                return "";
            },
            airFirstChangeMonthNumberSuffix(){
                if(this.selectedLoanDisclosure){
                    let number = _.parseNumber(this.selectedLoanDisclosure.airFirstChangeMonthNumber,0);
                    return this.getNumberSuffix(number);
                }
                return "";
            },
            airSubsequentChangesMonthNumberSuffix(){
                if(this.selectedLoanDisclosure){
                    let number = _.parseNumber(this.selectedLoanDisclosure.airSubsequentChangesMonthNumber,0);
                    return this.getNumberSuffix(number);
                }
                return "";
            }

        },
        created(){
            this.baseInit();
            this.fetchData();
        },
        watch: {
            orderId(newVal, oldVal){
                if(newVal === oldVal) return;
                this.fetchData();
            },
            selectedLoanId(newVal, oldVal){
                const self = this;
                if(self.loanDisclosures.length === 0) return;
                self.selectedLoanDisclosure = _.find(self.loanDisclosures, i => i.loanID === newVal);
            }
        },
        methods: {
            onRevertAirInitialInterestRateOverride() {
                if(this.selectedLoanDisclosure)
                    this.selectedLoanDisclosure.airInitialInterestRateOverride = null;
            },
            onRevertEscrowOverride(e){
                if(this.selectedLoanDisclosure)
                    this.selectedLoanDisclosure.escrowAmountOverride = null;
            },
            onRevertInitialEscrowPayment(e){
                if(this.selectedLoanDisclosure)
                    this.selectedLoanDisclosure.initialEscrowPaymentOverride = null;
            },
            onRevertEscrowDescriptionOverride(e){
                if(this.selectedLoanDisclosure)
                    this.selectedLoanDisclosure.escrowAmountDescriptionOverride = null;
            },
            onRevertMonthlyEscrowOverride(e){
                if(this.selectedLoanDisclosure)
                    this.selectedLoanDisclosure.monthlyEscrowPaymentOverride = null;
            },
            onRevertSecurityInterestTextOverride(e) {
                if(this.selectedLoanDisclosure)
                    this.selectedLoanDisclosure.securityInterestTextOverride = null;
            },
            onEscrowPossesionChange(e){
                if(this.selectedLoanDisclosure){
                    if(e){
                        this.setMonthlyYearlyEscrowAmounts();
                    }
                    else{
                        this.resetMonthlyYearlyEscrowAmounts();
                    }
                }
            },
            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel(e) {
                this.fetchData();
            },

            save(toastIfNoChanges=false){
                const self = this;

                if(_.isNil(self.originalLoanDisclosures)) return Promise.resolve(false);
                let changes = self.getAuditChanges(self.loanDisclosures, self.originalLoanDisclosures);

                if(changes.length === 0){
                    if(toastIfNoChanges)
                        self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let promise = self.$api.LoanDisclosuresApi.save(self.loanDisclosures).then(()=>{
                    self.$toast.success("Loan Disclosures Saved.");
                    self.originalLoanDisclosures = _.cloneDeep(self.loanDisclosures);
                    GlobalEventManager.saveCompleted({success: true});
                    return true;
                }).catch(err =>{
                    self.$toast.error(err.message);
                    GlobalEventManager.saveCompleted({success: false});
                    return false;
                });

                self.$rqBusy.wait(promise);
                return promise;
            },

            fetchData(){
                const self = this;
                let promise = self.$api.LoanDisclosuresApi.getByOrderId(self.orderId);
                self.$rqBusy.wait(promise).then(results => {
                    self.loanDisclosures = _.map(results, result => new LoanDisclosureModel(result));
                    self.originalLoanDisclosures = _.cloneDeep(self.loanDisclosures);
                    self.selectedLoanDisclosure = _.find(self.loanDisclosures, i => i.loanID === self.selectedLoanId);
                    if(!self.selectedLoanDisclosure.hasEscrowAccount){
                        self.selectedLoanDisclosure.escrowAmountValue = null;
                        self.selectedLoanDisclosure.monthlyEscrowPaymentValue = null;
                        self.selectedLoanDisclosure.escrowAmountOverride = null;
                        self.selectedLoanDisclosure.monthlyEscrowPaymentOverride = null;
                    }
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });
            },

            getNumberSuffix(number){
                let lastDigit = _.parseNumber(number.toString().split('').pop(),0);

                switch(lastDigit){
                    case 1:
                        return "st";
                    case 2:
                        return "nd";
                    case 3:
                        return "rd";
                    default:
                        return "th";
                }
            },

            resetMonthlyYearlyEscrowAmounts(){
                this.selectedLoanDisclosure.escrowAmountValue = null;
                this.selectedLoanDisclosure.monthlyEscrowPaymentValue = null;
                this.selectedLoanDisclosure.escrowAmountOverride = null;
                this.selectedLoanDisclosure.monthlyEscrowPaymentOverride = null;
            },
            setMonthlyYearlyEscrowAmounts(){
                const self = this;
                let promise = self.$api.LoanDisclosuresApi.getByOrderId(self.orderId);
                self.$rqBusy.wait(promise).then(results => {
                    let objLoanDisclosures = _.map(results, result => new LoanDisclosureModel(result));
                    const loanDisclosureData = _.find(objLoanDisclosures, i => i.loanID === this.selectedLoanId);
                    this.selectedLoanDisclosure.escrowAmount = loanDisclosureData.escrowAmount;
                    this.selectedLoanDisclosure.escrowAmountOverride = loanDisclosureData.escrowAmountOverride;
                    this.selectedLoanDisclosure.monthlyEscrowPayment = loanDisclosureData.monthlyEscrowPayment;
                    this.selectedLoanDisclosure.monthlyEscrowPaymentOverride = loanDisclosureData.monthlyEscrowPaymentOverride;
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });
            }
        }
    }

</script>