export class CdfPayoffPaymentDto {
	constructor(options) {        
        options = options || {};
		this.payoffPaymentID = _.parseNumber(options.payoffPaymentID, null);
		this.payeeID = _.parseNumber(options.payeeID, null);
		this.payoffDescription = options.payoffDescription || null;
		this.amount = _.parseNumber(options.amount, 0);
		this.mainID = _.parseNumber(options.mainID, null);
		this.sequenceNumber = _.parseNumber(options.sequenceNumber, 0);
        this.payoffID = _.parseNumber(options.payoffID, null);
        
        this.loanID = _.parseNumber(options.loanID, null);
		this.companyName = options.companyName || "";        
    }
    
    get isGeneralPayoff() {
        return _.parseBool(this.payoffID > 0, false);
    }

	get payeeCompany() {
        return {
            companyID: this.payeeID,
            companyName: this.companyName,
        };
    }

    set payeeCompany(val) {
        this.payeeID = _.get(val, "companyID", null);
        this.companyName = _.get(val, "companyName", null);
	}    
}
