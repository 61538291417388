<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />

        <div class="row">
            
            <rq-radio-group
                class="mt-1"
                id="grid_grouping"
                :inline="true"
                v-model="mode"
                :options="[
                    { automation_id: 'upload_new', value: 'new', text: 'Upload new' },
                    { automation_id: 'use_existing', value: 'old', text: 'Use Existing' }
                ]"
            />
        </div>
        <div v-if="mode == 'new'" class="row task-documents content-wrapper">
            <div class="document-upload-form">
                <form enctype="multipart/form-data" novalidate ref="fileForm">
                    <div class="dropbox" ondragenter="this.classList.add('dropTarget');" ondragleave="this.classList.remove('dropTarget');" ondrop="this.classList.remove('dropTarget')">
                        <span>{{fileMessage}}</span>
                        <input
                            ref="fileInput"
                            type="file"
                            automation_id="task_permissibleusedocument"
                            name="documents"
                            @change="onFileInputChange"
                            :accept="accepted"
                            class="input-file"
                        />
                    </div>
                </form>
            </div>
        </div>
        <div v-else class="row">
            <div class="col col-12 form-group">
                <label for="pcl_RolesID">Permissible Use Documents</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'pud_existingdocuments' }"
                    :items="existingDocuments"
                    display-expr="commonFileName"
                    value-expr="fileScanDocumentID"
                    :search-enabled="false"
                    :show-clear-button="true"
                    :disabled="false"
                    v-model="selectedDocumentId"
                    @value-changed="onDocumentSelectionChange"
                >
                </dx-select-box>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name:"UploadPermissibleUseDocument",
        props: {
             accepted: { type: String, required: true, default: "" },
             ordersID: {type: Number, required: true},
        },
        data () {
            return {
                uploadFile: null,
                selectedDocument: null,
                errorMessage: "",
                mode: "new",
                existingDocuments: [],
                selectedDocumentId: null,
                permissibleUseDocumentCategory: null
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            acceptedFileTypes() { return _.split(this.accepted, ','); },
            fileForm() { return _.get(this.$refs, "fileForm", null) || {}; },
            fileInput() { return _.get(this.$refs, "fileInput", null) || {}; },
            fileMessage() {return _.isNull(this.uploadFile) ? `Drag and drop or click to add a file` : `Selected ${this.uploadFile.name}`},
            fileScanCategories() {
                let items = this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_CATEGORIES);
                return items;
            },
        },

        watch: {
        },

        created () {
            this.setDialogOkButtonDisabled(true);
            this.fetchData();
        },

        methods: {

            fetchData() {
                const self = this;
                self.permissibleUseDocumentCategory = self.fileScanCategories.find(p=>p.name == 'Permissible Use Document');
                let apiPromise = self.$api.FileScanApi.getFileScanDocuments(self.ordersID);
                self.$rqBusy.wait(apiPromise).then((data) => {
                    self.existingDocuments = data.filter(p=> (!_.isNil(self.permissibleUseDocumentCategory) && p.fileScanCategoryID == self.permissibleUseDocumentCategory.id));
                })
                .catch(err => {
                    console.log(err);
                });
            },

            clearForm() {
                this.uploadFile = null;
                if (!this.fileForm) return;
                this.fileForm.reset();
                if (!this.fileInput) return;
                this.fileInput.value = null;
            },

            isValidFileExt(fileExt){
                this.fileUploadInvalid = _.includes(this.acceptedFileTypes, _.toLower(fileExt));
                return this.fileUploadInvalid;
            },

            onFileInputChange (e) {
                if (e.target.files.length <= 0) return;
                this.checkFile(e.target.files[0]);
            },

            checkFile (file) {
                if (!file) return;
                const self = this;
                self.errorMessage = "";
                let fileExt = file.name.substr(file.name.lastIndexOf('.'));
                if (!self.isValidFileExt(fileExt)) {
                    self.errorMessage = `Invalid File Type, expected (${self.accepted})`;
                    self.clearForm();
                    self.setDialogOkButtonDisabled(true);
                    return;
                }
                self.uploadFile = file;
                self.selectedDocument = null;
                self.selectedDocumentId = null;
                self.setDialogOkButtonDisabled(false);
            },

            onDocumentSelectionChange(e) {
                const self = this;
                self.selectedDocument = self.existingDocuments.find(p=>p.fileScanDocumentID == self.selectedDocumentId);
                self.uploadFile = null;
                self.setDialogOkButtonDisabled(false);
            },

            setDialogOkButtonDisabled(val) {
                if(val)
                    this.$emit("disable-ok");
                else
                    this.$emit("enable-ok");
            },
        }

    };
</script>
