export class RecordingItemizationModel{
    constructor(options){
        this.clientKey = _.uniqueId("recording-itemization-");
        this.recordingItemizationDetailID = options.recordingItemizationDetailID;
        this.recordingItemizationID = _.parseNumber(options.recordingItemizationID,0);
        this.manualRecordingFeeCalculationID = _.parseNumber(options.manualRecordingFeeCalculationID,null);
        this.manualTransferTaxCalculationID = _.parseNumber(options.manualTransferTaxCalculationID,null);
        this.line = _.parseNumber(options.line, 0);
        this.description = options.description;
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID,null);
        this.payeeCompanyName = options.payeeCompanyName;
        this.payeeContactID = _.parseNumber(options.payeeContactID,null);
        this.payeeContactName = options.payeeContactName;
        this.borrowerAmount = _.parseNumber(options.borrowerAmount, null);
        this.sellerAmount = _.parseNumber(options.sellerAmount, null);
        this.cdfSection = options.cdfSection;
        this.netFund = _.parseNumber(options.netFund,null);
        this.accountCodeId = _.parseNumber(options.accountCodeID,null);
        this.doNotPrint = _.parseBool(options.doNotPrint);
        this.isDeleted = false;
        this.paidByCompanyID = _.parseNumber(options.paidByCompanyID,null);
        this.paidForBorrower = _.parseNumber(options.paidForBorrower, null);
        this.paidForSeller = _.parseNumber(options.paidForSeller, null);
        this.paidForBorrowerBeforeClosing = _.parseNumber(options.paidForBorrowerBeforeClosing, null);
		this.paidForSellerBeforeClosing = _.parseNumber(options.paidForSellerBeforeClosing, null);
        this.reducePayorCheck = _.parseBool(options.reducePayorCheck, true);
        this.cdfLineType = _.parseNumber(options.cdfLineType, null);
    }
}