<template>
    <li><b>{{data.description}}</b>
        <ul>
            <li v-for="item in data.results" v-bind:key="item.key">{{item.result}}</li>
        </ul>
    </li>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    },
    computed: {
        prorationResultClass() {
            let classes = ["proration-result"];
            let themeColor = _.get(this, "$route.meta.theme", null);
            if(themeColor) classes.push(`theme-${themeColor}`)
            return classes;
        }
    },
    methods: {
        //RQO-31880 - removed, since it doesn't appear to be used
        // formatDate(fromDate, toDate) {
        //     if(fromDate === null && toDate === null) return "";
        //     return `${fromDate !== null ? moment(fromDate).format("MM/DD/YYYY") : ""} thru ${toDate !== null ? moment(toDate).format("MM/DD/YYYY") : ""}`
        // }
    }
}
</script>