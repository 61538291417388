<template>
    <div v-if="!propIsNull" id="invoice-details" class="content-wrapper">
        <rq-page-section title="Invoice Details">
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly || isTitleOnly">Fill Invoice</button>
                            <ul class="dropdown-menu">
                                <li v-if="hasCalculatedPremiums">
                                    <button type="button" class="dropdown-item" automation_id="fill_invoice_filed_premiums" @click="onFillFiledPremiums">Fill this Invoice with filed Premiums</button>
                                </li>
                                <li v-if="hasHud">
                                    <button type="button" class="dropdown-item" automation_id="fill_invoice_hud" @click="onFillHud">Fill this Invoice with filed Premiums and HUD items</button>
                                </li>
                                <li v-if="hasCdf">
                                    <button type="button" class="dropdown-item" automation_id="fill_invoice_cdf" @click="onFillCdf">Fill this Invoice with CDF Premiums and CDF items</button>
                                </li>
                                <li v-if="hasCdf">
                                    <button type="button" class="dropdown-item" automation_id="fill_invoice_cdf_premiums" @click="onFillCdfPremiums">Fill this Invoice with CDF Premiums</button>
                                </li>
                                <li v-if="hasCdf">
                                    <button type="button" class="dropdown-item" automation_id="fill_invoice_cdf_filed_premiums" @click="onFillPremiumsAndCdf">Fill this Invoice with filed Premiums and CDF Items</button>
                                </li>
                                <li v-if="noFillOptions">
                                    <button type="button" class="dropdown-item" automation_id="fill_invoice_cdf_filed_premiums" disabled>No available filed Premiums or mapped {{ssDescription}} exist on this File</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item ps-4">
                        <b-form-checkbox
                            automation_id="chk_print_premium_split"
                            id="chk_print_premium_split"
                            :disabled="readOnly"
                            v-model="invoiceDetail.printPremiumSplit"
                            >Print Split
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_invoice_date">Invoice Date</label>
                        <rqdx-date-box
                            id="dtp_invoice_date"
                            :min="minInvoiceDate"
                            :max="maxInvoiceDate"
                            v-model="invoiceDetail.invoiceDate"
                            :disabled="readOnly"
                            type="date"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="optional_invoice_number">Optional Invoice Number</label>
                        <input automation_id="optional_invoice_number" v-model="invoiceDetail.invoiceNumUser" type="text" maxlength="20" class="form-control" id="optional_invoice_number" placeholder="Optionial Invoice Number"/>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="customer_invoicee">Customer / Invoicee</label>
                        <company-picker
                            ref="customerCompanyPicker"
                            automation_id="customer_invoicee"
                            dialogTitle="Select Customer / Invoicee"
                            :disabled="readOnly"
                            v-model="customerCompany">
                        </company-picker>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Payee"
                            labelFor="invoice_payee"
                            :show-action="isPayeeOverridden"
                            @action-click="onRevertPayee"
                            action-automation-id="btn_revert_payee"
                            action-label="Revert"
                        >
                            <template #default>
                                <company-picker
                                    ref="payeeCompanyPicker"
                                    automation_id="invoice_payee"
                                    dialogTitle="Select Payee"
                                    :disabled="disablePayee"
                                    v-model="payeeCompany">
                                </company-picker>
                            </template>
                        </rq-action-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Tax Rate"
                            labelFor="txt_tax_rate"
                            :show-action="invoiceDetail.isTaxRateOverridden"
                            @action-click="onRevertTaxRate"
                            action-automation-id="btn_revert_tax_rate"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    v-model="invoiceDetail.taxRate"
                                    defaultValue="0"
                                    decimals="3"
                                    minValue="0"
                                    maxValue="100"
                                    cssClass="form-control"
                                    id="txt_tax_rate"
                                    automation_id="txt_tax_rate"
                                    :disabled="disableTaxRate"/>
                            </template>
                        </rq-action-form-group>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Agent Premium"
                            labelFor="txt_agent_premium"
                            :show-action="invoiceDetail.isAgentPremiumOverridden"
                            @action-click="onRevertAgentPremium"
                            action-automation-id="btn_revert_payee"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    v-model="invoiceDetail.agentPremiumOverride"
                                    defaultValue="0"
                                    decimals="2"
                                    cssClass="form-control"
                                    id="txt_agent_premium"
                                    automation_id="txt_agent_premium"
                                    input-group
                                    prepend-text="$"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Underwriter Premium"
                            labelFor="txt_underwriter_premium"
                            :show-action="invoiceDetail.isUnderwriterPremiumOverridden"
                            @action-click="onRevertUnderwriterPremium"
                            action-automation-id="btn_revert_payee"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    v-model="invoiceDetail.underwriterPremiumOverride"
                                    defaultValue="0"
                                    decimals="2"
                                    cssClass="form-control"
                                    id="txt_underwriter_premium"
                                    automation_id="txt_underwriter_premium"
                                    input-group
                                    prepend-text="$"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_initials">Initials</label>
                        <input automation_id="txt_initials" v-model="invoiceDetail.initials" type="text" maxlength="5" class="form-control" id="txt_initials"/>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section borderless>
            <rqdx-action-data-grid
                ref="invoiceLineDataGrid"
                title="Invoice Lines"
                class="rq-tab-content-grid"
                automation_id="tbl_invoice_lines"
                :actions="invoiceLineSelectionActions"
                :data-source="invoiceLineGridDataSource"
                :config="invoiceLineGridConfig"
                export-file-name="invoice-line-data"
                @delete="onDeleteAction"
                hide-search
                :rq-editable="!readOnly"
                hide-show-column-chooser
            >
            <template #toolbar>
                <ul class="nav navbar-nav me-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_invoice_line"
                            variant="theme"
                            size="sm"
                            :disabled="readOnly"
                            @click="onAddInvoiceLine">Add
                        </b-btn>
                    </li>
                    <li v-if="multipleInvoiceLines"
                        class="nav-item"
                        v-rq-tooltip.hover :title="hasNewInvoiceLines ? 'Save or cancel your changes to enable' : ''">
                        <b-btn
                            automation_id="btn_reorder_invoice_lines"
                            variant="theme"
                            size="sm"
                            :disabled="readOnly || hasNewInvoiceLines"
                            @click="onReorderInvoiceLines">
                            Reorder Invoice Lines
                        </b-btn>
                    </li>
                </ul>
            </template>
            </rqdx-action-data-grid>
        </rq-page-section>
        <rq-page-section
            title="Custom Data"
            collapsible
            borderless>
            <custom-data-container
                ref="customDataContainer"
                :reference-table="referenceTable"
                :reference-table-pk-value="invoiceDetail.invoiceID"
                :custom-data-tab-id="customDataTabID"
                v-model:value="customData"
                v-model:is-valid="customDataIsValid"
                :read-only="customDataReadOnly"
                @custom-data-loaded="onCustomDataLoaded"
            ></custom-data-container>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { CompanyPicker, CustomDataContainer } from "@/shared/components";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import InvoiceLoanPicker from "./InvoiceLoanPicker";
    import { InvoiceLineModel, InvoiceFillType } from "../models";
    import { SETTLEMENT_TYPE } from '@settlement/models';
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { UserSecuritySettings } from "@/shared/models/models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { SortableList } from "@/shared/components/rq";
    import { DateTime } from "luxon";

    export default {
        name: "InvoiceDetailSection",
        components:{CompanyPicker, CustomDataContainer},
        mixins: [GridSystemLookupMixin, GridInvokerMixin({ grid: "invoiceLineDataGrid" })],
        props:{
            invoiceDetail:{type: Object},
            originalData:{type: Object},
            loans:{type: Array},
            invoiceCustomData: { type: Array },
            originalInvoiceCustomData: { type: Array },
            isInvoiceCustomDataValid: { type: Boolean }
        },
        data(){
            return {
                customDataTabID: 0,
                customDataIsValid: true,
                customData: [],
                originalCustomData: [],
                hasPendingInvoiceLine: false,
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                systemDefaults: state => state.system.systemDefaults,
                order: state => state.orders.order,
                settlementStatementType: state => state.orders.orderSummary.settlementStatementType,
                authSettings: state => state.authentication.session.settings,
                currentUser: state => state.authentication.session.user,
            }),
            propIsNull(){return _.isNil(this.invoiceDetail)},
            readOnly() { return this.isReadOnly || this.isOrderLocked },
            localSecurity() {
                 return this.securitySettings.findValues([
                     "AllowChangePayeeNameOnInvoice",
                     "AllowChangeTaxRateOnInvoice",
                     "InvoicePayments",
                     "InvoicePostDateDays",
                     "InvoicePreDateDays",
                     "PaymentPreDateDays",
                     "PaymentPostDateDays",
                     "AdditionalFields_ScreenAccess"
                ]);
            },
            customDataReadOnly() { return this.readOnly || this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.Full; },
            isTitleOnly(){ return _.isEqual(this.order?.transactionTypeID, 3); },
            isSysAdmin(){
                let settings = new UserSecuritySettings(this.authSettings);
                return settings.findValue("IsSysAdmin");
            },
            disablePayee(){
                return this.readOnly || !this.localSecurity.AllowChangePayeeNameOnInvoice;
            },
            disableTaxRate(){
                return this.readOnly || !this.localSecurity.AllowChangeTaxRateOnInvoice;
            },
            editPayment(){
                if(this.readOnly) return false;
                return this.localSecurity.InvoicePayments === 2;
            },
            customerCompany: {
                get:function(){
                    return {
                        companyID: this.invoiceDetail.customerCompanyID,
                        companyName: this.invoiceDetail.customerCompanyName,
                        contactID: this.invoiceDetail.customerContactID,
                        contactName: this.invoiceDetail.customerContactName
                    }
                },
                set:function(val){
                    this.invoiceDetail.customerCompanyID = _.get(val, "companyID", null);
                    this.invoiceDetail.customerCompanyName = _.get(val, "companyName", null);
                    this.invoiceDetail.customerContactID = _.get(val, "contactID", null);
                    this.invoiceDetail.customerContactName = _.get(val, "contactName", null);
                }
            },
            payeeCompany:{
                get:function(){
                    return {
                        companyID: this.invoiceDetail.payeeCompanyID,
                        companyName: this.invoiceDetail.payeeCompanyName,
                        contactID: this.invoiceDetail.payeeContactID,
                        contactName: this.invoiceDetail.payeeContactName
                    }
                },
                set:function(val){
                    this.invoiceDetail.payeeCompanyID = _.get(val, "companyID", null);
                    this.invoiceDetail.payeeCompanyName = _.get(val, "companyName", null);
                    this.invoiceDetail.payeeContactID = _.get(val, "contactID", null);
                    this.invoiceDetail.payeeContactName = _.get(val, "contactName", null);
                }
            },
            isPayeeOverridden(){ return this.invoiceDetail.defaultPayeeCompanyID > 0 && this.invoiceDetail.payeeCompanyID !== this.invoiceDetail.defaultPayeeCompanyID; },
            isHud(){ return this.settlementStatementType === SETTLEMENT_TYPE.HUD_1974 || this.settlementStatementType === SETTLEMENT_TYPE.HUD_2010; },
            isCdf(){ return this.settlementStatementType === SETTLEMENT_TYPE.CDF; },
            isCss(){ return false; },//TODO: this will need to be implemented when we add the commercial settlment statement
            hasHud(){ return this.isHud && this.invoiceDetail && this.invoiceDetail.hasHud; },
            hasCdf(){ return this.isCdf && this.invoiceDetail && this.invoiceDetail.hasCdf; },
            hasCss(){ return this.isCss && this.invoiceDetail && this.invoiceDetail.hasCss; },
            hasCalculatedPremiums(){ return this.invoiceDetail && this.invoiceDetail.hasCalculatedPremiums; },
            noFillOptions(){ return !this.hasHud && !this.hasCdf && !this.hasCss && !this.hasCalculatedPremiums; },
            ssDescription(){ return this.isCdf ? "CDF" : this.isHud ? "HUD" : this.isCss ? "CSS" : ""; },
            minInvoiceDate() { return this.getMinMaxInvoiceDate("min"); },
            maxInvoiceDate() { return this.getMinMaxInvoiceDate("max"); },
            isNewInvoice() {
                return this.invoiceDetail.invoiceID === 0;
            },
            referenceTable() {
                return CustomDataReferenceTable.Invoices;
            },
            multipleInvoiceLines() { return this.invoiceDetail.invoiceLines.length > 1; },
            hasNewInvoiceLines() { return this.hasPendingInvoiceLine || _.filter(this.invoiceDetail.invoiceLines, l => l.invoiceLineID == 0).length > 0; }
        },
        created() {
            const self = this;
            self.loadGridConfig();
        },
        watch:{
            "invoiceDetail.underwriterPremiumOverride"(newValue){
                const self = this;
                self.invoiceDetail.isUnderwriterPremiumOverridden = newValue !== self.invoiceDetail.underwriterPremiumCalculated;
            },
            "invoiceDetail.agentPremiumOverride"(newValue){
                const self = this;
                self.invoiceDetail.isAgentPremiumOverridden = newValue !== self.invoiceDetail.agentPremiumCalculated;
            },
            customData: {
                handler(newValue, oldValue) {
                    if(_.isEqual(newValue, oldValue)) return;
                    this.$emit('update:invoiceCustomData', newValue);
                },
                deep: true
            },
            originalCustomData: {
                handler(newValue, oldValue) {
                    let self = this;
                    self.$emit('update:originalInvoiceCustomData', newValue);
                },
                deep: true
            },
            customDataIsValid: {
                handler(newValue, oldValue) {
                    if(_.isEqual(newValue, oldValue)) return;
                    this.$emit('update:isInvoiceCustomDataValid', newValue);
                },
                deep: true
            }
        },
        methods:{
            onReorderInvoiceLines() {
                const self = this;
                if(self.readOnly) return;
                let items = _.map(self.invoiceDetail.invoiceLines, item => {
                    return {
                        id: item.invoiceLineID,
                        description: item.description,
                        ordinal: item.sequence
                    };
                });

                const orderId = self.orderId;
                const invoiceId = self.invoiceDetail.invoiceID;

                self.$dialog.open({
                    title: "Reorder Invoice Lines",
                    width: 600,
                    adaptive: true,
                    component: SortableList,
                    props: { orderId, items },
                    onOk(e) {
                        const changes = e.component.getChanges();
                        if(_.isEmpty(changes)) return true;
                        const request = { orderId, changes };
                        const apiPromise = self.$api.InvoiceApi.reorderInvoiceLines(orderId, invoiceId, request);
                        self.$rqBusy.wait(apiPromise)
                            .then((result) => {
                                self.$emit("reorderInvoiceLines", result);
                                self.$toast.success("Invoice line items reordered.");
                            });
                        return true;
                    }
                });
            },

            getMinMaxInvoiceDate(minOrMax) {
                const self = this;
                if(minOrMax !== "min" && minOrMax !== "max") return null;

                let dayDiff = _.parseNumber(minOrMax === "min"
                    ? self.localSecurity.InvoicePreDateDays
                    : self.localSecurity.InvoicePostDateDays, -1);

                if(dayDiff < 0) return null;

                if(minOrMax === "min") dayDiff *= -1;
                return DateTime.now().plus({ days: dayDiff }).toISO();
            },

            loadGridConfig(){
                const self = this;
                self.invoiceLineSelectionActions = [
                    { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, disabled(e){
                        if(!self.editPayment || self.readOnly){
                            return true;
                        }
                        return false;
                    } }
                ];
                self.invoicePaymentSelectionActions = [
                    { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, disabled(e){
                        if(!self.editPayment || self.readOnly){
                            return true;
                        }
                        return false;
                    } }
                ];
                self.invoiceLineGridConfig = {
                    onInitNewRow: self.initNewRow,
                    onEditorPreparing: self.onEditorPreparing,
                    columns: [
                        {
                            dataField: "sequence",
                            caption: "No.",
                            width:55,
                            sortIndex: 0,
                            sortOrder: "asc",
                            allowEditing: false
                        },
                        {
                            dataField: "description",
                            caption: "Description",
                            allowEditing: true,
                            editorOptions:{ inputAttr: {automation_id: "txt_description"}},
                            validationRules: [{
                                type: "stringLength",
                                max:70,
                                message:"Invoice Description is limited to 70 characters"
                            }]
                        },
                        {
                            dataField: "amount",
                            caption: "Amount",
                            allowEditing: true,
                            alignment: "right",
                            width: 185,
                            minWidth: 185,
                            dataType: "number",
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            format: { type: "currency", precision: 2 },
                            editorOptions: {
                                format: {type: "currency", precision: 2},
                                showClearButton: true,
                                inputAttr: {automation_id: "txt_amount"}
                            },
                            precision: 2
                        },
                        {
                            dataField: "quantity",
                            caption: "Qty",
                            allowEditing: true,
                            alignment: "right",
                            dataType: "number",
                            width: 150,
                            minWidth: 150,
                            editorOptions:{
                                format: "#",
                                inputAttr: {automation_id: "txt_quantity"}
                            }
                        },
                        {
                            dataField: "taxable",
                            caption: "Taxable",
                            dataType: "boolean",
                            allowEditing: true,
                            width: 125,
                            minWidth: 125,
                            editorOptions:{ elementAttr: {automation_id: "chk_taxable"} },
                            cellTemplate: DxGridUtils.boolCellTemplate

                        },
                        {
                            dataField: "total",
                            caption: "Total",
                            allowEditing: false,
                            alignment: "right",
                            width: 125,
                            minWidth: 125,
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            editorOptions:{ inputAttr: {automation_id: "txt_total"} }
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions:{ inputAttr: {automation_id: "drp_account_code"} },
                                width: 250,
                                minWidth: 250,
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                        // {
                        //     dataField: "accountingCodeID",
                        //     caption: 'Account Code',
                        //     width: 250,
                        //     minWidth: 250,
                        //     lookup: {
                        //         dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES),
                        //         displayExpr: "name",
                        //         valueExpr: "id"
                        //     },
                        //     editorOptions:{ inputAttr: {automation_id: "drp_account_code"} }
                        // }
                    ],
                    summary:{
                        totalItems:[{
                            name: "taxableSummary",
                            showInColumn: "quantity",
                            displayFormat: "Taxable: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        },
                        {
                            name: "nonTaxableSummary",
                            showInColumn: "amount",
                            displayFormat: "Non-Taxable: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        },
                        {
                            name: "taxSummary",
                            showInColumn: "taxable",
                            displayFormat: "Tax: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        },
                        {
                            name: "totalSummary",
                            showInColumn: "total",
                            displayFormat: "Total: {0}",
                            valueFormat: {type:"currency", precision:2},
                            summaryType: "custom"
                        }],
                        calculateCustomSummary: function (options) {
                            switch(options.name){
                                case "taxableSummary":
                                    options.totalValue = self.invoiceDetail.totalTaxable;
                                    break;
                                case "nonTaxableSummary":
                                    options.totalValue = self.invoiceDetail.totalNonTaxable;
                                    break;
                                case "taxSummary":
                                    options.totalValue = self.invoiceDetail.taxes;
                                    break;
                                case "totalSummary":
                                    options.totalValue = self.invoiceDetail.grandTotal;
                                    break;
                            }
                        }
                    }
                };
                self.invoiceLineGridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.invoiceDetail.invoiceLines);
                    },
                    update: self.onGridUpdate,
                    insert: self.onGridInsert
                };
            },

            initNewRow(e){
                this.hasPendingInvoiceLine = true
                e.data = {
                    isNew: true,
                    quantity: 1,
                    taxable: false,
                    usersID: this.currentUser.usersID,
                    sequence: this.invoiceDetail.invoiceLines.length
                        ? Math.max(...this.invoiceDetail.invoiceLines.map(item => item.sequence)) + 1
                        : 1

                };
            },

            initNewPaymentRow(e){
                e.data = {
                    isNew: true
                };
            },

            onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.invoiceDetail.invoiceLines, il => il.clientKey === key);
                _.assign(item, values);

                self.reCalculateAmount();

                return Promise.resolve(item);
            },

            onGridInsert(values){
                const self = this;

                self.hasPendingInvoiceLine = false;

                let newItem = new InvoiceLineModel({
                    invoiceID: self.invoiceDetail.invoiceID
                });

                _.assign(newItem, values);
                self.invoiceDetail.invoiceLines.push(newItem);

                self.reCalculateAmount();

                return Promise.resolve(newItem);
            },

            onPaymentGridUpdate(key, values) {
                const self = this;
                if(!self.editPayment || self.readOnly) return;
                let item = _.find(self.invoiceDetail.invoicePayments, p => p.clientKey === key);
                _.assign(item, values);

                return Promise.resolve(item);
            },

            onFillInvoice(fillType, loans){
                const self = this;
                if(loans.length === 1){
                    let loanIds = [self.loans[0].loanID];
                    let request = self.buildFillRequest(fillType, loanIds);
                    self.$emit("fillInvoice", request);
                }
                else{
                    let okHandler = function(e) {
                        let loanIds = []
                        _.forEach(e.component.selectedLoans, l => {
                            loanIds.push(l.loanID);
                        });
                        let request = self.buildFillRequest(fillType, loanIds);
                        self.$emit("fillInvoice", request);
                        return true;
                    };
                    self.showLoanPickerDialog(fillType, loans, okHandler);
                }
            },

            onFillFiledPremiums(){
                let loans = this.loans;
                this.onFillInvoice(InvoiceFillType.Premiums, loans);
            },

            onFillRecordingFeesAndTransferTaxes(){
                let loans = this.loans;
                this.onFillInvoice(InvoiceFillType.RecordingFeesAndTransferTaxes, loans);
            },

            onFillHud(){
                let loans = _.filter(this.loans, l => _.parseNumber(l.hudMainId, 0) > 0);
                this.onFillInvoice(InvoiceFillType.PremiumsAndHUD, loans);
            },

            onFillCss(){
                let loans = _.filter(this.loans, l => _.parseNumber(l.cssMainId, 0) > 0);
                this.onFillInvoice(InvoiceFillType.PremiumsAndCSS, loans);
            },

            onFillCdf(){
                let loans = _.filter(this.loans, l => _.parseNumber(l.cdfMainId, 0) > 0);
                this.onFillInvoice(InvoiceFillType.CDFPremiumsAndCDF, loans);
            },

            onFillCdfPremiums(){
                let loans = _.filter(this.loans, l => _.parseNumber(l.cdfMainId, 0) > 0);
                this.onFillInvoice(InvoiceFillType.CDFPremiums, loans);
            },

            onFillPremiumsAndCdf(){
                let loans = _.filter(this.loans, l => _.parseNumber(l.cdfMainId, 0) > 0);
                this.onFillInvoice(InvoiceFillType.PremiumsAndCDF, loans);
            },

            onAddInvoiceLine(){
                this.invokeGridMethod("addRow");
            },

            onDeleteAction(e){
                const self = this;
                if(!e || !e.data || !self.editPayment || self.readOnly) return;
                let items = e.data;

                let confirmMessage = items.length === 1 ? "Are you sure you want to delete this invoice line?" : "Are you sure you want to delete these invoice lines?";

                let okHandler = function () {
                    _.forEach(items, i => {
                        let index = _.findIndex(self.invoiceDetail.invoiceLines, il => {
                            return il.clientKey === i.clientKey;
                        });

                        self.invoiceDetail.invoiceLines.splice(index, 1);

                        if(!i.isNew){
                            self.invoiceDetail.deletedInvoiceLines.push(i);
                        }
                    });

                    self.reCalculateAmount();

                    self.invokeGridMethod("refresh");

                    return true;
                };
                self.$dialog.confirm( "Delete Invoice line", confirmMessage, okHandler, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onRevertPayee(){
                this.invoiceDetail.payeeCompanyID = this.invoiceDetail.defaultPayeeCompanyID;
                this.invoiceDetail.payeeCompanyName = this.invoiceDetail.defaultPayeeCompanyName;
                this.invoiceDetail.payeeContactID = null;
                this.invoiceDetail.payeeContactName = null;
            },

            onRevertAgentPremium(){
                const self = this;
                self.invoiceDetail.agentPremiumOverride = self.invoiceDetail.agentPremiumCalculated;
            },

            onRevertUnderwriterPremium(){
                const self = this;
                self.invoiceDetail.underwriterPremiumOverride = self.invoiceDetail.underwriterPremiumCalculated;
            },

            onRevertTaxRate(){
                this.invoiceDetail.taxRate = this.invoiceDetail.defaultTaxRate;
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(e.dataField === "accountingCodeID") {e.editorOptions.readOnly = !this.allowEditAccountCodePermission(e.row.data); return;}
                e.editorOptions.disabled = this.readOnly;
            },

            allowEditAccountCodePermission(rowData) {
                //RQO-16973 - If a User Edits their own invoice line, they can edit account Code
                if (this.currentUser.usersID === rowData.usersID) return true;
                //Otherwise, use system setting
                return this.systemDefaults.onlySystemAdminsCanEditAccountingCodes ? this.isSysAdmin : true;
            },

            refresh(){
                const self = this;
                if(self.propIsNull) return;
                self.invokeGridMethod("refresh");
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            validate() {
                const self= this;
                let errors = [];
                if(self.localSecurity.PaymentPreDateDays === -1 && self.localSecurity.PaymentPostDateDays === -1) return errors;
                let now = DateTimeHelper.nowTenantStartOfDay();

                _.forEach(self.invoiceDetail.invoicePayments, p => {
                    let paymentDate = DateTimeHelper.getStartOf(p.paymentDate, "day");
                    if(!_.isNil(self.originalData)){
                        let original = _.find(self.originalData.invoicePayments, op=> {return op.invoicePaymentID === p.invoicePaymentID;});
                        if(!_.isNil(original)) {
                            let originalDate = DateTimeHelper.getStartOf(original.paymentDate, "day");
                            if(DateTimeHelper.diff(paymentDate, originalDate, "days").days === 0) return errors; //date hasn't changed from original
                        }
                    }

                    let daysDiff = DateTimeHelper.diff(now, paymentDate.toISO(), "days").days;

                    if(paymentDate.toString() == "Invalid date")
                        daysDiff = 0;



                    if(daysDiff > 0
                        && self.localSecurity.PaymentPreDateDays > -1
                        && Math.abs(daysDiff) > self.localSecurity.PaymentPreDateDays){
                            let days = self.localSecurity.PaymentPreDateDays === 1 ? " day" : " days";
                            errors.push("Restricted from posting payments more than " + self.localSecurity.PaymentPreDateDays + days + " from current date");
                    }

                    if(daysDiff < 0
                        && self.localSecurity.PaymentPostDateDays > -1
                        && Math.abs(daysDiff) > self.localSecurity.PaymentPostDateDays){
                            let days = self.localSecurity.PaymentPostDateDays === 1 ? " day" : " days";
                            errors.push("Restricted from posting payments more than " + self.localSecurity.PaymentPostDateDays  +  days + " from current date");
                    }

                    if(_.isNil(p.paymentDate)){
                        errors.push("Your Payment has no Post Date.");
                    }

                });
                return errors;
            },

            getFillDialogTitle(fillType) {
               switch(fillType) {
                    case InvoiceFillType.Premiums: return "Fill with filed Premiums";
                    case InvoiceFillType.PremiumsAndHUD: return "Fill with filed Premiums and HUD-1 Items";
                    case InvoiceFillType.PremiumsAndCSS: return "Fill with filed Premiums and Settlement Statement Items";
                    case InvoiceFillType.CDFPremiumsAndCDF: return "Fill with CDF Premiums and CDF Items";
                    case InvoiceFillType.CDFPremiums: return "Fill with CDF Premiums";
                    case InvoiceFillType.RecordingFeesAndTransferTaxes: return "Fill with Recording Fees and Taxes";
               }
            },

            showLoanPickerDialog(fillType, loans, okHandler){
                const self = this;
                self.$dialog.open({
                    title: self.getFillDialogTitle(fillType),
                    width: 1000,
                    height: 825,
                    component: InvoiceLoanPicker,
                    props:{ loans },
                    onOk: okHandler
                });
            },

            buildFillRequest(fillType, loanIds){
                const self = this;
                let request = {
                    invoiceID: self.invoiceDetail.invoiceID,
                    orderID: self.orderId,
                    loanIDs: loanIds,
                    fillType: fillType
                }

                return request;
            },

            onSave(e){
                const self = this;
                return self.invokeGridMethod("saveEditData");
            },

            reCalculateAmount(){
                const self = this;

                self.invoiceDetail.totalNonTaxable = 0;
                self.invoiceDetail.totalTaxable = 0;
                self.invoiceDetail.taxes = 0;
                self.invoiceDetail.grandTotal = 0;

                _.forEach(self.invoiceDetail.invoiceLines, i => {
                    if(i.taxable) {
                        self.invoiceDetail.totalTaxable += i.total;
                        self.invoiceDetail.taxes += _.round(i.total*self.invoiceDetail.taxRate/100, 2);
                    } else {
                        self.invoiceDetail.totalNonTaxable += i.total;
                    }
                });

                self.invoiceDetail.grandTotal = self.invoiceDetail.totalNonTaxable + self.invoiceDetail.totalTaxable + self.invoiceDetail.taxes;
            },

            updateDimensions(){
                this.invokeGridMethod("updateDimensions");
                this.invokeGridMethod("repaint");
            },

            onCustomDataLoaded(e) {
                let self = this;
                self.originalCustomData = _.cloneDeep(e);
            },

            customDataValidate() {
                let component = _.get(this, "$refs.customDataContainer");
                if(!component) return true;
                return component.validate();
            },

            customDataInitialize() {
                _.invoke(this, "$refs.customDataContainer.initialize");
            },

            customDataResetValidation() {
                _.invoke(this, "$refs.customDataContainer.resetFieldValidations");
            }
        }
    }
</script>
