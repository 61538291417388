<template>
    <div v-if="isHud" class="content-wrapper addendums">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="Addendums" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_addendum" v-focus variant="theme" @click="onAddMainAction" :disabled="readOnly">Add</b-btn>
                        <rq-report-button
                            text="Print Report"
                            :disabled="readOnly || hudMainId == 0 || !hasAddendums || !hasAddendumDetails"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                            map-ss-on-change
                            map-override
                        />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="mainAddendumGrid"
            automation_id="dg_main_grid"
            :actions="selectionActions"
            :config="gridMainConfig"
            :data-source="gridMainDataSource"
            export-file-name="hud-main-addendum-data"
            v-model:validation-errors="mainValidationErrors"
            :strikethrough-if-true="['isInactive']"
            @delete="onDeleteMainItems"
            @selectionChanged="onMainSelectionChanged"
            :rq-editable="!readOnly"
            hide-search
        />
        <rqdx-action-data-grid
            ref="detailAddendumGrid"
            automation_id="dg_detail_grid"
            :actions="detailGridActions"
            :config="gridDetailConfig"
            :data-source="gridDetailDataSource"
            export-file-name="hud-detail-addendum-data"
            v-model:validation-errors="detailValidationErrors"
            hide-search
            @delete="onDeleteDetailItems"
            :title="addendumTitle"
            :rq-editable="!readOnly">
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_addendum_detail"
                            variant="theme"
                            @click="onAddDetailAction"
                            :disabled="!canAddDetail">Add
                        </b-btn>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
    <div v-else class="content-wrapper">
        <rq-no-data text="Addendums are only available for 3-Page HUD" />
    </div>
</template>

<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { GlobalEventManager } from "@/app.events";
    import { HudLine } from "@settlement/models";
    import { HudAddendumDto, HudAddendumDetailDto } from "./models";
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { SettlementReport } from '@settlement/components/dashboard/models';

    export default {
        name: "HudAddendums",
        mixins: [BaseSettlementMixin],
        data() {
            return {
                // Main Addendum Grid properties
                itemTypeNamePlural: "Addendums" ,
                itemMainKey: "hudAddendumMainID",
                itemTypeMainName: "Addendum",
                selectionActions: [],
                items: [],
                deletedMainItems: [],
                originalData: [],
                alertMessage: "",
                hudLines: [],
                //Detail Addendum Grid properties
                detailGridActions: [],
                itemTypeDetailName: "Addendum Detail",
                itemDetailKey: "line",
                selectedMainItem: { hudAddendumMainID: 0 }, // Main Addendum Item
                mainValidationErrors: [],
                detailValidationErrors: []
            };
        },

        watch: {
            selectedLoanId(newVal, oldVal) {
                if (newVal === oldVal || _.parseNumber(oldVal, 0) === 0 || !this.isHud) return;
                this.commitGridData();
                this.selectedMainItem = { hudAddendumMainID: 0 };
                // Will want to map SS considering HUD Addendums do not have a generic table and saving addendums require mapping for HudMainID to exist for the loan
                // this.mapSettlementStatement(null, false, newVal).then(() => {
                //     this.fetchData();
                // });

                //mapping is now handled by loan select box
                this.fetchData();

                this.gridMainInstance.option("focusedRowIndex", -1);
                this.gridDetailInstance.option("focusedRowIndex", -1);
            },
        },

        computed: {
            canAddDetail() { return this.selectedMainItem.hudAddendumMainID > 0; },
            hudMainId() { return this.hudLines.length > 0 ? this.hudLines[0].hudMainID : 0; },
            gridMainInstance () { return this.$refs.mainAddendumGrid.gridInstance; },
            gridDetailInstance() { return this.$refs.detailAddendumGrid.gridInstance; },
            addendumTitle() {
                if (this.selectedMainItem.hudAddendumMainID === 0) return 'ADDENDUM DETAILS:'
                return `ADDENDUM DETAILS: ${this.selectedMainItem.descriptionOfCharges}`;
            },
            hasAddendums() {
                return this.items.length > 0;
            },
            hasAddendumDetails() {
                return _.some(this.items, item => {
                    return !!(item.hudAddendumDetails) && item.hudAddendumDetails.length > 0
                });
            },
            reportOptions() { return SettlementReport.reportOptionsHudAddendums(this.hudMainId); },
        },

        created() {
            const self = this;

            self.baseInit();

            // Main Addendum Grid
            self.selectionActions = [{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeMainName}`, disabled: self.readOnly }];
            self.initMainGridConfig();

            // Detail Addendum Grid
            self.detailGridActions = [{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeDetailName}`, disabled: self.readOnly }];
            self.initDetailGridConfig();

            self.fetchData();
        },

        methods: {
            // TODO: May no longer by needed, here for reference
            // fetchHudMainId() {
            //     const self = this;
            //     if(self.selectedLoanId === 0) return Promise.resolve(true);
            //     let getPromise = self.$api.HudAddendumsApi.getHudMainId(self.selectedLoanId);

            //     return self.$rqBusy.wait(getPromise).then(result => {
            //         self.hudMainID = result;
            //     });
            // },
            fetchData() {
                const self = this;
                self.deletedMainItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);

                let apiPromises = [
                    self.$api.HudAddendumsApi.getHudLines(self.selectedLoanId),
                    self.$api.HudAddendumsApi.getHudAddendumsByLoan(self.selectedLoanId)
                ];

                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(results => {
                        self.hudLines = _.map(results[0], result => new HudLine(result));
                        self.items = _.map(results[1], result => new HudAddendumDto(result, result.hudMainID));
                        self.originalData = _.map(results[1], result => new HudAddendumDto(result, result.hudMainID));
                        self.gridMainInstance.refresh();
                        self.gridDetailInstance.refresh();
                    });
            },

            onSave(e){
                this.commitGridData();
                this.validationErrors = [...this.mainValidationErrors, ...this.detailValidationErrors];
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            commitGridData() {
                this.gridMainInstance.saveEditData();
                this.gridDetailInstance.saveEditData();
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            save(refreshData = true) {
                const self = this;

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                if(!self.hasChanges()){
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let savePromise = self.$api.HudAddendumsApi.saveDeleteHudAddendums(self.items, self.deletedMainItems);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Addendum(s) Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Addendums");
                        console.error(err);
                    });
            },

            initMainGridConfig() {
                const self = this;

                self.gridMainConfig = {
                    focusedRowEnabled: true,
                    focusedRowIndex: 0,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            caption: "Description",
                            dataField: "descriptionOfCharges",
                            validationRules: [
                                { type: "required", message: "Description is required" }
                            ],
                            editorOptions: { maxLength: 80, valueChangeEvent: "input" }
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    onFocusedRowChanged: self.onMainAddendumGridFocusedRowChanged
                }

                self.initMainGridDataSource();
            },
            initMainGridDataSource() {
                const self = this;
                let api = self.$api.HudAddendumsApi;
                self.gridMainDataSource = {
                    key: self.itemMainKey,
                    load(){
                        return Promise.resolve(self.items);
                    },
                    insert(values){
                        return self.onMainGridInsert(values);
                    },
                    update(key, values) {
                        return self.onMainGridUpdate(key, values);
                    }
                };
            },
            onMainAddendumGridFocusedRowChanged(e) {
                if (e.row) {
                    this.selectedMainItem = e.row.data;
                    this.gridDetailInstance.refresh();
                }
            },
            onMainGridInsert(values) {
                const self = this;
                let newItem = new HudAddendumDto(values);
                newItem.hudAddendumMainID = -1;
                newItem.hudMainID = self.hudMainId;
                newItem.loanID = self.selectedLoanId;

                let savePromise =  self.$api.HudAddendumsApi.saveDeleteHudAddendums([newItem], []);
                return self.$rqBusy
                    .wait(savePromise)
                    .then(() =>{
                        self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Addendums Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Addendums");
                        console.error(err);
                    });
            },
            onMainGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.hudAddendumMainID === key);
                let updatedItem = new HudAddendumDto(_.assign({}, self.items[itemIndex], values), self.hudMainId);

                let savePromise =  self.$api.HudAddendumsApi.saveDeleteHudAddendums([updatedItem], []);

                return self.$rqBusy
                    .wait(savePromise)
                    .then(() =>{
                        self.fetchData().then(() => {
                            let main = _.find(self.items, x => x.hudAddendumMainID === self.selectedMainItem.hudAddendumMainID);

                            if(main) {
                                _.assign(self.selectedMainItem, main)
                            }
                        });
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Addendums Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Addendums");
                        console.error(err);
                    });
            },
            onAddMainAction(e) {
                if(!this.gridMainInstance) return;
                this.gridMainInstance.addRow();
            },
            onDeleteMainItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteMainItems(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected addendum(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            deleteMainItems(items) {
                const self = this;
                let savePromise =  self.$api.HudAddendumsApi.saveDeleteHudAddendums([], items);

                return self.$rqBusy
                    .wait(savePromise)
                    .then(() =>{
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Addendums Saved Successfully");
                        self.fetchData().then(() => {
                            if( self.items.length === 0) {
                                self.selectedMainItem = { hudAddendumMainID: 0, hudAddendumDetails: [] };
                                self.gridDetailInstance.refresh();
                            }
                        });
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error(err.errorMessage);
                    });
            },
            onMainSelectionChanged(e) {
                const self = this;
                const firstSelectedKey = e.currentDeselectedRowKeys.length
                    ? _.first(e.selectedRowKeys)
                    : _.first(e.currentSelectedRowKeys)
                const selectedItem = self.originalData.find(data => data.hudAddendumMainID === firstSelectedKey)
                    || { hudAddendumMainID: 0, hudAddendumDetails: [] };
                self.selectedMainItem = selectedItem;
                self.gridDetailInstance.refresh();
            },
            // Addendum Detail Grid Actions
            initDetailGridConfig() {
                const self = this;

                self.gridDetailConfig = {
                    focusedRowEnabled: false,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            caption: "HUD Line",
                            dataField: "hudLine",
                            validationRules: [
                            ],
                            width: 100,
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxNumberBox({
                                    value: cellInfo.value,
                                    showClearButton: false,
                                    format: "#",
                                    mode: "number",
                                    min: 0,
                                    max: 9999,
                                    inputAttr: {
                                        maxLength: 4
                                    },
                                    valueChangeEvent: "change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(newData, value, currentRowData) {
                                newData.hudLine = value;
                                let line = _.find(self.hudLines, x => x.line === _.parseNumber(value, 0));
                                if(line) {
                                    newData.description = line.description;
                                    newData.hudAmount = _.parseNumber(line.borrowerAmount, 0);
                                }
                            }
                        },
                        {
                            dataField: "line",
                            allowEditing: false,
                            validationRules: [
                            ],
                            visible: false
                        },
                        {
                            dataField: "description",
                            validationRules: [
                                { type: "required", message: "Description is required" }
                            ],
                            editorOptions: { maxLength: 80 }
                        },
                        {
                            dataField: "hudAmount",
                            caption: "HUD Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "addendumAmount",
                            caption: "Addendum Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 },
                                valueChangeEvent: "input",
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    // Summary Totals
                    summary:
                    {
                        totalItems: [
                            {
                                column: "hudLine",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return "TOTALS";
                                }
                            },

                            {
                                column: "hudAmount",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },

                            {
                                column: "addendumAmount",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                        ]
                    }
                }

                self.initDetailGridDataSource();
            },
            initDetailGridDataSource() {
                const self = this;
                self.gridDetailDataSource = {
                    key: self.itemDetailKey,
                    load(){
                        return Promise.resolve(self.selectedMainItem.hudAddendumDetails);
                    },
                    insert(values){
                        self.onDetailGridInsert(values);
                    },
                    update(key, values) {
                        self.onDetailGridUpdate(key, values);
                    }
                };
            },
            onDetailGridInsert(values) {
                const self = this;
                let newItem = new HudAddendumDetailDto(values);
                newItem.line = _.parseNumber(self.selectedMainItem.hudAddendumDetails.length, 0) + 1; // increment add new line
                newItem.hudAddendumMainID = self.selectedMainItem.hudAddendumMainID;
                newItem.hudMainID = self.hudMainId;

                self.selectedMainItem.hudAddendumDetails.push(newItem); // add to the detail grid

                let addDetailPromise = self.$api.HudAddendumsApi.addAddendumDetail(newItem);
                return self.$rqBusy.wait(addDetailPromise).then(result => {
                    self.$toast.success("Addendum Details Saved Successfully");
                });
            },
            onDetailGridUpdate(key, values) {
                const self = this;
                let itemDetailIndex = _.findIndex(self.selectedMainItem.hudAddendumDetails, detailItem => detailItem.line === key);
                if(itemDetailIndex < 0) return;

                let updatedDetailItem = new HudAddendumDetailDto(_.assign({}, self.selectedMainItem.hudAddendumDetails[itemDetailIndex], values));
                self.selectedMainItem.hudAddendumDetails[itemDetailIndex] = updatedDetailItem;

                let updateDetailPromise = self.$api.HudAddendumsApi.updateAddendumDetail(updatedDetailItem);
                return self.$rqBusy.wait(updateDetailPromise).then(result => {
                    self.$toast.success("Addendum Details Saved Successfully");
                });
            },
            onAddDetailAction(e) {
                if(!this.gridDetailInstance) return;
                this.gridDetailInstance.addRow();
            },
            onDeleteDetailItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedDetailItems = e.data;

                let okHandler = function (args) {
                    self.deleteDetailItems(selectedDetailItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected addendum detail(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            deleteDetailItems(detailItems) {
                const self = this;
                let deleteDetailPromise = self.$api.HudAddendumsApi.deleteAddendumDetails(detailItems);
                return self.$rqBusy.wait(deleteDetailPromise).then(result => {
                    self.fetchData();
                    _.each(detailItems, k => {
                        _.remove(self.selectedMainItem.hudAddendumDetails, i => i.hudAddendumMainID === k.hudAddendumMainID && i.line === k.line);
                    });

                    self.reorderAddendumItems();
                    self.gridDetailInstance.deselectAll();
                    self.gridDetailInstance.refresh();
                    self.$toast.success("Addendum(s) Saved Successfully");
                });
            },
            reorderAddendumItems() {
                const self = this;
                let line = 1;

                _.each(self.selectedMainItem.hudAddendumDetails, l => {
                    l.line = line;
                    line++;
                })
            },
            hasChanges(){
                //original data was empty and user added something
                if(this.originalData.length === 0 && this.items.length > 0){
                    return true;
                }
                //if the arrays are different length, then there are changes.  Items have been deleted and need to save
                if(this.items.length !== this.originalData.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(this.originalData, this.items);
                return changes.length > 0;
            },

         }
    };
</script>
