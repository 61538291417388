<template>
    <div class="content-wrapper search-view dupe-search h-theme">
        <rq-banner
            variant="error"
            title="Missing Required Field(s)"
            icon="fas fa-exclamation-triangle"
            :visible="showRequiredFieldBanner"
        />
        <rq-banner
            :message="requiredNumFieldMessage"
            variant="warn"
            :visible="showRequiredNumberFieldBanner"
        />
        <div class="rq-container">
            <rq-page-section borderless header-only>
                <template #header-actions>
                    <span class="fst-italic">{{requirementMessage}}</span>
                    <ul class="nav ms-auto">
                        <li class="nav-item">
                            <button
                                automation_id="btn_ds_search_for_order"
                                type="button"
                                class="btn btn-theme btn-block"
                                @click="searchOrders">Show Results
                            </button>
                        </li>
                    </ul>
                </template>
            </rq-page-section>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all section-group="duplicate-order-search-group" />
                </div>
            </div>
            <div @keyup.enter="searchOrders">
                <rq-section-card
                    v-for="(subset) of searchFieldSet.visibleSubsets"
                    :key="subset.id"
                    :title="`Search Criteria (${subset.label})`"
                    :ref="`field_section_${subset.id}`"
                    section-group="duplicate-order-search-group"
                    collapsible>
                    <div class="row">
                            <div class ="col ps-2">
                            <b-link
                                automation_id="lnk_clear_general_criteria"
                                class="btn btn-link btn-theme clear-criteria"
                                @click.prevent="clearCriteria(subset.id)">
                                Clear {{subset.label}} Search Criteria
                            </b-link>
                        </div>
                    </div>
                    <div class="row">
                        <div v-for="field in subset.visibleFields"
                            :key="field.fieldId"
                            class="col-12 col-md-6 col-lg-3">
                            <search-field-input
                                :searchField="field"
                                @fieldChanged="onSearchFieldChanged"
                                enable-required-indicator
                            />
                        </div>
                    </div>
                </rq-section-card>
                <rq-section-card
                    ref="resultSection"
                    title="Search Results"
                    section-group="duplicate-order-search-group"
                    collapsible
                    borderless>
                    <rqdx-action-data-grid
                        v-show="searchExecuted"
                        ref="dataGrid"
                        automation_id="dg_duplicateSearch"
                        :data-source="gridDataSource"
                        :config="gridConfig"
                        @selectionChanged="onGridSelectionChanged"
                        hide-search
                        fixed-header
                    />
                </rq-section-card>
            </div>
        </div>

        <Teleport
            v-if="dialogFooterAvailable"
            :to="`#${dialogFooterId}`">
            <div class="custom-footer">
                <section class="footer-left">
                </section>
                <section class="footer-right">
                    <button automation_id="btn_ds_cancel_modoal" type="button" class="btn btn-secondary" @click="$emit('close-dialog')">Cancel</button>
                    <span v-rq-tooltip="{ trigger:'hover', title: openDisabled ? openOrderTooltip : '' }">
                        <button automation_id="btn_ds_open_order" type="button" class="btn btn-theme ms-2" v-focus="!openDisabled" :disabled="openDisabled" @click="openSelectedOrder">Open File</button>
                    </span>
                    <span v-rq-tooltip="{ trigger:'hover', title: createDisabled ? createOrderTooltip : '' }">
                        <button automation_id="btn_ds_create_order" type="button" class="btn btn-theme ms-2" v-focus="focusCreate" :disabled="createDisabled" @click="createOrder">Create</button>
                    </span>
                </section>
            </div>
        </Teleport>
    </div>
</template>
<script>
    import converter from "number-to-words";
    import { mapState, mapGetters } from "vuex";
    import { DuplicateOrderSearchModel, SearchRequest, SortOption } from "@/shared/models/models";
    import { SearchFieldSet, SearchField, SearchLookupsModel } from "../models";
    import SearchFieldData from "../data/duplicateSearchFieldData";
    import SearchFieldInput from "./SearchFieldInput";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        components: { SearchFieldInput },
        props: {
            fileNumberLinksToOrder: { default: true },
            dialogContent: { default: false },
            prepopulate: { default: null },
            defaultFields: { default: null }
        },
        inject: {
            dialogFooterId: { from: "footerId", default: null },
            dialogFooterAvailable: { from: "footerAvailable", default: false }
        },
        data () {
            const self = this;
            return {
                searchLookups: [],
                searchResult: [],
                searchFieldSet: new SearchFieldSet(),
                searchExecuted: false,
                searchOptions: new DuplicateOrderSearchModel(),
                searchAllBranches: false,
                selectedOrder: { ordersID: 0 },
                openOrderTooltip: "Select a file to enable",
                createOrderTooltip: "Search to enable",
                goToOrderId: 0,
                gridDataSource: [],
                gridReady: true,
                isBusy: false,
                createDisabled: true,
                invalidFields: [],
                numRequiredValid: true,
                psOptions:  {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: true,
                    wheelPropagation: false,
                    interceptRailY: styles => ({ ...styles, height: 0 })
                },
                showResultGrid: false
            };
        },
        computed: {
            ...mapState({
                orderSummary: state => state.orders.orderSummary,
                duplicateSearchDetails: state => state.orders.duplicateSearchDetails,
                lookups: state => state.system.lookups
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            openDisabled () { return _.parseNumber(_.get(this, "selectedOrder.ordersID", 0), 0) === 0; },
            gridInstance () { return _.get(this, "$refs.dataGrid.gridInstance", null) || null; },
            numRequired() { return _.parseNumber(_.get(this, "duplicateSearchDetails.numberOfRequiredFields", null), 1); },
            isAnyFieldRequired() {
                return _.some(this.duplicateSearchDetails.fields, f => f.required)
            },
            requiredNumFieldMessage() {
                return `Please enter values for at least ${ converter.toWords(this.numRequired)} fields to search for a file.`;
            },
            requirementMessage() {
                let requiredFieldMessage = "Any required fields are marked with *.";
                let requiredNumberMessage = `Please enter values for at least ${ converter.toWords(this.numRequired)} fields to search for a file. ${requiredFieldMessage}`
                if(this.numRequired > 0)
                    return requiredNumberMessage;
                else if(this.isAnyFieldRequired)
                    return requiredFieldMessage;

                return "";
            },
            noData() { return _.isEmpty(this.searchResult); },
            showRequiredFieldBanner() { return this.invalidFields.length > 0; },
            showRequiredNumberFieldBanner() { return !this.showRequiredFieldBanner && !this.numRequiredValid },
            focusCreate() { return !this.createDisabled && this.noData; }
        },
        created () {
            const self = this;
            self.searchFieldSet = SearchFieldData.buildSearchFieldSet(self.duplicateSearchDetails.fields);
            self.initGridConfig();
            self.fetchData();
        },
        methods: {
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "gfNo", dataType: "string", caption: "File Number" },
                        DxGridUtils.dateColumn({
                            dataField: 'closeDate',
                        }),
                        { dataField: "status", dataType: "string" },
                        { dataField: "legalDescription", dataType: "string" },
                        { dataField: "salesPrice", dataType: "number", format: "currency" },
                        { dataField: "loanAmount", dataType: "number", format: "currency" },
                        { dataField: "lender", dataType: "string" },
                        { dataField: "buyerName", dataType: "string", caption: "Buyer" },
                        { dataField: "sellerName", dataType: "string", caption: "Seller" },
                        { dataField: "regionName", dataType: "string", caption: "Region", visible: false },
                        { dataField: "branchName", dataType: "string", caption: "Branch", visible: false },
                        DxGridUtils.dateColumn({
                            dataField: 'openDate',
                            visible: false,
                        }),
                        { dataField: "underwriter", dataType: "string", visible: false },
                        { dataField: "closeAgent", dataType: "string", visible: false },
                        { dataField: "escrowBank", dataType: "string", visible: false },
                        { dataField: "salesRep", dataType: "string", visible: false },
                        { dataField: "address", dataType: "string", visible: false },
                        { dataField: "propertyTaxId", dataType: "string", visible: false }
                    ],
                    noDataText: "No results found.",
                    remoteOperations: { paging: true, sorting: true },
                    // scrolling: { showScrollbar: "onHover", useNative: false },
                    selection: { mode: "single" },
                    storageKey: "duplicate_order_search_grid"
                };

                self.gridDataSource = {
                    key: "ordersID",
                    load: self.fetchGridData
                };
            },
            onSearchFieldChanged (searchField) {
                const self = this;
                if (searchField.fieldName === "orderRegId") {
                    self.getBranches(searchField.fieldValue, "orderBranch");
                }
                if (searchField.fieldName === "propertyState") {
                    self.getCounties(searchField.fieldValue, "propertyCountyId");
                }
            },
            setFieldOptions() {
                const self = this;

                self.searchLookups = new SearchLookupsModel(self.lookups);
                self.searchLookups.states = self.lookupHelpers.getStates();
                self.searchLookups.counties = [];
                self.searchFieldSet.setFieldOptions(self.searchLookups);

                let hasRegionsField = false;
                let hasBranchesField = false;
                _.forEach(self.searchFieldSet.allVisibleFields, f => {
                    if(f.fieldName === "orderRegId") {
                        hasRegionsField = true;
                        return hasBranchesField;
                    }
                    if(f.fieldName === "orderBranch") {
                        hasBranchesField = true;
                        return hasRegionsField;
                    }
                });

                let currentFileRegionId = _.getNumber(self, "orderSummary.regionId", 0);

                if (!hasBranchesField || hasRegionsField || currentFileRegionId <= 0) return;

                self.getBranches(currentFileRegionId, "orderBranch");
            },
            searchOrders () {
                const self = this;

                self.invalidFields = [];
                self.numRequiredValid = self.numRequired <= self.searchFieldSet.allFieldWithValues.length;
                if (!self.searchFieldSet.validate()) {
                    self.invalidFields = self.searchFieldSet.invalidFields.slice();
                }
                else if(self.numRequiredValid) {
                    self.searchExecuted = true;
                    self.gridInstance.clearSelection();
                    self.refreshGrid()
                    self.toggleResultGrid(true);
                    self.toggleFieldSections(false);
                    self.$nextTick()
                        .then(() => {
                            self.updateDimensions();
                        });
                }
            },
            fetchData () {
                const self = this;
                self.setFieldOptions();
                self.createDisabled = self.searchFieldSet.hasRequiredFields || self.numRequired > 0;
            },
            fetchGridData (loadOptions) {
                const self = this;
                let request = self.getCurrentSearchRequest();

                if (!self.searchExecuted || _.isEmpty(request.parameters)) {
                    self.searchResult = [];
                    return Promise.resolve({ data:[], totalCount:0 });
                }

                request.skip = _.parseNumber(loadOptions.skip, 0);
                request.take = _.parseNumber(loadOptions.take, 50);

                request.sortBy = [];
                if (loadOptions.sort) {
                    request.sortBy = _.map(loadOptions.sort, s => new SortOption({
                        field: s.selector,
                        direction: s.desc
                            ? SortOption.SortDirection.Descending
                            : SortOption.SortDirection.Ascending
                    }));
                }
                self.selectedOrder.ordersID = 0;
                let promise = self.$api.OrdersApi.getDuplicateSearchResult(request);
                return self.$rqBusy.wait(promise)
                    .then(response => {
                        self.searchFieldSet.clearValidation();
                        self.searchResult = response.results;
                        self.$nextTick().then(() => {
                            self.createDisabled = false;
                            self.createOrderTooltip = "";
                        });
                        return { data: response.results, totalCount: response.totalRecords };
                    })
                    .catch(error => {
                        self.$toast.error("Order search failed.");
                        console.error("Order search failed.", error);
                        return { data:[], totalCount: 0 };
                    });
            },
            getBranches (regionId, branchFieldName) {
                const self = this;
                let branchList = self.lookupHelpers.getBranches(regionId);
                self.searchLookups.branches = branchList;
                self.searchFieldSet.updateFieldOptions(branchFieldName, branchList);
            },
            getCounties (usState, countyFieldName) {
                const self = this;
                let countyList = self.lookupHelpers.getCountiesByState(usState);
                self.searchLookups.counties = countyList;
                self.searchFieldSet.updateFieldOptions(countyFieldName, countyList);
            },
            getCurrentSearchRequest () {
                const self = this;
                var searchParams = {};
                _.forEach(this.searchFieldSet.allChangedFields, field => {
                    let fieldParams = field.getSearchParameters(false);
                    _.forEach(fieldParams, p => { searchParams[p.key] = p.value; });
                });
                if(self.searchAllBranches) searchParams.allBranches = true;
                return new SearchRequest({
                    parameters: searchParams,
                    sortBy: [],
                    pageNumber: 1,
                    pageSize: 50,
                    pagingEnabled: true
                });
            },
            onGridSelectionChanged (e) {
                const self = this;
                var selectedData = e.selectedRowsData[0];
                if (selectedData) {
                    if (!selectedData.userAccessible) {
                        self.selectedOrder = null;
                        self.openOrderTooltip = "You do not have access to view the selected order.";
                        return false;
                    }
                    self.selectedOrder = selectedData;
                }
            },
            openSelectedOrder () {
                const self = this;
                if (self.selectedOrder) {
                    self.$emit("action", { name: "open", data: self.selectedOrder.ordersID });
                }
            },
            createOrder () {
                const self = this;
                self.$emit("action", { name: "create" });
            },
            clearCriteria(fieldSetId) {
                this.searchFieldSet.clearFieldsByFieldSet(fieldSetId);
                this.validationMessages = [];
            },
            updateDimensions () {
                if (!this.gridInstance) return;
                this.gridInstance.updateDimensions();
            },
            refreshGrid () {
                if (!this.gridInstance) return;
                this.gridInstance.refresh();
            },
            toggleResultGrid(show) {
                let section = this.$refs.resultSection;
                show ? section.expand() : section.collapse();
            },
            toggleFieldSections(show) {
                let sections = this.$refs;
                this.searchFieldSet.visibleSubsets.forEach(f => {
                    let item = sections[`field_section_${f.id}`][0];
                    show ? item.expand() : item.collapse();
                });
            }
        }
    };
</script>
