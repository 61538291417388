import OrderContactsMain from "./OrderContactsMain";
import OrderContacts from "./OrderContacts";
import { CompanyEdit, ContactEdit, CompanyContacts } from "@utilities/manage-companies/components";
import BuyerSellerPage from "../buyer-seller/BuyerSellerForm";
import { ContactsViewActions } from "@/router/actions/order-entry";


const noAccessMsg = "Access to orders is not authorized.  Please contact your administrator.";

export default {
    path: "contacts",
    component: OrderContactsMain,
    meta: {
        label: "File Contacts",
        include: true
    },
    children: [{
            name: "oe:oc:contacts",
            path: "",
            component: OrderContacts,
            meta: {
                include: false,
                entity: "Contact",
                extension: false,
                label: "File Contacts",
                logRouteAccess: true,
                actions: ContactsViewActions.LIST,
                accessKey: "OrderEntry",
                unauthorizedMessage: noAccessMsg,
                skipSaveOnLeave: true
            }
        },
        {
            name: "oe:oc:buyer",
            path: "buyer/:buyerSellerId?",
            component: BuyerSellerPage,
            meta: {
                entity: "Buyer",
                extension: false,
                label: "Buyer",
                logRouteAccess: true,
                actions: ContactsViewActions.BUYER,
                accessKey: "OrderEntry",
                unauthorizedMessage: noAccessMsg,
                skipSaveOnLeave: true,
                templateCategory: "bu"
            }
        },
        {
            name: "oe:oc:seller",
            path: "seller/:buyerSellerId?",
            component: BuyerSellerPage,
            meta: {
                entity: "Seller",
                extension: false,
                label: "Seller",
                logRouteAccess: true,
                actions: ContactsViewActions.SELLER,
                accessKey: "OrderEntry",
                unauthorizedMessage: noAccessMsg,
                skipSaveOnLeave: true,
                templateCategory: "se"
            }
        },
        {
            name: "oe:oc:company",
            path: "company/:companyId?",
            component: CompanyEdit,
            props: route => ({
                companyId: _.getNumber(route, "params.companyId", 0),
                showOrderLinks: true
            }),
            meta: {
                entity: "Company",
                extension: false,
                label: "Company",
                logRouteAccess: true,
                actions: ContactsViewActions.COMPANY,
                skipSaveOnLeave: true,
                unauthorizedMessage: noAccessMsg
            }
        },
        {
            name: "oe:oc:contact",
            path: "company/:companyId/contact/:contactId?",
            component: ContactEdit,
            props: route => ({
                contactId: _.getNumber(route, "params.contactId", 0),
                companyId: _.getNumber(route, "params.companyId", 0),
                companyName: _.get(route, "params.companyName", ""),
                companySalesRepStaffId: _.getNumber(route, "params.companySalesRepStaffId", null),
                showOrderLinks: true
            }),
            meta: {
                entity: "Contact",
                extension: false,
                label: "Contact",
                actions: ContactsViewActions.CONTACT,
                skipSaveOnLeave: true,
                unauthorizedMessage: noAccessMsg
            }
        },
        {
            name: "oe:oc:company-contacts",
            path: "company/:companyId/company-contacts",
            component: CompanyContacts,
            props: route => ({
                companyId: _.getNumber(route, "params.companyId", 0),
                companyName: _.get(route, "params.companyName", ""),
                regionId: _.getNumber(route, "params.regionId", 0),
                companySalesRepStaffId: _.getNumber(route, "params.companySalesRepStaffId", null),
                showOrderLinks: true,
                allowAdd: true
            }),
            meta: {
                entity: "Contact",
                extension: false,
                label: "Company Contacts",
                actions: ContactsViewActions.CONTACT,
                skipSaveOnLeave: true,
                unauthorizedMessage: noAccessMsg
            }
        }
    ]
};
