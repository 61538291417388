<template>
    <tr class="grid-row">
        <td class="rq-col-selection">
            <b-form-checkbox
                automation_id="chk_item_select"
                v-model="loanCostLineData.isSelected"
            />
        </td>
        <td v-if="includeAlta" class="rq-col-input rq-col-category">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_category' }"
                :defaultItemVisible="false"
                :disabled="readOnly"
                :items="altaCategories"
                v-model="loanCostLineData.category"
                value-expr="name"
                display-expr="name"
            />
        </td>
        <td v-if="isHud" class="rq-col-input rq-col-line-number">
            <rq-input-number
                v-model="loanCostLineData.hudLine"
                automation_id="txt_hudLine"
                readonly
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td :class="{
            'rq-col-description': true,
            'rq-col-input': !loanCostLineData.isOriginationPointLine,
            'rq-col-multi-input': loanCostLineData.isOriginationPointLine || loanCostLineData.isLoanDiscountLine,
            'rq-col-readonly': !canEditDescription
        }">
            <rq-input-number v-if="loanCostLineData.isOriginationPointLine || loanCostLineData.isLoanDiscountLine"
                defaultValue="0"
                decimals="3"
                maxValue="100"
                automation_id="txt_loan_percentage_rate"
                class="rq-clear-input-group flex-nowrap"
                cssClass="percent-input"
                v-model="loanCostLineData.originationPercent"
                input-group
                v-focus-select-all
                :readonly="readOnly">
                <template #append>
                    <span :class="{
                        'line-description-text': true,
                        'line-description-text-long':lineDescription.length > 50
                    }">{{lineDescription}}
                    </span>
                </template>
            </rq-input-number>

            <input v-else :id="`txt_orig_line_description_`+loanCostLineData.loanCostSectionDataID"
                :automation_id="`txt_orig_line_description_`+loanCostLineData.loanCostSectionDataID"
                ref="lineDescription"
                type="text"
                maxlength="150"
                :readonly="!canEditDescription"
                v-model="loanCostLineData.description"
                v-focus-select-all
            />
        </td>
        <td :class="{'rq-col-input rq-col-payee': true, 'rq-col-readonly': !canEditPayee }">
            <company-picker
                ref="refPayeeCompany"
                automation_id="pic_Payee_company"
                dialogTitle="Select Payee"
                :disabled="!canEditPayee"
                v-model="loanCostLineData.payeeCompany"
            />
        </td>
        <td v-if="isHud2010" :class="{'rq-col-input':true, 'rq-col-currency':true, 'rq-col-readonly': !canEditBorrowerOutside, 'overridden': loanCostLineData.isBorrowerOutsideOverridden && loanCostLineData.isOverridableLine}">
            <rq-input-number
                v-model="loanCostLineData.borrowerOutsideValue"
                defaultValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_breakout_amount"
                :readonly="!canEditBorrowerOutside"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td :class="{'rq-col-input rq-col-currency':true, 'rq-col-readonly': !canEditBorrowerAmount, 'overridden': loanCostLineData.isBorrowerAtClosingOverridden && loanCostLineData.isOverridableLine}">
            <rq-input-number
                v-model="loanCostLineData.borrowerAtClosingValue"
                defaultValue="0"
                prefix="$"
                decimals="2"
                :readonly="!canEditBorrowerAmount"
                automation_id="txt_borrower_at_closing_amount"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="isCdf" class="rq-col-input rq-col-currency" :class="{ 'rq-col-readonly': readOnly }">
            <rq-input-number
                v-model="loanCostLineData.borrowerBeforeClosing"
                defaultValue="0"
                prefix="$"
                decimals="2"
                :readonly="readOnly"
                automation_id="txt_borrower_before_closing_amount"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="!isWithOutSeller" class="rq-col-input rq-col-currency" :class="{ 'rq-col-readonly': !canEditSellerAmount }">
            <rq-input-number
                v-model="loanCostLineData.sellerAtClosing"
                defaultValue="0"
                prefix="$"
                decimals="2"
                :readonly="!canEditSellerAmount"
                automation_id="txt_borrower_at_closing_amount"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="isCdf && !isWithOutSeller" class="rq-col-input rq-col-currency" :class="{ 'rq-col-readonly': readOnly }">
            <rq-input-number
                v-model="loanCostLineData.sellerBeforeClosing"
                defaultValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_borrower_at_closing_amount"
                :readonly="readOnly"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td v-if="isCdf" class="col-paid-by-others text-right">{{formatAmount(loanCostLineData.paidByOthers)}}</td>
        <td v-if="isCdf" class="rq-col-cdf-section">
            <span class="text-nowrap">{{cdfSections[0].name}}</span>
        </td>
        <td v-if="isHud" class="rq-col-input rq-col-poc-whom" :class="{ 'rq-col-readonly': !canEditPOC }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_poc_who' }"
                :items="pocWhoOptions"
                value-expr="id"
                display-expr="name"
                :read-only="!canEditPOC"
                v-model="loanCostLineData.poc"
            />
        </td>
        <td v-if="isHud" class="rq-col-input rq-col-currency" :class="{ 'rq-col-readonly': !canEditPOC }">
            <rq-input-number
                v-model="loanCostLineData.pocAmount"
                defaultValue="0"
                minValue="0"
                prefix="$"
                decimals="2"
                automation_id="txt_poc_amount"
                :readonly="!canEditPOC"
                no-form-control-class
                v-focus-select-all
            />
        </td>
        <td class="rq-col-input rq-col-net-fund" :class="{ 'rq-col-readonly': !canEditNetFund }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_net_fund' }"
                value-expr="id"
                display-expr="name"
                :read-only="!canEditNetFund"
                :items="netFundOptions"
                v-model="loanCostLineData.netFund"
            />
        </td>
        <td v-if="isHud2010" class="rq-col-input rq-col-line-type rq-col-readonly">
            <input :id="`txt_hud_line_type_${loanCostLineData.loanCostSectionDataID}`" automation_id="txt_hud_line_type" class="form-control" type="text" readonly v-model="loanCostLineData.hudLineTypeDescription" />
        </td>
        <td class="rq-col-input rq-col-acct-code" :class="{ 'rq-col-readonly': !canEditAccountCode }">
            <dx-select-box
                :input-attr="{ automation_id: 'drp_acct_code' }"
                :items="accountCodeOptions"
                value-expr="id"
                display-expr="name"
                :read-only="!canEditAccountCode"
                v-model="loanCostLineData.accountCodeID"
                :use-item-text-as-title="true"
            />
        </td>
        <td v-if="includeAlta" class="rq-col-selection rq-col-print">
            <b-form-checkbox
                automation_id="chk_do_not_print"
                v-model="loanCostLineData.doNotPrint"
            />
        </td>
    </tr>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { CompanyPicker } from '@/shared/components/rq';
    import { SETTLEMENT_TYPE, POCWhoOptions, AltaCategories } from '@settlement/models';
    import { useCommonSettlementSecurity } from "../../../helpers";

    export default {
        name: "OriginationSectionLine",
        components: {
            CompanyPicker
        },
        props: {
            expandDetail: { type: Boolean, default: false },
            lineData: { type: Object, default: () => ({}) },
            readOnly: { type: Boolean, default: false },
            settlementType: { type: Number, default: SETTLEMENT_TYPE.CDF }
        },
        setup() {
            return useCommonSettlementSecurity();
        },
        data() {
            return {
                loanCostLineData: this.lineData,
                cdfSections: [
                    { id: 'A', name: 'A' }
                ],
                printOptions: [
                    { id: 0, name: 'No' },
                    { id: 1, name: 'Yes' }
                ]
            }
        },
        computed: {
            ...mapState({
                loans: state => state.orders.loans || [],
                isWithOutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
                includeAltaValue: state => _.getBool(state, "orders.orderSummary.includeAlta"),
                orderRegion: state => _.getNumber(state, "orders.order.regionID")
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            pocWhoOptions() { return POCWhoOptions.lookupItems; },
            netFundOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.NET_FUND); },
            accountCodeOptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, this.orderRegion, this.loanCostLineData.accountCodeID); },
            altaCategories() { return AltaCategories.lookupItems; },
            canAdjustBorrowerAtClosing() { return this.loanCostLineData.isOriginationPointLine || this.loanCostLineData.isLoanDiscountLine; },
            includeAlta() { return this.isCdf && this.includeAltaValue; },
            isCdf() { return this.settlementType === SETTLEMENT_TYPE.CDF; },
            isHud1974() { return this.settlementType === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.settlementType === SETTLEMENT_TYPE.HUD_2010; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            lineDescription() {
                return (this.isHud && (this.loanCostLineData.isOriginationPointLine || this.loanCostLineData.isLoanDiscountLine)) ? '% ' + this.loanCostLineData.description : this.loanCostLineData.description;
            },
            canEditDescription() {
                return !this.readOnly && !this.isPaidByOtherLine;
            },
            canEditPayee() {
                return !this.isPaidByOtherLine && !this.isHud2010DisclosureLine && !this.readOnly;
            },
            canEditBorrowerAmount() {
                if (this.isPaidByOtherLine || this.readOnly) return false;
                // For 2010 HUD there are business rules for certain inputs being editable
                if (!this.isHud2010) return true;
                // Business Rule: 2010 HUD - not editable if GFE1 or Disclosure line
                return !(this.loanCostLineData.isGFE1Line || this.isHud2010DisclosureLine || this.loanCostLineData.hudLine === 803);
            },
            canEditSellerAmount() {
                if (this.isPaidByOtherLine || this.readOnly) return false;
                // For 2010 HUD there are business rules for certain inputs being editable
                if (!this.isHud2010) return true;
                // Business Rule: 2010 HUD - not editable if GFE1 or Disclosure line
                return !(_.includes([801, 802], this.loanCostLineData.hudLine) || this.isHud2010DisclosureLine);
            },
            canEditBorrowerOutside() {
                if (this.isPaidByOtherLine || this.readOnly) return false;
                if (!this.isHud2010) return false;
                return this.loanCostLineData.isGFE1Line;
            },
            canEditPOC() {
                if (this.isPaidByOtherLine || this.readOnly) return false;
                if (!this.isHud2010) return true;

                // 2010 GFEA (803) Hud line cannot edit poc values
                return !(this.loanCostLineData.isGFEALine || this.isHud2010DisclosureLine);
            },
            canEditNetFund() {
                if (this.isPaidByOtherLine || this.readOnly) return false;
                if (!this.isHud2010) return true;

                // 2010 GFEA (803) Hud line cannot edit net fund values
                return !(this.loanCostLineData.isGFEALine || this.isHud2010DisclosureLine);
            },
            canEditAccountCode() {
                // Check account code configuration permission
                // If onlySysAdminCanEditAccountCode is set and if default line, only System Admin allowed permission to edit account code
                if (!_.isNil(this.loanCostLineData.defaultedSettlementType) && !this.allowEditAccountCode)
                    return false;

                return !(this.isPaidByOtherLine || this.isHud2010DisclosureLine || this.readOnly);
            },
            isHud2010DisclosureLine() { return this.isHud2010 && this.loanCostLineData.isDisclosureLine; },
            isPaidByOtherLine() { return !_.isNil(this.loanCostLineData.paidBySource) },
        },
        watch: {
            lineData(newVal) { this.loanCostLineData = newVal; },
            "loanCostLineData.originationPercent"(newValue, oldValue) {
                const self = this;
                if (newValue === oldValue) return;

                if (self.loanCostLineData.payeeCompanyID == null && self.loanCostLineData.isDefaulted === false) {
                    let selectedLoan = _.find(self.loans, function(l) { return l.loanID == self.loanCostLineData.loanID; });
                    self.loanCostLineData.payeeCompanyID = selectedLoan.lenderID;
                    self.loanCostLineData.payeeCompanyName = selectedLoan.lender;
                    self.loanCostLineData.isDefaulted = true;
                }

                let paidAtSeller = _.get(self, "loanCostLineData.sellerAtClosing", 0);
                let paidByOthers = _.get(self, "loanCostLineData.paidByOthers", 0);

                if (self.isHud2010) {
                    // For HUD 2010 adjust the borrowerOutside value
                    self.calculateUpdateBorrowerOutside(newValue, paidAtSeller, paidByOthers);
                    self.$emit("update803");
                }
                else {
                    // CDF or HUD 1974 adjust the borrowerAtClosing value
                    if (!this.canAdjustBorrowerAtClosing)  return;
                    self.calculateUpdateBorrowerAtClosing(newValue, paidAtSeller, paidByOthers);
                }
            },
            "loanCostLineData.paidByOthers"(newValue, oldValue) {
                if (newValue === oldValue) return;
                if (!this.canAdjustBorrowerAtClosing)  return;

                let percentage = _.get(this, "loanCostLineData.originationPercent", 0);
                let paidAtSeller = _.get(this, "loanCostLineData.sellerAtClosing", 0);
                this.calculateUpdateBorrowerAtClosing(percentage, paidAtSeller, newValue);
            },
            "loanCostLineData.sellerAtClosing"(newValue, oldValue) {
                if (newValue === oldValue) return;

                this.setLenderPayee();

                if (!this.canAdjustBorrowerAtClosing)  return;

                let percentage = _.get(this, "loanCostLineData.originationPercent", 0);
                let paidByOthers = _.get(this, "loanCostLineData.paidByOthers", 0);
                this.calculateUpdateBorrowerAtClosing(percentage, newValue, paidByOthers);
            },
            "loanCostLineData.loanID": {
                handler: function(newVal, oldValue) {
                    const self = this;
                    if (newVal === oldValue) return;
                },
                immediate: true,
            },
            "loanCostLineData.borrowerAtClosing"(newValue, oldValue) {
                const self = this;
                self.setLenderPayee();
            },
            "loanCostLineData.overrideAmountOfPaidForBorrowerAtClosing"(newValue, oldValue) {
                const self = this;
                self.setLenderPayee();
            },
            "loanCostLineData.overrideBorrowerOutside"(newValue, oldValue) {
                const self = this;

                if (newValue === oldValue) return;
                self.$emit("update803");

                self.setLenderPayee();
            },
            "loanCostLineData.borrowerBeforeClosing"(newValue, oldValue) {
                if (newValue === oldValue) return;
                // update associated fields for common settlement type lines identified by null
                if (this.loanCostLineData.defaultedSettlementType === null)
                    this.updateHudFieldsFromCdfField();
            },
            "loanCostLineData.sellerBeforeClosing"(newValue, oldValue) {
                if (newValue === oldValue) return;
                // update associated fields for common settlement type lines identified by null
                if (this.loanCostLineData.defaultedSettlementType === null)
                    this.updateHudFieldsFromCdfField();
            },
            "loanCostLineData.poc"(newValue, oldValue) {
                if (newValue === oldValue) return;
                // update associated fields for common settlement type lines identified by null
                if (this.loanCostLineData.defaultedSettlementType === null)
                    this.updateCdfFieldsFromHudField();
            },
            "loanCostLineData.pocAmount"(newValue, oldValue) {
                if (newValue === oldValue) return;
                // update associated fields for common settlement type lines identified by null
                if (this.loanCostLineData.defaultedSettlementType === null)
                    this.updateCdfFieldsFromHudField();
            },
        },
        methods: {
            updateHudFieldsFromCdfField() {
                // A Cdf field being update here so we want to update corresponding appropriate Hud fields on the same record to attempt to keep
                // business data in-sync between settelement types.
                // Note: These are fields that don't conflict or overlap between settlement types so we are able to adjust from UI since
                // they are hidden from one another. (borrowerAtClosing for instance can't be adjusted because all settlements commonly used that property and is handled
                // through switching settlement type on server side logic.)
                if (this.loanCostLineData.poc === 'B') {
                    this.loanCostLineData.pocAmount = this.loanCostLineData.borrowerBeforeClosing;
                }

                if (this.loanCostLineData.poc === 'S') {
                    this.loanCostLineData.pocAmount = this.loanCostLineData.sellerBeforeClosing;
                }
            },
            updateCdfFieldsFromHudField() {
                // A Hud field being update here so we want to update corresponding appropriate Cdf fields on the same record to attempt to keep
                // business data in-sync between settelement types.
                // Note: These are fields that don't conflict or overlap between settlement types so we are able to adjust from UI since
                // they are hidden from one another. (borrowerAtClosing for instance can't be adjusted because all settlements commonly used that property and is handled
                // through switching settlement type on server side logic.)
                if (this.loanCostLineData.poc === 'B') {
                    this.loanCostLineData.borrowerBeforeClosing = this.loanCostLineData.pocAmount;
                }

                if (this.loanCostLineData.poc === 'S') {
                    this.loanCostLineData.sellerBeforeClosing = this.loanCostLineData.pocAmount;
                }
            },
            calculateBorrowerAtClosing(percentage, paidAtSeller, paidByOthers) {
                const self = this;
                // Calculation for borrowerAtClosing
                // borrowerAtClose = (percentage x loan amount) - Paid By Others - Paid From Seller At Closing
                // Note: By design we are allowing negativee amounts
                let selectedLoan = _.find(self.loans, function(l) { return l.loanID == self.loanCostLineData.loanID; });
                let deductions = paidByOthers + paidAtSeller;
                let result = (percentage / 100) * selectedLoan.amount;

                return result - deductions;
            },
            calculateUpdateBorrowerAtClosing(percentage, paidAtSeller, paidByOthers) {
                const self = this;
                // Calculation for borrowerAtClosing
                // borrowerAtClose = (percentage x loan amount) - Paid By Others - Paid From Seller At Closing

                let calculation = self.calculateBorrowerAtClosing(percentage, paidAtSeller, paidByOthers);
                self.loanCostLineData.borrowerAtClosing = calculation;
            },
            calculateUpdateBorrowerOutside(percentage, paidAtSeller, paidByOthers) {
                const self = this;
                // Calculation for borrowerAtClosing
                // borrowerAtClose = (percentage x loan amount) - Paid By Others - Paid From Seller At Closing

                let calculation = self.calculateBorrowerAtClosing(percentage, paidAtSeller, paidByOthers);
                self.loanCostLineData.misc_Dollar = calculation;
            },
            setLenderPayee() {
                const self = this;
                // Set CompanyPayeeId if amount changed > 0 and lender is defined
                let selectedLoan = _.find(self.loans, function(l) { return l.loanID == self.loanCostLineData.loanID; });
                if (_.parseNumber(selectedLoan.lenderID, 0) > 0 && _.parseNumber(self.loanCostLineData.payeeCompanyID, 0) === 0) {
                    self.loanCostLineData.payeeCompanyID = selectedLoan.lenderID;
                    self.loanCostLineData.payeeCompanyName = selectedLoan.lender;
                }
            },
            formatAmount(amount) {
                return amount != null ? accounting.formatMoney(amount) : "";
            },
            focusDescription() {
                _.invoke(this, "$refs.lineDescription.focus");
            }
        }

    };
</script>
