<template>
    <rq-action-form-group
        :label="field.label"
        :action-automation-id="`btn_${field.key}_RestoreDefault`"
        action-label="Restore Default"
        action-class="overridden"
        :show-action="isOverridden"
        @action-click="onRestoreDefault">
        <div class="input-group">
            <input v-if="field.rows === 1"
                :id="field.key"
                :automation_id="`txt_${field.key}`"
                :placeHolder="field.placeholder"
                class="form-control"
                :disabled="readOnly"
                v-model="textValue"
                @input="onInput"
            />
            <textarea v-else
                :id="field.key"
                :automation_id="field.key"
                :placeHolder="field.placeholder"
                :rows="field.rows"
                class="form-control"
                :disabled="readOnly"
                v-model="textValue"
                @input="onInput">
            </textarea>
            <b-btn ref="expandedAreaButton" variant="theme" :disabled="readOnly || dialogActive" @click="showEditDialog" v-rq-tooltip.hover.top title="Expand Work Area">
                <FontAwesomeIcon icon="fas fa-edit" aria-hidden="true" />
            </b-btn>
        </div>
    </rq-action-form-group>
</template>

<script>
    import { ref } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { CommitmentDataDto } from "../models";
    import { MODULE_EVENTS } from "../enums";
    import { OrderServiceType } from "@/shared/models/enums";
    import { useVModel } from "@vueuse/core";

    export default {
        name: "TitleProductionRtfField",

        props: {
            loanId: { type: Number, default: 0 },
            field: { type: Object, required: true },
            commitmentData: { type: Object, default: () => new CommitmentDataDto() },
            originalData: { type: Object, default: () => new CommitmentDataDto() }
        },

        emits: ["update:commitmentData"],

        setup(props, { emit }) {
            const dialogActive = ref(false);
            const commitmentDataValue = useVModel(props, "commitmentData", emit);
            return {
                dialogActive,
                commitmentDataValue
            };
        },

        computed: {
            ...mapGetters([
                "getDefaultSectionCategoryId",
                "getSectionCategories"
            ]),
            ...mapState({
                commitmentDefaults: state => state.orders.commitmentDefaults,
                standardLanguageSections: state => state.orders.standardLanguageSections || [],
                currentFileNo: state => state.orders.orderSummary.fileNumber,
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                readOnly: state => _.parseBool(state.isPageReadOnly) || _.parseBool(state.orders.orderSummary.isLocked),
                overwriteVestingInfo: state => _.getBool(state, "orders.branch.overrideOrderOptionsVestingInformationSettings", false),
                branchSettings: (state) => state.orders.branch,
                systemDefaults: (state) => state.system.systemDefaults,
            }),
            localSecurity(){ return this.securitySettings.findValues(["CanBuildCommitPolicyPrompts"]); },
            includePrompt() { return this.field.includePrompt && this.localSecurity.CanBuildCommitPolicyPrompts; },
            includePromptValues() { return this.field.includePromptValues && this.localSecurity.CanBuildCommitPolicyPrompts; },
            customFields() {
                const self = this;
                let fields = _.get(self, "field.customFields", null) || [];
                if(_.isEmpty(fields)) return [];
                return _.map(fields, fld => ({ key: fld.key, label: fld.label, value: self.getDefaults(fld.key).text }));
            },
            originalRtfValue() { return this.getCommitmentValue(this.field.rtf, true); },
            originalTextValue() { return this.getCommitmentValue(this.field.text, true); },
            rtfValue: {
                get() { return this.getCommitmentValue(this.field.rtf); },
                set(val) { this.setCommitmentValue(this.field.rtf, val); }
            },
            textValue: {
                get() { return this.getCommitmentValue(this.field.text); },
                set(val) {
                    this.setCommitmentValue(this.field.text, _.trim(val));
                }
            },
            isOverridden: {
                get() {
                    let defaults = this.getDefaults();
                    let noValue = _.isEmpty(_.trim(this.textValue)) && _.isEmpty(_.trim(defaults.text));
                    return this.field.hasOverride && defaults.textAsRtf !== this.rtfValue && !noValue;
                },
                set(val) {
                    if(!this.field.hasOverride) return;
                    this.setCommitmentValue(this.field.override, val);
                }
            }
        },

        methods: {
            onRestoreDefault(e) {
                const self = this;
                let defaults = self.getDefaults();
                self.textValue = defaults.text;
                self.rtfValue = defaults.textAsRtf;
                self.isOverridden = false;
            },

            onInput(e) {
                this.rtfValue = this.textToRtf(this.textValue);
            },

            showEditDialog (e) {
                const self = this;
                self.dialogActive = true;
                let fieldName = _.get(self, "field.sectionName", null);
                let rtfContent = self.rtfValue || self.textToRtf();
                self.$events.emit(MODULE_EVENTS.EDIT_RTF_CONTENT, {
                    title: `Edit ${self.field.label}`,
                    documentName: `${self.field.label}.rtf`,
                    documentContent: rtfContent,
                    customFields: self.customFields,
                    showInsertClauses: self.field.includeStandardLanguage,
                    defaultCategoryId: self.getDefaultSectionCategoryId(fieldName),
                    categories: self.getSectionCategories(fieldName),
                    showPrompt: self.includePrompt,
                    showPromptValues: self.includePromptValues,
                    resultFormats: { html: true, rtf: true, fragment: true },
                    onComplete(contentResult) {
                        self.textValue = _.htmlToText(contentResult.html, true);
                        self.rtfValue = contentResult.rtf;
                        self.dialogActive = false;
                    },
                    onCancelled() {
                        _.invoke(self, "$refs.expandedAraButton.focus");
                        self.dialogActive = false;
                    }
                });
            },

            textToRtf(value="") { return `{\\rtf1\\ansi\\ansicpg1252\\deff0{\\fonttbl{\\f0\\fnil\\fcharset0 Tahoma;}}\\viewkind4\\uc1\\pard\\lang1033\\f0\\fs22 ${value}\\par}`; },

            getCommitmentValue(name, original=false) {
                let expr = this.getCommitmentValueExpr(name, original);
                if(_.isEmpty(expr)) return null;
                return _.get(this, expr, null);
            },

            setCommitmentValue(name, val, original=false) {
                let expr = this.getCommitmentValueExpr(name, original);
                if(_.isEmpty(expr)) return;
                _.set(this, expr, val);
            },

            getCommitmentValueExpr(name, original) {
                let dataExpr = original ? "originalData" : "commitmentDataValue";
                return `${dataExpr}.${this.field.entity}.${name}`;
            },

            getDefaultKey() {
                let defKey = this.field.default;
                let defLabel = this.field.label;

                let showOnHolderBuyer = this.overwriteVestingInfo ? this.branchSettings.showOnCurrentTitleHoldersBuyer : this.systemDefaults.showOnCurrentTitleHoldersBuyer;
                let showOnHolderSeller = this.overwriteVestingInfo ? this.branchSettings.showOnCurrentTitleHoldersSeller : this.systemDefaults.showOnCurrentTitleHoldersSeller;
                let showOnOwnerInsured = this.overwriteVestingInfo ? this.branchSettings.showOnOwnerInsured : this.systemDefaults.showOnOwnerInsured;
                let showOnMortgageBorrower = this.overwriteVestingInfo ? this.branchSettings.showOnMortgageBorrower : this.systemDefaults.showOnMortgageBorrower;
                let showOnVestedBuyer = this.overwriteVestingInfo ? this.branchSettings.showOnTitleVestedInBuyer : this.systemDefaults.showOnTitleVestedBuyer;
                let showOnVestedSeller = this.overwriteVestingInfo ? this.branchSettings.showOnTitleVestedInSeller : false;//This needs to be fixed, config option is not mapped in the file settings screen

                if (defKey === "Lender") return `Lender_${this.loanId}`;

                if (defKey === "BuyerNames" && defLabel === "Current Title Holder/Title Vested")
                        return showOnHolderBuyer
                        ? `${defKey}WithVesting`
                         : defKey;

                if (defKey === "SellerNames" && defLabel === "Current Title Holder/Title Vested")
                        if(this.orderSummary.sellerID == null)
                            return showOnHolderBuyer
                            ? `${defKey}WithVesting`
                             : defKey;
                        else
                            return showOnHolderSeller
                            ? `${defKey}WithVesting`
                             : defKey;

                if(defLabel === "Owner Insured")
                        return showOnOwnerInsured
                        ? `${defKey}WithVesting`
                        : defKey;
                if (defLabel === "Mortgage Borrower")
                        return showOnMortgageBorrower
                        ? `${defKey}WithVesting`
                        : defKey;
                if (defLabel === "Title Vested"){
                    if(this.orderSummary.service == OrderServiceType.TxBinder
                    && this.orderSummary.sellerID != null ){
                        defKey = 'SellerNames'

                        return showOnVestedSeller
                        ? `${defKey}WithVesting`
                        : defKey;
                    } else {
                        return showOnVestedBuyer
                        ? `${defKey}WithVesting`
                        : defKey;
                    }
                }
                //old logic commented out for 17375
                //if((defKey === "BuyerNames" || defKey === "SellerNames") && !this.excludeVestingInfo) return `${defKey}WithVesting`;
                return `${defKey}WithVesting`;
            },

            getDefaults(keyVal) {
                let key = _.isEmpty(keyVal) ? this.getDefaultKey() : keyVal;
                let result = _.find(this.commitmentDefaults, { key });
                return _.isNil(result)
                    ? { key, text: this.originalTextValue, textAsRtf: this.originalRtfValue }
                    : { key: result.key, text: result.text, textAsRtf: _.isEmpty(result.text) ? null : result.textAsRtf };
            }
        }
    };
</script>
