<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner"
        />
        <div class="row">
            <div class="col col-6 form-group">
                <label for="txt_primary_file">Primary File</label>
                <input
                    automation_id="txt_primary_file"
                    class="form-control"
                    type="text"
                    readonly
                    v-model="settings.gfNo"
                    />
            </div>
            <div :class="{ 'col col-6 form-group form-required':true, 'has-error':v$.numOfFilesToCreate.$error }" v-if="isCreateMode && !settings.setConfigOnly">
                <label for="txt_no_secondary_files">Number of new files</label>
                <rq-input-number
                    automation_id="txt_no_secondary_files"
                    ref="txt_no_secondary_files"
                    formatType="number"
                    defaultValue=""
                    :minValue="1"
                    :maxValue="500"
                    prepend-text="#"
                    decimals="0"
                    input-group
                    allow-null
                    no-prefix
                    v-model="v$.numOfFilesToCreate.$model"
                />
                <rq-validation-feedback
                    :messages="{ 'Number is required': v$.numOfFilesToCreate.$invalid,
                                 'Number must be between 1 and 500': v$.numOfFilesToCreate.isInRange.$invalid,
                                }"
                />
            </div>
        </div>
        <rq-tabs
            :tabs="tabItems"
            v-model="tabIndex"
            class="secondary-file-tab-content"
            >
            <template #main>
                <div class="row ps-3">
                    <div class="col-12 mb-3">
                        <b-form-checkbox
                            :automation_id="elementName('main', 'select all')"
                            :id="elementName('Main', 'select all')"
                            v-model="selectAllMain"
                            @change="onSelectAll($event, 'Main')"
                            >Select All
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row ps-3">
                    <div class="col-md-6 col-lg-6 mb-lg-6 mb-xl-6" v-for="(group, index) in itemsByTabByChunk('Main')" :key="index">
                        <b-form-checkbox v-for="item in group"
                            :key="item.name"
                            :automation_id="elementName(item.tab, item.name)"
                            v-model="item.selected"
                            :disabled="item.disabled"
                            @change="onChangeCheckbox('Main')"
                            >{{item.name}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
            <template #buyer>
                <div class="row ps-3">
                    <div class="col-12 mb-3">
                        <b-form-checkbox
                            :automation_id="elementName('Buyer', 'select all')"
                            :id="elementName('Buyer', 'select all')"
                            v-model="selectAllBuyer"
                            @change="onSelectAll($event, 'Buyer')"
                            >Select All
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row ps-3">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3">
                        <b-form-checkbox v-for="item in itemsByTab('Buyer')"
                            :key="item.name"
                            :automation_id="elementName(item.tab, item.name)"
                            v-model="item.selected"
                            :disabled="item.disabled"
                            @change="onChangeCheckbox('Buyer')"
                            >{{item.name}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
            <template #seller>
                <div class="row ps-3">
                    <div class="col-12 mb-3">
                        <b-form-checkbox
                            :automation_id="elementName('Seller', 'select all')"
                            :id="elementName('Seller', 'select all')"
                            v-model="selectAllSeller"
                            @change="onSelectAll($event, 'Seller')"
                            >Select All
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row ps-3">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3">
                        <b-form-checkbox v-for="item in itemsByTab('Seller')"
                            :key="item.name"
                            :automation_id="elementName(item.tab, item.name)"
                            v-model="item.selected"
                            :disabled="item.disabled"
                            @change="onChangeCheckbox('Seller')"
                            >{{item.name}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
            <template #property>
                <div class="row ps-3">
                    <div class="col-12 mb-3">
                        <b-form-checkbox
                            :automation_id="elementName('Property', 'select all')"
                            :id="elementName('Property', 'select all')"
                            v-model="selectAllProperty"
                            @change="onSelectAll($event, 'Property')"
                            >Select All
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row ps-3">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3">
                        <b-form-checkbox v-for="item in itemsByTab('Property')"
                            :key="item.name"
                            :automation_id="elementName(item.tab, item.name)"
                            v-model="item.selected"
                            :disabled="item.disabled"
                            @change="onChangeCheckbox('Property')"
                            >{{item.name}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
            <template #loan>
                <div class="row ps-3">
                    <div class="col-12 mb-3">
                        <b-form-checkbox
                            :automation_id="elementName('Loan', 'select all')"
                            :id="elementName('Loan', 'select all')"
                            v-model="selectAllLoan"
                            @change="onSelectAll($event, 'Loan')"
                            >Select All
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row ps-3">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3">
                        <b-form-checkbox v-for="item in itemsByTab('Loan')"
                            :key="item.name"
                            :automation_id="elementName(item.tab, item.name)"
                            v-model="item.selected"
                            :disabled="item.disabled"
                            @change="onChangeCheckbox('Loan')"
                            >{{item.name}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
            <template #title>
                <div class="row ps-3">
                    <div class="col-12 mb-3">
                        <b-form-checkbox
                            :automation_id="elementName('Title', 'select all')"
                            :id="elementName('Title', 'select all')"
                            v-model="selectAllTitle"
                            @change="onSelectAll($event, 'Title')"
                            >Select All
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row ps-3">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3">
                        <b-form-checkbox v-for="item in itemsByTab('Title')"
                            :key="item.name"
                            :automation_id="elementName(item.tab, item.name)"
                            v-model="item.selected"
                            :disabled="item.disabled"
                            @change="onChangeCheckbox('Title')"
                            >{{item.name}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
            <template #escrow>
                <div class="row ps-3">
                    <div class="col-12 mb-3">
                        <b-form-checkbox
                            :automation_id="elementName('Escrow', 'select all')"
                            :id="elementName('Escrow', 'select all')"
                            v-model="selectAllEscrow"
                            @change="onSelectAll($event, 'Escrow')"
                            >Select All
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row ps-3">
                    <div class="col-md-6 col-lg-5 mb-lg-0 mb-xl-3">
                        <b-form-checkbox v-for="item in itemsByTab('Escrow')"
                            :key="item.name"
                            :automation_id="elementName(item.tab, item.name)"
                            v-model="item.selected"
                            :disabled="item.disabled"
                            @change="onChangeCheckbox('Escrow')"
                            >{{item.name}}
                        </b-form-checkbox>
                    </div>
                </div>
            </template>
        </rq-tabs>
        <div class="alert alert-warning mt-2" v-if="!isCreateMode && !settings.setConfigOnly">
            <FontAwesomeIcon icon="fa fa-exclamation-circle" class="alert-icon pe-1" /> Any selection will overwrite the corresponding data for the {{numOfFilesToUpdate}} selected Secondary Files.
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";
    import { SecondaryFileMergeDto } from "../models";

    export default {
        name: "NewSecondaryFileDialog",
        props: {
            settings: {type: Object, required: true}
        },
        components: {  },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                errorMessage: null,
                tabIndex: 0,
                numOfFilesToCreate: null,
                numOfFilesToUpdate: 0,
                mergeSettings: {},
                selectAllMain: true,
                selectAllBuyer: true,
                selectAllSeller: true,
                selectAllProperty: true,
                selectAllLoan: true,
                selectAllTitle: true,
                selectAllEscrow: true,
                chunkSize: 10
            };
        },

        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                    if (!newValue) this.errorMessage = null;
                },
                deep: true,
                immediate: true
            },
        },

        computed: {
            ...mapState({
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                userInfo: state => state.authentication.session.user
            }),
            defaultSettings() {
                return [
                    { tab: 'Main', name: 'Sales Price', selected: true, disabled: false},
                    { tab: 'Main', name: 'Owner Policy Liability Override', selected: true, disabled: false},
                    { tab: 'Main', name: 'Process Template', selected: true, disabled: false},
                    { tab: 'Main', name: 'Escrow Account', selected: true, disabled: false},
                    { tab: 'Main', name: 'Escrow Unit', selected: true, disabled: false},
                    { tab: 'Main', name: 'Title Unit', selected: true, disabled: false},
                    { tab: 'Main', name: 'File Category', selected: true, disabled: false},
                    { tab: 'Main', name: 'Underwriter', selected: true, disabled: false},
                    { tab: 'Main', name: 'Place Of Closing', selected: true, disabled: false},
                    { tab: 'Main', name: 'Location', selected: true, disabled: false},
                    { tab: 'Main', name: 'Source Of Business', selected: true, disabled: false},
                    { tab: 'Main', name: 'Staff', selected: true, disabled: false},
                    { tab: 'Main', name: 'Critical Dates', selected: true, disabled: false},
                    { tab: 'Main', name: 'Friendly Name', selected: true, disabled: false},
                    { tab: 'Main', name: 'Custom Data', selected: true, disabled: false},
                    { tab: 'Buyer', name: 'Buyer Name', selected: true, disabled: false},
                    { tab: 'Buyer', name: 'Relationship', selected: true, disabled: false},
                    { tab: 'Buyer', name: 'Vesting Info', selected: true, disabled: false},
                    { tab: 'Buyer', name: 'Address', selected: true, disabled: false},
                    { tab: 'Buyer', name: 'Forwarding Address', selected: true, disabled: false},
                    { tab: 'Buyer', name: 'Parties', selected: true, disabled: false},
                    { tab: 'Buyer', name: 'Custom Data', selected: true, disabled: false},
                    { tab: 'Seller', name: 'Seller Name', selected: true, disabled: false},
                    { tab: 'Seller', name: 'Relationship', selected: true, disabled: false},
                    { tab: 'Seller', name: 'Vesting Info', selected: true, disabled: false},
                    { tab: 'Seller', name: 'Address', selected: true, disabled: false},
                    { tab: 'Seller', name: 'Forwarding Address', selected: true, disabled: false},
                    { tab: 'Seller', name: 'Parties', selected: true, disabled: false},
                    { tab: 'Seller', name: 'Custom Data', selected: true, disabled: false},
                    { tab: 'Property', name: 'Property Details', selected: true, disabled: false},
                    { tab: 'Property', name: 'Tax Identifiers', selected: true, disabled: false},
                    { tab: 'Property', name: 'Taxes', selected: true, disabled: false},
                    { tab: 'Property', name: 'Brief Legal Description', selected: true, disabled: false},
                    { tab: 'Property', name: 'Brief Legal Details', selected: true, disabled: false},
                    { tab: 'Property', name: 'Tax Inquiry Transactions', selected: true, disabled: false},
                    { tab: 'Property', name: 'Tax Inquiry Valuation', selected: true, disabled: false},
                    { tab: 'Property', name: 'Tax Inquiry Current Tax Info', selected: true, disabled: false},
                    { tab: 'Property', name: 'Tax Inquiry Assessments', selected: true, disabled: false},
                    { tab: 'Property', name: 'Custom Data', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Lender', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Loan Number', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Mort. Ins. Case Number', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Loan Amount', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Loan Policy Liability Override', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Monthly Principal', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Monthly Interest', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Interest Rate', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Term', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Mortgage Insurance', selected: true, disabled: false},
                    { tab: 'Loan', name: 'Custom Data', selected: true, disabled: false},
                    { tab: 'Title', name: 'Effective Date/Time', selected: true, disabled: false},
                    { tab: 'Title', name: 'Issue Date/Time', selected: true, disabled: false},
                    { tab: 'Title', name: 'Commitment Number', selected: true, disabled: false},
                    { tab: 'Title', name: 'Owner Insured', selected: true, disabled: false},
                    { tab: 'Title', name: 'Mortgage Insured', selected: true, disabled: false},
                    { tab: 'Title', name: 'Mortgage Borrower', selected: true, disabled: false},
                    { tab: 'Title', name: 'Current Title Holder', selected: true, disabled: false},
                    { tab: 'Title', name: 'Interest In Land', selected: true, disabled: false},
                    { tab: 'Title', name: 'Legal Description', selected: true, disabled: false},
                    { tab: 'Title', name: 'Schedule B and C', selected: true, disabled: false},
                    { tab: 'Title', name: 'Schedule D', selected: true, disabled: false},
                    { tab: 'Escrow', name: 'Settlement Statements', selected: true, disabled: false},
                    { tab: 'Escrow', name: 'Invoices', selected: true, disabled: false}
                ];
            },
            localSecurity() { return this.securitySettings.findValues(["AllowSecondaryFileCreate"]); },
            showBanner() { return _.size(this.errorMessage) > 0; },
            isAlta() { return _.getBool(this, "orderSummary.isAlta"); },
            isCreateMode() { return _.isEqual(this.settings.mode, 'Create'); },
            tabItems() {
                return [
                    { automation_id: "btn_tab_secondary_file_main", name: "main", title: this.tabTitle("Main") },
                    { automation_id: "btn_tab_secondary_file_buyer", name: "buyer", title: this.tabTitle("Buyer") },
                    { automation_id: "btn_tab_secondary_file_seller", name: "seller", title: this.tabTitle("Seller") },
                    { automation_id: "btn_tab_secondary_file_property", name: "property", title: this.tabTitle("Property") },
                    { automation_id: "btn_tab_secondary_file_loan", name: "loan", title: this.tabTitle("Loan") },
                    { automation_id: "btn_tab_secondary_file_title", name: "title", title: this.tabTitle("Title") },
                    { automation_id: "btn_tab_secondary_file_escrow", name: "escrow", title: this.tabTitle("Escrow") },
                ];
            },
        },

        created () {
            this.init();
        },

        validations() {
            const self = this;

            var result = {
                numOfFilesToCreate: {
                    required: requiredIf(() => !self.settings.setConfigOnly && _.isEqual(this.settings.mode, 'Create')),
                    isInRange(val) {
                        if (self.settings.setConfigOnly) return true;
                        if (!_.isEqual(this.settings.mode, 'Create')) return true;
                        return _.gt(val, 0) && _.lte(val, 500);
                    }
                }
            };
            return result;
        },

        methods: {
            elementName(prefix="", item="", suffix="") {
                return _.snakeCase(`${prefix} ${item} ${suffix}`);
            },

            init() {
                if (_.isNullOrEmpty(_.get(this, "settings.settingsJSON"))) {
                    this.mergeSettings = _.cloneDeep(this.defaultSettings);
                    this.initSettings(false);
                } else {
                    this.mergeSettings = JSON.parse(this.settings.settingsJSON);
                    this.initSettings(true);
                }
                this.numOfFilesToUpdate = _.size(_.split(this.settings.updateIDList, ','));
            },

            initSettings(mergeNew) {
                if (mergeNew) {
                    const defs = _.cloneDeep(this.defaultSettings);
                    for (let x = 0; x < _.size(defs); x++) {
                        let def = defs[x];
                        if (!_.some(this.mergeSettings, { tab: def.tab, name: def.name })) {
                            //add back new settings, but deselcted them since they are new, let the user add it back
                            this.mergeSettings.splice(x, 0, {tab: def.tab, name: def.name, selected: false, disabled: false});
                        }
                    }
                }

                this.selectAllMain = _.every(this.itemsByTab('Main'), {selected: true, disabled: false});
                this.selectAllBuyer = _.every(this.itemsByTab('Buyer'), {selected: true, disabled: false});
                this.selectAllSeller = _.every(this.itemsByTab('Seller'), {selected: true, disabled: false});
                this.selectAllProperty = _.every(this.itemsByTab('Property'), {selected: true, disabled: false});
                this.selectAllLoan = _.every(this.itemsByTab('Loan'), {selected: true, disabled: false});
                this.selectAllTitle = _.every(this.itemsByTab('Title'), {selected: true, disabled: false});
                this.selectAllEscrow = _.every(this.itemsByTab('Escrow'), {selected: true, disabled: false});
                if (this.isAlta) {
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Transactions' }), {disabled: false});
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Valuation' }), {disabled: false});
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Current Tax Info' }), {disabled: false});
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Assessments' }), {disabled: false});
                } else {
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Transactions' }), {disabled: true, selected: false});
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Valuation' }), {disabled: true, selected: false});
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Current Tax Info' }), {disabled: true, selected: false});
                    _.assign(_.find(this.mergeSettings, { tab: 'Property', name: 'Tax Inquiry Assessments' }), {disabled: true, selected: false});
                }
            },

            itemsByTabByChunk(tabName) {
                return _.chunk(_.filter(this.mergeSettings, {tab: tabName}), this.chunkSize);
            },

            itemsByTab(tabName) {
                return _.filter(this.mergeSettings, {tab: tabName});
            },

            onChangeCheckbox(tabName) {
                let tab = `selectAll${_.replace(tabName, ' ', '')}`;
                let items = this.itemsByTab(tabName);
                _.set(this, tab, _.every(items, {selected: true}));
            },

            onSelectAll(value, tabName) {
                _.each(this.itemsByTab(tabName), i => {
                    if (!i.disabled) i.selected = value;
                });
            },

            save(){
                const self = this;
                self.errorMessage = null;
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }
                let data = new SecondaryFileMergeDto(self.settings);
                data.settingsJSON = JSON.stringify(self.mergeSettings);
                data.newFileCount = _.parseNumber(self.numOfFilesToCreate, 0);
                let apiPromise = self.$api.OrdersApi.secondaryFileMerge(data);
                return self.$rqBusy.wait(apiPromise);
            },

            selectedCountByTab(tabName) {
                return _.size(_.filter(this.mergeSettings, {tab: tabName, selected: true}));
            },

            tabTitle(tabName) {
                return `${tabName} (${this.selectedCountByTab(tabName)})`;
            },
        }
    };
</script>
<style lang="scss">
    .secondary-file-tab-content {
        .tab-content {
            height: 300px;
        }
    }
</style>
