import ClosingMarket from "./ClosingMarket";

export default {
    name: "closing-market",
    path: "closing-market",
    component: ClosingMarket,
    meta: {
        entity: "Closing Market",
        label: "Closing Market",
        logRouteAccess: true,
        skipSaveOnLeave: true,
        include: true
    }
};
