
import { SearchFieldSet, SearchField, SearchLookupsModel } from '../models';

const rawFieldSetData = [
    { id: 1, label: 'Main', fields: [] },
    { id: 2, label: 'Loan', fields: [] },
    { id: 3, label: 'Property', fields: [] },
    { id: 4, label: 'Commitment Policy', fields: [] },
    { id: 5, label: 'Web', fields: [] },
    { id: 6, label: 'Company', fields: [] },
    { id: 7, label: 'Buyer/Seller', fields: [] }
];

const rawFieldData = [
    { fieldId: 1, fieldSetId: 1, fieldLabel: 'File Number', fieldName: 'Gfno', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 50 },
    { fieldId: 2, fieldSetId: 1, fieldLabel: 'Global', fieldName: 'IsGlobal', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 3, fieldSetId: 1, fieldLabel: 'Region', fieldName: 'RegionId', dataType: 'int', fieldType: 'select', lookupKey: 'regions', isRange: false, maxLength: null },
    { fieldId: 4, fieldSetId: 1, fieldLabel: 'Branch', fieldName: 'BranchId', dataType: 'int', fieldType: 'select', lookupKey: 'branches', isRange: false, maxLength: null },
    { fieldId: 6, fieldSetId: 1, fieldLabel: 'Status', fieldName: 'Status', dataType: 'int', fieldType: 'select', lookupKey: 'statuses', isRange: false, maxLength: null },
    { fieldId: 7, fieldSetId: 1, fieldLabel: 'Open Date', fieldName: 'OpenDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 8, fieldSetId: 1, fieldLabel: 'Underwriter', fieldName: 'UnderWriterCompanyId', dataType: 'int', fieldType: 'select', lookupKey: 'underwriters', isRange: false, maxLength: null },
    { fieldId: 9, fieldSetId: 1, fieldLabel: 'Title Company', fieldName: 'TitleCompanyCompanyId', dataType: 'int', fieldType: 'select', lookupKey: 'titleCompanies', isRange: false, maxLength: null },
    { fieldId: 10, fieldSetId: 1, fieldLabel: 'Service', fieldName: 'Service', dataType: 'int', fieldType: 'select', lookupKey: 'orderServices', isRange: false, maxLength: null },
    { fieldId: 11, fieldSetId: 1, fieldLabel: 'Closed Date', fieldName: 'CloseDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 12, fieldSetId: 1, fieldLabel: 'Cancel Date', fieldName: 'UserDefinedDate1', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 13, fieldSetId: 1, fieldLabel: 'Bank', fieldName: 'BankCompanyId', dataType: 'int', fieldType: 'select', lookupKey: 'companies', isRange: false, maxLength: null },
    { fieldId: 14, fieldSetId: 1, fieldLabel: 'Closing Agent', fieldName: 'CloseAgentStaffId', dataType: 'int', fieldType: 'select', lookupKey: 'closingAgents', isRange: false, maxLength: null },
    { fieldId: 15, fieldSetId: 1, fieldLabel: 'File Category', fieldName: 'OrderCategoryId', dataType: 'int', fieldType: 'select', lookupKey: 'orderCategories', isRange: false, maxLength: null },
    { fieldId: 16, fieldSetId: 1, fieldLabel: 'Escrow Unit', fieldName: 'EscrowUnitId', dataType: 'int', fieldType: 'select', lookupKey: 'escrowUnits', isRange: false, maxLength: null },
    { fieldId: 17, fieldSetId: 1, fieldLabel: 'Other Companies File Number', fieldName: 'OtherCompaniesFileNumber', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 50 },
    { fieldId: 18, fieldSetId: 1, fieldLabel: 'Sales Rep', fieldName: 'SalesRepresentativeStaffId', dataType: 'int', fieldType: 'select', lookupKey: 'salesReps', isRange: false, maxLength: null },
    { fieldId: 19, fieldSetId: 1, fieldLabel: 'Escrow Processor', fieldName: 'EscrowProcessorStaffId', dataType: 'int', fieldType: 'select', lookupKey: 'escrowProcessors', isRange: false, maxLength: null },
    { fieldId: 20, fieldSetId: 1, fieldLabel: 'Title Unit', fieldName: 'TitleUnitId', dataType: 'int', fieldType: 'select', lookupKey: 'titleUnits', isRange: false, maxLength: null },
    { fieldId: 21, fieldSetId: 1, fieldLabel: 'Sales Price', fieldName: 'SalesPrice', dataType: 'float', fieldType: 'number', isRange: true, prefix: '$', decimals: 2, commas: true, maxLength: null },
    { fieldId: 22, fieldSetId: 1, fieldLabel: 'Multiple Bank Account', fieldName: 'MultipleBankAccount', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 23, fieldSetId: 1, fieldLabel: 'Document Management Activity', fieldName: 'FileScanActivity', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 24, fieldSetId: 1, fieldLabel: 'Title Officer', fieldName: 'TitleOfficerStaffId', dataType: 'int', fieldType: 'select', lookupKey: 'titleOfficers', isRange: false, maxLength: null },
    { fieldId: 25, fieldSetId: 1, fieldLabel: 'Title Processor', fieldName: 'TitleProcessorStaffId', dataType: 'int', fieldType: 'select', lookupKey: 'titleProcessors', isRange: false, maxLength: null },
    { fieldId: 26, fieldSetId: 1, fieldLabel: 'Escrow Officer', fieldName: 'EscrowOfficerStaffId', dataType: 'int', fieldType: 'select', lookupKey: 'escrowOfficers', isRange: false, maxLength: null },
    { fieldId: 27, fieldSetId: 1, fieldLabel: 'Friendly Name', fieldName: 'FriendlyName', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 100 },
    { fieldId: 28, fieldSetId: 1, fieldLabel: 'Source Of Business', fieldName: 'SourceOfBusinessId', dataType: 'int', fieldType: 'select', lookupKey: 'sourceOfBusinesses', isRange: false, maxLength: null },
    { fieldId: 29, fieldSetId: 1, fieldLabel: 'Note Date', fieldName: 'NoteDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 30, fieldSetId: 1, fieldLabel: 'Note Contains', fieldName: 'NoteContains', dataType: 'string', fieldType: 'text', isRange: false, maxLength: null },
    { fieldId: 31, fieldSetId: 1, fieldLabel: 'Fund/Disbursement Date', fieldName: 'FundDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 32, fieldSetId: 1, fieldLabel: 'Settlement Agent', fieldName: 'SettlementAgentCompanyId', dataType: 'int', isRange: false, maxLength: null, fieldType: 'picker', pickerDialogTitle: 'Select Settlement Agent Company', pickerRoleId: '28', pickerRoleName: 'Settlement Agent' },
    { fieldId: 33, fieldSetId: 2, fieldLabel: 'Kind Of File', fieldName: 'KindOfOrder', dataType: 'int', fieldType: 'select', lookupKey: 'orderKinds', isRange: false, maxLength: null },
    { fieldId: 34, fieldSetId: 2, fieldLabel: 'Loan Number', fieldName: 'LoanNumber', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 30 },
    { fieldId: 35, fieldSetId: 2, fieldLabel: 'MIC#', fieldName: 'MortInsCaseNumber', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 20 },
    { fieldId: 36, fieldSetId: 2, fieldLabel: 'Loan Amount', fieldName: 'LoanAmount', dataType: 'float', fieldType: 'number', isRange: true, prefix: '$', decimals: 2, commas: true, maxLength: null },
    { fieldId: 37, fieldSetId: 2, fieldLabel: 'Prior Loan Policy Number', fieldName: 'PriorLoanPolicyNumber', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 50 },
    { fieldId: 38, fieldSetId: 2, fieldLabel: 'Prior Loan Policy Date', fieldName: 'PriorLoanPolicyDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 39, fieldSetId: 2, fieldLabel: 'HUD Exists', fieldName: 'HUDExists', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 40, fieldSetId: 2, fieldLabel: 'CDF Exists', fieldName: 'CDFExists', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 41, fieldSetId: 2, fieldLabel: 'CSS Exists', fieldName: 'CSSExists', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 42, fieldSetId: 2, fieldLabel: 'Invoice Exists', fieldName: 'InvoiceExists', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 43, fieldSetId: 2, fieldLabel: 'Disbursed Checks Exist', fieldName: 'DisbursedChecksExist', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 44, fieldSetId: 2, fieldLabel: 'Booked Desposits Exist', fieldName: 'BookedDespositsExist', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 45, fieldSetId: 2, fieldLabel: 'Invoice Date', fieldName: 'InvoiceDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 46, fieldSetId: 2, fieldLabel: 'Invoice ID', fieldName: 'InvoiceId', dataType: 'int', fieldType: 'text', isRange: false, maxLength: null },
    { fieldId: 47, fieldSetId: 2, fieldLabel: 'Invoice Balance', fieldName: 'InvoiceBalance', dataType: 'float', fieldType: 'text', isRange: false, maxLength: null },
    { fieldId: 48, fieldSetId: 2, fieldLabel: 'Invoice Num User', fieldName: 'InvoiceNumUser', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 20 },
    { fieldId: 49, fieldSetId: 2, fieldLabel: 'Invoice Balance Exists', fieldName: 'InvoiceBalanceExists', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 50, fieldSetId: 2, fieldLabel: 'Cdf Disbursement Date', fieldName: 'CdfDisbursementDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 51, fieldSetId: 3, fieldLabel: 'Subdivision', fieldName: 'SubdivisionId', dataType: 'int', fieldType: 'select', lookupKey: 'subdivisions', isRange: false, maxLength: null },
    { fieldId: 52, fieldSetId: 3, fieldLabel: 'Property Type', fieldName: 'PropertyTypeId', dataType: 'int', fieldType: 'select', lookupKey: 'propertyTypes', isRange: false, maxLength: null },
    { fieldId: 53, fieldSetId: 3, fieldLabel: 'Block', fieldName: 'Block', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 6 },
    { fieldId: 54, fieldSetId: 3, fieldLabel: 'Lot', fieldName: 'Lot', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 55, fieldSetId: 3, fieldLabel: 'Survey', fieldName: 'SurveyId', dataType: 'int', fieldType: 'select', lookupKey: 'surveys', isRange: false, maxLength: null },
    { fieldId: 56, fieldSetId: 3, fieldLabel: 'Acreage', fieldName: 'Acreage', dataType: 'float', fieldType: 'number', isRange: true, prefix: '', decimals: 2, commas: true, maxLength: null },
    { fieldId: 57, fieldSetId: 3, fieldLabel: 'Municipality', fieldName: 'Municipality', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 50 },
    { fieldId: 58, fieldSetId: 3, fieldLabel: 'Unit', fieldName: 'Unit', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 59, fieldSetId: 3, fieldLabel: 'Tract', fieldName: 'Tract', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 60, fieldSetId: 3, fieldLabel: 'Book', fieldName: 'Book', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 61, fieldSetId: 3, fieldLabel: 'Page', fieldName: 'Page', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 62, fieldSetId: 3, fieldLabel: 'District', fieldName: 'District', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 15 },
    { fieldId: 63, fieldSetId: 3, fieldLabel: 'Phase', fieldName: 'Phase', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 64, fieldSetId: 3, fieldLabel: 'Part', fieldName: 'Part', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 65, fieldSetId: 3, fieldLabel: 'Parcel', fieldName: 'Parcel', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 66, fieldSetId: 3, fieldLabel: 'Building', fieldName: 'Building', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 67, fieldSetId: 3, fieldLabel: 'Instrument Number', fieldName: 'InstrumentNumber', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 25 },
    { fieldId: 68, fieldSetId: 3, fieldLabel: 'Platname', fieldName: 'Platname', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 50 },
    { fieldId: 69, fieldSetId: 3, fieldLabel: 'ARB Number', fieldName: 'ARBNumber', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 20 },
    { fieldId: 70, fieldSetId: 3, fieldLabel: 'Out Lot', fieldName: 'OutLot', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 71, fieldSetId: 3, fieldLabel: 'Range', fieldName: 'Range', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 5 },
    { fieldId: 72, fieldSetId: 3, fieldLabel: 'Township', fieldName: 'Township', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 5 },
    { fieldId: 73, fieldSetId: 3, fieldLabel: 'Section', fieldName: 'Section', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 10 },
    { fieldId: 74, fieldSetId: 3, fieldLabel: 'Qtr1', fieldName: 'Qtr1', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 2 },
    { fieldId: 75, fieldSetId: 3, fieldLabel: 'Qtr2', fieldName: 'Qtr2', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 2 },
    { fieldId: 76, fieldSetId: 3, fieldLabel: 'Qtr3', fieldName: 'Qtr3', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 2 },
    { fieldId: 77, fieldSetId: 3, fieldLabel: 'Address 1', fieldName: 'Address1', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 100 },
    { fieldId: 78, fieldSetId: 3, fieldLabel: 'Address 2', fieldName: 'Address2', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 100 },
    { fieldId: 79, fieldSetId: 3, fieldLabel: 'City', fieldName: 'City', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 30 },
    { fieldId: 80, fieldSetId: 3, fieldLabel: 'State', fieldName: 'State', dataType: 'string', fieldType: 'select', lookupKey: 'states', isRange: false, maxLength: 2 },
    { fieldId: 81, fieldSetId: 3, fieldLabel: 'Zip', fieldName: 'Zip', dataType: 'string', fieldType: 'text', isRange: true, prefix: '', decimals: 0, commas: false, maxLength: 10 },
    { fieldId: 82, fieldSetId: 3, fieldLabel: 'County', fieldName: 'CountyId', dataType: 'int', fieldType: 'select', lookupKey: 'counties', isRange: false, maxLength: null },
    { fieldId: 83, fieldSetId: 3, fieldLabel: 'Tax Identification', fieldName: 'TaxIdentification', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 35 },
    { fieldId: 84, fieldSetId: 4, fieldLabel: 'Effective Date', fieldName: 'CpEffectiveDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 85, fieldSetId: 4, fieldLabel: 'Issued Date', fieldName: 'CpIssuedDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 86, fieldSetId: 4, fieldLabel: 'Owner Policy Date', fieldName: 'OwnerPolicyDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 87, fieldSetId: 4, fieldLabel: 'Owner Policy Number', fieldName: 'OwnerPolicyNumber', dataType: 'string', fieldType: null, isRange: false, maxLength: 50 },
    { fieldId: 88, fieldSetId: 4, fieldLabel: 'Mortgage Policy Number', fieldName: 'MortgagePolicyNumber', dataType: 'string', fieldType: null, isRange: false, maxLength: 50 },
    { fieldId: 89, fieldSetId: 4, fieldLabel: 'Binder Number', fieldName: 'BinderNumber', dataType: 'string', fieldType: null, isRange: false, maxLength: 25 },
    { fieldId: 90, fieldSetId: 4, fieldLabel: 'Mortgage Policy Date', fieldName: 'MortgagePolicyDate', dataType: 'date', fieldType: 'date', isRange: true, maxLength: null },
    { fieldId: 91, fieldSetId: 5, fieldLabel: 'Publish Order To Web', fieldName: 'PublishOrderToWeb', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 92, fieldSetId: 5, fieldLabel: 'Document Management Docs Exist', fieldName: 'FileScanDocsExist', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 93, fieldSetId: 5, fieldLabel: 'PC Docs Exist', fieldName: 'PCDocsExist', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 94, fieldSetId: 5, fieldLabel: 'PC Activity', fieldName: 'PCActivity', dataType: 'bool', fieldType: 'checkbox', isRange: false, maxLength: null },
    { fieldId: 95, fieldSetId: 6, fieldLabel: 'Company 1', fieldName: 'CompanyId1', dataType: 'int', isRange: false, maxLength: null, fieldType: 'picker', pickerDialogTitle: 'Select Company', pickerRoleId: null, pickerRoleName: '' },
    { fieldId: 96, fieldSetId: 6, fieldLabel: 'Role Type 1', fieldName: 'RoleTypeId1', dataType: 'int', fieldType: 'select', lookupKey: 'roles', isRange: false, maxLength: null },
    { fieldId: 97, fieldSetId: 6, fieldLabel: 'Company 2', fieldName: 'CompanyId2', dataType: 'int', isRange: false, maxLength: null, fieldType: 'picker', pickerDialogTitle: 'Select Company', pickerRoleId: null, pickerRoleName: '' },
    { fieldId: 98, fieldSetId: 6, fieldLabel: 'Role Type 2', fieldName: 'RoleTypeId2', dataType: 'int', fieldType: 'select', lookupKey: 'roles', isRange: false, maxLength: null },
    { fieldId: 99, fieldSetId: 6, fieldLabel: 'Company 3', fieldName: 'CompanyId3', dataType: 'int', isRange: false, maxLength: null, fieldType: 'picker', pickerDialogTitle: 'Select Company', pickerRoleId: null, pickerRoleName: '' },
    { fieldId: 100, fieldSetId: 6, fieldLabel: 'Role Type 3', fieldName: 'RoleTypeId3', dataType: 'int', fieldType: 'select', lookupKey: 'roles', isRange: false, maxLength: null },
    { fieldId: 101, fieldSetId: 6, fieldLabel: 'Company 4', fieldName: 'CompanyId4', dataType: 'int', isRange: false, maxLength: null, fieldType: 'picker', pickerDialogTitle: 'Select Company', pickerRoleId: null, pickerRoleName: '' },
    { fieldId: 102, fieldSetId: 6, fieldLabel: 'Role Type 4', fieldName: 'RoleTypeId4', dataType: 'int', fieldType: 'select', lookupKey: 'roles', isRange: false, maxLength: null },
    { fieldId: 103, fieldSetId: 6, fieldLabel: 'Company 5', fieldName: 'CompanyId5', dataType: 'int', isRange: false, maxLength: null, fieldType: 'picker', pickerDialogTitle: 'Select Company', pickerRoleId: null, pickerRoleName: '' },
    { fieldId: 104, fieldSetId: 6, fieldLabel: 'Role Type 5', fieldName: 'RoleTypeId5', dataType: 'int', fieldType: 'select', lookupKey: 'roles', isRange: false, maxLength: null },
    { fieldId: 105, fieldSetId: 6, fieldLabel: 'Company 6', fieldName: 'CompanyId6', dataType: 'int', isRange: false, maxLength: null, fieldType: 'picker', pickerDialogTitle: 'Select Company', pickerRoleId: null, pickerRoleName: '' },
    { fieldId: 106, fieldSetId: 6, fieldLabel: 'Role Type 6', fieldName: 'RoleTypeId6', dataType: 'int', fieldType: 'select', lookupKey: 'roles', isRange: false, maxLength: null },
    { fieldId: 107, fieldSetId: 7, fieldLabel: 'Buyer Name', fieldName: 'BuyerName', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 300 },
    { fieldId: 108, fieldSetId: 7, fieldLabel: 'Seller Name', fieldName: 'SellerName', dataType: 'string', fieldType: 'text', isRange: false, maxLength: 300 }
];

const _defaultFields = [ 'Gfno', 'RegionId', 'OpenDate', 'CloseDate', 'BuyerName', 'SellerName', 'CompanyId1', 'RoleTypeId1' ];

class SearchFieldData {
    constructor () { }

    static async buildSearchFieldSet (lookups, prepopulate, defaultFields) {
        defaultFields = defaultFields || _defaultFields;
        const rootFieldSet = new SearchFieldSet();
        for (let fs of rawFieldSetData) {
            fs.fields = _.filter(rawFieldData, function (f) {return f.fieldSetId === fs.id;});
            _.forEach(fs.fields, f => {
                const isDefault = _.includes(defaultFields, f.fieldName);
                f.visible = isDefault;
                f.isDefault = isDefault;
                if (prepopulate && prepopulate.length > 0) {
                    const popField = _.find(prepopulate, ppf => {return ppf.fieldName === f.fieldName;});
                    if (popField) {
                        f.visible = true;
                        f.fieldValue = popField.fieldValue;
                    }
                }
                if (lookups && f.lookupKey) {f.fieldOptions = lookups[f.lookupKey];}
            });
            rootFieldSet.subsets.push(new SearchFieldSet(fs));
        }
        return rootFieldSet;
    }
}

export default SearchFieldData;
