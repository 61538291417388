<template>
    <div class="content-wrapper">
        <rq-banner
            :message="defaultMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && (v$.$error || !customDataIsValid)"
            dismissable
        />

        <rq-page-section :title="loanFormTitle" headerSize="lg">
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <button v-if="!isNewLoan" automation_id="btn_add_loan" ref="btn_add_loan" class="btn btn-theme" type="button" @click="onAddLoan" :disabled="isOrderLocked || isReadOnly">Add</button>
                    </li>
                    <li class="nav-item">
                        <b-button automation_id="btn_delete_loan" v-if="!isNewLoan" variant="theme" @click="onDeleteLoan" :disabled="disabledDelete">Delete</b-button>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-select-box
                            v-if="showLoanDropDown"
                            id="drp_loan"
                            :display-template="item => `Loan ${item.name}`"
                            :items="loanOptions"
                            size="sm"
                            @change="onLoanDropdownChange"
                            v-model="selectedLoanId"
                            :default-item-enabled="false"
                        />
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <router-link automation_id="btn_view_order_loans" v-show="multipleLoans" :to="{ name:'oe:ol:loans', params: { orderId } }" class="btn btn-link btn-theme">View File Loans</router-link>
            </template>
            <fieldset :disabled="readOnly" v-if="currentLoan">
                <div class="row">

                    <!--Sales Price-->
                    <div class="col-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_sales_price">Sales Price</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rq-input-number
                                automation_id="txt_sales_price"
                                defaultValue="0"
                                decimals="2"
                                cssClass="form-control"
                                v-model="currentLoan.salesPrice"
                                @change="onSalesPriceChange"
                                v-focus-select-all
                            />
                        </div>
                    </div>

                    <!--Loan Amount-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.amount.$error}">
                        <label class="form-control-label" for="txt_sale_loan_amount">Loan Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rq-input-number
                                automation_id="txt_sale_loan_amount"
                                ref="txt_sale_loan_amount"
                                defaultValue="0"
                                decimals="2"
                                cssClass="form-control"
                                v-model="currentLoan.amount"
                                @change="onLoanAmountChange"
                                v-focus-select-all
                            />
                        </div>
                        <rq-validation-feedback>Please enter a value in at least one field</rq-validation-feedback>
                    </div>

                    <!--Loan Policy Liability Override-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.loanPolicyLiability.$error}">
                        <label class="form-control-label" for="txt_sale_loan_policy_liability_override">Loan Policy Liability Override</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rq-input-number v-model="currentLoan.loanPolicyLiability" defaultValue="0" decimals="2" cssClass="form-control" automation_id="txt_sale_loan_policy_liability_override" />
                        </div>
                        <rq-validation-feedback>Please enter a value in at least one field</rq-validation-feedback>
                    </div>
                    <!--Down Payment-->
                    <div class="col-12 col-md-6 col-lg-3 form-group">
                        <div class="row">
                            <label class="col col-form-label" for="txt_sales_down_payment">Down Payment</label>
                            <div v-if="!readOnly" v-show="!isDownPaymentCalculated && (currentLoan.loanOrder === 1)" class="col form-group-action ms-auto">
                                <button id="btn_recalculateDownPayment" automation_id="btn_recalculateDownPayment" type="button" class="btn btn-link btn-theme" @click="onRecalculateDownPayment">Recalculate</button>
                            </div>
                        </div>
                        <div class="input-group" id="txt_sales_down_payment">
                            <span class="input-group-text">$</span>
                            <rq-input-number :disabled="isDownPaymentDisabled"
                                v-model="currentLoan.downPayment"
                                defaultValue="0"
                                decimals="2"
                                v-focus-select-all
                                cssClass="form-control"
                                automation_id="txt_sales_down_payment"
                            />
                        </div>
                        <b-tooltip
                            target="txt_sales_down_payment"
                            title="Applies to Loan #1 Only."
                            @show="onShowDownPaymentTooltip">
                        </b-tooltip>
                    </div>
                </div>
                <div class="row">

                    <!-- Lender Company -->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.lenderCompany.companyID.$error || v$.currentLoan.lender.$error }">
                        <div class="row">
                            <label class="col col-form-label" for="txt_sale_lender">Lender</label>
                            <div v-if="!readOnly" v-show="currentLoan" class="col form-group-action ms-auto">
                                <button id="btn_set_seller_as_lender" automation_id="btn_set_seller_as_lender" type="button" class="btn btn-link btn-theme" @click="setSellerAsLender" :disabled="!canSetSellerAsLender" >Set Seller as Lender</button>
                            </div>
                        </div>

                        <company-picker
                            ref="refLender"
                            automation_id="pic_loan_lender"
                            companyRoleName="Lender"
                            :companyRoleId="6"
                            dialogTitle="Select a Lender"
                            :disabled="isOrderLocked || readOnly"
                            v-model="currentLoan.lenderCompany"
                            show-selection-summary
                        />
                        <rq-validation-feedback
                            :offset="lenderFeedbackOffset"
                            :messages="{
                                'Please enter a value in at least one field': v$.currentLoan.lenderCompany.companyID.$error,
                                'Enter a valid lender' : v$.currentLoan.lender.$error
                            }"
                        />
                    </div>

                    <!--Lender Contact-->
                    <div class="col-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="drp_lender_contact">Lender Contact</label>
                        <div class="input-group ">
                            <contact-picker
                                automation_id="drp_lender_contact"
                                dialogTitle="Select Lender Contact"
                                :disabled="isOrderLocked || readOnly"
                                :company-id="currentLoan.lenderID"
                                v-model:contact-name="currentLoan.lenderContact"
                                v-model="currentLoan.lenderContactID">
                            </contact-picker>
                        </div>
                    </div>

                    <!--Loan Number-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.number.$error}">
                        <label class="form-control-label" for="txt_sale_loan_number">Loan Number</label>
                        <input automation_id="txt_sale_loan_number" class="form-control" type="text" v-model="currentLoan.number" maxlength="30" />
                        <rq-validation-feedback>Please enter a value in at least one field</rq-validation-feedback>
                    </div>

                    <!--MIC#-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.mortInsCaseNumber.$error}">
                        <label class="form-control-label" for="txt_sale_mic">MIC#</label>
                        <input automation_id="txt_sale_mic" class="form-control" type="text" v-model="currentLoan.mortInsCaseNumber" maxlength="20" />
                        <rq-validation-feedback>Please enter a value in at least one field</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">

                    <!--Monthly Principal-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.monthlyPrincipal.$error}">
                        <label class="form-control-label" for="txt_sale_monthly_principal">Monthly Principal</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rq-input-number v-model="currentLoan.monthlyPrincipal" defaultValue="0" decimals="2" cssClass="form-control" automation_id="txt_sale_monthly_principal" />
                        </div>
                        <rq-validation-feedback>Please enter a value in at least one field</rq-validation-feedback>
                    </div>

                    <!--Monthly Interest-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.monthlyInterest.$error}">
                        <label class="form-control-label" for="txt_sale_monthly_interest">Monthly Interest</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rq-input-number v-model="currentLoan.monthlyInterest" defaultValue="0" decimals="2" cssClass="form-control" automation_id="txt_sale_monthly_interest" />
                        </div>
                        <rq-validation-feedback>Please enter a value in at least one field</rq-validation-feedback>
                    </div>

                    <!--Interest Rate-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.interestRate.$error}">
                        <label class="form-control-label" for="txt_sale_interest_rate">Interest Rate</label>
                        <div class="input-group">
                            <rq-input-number v-model="currentLoan.interestRate" defaultValue="0" decimals="3" minValue="0" maxValue="100" cssClass="form-control" automation_id="txt_sale_interest_rate" />
                            <span class="input-group-text">%</span>
                        </div>
                        <rq-validation-feedback :offset="40">Please enter a value in at least one field</rq-validation-feedback>
                    </div>

                    <!--Mortgage Insurance-->
                    <div class="col-12 col-md-6 col-lg-3 form-group" :class="{'has-error' : v$.currentLoan.mortgageInsurance.$error}">
                        <label class="form-control-label" for="txt_sale_mortgage_insurance">Mortgage Insurance Premium</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <rq-input-number v-model="currentLoan.mortgageInsurance" defaultValue="0" decimals="2" cssClass="form-control" automation_id="txt_sale_mortgage_insurance" />
                        </div>
                        <rq-validation-feedback>Please enter a value in at least one field</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <!--Term Period -->
                    <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                        <loan-term-length
                            :currentLoan="currentLoan"
                            v-on:loan-term-change="updateTermPeriod"
                        ></loan-term-length>
                    </div> -->
                    <div class="col-12">
                        <div class="row">
                            <!--Years-->
                            <div class="col-12 col-lg-2 col-sm-4 form-group" :class="{'has-error' : v$.currentLoan.term.$error}">
                                <label class="form-control-label" for="txt_term_years">Term &amp; Length</label>
                                <div class="input-group">
                                    <rq-input-number
                                        id="txt_term_years"
                                        automation_id="txt_term_years"
                                        defaultValue="0"
                                        decimals="0"
                                        v-focus-select-all
                                        v-model="termsLengthYears"
                                    />
                                    <span class="input-group-text">Years</span>
                                </div>
                                <rq-validation-feedback :offset="65">Please enter a value in at least one field</rq-validation-feedback>
                            </div>

                            <!--Months-->
                            <div class="col-12 col-lg-2 col-sm-4 form-group" :class="{'has-error' : v$.currentLoan.term.$error}">
                                <label class="form-control-label" for="txt_term_months"></label>
                                <div class="input-group">
                                    <rq-input-number
                                        id="txt_term_months"
                                        automation_id="txt_term_months"
                                        defaultValue="0"
                                        decimals="0"
                                        v-focus-select-all
                                        v-model="termsLengthMonths"
                                    />
                                    <span class="input-group-text">Months</span>
                                </div>
                                <rq-validation-feedback :offset="80">Please enter a value in at least one field</rq-validation-feedback>
                            </div>

                            <!--Days-->
                            <div class="col-12 col-lg-2 col-sm-4 form-group" :class="{'has-error' : v$.currentLoan.term.$error}">
                                <label class="form-control-label" for="txt_term_days"></label>
                                <div class="input-group">
                                    <rq-input-number
                                        id="txt_term_days"
                                        automation_id="txt_term_days"
                                        defaultValue="0"
                                        decimals="0"
                                        v-focus-select-all
                                        v-model="termsLengthDays"
                                    />
                                    <span class="input-group-text">Days</span>
                                </div>
                                <rq-validation-feedback :offset="60">Please enter a value in at least one field</rq-validation-feedback>
                            </div>

                            <!--LenderCreditAmount-->
                            <div v-if="isCdf" class="col-12 col-lg-3 col-sm-3 form-group">
                                <label class="form-control-label" for="txt_lender_credit_amount">Lender Credits</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rq-input-number
                                        automation_id="txt_lender_credit_amount"
                                        ref="txt_lender_credit_amount"
                                        defaultValue="0"
                                        decimals="2"
                                        cssClass="form-control"
                                        v-model="currentLoan.lenderCreditAmount"
                                        v-focus-select-all
                                    />
                                </div>
                            </div>

                            <div v-if="isCdf" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_loan_purpose">Loan Purpose</label>
                                <dx-select-box 
                                    id="drp_loan_purpose"
                                    value-expr="id"
                                    display-expr="name"
                                    :items="loanPurposes"
                                    v-model="currentOrderSummary.loanPurpose">
                                </dx-select-box>
                            </div>
                            <div v-if="isCdf" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_loan_product">Loan Product</label>
                                <dx-select-box
                                    id="drp_loan_product"
                                    value-expr="id"
                                    display-expr="name"
                                    :items="loanProducts"
                                    v-model="currentOrderSummary.loanProduct">
                                </dx-select-box>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_loan_type">Loan Type</label>
                                <dx-select-box
                                    id="drp_loan_type"
                                    value-expr="id"
                                    display-expr="name"
                                    :items="loanTypeOptions"
                                    v-model="currentOrderSummary.loanType">
                                </dx-select-box>
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="currentOrderSummary.loanType === 3">
                                <label for="txt_loan_type_other_description">Other Description</label>
                                <input automation_id="txt_loan_type_other_description" v-model="currentOrderSummary.loanTypeOtherDescription" :readonly="readOnly" type="text" class="form-control" id="txt_loan_type_other_description" placeholder="Description">
                            </div>

                            <!--LenderCreditsText-->
                            <div v-if="isCdf" class="col-12 col-lg-3 col-sm-3 form-group">
                                <label class="form-control-label" for="txt_lender_credits_text">Description</label>
                                <input automation_id="txt_lender_credits_text" class="form-control" type="text" v-model="currentLoan.lenderCreditsText" maxlength="150" />
                            </div>

                            <!-- Mortgage Type -->
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_mortgage_type">Mortgage Type</label>
                                <dx-select-box
                                    :input-attr="{ id:'drp_mortgage_type', automation_id:'drp_mortgage_type' }"
                                    :items="mortgageTypes"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                    v-model="currentLoan.mortgageTypeID"

                                    :disabled="readOnly"
                                />
                            </div>

                            <!-- Mortgage Clause Type -->
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_mortgage_clause_type">Mortgage Clause Type</label>
                                <dx-select-box
                                    :input-attr="{ id:'drp_mortgage_clause_type', automation_id:'drp_mortgage_clause_type' }"
                                    :items="mortgageClauseTypes"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                    v-model="currentLoan.mortgageClauseTypeID"

                                    :disabled="readOnly"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section
            title="Custom Data"
            collapsible
            borderless>
            <custom-data-container
                ref="customDataContainer"
                :reference-table="referenceTable"
                :reference-table-pk-value="currentLoan.loanID"
                :custom-data-tab-id="customDataTabID"
                v-model:value="customData"
                v-model:is-valid="customDataIsValid"
                :read-only="customDataReadOnly"
                @custom-data-loaded="onCustomDataLoaded"
            ></custom-data-container>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { CONTACT_ACTIONS, ORDER_ACTIONS } from "@/store/actions";
    import { ORDER_MUTATIONS } from "@/store/mutations";
    import { LoanModel, OrderMainDto } from "../models";
    import {CompanyPicker, ContactPicker, CustomDataContainer} from "@/shared/components";
    import LoanTermLengthMixin from "./LoanTermLengthMixin";
    import { SETTLEMENT_TYPE } from '@settlement/models';
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { SettlementOrderSummaryModel } from '../../settlement/models';

    export default {
        name: "LoanForm",

        components: { CompanyPicker, ContactPicker, CustomDataContainer },

        mixins: [LoanTermLengthMixin],

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                route: this.$route.params.type,
                validationErrors: [],
                currentLoan: null,
                originalLoan: null,
                currentOrderSummary: {},
                originalOrderSummary: {},
                currentIndex: -1,
                ignoreSave: false,
                selectedLoanId: 0,
                showBanner: false,
                defaultMessage: "Please correct the highlighted errors on screen to continue.",
                ignoreSalesPriceChange: false,
                customDataTabID: 0,
                customDataIsValid: true,
                customData: [],
                originalCustomData: []
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                order: state => new OrderMainDto(state.orders.order),
                loans: state => _.map(state.orders.loans, l => new LoanModel(l)),
                systemDefaults: state => state.system.systemDefaults,
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                sellerId: state => _.parseNumber(state.orders.orderSummary.sellerID, 0),
            }),
            ...mapGetters([ "lookupHelpers", "lookupItems" ]),
            customDataReadOnly() { return this.readOnly || this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.Full; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            autoCalcDownPayment() { return _.parseBool(_.get(this.systemDefaults, "autoCalcDownPayment")); },
            multipleLoans() { return this.loans.length > 1; },
            isDownPaymentDisabled() { return this.currentLoan.loanOrder > 1; },
            isNewLoan() { return this.currentIndex < 0; },
            loanFormTitle() {
                if(this.isNewLoan)
                    return "Loan Info";

                let loanParts = [];
                if (!_.isNil(this.currentLoan.loanOrder)) loanParts.push(`Loan #${this.currentLoan.loanOrder}`);
                if (!_.isEmpty(this.currentLoan.lender)) loanParts.push(this.currentLoan.lender);
                return _.join(loanParts, " : ");
            },
            isDownPaymentCalculated() {
                // We determine if downpayment has been calculated by comparing the would be calculation with the current downpayment
                let loanAmount = this.isDownPaymentDisabled ? this.loans[0].amount : this.currentLoan.amount;
                return this.currentLoan.downPayment === this.calculateDownPayment(this.order.salesPrice, loanAmount) ||
                       this.currentLoan.downPayment === this.calculateDownPayment(this.currentLoan.salesPrice, loanAmount);
            },
            readOnly() { return this.isReadOnly || this.isOrderLocked; },
            disabledDelete(){
                const self = this;
                let enable = false;
                if ( _.isNil(self.currentLoan.lenderCompany.companyID)
                        && self.currentLoan.amount == 0
                        && _.isNil(self.currentLoan.number)
                        && _.isNil(self.currentLoan.mortInsCaseNumber)
                        && self.currentLoan.monthlyPrincipal == 0
                        && self.currentLoan.monthlyInterest == 0
                        && self.currentLoan.interestRate == 0
                        && self.currentLoan.term == 0
                        && self.currentLoan.loanMonths == 0
                        && self.currentLoan.mortgageInsurance == 0
                        && self.currentLoan.loanPolicyLiability == 0)
                    enable = true;
                return this.isReadOnly || this.isOrderLocked || enable || !this.allowDeleteLoan } ,
            showLoanDropDown() {
                return !this.isNewLoan && this.loans.length > 1;
            },
            lenderFeedbackOffset(){
                return this.v$.currentLoan.lender.$error ? 90 : 40;
            },
            localSecurity(){
                return this.securitySettings.findValues(["AllowDeleteLoan", "AdditionalFields_ScreenAccess"]);
            },
            allowDeleteLoan(){ return this.localSecurity.AllowDeleteLoan; },
            referenceTable() {
                return CustomDataReferenceTable.SaleLoan;
            },
            canSetSellerAsLender(){
                return this.sellerId > 0;
            },
            loanOptions() { return this.lookupHelpers.getLoanOptions(); },
            mortgageTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.MORTGAGE_TYPES, null, this.currentLoan.mortgageTypeID); },
            mortgageClauseTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.MORTGAGE_CLAUSE_TYPES, null, this.currentLoan.mortgageClauseTypeID); },
            loanTypeOptions() {
                if (this.isCdf)
                    return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_TYPE);
                
                if (this.isHud1974) {
                    return _.map(this.lookupHelpers.getLookupItems(this.lookupItems.HUD_LOAN_TYPE), r => { return r.id === 1 
                        ? { ...r, ...{name: "FmHA"} } 
                        : r});
                }

                if (this.isHud2010) {
                    return _.filter(this.lookupHelpers.getLookupItems(this.lookupItems.HUD_LOAN_TYPE));
                }

                return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_TYPE); // CDF default
            },
            loanPurposes() { return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_PURPOSE); },
            loanProducts() { return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_PRODUCT); },
        },

        watch:{
            $route: {
                handler(newValue, oldValue) {
                    let currentLoanId = _.get(this, "currentLoan.loanID", 0) || 0;
                    let currentRouteId = _.parseNumber(_.get(newValue, "params.loanId", 0), 0);
                    if(currentLoanId > 0 && currentLoanId === currentRouteId) return;
                    this.currentIndex = _.findIndex(this.loans, l => l.loanID === currentRouteId);

                    if (this.currentIndex > -1) //On route change reset flag
                        this.ignoreSave = false;

                    this.setCurrentLoan();
                },
                immediate: true
            },
            currentLoan: {
                immediate: true,
                handler(val, oldVal){
                    this.loanTermLengthMixin_clearTermLength();
                    this.loanTermLengthMixin_setTimePeriod(val);
                }
            },

            termsLengthYears: function(val) {

                if(val > 0 && this.termsLengthMonths > 11) {
                    this.termsLengthMonths = 0;
                }
                if(val > 0 || this.termsLengthMonths > 0){
                    this.termsLengthDays = 0;
                }

                let data = this.loanTermLengthMixin_getTermPeriod();
                this.updateTermPeriod(data);
            },
            termsLengthMonths: function(val) {

                if(val > 11) {
                    this.termsLengthYears = 0;
                }
                if(this.termsLengthYears > 0 || val > 0){
                    this.termsLengthDays = 0;
                }

                let data = this.loanTermLengthMixin_getTermPeriod();
                this.updateTermPeriod(data);
            },
            termsLengthDays: function(val) {

                if(val > 0) {
                    this.termsLengthMonths = 0;
                    this.termsLengthYears = 0;
                }

                let data = this.loanTermLengthMixin_getTermPeriod();
                this.updateTermPeriod(data);
            }
        },

        created () {
            const self = this;
            self.fetchOrderSummaryData(self.selectedLoanId);
            GlobalEventManager.onSave(this, this.onSave);
            if(self.readOnly) return;
            GlobalEventManager.onCreateNew(this, this.onAddLoan);
            GlobalEventManager.onDelete(this, this.onDeleteLoan);
            GlobalEventManager.onCancel(this, this.onCancel);

            self.$nextTick(() => {
                //registering in next tick in case unregistered in previous route's beforeUnmount (passing function as argument in "off" doesn't work)
                self.$events.on("apply-template-success", e => {
                    self.$router.push({ name:"oe:ol:loans", params: { orderId: self.orderId } });
                });
            });
        },

        updated() { },

        mounted() { this.resetFocus();},

        beforeUnmount () {
            GlobalEventManager.unregister(this);
            this.$events.off("apply-template-success");
        },

        async beforeRouteLeave(to) {
            const self = this;
            let isLoanAvailable = _.findIndex(this.loans, l => l.loanID === this.currentLoan.loanID) > -1;

            if(to.name === "oe:ol:loans" && !self.multipleLoans && isLoanAvailable) return false;

            let changes = self.hasChanges();

            if(changes && !self.ignoreSave) {
                self.ignoreSalesPriceChange = false;
                let canContinue = await self.save();
                if(!canContinue) return false;
            }
            else{
                this.resetValidation();// reset form validation
            }
        },

        async beforeRouteUpdate() {
            if(this.isNewLoan) return;
            await this.save();
        },

        validations() {
            const self = this;
            let lenderValid = self.$refs.refLender ? self.$refs.refLender.isValid : true;
            const greaterThanZero = (value) => value > 0;
            const isLenderValid = () => lenderValid;


            let result = {
                currentLoan:{

                    lender: {},
                    lenderCompany:{
                        companyID: {}
                    },
                    amount: {},
                    number: {},
                    mortInsCaseNumber: {},
                    monthlyPrincipal: {},
                    monthlyInterest: {},
                    interestRate: {},
                    term: {},
                    loanMonths: {},
                    mortgageInsurance: {},
                    loanPolicyLiability: {},
                    salesPrice: {}
                }
            }

            if(_.isNil(self.currentLoan)) return result;

            if(!lenderValid)
                result.currentLoan.lender = { isLenderValid }

            let isSomethingRequired =  (_.isNil(self.currentLoan.lenderCompany) || _.isNil(self.currentLoan.lenderCompany.companyID))
                && self.currentLoan.amount === 0
                && _.isEmpty(self.currentLoan.number)
                && _.isEmpty(self.currentLoan.mortInsCaseNumber)
                && self.currentLoan.monthlyPrincipal === 0
                && self.currentLoan.monthlyInterest === 0
                && self.currentLoan.interestRate === 0
                && self.currentLoan.term === 0
                && self.currentLoan.loanMonths === 0
                && self.currentLoan.mortgageInsurance === 0
                && self.currentLoan.loanPolicyLiability === 0
                && (self.currentLoan.salesPrice === self.originalLoan.salesPrice || self.ignoreSalesPriceChange);

            if(isSomethingRequired){
                result.currentLoan.lenderCompany.companyID = { required };
                result.currentLoan.amount = { greaterThanZero };
                result.currentLoan.number = { required };
                result.currentLoan.mortInsCaseNumber = { required };
                result.currentLoan.monthlyPrincipal = { greaterThanZero };
                result.currentLoan.monthlyInterest = { greaterThanZero };
                result.currentLoan.interestRate = { greaterThanZero };
                result.currentLoan.term = { greaterThanZero };
                result.currentLoan.loanMonths = { greaterThanZero };
                result.currentLoan.mortgageInsurance = { greaterThanZero };
                result.currentLoan.loanPolicyLiability = { greaterThanZero };
            }

           return result;
        },

        methods: {

            async setCurrentLoan() {
                const self = this;
                let options = {};
                if (self.isNewLoan) {
                    let lastLoan = _.orderBy(self.loans, ['loanOrder'], ['desc'])[0];
                    options = {loanOrder: lastLoan.loanOrder + 1};
                } else {
                    options = self.loans[self.currentIndex];
                }
                _.assign(options, _.pick(self.order, ['salesPrice', 'downPayment', 'ordersID']));
                self.currentLoan = new LoanModel(options);
                self.selectedLoanId = self.currentLoan.loanID;
                self.originalLoan = new LoanModel(options);
                await self.fetchOrderSummaryData(self.selectedLoanId);
                self.resetFocus();
                _.invoke(self, "v$.reset"); //invoking this way in case it's called before setup is initialized
            },

            onShowDownPaymentTooltip(bvEvent) {
                if(!this.isDownPaymentDisabled)
                {
                    bvEvent.preventDefault();
                }
            },

            onViewOrderLoans() {
                this.$router.push({ name:"oe:ol:loans", params: { orderId: this.orderId } });
            },

            onLoanDropdownChange(e) {
                let self = this;
                this.$router
                        .push({
                            name: 'oe:ol:loan',
                            params: {
                                orderId: self.orderId,
                                loanId: e.selectedValue
                            }
                        });
            },

            resetValidation(){
                this.ignoreSalesPriceChange = false,
                this.v$.$reset();
            },

            hasChanges () {
                let changes = this.getAuditChanges(this.originalLoan.toDto(), this.currentLoan.toDto());
                let customDataChanges = _.differenceWith(this.customData, this.originalCustomData, _.isEqual);

                let loanSummaryChanges = this.getAuditChanges(this.currentOrderSummary, this.originalOrderSummary);
                return (changes.length > 0 || customDataChanges.length > 0 || loanSummaryChanges.length > 0);
            },

            isValid () {
                // Validations for saving a loan
                const self = this;
                self.validationErrors = [];
                let fieldCompleted = false;

                // Validation that any one of the input fields below were completed for user to save
                if ( _.isNil(self.currentLoan.lenderCompany.companyID)
                        && self.currentLoan.amount == 0
                        && _.isNil(self.currentLoan.number)
                        && _.isNil(self.currentLoan.mortInsCaseNumber)
                        && self.currentLoan.monthlyPrincipal == 0
                        && self.currentLoan.monthlyInterest == 0
                        && self.currentLoan.interestRate == 0
                        && self.currentLoan.term == 0
                        && self.currentLoan.loanMonths == 0
                        && self.currentLoan.mortgageInsurance == 0
                        && self.currentLoan.loanPolicyLiability == 0
                        && (self.currentLoan.salesPrice == self.originalLoan.salesPrice || self.ignoreSalesPriceChange)) {
                        self.validationErrors.push("Please enter information in at least one of the fields below to save.");
                        self.defaultMessage = "Please enter information in at least one of the fields below to save.";
                    }

                if (!self.$refs.refLender.isValid) self.validationErrors.push("Select a valid Lender");

                return self.validationErrors.length === 0;
            },

            async onSave (eventArgs) {
                this.ignoreSalesPriceChange = false,
                await this.save(eventArgs);
            },

            async save(args) {
                const self = this;

                self.showBanner = true;

                let loan = self.currentLoan;
                let changes = self.getAuditChanges(self.originalLoan.toDto(), loan.toDto());
                let loanSummaryChanges = this.getAuditChanges(this.currentOrderSummary, this.originalOrderSummary);
                let orderChanges = _.some(changes, ['name', 'salesPrice']) || _.some(changes, ['name', 'downPayment']);
                let roleChanges = _.some(changes, ['name', 'lenderID']) || _.some(changes, ['name', 'lenderContactID']);
                let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                let userInitiated = _.parseBool(args && args.userInitiated);
                let onClearLoan = _.get(args, "onClearLoan", false);

                if (self.isNewLoan || self.hasChanges() || userInitiated) {
                    self.v$.$touch();
                    _.invoke(self, "$refs.customDataContainer.validate");
                }

                if(self.ignoreSave){
                    self.ignoreSave = false;
                    GlobalEventManager.saveCompleted({ success: true });
                    return true;
                }

                if (self.userAccess.readOnly) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return true;
                }

                if (!onClearLoan && (!self.isValid() || !self.customDataIsValid)) {
                    GlobalEventManager.saveCompleted({ success: false });
                    return false;
                }

                if (!self.isNewLoan && changes.length == 0 && customDataChanges.length == 0 && loanSummaryChanges.length == 0) { //existing loan has no changes
                    if(userInitiated) self.$toast.info("No changes detected.");
                    GlobalEventManager.saveCompleted({ success: true });
                    return true;
                }

                this.resetValidation();// reset form validation

                try{
                    let apiPromises = self.$store.dispatch(ORDER_ACTIONS.SAVE_LOAN, { loan, changes });

                    let result = await self.$rqBusy.wait(apiPromises)

                    if (orderChanges) {
                        let newOrder = self.order;
                        newOrder.salesPrice = loan.salesPrice;
                        newOrder.downPayment = loan.downPayment;
                        newOrder.lastSalesPriceChange = loan.lastSalesPriceChange;
                        self.$store.commit(ORDER_MUTATIONS.SET_ORDER, newOrder);
                    }

                    if (roleChanges) {
                        self.$store.dispatch(CONTACT_ACTIONS.GET_ORDER_CONTACTS);
                    }

                    if(self.isNewLoan)
                    {
                        // when adding new loan, use the newly created loanID customData
                        customDataChanges = _.map(customDataChanges, x => {
                                                x.referenceTablePKValue = result.loanID;
                                                return x;
                                            });
                    }

                    if(self.isNewLoan){
                        let newSummary = _.cloneDeep(self.currentOrderSummary);
                        await self.fetchOrderSummaryData(result.loanID);
                        self.currentOrderSummary.loanPurpose = newSummary.loanPurpose;
                        self.currentOrderSummary.loanProduct = newSummary.loanProduct;
                        self.currentOrderSummary.loanType = newSummary.loanType;
                        self.currentOrderSummary.loanTypeOtherDescription = newSummary.loanTypeOtherDescription;
                    }
                    
                    await self.$rqBusy.wait(self.$api.SettlementOrderSummaryApi.update(self.currentOrderSummary));
                    
                    if(customDataChanges.length > 0) {
                        await self.$rqBusy.wait(self.$api.CustomDataApi.saveCustomData(customDataChanges));
                    }

                    if (self.isNewLoan && userInitiated) {
                        self.ignoreSave = true;
                        self.$route.meta.oneTimeSkipSave = true;
                        self.$router.push({ name:"oe:ol:loan", params: { orderId: self.orderId, loanId: result.loanID} });
                    } else {
                        await self.setCurrentLoan();
                        _.invoke(self, "$refs.customDataContainer.initialize");
                    }

                    self.$toast.success("Loan saved successfully.");
                    GlobalEventManager.saveCompleted({ success: true });
                    return true;
                }
                catch(error){
                    self.$toast.error("Loan Failed to Save");
                    console.error(error);
                    GlobalEventManager.saveCompleted({ success: false });
                    return false;
                }
            },

            onCancel (args) {this.cancel(args);},

            cancel (args) {
                const self = this;
                self.v$.$reset();
                _.invoke(self, "$refs.customDataContainer.resetFieldValidations");
                GlobalEventManager.saveCompleted({ success: false });
                let hasChanges = () => {
                    if (self.isNewLoan) return true;
                    return self.hasChanges();
                };
                let callback = () => {
                    if (self.isNewLoan) {
                        self.ignoreSave = true; //Prevent duplicate saves due to route changes initiating saves as well
                        self.$router.push({ name:"oe:ol:loans", params: { orderId: self.orderId } });
                        this.resetValidation();  // reset form validation
                        return;
                    }
                    self.setCurrentLoan();
                    _.invoke(self, "$refs.customDataContainer.initialize");
                }
                GlobalEventManager.confirmCancel({ hasChanges, callback });
            },

            onDeleteLoan (e) {
                const self = this;
                if(self.disabledDelete) return;
                if(self.multipleLoans) {
                    let loans =[];
                    loans.push(this.currentLoan.loanID);
                    self.$emit("delete-loan", loans);
                }
                else {
                    self.clearLoan();
                }
            },

            async onAddLoan () {
                const self = this;
                if(self.isOrderLocked || self.isReadOnly) return;
                self.ignoreSave = false;
                self.ignoreSalesPriceChange = true;
                let success = await self.save();
                if(!success) return;
                self.$emit("add-loan");
            },

            setSellerAsLender () {
                const self = this;
                let apiPromise = self.$api.OrdersApi.getSellerAsLender(self.orderId, self.currentLoan.loanID);
                self.$rqBusy.wait(apiPromise)
                    .then(lenderRef => {
                        self.currentLoan.lender = lenderRef.name;
                        self.currentLoan.lenderID = lenderRef.id;
                        self.currentLoan.lenderContactID = null;
                        self.currentLoan.lenderContact = null;
                    })
                    .catch(() => {
                        self.$toast.error({ message: "Failed to set lender" });
                    });
            },

            clearLoan(){
                const self = this;

                let confirmMessage = `<p>Deleting a loan will delete any associated commitments, title production, and policy areas as well as settlement statements if there are no associated checks that have been cut.</p>
                        <p>This will also delete any pending CDF imports associated with this loan that have not been processed.  Deleted data cannot be retrieved.</p>`;

                let okHandler = async function () {
                    let loanID = self.originalLoan.loanID;
                    self.currentLoan = new LoanModel({loanID: loanID, loanOrder: 1, ordersID: self.order.ordersID, loanTermPeriod: 1, downPayment: 0, salesPrice: self.order.salesPrice });
                    await self.save({onClearLoan: true});
                    return true;
                };
                self.$dialog.confirm( "Delete Loan(s)", confirmMessage, okHandler, null, { height: 325, width: 500 });
            },

            updateTermPeriod(data) {
                this.currentLoan.loanTermPeriod = data.loanTermPeriod;
                this.currentLoan.term = data.term;
                this.currentLoan.loanMonths = data.loanMonths;
            },

            calculateDownPayment(salesPrice, loanAmount) {
                return (salesPrice >= loanAmount && loanAmount > 0) ? (_.parseNumber(salesPrice, 0) - _.parseNumber(loanAmount, 0)) : 0;
            },

            onLoanAmountChange(e) {
                const self = this;
                // Re-calculate the current down payment field whenever loan amount value is changed
                self.$nextTick().then(() => {
                    let newLoanAmount = _.parseNumber(e.target.value);
                    if(newLoanAmount === 0 || self.currentLoan.salesPrice === 0) {
                        self.currentLoan.downPayment = 0;
                    }
                    if (!self.isDownPaymentDisabled && self.isDownPaymentCalculated && self.autoCalcDownPayment && newLoanAmount !== 0 && self.currentLoan.salesPrice !== 0) {
                        self.currentLoan.downPayment = self.calculateDownPayment(self.currentLoan.salesPrice, newLoanAmount);
                    }
                    self.currentLoan.amount = newLoanAmount;
                });
            },

            onSalesPriceChange(e) {
                const self = this;
                // Re-calculate the current down payment field whenever sales price is changed
                self.$nextTick().then(() => {
                    let newSalesPrice = _.parseNumber(e.target.value);
                    if(newSalesPrice === 0 || self.currentLoan.amount === 0) {
                        self.currentLoan.downPayment = 0;
                    }
                    if (self.autoCalcDownPayment && self.isDownPaymentCalculated && self.currentLoan.amount !== 0 && newSalesPrice !== 0) {
                        //If it's disabled then it's not the first loan, but they can still change sales price, so...
                        let loanAmount = self.isDownPaymentDisabled ? self.loans[0].amount : self.currentLoan.amount;
                        self.currentLoan.downPayment = self.calculateDownPayment(newSalesPrice, loanAmount);
                    }
                    self.currentLoan.salesPrice = newSalesPrice;
                });
            },

            onRecalculateDownPayment(e) {
                this.currentLoan.downPayment = this.calculateDownPayment(this.currentLoan.salesPrice, this.currentLoan.amount);
            },

            resetFocus() {
                _.invoke(this, "$refs.txt_sale_loan_amount.instance.focus");
                _.invoke(this, "$refs.txt_sale_loan_amount.instance.select");
            },
            onCustomDataLoaded(e) {
                let self = this;
                self.originalCustomData = _.cloneDeep(e);
            },
             async fetchOrderSummaryData(loanID){
                const self = this;

                try{
                    let promise = self.$api.SettlementOrderSummaryApi.getByOrderId(self.orderId);
                    let result = await self.$rqBusy.wait(promise);
                    let selectedSummary = new SettlementOrderSummaryModel(_.find(result, s => s.loanID == loanID) || {});

                    self.currentOrderSummary = selectedSummary;
                    self.originalOrderSummary = _.cloneDeep(self.currentOrderSummary);
                }
                catch(err){
                    self.$toast.error(err.errorMessage);
                }
            },
        }
    };
</script>