
export class AddendumDto {
	constructor(options) {
        options = options || {};
		this.addendumID = _.parseNumber(options.addendumID, 0);
		this.sectionCode = _.parseNumber(options.sectionCode, 0);
		this.orderNumber = options.orderNumber || null;
		this.description = options.description || null;
		this.amount = _.parseNumber(options.amount, 0);
		this.mainID = options.mainID || null;
		this.loanID = options.loanID || null;
		this.lastChangedBy = options.lastChangedBy || null;
		this.lastChanged = options.lastChanged || null;
		this.isPCUser = options.isPCUser || null;
		this.lastChangedByUserID = options.lastChangedByUserID || null;
		this.cdfLineNo = options.cdfLineNo || null;
		this.cdfLineId = _.parseNumber(options.cdfLineId, 0);
	}

	get sectionName() {
		switch(this.sectionCode){
			case 9:
				return "A";
			case 10:
				return "B";
			case 11:
				return "C";
			case 13:
				return "E";
			case 14:
				return "F";
			case 15:
				return "G";
			case 16:
				return "H";
			default:
				return "";
		}
	}
}
export class HudAddendumDto {
	constructor(options, hudMainId) {
		options = options || {};
		this.hudAddendumMainID = _.parseNumber(options.hudAddendumMainID, 0);
		this.hudMainID = _.parseNumber(hudMainId);
        this.descriptionOfCharges = options.descriptionOfCharges || "";
        this.loanID = _.parseNumber(options.loanID, 0);

		this.hudAddendumDetails = _.map(options.hudAddendumDetails, l => new HudAddendumDetailDto(l));
	}
}
export class HudAddendumDetailDto {
	constructor(options) {
        options = options || {};
        this.hudAddendumMainID = _.parseNumber(options.hudAddendumMainID, 0);
        this.line = _.parseNumber(options.line, 0);
        this.hudLine = _.parseNumber(options.hudLine, 0);
        this.description = options.description || "";
        this.hudAmount = _.parseNumber(options.hudAmount, 0);
        this.addendumAmount = _.parseNumber(options.addendumAmount, 0);
	}
}