<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <fieldset class="pe-3 ps-3" :disabled="readOnly">
        <div class="row" v-show="!isNew">
            <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.investmentID.$error }">
                <label for="drp_investment_acct">Investment Account</label>
                <dx-select-box
                    automation_id="drp_investment_acct"
                    :items="investmentAccounts"
                    display-expr="bankName"
                    value-expr="investmentID"
                    :defaultItemEnabled="false"
                    @value-changed="onInvestmentAccountChange"
                    v-model="v$.item.investmentID.$model"
                />
                <rq-validation-feedback>Investment Account is required</rq-validation-feedback>
            </div>
        </div>
        <div class="row" v-if="showAddInvestment">
            <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.bankName.$error }">
                <label for="txt_bank_name">Bank Name</label>
                <input automation_id="txt_bank_name"
                    class="form-control"
                    type="text"
                    v-model="v$.item.bankName.$model"
                    placeholder="Bank Name"
                    maxlength="65"
                    />
                <rq-validation-feedback>Bank Name is required</rq-validation-feedback>
            </div>
        </div>
        <div class="row" v-if="showAddInvestment">
            <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.accountNumber.$error }">
                <label for="txt_bank_name">Account Number</label>
                <input automation_id="txt_account_number"
                    class="form-control"
                    type="text"
                    v-model="v$.item.accountNumber.$model"
                    placeholder="Account Number"
                    maxlength="40"
                    />
                <rq-validation-feedback
                    :messages="{
                        'Account Number is required': v$.item.accountNumber.required.$invalid,
                        'That Investment Account already exists': v$.item.accountNumber.isUnique.$invalid
                    }"
                />
            </div>
        </div>
        <div class="row">
            <div :class="{ 'col col-6 form-group form-required':true, 'has-error':v$.item.amount.$error }">
                <label class="form-control-label" for="txt_amount">Amount</label>
                <rqInputNumber
                    automation_id="txt_amount"
                    formatType="money"
                    defaultValue="0"
                    :minValue="0"
                    decimals="2"
                    input-group
                    no-prefix
                    prependIcon="fas fa-dollar-sign"
                    v-model="v$.item.amount.$model" />
                    <rq-validation-feedback
                        :messages="amountMessages"
                    />
            </div>
        </div>
        <div class="row">
            <div class="col-6 form-group">
                <b-form-checkbox
                    automation_id="chk_use_check"
                    id="chk_use_check"
                    v-model="item.useCheck">Transfer as check</b-form-checkbox>
            </div>
        </div>
        </fieldset>
    </div>

</template>

<script>
    import { mapState } from "vuex";
    import { InvestmentDepositRequestDto }  from "../models";
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";

    export default {
        name: 'InvestmentAccountTransferForm',
        props: {
            fileBalance: { type: Number, default: 0 },
            investmentAccounts: { type: Array, default: () => [] },
            item: { type: Object, required: true, default: () => new InvestmentDepositRequestDto() },
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                errorMessage: "",
                originalItem: {}
            }
        },
        watch: {
            "v$.$error":{
                handler(newValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            ...mapState({
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                order: state => state.orders.order,
            }),
            amountMessages() {
                return  {
                    'Amount is required or must be greater than zero': this.v$.item.amount.isGreaterThanZero.$invalid,
                    [this.lessThanItemAmountError]: this.v$.item.amount.isLessThanItemAmount.$invalid,
                    [this.lessThanFileBalanceError]: !this.v$.item.amount.isLessThanItemAmount.$invalid
                        && this.v$.item.amount.isLessThanFileBalance.$invalid,
                };
            },
            isNew() { return _.size(this.investmentAccounts) == 1; },
            lessThanItemAmountError(){
                return `Cannot transfer more than the Deposit Amount ($${this.originalItem.amount}).`;
            },
            lessThanFileBalanceError(){
                return `Cannot transfer more than the File Account Balance ($${this.fileBalance}).`;
            },
            localSecurity(){
                return this.securitySettings.findValues(["AllowTransferToInvestmentAccount"]);
            },
            readOnly() {
                return !this.localSecurity.AllowTransferToInvestmentAccount || this.isFileLocked;
            },
            showAddInvestment() { return _.isEqual(this.item.investmentID, 0); }
        },
        created(){
            this.init();
        },
        validations() {
            const self = this;
            return {
                item: {
                    accountNumber: {
                        required:  requiredIf(() => self.showAddInvestment),
                        isUnique() {
                            if(self.item.investmentID > 0 || !self.item.bankName || !self.item.accountNumber) return true;
                            let existingAccount = _.find(self.investmentAccounts, a =>
                                    _.equalsIgnoreCaseAndWhitespace(self.item.bankName, a.bankName)
                                        && _.equalsIgnoreCaseAndWhitespace(self.item.accountNumber, a.accountNumber));
                            return _.isNil(existingAccount);
                        }
                    },
                    bankName: {
                        required:  requiredIf(() => self.showAddInvestment)
                    },
                    investmentID: {
                        isValid: val => self.isNew || _.gte(val, 0)
                    },
                    amount: {
                        isGreaterThanZero: val => _.gt(val, 0),
                        isLessThanItemAmount: val => _.lte(val, _.parseNumber(self.originalItem.amount, 0)),
                        isLessThanFileBalance: val => _.lte(val, _.parseNumber(self.fileBalance, 0))
                    },
                }
            };
        },
        methods: {
            init() {
                this.originalItem = _.clone(this.item);
                this.$emit(`${this.readOnly ? "disable" : "enable"}-ok`);
            },

            onInvestmentAccountChange(e) {
                if(_.isNil(e.event)) return;
                let title = 'Transfer to New Investment Account';
                if (_.gt(e.value, 0)) {
                    let invAcct = _.find(this.investmentAccounts, ["investmentID", e.value]);
                    title = _.truncate(`Transfer to ${invAcct.bankName}`, {'length': 50});
                }
                this.$emit("update-dialog-options", { title });
            },

            save(){
                const self = this;
                if(self.readOnly) return;
                self.errorMessage = "";
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }
                let request = new InvestmentDepositRequestDto(self.item);
                let apiPromise = self.$api.CheckWritingApi.createInvestmentDeposit(request);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    }
</script>