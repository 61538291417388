<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="v$.$error && showBanner"
        />
        <div class="row mt-3">
            <div class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_code">Code</label>
                <input automation_id="txt_code" type="text" class="form-control" :value="fee.code" disabled>
            </div>
            <div class="col col-12 col-md-6 col-lg-3">
                <rq-action-form-group
                    action-class="overridden"
                    label="Description"
                    labelFor="txt_description"
                    :show-action="fee.descriptionOverridden"
                    @action-click="fee.revertDescription()"
                    action-automation-id="btn_description"
                    action-label="Revert"
                    :has-error="v$.fee.description.$error"
                    required>
                    <input id="txt_description"
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        :disabled="readOnly"
                        v-model="fee.description">
                    <rq-validation-feedback>Description is required.</rq-validation-feedback>
                </rq-action-form-group>
            </div>
            <div class="col col-12 col-md-6 col-lg-3">
                <rq-action-form-group
                    action-class="overridden"
                    label="Payee"
                    :show-action="fee.payeeOverridden"
                    @action-click="fee.revertPayee()"
                    action-automation-id="btn_description"
                    action-label="Revert">
                    <company-picker
                        automation_id="pic_Payee_company"
                        dialogTitle="Select Payee"
                        :disabled="readOnly"
                        v-model="fee.payeeCompany"
                    />
                </rq-action-form-group>
            </div>
            <div class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_override_date">Override Date</label>
                <input automation_id="txt_override_date" type="text" class="form-control" :value="overrideDateDisplay" disabled>
            </div>
            <div class="col col-12 col-md-6 col-lg-3">
                <rq-action-form-group
                    action-class="overridden"
                    label="Borrower Amount"
                    labelFor="txt_buyer_amount"
                    :show-action="fee.buyerAmountOverridden"
                    @action-click="fee.revertBuyerAmount()"
                    action-automation-id="btn_buyer_amount"
                    action-label="Revert">
                    <rq-input-number
                        id="txt_buyer_amount"
                        formatType="money"
                        defaultValue="0"
                        decimals="2"
                        value-event="input"
                        v-model="fee.buyerAmount"
                        input-group
                        prepend-text="$"
                        :disabled="readOnly"
                    />
                </rq-action-form-group>
            </div>
            <div class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_buyer_tax">Borrower Tax</label>
                <input automation_id="txt_buyer_tax" type="text" class="form-control" :value="buyerTaxDisplay" disabled>
            </div>
            <div class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_buyer_total">Borrower At Closing</label>
                <input automation_id="txt_buyer_total" type="text" class="form-control" :value="buyerTotalDisplay" disabled>
            </div>
            <div class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_borrower_before_closing">Borrower Before Closing</label>
                <rq-input-number
                    id="txt_borrower_before_closing"
                    formatType="money"
                    defaultValue="0"
                    decimals="2"
                    value-event="input"
                    :disabled="readOnly"
                    v-model="fee.paidForBorrowerBeforeClosing"
                />
            </div>
            <div v-if="!isWithOutSeller" class="col col-12 col-md-6 col-lg-3">
                <rq-action-form-group
                    action-class="overridden"
                    label="Seller Amount"
                    labelFor="txt_seller_amount"
                    :show-action="fee.sellerAmountOverridden"
                    @action-click="fee.revertSellerAmount()"
                    action-automation-id="btn_revert_seller_amount"
                    action-label="Revert">
                    <rq-input-number
                        id="txt_seller_amount"
                        formatType="money"
                        defaultValue="0"
                        decimals="2"
                        value-event="input"
                        v-model="fee.sellerAmount"
                        input-group
                        prepend-text="$"
                        :disabled="readOnly"
                    />
                </rq-action-form-group>
            </div>
            <div v-if="!isWithOutSeller" class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_seller_tax">Seller Tax</label>
                <input automation_id="txt_seller_tax" type="text" class="form-control" :value="sellerTaxDisplay" disabled>
            </div>
            <div v-if="!isWithOutSeller" class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_seller_total">Seller At Closing</label>
                <input automation_id="txt_seller_total" type="text" class="form-control" :value="sellerTotalDisplay" disabled>
            </div>
            <div v-if="!isWithOutSeller" class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_seller_before_closing">Seller Before Closing</label>
                <rq-input-number
                    id="txt_seller_before_closing"
                    formatType="money"
                    defaultValue="0"
                    decimals="2"
                    value-event="input"
                    :disabled="readOnly"
                    v-model="fee.paidForSellerBeforeClosing"
                />
            </div>
            <div class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="drp_net_fund">Net Fund</label>
                <dx-select-box
                    :input-attr="{ id:'drp_net_fund', automation_id:'drp_net_fund' }"
                    value-expr="id"
                    display-expr="name"
                    :items="netFundOptions"
                    :disabled="readOnly"
                    v-model="fee.netFund"
                />
            </div>
            <div class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="drp_acct_code">Account Codes</label>
                <dx-select-box
                    :input-attr="{ id:'drp_acct_code', automation_id:'drp_acct_code' }"
                    value-expr="id"
                    display-expr="name"
                    :items="accountCodes"
                    :disabled="readOnly"
                    v-model="fee.accountingCodeID"
                />
            </div>
            <div v-if="isHud" class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="drp_line_type">Line Type</label>
                <dx-select-box
                    :input-attr="{ id:'drp_line_type', automation_id:'drp_line_type' }"
                    value-expr="id"
                    display-expr="name"
                    :items="lineTypes"
                    :disabled="lineTypesEmpty || readOnly"
                    v-model="fee.lineType"
                />
            </div>
            <div v-if="isCdf" class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="drp_cdf_section">CDF Section</label>
                <dx-select-box
                    :input-attr="{ id:'drp_cdf_section', automation_id:'drp_cdf_section' }"
                    value-expr="id"
                    display-expr="name"
                    :items="cdfSections"
                    :disabled="cdfSectionsEmpty || readOnly"
                    v-model="fee.cdfSection"
                />
            </div>
            <div v-if="isHud" class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="drp_poc_who">POC Whom</label>
                <dx-select-box
                    :input-attr="{ id:'drp_poc_who', automation_id:'drp_poc_who' }"
                    value-expr="id"
                    display-expr="name"
                    :items="pocWhoIntOptions"
                    :disabled="readOnly"
                    v-model="fee.pocWhom"
                />
            </div>
            <div v-if="isHud" class="col col-12 col-md-6 col-lg-3 form-group">
                <label for="txt_poc_amount">POC Amount</label>
                <rq-input-number
                    id="txt_poc_amount"
                    formatType="money"
                    defaultValue="0"
                    decimals="2"
                    value-event="input"
                    :disabled="readOnly"
                    v-model="fee.pocAmount"
                />
            </div>
            <div v-if="isHud2010" class="col col-12 col-md-6 col-lg-3 form-group">
                <label></label>
                <b-form-checkbox
                    automation_id="chk_apply_to_title_svc"
                    :disabled="readOnly"
                    v-model="fee.applyToTitleServices">Apply to Title Services</b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { mapState, mapGetters } from "vuex";
    import { CompanyPicker } from '@/shared/components/rq';
    import { POCWhoIntOptions, POCNetFundOptions, OrderSettlementFee } from "@settlement/models";
    import { SETTLEMENT_TYPE } from '../../models';
    import { DateTime } from "luxon";

    export default {
        components: {
            CompanyPicker
        },
        props: {
            orderSettlementFee: { type: Object, default: () => ({}) },
            settlementType: { type: Number, default: SETTLEMENT_TYPE.CDF },
            isWithOutSeller: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false }
        },

        setup: () => ({ v$: useVuelidate() }),

        data(){
            const self = this;
            return {
                fee: new OrderSettlementFee(),
                showBanner: false,
            };
        },

        computed: {
            ...mapState({
                cdfSections: state => _.map(state.orders.orderLookups.cdfSections, item => ({ id:_.parseNumber(item.id), name: item.name })),
                lineTypes: state => state.system.lookups.settlementFeeLineTypes,
                orderRegion: state => _.getNumber(state, "orders.order.regionID")
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            cdfSectionsEmpty() { return _.isEmpty(this.cdfSections); },
            lineTypesEmpty() { return _.isEmpty(this.lineTypes); },
            buyerTaxDisplay(){ return this.formatMoney(this.fee.buyerTax); },
            buyerTotalDisplay(){ return this.formatMoney(this.fee.buyerTotal); },
            sellerTaxDisplay(){ return this.formatMoney(this.fee.sellerTax); },
            sellerTotalDisplay(){ return this.formatMoney(this.fee.sellerTotal); },
            overrideDateDisplay() { return _.isNil(this.fee.overrideDate) ? "" : DateTime.fromJSDate(this.fee.overrideDate).toFormat("MM/dd/yyyy"); },
            isCdf() { return this.settlementType === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.settlementType === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.settlementType === SETTLEMENT_TYPE.HUD_2010; },
            accountCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, this.orderRegion, this.fee.accountingCodeID); }
        },

        watch: {
            orderSettlementFee:{
                handler(newValue, oldValue) {
                    this.fee = new OrderSettlementFee(newValue);
                },
                immediate: true
            }
        },

        validations() {
            return {
                fee: {
                    description: { required }
                }
            };
        },

        created() {
            this.pocWhoIntOptions = POCWhoIntOptions.lookupItems;
            this.netFundOptions = POCNetFundOptions.lookupItems;
        },

        methods:{

            isValid() {
                this.v$.$touch();
                this.showBanner = true;
                return !this.v$.$error;
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); }
        }
    };
</script>

