import { LoanHolderType, AlertSeverity } from "@/shared/models/enums";

export default class OrderRateCalculation {
	constructor(options) {
		options = options || {};
		this.orderRateCalculationID = options.orderRateCalculationID || options.cdfOrderRateCalculationID || null;

		let uniqueKey = _.parseNumber(this.orderRateCalculationID, 0) || _.uniqueId();
		this.clientKey = `rate${uniqueKey}`;
		this.clientHash = `#${this.clientKey}`;

		this.cdfOrderRateCalculationID = options.cdfOrderRateCalculationID || options.orderRateCalculationID || null;
		this.ordersID = options.ordersID || null;
		this.loanHolder = _.parseNumber(options.loanHolder, 0);
		this.policyDate = options.policyDate || null;
		this.rateID = options.rateID || null;
		this.loanFundDate = options.loanFundDate || null;
		this.simultaneousCalculation = _.parseBool(options.simultaneousCalculation, null);
		this.loanID = options.loanID || null;
		this.loanNumber = _.parseNumber(options.loanNumber, null);
		this.policyNumber = options.policyNumber || null;
		this.loanAmount = _.parseNumber(options.loanAmount, null);
		this.saleAmount = _.parseNumber(options.saleAmount, null);
		this.loanOverrideAmount = _.parseNumber(options.loanOverrideAmount, null);
		this.salesOverrideAmount = _.parseNumber(options.salesOverrideAmount, null);
		this.sectionID = options.sectionID || null;
		this.cdfLine = options.cdfLine || null;
		this.payeeCompanyID = options.payeeCompanyID || null;
		this.payeeCompanyName = options.payeeCompanyName || null;
		this.payeeContactID = options.payeeContactID || null;
		this.accountingCodeID = options.accountingCodeID || null;
		this.previousAmount = _.parseNumber(options.previousAmount, null);
		this.addtlRemitDate = options.addtlRemitDate || null;

		// NOTE: this applies to loan_Something and owner_Something variables, these are local variables which are dynamically accessed on
		// some of the getters and setters on this object.
		this.loan_CalculatedBasicPremium = _.parseNumber(options.loan_CalculatedBasicPremium, null);
		this.loan_CalculatedRoundedPremiumAmount = _.parseNumber(options.loan_CalculatedRoundedPremiumAmount, null);
		this.loan_CalculatedEndorsementTotal = _.parseNumber(options.loan_CalculatedEndorsementTotal, null);
		this.loan_CalculatedSimultaneousFeeAmount = _.parseNumber(options.loan_CalculatedSimultaneousFeeAmount, null);
		this.loan_CalculatedCreditTotal = _.parseNumber(options.loan_CalculatedCreditTotal, null);
		this.loan_CalculatedTotalPremium = _.parseNumber(options.loan_CalculatedTotalPremium, null);
		this.loan_CalculatedRoundedPremiumAmount_Assoc = _.parseNumber(options.loan_CalculatedRoundedPremiumAmount_Assoc, null);
		this.loan_CalculatedFeeTotal = _.parseNumber(options.loan_CalculatedFeeTotal, null);
		this.loan_CalculatedPremiumEndorsements = _.parseNumber(options.loan_CalculatedPremiumEndorsements, null);
		this.loan_CalculatedUnderwriterSplit = _.parseNumber(options.loan_CalculatedUnderwriterSplit, null);
		this.loan_CalculatedSimultaneousSplit = _.parseNumber(options.loan_CalculatedSimultaneousSplit, null);
		this.loan_CalculatedUnderwriterSplit_Assoc = _.parseNumber(options.loan_CalculatedUnderwriterSplit_Assoc, null);
		this.loan_OverrideBasicPremium = _.parseNumber(options.loan_OverrideBasicPremium, null);
		this.loan_OverrideRoundedPremiumAmount = _.parseNumber(options.loan_OverrideRoundedPremiumAmount, null);
		this.loan_OverrideEndorsementTotal = _.parseNumber(options.loan_OverrideEndorsementTotal, null);
		this.loan_OverrideSimultaneousFeeAmount = _.parseNumber(options.loan_OverrideSimultaneousFeeAmount, null);
		this.loan_OverrideCreditTotal = _.parseNumber(options.loan_OverrideCreditTotal, null);
		this.loan_OverrideTotalPremium = _.parseNumber(options.loan_OverrideTotalPremium, null);
		this.loan_OverrideTotalPremium_Assoc = _.parseNumber(options.loan_OverrideTotalPremium_Assoc, null);
		this.loan_OverridePremiumEndorsements = _.parseNumber(options.loan_OverridePremiumEndorsements, null);
		this.loan_TaxRateBasic = _.parseNumber(options.loan_TaxRateBasic, null);
		this.loan_TaxAmountBasic = _.parseNumber(options.loan_TaxAmountBasic, null);
		this.loan_TaxTotalBasic = _.parseNumber(options.loan_TaxTotalBasic, null);
		this.loan_TaxRateSimultaneous = _.parseNumber(options.loan_TaxRateSimultaneous, null);
		this.loan_TaxAmountSimultaneous = _.parseNumber(options.loan_TaxAmountSimultaneous, null);
		this.loan_TaxTotalSimultaneous = _.parseNumber(options.loan_TaxTotalSimultaneous, null);
		this.loan_TaxRateAssociated = _.parseNumber(options.loan_TaxRateAssociated, null);
		this.loan_TaxAmountAssociated = _.parseNumber(options.loan_TaxAmountAssociated, null);
        this.loan_TaxTotalAssociated = _.parseNumber(options.loan_TaxTotalAssociated, null);

		this.owner_CalculatedBasicPremium = _.parseNumber(options.owner_CalculatedBasicPremium, null);
		this.owner_CalculatedRoundedPremiumAmount = _.parseNumber(options.owner_CalculatedRoundedPremiumAmount, null);
		this.owner_CalculatedEndorsementTotal = _.parseNumber(options.owner_CalculatedEndorsementTotal, null);
		this.owner_CalculatedSimultaneousFeeAmount = _.parseNumber(options.owner_CalculatedSimultaneousFeeAmount, null);
		this.owner_CalculatedCreditTotal = _.parseNumber(options.owner_CalculatedCreditTotal, null);
		this.owner_CalculatedTotalPremium = _.parseNumber(options.owner_CalculatedTotalPremium, null);
		this.owner_CalculatedRoundedPremiumAmount_Assoc = _.parseNumber(options.owner_CalculatedRoundedPremiumAmount_Assoc, null);
		this.owner_CalculatedFeeTotal = _.parseNumber(options.owner_CalculatedFeeTotal, null);
		this.owner_CalculatedPremiumEndorsements = _.parseNumber(options.owner_CalculatedPremiumEndorsements, null);
		this.owner_CalculatedUnderwriterSplit = _.parseNumber(options.owner_CalculatedUnderwriterSplit, null);
		this.owner_CalculatedSimultaneousSplit = _.parseNumber(options.owner_CalculatedSimultaneousSplit, null);
		this.owner_CalculatedUnderwriterSplit_Assoc = _.parseNumber(options.owner_CalculatedUnderwriterSplit_Assoc, null);
		this.owner_OverrideBasicPremium = _.parseNumber(options.owner_OverrideBasicPremium, null);
		this.owner_OverrideRoundedPremiumAmount = _.parseNumber(options.owner_OverrideRoundedPremiumAmount, null);
		this.owner_OverrideEndorsementTotal = _.parseNumber(options.owner_OverrideEndorsementTotal, null);
		this.owner_OverrideSimultaneousFeeAmount = _.parseNumber(options.owner_OverrideSimultaneousFeeAmount, null);
		this.owner_OverrideCreditTotal = _.parseNumber(options.owner_OverrideCreditTotal, null);
		this.owner_OverrideTotalPremium = _.parseNumber(options.owner_OverrideTotalPremium, null);
		this.owner_OverrideTotalPremium_Assoc = _.parseNumber(options.owner_OverrideTotalPremium_Assoc, null);
		this.owner_OverridePremiumEndorsements = _.parseNumber(options.owner_OverridePremiumEndorsements, null);
		this.owner_TaxRateBasic = _.parseNumber(options.owner_TaxRateBasic, null);
		this.owner_TaxAmountBasic = _.parseNumber(options.owner_TaxAmountBasic, null);
		this.owner_TaxTotalBasic = _.parseNumber(options.owner_TaxTotalBasic, null);
		this.owner_TaxRateSimultaneous = _.parseNumber(options.owner_TaxRateSimultaneous, null);
		this.owner_TaxAmountSimultaneous = _.parseNumber(options.owner_TaxAmountSimultaneous, null);
		this.owner_TaxTotalSimultaneous = _.parseNumber(options.owner_TaxTotalSimultaneous, null);
		this.owner_TaxRateAssociated = _.parseNumber(options.owner_TaxRateAssociated, null);
		this.owner_TaxAmountAssociated = _.parseNumber(options.owner_TaxAmountAssociated, null);
		this.owner_TaxTotalAssociated = _.parseNumber(options.owner_TaxTotalAssociated, null);

		this.applyOverride_Basic = _.parseBool(options.applyOverride_Basic, null);
		this.sellerPayPctForOwner_Basic = _.parseNumber(options.sellerPayPctForOwner_Basic, null);
		this.buyerPayPctForLoan_Basic = _.parseNumber(options.buyerPayPctForLoan_Basic, null);
		this.sellerToPay_Basic = _.parseNumber(options.sellerToPay_Basic, null);
		this.buyerToPay_Basic = _.parseNumber(options.buyerToPay_Basic, null);
		this.nonGFE_Basic = _.parseBool(options.nonGFE_Basic);

		this.applyOverride_Simultaneous = _.parseBool(options.applyOverride_Simultaneous, null);
		this.sellerPayPctForOwner_Simultaneous = _.parseNumber(options.sellerPayPctForOwner_Simultaneous, null);
		this.buyerPayPctForLoan_Simultaneous = _.parseNumber(options.buyerPayPctForLoan_Simultaneous, null);
		this.sellerToPay_Simultaneous = _.parseNumber(options.sellerToPay_Simultaneous, null);
		this.buyerToPay_Simultaneous = _.parseNumber(options.buyerToPay_Simultaneous, null);
		this.nonGFE_Simultaneous = _.parseBool(options.nonGFE_Simultaneous);

		this.applyOverride_Ric = _.parseBool(options.applyOverride_Ric, null);
		this.sellerPayPctForOwner_Ric = _.parseNumber(options.sellerPayPctForOwner_Ric, null);
		this.buyerPayPctForLoan_Ric = _.parseNumber(options.buyerPayPctForLoan_Ric, null);
		this.sellerToPay_Ric = _.parseNumber(options.sellerToPay_Ric, null);
		this.buyerToPay_Ric = _.parseNumber(options.buyerToPay_Ric, null);

		this.applyOverride_Assoc = _.parseBool(options.applyOverride_Assoc, null);
		this.sellerPayPctForOwner_Assoc = _.parseNumber(options.sellerPayPctForOwner_Assoc, null);
		this.buyerPayPctForLoan_Assoc = _.parseNumber(options.buyerPayPctForLoan_Assoc, null);
		this.sellerToPay_Assoc = _.parseNumber(options.sellerToPay_Assoc, null);
		this.buyerToPay_Assoc = _.parseNumber(options.buyerToPay_Assoc, null);

		this.sellerToPayEndorsement = _.parseNumber(options.sellerToPayEndorsement, null);
		this.buyerToPayEndorsement = _.parseNumber(options.buyerToPayEndorsement, null);

		this.buyerToPayTotal = _.parseNumber(options.buyerToPayTotal, null);
		this.sellerToPayTotal = _.parseNumber(options.sellerToPayTotal, null);

		this.sellerToPayFees = _.parseNumber(options.sellerToPayFees, null);
        this.buyerToPayFees = _.parseNumber(options.buyerToPayFees, null);

		// NOTE: KPACA 1/9/2020 - Not Used in the UI but its being populated and passed back to the api for processing
		this.poC_BasicAmount = _.parseNumber(options.poC_BasicAmount, null);
		this.poC_Basic_Who = options.poC_Basic_Who || null;
		this.poC_Basic_NF = options.poC_Basic_NF || null;
		this.poC_Simult_Amount = options.poC_Simult_Amount || null;
		this.poC_Simult_Who = options.poC_Simult_Who || null;
		this.poC_Simult_NF = options.poC_Simult_NF || null;
		// NOTE: KPACA 1/9/2020 - Not Used in the UI but its being populated and passed back to the api for processing

		this.overrideUnderwriter = _.parseBool(options.overrideUnderwriter, null);
		this.overrideUnderwriterAmount = _.parseNumber(options.overrideUnderwriterAmount, null);
		this.underwriterSplitPercent = _.parseNumber(options.underwriterSplitPercent, null);

		this.buyerSellerAmountApplyOverride_Basic = _.parseBool(options.buyerSellerAmountApplyOverride_Basic, null);
        this.buyerSellerAmountApplyOverride_Simultaneous = _.parseBool(options.buyerSellerAmountApplyOverride_Simultaneous, null);
        this.buyerSellerAmountApplyOverride_Ric =_.parseBool(options.buyerSellerAmountApplyOverride_Ric, null);
        this.buyerSellerAmountApplyOverride_Assoc = _.parseBool(options.buyerSellerAmountApplyOverride_Assoc, null);

		//Derived properties
		this.premiumName = options.premiumName || "[Select a premium name]";
		this.lenderName = options.lenderName || "";
		this.description = (this.isLoanHolder)
			? "Owner"
			: _.isEmpty(this.lenderName)
				? "Loan"
				: `Loan (${this.lenderName})`;

		this.isCdfPremium = _.parseBool(options.isCdfPremium);

		this.alerts = options.alerts || [];

		this.summaryExpanded = _.parseBool(options.summaryExpanded);
		this.isOverrideCDFAdjustment = false;
		this.isOverrideHUDAdjustment = false;
		//Policy Code Addition to UI summary
		this.policyCode = options.policyCode;
		this.reportCode = options.reportCode;
		this.reissueCreditReportCode = options.reissueCreditReportCode;//RQO-18890
		this.endorsementTotals = {
			buyerEndorsements: 0,
			sellerEndorsements: 0,
			endorsementUwSplit: 0,
			endorsementTotal: 0,
			showEndorsements: false,
			buyerFees: 0,
			sellerFees: 0,
			feeUwSplit: 0,
			feeTotal: 0,
			showFees: false
		};
	}

	get buyerSellerAmountApplyOverride() {
		if (_.parseBool(this.isCdfPremium, false))
			return this[`buyerSellerAmountApplyOverride_Basic`];
		else
			return this[`buyerSellerAmountApplyOverride_${this.simultaneousBasicRateType}`];
	}
	set buyerSellerAmountApplyOverride(val) {
		if (_.parseBool(this.isCdfPremium, false))
			this[`buyerSellerAmountApplyOverride_Basic`] = val;
		else
			this[`buyerSellerAmountApplyOverride_${this.simultaneousBasicRateType}`] = val;
	}

	get isLoanHolder() { return this.loanHolder === LoanHolderType.Owner; }
	set isLoanHolder(val) { this.loanHolder = _.parseBool(val) ? LoanHolderType.Owner : LoanHolderType.Loan; }

	get noTax() { return !_.gt(this.taxRate, 0) && !_.gt(this.taxRateAssociated, 0); }
	get noRic() { return _.isNull(this.creditTotal) || _.eq(this.creditTotal, 0); }

	get id() { return this.orderRateCalculationID; }
	get displayValues() {
		return [
			{ value: this.description, hideIfEmpty: false, format:"title" },
			{ label:"Premium Name", value: this.premiumName, hideIfEmpty: true, format:"none" },
			{
				label:"Amount",
				value: this.noTax
					? this.totalPremium
					: this.applyOverride
						? (this.totalPremium + this.taxAmount)
						: this.taxTotal,
				hideIfEmpty: false,
				format:"money"
			}
		];
	}

	get premiumNameDisplay() {
		let simulCal = this.simultaneousCalculation;
		let labelDisplay = simulCal
								? this.isCdfPremium
										?  "CDF Simultaneous Fee"
										:  "Simultaneous Fee"
								: "";
		if(labelDisplay !== "")
			return labelDisplay;
		let validPremiumName = false;
		if (this.premiumName !== "[Select a premium name]")
			validPremiumName = true;
		if(!validPremiumName)
			labelDisplay = "Basic Premium";
		else if(validPremiumName)
			labelDisplay = this.premiumName;
		return labelDisplay;
	}

	get policyLiabilityTotalLabel() { return this.isLoanHolder ?"Sale Price" : "Loan Amount"; }
	get policyLiabilityTotal() { return this.isLoanHolder ? this.saleAmount : this.loanAmount; }
	set policyLiabilityTotal(val) {
		if(this.isLoanHolder)
			this.saleAmount = val;
		else
			this.loanAmount = val;
	}

	get policyLiabilityOverrideLabel() { return this.isLoanHolder ? "Owner Liability Override" : "Loan Liability Override"; }
	get policyLiabilityOverride() { return this.isLoanHolder ? this.salesOverrideAmount : this.loanOverrideAmount; }
	set policyLiabilityOverride(val) {
		if(this.isLoanHolder)
			this.salesOverrideAmount = val;
		else
			this.loanOverrideAmount = val;
	}

	get sellerPayPctForOwner() {
		if (_.parseBool(this.isCdfPremium, false))
			return this[`sellerPayPctForOwner_Basic`];
		else
			return this[`sellerPayPctForOwner_${this.simultaneousBasicRateType}`];
	}
	set sellerPayPctForOwner(val) {
		if (_.parseBool(this.isCdfPremium, false))
			this[`sellerPayPctForOwner_Basic`] = val;
		else
			this[`sellerPayPctForOwner_${this.simultaneousBasicRateType}`] = val;
	}

	get buyerPayPctForLoan() {
		if (_.parseBool(this.isCdfPremium, false))
			return this[`buyerPayPctForLoan_Basic`];
		else
			return this[`buyerPayPctForLoan_${this.simultaneousBasicRateType}`];
	}
	set buyerPayPctForLoan(val) {
		if (_.parseBool(this.isCdfPremium, false))
			this[`buyerPayPctForLoan_Basic`] = val; //FYI - per RQ1, CDF simulataneous maps to basic fields
		else
			this[`buyerPayPctForLoan_${this.simultaneousBasicRateType}`] = val;
	}

	get sellerToPay() {
		if (_.parseBool(this.isCdfPremium, false))
			return this[`sellerToPay_Basic`];
		else
			return this[`sellerToPay_${this.simultaneousBasicRateType}`];
	}
	set sellerToPay(val) {
		if (_.parseBool(this.isCdfPremium, false))
			this[`sellerToPay_Basic`] = val; //FYI - per RQ1, CDF simulataneous maps to basic fields
		else
			this[`sellerToPay_${this.simultaneousBasicRateType}`] = val;
	}

	get buyerToPay() {
		if (_.parseBool(this.isCdfPremium, false))
			return this[`buyerToPay_Basic`]; //FYI - per RQ1, CDF simulataneous maps to basic fields
		else
			return this[`buyerToPay_${this.simultaneousBasicRateType}`];
	}
	set buyerToPay(val) {
		if (_.parseBool(this.isCdfPremium, false))
			this[`buyerToPay_Basic`] = val; //FYI - per RQ1, CDF simulataneous maps to basic fields
		else
			this[`buyerToPay_${this.simultaneousBasicRateType}`] = val;
	}

	get nonGFE() {return this[`nonGFE_${this.simultaneousBasicRateType}`]; }
	set nonGFE(val) { this[`nonGFE_${this.simultaneousBasicRateType}`] = val; }

	get applyOverride() {
		if (_.parseBool(this.isCdfPremium, false))
			return this[`applyOverride_Basic`]; //FYI - per RQ1, CDF simulataneous maps to basic fields
		return this[`applyOverride_${this.simultaneousBasicRateType}`];
	 }
	set applyOverride(val) {
		if (_.parseBool(this.isCdfPremium, false))
			this.applyOverride_Basic = val; //FYI - per RQ1, CDF simulataneous maps to basic fields
		else {
			this.applyOverride_Basic = val && !this.simultaneousCalculation;
			this.applyOverride_Simultaneous = val && this.simultaneousCalculation;
		}
	}

	// NOTE: RQ1 seems to have extra logic that deals with rounding which is set in configuration.
	// we have a separate story that will deal with rounding. as of now i will use the rounded amounts
	get calculatedPremiumAmount() {
		if (_.parseBool(this.isCdfPremium, false))
			// return this.applyOverride ? this[`${this.ownerLoanPrefix}CalculatedBasicPremium`] : this[`${this.ownerLoanPrefix}CalculatedRoundedPremiumAmount`];
			return this[`${this.ownerLoanPrefix}CalculatedRoundedPremiumAmount`];
		else
			return _.parseBool(this.simultaneousCalculation, false)
				? this[`${this.ownerLoanPrefix}CalculatedSimultaneousFeeAmount`]
				: this[`${this.ownerLoanPrefix}CalculatedRoundedPremiumAmount`];
	}

	// NOTE: RQ1 seems to have extra logic that deals with rounding which is set in configuration.
	// we have a separate story that will deal with rounding. as of now i will use the rounded amounts
	get premiumAmount() {
		if (_.parseBool(this.isCdfPremium, false))
			return this.applyOverride ? this[`${this.ownerLoanPrefix}OverrideBasicPremium`] : this[`${this.ownerLoanPrefix}CalculatedRoundedPremiumAmount`];
		else
			return _.parseBool(this.simultaneousCalculation, false)
				? this[`${this.ownerLoanPrefix}OverrideSimultaneousFeeAmount`]
				: (this.applyOverride ? this[`${this.ownerLoanPrefix}OverrideBasicPremium`] : this[`${this.ownerLoanPrefix}CalculatedRoundedPremiumAmount`]);
	}
	set premiumAmount(val) {
		if (_.parseBool(this.isCdfPremium, false))
			this[`${this.ownerLoanPrefix}OverrideBasicPremium`] = val;
		else {
			if(_.parseBool(this.simultaneousCalculation, false))
				this[`${this.ownerLoanPrefix}OverrideSimultaneousFeeAmount`] = val;
			else
				this[`${this.ownerLoanPrefix}OverrideBasicPremium`] = val;
		}
	}

	get agentTotal() { return (_.parseNumber(this.premiumAmount, 0) - _.parseNumber(this.underwriterSplit, 0)) + _.parseNumber(this.creditTotal, 0); }

	get simultaneousFeeAmount() { return this.applyOverride ? this[`${this.ownerLoanPrefix}OverrideSimultaneousFeeAmount`] : this[`${this.ownerLoanPrefix}CalculatedSimultaneousFeeAmount`]; }
	set simultaneousFeeAmount(val) { this[`${this.ownerLoanPrefix}OverrideSimultaneousFeeAmount`] = val; }

	get calculatedCreditTotal() { return this[`${this.ownerLoanPrefix}CalculatedCreditTotal`]; }
	get creditTotal() { return this.applyOverride ? this[`${this.ownerLoanPrefix}OverrideCreditTotal`] : this[`${this.ownerLoanPrefix}CalculatedCreditTotal`]; }
	set creditTotal(val) { this[`${this.ownerLoanPrefix}OverrideCreditTotal`] = val; }

	get endorsementTotal() { return this.applyOverride ? this[`${this.ownerLoanPrefix}OverrideEndorsementTotal`] : this[`${this.ownerLoanPrefix}CalculatedEndorsementTotal`]; }
	set endorsementTotal(val) { this[`${this.ownerLoanPrefix}OverrideEndorsementTotal`] = val; }

	// [RQO-3043] MPH
	get totalPremium() {
		return this.applyOverride ? this[`${this.ownerLoanPrefix}OverrideTotalPremium`] : this[`${this.ownerLoanPrefix}CalculatedTotalPremium`];
	}
	set totalPremium(val) { this[`${this.ownerLoanPrefix}OverrideTotalPremium`] = val; }

	get premiumEndorsements() { return this.applyOverride ? this[`${this.ownerLoanPrefix}OverridePremiumEndorsements`] : this[`${this.ownerLoanPrefix}CalculatedPremiumEndorsements`]; }
	set premiumEndorsements(val) { this[`${this.ownerLoanPrefix}OverridePremiumEndorsements`] = val; }

	get calculatedFeeTotal() { return this[`${this.ownerLoanPrefix}CalculatedFeeTotal`]; }
	set calculatedFeeTotal(val) { this[`${this.ownerLoanPrefix}CalculatedFeeTotal`] = val; }

	get calculatedUnderwriterSplit() {
		return _.parseBool(this.simultaneousCalculation, false)
			? this[`${this.ownerLoanPrefix}CalculatedSimultaneousSplit`]
			: this[`${this.ownerLoanPrefix}CalculatedUnderwriterSplit`];
	}
	set calculatedUnderwriterSplit(val) { this.overrideUnderwriterAmount = val;	}

	get underwriterSplit() { return this.overrideUnderwriter ? this.overrideUnderwriterAmount : this.calculatedUnderwriterSplit; }
	set underwriterSplit(val) { this.overrideUnderwriterAmount = val; }

	get taxRate() { return _.parseNumber(this[`${this.ownerLoanPrefix}TaxRate${this.simultaneousBasicRateType}`], 0); }
	set taxRate(val) { this[`${this.ownerLoanPrefix}TaxRate${this.simultaneousBasicRateType}`] = val; }

	get taxAmount() { return _.parseNumber(this[`${this.ownerLoanPrefix}TaxAmount${this.simultaneousBasicRateType}`], 0); }
	set taxAmount(val) { this[`${this.ownerLoanPrefix}TaxAmount${this.simultaneousBasicRateType}`] = val; }

	get taxTotal() { return (this.isCdfPremium && !this.isLoanHolder) ? _.parseNumber(this[`${this.ownerLoanPrefix}TaxTotalBasic`], 0) : _.parseNumber(this[`${this.ownerLoanPrefix}TaxTotal${this.simultaneousBasicRateType}`], 0);	}
	set taxTotal(val) { this[`${this.ownerLoanPrefix}TaxTotal${this.simultaneousBasicRateType}`] = val; }

	get sellerToPayAdjustment() { return _.parseNumber(this.sellerToPay + this.sellerToPay_Assoc + this.sellerToPay_Ric); }

	/* Associated getter/setters */
	get calculatedPremiumAmount_Assoc() { return this[`${this.ownerLoanPrefix}CalculatedRoundedPremiumAmount_Assoc`]; }

	get premiumAmount_Assoc() { return this.applyOverride_Assoc ? this[`${this.ownerLoanPrefix}OverrideTotalPremium_Assoc`] : this[`${this.ownerLoanPrefix}CalculatedRoundedPremiumAmount_Assoc`]; }
	set premiumAmount_Assoc(val) { this[`${this.ownerLoanPrefix}OverrideTotalPremium_Assoc`] = val; }

	get underwriterSplit_Assoc() { return this[`${this.ownerLoanPrefix}CalculatedUnderwriterSplit_Assoc`]; }

	get taxRateAssociated() { return _.parseNumber(this[`${this.ownerLoanPrefix}TaxRateAssociated`], 0); }
	set taxRateAssociated(val) { this[`${this.ownerLoanPrefix}TaxRateAssociated`] = val; }

	get taxAmountAssociated() { return _.parseNumber(this[`${this.ownerLoanPrefix}TaxAmountAssociated`], 0); }
	set taxAmountAssociated(val) { this[`${this.ownerLoanPrefix}TaxAmountAssociated`] = val; }

	get taxTotalAssociated() { return _.parseNumber(this[`${this.ownerLoanPrefix}TaxTotalAssociated`], 0);	}
	set taxTotalAssociated(val) { this[`${this.ownerLoanPrefix}TaxTotalAssociated`] = val; }
	/******************************/

	get ownerLoanPrefix() { return this.isLoanHolder ? "owner_" : "loan_"; }
	get simultaneousBasicRateType() { return this.simultaneousCalculation ? "Simultaneous" : "Basic"; }

	get hasAlerts() { return !_.isEmpty(this.alerts); }
	get hasError() { return this.hasAlert(AlertSeverity.Error); }
	get hasWarning() { return this.hasAlert(AlertSeverity.Warning); }

	hasAlert(severity) { return this.hasAlerts && _.some(this.alerts, a => a.severity === severity); }

	toDataObject() { return _.pickBy(this, (v,k) => { return !_.includes(["description", "premiumName", "lenderName", "cdfTotal", "alerts", "summaryExpanded"], k); }); }
}

const simultaneousOptions = { None: 0, LoanWithSimultOwner: 1, OwnerWithSimultLoan: 2 };
export class SimultaneousOptions {
    static get None () {return simultaneousOptions.None;}
    static get LoanWithSimultOwner () {return simultaneousOptions.LoanWithSimultOwner;}
    static get OwnerWithSimultLoan () {return simultaneousOptions.OwnerWithSimultLoan;}

    static get lookupItems () {
        return _.map(simultaneousOptions, c => { return { id: c, name: SimultaneousOptions.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case simultaneousOptions.LoanWithSimultOwner: return 'Yes (Loan with Simultaneous Owner Policy)';
            case simultaneousOptions.OwnerWithSimultLoan: return 'Yes (Owner with Simultaneous Loan Policy)';
        }
        return 'No';
    }
}
