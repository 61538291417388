import { CompanyEdit, ContactEdit, CompanyContacts } from "@utilities/manage-companies/components";
import SettlementStatementContacts from "@settlement/components/contacts/SettlementStatementContacts";
import SettlementStatementContactCards from "@settlement/components/contacts/SettlementStatementContactCards";

import { ContactsViewActions } from "@/router/actions/order-entry";
import ViewActions from "@/router/actions/settlement";

const noContactAccessMsg = "Access to order contacts is not authorized.  Please contact your administrator.";

export default {
    path: "settlement-statement-contacts",
    component: SettlementStatementContacts,
    meta: {
        label: "CDF Contacts",
        include: true,
        requireSSMapping: true
    },
    children: [{
            name: "settlement:contacts:contact-cards",
            path: "",
            component: SettlementStatementContactCards,
            meta: {
                include: false,
                entity: "CDF Contacts",
                extension: false,
                label: "CDF Contacts",
                actions: ViewActions.SETTLEMENT_CONTACTS,
                accessKey: "GenericSettlementStatement",
                unauthorizedMessage: noContactAccessMsg,
                skipSaveOnLeave: true
            }
        },
        {
            name: "settlement:contacts:company",
            path: "company/:companyId?",
            component: CompanyEdit,
            props: route => ({
                companyId: _.getNumber(route, "params.companyId", 0),
                showSettlementLinks: true
            }),
            meta: {
                entity: "Company",
                extension: false,
                label: "Company",
                actions: ContactsViewActions.COMPANY,
                accessKey: "GenericSettlementStatement",
                unauthorizedMessage: noContactAccessMsg,
                skipSaveOnLeave: true
            }
        },
        {
            name: "settlement:contacts:contact",
            path: "company/:companyId/contact/:contactId?",
            component: ContactEdit,
            props: route => ({
                contactId: _.getNumber(route, "params.contactId", 0),
                companyId: _.getNumber(route, "params.companyId", 0),
                companyName: _.get(route, "params.companyName", ""),
                showSettlementLinks: true
            }),
            meta: {
                entity: "Contact",
                extension: false,
                label: "Contact",
                actions: ContactsViewActions.CONTACT,
                accessKey: "GenericSettlementStatement",
                unauthorizedMessage: noContactAccessMsg,
                skipSaveOnLeave: true
            }
        },
        {
            name: "settlement:contacts:company-contacts",
            path: "company/:companyId/company-contacts",
            component: CompanyContacts,
            props: route => ({
                companyId: _.getNumber(route, "params.companyId", 0),
                companyName: _.get(route, "params.companyName", ""),
                regionId: _.getNumber(route, "params.regionId", 0),
                showSettlementLinks: true,
                allowAdd: true
            }),
            meta: {
                entity: "Contact",
                extension: false,
                label: "Company Contacts",
                actions: ContactsViewActions.CONTACT,
                accessKey: "GenericSettlementStatement",
                skipSaveOnLeave: true,
                unauthorizedMessage: noContactAccessMsg
            }
        }
    ]
}