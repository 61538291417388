// SettlementStatementCalculators.js
import { computed } from "vue";
import { useRqDialog, useRqBusy, useRqToast } from "@/shared/plugins/composables";
import { useStore } from "vuex";

import Prorations from "./components/prorations/ProrationCalculator";
import PremiumsMain from "./components/premiums/PremiumsMain";
import SettlementFees from "./components/fees/SettlementFeeList";
import RecordingFeesAndTaxes from "./components/recording-fees-and-taxes/RecordingFeesAndTaxes";
import RealtorCommission from "./components/realtor-commission/RealtorCommission";
import AggregateAdjustment from "./components/aggregate-adjustment/AggregateAdjustment";
import PayoffList from "./components/payoffs/PayoffList";
import PayOffDetail from "./components/payoffs/PayOffDetail";
import PaidByOthers from "./components/paid-by-others/PaidByOthers.vue";
import Addendums from "./components/addendums/Addendums.vue";

const PRORATIONS = "settlement:prorations";
const PREMIUMS = "premiums";
const FEES = "settlement:fees";
const COMMISSIONS = "settlement:realtor-commission";
const PAID_BY_OTHERS = "settlement:paid-by-others";
const PAYOFFS = "settlement:payoffs:list";
const PAYOFF_DETAIL = "settlement:payoffs:detail";
const REC_FEES = "settlement:recording-fees-and-taxes";
const AGG_ADJUSTMENTS = "settlement:aggregate-adjustment";
const CDF_ADDENDUMS = "settlement:addendums";

export const CALCULATOR_TYPE = {
    PRORATIONS,
    PREMIUMS,
    FEES,
    COMMISSIONS,
    PAID_BY_OTHERS,
    PAYOFFS,
    PAYOFF_DETAIL,
    REC_FEES,
    AGG_ADJUSTMENTS,
    CDF_ADDENDUMS
};

export function useSettlementStatementCalculators() {
    const store = useStore();
    const { openDialog } = useRqDialog();
    // const { waitFor } = useRqBusy();
    // const { toastError } = useRqToast();

    const orderId = computed(() => store.state.orders.orderId);

    function getCalculatorComponent(key) {
        switch(key) {
            case PRORATIONS: return Prorations;
            case PREMIUMS: return PremiumsMain;
            case FEES: return SettlementFees;
            case REC_FEES: return RecordingFeesAndTaxes;
            case COMMISSIONS: return RealtorCommission;
            case PAID_BY_OTHERS: return PaidByOthers;
            case PAYOFFS: return PayoffList;
            case PAYOFF_DETAIL: return PayOffDetail;
            case AGG_ADJUSTMENTS: return AggregateAdjustment;
            case CDF_ADDENDUMS: return Addendums;
        }
    }

    function launchCalculator({ calculator="", title="Calculator", properties={}, onOk=()=>true, onCancel=()=>true, okOnly=false }) {
        if(!calculator) return;
        const calculatorComponent = getCalculatorComponent(calculator);
        openDialog({
            title,
            width: "80%",
            height: "80%",
            component: calculatorComponent,
            okTitle: "OK",
            okOnly: okOnly,
            props: _.assign({displayMode: "modal"}, properties),
            onOk: async (e) => {
                switch(calculator) {
                    case CALCULATOR_TYPE.REC_FEES:
                        await e.component.save?.(e.component.activeTabIndex);
                        break;
                    case CALCULATOR_TYPE.PAYOFF_DETAIL:
                        await e.component.save?.(properties);
                        break;
                    case CALCULATOR_TYPE.PRORATIONS:
                        break;
                    default: 
                        await e.component.save?.();        
                        break;
                }

                return onOk(e);
                // let result = e.component.save();
                // return result.isValid ? onOk(e) : false;
            },
            onCancel
        });
    }

    return {
        CALCULATOR_TYPE,
        launchCalculator
    };
}