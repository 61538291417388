<template>
    <div class="content-wrapper tp-policy-content">
        <rq-page-section :title="viewTitle" headerSize="lg" header-only borderless>
            <template #header-actions>
                <slot name="loan-selection"></slot>
            </template>
            <template #header-secondary>
                <slot name="loan-list-link"></slot>
                <button
                    automation_id="btn_view_premiums"
                    type="button"
                    class="btn btn-link btn-theme"
                    @click="onShowPremiums"
                    v-if="localSecurity.PremiumsAccessible">View Premiums
                </button>
            </template>
        </rq-page-section>
        <RqTabs
            :key="tabComponentKey"
            ref="tabsComponent"
            :tabs="tabItems"
            v-model="activeTabIndex"
            scrollable>
            <template #sched-a>
                <schedule-a
                    :loan-id="loanId"
                    v-model:commitment-data="commitmentData"
                    :original-data="originalCommitmentData"
                />
            </template>
            <template #legal-desc>
                <legal-description
                    v-model:commitment-data="commitmentData"
                />
            </template>
            <template #sched-b>
                <schedule-b
                    ref="schedBComponent"
                    :commitment-data="commitmentData"
                    title-production-tab="B"
                    v-model:has-changes="hasClauseChanges"
                />
            </template>
            <template #sched-c>
                <clause-management
                    ref="schedCComponent"
                    title="Schedule C"
                    title-production-tab="C"
                    :commitment-data="commitmentData"
                    v-model:has-changes="hasClauseChanges"
                />
            </template>
            <template #sched-d>
                <schedule-d />
            </template>
            <template
                v-for="tab in customTabs"
                :key="`tab_${tab.titleProdCustomTabID}`"
                #[tab.tabName]>
                <clause-management
                    :ref="tab.ref"
                    :title="tab.tabLabel"
                    :title-production-tab="`custom_${tab.titleProdCustomTabID}`"
                    :title-prod-custom-tab-id="tab.titleProdCustomTabID"
                    v-model:commitment-data="commitmentData"
                    v-model:has-changes="hasClauseChanges"
                />
            </template>
        </RqTabs>

        <form v-show="false" enctype="multipart/form-data" novalidate ref="fileForm">
            <input
                id="uploadFile"
                ref="fileInput"
                type="file"
                automation_id="txt_upload_file"
                name="commitments"
                :disabled="false"
                @change="onUploadFileChange"
                accept=".xml"
            >
        </form>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { COMMITMENT_ACTIONS, ORDER_ACTIONS } from "@/store/actions";
    import { GlobalEventManager } from "@/app.events";
    import { UploadCategory } from "@/shared/models/enums";
    import { CommitmentDataDto } from "../models";
    import { LoanModel, OrderMainDto } from "../../order-entry/models";
    import { ScheduleA, LegalDescription, ScheduleB, ClauseManagement, ScheduleD } from "../components/";
    import RegEx from "@/shared/utilities/RegEx";
    import PremiumsMain from "@settlement/components/premiums/PremiumsMain";

    export default {
        name: "TitleProduction",

        props: {
            loanId: { type: Number, default: 0 },
            viewTitle: { type: String, default: "Title Production" }
        },

        components: {
            ClauseManagement,
            ScheduleA,
            LegalDescription,
            ScheduleB,
            ScheduleD
        },

        data () {
            return {
                commitmentData: new CommitmentDataDto(),
                originalCommitmentData: new CommitmentDataDto(),
                selectedLoanId: 0,
                xml: {},
                dataLoaded: false,
                activeTabIndex: 0,
                customTabs: [],
                hasClauseChanges: false,
                tabComponentKey: _.parseNumber(_.uniqueId())
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                commitment: state => state.orders.commitment || {},
                loans: state => state.orders.loans || [],
                storedCustomTabs: state => state.orders.customTabs || [],
                readOnly: state => _.parseBool(state.isPageReadOnly),
                currentFileNo: state => state.orders.orderSummary.fileNumber,
                standardLanguageSections: state => state.orders.standardLanguageSections || [],
                contentChangePending: state => state.orders.contentChangePending,
                orderBranch: state => state.orders.branch || {},
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            commitmentLoan() { return _.find(this.loans, l => l.loanID === this.loanId); },
            currentRoutes() { return _.filter(this.tabRoutes, r => r.isAlta || !_.parseBool(this.orderSummary.isAlta)); },
            validCommitmentData () { return _.getNumber(this, "commitmentData.header.commitmentPolicyHeaderID", 0) > 0; },
            localSecurity() { return { PremiumsAccessible: this.securitySettings.hasAccess("Premiums") }; },
            tabItems() {
                let staticTabs = [
                    { title: "Schedule A", name: "sched-a" },
                    { title: "Legal Description", name: "legal-desc" },
                    { title: "Schedule B", name: "sched-b", lazy: true },
                    { title: "Schedule C", name: "sched-c", lazy: true, visible: !this.orderSummary.isAlta },
                    { title: "Schedule D", name: "sched-d", lazy: true, visible: !this.orderSummary.isAlta }
                ];
                let custTabs = _.map(this.customTabs, (tab, index) => ({
                    automation_id: `btn_tab_custom_${index}`,
                    title: tab.tabLabel,
                    name: tab.tabName,
                    ref: tab.ref,
                    lazy: true
                }));
                return [
                    ...staticTabs,
                    ...custTabs
                ];
            }
        },

        watch: {
            commitment: {
                handler() {
                    this.setCommitmentData();
                },
                deep: true
            },
            storedCustomTabs: {
                handler(newValue, oldValue) {
                    this.customTabs = _.map(newValue, (tab, index) => ({
                        tabName: `custom-tab-${index}`,
                        ref: `customTab${tab.titleProdCustomTabID}`,
                        ...tab
                    }));
                },
                immediate: true
            },
            loanId: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === 0) return;
                    this.fetchData();
                },
                immediate: true
            }
        },

        created () {
            const self = this;

            self.beginLoad = new Date();
            GlobalEventManager.onSave(self, self.onSave);

            if (!self.readOnly) {
                GlobalEventManager.onCancel(self, self.onCancel);
                self.$nextTick()
                    .then(() => {
                        //registering in next tick in case unregistered in previous route's beforeUnmount (passing function as argument in "off" doesn't work)
                        self.$events.on("apply-template-success", e => {
                            self.fetchData(true);
                        });
                        self.$events.on("title-production-refresh", e => {
                            self.fetchData(true);
                        });
                    });
            }
            GlobalEventManager.onFireAction(self, self.handleAction);
        },

        beforeUnmount() {
            this.$store.dispatch(COMMITMENT_ACTIONS.CLEAR_STATE);
            this.$events.off("apply-template-success");
            this.$events.off("title-production-refresh");
            GlobalEventManager.unregister(this);
        },

        methods: {
            fetchData(reset=false) {
                const self = this;
                let promises = [];
                if (reset) {
                    self.resetLazyLoadState();
                    promises.push(self.$store.dispatch(COMMITMENT_ACTIONS.CLEAR_STATE, true));
                }
                promises.push(self.$store.dispatch(COMMITMENT_ACTIONS.GET_TITLE_PRODUCTION_DATA, self.loanId));
                let storePromise = Promise.all(promises);
                return self.$rqBusy.wait(storePromise)
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Failed to retrieve Title Production Data");
                    });
            },

            handleAction (event) {
                if (this.$rqBusy.isBusy()) return;
                switch (event.key) {
                    case "upload":
                        this.handleUpload();
                        break;
                    case "download":
                        this.onHandleDownload();
                        break;
                }
            },

            setCommitmentData(obj=null) {
                let commitmentData = _.cloneDeep(obj || this.commitment);
                if (_.isNil(commitmentData)) return;
                commitmentData.detail.loanAmount = this.commitmentLoan.amount;
                commitmentData.detail.loanPolicyLiability = this.commitmentLoan.loanPolicyLiability;
                commitmentData.header.salesPrice = this.order.salesPrice;
                commitmentData.header.ownerPolicyLiability = this.order.ownerPolicyLiability;
                this.commitmentData = new CommitmentDataDto(commitmentData);
                this.originalCommitmentData = new CommitmentDataDto(commitmentData);
            },

            onCancel() {
                const self = this;
                if (self.$rqBusy.isBusy()) return;
                let changes = self.getAuditChanges(self.originalCommitmentData, self.commitmentData);
                if(_.isEmpty(changes) && !self.hasClauseChanges) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                self.$dialog.confirm("Confirm", "Discard changes and reload data?", () => {
                    self.setCommitmentData();
                    self.hasClauseChanges = false;
                });
            },

            onSave(args) {
                const self = this;
                if (this.$rqBusy.isBusy()) return;
                if(!this.contentChangePending) {
                    this.save(args);
                    return;
                }
                self.$rqBusy.startWait();
                let unwatchChangePending = self.$watch("contentChangePending", function(newValue) {
                    self.$rqBusy.endWait();
                    if(_.parseBool(newValue)) return;
                    unwatchChangePending();
                    this.save(args);
                });
            },

            onLegalDescriptionChange(content) {
                const self = this;
                self.commitmentData.detail.legalDescription = content.rtf;
                self.commitmentData.detail.legalDescriptionHtml = content.html;
            },

            resetLazyLoadState() {
                _.invoke(this, "$refs.tabsComponent.resetLazyLoadStates");
            },

            getClauseData() {
                const self = this;
                let schedBClauses = _.invoke(self, "$refs.schedBComponent.getListData") || [];
                let schedCClauses = _.invoke(self, "$refs.schedCComponent.getListData") || [];
                let customTabClauses = [];
                let customTabComponents = _.map(self.customTabs, tab=> _.get(self, `$refs.${tab.ref}`, null));
                _.remove(customTabComponents, _.isNil);
                if(!_.isEmpty(customTabComponents)) {
                    let customTabLists = _.map(customTabComponents, c => _.invoke(c, "getListData") || []);
                    customTabClauses = _.flatten(customTabLists);
                }
                return _.concat(schedBClauses, schedCClauses, customTabClauses);
            },

            async save(args) {
                const self = this;

                if (self.readOnly) return true;

                let userInitiated = _.parseBool(args?.userInitiated);
                let changes = self.getAuditChanges(self.originalCommitmentData, self.commitmentData);
                let assignedLanguages = self.hasClauseChanges ? self.getClauseData() : [];
                if (changes.length === 0 && _.isEmpty(assignedLanguages)) {
                    if (userInitiated) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({ success: true });
                    return true;
                }

                let currentHoldersUpdated = _.find(changes, r => {
                        return r.name.toLowerCase() === 'header.currentholderstext'
                    });

                if(!_.isNil(currentHoldersUpdated)) {
                    self.commitmentData.header.currentHoldersManualOverride = currentHoldersUpdated.new != '';
                    changes = self.getAuditChanges(self.originalCommitmentData, self.commitmentData);
                }

                self.commitmentData.syncDateTimeOffsetValues();

                const processSave = async () => {
                    const originalLoan = new LoanModel(self.commitmentLoan);
                    const modifiedLoan = new LoanModel(self.commitmentLoan);
                    
                    // RQO-31834 - The loan policy liability override is set to zero 
                    // unless the mortgage insurance amount differs from the loan amount.
                    modifiedLoan.loanPolicyLiability = self.commitmentData.detail.mortgageInsuredAmount == originalLoan.amount ?
                        0 : self.commitmentData.detail.mortgageInsuredAmount;
                    if (originalLoan.loanPolicyLiability !== modifiedLoan.loanPolicyLiability)
                    {
                        const loanChanges = self.getAuditChanges(originalLoan.toDto(), modifiedLoan.toDto());
                        await self.$store.dispatch(ORDER_ACTIONS.SAVE_LOAN, { loan: modifiedLoan, changes: loanChanges });
                    }

                    const originalOrder = new OrderMainDto(self.order);
                    const modifiedOrder = new OrderMainDto(self.order);
                    
                    // RQO-31834 - The owner policy liability override is set to zero 
                    // unless the owner insurance amount differs from the sales price.
                    modifiedOrder.ownerPolicyLiability = self.commitmentData.header.ownerInsuredAmount == originalOrder.salesPrice ?
                         0 : self.commitmentData.header.ownerInsuredAmount;
                    if (originalOrder.ownerPolicyLiability !== modifiedOrder.ownerPolicyLiability)
                    {
                        const orderChanges = self.getAuditChanges(originalOrder.toEntity(), modifiedOrder.toEntity());
                        await self.$store.dispatch(ORDER_ACTIONS.UPDATE_ORDER_MAIN, { order: modifiedOrder, changes: orderChanges });
                    }

                    let request = { data: { ...self.commitmentData, assignedLanguages }, changes };
                    let result = await self.$store.dispatch(COMMITMENT_ACTIONS.UPDATE_COMMITMENT_DATA, { request, updateClauses: self.hasClauseChanges });

                    return result;
                };

                let success = true;
                try {
                    let commitment = await self.$rqBusy.wait(processSave());
                    if(userInitiated) {
                        self.setCommitmentData(commitment);
                    }
                    else {
                        self.resetLazyLoadState();
                    }
                    self.hasClauseChanges = false;
                    self.$toast.success({ message: "Commitment Data Saved Successfully" });
                    success = true;
                }
                catch(error) {
                    console.error(error);
                    self.$toast.error("Failed to save Commitment Data");
                    success = false;
                }

                GlobalEventManager.saveCompleted({ success });

                return success;
            },

            handleUpload () {
                _.invoke(this, "$refs.fileInput.click");
            },

            onUploadFileChange (e) {
                const self = this;
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return Promise.resolve(true);

                const formData = new FormData();
                formData.append("FILE", e.target.files[0], e.target.files[0].name);
                formData.append("ORDER", self.orderId);
                formData.append("LOAN", self.loanId);
                formData.append("CATEGORY", UploadCategory.TitleProduction);

                return new Promise((resolve, reject) => {
                    let apiPromise = this.$api.CommitmentsApi.uploadCommmitmentData(formData);
                    this.$rqBusy.wait(apiPromise)
                        .then(result => {
                            if (result.success) {
                                self.clearUploadValue();
                                self.$toast.success({ message: "Document uploaded successfully." });
                                self.fetchData(true);
                            } else {
                                let errMsg = result.message ? result.message : "A problem occurred while uploading your document.  Please contact your administrator.";
                                self.$toast.error({ message: errMsg, autoHideEnabled: false });
                            }
                            resolve(true);
                        })
                        .catch(err => {
                            let errMsg = err.message ? err.message : "A problem occurred while uploading your document.  Please try again.";
                            self.$toast.error({ message: errMsg, autoHideEnabled: false });
                            reject(err);
                            return err;
                        });
                });
            },

            clearUploadValue () {
                this.$refs.fileInput.value = null;
                this.$refs.fileForm.reset();
            },

            onHandleDownload () {
                const self = this;
                self.$dialog.promptInput({
                    title: "Download",
                    label: "Enter download filename (without extension):",
                    value: `CommitmentData_${self.currentFileNo}`,
                    requiredMessage: "Filename is required",
                    validators: {
                        isValidFileName: {
                            validator: val => RegEx.FileName.test(val),
                            message: "Filename is not valid"
                        }
                    },
                    onOk: e => {
                        self.handleDownload(e.value);
                        return true;
                    }
                });
            },

            handleDownload (filename) {
                const self = this;

                let apiPromise = self.$api.CommitmentsApi.downloadCommmitmentData(self.orderId, self.loanId, filename);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.xml = result[1];
                        self.download(result[0]);
                    })
                    .catch(() => {
                        console.log("Error fetching download.");
                    });
            },

            download( filename) {
                const self = this;
                var file = new Blob([this.xml], {type: "xml"});
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(file, filename);
                else { // Others
                    var a = document.createElement("a"),
                            url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },

            onShowPremiums(){
                const self = this;
                if (!self.localSecurity.PremiumsAccessible) return;

                let okHandler = function (e) {
                    return e.component.save(true).then(() => {
                        self.fetchData();
                        return true;
                    });
                };
                
                self.save().then(() => {
                    self.$dialog.open({
                        title: "Premium Rates",
                        width: "95%",
                        minWidth: 1200,
                        height: "95%",
                        minHeight: 700,
                        component: PremiumsMain,
                        props: { noTitle: true, showHeaderCalculate: true },
                        onOk: okHandler
                    });
                });
            },

        }
    };
</script>