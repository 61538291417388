<template>
    <div class="row">
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.lot }" v-show="!selectedPropertyLayout.hideLot">
            <label class="form-control-label" for="txt_bld_lot">Lot</label>
            <input automation_id="txt_bld_lot" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.lot" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.block }" v-show="!selectedPropertyLayout.hideBlock">
            <label class="form-control-label" for="txt_bld_block">Block</label>
            <input automation_id="txt_bld_block" class="form-control" type="text" maxlength="6" @change="onFieldChange" v-model="property.block" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.subDivCode }" v-show="!selectedPropertyLayout.hideSubDivCode">
            <label class="form-control-label" for="txt_bld_subdivision">Subdivision</label>
            <div class="input-group">
                <dx-select-box ref="subdivisionSelectBox"
                    :input-attr="{ automation_id: 'drp_bld_subdivision' }"
                    :data-source="subdivisionDataSource"
                    value-expr="id"
                    display-expr="name"
                    v-model:display-value="property.subdivisionName"
                    :disabled="readOnly"
                    v-model="property.subdivisionID"
                    @value-changed="onFieldChange"
                />
                <button id="btnSubdivision" class="btn btn-theme" title="Search Subdivisions." v-on:click="onSubdivisionsClick" :disabled="!canChangeSubdivision">
                    <FontAwesomeIcon icon="fa fa-search" aria-hidden="true" />
                </button>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.platname }" v-show="!selectedPropertyLayout.hidePlatname">
            <label class="form-control-label" for="txt_bld_platname">Platname</label>
            <input automation_id="txt_bld_platname" class="form-control" type="text" maxlength="50" @change="onFieldChange" v-model="property.platname" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.phase }" v-show="!selectedPropertyLayout.hidePhase">
            <label class="form-control-label" for="txt_bld_phase">Phase</label>
            <input automation_id="txt_bld_phase" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.phase" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.section }" v-show="!selectedPropertyLayout.hideSection">
            <label class="form-control-label" for="txt_bld_section">Section</label>
            <input automation_id="txt_bld_section" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.section" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.township }" v-show="!selectedPropertyLayout.hideTownship">
            <label class="form-control-label" for="txt_bld_township">Township</label>
            <input automation_id="txt_bld_township" class="form-control" type="text" maxlength="5" @change="onFieldChange" v-model="property.township" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.arbNum }" v-show="!selectedPropertyLayout.hideARBNum">
            <label class="form-control-label" for="txt_bld_arbnum">ARBNum</label>
            <input automation_id="txt_bld_arbnum" class="form-control" type="text" maxlength="20" @change="onFieldChange" v-model="property.arbNumber" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{'order': selectedPropertyLayout.propertyLayoutSequence.acreage }" v-show="!selectedPropertyLayout.hideAcreage">
            <label class="form-control-label" for="txt_bld_acreage">Acreage</label>
            <rqInputNumber automation_id="txt_bld_acreage" defaultValue="0" decimals="3" @change="onFieldChange" v-model="property.acreage" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.tract }" v-show="!selectedPropertyLayout.hideTract">
            <label class="form-control-label" for="txt_bld_tract">Tract</label>
            <input automation_id="txt_bld_tract" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.tract" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.number }" v-show="!selectedPropertyLayout.hideNumber">
            <label class="form-control-label" for="drp_bld_survey">Survey/Abstract</label>
            <div class="input-group">
                <dx-select-box ref="surveySelectBox"
                    :input-attr="{ automation_id: 'drp_bld_survey' }"
                    :data-source="surveyDataSource"
                    value-expr="id"
                    display-expr="name"
                    v-model:display-value="property.surveyName"
                    :disabled="readOnly"
                    v-model="property.surveyID"
                    @value-changed="onFieldChange"
                />
                <button id="btnSurvey"
                        class="btn btn-default"
                        title="Click to add/edit surveys."
                        v-on:click="onSurveyClick"
                        :disabled="!canChangeSurveyAccess"
                        >
                    <FontAwesomeIcon icon="fa fa-search" aria-hidden="true" />
                </button>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.parcel }" v-show="!selectedPropertyLayout.hideParcel">
            <label class="form-control-label" for="txt_bld_parcel">Parcel</label>
            <input automation_id="txt_bld_parcel" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.parcel" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.range }" v-show="!selectedPropertyLayout.hideRange">
            <label class="form-control-label" for="txt_bld_range">Range</label>
            <input automation_id="txt_bld_range" class="form-control" type="text" maxlength="5" @change="onFieldChange" v-model="property.range" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.part }" v-show="!selectedPropertyLayout.hidePart">
            <label class="form-control-label" for="txt_bld_part">Part</label>
            <input automation_id="txt_bld_part" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.part" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.unit }" v-show="!selectedPropertyLayout.hideUnit">
            <label class="form-control-label" for="txt_bld_unit">Unit</label>
            <input automation_id="txt_bld_unit" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.unit" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.building }" v-show="!selectedPropertyLayout.hideBuilding">
            <label class="form-control-label" for="txt_bld_building">Building</label>
            <input automation_id="txt_bld_building" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.building" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.outLot }" v-show="!selectedPropertyLayout.hideOutLot">
            <label class="form-control-label" for="txt_bld_outlot">OutLot</label>
            <input automation_id="txt_bld_outlot" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.outLot" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.book }" v-show="!selectedPropertyLayout.hideBook">
            <label class="form-control-label" for="txt_bld_book">Book</label>
            <input automation_id="txt_bld_book" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.book" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.page }" v-show="!selectedPropertyLayout.hidePage">
            <label class="form-control-label" for="txt_bld_page">Page</label>
            <input automation_id="txt_bld_page" class="form-control" type="text" maxlength="10" @change="onFieldChange" v-model="property.page" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.municipality }" v-show="!selectedPropertyLayout.hideMunicipality">
            <label class="form-control-label" for="txt_bld_municipality">Municipality</label>
            <input automation_id="txt_bld_municipality" class="form-control" type="text" maxlength="50" @change="onFieldChange" v-model="property.municipality" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.qtr1 }" v-show="!selectedPropertyLayout.hideQtr1">
            <label class="form-control-label" for="txt_bld_qtr1">Qtr1</label>
            <input automation_id="txt_bld_qtr1" class="form-control" type="text" maxlength="2" @change="onFieldChange" v-model="property.qtr1" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.qtr2 }" v-show="!selectedPropertyLayout.hideQtr2">
            <label class="form-control-label" for="txt_bld_qtr2">Qtr2</label>
            <input automation_id="txt_bld_qtr2" class="form-control" type="text" maxlength="2" @change="onFieldChange" v-model="property.qtr2" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.qtr3 }" v-show="!selectedPropertyLayout.hideQtr3">
            <label class="form-control-label" for="txt_bld_qtr3">Qtr3</label>
            <input automation_id="txt_bld_qtr3" class="form-control" type="text" maxlength="2" @change="onFieldChange" v-model="property.qtr3" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.instrNum }" v-show="!selectedPropertyLayout.hideInstrNum">
            <label class="form-control-label" for="txt_bld_instrnum">InstrNum</label>
            <input automation_id="txt_bld_instrnum" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.instrumentNumber" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.district }" v-show="!selectedPropertyLayout.hideDistrict">
            <label class="form-control-label" for="txt_bld_district">District</label>
            <input automation_id="txt_bld_district" class="form-control" type="text" maxlength="15" @change="onFieldChange" v-model="property.district" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.recordOfSurvey }" v-show="!selectedPropertyLayout.hideRecordOfSurvey">
            <label class="form-control-label" for="txt_bld_recordofsurvey">Record of Survey</label>
            <input automation_id="txt_bld_recordofsurvey" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.recordOfSurvey" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3 pt-3 ps-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.incorporated }" v-show="!selectedPropertyLayout.hideIncorporated">
            <b-form-checkbox automation_id="chk_bld_incorporated" @change="onFieldChange" v-model="property.incorporated" :disabled="readOnly">Incorporated</b-form-checkbox>
        </div>
    <!-- RQO-18468  added missing fields (Section Group not needed) -->
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.boatSlip }" v-show="!selectedPropertyLayout.hideBoatSlip">
            <label class="form-control-label" for="txt_bld_boatslip">Boat Slip</label>
            <input automation_id="txt_bld_boatslip" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.boatSlip" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.carport }" v-show="!selectedPropertyLayout.hideCarport">
            <label class="form-control-label" for="txt_bld_carport">Carport</label>
            <input automation_id="txt_bld_carport" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.carport" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.garage }" v-show="!selectedPropertyLayout.hideGarage">
            <label class="form-control-label" for="txt_bld_garage">Garage</label>
            <input automation_id="txt_bld_garage" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.garage" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.maps }" v-show="!selectedPropertyLayout.hideMaps">
            <label class="form-control-label" for="txt_bld_maps">Maps</label>
            <input automation_id="txt_bld_maps" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.maps" :disabled="readOnly" />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.storage }" v-show="!selectedPropertyLayout.hideStorage">
            <label class="form-control-label" for="txt_bld_storage">Storage</label>
            <input automation_id="txt_bld_storage" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.storage" :disabled="readOnly" />
        </div>
        <!-- <div class="form-group col-sm-12 col-md-4 col-lg-3" :style="{ 'order': selectedPropertyLayout.propertyLayoutSequence.sectionGroup }" v-show="!selectedPropertyLayout.hideSectionGroup">
            <label class="form-control-label" for="txt_bld_sectiongroup">Section Group</label>
            <input automation_id="txt_bld_sectiongroup" class="form-control" type="text" maxlength="25" @change="onFieldChange" v-model="property.sectiongroup" :disabled="readOnly" />
        </div> -->
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { REFRESH_LOOKUP } from "@/store/actions";
    import { PropertyLayoutModel } from "../models";
    import SubdivisionLookup from "./subdivisions/SubdivisionLookup";
    import SurveyLookup from "./surveys/SurveyLookup";
    import ArrayStore from 'devextreme/data/array_store';

    export default {

        props: {
            property: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },

        data () {
            return {
                selectedPropertyLayout: new PropertyLayoutModel(),
                propertyLayouts: []
            };
        },
        created () {
            const self = this;
            self.propertyLayouts = self.lookupHelpers.getPropertyLayouts();
            self.setSelectedPropertyLayout(self.property.propertyLayoutID);
        },

        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                order: state => state.orders.order,
                readOnly: state => state.isPageReadOnly,
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            isOrderLocked() { return _.get(this, "$store.state.orders.orderSummary.isLocked", false); },
            surveyDataSource() {
                return new ArrayStore({
                    data: this.lookupHelpers.getLookupItems(this.lookupItems.SURVEYS),
                    key: 'id'
                });
            },
            subdivisionDataSource() {
                let regionID = _.getNumber(this, "order.regionID", this.globalRegionId);
                return new ArrayStore({
                    data: this.lookupHelpers.getLookupItems(this.lookupItems.SUBDIVISIONS, regionID),
                    key: 'id'
                });
            },
            canChangeSubdivision(){ return this.securitySettings.hasAccess("Subdivision") && !this.isFileLocked; },
            canChangeSurveyAccess(){ return this.securitySettings.hasAccess("Survey")  && !this.isFileLocked; }

        },

        watch: {
            "property.propertyLayoutID" (val) {
                this.setSelectedPropertyLayout(val);
            }
        },

        methods: {

            setSelectedPropertyLayout (val) {
                let propertyLayout = this.propertyLayouts.find(l => _.parseNumber(l.propertyLayoutID) === _.parseNumber(val));
                this.selectedPropertyLayout = propertyLayout || new PropertyLayoutModel();
            },

            onFieldChange (e) {
                this.$emit('change');
            },

            onSubdivisionsClick () {
                const self = this;

                let onOk = function(e) {
                    let dialogResult = e.component.dialogResult();
                    self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.SUBDIVISIONS });//do a full refresh since they may have added some to other regions
                    if(self.readOnly) return;
                    self.property.subdivisionID = _.isNil(dialogResult.data) ? null : dialogResult.data.subdivisionID;
                    _.invoke(self, "$refs.subdivisionSelectBox.instance.focus");
                    return;
                };
                let componentAction = function(e) {
                    self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.SUBDIVISIONS });
                    _.invoke(self, "$refs.subdivisionSelectBox.instance.focus");
                    return;
                };

                self.$dialog.open({
                    title: "Add/Edit Subdivisions",
                    height: 650,
                    width: 850,
                    draggable: false,
                    scrollable: false,
                    resizable: false,
                    component: SubdivisionLookup,
                    customFooter: true,
                    props: { selectedId: self.property.subdivisionID },
                    onOk: onOk,
                    onComponentAction: componentAction
                });
            },

            onSurveyClick () {
                const self = this;

                let okHandler = function(e) {
                    let dialogResult = e.component.dialogResult();
                    self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.SURVEYS });
                    if(self.readOnly) return;
                    self.property.surveyID = _.isNil(dialogResult.data) ? null : dialogResult.data.surveyID;
                    _.invoke(self, "$refs.surveySelectBox.instance.focus");
                    return;
                };
                let componentAction = function(e) {
                    self.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: self.lookupItems.SURVEYS });
                    _.invoke(self, "$refs.surveySelectBox.instance.focus");
                    return;
                };

                self.$dialog.open({
                    title: "Add/Edit Surveys",
                    height: 650,
                    width: 850,
                    draggable: false,
                    scrollable: false,
                    resizable: false,
                    component: SurveyLookup,
                    customFooter: true,
                    props: { selectedId: self.property.surveyID, propertyState: self.property.state },
                    onOk: okHandler,
                    onComponentAction: componentAction
                });
            },
        }

    };
</script>