import SettlementPage from "./Settlement";
import Routes from "./routes";

let SettlementModule = {
    meta: { label: "Settlement Statement", include: true },
    path: "settlement",
    redirect: "settlement/payoffs",
    component: SettlementPage,
    children: Routes
};

export default SettlementModule;
