<template>
    <rq-page-section title="Monthly Principal, Interest & Mortgage Insurance" collapsible>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <rq-checkbox v-if="orderSummary.isReverseMortgage"
                        id="chk_monthly_amount"
                        automation_id="chk_monthly_amount"
                        v-model="loanTerm.hudMonthlyAmountNA"
                    >
                        N/A
                    </rq-checkbox>                    
                    <rq-action-form-group
                        label="Monthly Principal, Interest & Mortgage Insurance"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="loanTerm.hudPIAmountOverride"
                        @action-click="onRevertInitialMonthlyAmountOverride">
                        <rqInputNumber
                            id="txt_principal_initial_monthly_amount"
                            automation_id="txt_principal_initial_monthly_amount"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="loanTerm.hudInitialMonthlyAmount"
                        />
                    </rq-action-form-group>                    
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label>Includes</label>
                    <div class="row">
                        <div class="col col-auto me-auto">
                        <b-form-checkbox
                            automation_id="cb_includes_principal"
                            v-model="loanTerm.hudPrincipalIncluded"
                            inline
                            @change="onIncludeChange($event, includesType.Principal)">Principal
                        </b-form-checkbox>
                        </div>
                        <div class="col col-auto">
                            <span class="fw-semibold">{{formatMoney(this.monthlyPrincipal)}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-auto me-auto">
                            <b-form-checkbox
                                automation_id="cb_includes_interest"
                                v-model="loanTerm.hudInterestIncluded"
                                @change="onIncludeChange($event, includesType.Interest)">Interest
                            </b-form-checkbox>
                        </div>
                        <div class="col col-auto">
                            <span class="fw-semibold">{{formatMoney(this.monthlyInterest)}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-auto me-auto">
                            <b-form-checkbox
                                automation_id="cb_includes_mortgage_insurance"
                                v-model="loanTerm.hudMortgageInsurance"
                                @change="onIncludeChange($event, includesType.MortgageInsurance)">Mortgage Insurance
                            </b-form-checkbox>
                        </div>
                        <div class="col col-auto">
                            <span class="fw-semibold">{{formatMoney(this.mortgageInsurance)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <label for="txt_increase_amount">Can this amount rise?</label>
                    <rq-radio-group 
                        v-model="loanTerm.hudMonthlyAmountCanRise"
                        inline
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
        </fieldset>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label class="form-control-label" for="dtp_first_monthly_change_date">Change Date</label>
                    <div class="input-group">
                        <rqdx-date-box
                            id="dtp_first_monthly_change_date"
                            :config="{
                                showClearButton: true,
                                displayFormat: 'MM/dd/yyyy',
                                useMaskBehavior: true,
                            }"
                            :disabled="!loanTerm.hudMonthlyAmountCanRise"
                            v-model="loanTerm.hudFirstMonthlyAmountIncreaseDate "
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_new_monthly_amount">New Monthly Amount</label>
                    <rqInputNumber
                        id="txt_new_monthly_amount"
                        automation_id="txt_new_monthly_amount"
                        defaultValue="0"
                        decimals="2"
                        input-group
                        :disabled="!loanTerm.hudMonthlyAmountCanRise"
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="loanTerm.hudMonthlyAmountCanRiseTo"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <rq-action-form-group
                            label="Maximum PIMI Payment"
                            labelFor="txt_max_pimi_payment"
                            :show-action="orderSummary.isReverseMortgage"
                        >            
                        <template #action>
                            <rq-checkbox v-if="loanTerm.hudMonthlyAmountCanRise"
                                id="chk_pimi_payment"
                                automation_id="chk_pimi_payment"
                                v-model="loanTerm.hudLoanBalanceRiseNA"
                            >
                                Unknown
                            </rq-checkbox>
                        </template>
                        <template #default>                                                          
                            <rqInputNumber
                                id="txt_max_pimi_payment"
                                automation_id="txt_max_pimi_payment"
                                defaultValue="0"
                                decimals="2"
                                input-group
                                :disabled="!loanTerm.hudMonthlyAmountCanRise"
                                v-focus-select-all
                                prependIcon="fas fa-dollar-sign"
                                v-model="loanTerm.hudMonthlyMaxAmountCanRiseTo"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
        </fieldset>
    </rq-page-section>
</template>
<script>
import { mapState } from "vuex";
import { LoanTermSectionHud2010DataModel } from '@settlement/models';
const IncludesType = {
    Principal: 1,
    Interest: 2,
    MortgageInsurance: 3
}
export default {
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        loanTerm: {
            type: Object,
            default: () => {
                return {}
            }
        },
        originalLoanTerm: {
            type: Object,
            default: () => {
                return new LoanTermSectionHud2010DataModel()
            }
        },
        monthlyPrincipal: {
            type: Number,
            default: 0
        },
        monthlyInterest: {
            type: Number,
            default: 0
        },
        mortgageInsurance: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            selectedIncludes: []
        };
    },
    computed: {
        ...mapState({
                orderSummary: state => state.orders.orderSummary,
            }),                    
        totalPIAmount() {
            let principalAmount = this.loanTerm.hudPrincipalIncluded ? this.monthlyPrincipal : 0;
            let interestAmount = this.loanTerm.hudInterestIncluded ? this.monthlyInterest : 0;
            let mortgageInsuranceAmount = this.loanTerm.hudMortgageInsurance ? this.mortgageInsurance : 0;
            return principalAmount + interestAmount + mortgageInsuranceAmount;
        }
    },
    watch: {
        "loanTerm.hudInitialMonthlyAmount": {
            immediate: false,
            handler: function(newVal, oldVal) {
                if(oldVal === undefined || newVal === oldVal) return;
                let self = this;
                self.$nextTick(()=>{
                    self.loanTerm.hudPIAmountOverride  = self.loanTerm.hudInitialMonthlyAmount != this.totalPIAmount;
                });
            }
        }
    },
    created() {
        this.initializeNonReactiveVariables();
    },

    methods: {
        initializeNonReactiveVariables() {
            this.includesType = IncludesType;
            this.includesData = [
                { text: 'Principal', value: this.monthlyPrincipal },
                { text: 'Interest', value: this.monthlyInterest },
                { text: 'Mortgage Insurance', value: this.mortgageInsurance }
            ]
        },
        onRevertInitialMonthlyAmountOverride() {
            this.loanTerm.hudInitialMonthlyAmount = this.totalPIAmount;
        },
        onIncludeChange(value, type) {
            let amount = 0;
            switch(type)
            {
                case IncludesType.Principal:
                    amount = this.monthlyPrincipal;
                    break;
                case IncludesType.Interest:
                    amount = this.monthlyInterest;
                    break;
                case IncludesType.MortgageInsurance:
                    amount = this.mortgageInsurance;
                    break;
            }
            this.loanTerm.hudInitialMonthlyAmount = value ? this.loanTerm.hudInitialMonthlyAmount + amount : this.loanTerm.hudInitialMonthlyAmount - amount;
        },
        formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },
    }
}
</script>
<style lang="scss">
.form-group .custom-checkbox-label label { width: 60%; }
</style>