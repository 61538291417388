<template>
    <div class="property-view content-wrapper" :class="{ 'property-view-tabbed': tabsEnabled }">
        <!--validation summary-->
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="v$.$error || !customDataIsValid"
            dismissable
        />

        <rq-page-section :title="propertyTitle" headerSize="lg" borderless>
            <template v-if="!isNewProperty" #header-secondary>
                <router-link
                    automation_id="btn_view_order_properties"
                    v-show="multipleProperties"
                    :to="{ name:'oe:op:properties', params: { orderId } }"
                    class="btn btn-link btn-theme">View File Properties
                </router-link>
            </template>
            <template v-if="!isNewProperty" #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <add-property-dropdown
                            ref="btn_add_property"
                            automation_id="btn_add_property"
                            :disabled="readOnly"
                            @add-property="onAddProperty"
                            @copy-current="onCopyCurrentProperty"
                            show-copy-current
                        />
                    </li>
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_delete_property"
                            :disabled="readOnly"
                            v-if="canDelete"
                            variant="theme"
                            @click="onDelete">Delete Property
                        </b-button>
                    </li>
                    <li class="nav-item form-group ps-3">
                        <b-form-checkbox
                            automation_id="chk_properties_on_all_statements"
                            v-model="allPropsOnAllSettlementStatements"
                            :disabled="readOnly">All Properties on all Settlement Statements
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item form-group ps-3">
                        <b-form-checkbox id="chk_property_foreign_address" 
                            automation_id="chk_property_foreign_address" 
                            v-model="currentProperty.isForeignAddress"> Foreign Address
                        </b-form-checkbox>
                    </li>
                </ul>
                <ul class="nav ms-auto" v-if="multipleProperties">
                    <li class="nav-item">
                        <dx-select-box
                            class="form-control form-control-sm"
                            :input-attr="{ automation_id: 'drp_properties' }"
                            :data-source="propertyDropdownDataSource"
                            value-expr="id"
                            display-expr="name"
                            v-model="selectedPropertyId"
                            @value-changed="onPropertyDropdownChange" />
                    </li>
                </ul>
            </template>
            <template v-else #header-actions>
                <ul class="nav">
                    <li class="nav-item form-group ps-3">
                        <b-form-checkbox id="chk_property_foreign_address" 
                            automation_id="chk_property_foreign_address" 
                            v-model="currentProperty.isForeignAddress"> Foreign Address
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <RqTabs
            :tabs="tabItems"
            :navClass="!tabsEnabled ? 'd-none' : ''" 
            :scrollable="tabsEnabled"
            >
            <template #details>
                <rq-page-section title="Details" collapsible>
                    <template #header-actions>
                        <ul class="nav ms-auto">
                            <li class="nav-item">
                                <rq-section-expand-collapse-all />
                            </li>
                        </ul>
                    </template>
                    <fieldset :disabled="readOnly">
                        <div v-show="!allPropsOnAllSettlementStatements" class="row">
                            <div class="col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="drp_loan_selection_for_property">Loan</label>
                                <rqdxDropDownBox
                                    automation_id="ddb_loan"
                                    ref="loanDropDownBox"
                                    :value="currentProperty.loanID"
                                    :disabled="allPropsOnAllSettlementStatements"
                                    :dataSource="loans"
                                    :config="loanDropDownBoxConfig" />
                            </div>
                        </div>

                        <property-information
                            ref="propertyInformation"
                            v-model="currentProperty"
                            :userInitiatedSave="userInitiatedSave"
                            @state-change="onStateChange"
                        >
                            <template #default>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-8 form-group">
                                    <label class="form-control-label" for="txt_tax_id">Tax ID</label>
                                    <tax-id-tagbox
                                        ref="taxIdTagbox"
                                        valueAttr="propertyTaxIdentificationID"
                                        displayAttr="taxIdentification"
                                        add-tooltip="Add Tax ID"
                                        delete-tooltip="Delete Tax ID"
                                        v-model="taxIdentification"
                                        @add-item="onAddPropertyTaxIdentification"
                                        :disabled="readOnly"
                                    />
                                </div>
                            </template>
                        </property-information>
                    </fieldset>
                </rq-page-section>
                <rq-page-section title="Taxes" collapsible>
                    <tax-information ref="taxInformationComponent" :taxInformation="taxInformation" @change="onTaxInformationChange"/>
                </rq-page-section>
                <rq-page-section title="Brief Legal" class="brief-legal-section" collapsible hide-collapsed-actions>
                    <template #header-actions>
                        <ul class="nav">
                            <li class="nav-item">
                                <b-button
                                    v-show="!legalDescEmpty"
                                    automation_id="btn_refresh_brief_legal"
                                    variant="theme"
                                    :disabled="currentProperty.noAutoLegal"
                                    @click="onPreviewBriefLegalDescription">Preview
                                </b-button>
                            </li>
                        </ul>
                    </template>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-3 form-group">
                            <label for="drp_property_layout">Property Layout:</label>
                            <dx-select-box
                                automation_id="drp_property_layout"
                                value-expr="propertyLayoutID"
                                display-expr="description"
                                :data-source="propertyLayoutDataSource"
                                :disabled="readOnly"
                                v-model="currentProperty.propertyLayoutID"
                                v-model:display-value="currentProperty.propertyLayoutName"
                                @keyDown="onPropertyLayoutKeyDown"
                                @value-changed="onPropertyLayoutChange"
                            />
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-9">
                            <rq-action-form-group
                                label="Brief Legal Description"
                                show-action
                            >
                                <template #action>
                                    <b-form-checkbox
                                        id="cb_override_brief_legal"
                                        automation_id="cb_override_brief_legal"
                                        v-model="currentProperty.noAutoLegal"
                                        :disabled="isOrderLocked || readOnly">Override
                                    </b-form-checkbox>
                                </template>
                                <template #default>
                                    <div class="input-group">
                                        <input
                                            automation_id="txt_brief_legal_description"
                                            type="text"
                                            class="form-control"
                                            :disabled="readOnly || !currentProperty.noAutoLegal"
                                            @input="onBriefLegalInput"
                                            v-model="currentProperty.legalDescription"/>
                                    </div>
                                </template>
                            </rq-action-form-group>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <brief-legal
                                :property="currentProperty"
                            />
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section title="Custom Data" collapsible borderless>
                    <custom-data-container
                        ref="customDataContainer"
                        :reference-table="referenceTable"
                        :reference-table-pk-value="selectedPropertyId"
                        :custom-data-tab-id="customDataTabID"
                        v-model:value="customData"
                        :read-only="customDataReadOnly"
                        @custom-data-loaded="onCustomDataLoaded"
                    ></custom-data-container>
                </rq-page-section>
            </template>
            <template #taxInquiry>
                <tax-inquiry
                    :tax-inquiry="taxInquiry"
                    :current-property="currentProperty"
                    :is-order-locked="isOrderLocked">
                </tax-inquiry>
            </template>
        </RqTabs>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { PropertyViewModel, PropertyEntityModel, PropertyLayoutModel, PropertyLayoutSequence, PropertyTaxIdentification, PropertyTaxInformation, AutoLegalDescriptionModel } from "../models";
    import { ORDER_MUTATIONS } from "@/store/mutations";
    import {ORDER_ACTIONS, PROPERTY_ACTIONS, GET_ORDER} from "@/store/actions";
    import RegEx from "@/shared/utilities/RegEx";
    import { TaxIdTagbox, TaxInformation, BriefLegal, TaxInquiry, AddPropertyDropdown, PropertyInformation } from "../components";
    import ArrayStore from 'devextreme/data/array_store';
    import { useVuelidate } from "@vuelidate/core";
    import { maxValue } from "@vuelidate/validators";
    import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
    import {  CustomDataContainer} from "@/shared/components";
    import { UserScreenAccessLevel } from "@/shared/models/enums";

    export default {
        name: "Property",

        components: {
            AddPropertyDropdown,
            TaxInformation,
            BriefLegal,
            TaxInquiry,
            TaxIdTagbox,
            PropertyInformation,
            CustomDataContainer
        },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            const self = this;
            return {
                propertyId: 0,
                actionEvent: "",
                allPropsOnAllSettlementStatements: true,
                propertyModels: [],
                taxIdentification: [],
                taxInformation: [],
                taxInquiry: {},
                propertyIndex: -1,
                selectedCopySrcId: 0,
                currentProperty: new PropertyEntityModel(),
                loanDropDownBoxConfig: {},
                skipSave: false,
                taxIdErrorCount: 0,
                taxesErrorCount: 0,
                selectedPropertyId: 0,
                userInitiatedSave: false,
                customDataTabID: 0,
                customDataIsValid: true,
                customData: [],
                originalCustomData: []
            };
        },

        validations() {
            return {
                currentProperty: {
                    state: {
                        stateValid(value, vm) {
                            return !_.isEmpty(value) || this.currentProperty.isForeignAddress || (
                            this.userInitiatedSave === false
                            && this.properties.length === 1
                            && _.isEmpty(this.currentProperty.address1)
                            && _.isEmpty(this.currentProperty.address2)
                            && _.isEmpty(this.currentProperty.city)
                            && _.isEmpty(this.currentProperty.zip)
                            && _.isEmpty(this.currentProperty.countyID)
                            && !this.isNewProperty);
                        }
                    },
                    zip: {
                        zipFormatValid: value => _.isEmpty(value) || value.length === 5 || value.length === 10 || this.currentProperty.isForeignAddress
                    }
                },
                taxesErrorCount: {
                    maxValue: maxValue(0)
                }
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                loans: state => state.orders.loans,
                properties: state => state.properties.properties,
                user: state => state.authentication.session.user,
                readOnly: state => state.isPageReadOnly
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
            ]),
            localSecurity() {
                 return this.securitySettings.findValues(["AdditionalFields_ScreenAccess"]);
            },
            customDataReadOnly() { return this.readOnly || this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.Full; },
            isOrderLocked() { return _.parseBool(this.orderSummary.isLocked); },
            canDelete() { return this.userAccess.full },
            propertyLayoutDataSource() {
                return new ArrayStore({
                    data: this.lookupHelpers.getOrderPropertyLayouts(),
                    key: 'id'
                });
            },
            propertyDropdownDataSource() {
                return new ArrayStore({
                    data: this.propertyDropdownData,
                    key: 'id'
                });
            },
            storedAllPropertiesOnAllSettlementStatements() { return _.parseBool(this.orderSummary.allPropertiesOnAllSettlementStatements); },
            allPropsOnAllSettlementStatementsChanged() { return this.allPropsOnAllSettlementStatements !== this.storedAllPropertiesOnAllSettlementStatements; },
            address() {
                if(this.currentProperty.address1 || this.currentProperty.city || this.currentProperty.state || this.currentProperty.zip)
                    return `${this.currentProperty.address1} ${this.currentProperty.city} ${this.currentProperty.state} ${this.currentProperty.zip}`;
                else {
                    return '';
                }
            },
            readOnlyTaxId(){
                return  this.isNewProperty ? true : _.get(this, "taxIdentification.length", 0) > 1;
            },
            isNewProperty() { return this.propertyId === 0; },
            isBlankProperty() { return this.propertyId === 0 && _.isNullOrEmpty(this.currentProperty.state);},
            multipleProperties() { return this.properties.length > 1; },
            propertyTitle() { return this.isNewProperty ? "Add New Property" : "Property Info"; },
            orderMismatch() { return this.orderId !== this.currentProperty.ordersID},
            propertyDropdownData() {
                return _.map(this.properties, (item, index) => {
                    let address = _.getWithNullCheck(item, "property.address1", "");
                    let city = _.getWithNullCheck(item, "property.city", "");
                    let state = _.getWithNullCheck(item, "property.state", "");
                    let truncateObj = {
                        length: 25
                    };
                    let addressDescription = `${address.length > 0 ? `${_.truncate(address, truncateObj)}, `: ''}${city.length > 0 ? `${_.truncate(city, truncateObj)}, `: ''}${state}`;
                    return {
                        id: item.property.propertyID,
                        name: `Property ${index + 1} of ${this.properties.length}: ${addressDescription}`
                    }
                });
            },
            showPropertyDropdown() {
                return this.properties.length > 1;
            },
            tabsEnabled() {
                return this.currentProperty.state==='OH' && !this.isNewProperty;
            },
            legalDescEmpty() { return _.isEmpty(_.trim(this.currentProperty.legalDescription)); },
            referenceTable() {
                return CustomDataReferenceTable.Property;
            },
            tabItems() {
                const self = this;
                return [
                    { name: "details", title: "Details"},
                    { name: "taxInquiry", title: "Tax Inquiry", visible: self.tabsEnabled },
                ];
            },
            defaultEstimatedValue(){
                let primaryLoanAmount = this.loans != null && this.loans.length > 0 ? this.loans[0]?.amount : 0;
                if(this.order.salesPrice > 0){
                    return this.order.salesPrice;
                }
                else if(primaryLoanAmount > 0){
                    return primaryLoanAmount;
                }
                else{
                    return null;
                }
            },
        },

        watch: {
             $route(newValue, oldValue) {
                let routeId = _.parseNumber(newValue.params.propertyId, 0);
                if(newValue === oldValue || (routeId === this.propertyId)) return;

                this.skipSave = false;

                this.propertyId = this.selectedPropertyId = routeId;

                this.currentProperty = new PropertyEntityModel();

                if (this.isNewProperty) {
                    this.actionEvent = _.get(this, "$route.params.action", "");
                    this.loadNewProperty();
                    return;
                }

                this.setPropertyData();
            },

            allPropsOnAllSettlementStatements(newValue, oldValue){
                const self = this;
                if(newValue === oldValue || !self.allPropsOnAllSettlementStatementsChanged) return;

                var message = "This will change the assignment of the propertyModels to the HUD and could change prorations.  You will need to recalculate your HUD(s).  Would you like to continue?";
                let okHandler = () => true;
                let cancelHandler = () => { self.allPropsOnAllSettlementStatements = !self.allPropsOnAllSettlementStatements; };
                self.$dialog.confirm("Warning", message, okHandler, cancelHandler);
            },
        },

        created() {
            const self = this;
                GlobalEventManager.onSave(self, self.onSave);
                GlobalEventManager.onFireAction(self, self.onGlobalAction);
                GlobalEventManager.onCancel(self, self.onCancel);

                self.$nextTick(() => {
                    //registering in next tick in case unregistered in previous route's beforeUnmount (passing function as argument in "off" doesn't work)
                    self.$events.on("apply-template-success", self.onApplyTemplateSuccess);
                });

            self.propertyId = _.parseNumber(self.$route.params.propertyId, 0);
            self.selectedPropertyId = self.propertyId;
            self.actionEvent = _.get(this, "$route.params.action", "");

            self.loadLoanSettings();
            self.fetchPropertyData();
        },

        mounted() {
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
            this.$events.off("apply-template-success");
        },

        beforeRouteUpdate(to, from, next) {
            const self = this;
            if (!self.readOnly && !_.getBool(to, 'params.skipSave', false)) {
                self.save(false, true)
                    .then(allow => {
                        if(allow) {
                            next();
                        }
                        else {
                            next(false);
                        }
                    });
            } else {
                next();
            }
        },

        methods: {
            onSave(e) {
                let userInitiated = _.getBool(e, "userInitiated");
                let navigatingTo = _.get(e, "navigatingTo", '') || '';
                this.save(userInitiated,null,navigatingTo);
            },

            onAddProperty(e) {
                let args = e || {};
                args.loanID = this.loans[0].loanID;
                args.sequence = this.properties.length + 1;
                this.$emit("add-property", args);
            },

            onCopyCurrentProperty(e){
                let args = e || {};
                args.loanID = this.loans[0].loanID;
                args.sequence = this.properties.length + 1;

                let current = {
                    property: this.currentProperty.toDataObject(),
                };

                args.copyData = current;
                args.action = 'copyProperty';
                this.$emit("add-property", args);
            },

            onApplyTemplateSuccess(e) {
                const self = this;
                self.fetchPropertyData().then(() => {
                    // Let the default routing events manage navigation.
                    self.skipSave = true;
                    self.$router.push({ name:"oe:op:properties", params: { orderId: self.order.ordersID } });
                });

            },

            onGlobalAction(e) {
                const self = this;
                if(e.disableWhenReadOnly && this.readOnly) return;

                let onOk = () => {
                    return true;
                };
                let onCancel = () => {
                    self.$dialog.closeAll();
                };

                let changes = self.getChanges();
                if(self.allPropsOnAllSettlementStatementsChanged){
                    changes.push({
                        name: "AllPropertiesOnAllSettlementStatements",
                        old: (!self.allPropsOnAllSettlementStatements).toString(),
                        new: self.allPropsOnAllSettlementStatements.toString()
                    });
                }
                let hasChanges = changes.length > 0 || self.hasTaxIdentificationChanges();

                if (e.key == "applyTemplate" && self.isNewProperty && !self.isBlankProperty) {
                    self.$dialog.confirm("Confirm Changes", "Using a template will remove the new property that has not been saved. Would you like to continue?", onOk, onCancel, { cancelTitle: "No", okTitle: "Yes"});
                }
                else if (e.key == "applyTemplate" && hasChanges && !self.isBlankProperty) {
                    self.$dialog.confirm("Confirm Changes", "Using a template will not commit outstanding edits that have not been saved. Would you like to continue?", onOk, onCancel, { cancelTitle: "No", okTitle: "Yes"});
                }
            },

            onDelete(e) {
                const self = this;
                if (self.properties.length === 0 || self.propertyId <= 0) return;
                let okHandler = () => {
                    self.skipSave = true;
                    let properties= [];
                    properties.push(self.propertyId);
                    self.$emit("delete-property", properties);
                };
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete this property?", okHandler);
            },

            onAddPropertyTaxIdentification(e) {
                if(!e.value) return;
                let nextpropertyTaxIdentificationID = 0;
                let lastPropertyTaxIdentification = _.minBy(this.taxIdentification, 'propertyTaxIdentificationID');
                if (lastPropertyTaxIdentification) {
                    nextpropertyTaxIdentificationID = lastPropertyTaxIdentification.propertyTaxIdentificationID > 0 ? nextpropertyTaxIdentificationID : _.parseNumber(lastPropertyTaxIdentification.propertyTaxIdentificationID, 1) - 1
                }
                this.taxIdentification.push(new PropertyTaxIdentification({
                    propertyTaxIdentificationID: nextpropertyTaxIdentificationID,
                    propertyID: this.propertyId,
                    taxIdentification: e.value
                }));
            },

            loadLoanSettings(){
                const self = this;
                self.allPropsOnAllSettlementStatements = self.storedAllPropertiesOnAllSettlementStatements;
                self.loanDropDownBoxConfig = {
                    valueExpr: "loanID",
                    displayExpr (item) {
                        let displayParts = [];
                        if(_.isNullOrEmpty(item.number)) {
                            displayParts.push(item.loanOrder);
                        } else {
                            displayParts.push(item.number);
                        }

                        if(_.isNullOrEmpty(item.lender)) {
                            displayParts.push(accounting.formatMoney(item.amount));
                        } else {
                            displayParts.push(item.lender);
                        }
                        return _.join(displayParts, " - ");
                    },
                    dropDownOptions: { width: 600 },
                    focusStateEnabled: false,
                    disabled: self.allPropsOnAllSettlementStatements,
                    onValueChanged (e) {
                        if (e.value === null) {
                            //self.currentProperty.loanID = self.loans[0].loanID;
                        }
                    },
                    contentTemplate (e) {
                        var value = e.component.option("value");
                        return $("<div>").dxDataGrid({
                            dataSource: e.component.option("dataSource"),
                            columns: [
                                { dataField:"loanOrder", width:100 },
                                {
                                    dataField:"amount",
                                    width:100,
                                    cellTemplate: function (container, options) {
                                        $("<span>").append(accounting.formatMoney(options.text))
                                            .appendTo(container);
                                    }
                                },
                                { dataField:"number", width:100 },
                                { dataField:"lender" }
                            ],
                            height: 265,
                            selection: { mode: "single" },
                            onSelectionChanged (selectedItems) {
                                if (selectedItems && selectedItems.selectedRowKeys.length > 0) {
                                    var keys = selectedItems.selectedRowKeys;
                                    if(keys.length === 0) {
                                        e.component.close();
                                        return;
                                    }
                                    self.currentProperty.loanID = keys[0].loanID;
                                    e.component.option("value", keys[0].loanID);
                                    e.component.close();
                                }
                            }
                        });
                    }
                };
            },

            fetchPropertyData() {
                const self = this;

                return new Promise((resolve, reject) => {
                    if(self.isNewProperty)
                        self.loadNewProperty();
                    else
                        self.setPropertyData();
                    resolve();
                })
            },

            onPropertyDropdownChange(e) {
                const self = this;
                if(_.isNil(e.event)) return;

                let previousPropertyId = e.previousValue;
                let newPropertyId = e.value;

                if(self.validate())
                    self.navigateToProperty(newPropertyId);
                else {
                    self.$nextTick(() => {
                        self.selectedPropertyId = previousPropertyId;
                    });
                }
            },

            loadNewProperty() {
                const self = this;
                self.propertyIndex = -1;
                self.skipSave = false;
                let branch = self.lookupHelpers.getBranch(self.orderSummary.branchID);
                self.currentProperty = new PropertyEntityModel({
                    ordersID: self.orderId,
                    loanID: self.loans[0].loanID,
                    sequence: self.properties.length + 1,
                    state: branch.defaultState,
                    countyID: branch.defaultCountyID,
                    countyName: branch.defaultCountyName,
                    estimatedValue: self.defaultEstimatedValue
                });
                // Detect if we need to copy property data to the new property
                if (self.actionEvent === "copyProperty") {
                    let copyProperty = JSON.parse(_.get(self, "$route.params.copyData", null));

                    if (!_.isNil(_.get(copyProperty, "property", null))) {
                        let property = new PropertyEntityModel(copyProperty.property);
                            self.currentProperty = property.propertyInfo; // Get property info apart from propertyId
                    }
                }
                self.taxIdentification = [];
                self.taxInformation = self.createNewTaxInformation();
                self.taxInquiry = {};
                self.resetTaxIdComponent();
                self.resetValidation();
            },

            save(userInitiated=false, forceProcessResult=false, navigatingTo = '') {
                const self = this;
                self.userInitiatedSave = userInitiated;
                let isNew = self.isNewProperty;

                if(self.skipSave) {
                    self.skipSave = false;
                    return self.saveComplete();
                }

                if (self.orderMismatch || self.readOnly) return self.saveComplete();

                let isValid = self.validate();
                self.customDataIsValid = _.invoke(self, "$refs.customDataContainer.validate");
                if(!isValid || !self.customDataIsValid) return self.saveComplete("invalid");


                let changes = self.getChanges();
                let customDataChanges = _.differenceWith(this.customData, this.originalCustomData, _.isEqual);

                if(!isNew && changes.length === 0 && !self.hasTaxIdentificationChanges() && customDataChanges.length === 0) {
                    if(userInitiated && navigatingTo != 'cdf-wysiwyg'){
                        return self.saveComplete("no-change", isNew, userInitiated);
                    }
                }

                return _.invoke(self, "$refs.propertyInformation.validateAddressOnSave")
                    .then((isValidAddress) => {
                        let data = self.getCurrentProperty().toDataObject();
                        let storePromise = isNew
                            ? self.$store.dispatch(PROPERTY_ACTIONS.ADD_PROPERTY, data)
                            : self.$store.dispatch(PROPERTY_ACTIONS.UPDATE_PROPERTY, { data, changes });
                        let savePromise = storePromise.then(result => self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { orderId: self.orderId, refresh: true })
                                                      .then(() => result)); //is a full order data refresh really necessary?
                        return self.$rqBusy.wait(savePromise);
                    })
                    .then((result) => {
                        if(!userInitiated || forceProcessResult) return true;
                        let resultId = _.getNumber(result, "property.propertyID", null) || 0;
                        self.propertyId = resultId;
                        self.selectedPropertyId = resultId;

                        if(isNew)
                        {
                            // when adding new buyerseller, use the newly created buyersellerId when saving
                            customDataChanges = _.map(customDataChanges, x => {
                                                    x.referenceTablePKValue = self.selectedPropertyId;
                                                    return x;
                                                });
                        }
                        if(customDataChanges.length > 0)
                            return self.$rqBusy.wait(self.$api.CustomDataApi.saveCustomData(customDataChanges))
                                                .then(() => true);

                        return true;
                    })
                    .then(() => {
                        if((isNew || changes.length > 0 || customDataChanges.length > 0) && navigatingTo != 'cdf-wysiwyg'){
                            self.saveComplete("success", isNew, true);
                        }
                        else{
                            self.saveComplete();
                        }
                        if(isNew)
                        {
                            self.skipSave = true;
                            self.setPropertyData();
                            self.navigateToProperty(self.selectedPropertyId);
                        }
                        else {
                            self.setCurrentProperty();
                            self.resetValidation();
                            _.invoke(self, "$refs.customDataContainer.initialize");
                        }

                        return true;
                    })
                    .catch(err => {
                        console.error("Property Save Error: ", err);
                        self.saveComplete("error", isNew, true);
                        return err;
                    });
            },

            saveComplete(status="success", isNew=false, notify=false) {
                const self = this;
                let success = status === "success" || status === "no-change";
                let abort = status === "invalid" || status === "error";
                if(notify) {
                    switch(status) {
                        case "success":
                            self.$toast.success(`Property ${ isNew ? "added" : "saved" } successfully.`);
                            break;
                        case "no-change":
                            self.$toast.info("No changes detected");
                            break;
                        case "error":
                            self.$toast.error(`An issue occurred during property ${isNew ? "creation" : "update" }.`);
                            break;
                    }
                }
                GlobalEventManager.saveCompleted({ success, abort });
                return Promise.resolve(success);
            },

            validate() {
                const self = this;
                self.v$.$touch();
                let propertyInformationValid = self.$refs.propertyInformation.validate();

                // Dont validate on tax information below because references to these are not created till property has been adding on server side
                if (!self.isNewProperty) {
                    self.taxesErrorCount = self.$refs.taxInformationComponent.validate().length;
                }
                return !self.v$.$error && propertyInformationValid;
            },

            getChanges() {
                const self = this;
                let currentData = self.getCurrentProperty();
                let property = self.getOriginalProperty();

                if (!_.isNil(property)) {
                    let original = new PropertyViewModel({
                        property: property.property,
                        taxInformation: property.taxInformation,
                        taxIdentification: property.taxIdentification,
                        taxInquiry: property.taxInquiry
                    });
                    let changes = self.getAuditChanges(original.toChangesDataObject(), currentData.toChangesDataObject());
                    if(!self.isNewProperty && self.allPropsOnAllSettlementStatementsChanged){
                        //Manually add change item for the AllPropertiesOnAllSettlementStatements because it's not technically on the property object.
                        changes.push({
                            name: "AllPropertiesOnAllSettlementStatements",
                            old: (!self.allPropsOnAllSettlementStatements).toString(),
                            new: self.allPropsOnAllSettlementStatements.toString()
                        });
                    }
                    return changes;
                }

                return {};
            },

            getOriginalProperty() {
                const self = this;
                let originalProperty = _.find(self.properties, item => _.parseNumber(item.property.propertyID, 0) === _.parseNumber(self.propertyId, 0))
                return new PropertyViewModel(originalProperty);
            },

            hasTaxIdentificationChanges() {
                let property = this.getOriginalProperty();
                if (_.isNil(property)) return false;
                return !_.isEqual(this.taxIdentification, property.taxIdentification);
            },

            onCancel() {
                this.cancel();
            },

            cancel() {
                const self = this;
                _.invoke(self, "$refs.customDataContainer.resetFieldValidations");
                self.skipSave = true;
                if(self.isNewProperty) {
                    // No properties... Allow them to cancel back to File Main.
                    if (self.properties.length == 0) {
                        self.$router.push({ name: 'oe:main' });
                    }
                    else {
                        self.$router.push({ name: "oe:op:properties", params: { orderId: self.orderId } });
                        _.delay(() => {
                            self.skipSave = false;
                        }, 2000);
                    }
                    return;
                }
                // Conundrum where the default property created when starting a new file.
                // This property doesn't abide by validation (state required).
                // As such, if this is the ONLY property, roll out to File Main.
                else if (self.properties.length == 1) {
                    self.$router.push({ name: 'oe:main' });
                    return;
                }
                let hasChanges = () => {
                    let changes = self.getChanges();
                    let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);

                    //Manually add change item for the AllPropertiesOnAllSettlementStatements because it"s not technically on the property object.
                    if(self.allPropsOnAllSettlementStatementsChanged){
                        changes.push({
                            name: "AllPropertiesOnAllSettlementStatements",
                            old: (!self.allPropsOnAllSettlementStatements).toString(),
                            new: self.allPropsOnAllSettlementStatements.toString()
                        });
                    }

                    return (changes.length > 0 || self.taxIdAdded || self.taxIdDeleted || customDataChanges.length > 0);
                };

                GlobalEventManager.confirmCancel({
                    hasChanges,
                    callback: () => {
                        self.skipSave = false;
                        self.resetTaxIdComponent();
                        self.fetchPropertyData();
                        _.invoke(self, "$refs.customDataContainer.initialize");
                    }
                });
            },

            setPropertyData(){
                const self = this;
                self.propertyModels = _.map(self.properties, p => new PropertyViewModel(p));
                self.setCurrentProperty();
                self.resetValidation();
            },

            getCurrentProperty() {
                let self = this;
                if(!self.isNewProperty) {
                    if(this.currentProperty.propertyID === null || this.currentProperty.propertyID === 0)
                        this.currentProperty.propertyID = self.propertyId;
                }
                return new PropertyViewModel({
                    property: this.currentProperty,
                    taxInformation: this.taxInformation,
                    taxIdentification: this.taxIdentification,
                    taxInquiry: this.taxInquiry
                });
            },

            setCurrentProperty(propertyData=null) {
                const self = this;

                if(self.isNewProperty) return;

                let propertyModel = _.isNil(propertyData)
                    ? self.getOriginalProperty()
                    : propertyData;

                self.propertyIndex = self.properties.length === 1 ? 0
                    : _.findIndex(self.properties, p => _.parseNumber(p.property.propertyID) === propertyModel.property.propertyID);

                self.currentProperty = new PropertyEntityModel(propertyModel.property);

                if(_.every(self.loans, l => l.loanID !== self.currentProperty.loanID))
                    self.currentProperty.loanID = self.loans[0].loanID;

                self.taxIdentification = _.map(propertyModel.taxIdentification, tid => new PropertyTaxIdentification(tid));
                self.taxInformation = _.map(propertyModel.taxInformation, tin => new PropertyTaxInformation(tin));
                self.taxInquiry = propertyModel.taxInquiry;
            },

            getBriefLegalDescription(noAutoLegalBool=false) {
                const self = this;
                if (noAutoLegalBool) return;
                let apiPromise = self.$api.PropertiesApi.getBriefLegalDescription(self.currentProperty);
                return apiPromise
                    .then(result => {
                        return result;
                    });
            },

            onTaxInformationChange(info) {
                const self = this;
                self.propertyModels[self.propertyIndex].taxInformation = self.taxInformation.slice();
            },

            onBriefLegalInput() {
                if (_.parseBool(this.currentProperty.noAutoLegal)) return;
                this.currentProperty.noAutoLegal = true;
            },

            onPreviewBriefLegalDescription() {
                let self = this;
                self.getBriefLegalDescription()
                    .then((result) => {
                        self.$dialog.messageBox("Brief Legal Description", result);
                    });
            },

            onPropertyLayoutKeyDown(e) {
                if (e.event.key === "Tab") {
                    let self = this;
                    self.getBriefLegalDescription()
                        .then((result) => {
                            self.currentProperty.legalDescription = result;
                        });
                }
            },

            onPropertyLayoutChange() {
                let self = this;
                self.getBriefLegalDescription()
                    .then((result) => {
                        self.currentProperty.legalDescription = result;
                    });
            },

            resetTaxIdComponent() {
                let taxIdComponent = this.$refs.taxIdTagbox;
                if(!taxIdComponent) return;
                taxIdComponent.reset();
            },

            navigateToProperty(propertyId) {
                this.$router.push({ params: { orderId: this.orderId, propertyId } });
            },

            createNewTaxInformation() {
                let prorationDefaults = this.lookupHelpers.getProrationDefaults();
                return _.map(_.filter(prorationDefaults, item => item.taxCategory !== 0), (item) => {
                    return new PropertyTaxInformation({
                        taxCategoryName: item.description,
                        currentTaxes: 0,
                        taxCategory: item.taxCategory
                    })
                });
            },

            onStateChange(e) {
                const self = this;

                let newValue = _.get(e, 'value');
                let oldValue = _.get(e, 'previousValue');

                if (oldValue == 'OH') {
                    self.taxInquiry = {};
                }
            },

            resetValidation() {
                const self = this;
                if(!_.isFunction(self.v$.$reset)) return;
                _.invoke(self, "$refs.propertyInformation.resetValidation");
                self.v$.$reset();
            },
            onCustomDataLoaded(e) {
                let self = this;
                self.originalCustomData = _.cloneDeep(e);
            }
        }
    };

</script>