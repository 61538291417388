<template>
    <div class="content-wrapper loancost-section">
        <rq-page-section title="Prepaids" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li v-if="isHud2010" class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly" v-focus>Add</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_disclosure_line" @click="onAddPrepaidLine(PrepaidTypes.AdditionalPrepaid, HudLineTypes.Disclosure)">Disclosure</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_general_line" @click="onAddPrepaidLine(PrepaidTypes.AdditionalPrepaid, HudLineTypes.General)">General</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_gfe3_line" @click="onAddPrepaidLine(PrepaidTypes.AdditionalPrepaid, HudLineTypes.GFE_3)">GFE #3</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_gfe11_line" @click="onAddPrepaidLine(PrepaidTypes.AdditionalPrepaid, HudLineTypes.GFE_11)">GFE #11</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li v-else class="nav-item">
                        <b-button
                            automation_id="btn_add_charge"
                            variant="theme"
                            @click="onAddPrepaidLine(PrepaidTypes.AdditionalPrepaid, null)"
                            v-rq-tooltip.hover.top="{ title: `Add Charge` }"
                            :disabled="readOnly"
                            v-focus>Add
                        </b-button>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rq-no-data
            v-if="noData"
            class="h-50"
            text="No charges found for the selected loan."
        />
        <prepaid-section
            v-else
            ref="prepaidSectionTable"
            :section-data="items"
            title="Prepaids"
            :settlementType="selectedView"
            @revert="onRevertItems"
            @delete="onDeleteItems"
            @netfund="onChangeNetFundItems"
            @setAddendum="onSetAddendumItem"
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { PREPAID_SECTION_DATA_ACTIONS } from "@/store/actions";
    import { PrepaidModel, PrepaidTypes, SETTLEMENT_TYPE, HudLineTypes } from '@settlement/models';
    import PrepaidSection from './components/PrepaidSection';
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        name: "Prepaids",
        mixins: [BaseSettlementMixin],

        components: {
            PrepaidSection
        },

        data(){
            return {
                items: [],
                itemKey: "prepaidSectionDataID",
                deletedItems: [],
                originalData: [],
                prepaidCdfSections: [
                    { id: 'F', name: 'F - Prepaids' }
                ],
                PrepaidTypes: PrepaidTypes
            };
        },

        computed:{
            ...mapState({
                prepaidSections: state => state.prepaids.prepaidSections
            }),
            loanPrepaidSections() { return _.filter(this.prepaidSections, p => p.loanID === this.selectedLoanId); },
            selectedItems() { return _.filter(this.items, "isSelected"); },
            canDelete() { return !_.isEmpty(this.selectedItems) && !this.readOnly && _.every(this.selectedItems, item => !item.required && !item.isPaidByOther); },
            canRevert() {
                return !_.isEmpty(this.selectedItems)
                    && !this.readOnly
                    && _.some(this.selectedItems, item => item.isOverridableLine
                            && (item.isBorrowerAtClosingOverridden
                                || item.isBorrowerOutsideOverridden
                                || item.isPrepaidIntToDateOverridden
                                || item.isPrepaidIntAmountOverridden));
            },
            itemsSelected(){ return !_.isEmpty(this.selectedItems) },
            noData() { return _.isEmpty(this.items); },
            HudLineTypes() { return HudLineTypes; }
        },

        watch:{
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                self.save();
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.fetchData();
            }
        },

        created() {
            this.baseInit();
            this.fetchData();
        },

        methods:{

            onRevertItems() {
                const self = this;
                // Hide any displayed tooltips after the Revert button is de-pressed
                this.$root.$emit('bv::hide::tooltip', 'btn_revert');

                _.forEach(self.selectedItems, (item) => {
                    if (item.isOverridableLine) {
                        item.revertOverrides();
                        item.isSelected = false;
                    }
                });
            },

            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel() {
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onAddPrepaidLine(lineType, hudLineType) {
                const self = this;

                let newItem = new PrepaidModel({
                    prepaidLineType: lineType,
                    hudLineType: _.parseNumber(hudLineType, HudLineTypes.General)
                });
                newItem.prepaidSectionDataID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.description = newItem.prepaidDescription;
                newItem.required = false;

                if (self.isHud) {
                    // We set hudLine values to push to server side versus cdf line (line) which is determined during mapping
                    let maxHudLineItem = _.maxBy(self.items, "hudLine");
                    newItem.hudLine = _.parseNumber(maxHudLineItem.hudLine, 0) + 1;
                }

                // New lines entered within the UI application is considered common among all settlement statements so
                // set all the sections
                newItem.hudSection = 900;
                newItem.cdfSection = "F";

                // By setting the default to null we know that this is common among all settlement statements versus being specifically initialized to CDF / HUD
                // as they can be different based on defaulted lines
                newItem.defaultedSettlementType = null;

                self.items.push(newItem);

                // Focus on description on newly added line
                self.$nextTick(() => {
                    self.$events.emit("prepaids::focus-line", { id: newItem.prepaidSectionDataID });
                });
            },

            onDeleteItems() {
                const self = this;
                let confirmMessage = 'Are you sure you wish to delete selected charge(s)?';

                let ok = function() {
                    _.forEach(self.selectedItems, (item) => {
                        if(item.prepaidSectionDataID > 0) {
                            // Track deleted items as these are persisted and need to be actually deleted on server side
                            self.deletedItems.push(item);
                        }

                        // Remove associated PBO line if it exists
                        if (item.paidByCompanyID > 0) {
                            let pboItemIndex =  _.findIndex(self.items, i => i.paidBySource === item.hudLine);
                            if(pboItemIndex > -1) self.items.splice(pboItemIndex, 1);
                        }

                        // Keep all items synced
                        let index = self.items.indexOf(item);                        
                        self.items.splice(index,1);
                    });

                    // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                    if (self.isHud) {
                        self.reorderHudItems();
                    }                    
                }
                self.$dialog.confirm('Confirm', confirmMessage, ok);
            },

            reorderHudItems() {
                const self = this;
                let hudItems = _.filter(self.items, i => i.hudSection === 900);
                if(hudItems.length === 0) return; // Nothing to reorder

                let minLine = _.minBy(hudItems, function(i) { return i.hudLine; });
                let lineNumber = _.parseNumber(minLine.hudLine, 0);
                _.forEach(_.sortBy(hudItems, h => h.hudLine), (hudItem) => {
                    if (hudItem.hudLine !== lineNumber) {
                        let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === hudItem.prepaidSectionDataID);
                        self.items[itemIndex].hudLine = lineNumber;
                        hudItem.hudLine = lineNumber;
                    }
                    lineNumber = lineNumber + 1;
                });
            },

            onChangeNetFundItems(e) {
                const self = this;
                if (!e) return;

                _.forEach(self.selectedItems, (item) => {
                    item.netFund = _.getNumber(e, "key", 0);
                    item.isSelected = false;
                });
            },

            onSetAddendumItem(e) {
                const self = this;
                if (!e) return;

                let lineItem = self.selectedItems[0];
                let isSetAddendum = !_.parseBool(lineItem.isAddendum); //if isAddendum is not true then we know we're setting an addendum line

                // When setting Addendum, since there can only be one addendum per section, wit's implied that the any
                // other lines (should just be one if any) that is an addendum line needs to be cleared from being one
                if (isSetAddendum) {
                    _.forEach(self.items, (item) => {
                        if (lineItem.prepaidSectionDataID === item.prepaidSectionDataID)
                            item.isAddendum = true;
                        else
                            item.isAddendum = false;
                    });
                }
                else // remove a specific addendum flag on a specific line
                    lineItem.isAddendum = !_.parseBool(lineItem.isAddendum);

                lineItem.isSelected = false;
            },

            mapData(itemData=null, type=null) {
                const self = this;
                let items =  _.isNil(itemData) ? self.loanPrepaidSections : itemData;
                let ssType = _.isNil(type) ? self.selectedView : type;
                let isCdf = ssType === SETTLEMENT_TYPE.CDF;
                let viewItems = _.filter(items, item =>
                    (item.defaultedSettlementType === ssType || _.isNil(item.defaultedSettlementType))
                        && (isCdf ? item.cdfSection === "F" : item.hudSection === 900)
                        && (!this.isWithOutSeller || !item.isPayoff) // Additional filter when SS is without seller we don't want to show payoff items
                );

                let sortBy = isCdf ? "line" : "hudLine";
                return _.sortBy(_.map(viewItems, item => new PrepaidModel(item)), sortBy);
            },

            populateItems() {
                const self = this;
                // Keep track of hud items through out the component
                self.items = self.mapData();
                self.originalData = self.mapData();
            },

            fetchData() {
                const self = this;
                self.deleteItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);

                // let getPromise = self.$api.PrepaidsApi.getRecordsByLoan(self.selectedLoanId);
                let getPromise = self.$store.dispatch(PREPAID_SECTION_DATA_ACTIONS.GET_PREPAID_SECTION_DATA);
                return self.$rqBusy.wait(getPromise)
                    .then(() => self.$nextTick())
                    .then(() => {
                        self.populateItems();
                    });
            },

            save(refreshData = false) {
                const self = this;
                if(!self.hasChanges()){
                    if(refreshData)
                        self.fetchData();
                    else
                        self.populateItems();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let savePromise = self.$store.dispatch(PREPAID_SECTION_DATA_ACTIONS.UPDATE_PREPAID_SECTION_DATA, { items: self.items, deletedItems: self.deletedItems});
                return self.$rqBusy.wait(savePromise)
                    .then(() => self.$nextTick())
                    .then(() =>{
                        self.populateItems();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Prepaid Fees Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Prepaid Fees");
                        console.error(err);
                    });

            },

            hasChanges(){
                //if the arrays are different length, then there are changes.  Deletes have already been saved
                if(this.items.length !== this.originalData.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(this.originalData, this.items);
                return changes.length > 0;
            }
        }
    }
</script>
