<template>
    <div class="rq-container content-wrapper">
        <order-selection
            ref="orderSelectionComponent"
            automation_id="dg_applyTemplate"
            :visible-columns="visibleColumns"
            :hidden-columns-in-chooser="hiddenColumnsInChooser"
            status-filter="4"
            :template-target="category"
            v-model="selectedOrderId"
            @double-click="onRowDoubleClick"
            @single-click="onRowClick"
            fixed-header
        />
    </div>
</template>

<script>
    import OrderSelection from "./OrderSelection";
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import { ORDER_ACTIONS } from "@/store/actions";

    export default {
        name: "ApplyTemplate",
        props: {
            category: { type: String, default: null },
            targetId: { type: Number, default: null },
            targetLoanId: { type: Number, default: null }
        },
        components: { OrderSelection },
        data () {
            return {
                standardColumns: ["gfNo", "region", "branch", "status", "buyer", "seller", "lender", "underwriter", "propertyAddress"],
                buyerSellerColumns : ["ownerPolicyNumber", "escrowLockDate", "cancelledDate", "mortgagePolicyNumber", "titleCompany", "earnestMoney"],
                titleProductionPolicy: ["salesRep", "legalDescription"],
                propertyColumns: ["gfNo", "lenderCompanyID", "buyer", "seller", "openDate", "propertyAddress"],
                loanColumns: ["gfNo", "lenderCompanyID", "buyer", "seller", "openDate", "propertyAddress"],
                selectedOrderId: 0,
                selectedGFNO: "",
                selectedDefaultSSType: 0,
                selectedDefaultIncludeAlta: false,
                templateCategories: {
                    oe:"Order Entry",
                    op:"Properties",
                    ol:"Loans",
                    tp:"Title Production/Policy",
                    se:"Seller",
                    bu:"Buyer",
                    oi:"Invoices",
                    os:"Settlement Statement"
                },
                templateConfirmMessages: {
                    oe:"<p>Applying a template will update all entries within File Main with entries in the template. This will also add Buyers, Sellers, Loans and Properties from the selected template.</p>",
                    op:"<p>Applying a template will add Properties to this file from the selected Template.</p>",
                    ol:"<p>Applying a template will add Loans to this file from the selected Template.</p>",
                    tp:"<p>This will delete all entries in the Title Production & Policy for this file and replace them with entries in the selected Template.</p>",
                    se:"<p>Applying a template will add Sellers to this file from the selected Template.</p>",
                    bu:"<p>Applying a template will add Buyers to this file from the selected Template.</p>",
                    oi:"<p>Applying a template will add Invoices to this file from the selected Template.</p>",
                    os:"<p>The Settlement Statement will be updated with template information by adding lines where applicable.  Any entries on the Debits/Credits Page will be replaced with template information.</p>"
                }
            };
        },

        computed: {
            gridInstance () { return _.get(this, "$refs.orderSelectionComponent.gridInstance", null); },
            visibleColumns() {
                const self = this;
                if (self.category === "bu" || self.category === "se"){
                    return _.concat(self.standardColumns, self.buyerSellerColumns);
                }
                else if (self.category === "oe"){
                    return self.standardColumns;
                }
                else if(self.category === "op") {
                    return self.propertyColumns;
                }
                else if(self.category === "ol") {
                    return self.loanColumns;
                }
                else {
                    return _.concat(self.standardColumns, self.titleProductionPolicy);
                }
            },
            hiddenColumnsInChooser() {
                let opolHiddenColumns = ["earnestMoney", "bankCompanyID", "ordersID", "closeAgentStaffID", "escrowLockDate", "mortgagePolicyNumber", "ownerPolicyNumber", "salesRepresentativeStaffID"];
                if(this.category === "oe")
                    return ["earnestMoney", "bankCompanyID", "ordersID"];
                else if(this.category === "op")
                    return opolHiddenColumns;
                else if(this.category === "ol")
                    return [...opolHiddenColumns, "sellingAgentCompanyID"];

                return [];
            },
            confirmMessage() {
                let defMsg = this.templateConfirmMessages.oe;
                return _.get(this.templateConfirmMessages, this.category, defMsg) || defMsg;
            }
        },

        watch: {
            selectedOrderId: {
                handler(newValue) {
                    if(_.parseNumber(newValue, 0) > 0)
                        this.$emit("enable-ok");
                    else
                        this.$emit("disable-ok");
                },
                immediate: true
            }
        },

        methods: {

            onRowDoubleClick (e) {
                if(e <= 0) return;
                this.selectedOrderId = e;
                this.$emit("ok");
            },

            onRowClick (e) {
                if(e <= 0) return;
                this.selectedOrderId = e;
            },

            confirmApplyTemplate () {
                const self = this;
                let defCat = self.templateCategories.oe;
                let category = _.get(self.templateCategories, self.category, defCat) || defCat;
                return new Promise((resolve, reject) => {
                    self.$dialog.confirm(
                        "Apply Template",
                        self.confirmMessage,
                        () => {
                            self.applyTemplate()
                                .then(resolve)
                                .catch(reject);
                            return true;
                        },
                        () => resolve(false)
                    );
                });
            },

            applyTemplate () {
                const self = this;

                let applyTemplateArgs = {
                    OrdersIdSource: self.selectedOrderId,
                    OrdersIdTarget: self.targetId,
                    LoanIdTarget: self.targetLoanId,
                    OverrideAll: true,
                    NewOrder: false,
                    Category: self.category,
                    routeName: self.$route.name
                };

                let storePromise = self.$store.dispatch(ORDER_ACTIONS.APPLY_TEMPLATE, applyTemplateArgs);
                return self.$rqBusy.wait(storePromise)
                    .then(() => true)
                    .catch(err => {
                        console.error(err);
                        self.$toast.error("Apply template failed");
                        return err;
                    });
            },

            updateDimensions () {
                const self = this;
                if (!self.gridInstance) return;
                self.gridInstance.updateDimensions();
            }
        }

    };
</script>