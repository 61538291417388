import ViewActions from "@/router/actions/settlement";
import PayoffMain from "./PayoffMain";
import PayoffList from "./PayoffList";
import PayoffDetail from "./PayOffDetail";

const noPayoffAccessMsg = "Access to settlement payoffs is not authorized.  Please contact your administrator.";

export default {
    path: "payoffs",
    component: PayoffMain,
    meta: {
        label: "Payoffs",
        include: true
    },
    children: [
        {
            name: "settlement:payoffs:list",
            path: ":loanId?",
            component: PayoffList,
            meta: {
                entity: "Payoff",
                extension: false,
                label: "PayOff List",
                include: false,
                actions: ViewActions.PAYOFFS_LIST,
                accessKey: "Payoff",
                unauthorizedMessage: noPayoffAccessMsg,
                skipSaveOnLeave: true,
            }
        },
        {
            name: "settlement:payoffs:detail",
            path: ":loanId/payoff/:payoffId?",
            component: PayoffDetail,
            meta: {
                entity: "Payoff",
                extension: false,
                label: "Payoff Detail",
                actions: ViewActions.PAYOFFS_DETAIL,
                accessKey: "Payoff",
                unauthorizedMessage: noPayoffAccessMsg,
            }
        }
    ]
}
