<template>
    <div class="content-wrapper">
        <rq-banner
            icon="fas fa-exclamation-circle"
            variant="error"
            :message="errorMessage"
            :visible="hasErrors"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="row ps-3 pe-3 pt-3">
            <div class="col col-12">
                <rq-action-form-group
                    label="Description"
                    label-for="drp_description"
                    :has-error="v$.item.description.$error"
                    required
                    show-action>
                    <template #action>
                        <b-form-checkbox
                            automation_id="chk_use_default_description"
                            id="chk_use_default_description"
                            @change="onUseDefaultDescriptionChange"
                            :disabled="localSecurity.PredefinedDescriptions"
                            v-model="item.useDefaultDescription">Use Default Description</b-form-checkbox>
                    </template>
                    <dx-select-box v-if="item.useDefaultDescription || localSecurity.PredefinedDescriptions"
                        automation_id="drp_description"
                        value-expr="id"
                        display-expr="name"
                        :items="fileScanDescriptions"
                        @value-changed="onPredefinedDescriptionChange"
                        v-model="item.fileScanDescriptionID"
                    />
                    <input v-else
                        id="txt_description"
                        automation_id="txt_description"
                        type="text"
                        class="form-control"
                        placeholder="Description"
                        v-model="v$.item.description.$model"
                        @change="onDescriptionChange"
                    >
                    <rq-validation-feedback>Description is required.</rq-validation-feedback>
                </rq-action-form-group>
            </div>
        </div>
        <div class="row ps-3 pe-3">
            <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.fileScanCategoryID.$error }">
                <label for="drp_file_scan_category_id">Category</label>
                <dx-select-box
                    automation_id="drp_file_scan_category_id"
                    value-expr="id"
                    display-expr="name"
                    :items="fileScanCategories"
                    @value-changed="onCategoryChange"
                    v-model="v$.item.fileScanCategoryID.$model"
                />
                <rq-validation-feedback>Category is required</rq-validation-feedback>
            </div>
        </div>
        <div class="row ps-3 pe-3">
            <div class="col col-12 form-group">
                <label for="tb_tags">Tags</label>
                <dx-tag-box
                    automation_id="tb_tags"
                    ref="tb_tags"
                    class="form-control"
                    :data-source="documentTags"
                    display-expr="name"
                    value-expr="id"
                    :search-enabled="true"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model="item.tagIDs"
                    @valueChanged="onTagChange"
                />
            </div>
        </div>
        <div class="row ps-3 pe-3" v-show="item.requiresAttention">
            <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.fileScanDocumentDuplicateAction.$error }">
                <label for="drp_file_scan_duplicate_action">Action</label>
                <dx-select-box
                    automation_id="drp_file_scan_duplicate_action"
                    value-expr="id"
                    display-expr="name"
                    :items="documentActions"
                    v-model="v$.item.fileScanDocumentDuplicateAction.$model"
                    @value-changed="onActionChange"
                />
                <rq-validation-feedback>Action is required</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { FileScanDocumentType }  from "../../../documents/enums";
    import { FileScanDocumentDuplicateAction }  from "../../../document-mgmt/enums";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { DateTimeHelper } from "@/shared/utilities";
    export default {
        name: 'UploadDocumentProperties',
        props: {
            item: {type: Object, default: null}
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                hadError: false,
                hasErrors: false,
                originalDescription: "",
                errorMessage: ""
            }
        },
        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            localSecurity(){
                return this.securitySettings.findValues(["CanReplaceDocuments","FileScanCanAttachFilesAndDocuments","PredefinedDescriptions"]);
            },
            fileScanDescriptions() { return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_DESCRIPTIONS); },
            hasDescriptionChanged() { return !_.isEqual(this.originalDescription, this.item.description); },
            hasValidDescription() {
                if (this.item.useDefaultDescription && _.getNumber(this.item, "fileScanDescriptionID", 0) > 0) {
                    return true;
                }
                if (!this.item.useDefaultDescription && _.size(this.item.description) > 0) {
                    return true;
                }
                return false;
            },
            documentTags() { return this.lookupHelpers.getLookupItems(this.lookupItems.DOCUMENT_TAGS); },
            fileScanCategories() { return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_CATEGORIES); },
            fileScanDocumentTypes() { return FileScanDocumentType.lookupItems; },
            tooltipContainer() { return `#${this.$parent.dialogId}`; },
        },
        created(){
            this.originalDescription = this.item.description;
            this.documentActions = this.localSecurity.CanReplaceDocuments
                                 ? _.filter(FileScanDocumentDuplicateAction.lookupItems, function(i) { return i.id > 0 ; })
                                 : _.filter(FileScanDocumentDuplicateAction.lookupItems, function(i) { return i.id == FileScanDocumentDuplicateAction.CreateNew ; });
        },
        validations: function() {
            const self = this;

            var result = {
                item: {
                    fileScanCategoryID: {
                        required,
                    },
                    fileScanDocumentDuplicateAction: {
                        required,
                    },
                    description: {
                        required,
                        hasValidDescription(val) {
                            return self.hasValidDescription;
                        }
                    },
                }
            }
            return result;
        },
        methods: {
            onActionChange(e) {
                if (!_.isNumber(e.selectedValue)) {
                    if(e.selectedValue)
                        this.$emit("disable-ok");
                    return;
                }
                if (e.selectedValue == FileScanDocumentDuplicateAction.CreateNew && this.hasDescriptionChanged) {
                    this.item.description = `${this.originalDescription}_${DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a")}`;
                    this.item.useDefaultDescription = false;
                }
                if (e.selectedValue == FileScanDocumentDuplicateAction.ReplaceExisting && this.hasDescriptionChanged) {
                    this.item.description = this.originalDescription;
                }
                this.$emit("enable-ok");
                this.hasErrors = false;
                this.errorMessage = "";
            },
            onCategoryChange(e) {
                this.item.fileScanCategoryDisplay = this.lookupHelpers.getLookupItemName(this.lookupItems.FILE_SCAN_CATEGORIES, e.selectedValue);
                this.v$.$touch();
            },
            onDescriptionChange(e) {
                this.hasErrors = this.hasErrors && !this.hasDescriptionChanged;
                if (!this.hasErrors && this.item.requiresAttention) {
                    this.errorMessage = "";
                    this.item.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.CreateNew;
                    this.item.requiresAttention = false;
                    this.$emit("enable-ok");
                }
                this.v$.$touch();
            },
            onUseDefaultDescriptionChange(checked) {
                if (!checked) {
                    this.item.description = this.originalDescription;
                    if (this.hadError) {
                        this.errorMessage = `Document with file type [${this.item.fileTypeDisplay}] and Description [${this.item.description}] already exists.  Please change the description or choose the appropriate Action.`;
                        this.hasErrors = true;
                        this.disable();
                    }
                }
                this.v$.$touch();
            },
            onPredefinedDescriptionChange(e) {
                if (this.item.useDefaultDescription) {
                    this.item.description = this.lookupHelpers.getLookupItemName(this.lookupItems.FILE_SCAN_DESCRIPTIONS, e.value);
                }
                this.v$.$touch();
                this.hasErrors = this.hasErrors && !this.hasDescriptionChanged;
                if (!this.hasErrors && this.item.requiresAttention) {
                    this.errorMessage = "";
                    this.item.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.CreateNew;
                    this.item.requiresAttention = false;
                    this.$emit("enable-ok");
                }
            },
            onTagChange(e) {
                this.item.tagNames = _.join(_.map(e.value, id => _.find(this.documentTags, { id }).name), ", ") || "";
            },
            disable() {
                this.$emit("disable-ok");
            },
        }
    }
</script>