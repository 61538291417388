<template>
    <div class="content-wrapper">
        <order-summary/>
        <router-view></router-view>
    </div>
</template>

<script>
    import OrderSummary from '../shared/components/OrderSummary';
    import Routes from './routes';

    export default {
    
        components: {
            OrderSummary
        },

        data () {
            return {
                routes: Routes
            };
        }
    };
</script>