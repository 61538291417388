<template>
    <div class="content-wrapper" id="ezjacket-policies-grid">
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Policy Jackets"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            @schedule-upload="onScheduleUpload"
            @void="onVoidPolicyJacket"
            hide-search>
            <template #toolbar>
                <div class="rq-title navbar-text me-auto">
                    <b-btn variant="theme" size="sm" @click="onAddItem" v-if="showAdd">Add </b-btn>
                </div>
            </template>
        </rqdx-action-data-grid>
        <grid-popover
            id="endorsements"
            container="#ezjacket-policies-grid"
            :popover="gridPopoverEndorsements"
            v-model:visible="gridPopoverEndorsements.visible"
        />
        <grid-popover
            id="fees"
            container="#ezjacket-policies-grid"
            :popover="gridPopoverFees"
            v-model:visible="gridPopoverFees.visible"
        />
        <grid-popover
            id="images"
            container="#ezjacket-policies-grid"
            :popover="gridPopoverImages"
            v-model:visible="gridPopoverImages.visible"
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import GridPopover from "@/shared/components/rq/GridPopover";
    import { EzJacketPolicyStatus, EzJacketPolicyCategory, EzJacketRemittanceStatus } from "../enums";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import SmktEzJacketScheduleUpload from "../../ezjacket/components/SmktEzJacketScheduleUpload.vue";

    export default {
        name: "PolicyJacketList",
        components: { GridPopover },
        mixins: [ GridSystemLookupMixin ],
        data: function() {
            return {
                gridDataSource: [],
                gridConfig: {},
                showAdd: false,
                items: [],
                images: [],
                gridPopoverEndorsements: {
                    visible: false,
                    target: null,
                    gridConfig: {},
                    items: [],
                    title: null
                },
                gridPopoverFees: {
                    visible: false,
                    target: null,
                    gridConfig: {},
                    items: [],
                    title: null
                },
                gridPopoverImages: {
                    visible: false,
                    target: null,
                    gridConfig: {},
                    items: [],
                    title: null
                },
            }
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary
            }),
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            policyStatuses() { return EzJacketPolicyStatus.lookupItems; },
            policyCategories() { return EzJacketPolicyCategory.lookupItems; },
            remittanceStatuses(){ return EzJacketRemittanceStatus.lookupItems; },
            selectionActions() {
                return [
                    { name: "schedule-upload", text: "Upload Schedule", eventName: "schedule-upload", requireSelection: true },
                    // { name: "void", text: "Void", eventName: "void", tooltip: `Void ${this.itemTypeName}` }
                ];
            },
        },
        created () {
            this.initGridConfig();
            this.fetchData();
        },
        methods: {
            elementName(prefix = "", suffix = "") {
                return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "orderId",
                            dataType: "number",
                            caption: "Order #",
                        },
                        {
                            dataField: "policyNumber",
                            dataType: "string",
                            caption: "Policy #",
                        },
                        {
                            dataField: "name",
                            dataType: "string",
                            caption: "Policy Name",
                        },
                        {
                            dataField: "category",
                            dataType: "number",
                            lookup: {
                                dataSource: self.policyCategories,
                                valueExpr: "id",
                                displayExpr: "name",
                            },
                        },
                        {
                            dataField: "calculatedPremium",
                            dataType: "number",
                            caption: "Premium",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "liability",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "priorPremium",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "premium",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "netPremium",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "insuredName",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "effectiveDate",
                            dataType: "date",
                            caption: "Effective Date",
                            visible: false
                        },
                        {
                            dataField: "policyStatus",
                            dataType: "number",
                            lookup: {
                                dataSource: self.policyStatuses,
                                valueExpr: "id",
                                displayExpr: "name",
                            },
                        },
                        {
                            dataField: "hasImages",
                            dataType: "boolean",
                            caption: "Has Schedules",
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "policy-image-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        const items = _.filter(self.images, { policyId: cellInfo.data.id });
                                        self.updateImageGridPopover(items, e.target.id);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                        },
                        {
                            dataField: "hasEndorsements",
                            dataType: "boolean",
                            calculateCellValue: function(data) {
                                return _.size(data.endorsements) > 0;
                            },
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "policy-endorsement-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateEndorsementGridPopover(cellInfo.data.endorsements, e.target.id);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                        },
                        {
                            dataField: "hasFees",
                            dataType: "boolean",
                            calculateCellValue: function(data) {
                                return _.size(data.fees) > 0;
                            },
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "policy-fee-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateFeeGridPopover(cellInfo.data.fees, e.target.id);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                            visible: false
                        },
                        {
                            dataField: "isFinalized",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                        {
                            dataField: "remittanceStatus",
                            dataType: "number",
                            lookup: {
                                dataSource: self.remittanceStatuses,
                                valueExpr: "id",
                                displayExpr: "name",
                            },
                            visible: false
                        },
                        {
                            dataField: "remittedDateTime",
                            dataType: "datetime",
                            caption: "Remittance Date",
                            visible: false
                        },
                        {
                            dataField: "createdBy",
                            dataType: "string",
                            caption: "Created By",
                        },
                        {
                            dataField: "createdDateTime",
                            dataType: "datetime",
                            caption: "Created On"
                        },
                    ],
                };
                self.gridDataSource = {
                    key: "id",
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },
            fetchData() {
                const self = this;
                self.showAdd = false;
                let apiPromise = self.$api.SmktEzJacketApi.getPolicyData(self.orderId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = result.policies;
                        self.images = result.images;
                        self.refresh();
                        self.showAdd = _.isEmpty(self.items);
                    })
                    .catch(error => {
                        console.log(error);
                        self.$toast.error({ message: `Error loading Policy List.` });
                        return error;
                    });
            },
            getPopoverGridColumns(type) {
                if (_.isEqual(type, "Endorsements")) {
                    return {
                        keyExpr: "id",
                        width: "650px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                    minWidth: 400
                                },
                                {
                                    dataField: "calculatedNetPremium",
                                    dataType: "number",
                                    caption: "Net Premium",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                                {
                                    dataField: "calculatedPremium",
                                    dataType: "number",
                                    caption: "Premium",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                        ],
                        summary: {
                            totalItems: [
                                {
                                    name: "TotalLabel",
                                    column: "name",
                                    alignment: "left",
                                    displayFormat: "TOTAL",
                                    cssClass: "rq-summary-label",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemNetTotal",
                                    column: "calculatedNetPremium",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemTotal",
                                    column: "calculatedPremium",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                            ]
                        }
                    };
                } else if (_.isEqual(type, "Fees")) {
                    return {
                        keyExpr: "id",
                        width: "525px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                    minWidth: 400
                                },
                                {
                                    dataField: "amount",
                                    dataType: "number",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                        ],
                        summary: {
                            totalItems: [
                                {
                                    name: "TotalLabel",
                                    column: "name",
                                    alignment: "left",
                                    displayFormat: "TOTAL",
                                    cssClass: "rq-summary-label",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemTotal",
                                    column: "amount",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                            ]
                        }
                    };
                } else {
                    return {
                        keyExpr: "id",
                        width: "510px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                    minWidth: 400
                                },
                                {
                                    dataField: "status",
                                    dataType: "string",
                                    width: 100
                                },
                        ]
                    };
                }
            },
            onAddItem() {
                this.$events.$emit("add-jacket");
            },
            onScheduleUpload(e){
                if(!e || !e.data) return;
                this.showScheduleUploadDialog(e.data);
            },
            onVoidPolicyJacket(){

            },
            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            showScheduleUploadDialog(item) {
                const self = this;
                let onOk = (e) => {
                    return e.component.save()
                            .then(result => {
                                self.fetchData();
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                console.log(error.errorMessage);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    return true;
                };
                let title = `Upload Documents to Policy#: ${item.policyNumber.trim()}`;
                self.$dialog.open({
                    title: title,
                    width: "60%",
                    height: "60%",
                    closeOnEsc: true,
                    component: SmktEzJacketScheduleUpload,
                    props: { id: item.orderId, policyId: item.id},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
            updateEndorsementGridPopover(items=null, target=null) {
                const self = this;
                let popoverGridConfig = self.getPopoverGridColumns("Endorsements");
                let newID = target;
                let lastID = _.get(self.gridPopoverEndorsements, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopoverEndorsements);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    gridPopover.target = newID;
                    gridPopover.gridConfig = popoverGridConfig;
                    gridPopover.items = items;
                    gridPopover.title = "Endorsements";
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }
                self.$nextTick(() => {
                    self.gridPopoverEndorsements = gridPopover;
                });
            },
            updateFeeGridPopover(items=null, target=null) {
                const self = this;
                let popoverGridConfig = self.getPopoverGridColumns("Fees");
                let newID = target;
                let lastID = _.get(self.gridPopoverFees, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopoverFees);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    gridPopover.target = newID;
                    gridPopover.gridConfig = popoverGridConfig;
                    gridPopover.items = items;
                    gridPopover.title = "Fees";
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }
                self.$nextTick(() => {
                    self.gridPopoverFees = gridPopover;
                });
            },
            updateImageGridPopover(items=null, target=null) {
                const self = this;
                let popoverGridConfig = self.getPopoverGridColumns("Images");
                let newID = target;
                let lastID = _.get(self.gridPopoverImages, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopoverImages);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    gridPopover.target = newID;
                    gridPopover.gridConfig = popoverGridConfig;
                    gridPopover.items = items;
                    gridPopover.title = "Schedules";
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }
                self.$nextTick(() => {
                    self.gridPopoverImages = gridPopover;
                });
            },
        }
    }
</script>