<template>
    <div id="settlement-statement-contacts" class="content-wrapper">
        <rq-banner
            v-for="(dNDBW) in dNDBWList"
            :key="dNDBW.id"
            :message="dNDBW.message"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="dNDBWList.length > 0"
            dismissable
            sticky
        />
        <rq-page-section title="CDF Contacts" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" ref="addLineBtn" :disabled="readOnly" v-focus>Add</button>
                            <ul class="dropdown-menu">
                                <li v-if="!hasLender" automation_id="btn_add_lender" >
                                    <button type="button" class="dropdown-item" @click="onAddLender">Lender</button>
                                </li>
                                <li v-if="!hasMortgageBroker">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_mortgage_broker" @click="onAddMortgageBroker">Mortgage Broker</button>
                                </li>
                                <li v-if="!hasBuyerRealEstateAgent && !isWithOutSeller">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_buyer_real_estate_agent" @click="onAddBuyerRealtor">Real Estate Agent (B)</button>
                                </li>
                                <li v-if="!hasSellerRealEstateAgent && !isWithOutSeller">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_seller_real_estate_agent" @click="onAddSellerRealtor">Real Estate Agent (S)</button>
                                </li>
                                <li v-if="!hasSettlementAgent">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_settlement_agent" @click="onAddSettlementAgent">Settlement Agent</button>
                                </li>
                                <li v-if="hasAllContacts">
                                    <button type="button" class="dropdown-item" automation_id="btn_all_contacts" disabled>All available contacts already assigned</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <button automation_id="btn_pull" type="button" class="btn btn-default btn-theme" :disabled="readOnly" @click="pull">Pull</button>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                        />
                    </li>
                </ul>
            </template>
            <div class="row rq-card-list">
                <div v-for="contact in contactItems" :key="contact.clientKey"
                    class="col-12 col-lg-6 col-xl-4 col-pc-3 rq-card-col">
                    <settlement-statement-contact-card
                        :contact="contact"
                        @detail-click="onCardDetailClick"
                        @assign="onAssignContactClick"
                        @delete="onDeleteContactClick"
                        @save="onSave"
                        show-assign
                        show-delete
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { ref, computed } from "vue";
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { ContactCardModel } from "./models";
    import { SETTLEMENT_TYPE, SettlementTypeOption } from "@settlement/models";
    import SettlementStatementContactCard from "./components/SettlementStatementContactCard";
    import { CompanyContactLookup } from "@/modules/file/order-entry/contacts/components";

    const FILTER_ROLE_TYPE_IDS = [15,28,2,24,19,16,10,6];
    //const FILTER_EXCLUSIONS = [ 3, 18, 21, 20, 22, 26, 25, 23, 103 ];

    export default {
        name: "SettlementStatementContactCards",
        components: { SettlementStatementContactCard },
        data () {
            return {
                filtersExpanded: false,
                selectedLoanId: 0,
                items: [],
                originalItems: [],
                addedItems: [],
                deletedItems: [],
                roleFilter: [],
                tooltipOptions: {
                    container: "#order-contact-manager",
                    boundary: "window"
                }
            };
        },
        setup(props) {
            const dNDBWList = ref([]);
            return {
                dNDBWList,
            }
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                currentFileNo: state => state.orders.orderSummary.fileNumber,
                loans: state => state.orders.loans || [],
                isWithOutSeller: state => _.parseBool(state.orders.orderSummary.isWithOutSeller, false),
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF)
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            contactItems() {
                return this.isWithOutSeller ? _.filter(this.items, item => item.roleTypeID !== 10) : this.items;
            },
            hasLender(){
                return _.some(this.items, item => item.roleTypeID === 6);
            },
            hasMortgageBroker(){
                return _.some(this.items, item => item.roleTypeID === 14);
            },
            hasBuyerRealEstateAgent(){
                return _.some(this.items, item => item.roleTypeID === 10 && item.isBuyerRealtor === true);
            },
            hasSellerRealEstateAgent(){
                return _.some(this.items, item => item.roleTypeID === 10 && item.isBuyerRealtor === false);
            },
            hasSettlementAgent(){
                return _.some(this.items, item => item.roleTypeID === 28);
            },
            hasAllContacts(){
                return this.hasLender && this.hasMortgageBroker && this.hasBuyerRealEstateAgent && this.hasSellerRealEstateAgent && this.hasSettlementAgent;
            },
        },

        mounted () {
            this.resetFocus();
        },

        created () {
            const self = this;
            if (self.userAccess.full && !self.embeddedMode) {
                GlobalEventManager.onSave(self, self.onSaveClick);
                GlobalEventManager.onCancel(self, self.onCancelClick);
            }

            let defaultLoanId = this.orderSummary.defaultLoanID;
            this.selectedLoanId = !_.isNil(defaultLoanId) ? defaultLoanId : this.loans[0].loanID;
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },
        watch:{
            selectedLoanId(newVal, oldVal){
                const self = this;
                if(_.isNil(newVal)) return;
                if(self.isDirty()){
                    self.save(false).then(()=>{
                        self.fetchData();
                    });
                }
                else{
                    self.fetchData();
                }
            },
            contactItems(newVal, oldVal) {
                const self = this;
                self.dNDBWList = [];
                self.updateDNDBW();
            }
        },
        methods: {

            isDirty() {
                const self = this;
                //see if items were added/removed
                if(self.addedItems.length > 0) return true;
                if(_.some(self.items, i => i.settlementStatementContactID === 0)) return true;
                if(self.items.length !== self.originalItems.length) return true;

                let itemChanges = self.getAuditChanges(self.items, self.originalItems);
                if(itemChanges.length > 0) return true;

                return false;
            },

            fetchData () {
                const self = this;
                let promise = self.$api.SettlementStatementContactsApi.getByLoanId(self.selectedLoanId).then((result) => {
                        self.mapData(result);
                    }).catch(err =>{
                        self.$toast.error(err.errorMessage);
                    });
                self.$rqBusy.wait(promise);
                return promise;
            },

            clearList() {
                const self = this;
                self.dNDBWList = [];
            },

            updateDNDBW() {
                const self = this;
                const newWarnings = [];
                self.$api.SettlementStatementContactsApi.getDoNotDoBusinessWithList(self.selectedLoanId).then((result) => {
                    result.forEach(warning => {
                        const warningObj = {
                            id: _.createUuid(),
                            message: warning,
                        }
                        newWarnings.push(warningObj);
                    });
                    
                    self.dNDBWList = newWarnings;
                });
            },

            save(userInitiated=false) {
                const self = this;

                if(!self.isDirty()) {
                    GlobalEventManager.saveCompleted({success: true});
                    if(!userInitiated) return Promise.resolve(false);
                    self.$toast.info("No changes detected.");
                    return Promise.resolve(false);
                }

                let updates = [];
                _.forEach(self.items, (i) => {
                    let original = _.find(self.originalItems, (o) => { return o.clientKey === i.clientKey;});
                    let changes = self.getAuditChanges(original, i);
                    if(changes.length > 0)
                        updates.push(i);
                });
                let data = { loanID: self.selectedLoanId, contacts: _.concat(updates, self.addedItems)};

                let promise = self.$api.SettlementStatementContactsApi.save(data).then((result)=>{
                    GlobalEventManager.saveCompleted({success: true});
                    self.mapData(result);
                    self.$toast.success("Settlement Contacts Saved Successfully.")
                })
                .catch(err => {
                    GlobalEventManager.saveCompleted({success: false});
                    self.$toast.error(err.errorMessage);
                });

                self.$rqBusy.wait(promise);

                return promise;
            },

            pull(){
                const self = this;

                self.save(false).then(() =>{
                    let promise = self.$api.SettlementStatementContactsApi.pullByLoanId(self.selectedLoanId).then((result)=>{
                        self.mapData(result);

                        self.$toast.success("Settlement Contacts Pulled.")
                    })
                    .then(() => {
                        this.onMapSettlementData(this.selectedView, true);
                    })
                    .catch(err => {
                        self.$toast.error(err.errorMessage);
                    });

                    self.$rqBusy.wait(promise);

                    return promise;
                });
            },

            mapData(data){
                const self = this;
                self.items = _.map(data, (d) => {return new ContactCardModel(d);});
                self.originalItems = _.cloneDeep(self.items);
                self.addedItems.length = 0;
                self.deletedItems.length = 0;
            },

            onAddLender(e){
                this.showAddCompanyLookup(6, "Lender");
            },

            onAddMortgageBroker(e){
                this.showAddCompanyLookup(14, "Mortgage Broker");
            },

            onAddBuyerRealtor(e){
                this.showAddCompanyLookup(10, "Real Estate Agent", true);
            },

            onAddSellerRealtor(e){
                this.showAddCompanyLookup(10, "Real Estate Agent", false);
            },

            onAddSettlementAgent(e){
                this.showAddCompanyLookup(28, "Settlement Agent");
            },

            onAssignContactClick(e) {
                const self = this;
                let contact = e.data;
                let handleOk = function(ee) {
                    let result = _.get(ee, "originalEvent.data", e.data);
                    contact.companyID = result.companyID;
                    contact.contactID = result.contactID;
                    self.save(false);
                    return true;
                }
                let props = {
                    roleTypeId: contact.roleTypeID,
                    roleTypeName: contact.roleTypeName,
                    selectedCompanyId: contact.companyID,
                    selectedContactId: contact.contactID,
                    targetCompany: {
                        companyId: contact.companyID,
                        name: contact.companyName,
                        regionId: contact.regionID
                    }
                }
                self.showCompanyLookup(props, handleOk);
            },

            onSaveClick(e) {
                let userInitiated = _.get(e, "userInitiated", false);
                this.save(userInitiated);
            },

            onSave(){
                this.save(true);
            },

            onCancelClick(e) {
                const self = this;
                if(!self.isDirty()) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                let okHandler = () => {
                    self.deleteditems.length = 0;
                    self.fetchData();
                };
                self.$dialog.confirm("Cancel Changes", "Are you sure you want to cancel and undo the current recording itemization changes?", okHandler);
            },

            onDeleteContactClick (e) {
                const self = this;
                if (!e || !e.data || self.readOnly) return;

                let contactData = e.data;
                var okHandler = function () {

                    contactData.isDeleted = true;
                    let data = { loanID: self.selectedLoanId, contacts: [contactData]};

                    let promise = self.$api.SettlementStatementContactsApi.save(data).then((result)=>{
                        GlobalEventManager.saveCompleted({success: true});
                        self.mapData(result);
                        self.$toast.success("Contact Deleted");
                        return true;
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error(err.errorMessage);
                        return true;
                    });

                    self.$rqBusy.wait(promise);

                    return promise;

                };
                self.$dialog.confirm("Confirm", `Are you sure you want to remove this ${contactData.roleTypeName} contact?`, okHandler);
            },

            onCardDetailClick(e) {
                if(!e || !e.type || !e.data) return;
                switch(e.type) {
                    case "company":
                        this.navigateToCompany(e.data);
                        break;
                    case "contact":
                        this.navigateToContact(e.data);
                        break;
                }
            },

            showAddCompanyLookup(roleTypeId, roleTypeName, isBuyerRealtor = false) {
                const self = this;
                let handleOk = function(e) {
                    let result = _.get(e, "originalEvent.data", e.data);
                    let options = {
                        loanID: self.selectedLoanId,
                        companyID: result.companyID,
                        contactID: result.contactID,
                        roleTypeID: roleTypeId,
                        isBuyerRealtor: isBuyerRealtor,
                    };
                    let newItem = new ContactCardModel(options);
                    self.addedItems.push(newItem);
                    self.save(false);
                    return true;
                };

                self.showCompanyLookup({ roleTypeId, roleTypeName }, handleOk);
            },

            showCompanyLookup(args, handleOk, handleCancel=()=>true) {
                const self = this;
                self.$dialog.open({
                    title: args.roleTypeName,
                    height:"90%",
                    width:"85%",
                    component: CompanyContactLookup,
                    props: {
                        roleTypeId: args.roleTypeId,
                        selectedCompanyId: args.selectedCompanyId,
                        selectedContactId: args.selectedContactId,
                        targetCompany: args.targetCompany
                    },
                    onOk: handleOk,
                    onCancel: handleCancel,
                    onComponentAction(e) {
                        if(e.originalEvent.name !== "selection") return;
                        return handleOk(e);
                    }
                });
            },


            navigate(contact, { isCompany=false, isContact=false, isBuyerSeller=false }) {
                if(isCompany) this.navigateToCompany(contact);
                if(isContact) this.navigateToContact(contact);
            },

            navigateToCompany(contact) {
                if(!contact || _.parseNumber(contact.companyID, 0) === 0) return;
                this.$router.push({ name:"settlement:contacts:company", params: { companyId: contact.companyID } });
            },

            navigateToContact(contact) {
                if(!contact || _.parseNumber(contact.companyID, 0) === 0 || _.parseNumber(contact.contactID, 0) === 0) return;
                this.$router.push({ name:"settlement:contacts:contact", params: { companyId: contact.companyID, contactId: contact.contactID } });
            },

            listKey(index, tag) { return `rq_data_list_${tag}_${_.uniqueId()}_${index}`; },

            resetFocus() {
                _.invoke(this, "$refs.addBtn.$el.firstElementChild.focus");
            },
            onMapSettlementData(settlementType, onUserInitiated = true) {
                const self = this;
                let updatedItem = SettlementTypeOption.displayLabel(settlementType);
                let selectedLoanId = _.gt(self.defaultLoanId, 0) ? self.defaultLoanId : self.loans[0].loanID;
                // Save before mapping in case anything to save
                let mapPromise = self.$api.SettlementDashboardApi.mapSettlementDataOverride(settlementType, selectedLoanId);
                return self.$rqBusy.wait(mapPromise, { topLevel: true }).then(() =>{
                    self.fetchData();
                    if (onUserInitiated)
                        self.$toast.success(`${updatedItem} updated`);
                }).catch(err => {
                    self.$toast.error(`${updatedItem} failed to update`);
                });
            }
        }
    };
</script>