<template>
    <div class="content-wrapper rq-container">
        <div v-show="configurationErrors.length > 0" class="validation-summary alert alert-danger">
            <h6>Please correct the following configuration errors:</h6>
            <div v-for="(e,index) in configurationErrors" :key="index" class="validation-summary-error">{{e}}</div>
        </div>
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <fieldset class="py-3" :disabled="readOnly">
            <div class="row">
                <div :class="{ 'col col-5 form-required form-group':true, 'has-error': v$.request.lossAdvanceReasonCategoryID.$error }">
                    <label for="drp_lossAdvanceReasonCategoryID">{{moneyMovementType}} Category</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_lossAdvanceReasonCategoryID', id: 'drp_lossAdvanceReasonCategoryID' }"
                        :items="lossAdvanceCategories"
                        :disabled="readOnly"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.request.lossAdvanceReasonCategoryID.$model"
                        :search-enabled="false"
                    />
                </div>
                <div :class="{ 'col col-5 form-required form-group':true, 'has-error': v$.request.lossAdvanceReasonID.$error }">
                    <label for="drp_lossAdvanceReasonID">{{moneyMovementType}} Reason</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_lossAdvanceReasonID', id: 'drp_lossAdvanceReasonID' }"
                        :items="reasonsByCategory"
                        :disabled="disableReason"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.request.lossAdvanceReasonID.$model"
                        :search-enabled="false"
                    />
                </div>
                <div :class="{ 'col col-2 form-group':true }">
                    <label for="txt_lossFile">{{moneyMovementType}} File#</label>
                    <input
                        automation_id="txt_name"
                        class="form-control"
                        type="text"
                        disabled
                        v-model="autoConsolidatedPayee.gfNo"
                        />
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-12 form-required form-group':true, 'has-error': v$.request.requestorComment.$error }">
                    <label for="txt_comment">Comment</label>
                    <textarea
                        rows="3"
                        automation_id="txt_comment"
                        v-model="v$.request.requestorComment.$model"
                        type="text"
                        class="form-control"
                        id="txt_comment"
                        placeholder="Comment"
                        maxlength="1000">
                    </textarea>
                    <rq-validation-feedback
                        :messages="{ 'Comment is required': v$.request.requestorComment.required.$invalid }"
                    />
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-6 form-required form-group':true, 'has-error': v$.request.debtorRolesID.$error }">
                    <label for="drp_debtorRolesID">Debtor</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_debtorRolesID' }"
                        :items="debtorRoles"
                        display-expr="name"
                        value-expr="id"
                        :search-enabled="false"
                        :show-clear-button="true"
                        :disabled="readOnly"
                        v-model="v$.request.debtorRolesID.$model">
                    </dx-select-box>
                    <rq-validation-feedback
                        :messages="{ 'Debtor is required': v$.request.requestorComment.required.$invalid }"
                    />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_check_amount">Check Amount</label>
                    <rq-input-number
                        automation_id="txt_check_amount"
                        ref="txt_check_amount"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        :disabled="true"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="check.amount"
                    />
                </div>
                <div :class="{ 'col col-3 form-group form-required':true, 'has-error':v$.request.requestedAmount.$error }">
                    <rq-action-form-group
                            :label="amountLabel"
                            labelFor="txt_requested_amount"
                            required
                            :hasError="v$.request.requestedAmount.$error"
                            :show-action="showRecoverable"
                        >
                        <template #default>
                            <rq-input-number
                                automation_id="txt_requested_amount"
                                ref="txt_requested_amount"
                                formatType="money"
                                defaultValue="0"
                                :minValue="0"
                                :disabled="readOnly"
                                decimals="2"
                                input-group
                                no-prefix
                                prependIcon="fas fa-dollar-sign"
                                v-model="v$.request.requestedAmount.$model"
                                />
                                <rq-validation-feedback
                                    :messages="{ 'Amount must be less than or equal to Check Amount': v$.request.requestedAmount.inRange.$invalid }"
                                />
                        </template>
                        <template #action>
                            <b-form-checkbox
                                v-show="showRecoverable"
                                automation_id="chk_isRecoverable"
                                id="chk_isRecoverable"
                                v-model="request.isRecoverable"
                                :disabled="readOnly">
                                Recoverable?
                            </b-form-checkbox>
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { MoneyMovementApprovalDto }  from "../models";
    import { ContactCardDto } from '@order-entry/contacts/models';
    import { SystemLookupItem } from "@/shared/models/models";
    import { AutoConsolidatePayeeDto } from '@config/checkWriting/models';
    import { MoneyMovementType } from '@config/enums';
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: "CheckWritingLossAdvanceForm",
        props: {
            check: {type: Object, required: true},
            moneyMovementTypeID: {type: Number, required: true},
            lookupData: {type: Object, required: true},
        },
        components: {  },

        inject: ["dialogId"],

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                accountBalance: 0,
                autoConsolidatedPayee: new AutoConsolidatePayeeDto(),
                configurationErrors: [],
                errorMessage: "",
                lossAdvanceCategories: [],
                lossAdvanceReasons: [],
                debtorRoles: [],
                orderContacts: [],
                request: new MoneyMovementApprovalDto(),
                isBuyerSeller: false
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "usStatesLookup"
            ]),
            ...mapState({
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                order: state => state.orders.order,
            }),
            amountLabel() { return `${this.moneyMovementType} Amount`; },
            disableReason() { return _.isNullOrEmpty(this.request.lossAdvanceReasonCategoryID); },
            reasonsByCategory() { return _.filter(this.lossAdvanceReasons, ["additionalIdentity", this.request.lossAdvanceReasonCategoryID]); },
            readOnly() { return this.isFileLocked || _.size(this.configurationErrors) > 0; },
            showRecoverable() { return _.isEqual(this.moneyMovementTypeID, MoneyMovementType.Loss); },
            moneyMovementType() { return MoneyMovementType.displayValue(this.moneyMovementTypeID); },
        },
        created(){
            this.init();
        },
        validations() {
            return {
                request: {
                    debtorRolesID: {
                        required
                    },
                    lossAdvanceReasonCategoryID: {
                        required
                    },
                    lossAdvanceReasonID: {
                        required
                    },
                    requestorComment: {
                        required
                    },
                    requestedAmount: {
                        required,
                        inRange(val) {
                            return _.inRange(_.parseNumber(val, 0), .01, _.parseNumber(this.check.amount, 0)+.01);
                        },
                    },
                }
            };
        },
        methods: {
            init() {
                this.accountBalance = _.getNumber(this, "lookupData.accountBalance", 0);
                this.autoConsolidatedPayee = new AutoConsolidatePayeeDto(_.get(this, "lookupData.autoConsolidatedPayee"));
                this.request = new MoneyMovementApprovalDto({moneyMovementTypeID: this.moneyMovementTypeID, entityID: this.check.checksID, requestedAmount: this.check.amount, autoConsolidatePayeeID: this.autoConsolidatedPayee.autoConsolidatePayeeID});
                this.lossAdvanceCategories = _.map(_.get(this, "lookupData.reasonCategories"), i => new SystemLookupItem(i));
                this.lossAdvanceReasons = _.map(_.get(this, "lookupData.reasons"), i => new SystemLookupItem(i));
                this.orderContacts = _.map(_.get(this, "lookupData.contacts"), i => new ContactCardDto(i));
                this.debtorRoles = _.map(_.get(this, "orderContacts"), i => this.orderContactToDebtor(i));
                this.validateConfiguration();
                this.$emit(`${this.readOnly ? "disable" : "enable"}-ok`);
            },

            orderContactToDebtor(contact) {
                if (_.isEqual(contact.role, "Buyer") && _.isNullOrEmpty(contact.name)) return new SystemLookupItem({id: contact.rolesID, name: 'Buyer #1'});
                if (_.isEqual(contact.role, "Seller") && _.isNullOrEmpty(contact.name)) return new SystemLookupItem({id: contact.rolesID, name: 'Seller #1'});
                if (_.parseNumber(contact.contactID, 0) > 0) return new SystemLookupItem({id: contact.rolesID, name: `${contact.name} - ${contact.firstName} ${contact.lastName}`});
                return new SystemLookupItem({id: contact.rolesID, name: contact.name});
            },

            save(){
                const self = this;
                if(self.readOnly) return Promise.resolve({});
                self.errorMessage = "";
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }
                let data = {request: self.request, ordersID: self.order.ordersID, regionID: self.order.regionID};
                let apiPromise = self.$api.CheckWritingApi.saveLossAdvance(data, null);
                return self.$rqBusy.wait(apiPromise);
            },

            validateConfiguration(){
                const self = this;
                self.configurationErrors.length = 0;
                if(_.size(self.lossAdvanceCategories) == 0) self.configurationErrors.push(`There are no ${self.moneyMovementType} Categories defined in configuration for this Region/Type.`);
                if(_.size(self.lossAdvanceReasons) == 0) self.configurationErrors.push(`There are no ${self.moneyMovementType} Reasons defined in configuration for this Region/Type.`);
                if(_.lt(self.autoConsolidatedPayee.autoConsolidatePayeeID, 0)) self.configurationErrors.push(`There are no Auto-Consolidated Payees configured for ${self.moneyMovementType} in this Region.`);
            },

        }
    }
</script>
