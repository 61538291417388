<template>
    <div class="content-wrapper">
        <rq-page-section :title="viewTitle" headerSize="lg" borderless header-only>
            <template v-if="addEnabled && userAccess.full" #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_loan" variant="theme" v-focus :disabled="readOnly" @click="onAddLoan">Add Loan</b-btn>
                    </li>
                    <li v-if="multipleLoans" class="nav-item">
                        <b-btn automation_id="btn_reorder_loans" variant="theme" :disabled="readOnly" @click="onReorderLoans"> Reorder Loans </b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="gridName"
            :actions="selectionActions"
            :data-source="loans"
            :config="gridConfig"
            :export-file-name="exportFileName"
            @select="onSelectAction"
            @delete="onDeleteAction"
            @rowDoubleClick="onGridRowDoubleClick"
            hide-search
        />
    </div>
</template>

<script>
    import { mapState, mapGetters} from "vuex";
    import { ORDER_ACTIONS } from "@/store/actions";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { LoanModel } from "../models";
    import { SortableList } from "@/shared/components/rq";

    export default {
        name: "OrderLoans",

        props: {
            showDelete: { type: Boolean, default: true }
        },

        data () {
            return {
                isLoaded: false
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                fileNumber: state => _.get(state, "orderSummary.fileNumber", ""),
                loans: state => _.map(state.orders.loans, l => new LoanModel(l))
            }),
            ...mapGetters(["currentSession"]),
            viewTitle() { return _.get(this.$route, "meta.label", null); },
            addEnabled() { return _.parseBool(_.get(this.$route, "meta.addEnabled", null)); },
            reorderEnabled() { return _.parseBool(_.get(this.$route, "meta.reorderEnabled", null)); },
            deleteEnabled() { return _.parseBool(_.get(this.$route, "meta.deleteEnabled", null)); },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", {}); },
            multipleLoans() { return this.loans.length > 1; },
            exportFileName() { return `${this.fileNumber}_loans` },
            gridStorageKey() {
                return `${this.gridName}::${_.get(this, "$route.name", "")}::${_.get(this, "currentSession.username", "")}`
            },
            localSecurity() { return this.securitySettings.findValues(["AllowDeleteLoan"]); },
            allowLoanDelete() { return this.localSecurity.AllowDeleteLoan && !this.readOnly; },
            selectionActions() {
                const self = this;
                let actions = [{
                    name: "view-edit",
                    text: self.readOnly ? "View" : "Edit",
                    eventName: "select",
                    requireSelection: true
                }];
                if(self.showDelete)
                    actions.push({
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        allowMultiSelection: true,
                        disabled: () => self.allowLoanDelete ? false : "Access Restricted"
                    });
                return actions;
            }
        },

        created () {
            const self = this;
            self.gridName = "tbl_loans";
            self.loadGridConfig();
        },

        methods: {

            loadGridConfig() {
                const self = this;
                self.gridConfig = {
                    keyExpr: "loanID",
                    storageKey: self.gridStorageKey,
                    columns: [
                        { dataField: "loanOrder", dataType: "number", caption: "No.", width:50, sortIndex: 0, sortOrder: "asc" },
                        { dataField: "lender", dataType: "string" },
                        { dataField: "amount", dataType: "number", caption: "Loan Amount", alignment: "right", cellTemplate: DxGridUtils.moneyCellTemplate },
                        { dataField: "loanPolicyLiability", dataType: "number", caption:"Loan Policy Liability Override", alignment: "right", cellTemplate: DxGridUtils.moneyCellTemplate },
                        { dataField: "number", dataType: "string", caption: "Loan Number" },
                        { dataField: "termLengthDisplay", dataType: "number", caption:"Term" }
                    ],
                };
            },

            onGridRowDoubleClick(e) {
                this.$emit("loan-selected", e);
            },

            onSelectAction(e) {
                this.$emit("loan-selected", e);
            },

            onDeleteAction(e) {
                if(!e?.data || !this.allowLoanDelete || this.readOnly) return;
                let loans = [];
                for(let x = 0; x < e.data.length; x++) {
                    loans.push(e.data[x].loanID);
                }
                this.$emit("delete-loan", loans);
            },

            onAddLoan () {
                const self = this;
                self.$emit("add-loan");
            },

            onReorderLoans() {
                const self = this;
                if(self.readOnly) return;
                let items = _.map(self.loans, l => {
                    return {
                        id: l.loanID,
                        description: _.joinParts([l.lender, l.number, self.formatMoney(l.amount)], " - "),
                        ordinal: l.loanOrder
                    };
                });

                let orderId = self.orderId;
                self.$dialog.open({
                    title: "Reorder Loans",
                    width: 600,
                    adaptive: true,
                    component: SortableList,
                    props: { orderId, items },
                    onOk(e) {
                        let changes = e.component.getChanges();
                        if(_.isEmpty(changes)) return true;
                        let request = { orderId, changes };
                        let apiPromise = self.$store.dispatch(ORDER_ACTIONS.REORDER_LOANS, request);
                        self.$rqBusy.wait(apiPromise)
                            .then(() => {
                                self.gridInstance.refresh();
                                self.$toast.success("Loan order updated.");
                            });
                        return true;
                    }
                });
            },

            formatMoney(v) {
                return accounting.formatMoney(
                    _.parseNumber(v, 0),
                    { format: { pos:"%s%v", neg:"(%s%v)", zero:"--" } }
                );
            }
        }
    };
</script>