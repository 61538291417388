    import AddEditTask from "@workflow/components/AddEditTask";
    import WorkflowTaskPackageDialog from "@configuration/workflowDepartments/components/WorkflowTaskPackageDialog";
    import { mapState } from "vuex";
    import AssignToTasks from "@workflow/components/AssignToTasks";
    import ChangeDateForm from "@workflow/components/ChangeDateForm";
    import { OrderWorkflowTaskNote, WorkflowTask } from './models';
    import EmailForm from "@/shared/components/rq/dialogs/EmailForm";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {

        data() {
            return {
                searchTerm: "",
                selectionActions: [],
                showValidationBanner: false,
                validationBannerMessage: ""
            };
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
            }),
            localSecurity(){
                return this.securitySettings.findValues(["AllowAddWorkflowTask"]);
            }
        },
        methods: {

            messageBanner(message="") {
                this.validationBannerMessage = message;
                this.showValidationBanner = !_.isEmpty(message);
            },

            handleAddTask () {
                const self = this;
                if (!self.localSecurity.AllowAddWorkflowTask) {
                    self.$toast.error("Permission denied. Contact your administrator.");
                } else {
                    self.$dialog.open({
                        title: "Create Task",
                        height: "auto",
                        width: 780,
                        adaptive: true,
                        component: AddEditTask,
                        onOk (e) {
                            return e.component
                                .save()
                                .then(canContinue => {
                                    if (canContinue) self.refresh();
                                    return canContinue;
                                });
                        }
                    });
                }
            },

            handleAddTaskPackage () {
                const self = this;
                if (!self.localSecurity.AllowAddWorkflowTask) {
                    self.$toast.error("Permission denied. Contact your administrator.");
                } else {
                    self.$dialog.open({
                        title: "Add Task Package",
                        height: "96.5%",
                        minHeight: 700,
                        width: "70%",
                        adaptive:false,
                        scrollable:false,
                        component: WorkflowTaskPackageDialog,
                        onOk (e) {
                            const selectedPackages =  e.component.selectItems(e);
                            let apiPromise = self.$api.OrderWorkflowApi.saveTaskPackages(selectedPackages, self.orderId);
                            self.$rqBusy.wait(apiPromise)
                                .then(() => self.refresh())
                                .catch(error => {
                                    self.$toast.error("Failed to add Task Package.");
                                    console.error(error);
                                });
                            return selectedPackages;
                        }
                    });
                }
            },

            async handleDeleteTasks (tasks) {
                const self = this;
                let title = (tasks.length > 1) ? "Delete" : `${tasks[0].taskName}: Delete`;
                let prompt = `Delete selected task${tasks.length === 1 ? "" : "s"}?`;
                let ok = () => {
                    let taskIDs = _.map(tasks, t => t.orderWorkflowTaskID);
                    let promise = self.$api.OrderWorkflowApi.deleteTasks(taskIDs);
                    self.$rqBusy.wait(promise).then(() => self.refresh());
                };

                self.$dialog.confirm(title, prompt, ok);
            },

            handleCopyTasks (tasks) {
                const self = this;
                let title = "Copy Tasks";
                let prompt = `Copy selected task${tasks.length === 1 ? "" : "s"}?`;
                let ok = () => {
                    let taskIDs = _.map(tasks, t => t.orderWorkflowTaskID);
                    let apiPromise = self.$api.OrderWorkflowApi.copyTasks(taskIDs);
                    self.$rqBusy.wait(apiPromise)
                        .then(() => self.refresh())
                        .catch(error => {
                            self.$toast.error("Failed to copy selected tasks.");
                            console.error(error);
                        });
                };
                self.$dialog.confirm(title, prompt, ok);
            },

            handleEditTask (task, isOrderLocked) {
                const self = this;
                let dialogTitle = task.taskName || "(No Task Name)";
                self.$dialog.open({
                    title: `Edit Task: ${dialogTitle}`,
                    height: "96.5%",
                    minHeight: 700,
                    width: "90%",
                    component: AddEditTask,
                    props: {
                        orderWorkflowTask: task,
                        isOrderLocked
                    },
                    onOk (e) {
                        self.gridInstance.clearSelection();
                        return e.component.save()
                            .then(canContinue => {
                                if (canContinue) {
                                    // TODO: kpaca 1/29/2019 - find a better way to communicate with sibling
                                    self.refresh();
                                }
                                return canContinue;
                            });
                    },
                    onCancel(e) {
                        if(!e.component.taskNotesChanged) return;
                        self.refresh();
                    }
                });
            },
            handleEditNote (task) {
                const self = this;
                let note = new OrderWorkflowTaskNote({orderWorkflowTaskID: task.orderWorkflowTaskID, usersID: self.user.usersID, author: self.user.displayName, noteDate: DateTimeHelper.now()});
                self.$dialog.promptInput({
                    title: `Task ${task.taskName}: Note`,
                    label: "Note",
                    value: note.notes,
                    multiline: true,
                    onOk (e) {
                        note.notes = e.value;
                        return self.createNote(note);
                    }
                });
            },

            createNote(note){
                const self = this;
                let apiPromise = self.$api.OrderWorkflowApi.saveNote(note);
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.$toast.success("Workflow Task Note was created.");
                        self.refresh();
                        return true;
                    }).catch(err => {
                        self.$toast.error("An issue occurred while saving note");
                        console.error(err);
                        return err;
                    });
            },

            handleAssignTasks (tasks) {
                const self = this;

                if(_.some(tasks, t => _.parseBool(t.notApplicableYN))) {
                    self.messageBanner("Please select 1 or more Tasks that are not N/A");
                    return;
                }

                let taskIDs = _.map(tasks, t => t.orderWorkflowTaskID);
                let orderId = _.reduceMatchingProp(tasks, "ordersID");
                let departmentId = _.reduceMatchingProp(tasks, "workflowDepartmentID");
                let userId = _.reduceMatchingProp(tasks, "workflowDepartmentUserID");
                let rolesId = _.reduceMatchingProp(tasks, "rolesID");

                let title = "Assign Department/User";

                self.$dialog.open({
                    title,
                    height: "auto",
                    width: 500,
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    component: AssignToTasks,
                    props: { taskIDs, orderId, departmentId, userId, rolesId },
                    onCancel () {
                        self.gridInstance.clearSelection();
                    },
                    onOk (e) {
                        return e.component.submitAssignments()
                            .then(canContinue => {
                                if (canContinue){
                                    self.refresh();
                                    self.$toast.success("Task assignment(s) successful.");
                                }
                                return canContinue;
                            });
                    }
                });
            },

            handleAssignTasksToMe(tasks) {
                const self = this;

                if(_.some(tasks, t => _.parseBool(t.notApplicableYN))) {
                    self.messageBanner("Please select 1 or more Tasks that are not N/A");
                    return;
                }

                let orderWorkflowTaskIDs = _.map(tasks, t => t.orderWorkflowTaskID);
                let promise = self.$api.OrderWorkflowApi.assignTasksToMe({ orderWorkflowTaskIDs });

                return self.$rqBusy.wait(promise)
                    .then(result => {
                        self.refresh();
                        if (result.errorCode == 1)
                            self.messageBanner("Invalid Assignment - You are not a user of one or more of these departments. Please assign task(s) to a user within the department");

                        return true;
                    })
                    .catch(errorInfo => {
                        self.$toast.error("Failed to assign task(s).");
                        console.error(errorInfo);
                    });
            },

            async handleMarkTasksComplete (tasks) {
                const self = this;

                if (self.readOnly) return;

                if (_.every(tasks, t => t.isComplete)) {
                    self.messageBanner('Please select 1 or more <span class="font-italic">Not Completed</span> tasks to Mark Complete');
                    return;
                }

                let title = "Mark Complete";
                let prompt = "Mark selected incomplete tasks complete?";
                let cancel = () => {
                    self.gridInstance.clearSelection();
                };
                let ok = () => {
                    let incompleteTasks = _.filter(tasks, t => !t.isComplete);
                    let taskIDs = _.map(incompleteTasks, t => t.orderWorkflowTaskID);
                    let promise = self.$api.OrderWorkflowApi.setComplete(taskIDs)
                        .then(() => self.refresh());
                    self.$rqBusy.wait(promise);
                };
                self.$dialog.confirm(title, prompt, ok, cancel);
            },

            async handleUpdateDueDate (tasks) {
                const self = this;
                let title = "Change Due Date";
                let prompt = "Start Date and Due Date should be according to the Workflow Task configuration. Do you wish to override?";
                let itemTypeName = "Due Date";
                self.showChangeDate(tasks, title, itemTypeName, prompt);
            },

            async handleUpdateStartDate (tasks) {
                const self = this;
                let title = "Change Start Date";
                let prompt = "Start Date and Due Date should be according to the Workflow Task configuration. Do you wish to override?";
                let itemTypeName = "Start Date";
                self.showChangeDate(tasks, title, itemTypeName, prompt);
            },

            handleToggleTasksNonApplicable (tasks) {
                const self = this;
                let title = "Toggle N/A";
                let prompt = "Toggle N/A for selected tasks?";
                let cancel = () => {
                    self.gridInstance.clearSelection();
                };
                let ok = () => {
                    let taskIDs = _.map(tasks, t => t.orderWorkflowTaskID);
                    let apiPromise = self.$api.OrderWorkflowApi.toggleNotApplicable(taskIDs);
                    self.$rqBusy.wait(apiPromise)
                        .then(() => self.refresh())
                        .catch(error => {
                            self.$toast.error("Failed to toggle N/A on task.");
                            console.error(error);
                        });
                };
                self.$dialog.confirm(title, prompt, ok, cancel);
            },

            async handleReopenTasks (tasks) {
                const self = this;

                if (_.every(tasks, t => !t.isComplete)) {
                    self.messageBanner(`Please select 1 or more <span class="font-italic">Completed</span> tasks to ReOpen`);
                    return;
                }

                let title = "Reopen Tasks";
                let prompt = "Reopen selected completed tasks?";
                let cancel = () => {
                    self.gridInstance.clearSelection();
                };
                let ok = () => {
                    let completedTasks = _.filter(tasks, t => t.isComplete);
                    let taskIDs = _.map(completedTasks, t => t.orderWorkflowTaskID);
                    let apiPromise = self.$api.OrderWorkflowApi.reopen(taskIDs);
                    self.$rqBusy.wait(apiPromise)
                        .then(() => self.refresh())
                        .catch(error => {
                            self.$toast.error("Failed to reopen selected tasks.");
                            console.error(error);
                        });
                };

                self.$dialog.confirm(title, prompt, ok, cancel);
            },

            async handleEmailTask(task, includeOrderRecipients=true){
                const self = this;
                let fileNumber = task.fileNumber || task.gfNo;
                let subject = `${fileNumber}: ${task.taskName}`;
                let promise = self.$api.OrderWorkflowApi.getTaskEmailRecipients(task.orderWorkflowTaskID);
                self.$rqBusy.wait(promise).then(
                    results => {
                        let recipients = results.length > 0 ? results.join(";") : results[0];
                        self.showEmail({ title: "Email Task", subject: subject, toList: recipients, includeOrderRecipients: includeOrderRecipients });
                        self.gridInstance.clearSelection();
                    }
                ).catch(err => {
                    self.$toast.error(err.message);
                });
            },

            showEmail(args) {
                const self = this;
                self.$dialog.open({
                    title: _.get(args, "modalTitle", "Send Email"),
                    width: "90%",
                    height: "90%",
                    okTitle: "Send",
                    component: EmailForm,
                    props: {
                        subject: args.subject,
                        to: args.toList,
                        includeOrderRecipients: args.includeOrderRecipients
                    },
                    onOk(e) {
                        return e.component.onOk();
                    }
                });
            },

            showChangeDate(items, title, itemTypeName, prompt){
                const self = this;
                if (self.readOnly) return;
                self.$dialog.open({
                    title,
                    height: "auto",
                    width: 500,
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    component: ChangeDateForm,
                    props: { items, itemTypeName},
                    onCancel () {
                        self.gridInstance.clearSelection();
                    },
                    onOk (e) {
                        if(!e.component.isValid()) return false;
                        let ok = () => e.component.save()
                            .then(() => {
                                self.refresh();
                                return true;
                            });
                        self.$dialog.confirm("Override Confirmation ", prompt, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
                    }
                });
            }

        }
    }