<template>
    <rq-page-section id="sched-a" title="Schedule A" borderless>
        <!-- Effective Date/Time, Commitment Number, Issue Date/Time, Mortgage Amount, Insured Amount -->
        <fieldset :disabled="readOnly" v-if="validCommitmentData">
            <div class="row">
                <div class="col col-12 col-md-6">
                    <SplitDateTime
                        field-descriptor="effective"
                        :default-time="defaultTime"
                        :disabled="readOnly"
                        v-model="effectiveDate"
                    />
                </div>
                <div class="col col-12 col-md-6">
                    <SplitDateTime
                        field-descriptor="issue"
                        :disabled="readOnly"
                        v-model="issueDate"
                    />
                </div>
                <div class="col-12 col-lg-3 form-group">
                    <label for="commitment_number">Commitment Number</label>
                    <input id="commitment_number"
                        automation_id="txt_commitment_number"
                        type="text"
                        placeholder="Commitment Number"
                        class="form-control"
                        v-model="commitmentNumber"
                    >
                </div>
                <div class="col-12 col-lg-3 form-group">
                    <label for="owner_insured_amount">Owner Insured Amount</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rq-input-number
                            id="owner_insured_amount"
                            defaultValue="0"
                            decimals="2"
                            v-model="ownerInsuredAmount"
                            @change="onOwnerInsuredAmountChanged"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-3 form-group">
                    <label for="mortgage_insured_amount">Mortgage Insured Amount</label>
                    <div class="input-group">
                        <span class="input-group-text">$</span>
                        <rq-input-number
                            id="mortgage_insured_amount"
                            defaultValue="0"
                            decimals="2"
                            v-model="mortgageInsuredAmount"
                            @change="onMortgageInsuredAmountChanged"
                        />
                    </div>
                </div>
            </div>
        </fieldset>

        <!-- Sections based on Service Type -->
        <div class="row rtf-row" v-for="section in sections" :value="section.key" :key="section.key">
            <div class="col-12">
                <div class="h5" v-if="section.showHeader === true">{{section.text}}</div>
                <div class="row">
                    <div v-for="field in section.fields" :key="field.key" :class="field.class" :disabled="readOnly">
                        <!-- Binder Begin -->
                        <div class="form-group" v-if="field.key === 'binder_date'">
                            <SplitDateTime
                                field-descriptor="binder"
                                :disabled="readOnly"
                                v-model="binderDate"
                            />
                        </div>
                        <div class="form-group" v-else-if="field.key === 'binder_number'">
                            <label for="binder_number">Binder Number</label>
                            <input
                                id="txt_binder_number"
                                automation_id="txt_binder_number"
                                type="text"
                                placeholder="Binder Number"
                                class="form-control"
                                v-model="binderNumber">
                        </div>
                        <!-- Binder End -->
                        <title-production-rtf-field
                            v-else
                            :loan-id="loanId"
                            :field="field"
                            :original-data="originalData"
                            v-model:commitment-data="commitmentDataValue"
                        />
                    </div>
                </div>
            </div>
        </div>
    </rq-page-section>
</template>

<script>
    import { computed, } from "vue";
    import { mapState } from "vuex";
    import TitleProductionRtfField from "../TitleProductionRtfField.vue";
    import SplitDateTime from "../SplitDateTime.vue";
    import { Services } from "../../config/";
    import { CommitmentDataDto } from "../../models";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { DateTime } from "luxon";

    export default {
        components: { TitleProductionRtfField, SplitDateTime },

        props: {
            loanId: { type: Number, default: 0 },
            commitmentData: { type: Object, default: () => new CommitmentDataDto() },
            originalData: { type: Object, default: () => new CommitmentDataDto() }
        },

        emits: ["update:commitmentData"],

        setup(props, { emit }) {
            const commitmentDataValue = computed({
                get() { return props.commitmentData; },
                set(val) { emit("update:commitmentData", val); }
            });
            const getComputedField = fieldName => computed({
                get() { return commitmentDataValue.value.header?.[fieldName]; },
                set(val) { commitmentDataValue.value.header[fieldName] = val; }
            });
            const getComputedDetailField = fieldName => computed({
                get() { return commitmentDataValue.value.detail?.[fieldName]; },
                set(val) { commitmentDataValue.value.detail[fieldName] = val; }
            });

            const effectiveDate = getComputedField("effectiveDate");
            const issueDate = getComputedField("issueDate");
            const binderDate = getComputedField("binderDate");
            const commitmentNumber = getComputedField("commitmentNumber");
            const binderNumber = getComputedField("binderNumber");
            const ownerInsuredAmount = getComputedField("ownerInsuredAmount");
            const mortgageInsuredAmount = getComputedDetailField("mortgageInsuredAmount");

            return {
                effectiveDate,
                issueDate,
                binderDate,
                commitmentNumber,
                binderNumber,
                commitmentDataValue,
                ownerInsuredAmount,
                mortgageInsuredAmount
            };
        },

        watch: {
            "effectiveDate"(newVal, oldVal) {
                const self = this;
                if(_.isNil(newVal) || !_.isNil(this.issueDate)) return;
                //because of DST, we need to make sure we keep the same timezone offset as effective date when setting issue date
                let effectiveDt = DateTime.fromISO(newVal, { setZone: true });
                let nowDt = DateTime.now();
                let combined = DateTimeHelper.joinDateParts({
                    date: nowDt.startOf("day").toISO(),
                    time: nowDt.toISO(),
                    timezone: effectiveDt.zoneName,
                    format: "yyyy-MM-dd'T'HH:mm:ss.SSS0000ZZ"
                });
                self.issueDate = combined;
            }
        },

        computed: {
            ...mapState({
                order: state => state.orders.order,
                serviceId: state => state.orders.order.service,
                loans: state => state.orders.loans || [],
                readOnly: state => _.parseBool(state.isPageReadOnly),
                systemDefaults: state => state.system.systemDefaults,
            }),
            commitmentLoan() { return _.find(this.loans, l => l.loanID === this.loanId); },
            salesPrice() {return _.get(this.order, "ownerPolicyLiability", null) || _.get(this.order, "salesPrice", null);},
            service() {return _.find(Services, o => { return o.id === _.parseNumber(this.serviceId, -1); });},
            sections() {return _.isNil(this.service) || !this.validCommitmentData ? [] : this.service.sections;},
            loanPolicyLiability() {
                if (this.commitmentLoan.loanPolicyLiability) {
                    return this.commitmentLoan.loanPolicyLiability;
                }
                return this.commitmentLoan.amount;
            },
            editingFieldKey() { return _.get(this, "editingFieldInfo.key", ""); },
            validCommitmentData () {
                return (_.get(this.commitmentDataValue, "header.commitmentPolicyHeaderID", 0) > 0);
            },
            defaultTime(){
                const self = this;
                let defaultEffectiveTime = self.systemDefaults.defaultEffectiveTime;
                if(!_.isNil(defaultEffectiveTime)){
                    return DateTimeHelper.joinDateParts({ date: DateTime.now().toISO(), time: defaultEffectiveTime });
                }
                return DateTime.now().toISO();
            },
            // ...dateTimeSplit({ fields: { path: 'commitmentData.header.effectiveDate', date: 'calcEffectiveDate', time: 'calcEffectiveTime', defaultTimePath: 'defaultTime' } }),
            // ...dateTimeSplit({ fields: { path: 'commitmentData.header.issueDate', date: 'calcIssueDate', time: 'calcIssueTime' } }),
            // ...dateTimeSplit({ fields: { path: 'commitmentData.header.binderDate', date: 'calcBinderDate', time: 'calcBinderTime' } }),
        },
        methods: {
            onOwnerInsuredAmountChanged(e){
                const self = this;
                self.$dialog.confirm(
                        "Confirm",
                        "This will change the liability override. Are you sure you want to change the liability override?",
                        () => { },
                        () => { self.ownerInsuredAmount = self.originalData.header.ownerInsuredAmount; },
                        { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onMortgageInsuredAmountChanged(e){
                const self = this;
                self.$dialog.confirm(
                        "Confirm",
                        "This will change the liability override. Are you sure you want to change the liability override?",
                        () => { },
                        () => { self.mortgageInsuredAmount = self.originalData.detail.mortgageInsuredAmount; },
                        { cancelTitle: 'No', okTitle: 'Yes'});
            }
        }
    };
</script>