<template>
    <div class="content-wrapper settlement">
        <order-summary/>
        <rq-scroll-container v-if="useScrollLayout">
            <rq-banner  icon="fas fa-exclamation-triangle" :message="error" variant="error" :visible="hasError" dismissable sticky/>
            <router-view ref="routeComponent"></router-view>
        </rq-scroll-container>
        <template v-else>
            <rq-banner icon="fas fa-exclamation-triangle" :message="error" variant="error" :visible="hasError" dismissable sticky/>
            <router-view ref="routeComponent"></router-view>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import OrderSummary from "../shared/components/OrderSummary";
    import { SETTLEMENT_TYPE, SettlementTypeOption } from "@settlement/models";
    import { GlobalEventManager } from "@/app.events";

    export default {
        name: "SettlementMain",

        components: { OrderSummary },

        computed: {
            ...mapState({
                error: state => state.error,
                currentSsType: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                selectedLoanId: state => _.getNumber(state, "settlement.selectedLoanId", 0)
            }),
            hasError() { return !_.isEmpty(this.error); },
            useScrollLayout() { return _.getBool(this, "$route.meta.useScrollLayout", true); },
        },

        created() {
            GlobalEventManager.onFireAction(this, this.handleAction);
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },

        methods: {
            handleAction(e) {
                if(e.key !== "mapSS" || this.$route.name === "settlement:settlement-dashboard") return;
                this.onMapSettlementData();
            },

            onMapSettlementData() {
                const self = this;
                let updatedItem = SettlementTypeOption.displayValue(self.currentSsType);
                return self.$rq.saveCurrentRoute()
                    .then(() => {
                        let mapPromise = self.$api.LoanCostChargesApi.mapSettlementData(self.currentSsType, self.selectedLoanId);
                        return self.$rqBusy.wait(mapPromise)
                            .then(() =>{
                                _.invoke(self, "$refs.routeComponent.fetchData");
                                self.$events.$emit("map-ss-complete", { success: true });
                                self.$toast.success(`${updatedItem} updated`);
                            })
                            .catch(err => {
                                self.$toast.error(`${updatedItem} failed to update`);
                                self.$events.$emit("map-ss-complete", { success: false });
                                console.error(err);
                            });
                    });
            },
        }
    };
</script>