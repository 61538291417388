<template>
    <div class="rq-container content-wrapper">
        <rq-banner
            variant="error"
            :title= "errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner"
            dismissable
        />
        <order-selection
            ref="orderSelectionComponent"
            automation_id="dg_order_selection"
            :search-term="searchText"
            :visible-columns="visibleColumns"
            :order-filter="orderFilter"
            :status-filter="statusFilter"
            v-model:selected-order="selectedOrder"
            @double-click="onRowDoubleClick"
            fixed-header
        />
    </div>
</template>

<script>
    import OrderSelection from "./OrderSelection";

    export default {
        name: "SelectExistingOrder",

        components: { OrderSelection },

        props: {
            searchTerm: { type: String, default: null },
            visibleColumns: { type: Array, default: () => [] },
            orderFilter: { type: Array, default: () => [] },
            statusFilter: { type: String, default: null },
        },

        data () {
            const self = this;
            return {
                searchText: self.searchTerm,
                selectedOrder: null,
                errorMessage: ""
            };
        },

        computed: {
            showErrorBanner() { return !_.isEmpty(this.errorMessage); }
        },

        watch: {
            selectedOrder: {
                handler(newValue) {
                    if(_.isEmpty(newValue))
                        this.$emit("disable-ok");
                    else
                        this.$emit("enable-ok");
                },
                deep: true,
                immediate: true
            }
        },

        methods: {

            onRowDoubleClick (e) {
                if(_.isEmpty(this.selectedOrder)) return;
                this.$emit("ok");
            },

            dialogResult(){ return this.selectedOrder; },

            updateDimensions () {
                _.invoke(this, "$refs.orderSelectionComponent.updateDimensions");
            }
        }

    };
</script>