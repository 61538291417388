import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { SETTLEMENT_TYPE, SettlementTypeOption } from '@settlement/models';
import { useSecuritySettings } from "@/shared/composables/";
import { useRqBusy, useRqToast } from "@/shared/plugins/composables/";
import LoanCostChargesApi from "@/api/loanCostCharges";

export function useCommonSettlementSecurity() {
    const store = useStore();
    const { isSysAdmin } = useSecuritySettings();
    const allowEditAccountCode = computed(() => !store.state.system.systemDefaults?.onlySystemAdminsCanEditAccountingCodes || isSysAdmin.value);
    return {
        allowEditAccountCode
    };
}

//WIP - supplemental loan values composable; see usage below
export function useLoanValues() {
    const store = useStore();
    const selectedLoanId = ref(null);
    const loans = computed(() => store.state.orders.loans || []);
    const defaultLoanId = computed(() => store.getters.defaultLoanId);

    watch(loans, newValue => {
        if(_.isEmpty(newValue)) return;

        let selectedId = _.parseNumber(selectedLoanId.value, 0);
        let defaultId = _.parseNumber(defaultLoanId.value, 0);

        if(selectedId === 0) {
            if(defaultId > 0)
                selectedLoanId.value = defaultLoanId.value;
            return;
        }

        if(selectedId === defaultId) return;

        let selectedIndex = _.findIndex(newValue, l => l.loanID === selectedId);
        let defaultIndex = _.findIndex(newValue, l => l.loanID === defaultId);

        if(selectedIndex >= 0) return;

        selectedLoanId.value = defaultIndex < 0
            ? newValue[0]?.loanID
            : defaultLoanId.value;
    });

    return {
        selectedLoanId,
        defaultLoanId,
        loans
    };
}

//WIP - eventual replacementt for BaseSettlementMixin.cs
export function useBaseSettlementHelpers({ fetchData = () => Promise.resolve(true), saveRoute = () => Promise.resolve(true) }) {
    const store = useStore();
    const { waitFor } = useRqBusy();
    const { toastSuccess, toastError } = useRqToast();

    const {
        selectedLoanId,
        defaultLoanId,
        loans
    } = useLoanValues();

    const validationErrors = ref([]);
    const showErrorBanner = ref(false);

    const orderId = computed(() => store.state.orders.orderId);
    const order = computed(() => store.state.orders.order);
    const payoffs = computed(() => store.state.settlement.payoffs);
    const readOnly = computed(() => store.state.isPageReadOnly);
    const orderSummary = computed(() => store.state.orders.orderSummary || {});
    const selectedView = computed(() => _.parseNumber(orderSummary.value?.settlementStatementType, SETTLEMENT_TYPE.CDF));
    const isWithOutSeller = computed(() => _.getBool(orderSummary.value?.isWithOutSeller));
    const includeAltaValue = computed(() => _.getBool(orderSummary.value?.includeAlta));
    const cdfSections = computed(() => store.state.orders.orderLookups.cdfSections);
    const systemDefaults = computed(() => store.state.system.systemDefaults);
    const authSettings = computed(() => store.state.authentication.session.settings);

    const lookupHelpers = computed(() => store.getters.lookupHelpers);
    const lookupItems = computed(() => store.getters.lookupItems);

    const SettlementTypes = SETTLEMENT_TYPE;
    const includeAlta = computed(() => isCdf.value && includeAltaValue.value);
    const isCdf = computed(() => selectedView.value === SETTLEMENT_TYPE.CDF);
    const isHud1974 = computed(() => selectedView.value === SETTLEMENT_TYPE.HUD_1974);
    const isHud2010 = computed(() => selectedView.value === SETTLEMENT_TYPE.HUD_2010);
    const isHud = computed(() => isHud1974.value || isHud2010.value);
    const hasErrors = computed(() => !_.isEmpty(validationErrors.value));

    async function mapSettlementStatement(type=null, userInitiated=false, useLoanId=0) {
        let ssType = _.isNumber(type) ? type : selectedView.value;
        let ssTypeName = SettlementTypeOption.displayValue(selectedView.value);
        let loanId = useLoanId > 0 ? useLoanId : self.selectedLoanId;

        await saveRoute();

        let mapPromise = LoanCostChargesApi.mapSettlementData(ssType, loanId);
        try {
            await waitFor(mapPromise);
            if (userInitiated) {
                fetchData();
                toastSuccess(`${ssTypeName} updated`);
            }
            return { success: true };

        }
        catch(err) {
            toastError(`${ssTypeName} failed to update`);
            console.error(err);
            return { success: false };
        }
    }

    const parseFixed = (v, d) => accounting.parse(accounting.toFixed(v, d));

    return {
        selectedLoanId,
        validationErrors,
        showErrorBanner,
        orderId,
        order,
        loans,
        payoffs,
        readOnly,
        orderSummary,
        selectedView,
        isWithOutSeller,
        includeAlta,
        cdfSections,
        systemDefaults,
        authSettings,
        lookupHelpers,
        lookupItems,
        defaultLoanId,
        SettlementTypes,
        isCdf,
        isHud1974,
        isHud2010,
        isHud,
        hasErrors,
        mapSettlementStatement,
        parseFixed
    };
}