<template>
    <div class="view-container aggregate-adjustment">
        <rq-banner
            :message="alertMessages"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="hasAlerts"
            dismissable
        />
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showValidationBanner && v$.$error"
            dismissable
        />
        <rq-page-section title="Aggregate Adjustment" headerSize="lg">
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button automation_id="btn_clear_form"
                            variant="theme"
                            v-focus
                            @click="onClearForm"
                            :disabled="readOnly">Clear Form
                        </b-button>
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="drp_loans">Loans</label>
                    <dx-select-box
                        :input-attr="$utils.idAttrs('drp_loans')"
                        :items="loanOptions"
                        :search-enabled="false"
                        value-expr="id"
                        display-expr="name"
                        :disabled="readOnly"
                        v-model="selectedLoanId"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group form-required" :class="{'has-error' : v$.aggregateDataResult.aggMainItem.firstPaymentDate.$error}">
                    <label for="dtp_first_payment_date">First Payment Date</label>
                    <rqdx-date-box
                        ref="fpDateBox"
                        id="dtp_first_payment_date"
                        v-model="v$.aggregateDataResult.aggMainItem.firstPaymentDate.$model"
                        :disabled="readOnly"
                    />
                    <rq-validation-feedback>First Payment Date is required</rq-validation-feedback>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_monthly_escrow_payment">Monthly Payment Amount</label>
                    <rqInputNumber id="txt_monthly_escrow_payment"
                        automation_id="txt_monthly_escrow_payment"
                        type="text"
                        defaultValue="0"
                        decimals="2"
                        cssClass="form-control"
                        :disabled="true"
                        prefix="$"
                        v-model="aggregateDataResult.aggMainItem.monthlyEscrowPayment">
                    </rqInputNumber>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group mt-2">
                    <b-form-checkbox automation_id="chk_include_mip_in_cushion" :disabled="readOnly || disableInput"
                        v-model="aggregateDataResult.aggMainItem.include1002TotalInCushionAmt">Include MIP in Cushion
                    </b-form-checkbox>
                    <b-form-checkbox automation_id="chk_include_mip_in_cushion" :disabled="readOnly || disableInput"
                        v-model="aggregateDataResult.aggMainItem.calcSection1000MonthsReserve">Calculate Months in Reserve
                    </b-form-checkbox>
                    <b-form-checkbox automation_id="chk_include_mip_in_cushion" :disabled="readOnly || disableInput"
                        v-model="aggregateDataResult.aggMainItem.applyAmountToSeller">Apply Aggregate Amount to Seller
                    </b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">&nbsp;</div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="drp_number_of_months_cushion">Number of Months Cushion</label>
                    <rqInputNumber id="txt_number_months_cushion"
                        automation_id="txt_number_months_cushion"
                        defaultValue="0"
                        decimals="0"
                        cssClass="form-control"
                        :disabled="readOnly || disableInput"
                        v-model="aggregateDataResult.aggMainItem.numberMonthsCushion">
                    </rqInputNumber>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_cushion_amount">Cushion Amount</label>
                    <rqInputNumber id="txt_cushion_amount"
                        automation_id="txt_cushion_amount"
                        defaultValue="0"
                        decimals="2"
                        cssClass="form-control"
                        prefix="$"
                        :disabled="true"
                        v-model="aggregateDataResult.aggMainItem.cushionAmount">
                    </rqInputNumber>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Disbursement Schedule" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-expand-collapse-all
                            :all-expanded="allItemsExpanded"
                            :all-collapsed="allItemsCollapsed"
                            expand-all-tooltip="Expand All Disbursements"
                            collapse-all-tooltip="Collapse All Disbursements"
                            @expand-all="onExpandAll(true)"
                            @collapse-all="onExpandAll(false)"
                        />
                    </li>
                </ul>
            </template>
            <div class="row" :class="classAttr">
                <div class="col-6">
                    <div class="row mb-4 disbursement-schedule-header">
                        <div class="col-6">Description</div>
                        <div class="col-6">Amount</div>
                    </div>
                    <agg-escrow-item
                        v-for="eItem in aggregateDataResult.aggMainItem.escrowItems"
                        :key="eItem.aggEscrowItemID"
                        :disabled="disableInput"
                        v-bind:escrowItem="eItem"
                        @docalculate="calculateOption"
                    />
                </div>
                <div class="col-3">
                    <div class="sum-total">
                        <div class="row pt-3">
                            <div class="col sum-total-title">
                                <span>Aggregate Adjustment</span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col sum-total-value">
                                <span>{{formatMoney(sumTotal)}}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { AggregateDataResult } from "@settlement/models";
    import AggEscrowItem from './components/AggEscrowItem';
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        mixins: [BaseSettlementMixin],
        components: {
            AggEscrowItem
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                aggregateDataResult: {},
                isDirty: false,
                expandAllSections: false,
                showValidationBanner: false,
                alerts: []
            }
        },
        computed: {
            ...mapState({
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            hasAlerts() { return this.alerts.length > 0; },
            alertMessages() { return _.map(this.alerts,'message').join(', '); },
            sumTotal() {
                return _.sumBy(this.aggregateDataResult.aggMainItem.escrowItems, function(o) { return o.sum; });
            },
            classAttr() {
                let theme = _.get(this, "$route.meta.theme", "default") || "default";
                return `disbursement-schedule theme-${theme}`;
            },
            disableInput() {
                return _.isNil(this.aggregateDataResult.aggMainItem.firstPaymentDate);
            },
            allItemsExpanded() {return _.every(this.aggregateDataResult.aggMainItem.escrowItems, l => l.sectionExpanded);},
            allItemsCollapsed() { return _.every(this.aggregateDataResult.aggMainItem.escrowItems, l => !l.sectionExpanded); },
            loanOptions() { return this.lookupHelpers.getLoanOptions(); }
        },
        watch:{
            selectedLoanId(newValue, oldValue){
                if (newValue === oldValue) return;
                const self = this;
                self.fetchData();
            },
            "aggregateDataResult.aggMainItem.firstPaymentDate"(newValue, oldValue) {
                if(newValue === oldValue) return;
                let invalid = _.isNil(newValue);
                _.forEach(this.aggregateDataResult.aggMainItem.escrowItems, i => {
                    i.disabled = invalid;
                    _.forEach(i.escrowItemDetails, d => {
                        d.disabled = invalid;
                    });
                });
            },
        },
        created() {
            const self = this;
            self.baseInit();
            if (!self.readOnly) {
                // Note: Global event for Save, Cancel is initialized by BaseSettlementMixin
                GlobalEventManager.onClear(self, self.onClearClick);
            }

            self.aggregateDataResult = new AggregateDataResult();
        },
        mounted() {
            this.fetchData();
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        validations() {
            return {
                aggregateDataResult: {
                    aggMainItem: {
                        firstPaymentDate: { required }
                    }
                }
            };
        },

        methods: {
            onClearForm() {
                this.doClear();
            },
            onClearClick(e) {
                this.doClear();
            },
            onSave(e) {
                this.doCalculate(false, _.getBool(e, "userInitiated"));
            },
            onCancel(e) {
                this.fetchData();
            },
            onCursorFocus() {
                const self = this;
                self.$nextTick().then( () => {
                    self.$refs.fpDateBox.focus();
                });
            },
            calculateOption() {
                this.isDirty = true;
            },
            fetchData() {
                const self = this;
                return self.getAggregateData();
            },
            doClear(){
                const self = this;
                self.showValidationBanner = false;
                // Clear all values
                self.aggregateDataResult.aggMainItem.firstPaymentDate = null;
                self.aggregateDataResult.aggMainItem.monthlyEscrowPayment = 0;
                self.aggregateDataResult.aggMainItem.numberMonthsCushion = 0;
                self.aggregateDataResult.aggMainItem.cushionAmount = 0;
                self.aggregateDataResult.aggMainItem.include1002TotalInCushionAmt = false;
                self.aggregateDataResult.aggMainItem.calcSection1000MonthsReserve = false;
                self.aggregateDataResult.aggMainItem.applyAmountToSeller = false;
                self.aggregateDataResult.aggMainItem.clear = true;

                _.each(self.aggregateDataResult.aggMainItem.escrowItems, escrowItem => {
                        escrowItem.sum = 0;
                        _.each(escrowItem.escrowItemDetails, escrowItemDetail => {
                                escrowItemDetail.due = false;
                            });
                    });
                self.doCalculate(true);
            },
            getAggregateData() {
                const self = this;
                self.showValidationBanner = false;
                let aggPromise = self.$api.AggegateAdjustmentsApi.getAggregateData(self.selectedLoanId);
                return self.$rqBusy.wait(aggPromise)
                    .then((result) => {
                        self.refreshData(result);
                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    })
                    .catch(error => {
                        GlobalEventManager.saveCompleted({success: false});
                        console.error(error);
                    });
            },
            doCalculate(clearOption, userInitiated=false){
                const self = this;
                let changes = self.getAuditChanges(self.originalAggregateData.aggMainItem, self.aggregateDataResult.aggMainItem);

                if (!self.isDirty && changes.length === 0) {
                    if(userInitiated) self.$toast.info({ message: "No changes detected" });
                    GlobalEventManager.saveCompleted({success: true});
                    return true;
                }

                self.showValidationBanner = userInitiated;
                self.v$.$touch();

                if (self.v$.$error) {
                    GlobalEventManager.saveCompleted({ success: false });
                    return false;
                }

                self.aggregateDataResult.alerts = self.alerts = []; // reset alerts
                let savePromise = self.$api.AggegateAdjustmentsApi.calculate(self.aggregateDataResult);
                return self.$rqBusy.wait(savePromise)
                    .then((result) => {
                        self.refreshData(result);
                        self.isDirty = false;

                        if (!self.hasAlerts)
                            self.$toast.success("Changes saved successfully");

                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    })
                    .catch(error => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Aggregate Adjustments");
                        console.error(error);
                    });
            },
            refreshData(data) {
                this.aggregateDataResult = new AggregateDataResult(data);
                this.originalAggregateData = new AggregateDataResult(data);
                this.alerts = data.alerts;
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },
            onExpandAll(expandAll){
                _.updateAll(this.aggregateDataResult.aggMainItem.escrowItems, "sectionExpanded", expandAll);
            }
        }
    }
</script>

