<template>
    <div class="policy-content">
        <rq-page-section :title="viewTitle" headerSize="lg">
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-if="hasPolicyNumber"
                        v-rq-tooltip.hover.top :title="(!localSecurity.CanVoidPolicies || readOnly) ? 'Access Restricted' : ''">
                        <b-btn
                            variant="theme"
                            :disabled="!localSecurity.CanVoidPolicies || readOnly"
                            @click="voidPolicy">Void Policy
                        </b-btn>
                    </li>
                    <li class="nav-item" v-if="remitDateLocked"
                        v-rq-tooltip.hover.top :title="(!localSecurity.CanUnlockPolicyRemitDates || readOnly) ? 'Access Restricted' : ''">
                        <b-btn
                            variant="theme"
                            :disabled="!localSecurity.CanUnlockPolicyRemitDates || readOnly || manualUnlockValue"
                            @click="unlockRemitDate">Unlock
                        </b-btn>
                    </li>
                </ul>
                <slot name="loan-selection"></slot>
            </template>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="policy_number">Policy Number</label>
                    <policy-number-input
                        ref="policyNumber"
                        :loan-id="loanId"
                        :policy-type="typeOfPoliciesType"
                        :disabled="readOnly || remitDateLocked"
                        v-model="policyNumber"
                        @assign="onAssignPolicyNumber"
                        :init="initPolicyNumber"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <SplitDateTime
                        field-descriptor="policy"
                        :show-date-action="false"
                        :date-action-automation-id="copyLinkAutoId"
                        :date-action-label="copyLinkText"
                        :disabled="(readOnly || remitDateLocked) && !manualUnlockValue"
                        v-model="policyDate"
                        @action-click="onCopyPolicyDate"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label>Remit Date</label>
                    <rqdx-date-box
                        id="dtp_owner_remit_date"
                        :disabled="(readOnly || remitDateLocked) && !manualUnlockValue"
                        v-model="remitDate"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label>Submit Date</label>
                    <rqdx-date-box
                        id="dtp_owner_submit_date"
                         :disabled="(readOnly || remitDateLocked) && !manualUnlockValue"
                        v-model="submitDate"
                    />
                </div>
                <title-production-rtf-field
                    v-if="showTitleVested"
                    class="col-12 col-sm-12 col-md-6"
                    :loan-id="loanId"
                    :field="titleVestedField"
                    :original-data="originalCommitmentData"
                    v-model:commitment-data="commitmentData"
                />
                <div class="col-12 col-sm-12 col-md-3 form-group">
                    <label>Form Type</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_form_type', automation_id:'drp_form_type' }"
                            :items="policyFormTypes"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            :show-clear-button="true"
                            v-model="policyFormTypeID"
                            :disabled="readOnly"
                        />
                </div>
            </div>
            <slot></slot>
        </rq-page-section>

        <clauseManagement
            ref="clauseManagement"
            title="Clause Management"
            :policy-type="policyType"
            v-model:commitment-data="commitmentData"
            v-model:has-changes="hasClauseChanges"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { COMMITMENT_MUTATIONS } from "@/store/mutations";
    import { COMMITMENT_ACTIONS } from "@/store/actions";
    import { TypeOfPolicies } from "@config/enums";
    import { CommitmentDataDto } from "../models";
    import { Fields } from "../config/";
    import { PolicyType } from "../enums";
    import { OrderServiceType } from "@/shared/models/enums";
    import { ClauseManagement, PolicyNumberInput, TitleProductionRtfField } from "../components/";
    import PremiumsMain from '@settlement/components/premiums/PremiumsMain';
    import SplitDateTime from "../components/SplitDateTime.vue";

    export default {
        name: "Policy",
        props: {
            loanId: { type: Number, default: 0 },
            policyType: { type: Number, default: null, required: true }
        },
        components: { ClauseManagement, PolicyNumberInput, TitleProductionRtfField, SplitDateTime },
        emits: ["policy-voided"],
        data() {
            return {
                viewTitle: '',
                titleVestedField: Fields.TITLE_HOLDER_VESTED,
                commitmentData: new CommitmentDataDto(),
                originalCommitmentData: new CommitmentDataDto(),
                manualUnlock: false,
                manualUnlockValue: false,
                selectedLoanId: null,
                hasClauseChanges: false,
                initPolicyNumber: false,
                showGenerateEzJacket: false
            }
        },

        computed: {
            ...mapState({
                commitment: state => state.orders.commitment || {},
                orderId: state => state.orders.order.ordersID,
                orderSummary: state => state.orders.orderSummary || {},
                serviceId: state => _.parseNumber(state.orders.order.service, -1),
                readOnly: state => _.parseBool(state.isPageReadOnly),
                loans: state => state.orders.loans || [],
                systemDefaults: state => state.system.systemDefaults,
                contentChangePending: state => state.orders.contentChangePending,
                orderBranch: state => state.orders.branch || {},
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            isLoanPolicy() { return this.policyType === PolicyType.Loan; },
            policyTypeName() { return this.isLoanPolicy ? "Loan" : "Owner"; },
            typeOfPoliciesType() { return this.isLoanPolicy ? TypeOfPolicies.Mortgage : TypeOfPolicies.Owner },
            policyFormTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.POLICY_FORM_TYPES); },
            localSecurity() { return this.securitySettings.findValues([ "CanVoidPolicies", "CanUnlockPolicyRemitDates"]); },
            premiumsSecurity() { return { PremiumsAccessible: this.securitySettings.hasAccess("Premiums") }; },
            copyLinkAutoId() { return this.isLoanPolicy ? "btn_copy_to_owner_policy" : "btn_copy_to_loan_policy"; },
            copyLinkText() { return this.isLoanPolicy ? "Copy to Owner Policy" : "Copy From Loan Policy(s)"; },
            showTitleVested () {
                return (!this.isLoanPolicy && (_.includes(OrderServiceType.simultaneousEligibleTypes, this.serviceId)
                                                                || _.includes(OrderServiceType.ownerSpecificTypes, this.serviceId)))
                                        || (this.isLoanPolicy && _.includes(OrderServiceType.loanSpecificTypes, this.serviceId) && this.serviceId !== OrderServiceType.AltaConstructionLoan) },
            showCopyPolicyDate() { return this.isLoanPolicy ? this.showCopyToOwnerLink : this.showCopyFromLoanLink; },
            showCopyToOwnerLink () {
                return (!this.remitDateLocked &&
                    this.serviceId !== OrderServiceType.ALTAMortgage &&
                    this.serviceId !== OrderServiceType.TXMortgage);
            },
            showCopyFromLoanLink () {
                return (!this.remitDateLocked &&
                    this.serviceId !== OrderServiceType.AltaOwner &&
                    this.serviceId !== OrderServiceType.TxOwnerResident &&
                    this.serviceId !== OrderServiceType.TxOwnerNonResident);
            },
            hasPolicyNumber() { return !_.isEmpty(this.originalPolicyNumber); },
            lockRemitDate () { return this.getCommitmentValue("lockRemitDate", "bool"); },
            remitDateLocked() { return !this.manualUnlock && this.lockRemitDate; },
            originalPolicyNumber() { return this.getCommitmentValue("policyNumber", null, true); },
            policyNumber: {
                get() { return this.getCommitmentValue("policyNumber"); },
                set(val) { this.setCommitmentValue("policyNumber", val); }
            },
            policyDate: {
                get() { return this.getCommitmentValue("policyDate"); },
                set(val) { this.setCommitmentValue("policyDate", val); }
            },
            remitDate: {
                get() { return this.getCommitmentValue("remitDate"); },
                set(val) { this.setCommitmentValue("remitDate", val); }
            },
            submitDate: {
                get() { return this.getCommitmentValue("submitDate"); },
                set(val) { this.setCommitmentValue("submitDate", val); }
            },
            policyFormTypeID: {
                get() { return this.getCommitmentValue("policyFormTypeID"); },
                set(val) { this.setCommitmentValue("policyFormTypeID", val); }
            },
        },

        watch: {
            commitment: {
                handler(newValue, oldValue) {
                    this.initPolicyNumber = false;
                    this.setCommitmentData();
                },
                deep: true
            },
            loanId: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.initPolicyNumber = false;
                    this.fetchData();
                },
                immediate: true
            }
        },

        created () {
            const self = this;
            if (self.readOnly) return;
            GlobalEventManager.onSave(self, self.onSave);
            GlobalEventManager.onCancel(self, self.onCancel);
            self.$nextTick(() => {
                //registering in next tick in case unregistered in previous route's beforeUnmount (passing function as argument in "off" doesn't work)
                self.$events.on("apply-template-success", e => {
                    _.invoke(self, "$refs.clauseManagement.reset");
                    self.setCommitmentData();
                });
            });
        },

        beforeUnmount () {
            this.$store.dispatch(COMMITMENT_ACTIONS.CLEAR_STATE);
            this.$events.off("apply-template-success");
            GlobalEventManager.unregister(this);
        },

        // beforeRouteLeave(to, from, next) {
        //     const self = this;
        //     next();
        // },

        methods: {
            fetchData () {
                const self = this;
                self.viewTitle = `${self.policyTypeName} Policy`;
                if(self.isLoanPolicy) {
                    let loan = _.find(self.loans, {loanID: self.loanId});
                    if(!_.isNil(loan)) {
                        self.viewTitle = `${self.viewTitle}: Loan #${loan.loanOrder}`;
                        if(!_.isEmpty(loan.lender))  self.viewTitle = `${self.viewTitle}: ${loan.lender}`;
                    }
                }

                let storePromise = this.$store.dispatch(COMMITMENT_ACTIONS.GET_COMMITMENT_DATA, self.loanId);
                self.$rqBusy.wait(storePromise)
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: "Failed to retrieve Commitment Data" });
                    });
            },

            onCopyPolicyDate(e) {
                this.commitmentData.header.ownerPolicyDate = this.commitmentData.detail.mortgagePolicyDate;
            },

            onSave(args) {
                const self = this;
                if (self.$rqBusy.isBusy()) return;
                if(!self.contentChangePending) {
                    self.save(args);
                    return;
                }
                let unwatchChangePending = self.$watch("contentChangePending", function(newValue) {
                    if(_.parseBool(newValue)) return;
                    unwatchChangePending();
                    self.save(args);
                });
            },

            onCancel() {
                const self = this;
                if (self.$rqBusy.isBusy()) return;
                let changes = self.getAuditChanges(self.originalCommitmentData, self.commitmentData);
                if(_.isEmpty(changes) && !self.hasClauseChanges) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                self.$dialog.confirm("Confirm", "Discard changes and reload data?", () => {
                    self.setCommitmentData();
                    self.hasClauseChanges = false;
                });
            },

            onAssignPolicyNumber(policyNumber) {
                const self = this;
                self.setCommitmentValue("policyNumber", policyNumber);
                self.originalCommitmentData.header.ownerPolicyNumber = policyNumber;
                self.$store.commit(COMMITMENT_MUTATIONS.SET_COMMITMENT_DATA, _.clone(self.commitmentData));
                self.$refs.policyNumber.onSave(self.policyNumber);
                self.$toast.success(`${self.policyTypeName} Policy Number set to ${policyNumber}`);
            },

            setCommitmentData(commitment) {
                let commitmentData = commitment || this.commitment;
                if (_.isNil(commitmentData)) return;
                this.commitmentData = new CommitmentDataDto(commitmentData);
                this.originalCommitmentData = new CommitmentDataDto(commitmentData);
                this.initPolicyNumber = true;
            },

            save(e) {
                const self = this;
                if (self.readOnly) return;
                if (self.$rqBusy.isBusy()) return;
                self.$emit("update:validationErrors", []);
                let changes = self.getAuditChanges(self.originalCommitmentData, self.commitmentData);
                let assignedLanguages = self.hasClauseChanges ? self.getClauseData() : [];
                if (changes.length === 0 && _.isEmpty(assignedLanguages)) {
                    if (e && e.userInitiated) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }

                self.commitmentData.syncDateTimeOffsetValues();

                let request = { data: { ...self.commitmentData, assignedLanguages }, changes };
                let success = true;
                let storePromise = self.$store.dispatch(COMMITMENT_ACTIONS.UPDATE_COMMITMENT_DATA, { request, updateClauses: self.hasClauseChanges });
                return self.$rqBusy.wait(storePromise)
                    .then(commitment => {
                        self.hasClauseChanges = false;
                        self.$toast.success(`${self.policyTypeName} Policy Data Saved Successfully`);
                        self.$refs.policyNumber.onSave(self.policyNumber);
                        self.$refs.clauseManagement.onSave();
                        return success;
                    })
                    .catch(error => {
                        self.$emit("update:validationErrors", [error.errorMessage]);
                        self.$toast.error(`Failed to save ${self.policyTypeName} Policy: ${error.errorMessage}`);
                        success = false;
                        return success;
                    })
                    // .then((commitSaveSuccess) => {
                    //     if (commitSaveSuccess && _.getBool(self.orderBranch, 'promptForAuditLogVersion', false)) {
                    //         // Must disable the wait because we can't have the loading spinner over the prompt.
                    //         self.$rqBusy.endWait(true);
                    //         return self.auditLog_versionPrompt();
                    //     }
                    //     return Promise.resolve();
                    // })
                    // .catch((err) => {})
                    // .then(() => success)
                    .finally(() => {
                        GlobalEventManager.saveCompleted({ success });
                    });
            },

            voidPolicy() {
                const self = this;
                if (self.readOnly || !self.localSecurity.CanVoidPolicies) return;
                let ok = () => {
                    self.$emit("update:validationErrors", []);
                    let changes = self.getAuditChanges(self.originalCommitmentData, self.commitmentData);
                    let voidRequest = { data: { commitmentPolicyData: self.commitmentData, policyType: self.policyType }, changes };
                    let storePromise = self.$store.dispatch(COMMITMENT_ACTIONS.VOID_POLICY, voidRequest);
                    self.$rqBusy.wait(storePromise)
                        .then(commitment => {
                            self.setCommitmentData(commitment);
                            self.$refs.policyNumber.onSave(self.policyNumber, true);
                            self.$toast.success(`${self.policyTypeName} Policy Number has been Voided`);
                            self.$emit("policy-voided");
                        })
                        .catch(error => {
                            console.error(error);
                            self.$toast.error(`Failed to Void ${self.policyTypeName} Policy`);
                        });
                };
                self.$dialog.confirm("Confirm", "This will void the current policy jacket. Once the jacket has been voided, it cannot be undone.", ok);
            },

            unlockRemitDate() {
                const self = this;
                if (!self.localSecurity.CanUnlockPolicyRemitDates || self.readOnly || self.manualUnlockValue) return;
                self.manualUnlockValue = !self.manualUnlock;
            },

            getClauseData() {
                return _.invoke(this, "$refs.clauseManagement.getListData", null) || [];
            },

            getCommitmentValue(name, type=null, original=false) {
                let expr = this.getCommitmentValueExpr(name, original);
                if(_.isEmpty(expr)) return null;
                switch(type) {
                    case "bool": return _.getBool(this, expr);
                    case "number": return _.getNumber(this, expr, null);
                    default: return _.get(this, expr, null);
                }
            },

            setCommitmentValue(name, val, original=false) {
                let expr = this.getCommitmentValueExpr(name, original);
                if(_.isEmpty(expr)) return null;
                _.set(this, expr, val);
            },

            getCommitmentValueExpr(name, original) {
                let dataExpr = original ? "originalCommitmentData" : "commitmentData";
                let objExpr = this.isLoanPolicy ? "detail" : "header";
                let valueExpr = this.isLoanPolicy
                    ? this.getLoanPolicyValueExpr(name)
                    : this.getOwnerPolicyValueExpr(name);
                return `${dataExpr}.${objExpr}.${valueExpr}`;
            },

            getOwnerPolicyValueExpr(name, dataExpr) {
                switch(name) {
                    case "lockRemitDate": return "ownerRemitDateLock";
                    case "policyNumber": return "ownerPolicyNumber";
                    case "policyDate": return "ownerPolicyDate";
                    case "remitDate": return "ownerRemitDate";
                    case "submitDate": return "ownerSubmitDate";
                    case "policyFormTypeID": return "policyFormTypeID";
                }
                return "";
            },

            getLoanPolicyValueExpr(name, obj="commitmentData") {
                switch(name) {
                    case "lockRemitDate": return "remitDateLock";
                    case "policyNumber": return "mortgagePolicyNumber";
                    case "policyDate": return "mortgagePolicyDate";
                    case "remitDate": return "remitDate";
                    case "submitDate": return "submitDate";
                    case "policyFormTypeID": return "policyFormTypeID";
                }
                return "";
            },

            onShowPremiums(){
                const self = this;

                let okHandler = function (e) {
                    return e.component.save(true).then(() => {
                        self.fetchData();
                        return true;
                    });
                };

                self.$dialog.open({
                    title: "Premium Rates",
                    width: "95%",
                    minWidth: 1200,
                    height: "95%",
                    minHeight: 700,
                    component: PremiumsMain,
                    props: { noTitle: true, showHeaderCalculate: true },
                    onOk: okHandler
                });
            }

        }
    };
</script>
