<template>
    <div class="content-wrapper">
        <div class="row">
            <div :class="{'col':true,  'col-sm-6': settings.isSimultaneousOrLoan }" v-show="settings.isSimultaneousOrOwner">
                <rq-page-section  title="Owner's Policy" headerSize="sm" borderless>
                    <div class="row">
                        <div class="col">Strike # 1</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO1"
                                v-model="localCommitment.header.strikeO1"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 2</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO2"
                                v-model="localCommitment.header.strikeO2"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 3</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO3"
                                v-model="localCommitment.header.strikeO3"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 4</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO4"
                                v-model="localCommitment.header.strikeO4"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 5</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO5"
                                v-model="localCommitment.header.strikeO5"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 6</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO6"
                                v-model="localCommitment.header.strikeO6"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 7</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO7"
                                v-model="localCommitment.header.strikeO7"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 8</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO8"
                                v-model="localCommitment.header.strikeO8"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 9</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="owner_policy_strikeO1"
                                v-model="localCommitment.header.strikeO9"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div :class="{'col':true,  'col-sm-6': settings.isSimultaneousOrOwner }" v-show="settings.isSimultaneousOrLoan">
                <rq-page-section  title="Loan Policy" headerSize="sm" borderless>
                    <div class="row">
                        <div class="col">Strike # 1</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee1"
                                v-model="localCommitment.detail.strikeMortgagee1"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 2</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee2"
                                v-model="localCommitment.detail.strikeMortgagee2"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 3</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee3"
                                v-model="localCommitment.detail.strikeMortgagee3"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 4</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee4"
                                v-model="localCommitment.detail.strikeMortgagee4"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 5</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee5"
                                v-model="localCommitment.detail.strikeMortgagee5"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 6</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee6"
                                v-model="localCommitment.detail.strikeMortgagee6"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 7</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee7"
                                v-model="localCommitment.detail.strikeMortgagee7"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 8</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee8"
                                v-model="localCommitment.detail.strikeMortgagee8"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">Strike # 9</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="loan_policy_strikeMortgagee9"
                                v-model="localCommitment.detail.strikeMortgagee9"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
        </div>
    </div>
</template>
<script>
import { GlobalEventManager } from "@/app.events";
import { COMMITMENT_ACTIONS } from "@/store/actions";
import { CommitmentDataDto } from '../../models';
export default {
    props:{
        commitmentData: { type: Object, default: new CommitmentDataDto() },
        settings: { type: Object, default: () => {} }
    },
    data() {
        return {
            switchOn: "Yes",
            switchOff: "No",
            localCommitment: _.cloneDeep(this.commitmentData)
        }
    },
    methods: {
        save(e) {
            const self = this;
            let changes = self.getAuditChanges(self.commitmentData, self.localCommitment);
            if (changes.length === 0) {
                self.$toast.info("No changes detected");
                GlobalEventManager.saveCompleted({ success: true });
                return Promise.resolve(true);
            }

            let request = { data: { ...self.localCommitment }, changes };
            let success = true;
            let storePromise = self.$store.dispatch(COMMITMENT_ACTIONS.UPDATE_COMMITMENT_DATA, { request });
            return self.$rqBusy.wait(storePromise)
                .then(commitment => {
                    self.$toast.success(`ALTA Policy Options Saved Successfully`);
                    return success;
                })
                .catch(error => {
                    self.$toast.error(`Failed to save ALTA Policy Options: ${error.errorMessage}`);
                    success = false;
                    return success;
                })
                .finally(() => {
                    GlobalEventManager.saveCompleted({ success });
                });
        }
    }
}
</script>