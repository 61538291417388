<template>
    <div class="content-wrapper">
        <order-summary collapsible />
        <check-writing></check-writing>
    </div>
</template>
<script>
    import OrderSummary from "@file-shared/components/OrderSummary";
    import CheckWriting from "./views/CheckWriting";
    export default {
        name: "CheckWritingMain",
        components: { OrderSummary, CheckWriting },
    }
</script>