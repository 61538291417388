<template>
    <div :class="{'search-view super-search':true, 'search-dialog':dialogContent }">
        <div class="row">
            <div class="col-3 col-filters col-filter-select">
                <div class="filter-header">
                    <h4>Filter by:</h4>
                    <div class="filter-actions">
                        <button automation_id="btn_ss_select_filters" type="button" class="btn btn-theme btn-link" @click="onSelectFields">Select Filters</button>
                        <button automation_id="btn_ss_reset_filters" type="button" class="btn btn-theme btn-link" @click="onReset">Reset Filters</button>
                        <button automation_id="btn_ss_clear_filters" type="button" class="btn btn-theme btn-link" @click="onClear">Clear Filters</button>
                    </div>
                </div>
                <div v-show="!noFilters" class="filter-list" @keyup.enter="onSearch">
                    <rq-scroll-container
                        psClassAttr="filter-scroll-area"
                        :psOptions="psOptions"
                        perfect-scrollbar
                        shadows-enabled>
                        <rq-section-card
                            v-for="subset in searchFieldSet.visibleSubsets"
                            :key="subset.id"
                            :title="subset.label"
                            collapsible
                            v-model:collapse="subset.viewCollapsed"
                            :theme="subset.isRequired ? 'red' : ''">
                            <template #card-actions>
                                <button type="button" class="btn btn-icon btn-card-action" v-rq-tooltip.hover.top="{ title: 'Remove Group' }" @click="subset.hideFields()">
                                    <FontAwesomeIcon icon="fa fa-times-circle" />
                                </button>
                            </template>
                            <search-field-input
                                v-for="field in subset.visibleFields"
                                :key="field.fieldId"
                                :searchField="field"
                                @fieldChanged="onSearchFieldChanged"
                                enable-removal
                                enable-required-indicator
                            />
                        </rq-section-card>
                    </rq-scroll-container>
                </div>
                <rq-no-data v-show="noFilters" text="No filters selected" />
                <button automation_id="btn_ds_search_for_order" type="button" class="btn btn-theme btn-block" @click="onSearch">Search</button>
                <search-filter-selection
                    :searchFieldSet="searchFieldSet"
                    v-model="filterSelectionActive"
                />
            </div>
            <div class="col-9 col-result">
                <rq-banner
                    message="Enter a value in at least one filter input to search for orders."
                    variant="error"
                    :visible="showValidationAlert"
                    dismissable
                />
                <rq-no-data v-show="!searchExecuted" text="Enter filter values to the left and click &quot;Search&quot;." />
                <div v-show="searchExecuted" class="grid-container">
                    <rqdxDataGrid
                        ref="dataGrid"
                        automation_id="dg_superSearch"
                        :dataSource="gridDataSource"
                        :config="gridConfig"
                        @rowDoubleClick="onRowDoubleClick"
                        @selectionChanged="onGridSelectionChanged"
                        @contentReady="onGridContentReady"
                        compact-grid
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // import './search.scss';
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import {
        OrderSearchResultModel,
        SearchFieldSet,
        SearchField,
        SearchLookupsModel
    } from "../models";
    import SearchFieldData from "../data/fieldData";
    import SearchFieldInput from "./SearchFieldInput";
    import SearchFilterSelection from "./SearchFilterSelection";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        components: { SearchFieldInput, SearchFilterSelection },

        props: {
            fileNumberLinksToOrder: { type: Boolean, default: true },
            dialogContent: { type: Boolean, default: false },
            prepopulate: { default: null },
            defaultFields: { default: null }
        },

        data() {
            const self = this;
            return {
                searchFieldSet: new SearchFieldSet(),
                latestOrderId: 0,
                searchLookups: [],
                searchResult: [],
                gridDataSource: [],
                gridConfig: {
                    columns: [
                        {
                            dataField: "gfNo",
                            caption: "File Number",
                            cellTemplate(container, options) {
                                let gfnoValue = options.value || "[NO ID]";
                                if (self.fileNumberLinksToOrder) {
                                    $("<button>")
                                        .addClass("btn btn-link btn-theme p-0")
                                        .on("dxclick", function() {
                                            self.openOrderWithOrderId(
                                                options.data.ordersID
                                            );
                                        })
                                        .append(gfnoValue)
                                        .appendTo(container);
                                } else {
                                    $("<span>")
                                        .append(gfnoValue)
                                        .appendTo(container);
                                }
                            }
                        },
                        { dataField: "status" },
                        { dataField: "duration" },
                        { dataField: "loanOrder" },
                        DxGridUtils.dateColumn({
                            dataField: 'openDate',
                        }),
                        DxGridUtils.dateColumn({
                            dataField: 'closeDate',
                        }),
                        DxGridUtils.dateColumn({
                            dataField: 'userDefinedDate1',
                            caption: 'Cancelled Date',
                        }),
                        { dataField: "address1" },
                        { dataField: "salesPrice" },
                        { dataField: "loanAmount" },
                        { dataField: "buyer" },
                        { dataField: "seller" },
                        { dataField: "legalDescription" },
                        { dataField: "lender" },
                        { dataField: "earnestMoney" },
                        { dataField: "titleCompany" },
                        { dataField: "underwriterCompanyID" },
                        { dataField: "closeAgent" },
                        { dataField: "bankCompanyID" },
                        { dataField: "salesRepresentativeStaffID" },
                        { dataField: "otherCompaniesFileNumber", caption: "Other Co's File Number" },
                        { dataField: "ownerPolicyNumber" },
                        { dataField: "mortgagePolicyNumber" },
                        { dataField: "createdByUser", caption: "Created By" },
                        { dataField: "underwriter" },
                        { dataField: "escrowBank" },
                        { dataField: "salesRep" },
                        { dataField: "listingAgent" },
                        { dataField: "sellingAgent" }
                    ],
                    export: { enabled: true },
                    allowColumnReordering: true,
                    allowColumnResizing: true,
                    columnChooser: { enabled: true },
                    remoteOperations: { paging: false, sorting: true },
                    paging: { enabled: false },
                    // loadPanel: { enabled: false },
                    // pager: { showPageSizeSelector: true, allowedPageSizes: [10, 25, 50], showInfo: true },
                    height: "100%",
                    columnAutoWidth: true,
                    selection: { mode: "single" },
                    hoverStateEnabled: true,
                    // scrolling: { showScrollbar: "always", scrollByThumb:true, useNative: false },
                    showBorders: false,
                    showColumnLines: false
                },
                gridReady: true,
                isValid: false,
                searchExecuted: false,
                showValidationAlert: false,
                filterSelectionActive: false,
                psOptions:  {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: true,
                    wheelPropagation: false,
                    interceptRailY: styles => ({ ...styles, height: 0 })
                }
            };
        },

        computed: {
            ...mapState({
                lookups: state => state.system.lookups
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            noData() { return _.isEmpty(this.searchResult); },
            noFilters() { return _.isEmpty(_.get(this, "searchFieldSet.visibleSubsets", null) || []); },
            noDataText() { return this.searchExecuted ? "No files found." : "Enter filter values to the left and click \"Search\"."; },
            gridInstance() { return this.$refs.dataGrid.getGridInstance(); }
        },

        created() {
            const self = this;
            if (GlobalEventManager && !this.dialogContent) {
                GlobalEventManager.onFireAction(this, this.onGlobalAction);
            }
            self.fetchData();
        },

        mounted() {
            const self = this;
            self.gridDataSource = { load: self.fetchGridData };
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {
            onGlobalAction(e) {
                switch (e.key) {
                    case "search":
                        this.onSearch();
                        break;
                    case "clear":
                        this.onClear();
                        break;
                    case "reset":
                        this.onReset();
                        break;
                    case "fields":
                        this.onSelectFields();
                        break;
                }
            },

            onSearch() {
                this.searchOrders();
            },

            onClear() {
                this.clearFields();
            },

            onReset() {
                const self = this;
                let allVisibleFields = self.searchFieldSet.allVisibleFields;
                let allDefaultFields = self.searchFieldSet.allDefaultFields;

                if (_.every(allVisibleFields, f => f.isDefault) && _.every(allDefaultFields, f => f.visible)) {
                    self.$toast.info("The currently visible fields are the default.");
                    return;
                }

                self.$dialog.confirm(
                    "Reset Fields",
                    "Do you want to reset to the default selected/visible fields?",
                    () => { self.searchFieldSet.resetVisibleToDefault(); }
                );
            },

            onSelectFields() {
                this.filterSelectionActive = !this.filterSelectionActive;
            },

            onGridSelectionChanged(e) {
                this.$emit("selectionChanged", e.selectedRowsData[0]);
            },

            onGridContentReady(e) {},

            onRowDoubleClick(e) {
                this.$emit("rowDoubleClick", e.data);
            },

            searchOrders() {
                const self = this;

                if (self.searchFieldSet.isValid) {
                    self.showValidationAlert = false;
                    if (self.searchFieldSet.allChangedFields.length <= 0) {
                        self.showValidationAlert = true;
                        return;
                    }
                    self.searchExecuted = true;
                    self.gridInstance.refresh();
                } else {
                    self.validationMessages = self.searchFieldSet.validationMessages.slice();
                }
            },

            fetchData() {
                const self = this;
                 let fetchPromise = new Promise((resolve, reject) => {
                //     self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA).then(id => {
                //         self.latestOrderId = id;
                //         self.$api.OrdersApi.getOrderLookups(id)
                //             .then(data => {
                                self.searchLookups = new SearchLookupsModel(self.lookups);
                                self.searchLookups.counties = [];
                                self.searchLookups.states = self.lookupHelpers.getStates();
                                if (self.prepopulate && self.prepopulate.length > 0 && !self.defaultFields) {
                                    SearchFieldData.buildSearchFieldSet(self.searchLookups, self.prepopulate, [])
                                        .then(result => {
                                            self.searchFieldSet = result;
                                            resolve();
                                        });
                                } else {
                                    SearchFieldData.buildSearchFieldSet(self.searchLookups)
                                        .then(result => {
                                            self.searchFieldSet = result;
                                            resolve();
                                        });
                                }
                //             })
                //             .catch(err => {
                //                 console.error("Fetch Search Lookups Failed.", err);
                //                 reject(err);
                //             });
                //     });
                });
                return self.$rqBusy.wait(fetchPromise);
            },

            fetchGridData(loadOptions) {
                const self = this;
                let request = self.getCurrentSearchRequest();
                let skip = parseInt(loadOptions.skip);
                let take = parseInt(loadOptions.take) || 10;

                request.skip = skip || 0;
                request.take = take || 10;

                request.sortBy = [];
                if (loadOptions.sort) {
                    for (let i = 0; i < loadOptions.sort.length; i++) {
                        request.sortBy.push(
                            new SortOption({
                                field: loadOptions.sort[i].selector,
                                direction: loadOptions.sort[i].desc
                                    ? SortOption.SortDirection.Descending
                                    : SortOption.SortDirection.Ascending
                            })
                        );
                    }
                }

                // remove condition to allow retrieving ALL orders
                if (Object.keys(request.parameterObjects).length > 0) {
                    let apiPromise = self.$api.OrdersApi.getOrderSearchResult(request);
                    return self.$rqBusy.wait(apiPromise)
                        .then(response => {
                            self.searchResult = response.results;
                            return { data:response.results, totalCount: response.totalRecords };
                        })
                        .catch(err => {
                            console.error(err);
                            return "An issue occurred while retrieving search result."
                        });
                } else {
                    return Promise.resolve([], { totalCount: 0 });
                }
            },

            getBranches(regionId) {
                const self = this;
                let branchList = self.lookupHelpers.getBranches(regionId);
                self.searchLookups.branches = branchList;
                self.searchFieldSet.updateFieldOptions("BranchId", branchList);
            },

            getCounties(usState) {
                const self = this;
                let countyList = self.lookupHelpers.getCountiesByState(usState);
                self.searchLookups.counties = countyList;
                self.searchFieldSet.updateFieldOptions("CountyId", countyList);
                // geoService.getCountiesByStateAsync(usState).then(countyList => {
                //     self.lookups.counties = countyList;
                //     self.searchFieldSet.updateFieldOptions("CountyId", countyList);
                // });
            },

            getCurrentSearchRequest() {
                const self = this;
                let searchParams = {};
                for (let field of this.searchFieldSet.allChangedFields) {
                    let fieldParams = field.getSearchParameters();
                    _.forEach(fieldParams, function(p) {
                        searchParams[p.key] = p.value;
                    });
                }
                return new SearchRequest({
                    parameterObjects: searchParams,
                    sortBy: [],
                    pageNumber: 1,
                    pageSize: 10,
                    pagingEnabled: false
                });
            },

            onSearchFieldChanged(searchField) {
                const self = this;
                if (searchField.fieldName === "RegionId") {
                    self.getBranches(searchField.fieldValue);
                }
                if (searchField.fieldName === "State") {
                    self.getCounties(searchField.fieldValue);
                }
            },

            openOrderWithOrderId(id) {
                this.$router.push(`/order/${id}/oe/`);
            },

            openOrderReport() {},

            clearFields() {
                const self = this;
                this.searchFieldSet.clearFields();
                this.validationMessages = [];
                this.gridInstance.refresh();
            },

            updateDimensions() {
                if (!this.gridInstance) return;
                this.gridInstance.updateDimensions();
            }
        }
    };
</script>
