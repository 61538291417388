<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"/>
        <rq-page-section borderless>
            <fieldset :disabled="readOnly">
                <!-- <div class="col-12 ps-0 pe-0">
                    <rqValidationSummary :dataSource="allValidationErrors" />
                </div> -->
                <div class="row">
                    <div class="col-6 form-group form-required" :class="{'has-error' : v$.survey.name.$error }">
                        <label for="txt_survey_name" class="form-control-label">Abstract Name</label>
                        <input id="txt_survey_name" automation_id="txt_survey_name" maxlength="160" name="survey" type="text" v-model="v$.survey.name.$model" class="form-control" >
                        <rq-validation-feedback
                            :messages="{
                                'Abstract Name is required. ': v$.survey.name.required.$invalid,
                                'Abstract Name already exists.' : v$.survey.name.isUnique.$invalid
                             }"
                        />
                    </div>
                    <div class="col-6 form-group">
                        <label for="txt_abstract_number" class="form-control-label">Abstract Number</label>
                        <rqInputNumber automation_id="txt_abstract_number" v-model="survey.abstractNumber" formatType="basic" :allowNull="true" decimals="0"></rqInputNumber>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_acres" class="form-control-label">Acres</label>
                        <rqInputNumber automation_id="txt_acres" v-model="survey.acres" formatType="basic" :allowNull="true" decimals="2"></rqInputNumber>
                    </div>
                    <div class="col-6 form-group">
                        <label for="drp_select_counties" class="form-control-label">County</label>
                        <dx-select-box id= "drp_select_counties" :items="counties" display-expr="name" value-expr="id" v-model="survey.countyID" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_patentNumber" class="form-control-label">Patent Number</label>
                        <input id="txt_patentNumber" automation_id="txt_patentNumber" maxlength="20" name="patentNumber" type="text"  v-model="survey.patentNumber" class="form-control" />
                    </div>
                    <div class="col-6 form-group">
                        <label for="dtp_patent_date" class="form-control-label">Patent Date</label>
                        <rqdx-date-box
                            id="dtp_patent_date"
                            v-model="survey.patentDate"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="txt_notes" class="form-control-label">Notes</label>
                        <textarea id="txt_notes" automation_id="txt_notes" maxlength="60" rows="5" name="notes"  v-model="survey.notes" class="form-control"></textarea>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { SurveyDto } from "@/shared/models/models";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";


    export default {
        name: "SurveyForm",
        props: {
            embeddedMode: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            propertyState: { type: String, default: "" },
            surveyId: { type: Number, default: 0 },

        },
        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                currentSurveyId: 0,
                originalSurvey: new SurveyDto(),
                survey: new SurveyDto(),
                counties: [],
                showBanner: false,
                serverValidationErrors: [],
                duplicateValue: ""
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            addMode() { return this.currentSurveyId === 0; },
            allValidationErrors() {return this.survey.isDirty ? _.concat(this.survey.validationErrors, this.serverValidationErrors) : []}
        },
        validations() {
            const self = this;
            return {
                survey: {
                    name: {
                        required,
                        isUnique: val => _.isEmpty(self.duplicateValue) || val !== self.duplicateValue
                    },
                }
            }
        },

        watch:{
            survey: {
                handler(newValue, oldValue){
                    if (_.isNil(oldValue)) return;
                    let isDirty = !_.isEqual(newValue, this.originalSurvey);
                    this.survey.isDirty = isDirty;
                    //this.$emit("enable-save-button", (_.getNumber(this, "survey.validationErrors.length", 0) === 0));
                },
                immediate: false,
                deep: true
            }
        },

        methods: {

            loadSurvey(data) {
                const self = this;
                self.originalSurvey = new SurveyDto(data);
                self.survey = new SurveyDto(data);
                if (!_.isEmpty(data.state)) {
                    self.counties = self.lookupHelpers.getCountiesByState(data.state);
                } else if (!_.isEmpty(self.propertyState)) {
                    self.counties = self.lookupHelpers.getCountiesByState(self.propertyState);
                } else {
                    self.counties = self.lookupHelpers.getLookupItems(self.lookupItems.COUNTIES);
                }
                $('#txt_survey_name')[0].focus();

                // if(self.survey.surveyID > 0 && !self.currentSurveyId)
                //     self.currentSurveyId = self.survey.surveyID;
            },

            validate () {
                const self = this;
                self.v$.$touch();
                this.showBanner = true;
                return { success: this.survey.isValid, errors: this.survey.validationErrors };
            },

            save () {
                const self = this;
                let dialogMode = self.addMode ? "add" : "edit";
                let saveMode = self.addMode ? "created" : "saved";

                self.serverValidationErrors = [];
                let savePromise = new Promise((resolve, reject) => {
                    let changes = self.getAuditChanges(self.originalSurvey, self.survey);
                    if (changes.length > 0) {
                        self.$api.SurveysApi.saveSurvey(self.survey)
                            .then(survey => {
                                self.$toast.success({ message: `Survey ${saveMode} successfully.` });
                                self.reset();
                                resolve({ success:true, dialogMode, data: survey });
                            })
                            .catch(err => {
                                self.serverValidationErrors.push(err.errorMessage);
                                if(_.includes(self.serverValidationErrors[0], 'already exists.')){
                                    self.duplicateValue = self.survey.name;
                                }
                                reject({ success:false, dialogMode, data: self.survey });
                            });
                    } else {
                        resolve({ success: true, dialogMode, data: self.survey, noChanges: true });
                    }
                });
                return self.$rqBusy.wait(savePromise);
            },

            reset () {
                const self = this;
                self.showBanner = false;
                self.duplicateValue = "";
                self.v$.$reset();
            }
        }
    };
</script>