<template>
    <div class="content-wrapper premium-summary">
        <div class="row">
            <div class="col-auto ms-auto">
                <rq-expand-collapse-all
                    :all-expanded="allExpanded"
                    :all-collapsed="allCollapsed"
                    expand-all-tooltip="Expand All Sections"
                    collapse-all-tooltip="Collapse All Sections"
                    @expand-all="onCollapseExpandAll(true)"
                    @collapse-all="onCollapseExpandAll(false)"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="rq-table-container">
                    <table class="rq-table rq-table-bordered">
                        <thead>
                            <tr>
                                <th :colspan="hasReissueCredit ? 2 : 3"></th>
                                <th v-if="hasReissueCredit"></th>
                                <th class="rq-col-bottom-border th-lg" colspan="2">Code</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th class="shaded-bg"></th>
                                <th></th>
                                <th class="rq-col-bottom-border th-lg" colspan="2">Buyer Pays</th>
                                <th class="rq-col-bottom-border th-lg" colspan="2">Seller Pays</th>
                            </tr>
                            <tr>
                                <th :colspan="hasReissueCredit ? 2 : 3" class="text-left">Description</th>
                                <th v-if="hasReissueCredit" class="text-center">Breakout</th>
                                <th class="shaded-dark-bg text-end">Policy</th>
                                <th class="shaded-dark-bg text-end">Report</th>
                                <th class="text-end">Amount</th>
                                <th class="text-end">Tax</th>
                                <th class="shaded-bg text-end">Total</th>
                                <th class="text-end">CDF</th>
                                <th class="text-end">UW Split</th>
                                <th class="shaded-dark-bg text-end">%</th>
                                <th class="shaded-dark-bg text-end">Amount</th>
                                <th class="shaded-bg text-end">%</th>
                                <th class="shaded-bg text-end">Amount</th>
                            </tr>
                        </thead>
                        <tbody :key="index" v-for="(premium, index) in premiums" :ref="`collapse-${index}`">
                            <tr class="toggle-row" @click="premium.summaryExpanded = !premium.summaryExpanded" :id="`collapse-${index}`">
                                <th colspan="13">{{premium.description}}</th>
                                <th class="expand-collapse-toggle">
                                    <FontAwesomeIcon icon="fas fa-caret-down" :rotation="premium.summaryExpanded ? 0 : 90" />
                                </th>
                            </tr>
                            <tr v-show="premium.summaryExpanded" :class="{ 'alt-row': index % 2 > 0 }" :key="premium.orderRateCalculationID">
                                <td :colspan="hasReissueCredit ? 2 : 3">{{premium.premiumNameDisplay}}</td>
                                <td v-if="hasReissueCredit"></td>
                                <td class="text-end">{{premium.policyCode}}</td>
                                <td class="text-end">{{premium.reportCode}}</td>
                                <td class="text-end">{{formatMoney(premium.premiumAmount)}}</td>
                                <td class="text-end">{{formatMoney(premium.taxAmount)}}</td>
                                <th class="text-end">{{formatMoney(premium.taxTotal)}}</th>
                                <td class="text-end">{{formatMoney(premium.cdfTotal)}}</td>
                                <td class="text-end">{{formatMoney(premium.underwriterSplit)}}</td>
                                <td class="text-end">{{premium.buyerPayPctForLoan}}%</td>
                                <th class="text-end">{{formatMoney(premium.buyerToPay)}}</th>
                                <td class="text-end">{{premium.sellerPayPctForOwner}}%</td>
                                <th class="text-end">{{formatMoney(premium.sellerToPay)}}</th>
                            </tr>
                            <tr v-if="!premium.noRic" v-show="premium.summaryExpanded" :class="{ 'alt-row': index % 2 > 0 }" :key="`${premium.orderRateCalculationID}_1`">
                                <td :colspan="hasReissueCredit ? 2 : 3">Reissue Credit</td>
                                <td class="text-center" v-if="hasReissueCredit">
                                    <FontAwesomeIcon v-if="rateOrderInfo.breakoutRIConHUD" icon="fas fa-check-circle" class=" m-auto font-lg" />
                                </td>
                                <td class="text-end"></td>
                                <td class="text-end">{{premium.reissueCreditReportCode}}</td>
                                <td class="text-end">{{formatMoney(premium.creditTotal)}}</td>
                                <td class="text-end"></td>
                                <th class="text-end">{{formatMoney(premium.creditTotal)}}</th>
                                <td class="text-end"></td>
                                <td class="text-end"></td>
                                <td class="text-end">{{premium.buyerPayPctForLoan_Ric}}%</td>
                                <th class="text-end">{{formatMoney(premium.buyerToPay_Ric)}}</th>
                                <td class="text-end">{{premium.sellerPayPctForOwner_Ric}}%</td>
                                <th class="text-end">{{formatMoney(premium.sellerToPay_Ric)}}</th>
                            </tr>

                            <!--Endorsement-->
                            <tr v-if="premium.endorsementTotals.showEndorsements" v-show="premium.summaryExpanded" :class="{ 'alt-row': index % 2 > 0 }" :key="`${premium.orderRateCalculationID}_2`">
                                <td :colspan="hasReissueCredit ? 2 : 3">Endorsement</td>
                                <td v-if="hasReissueCredit"></td>
                                <td class="text-end"></td>
                                <td class="text-end"></td>
                                <td class="text-end">{{formatMoney(premium.endorsementTotals.endorsementTotal)}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-end">{{formatMoney(premium.endorsementTotals.endorsementUwSplit)}}</td>
                                <td></td>
                                <th class="text-end">{{formatMoney(premium.endorsementTotals.buyerEndorsements)}}</th>
                                <td></td>
                                <th class="text-end">{{formatMoney(premium.endorsementTotals.sellerEndorsements)}}</th>
                            </tr>
                            <!---->
                            <!--Fees-->
                            <tr v-if="premium.endorsementTotals.showFees" v-show="premium.summaryExpanded" :class="{ 'alt-row': index % 2 > 0 }" :key="`${premium.orderRateCalculationID}_3`">
                                <td :colspan="hasReissueCredit ? 2 : 3">Fee</td>
                                <td v-if="hasReissueCredit"></td>
                                <td class="text-end"></td>
                                <td class="text-end"></td>
                                <td class="text-end">{{formatMoney(premium.endorsementTotals.feeTotal)}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-end">{{formatMoney(premium.endorsementTotals.feeUwSplit)}}</td>
                                <td></td>
                                <th class="text-end">{{formatMoney(premium.endorsementTotals.buyerFees)}}</th>
                                <td></td>
                                <th class="text-end">{{formatMoney(premium.endorsementTotals.sellerFees)}}</th>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="total-row">
                                <th class="summary-total" :colspan="hasReissueCredit ? 2 : 3">Total Premium <small>(including endorsements &amp; fees)</small></th>
                                <td v-if="hasReissueCredit"></td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <th>{{formatMoney(totalPremium)}}</th>
                                <td>&nbsp;</td>
                                <th>{{formatMoney(taxTotal)}}</th>
                                <th>{{formatMoney(cdfTotal)}}</th>
                                <th>{{formatMoney(underwriterSplitTotal)}}</th>
                                <td>&nbsp;</td>
                                <th>{{formatMoney(buyerAmountTotal)}}</th>
                                <td>&nbsp;</td>
                                <th>{{formatMoney(sellerAmountTotal)}}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { RateOrderInfoDto, EndorsementPolicy } from "@settlement/models";
    import { BreakoutEndorsementOption, EndorsementYesNo } from "@settlement/models/enums";
    import OrderEndorsement from "@settlement/models/OrderEndorsement";

    export default {
        props: {
            premiums: { type: Array, default:() => [] },
            cdfPremiums: { type: Array, default:() => [] },
        },
        data(){
            return {
                salesPrice: 0,
                ownerOverrideTotal: 0,
                loanAmountTotal: 0,
                loanOverrideTotal: 0,
                totalPremium: 0,
                taxTotal: 0,
                underwriterSplitTotal: 0,
                buyerAmountTotal: 0,
                sellerAmountTotal: 0,
                agentTotal: 0,
                cdfTotal: 0,
                expandAllSections: true
             };
        },
        computed: {
            ...mapState({
                storedEndorsements: state => state.rateEngine.endorsements,
                regions: state => state.system.lookups.regions,
                orderRegionId: state => state.orders.orderSummary.regionID
            }),
            rateOrderInfo() { return new RateOrderInfoDto(_.get(this, "$store.state.rateEngine.rateOrderInfo", {})); },
            hasReissueCredit() { return _.some(this.premiums, p => !_.eq(p.creditTotal, 0)); },
            hasEndorsements() { return _.some(this.premiums, p => !_.eq(p.endorsementTotal, 0)); },
            hasFees() { return _.some(this.premiums, p => !_.eq(p.calculatedFeeTotal, 0)); },
            allExpanded() { return _.every(this.premiums, p => p.summaryExpanded); },
            allCollapsed() { return _.every(this.premiums, p => !p.summaryExpanded); }
        },
        watch:{
            premiums: {
                handler(newVal, oldVal) {
                    this.calculateTotals();
                    this.$emit('setSummary', {
                        totalPremium: this.totalPremium,
                        underwriterSplitTotal: this.underwriterSplitTotal
                    })
                },
                immediate: true
            }
        },
        methods:{
            calculateTotals() {
                this.salesPrice = 0;
                this.ownerOverrideTotal = 0;
                this.loanAmountTotal = 0;
                this.loanOverrideTotal = 0;
                this.totalPremium = 0;
                this.taxTotal = 0;
                this.underwriterSplitTotal = 0;
                this.buyerAmountTotal = 0;
                this.sellerAmountTotal = 0;
                this.agentTotal = 0;
                this.cdfTotal = 0;

                _.each(this.premiums, p => {
                    let creditTotal = _.parseNumber(p.creditTotal, 0);
                    if(p.isLoanHolder) {
                        this.salesPrice = p.saleAmount;
                        this.ownerOverrideTotal = p.salesOverrideAmount;
                    }
                    else
                        this.loanAmountTotal += _.parseNumber(p.loanAmount, 0);

                    this.loanOverrideTotal += _.parseNumber(p.loanOverrideAmount, 0);
                    let endorsementTotals = this.calculateEndorsementTotals(p);
                    this.totalPremium += _.parseNumber(p.premiumAmount, 0) + _.parseNumber(endorsementTotals.endorsementTotal, 0) + _.parseNumber(endorsementTotals.feeTotal, 0);
                    this.totalPremium += creditTotal;
                    this.taxTotal += _.parseNumber(p.taxTotal, 0) + creditTotal;
                    this.underwriterSplitTotal += _.parseNumber(p.underwriterSplit + endorsementTotals.endorsementUwSplit + endorsementTotals.feeUwSplit, 0);
                    this.buyerAmountTotal += _.parseNumber(p.buyerToPay, 0) + _.parseNumber(p.buyerToPay_Ric, 0) + _.parseNumber(endorsementTotals.buyerEndorsements, 0) + _.parseNumber(endorsementTotals.buyerFees, 0);
                    this.sellerAmountTotal += _.parseNumber(p.sellerToPay, 0) + _.parseNumber(p.sellerToPay_Ric, 0) + _.parseNumber(endorsementTotals.sellerEndorsments, 0) + _.parseNumber(endorsementTotals.sellerFees, 0);
                    this.agentTotal += _.parseNumber(p.agentTotal, 0);

                    let cdfPremium = _.find(this.cdfPremiums, cdf => cdf.cdfOrderRateCalculationID === p.cdfOrderRateCalculationID);

                    // NOTE KPACA 1/9/2020 - p.cdfTotal is a UI only property/variable, its never gonna be passed back to the api.
                    p.cdfTotal = cdfPremium ? _.parseNumber(cdfPremium.premiumAmount, 0) : 0;
                    this.cdfTotal += p.cdfTotal;
                });
            },
            calculateEndorsementTotals(premium) {
                const self = this;
                let storeEndorsements = _.filter(self.storedEndorsements, e => e.orderRateCalculationID === premium.orderRateCalculationID);
                let endorsements = _.map(storeEndorsements, item => new OrderEndorsement(item));

                const showSection = type => _.some(endorsements, oe => oe.breakoutOverride === BreakoutEndorsementOption.Yes
                    && oe.showOutsideOfPremiums !== EndorsementYesNo.Yes
                    && (type === "fees" && oe.endorsementPolicyTypeID === EndorsementPolicy.Fee)
                        || (type !== "fees" && oe.endorsementPolicyTypeID !== EndorsementPolicy.Fee));

                let orderRegion = _.find(self.regions, r => r.regionID == self.orderRegionId);
                let breakoutEndorsementHUD = orderRegion?.breakoutEndorsementHUD ?? false;
                let isCombineEndorsements = orderRegion?.isCombineEndorsements ?? false;

                const endValue = (oe,field) => 
                ((oe.breakoutOverride === BreakoutEndorsementOption.UseDefault && (breakoutEndorsementHUD || isCombineEndorsements)) 
                || oe.breakoutOverride === BreakoutEndorsementOption.Yes 
                || oe.breakoutOverride ===  BreakoutEndorsementOption.CombineEndorsements) 
                && oe.endorsementPolicyTypeID !== EndorsementPolicy.Fee 
                && oe.showOutsideOfPremiums !== EndorsementYesNo.Yes 
                ? _.getNumber(oe, field, 0) : 0;
                const feeValue = (oe,field) => oe.breakoutOverride === BreakoutEndorsementOption.Yes && oe.endorsementPolicyTypeID === EndorsementPolicy.Fee && oe.showOutsideOfPremiums !== EndorsementYesNo.Yes ? _.getNumber(oe, field, 0) : 0;

                let buyerEndorsements = _.reduce(endorsements, (total, oe) => total + endValue(oe, "buyerToPay"), 0);
                let sellerEndorsements = _.reduce(endorsements, (total, oe) => total + endValue(oe, "sellerToPay"), 0);
                let endorsementUwSplit = _.reduce(endorsements, (total, oe) => total + endValue(oe, "overrideUnderwriterAmount"), 0);
                let endorsementTotal = buyerEndorsements + sellerEndorsements; //UwSplit intentionally omitted

                let buyerFees = _.reduce(endorsements, (total, oe) => total + feeValue(oe, "buyerToPay"), 0);
                let sellerFees = _.reduce(endorsements, (total, oe) => total + feeValue(oe, "sellerToPay"), 0);
                let feeUwSplit = _.reduce(endorsements, (total, oe) => total + feeValue(oe, "overrideUnderwriterAmount"), 0);
                let feeTotal = buyerFees + sellerFees; //UwSplit intentionally omitted

                premium.endorsementTotals = {
                    buyerEndorsements,
                    sellerEndorsements,
                    endorsementUwSplit,
                    endorsementTotal,
                    showEndorsements: showSection("endorsements"),
                    buyerFees,
                    sellerFees,
                    feeUwSplit,
                    feeTotal,
                    showFees: showSection("fees")
                };
                return premium.endorsementTotals;
            },
            onCollapseExpandAll(expanded) {
                _.forEach(this.premiums, p => { p.summaryExpanded = expanded; });
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } }); }
        }
    };
</script>