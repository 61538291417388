<template>
    <div class="card contact-card rq-contact-card">
        <div class="card-header" @click="onCardClick">
            <slot name="card-header">
                <span automation_id="lbl_contact_role" class="card-title">{{contact.title}}</span>
            </slot>
        </div>
        <div class="card-body card-body-large" @click="onCardClick">
            <div class="contact-avatar">
                <FontAwesomeIcon icon="fas fa-building" />
            </div>
            <div class="contact-names">
                <div v-if="companyName" automation_id="lbl_company_name" class="company-name" :title="companyName" v-shave="{ height: 78, spaces: false }">{{companyName}}</div>
                <div v-if="contactName" automation_id="lbl_contact_name" class="contact-name" :title="contactName" v-shave="{ height: 78, spaces: false }">{{contactName}}</div>
                <div v-if="address" automation_id="lbl_contact_adress" class="contact-address" :title="address" v-shave="{ height: 78, spaces: false }">{{address}}</div>
            </div>
            <div class="contact-licenses">
                <div v-if="nmlsID" class="contact-license">NMLS ID - {{nmlsID}}</div>
                <div v-if="licenseID" class="contact-license">License ID - {{licenseID}}</div>
                <div v-if="contactNMLSID" class="contact-license">Contact NMLS ID - {{contactNMLSID}}</div>
                <div v-if="contactLicenseID" class="contact-license">Contact License ID - {{contactLicenseID}}</div>
            </div>
            <div class="contact-detail">
                <div v-if="email" automation_id="lbl_contact_email" class="contact-email text-truncate" :title="email"><a :id="elementIDs.contactEmailLink" :href="`mailto:${email}`">{{email}}</a></div>
                <div v-if="phone" automation_id="lbl_contact_phone" class="contact-phone">{{phone}}</div>
            </div>
            <div class="contact-revert">
                <b-btn
                    :id="elementIDs.revertButton"
                    automation_id="btn_revert_contact"
                    variant="link"
                    size="sm"
                    class="btn-theme btn-revert"
                    v-show="isOverridden"
                    @click.stop.prevent="onRevert">Revert</b-btn>
            </div>
        </div>
        <div class="card-footer">
            <slot name="card-footer">
                <div class="row">
                    <div v-rq-tooltip.html.hover.top="{ title: deleteTooltip }" class="col">
                        <b-btn v-if="showDelete"
                            :automation_id="actionAutomationId.delete"
                            variant="icon"
                            class="w-100"
                            :disabled="deleteDisabled"
                            @click="onDeleteClick">
                            <FontAwesomeIcon :icon="actionIcon.delete" />
                        </b-btn>
                    </div>
                    <div v-rq-tooltip.html.hover.top="{ title: overrideActionTooltip }" class="col">
                        <b-btn v-if="showCompanyAction"
                            :automation_id="actionAutomationId.company"
                            variant="icon"
                            class="w-100"
                            :disabled="companyActionDisabled || readOnly"
                            @click="onOverrideClick">
                            <FontAwesomeIcon icon="fas fa-comment-alt-edit" />
                        </b-btn>
                    </div>
                    <div v-rq-tooltip.html.hover.top="{ title: companyActionTooltip }" class="col">
                        <b-btn v-if="showCompanyAction"
                            :automation_id="actionAutomationId.company"
                            variant="icon"
                            class="w-100"
                            :disabled="companyActionDisabled"
                            @click="onCompanyActionClick">
                            <FontAwesomeIcon :icon="actionIcon.company" />
                        </b-btn>
                    </div>
                    <div v-rq-tooltip.html.hover.top="{ title: contactActionTooltip }" class="col">
                        <b-btn v-if="showContactAction"
                            :automation_id="actionAutomationId.contact"
                            variant="icon"
                            class="w-100"
                            :disabled="contactActionDisabled"
                            @click="onContactActionClick">
                            <FontAwesomeIcon :icon="actionIcon.contact" />
                        </b-btn>
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ContactCardModel, SettlementStatementContactOverrideModel } from "../models";
    import SettlementStatementContactOverrideDialog from "./SettlementStatementContactOverrideDialog";

    export default {
        name: "SettlementStatementContactCard",
        props: {
            contact: { type: ContactCardModel, default: () => ({}) },
            showAssign: { type: Boolean, default: false },
            showDelete: { type: Boolean, default: false }
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            roleTypeID() { return this.contact.roleTypeID; },
            roleObject() { return _.find(this.lookupHelpers.getRoles(), r => _.parseNumber(r.id) === _.parseNumber(this.roleTypeID)); },
            canDelete() { return true; },
            canAssign() { return !this.readOnly },
            showCompanyAction() { return this.contact.companyID || this.showAssign; },
            showContactAction() { return this.contact.companyID && this.showAssign; },
            deleteDisabled() { return !this.canDelete || this.readOnly; },
            companyActionDisabled() { return !this.contact.companyID && !this.canAssign; },
            contactActionDisabled() { return !this.contact.contactID && !this.canAssign; },
            deleteTooltip() {
                if(!this.showDelete) return "";
                if(this.readOnly) return "Access Restricted";
                return `Delete ${_.startCase(this.contact.roleTypeName)}`;
            },
            companyActionTooltip() {
                if(!this.showCompanyAction) return "";
                if(this.contact.companyID) return "View/Edit Company";
                if(!this.canAssign) return "Company/contact cannot be<br/>assigned to this role from here.";
                if(this.readOnly) return "Access Restricted";
                return "Assign Company/Contact";
            },
            contactActionTooltip() {
                if(!this.showContactAction) return "";
                if(this.contact.contactID) return "View/Edit Contact";
                if(!this.canAssign) return "Company/contact cannot be<br/>assigned to this role from here.";
                if(this.readOnly) return "Access Restricted";
                return "Assign Contact";
            },
            overrideActionTooltip() {
                if(!this.showCompanyAction) return "";
                if(this.contact.companyID) return "Override Contact";
                if(!this.canAssign) return "Company/contact cannot be <br/>overridden from here.";
                if(this.readOnly) return "Access Restricted";
                return "Override Contact";
            },
            actionIcon() {
                return {
                    delete: "far fa-trash-alt",
                    company: this.contact.companyID ? "far fa-building" : "fas fa-plus",
                    contact: this.contact.contactID ? "fas fa-user-edit" : "fas fa-user-plus"
                };
            },
            actionAutomationId() {
                return {
                    delete: "btn_delete_contact",
                    company: this.contact.companyID ? "btn_edit_company" : "btn_assign_company",
                    contact: this.contact.contactID ? "btn_edit_contact" : "btn_assign_contact"
                };
            },
            companyName(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.companyName;
                return self.contact.override.name;
            },

            contactName(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.contactName;
                return self.contact.override.contact;
            },

            address(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.address;
                return self.contact.override.address;
            },

            email(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.email;
                return self.contact.override.email;
            },

            phone(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.primaryPhone;
                return self.contact.override.phone;
            },

            nmlsID(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.nmlsid;
                return self.contact.override.nmlsid;
            },

            licenseID(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.stateLicenseID;
                return self.contact.override.licenseID;
            },

            contactNMLSID(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.contactNMLSID;
                return self.contact.override.contactNMLSID;
            },

            contactLicenseID(){
                const self = this;
                if(_.isNil(self.contact.override)) return self.contact.contactStateLicenseID;
                return self.contact.override.contactLicenseID;
            },

            isOverridden(){
                const self = this;
                if(_.isNil(self.contact.override)) return false;

                if(  self.contact.override.name !== self.contact.companyName ||
                        self.contact.override.address !== self.contact.address ||
                        self.contact.override.contact !== self.contact.contactName ||
                        self.contact.override.email !== self.contact.email ||
                        self.contact.override.phone !== self.contact.primaryPhone ||
                        self.contact.override.licenseID !== self.contact.stateLicenseID ||
                        self.contact.override.nmlsid !== self.contact.nmlsid ||
                        self.contact.override.contactNMLSID !== self.contact.contactNMLSID ||
                        self.contact.override.contactLicenseID !== self.contact.contactStateLicenseID){
                    return true;
                }
                return false;
            },

            contactRoleId() { return _.getNumber(this, "contact.rolesID", 0); },

            elementIDs() {
                const elementId = pfx => this.contactRoleId > 0 ? `${pfx}${this.contactRoleId}` : _.uniqueId(pfx);
                return {
                    revertButton: elementId("btn_revert_contact_"),
                    contactEmailLink: elementId("lnk_contact_email_")
                };
            },

            readOnly() { return _.get(this, "$store.state.isPageReadOnly", false); }
        },

        methods: {

            onCardClick(e) {
                if(_.some(this.elementIDs, id => e.target.id === id)) return;
                let type = this.contact.isBuyerSeller ? "buyer-seller" : "company";
                this.$emit("detail-click", { type, data: this.contact });
            },

            onCompanyActionClick(e) {
                if(this.contact.companyID)
                    this.$emit("detail-click", { type: "company", data: this.contact });
                else
                    this.$emit("assign", { data: this.contact });
            },

            onContactActionClick(e) {
                if(this.contact.companyID && this.contact.contactID)
                    this.$emit("detail-click", { type: "contact", data: this.contact });
                else
                    this.$emit("assign", { data: this.contact });
            },

            onDeleteClick(e) {
                this.$emit("delete", { data: this.contact });
            },

            onOverrideClick(){
                const self = this;
                if(_.isNil(self.contact.override)){
                    self.contact.override = new SettlementStatementContactOverrideModel({
                        companyID: self.contact.companyID,
                        name: self.contact.companyName,
                        address: self.contact.address,
                        contact: self.contact.contactName,
                        email: self.contact.email,
                        phone: self.contact.primaryPhone,
                        licenseID: self.contact.stateLicenseID,
                        nmlsid: self.contact.nmlsid,
                        contactNMLSID: self.contact.contactNMLSID,
                        contactLicenseID: self.contact.contactStateLicenseID
                    });
                }
                self.$dialog.open({
                    title: "Override Contact",
                    width: 1000,
                    height: 825,
                    component: SettlementStatementContactOverrideDialog,
                    props:{
                        contact: self.contact
                    },
                    onOk: function(e) {
                        e.component.save();
                        self.$emit("save", {data: self.contact});
                        return true;
                    },
                });
            },

            onRevert(){
                const self = this;

                let okHandler = function(){
                    self.contact.override = null;
                    self.$emit("save", {data: self.contact});
                };

                self.$dialog.confirm(
                    "Confirm Delete",
                    "This will revert all overridden field on this Settlement Statement Contact.  Do you want to continue?",
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});

            }
        }
    };
</script>
