<template>
    <input
        class="form-control"
        type="text"
        maxlength="15"
        v-model="boundValue"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @keypress="preventSpecialCharacterInput"
    />
</template>
<script>
    import RegEx from "@/shared/utilities/RegEx";

    export default {
        name: "ForeignIdInput",

        props:{
            modelValue: { type: String, default: "" }
        },

        data() {
            return {
                isFocused: false
            }
        },

        computed: {
            localSecurity() { return this.securitySettings.findValues(["CanEditSSN","CanViewSSN"]); },
            maskMode() {
                switch (_.parseNumber(this.localSecurity.CanViewSSN, 0)) {
                    case 0: return "all";
                    case 1: return "last-4";
                }
                return "none";
            },
            boundValue: {
                get() {
                    if(this.isFocused || this.maskMode === "none") return this.modelValue;
                    return this.getFormattedValue();
                },
                set(val) {
                    if(!this.isFocused && this.maskMode !== "none") return;
                    this.$emit("update:modelValue", val);
                }
            },
        },

        methods:{
            focus() {
                _.invoke(this, "$el.focus");
            },
            getFormattedValue() {
                let displayValue = "";
                _.each(this.modelValue, (char,index,arr) => {
                    if((this.maskMode === "last-4" && arr.length - index <= 4) || this.maskMode === "none")
                        displayValue += char;
                    else
                        displayValue += "*";
                });
                return displayValue;
            },
            preventSpecialCharacterInput(event) {
                if(!RegEx.Foreign.test(event.key))
                    event.preventDefault();
            }
        }
    }
</script>
