<template>
    <div class="rename-page-form">
        <rq-page-section borderless>
            <div class="col col-12 col-lg-12 col-md-6 col-lg-6 form-group">
                <label>New Name</label>
                    <input
                        automation_id="txt_customPageName"
                        v-model="systemDefaults.customPageName"
                        type="text"
                        class="form-control"
                        id="displayLabel"
                        maxlength="50">
            </div>
        </rq-page-section>
    </div>
</template>
<script>
import { SystemDefaultDto, CDFSystemDefaultDto }  from "@/modules/configuration/models";
export default {
    name: "RenamePageForm",
    data: function () {
        return {
            originalData: {
                systemDefaults: {},
                cdfSystemDefaults: {},
                settlementStatementDocuments: []
            },
            systemDefaults: {},
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData () {
            const self = this;
            let apiPromise = self.$api.SystemDefaultApi.getSystemDefaultData();
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.originalData.systemDefaults = new SystemDefaultDto(result.systemDefault);
                    self.systemDefaults = new SystemDefaultDto(result.systemDefault);
                    return result;
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error(`Error loading System Defaults.`);
                    return error;
                });
        },
        getRequestData() {
            const self = this;
            return {
                systemDefault: self.systemDefaults.toDataObject(),
                cdfSystemDefault: null,
                settlementStatementDocuments: []
            };
        },
        hasChanges() {
            const self = this;
            let originalSd = self.originalData.systemDefaults.toDataObject();
            let currentSd = self.systemDefaults.toDataObject();
            let sdChanges = self.getAuditChanges(originalSd, currentSd);
            return !_.isEmpty(sdChanges);
        }
    }
}
</script>