<template>
    <div class="row">

        <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 form-group" :class="{'form-required' : addressRequired, 'has-error' : addressRequired && v$.address1.required.$invalid}">
            <label :for="getAutomationId('txt','line1')">Address 1</label>
            <dx-autocomplete
                ref="address1Autocomplete"
                :id="getAutomationId('txt','line1')"
                :automation_id="getAutomationId('txt','line1')"
                class="form-control"
                :dataSource="addressDataSource"
                item-template="item-template"
                :min-search-length="3"
                :input-attr="addressInputAttr"
                :disabled="readOnly"
                value-expr="text"
                v-model="address1"
                @initialized="onAddressContentReady"
                @focus-out="onAddress1FocusOut"
                @focus-in="onAddress1FocusIn"
                @opened="onAddress1Opened"
                @selectionChanged="onAutoCompleteSelectionChanged"
                @input="onAddress1Input"
            >
                <template #item-template="{ data }">
                    <div>{{data ? data.text : ""}}</div>
                </template>
            </dx-autocomplete>
            <rq-validation-feedback>Address1 is required if SSN/Tax ID is updated</rq-validation-feedback>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 form-group">
            <label :for="getAutomationId('txt', 'line2')">Address 2</label>
            <input :id="getAutomationId('txt', 'line2')"
                :automation_id="getAutomationId('txt', 'line2')"
                type="text"
                class="form-control"
                placeholder="Line 2"
                maxlength="100"
                :disabled="readOnly"
                v-model="address2"
            >
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 form-group" :class="{'form-required' : addressRequired, 'has-error' : addressRequired && v$.city.required.$invalid}">
            <label :for="getAutomationId('txt','city')">City</label>
            <input :id="getAutomationId('txt','city')"
                :automation_id="getAutomationId('txt','city')"
                type="text"
                class="form-control"
                placeholder="City"
                maxlength="30"
                :disabled="readOnly"
                v-model="city"
            >
            <rq-validation-feedback>City is required if SSN/Tax ID is updated</rq-validation-feedback>
        </div>

        <div v-if="!isForeign" class="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-group" :class="{'form-required' : addressRequired, 'has-error' : addressRequired && v$.state.required.$invalid}">
            <label :for="getAutomationId('drp', 'state')">State</label>
            <dx-select-box
                :input-attr="{ automation_id: getAutomationId('drp', 'state'), id: getAutomationId('drp', 'state') }"
                class="form-control"
                value-expr="id"
                display-expr="id"
                :items="usStatesLookup"
                :search-enabled="true"
                :disabled="readOnly"
                v-model="state"
            />
            <rq-validation-feedback>State is required if SSN/Tax ID is updated</rq-validation-feedback>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-group" :class="{'form-required' : addressRequired, 'has-error' : v$.zip.$error || (addressRequired && v$.zip.required.$invalid)}">
            <label :for="getAutomationId('txt', 'zip')">Zip</label>
            <input v-if="isForeign"
                :id="getAutomationId('txt', 'zip')"
                :automationId="getAutomationId('txt', 'zip')"
                placeholder="Zip"
                :disabled="readOnly"
                v-model="zip"
                ref="foreignZip"
                maxlength="10"
                class="form-control"
            />
            <rq-masked-input v-else
                :id="getAutomationId('txt', 'zip')"
                type="text"
                placeholder="Zip"
                :disabled="readOnly"
                v-model="zip"
                cssClass="form-control"
                maskType="zip"
                is-masked
            />
            <rq-validation-feedback
                :messages="{
                    'Zip code format is invalid.': v$.zip.zipFormatValid.$invalid,
                    'Zip code is required if SSN/Tax ID is updated.': addressRequired && v$.zip.required.$invalid
                }"
            />
        </div>

        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-group">
            <label :for="getAutomationId('txt','country')">Country</label>
            <input
                :id="getAutomationId('txt', 'country')"
                :automation_id="getAutomationId('txt', 'country')"
                type="text"
                class="form-control"
                placeholder="Country"
                maxlength="50"
                v-model="country"
                :disabled="!isForeign || readOnly"
            />
        </div>
        <div v-if="isForeign" class="col-lg-3 col-md-6 col-sm-12 col-xs-12 form-group" >
            <label :for="getAutomationId('txt','regionTerritory')">Region/Territory</label>
            <input :id="getAutomationId('txt','regionTerritory')"
                :automation_id="getAutomationId('txt','regionTerritory')"
                type="text"
                class="form-control"
                placeholder="Region/Territory"
                maxlength="150"
                :disabled="readOnly"
                v-model="regionTerritory"
            >
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        props: {
            buyerSeller: { type: Object, default: () => ({}) },
            addressType: { type: String, default: "current" },
            automationIdPart: { type: String, default: ""},
            readOnly: { type: Boolean, default: false }
        },

        setup: () => ({ v$: useVuelidate() }),
         data() {
            return {
                superMarketDataVerificationEnabled: false,
                addressInputAttr: {},
                addressDataSource: {},
                addressContentReady: false,
                isAddress1Focused: false,
            }
        },

        validations() {
            return {
                address1: { required: requiredIf(() => this.addressRequired) },
                city: { required: requiredIf(() => this.addressRequired) },
                state: { required: requiredIf(() => this.addressRequired) },
                zip: {
                    required: requiredIf(() => this.addressRequired),
                    zipFormatValid: value => _.isEmpty(value) || value.length === 5 || value.length === 10 || this.isForeign
                }
            };
        },
        computed: {
            ...mapGetters([
                "usStatesLookup"
            ]),
            address1: {
                get() { return this.getPropVal("Address1"); },
                set(val) { this.setPropVal("Address1", val); }
            },
            address2: {
                get() { return this.getPropVal("Address2"); },
                set(val) { this.setPropVal("Address2", val); }
            },
            city: {
                get() { return this.getPropVal("City"); },
                set(val) { this.setPropVal("City", val); }
            },
            state: {
                get() { return this.getPropVal("State"); },
                set(val) { this.setPropVal("State", val); }
            },
            zip: {
                get() { return this.getPropVal("Zip"); },
                set(val) { this.setPropVal("Zip", val); }
            },
            country: {
                get() { return this.getPropVal("Country"); },
                set(val) { this.setPropVal("Country", val); }
            },
            regionTerritory: {
                get() { return this.getPropVal("RegionTerritory"); },
                set(val) { this.setPropVal("RegionTerritory", val); }
            },
            isForeign: {
                get() { return this.getPropVal("isForeignAddress"); },
                set(val) {
                    this.setPropVal("isForeignAddress", val);
                    this.focusForeignZip(val);
                }
            },
            isMilitary: {
                get() { return this.getPropVal("Military"); },
                set(val) { this.setPropVal("Military", val); }
            },
            addressRequired() {
                return this.automationIdPart === 'primary' &&
                this.superMarketDataVerificationEnabled &&
                (this.checkIfTaxIdChanged(this.buyerSeller.socialSecurityNumber1) ||
                this.checkIfTaxIdChanged(this.buyerSeller.socialSecurityNumber2));
            }
        },

        created () {
            const self = this;
            const licenseStore = useLicenseStore();
            const featureFlag = 'superMarketAddressAndTaxIdVerification';
            self.superMarketDataVerificationEnabled = licenseStore.checkFeature(featureFlag);
            self.initAddressAutocomplete();
        },

        methods: {
            touch(){ this.v$.$touch(); },
            reset(){ this.v$.$reset(); },
            isValid() { return !this.v$.$error; },
            getAutomationId (type, field) {
                return `${type}_${this.automationIdPart}_${field}`;
            },
            getPropName(name) {
                if(name === "isForeignAddress") {
                   return this.addressType === "current" ? name : "isForeignFowardingAddress";
                }
                return `${this.addressType}${name}`;
            },
            getPropVal(name) {
                let propName = this.getPropName(name);
                return this.buyerSeller[propName];
            },
            setPropVal(name, val) {
                let propName = this.getPropName(name);
                this.buyerSeller[propName] = val;
            },
            focusForeignZip(val) {
                let self = this;
                if (val) {
                    this.$nextTick(() =>{
                        self.$refs.foreignZip.$el.focus();
                    });
                }
            },
            checkIfTaxIdChanged(taxId) {
                return !_.isNil(taxId) && taxId != '' && (taxId.indexOf('*') < 0);
            },

            initAddressAutocomplete() {
                const self = this;
                self.addressDataSource = {
                    key: "text",
                    load(loadOptions){
                        return  self.isMilitary || self.isForeign ? [] : self.$api.UtilitiesApi.addressAutoComplete(loadOptions.searchValue, self.state || "");
                    }
                };
                self.addressInputAttr = {
                    autocomplete: Math.random(),
                    automation_id: "txt_address1",
                };
            },

            onAddressContentReady(e) {
                const self = this;
                if (self.addressContentReady) return;
                self.addressContentReady = true;
                self.$nextTick(() => {
                    e.component.focus();
                });
            },

            onAddress1FocusIn(e){
                this.isAddress1Focused = true;
            },

            onAddress1FocusOut(e){
                this.isAddress1Focused = false;
            },

            onAddress1Opened(e){
                if(this.isAddress1Focused || !this.$refs.address1Autocomplete) return;
                this.setAddressAutocompleteOption("opened", false);
            },

            setAddressAutocompleteOption(optionName, val) {
                _.invoke(this, "$refs.address1Autocomplete.instance.option", optionName, val);
            },

            onAutoCompleteSelectionChanged(e) {
                const self = this;
                let selectedItem = e.component.option("selectedItem");
                if(!_.isObject(selectedItem)) return;

                self.$nextTick(()=>{
                    self.address1 = selectedItem.address1;
                    self.address2 = selectedItem.address2;
                    self.city = selectedItem.city;
                    self.state = selectedItem.state;
                    self.zip = selectedItem.zip;
                });
            },

            onAddress1Input(e) {
                const self = this;
                const input = e.component._input().get(0);
                if (!_.isNil(self.address1) && self.address1.length > 100) {
                    self.address1 = self.address1.slice(0, 100);
                    input.value = self.address1;
                }
            }
        }
    };
</script>
