export class HudGoodFaithEstimateDto {
	constructor(options, hudMainId) {
		options = options || {};
		this.hudGoodFaithEstimateID = _.parseNumber(options.hudGoodFaithEstimateID, 0);
		this.hudMainID = _.parseNumber(hudMainId, null);
        this.loanID = _.parseNumber(options.loanID, 0);
        this.hudSection8000Items = _.map(options.hudSection8000Items, l => new HudGFECanChangeChargeDto(l));
        this.hudSection5000Items = _.map(options.hudSection5000Items, l => new HudGFECannotChangeChargeDto(l));
        this.hudSection6000Items = _.map(options.hudSection6000Items, l => new HudGFECannotChangeChargeDto(l));
	}
}

export class HudGFEChargeDto {
	constructor(options, hudMainId) {
        options = options || {};
        this.hudLine = _.parseNumber(options.hudLine, 0);
        this.amount = _.parseNumber(options.amount, 0);
        this.description = options.description || "";
        this.gfeLineNumber = _.parseNumber(options.gfeLineNumber, 0);
        this.hudMainID = _.parseNumber(hudMainId, null);
        this.requiredLine = _.parseBool(options.requiredLine, false);
        this.overrideHudLine = _.parseBool(options.overrideHudLine, false);
        this.hudLineAmount = _.parseNumber(options.hudLineAmount, 0);
        this.loanID = _.parseNumber(options.loanID, 0);
    }
    
	get hudLineAmountValue() {
        return this.hudLineAmount;
	}

	set hudLineAmountValue(val) {
        this.overrideHudLine = true;
        this.hudLineAmount = val;
	}

	get isHudLineAmountOverridden() {
		return this.overrideHudLine;
    }

	get canRevert() {
        return this.isHudLineAmountOverridden;
	}    
}

export class HudGFECannotChangeChargeDto extends HudGFEChargeDto {
    constructor(options, hudMainId) {
        super(options, hudMainId);
        this.hudgfeChargesThatCannotChangeID = _.parseNumber(options.hudgfeChargesThatCannotChangeID, null);
    }
}

export class HudGFECanChangeChargeDto extends HudGFEChargeDto {
    constructor(options, hudMainId) {
        super(options, hudMainId);
        this.hudgfeChargesThatCanChangeID = _.parseNumber(options.hudgfeChargesThatCanChangeID, null);
    }
}