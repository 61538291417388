<template>
    <aside :class="{'filter-select-tray': true, 'active': modelValue }">
        <button type="button"
            class="close close-sidebar"
            aria-label="Close"
            @click="hideTray"><FontAwesomeIcon aria-hidden="true" icon="fa fa-times" />
        </button>
        <span class="sidebar-header">Select Fields</span>
        <rq-scroll-container
            psClassAttr="filter-scroll-area"
            :psOptions="psOptions"
            perfect-scrollbar
            shadows-enabled>
            <rq-section-card
                v-for="subset in searchFieldSet.subsets"
                :key="subset.id"
                :title="subset.label"
                collapsible
                v-model:collapse="subset.viewCollapsed">
                <fieldset>
                    <div class="row">
                        <div v-for="field in subset.fields" :key="field.fieldId" class="col-md-6 form-group mb-0">
                            <b-form-checkbox :automation_id="field.automationId + '_visible'" v-model="field.visible">
                                {{field.fieldLabel}}
                            </b-form-checkbox>
                        </div>
                    </div>
                </fieldset>
            </rq-section-card>
        </rq-scroll-container>

    </aside>
</template>

<script>
    import { SelectListItem } from "@/shared/models/models";
    import { SearchFieldSet, SearchFieldGroup, SearchField } from "../models";
    export default {
        props: {
            searchFieldSet: { default: new SearchFieldSet() },
            modelValue: { default: false }
        },
        data () {
            const self = this;
            return {
                collapseContainers: _.fill(Array(10), true),
                psOptions:  {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: true,
                    wheelPropagation: false,
                    interceptRailY: styles => ({ ...styles, height: 0 })
                }
            };
        },
        methods: {
            initCollapseContainers () {
                if (!this.searchFieldSet || !this.searchFieldSet.subsets || this.searchFieldSet.subsets.length <= 0) return;
                this.collapseContainers = _.fill(Array(this.searchFieldSet.subsets.length), true);
            },
            getDataSource (fields) {
                return { key: "id", data: fields };
            },

            showTray () {
                this.$emit("update:modelValue", true);
            },

            hideTray () {
                this.$emit("update:modelValue", false);
            }
        }
    };
</script>
