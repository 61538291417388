import Store from "../../../store";
import { PolicyType } from "../../../shared/models/enums";

export { NumberFormat, ListFormatCharacter, TextControlListType, PolicyType, LoanHolderType } from "../../../shared/models/enums";

export const MODULE_EVENTS = {
    EDIT_RTF_CONTENT: "commitment:edit-rtf-content",
    CREATE_RTF_CONTENT: "commitment:create-rtf-content"
};

const assignedLanguageListType = {
    RestrictiveCovenants: 0,
    Requirements: 1,
    Exceptions: 2,
    SubordinateLiens: 3,
    OwnerSchedB: 4,
    MortSchedBResCov: 5,
    MortSchedBExceptions: 6,
    MortSchedBSubLiens: 7,
    InsuredMortgage: 8,
    RequiredDocuments: 9,
    CustomCategory: 10,
    CustomSections: 11
};
export class AssignedLanguageListType {
    static get RestrictiveCovenants () {return assignedLanguageListType.RestrictiveCovenants;}
    static get Requirements () {return assignedLanguageListType.Requirements;}
    static get Exceptions () {return assignedLanguageListType.Exceptions;}
    static get SubordinateLiens () {return assignedLanguageListType.SubordinateLiens;}
    static get OwnerSchedB () {return assignedLanguageListType.OwnerSchedB;}
    static get MortSchedBResCov () {return assignedLanguageListType.MortSchedBResCov;}
    static get MortSchedBExceptions () {return assignedLanguageListType.MortSchedBExceptions;}
    static get MortSchedBSubLiens () {return assignedLanguageListType.MortSchedBSubLiens;}
    static get InsuredMortgage () {return assignedLanguageListType.InsuredMortgage;}
    static get RequiredDocuments () {return assignedLanguageListType.RequiredDocuments;}
    static get CustomCategory () {return assignedLanguageListType.CustomCategory;}
    static get CustomSections () {return assignedLanguageListType.CustomSections;}

    static displayValue (id) {
        const systemDefaults = _.get(Store, "state.system.systemDefaults", null);
        if (systemDefaults) {
            let typeKey = _.findKey(assignedLanguageListType, v => v === id);
            let listTypeProperty = `listType${typeKey}`;
            let systemValue = systemDefaults[listTypeProperty];
            if (systemValue) return systemValue;
        }
        switch (id) {
            case assignedLanguageListType.RestrictiveCovenants:
                return "Restrictive Covenants";
            case assignedLanguageListType.Requirements:
                return "Requirements";
            case assignedLanguageListType.Exceptions:
                return "Exceptions";
            case assignedLanguageListType.SubordinateLiens:
                return "Subordinate Liens";
            case assignedLanguageListType.OwnerSchedB:
                return "Owner Schedule B";
            case assignedLanguageListType.MortSchedBResCov:
                return "MortSchedBResCov";
            case assignedLanguageListType.MortSchedBExceptions:
                return "MortSchedBExceptions";
            case assignedLanguageListType.MortSchedBSubLiens:
                return "MortSchedBSubLiens";
            case assignedLanguageListType.InsuredMortgage:
                return "Insured Mortgage";
            case assignedLanguageListType.RequiredDocuments:
                return "Required Documents";
            case assignedLanguageListType.CustomCategory:
                return "Custom Category";
            case assignedLanguageListType.CustomSections:
                return "Custom Sections";
        }
        return "Invalid AssignedLanguageListType Value";
    }

    static get titleProductionTypes () {
        return [
            AssignedLanguageListType.Exceptions,
            AssignedLanguageListType.SubordinateLiens,
            AssignedLanguageListType.RestrictiveCovenants,
            AssignedLanguageListType.Requirements,
            AssignedLanguageListType.RequiredDocuments
        ];
    }

    static get policyTypes () {
        return [
            AssignedLanguageListType.OwnerSchedB,
            AssignedLanguageListType.MortSchedBExceptions,
            AssignedLanguageListType.MortSchedBResCov,
            AssignedLanguageListType.InsuredMortgage
        ];
    }

    static get syncedPolicyTypes () {
        return [
            AssignedLanguageListType.OwnerSchedB,
            AssignedLanguageListType.MortSchedBExceptions,
            AssignedLanguageListType.MortSchedBResCov
        ];
    }

    static getSectionName(id, tab=null) {
        switch (id) {
            case assignedLanguageListType.RestrictiveCovenants:
                return "ScheduleBRestrictiveCovenants";
            case assignedLanguageListType.Requirements:
                return tab === "B" ? "ScheduleBRequirements" : "ScheduleCRequirements";
            case assignedLanguageListType.Exceptions:
                return "ScheduleBExceptions";
            case assignedLanguageListType.SubordinateLiens:
                return "ScheduleBSubordinateLiens";
            case assignedLanguageListType.OwnerSchedB:
                return "OwnerPolicyExceptions";
            case assignedLanguageListType.MortSchedBResCov:
                return "LoanPolicyRestrictiveCovenants";
            case assignedLanguageListType.MortSchedBExceptions:
                return "LoanPolicyExceptions";
            case assignedLanguageListType.MortSchedBSubLiens:
                return "LoanPolicySubordinateLiens";
            case assignedLanguageListType.InsuredMortgage:
                return "LoanPolicyInsuredMortgage";
            case assignedLanguageListType.RequiredDocuments:
                return "ScheduleBRequiredDocuments";
            default:
                return null;
        }
    }

    static getTitleProductionLookupItems (isAlta, tabLetter, systemDefaults) {
        const altaTypes = [1, 2, 9];
        const txBTypes = [2, 3, 0, 9];
        const txCTypes = [1];
        let returnTypes = isAlta ? altaTypes :
            tabLetter.toUpperCase() === "B" ? txBTypes :
                tabLetter.toUpperCase() === "C" ? txCTypes : [];
        return _.map(returnTypes, typeVal => ({ id: typeVal, name: AssignedLanguageListType.displayValue(typeVal) }));
    }

    static getPolicyLookupItems (isAlta, policyType, systemDefaults) {
        const ownerTypes = [4];
        const loanTypes = isAlta ? [6, 7, 8] : [6, 5, 8];
        let returnTypes = policyType === PolicyType.Owner ? ownerTypes :
            policyType === PolicyType.Loan ? loanTypes : [];
        return _.map(returnTypes, typeVal => ({ id: typeVal, name: AssignedLanguageListType.displayValue(typeVal) }));
    }

    static get lookupItems () {
        return _.map(assignedLanguageListType, a => ({ id: a, name: AssignedLanguageListType.displayValue(a) }));
    }
}

const appliesToType = {
    All: 0,
    Owner: 1,
    Loan: 2,
    None: 3
};
export class AppliesToType {
    static get All () {return appliesToType.All;}
    static get Owner () {return appliesToType.Owner;}
    static get Loan () {return appliesToType.Loan;}
    static get None () {return appliesToType.None;}

    static displayValue (id) {
        switch (id) {
            case appliesToType.All:
                return "All";
            case appliesToType.Owner:
                return "Owner Policy";
            case appliesToType.Loan:
                return "Loan Policy";
            case appliesToType.None:
                return "None";
        }
        return "";
    }

    static displayIcon (id) {
        switch (id) {
            case appliesToType.Owner:
                return "fas fa-user";
            case appliesToType.Loan:
                return "fas fa-landmark";
            case appliesToType.None:
                return "fas fa-ban";
        }
        return "";
    }

    static get lookupItems () { return this.getLookupItems(); }

    static getLookupItems (keyExpr="id", textExpr="name") {
        return _.map(appliesToType, a => {return { [keyExpr]: a, [textExpr]: AppliesToType.displayValue(a) };});
    }

    static getPolicyAppliesToTypes (policyType=null) {
        const all = [0,1,2,3];
        const ownerTypes = [0,1];
        const loanTypes = [0,2];
        if (policyType === PolicyType.Owner) return ownerTypes;
        if (policyType === PolicyType.Loan) return loanTypes;
        return all;
    }
}

const ezJacketPolicyStatus = {
    Pending: 0, NonRemitted: 1, Remitted: 2
};
export class EzJacketPolicyStatus {
    static get Pending () {return ezJacketPolicyStatus.Pending;}
    static get NonRemitted () {return ezJacketPolicyStatus.NonRemitted;}
    static get Remitted () {return ezJacketPolicyStatus.Remitted;}

    static get lookupItems () {
        return _.map(ezJacketPolicyStatus, c => { return { id: c, name: EzJacketPolicyStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(ezJacketPolicyStatus, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const ezJacketPropertyType = {
    Residential: 0, NonResidential: 1
};
export class EzJacketPropertyType {
    static get Residential () {return ezJacketPropertyType.Residential;}
    static get NonResidential () {return ezJacketPropertyType.NonResidential;}

    static get lookupItems () {
        return _.map(ezJacketPropertyType, c => { return { id: c, name: EzJacketPropertyType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(ezJacketPropertyType, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const ezJacketPolicyCategory = {
    Owners: 0, Lenders: 1
};
export class EzJacketPolicyCategory {
    static get Owners () {return ezJacketPolicyCategory.Owners;}
    static get Lenders () {return ezJacketPolicyCategory.Lenders;}

    static get lookupItems () {
        return _.map(ezJacketPolicyCategory, c => { return { id: c, name: EzJacketPolicyCategory.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(ezJacketPolicyCategory, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const ezJacketRemittanceStatus = {
    Voided: 0, Edited: 1
};
export class EzJacketRemittanceStatus {
    static get Voided () {return ezJacketRemittanceStatus.Voided;}
    static get Edited () {return ezJacketRemittanceStatus.Edited;}

    static get lookupItems () {
        return _.map(ezJacketRemittanceStatus, c => { return { id: c, name: EzJacketRemittanceStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(ezJacketRemittanceStatus, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}
