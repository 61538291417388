<template>
    <rq-page-section title="Loan" collapsible>
        <template #header-actions>
            <ul class="nav ms-auto">
                <li class="nav-item">
                    <rq-section-expand-collapse-all />
                </li>
            </ul>
        </template>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <rq-action-form-group
                        label="Loan Amount"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="false"
                        @action-click="onRevertLoanAmountOverride">
                        <rqInputNumber
                            id="txt_loan_amount"
                            automation_id="txt_loan_amount"
                            ref="txt_loan_amount"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            @blur="onEditLoanAmount"
                            v-model="loanTerm.loanAmount"
                        />
                    </rq-action-form-group>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="row">
                        <!--Years-->
                        <div class="col-12 col-sm-4 form-group">
                            <rq-action-form-group
                                label="Term &amp; Length"
                                labelFor="txt_term_years"
                                :show-action="orderSummary.isReverseMortgage"
                            >
                                <template #action>
                                    <b-form-checkbox
                                        id="chk_loan_term"
                                        automation_id="chk_loan_term"
                                        v-model="loanTerm.hudLoanTermNA"
                                    >
                                        N/A
                                    </b-form-checkbox>
                                </template>
                                <template #default>
                                    <div class="input-group">
                                        <rqInputNumber
                                                id="txt_term_years"
                                                automation_id="txt_term_years"
                                                defaultValue="0"
                                                decimals="0"
                                                v-focus-select-all
                                                @blur="onEditTermsLengthYears"
                                                v-model="termsLengthYears">
                                            </rqInputNumber>
                                            <span class="input-group-text">Years</span>
                                    </div>
                                </template>
                            </rq-action-form-group>
                        </div>

                        <!--Months-->
                        <!-- <div class="col-12 col-sm-4 form-group" :class="{'overridden': loanTerm.hudInitialLoanTermOverride}"> -->
                            <div class="col-12 col-sm-4 form-group">
                            <label for="txt_term_months">&nbsp;</label>
                            <div class="input-group">
                                <rqInputNumber
                                    id="txt_term_months"
                                    automation_id="txt_term_months"
                                    defaultValue="0"
                                    decimals="0"
                                    v-focus-select-all
                                    @blur="onEditTermsLengthMonths"
                                    v-model="termsLengthMonths">
                                </rqInputNumber>
                                <span class="input-group-text">Months</span>
                            </div>
                        </div>

                        <!--Days-->
                        <!-- <div class="col-12 col-sm-4 form-group" :class="{'overridden': loanTerm.hudInitialLoanTermOverride}"> -->
                        <div class="col-12 col-sm-4 form-group">
                            <label for="txt_term_days">&nbsp;</label>
                            <div class="input-group">
                                <rqInputNumber
                                    id="txt_term_days"
                                    automation_id="txt_term_days"
                                    defaultValue="0"
                                    decimals="0"
                                    v-focus-select-all
                                    @blur="onEditTermsLengthDays"
                                    v-model="termsLengthDays">
                                </rqInputNumber>
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-3 form-group">
                    <label for="txt_increase_amount">Can this amount increase?</label>
                    <rq-radio-group 
                            v-model="loanTerm.isLoanAmountIncrease"
                            inline
                            :options="[
                                { automation_id: 'radio_no', value: false, text: 'NO'},
                                { automation_id: 'radio_yes', value: true, text: 'YES'}
                            ]"
                        />
                </div>
                <!-- Loan Amount Text Override -->
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_can_rise_to_max_of">Can rise to a maximum of</label>
                    <rqInputNumber
                        id="txt_can_rise_to_max_of"
                        automation_id="txt_can_rise_to_max_of"
                        defaultValue="0"
                        decimals="2"
                        input-group
                        :disabled="!loanTerm.isLoanAmountIncrease"
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="loanTerm.maxLoanAmount"
                    />
                </div>
            </div>
        </fieldset>
    </rq-page-section>
</template>
<script>

import { mapState } from "vuex";
import { LoanTermSectionHud2010DataModel } from '@settlement/models';
import LoanTermLengthMixin from "@order-entry/loans/LoanTermLengthMixin";
export default {
    mixins: [LoanTermLengthMixin],
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        loanTerm: {
            type: Object,
            default: () => {
                return new LoanTermSectionHud2010DataModel()
            }
        },
        originalLoanTerm: {
            type: Object,
            default: () => {
                return new LoanTermSectionHud2010DataModel()
            }
        },
        loan: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data: function() {
        return {
        }
    },
    computed:{
        ...mapState({
            loans: state => state.orders.loans || [],
            orderSummary: state => state.orders.orderSummary,
        }),
        isLoanTermLengthOverridden() {
            return this.termsLengthYears !== this.originalLengthTermData.termsLengthYears
                    || this.termsLengthMonths !== this.originalLengthTermData.termsLengthMonths
                    || this.termsLengthDays !== this.originalLengthTermData.termsLengthDays;
        },
        originalLengthTermData() {
            return this.loanTermLengthMixin_getTermsData(this.loan);
        }
    },
    watch: {
        loanTerm: function(val) {
            let self = this;
            this.loanTermLengthMixin_clearTermLength();
            this.loanTermLengthMixin_setTimePeriod({
                term: _.parseNumber(self.loan.term, 0),
                loanTermPeriod: _.parseNumber(self.loan.loanTermPeriod, 0),
                loanMonths: _.parseNumber(self.loan.loanMonths, 0)
            });
        },
        // "loanTerm.loanAmount": {
        //     immediate: false,
        //     handler: function(newVal, oldVal) {
        //         if(oldVal === undefined || newVal === oldVal) return;
        //         this.loanTerm.hudInitialLoanAmountOverride = this.loanTerm.loanAmount !== this.loan.amount;
        //     }
        // },
        // termsLengthYears: function(val) {
    },
    mounted() {
        this.resetFocus();
    },
    methods: {
        onRevertLoanAmountOverride() {
            this.loanTerm.loanAmount = this.loan.amount;
            this.loanTerm.hudInitialLoanAmountOverride = false;
        },
        onRevertLoanTermLengthOverride() {
            let self = this;
            this.loanTermLengthMixin_setTimePeriod({
                term: _.parseNumber(self.loan.term, 0),
                loanTermPeriod: _.parseNumber(self.loan.loanTermPeriod, 0),
                loanMonths: _.parseNumber(self.loan.loanMonths, 0)
            });
            this.loanTerm.hudInitialLoanTermOverride = false;
        },
        updateTermPeriod(data) {
            this.loanTerm.hudLoanTermPeriod = data.loanTermPeriod;
            this.loanTerm.hudTerm = data.term;
            this.loanTerm.hudLoanMonths = data.loanMonths;
            // this.loanTerm.hudInitialLoanTermOverride = this.isLoanTermLengthOverridden;
        },
        onEditLoanAmount(e) {
            const self = this;
            // let loan = self.selectedLoan(self.selectedLoanId);
            let newLoanAmount = e.value;
            let origLoanAmount = _.isNil(self.loan.amount) ? 0 : self.loan.amount;

            let handleOk = function(e) {
                return true;
            }

            let handleCancel = function(e) {
                self.loanTerm.loanAmount = origLoanAmount;
                return true;
            }

            // values match so no need to prompt with confirmation dialog
            if (newLoanAmount !== origLoanAmount) {
                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Loan Amount for Loan #${self.loan.loanOrder} for this file.`,
                    handleOk, // handleOk
                    handleCancel, // handleCancel
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            }
        },
        onEditTermsLengthYears(e) {
            const self = this;
            let newLengthYears = e.value;
            let origLengthYears = self.termsLengthYears;

            let handleOk = function(e) {
                if (newLengthYears > 0 && self.termsLengthMonths > 11) {
                    self.termsLengthMonths = 0;
                }
                if (newLengthYears > 0 || self.termsLengthMonths > 0){
                    self.termsLengthDays = 0;
                }

                let data = self.loanTermLengthMixin_getTermPeriod();
                self.updateTermPeriod(data);
                return true;
            }

            let handleCancel = function(e) {
                self.termsLengthYears = origLengthYears;
                return true;
            }

            // values match so no need to prompt with confirmation dialog
            if (newLengthYears !== origLengthYears) {
                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Loan Terms for Loan #${self.loan.loanOrder} for this file.`,
                    handleOk, // handleOk
                    handleCancel, // handleCancel
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            }
        },
        onEditTermsLengthMonths(e) {
            const self = this;
            let newLengthMonths = e.value;
            let origLengthMonths = self.termsLengthMonths;

            let handleOk = function(e) {
                if (newLengthMonths > 11) {
                    self.termsLengthYears = 0;
                }
                if (self.termsLengthYears > 0 || newLengthMonths > 0){
                    self.termsLengthDays = 0;
                }

                let data = self.loanTermLengthMixin_getTermPeriod();
                self.updateTermPeriod(data);
                return true;
            }

            let handleCancel = function(e) {
                self.termsLengthMonths = origLengthMonths;
                return true;
            }

            // values match so no need to prompt with confirmation dialog
            if (newLengthMonths !== origLengthMonths) {
                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Loan Terms for Loan #${self.loan.loanOrder} for this file.`,
                    handleOk, // handleOk
                    handleCancel, // handleCancel
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            }
        },
        onEditTermsLengthDays(e) {
            const self = this;
            let newLengthDays = e.value;
            let origLengthDays = self.termsLengthDays;

            let handleOk = function(e) {
                if (newLengthDays > 0) {
                    self.termsLengthMonths = 0;
                    self.termsLengthYears = 0;
                }

                let data = self.loanTermLengthMixin_getTermPeriod();
                self.updateTermPeriod(data);
                return true;
            }

            let handleCancel = function(e) {
                self.termsLengthDays = origLengthDays;
                return true;
            }

            // values match so no need to prompt with confirmation dialog
            if (newLengthDays !== origLengthDays) {
                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Loan Terms for Loan #${self.loan.loanOrder} for this file.`,
                    handleOk, // handleOk
                    handleCancel, // handleCancel
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            }
        },
        resetFocus() {
            if (this.loans.length == 1)
                this.$refs.txt_loan_amount.focus();
        }
    }
}
</script>