<template>
    <div class="row">
        <div class="col-3 form-group mb-0">
            <label for="drp_underwriter">Underwriter</label>
            <dx-select-box
                ref="underwriterSelectBox"
                :input-attr="$utils.idAttrs('drp_underwriter')"
                value-expr="id"
                display-expr="name"
                :items="underwriters"
                :disabled="readOnly"
                v-model="rateOrderInfo.underwriterCompanyID"
                @value-changed="onUnderwriterChange"
            />
        </div>
        <div class="col-3 form-group mb-0">
            <label for="drp_service">Service</label>
            <dx-select-box
                :input-attr="$utils.idAttrs('drp_service')"
                value-expr="id"
                display-expr="name"
                :items="orderServices"
                :disabled="readOnly"
                v-model="rateOrderInfo.service"
                @value-changed="onServiceChange"
            />
        </div>
        <div v-show="simultaneousEligibleService"
            class="col-3 form-group mb-0">
            <label for="drp_is_simultaneous">Is this a simultaneous issue?</label>
            <dx-select-box
                :input-attr="$utils.idAttrs('drp_is_simultaneous')"
                value-expr="id"
                display-expr="name"
                :items="simultaneousLookupItems"
                :disabled="readOnly"
                v-model="selectedSimultaneousValue"
                @value-changed="onSimultaneousChange"
            />
        </div>
        <div v-show="simultaneousLoanSelection"
            class="col-3 form-group mb-0">
            <label for="tb_loans">Loans Affected</label>
            <dx-tag-box
                automation_id="tb_loans"
                ref="tb_loans"
                class="form-control"
                :data-source="loans"
                :display-expr="item => item ? `${item.loanOrder} - ${item.lender || ''}` : ''"
                value-expr="loanID"
                :show-selection-controls="true"
                :show-clear-button="true"
                :max-displayed-tags="1"
                :show-drop-down-button="true"
                apply-value-mode="useButtons"
                v-model:value="simultaneousLoanIDs"                
                @valueChanged="onSimultaneousLoanChange"
                :disabled="readOnly"                
            />
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { RATE_ACTIONS } from "@/store/actions";
    import { OrderServiceType } from '@/shared/models/enums';
    import { RateOrderInfoDto } from "@settlement/models";
    import { SimultaneousOptions } from "@settlement/models/OrderRateCalculation";

    export default {
        props: {
            premiums: { type: Array, default: () => [] },
            simultaneousOption: { type: Number, default: 0 },
            readOnly: { type: Boolean, default: false },
        },

        data(){
            return {
                rateOrderInfo: new RateOrderInfoDto(),
                underwriterReady: false,
                simultaneousLoanIDs: []
            };
        },

        computed: {
            ...mapState({
                storedRateOrderInfo: state => state.rateEngine.rateOrderInfo,
                loans: state => state.rateEngine.loans,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            orderId() { return _.getNumber(this, "$store.state.orders.orderId", 0); },
            simultaneousLookupItems() { return SimultaneousOptions.lookupItems; },
            simultaneousEligibleService() { return _.includes(OrderServiceType.simultaneousEligibleTypes, this.rateOrderInfo.service); },
            simultaneousLoanSelection() { return this.loans.length > 1 && this.selectedSimultaneousValue === SimultaneousOptions.OwnerWithSimultLoan }, 
            orderServices() { return this.lookupHelpers.getOrderServices(); },
            underwriters() { return this.lookupHelpers.getUnderwriters(_.getNumber(this, "$store.state.orders.orderSummary.regionID", 0)); },
            selectedSimultaneousValue: {
                get() { return this.simultaneousOption; },
                set(val) { this.$emit("update:simultaneousOption", val); }
            },
        },

        watch: {
            storedRateOrderInfo: {
                handler(newValue, oldValue) {
                    if(_.isNil(newValue) || newValue === oldValue) return;
                    this.rateOrderInfo = new RateOrderInfoDto(newValue);
                },
                immediate: true
            },
            premiums: {
                handler(newValue, oldValue) {
                    this.simultaneousLoanIDs = _.map(_.filter(newValue, p => !_.isNil(p.loanID) && p.simultaneousCalculation), 'loanID');
                },
                immediate: true,
                deep: true
            }            
        },
        
        methods:{
            onUnderwriterChange(e) {
                if(_.isNil(e.event)) return;
                this.save({
                    target: "UnderwriterCompanyID",
                    message:"The underwriter was changed and calculations on the order may need to be updated."
                });
            },

            onServiceChange(e) {
                if(_.isNil(e.event)) return;
                this.save({
                    target: "Service",
                    message:"The order service was changed and calculations on the order may need to be updated."
                });
            },

            onSimultaneousChange(e) {
                if(_.isNil(e.event)) return;
                this.$emit("simultaneous-change", e);
            },

            onSimultaneousLoanChange(e) {
                if(_.isNil(e.event)) return;
                this.$emit("simultaneous-loan-change", e);
            },

            focus() {
                _.invoke(this, "$refs.underwriterSelectBox.instance.focus");
            },

            save(alert=null){
                const self = this;
                let storePromise = self.$store.dispatch(RATE_ACTIONS.SAVE_RATE_ORDER_INFO, self.rateOrderInfo.toDataObject());
                return self.$parent.$rqBusy.wait(storePromise)
                    .then(() => {
                        self.$emit("changed", { alert });
                    })
                    .catch(error => {
                        self.$toast.error("An issue occurred saving file information.");
                        console.error(error);
                    });
            },
        },
    };
</script>