<template>
    <div class="btn-group rq-section-dropdown">
        <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="disabled">Add</button>
        <ul class="dropdown-menu">
            <li><button type="button" class="dropdown-item" automation_id="new_property_address" @click="onAddEmpty">New Property</button></li>
            <li v-if="this.showCopyCurrent"><button type="button" class="dropdown-item" automation_id="current_property_address" @click="onCopyCurrent">Current Property Address</button></li>
            <li><hr class="dropdown-divider" /></li>
            <li><h6 class="dropdown-header">Buyer</h6></li>
            <li><button type="button" class="dropdown-item" automation_id="buyer_primary_address" @click="onAddBuyerPrimary">Primary Address</button></li>
            <li><button type="button" class="dropdown-item" automation_id="buyer_forwarding_address" @click="onAddBuyerForwarding">Forwarding Address</button></li>
            <li><hr class="dropdown-divider" /></li>
            <li><h6 class="dropdown-header">Seller</h6></li>
            <li><button type="button" class="dropdown-item" automation_id="seller_primary_address" @click="onAddSellerPrimary">Primary Address</button></li>
            <li><button type="button" class="dropdown-item" automation_id="seller_forwarding_address" @click="onAddSellerForwarding">Forwarding Address</button></li>
        </ul>
    </div>
</template>

<script>
    const BUYER_SELLER_TYPE = { Buyer: 0, Seller: 1 };
    const ADDRESS_TYPE = { Primary: 0, Forwarding: 1 };

    export default {
        name: "AddPropertyDropdown",
        props: {
            showCopyCurrent: { type:Boolean, default: false },
            disabled: { type:Boolean, default: false }
        },
        methods: {
            onAddEmpty() { this.$emit("add-property"); },
            onCopyCurrent() { this.$emit("copy-current"); },
            onAddBuyerPrimary() { this.emitEvent( BUYER_SELLER_TYPE.Buyer, ADDRESS_TYPE.Primary); },
            onAddBuyerForwarding() { this.emitEvent( BUYER_SELLER_TYPE.Buyer, ADDRESS_TYPE.Forwarding); },
            onAddSellerPrimary() { this.emitEvent( BUYER_SELLER_TYPE.Seller, ADDRESS_TYPE.Primary); },
            onAddSellerForwarding() { this.emitEvent( BUYER_SELLER_TYPE.Seller, ADDRESS_TYPE.Forwarding); },
            emitEvent(buyerSellerType=null, addressType=null) { this.$emit("add-property", { buyerSellerType, addressType }); }
        }
    };

</script>
