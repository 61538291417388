<template>
    <div class="content-wrapper">
        <div class="rq-master-detail-container">
            <section>
                <rqdx-action-data-grid
                    ref="fixesDataGrid"
                    title="Receipts and Disbursements Info"
                    title-size="sm"
                    automation_id="tbl_fixes"
                    :actions="fixActions"
                    :config="fixGridConfig"
                    :data-source="fixGridDataSource"
                    export-file-name="sync_fixes"
                    class="pb-0"
                    @fix="onFixSelected"
                    hide-search>
                    <template #toolbar>
                        <ul class="nav ms-2 me-auto">
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_fix_all"
                                    variant="theme"
                                    v-rq-tooltip.hover.top
                                    title="Fix all auto-fix issues"
                                    @click="onFixAll">Fix All
                                </b-btn>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
            <section>
                <div class="rq-container content-wrapper">
                    <div class="row flex-fill">
                        <div class="col col-6 flex-fill">
                            <rqdx-action-data-grid
                                ref="existingItemsDataGrid"
                                title="Existing Checks/Deposits"
                                title-size="sm"
                                class="h-100"
                                automation_id="tbl_existing"
                                :config="existingGridConfig"
                                :data-source="existingGridDataSource"
                                export-file-name="sync_existing"
                                :persist-state="false"
                                hide-search
                                hide-show-column-chooser
                            />
                        </div>
                        <div class="col col-6 flex-fill">
                            <rqdx-action-data-grid
                                ref="expectedItemsDataGrid"
                                title="Expected Checks/Deposits"
                                title-size="sm"
                                class="h-100"
                                automation_id="tbl_expected"
                                :config="expectedGridConfig"
                                :data-source="expectedGridDataSource"
                                export-file-name="sync_expected"
                                :persist-state="false"
                                hide-search
                                hide-show-column-chooser
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SyncCheckDepositDto} from "../models";
import DxGridUtils from "@/shared/utilities/DxGridUtils";

export default {
    name: "SyncReceiptsAndDisbursements",
    props: {
        syncData: { type: Object, required: true, default: () => {} },
        source: {type: Object, required: true, default: () => {}},
    },
    data () {
        return {
            fixes: [],
            existingItems: [],
            expectedItems: [],
            selectedFixes: [],
            somethingFixed: false
        };
    },
    created(){
        this.initData(this.syncData);
        this.initNonReactiveVariables();
        this.initGridConfig();
    },
    beforeUnmount () {
    },
    watch: {

    },
    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
        ...mapState({
            orderId: state => state.orders.orderId,
            order: state => state.orders.order,
            isReadOnly: state => _.parseBool(state.isPageReadOnly),
            isOrderLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
            systemDefaults: state => state.system.systemDefaults,
            user: state => state.authentication.session.user
        }),
        fixesGrid() { return _.get(this, "$refs.fixesDataGrid.gridInstance", null); },
        existingGrid() { return _.get(this, "$refs.existingItemsDataGrid.gridInstance", null); },
        expectedGrid() { return _.get(this, "$refs.expectedItemsDataGrid.gridInstance", null); },
    },
    methods: {
        initData(syncData){
            const self = this;
            if(syncData){
                self.fixes = _.map(syncData.fixes, f => new SyncCheckDepositDto(f));
                self.expectedItems = _.map(syncData.expectedChecksAndDeposits, e => new SyncCheckDepositDto(e));
                self.existingItems = _.map(syncData.actualChecksAndDeposits, a => new SyncCheckDepositDto(a));
            }
        },
        initGridConfig(){
            const self = this;
            self.clientKey = "clientKey";
            self.fixGridDataSource = {
                key: self.clientKey,
                load () {
                    return Promise.resolve(self.fixes);
                },
            };
            self.fixGridConfig = {
                paging: { enabled: false },
                columns: [
                    { dataField: "canAutoFix", caption: "Auto-fix?", minWidth: 100, dataType: "boolean",cellTemplate: DxGridUtils.boolCellTemplate},
                    { dataField: "action", dataType: "string", caption: "List of changes in Settlement Statment that need to be corrected in File"},
                    { dataField: "settlementDescription", dataType: "string", caption: "Settlement Number"}
                ],
                onCellPrepared: function(e) {
                    if (e.rowType === "data" & e.column.command === 'select' && e.data.canAutoFix === false) {
                        e.cellElement.find('.dx-select-checkbox').dxCheckBox("instance").option("disabled", true);
                        e.cellElement.off();
                    }
                },
                onSelectionChanged: function(e) {
                    $.each(e.selectedRowsData, function(i, v) {
                        if (v.canAutoFix === false){
                        e.component.deselectRows(e.selectedRowKeys[i]); return;
                        }
                    })
                }
            };
            self.existingGridDataSource = {
                key: self.clientKey,
                load () {
                    return Promise.resolve(self.existingItems);
                },
            };
            self.existingGridConfig = {
                selection: { mode: "none" },
                paging: { enabled: false },
                columns: [
                    { dataField: "lineDescription", dataType: "string", caption: "Line" },
                    {
                        dataField: "amount",
                        caption: "Amount",
                        allowSearch: false,
                        dataType: "number",
                        minWidth: 75,
                        format: {
                            type: "currency",
                            precision: 2
                        }
                    },
                    DxGridUtils.dateColumn({
                        dataField: "date",
                    }),
                    { dataField: "payeePayor", dataType: "string", caption: "Payee/Payor", width: 175 },
                    { dataField: "description", dataType: "string", caption: "Descriptions", width: 200 }
                ],
                wordWrapEnabled: false
            };
            self.expectedGridDataSource = {
                key: self.clientKey,
                load () {
                    return Promise.resolve(self.expectedItems);
                },
            };
            self.expectedGridConfig = {
                selection: { mode: "none" },
                paging: { enabled: false },
                columns: [
                    { dataField: "lineDescription", dataType: "string", caption: "Line" },
                    {
                        dataField: "amount",
                        caption: "Amount",
                        allowSearch: false,
                        dataType: "number",
                        minWidth: 75,
                        format: {
                            type: "currency",
                            precision: 2
                        }
                    },
                    DxGridUtils.dateColumn({
                        dataField: "date",
                    }),
                    { dataField: "payeePayor", dataType: "string", caption: "Payee/Payor", width: 175 },
                    { dataField: "description", dataType: "string", caption: "Descriptions", width: 200 }
                ],
                wordWrapEnabled: false
            };
        },
        initNonReactiveVariables() {
            const self = this;
            self.fixActions = [
                { name: "fix", text: "Fix Differences", eventName: "fix", requireSelection: true, allowMultiSelection: true, tooltip: `Fix selected item(s)` },
            ];

        },
        onFixAll(e){
            const self = this;
            let validFixes = _.filter(self.fixes, f => {if(f.canAutoFix === true) return f;});
            self.submitFixes(validFixes, true);
        },
        onFixSelected(e){
            if(!e || !e.data) return;
            const self = this;
            let validFixes = _.filter(e.data, f => {if(f.canAutoFix === true) return f;});
            self.submitFixes(validFixes);
        },
        submitFixes(fixes, fixAll = false){
            const self = this;

            let apiPromise = self.$api.CheckWritingApi.applyFixes(self.order.ordersID, fixes);

            self.$rqBusy.wait(apiPromise)
                .then(() => {
                    self.somethingFixed = true;
                    if(fixAll){
                        self.$emit("ok");
                    }
                    else{
                        let promise = self.$api.CheckWritingApi.getSyncData(self.order.ordersID, self.source);
                        self.$rqBusy.wait(promise)
                            .then(result => {
                                self.initData(result);
                                self.refreshGrids();
                            });
                    }
                })
                .catch(error => {
                    self.$toast.error("Error fixing selected item(s).");
                });
        },
        refreshGrids(){
            const self = this;
            self.fixesGrid.refresh();
            self.existingGrid.refresh();
            self.expectedGrid.refresh();
        },
        updateDimensions: _.debounce(function() {
            const self = this;
            self.fixesGrid.updateDimensions();
            self.existingGrid.updateDimensions();
            self.expectedGrid.updateDimensions();
        }, 300)
    }
}
</script>
