<template>
    <div class="content-wrapper tp-policy-main">

        <order-summary/>

        <rq-scroll-container v-if="useScrollLayout">
            <router-view v-slot="{ Component }">
                <component
                    :key="orderId"
                    :is="Component"
                    @loan-selected="onLoanSelected"
                />
            </router-view>
        </rq-scroll-container>

        <router-view v-else v-slot="{ Component }">
            <component
                :key="orderId"
                :is="Component"
                @loan-selected="onLoanSelected"
            />
        </router-view>

    </div>
</template>

<script>
    import { mapState} from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import { MODULE_EVENTS } from "./enums";
    import { DocumentFileType } from "@documents/enums";

    import { OrderSummary } from "../shared/components/";
    import { DocumentEditorDialog } from "@/shared/components/rq";

    export default {
        name: "CommitmentMain",

        components: { OrderSummary },

        computed: {
            ...mapState({
                storedOrderId: state => state.orders.orderId,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                gfNo: state => state.orders.order.gfNo,
            }),
            useScrollLayout() { return _.getBool(this, "$route.meta.useScrollLayout"); },
            loanId() { return _.getNumber(this, "$route.params.loanId", 0); },
            routeOrderId() { return _.getNumber(this, "$route.params.orderId", 0); },
            orderId() { return this.routeOrderId > 0 ? this.routeOrderId : this.storedOrderId; },
        },

        watch: {
            routeOrderId: {
                handler(newValue, oldValue) {
                    if(this.readOnly) return;
                    this.$store.dispatch(DOCUMENT_ACTIONS.GET_STANDARD_LANGUAGE_CATEGORIES);
                },
                immediate: true
            }
        },

        created () {
            const self = this;
            if (self.readOnly) return;

            //GlobalEventManager.onFireAction(this, this.handleAction);
            self.registerListeners();
        },

        beforeUnmount () {
            this.unregisterListeners();
            GlobalEventManager.unregister(this);
        },

        methods: {
            //RQO-24436 disable title production version/audit log
            // handleAction(e){
            //     switch (e.key) {
            //         case "tp-audit-log":
            //             this.openAuditLogDialog(e.props);
            //             break;
            //         case "tp-create-label":
            //             this.openAuditLogCommentDialog();
            //             break;
            //     }
			// },

            registerListeners() {
                this.$events.on(MODULE_EVENTS.CREATE_RTF_CONTENT, this.onLaunchEditorEvent);
                this.$events.on(MODULE_EVENTS.EDIT_RTF_CONTENT, this.onLaunchEditorEvent);
            },
            unregisterListeners() {
                this.$events.off(MODULE_EVENTS.CREATE_RTF_CONTENT);
                this.$events.off(MODULE_EVENTS.EDIT_RTF_CONTENT);
            },

            onLoanSelected(e){
                if(!e || !e.data) return;
                let loanId = _.isObject(e.data)
                    ? _.getNumber(e, "data.loanID", 0)
                    : _.parseNumber(e.data, 0);
                if(loanId === 0) return;
                this.selectLoan(loanId);
            },

            selectLoan(loanId){
                this.$router.push({
                    name: _.startsWith(this.$route.name, "tp:")
                        ? "tp:loan:form"
                        : "policy:loan:form",
                    params: { orderId: this.orderId, loanId }
                });
            },

            onLaunchEditorEvent(e) {
                const self = this;
                self.showDocumentEditorDialog(e);
            },

            showDocumentEditorDialog({
                title="Title Production Editor",
                documentName=null,
                documentContent=null,
                fileType=DocumentFileType.RTF,
                customFields=[],
                showInsertClauses=false,
                enableClausePromptEntry=false,
                defaultCategoryId=null,
                categories=[],
                showAppliesTo=false,
                showPrompt=false,
                showPromptValues=false,
                onComplete=null,
                onCancelled=null,
                onAction=null,
                resultFormats={ html:true, rtf:true, docx:false, fragment:true }
            }) {
                const self = this;
                const onCancel = function(e) {
                    if(!_.isFunction(onCancelled)) return true;
                    return onCancelled();
                };
                const onOk = function(e) {
                    let componentPromise = e.component.getContent(resultFormats);
                    return componentPromise
                        .then(contentResult => {
                            if(!contentResult.changed || !_.isFunction(onComplete)){
                                return onCancel();
                            }
                            let completeResult = onComplete(contentResult);
                            if(_.isNil(completeResult)) return true;
                            return completeResult;
                        });
                };
                const onComponentAction = function(e) {
                    if(!_.isFunction(onAction)) return false;
                    return _.parseBool(onAction(e));
                };
                self.$dialog.open({
                    title,
                    height: "95%",
                    width: "95%",
                    scrollable: false,
                    draggable: false,
                    component: DocumentEditorDialog,
                    props: {
                        documentName,
                        documentContent,
                        fileType,
                        customFields,
                        showInsertClauses,
                        enableClausePromptEntry,
                        showAppliesTo,
                        showPrompt,
                        showPromptValues,
                        defaultCategoryId,
                        categories,
                        showInsertDocLink: true,
                        orderId: self.orderId,
                        loanId: self.loanId
                    },
                    onOk,
                    onCancel,
                    onComponentAction
                });
            }
        }
    };
</script>
