<template>
    <div class="rq-master-detail-container">
        <section @click="onGridClick">
            <rqdx-action-data-grid
                ref="dataGrid"
                :automation_id="automationId"
                :actions="selectionActions"
                :config="gridConfig"
                class="rq-tab-content-grid"
                :data-source="gridDataSource"
                :strikethrough-if-true="['inactive']"
                :read-only="readOnly"
                @edit="onEditItem"
                @ten99="onEdit1099"
                @delete="onDeleteItem"
                @print-item="onPrintItem"
                @print-cover-letter="onPrintCoverLetter"
                @print-receiptofDeposit="onPrintReceiptOfDeposit"
                @rowDoubleClick="onEditItem"
                @view-payee="onViewPayeeInfo"
                export-file-name="check_writing_ledger_data"
                integrated-search
                rq-filters
            />
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { MoneyMovementApprovalDto, CheckPrintRequestDto, DepositShortDto }  from "../models";
    import CheckWritingPrintForm from "./CheckWritingPrintForm.vue";
    import CheckWritingManualPayee from "../components/CheckWritingManualPayee";
    import { CheckStatus, CheckStatusFilter, DepositStatus, FileBalanceStatus } from '../enums';
    import { DepositEditingOptions } from '../../../configuration/enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DisplayAnticipatedDeposits } from "@config/enums";

    export default {
        name:"CheckWritingLedgerList",
        props: {
            bank: { type: Object, required: true, default: () => ({}) },
            items: { type: Array, default: () => [] },
            itemDetail: { type: Array, required: true, default: () => [] },
            summary: { type: Object, required: true, default: () => ({}) },
        },
        data () {
            return {
                errorMessage: "",
                searchText: "",
                selectionActions: [],
                approvalPopover: { target: null, item: {}, visible: false },
                popover: { target: null, isCheck: false, item: {}, itemDetail: [], visible: false, title: "" }
            };
        },

        watch: {
            bank:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                }
            },
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
            errorMessage(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit("update-error-message", this.errorMessage);
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                contacts: state => state.orders.contacts.contacts,
                systemDefaults: state => state.system.systemDefaults,
                order: state => state.orders.order,
                isConsolidatedFile: state => _.parseBool(state.orders.orderSummary.isConsolidatedFile),
                readOnly: state => _.parseBool(state.isPageReadOnly) || _.parseBool(state.orders.orderSummary.isLocked) || _.parseBool(state.orders.orderSummary.isEscrowLocked),
            }),
            automationId() {return this.isConsolidatedFile ? 'tbl_consolidated_ledger' : 'tbl_ledger'},
            filteredItems() { return _.isEqual(this.bank.companyID, 0) ? _.clone(this.items) : _.filter(_.clone(this.items), ["bankCompanyID", this.bank.companyID]); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            isFileBalanced() { return _.get(this.summary, "balanceStatus", -1) != FileBalanceStatus.NotBalanced; },
            allowPrintAnticipatedDeposits() { const self = this;
                return self.localSecurity.AnticipatedDeposits === DisplayAnticipatedDeposits.Full
            },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AnticipatedDeposits",
                    "AllowChecksWhenUnbalanced",
                    "AllowDisbursementWithoutGoodFunds",
                    "AllowDeleteCheck",
                    "AllowDepositDelete",
                    "AllowPrintChecks",
                    "CanDeleteBookedDeposits",
                    "ForcePayeeOnChecks",
                    "DepositChangeOption",
                    "DepositSlip_ScreenAccess"
                    ]);

            },

        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            initNonReactiveVariables() {
                const self = this;
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: function(e) {
                            return self.editDisabled(e);
                        }
                    },
                    {
                        name: "view-payee",
                        text: "View Payee",
                        eventName: "view-payee",
                        requireSelection: true,
                        tooltip: `View Check Payee Information`,
                        disabled: function(e) { return self.readOnly || !e.data.isCheck ;}
                    },
                    {
                        name: "ten99",
                        text: "1099",
                        eventName: "ten99",
                        requireSelection: true,
                        allowMultiSelection: false,
                        visible: function(e) {
                            if (!_.hasIn(e.data, "buyerSellerID")) return false;
                            return self.ten99Visible(e.data.buyerSellerID);
                        }
                    },
                    {
                        name: "print-options",
                        text: "Print",
                        children: [
                            { name: "print-check", text: "Print Check", eventName: "print-item", requireSelection: true, allowMultiSelection: true, tooltip: `Print Check`, disabled: function(e) { return self.printDisabled(e) || !self.isPrintCheckOptionVisible(e)} },
                            { name: "print-check", text: "Print Receipt", eventName: "print-item", requireSelection: true, allowMultiSelection: true, tooltip: `Print Receipt`, disabled: function(e) { return self.printDisabled(e) || self.isPrintCheckOptionVisible(e)} },
                            { name: "print-cover-letter", text: "Print Cover Letter", eventName: "print-cover-letter", requireSelection: true, allowMultiSelection: false, tooltip: `Print Cover Letter`, disabled: function(e) { return self.printCoverLetterDisabled(e);} },
                        ]
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            return self.deleteDisabled(e);
                        }
                    }
                ];

                self.statuses = _.concat(CheckStatus.lookupItems, DepositStatus.lookupItems);
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                self.statusFilter = _.concat(_.clone(CheckStatusFilter.lookupItems), _.clone(DepositStatus.lookupItems));
                //RQO-29599 change the label on the filter dropdown only so the user knows which anticipated belongs to which item
                let anticipatedCheck = _.find(self.statusFilter, {id: 0});
                anticipatedCheck.name = 'Anticipated (Check)';
                let anticipatedReceipt = _.find(self.statusFilter, {id: 51});
                anticipatedReceipt.name = 'Anticipated (Receipt)';
                self.statusFilter = _.sortBy(self.statusFilter, "name");
            },

            editDisabled(e){
                const self = this;
                if (e.data.isCheck) {
                    return self.readOnly || e.data.isTransfer;
                } else {
                    if (self.localSecurity.DepositChangeOption == DepositEditingOptions.NoChange) {
                        return "Access Restricted";
                    }
                    if (self.localSecurity.DepositChangeOption == DepositEditingOptions.ChangeNonBooked && _.get(e.data, 'depositStatus', 0) != DepositStatus.Anticipated) {
                        return "Access Restricted";
                    }
                    if (self.localSecurity.DepositChangeOption == DepositEditingOptions.ChangeUnverifiedUnreconciled && (_.get(e.data, 'depositVerified', null) != null || _.get(e.data, 'reconciliationDate', null) != null)) {
                        return "Access Restricted";
                    }
                }
                return self.readOnly;
            },

            isPrintCheckOptionVisible(e) {
                if (_.some(e.data, ["isCheck", false])) return false;
                return true;
            },

            printDisabled(e){
                const self = this;
                if (_.some(e.data, ["isCheck", false]) && _.some(e.data, ["isCheck", true])) return true;

                if ( _.some(e.data, ["isCheck", false])) {
                    //RQO-18532 - allow printing while RO only if they all already have receipt IDs
                    let allHaveReciptID = _.every(e.data, i => !_.isNullOrEmpty(i.receiptDisplay));
                    return (self.readOnly && allHaveReciptID) ? false : self.readOnly;
                }
                else {
                    if (!self.localSecurity.AllowPrintChecks || !self.allowPrintAnticipatedDeposits) return "Access Restricted";
                    if (_.some(e.data, ["payee", "No Payee Indicated"])) return "Some checks do not have a Payee";
                    if (_.some(e.data, ["status", CheckStatus.Hold])) return "Some checks are on Hold";
                    if (_.some(e.data, ["status", CheckStatus.PendingApproval])) return "Some checks are Pending Approval";
                    if (_.some(e.data, ["status", CheckStatus.Rejected])) return "Some checks are Rejected";
                    if (_.some(e.data, ["hasValidAccountCode", false])) return `Some checks require an Account Code.`;
                    if (_.some(e.data, ["status", CheckStatus.Void])) return "Some checks are Void";
                    if (_.some(e.data, ["status", CheckStatus.StopPay])) return "Some checks are Stop Pay";
                    return self.readOnly
                        || _.some(e.data, ["status", CheckStatus.Issued])
                        || _.some(e.data, ["status", CheckStatus.Cleared])
                        || _.some(e.data, ["isCheck", false])
                        || _.some(e.data, ["isWire", true])
                        || _.some(e.data, ["isTransfer", true]);
                }
            },

            printCoverLetterDisabled(e) {
                const self = this;
                if(!e.data.isCheck) return true;
                if (e.data.status === CheckStatus.Issued || e.data.status === CheckStatus.Wire) return false;
                return (
                    self.readOnly ||
                    e.data.status === CheckStatus.ExpectedWireOut ||
                    e.data.status === CheckStatus.Hold ||
                    e.data.status === CheckStatus.Void ||
                    e.data.status === CheckStatus.StopPay ||
                    e.data.status === CheckStatus.None
                );
            },

            ten99Visible(buyerSellerID){
                const self = this;
                if (self.readOnly || _.parseNumber(buyerSellerID, 0) == 0) return false;
                return _.some(self.contacts, c => c.role === "Seller" && c.buyerSellerID === buyerSellerID);
            },

            deleteDisabled(e){
                const self = this;
                let checks = _.filter(e.data, ["isCheck", true]);
                let deposits = _.filter(e.data, ["isCheck", false]);
                let disableCheck = (_.size(checks) > 0 && !self.localSecurity.AllowDeleteCheck) || !_.every(checks, ['status', CheckStatus.None]);
                let disableDeposit =  _.some(deposits, x => x.status === DepositStatus.Reconciled
                                                            || x.status === DepositStatus.Anticipated)
                                        && !self.localSecurity.AllowDepositDelete;

                let disableBookedDeposit =  _.some(deposits, ['status', DepositStatus.Booked]) && !self.localSecurity.CanDeleteBookedDeposits;
                if (_.some(deposits, ["isVerified", true])) {
                    return "One or more of the selected Deposits are Verified and cannot be deleted.";
                }
                else if (_.some(deposits, ["isReconciled", true])) {
                    return "One or more of the selected Deposits are Reconciled and cannot be deleted.";
                }
                else if (self.readOnly || disableCheck || disableDeposit || disableBookedDeposit) {
                    return "Access Restricted";
                }
                else
                    return self.readOnly;
            },

            initGridConfig(){
                const self = this;
                let defaultSort = _.parseBool(self.systemDefaults.receiptsAndDisbursementsSortedByDate, false) ? {sortIndex: 1, sortOrder: "asc"} : {};//set to 1 bc there is a hidden sort at the end of the grid to make sure deposits come before checks*
                self.gridConfig = {
                    allowColumnReordering: false,
                    columns: [
                        {
                            dataField: "datePrinted",
                            dataType: "date",
                            caption: "Date",
                            ...defaultSort
                        },
                        {
                            dataField: "numberDisplay",
                            dataType: "string",
                            caption: "Number",
                        },
                        {
                            dataField: "payorPayee",
                            dataType: "string",
                            caption: "Payee/Payor",
                            minWidth: 175,
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            cellTemplate: DxGridUtils.textPlusCountCellTemplate({
                                idAppend: "ledger-popup-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updatePopover(cellInfo.data, e.target);
                                        e.stopPropagation();
                                    },
                                }
                            })
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            cellTemplate: DxGridUtils.ledgerAmountCellTemplate
                        },
                        {
                            dataField: "typeOfFundsDisplay",
                            dataType: "string",
                            caption: "Type of Funds",
                        },
                        {
                            dataField: "expectedWireDate",
                            dataType: "date",
                            caption: "Expected Wire Date",
                            visible: false,
                        },
                        {
                            dataField: "status",
                            dataType: "number",
                            cellTemplate: DxGridUtils.checkStatusCellTemplate({
                                idLookup: "status",
                                idAppend: "check-approval-popup-info-",
                                statuses: self.statuses,
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateApprovalPopover(cellInfo.data.moneyMovementApprovalID, e.target);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                            lookup: {
                                dataSource: self.statuses,
                                valueExpr: "id",
                                displayExpr: "name",
                            },
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                filterType: "tags",
                                dataSource: self.statusFilter,
                            },
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "receiptDisplay",
                            dataType: "string",
                            caption: "Receipt ID",
                            visible: false
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate
                        },
                        {
                            dataField: "depositVerified",
                            dataType: "date",
                            caption: "Verified Date",
                            visible: false,
                        },
                        {
                            dataField: "memo",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "referenceNumber",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "bankCompanyID",
                            dataType: "number",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"

                            },
                            visible: false
                        },
                        {
                            dataField: "originalGFNo",
                            dataType: "string",
                            caption: "Original File#",
                            visible: false,
                            showInColumnChooser: self.isConsolidatedFile,
                        },
                        {
                            dataField: "sortOrder",
                            dataType: "number",
                            sortIndex: 0,
                            sortOrder: "asc",
                            visible: false
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalChecksLabel",
                                column: "payorPayee",
                                alignment: "left",
                                displayFormat: "TOTAL DISBURSEMENTS",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalChecks",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "TotalDepositsLabel",
                                column: "payorPayee",
                                alignment: "left",
                                displayFormat: "TOTAL RECEIPTS",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalDeposits",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                        ],
                        calculateCustomSummary(options) {
                            if (options.name == "TotalDeposits") {
                                options.totalValue = self.summary.anticipatedDeposits + self.summary.receipts;
                            } else if (options.name == "TotalChecks") {
                                options.totalValue = self.summary.anticipatedDisbursements + self.summary.disbursements;
                            }
                        }
                    },
                    onRowPrepared (e) {
                        if (e.rowType !== "data") return;
                        let rowBackgroundColorVariant = e.data?.useRowRedStrikeThrough;
                        if(!rowBackgroundColorVariant) return;
                        e.rowElement.addClass("rq-strike-through-red");
                    }
                };

                self.gridDataSource = {
                    key: "id",
                    load (loadOptions) {
                        return Promise.resolve(self.filteredItems);
                    },
                };
            },

            onEditItem(e) {
                let editDisabled = this.editDisabled(e);
                if(editDisabled) return;

                if(!e || !e.data) return;
                // this.clear();
                let id = _.parseNumber(_.trimStart(_.get(e.data, "id"), 'CD-'));
                if (_.get(e.data, "isCheck", false)) {
                    this.$events.$emit('edit-check', {data: {checksID: id}});
                } else {
                    this.$events.$emit('edit-deposit', {data: {depositID: id}});
                }
            },

            onEdit1099(e) {
                if(!e || !e.data) return;
                if(!this.ten99Visible(e.data.buyerSellerID)) return;
                this.$events.$emit('edit-1099', e.data.buyerSellerID);
            },

            onGridClick(e) {
                //used as a click off for the check popover
                const self = this;
                if (_.getBool(self, "popover.visible", false)) {
                    self.popover.visible = false;
                    self.$events.$emit("show-popover", self.popover);
                }
                if (_.getBool(self, "approvalPopover.visible", false)) {
                    self.approvalPopover.visible = false;
                    self.$events.$emit("show-approval-popover", self.approvalPopover);
                }
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let deleteDisabled = self.deleteDisabled(e);
                if(deleteDisabled) return;

                let items = e.data;
                let depositIDs = _.map(items, i => _.parseNumber(_.trimStart(_.get(i, "id"), 'D-')));
                let itemLines = _.filter(self.itemDetail, d => _.includes(depositIDs, d.depositID));
                let hasEarnestMoney = _.some(itemLines, ['description', 'Earnest Money']);
                let ok = function (args) {
                    let ids = _.map(items, "id");
                    let apiPromise = self.$api.CheckWritingApi.deleteLedgers(self.order.ordersID, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.$emit("update-data", result);
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected Items are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting Item.` });
                            }
                            return true;
                        })
                        .finally(() => {
                            if (hasEarnestMoney) self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: self.order.ordersID, refresh: true });//deleting earnest money affects the order, so refresh
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? "Items" : "Item"}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onPrintItem(e){
                if(!e || !e.data) return;
                const self = this;
                let printDisabled = self.printDisabled(e);
                if(printDisabled) return;

                if ( _.some(e.data, ["isCheck", false])){
                    self.onPrintReceiptOfDeposit(e);
                }else{
                    self.onPrintCheck(e);
                }
            },

            onPrintReceiptOfDeposit(e){
                if(!e || !e.data) return;
                const self = this;

                var deposits = _.map(e.data, x => {return new DepositShortDto({depositID: x.depositID,
                                                                               receiptID: x.receiptID})});
                //RQO-18532 - allow printing while RO only if they all already have receipt IDs
                let allHaveReciptID = _.every(e.data, i => !_.isNullOrEmpty(i.receiptDisplay));
                if (self.readOnly && !allHaveReciptID) return;
                self.$emit("print-receipt-of-deposit", deposits);
            },

            onPrintCheck(e) {
                if(!e || !e.data) return;
                let checks = _.filter(e.data, ["isCheck", true]);
                this.$events.$emit('print-checks', checks);
            },

            onPrintCoverLetter(e) {
                const self = this;
                let printDisabled = self.printCoverLetterDisabled(e);
                if (printDisabled) return;
                if(!e || !e.data) return;

                this.$events.$emit('print-cover-letters', e.data);
            },

            onViewPayeeInfo(e) {
                if(!e || !e.data) return;
                if(this.readOnly || !e.data.isCheck) return;
                this.showPayeeInfo(e.data);
            },

            updateApprovalPopover(moneyMovementApprovalID=null, target=null) {
                const self = this;
                self.$api.MoneyMovementApprovalsApi.getApproval(moneyMovementApprovalID)
                    .then(data => {
                        let newID = _.get(target, "id") || null;
                        let lastID = _.get(self.approvalPopover, "target") || null;
                        let isNewItem = !_.isEqual(newID, lastID);
                        if (isNewItem) {
                            if (!_.isNil(lastID)) {
                                self.approvalPopover.visible = false;
                                self.$events.$emit("show-approval-popover", self.approvalPopover);
                            }
                            self.approvalPopover.target = newID;
                            self.approvalPopover.item = new MoneyMovementApprovalDto(data);
                            self.approvalPopover.visible = true;
                        } else {
                            self.approvalPopover.visible = !self.approvalPopover.visible;
                        }
                        self.$nextTick(() => {
                            self.$events.$emit("show-approval-popover", self.approvalPopover);
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error getting approval info.` });
                    });
            },

            updatePopover(item=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.popover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        self.popover.visible = false;
                        self.$events.$emit("show-popover", self.popover);
                    }
                    let id = item.isCheck ? _.parseNumber(_.replace(item.id, 'C', '')) : _.parseNumber(_.replace(item.id, 'D', ''));
                    let key = item.isCheck ? "checksID" : "depositID";
                    let itemDetail = _.filter(self.itemDetail, [key, id]) || [];
                    self.popover.isCheck = item.isCheck;
                    self.popover.target = newID;
                    self.popover.item = item;
                    self.popover.itemDetail = itemDetail;
                    self.popover.visible = true;
                } else {
                    self.popover.visible = !self.popover.visible;
                }
                self.$nextTick(() => {
                    self.$events.$emit("show-popover", self.popover);
                });
            },

            showPrintChecksDialog(checksIDs) {
                const self = this;
                let request = new CheckPrintRequestDto({ordersID: self.order.ordersID, checksIDs: checksIDs});
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    if (!form.isValid()) {
                       return false;
                    }
                    return form.printChecks()
                        .then(data => {
                            self.$emit("update-check-data", data);
                            return true;
                        }).catch(e => {
                            self.errorMessage = e.errorMessage;
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };
                self.$dialog.open({
                    title: `Print ${checksIDs.length} Checks`,
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: CheckWritingPrintForm,
                    onOk: onOk,
                    props: {
                        item: request,
                        bank: self.bank,
                        summary: self.summary,
                        lastFinalizedReconDate: self.order.lastFinalizedReconDate
                    },
                });
            },

            refresh() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.option("pager.visible", this.items.length > 50);
                this.gridInstance.refresh();
                this.gridInstance.updateDimensions();
            },

            showPayeeInfo(item) {
                const self = this;
                self.$dialog.open({
                    title: "Payee Information",
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: CheckWritingManualPayee,
                    okOnly: true,
                    props: {
                        item: item,
                        readOnly: true
                    }
                });
            },
        }
    }
</script>
