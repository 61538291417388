<template>
    <div class="content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <rqdx-action-data-grid
            ref="dataGrid"
            title-size="md"
            automation_id="tbl_deposit_lines"
            class="rq-modal-datagrid"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :read-only="readOnly"
            :persist-state="false"
            @split="onSplitDeposit"
            @move="onMoveDeposit"
            export-file-name="check_writing_deposit_lines_data"
            hide-search
            rq-editable
            rq-filters>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { DepositStatus } from '../enums';
    import { UserSecuritySettings } from "@/shared/models/models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "CheckWritingSplitMoveDepositForm",
        mixins: [GridSystemLookupMixin],
        props: {
            bank: {type: Object, required: false},
            deposit: {type: Object, required: false},
            depositLines: {type: Array, required: false, default: () => []},
            depositLineLookups: { type: Array, required: false, default: () => [] },
            summary: { type: Object, required: false, default: () => ({}) },
            allDeposits: {type: Array, required: false, default: () => []}
        },
        components: {  },
        data() {
            return {
                errorMessage: "",
                item: {},
                itemDetail: [],
                ledgerSummary: {},
                detailUpdates: false,
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                order: state => state.orders.order,
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user,
                authSettings: state => state.authentication.session.settings
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "AllowDepositDelete",
                    "DepositPreDateDays",
                    "DepositPostDateDays",
                    ]);
            },
            readOnly() { return this.isFileLocked; },
            tooltipContainer() { return `#${this.$parent.$parent.dialogId}`; },
            isSysAdmin(){
                let settings = new UserSecuritySettings(this.authSettings);
                return settings.findValue("IsSysAdmin");
            },
        },
        created(){
            this.init();
            this.initNonReactiveVariables();
            this.initGridConfig();
        },
        mounted() {
            _.invoke(this, '$refs.txt_amount.focus');
        },

        methods: {
            init() {
                this.ledgerSummary = _.clone(this.summary);
                this.item = _.clone(this.deposit);
                this.itemDetail = _.clone(this.depositLines);
                this.$emit(`${this.readOnly ? "disable" : "enable"}-ok`);
            },

            onSplitDeposit(e) {
                if(!e || !e.data) return;
                const self = this;
                let splitDepositDisabled = self.actionDisabled(e);
                if(splitDepositDisabled) return;
                let data = { type: "split", depositID: self.deposit.depositID, lines: e.data }
                self.$emit("ok", data);

            },

            onMoveDeposit(e) {
                if(!e || !e.data) return;
                const self = this;
                let moveDepositDisabled = self.actionDisabled(e);
                if(moveDepositDisabled) return;
                let data = { type: "move", sourceDepositID: self.deposit.depositID, targetDepositID: e.action.id, lines: e.data };
                self.$emit("ok", data)
            },

            getNullText(v) { return _.isNullOrEmpty(v) ? '(Blank)' : v; },

            getChildren() {
                const self = this;
                let children = [];
                _.each(self.allDeposits, c =>
                    children.push({ name: "move-deposit",
                    text: `Payor: ${self.getNullText(c.payor)}   Amount: ${self.formatMoney(c.amount)}`,
                    id: c.depositID,
                    eventName: "move",
                    requireSelection: true,
                    allowMultiSelection: true
                    })
                );
                return children;
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    allowColumnReordering: false,
                    allowEditing: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        {
                            dataField: "lineNumberStr",
                            dataType: "string",
                            caption: "Line",
                            allowEditing: false,
                            calculateCellValue: function(rowData){
                                return DxGridUtils.lineNumberStrDisplay(rowData)
                            }
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            allowEditing: false
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions: { disabled: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalLabel",
                                column: "description",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "DepositTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                };

                self.gridDataSource = {
                    key: self.detailKey,
                    load (loadOptions) {
                        return Promise.resolve(self.itemDetail);
                    },
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Receipt";
                self.itemTypeNamePlural = "Receipts";
                self.itemKey = "depositID";
                self.detailTypeName = "Receipt Line";
                self.detailTypeNamePlural = "Receipt Lines";
                self.detailKey = "depositLineID";
                self.statuses = DepositStatus.lookupItems;
                self.selectionActions = [
                    {
                        name: "split",
                        text: "Split",
                        eventName: "split",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: "Split Selected Receipt or Multiple Receipts",
                        disabled: function(e) { return self.actionDisabled(e) || self.depositLines.length === 1 || _.size(e.data) === _.size(self.itemDetail);}
                    },
                    {
                        name: "move",
                        text: "Move",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: "Move Selected Receipt or Multiple Receipts",
                        disabled: function(e) { return self.actionDisabled(e) || _.size(self.getChildren()) === 0;},
                        children: self.getChildren()
                    }
                ];
            },

            actionDisabled(e){
                const self = this;
                return self.readOnly || !self.localSecurity.AllowDepositDelete;
            },

            refresh() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },
        }
    }
</script>
<style lang="scss" scoped>
    .rq-modal-datagrid {
        margin-left: -8px;
        margin-right: -8px;
        margin-top: 50px;
        margin-bottom: 50px;

        .navbar.rq-grid-selection-navbar .nav-item .dropdown-menu {
            max-height: 170px;
            overflow-y: auto;
        }
    }
</style>
