
<template>
    <div class="content-wrapper custom-data-page">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorMessage"
            dismissable
        />
        <rq-page-section :title="customPageName" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn  id="toggleButton"
                            v-show="showButton"
                            automation_id="btn_manage_custom_page_name"
                            variant="theme"
                            @click="openEditPageNameDialog">Edit Page Name
                        </b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>

        <RqTabs
            :tabs="tabItems"
            scrollable>
            <template
                v-for="item in customData"
                :key="item.customDataTab.customDataTabID"
                #[item.tabName]>
                    <div class="rq-container">
                        <custom-data-container
                            :reference-table="referenceTable"
                            :reference-table-pk-value="orderState.ordersID"
                            :custom-data-tab-id="item.customDataTab.customDataTabID"
                            v-model:value="item.customDataList"
                            v-model:has-changes="item.customDataHasChanges"
                            v-model:is-valid="item.customDataIsValid"
                            :read-only="readOnly"
                        />
                    </div>
            </template>
        </RqTabs>
    </div>
</template>

<script>
import { provide } from 'vue'
import RenamePageForm from '../dialogs/RenamePageForm';
import { CustomDataContainer} from "@/shared/components";
import { GlobalEventManager } from '@/app.events';
import { mapState } from "vuex";
import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
import { SET_SYSTEM_DEFAULT_DATA } from "@/store/mutations";
import { TabAlert } from "@/shared/models/models";

export default ({
    name: "CustomPage",
    components:  { CustomDataContainer },
    data: function () {
        return {
            customData: [],
            referenceTable: CustomDataReferenceTable.Orders,
            childValidationCallbacks: []
        }
    },
    computed: {
        ...mapState({
            orderState: state => state.orders.order,
            systemDefaults: state => state.system.systemDefaults,
            readOnly: state => state.isPageReadOnly
        }),
        showButton() {
            return _.getBool(this, '$route.params.showButton');
        },
        customPageName() {
            return _.get(this, "systemDefaults.customPageName", null) || "Custom Page";
        },
        showErrorMessage() {
            return _.some(this.customData, x => !x.customDataIsValid)
        },
        tabItems() {
            return _.map(this.customData, (item, index) => ({
                automation_id: `btn_custom_data_tab_${index}`,
                name: item.tabName,
                title: item.customDataTab.label,
                alertCount: item.customDataIsValid ? 0 : 1
            }));
        }
    },
    created()  {
        const self = this;
        this.initialize();
        GlobalEventManager.onSave(self, self.onSave);
        GlobalEventManager.onCancel(self, self.onCancel);
    },
    beforeUnmount () {
        const self = this;
        GlobalEventManager.unregister(self);
    },
    provide() {
        return {
            validationCallbacks: this.childValidationCallbacks
        };
    },
    methods: {
        openEditPageNameDialog() {
            const self = this;
            self.$dialog.open({
                title: "Rename Page",
                height: "auto",
                width: 500,
                adaptive: true,
                component: RenamePageForm,
                onOk(e){
                    let hasChanges = e.component.hasChanges();
                    let requestData = e.component.getRequestData();
                    if(hasChanges){
                            let apiPromise = self.$api.SystemDefaultApi.updateSystemDefault(requestData);
                            return self.$rqBusy.wait(apiPromise)
                            .then(result => {
                                self.$store.commit(SET_SYSTEM_DEFAULT_DATA, result);
                                return true;
                            });
                        }
                }
            });
        },
        initialize() {
            const self = this;
            let apiPromise = self.$api.CustomDataApi.getCustomDataTabs(self.referenceTable, self.orderState.regionID);
            self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.customData = self.mapData(result);
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error Loading Custom Data.` });
                    return error;
                });
        },
        mapData(result) {
            return _.map(result, x => ({
                customDataTab: x,
                customDataList: [],
                customDataIsValid: true,
                customDataHasChanges: false,
                tabAlert: new TabAlert(),
                tabName: `custom-data-tab-${x.customDataTabID}`
            }));
        },
        onSave() {
            const self = this;
            let saveData = [];
            self.generateTabAlerts();
            // only get customData from customDataContainer that are valid and has changes.
            let filteredData = _.map(_.filter(self.customData, x => {
                                        if(x.customDataIsValid && x.customDataHasChanges) return x;
                                    }), y => y.customDataList);

            // flatten to single dimension array
            saveData = _.flatten(filteredData);

            if(_.isEmpty(saveData)) {
                self.$toast.info({ message: "No changes detected" });
                return Promise.resolve(true);
            }

            let apiPromise = self.$api.CustomDataApi.saveCustomData(saveData);
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.$toast.success("Data Saved Successfully.");
                    self.initialize();
                    this.generateTabAlert= new TabAlert();
                })
                .catch(err => {
                    self.$toast.error("Error Saving Custom Data.");
                    console.error(err);
                });
        },
        onCancel() {
            const self=this;
            this.initialize();
        },
        generateTabAlerts() {
            let self = this;
            _.each(self.childValidationCallbacks, callback => {
                var result = callback();
                var customDataItem = _.find(self.customData, function(cd) { return cd.customDataTabID == result.customDataTabID; });
                if(customDataItem){
                    customDataItem.tabAlert = result;
                }
            })
        },
        reportTabErrors(tabId, numberOfErrors){

        }
    }
})
</script>
