<template>
    <div class="content-wrapper">
        <template v-if="isHud && isWithOutSeller">
            <rq-banner
                message="Please correct the highlighted errors on screen to continue."
                variant="error"
                icon="fas fa-exclamation-triangle"
                :visible="showErrorBanner && hasErrors"
                dismissable
            />
            <rq-page-section title="Disbursements / Net Settlement" header-size="lg" header-only borderless>
                <template #header-actions>
                    <ul v-if="isDisbursement" class="nav">
                        <li class="nav-item">
                            <b-button automation_id="btn_add"
                                ref="btn_add"
                                variant="theme"
                                :disabled="readOnly"
                                @click="onAddItem"
                                v-focus>
                                Add
                            </b-button>                            
                            <!-- <button automation_id="btn_add" type="button" class="btn btn-default btn-theme" @click="onAddItem" :disabled="readOnly">Add</button> -->
                        </li>
                    </ul>
                    <ul class="nav ms-auto grid-tools-offset">
                        <li class="nav-item">
                            <rq-loan-select-box v-model="selectedLoanId" />
                        </li>
                    </ul>
                </template>
            </rq-page-section>

            <rq-tabs
                :tabs="tabItems"
                v-model="selectedTabIndex">
                <template #disbursements>
                    <rqdx-action-data-grid
                        ref="disburseDataGrid"
                        automation_id="dg_disbursements"
                        :actions="disburseSelectionActions"
                        :config="disburseGridConfig"
                        :data-source="disburseGridDataSource"
                        export-file-name="disbursement-data"
                        :strikethrough-if-true="['isInactive']"
                        v-model:validation-errors="validationErrors"
                        focus-after-insert="first-row"
                        @delete="onDeleteDisburseItems"
                        @netfund="onChangeNetFundItems"
                        @editorPreparing="onEditorPreparing"
                        hide-search
                        :rq-editable="!readOnly">
                    </rqdx-action-data-grid>
                </template>
                <template #net-settlement>
                    <rqdx-action-data-grid
                        ref="netDataGrid"
                        automation_id="dg_net_settlement"
                        :actions="netSelectionActions"
                        :config="netGridConfig"
                        :data-source="netGridDataSource"
                        export-file-name="net-settlement-data"
                        :strikethrough-if-true="['isInactive']"
                        v-model:validation-errors="validationErrors"
                        focus-after-insert="first-row"
                        @editorPreparing="onEditorPreparing"
                        hide-search
                        :rq-editable="!readOnly">
                    </rqdx-action-data-grid>
                </template>
            </rq-tabs>
        </template>
        <rq-no-data v-else
            text="Disbursements / Net Settlement only available for HUD-1A"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { GlobalEventManager } from '@/app.events';
    import { LoanCostTypes, POCWhoOptions, SETTLEMENT_TYPE, SettlementTypeOption, HudLineTypes, SsGridActions } from '@settlement/models';
    import { LoanCostModel } from '../../models';

    export default {
        name: 'NetDisbursement',
        mixins: [GridCompanyPickerMixin, GridSystemLookupMixin],
        props: {
            activeTabIndex: { type: Number, default: 0 }
        },
        data() {
            return {
                itemKey: "loanCostSectionDataID",
                items: [],
                disburseSelectionActions: [],
                netSelectionActions: [],
                disburseItems: [],
                netItems: [],
                deletedItems: [],
                originalData: [],
                validationErrors: [],
                selectedLoanId: 0,
                LoanCostTypes: LoanCostTypes,
                selectedTabIndex: 0,
                gridRefs: ["disburseDataGrid", "netDataGrid"],
                showErrorBanner: false
            };
        },

        computed:{
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "defaultLoanId",
            ]),
            ...mapState({
                loans: state => state.orders.loans || [],
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                readOnly: state => state.isPageReadOnly,
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
            }),
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_disbursements", name: "disbursements", title: "Disbursements" },
                    { automation_id: "btn_tab_netSettlement", name: "net-settlement", title: "Net Settlement" },
                ];
            },
            gridDisburseInstance () { return this.$refs.disburseDataGrid.gridInstance; },
            gridNetInstance () { return this.$refs.netDataGrid.gridInstance; },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            SettlementTypes() { return SETTLEMENT_TYPE; },
            isWithOutSeller() { return _.parseBool(this.orderSummary.isWithOutSeller, false); },
            pocWhoOptions() { return POCWhoOptions.lookupItems; },
            gridStorageKey() { return `settlement_disbursements_${this.selectedView}_${this.isWithOutSeller}`; },
            HudLineTypes() { return HudLineTypes; },
            isDisbursement() { return this.selectedTabIndex === 0; },
            hasErrors() { return !_.isEmpty(this.validationErrors); }
        },

        watch:{
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                self.save();
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeSSViewSelected(newValue);
                this.fetchData();
            },
            selectedTabIndex(newValue, oldValue) {
                if (newValue === oldValue) return;
                const self = this;
                _.invoke(self, `$refs.${self.gridRefs[oldValue]}.gridInstance.hideColumnChooser`);
                _.delay(() => {
                    _.invoke(self, `$refs.${self.gridRefs[newValue]}.gridInstance.updateDimensions`);
                    _.invoke(self, `$refs.${self.gridRefs[newValue]}.gridInstance.repaint`);
                }, 200);
            },
            activeTabIndex: {
                handler(newValue, oldValue) {
                    if (newValue == oldValue) return;
                    this.selectedTabIndex = newValue;
                },
                immediate: true
            },
        },

        created() {
            const self = this;
            if (self.loans.length > 0){
                self.selectedLoanId = self.defaultLoanId > 0 ? self.defaultLoanId : self.loans[0].loanID;
            }

            GlobalEventManager.onSave(self, self.onSave);
            GlobalEventManager.onCancel(self, self.onCancel);

            self.initNonReactiveVariables();

            // Disbursement Grid Configurations
            self.initDisburseGridConfig();
            self.initDisburseGridDataSource();

            // Net Settlement Grid Configurations
            self.initNetGridConfig();
            self.initNetGridDataSource();

            self.fetchData();
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {
            onChangeSSViewSelected(e) {
                const self = this;
                self.initDisburseGridConfig();
                self.initNetGridConfig();
                self.$nextTick().then(() => {
                    _.invoke(self, "$refs.disburseDataGrid.loadGrid");
                    _.invoke(self, "$refs.netDataGrid.loadGrid");
                });
            },

            onSave(e){
                this.gridDisburseInstance.saveEditData();
                this.gridNetInstance.saveEditData();
                this.save(_.getBool(e, "userInitiated"));
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            gridRefresh() {
                this.gridDisburseInstance.clearSelection();
                this.gridDisburseInstance.refresh();

                this.gridNetInstance.clearSelection();
                this.gridNetInstance.refresh();
            },
            fetchData() {
                const self = this;
                self.deletedItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getPromise = self.$api.LoanCostChargesApi.getNetDisbursementsByLoan(self.selectedLoanId);

                return self.$rqBusy.wait(getPromise)
                    .then(results => {
                        self.originalData = self.mapItems(results);
                        self.items = self.mapItems(results);
                        self.disburseItems = self.mapItems(results, 1500);
                        self.netItems = self.mapItems(results, 1600);

                        self.gridRefresh();
                    });
            },
            updateLine1604() {
                const self = this;
                let item1604 = _.find(self.items, item => item.hudLine === 1604);
                let item1604Index = _.findIndex(self.items, item => item.hudLine === 1604);
                let net1604Index = _.findIndex(self.netItems, item => item.hudLine === 1604);
                if (item1604Index < 0) return;

                let line1600Item = _.find(self.items, i => i.hudLine === 1600);
                let line1601Item = _.find(self.items, i => i.hudLine === 1601);
                let sumTotal = _.sumBy(_.filter(self.items, l => _.includes([1602, 1603], l.hudLine)), item => item.borrowerAtClosing);
                let line1604total = line1600Item.borrowerAtClosing + line1601Item.borrowerAtClosing - sumTotal;

                self.items[item1604Index].borrowerAtClosing = line1604total;
                self.netItems[net1604Index].borrowerAtClosing = line1604total;
            },
            mapItems(items, section) {
                let filtered = _.isNil(section) ? items : _.filter(items, item => item.hudSection === section);
                return _.sortBy(_.map(filtered, item => new LoanCostModel(item)), "hudLine");
            },
            save(refreshData = true) {
                const self = this;

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                if(!self.hasChanges()){
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let savePromise = self.$api.LoanCostChargesApi.saveDeleteRecords(self.items, self.deletedItems);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Disbursement/Net Settlement Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Disbursement/Net Settlement");
                        console.error(err);
                    });
            },
            isPayeeColumnDisabled(lineData) {
                return lineData.isPayOff;
            },
            onEditorPreparing(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;

                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },
            isColumnDisabled(dataField, data) {
                if (data.isPayOff) return true;

                if (data.hudSection === 1600) {
                    switch (dataField) {
                        case 'borrowerAtClosing':
                            return data.hudLine !== 1601;
                        default:
                            return true;
                    }
                }
                return false;
            },
            hasChanges(){
                //original data was empty and user added something
                if(this.originalData.length === 0 && this.items.length > 0){
                    return true;
                }
                //if the arrays are different length, then there are changes.  Items have been deleted and need to save
                if(this.items.length !== this.originalData.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(this.originalData, this.items);
                return changes.length > 0;
            },
            onAddItem() {
                const self = this;

                // Adding Disbursement
                if (self.selectedTabIndex === 0) {
                    if(!self.gridDisburseInstance) return;
                    self.gridDisburseInstance.addRow();
                }

                // Adding Net Settlement
                if (self.selectedTabIndex === 1) {
                    if(!self.gridNetInstance) return;
                    self.gridNetInstance.addRow();
                }
            },
            onDeleteDisburseItems(e) {
                // Deletion applied to Disbursements
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteItems(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    "Are you sure you wish to delete selected charge(s)?",
                    okHandler,
                    null, { cancelTitle: "No", okTitle: "Yes"});
            },

            onChangeNetFundItems(e) {
                const self = this;
                if (!e || !e.data) return;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    let line = _.find(self.items, l => l.hudLine === item.hudLine);
                    item.netFund = line.netFund = _.getNumber(e.action, "key", 0);
                });

                this.gridDisburseInstance.clearSelection();
                self.gridDisburseInstance.refresh();
            },

            deleteItems(items) {
                // Delete Disburse Items
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.itemKey);
                    self.removeItems(key);

                    if(key > 0){
                        self.deletedItems.push(item);
                    }
                });

                // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                self.reorderHudItems();
            },

            removeItems(key) {
                // Remove Items for Disburse Items
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                let disburseIndex = _.findIndex(self.disburseItems, item => item[self.itemKey] === key);
                if(itemIndex >= 0) {
                    self.items.splice(itemIndex, 1);
                    self.disburseItems.splice(disburseIndex, 1);
                }
                this.gridDisburseInstance.clearSelection();
                this.gridDisburseInstance.refresh();
            },

            reorderHudItems() {
                const self = this;
                let hudSection = 1500;
                let hudItems = _.filter(self.items, i => i.hudSection === hudSection);

                if (hudItems.length === 0) return; // no hud items to reorder
                let minLine = _.minBy(hudItems, function(i) { return i.hudLine; });
                let maxLine = _.maxBy(hudItems, function(i) { return i.hudLine; });

                let lineNumber = hudSection + 1;
                _.forEach(_.sortBy(hudItems, h => h.hudLine), (hudItem) => {
                    if (hudItem.hudLine != lineNumber) {
                        let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === hudItem.loanCostSectionDataID);
                        let disburseIndex = _.findIndex(self.disburseItems, item => item[self.itemKey] === hudItem.loanCostSectionDataID);
                        self.items[itemIndex].hudLine = lineNumber;
                        self.disburseItems[disburseIndex].hudLine = lineNumber;
                        hudItem.hudLine = lineNumber;
                    }
                    lineNumber = lineNumber + 1;
                });
            },
            initNonReactiveVariables() {
                const self = this;

                self.disburseSelectionActions = [
                {
                    name: "Delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(_.some(e.data, item => item.isPayOff)) {
                            return "Unselect required lines to delete";
                        } else {
                            return false;
                        }
                    },
                    tooltip: `Delete Disbursement(s)`
                },
                {
                    name: "Apply-Net-Fund",
                    text: "Apply Net Fund",
                    eventName: "netfund",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if (self.isHud2010 && _.find(e.data, item => item.hudLine === 1601)) return true;

                        return false;
                    },
                    children: [
                        SsGridActions.NETFUND_NONE,
                        SsGridActions.NETFUND_POSITIVE,
                        SsGridActions.NETFUND_NEGATIVE
                    ]
                },];

                self.netSelectionActions = [
                {
                    name: "Delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        return true; // Net Settlements can't be deleted
                    },
                    tooltip: `Delete Net Settlement(s)`
                }];
            },
            // Disbursement
            initDisburseGridConfig() {
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyName",
                    contactIDExpr: "payeeContactID",
                    contactNameExpr: "payeeContactName",
                    showContactPicker: false,
                };

                self.disburseGridConfig = {
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            dataField: "hudLine",
                            allowEditing: false,
                            caption: "HUD Line",
                            visible: self.isHud,
                            width: 60
                        },
                        {
                            dataField: "description",
                        },
                        //from GridCompanyPickerMixin
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyName",
                                caption: "Payee",
                                width: "20%"
                            },
                            disabled: cellInfo => {
                                return self.isPayeeColumnDisabled(cellInfo.data);
                            },
                            ...payeePickerInfo
                        }),
                        {
                            caption: "Borrower",
                            alignment: "center",
                            columns: [
                            {
                                dataField: "borrowerAtClosing",
                                caption: "At Closing",
                                width: 90,
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: "$ #,##0.##"
                                },
                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;
                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            }]
                        },
                        {
                            dataField: "poc",
                            caption: 'POC Whom',
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.pocWhoOptions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            visible: self.isHud,
                            width: 75
                        },
                        {
                            dataField: "pocAmount",
                            caption: "POC Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            visible: self.isHud,
                            width: 75
                        },
                        {
                            dataField: "netFund",
                            caption: 'Net Fund',
                            width: 60,
                            lookup: {
                                dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.NET_FUND),
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                width: 140,
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                        // {
                        //     dataField: "accountCodeID",
                        //     caption: 'Account Code',
                        //     width: 140,
                        //     lookup: {
                        //         dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES),
                        //         displayExpr: "name",
                        //         valueExpr: "id"
                        //     }
                        // },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    storageKey: self.gridStorageKey,

                    // Summary Totals
                    summary:
                    {
                        totalItems: [
                            {
                                column: "description",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return "TOTALS";
                                }
                            },
                            {
                                column: "borrowerAtClosing",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                            {
                                column: "pocAmount",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                        ]
                    }
                };
            },
            initDisburseGridDataSource() {
                const self = this;
                self.disburseGridDataSource = {
                    key: self.itemKey,
                    load(){
                        return Promise.resolve(self.disburseItems);
                    },
                    insert(values){
                        self.onDisburseGridInsert(values);
                    },
                    update(key, values) {
                        self.onDisburseGridUpdate(key, values);
                    }
                };
            },
            getNextAvailableDisburseHudLine() {
                const self = this;
                let hudData = self.isHud ? self.disburseItems : self.originalData;
                let hudItems = _.filter(hudData, i => i.hudLine > 1500 && i.hudLine < 1600);
                let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                let nextAvailableLineNumber = _.getNumber(maxHudLineItem, "hudLine", 1500) + 1;

                return nextAvailableLineNumber;
            },
            onDisburseGridInsert(values) {
                const self = this;
                let newItem = new LoanCostModel(values);
                newItem.loanCostSectionDataID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.lineType = LoanCostTypes.AdditionalOtherCost;
                newItem.required = false;

                // We set hudLine values to push to server side
                newItem.hudLine = self.getNextAvailableDisburseHudLine();
                newItem.hudSection = 1500;

                // By setting the default to null we know that this is common among all settlement statements versus being specifically initialized to CDF / HUD
                // as they can be different based on defaulted lines
                newItem.defaultedSettlementType = null;

                self.items.push(newItem);
                self.disburseItems.push(newItem);
                return Promise.resolve(newItem);
            },
            onDisburseGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.loanCostSectionDataID === key);
                let disburseIndex = _.findIndex(self.disburseItems, item => item.loanCostSectionDataID === key);
                if (itemIndex < 0) return;

                let newUpdateItem = new LoanCostModel((_.assign({}, self.items[itemIndex], values)));
                let newUpdateDisburseItem = new LoanCostModel((_.assign({}, self.disburseItems[disburseIndex], values)));

                self.items[itemIndex] = newUpdateItem;
                self.disburseItems[disburseIndex] = newUpdateItem;

                return Promise.resolve(newUpdateItem);
            },

            // Net Settlement
            initNetGridConfig() {
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyName",
                    contactIDExpr: "payeeContactID",
                    contactNameExpr: "payeeContactName",
                    showContactPicker: false,
                };

                self.netGridConfig = {
                    onInitNewRow: self.initNewRow,
                    focusedRowEnabled: false,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            dataField: "hudLine",
                            allowEditing: false,
                            caption: "HUD Line",
                            visible: self.isHud,
                            width: 60
                        },
                        {
                            dataField: "description"
                        },
                        {
                            caption: "Borrower",
                            alignment: "center",
                            columns: [
                            {
                                dataField: "borrowerAtClosing",
                                caption: "At Closing",
                                width: 90,
                                dataType: "number",
                                format: {
                                    type: "currency",
                                    precision: 2
                                },
                                editorOptions: {
                                    format: "$ #,##0.##"
                                },
                                cellTemplate(cellElement, cellInfo) {
                                    let displayText = cellInfo.text;
                                    cellElement.append(accounting.formatMoney(displayText));
                                }
                            }]
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    storageKey: self.gridStorageKey,
                };
            },
            initNetGridDataSource() {
                const self = this;
                self.netGridDataSource = {
                    key: self.itemKey,
                    load(){
                        return Promise.resolve(self.netItems);
                    },
                    insert(values){
                        self.onNetGridInsert(values);
                    },
                    update(key, values) {
                        self.onNetGridUpdate(key, values);
                    }
                };
            },
            getNextAvailableNetHudLine() {
                const self = this;
                let hudData = self.isHud ? self.netItems : self.originalData;
                let hudItems = _.filter(hudData, i => i.hudLine > 1600 && i.hudLine < 1700);
                let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.hudLine; });
                let nextAvailableLineNumber = _.getNumber(maxHudLineItem, "hudLine", 1600) + 1;

                return nextAvailableLineNumber;
            },
            onNetGridInsert(values) {
                const self = this;
                let newItem = new LoanCostModel(values);
                newItem.loanCostSectionDataID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.lineType = LoanCostTypes.AdditionalOtherCost;
                newItem.required = false;

                // We set hudLine values to push to server side
                newItem.hudLine = self.getNextAvailableNetHudLine();
                newItem.hudSection = 1600;

                // By setting the default to null we know that this is common among all settlement statements versus being specifically initialized to CDF / HUD
                // as they can be different based on defaulted lines
                newItem.defaultedSettlementType = null;

                self.items.push(newItem);
                // self.netItems.push(newItem);
                return Promise.resolve(newItem);
            },
            onNetGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.loanCostSectionDataID === key);
                let netIndex = _.findIndex(self.netItems, item => item.loanCostSectionDataID === key);
                if (itemIndex < 0) return;

                let newUpdateItem = new LoanCostModel((_.assign({}, self.items[itemIndex], values)));
                self.items[itemIndex] = newUpdateItem;
                self.netItems[netIndex] = newUpdateItem;

                self.updateLine1604();
                return Promise.resolve(newUpdateItem);
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },
        },
    };
</script>