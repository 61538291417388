<template>
    <div :class="{ 'img-upload-container rq-container content-wrapper':true, 'no-preview':!scannedSignatureExists }">
        <rq-banner
            variant="error"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="!isImageValid"
        />
        <rq-image-preview
            ref="signatureImage"
            v-model="imageData"
            :show-action="false"
        />
        <Teleport
            v-if="dialogFooterAvailable"
            :to="`#${dialogFooterId}`">
            <div class="img-upload-footer">
                <button  type="button" class="btn btn-primary" @click="$emit('close-dialog')">Close</button>
                <button v-if="scannedSignatureExists" class="btn btn-secondary" type="button" @click="onDeleteImageClick">Delete Image</button>
            </div>
        </Teleport>
    </div>
</template>

<script>
    import { ImageDataModel } from '@/shared/models/models';
    import { RoleType } from "@/shared/models/enums";

    export default {
        props: {
            args: { default: () => { return { buyerSellerId: 0 }; } }
        },
        data () {
            return {
                buyerSellerId: 0,
                imageData: {},
                imagePreviewVisible: false,
                imageScale: 1,
                isImageValid: true,
                errorMessage: ""
            };
        },
        provide(){
            return {
                imageInfoCallback: {
                    onImageChanged: (data) => {
                        if(data.validImage){
                            //reset to default behavior
                            this.isImageValid = true;
                            this.errorMessage = "";
                        }
                        else{
                            this.isImageValid = false;
                            this.errorMessage = data.message;
                        }
                        
                    }
                }
            }
        },
        computed: {
            scannedSignatureExists () { return (this.imageData && this.imageData.dataUrl); },
            isBusy() { return this.$rqBusy.isBusy(); },
            roleType() { return this.$route.name === "oe:oc:buyer" ? RoleType.Buyer : RoleType.Seller; },
            isSeller() { return this.roleType === RoleType.Seller; },
            message() { return `${this.isSeller ? "Seller" : "Buyer"} information saved`; }
        },
        inject: {
            dialogFooterId: { from: "footerId", default: null },
            dialogFooterAvailable: { from: "footerAvailable", default: false }
        },
        watch: {
            imageData: {
                handler: function(newValue, oldValue) {
                    let component = this.$refs.signatureImage;
                    if(component && component.imageChanged) {
                        this.save();
                    }
                },
                deep: true
            }
        },
        created () { this.buyerSellerId = this.args.buyerSellerId; },
        mounted () {
            this.fetchData();
            this.setImageScale();
        },
        methods: {

            fetchData () {
                const self = this;
                let apiPromise = self.$api.OrdersApi.getSignatureImageData(self.buyerSellerId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        if (!result) return;
                        self.imageData = new ImageDataModel(result);
                        self.setImageScale();
                    })
                    .catch(err => { self.onFailure(err, "retrieval"); });
            },

            setImageScale() {
                let imgHeight = _.get(this, "imageData.height", 0);
                let imgWidth = _.get(this, "imageData.width", 0);
                let scale = (!this.$el || !this.$el.clientHeight || !this.$el.clientWidth || imgHeight === 0 || imgWidth === 0)
                    ? 1
                    : Math.min(this.$el.clientHeight/imgHeight, this.$el.clientWidth/imgWidth);
                this.imageScale = scale > 1 ? 1 : scale < 0 ? 0.1 : scale;
            },

            onDeleteImageClick () {
                const self = this;
                self.$dialog.confirm("Delete Signature Image","Are you sure you want to delete this image?", () => { self.deleteImage(); });
            },

            deleteImage () {
                const self = this;
                let apiPromise = self.$api.OrdersApi.removeSignatureImage(this.buyerSellerId);
                self.$rqBusy.wait(apiPromise)
                    .then(x => {
                        self.imageData = {};
                        self.$toast.success({ message: self.message });
                    })
                    .catch(err => { self.onFailure(err, "deletion"); });
            },

            save () {
                const self = this;
                let apiPromise = self.$api.OrdersApi.updateSignatureImage(this.buyerSellerId, self.imageData);
                self.$rqBusy.wait(apiPromise)
                    .then(x => {
                        self.setImageScale();
                        if (self.imageData?.dataUrl) self.$toast.success({ message: self.message });
                        return Promise.resolve(true);
                    })
                    .catch(err => {
                        self.onFailure(err, "update");
                    });
            },

            onFailure(error, action){
                this.imageData = {};
                console.error(error);
                this.$toast.error(`Signature image ${action} failed.`);
                return Promise.resolve(false);
            },

            updateDimensions (e) {
                this.setImageScale();
            }
        }
    };
</script>