import Signings from "./views/Signings";
import { ViewActions } from "@/router/actions/closing";

const noAccessMsg = "Access to orders is not authorized.  Please contact your administrator.";

let pages = [{
    name: "signing",
    path: "",
    component: Signings,
    meta: {
        entity: "Signing",
        extension: false,
        label: "Signings",
        logRouteAccess: true,
        actions: ViewActions.SIGNINGS,
        accessKey: "OrderEntry",
        unauthorizedMessage: noAccessMsg
    }
}];

export default pages;
