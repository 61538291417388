<template>
    <div class="rq-container py-3">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="form-group">
            <file-number-input
                automation_id="txt_gfno"
                label="File #"
                v-model:order-id="ordersID"
                v-model:has-error="fileNumberInvalid"
                v-model="gfNo"
                required
                show-clear-button
                show-search-button
                @file-selected="fileSelected"
            />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { SearchRequest, SortOption } from "@/shared/models/models";
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    const DEFAULT_ERROR_MESSAGE = "Please correct the highlighted errors on screen to continue.";
    export default {
        name: 'CheckWritingTransferCheckToFile',
        components: { FileNumberInput },
        props: {
            checksID: {type: Number, required: true, default: 0}
        },
        data() {
            return {
                gfNo: "",
                ordersID: 0,
                fileNumberInvalid: false,
                errorMessage: ""
            }
        },
        created() {
            this.$emit("disable-ok");
        },
        watch: {
            fileNumberInvalid(newValue, oldValue) {
                if(newValue || newValue === oldValue || this.errorMessage !== DEFAULT_ERROR_MESSAGE) return;
                this.errorMessage = "";
                this.$emit("enable-ok");
            },
            ordersID(newValue) {
                if(_.parseNumber(newValue, 0) > 0) {
                    this.isInvalidGFNo(this.gfNo);
                } else {
                    this.$emit("disable-ok");
                }
            },
            gfNo(newValue) {
                if(_.isNullOrEmpty(newValue)) {
                    this.errorMessage = "";
                }
            }
        },
        methods: {
            fileSelected(file) {
                const self = this;
                self.isInvalidGFNo(file.data.gfNo);
            },
            isInvalidGFNo(value) {
                this.$emit("disable-ok");
                this.errorMessage = "";
                if(value === "") {
                    return false;
                }

                let apiPromise = this.$api.CheckWritingApi.canTransferCheckToFile(this.checksID, value);
                this.$rqBusy.wait(apiPromise)
                    .then((ordersID) => {
                        if (ordersID == 0) {
                            this.errorMessage = "Escrow banks not the same.";
                            this.$emit("disable-ok");
                        } else {
                            this.errorMessage = "";
                            this.$emit("enable-ok");
                        }
                    });
            },

            save(){
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.saveCheckToFile(self.checksID, self.ordersID);
                return self.$rqBusy.wait(apiPromise);
            },
        },
    }
</script>