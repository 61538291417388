import OrderEntryPage from "./OrderEntry";
import oeBeforeRouteEnter from "./route-guards";
import Routes from "./routes";

//_.forEach(Routes, r => { r.meta.theme = "teal"; });

let OrderEntryModule = {
    meta: { label: "File Information", include: true, accessKey: "OrderEntry"},
    path: "oe",
    component: OrderEntryPage,
    children: Routes,
    beforeEnter: oeBeforeRouteEnter
};

export default OrderEntryModule;
