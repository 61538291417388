<template>
    <rq-page-section title="Prepayment Penalty" collapsible>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-2 col-md-6 col-lg-3 form-group">
                    <label for="rb_loan_prepayment_penalty">Does the loan have a prepayment penalty?</label>
                    <rq-radio-group 
                        id="rb_loan_prepayment_penalty"
                        v-model="loanTerm.isPrepaymentPenaltyIncrease"
                        inline
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_max_prepayment_penalty">Maximum Prepayment Penalty</label>
                    <rqInputNumber
                        id="txt_max_prepayment_penalty"
                        automation_id="txt_max_prepayment_penalty"
                        defaultValue="0"
                        decimals="2"
                        input-group
                        :disabled="!loanTerm.isPrepaymentPenaltyIncrease"
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="loanTerm.prepaymentPenaltyAmount"
                    />
                </div>
            </div>
        </fieldset>
    </rq-page-section>
</template>
<script>
import { LoanTermSectionHud2010DataModel } from '@settlement/models';
export default {
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        loanTerm: {
            type: Object,
            default: () => {
                return {}
            }
        },
        originalLoanTerm: {
            type: Object,
            default: () => {
                return new LoanTermSectionHud2010DataModel()
            }
        }
    },
}
</script>