<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"/>
        <rq-page-section borderless>
            <div class="header-actions" v-if="!hasAccess">
                <ul class="nav">
                    <li class="nav-item">
                        <span class="badge badge-danger rq-section-badge">Associated Subdivision is not in your region</span>
                    </li>
                </ul>
            </div>

            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-6 form-group form-required" :class="{'has-error' : v$.subdivision.name.$error}" >
                        <label for="txt_subdivision_name" class="form-control-label">Name</label>
                        <input id="txt_subdivision_name" automation_id="txt_subdivision_name" required maxlength="160" type="text" v-model="subdivision.name" class="form-control" />
                        <rq-validation-feedback
                            :messages="{ 
                                'Subdivision Name is required. ': v$.subdivision.name.required.$invalid,
                                'Subdivision Name already exists.': v$.subdivision.name.isUnique.$invalid
                             }"
                        />
                    </div>
                    <div class="col-6 form-group">
                        <label for="drp_regions" class="form-control-label">Region</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_regions', id: 'drp_regions' }"
                            :items="regionsLookup"
                            value-expr="regionID"
                            display-expr="displayName"
                            v-model="subdivision.regionID"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="plat" class="form-control-label">Plat</label>
                        <input id="plat" automation_id="txt_plat" maxlength="20" name="plat" type="text"  v-model="subdivision.plat" class="form-control" />
                    </div>
                    <div class="col-6 form-group">
                        <label for="dtp_patent_date" class="form-control-label">File Date</label>
                        <rqdx-date-box
                            id="dtp_patent_date"
                            v-model="subdivision.patentDate"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="notes" class="form-control-label">Notes</label>
                        <textarea id="notes" automation_id="ta_notes" maxlength="60" rows="5" name="notes"  v-model="subdivision.notes" class="form-control"></textarea>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { SubdivisionDto } from "@/shared/models/models";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";

    export default {
        name: "SubdivisionForm",
        props: {
            embeddedMode: { type: Boolean, default: false },
            subdivisionId: { type: Number, default: 0 },
            subdivisionData: { type: Object, default: null }
        },

        data () {
            return {
                originalSubdivision: new SubdivisionDto(),
                subdivision: new SubdivisionDto(),
                showBanner: false,
                serverValidationErrors: [],
                duplicateValue: ""
            };
        },

        watch:{
            subdivision: {
                handler(newValue, oldValue){
                    if (_.isNil(oldValue)) return;
                    let isDirty = !_.isEqual(newValue, this.originalSubdivision);
                    this.subdivision.isDirty = isDirty;
                },
                immediate: false,
                deep: true
            }
        },
        setup: () => ({ v$: useVuelidate() }),

        computed: {
            ...mapState({
                order: state => state.orders.order,
                regions: state => state.authentication.session.regions
            }),
            ...mapGetters([
                "regionsLookup"
            ]),
            readOnly() { return !this.hasAccess; },
            userDefaultRegion () {
                let defaultRegion = _.find(this.regions, r => r.isDefault);
                return _.isNil(defaultRegion) ? -1 : defaultRegion;
            },
            addMode() { return this.subdivision.subdivisionID === 0; },
            allValidationErrors() {return this.subdivision.isDirty ? _.concat(this.subdivision.validationErrors, this.serverValidationErrors) : []},
            hasAccess(){
                if (this.addMode) return true;
                if (!this.subdivision) return false;
                let originalSubdivisionRegion = _.find(this.regions, r => _.parseNumber(r.regionID) === _.parseNumber(this.originalSubdivision.regionID));
                let subdivisionRegion = _.find(this.regions, r => _.parseNumber(r.regionID) === _.parseNumber(this.subdivision.regionID));
                let adminRegion = _.find(this.regions, r => r.isAdminRegion);
                let userHasAccessToRegion = !_.isNil(subdivisionRegion) && !_.isNil(originalSubdivisionRegion);
                let userHasAdminRegion = !_.isNil(adminRegion);
                return userHasAdminRegion || userHasAccessToRegion;
            }
        },
        validations() {
            const self = this;
            return {
                subdivision: {
                    name: {
                        required,
                        isUnique: val => _.isEmpty(self.duplicateValue) || val !== self.duplicateValue
                    },
                }
            }
        },
        methods: {
            loadSubdivision(data) {
                const self = this;

                self.originalSubdivision = new SubdivisionDto(data);
                self.subdivision = new SubdivisionDto(data);
                $('#txt_subdivision_name')[0].focus();
            },

            validate () {
                 const self = this;
                self.v$.$touch();
                this.showBanner = true;
                return { success: this.subdivision.isValid, errors: this.subdivision.validationErrors };
            },

            save () {
                const self = this;
                let dialogMode = self.addMode ? "add" : "edit";
                let saveMode = self.addMode ? "created" : "saved";

                self.serverValidationErrors = [];
                let savePromise = new Promise((resolve, reject) => {
                    let changes = self.getAuditChanges(self.originalSubdivision, self.subdivision);
                    if (changes.length > 0) {
                        self.$api.SubdivisionsApi.saveSubdivision(self.subdivision)
                            .then(subdivision => {
                                self.$toast.success({ message: `Subdivision ${saveMode} successfully.` });
                                self.reset();
                                resolve({ success:true, dialogMode, data: subdivision });
                            })
                            .catch(err => {
                                self.serverValidationErrors.push(err.errorMessage);
                                if(_.includes(self.serverValidationErrors[0], 'already exists.')){
                                    self.duplicateValue = self.subdivision.name;
                                }
                                reject({ success:false, dialogMode, data: self.subdivision });
                            });
                    } else {
                        resolve({ success: true, dialogMode, data: self.subdivision, noChanges: true });
                    }
                });
                return self.$rqBusy.wait(savePromise);
            },
            reset () {
                const self = this;
                self.showBanner = false;
                self.duplicateValue = "";
                self.v$.$reset();
            }
        }
    };
</script>