import { Region } from "@/shared/models/models";
import { AlertSeverity } from "@/shared/models/enums";
import { CDFTaxType, Hud1974TaxType, Hud2010TaxType } from '@config/enums';
import { AltaCategories, EscrowOverrideOptions, HudLineTypes, RecordingFeeGridType } from '@settlement/models';
import { EndorsementPolicy, BreakoutEndorsementOption } from "./enums";
import { DateTime } from "luxon";

export * from "./enums";

export class PayoffDetail {
    constructor(options) {
        options = options || {};
        const _uid = -_.uniqueId();
        this.payoffDetailID = options.payoffDetailID || _uid;
        this.payoffID = options.payoffID || null;
        this.payoffDescriptionID = options.payoffDescriptionID === -1 ? null : options.payoffDescriptionID || null;
        this.payoffDescription = options.payoffDescription || "";
        this.amount = parseFloat(options.amount) || 0;

        //client model helper properties
        this.clientKey = options.clientKey || _uid;
        this.editMode = _.isBoolean(options.editMode) ? options.editMode : false;
        this.newItem = _.isBoolean(options.newItem) ? options.newItem : false;
        this.focus = options.focus || "";
    }

    get isEmpty() { return _.isEmpty(this.payoffDescription) && _.eq(this.amount, 0); }

    toDataObject() {
        return _.pick(this, [
            "payoffDetailID",
            "payoffID",
            "payoffDescriptionID",
            "payoffDescription",
            "amount"
        ]);
    }
}

export class CalculatorListItem {
    constructor(options, data=null) {
        options = options || {};
        this.id = options.id || 0;
        this.description = options.description || "(No Description)";
        this.amount = options.amount || null;
        this.amounts = options.amounts || null;
        this.selected = _.isBoolean(options.selected) ? options.selected : false;

        this.data = data || {}; //original object from which the list item options were derived
    }
}

export class ListItemModel {
    constructor(options, data=null) {
        options = options || {};
        this.id = options.id || 0;
        this.description = options.description || "(No Description)";
        this.amount = options.amount || null;
        this.amounts = options.amounts || null;
        this.selected = _.isBoolean(options.selected) ? options.selected : false;

        this.data = data || {}; //original object from which the list item options were derived
    }
}

export class CancelableItem {
	constructor(options){
		this.dataObjectExclude = ["originalData"];
		this.originalData = {};

		this.init(options);
		this.refreshOriginalData();
	}
	init(options) { /* override this function with subclass data initialization */ }
	get currentData() { return this.getCurrentData(); }
	getCurrentData() { return _.pickBy(this, (value,key) => { return !_.includes(this.dataObjectExclude, key); }); }
	refreshOriginalData() { this.originalData = this.getCurrentData(); }
	revertToOriginalData() { this.init(this.originalData); }
}

export class RecordingInformationItem extends CancelableItem {
	constructor(options) { super(options); }
	init(options) {
		let _options = _.cloneDeep(options || {});
		this.clientKey = _.uniqueId("recording-information-");
		this.company = _options.company || "";
		this.document = _options.document || "";
		this.hud = _options.hud || null;
		this.hudLine = _options.hudLine || null;
		this.ss = _options.ss || null;
		this.ssLine = _options.ssLine || null;
		this.recordingInformationID = _.parseNumber(_options.recordingInformationID, 0);
		this.ordersID = _.parseNumber(_options.ordersID, null);
		this.loanID = _.parseNumber(_options.loanID, null);
		this.recordingDocumentID = _.parseNumber(_options.recordingDocumentID, null);
		this.hudMainID = _.parseNumber(_options.hudMainID, null);
		this.recordingSection = _.parseNumber(_options.recordingSection, null);
		this.cssMainID = _.parseNumber(_options.cssMainID, null);
		this.cssLineID = _.parseNumber(_options.cssLineID, null);
		this.recordingFee = _.parseNumber(_options.recordingFee, 0);
		this.recordingDate = _options.recordingDate || null;
		this.amount = _.parseNumber(_options.amount, 0);
		this.notes = _options.notes || null;
		this.companyID = _.parseNumber(_options.companyID, null);
		this.company = _options.company;
		this.lineType = _.parseNumber(_options.lineType, null);
		this.lineTypeID = _.parseNumber(_options.lineTypeID, null);
		this.cdfMainID = _.parseNumber(_options.cdfMainID, null);
		this.cdfLineID = _.parseNumber(_options.cdfLineID, null);
		this.cdfRecordingItemizationID = _.parseNumber(_options.cdfRecordingItemizationID, null);
		this.contactID = null;
		this.contactName = null;

		this.selected = _.parseBool(_options.selected);
		this.dataObjectExclude.push("selected");
	}

	get id() { return this.recordingInformationID; }
	get description() { return this.document || "(No Document Selected)"; }
	get amounts() { return [this.amount, this.recordingFee]; }


    get companyData() {
        return { companyID: this.companyID, companyName: this.company };
    }

    set companyData(val) {
        this.companyID = _.get(val, "companyID", null);
        this.company = _.get(val, "companyName", null);
    }
}

export const CALCULATION_BASIS = {
	FLAT_FEE: 0,
	PER_PAGE: 1
}

export class BaseCalculation  {
	constructor(options) {
		let _options = _.cloneDeep(options || {});

	   this.ordersId = _options.ordersId || null;
	   this.loanId = _options.loanId || null;

	   this.overrideCalculations = _.isBoolean(_options.overrideCalculations) ? _options.overrideCalculations : false;

	   this.recordingDocumentTypeID = _.parseNumber(_options.recordingDocumentTypeID, null);
	   this.buyerAmount = _.parseNumber(_options.buyerAmount, 0);
	   this.sellerAmount = _.parseNumber(_options.sellerAmount, 0);
	   this.overrideBuyerAmount = _.parseNumber(_options.overrideBuyerAmount, null);
	   this.overrideSellerAmount = _.parseNumber(_options.overrideSellerAmount, null);

	   this.hud1974LineId = _.parseNumber((_options.hud1974LineId || _options.huD1974LineId), 0);
	   this.hud2010LineId = _.parseNumber((_options.hud2010LineId || _options.huD2010LineId), 0);
	   this.cdfLineId = _.parseNumber(_options.cdfLineId, 0);
	   this.cssLineId = _.parseNumber(_options.cssLineId, 0);
	   this.hud1974Present = _.parseNumber((_options.hud1974Present || _options.huD1974Present), 0);
	   this.hud2010Present = _.parseNumber((_options.hud2010Present || _options.huD2010Present), 0);
	   this.cdfPresent = _.parseNumber(_options.cdfPresent, 0);
	   this.cssPresent = _.parseNumber(_options.cssPresent, 0);
	   this.description = _options.description || null;
	   this.hud1974LineType = _.parseNumber((_options.hud1974LineType || _options.huD1974LineType), 0);
	   this.hud2010LineType = _.parseNumber((_options.hud2010LineType || _options.huD2010LineType), 0);
	   this.cdfLineType = _.parseNumber(_options.cdfLineType, 0);
	   this.cssLineType = _.parseNumber(_options.cssLineType, 0);
	   this.whoPays = _.parseNumber(_options.whoPays, 0);
	   this.percentage = _.parseNumber(_options.percentage, 100);
	   this.payeeCompanyId = _options.payeeCompanyId || null;
	   this.payeeCompanyName = _options.payeeCompanyName || null;
	   this.accountCodeId = _options.accountCodeId || null;
	   this.calculationBasis = _.parseNumber(_options.calculationBasis, 0);
	   this.flatFeeAmount = _.parseNumber(_options.flatFeeAmount, 0);
	   this.netFund = _.parseNumber(_options.netFund, null);
	   this.paidForBorrower = _.parseNumber(_options.paidForBorrower, 0);
	   this.paidForSeller = _.parseNumber(_options.paidForSeller, 0);
	   this.cdfSection = _options.cdfSection;
	   this.paidForBorrowerBeforeClosing = _.parseNumber(_options.paidForBorrowerBeforeClosing, 0);
	   this.paidForSellerBeforeClosing = _.parseNumber(_options.paidForSellerBeforeClosing, 0);
	   this.isDeleted = _options.isDeleted || false;
	   this.pocWhom = options.pocWhom || "";
	   this.amountPOC = _.parseNumber(options.amountPOC, 0);
       this.recordingDocumentCreated = _options.recordingDocumentCreated || false;
	   this.selected = _.isBoolean(_options.selected) ? _options.selected : false;
	   this.overrideDescription = _options.overrideDescription || null;
   }
}
export class CombinedCalculation extends BaseCalculation {
	constructor(options, ssType) {
		super(options);
		let _options = options || {};
		this.clientKey = _.uniqueId("rft-calculation-");

		this.manualRecordingFeeCalculationID = _.parseNumber(_options.manualRecordingFeeCalculationID, 0);
		this.recordingFeeConfigurationID = _options.recordingFeeConfigurationID || null;
		this.manualTransferTaxCalculationID = _.parseNumber(_options.manualTransferTaxCalculationID, 0);
		this.transferTaxConfigurationID = _options.transferTaxConfigurationID || null;

		this.recordingFeeGridType = RecordingFeeGridType.None;
		if(_.isNil(_options.recordingFeeGridType)) {
			if(this.manualRecordingFeeCalculationID > 0){
				this.recordingFeeGridType = RecordingFeeGridType.Fee;
			}
			else if(this.manualTransferTaxCalculationID > 0){
				this.recordingFeeGridType = RecordingFeeGridType.Tax;
			}
		}
		else {
			this.recordingFeeGridType = _options.recordingFeeGridType;
		}

		//Recording Fees - start
		this.pagesCount = _.parseNumber(_options.pagesCount, null);
		this.hudLine = _.parseNumber(options.hudLine, null);
		this.payeeContactId = null;
		this.payeeContactName = null;
		this.initialPages = _options.initialPages || null;
		this.initialPageAmount = _options.initialPageAmount || null;
		this.additionalPageAmount = _options.additionalPageAmount || null;
		this.supermarketSource = _options.supermarketSource || null;
		this.overridePagesCount = _options.overridePagesCount || null;
		this.payeeCompanyName = _options.payeeCompanyName || null;
		//Recording Fees - end

		//Transfer Taxes - start
		this.hud1974AdditionalDescription = _options.hud1974AdditionalDescription || null;
		this.hud2010AdditionalDescription = _options.hud2010AdditionalDescription || null;
		this.cdfAdditionalDescription = _options.cdfAdditionalDescription || null;
		this.cssAdditionalDescription = _options.cssAdditionalDescription || null;
		this.percentageMinimum = _options.percentageMinimum || null;
		this.percentageMaximum = _options.percentageMaximum || null;
		this.percentagePercent = _options.percentagePercent || null;
		this.chargePercentageBasisID = _options.chargePercentageBasisID || null;
		this.scalableBasisID = _options.scalableBasisID || null;
		this.scalableMinimum = _options.scalableMinimum || null;
		this.scalableMaximum = _options.scalableMaximum || null;
		this.coverageRoundingID = _options.coverageRoundingID || null;
		this.calculationRoundingID = _options.calculationRoundingID || null;
		this.supermarketSource = _options.supermarketSource || null;
		this.paidByOthers = _.parseNumber(_options.paidByOthers, 0);
		//Transfer Taxes - end

		// Recording Fees & Transfer Taxes Shared Fields - start
		this.includedInLastFeeCalc = _options.includedInLastFeeCalc || false; // this field is not editable in the UI but we must account for it in the UI model so that it is not overwritten on save
		// Recording Fees & Transfer Taxes Shared Fields - end

		this.ssType = ssType;


	}

	get manualCalculationID() {
		return this.recordingFeeGridType ===  0
			? this.manualRecordingFeeCalculationID
			: this.manualTransferTaxCalculationID;
	}

	get calculationConfigurationID() {
		return this.recordingFeeGridType ===  0
			? this.recordingFeeConfigurationID
			: this.transferTaxConfigurationID;
	}

	get lineType() {
		let rawLineType = 0;
		switch(this.ssType) {
			case SETTLEMENT_TYPE.CDF:
				rawLineType = this.cdfLineType;
				break;
			case SETTLEMENT_TYPE.HUD_1974:
				rawLineType = this.hud1974LineType;
				break;
			case SETTLEMENT_TYPE.HUD_2010:
				rawLineType = this.hud2010LineType;
				break;
		}
		return this.recordingFeeGridType === RecordingFeeGridType.Tax
			? rawLineType + 100
			: rawLineType;
	}

	set lineType(val) {
		this.setLineType(val);
	}

	get cdfType() {
		return CDFTaxType.displayValue(this.cdfLineType);
	}

	get hud1974Type() {
		return Hud1974TaxType.displayValue(this.hud1974LineType);
	}

	get hud2010Type() {
		return Hud2010TaxType.displayValue(this.hud2010LineType);
	}

	get isTransferTaxType() {
		if (this.cdfLineType === CDFTaxType.E02CityTransferTax
			|| this.cdfLineType === CDFTaxType.E02CountyTransferTax
			|| this.cdfLineType === CDFTaxType.E02StateTransferTax
			|| this.hud1974LineType === Hud1974TaxType.Line1202CityCountyDeed
			|| this.hud1974LineType === Hud1974TaxType.Line1202CityCountyMortgage
			|| this.hud1974LineType === Hud1974TaxType.Line1203StateDeed
			|| this.hud1974LineType === Hud1974TaxType.Line1203StateMortgage
			|| this.hud2010LineType === Hud2010TaxType.Line1204CityCountyDeed
			|| this.hud2010LineType === Hud2010TaxType.Line1204CityCountyMortgage
			|| this.hud2010LineType === Hud2010TaxType.Line1205StateDeed
			|| this.hud2010LineType === Hud2010TaxType.Line1205StateMortgage) {
				return true;
			}

		return false;
	}

	get id() { return this.manualCalculationID}
	get amounts() {
		return this.overrideCalculations
			? [this.overrideBuyerAmount, this.overrideSellerAmount]
			: [this.buyerAmount, this.sellerAmount];
	}

	get descriptionDisplay() {
		if (this.descriptionOverridden)
			return this.overrideDescription;
		else
			return this.description;
	}
	set descriptionDisplay(val) {
		if (this.description != val)
        {
            this.overrideDescription = val;
            this.setOverrideCalculations();
        }
	}

	get pagesCountDisplay() {
		if (this.pagesCountOverridden)
			return this.overridePagesCount;
		else
			return this.pagesCount;
	}
	set pagesCountDisplay(val) {
		if (this.supermarketSource)
			this.overridePagesCount = val;
		else
			this.pagesCount = val;
	}

	get buyerAmountDisplay() {
		if (this.buyerAmountOverridden)
				return this.overrideBuyerAmount;
		else
			return this.buyerAmount;
	}
	set buyerAmountDisplay(val) {
		if (this.calculationConfigurationID != null || this.supermarketSource)
			this.overrideBuyerAmount = val;
		else
			this.buyerAmount = val;
	}
	get sellerAmountDisplay() {
		if (this.sellerAmountOverridden)
			return this.overrideSellerAmount;
		else
			return this.sellerAmount;
	}
	set sellerAmountDisplay(val) {
		if (this.calculationConfigurationID != null || this.supermarketSource)
			this.overrideSellerAmount = val;
		else
			this.sellerAmount = val;
	}

	get buyerAmountOverridden() { return !_.isNil(this.overrideBuyerAmount) }
	get sellerAmountOverridden() { return !_.isNil(this.overrideSellerAmount) }
	get pagesCountOverridden() { return !_.isNil(this.overridePagesCount)}
	get descriptionOverridden() { return !_.isNil(this.overrideDescription)}

	setOverrideCalculations(){
		this.overrideCalculations = !_.isNil(this.overrideBuyerAmount) || !_.isNil(this.overrideSellerAmount) || !_.isNil(this.overrideDescription) || !_.isNil(this.overridePagesCount);
	}

	setLineType(val) {
		let rawLineType = (this.recordingFeeGridType === RecordingFeeGridType.Tax && val >= 100)
			? val - 100
			: val;
		switch(this.ssType) {
			case SETTLEMENT_TYPE.CDF:
				this.cdfLineType = rawLineType;
				break;
			case SETTLEMENT_TYPE.HUD_1974:
				this.hud1974LineType = rawLineType;
				break;
			case SETTLEMENT_TYPE.HUD_2010:
				this.hud2010LineType = rawLineType;
				break;
		}
	}

	// RQO-7714: calculate buyer/seller amount for Per Page calculation basis.
	calculate() {
		if (this.overrideCalculations) return;

		if (this.calculationBasis == CALCULATION_BASIS.PER_PAGE){
			if (this.pagesCount == 0){
				this.sellerAmount = 0;
				this.buyerAmount = 0;
				return;
			}

			let remainingPages = 0;
			if (this.pagesCount > this.initialPages)
				remainingPages = _.parseNumber(this.pagesCount, 0) - this.initialPages;

			let wholeAmount = _.parseNumber(this.initialPageAmount, 0) + (remainingPages * _.parseNumber(this.additionalPageAmount, 0));
			let whoPaysAmount = (wholeAmount * this.percentage) / 100;
			let otherPaysAmount = wholeAmount - whoPaysAmount;

			this.sellerAmount = (this.whoPays == 1) ? whoPaysAmount : otherPaysAmount;
			this.buyerAmount = (this.whoPays == 0) ? whoPaysAmount : otherPaysAmount;
		}
	}
}

export class ManualRecordingFeeCalculation extends BaseCalculation {
	constructor(options) {
		super(options);

		let _options = _.cloneDeep(options || {});
		this.clientKey = _.uniqueId("manual-recording-fee-calculation-");
		this.manualRecordingFeeCalculationID = _.parseNumber(_options.manualRecordingFeeCalculationID, 0);

		this.recordingFeeConfigurationID = _options.recordingFeeConfigurationID || null;

		this.pagesCount = _.parseNumber(_options.pagesCount, null);
		this.hudLine = _.parseNumber(options.hudLine, null);
		this.payeeContactId = null;
		this.payeeContactName = null;
		this.initialPages = _options.initialPages || null;
		this.initialPageAmount = _options.initialPageAmount || null;
		this.additionalPageAmount = _options.additionalPageAmount || null;
		this.includedInLastFeeCalc = _options.includedInLastFeeCalc || false; // this field is not editable in the UI but we must account for it in the UI model so that it is not overwritten on save
		this.supermarketSource = _options.supermarketSource;
		this.overridePagesCount = _options.overridePagesCount;
	}

	get id() { return this.manualRecordingFeeCalculationID; }
	get amounts() {
		return this.overrideCalculations
			? [this.overrideBuyerAmount, this.overrideSellerAmount]
			: [this.buyerAmount, this.sellerAmount];
	}

	get buyerAmountDisplay() {
		if (this.buyerAmountOverridden)
		 	return this.overrideBuyerAmount;
		else
			return this.buyerAmount;
	}
	set buyerAmountDisplay(val) {
		if (this.recordingFeeConfigurationID != null)
			this.overrideBuyerAmount = val;
		else
			this.buyerAmount = val;
	}
	get sellerAmountDisplay() {
		if (this.sellerAmountOverridden)
			return this.overrideSellerAmount;
		else
			return this.sellerAmount;
	}
	set sellerAmountDisplay(val) {
		if (this.recordingFeeConfigurationID != null)
			this.overrideSellerAmount = val;
		else
			this.sellerAmount = val;
	}

	get buyerAmountOverridden() { return !_.isNil(this.overrideBuyerAmount) }
	get sellerAmountOverridden() { return !_.isNil(this.overrideSellerAmount) }

	setOverrideCalculations(){
		this.overrideCalculations = !_.isNil(this.overrideBuyerAmount) || !_.isNil(this.overrideSellerAmount);
	}

	// RQO-7714: calculate buyer/seller amount for Per Page calculation basis.
	calculate() {
		if (this.overrideCalculations) return;

		if (this.calculationBasis == CALCULATION_BASIS.PER_PAGE){
			if (this.pagesCount == 0){
				this.sellerAmount = 0;
				this.buyerAmount = 0;
				return;
			}

			let remainingPages = 0;
			if (this.pagesCount > this.initialPages)
				remainingPages = _.parseNumber(this.pagesCount, 0) - this.initialPages;

			let wholeAmount = _.parseNumber(this.initialPageAmount, 0) + (remainingPages * _.parseNumber(this.additionalPageAmount, 0));
			let whoPaysAmount = (wholeAmount * this.percentage) / 100;
			let otherPaysAmount = wholeAmount - whoPaysAmount;

			this.sellerAmount = (this.whoPays == 1) ? whoPaysAmount : otherPaysAmount;
			this.buyerAmount = (this.whoPays == 0) ? whoPaysAmount : otherPaysAmount;
		}
	}
}

export class ManualTransferTaxCalculation extends BaseCalculation {
	constructor(options) {
		super(options);
		let _options = _.cloneDeep(options || {});
		this.clientKey = _.uniqueId("manual-transfer-tax-calculation-");
		this.manualTransferTaxCalculationID = _.parseNumber(_options.manualTransferTaxCalculationID, 0);

		this.transferTaxConfigurationID = _options.transferTaxConfigurationID || null;

		this.hud1974AdditionalDescription = _options.hud1974AdditionalDescription || null;
		this.hud2010AdditionalDescription = _options.hud2010AdditionalDescription || null;
		this.cdfAdditionalDescription = _options.cdfAdditionalDescription || null;
		this.cssAdditionalDescription = _options.cssAdditionalDescription || null;
		this.percentageMinimum = _options.percentageMinimum || null;
		this.percentageMaximum = _options.percentageMaximum || null;
		this.percentagePercent = _options.percentagePercent || null;
		this.chargePercentageBasisID = _options.chargePercentageBasisID || null;
		this.scalableBasisID = _options.scalableBasisID || null;
		this.scalableMinimum = _options.scalableMinimum || null;
		this.scalableMaximum = _options.scalableMaximum || null;
		this.coverageRoundingID = _options.coverageRoundingID || null;
		this.calculationRoundingID = _options.calculationRoundingID || null;
		this.paidByOthers = _.parseNumber(_options.paidByOthers, 0);
		this.includedInLastFeeCalc = _options.includedInLastFeeCalc || false; // this field is not editable in the UI but we must account for it in the UI model so that it is not overwritten on save
	}

	get cdfType() {
		return CDFTaxType.displayValue(this.cdfLineType);
	}

	get hud1974Type() {
		return Hud1974TaxType.displayValue(this.hud1974LineType);
	}

	get hud2010Type() {
		return Hud2010TaxType.displayValue(this.hud2010LineType);
	}

	get isTransferTaxType() {
		if (this.cdfLineType === CDFTaxType.E02CityTransferTax
			|| this.cdfLineType === CDFTaxType.E02CountyTransferTax
			|| this.cdfLineType === CDFTaxType.E02StateTransferTax
			|| this.hud1974LineType === Hud1974TaxType.Line1202CityCountyDeed
			|| this.hud1974LineType === Hud1974TaxType.Line1202CityCountyMortgage
			|| this.hud1974LineType === Hud1974TaxType.Line1203StateDeed
			|| this.hud1974LineType === Hud1974TaxType.Line1203StateMortgage
			|| this.hud2010LineType === Hud2010TaxType.Line1204CityCountyDeed
			|| this.hud2010LineType === Hud2010TaxType.Line1204CityCountyMortgage
			|| this.hud2010LineType === Hud2010TaxType.Line1205StateDeed
			|| this.hud2010LineType === Hud2010TaxType.Line1205StateMortgage) {
				return true;
			}

		return false;
	}

	get id() { return this.manualTransferTaxCalculationID; }
	get amounts() {
		return this.overrideCalculations
			? [this.overrideBuyerAmount, this.overrideSellerAmount]
			: [this.buyerAmount, this.sellerAmount];
	}

	get buyerAmountDisplay() {
		if (this.buyerAmountOverridden)
		 	return this.overrideBuyerAmount;
		else
			return this.buyerAmount;
	}
	set buyerAmountDisplay(val) {
		if (this.transferTaxConfigurationID != null)
			this.overrideBuyerAmount = val;
		else {
			this.buyerAmount = val;
		}
	}
	get sellerAmountDisplay() {
		if (this.sellerAmountOverridden)
			return this.overrideSellerAmount;
		else
			return this.sellerAmount;
	}
	set sellerAmountDisplay(val) {
		if (this.transferTaxConfigurationID != null)
			this.overrideSellerAmount = val;
		else {
			this.sellerAmount = val;
		}
	}

	get buyerAmountOverridden() { return !_.isNil(this.overrideBuyerAmount) }
	get sellerAmountOverridden() { return !_.isNil(this.overrideSellerAmount) }

	setOverrideCalculations(){
		this.overrideCalculations = !_.isNil(this.overrideBuyerAmount) || !_.isNil(this.overrideSellerAmount);
	}
}

export class ProrateDto {
	constructor(options) {
		options = options || {};

		this.prorateID = _.parseNumber(options.prorateID, 0);
		this.description = options.description || null;
		this.amount = _.parseNumber(options.amount, null);
		this.currentTaxes = _.parseNumber(options.currentTaxes, 0);
		this.taxCategory = _.parseNumber(options.taxCategory, 0);
		this.propertyID = options.propertyID || null;

		this.fromDate = options.fromDate || null;
		this.toDate = options.toDate || null;
		this.prorationDate = options.prorationDate || null;
		this.addendumTaxDescription = options.addendumTaxDescription || this.description;
		this.addendumBuyerAmount = _.parseNumber(options.addendumBuyerAmount, 0);
		this.addendumSellerAmount = _.parseNumber(options.addendumSellerAmount, 0);
		this.addendumBuyerPaidInAdvance = _.parseNumber(options.addendumBuyerPaidInAdvance, 0);
		this.addendumSellerPaidInAdvance = _.parseNumber(options.addendumSellerPaidInAdvance, 0);
		this.addendumBuyerNotYetDue = _.parseNumber(options.addendumBuyerNotYetDue, 0);
		this.addendumSellerNotYetDue = _.parseNumber(options.addendumSellerNotYetDue, 0);
		this.daysInYear = _.parseNumber(options.daysInYear, 0);
		this.periodsPerYear = _.parseNumber(options.periodsPerYear, 1);
		this.periodsInArrears = _.parseNumber(options.periodsInArrears, 0);
		this.defaultProrationMethod = options.defaultProrationMethod || null;
		this.defaultProrationDate = options.defaultProrationDate || null;
		this.payeeCompanyID = options.payeeCompanyID || null;
		this.payeeCompanyName = options.payeeCompanyName || null;
		this.paymentDueDescription = options.paymentDueDescription || null;
		this.daysInYearDescription = options.daysInYearDescription || null;
		this.prorationMethodDescription = options.prorationMethodDescription || null;
		this.overrideProrationDate = _.isBoolean(options.overrideProrationDate) ? options.overrideProrationDate : false;
		this.overrideAmount = _.isBoolean(options.overrideAmount) ? options.overrideAmount : false;
		this.overrideDescription = _.isBoolean(options.overrideDescription) ? options.overrideDescription : false;
		this.originalDescription = options.originalDescription;
		this.fullOverride = _.isBoolean(options.fullOverride) ? options.fullOverride : false;
		this.includeIn1099 = _.isBoolean(options.includeIn1099) ? options.includeIn1099 : false;
		this.paymentDue = _.parseNumber(options.paymentDue, 0);
		this.sequence = options.sequence || null;
		this.firstPeriodStartDate = options.firstPeriodStartDate || null;
		this.rent = _.isBoolean(options.rent) ? options.rent : false;
		this.prorationMethod = options.prorationMethod || null;
		this.selected = _.isBoolean(options.selected) ? options.selected : false;
		this.result1 = options.result1;
		this.result2 = options.result2;
		this.result3 = options.result3;
		this.buyerAmount = _.parseNumber(options.buyerAmount, 0);
		this.sellerAmount = _.parseNumber(options.sellerAmount, 0);
		this.startOfMonthDate = options.startOfMonthDate || null;
		this.isDateEdited = _.parseBool(options.isDateEdited);
		this.enableCalculation = _.isBoolean(options.enableCalculation) ? options.enableCalculation : false;
	}

	get id() { return this.prorateID; }
	get displayAmount() { return (this.overrideAmount === true || !this.taxCategory) ? this.amount : this.currentTaxes; }
	get displayValues() {
		return [
			{ label:"Description", value: this.description, hideIfEmpty: false, format:"none" },
			{ label:"Amount", value: (this.overrideAmount === true || !this.taxCategory) ? this.amount : this.currentTaxes, hideIfEmpty: false, format:"money" }
		];
	}

	get buyerAmountDisplay(){
		return Math.abs(this.buyerAmount);
	}

	get sellerAmountDisplay(){
		return Math.abs(this.sellerAmount);
	}

	get totalAmountDisplay(){
		if(this.overrideAmount || !this.enableCalculation)
			return this.amount;
		return this.currentTaxes;
	}
	get results(){
		let results = [];
		if(this.result1) results.push({key:1, description: this.description, result: this.result1});
		if(this.result2) results.push({key:2, description: this.description, result: this.result2});
		if(this.result3) results.push({key:3, description: this.description, result: this.result3});
		return results;
	}

    toDataObject(condensed=false) {
		let omittedProperties = ["id", "payee", "displayAmount", "selected", "results"];
		return condensed
			? _.pick(this, [ "taxCategory", "description", "prorationMethod", "paymentDue", "periodsPerYear", "daysInYear", "includeIn1099", "rent", "display", "propertyID", "prorationDate", "result1", "result2", "result3", "buyerAmount", "sellerAmount"])
			: _.pickBy(this, (value,key) => { return !_.includes(omittedProperties, key); });
	}

}
export class ProrationDefault {
	constructor(options) {
		options = options || {};
		this.taxCategory = _.parseNumber(options.taxCategory, 0);
		this.description = options.description || "";
		this.paymentDue = _.parseNumber(options.paymentDue, 0);
		this.periodsPerYear = _.parseNumber(options.periodsPerYear, 0);
		this.prorationMethod = _.parseNumber(options.prorationMethod, 0);
		this.daysInYear = _.parseNumber(options.daysInYear, 0);
		this.regionID = _.parseNumber(options.regionID, -1);
		this.rent = _.parseBool(options.rent, false);
		this.includeIn1099 = _.parseBool(options.includeIn1099, false);
	}

	get display () {
		return this.taxCategory === 0 ? "Custom" : `Tax Category ${this.taxCategory} - ${this.description}`;
	}
}

export class ProrateResult {
	constructor(options) {
        options = options || {};
		this.prorateResultID = options.prorateResultID || null;
		this.prorateID = options.prorateID || null;
		this.description = options.description || "";
		this.sequence = options.sequence || null;
		this.amount = _.parseNumber(options.amount, 0);
		this.resultRoleType = options.resultRoleType || null;
		this.resultType = options.resultType || null;
		this.fromDate = options.fromDate || null;
		this.toDate = options.toDate || null;
	}
}

export const OUTSIDE_PARTY_PERCENT_AMOUNT = {
	PERCENT: 1,
	AMOUNT: 2
}

export class OrderOutsidePartyToPayDto {
	constructor(options) {
		options = options || {};
		this.orderOutsidePartyToPayID = _.parseNumber(options.orderOutsidePartyToPayID, 0);
		this.ordersID = options.ordersID || null;
		this.orderRateCalculationID = _.parseNumber(options.orderRateCalculationID, 0);
		this.orderOutsidePartyToPayCompanyID = _.parseNumber(options.orderOutsidePartyToPayCompanyID, 0);
		this.companyName = options.companyName || null;
		this.orderOutsidePartyToPayPCT = _.parseNumber(options.orderOutsidePartyToPayPCT, 0);
		this.orderOutsidePartyToPayAmount = _.parseNumber(options.orderOutsidePartyToPayAmount, 0);
		this.orderOutsidePartyToPayTotal = _.parseNumber(options.orderOutsidePartyToPayTotal, 0);
		this.orderOutsidePartyToPayComment = options.orderOutsidePartyToPayComment || null;
		this.valueType = options.valueType ? options.valueType : _.parseNumber(this.orderOutsidePartyToPayAmount, 0) === 0 ? OUTSIDE_PARTY_PERCENT_AMOUNT.PERCENT : OUTSIDE_PARTY_PERCENT_AMOUNT.AMOUNT;
	}

	get company() {
		return {
			companyID: this.orderOutsidePartyToPayCompanyID,
			companyName: this.companyName
		};
	}
	set company(val) {
		this.orderOutsidePartyToPayCompanyID = _.get(val, "companyID", null);
		this.companyName = _.get(val, "companyName", null);
	}
	set percentAmountData(value) {
		switch(this.valueType) {
			case OUTSIDE_PARTY_PERCENT_AMOUNT.PERCENT:
				this.orderOutsidePartyToPayPCT = value;
				break;
			case OUTSIDE_PARTY_PERCENT_AMOUNT.AMOUNT:
				this.orderOutsidePartyToPayAmount = value;
				break;
		}
	}
	get percentAmountData() {
		switch(this.valueType) {
			case OUTSIDE_PARTY_PERCENT_AMOUNT.PERCENT:
				return this.orderOutsidePartyToPayPCT;
			case OUTSIDE_PARTY_PERCENT_AMOUNT.AMOUNT:
				return this.orderOutsidePartyToPayAmount;
			default:
				return 0;
		}
	}
}

export class OrderRateNoteDto {
    constructor (options) {
        options = options || {};
        this.orderRateNoteID = _.parseNumber(options.orderRateNoteID, 0);
        this.orderRateCalculationID = _.parseNumber(options.orderRateCalculationID, null);
        this.usersID = options.usersID || null;
        this.notes = options.notes || null;
        this.nDate = options.nDate || null;
        this.author = options.author || null;
        // this.editMode = _.isBoolean(options.editMode) ? options.editMode : false;
        this.readOnly = _.parseBool(options.readOnly, false);
        // this.displayCollapsed = _.isBoolean(options.displayCollapsed) ? options.displayCollapsed : true;
        // this.noteTextCollapsible = _.isBoolean(options.noteTextCollapsible) ? options.noteTextCollapsible : false;
        // this.originalValues = _.toPlainObject(options);
    }
    get isNew() { return this.orderRateNoteID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.notes)) {
            errorList.push("Note Text is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class RateOrderInfoDto {
    constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID);
        this.gfNo = options.gfNo || null;
        this.premiumSplitCodeTypes = _.parseNumber(options.premiumSplitCodeTypes, null);
        this.premiumTypeID = _.parseNumber(options.premiumTypeID, null);
        this.regionID = _.parseNumber(options.regionID, null);
        this.underwriterPremiumCalculated = _.parseNumber(options.underwriterPremiumCalculated, null);
        this.agentPremiumCalculated = _.parseNumber(options.agentPremiumCalculated, null);
        this.premiumUpdate = options.premiumUpdate || null;

        this.underwriterCompanyID = _.parseNumber(options.underwriterCompanyID, null);
        this.service = _.parseNumber(options.service, null);
        this.loanPolicyLiability = _.parseNumber(options.loanPolicyLiability, null);
        this.ownerPolicyLiability = _.parseNumber(options.ownerPolicyLiability, null);
        this.salesPrice = _.parseNumber(options.salesPrice, null);

        this.breakoutRIConHUD = _.parseBool(options.breakoutRIConHUD);
        this.region = new Region(options.region);

        this.reissueLoanCreditForRateCalculator = _.parseBool(options.reissueLoanCreditForRateCalculator, null);
        this.priorLoanAmount = _.parseNumber(options.priorLoanAmount, null);
        this.priorLoanPolicyDate = options.priorLoanPolicyDate || null;
        this.priorLoanPolicyNumber = options.priorLoanPolicyNumber || null;
        this.priorRateIDForLoanReissueCredit = _.parseNumber(options.priorRateIDForLoanReissueCredit, null);
        this.priorYearsNumForLoanReissueCredit = _.parseNumber(options.priorYearsNumForLoanReissueCredit, null);
        this.loanCDFSection = _.parseNumber(options.loanCDFSection, null);

        this.reissueOwnerCreditForRateCalculator = _.parseBool(options.reissueOwnerCreditForRateCalculator, null);
        this.priorSalesPrice = _.parseNumber(options.priorSalesPrice, null);
        this.priorOwnerPolicyDate = options.priorOwnerPolicyDate || null;
        this.priorOwnerPolicyNumber = options.priorOwnerPolicyNumber || null;
        this.priorRateIDForOwnerReissueCredit = _.parseNumber(options.priorRateIDForOwnerReissueCredit, null);
        this.priorYearsNumForOwnerReissueCredit = _.parseNumber(options.priorYearsNumForOwnerReissueCredit, null);
        this.ownerCDFSection = _.parseNumber(options.ownerCDFSection, null);
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class RateCalculationDetailDto {
    constructor (options) {
		options = options || {};

		this.clientKey = _.uniqueId("calc-detail-");

		this.trackingId = options.trackingId || 0;
		this.coverageRoundingRule = options.coverageRoundingRule || null;
		this.premiumRoundingRules = options.premiumRoundingRules || null;
		this.rateRules = options.rateRules || null;
		this.ricRules = options.ricRules || null;
		this.simultaneousRules = options.simultaneousRules || null;
		this.underwriterSplit = options.underwriterSplit || null;
		this.cdfCalculationDetail = options.cdfCalculationDetail || null;

		//linked title insurance premium items
		this.titleInsuranceAdjustmentPct = _.parseNumber(options.titleInsuranceAdjustmentPct, 0);
		this.titleInsuranceAdjustmentAmountIndicator = options.titleInsuranceAdjustmentAmountIndicator || "";
		this.titleInsuranceAdjustmentPctIndicator = options.titleInsuranceAdjustmentPctIndicator || "";
		this.titleInsuranceAdjustmentAmount = _.parseNumber(options.titleInsuranceAdjustmentAmount, 0);
		this.titleInsuranceTotal = _.parseNumber(options.titleInsuranceTotal, 0);

		//linked re-issue credit premium items
		this.reissueCreditCalculationMethodID = _.parseNumber(options.reissueCreditCalculationMethodID, 0);
		this.ricAdjustmentPct = _.parseNumber(options.ricAdjustmentPct, 0);
		this.ricAdjustmentAmountIndicator = options.ricAdjustmentAmountIndicator || "";
		this.ricAdjustmentPctIndicator = options.ricAdjustmentPctIndicator || "";
		this.ricAdjustmentAmount = _.parseNumber(options.ricAdjustmentAmount, 0);
		this.ricTotal = _.parseNumber(options.ricTotal, 0);

		//linked simultaneous premium items
		this.simultaneousTotal = _.parseNumber(options.simultaneousTotal, 0);

		//linked underwriter premium items
		this.underwriterSplitAdjustmentPct = _.parseNumber(options.underwriterSplitAdjustmentPct, 0);
		this.underwriterSplitAdjustmentAmountIndicator = options.underwriterSplitAdjustmentAmountIndicator || "";
		this.underwriterSplitAdjustmentPctIndicator = options.underwriterSplitAdjustmentPctIndicator || "";
		this.underwriterSplitAdjustmentAmount = _.parseNumber(options.underwriterSplitAdjustmentAmount, 0);
		this.underwriterSplitTotal = _.parseNumber(options.underwriterSplitTotal, 0);
	}
}

// Coverage rounding object
export class RateEngineCoverageRoundingDto {
	constructor(options) {
		options = options || {};
		this.clientKey = _.uniqueId("cov-round-detail-");
		this.rateEngineCoverageRoundingID = _.parseNumber(options.rateEngineCoverageRoundingID, 0);
		this.fromAmount = _.parseNumber(options.fromAmount, 0);
		this.toAmount = _.parseNumber(options.toAmount, 0);
		this.roundedAmount = _.parseNumber(options.roundedAmount, 0);
		this.toNearest = _.parseNumber(options.toNearest, 0);
		this.rateEngineRoundingRuleID = _.parseNumber(options.rateEngineRoundingRuleID, 0);
		this.rule = options.rule;
	}
}

// Title Insurance object
// TODO: Re-check as columns don't see to exactly match up with calculation details
export class RateEngineTitleInsuranceRateDto {
	constructor(options) {
		options = options || {};
		this.clientKey = _.uniqueId("title-ins-rate-detail-");
		this.fromAmount = _.parseNumber(options.fromAmount, 0);
		this.toAmount = _.parseNumber(options.toAmount, 0);
		this.per = _.parseNumber(options.per, 0);
		this.rate = _.parseNumber(options.rate, 0);
		this.maxRate = _.parseNumber(options.maxRate, 0);
		this.amount = _.parseNumber(options.amount, 0);
		this.calculatedUnderwriterSplit = _.parseNumber(options.calculatedUnderwriterSplit, 0);
		this.calculatedUnderwriterSplit_Assoc = _.parseNumber(options.calculatedUnderwriterSplit_Assoc, 0);
		this.simultaenousUnderwriterSplit = _.parseNumber(options.simultaenousUnderwriterSplit, 0);
		this.name = options.name;
		this.premium = _.parseNumber(options.premium, 0);
	}
}

// Premium Rounding object
export class RateEnginePremiumRoundingDto {
	constructor(options) {
		options = options || {};
		this.clientKey = _.uniqueId("premium-round-detail-");
		this.rateEnginePremiumRoundingID = _.parseNumber(options.rateEnginePremiumRoundingID, 0);
		this.fromAmount = _.parseNumber(options.fromAmount, 0);
		this.toAmount = _.parseNumber(options.toAmount, 0);
		this.toNearest = _.parseNumber(options.toNearest, 0);
		this.RateEngineRoundingRuleID = _.parseNumber(options.RateEngineRoundingRuleID, 0);
		this.rule = options.rule;
		this.roundedAmount = _.parseNumber(options.roundedAmount, 0);
	}
}

// Re-issue Credit object
export class RateEngineReissueCreditDto {
	constructor(options) {
		options = options || {};
		this.clientKey = _.uniqueId("ric-detail-");
		this.rateEngineReissueCreditID = _.parseNumber(options.rateEngineReissueCreditID, 0);
		this.fromAmount = _.parseNumber(options.fromAmount, 0);
		this.toAmount = _.parseNumber(options.toAmount, 0);
		this.per = _.parseNumber(options.per, 0);
		this.ratePerThousand = _.parseNumber(options.ratePerThousand, 0);
		this.maxRate = _.parseNumber(options.maxRate, 0);
		this.discount = _.parseNumber(options.discount, 0);
		this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN);
		this.percentCredit = _.parseNumber(options.percentCredit, 0);
		this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, 0);
		this.reportCodes = options.reportCodes || null;
		this.fromYears = _.parseNumber(options.fromYears, 0);
		this.toYears = _.parseNumber(options.toYears, 0);
		this.usePerRate = _.parseBool(options.usePerRate, false);
		this.uwSplitPercent = _.parseNumber(options.uwSplitPercent, 0);
	}
    get isNew() { return this.rateEngineReissueCreditID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];

        return errorList;
    }

	toDataObject() { return _.omit(this, ['clientKey', 'discount']); }
}

// Underwriter Split object
// TODO: Premium Split??
export class RateEngineUnderwriterSplitDto {
	constructor(options) {
		options = options || {};
		this.clientKey = _.uniqueId("uw-split-detail-");
		this.calculatedUnderwriterSplit = _.parseNumber(options.calculatedUnderwriterSplit, 0);
		this.rateEngineUnderwriterSplitID = _.parseNumber(options.rateEngineUnderwriterSplitID, 0);
		this.fromAmount = _.parseNumber(options.fromAmount, 0);
		this.toAmount = _.parseNumber(options.toAmount, 0);
		this.per = _.parseNumber(options.per, 0);
		this.rate = _.parseNumber(options.rate, 0);
		this.splitPercent = _.parseNumber(options.splitPercent, 0);
	}
}

// Simultaneous object
// TODO: Not enough information on what we need to display from story. Mockup didn't have much. RQ1 didn't display anything.
export class RateEngineSimultaneousDto {
	constructor(options) {
		options = options || {};
		this.clientKey = _.uniqueId("simul-detail-");
		this.rateEngineSimultaneousID = _.parseNumber(options.rateEngineSimultaneousID, null);
		this.rateEffectiveDateID = _.parseNumber(options.rateEffectiveDateID, null);
		this.loanPolicyID = _.parseNumber(options.loanPolicyID, 1);
		this.loanPolicyCode = options.loanPolicyCode || null;
		this.loanFlatFeeAmount = _.parseNumber(options.loanFlatFeeAmount, null);
		this.loanFlatExcessAmount = _.parseNumber(options.loanFlatExcessAmount, null);
		this.loanFlatExcessPolicy = _.parseNumber(options.loanFlatExcessPolicy, null);
		this.loanFlatExcessCalcMethodID = _.parseNumber(options.loanFlatExcessCalcMethodID, null);
		this.loanFlatExcessCalcPctDiff = _.parseNumber(options.loanFlatExcessCalcPctDiff, null);
		this.loanPercentagePct = _.parseNumber(options.loanPercentagePct, null);
		this.loanPercentageExcessPct = _.parseNumber(options.loanPercentageExcessPct, null);
		this.loanPercentageExcessPolicy = _.parseNumber(options.loanPercentageExcessPolicy, null);
		this.loanPercentageExcessCalcMethodID = _.parseNumber(options.loanPercentageExcessCalcMethodID, null);
		this.loanPercentageExcessCalcPctDiff = _.parseNumber(options.loanPercentageExcessCalcPctDiff, null);
		this.salesPolicyID = _.parseNumber(options.salesPolicyID, 1);
		this.salesPolicyCode = options.salesPolicyCode || null;
		this.salesFlatFeeAmount = _.parseNumber(options.salesFlatFeeAmount, null);
		this.salesFlatExcessAmount = _.parseNumber(options.salesFlatExcessAmount, null);
		this.salesFlatExcessPolicy = _.parseNumber(options.salesFlatExcessPolicy, null);
		this.salesFlatExcessCalcMethodID = _.parseNumber(options.salesFlatExcessCalcMethodID, null);
		this.salesFlatExcessCalcPctDiff = _.parseNumber(options.salesFlatExcessCalcPctDiff, null);
		this.salesPercentagePct = _.parseNumber(options.salesPercentagePct, null);
		this.salesPercentageExcessPct = _.parseNumber(options.salesPercentageExcessPct, null);
		this.salesPercentageExcessPolicy = _.parseNumber(options.salesPercentageExcessPolicy, null);
		this.salesPercentageExcessCalcMethodID = _.parseNumber(options.salesPercentageExcessCalcMethodID, null);
		this.salesPercentageExcessCalcPctDiff = _.parseNumber(options.salesPercentageExcessCalcPctDiff, null);
		this.deletedYN = _.parseBool(options.deletedYN, false);
		this.isSystemRecordYN = _.parseBool(options.isSystemRecordYN, false);
		this.loanUsePremiumRounding = _.parseBool(options.loanUsePremiumRounding, false);
		this.salesUsePremiumRounding = _.parseBool(options.salesUsePremiumRounding, false);
		this.loanFlatFeePercentageAmount = _.parseNumber(options.loanFlatFeePercentageAmount, null);
		this.loanFlatFeePercentagePct = _.parseNumber(options.loanFlatFeePercentagePct, null);
		this.loanFlatFeePercentageExcessAmount = _.parseNumber(options.loanFlatFeePercentageExcessAmount, null);
		this.loanFlatFeePercentageExcessPct = _.parseNumber(options.loanFlatFeePercentageExcessPct, null);
		this.loanFlatFeePercentageExcessCalcMethodID = _.parseNumber(options.loanFlatFeePercentageExcessCalcMethodID, null);
		this.loanFlatFeePercentageExcessCalcPctDiff = _.parseNumber(options.loanFlatFeePercentageExcessCalcPctDiff, null);
		this.loanFlatFeePercentageExcessPolicy = _.parseNumber(options.loanFlatFeePercentageExcessPolicy, null);
		this.salesFlatFeePercentageAmount = _.parseNumber(options.salesFlatFeePercentageAmount, null);
		this.salesFlatFeePercentagePct = _.parseNumber(options.salesFlatFeePercentagePct, null);
		this.salesFlatFeePercentageExcessAmount = _.parseNumber(options.salesFlatFeePercentageExcessAmount, null);
		this.salesFlatFeePercentageExcessPct = _.parseNumber(options.salesFlatFeePercentageExcessPct, null);
		this.salesFlatFeePercentageExcessCalcMethodID = _.parseNumber(options.salesFlatFeePercentageExcessCalcMethodID, null);
		this.salesFlatFeePercentageExcessCalcPctDiff = _.parseNumber(options.salesFlatFeePercentageExcessCalcPctDiff, null);
		this.salesFlatFeePercentageExcessPolicy = _.parseNumber(options.salesFlatFeePercentageExcessPolicy, null);
		this.cdfRateID = _.parseNumber(options.cdfRateID, null);
		this.loanConcurrentPolicy = _.parseNumber(options.loanConcurrentPolicy, null);
		this.salesConcurrentPolicy = _.parseNumber(options.salesConcurrentPolicy, null);
		this.loanFlatFeeDifferenceOwnersMin = _.parseNumber(options.loanFlatFeeDifferenceOwnersMin, null);
		this.salesFlatFeeDifferenceOwnersMin = _.parseNumber(options.salesFlatFeeDifferenceOwnersMin, null);
		this.loanFlatFeeDifferenceAmount = _.parseNumber(options.loanFlatFeeDifferenceAmount, null);
		this.salesFlatFeeDifferenceAmount = _.parseNumber(options.salesFlatFeeDifferenceAmount, null);
		this.loanUseExcessPremiumUWSplit = _.parseBool(options.loanUseExcessPremiumUWSplit, false);
		this.salesUseExcessPremiumUWSplit = _.parseBool(options.salesUseExcessPremiumUWSplit, false);
		this.loanFlatFeeUWSplitOverride = _.parseBool(options.loanFlatFeeUWSplitOverride, false);
		this.salesFlatFeeUWSplitOverride = _.parseBool(options.salesFlatFeeUWSplitOverride, false);
		this.loanFlatFeeUWSplitPercent = _.parseNumber(options.loanFlatFeeUWSplitPercent, 0);
		this.salesFlatFeeUWSplitPercent = _.parseNumber(options.salesFlatFeeUWSplitPercent, 0);
		this.loanPercentagePctPolicy = _.parseNumber(options.loanPercentagePctPolicy, null);
		this.loanPercentageExcessPctPolicy = _.parseNumber(options.loanPercentageExcessPctPolicy, null);
		this.salesPercentagePctPolicy = _.parseNumber(options.salesPercentagePctPolicy, null);
		this.salesPercentageExcessPctPolicy = _.parseNumber(options.salesPercentageExcessPctPolicy, null);
		this.loanFlatFeePercentagePctPolicy = _.parseNumber(options.loanFlatFeePercentagePctPolicy, null);
		this.loanFlatFeePercentageExcessPctPolicy = _.parseNumber(options.loanFlatFeePercentageExcessPctPolicy, null);
		this.salesFlatFeePercentagePctPolicy = _.parseNumber(options.salesFlatFeePercentagePctPolicy, null);
		this.salesFlatFeePercentageExcessPctPolicy = _.parseNumber(options.salesFlatFeePercentageExcessPctPolicy, null);
		this.loanFlatFeePercentageExcessBasedOn = _.parseBool(options.loanFlatFeePercentageExcessBasedOn);
		this.salesFlatFeePercentageExcessBasedOn = _.parseBool(options.salesFlatFeePercentageExcessBasedOn);
		this.loanRoundingAfterCalc = _.parseBool(options.loanRoundingAfterCalc, false);
		this.salesRoundingAfterCalc = _.parseBool(options.salesRoundingAfterCalc, false);
		this.loanRoundBeforeExcessCalc = _.parseBool(options.loanRoundBeforeExcessCalc, false);
		this.salesRoundBeforeExcessCalc = _.parseBool(options.salesRoundBeforeExcessCalc, false);
		this.loanFlatExcessMinimumAmount = _.parseNumber(options.loanFlatExcessMinimumAmount, null);
		this.loanFlatFeePercentageExcessMinimumAmount = _.parseNumber(options.loanFlatFeePercentageExcessMinimumAmount, null);
		this.loanPercentageExcessMinimumAmount = _.parseNumber(options.loanPercentageExcessMinimumAmount, null);
	}
    get isNew() { return this.rateEngineSimultaneousID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNullOrEmpty(this.loanPolicyID)) {
            errorList.push("Loan Policy is required.");
        }
        if (_.isNullOrEmpty(this.salesPolicyID)) {
            errorList.push("Sales Policy is required.");
        }
        if (_.isNullOrEmpty(this.rateEffectiveDateID)) {
            errorList.push("Rate Effective Date is required.");
        }
        return errorList;
    }

    toDataObject() { return _.omit(_.toPlainObject(this), ["clientKey"]); }
}

// CDF Calculation
export class CdfCalculationDetailDto {
	constructor(options) {
		options = options || {};
		this.clientKey = _.uniqueId("cdf-detail-");
		this.ownersFullRate = _.parseNumber(options.ownersFullRate, 0);
		this.loanAmount = _.parseNumber(options.loanAmount, 0);
		this.fullLoanRate = _.parseNumber(options.fullLoanRate, 0);
	}
}

export class PremiumEndorsementTotal {
	constructor(options) {
		options = options || {};
		this.amount = options.amount || 0;
		this.taxAmount = options.tax || 0;
		this.totalAmount = options.total || 0;
		this.buyerAmount = options.buyer || 0;
		this.sellerAmount = options.seller || 0;
		this.uwSplitAmount = options.uwSplit || 0;
	}
}

export class EndorsementDto {
	constructor(options) {
        options = options || {};
		this.accountingCodeID =  _.parseNumber(options.accountingCodeID, null);
		this.alwaysApplyEndorsement =  _.parseBool(options.alwaysApplyEndorsement, null);
		this.applyToTitleServices = _.parseBool(options.applyToTitleServices);
		this.basedOnAmount =  _.parseNumber(options.basedOnAmount, null);
		this.basedOnMaximum =  _.parseNumber(options.basedOnMaximum, null);
		this.basedOnMinimum =  _.parseNumber(options.basedOnMinimum, null);
		this.basedOnPer =  _.parseNumber(options.basedOnPer, null);
		this.behavesLikeRecordingFee = options.behavesLikeRecordingFee || null;
		this.cdfLineType = _.parseNumber(options.cdfLineType, null);
		this.chargePercentageBasisID =  _.parseNumber(options.chargePercentageBasisID, null);
		this.code = options.code || "";
		this.coverageRoundingMethod =  _.parseNumber(options.coverageRoundingMethod, 0);
		this.deletedYN =  _.parseBool(options.deletedYN, false);
		this.description = options.description || "";
		this.endorsementBasedOnID =  _.parseNumber(options.endorsementBasedOnID, null);
		this.endorsementChargeTypeID =  _.parseNumber(options.endorsementChargeTypeID, 1);
		this.endorsementChargesSettlementFeeRoundingMethod =  _.parseNumber(options.endorsementChargesSettlementFeeRoundingMethod, 0);
		this.endorsementHasDocumentTemplatesYN =  _.parseBool(options.endorsementHasDocumentTemplatesYN, null);
		this.endorsementID =  _.parseNumber(options.endorsementID, 0);
		this.endorsementPolicyTypeID =  _.parseNumber(options.endorsementPolicyTypeID, null);
		this.filingCode = options.filingCode || null;
		this.hudLineType =  _.parseNumber(options.hudLineType, null);
		this.importDate = options.importDate || null;
		this.isInactiveYN = _.parseBool(options.isInactiveYN);
		this.isSystemRecordYN =  _.parseBool(options.isSystemRecordYN, false);
		this.lastModified = options.lastModified || null;
		this.lastModifiedBy = options.lastModifiedBy || null;
		this.lastRamQuestUpdate = options.lastRamQuestUpdate || null;
		this.loan =  _.parseNumber(options.loan, null);
		this.loanConcurrentPolicy =  _.parseNumber(options.loanConcurrentPolicy, null);
		this.loanFlatExcessAmount =  _.parseNumber(options.loanFlatExcessAmount, null);
		this.loanFlatExcessCalcMethodID =  _.parseNumber(options.loanFlatExcessCalcMethodID, 0);
		this.loanFlatExcessCalcPctDiff =  _.parseNumber(options.loanFlatExcessCalcPctDiff, null);
		this.loanFlatExcessPolicy =  _.parseNumber(options.loanFlatExcessPolicy, null);
		this.loanFlatFeeAmount =  _.parseNumber(options.loanFlatFeeAmount, null);
		this.loanFlatFeeDifferenceAmount =  _.parseNumber(options.loanFlatFeeDifferenceAmount, null);
		this.loanFlatFeeDifferenceOwnersMin =  _.parseNumber(options.loanFlatFeeDifferenceOwnersMin, null);
		this.loanFlatFeePercentageAmount =  _.parseNumber(options.loanFlatFeePercentageAmount, null);
		this.loanFlatFeePercentageExcessAmount =  _.parseNumber(options.loanFlatFeePercentageExcessAmount, null);
		this.loanFlatFeePercentageExcessCalcMethodID =  _.parseNumber(options.loanFlatFeePercentageExcessCalcMethodID, 0);
		this.loanFlatFeePercentageExcessCalcPctDiff =  _.parseNumber(options.loanFlatFeePercentageExcessCalcPctDiff, null);
		this.loanFlatFeePercentageExcessPct =  _.parseNumber(options.loanFlatFeePercentageExcessPct, null);
		this.loanFlatFeePercentageExcessPctPolicy = _.parseNumber(options.loanFlatFeePercentageExcessPctPolicy, null);
		this.loanFlatFeePercentageExcessPolicy = _.parseNumber(options.loanFlatFeePercentageExcessPolicy, null);
		this.loanFlatFeePercentagePct = _.parseNumber(options.loanFlatFeePercentagePct, null);
		this.loanFlatFeePercentagePctPolicy = _.parseNumber(options.loanFlatFeePercentagePctPolicy, null);
		this.loanPercentageExcessCalcMethodID = _.parseNumber(options.loanPercentageExcessCalcMethodID, 0);
		this.loanPercentageExcessCalcPctDiff = _.parseNumber(options.loanPercentageExcessCalcPctDiff, null);
		this.loanPercentageExcessPct = _.parseNumber(options.loanPercentageExcessPct, null);
		this.loanPercentageExcessPctPolicy = _.parseNumber(options.loanPercentageExcessPctPolicy, null);
		this.loanPercentageExcessPolicy = _.parseNumber(options.loanPercentageExcessPolicy, null);
		this.loanPercentagePct = _.parseNumber(options.loanPercentagePct, null);
		this.loanPercentagePctPolicy = _.parseNumber(options.loanPercentagePctPolicy, null);
		this.loanPolicyCode = options.loanPolicyCode || "";
		this.loanPolicyID =  _.parseNumber(options.loanPolicyID, null);
		this.loanUsePremiumRounding =  _.parseBool(options.loanUsePremiumRounding, null);
		this.overrideAmountsByDefault = _.parseBool(options.overrideAmountsByDefault, null);
		this.overrideDefaultSplitRulesYN = _.parseBool(options.overrideDefaultSplitRulesYN, null);
		this.owner =  _.parseNumber(options.owner, null);
		this.payPercent = _.parseNumber(options.payPercent, null);
		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyDisplay = options.payeeCompanyDisplay || null;
		this.payeeContactID = _.parseNumber(options.payeeContactID, null);
		this.payeeContactDisplay = options.payeeContactDisplay || null;
		this.percentageMaximum = _.parseNumber(options.percentageMaximum, null);
		this.percentageMinimum = _.parseNumber(options.percentageMinimum, null);
		this.percentagePercent = _.parseNumber(options.percentagePercent, null);
		this.percentageRateID =  _.parseNumber(options.percentageRateID, null);
		this.policyCode = options.policyCode || "";
		this.rateEngineRoundingRuleID =  _.parseNumber(options.rateEngineRoundingRuleID, null);
		this.rateEngineSplitRulesID =  _.parseNumber(options.rateEngineSplitRulesID, null);
		this.rateRuleReference = options.rateRuleReference || null;
		this.regionID =  _.parseNumber(options.regionID, 1);
		this.salesConcurrentPolicy =  _.parseNumber(options.salesConcurrentPolicy, null);
		this.salesFlatExcessAmount =  _.parseNumber(options.salesFlatExcessAmount, null);
		this.salesFlatExcessCalcMethodID =  _.parseNumber(options.salesFlatExcessCalcMethodID, 0);
		this.salesFlatExcessCalcPctDiff =  _.parseNumber(options.salesFlatExcessCalcPctDiff, null);
		this.salesFlatExcessPolicy =  _.parseNumber(options.salesFlatExcessPolicy, null);
		this.salesFlatFeeAmount =  _.parseNumber(options.salesFlatFeeAmount, null);
		this.salesFlatFeeDifferenceAmount =  _.parseNumber(options.salesFlatFeeDifferenceAmount, null);
		this.salesFlatFeeDifferenceOwnersMin =  _.parseNumber(options.salesFlatFeeDifferenceOwnersMin, null);
		this.salesFlatFeePercentageAmount =  _.parseNumber(options.salesFlatFeePercentageAmount, null);
		this.salesFlatFeePercentageExcessAmount =  _.parseNumber(options.salesFlatFeePercentageExcessAmount, null);
		this.salesFlatFeePercentageExcessCalcMethodID =  _.parseNumber(options.salesFlatFeePercentageExcessCalcMethodID, 0);
		this.salesFlatFeePercentageExcessCalcPctDiff =  _.parseNumber(options.salesFlatFeePercentageExcessCalcPctDiff, null);
		this.salesFlatFeePercentageExcessPct =  _.parseNumber(options.salesFlatFeePercentageExcessPct, null);
		this.salesFlatFeePercentageExcessPctPolicy = _.parseNumber(options.salesFlatFeePercentageExcessPctPolicy, null);
		this.salesFlatFeePercentageExcessPolicy =  _.parseNumber(options.salesFlatFeePercentageExcessPolicy, null);
		this.salesFlatFeePercentagePct =  _.parseNumber(options.salesFlatFeePercentagePct, null);
		this.salesFlatFeePercentagePctPolicy = _.parseNumber(options.salesFlatFeePercentagePctPolicy, null);
		this.salesPercentageExcessCalcMethodID =  _.parseNumber(options.salesPercentageExcessCalcMethodID, 0);
		this.salesPercentageExcessCalcPctDiff =  _.parseNumber(options.salesPercentageExcessCalcPctDiff, null);
		this.salesPercentageExcessPct =  _.parseNumber(options.salesPercentageExcessPct, null);
		this.salesPercentageExcessPctPolicy = _.parseNumber(options.salesPercentageExcessPctPolicy, null);
		this.salesPercentageExcessPolicy =  _.parseNumber(options.salesPercentageExcessPolicy, null);
		this.salesPercentagePct =  _.parseNumber(options.salesPercentagePct, null);
		this.salesPercentagePctPolicy = _.parseNumber(options.salesPercentagePctPolicy, null);
		this.salesPolicyCode = options.salesPolicyCode || "";
		this.salesPolicyID =  _.parseNumber(options.salesPolicyID, null);
		this.salesUsePremiumRounding =  _.parseBool(options.salesUsePremiumRounding);
		this.sectionNumber = _.parseNumber(options.sectionNumber, null);
		this.settlementFeeBasedOn = _.parseNumber(options.settlementFeeBasedOn, null);
		this.showOutsideOfPremiums = _.parseNumber(options.showOutsideOfPremiums, 2);
		this.splitOverridePCT = _.parseNumber(options.splitOverridePCT, null);
		this.state = options.state || "";
		this.taxContactRole = _.parseNumber(options.taxContactRole, null);
		this.taxRateBasedOn = _.parseNumber(options.taxRateBasedOn, null);
		this.taxRateEffectiveBasis = _.parseNumber(options.taxRateEffectiveBasis, null);
		this.taxType = _.parseNumber(options.taxType, null);
		this.underwriterID = _.parseNumber(options.underwriterID, null);
		this.whoPays = _.parseNumber(options.whoPays, null);
		this.noUnderwriterSplit = _.parseBool(options.noUnderwriterSplit);
		this.underwriterSplitType = _.parseNumber(options.underwriterSplitType);
		this.county = options.county || "";
		this.docs = options.docs || _.parseBool(options.hasDocuments);
		this.accountCodeDescription = options.accountCodeDescription || "";
		this.canEdit = _.parseBool(options.canEdit, true);
		this.canDelete = _.parseBool(options.canDelete, true);

		this.breakoutOverride = this.endorsementPolicyTypeID === EndorsementPolicy.Fee
			? BreakoutEndorsementOption.Yes
			: _.parseNumber(options.breakoutOverride, null);
	}

	get payee() {
		return {
			companyID: this.payeeCompanyID,
			companyName: this.payeeCompanyDisplay,
			contactID: this.payeeContactID,
            contactName: this.payeeContactDisplay
		};
	}

	set payee(val) {
		this.payeeCompanyID = _.get(val, "companyID", null);
		this.payeeCompanyDisplay = _.get(val, "companyName", null);
		this.payeeContactID= _.get(val, "contactID", null);
		this.payeeContactDisplay= _.get(val, "contactName", null);
	}

    get isNew() { return this.endorsementID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.code)) {
            errorList.push("Code is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (this.regionID === 0) {
            errorList.push("Region is required.");
        }
        if (this.endorsementPolicyTypeID === null || this.endorsementPolicyTypeID === 0) {
			errorList.push("Type is required.");
        }
        return errorList;
    }

    get verifiedItem() {
        let errorSetting = {};
        if (_.isEmpty(this.code)) {
            errorSetting.requiredCode = true;
        }
        if (_.isEmpty(this.description)) {
            errorSetting.requiredDesc = true;
        }
        if (this.regionID === 0) {
            errorSetting.requiredRegion = true;
        }
        if (this.endorsementPolicyTypeID === null || this.endorsementPolicyTypeID === 0) {
			errorSetting.requiredPolType = true;
        }
        return errorSetting;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class OrderSettlementFee {
	constructor(options) {
        options = options || {};
		this.clientKey = _.uniqueId("order-settlement-fee-");
		this.regionID = _.parseNumber(options.regionID, null);
		this.ordersID = _.parseNumber(options.ordersID, null);
		this.endorsementID = _.parseNumber(options.endorsementID, null);
		this.loanPolicyID = _.parseNumber(options.loanPolicyID, null);
		this.orderSettlementFeeID = _.parseNumber(options.orderSettlementFeeID, null);

		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyName = options.payeeCompanyName || null;

		this.originalPayeeCompanyID = _.parseNumber(options.originalPayeeCompanyID, null);
		this.originalPayeeCompanyName = options.originalPayeeCompanyName || null;

		this.code = options.code || "";
		this._description = options.description || "";
		this.originalDescription = options.originalDescription || "";

		this.pocAmount = _.parseNumber(options.pocAmount, null);
		this.pocWhom = _.parseNumber(options.pocWhom, null);

		this.amountsOverridden = _.parseBool(options.amountsOverridden, false);
		this.taxRate = _.parseNumber(options.taxRate, null);
		this.overrideAmount = _.parseNumber(options.overrideAmount, null);

		this._buyerAmount = _.parseNumber(options.buyerAmount, 0);
		this.buyerTax = _.parseNumber(options.buyerTax, 0);
		this.originalBuyerAmount = _.parseNumber(options.originalBuyerAmount, 0);
		this.originalBuyerTax = _.parseNumber(options.originalBuyerTax, 0);
		this.buyerTotal = _.parseNumber(options.buyerTotal, 0);

		this._sellerAmount = _.parseNumber(options.sellerAmount, 0);
		this.sellerTax = _.parseNumber(options.sellerTax, 0);
		this.originalSellerAmount = _.parseNumber(options.originalSellerAmount, 0);
		this.originalSellerTax = _.parseNumber(options.originalSellerTax, 0);
		this.sellerTotal = _.parseNumber(options.sellerTotal, 0);

		this.paidByOthers = _.parseNumber(options.paidByOthers, 0);
		this.paidForBorrowerBeforeClosing = _.parseNumber(options.paidForBorrowerBeforeClosing, 0);
		this.paidForSellerBeforeClosing = _.parseNumber(options.paidForSellerBeforeClosing, 0);

		this.policyDate = options.policyDate || null;
		this.overrideDate = options.overrideDate || null;

		this.applyToTitleServices = _.parseBool(options.applyToTitleServices, false);

		this.sectionID = _.parseNumber(options.sectionID, null);
		this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
		this.lineType = _.parseNumber(options.lineType, null);
		this.cdfLine = _.parseNumber(options.cdfLine, null);
		this.hudLineSection = _.parseNumber(options.hudLineSection, null);
		this.cdfSection = _.parseNumber(options.cdfSection, null);
		this.netFund = _.parseNumber(options.netFund, null);
	}

	get hasPayeeCompany() { return _.gt(_.parseNumber(this.payeeCompanyID, 0), 0); }

	get payeeCompany() { return { companyID: this.payeeCompanyID, companyName: this.payeeCompanyName };}
    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
		this.calculateAmounts();
    }

	get description() { return this._description;}
	set description(val){
		this._description = val;
		this.calculateAmounts();
	}

	get originalAmount() { return this.originalBuyerAmount + this.originalSellerAmount; }

	get buyerAmount() { return this._buyerAmount; }
	set buyerAmount(val) {
		this._buyerAmount = val;
		this.calculateAmounts();
	}

	get sellerAmount() { return this._sellerAmount; }
	set sellerAmount(val) {
		this._sellerAmount = val;
		this.calculateAmounts();
	}

	get descriptionOverridden() { return this._description !== this.originalDescription; }

	get payeeOverridden() { return _.parseNumber(this.originalPayeeCompanyID,0) > 0 && this.payeeCompanyID !== this.originalPayeeCompanyID; }

	get buyerAmountOverridden() { return this._buyerAmount !== this.originalBuyerAmount; }

	get sellerAmountOverridden() { return this._sellerAmount !== this.originalSellerAmount; }

	revertBuyerAmount() {
		this._buyerAmount = this.originalBuyerAmount;
		this.updateTaxTotals();
		this.updateOverridden();
	}

	revertSellerAmount() {
		this._sellerAmount = this.originalSellerAmount;
		this.updateTaxTotals();
		this.updateOverridden();
	}

	revertDescription() {
		this.description = this.originalDescription;
		this.updateOverridden();
	}

	revertPayee() {
		this.payeeCompanyID = this.originalPayeeCompanyID;
		this.payeeCompanyName = this.originalPayeeCompanyName;
		this.updateOverridden();
	}

	revertOverrides() {
        this.amountsOverridden = false;
		this.description = this.originalDescription;
        this._buyerAmount = this.originalBuyerAmount;
        this._sellerAmount = this.originalSellerAmount;
		this.payeeCompanyID = this.originalPayeeCompanyID;
		this.payeeCompanyName = this.originalPayeeCompanyName;
		this.updateTaxTotals();
		this.updateOverrideDate(false);
    }

    calculateAmounts() {
        this.updateOverridden();
        this.updateTaxTotals();
    }

	updateOverrideDate(update=true) {
		if (update)
			this.overrideDate = DateTime.now().toJSDate();
		else
			this.overrideDate = null;
	}

    updateOverridden() {
        const self = this;
        let overridableFields = ["buyerAmount", "buyerTax", "sellerAmount", "sellerTax", "description", "payeeCompanyName", "payeeCompanyID"];
        let isOverridden = false;
        _.each(overridableFields, f => {
			isOverridden = self.isValueOverridden(f);

			if (isOverridden)
				return false;
		});
		this.amountsOverridden = isOverridden;

		// Update override date field value if there is an any amount override that occurred
		this.updateOverrideDate(this.amountsOverridden);
    }

    updateTaxTotals() {
		const parseFixed = (v, d) => accounting.parse(accounting.toFixed(v, d));
        this.buyerTax = parseFixed(this._buyerAmount * (this.taxRate/100), 2);
        this.buyerTotal = this._buyerAmount + this.buyerTax;

        this.sellerTax = parseFixed(this._sellerAmount * (this.taxRate/100), 2);
        this.sellerTotal = this._sellerAmount + this.sellerTax;
    }

    isValueOverridden(field) {
        return this[`original${_.upperFirst(field)}`] !== null && this[field] !== this[`original${_.upperFirst(field)}`];
    }

	toDataObject() {
		let dto = _.pickBy(this, (v,k) => { return !_.includes(["clientKey", "endorsementID", "_buyerAmount", "_sellerAmount"], k); });
		dto.buyerAmount = this._buyerAmount;
		dto.sellerAmount = this._sellerAmount;
		dto.description = this._description;
		return dto;
	}

}

export class AggEscrowItemDetailDto {
	constructor(options, invalid) {
		options = options || {};
		this.aggEscrowItemDetailID = _.parseNumber(options.aggEscrowItemDetailID, 0);
		this.aggEscrowItemID = _.parseNumber(options.aggEscrowItemID, 0);
		this.due = _.parseBool(options.due);
		this.month = _.parseNumber(options.month, 0);
		this.paymentAmountFromEscrow = _.parseNumber(options.paymentAmountFromEscrow, 0);
		this.monthName = options.monthName || "";
		this.disabled = invalid;
	}
}

export class AggEscrowItemDto {
	constructor(options, invalid) {
		options = options || {};
		this.aggEscrowItemID = _.parseNumber(options.aggEscrowItemID, 0);
		this.aggMainID = _.parseNumber(options.aggMainID, 0);
		this.description = options.description || "";
		this.sum = _.parseNumber(options.sum, 0);
		this.sectionExpanded = _.parseBool(options.sectionExpanded, false);
		this.disabled = invalid;

		this.escrowItemDetails = _.map(options.escrowItemDetails, l => new AggEscrowItemDetailDto(l, invalid));
	}
}

export class AggMainDto {
	constructor(options) {
		options = options || {};
		this.aggMainID = _.parseNumber(options.aggMainID, 0);
		this.loanID = _.parseNumber(options.loanID, 0);
		this.applyAmountToSeller = _.parseBool(options.applyAmountToSeller);
		this.calcSection1000MonthsReserve = _.parseBool(options.calcSection1000MonthsReserve);
		this.clear = _.parseBool(options.clear);
		this.cushionAmount = _.parseNumber(options.cushionAmount, 0);
		this.firstPaymentDate = options.firstPaymentDate || null;
		this.monthlyEscrowPayment = _.parseNumber(options.monthlyEscrowPayment, 0);
		this.include1002TotalInCushionAmt = _.parseBool(options.include1002TotalInCushionAmt);
		this.initialDeposit = _.parseNumber(options.initialDeposit, 0);
		this.numberMonthsCushion = _.parseNumber(options.numberMonthsCushion, 0);

		let invalid = _.isNil(options.firstPaymentDate);

		this.escrowItems = _.map(options.escrowItems, l => new AggEscrowItemDto(l, invalid));
	}
}

export class AggregateDataResult {
	constructor(options) {
		options = options || {};
		this.aggMainItem = new AggMainDto(options.aggMainItem);
		this.alerts = _.map(options.alerts, l => new AggAlertDto(l));
	}
}

export class AggAlertDto {
	constructor(options) {
		options = options || {};
		this.target = options.target || 'None';
		this.message = options.message || '';
		this.severity = _.parseNumber(options.severity, 0);
	}
}
export class PremiumFileInfoModel {
    constructor(options) {
        options = options || {};
		this.underwriterCompanyID = _.parseNumber(options.underwriterCompanyID, 0);
		this.service = _.parseNumber(options.service, 0);
		this.simultaneousOption = _.parseNumber(options.simultaneousOption, 0);
    }
}

const LOAN_COST_TYPES = {
	OriginationLoan: { key: 0, value: "ORIGINATION_PERCENT" },
	AdditionalLoanCharge: { key: 1, value: "ADDITIONAL_ORIGINATION_CHARGE" },
	AdditionalTitleCharge: { key: 2, value: "ADDITIONAL_TITLE_CHARGE" },
	AdditionalOtherCost: { key: 3, value: "OTHER_COST" },
}

export const RECORDING_DOC_SEED_TYPE = {
	HUD_1974: 1,
	HUD_2010: 2,
	CDF: 3
}

export const SETTLEMENT_TYPE = {
	CDF: 0,
	HUD_1974: 1,
	HUD_2010: 2,
	CSS: 3
}

export class SettlementTypeOption {
	static get CDF() { return SETTLEMENT_TYPE.CDF; }
	static get HUD_1974() { return SETTLEMENT_TYPE.HUD_1974; }
	static get HUD_2010() { return SETTLEMENT_TYPE.HUD_2010; }

    static displayValue (id) {
        let enumKey = _.findKey(SETTLEMENT_TYPE, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
	}

    static displayLabel (id, isWithOutSeller=false, includeAlta=false, isP2AB=false) {
		switch(id){
			case SETTLEMENT_TYPE.CDF:
				return this.displayCdfTypeLabel(isWithOutSeller, includeAlta, isP2AB);
			case SETTLEMENT_TYPE.HUD_1974:
				return isWithOutSeller ? "2-Page HUD-1A" : "2-Page HUD";
			case SETTLEMENT_TYPE.HUD_2010:
				return isWithOutSeller ? "3-Page HUD-1A" : "3-Page HUD";
			default:
				return this.displayValue(id);
		}
	}

	static displayCdfTypeLabel(isWithOutSeller=false, includeAlta=false, isP2AB=false) {
		var cdfLabel = "CDF";
		cdfLabel += isP2AB ? "/P2A2B" : "";
		cdfLabel += includeAlta ? "/ALTA" : "";
		cdfLabel += isWithOutSeller ? " without Seller" : "";
		return cdfLabel;
	}

	static getTypeName(id) {
		switch(id) {
			case SETTLEMENT_TYPE.CDF: return "cdf";
			case SETTLEMENT_TYPE.HUD_1974: return "hud1974";
			case SETTLEMENT_TYPE.HUD_2010: return "hud2010";
		}
		return "";
	}

	static getSeedType(id) {
		switch(id) {
			case SETTLEMENT_TYPE.CDF: return RECORDING_DOC_SEED_TYPE.CDF;
			case SETTLEMENT_TYPE.HUD_1974: return RECORDING_DOC_SEED_TYPE.HUD_1974;
			case SETTLEMENT_TYPE.HUD_2010: return RECORDING_DOC_SEED_TYPE.HUD_2010;
		}
		return null;
	}
}


export class LoanCostTypes {
    static get OriginationLoan () {return LOAN_COST_TYPES.OriginationLoan.value;}
	static get AdditionalLoanCharge () {return LOAN_COST_TYPES.AdditionalLoanCharge.value}
	static get AdditionalTitleCharge () {return LOAN_COST_TYPES.AdditionalTitleCharge.value}
	static get AdditionalOtherCost () {return LOAN_COST_TYPES.AdditionalOtherCost.value}
}

export class LoanCostModel {
	constructor(options){
		options = options || {};
		this.loanCostSectionDataID = _.parseNumber(options.loanCostSectionDataID, null);
		this.ordersID = _.parseNumber(options.ordersID, 0),
		this.loanID = _.parseNumber(options.loanID, null);
		this.description = options.description || "";
		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyName = options.payeeCompanyName || "";
		this.payeeContactID = _.parseNumber(options.payeeContactID);
		this.payeeContactName = options.payeeContactName || "";
		this.borrowerAtClosing = _.parseNumber(options.borrowerAtClosing, 0);
		this.borrowerBeforeClosing = _.parseNumber(options.borrowerBeforeClosing, 0);
		this.sellerAtClosing = _.parseNumber(options.sellerAtClosing, 0);
		this.sellerBeforeClosing = _.parseNumber(options.sellerBeforeClosing, 0);
		this.salesPrice = _.parseNumber(options.salesPrice, 0);
		this.loanAmount = _.parseNumber(options.loanAmount, 0);

		// HUD
		this.hudLineType = _.parseNumber(options.hudLineType, 0);
		this.hudSection = _.parseNumber(options.hudSection, null);
		this.hudLineID = _.parseNumber(options.hudLineID, null);
		this.hudLine = _.parseNumber(options.hudLine, null);
		this.poc = options.poc || "";
		this.pocAmount = _.parseNumber(options.pocAmount, 0);

		// HUD 2010
		this.misc_Dollar = _.parseNumber(options.misc_Dollar, 0); // matches DB name but represents borrower outside value
		this.overrideBorrowerOutside = _.parseNumber(options.overrideBorrowerOutside, null);

		// CDF
		this.cdfSection = options.cdfSection || null;
		this.cdfLineID = _.parseNumber(options.cdfLineID, null);
		this.line = _.parseNumber(options.line);

		this.lineDataTypeID = _.parseNumber(options.lineDataTypeID); // reference to LineDataType table
		this.lineType = options.lineType || ""; // reference to LineType
		this.paidByOthers = _.parseNumber(options.paidByOthers, 0);
		this.netFund = _.parseNumber(options.netFund, null);
		this.accountCodeID = _.parseNumber(options.accountCodeID, null);
		this.doNotPrint = _.parseBool(options.doNotPrint, false);
		this.required = _.parseBool(options.required, false);
		this.premiumType = _.parseNumber(options.premiumType, null);
		this.category = options.category || "";

		// Origination Loan percent fields
		this.originationPercent = _.parseNumber(options.originationPercent, 0);

		this.isDefaulted = false;
		this.isSelected = false;
		this.isPayOff = _.parseBool(options.isPayOff, false);
		this.payoffID = _.parseNumber(options.payoffID);
		this.orderRateCalculationID = _.parseNumber(options.orderRateCalculationID, null);
		this.orderEndorsementID = _.parseNumber(options.orderEndorsementID, null);
		this.orderSettlementFeeID = _.parseNumber(options.orderSettlementFeeID, null);
		this.orderOutsidePartyToPayID = _.parseNumber(options.orderOutsidePartyToPayID, null);
		this.realtorCommissionPayeeID = _.parseNumber(options.realtorCommissionPayeeID, null);
		this.overrideAmountOfPaidForBorrowerAtClosing = _.parseNumber(options.overrideAmountOfPaidForBorrowerAtClosing, null);
		this.overrideAmountOfPaidForSellerAtClosing = _.parseNumber(options.overrideAmountOfPaidForSellerAtClosing, null);

		this.defaultedSettlementType = _.parseNumber(options.defaultedSettlementType, null);

		// Paid By Others fields
		this.paidByCompanyID = _.parseNumber(options.paidByCompanyID, null);
		this.paidForBorrower = _.parseNumber(options.paidForBorrower, null);
		this.paidForSeller = _.parseNumber(options.paidForSeller, null);
		this.isReducePayorCheck = _.parseBool(options.isReducePayorCheck, null);
		this.paidBySource = _.parseNumber(options.paidBySource, null);

		// Addendum field
		this.isAddendum = _.parseBool(options.isAddendum, null);
	}

	toDataObject() { return _.pickBy(this, (v,k) => { return !_.includes(["isDefaulted"], k); }); }

	revertOverrides() {
		// revert borrower at closing values
		this.overrideAmountOfPaidForBorrowerAtClosing = null;
		this.overrideAmountOfPaidForSellerAtClosing = null;
		this.overrideBorrowerOutside = null;
    }

	get isOriginationPointLine() { return _.parseNumber(this.line) === 1 || _.parseNumber(this.hudLine) === 801; }
	get isLoanDiscountLine() { return _.parseNumber(this.hudLine) === 802; }
	get isGeneralLine() { return _.parseNumber(this.hudLineType) === HudLineTypes.General; }
	get isDisclosureLine() { return _.parseNumber(this.hudLineType) === HudLineTypes.Disclosure; }
	get isGFEALine() { return _.parseNumber(this.hudLineType) === HudLineTypes.GFE_A; }
	get isGFE1Line() { return _.parseNumber(this.hudLineType) === HudLineTypes.GFE_1; }
	get isGFE3Line() { return _.parseNumber(this.hudLineType) === HudLineTypes.GFE_3; }
	get isGFE4OutsideLine() { return _.parseNumber(this.hudLineType) === HudLineTypes.GFE_4_Outside; }
	get isGFE5OutsideLine() { return _.parseNumber(this.hudLineType) === HudLineTypes.GFE_5_Outside; }
	get isGFE6OutsideLine() { return _.parseNumber(this.hudLineType) === HudLineTypes.GFE_6_Outside; }
	get hudLineTypeDescription() {
		// Hack because RQ1 doesn't actually have GFE #2 enum and hardcodes it by line number for logic
		// To be consistent with RQ1 we fabricate the description but the linetype is GFE #1 which allows it to
		// behave like 801 in terms of certain input being read only and borrowe outside calculations
		if (this.hudLine === 802) return "GFE #2";

		return HudLineTypes.displayValue(this.hudLineType);
	}
	get isPaidByOther() { return !_.isNil(this.paidBySource); }
	get hasPaidByOther() { return !_.isNil(this.paidByCompanyID); }

	get borrowerOutsideValue() {
		if (!_.isNil(this.overrideBorrowerOutside))
			return this.overrideBorrowerOutside;
		else
			return this.misc_Dollar;
	}

	set borrowerOutsideValue(val) {
		if (this.isOverridableLine) {
			this.overrideBorrowerOutside = (val === this.misc_Dollar) ? null : val;
		}
		else {
			this.misc_Dollar = val;
		}
	}

	get isBorrowerOutsideOverridden() {
		return !_.isNil(this.overrideBorrowerOutside);
	}

	get borrowerAtClosingValue() {
		if (!_.isNil(this.overrideAmountOfPaidForBorrowerAtClosing))
			return this.overrideAmountOfPaidForBorrowerAtClosing;
		else
			return this.borrowerAtClosing;
	}

	set borrowerAtClosingValue(val) {
		if (this.isOverridableLine) {
			this.overrideAmountOfPaidForBorrowerAtClosing = (val === this.borrowerAtClosing) ? null : val;
		}
		else {
			this.borrowerAtClosing = val;
			this.overrideAmountOfPaidForBorrowerAtClosing = null;
		}
	}

	get isBorrowerAtClosingOverridden() {
		return !_.isNil(this.overrideAmountOfPaidForBorrowerAtClosing);
	}

	get sellerAtClosingValue() {
		if (!_.isNil(this.overrideAmountOfPaidForSellerAtClosing))
			return this.overrideAmountOfPaidForSellerAtClosing;
		else
			return this.sellerAtClosing;
	}

	set sellerAtClosingValue(val) {
		if (this.isOverridableLine) {
			this.overrideAmountOfPaidForSellerAtClosing = (val === this.sellerAtClosing) ? null : val;
		}
		else {
			this.sellerAtClosing = val;
			this.overrideAmountOfPaidForSellerAtClosing = null;
		}
	}

	get isSellerAtClosingOverridden() {
		return !_.isNil(this.overrideAmountOfPaidForSellerAtClosing);
	}

	get hasPayeeCompany() { return _.gt(_.parseNumber(this.payeeCompanyID, 0), 0); }

	get loanCostDescription() {
		switch (this.lineType) {
			case LoanCostTypes.OriginationLoan:
				return "Origination Loan Charge";
			default:
				return "";
		}
	}

	get payeeCompany() {
        return {
            companyID: this.payeeCompanyID,
            companyName: this.payeeCompanyName,
            contactID: this.payeeContactID,
            contactName: this.payeeContactName
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
        this.payeeContactID = _.get(val, "contactID", null);
        this.payeeContactName = _.get(val, "contactName", null);
	}

	get isOverridableLine() {
		return false;
	}

	get isPremiumOrEndorsement(){
		return _.parseNumber(this.premiumType, 0) > 0;
	}

	get isSettlementFeeLine() {
		return !_.isNil(this.orderSettlementFeeID);
	}
}

export class OriginationChargeModel extends LoanCostModel {
	constructor(options) {
		super(options);
		this.cdfSection = options.cdfSection || null;
		this.coverageAmount1 = _.parseNumber(options.coverageAmount1, null); // HUD 1974
		this.coverageAmount2 = _.parseNumber(options.coverageAmount2, null); // HUD 1974
		this.category = options.category || AltaCategories.LoanCharges;
	}

	get isOverridableLine() {
		// Allow Reverting origination line at the time of this implementation
		if ((this.isOriginationPointLine || this.isLoanDiscountLine) && this.required === true) {
			return true;
		}

		return false;
	}
}

export class TitleChargeModel extends LoanCostModel {
	constructor(options) {
		super(options);
		this.cdfSection = options.cdfSection || null;
		this.coverageAmount1 = _.parseNumber(options.coverageAmount1, null); // HUD 1974
		this.coverageAmount2 = _.parseNumber(options.coverageAmount2, null); // HUD 1974
		this.category = options.category;
	}

	get isOverridableLine() {
		// CDF only allows to override endorsement and settlement fee (common lines)
		if (!_.includes([SETTLEMENT_TYPE.HUD_1974, SETTLEMENT_TYPE.HUD_2010], this.defaultedSettlementType)) {
			if (this.isPremiumOrEndorsement || this.isSettlementFeeLine)
				return true;
		}

		// 1974 HUD
		if (this.defaultedSettlementType === SETTLEMENT_TYPE.HUD_1974
			&& this.required === true
			&& _.includes([1108], this.hudLine)) {
			return true;
		}

		// 2010 HUD
		if (this.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010
			&& this.required === true
			&& _.includes([1101, 1103, 1104, 1107, 1108], this.hudLine)) {
			return true;
		}

		return false;
	}

	get canRevert() {
		// CDF only allows to override endorsement and settlement fee (common lines)
		if (!_.includes([SETTLEMENT_TYPE.HUD_1974, SETTLEMENT_TYPE.HUD_2010], this.defaultedSettlementType)) {
			if (this.isPremiumOrEndorsement || this.isSettlementFeeLine)
				return this.isBorrowerAtClosingOverridden || this.isSellerAtClosingOverridden || this.isBorrowerOutsideOverridden;
		}

		// 1974 HUD
		if (this.defaultedSettlementType === SETTLEMENT_TYPE.HUD_1974) {
			switch (this.hudLine) {
				case 1108:
					return this.isBorrowerAtClosingOverridden || this.isSellerAtClosingOverridden;
				default:
					return false;
			}
		}

		// 2010 HUD
		if (this.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010) {
			switch (this.hudLine) {
				case 1101:
				case 1103:
					return this.isBorrowerAtClosingOverridden;
				case 1104:
				case 1107:
				case 1108:
					return this.isBorrowerOutsideOverridden;
				default:
					return false;
			}
		}
		return false;
	}

}

export class OtherChargeModel extends LoanCostModel {
	constructor(options) {
		super(options);
		this.cdfSection = options.cdfSection || null;
		this.category = options.category || AltaCategories.Miscellaneous;
	}

	get isOverridableLine() {
		// Allow Reverting title line at the time of this implementation
		if (this.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010
			&& this.required === true
			&& _.includes([1301], this.hudLine)) {
			return true;
		}

		// Premiums can be identified in CDF by premiumType
		if (this.isPremiumOrEndorsement) return true;

		// Other common lines among settlement statements that come from calculator where we may want to override
		if (this.isSettlementFeeLine) return true;

		return false;
	}

	get canRevert() {
		if (this.defaultedSettlementType === SETTLEMENT_TYPE.HUD_2010) {
			switch (this.hudLine) {
				case 1301:
					return this.isBorrowerAtClosingOverridden;
				default:
					return false;
			}
		}

		// CDF only allows to override endorsement and settlement fee (common lines)
		if (!_.includes([SETTLEMENT_TYPE.HUD_1974, SETTLEMENT_TYPE.HUD_2010], this.defaultedSettlementType)) {
			if (this.isPremiumOrEndorsement || this.isSettlementFeeLine)
				return this.isBorrowerAtClosingOverridden || this.isSellerAtClosingOverridden || this.isBorrowerOutsideOverridden;
		}

		// 1974 HUD
		if (this.defaultedSettlementType === SETTLEMENT_TYPE.HUD_1974) {
			switch (this.hudLine) {
				case 1108:
					return this.isBorrowerAtClosingOverridden || this.isSellerAtClosingOverridden;
				default:
					return false;
			}
		}

		return false;
	}
}

const ESCROW_TYPES = {
	EscrowItem: { key: 0, value: "ESCROW_ITEM" },
	EscrowAggregateAdjustment: { key: 1, value: "ESCROW_AGGREGATE_ADJUSTMENT" }
}

export class EscrowTypes {
    static get EscrowItem () {return ESCROW_TYPES.EscrowItem.value;}
	static get EscrowAggregateAdjustment () {return ESCROW_TYPES.EscrowAggregateAdjustment.value}
}

export class SettlementStatementData {
	constructor (options, loans=[]) {
		options = options || {};
		this.settlementStatementDataID = _.parseNumber(options.settlementStatementDataID);
		this.loanID = _.parseNumber(options.loanID);
		this.settlementStatementDataTypeID = _.parseNumber(options.settlementStatementDataTypeID);
		this.description = options.description || '';
		this.borrowerAmount = _.parseNumber(options.borrowerAmount, 0);
		this.sellerAmount = _.parseNumber(options.sellerAmount, 0);
		this.netFund = _.parseNumber(options.netFund);

		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyName = options.payeeCompanyName || null;
		this.payeeContactID = _.parseNumber(options.payeeContactID, null);
		this.payeeContactName = options.payeeContactName || null;

		this.escrowOverrideStatus = _.parseNumber(options.escrowOverrideStatus, EscrowOverrideOptions.None);

		this.isDefaulted = _.parseBool(options.isDefaulted);

		this.setDefaultPayee(loans);
	}

	setDefaultPayee(loans) {
		const self = this;
		if (_.isEmpty(loans) || self.payeeCompanyID > 0) return;
		let selectedLoan = _.find(loans, l => l.loanID === self.loanID);
		if (_.isNil(selectedLoan)) return;
		self.payeeCompanyID = selectedLoan.lenderID;
		self.payeeCompanyName = selectedLoan.lender;
		self.payeeContactID = selectedLoan.lenderContactID;
		self.payeeContactName = selectedLoan.lenderContact;
		self.isDefaulted = true;
	}

	get hasPayeeCompany() { return _.gt(_.parseNumber(this.payeeCompanyID, 0), 0); }

	get payeeCompany() {
        return {
            companyID: this.payeeCompanyID,
            companyName: this.payeeCompanyName,
            contactID: this.payeeContactID,
            contactName: this.payeeContactName
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
        this.payeeContactID = _.get(val, "contactID", null);
        this.payeeContactName = _.get(val, "contactName", null);
    }

}
export class EscrowModel {
	constructor(options){
		options = options || {};
		this.escrowSectionDataID = _.parseNumber(options.escrowSectionDataID);
		this.loanID = _.parseNumber(options.loanID);
		this.description = options.description || "";
		this.borrowerAtClosing = _.parseNumber(options.borrowerAtClosing, 0);
		this.borrowerBeforeClosing = _.parseNumber(options.borrowerBeforeClosing, 0);
		this.sellerAtClosing = _.parseNumber(options.sellerAtClosing, 0);
		this.sellerBeforeClosing = _.parseNumber(options.sellerBeforeClosing), 0;

		// HUD
		this.hudSection = _.parseNumber(options.hudSection, null);
		this.hudLineType = _.parseNumber(options.hudLineType, null);
		this.hudLineID = _.parseNumber(options.hudLineID, null);
		this.hudLine = _.parseNumber(options.hudLine, null); // hud line
		this.poc = options.poc || "";
		this.pocAmount = _.parseNumber(options.pocAmount, 0);

		// HUD 2010
		this.misc_Dollar = _.parseNumber(options.misc_Dollar, 0); // matches DB name but represents borrower outside value
		this.overrideBorrowerOutside = _.parseNumber(options.overrideBorrowerOutside, null);

		// CDF
		this.category = options.category || AltaCategories.Impounds;
		this.cdfSection = options.cdfSection || "";
		this.cdfLineID = _.parseNumber(options.cdfLineID, null);
		this.line = _.parseNumber(options.line, null); // cdf line

		this.lineDataTypeID = _.parseNumber(options.lineDataTypeID);
		this.lineType = options.lineType || ""; // reference to LineType
		this.paidByOthers = _.parseNumber(options.paidByOthers, 0);
		this.netFund = _.parseNumber(options.netFund, null);
		this.accountCodeID = _.parseNumber(options.accountCodeID, null);
		this.doNotPrint = _.parseBool(options.doNotPrint, false);
		this.required = _.parseBool(options.required, false);
		this.isDefaulted = false;
		this.monthAmount = _.parseNumber(options.monthAmount, 0);
		this.numberOfMonths = _.parseNumber(options.numberOfMonths, 0);
		this.monthOverrideAmount = _.parseNumber(options.monthOverrideAmount, null);
		this.overrideAmountOfPaidForBorrowerAtClosing = _.parseNumber(options.overrideAmountOfPaidForBorrowerAtClosing, null);
		this.overrideDescription = options.overrideDescription || null;


		// Paid By Others fields
		this.paidByCompanyID = _.parseNumber(options.paidByCompanyID, null);
		this.paidForBorrower = _.parseNumber(options.paidForBorrower, null);
		this.paidForSeller = _.parseNumber(options.paidForSeller, null);
		this.isReducePayorCheck = _.parseBool(options.isReducePayorCheck, false);
		this.defaultedSettlementType = _.parseNumber(options.defaultedSettlementType, null); // represents the settlement type for this line that was defined
		this.currentSettlementType = _.parseNumber(options.currentSettlementType, 0); // Current settlement type application is targeting

		// Config information related to escrow data from DTO
		this.transferMIP = _.parseBool(options.transferMIP);
		this.transferPropertyTax = _.parseBool(options.transferPropertyTax);
	}

	toDataObject() { return _.pickBy(this, (v,k) => { return !_.includes(["isDefaulted"], k); }); }

	get escrowDescription() {
		switch (this.lineType) {
			case EscrowTypes.EscrowAggregateAdjustment:
				return "Aggregate Adjustment";
			default:
				return "Escrow Item";
		}
	}

	// Description
	get descriptionValue() {
		if (!_.isNil(this.overrideDescription))
			return this.overrideDescription;
		else
			return this.description;
	}

	set descriptionValue(val) {
		if (val === this.description) {
			this.overrideDescription = null;
		}
		else {
			this.overrideDescription = val;
		}
	}

	get isDescriptionOverridden() {
		return !_.isNil(this.overrideDescription);
	}

	// MonthAmount
	get monthAmountValue() {
		if (!_.isNil(this.monthOverrideAmount))
			return this.monthOverrideAmount;
		else
			return this.monthAmount;
	}

	set monthAmountValue(val) {
		if (val === this.monthAmount) {
			this.monthOverrideAmount = null;
		}
		else {
			this.monthOverrideAmount = val;

			// 2010 Hud logic
			if (this.currentSettlementType === SETTLEMENT_TYPE.HUD_2010) {
				if (this.hudLine === 1001) return; // 2010 HUD line 1001 does not require any adjustments so return
				let borrowerOutsideResult = this.calculateBorrowerOutsideResult(val, this.numberOfMonths);
				this.misc_Dollar = borrowerOutsideResult;
				return;
			}

			// CDF, 1974 HUD logic
			if (_.includes([SETTLEMENT_TYPE.CDF, SETTLEMENT_TYPE.HUD_2010], this.currentSettlementType)) {
				let borrowerAtClosingResult = this.calculateBorrowerAtClosingResult(val, this.numberOfMonths, this.sellerAtClosingValue, this.paidByOthers);
				this.borrowerAtClosing = borrowerAtClosingResult;
				this.misc_Dollar = null; // Set to null since only 2010 maps this value
			}
		}
	}

	get isMonthAmountOverridden() {
		return !_.isNil(this.monthOverrideAmount);
	}

	// 2010 HUD - misc_Dollar
	// borrowerOutside
	get borrowerOutsideValue() {
		if (!_.isNil(this.overrideBorrowerOutside))
			return this.overrideBorrowerOutside;
		else
			return this.misc_Dollar;
	}

	set borrowerOutsideValue(val) {
		// For additional common escrow items among settlement statements we don't care about override values
		// TODO: Perhaps handle Aggregate Adjustment as an override, allowing it to be just an editable value for now
		if (this.isAdditionalCommonEscrowLine || this.isAggregateAdjustmentLine) {
			// this.borrowerAtClosing = val;
			// this.overrideAmountOfPaidForBorrowerAtClosing = null;
			this.misc_Dollar = val;
			this.overrideBorrowerOutside = null;
		}

		// For defaulted escrow items respectively among settlement statements we care about setting the override values
		if (val === this.misc_Dollar) {
			// this.overrideAmountOfPaidForBorrowerAtClosing = null;
			this.overrideBorrowerOutside = null;
		}
		else {
			// this.overrideAmountOfPaidForBorrowerAtClosing = val;
			this.overrideBorrowerOutside = val;
		}
	}

	get isBorrowerOutsideOverridden() {
		return !_.isNil(this.overrideBorrowerOutside);
	}

	// borrowerAtClosing
	get borrowerAtClosingValue() {
		if (!_.isNil(this.overrideAmountOfPaidForBorrowerAtClosing))
			return this.overrideAmountOfPaidForBorrowerAtClosing;
		else
			return this.borrowerAtClosing;
	}

	set borrowerAtClosingValue(val) {

		// For additional common escrow items among settlement statements we don't care about override values
		// TODO: Perhaps handle Aggregate Adjustment as an override, allowing it to be just an editable value for now
		if (this.isAdditionalCommonEscrowLine || this.isAggregateAdjustmentLine) {
			this.borrowerAtClosing = val;
			this.overrideAmountOfPaidForBorrowerAtClosing = null;
		}

		// For defaulted escrow items respectively among settlement statements we care about setting the override values
		if (val === this.borrowerAtClosing) {
			this.overrideAmountOfPaidForBorrowerAtClosing = null;
		}
		else {
			this.overrideAmountOfPaidForBorrowerAtClosing = val;
		}
	}

	get isBorrowerAtClosingOverridden() {
		return !_.isNil(this.overrideAmountOfPaidForBorrowerAtClosing);
	}

	get sellerAtClosingValue() {
		return this.sellerAtClosing;
	}

	set sellerAtClosingValue(val) {
		this.sellerAtClosing = val;

		if (this.currentSettlementType === SETTLEMENT_TYPE.HUD_2010) return ; // return cause there does not need to be any borrower adjustments for 2010

		let borrowerAtClosingResult = this.calculateBorrowerAtClosingResult(this.monthAmountValue, this.numberOfMonths, this.sellerAtClosingValue, this.paidByOthers);
		this.borrowerAtClosing = borrowerAtClosingResult;
	}

	get numberOfMonthsValue() {
		return this.numberOfMonths;
	}

	set numberOfMonthsValue(val) {
		this.numberOfMonths = val;

		if (this.currentSettlementType === SETTLEMENT_TYPE.HUD_2010) {
			if (this.hudLine === 1001) return; // return cause there does not need to be any borrower adjustments for 2010
			let borrowerOutsideResult = this.calculateBorrowerOutsideResult(this.monthAmountValue, val);
			this.misc_Dollar = borrowerOutsideResult;
			return;
		}

		// CDF, 1974 HUD
		if (_.includes([SETTLEMENT_TYPE.CDF, SETTLEMENT_TYPE.HUD_2010], this.currentSettlementType)) {
			let borrowerAtClosingResult = this.calculateBorrowerAtClosingResult(this.monthAmountValue, val, this.sellerAtClosingValue, this.paidByOthers);
			this.borrowerAtClosing = borrowerAtClosingResult;
		}
	}

	calculateBorrowerAtClosingResult(monthAmount, numOfMonths, paidAtSeller, paidByOthers) {
		// Do not calculate borrower amount for aggregate adjustment line as an exception to the rule
		if (this.isAggregateAdjustmentLine) return this.borrowerAtClosingValue;

		// Calculation for borrowerAtClosing
		// monthAmount = (Prepaids.borrowerAtClosing + Prepaids.borrowerBeforeClosing + Prepaids.SellerAtClosing + Prepaids.sellerBeforeClosing + Prepaids.PaidByOthers) / 12
		// borrowerAtClosing = (monthAmount x numOfMonths) - Paid By Others - Paid From Seller At Closing

		let deductions = paidByOthers + paidAtSeller;
		let result = monthAmount * numOfMonths;
		let borrowerResult = 0;
		if (result >= deductions)
			borrowerResult = result - deductions;
		else
			borrowerResult = 0;

		return borrowerResult;
	}

	calculateBorrowerOutsideResult(monthAmount, numOfMonths) {
		// Do not calculate borrower amount for aggregate adjustment line as an exception to the rule
		if (this.isAggregateAdjustmentLine) return this.borrowerAtClosingValue;

		// Calculation for misc_Dollar (borrowerOutside)
		// monthAmount = (Prepaids.borrowerAtClosing + Prepaids.borrowerBeforeClosing + Prepaids.SellerAtClosing + Prepaids.sellerBeforeClosing + Prepaids.PaidByOthers) / 12
		// borrowerAtClosing = (monthAmount x numOfMonths)

		let result = monthAmount * numOfMonths;
		return result;
	}

	get isAdditionalCommonEscrowLine() {
		return this.defaultedSettlementType == null;
	}

	get isAggregateAdjustmentLine() {
		return this.lineType == EscrowTypes.EscrowAggregateAdjustment;
	}
}

const PREPAID_TYPES = {
	PrepaidInterest: { key: 0, value: "PREPAID_INTEREST" },
	MortageInsPremium: { key: 1, value: "MORTGAGE_INS_PREMIUM" },
	HomeownersInsPremium: { key: 2, value: "HOMEOWNERS_INS_PREMIUM"	},
	PropertyTax: { key: 3, value: "PROPERTY_TAX" },
    AdditionalPrepaid: { key: 4, value: "ADDITIONAL_PREPAID" }
}

export class PrepaidTypes {
    static get PrepaidInterest () {return PREPAID_TYPES.PrepaidInterest.value;}
    static get MortgageInsPremium () {return PREPAID_TYPES.MortageInsPremium.value}
    static get HomeownersInsPremium () {return PREPAID_TYPES.HomeownersInsPremium.value;}
	static get PropertyTax () {return PREPAID_TYPES.PropertyTax.value;}
	static get AdditionalPrepaid () {return PREPAID_TYPES.AdditionalPrepaid.value;}
}

export class PrepaidModel {
	constructor(options){
		options = options || {};
		this.prepaidSectionDataID = _.parseNumber(options.prepaidSectionDataID);
		this.loanID = _.parseNumber(options.loanID);
		this.description = options.description || "";
		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID);
		this.payeeCompanyName = options.payeeCompanyName || "";
		this.payeeContactID = _.parseNumber(options.payeeContactID);
		this.payeeContactName = options.payeeContactName || "";
		this.borrowerAtClosing = _.parseNumber(options.borrowerAtClosing, 0);
		this.borrowerBeforeClosing = _.parseNumber(options.borrowerBeforeClosing, 0);
		this.sellerAtClosing = _.parseNumber(options.sellerAtClosing, 0);
		this.sellerBeforeClosing = _.parseNumber(options.sellerBeforeClosing, 0);
		this.hudSection = _.parseNumber(options.hudSection, null);
		this.hudLineType = _.parseNumber(options.hudLineType, 0);
		this.hudLine = _.parseNumber(options.hudLine, null); // hud line
		this.hudLineID = _.parseNumber(options.hudLineID, null);
		this.cdfSection = options.cdfSection || "";
		this.cdfLineID = _.parseNumber(options.cdfLineID, null);
		this.category = options.category || AltaCategories.Miscellaneous;
		this.poc = options.poc || "";
		this.pocAmount = _.parseNumber(options.pocAmount, 0);
		this.paidByOthers = _.parseNumber(options.paidByOthers, 0);
		this.netFund = _.parseNumber(options.netFund);
		this.accountCodeID = _.parseNumber(options.accountCodeID);
		this.doNotPrint = _.parseBool(options.doNotPrint, false);
		this.line = _.parseNumber(options.line, null); // cdf line
		this.prepaidLineType = options.prepaidLineType || "";
		this.required = _.parseBool(options.required, false);
		this.loanAmount = _.parseNumber(options.loanAmount, 0);
		this.loanInterest = _.parseNumber(options.loanInterest, 0);
		this.perDiemAutoCalculate = _.parseBool(options.perDiemAutoCalculate, false);

		// Prepaid Homeowners Insurance Premium fields
		this.prepaidHIPMonths = _.parseNumber(options.prepaidHIPMonths, 0);

		// Prepaid Mortgage Insurance Premium fields
		this.prepaidMIPMonths = _.parseNumber(options.prepaidMIPMonths, 0);

		// Prepaid Property Tax fields
		this.propertyTaxMonths = _.parseNumber(options.propertyTaxMonths, 0);

		// Prepaid Interest fields
		this.prepaidIntFromDate = options.prepaidIntFromDate || null;
		this.prepaidIntToDate = options.prepaidIntToDate || null;
		this.prepaidIntAmount = _.parseNumber(options.prepaidIntAmount, 0);
		this.prepaidIntAmountOverride = _.parseNumber(options.prepaidIntAmountOverride, null);

		// Overrides
		this.overrideAmountOfPaidForBorrowerAtClosing = _.parseNumber(options.overrideAmountOfPaidForBorrowerAtClosing, null);
		this.prepaidIntFromDateOverride = options.prepaidIntFromDateOverride || null;
		this.prepaidIntToDateOverride = options.prepaidIntToDateOverride || null;

		this.isDefaulted = false;
		this.isSelected = false;

		this.defaultedSettlementType = _.parseNumber(options.defaultedSettlementType, null);
		this.prepaidIntPercent = _.parseNumber(options.prepaidIntPercent, 0);
		this.prepaidIntTotalDays = _.parseNumber(options.prepaidIntTotalDays, 0);

		// Paid By Others fields
		this.paidByCompanyID = _.parseNumber(options.paidByCompanyID, null);
		this.paidForBorrower = _.parseNumber(options.paidForBorrower, null);
		this.paidForSeller = _.parseNumber(options.paidForSeller, null);
		this.isReducePayorCheck = _.parseBool(options.isReducePayorCheck, null);
		this.paidBySource = _.parseNumber(options.paidBySource, null);

		// Addendum field
		this.isAddendum = _.parseBool(options.isAddendum, null);
	}

	toDataObject() { return _.pickBy(this, (v,k) => { return !_.includes(["isDefaulted"], k); }); }

	get isPaidByOther() { return !_.isNil(this.paidBySource); }
	get hasPayeeCompany() { return _.gt(_.parseNumber(this.payeeCompanyID, 0), 0); }

	get hudLineTypeDescription() {
		// Hack because RQ1 doesn't actually have GFE #10 enum and hardcodes it by line number for logic
		// To be consistent with RQ1 we fabricate the description but the linetype is set to NONE
		if (this.hudLine === 901) return "GFE #10";

		return HudLineTypes.displayValue(this.hudLineType);
	}

	get isDisclosureLine() { return _.parseNumber(this.hudLineType) === HudLineTypes.Disclosure; }

	get prepaidDescription() {
		switch (this.prepaidLineType) {
			case PrepaidTypes.HomeownersInsPremium:
				return "Homeowner's Insurance Premium";
			case PrepaidTypes.MortgageInsPremium:
				return "Mortgage Insurance Premium";
			case PrepaidTypes.PrepaidInterest:
				return "Prepaid Interest";
			case PrepaidTypes.PropertyTax:
				return "Property Tax";
			default:
				return "";
		}
	}

	get payeeCompany() {
        return {
            companyID: this.payeeCompanyID,
            companyName: this.payeeCompanyName,
            contactID: this.payeeContactID,
            contactName: this.payeeContactName
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
        this.payeeContactID = _.get(val, "contactID", null);
        this.payeeContactName = _.get(val, "contactName", null);
	}

	revertOverrides() {
		// revert override fields
		this.overrideAmountOfPaidForBorrowerAtClosing = null;
		this.prepaidIntFromDateOverride = null;
		this.prepaidIntToDateOverride = null;
		this.prepaidIntTotalDays = null;

		if (this.prepaidIntPercent === this.loanInterestRate || this.prepaidIntPercent === 0) {
			this.prepaidIntAmountOverride =  null;
		}

    }

	get prepaidIntAmountValue() {
		if (!_.isNil(this.prepaidIntAmountOverride))
			return this.prepaidIntAmountOverride;
		else
			return this.prepaidIntAmount;
	}

	set prepaidIntAmountValue(val) {
		// Line 3 - Prepaid Interest is only prepaid that handles overrides
		if (val === this.prepaidIntAmount) {
			this.prepaidIntAmountOverride = null;
		}
		else {
			this.prepaidIntAmountOverride = val;
		}
	}

	get isPrepaidIntAmountOverridden() {
		return !_.isNil(this.prepaidIntAmountOverride);
	}

	get borrowerAtClosingValue() {
		if (!_.isNil(this.overrideAmountOfPaidForBorrowerAtClosing))
			return this.overrideAmountOfPaidForBorrowerAtClosing;
		else
			return this.borrowerAtClosing;
	}

	set borrowerAtClosingValue(val) {
		// Line 3 - Prepaid Interest is only prepaid that handles overrides
		if (this.isOverridableLine) {
			let roundedValue = accounting.parse(accounting.toFixed(this.borrowerAtClosing, 2));
			this.overrideAmountOfPaidForBorrowerAtClosing = (val === roundedValue) ? null : val;
		}
		else {
			this.borrowerAtClosing = val;
			this.overrideAmountOfPaidForBorrowerAtClosing = null;
		}
	}

	get isBorrowerAtClosingOverridden() {
		return !_.isNil(this.overrideAmountOfPaidForBorrowerAtClosing);
	}

	// Prepaid Interest From Date
	get prepaidIntFromDateValue() {
		if (!_.isNil(this.prepaidIntFromDateOverride))
			return this.prepaidIntFromDateOverride;
		else
			return this.prepaidIntFromDate;
	}

	set prepaidIntFromDateValue(val) {
		if (this.isDateEqual(val, this.prepaidIntFromDate)) {
			this.prepaidIntFromDateOverride = null;
		}
		else {
			this.prepaidIntFromDateOverride = val;
		}
	}

	get isPrepaidIntFromDateOverridden() {
		return !_.isNil(this.prepaidIntFromDateOverride);
	}

	// Prepaid Interest To Date
	get prepaidIntToDateValue() {
		if (!_.isNil(this.prepaidIntToDateOverride))
			return this.prepaidIntToDateOverride;
		else
			return this.prepaidIntToDate;
	}

	set prepaidIntToDateValue(val) {
		if (this.isDateEqual(val, this.prepaidIntToDate)) {
			this.prepaidIntToDateOverride = null;
		}
		else {
			this.prepaidIntToDateOverride = val;
		}
	}

	get isPrepaidIntToDateOverridden() {
		return !_.isNil(this.prepaidIntToDateOverride);
	}

	get isOverridableLine() {
		// Identify overridable lines for this section
		// Prepaid Interest is an overridable line
		// more may come...

		let isPrepaidInterest = this.line === 3 || this.hudLine === 901; // CDF, HUD prepaid interest line

		if (isPrepaidInterest && this.required === true) {
			return true;
		}

		return false;
	}

	//TODO: need to move this method to a common helper file
	isDateEqual(date1, date2){
		try {
			if(date1 === date2)
				return true;

			let d1 = new Date(date1);
			let d2 = new Date(date2);

			return (
				d1.getDate() == d2.getDate()
				&& d1.getMonth() == d2.getMonth()
				&& d1.getFullYear() == d2.getFullYear()
			);

		} catch {
			return false;
		}
	}

}

export class PayoffModel {
	constructor(options) {
		options = options || {};
		this.payoffID = _.parseNumber(options.payoffID, -1);
		this.ordersID = _.parseNumber(options.ordersID, 0);
		this.doNotMapCDF = _.parseBool(options.doNotMapCDF, false);
		this.doNotMapHUD = _.parseBool(options.doNotMapHUD, false);
		this.doNotMapCSS = _.parseBool(options.doNotMapCSS, false);
		this.payoffType = _.parseNumber(options.payoffType, 0);
		this.description = options.description;
		this.principalBalance = _.parseNumber(options.principalBalance, 0);
		this.loanNumber = options.loanNumber;
		this.loanID = options.loanID;
		this.section = _.parseNumber(options.section, 500); // Default to Seller
		this.sequence = _.parseNumber(options.sequence, null);
		this.totalPayoffAmountOverride = options.totalPayoffAmountOverride;
		this.payoutAmount = _.parseNumber(options.payoutAmount, 0);
		this.interestFromDate = options.interestFromDate;
		this.ovrThruDate = _.parseBool(options.ovrThruDate, false);
		this.interestThruDate = options.interestThruDate;
		this.ovrPerDiem = _.parseBool(options.ovrPerDiem, false);
		this.perDiem = _.parseNumber(options.perDiem, 0);
		this.cushionDays = _.parseNumber(options.cushionDays, 0);
		this.interestRate = _.parseNumber(options.interestRate, 0);
		this.daysInYearType = _.parseNumber(options.daysInYearType, 0);
		this.ovrTotalInterest =  _.parseBool(options.ovrTotalInterest, false);
		this.totalInterest = _.parseNumber(options.totalInterest, 0);
		this.pocWhom = _.parseNumber(options.pocWhom, 0);
		this.amountPOC = _.parseNumber(options.amountPOC, 0);
		this.netFund =  _.parseNumber(options.netFund, 0);
		this.netAmount =  _.parseNumber(options.netAmount, 0);
		this.details = [];
	}
}

export class Worksheet801Model {
	constructor(options){
		options = options || {};
		this.worksheet801ID = _.parseNumber(options.worksheet801ID, 0);
		this.hudMainID = _.parseNumber(options.hudMainID, null);
		this.description = options.description;
		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyName = options.payeeCompanyName;
		this.amount = options.amount;
		this.pocWhom = options.pocWhom || "";
		this.amountPOC = options.amountPOC;
		this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
		this.netFund = options.netFund;
		this.netFundAmount = options.netFundAmount;
		this.payeeContactID = _.parseNumber(options.payeeContactID, null);
		this.payeeContactName = options.payeeContactName;
		this.loanID = _.parseNumber(options.loanID, null);
	}

	get payeeCompany() {
        return {
            companyID: this.payeeCompanyID,
            companyName: this.payeeCompanyName,
            contactID: this.payeeContactID,
            contactName: this.payeeContactName
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
        this.payeeContactID = _.get(val, "contactID", null);
        this.payeeContactName = _.get(val, "contactName", null);
    }
}

export class AdditionalTitleServiceModel{
	constructor(options){
		options = options || {};
		this.additionalTitleServicesID = _.parseNumber(options.additionalTitleServicesID, 0);
		this.hudMainID = _.parseNumber(options.hudMainID, null);
		this.line = _.parseNumber(options.line, 0);
		this.description = options.description;
		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyName = options.payeeCompanyName;
		this.borrowerCharges = options.borrowerCharges;
		this.sellerCharges = options.sellerCharges;
		this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
		this.payeeContactID = _.parseNumber(options.payeeContactID, null);
		this.payeeContactName = options.payeeContactName;
		this.isSettlementFee = options.isSettlementFee;
		this.borrowerTax = options.borrowerTax;
		this.sellerTax = options.sellerTax;
		this.loanID = _.parseNumber(options.loanID, null);
	}

	get payeeCompany() {
        return {
            companyID: this.payeeCompanyID,
            companyName: this.payeeCompanyName,
            contactID: this.payeeContactID,
            contactName: this.payeeContactName
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
        this.payeeContactID = _.get(val, "contactID", null);
        this.payeeContactName = _.get(val, "contactName", null);
    }
}

export class PaidByOthersModel{
	constructor(options){
		options = options || {};
		this.clientKey = _.uniqueId("paid-by-other-item-");
		this.statementType = _.parseNumber(options.statementType, null);
		this.lineId = _.parseNumber(options.lineId, null);
		this.line = _.parseNumber(options.line, null);
		this.lineDescription = options.lineDescription || "";
		this.sectionName = options.sectionName || "";
		this.itemDescription = options.itemDescription || "";
		this.payorCompanyId = _.parseNumber(options.payorCompanyId, null);
		this.payorCompanyName = options.payorCompanyName || "";
		this.paidForBorrower = _.parseNumber(options.paidForBorrower, 0);
		this.paidForBorrowerAtClosing = _.parseNumber(options.paidForBorrowerAtClosing, 0);
		this.overrideAmountOfPaidForBorrowerAtClosing = _.parseNumber(options.overrideAmountOfPaidForBorrowerAtClosing, null);
		this.paidForSeller = _.parseNumber(options.paidForSeller, 0);
		this.paidForSellerAtClosing = _.parseNumber(options.paidForSellerAtClosing, 0);
		this.overrideAmountOfPaidForSellerAtClosing = _.parseNumber(options.overrideAmountOfPaidForSellerAtClosing, null);
		this.totalCharges = _.parseNumber(options.totalCharges, 0);
		this.reducePayorCheck = _.parseBool(options.reducePayorCheck, true);
		this.isRecordingItemization = _.parseBool(options.isRecordingItemization, false);
		this.recordingItemizationDetailId = _.parseNumber(options.recordingItemizationDetailId, null);
		this.selected = false;
		this.lineType = options.lineType || "";
		this.loanID = _.parseNumber(options.loanID, 0);
		this.id = _.parseNumber(options.id, 0); // Id associated with the original section data record for (loanCostSectionData, EscrowSectionData, PrepaidSectionData, ...)
	}

	get paidForBorrowerAmount(){
		return this.paidForBorrower;
	}
	set paidForBorrowerAmount(val){
		this.paidForBorrower = _.parseNumber(val, 0);
		this.totalCharges = this.paidForBorrower + this.paidForSeller;
	}
	get paidForSellerAmount(){
		return this.paidForSeller;
	}
	set paidForSellerAmount(val){
		this.paidForSeller = _.parseNumber(val, 0);
		this.totalCharges = this.paidForBorrower + this.paidForSeller;
	}
	get amount(){
		return this.totalCharges;
	}
	get description(){
		return `${this.lineDescription} - ${this.itemDescription}`;
	}
	get isPaidForBorrowerAtClosingOverridden() {
		return !_.isNil(this.overrideAmountOfPaidForBorrowerAtClosing);
	}
	get isPaidForSellerAtClosingOverridden() {
		return !_.isNil(this.overrideAmountOfPaidForSellerAtClosing);
	}
	get borrowerAtClosingValue(){
		return this.isPaidForBorrowerAtClosingOverridden ? this.overrideAmountOfPaidForBorrowerAtClosing : this.paidForBorrowerAtClosing;
	}
	get sellerAtClosingValue(){
		return this.isPaidForSellerAtClosingOverridden ? this.overrideAmountOfPaidForSellerAtClosing : this.paidForSellerAtClosing;
	}
}

export class ProjectedPaymentModel {
	constructor(options) {
		options = options || {};
		this.projectedPaymentSectionDataID = _.parseNumber(options.projectedPaymentSectionDataID, 0);
		this.loanID = _.parseNumber(options.loanID, 0);

		// Loan
		this.mortgageInsurance = _.parseNumber(options.mortgageInsurance, 0);

		// CDFMain
		// this.monthlyPrincipalInterest = _.parseNumber(options.monthlyPrincipalInterest, 0);
		this.monthlyPrincipalInterestOverride = _.parseNumber(options.monthlyPrincipalInterestOverride, null);

		this.numberOfColumn = _.parseNumber(options.numberOfColumn, 2);
		this.paymentCalculationYearFrom1 = _.parseNumber(options.paymentCalculationYearFrom1, 0);
		this.paymentCalculationYearTo1 = _.parseNumber(options.paymentCalculationYearTo1, 0);
		this.paymentCalculationYearFrom2 = _.parseNumber(options.paymentCalculationYearFrom2, 0);
		this.paymentCalculationYearTo2 = _.parseNumber(options.paymentCalculationYearTo2, 0);
		this.paymentCalculationYearFrom3 = _.parseNumber(options.paymentCalculationYearFrom3, 0);
		this.paymentCalculationYearTo3 = _.parseNumber(options.paymentCalculationYearTo3, 0);
		this.paymentCalculationYearFrom4 = _.parseNumber(options.paymentCalculationYearFrom4, 0);
		this.paymentCalculationYearTo4 = _.parseNumber(options.paymentCalculationYearTo4, 0);
		this.principalInterestAmount1 = _.parseNumber(options.principalInterestAmount1, 0);
		this.principalInterestAmount2 = _.parseNumber(options.principalInterestAmount2, 0);
		this.principalInterestAmount3 = _.parseNumber(options.principalInterestAmount3, 0);
		this.principalInterestAmount4 = _.parseNumber(options.principalInterestAmount4, 0);
		this.mortgageInsuranceAmount2 = _.parseNumber(options.mortgageInsuranceAmount2, 0);
		this.mortgageInsuranceAmount3 = _.parseNumber(options.mortgageInsuranceAmount3, 0);
		this.mortgageInsuranceAmount4 = _.parseNumber(options.mortgageInsuranceAmount4, 0);
		this.estimatedEscrowAmount1 = _.parseNumber(options.estimatedEscrowAmount1, 0);
		this.estimatedEscrowAmount2 = _.parseNumber(options.estimatedEscrowAmount2, 0);
		this.estimatedEscrowAmount3 = _.parseNumber(options.estimatedEscrowAmount3, 0);
		this.estimatedEscrowAmount4 = _.parseNumber(options.estimatedEscrowAmount4, 0);
		this.estimatedTotalMonthlyAmount1 = _.parseNumber(options.estimatedTotalMonthlyAmount1, 0);
		this.estimatedTotalMonthlyAmount2 = _.parseNumber(options.estimatedTotalMonthlyAmount2, 0);
		this.estimatedTotalMonthlyAmount3 = _.parseNumber(options.estimatedTotalMonthlyAmount3, 0);
		this.estimatedTotalMonthlyAmount4 = _.parseNumber(options.estimatedTotalMonthlyAmount4, 0);
		this.estimatedTaxInsuranceAssessmentAmount = _.parseNumber(options.estimatedTaxInsuranceAssessmentAmount, 0);
		this.isPropertyTax = _.parseBool(options.isPropertyTax, false);
		this.isHomeownerInsurance = _.parseBool(options.isHomeownerInsurance);
		this.isOther = _.parseBool(options.isOther, false);

		this.otherText = options.otherText || "";
		this.otherTextOptions = options.otherTextOptions || "";

		this.isPropertyTaxEscrow = _.parseNumber(options.isPropertyTaxEscrow, 0);
		this.isHomeownerInsuranceEscrow = _.parseNumber(options.isHomeownerInsuranceEscrow), 0;
		this.isOtherEscrow = _.parseNumber(options.isOtherEscrow, 0);
		this.estimatedTotalMonthlyOverrideAmount1 = _.parseNumber(options.estimatedTotalMonthlyOverrideAmount1, null);
		this.estimatedTotalMonthlyOverrideAmount2 = _.parseNumber(options.estimatedTotalMonthlyOverrideAmount2, null);
		this.estimatedTotalMonthlyOverrideAmount3 = _.parseNumber(options.estimatedTotalMonthlyOverrideAmount3, null);
		this.estimatedTotalMonthlyOverrideAmount4 = _.parseNumber(options.estimatedTotalMonthlyOverrideAmount4, null);
		this.estimatedEscrowAmount1Override = _.parseNumber(options.estimatedEscrowAmount1Override, null);
		this.isPCUser = _.parseBool(options.isPCUser, false);
		this.paymentFrequencyType = _.parseNumber(options.paymentFrequencyType, 3);
		this.isInterestOnly1 = _.parseBool(options.isInterestOnly1, false);
		this.isInterestOnly2 = _.parseBool(options.isInterestOnly2, false);
		this.isInterestOnly3 = _.parseBool(options.isInterestOnly3, false);
		this.principalInterestAmount2Max = _.parseNumber(options.principalInterestAmount2Max, 0);
		this.principalInterestAmount3Max = _.parseNumber(options.principalInterestAmount3Max, 0);
		this.principalInterestAmount4Max = _.parseNumber(options.principalInterestAmount4Max, 0);
		this.estimatedTotalMonthlyAmount2Max = _.parseNumber(options.estimatedTotalMonthlyAmount2Max, 0);
		this.estimatedTotalMonthlyAmount3Max = _.parseNumber(options.estimatedTotalMonthlyAmount3Max, 0);
		this.estimatedTotalMonthlyAmount4Max = _.parseNumber(options.estimatedTotalMonthlyAmount4Max, 0);
		this.estimatedTotalMonthlyOverrideAmount2Max = _.parseNumber(options.estimatedTotalMonthlyOverrideAmount2Max, null);
		this.estimatedTotalMonthlyOverrideAmount3Max = _.parseNumber(options.estimatedTotalMonthlyOverrideAmount3Max, null);
		this.estimatedTotalMonthlyOverrideAmount4Max = _.parseNumber(options.estimatedTotalMonthlyOverrideAmount4Max, null);

		this.canInterestIncrease = _.parseBool(options.canInterestIncrease, false);
		this.isBalloonPayment = _.parseBool(options.isBalloonPayment, false);
	}

	get estimatedEscrowAmount1Value() {
		if (!_.isNil(this.estimatedEscrowAmount1Override))
			return this.estimatedEscrowAmount1Override;
		else
			return this.estimatedEscrowAmount1;
	}

	set estimatedEscrowAmount1Value(val) {
		if (val === this.estimatedEscrowAmount1) {
			this.estimatedEscrowAmount1Override = null;
		}
		else {
			this.estimatedEscrowAmount1Override = val;
		}
	}

	get isEstimatedEscrowAmount1Overridden() {
		return !_.isNil(this.estimatedEscrowAmount1Override);
	}
}

export class DebitCreditModel {
	constructor(options) {
		options = options || {};

        this.debitCreditSectionDataID = _.parseNumber(options.debitCreditSectionDataID);
        this.loanID = _.parseNumber(options.loanID);
        this.description = options.description || "";
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
        this.payeeContactID = _.parseNumber(options.payeeContactID, null);

		this.netFund = _.parseNumber(options.netFund, null);
        this.accountCodeID = _.parseNumber(options.accountCodeID);
        this.doNotPrint = _.parseBool(options.doNotPrint, false);

        this.debitCreditLineID = _.parseNumber(options.debitCreditLineID, null);
        this.required = _.parseBool(options.required, false);
		this.amount = _.parseNumber(options.amount, null);
        this.isDebitCredit = _.parseBool(options.isDebitCredit, null);
        this.adjustmentCredit = _.parseNumber(options.adjustmentCredit, 1);
		this.isAdjustmentForOwnerPolicyPaidbySeller = _.parseBool(options.isAdjustmentForOwnerPolicyPaidbySeller);
		this.isAdditionalDeposit = _.parseBool(options.isAdditionalDeposit);

		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.payeeCompanyName = options.payeeCompanyName || "";
		this.payeeContactID = _.parseNumber(options.payeeContactID, null);
		this.payeeContactName = options.payeeContactName || "";
		this.earnestMoneyHeldBy = _.parseNumber(options.earnestMoneyHeldBy, 0);
		this.isProration = _.parseBool(options.isProration, false);
		this.fromDate = DateTime.fromISO(options.fromDate).isValid ? DateTime.fromISO(options.fromDate).toFormat("MM/dd/yyyy") : null;
		this.toDate = DateTime.fromISO(options.toDate).isValid ? DateTime.fromISO(options.toDate).toFormat("MM/dd/yyyy") : null;
		this.prorationMethod = options.prorationMethod || "";

		// CDF specific
		this.cdfSection = options.cdfSection || "";
        this.line = _.parseNumber(options.line);
		this.cdfLineID = _.parseNumber(options.cdfLineID, null);

		// HUD specific
        this.hudSection = _.parseNumber(options.hudSection);
        this.hudLineType = options.hudLineType || "";
		this.hudLine = _.parseNumber(options.hudLine, null);
		this.pocAmount = _.parseNumber(options.pocAmount, null);
		this.poc = options.poc || "";

		this.payoffID = _.parseNumber(options.payoffID);
		this.defaultedSettlementType = _.parseNumber(options.defaultedSettlementType, null);
		this.isPayoffAssociated = _.parseBool(options.isPayoffAssociated);

		// overrides
		this.overrideAmount = _.parseNumber(options.overrideAmount, null);
		this.overrideDescription = options.overrideDescription;

		this.category = options.category || AltaCategories.ProrationAdj;

		this.hideDebitCredit = _.parseBool(options.hideDebitCredit);
	}

	get isLineOverridden() {
		return this.isDescriptionOverridden || this.isAmountOverridden;
	}

	get isDescriptionOverridden() {
		return this.overrideDescription != null && this.overrideDescription != (this.description ?? "");
	}

	get descriptionValue() {
		return this.isDescriptionOverridden ? this.overrideDescription : this.description;
	}

	set descriptionValue(val) {
		let origDescription = this.description;
		if (this.isAdjustmentForOwnerPolicyPaidbySeller || this.isAdditionalDeposit) {
			if (val === "") {
				this.overrideDescription = this.description != "" ? "" : null;
				return;
			}
			this.overrideDescription = val;
		}
		else {
			this.description = val;
			this.overrideDescription = null;
		}
	}
	revertOverrideDescription(line) {
		line.overrideDescription = null;
	}

	revertOverrideAmount(line) {
		line.overrideAmount = null;
	}

	get isAmountOverridden() {
		return !_.isNil(this.overrideAmount) && (this.amount !== this.overrideAmount);
	}

	get amountValue() {
		return this.isAmountOverridden ? this.overrideAmount : this.amount;
	}

	set amountValue(val) {
		if (this.isAdjustmentForOwnerPolicyPaidbySeller || this.isAdditionalDeposit || this.isProration) {
			if(val === 0 && this.amount === null){
				this.amount = null;
				this.overrideAmount = null;
			}
			else{
			this.overrideAmount = val;
			}
		}
		else {
			this.amount = val;
			this.overrideAmount = null;
		}
	}

	get isPayOff() { return _.parseNumber(this.payoffID, 0) > 0; }

	get hasPayeeCompany() { return _.gt(_.parseNumber(this.payeeCompanyID, 0), 0); }

	get payeeCompany() {
        return {
            companyID: this.payeeCompanyID,
            companyName: this.payeeCompanyName,
            contactID: this.payeeContactID,
            contactName: this.payeeContactName
        };
    }

    set payeeCompany(val) {
        this.payeeCompanyID = _.get(val, "companyID", null);
        this.payeeCompanyName = _.get(val, "companyName", null);
        this.payeeContactID = _.get(val, "contactID", null);
        this.payeeContactName = _.get(val, "contactName", null);
	}

	get lineDescription() {
		let result = this.description || '';

		if (this.isProration) {
			result = `${result} ${this.fromDate} ${this.prorationMethod} ${this.toDate}`;
		}
		return result;
	}

	set lineDescription(val) {
		this.description = val;
	}
}

export class DebitCreditOverrideStatus {
	constructor(options) {
		this.loanID = _.parseNumber(options.loanID, 0);
        this.isL08InUse = _.parseBool(options.isL08InUse);
        this.isN10InUse = _.parseBool(options.isN10InUse);
        this.is208InUse = _.parseBool(options.is208InUse);
        this.is209InUse = _.parseBool(options.is209InUse);
        this.is508InUse = _.parseBool(options.is508InUse);
        this.is509InUse = _.parseBool(options.is509InUse);
        this.cdfL08Description = (options.cdfL08Description) || "";
        this.cdfN10Description = (options.cdfN10Description) || "";
		this.hud208Description = (options.hud208Description) || "";
		this.hud209Description = (options.hud209Description) || "";
        this.hud508Description = (options.hud508Description) || "";
		this.hud509Description = (options.hud509Description) || "";
	}

	get isCdfInUse() {
		return this.isL08InUse || this.isN10InUse;
	}

	get isHudOwnerInUse() {
		return this.is208InUse || this.is508InUse;
	}

	get isHudLenderInUse() {
		return this.is209InUse || this.is509InUse;
	}
}

export class CashToCloseSectionDataModel {
	constructor(options) {
		options = options || {};
		this.cashToCloseSectionDataID = _.parseNumber(options.cashToCloseSectionDataID, 0);
		this.loanID = _.parseNumber(options.loanID, 0);
		this.loanEstimateClosingCostAmount = _.parseNumber(options.loanEstimateClosingCostAmount, 0);
		this.loanEstimateClosingCostPaidBeforeClosingAmount = _.parseNumber(options.loanEstimateClosingCostPaidBeforeClosingAmount, 0);
		this.loanEstimateClosingCostFinanceAmount = _.parseNumber(options.loanEstimateClosingCostFinanceAmount, 0);
		this.loanEstimateDownPaymentFromBorrowerAmount = _.parseNumber(options.loanEstimateDownPaymentFromBorrowerAmount, 0);
		this.loanEstimateDepositAmount = _.parseNumber(options.loanEstimateDepositAmount, 0);
		this.loanEstimateFundForBorrowerAmount = _.parseNumber(options.loanEstimateFundForBorrowerAmount, 0);
		this.loanEstimateSellerCreditAmount = _.parseNumber(options.loanEstimateSellerCreditAmount, 0);
		this.loanEstimateAdjustmentAmount= _.parseNumber(options.loanEstimateAdjustmentAmount, 0);
		this.loanEstimateTotalAmount = _.parseNumber(options.loanEstimateTotalAmount, 0);

		this.finalClosingCostAmount = _.parseNumber(options.finalClosingCostAmount, 0);
		this.finalClosingCostPaidBeforeClosingAmount = _.parseNumber(options.finalClosingCostPaidBeforeClosingAmount, 0);
		this.finalClosingCostFinanceAmount = _.parseNumber(options.finalClosingCostFinanceAmount, 0);
		this.finalClosingCostFinanceOverrideAmount= _.parseNumber(options.finalClosingCostFinanceOverrideAmount, 0);
		this.finalDownPaymentFromBorrowerAmount = _.parseNumber(options.finalDownPaymentFromBorrowerAmount, 0);
		this.finalDownPaymentFromBorrowerOverrideAmount = _.parseNumber(options.finalDownPaymentFromBorrowerOverrideAmount, 0);
		this.finalDepositAmount = _.parseNumber(options.finalDepositAmount, 0);
		this.finalFundForBorrowerAmount = _.parseNumber(options.finalFundForBorrowerAmount, 0);
		this.finalFundForBorrowerOverrideAmount = _.parseNumber(options.finalFundForBorrowerOverrideAmount, 0);
		this.finalSellerCreditAmount = _.parseNumber(options.finalSellerCreditAmount, 0);
		this.finalSellerCreditOverrideAmount= _.parseNumber(options.finalSellerCreditOverrideAmount, null);
		this.finalAdjustmentAmount = _.parseNumber(options.finalAdjustmentAmount, 0);
		this.finalAdjustmentOverrideAmount = _.parseNumber(options.finalAdjustmentOverrideAmount, null);
		this.finalTotalAmount = _.parseNumber(options.finalTotalAmount, 0);

		this.isClosingCostChange = _.parseBool(options.isClosingCostChange);
		this.isClosingCostPaidBeforeClosingChange = _.parseBool(options.isClosingCostPaidBeforeClosingChange);
		this.isClosingCostFinanceChange = _.parseBool(options.isClosingCostFinanceChange);
		this.isDownPaymentFromBorrowerChange = _.parseBool(options.isDownPaymentFromBorrowerChange);
		this.isDepositChange = _.parseBool(options.isDepositChange);
		this.isFundForBorrowerChange = _.parseBool(options.isFundForBorrowerChange);
		this.isSellerCreditChange = _.parseBool(options.isSellerCreditChange);
		this.isAdjustmentChange= _.parseBool(options.isAdjustmentChange);
		this.isClosingCostOverride = _.parseBool(options.isClosingCostOverride);
		this.isClosingCostPaidBeforeClosingOverride = _.parseBool(options.isClosingCostPaidBeforeClosingOverride);
		this.isClosingCostFinanceOverride = _.parseBool(options.isClosingCostFinanceOverride);
		this.isDownPaymentFromBorrowerOverride = _.parseBool(options.isDownPaymentFromBorrowerOverride);
		this.isDepositOverride = _.parseBool(options.isDepositOverride);
		this.isFundForBorrowerOverride = _.parseBool(options.isFundForBorrowerOverride);
		this.isSellerCreditOverride = _.parseBool(options.isSellerCreditOverride);
		this.isAdjustmentOverride = _.parseBool(options.isAdjustmentOverride);

		this.closingCostOverrideText = (options.closingCostOverrideText) || "";
		this.closingCostOverrideRtfFormatText = (options.closingCostOverrideRtfFormatText) || "";
		this.closingCostPaidBeforeClosingOverrideText = (options.closingCostPaidBeforeClosingOverrideText) || "";
		this.closingCostPaidBeforeClosingOverrideRtfFormatText = (options.closingCostPaidBeforeClosingOverrideRtfFormatText) || "";
		this.closingCostFinanceOverrideText = (options.closingCostFinanceOverrideText) || "";
		this.closingCostFinanceOverrideRtfFormatText = (options.closingCostFinanceOverrideRtfFormatText) || "";
		this.downPaymentFromBorrowerOverrideText = (options.downPaymentFromBorrowerOverrideText) || "";
		this.downPaymentFromBorrowerOverrideRtfFormatText = (options.downPaymentFromBorrowerOverrideRtfFormatText) || "";
		this.depositOverrideText = (options.depositOverrideText) || "";
		this.depositOverrideRtfFormatText = (options.depositOverrideRtfFormatText) || "";
		this.fundForBorrowerOverrideText = (options.fundForBorrowerOverrideText) || "";
		this.fundForBorrowerOverrideRtfFormatText = (options.fundForBorrowerOverrideRtfFormatText) || "";
		this.sellerCreditOverrideText = (options.sellerCreditOverrideText) || "";
		this.sellerCreditOverrideRtfFormatText = (options.sellerCreditOverrideRtfFormatText) || "";
		this.adjustmentOverrideText = (options.adjustmentOverrideText) || "";
		this.adjustmentOverrideRtfFormatText = (options.adjustmentOverrideRtfFormatText) || "";
		this.isLoanAmountChange = _.parseBool(options.isLoanAmountChange);
		this.loanEstimateLoanAmount = _.parseNumber(options.loanEstimateLoanAmount, 0);
		this.finalLoanAmount = _.parseNumber(options.finalLoanAmount, 0);
		this.loanAmountOverrideText = (options.loanAmountOverrideText) || "";
		this.loanAmountOverrideRtfFormatText = (options.loanAmountOverrideRtfFormatText) || "";
		this.isTotalPayoffPaymentChange = _.parseBool(options.isTotalPayoffPaymentChange);
		this.loanEstimateTotalPayoffPaymentAmount = _.parseNumber(options.loanEstimateTotalPayoffPaymentAmount, 0);
		this.finalTotalPayoffPaymentAmount = _.parseNumber(options.finalTotalPayoffPaymentAmount, 0);
		this.totalPayoffPaymentOverrideText = (options.totalPayoffPaymentOverrideText) || "";
		this.totalPayoffPaymentOverrideRtfFormatText = (options.totalPayoffPaymentOverrideRtfFormatText) || "";
		this.isLoanAmountOverride = _.parseBool(options.isLoanAmountOverride);
		this.isTotalPayoffPaymentOverride = _.parseBool(options.isTotalPayoffPaymentOverride);
		this.loanEstimateTotalAmountWithoutSeller = _.parseNumber(options.loanEstimateTotalAmountWithoutSeller, 0);
		this.finalTotalAmountWithoutSeller = _.parseNumber(options.finalTotalAmountWithoutSeller, 0);
		this.financedClosingCosts = _.parseNumber(options.financedClosingCosts, 0);
		this.isClosingCostChangeOverride = _.parseBool(options.isClosingCostChangeOverride);
		this.isClosingCostPaidBeforeClosingChangeOverride = _.parseBool(options.isClosingCostPaidBeforeClosingChangeOverride);
		this.isClosingCostFinanceChangeOverride = _.parseBool(options.isClosingCostFinanceChangeOverride);
		this.isDownPaymentFromBorrowerChangeOverride = _.parseBool(options.isDownPaymentFromBorrowerChangeOverride);
		this.isDepositChangeOverride = _.parseBool(options.isDepositChangeOverride);
		this.isFundForBorrowerChangeOverride = _.parseBool(options.isFundForBorrowerChangeOverride);
		this.isSellerCreditChangeOverride = _.parseBool(options.isSellerCreditChangeOverride);
		this.isAdjustmentChangeOverride = _.parseBool(options.isAdjustmentChangeOverride);
		this.isTotalPayoffPaymentChangeOverride = _.parseBool(options.isTotalPayoffPaymentChangeOverride);
		this.isLoanAmountChangeOverride = _.parseBool(options.isLoanAmountChangeOverride);
	}

	get finalSellerCreditAmountValue() {
		if (!_.isNil(this.finalSellerCreditOverrideAmount))
			return this.finalSellerCreditOverrideAmount;
		else
			return this.finalSellerCreditAmount;
	}

	set finalSellerCreditAmountValue(val) {
		if (val === this.finalSellerCreditAmount) {
			this.finalSellerCreditOverrideAmount = null;
		}
		else {
			this.finalSellerCreditOverrideAmount = val;
		}
	}

	get isFinalSellerCreditAmountOverridden() {
		return !_.isNil(this.finalSellerCreditOverrideAmount);
	}

	//finalAdjustmentAmount
	get finalAdjustmentAmountValue() {
		if (!_.isNil(this.finalAdjustmentOverrideAmount))
			return this.finalAdjustmentOverrideAmount;
		else
			return this.finalAdjustmentAmount;
	}

	set finalAdjustmentAmountValue(val) {
		if (val === this.finalAdjustmentAmount) {
			this.finalAdjustmentOverrideAmount = null;
		}
		else {
			this.finalAdjustmentOverrideAmount = val;
		}
	}

	get isFinalAdjustmentAmountOverridden() {
		return !_.isNil(this.finalAdjustmentOverrideAmount);
	}
}

export class LoanTermSectionDataModel {
	constructor(options) {
		options = options || {};
		this.loanTermSectionDataID = _.parseNumber(options.loanTermSectionDataID, 0);
		this.loanID = _.parseNumber(options.loanID, 0);

		// Loan
		this.loanAmount = _.parseNumber(options.loanAmount, 0);
		this.loanInterestRate = _.parseNumber(options.loanInterestRate, 0);

		// CDFMain
		this.monthlyPrincipalInterest = _.parseNumber(options.monthlyPrincipalInterest, 0);
		this.monthlyPrincipalInterestOverride = _.parseNumber(options.monthlyPrincipalInterestOverride, null);

        this.isLoanAmountIncrease = _.parseBool(options.isLoanAmountIncrease, false);
		this.maxLoanAmount = _.parseNumber(options.maxLoanAmount, null);
		this.loanIncreaseYear = _.parseNumber(options.loanIncreaseYear, null);
		this.isLoanAmountOverride = _.parseBool(options.isLoanAmountOverride, false);
		this.loanAmountOverrideText = (options.loanAmountOverrideText) || "";
		this.isInterestRateIncrease = _.parseBool(options.isInterestRateIncrease, false);
		this.years = _.parseNumber(options.years, 0);
		this.year1 = _.parseNumber(options.year1, 0);
		this.interestRateHighPercentage = _.parseNumber(options.interestRateHighPercentage, 0);
		this.year2 = _.parseNumber(options.year2, 0);
		this.isInterestRateOverride = _.parseBool(options.isInterestRateOverride, false);
		this.interestRateOverrideText = (options.interestRateOverrideText) || "";
		this.isMonthlyPrincipalInterestIncrease = _.parseBool(options.isMonthlyPrincipalInterestIncrease, false);
		this.monthlyPrincipalInterestYears = _.parseNumber(options.monthlyPrincipalInterestYears, null);
		this.monthlyPrincipalInterestYear1 = _.parseNumber(options.monthlyPrincipalInterestYear1, 0);
		// this.monthlyPrincipalInterestHighPercentage = _.parseNumber(options.monthlyPrincipalInterestHighPercentage);
		this.monthlyPrincipalInterestHigh = _.parseNumber(options.monthlyPrincipalInterestHigh, 0);
		this.monthlyPrincipalInterestYear2 = _.parseNumber(options.monthlyPrincipalInterestYear2, 0);
		this.monthlyPrincipalInterestYear3 = _.parseNumber(options.monthlyPrincipalInterestYear3, 0);
		this.isMonthlyPrincipalInterestOverride = _.parseBool(options.isMonthlyPrincipalInterestOverride, false);
		this.monthlyPrincipalInterestOverrideText = (options.monthlyPrincipalInterestOverrideText) || "";
		this.isPrepaymentPenaltyIncrease = _.parseBool(options.isPrepaymentPenaltyIncrease, false);
		this.prepaymentPenaltyAmount = _.parseNumber(options.prepaymentPenaltyAmount, null);
		this.prepaymentPenaltyYears = _.parseNumber(options.prepaymentPenaltyYears, null);
		this.isPrepaymentPenaltyOverride = _.parseBool(options.isPrepaymentPenaltyOverride, false);
		this.prepaymentPenaltyOverrideText = (options.prepaymentPenaltyOverrideText) || "";
		this.isBalloonPaymentIncrease = _.parseBool(options.isBalloonPaymentIncrease, false);
		this.balloonPaymentAmount = _.parseNumber(options.balloonPaymentAmount, null);
		this.balloonPaymentYears = _.parseNumber(options.balloonPaymentYears, null);
		this.isBalloonPaymentOverride = _.parseBool(options.isBalloonPaymentOverride, false);
		this.balloonPaymentOverrideText = (options.balloonPaymentOverrideText) || "";
		this.balloonPaymentOverrideRtfFormatText = (options.balloonPaymentOverrideRtfFormatText) || "";
		this.prepaymentPenaltyOverrideRtfFormatText = (options.prepaymentPenaltyOverrideRtfFormatText) || "";
		this.monthlyPrincipalInterestOverrideRtfFormatText = (options.monthlyPrincipalInterestOverrideRtfFormatText) || "";
		this.loanAmountOverrideRtfFormatText = (options.loanAmountOverrideRtfFormatText) || "";
		this.interestRateOverrideRtfFormatText = (options.interestRateOverrideRtfFormatText) || "";
		this.lastChangedBy = (options.lastChangedBy);
        this.lastChanged = (options.lastChanged);
		this.isPCUser = _.parseBool(options.isPCUser, null);
		this.lastChangedByUserID = _.parseNumber(options.lastChangedByUserID, null);
		this.isNotDisplayAP = _.parseBool(options.isNotDisplayAP, false);
		this.isNotDisplayAIR = _.parseBool(options.isNotDisplayAIR, false);
	}

	get monthlyPIValue() {
		if (!_.isNil(this.monthlyPrincipalInterestOverride))
			return this.monthlyPrincipalInterestOverride;
		else
			return this.monthlyPrincipalInterest;
	}

	set monthlyPIValue(val) {
		if (val === this.monthlyPrincipalInterest) {
			this.monthlyPrincipalInterestOverride = null;
		}
		else {
			this.monthlyPrincipalInterestOverride = val;
		}
	}

	get monthlyPIOverridden() {
		return !_.isNil(this.monthlyPrincipalInterestOverride);
	}

	toDataObject() { return _.toPlainObject(this); }
}

export class LoanTermSectionHud2010DataModel extends LoanTermSectionDataModel {
	constructor(options) {
		options = options || {};
		super(options);

		// Loan section properties
		this.hudInitialLoanAmountOverride = _.parseBool(options.hudInitialLoanAmountOverride, false); // tinyint
		this.hudTerm = _.parseNumber(options.hudTerm, 0);
		this.hudLoanTermPeriod = _.parseNumber(options.hudLoanTermPeriod, 0);
		this.hudLoanMonths = _.parseNumber(options.hudLoanMonths, 0);
		this.hudInitialLoanTermOverride = _.parseBool(options.hudInitialLoanTermOverride, false);

		// Properties added here are explicit to 2010 HUD where LoanTermSectionDataModel covers common properties
		// Interest Rate related properties
		this.hudFirstInterestRateChangeDate = options.hudFirstInterestRateChangeDate || null; // date
		this.hudInterestRateChangePeriod = options.hudInterestRateChangePeriod || ""; // varchar
		this.hudWhenInterestRateCanChange = options.hudWhenInterestRateCanChange || null; // date
		this.hudInterestRateChangeAmount = _.parseNumber(options.hudInterestRateChangeAmount, 0); // float
		this.hudInterestRateHighestAmount = _.parseNumber(options.hudInterestRateHighestAmount, 0); // float
		this.hudInitialInterestRateOverride = _.parseBool(options.hudInitialInterestRateOverride, false); // tinyint
		this.hudInterestRateLowestAmount = _.parseNumber(options.hudInterestRateLowestAmount, 0); // money

		// Initial Monthly Principal, Interest and Mortgate Insurance properties
		this.hudInitialMonthlyAmount = _.parseNumber(options.hudInitialMonthlyAmount, 0); // money
		this.hudPrincipalIncluded = _.parseBool(options.hudPrincipalIncluded, false); // tinyint
		this.hudInterestIncluded = _.parseBool(options.hudInterestIncluded, false); // tinyint
		this.hudMortgageInsurance = _.parseBool(options.hudMortgageInsurance, false); // tinyint
		this.hudFirstMonthlyAmountIncreaseDate = options.hudFirstMonthlyAmountIncreaseDate || null; // date
		this.hudMonthlyAmountCanRise = _.parseBool(options.hudMonthlyAmountCanRise, false); // tinyint
		this.hudMonthlyAmountCanRiseTo = _.parseNumber(options.hudMonthlyAmountCanRiseTo, 0); // money
		this.hudPIAmountOverride = _.parseBool(options.hudPIAmountOverride, false); // tinyint
		this.hudMonthlyMaxAmountCanRiseTo = _.parseNumber(options.hudMonthlyMaxAmountCanRiseTo, 0); // money

		// Does Loan Have a Balloon Payment
		this.hudLoanBalloonPaymentDueDate = options.hudLoanBalloonPaymentDueDate || null; // date

		// Escrow Account
		this.hudAdditionalMonthlyEscrowPaymentAmount = _.parseNumber(options.hudAdditionalMonthlyEscrowPaymentAmount, 0); // money
		this.hudTotalInitialMonthlyAmountOwed = _.parseNumber(options.hudTotalInitialMonthlyAmountOwed, 0); // money
		this.hudAdditionalMonthlyEscrowForFloodInsurance = _.parseBool(options.hudAdditionalMonthlyEscrowForFloodInsurance, false); // tinyint
		this.hudAdditionalMonthlyEscrowForCustom2 = _.parseBool(options.hudAdditionalMonthlyEscrowForCustom2, false); // tinyint
		this.hudAdditionalMonthlyEscrowForCustom3 = _.parseBool(options.hudAdditionalMonthlyEscrowForCustom3, false); // tinyint
		this.hudAdditionalMonthlyEscrowForCustom2Desc = options.hudAdditionalMonthlyEscrowForCustom2Desc || ""; // varchar
		this.hudAdditionalMonthlyEscrowForCustom3Desc = options.hudAdditionalMonthlyEscrowForCustom3Desc || ""; // varchar
		this.hudNoMonthlyEscrowPaymentForItems = _.parseBool(options.hudNoMonthlyEscrowPaymentForItems, false);
		this.hudTotalMonthlyAmoutOwedOverride = _.parseBool(options.hudTotalMonthlyAmoutOwedOverride, false);
		this.hudAdditionalMonthlyEscrowForPropertyTaxes = _.parseBool(options.hudAdditionalMonthlyEscrowForPropertyTaxes, false);
		this.hudAdditionalMonthlyEscrowForHomeOwnersInsurance = _.parseBool(options.hudAdditionalMonthlyEscrowForHomeOwnersInsurance, false);
		this.hudAdditionalMonthlyEscrowForCustom1 = _.parseBool(options.hudAdditionalMonthlyEscrowForCustom1, false);
		this.hudAdditionalMonthlyEscrowForCustom1Desc =options.hudAdditionalMonthlyEscrowForCustom1Desc || ""; // varchar

		// Reverse Mortgage parmeters
		this.hudLoanTermNA = _.parseBool(options.hudLoanTermNA);
		this.hudMonthlyAmountNA = _.parseBool(options.hudMonthlyAmountNA);
		this.hudLoanBalanceRiseNA = _.parseBool(options.hudLoanBalanceRiseNA);
	}
}

export class LoanDisclosureModel {

	constructor(options) {
        options = options || {};
		this.loanDisclosureSectionDataID = _.parseNumber(options.loanDisclosureSectionDataID, 0);
		this.loanID = _.parseNumber(options.loanID, 0);

		this.allowAssumption = _.parseBool(options.allowAssumption);
		this.hasDemandFeature = _.parseBool(options.hasDemandFeature);
		this.latePaymentDays = _.parseNumber(options.latePaymentDays, 0);
		this.latePaymentDescription = options.latePaymentDescription || null;
		this.negativeAmortization = _.parseNumber(options.negativeAmortization);
		this.partialPayments = _.parseNumber(options.partialPayments, 0);
		this.hasEscrowAccount = _.parseBool(options.hasEscrowAccount);
		this.doesNotHaveEscrowReason = _.parseBool(options.doesNotHaveEscrowReason);

		// Override
		this.escrowAmountOverride = _.parseNumber(options.escrowAmountOverride, null);

		this.escrowAmountDescription = options.escrowAmountDescription || null;
		// Add Override
		this.escrowAmountDescriptionOverride = options.escrowAmountDescriptionOverride || null;

		this.nonEscrowAmount = _.parseNumber(options.nonEscrowAmount, 0);
		this.nonEscrowAmountDescription = options.nonEscrowAmountDescription || null;

		// Override
		this.initialEscrowPaymentOverride = _.parseNumber(options.initialEscrowPaymentOverride, null);

		this.initialEscrowPaymentDescription = options.initialEscrowPaymentDescription || null;

		// Override
		this.monthlyEscrowPaymentOverride = _.parseNumber(options.monthlyEscrowPaymentOverride, null);

		this.monthlyEscrowPaymentDescription = options.monthlyEscrowPaymentDescription || null;
		this.noEscrowAmount = _.parseNumber(options.noEscrowAmount, 0);
		this.noEscrowAmountDescription = options.noEscrowAmountDescription || null;
		this.escrowWaiverFee = _.parseNumber(options.escrowWaiverFee, 0);
		this.escrowWaiverFeeDescription = options.escrowWaiverFeeDescription || null;
		this.hasInterestOnlyPayments = _.parseBool(options.hasInterestOnlyPayments);
		this.interestOnlyPaymentCount = _.parseNumber(options.interestOnlyPaymentCount, 0);
		this.hasOptionalPayments = _.parseBool(options.hasOptionalPayments);
		this.optionalPaymentCount = _.parseNumber(options.optionalPaymentCount, 0);
		this.hasStepPayments = _.parseBool(options.hasStepPayments);
		this.stepPaymentCount = _.parseNumber(options.stepPaymentCount, 0);
		this.hasSeasonalPayments = _.parseBool(options.hasSeasonalPayments);
		this.seasonalPaymentFromMonth = _.parseNumber(options.seasonalPaymentFromMonth, 0);
		this.seasonalPaymentThroughMonth = _.parseNumber(options.seasonalPaymentThroughMonth, 0);
		this.firstChangeMinAmount = _.parseNumber(options.firstChangeMinAmount, 0);
		this.firstChangeMaxAmount = _.parseNumber(options.firstChangeMaxAmount, 0);
		this.firstChangePaymentNumber = _.parseNumber(options.firstChangePaymentNumber, 0);
		this.subsequentChangesNumberOfYears = _.parseNumber(options.subsequentChangesNumberOfYears, 0);
		this.maximumPaymentAmount = _.parseNumber(options.maximumPaymentAmount, 0);
		this.maximumPaymentNumber = _.parseNumber(options.maximumPaymentNumber, 0);
		this.airIndexDescription = options.airIndexDescription || null;
		this.airIndexPercentage = _.parseNumber(options.airIndexPercentage, 0);

		// Add this override?
		this.airInitialInterestRateOverride = _.parseNumber(options.airInitialInterestRateOverride, null);

		this.airInitialInterestRate = _.parseNumber(options.airInitialInterestRate, 0);

		this.airMinInterestRate = _.parseNumber(options.airMinInterestRate, 0);
		this.airMaxInterestRate = _.parseNumber(options.airMaxInterestRate, 0);
		this.airFirstChangeMonthNumber = _.parseNumber(options.airFirstChangeMonthNumber, 0);
		this.airSubsequentChangesMonthNumber = _.parseNumber(options.airSubsequentChangesMonthNumber, 0);
		this.airFirstChangeLimitPct = _.parseNumber(options.airFirstChangeLimitPct, 0);
		this.airSubsequentChangeLimitPct = _.parseNumber(options.airSubsequentChangeLimitPct, 0);
		this.marginIndex = _.parseNumber(options.marginIndex, 0);
		this.airIndexName = options.airIndexName || null;

		// Computed
		this.hasAIR = _.parseBool(options.hasAIR);
		this.hasAP= _.parseBool(options.hasAP);
		this.escrowAmount = _.parseNumber(options.escrowAmount, 0);
		this.initialEscrowPayment = _.parseNumber(options.initialEscrowPayment, 0);
		this.monthlyEscrowPayment = _.parseNumber(options.monthlyEscrowPayment, 0);
		this.securityInterestText = options.securityInterestText || null;
		this.securityInterestTextOverride = options.securityInterestTextOverride || null;
	}


	get airInitialInterestRateValue() {
		if (!_.isNil(this.airInitialInterestRateOverride))
			return this.airInitialInterestRateOverride;
		else
			return this.airInitialInterestRate;
	}

	set airInitialInterestRateValue(val) {
		if (val === this.airInitialInterestRate) {
			this.airInitialInterestRateOverride = null;
		}
		else {
			this.airInitialInterestRateOverride = val;
		}
	}

	get isAirInitialRateInterestOverridden() {
		return !_.isNil(this.airInitialInterestRateOverride);
	}

	//escrowAmountOverride
	//escrowAmount
	get escrowAmountValue() {
		if (!_.isNil(this.escrowAmountOverride))
			return this.escrowAmountOverride;
		else
			return this.escrowAmount;
	}

	set escrowAmountValue(val) {
		if(val == null){
			this.escrowAmount = 0;
		}
		else{
			if (val === this.escrowAmount) {
				this.escrowAmountOverride = null;
			}
			else{
				this.escrowAmountOverride = val;
			}
		}
	}

	get isEscrowAmountOverridden() {
		return !_.isNil(this.escrowAmountOverride);
	}

	//EscrowOverrideText
	get escrowDescriptionValue() {
		if (!_.isNil(this.escrowAmountDescriptionOverride))
			return this.escrowAmountDescriptionOverride;
		else
			return this.escrowAmountDescription;
	}

	set escrowDescriptionValue(val) {
		if (val === this.escrowAmountDescription) {
			this.escrowAmountDescriptionOverride = null;
		}
		else {
			this.escrowAmountDescriptionOverride = val;
		}
	}

	get isEscrowDescriptionOverridden() {
		return !_.isNil(this.escrowAmountDescriptionOverride);
	}

	get initialEscrowPaymentValue() {
		if (!_.isNil(this.initialEscrowPaymentOverride))
			return this.initialEscrowPaymentOverride;
		else
			return this.initialEscrowPayment;
	}

	set initialEscrowPaymentValue(val) {
		if (val === this.initialEscrowPayment) {
			this.initialEscrowPaymentOverride = null;
		}
		else {
			this.initialEscrowPaymentOverride = val;
		}
	}

	get isInitialEscrowPaymentOverridden() {
		return !_.isNil(this.initialEscrowPaymentOverride);
	}

	//monthlyEscrowPaymentValue
	get monthlyEscrowPaymentValue() {
		if (!_.isNil(this.monthlyEscrowPaymentOverride))
			return this.monthlyEscrowPaymentOverride;
		else
			return this.monthlyEscrowPayment;
	}

	set monthlyEscrowPaymentValue(val) {
		if(val == null){
			this.monthlyEscrowPayment = 0;
		}
		else{
			if (val === this.monthlyEscrowPayment) {
				this.monthlyEscrowPaymentOverride = null;
			}
			else {
				this.monthlyEscrowPaymentOverride = val;
			}
		}
	}

	get isMonthlyEscrowPaymentOverridden() {
		return !_.isNil(this.monthlyEscrowPaymentOverride);
	}

	//SecurityInterestOverrideText
	get securityInterestTextValue() {
		if (!_.isNil(this.securityInterestTextOverride))
			return this.securityInterestTextOverride;
		else
			return this.securityInterestText;
	}

	set securityInterestTextValue(val) {
		if (val === this.securityInterestText) {
			this.securityInterestTextOverride = null;
		}
		else {
			this.securityInterestTextOverride = val;
		}
	}

	get isSecurityInterestTextOverridden() {
		return !_.isNil(this.securityInterestTextOverride);
	}
}

export class LoanCalculationModel {
	constructor(options) {
		options = options || {};
		this.loanCalculationSectionDataID = _.parseNumber(options.loanCalculationSectionDataID, 0);
		this.loanID = _.parseNumber(options.loanID, 0);
		this.totalPaymentAmount = _.parseNumber(options.totalPaymentAmount, 0);
		this.totalPaymentOverrideAmount = _.parseNumber(options.totalPaymentOverrideAmount, null);
		this.financeChargeAmount = _.parseNumber(options.financeChargeAmount, 0);
		this.financeChargeOverrideAmount = _.parseNumber(options.financeChargeOverrideAmount, null);
		this.financedAmount = _.parseNumber(options.financedAmount, 0);
		this.financedOverrideAmount = _.parseNumber(options.financedOverrideAmount, null);
		this.annualPercentageRate = _.parseNumber(options.annualPercentageRate, 0);
		this.annualPercentageRateOverride = _.parseNumber(options.annualPercentageRateOverride, null);
		this.totalInterestPercentage = _.parseNumber(options.totalInterestPercentage, 0);
		this.totalInterestPercentageOverride = _.parseNumber(options.totalInterestPercentageOverride, null);
		this.isLiabilityAfterForeclosure1 = _.parseBool(options.isLiabilityAfterForeclosure1);
		this.isLiabilityAfterForeclosure2 = _.parseBool(options.isLiabilityAfterForeclosure2);
	}

	get financedAmountValue() {
		if (!_.isNil(this.financedOverrideAmount))
			return this.financedOverrideAmount;
		else
			return this.financedAmount;
	}

	set financedAmountValue(val) {
		if (val === this.financedAmount) {
			this.financedOverrideAmount = null;
		}
		else {
			this.financedOverrideAmount = val;
		}
	}
}
export class SettlementOrderSummaryModel{
	constructor(options){
		options = options || {};
		this.settlementOrderSummaryID = _.parseNumber(options.settlementOrderSummaryID, 0);
		this.loanID = _.parseNumber(options.loanID,null);
		this.dateIssued = options.dateIssued;
		this.appraisedPropertyValue = _.parseNumber(options.appraisedPropertyValue,0);
		this.estimatedPropertyValue = _.parseNumber(options.estimatedPropertyValue,0);
		this.loanPurpose = _.parseNumber(options.loanPurpose,null);
		this.loanProduct = _.parseNumber(options.loanProduct, null);
		this.loanType = _.parseNumber(options.loanType, null);
		this.loanTypeOtherDescription = options.loanTypeOtherDescription;
		this.closingDate = options.closingDate || null;
		this.disbursementDate = options.disbursementDate || null;
		this.settlementAgentRolesId = _.parseNumber(options.settlementAgentRolesId,null);
        this.placeOfClosingCompanyId = options.placeOfClosingCompanyId || null;
        this.placeOfClosingCompanyName = options.placeOfClosingCompanyName || null;
		this.settlementAgentCompanyId = _.parseNumber(options.settlementAgentCompanyId,null);
		this.settlementAgentCompanyName = options.settlementAgentCompanyName;
		this.settlementAgentContactId = _.parseNumber(options.settlementAgentContactId, null);
		this.settlementAgentContactName = options.settlementAgentContactName;
		this.salesPrice = _.parseNumber(options.salesPrice, 0);
		this.loanTermPeriod = _.parseNumber(options.loanTermPeriod, 0);
		this.loanTerm = _.parseNumber(options.loanTerm, 0);
		this.loanMonths = _.parseNumber(options.loanMonths, 0);
		this.lenderName = options.lenderName;
		this.loanNumber = options.loanNumber;
		this.loanMicNumber = options.loanMicNumber;
		this.salePriceType = _.parseNumber(options.salePriceType, 0);
	}

	get settlementAgent() {
        return {
            companyID: this.settlementAgentCompanyId,
            companyName: this.settlementAgentCompanyName,
            contactID: this.settlementAgentContactId,
            contactName: this.settlementAgentContactName
        };
    }

    set settlementAgent(val) {
        this.settlementAgentCompanyId = _.get(val, "companyID", null);
        this.settlementAgentCompanyName = _.get(val, "companyName", null);
        this.settlementAgentContactId = _.get(val, "contactID", null);
        this.settlementAgentContactName = _.get(val, "contactName", null);
    }

	get hasSettlementAgentCompany(){
		return this.settlementAgentCompanyId > 0;
	}

	get placeOfClosingCompany() {
        return {
            companyID: this.placeOfClosingCompanyId,
            companyName: this.placeOfClosingCompanyName,
        };
    }

    set placeOfClosingCompany(val) {
        this.placeOfClosingCompanyId = _.get(val, "companyID", null);
        this.placeOfClosingCompanyName = _.get(val, "companyName", null);
	}

	//added this property so this object will work with the LoanTermLength component
	get term(){
		return this.loanTerm;
	}
	set term(val){
		this.loanTerm = val;
	}
}

export class RateEngineAlert {
	constructor(options) {
		options = options || {};
		this.orderRateCalculationID = _.parseNumber(options.orderRateCalculationID, 0);
		this.target = options.target || "";
		this.message = options.message || "";
		this.severity = options.severity || AlertSeverity.Warning;
	}

	get bsVariant() {
		switch(this.severity) {
			case AlertSeverity.Info: return "info";
			case AlertSeverity.Warning: return "warning";
			case AlertSeverity.Error: return "danger";
		}
		return "warning";
	}

	get rqVariant() {
		switch(this.severity) {
			case AlertSeverity.Info: return "info";
			case AlertSeverity.Warning: return "warning";
			case AlertSeverity.Error: return "error";
		}
		return "warning";
	}
}

export class HudLine {
	constructor(options) {
        options = options || {};
		this.hudLineID = options.hudLineID || null;
		this.description = options.description || "";
		this.pocWhom = options.pocWhom || "";
		this.paidAllForBorrower = _.parseBool(options.paidAllForBorrower, false);
		this.paidAllForSeller = _.parseBool(options.paidAllForSeller, false);
		this.misc_Number = _.parseNumber(options.misc_Number, 0);
		this.dateFrom = options.dateFrom || null; //
		this.dateTo = options.dateTo || null; //
		this.amountPOC = _.parseNumber(options.amountPOC, 0);
		this.borrowerAmount = _.parseNumber(options.borrowerAmount, 0);
		this.misc_Dollar = _.parseNumber(options.misc_Dollar, 0);
		this.paidForBorrower = _.parseNumber(options.paidForBorrower, 0);
		this.paidForBorrowerTotal = _.parseNumber(options.paidForBorrowerTotal, 0);
		this.paidForSeller = _.parseNumber(options.paidForSeller, 0);
		this.paidForSellerTotal = _.parseNumber(options.paidForSellerTotal, 0);
		this.sellerAmount = _.parseNumber(options.sellerAmount, 0);
		this.totalAmount = _.parseNumber(options.totalAmount, 0);
		this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
		this.line = _.parseNumber(options.line, 0);
		this.ordersID = _.parseNumber(options.ordersID, null);
		this.paidBySource = _.parseNumber(options.paidBySource, null);
		this.paidByCompanyID = _.parseNumber(options.paidByCompanyID, null);
		this.payeeCompanyID = _.parseNumber(options.payeeCompanyID, null);
		this.netFund = _.parseNumber(options.netFund, null);
		this.misc_Value = _.parseNumber(options.misc_Value, null);
		this.misc_Value2 = _.parseNumber(options.misc_Value2, null);
		this.lineType = _.parseNumber(options.lineType, 0);
		this.payoffID = _.parseNumber(options.payoffID, null);
		this.isDebitCredit = _.parseBool(options.isDebitCredit, false);
		this.gfeBucket = _.parseNumber(options.gfeBucket, null);
		this.hudMainID = _.parseNumber(options.hudMainID, null);
		this.payeeContactID = _.parseNumber(options.payeeContactID, null);
		this.overrideLine = _.parseNumber(options.overrideLine, null);
		this.borrowerTax = _.parseNumber(options.borrowerTax, 0);
		this.sellerTax = _.parseNumber(options.sellerTax, 0);
		this.isSettlementFee = _.parseBool(options.isSettlementFee, false);
		this.isPremium = _.parseBool(options.isPremium, false);
		this.paymentDue = _.parseNumber(options.paymentDue, null);
		this.isBreakoutRIConHUD = _.parseBool(options.isBreakoutRIConHUD, false);
		this.hudLinePolicyName = _.parseNumber(options.hudLinePolicyName, null);
		this.hasChangePayee = _.parseBool(options.hasChangePayee, false);
		this.coverageAmount1 = _.parseNumber(options.coverageAmount1, 0);
		this.coverageAmount2 = _.parseNumber(options.coverageAmount2, 0);
		this.disclosureText1 = options.disclosureText1 || null;
		this.govtChargeDeedAmount = _.parseNumber(options.govtChargeDeedAmount, 0);
		this.govtChargeMortgageAmount = _.parseNumber(options.govtChargeMortgageAmount, 0);
		this.govtChargeReleasesAmount = _.parseNumber(options.govtChargeReleasesAmount, 0);

		this.derivedAmount = _.parseNumber(options.derivedAmount, 0);
	}
}

export class PremiumLineItemModel {
	constructor(options) {
		options = options || {};
		this.isSelected = false;
		this.isSimultaneous = _.parseBool(options.isSimultaneous);
		this.applyOverride = _.parseBool(options.applyOverride);
		this.amount = _.parseNumber(options.amount, 0);
		this.total = _.parseNumber(options.total, 0);
		this.taxRate = _.parseNumber(options.taxRate, 0);
		this.taxAmount = _.parseNumber(options.taxAmount, 0);
		this.overrideUnderwriter = _.parseBool(options.overrideUnderwriter, false);
		this.buyerSellerAmountApplyOverride = _.parseBool(options.buyerSellerAmountApplyOverride, false);
		this.underwriterSplit = _.parseNumber(options.underwriterSplit, 0);
		this.underwriterSplitPercent = _.parseNumber(options.underwriterSplitPercent, 0);
		this.buyerPercent = _.parseNumber(options.buyerPercent, 0);
		this.buyerAmount = _.parseNumber(options.buyerAmount, 0);
		this.sellerPercent = _.parseNumber(options.sellerPercent, 0);
		this.sellerAmount = _.parseNumber(options.sellerAmount, 0);
		this.payeeCompanyID = options.payeeCompanyID || null;
		this.payeeCompanyName = options.payeeCompanyName || null;
		this.payeeContactID = options.payeeContactID || null;
		this.addtlRemitDate = options.addtlRemitDate || null;
		this.cdfLine = options.cdfLine || null;
		this.sectionID = options.sectionID || null;
		this.nonGfe = _.parseBool(options.nonGfe);
		this.pocAmount = _.parseNumber(options.pocAmount, 0);
		this.pocWhom = _.parseNumber(options.pocWhom, null);
		this.netFund = _.parseNumber(options.netFund, null);
	}
}

export const SsGridActions = {
	// Net Fund options
    NETFUND_NONE: {
		name: "netfund",
		eventName: "netfund",
		automation_id: "btn_netfund_none",
		key: 0,
        text: "None",
        tooltip: "Mark selected lines to Netfund None",
        allowMultiSelection: true
    },
    NETFUND_POSITIVE: {
		name: "netfund",
		eventName: "netfund",
        automation_id: "btn_netfund_positive",
        key: 1,
        text: "Positive (+)",
        tooltip: "Mark selected lines to Netfund Positive",
        allowMultiSelection: true
    },
    NETFUND_NEGATIVE: {
		name: "netfund",
		eventName: "netfund",
        automation_id: "btn_netfund_negative",
        key: 2,
        text: "Negative (-)",
        tooltip: "Mark selected lines to Netfund Negative",
        allowMultiSelection: true
	},
	// CDF Sections
	SECTION_A: {
		name: "moveSection",
		eventName: "moveSection",
		automation_id: "btn_moveSection_A",
		key: "A",
		text: "A - Loan Charges",
		allowMultiselection: true
	},
	SECTION_B: {
		name: "moveSection",
		eventName: "moveSection",
		automation_id: "btn_moveSection_B",
		key: "B",
		text: "B - Title Charges",
		allowMultiselection: true
	},
	SECTION_C: {
		name: "moveSection",
		eventName: "moveSection",
		automation_id: "btn_moveSection_C",
		key: "C",
		text: "C - Title Charges",
		allowMultiselection: true
	},
	SECTION_H: {
		name: "moveSection",
		eventName: "moveSection",
		automation_id: "btn_moveSection_H",
		key: "H",
		text: "H - Other Charges",
		allowMultiselection: true
	},
};