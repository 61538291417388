<template>
    <div class="rq-container py-3">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <fieldset :disabled="readOnly">
            <div class="row">
                <div :class="{ 'col col-12 form-group':true }">
                    <label for="txt_wire_number">EFER Number</label>
                    <div class="input-group">
                        <span class="input-group-text">EFER-</span>
                        <rqInputNumber
                            automation_id="txt_wire_number"
                            formatType="number"
                            defaultValue=""
                            :minValue="0"
                            :maxChars="15"
                            :commas="false"
                            allow-null
                            decimals="0"
                            input-group
                            no-prefix
                            v-model="item.checkNumber" />
                    </div>
                    <!-- <rq-validation-feedback>EFER Number is required</rq-validation-feedback> -->
                </div>
                <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.checkDate.$error }">
                    <label for="dtp_check_date">EFER Date</label>
                    <rqdx-date-box
                        id="dtp_check_date"
                        v-model="v$.item.checkDate.$model"
                    />
                    <rq-validation-feedback>EFER Date is required</rq-validation-feedback>
                </div>
                <div :class="{ 'col col-12 form-group form-required':true, 'has-error':v$.item.bankCompanyID.$error }">
                    <label for="dtp_wire_out_date">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_escrow_bank', id: 'drp_escrow_bank' }"
                        :items="escrowAccounts"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.item.bankCompanyID.$model"
                        :search-enabled="false"
                    />
                    <rq-validation-feedback>Escrow Account is required</rq-validation-feedback>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    const DEFAULT_ERROR_MESSAGE = "Please correct the highlighted errors on screen to continue.";
    export default {
        name: 'CheckWritingTransferCheckToEscrowAccount',
        components: { },
        setup: () => ({ v$: useVuelidate() }),
        props: {
            check: {type: Object, required: true}
        },
        data() {
            return {
                item: {},
                original: {},
                escrowAccounts: [],
                errorMessage: ""
            }
        },
        created() {
            this.original = _.clone(this.check);
            this.item = _.clone(_.set(this.check, 'bankCompanyID', null));
            this.escrowAccounts = _.filter(this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_ACCOUNTS, this.order.regionID), i => i.id != this.original.bankCompanyID);
        },
        computed: {
            ...mapState({
                order: state => state.orders.order,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowFileFundTransfer",
                    "AllowFinalizedReconModifications",
                    ]);
            },
        },
        validations() {
            var result = {
                item: {
                    bankCompanyID: {
                        required
                    },
                    checkDate: {
                        required
                    },
                }
            }
            return result;
        },
        methods: {
            save(){
                const self = this;
                self.v$.$touch();
                if (!self.localSecurity.AllowFileFundTransfer) { return Promise.reject({errorMessage: 'Access Denied.'}); }
                if (self.v$.$error) { return Promise.reject({errorMessage: DEFAULT_ERROR_MESSAGE}); }
                let changes = self.getAuditChanges(self.original, self.item);
                let apiPromise = self.$api.CheckWritingApi.saveCheckToEscrowAccount(self.item, changes);
                return self.$rqBusy.wait(apiPromise);
            },
        },
    }
</script>