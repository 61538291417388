export class CancelableItem {
	constructor(options){
		this.dataObjectExclude = ["originalData"];
		this.originalData = {};

		this.init(options);
		this.refreshOriginalData();
	}
	init(options) { /* override this function with subclass data initialization */ }
	get currentData() { return this.getCurrentData(); }
	getCurrentData() { return _.pickBy(this, (value,key) => { return !_.includes(this.dataObjectExclude, key); }); }
	refreshOriginalData() { this.originalData = this.getCurrentData(); }
	revertToOriginalData() { this.init(this.originalData); }
}

export class RecordingInformationItem extends CancelableItem {
	constructor(options) { super(options); }
	init(options) {
		let _options = _.cloneDeep(options || {});
		this.clientKey = _.uniqueId("recording-information-");
		this.company = _options.company || "";
		this.document = _options.document || "";
		this.hud = _options.hud || null;
		this.hudLine = _options.hudLine || null;
		this.ss = _options.ss || null;
		this.ssLine = _options.ssLine || null;
		this.recordingInformationID = _.parseNumber(_options.recordingInformationID, 0);
		this.ordersID = _.parseNumber(_options.ordersID, null);
		this.loanID = _.parseNumber(_options.loanID, null);
		this.recordingDocumentID = _.parseNumber(_options.recordingDocumentID, null);
		this.hudMainID = _.parseNumber(_options.hudMainID, null);
		this.recordingSection = _.parseNumber(_options.recordingSection, null);
		this.cssMainID = _.parseNumber(_options.cssMainID, null);
		this.cssLineID = _.parseNumber(_options.cssLineID, null);
		this.recordingFee = _.parseNumber(_options.recordingFee, 0);
		this.recordingDate = _options.recordingDate || null;
		this.amount = _.parseNumber(_options.amount, 0);
		this.notes = _options.notes || null;
		this.companyID = _.parseNumber(_options.companyID, null);
		this.company = _options.company;
		this.lineType = _.parseNumber(_options.lineType, null);
		this.lineTypeID = _.parseNumber(_options.lineTypeID, null);
		this.cdfMainID = _.parseNumber(_options.cdfMainID, null);
		this.cdfLineID = _.parseNumber(_options.cdfLineID, null);
		this.cdfRecordingItemizationID = _.parseNumber(_options.cdfRecordingItemizationID, null);
		this.contactID = null;
		this.contactName = null;
        this.recordingDocumentStatus = options?.recordingDocumentStatus || null;
        this.submittedDate = options?.submittedDate || null;
        this.recordingType = options?.recordingType || null;
        this.liber = options?.liber || null;
        this.volume = options?.volume || null;
        this.book = options?.book || null;
        this.page = options?.page || null;
        this.instrumentNumber = options?.instrumentNumber || null;
        this.documentType = options?.documentType || null;
        this.description = options?.description || "";
        this.rejectedDate = options?.rejectedDate || null;
        this.recordingRejectionID = _.parseNumber(_options.recordingRejectionID, null);
        this.rejectedClearedDate = options?.rejectedClearedDate || null;
        this.isDeleted = false;
        this.manualRecordingFeeCalculationID = _.parseNumber(_options.manualRecordingFeeCalculationID, null);
        this.recordingInformationOrder = _.parseNumber(_options.recordingInformationOrder, null);
        //this.rejectedReason = options?.rejectedReason || null;

		this.selected = _.parseBool(_options.selected);
		this.dataObjectExclude.push("selected");
	}

	get id() { return this.recordingInformationID; }
	get amounts() { return [this.amount, this.recordingFee]; }
    get feeDifference() { return this.amount > 0 && this.recordingFee > 0 ? this.amount - this.recordingFee : 0; }


    get companyData() {
        return { companyID: this.companyID, companyName: this.company };
    }

    set companyData(val) {
        this.companyID = _.get(val, "companyID", null);
        this.company = _.get(val, "companyName", null);
    }
}

const recordingStatus = {
    New: 1,
    AuditPassed: 2,
    AuditFailed: 3,
    RejectedByCounty: 4,
    RejectionCorrectionPath: 5,
    Recorded: 6,
    SentBackToCustomer: 7,
};
export class RecordingStatus {
    static get New() { return recordingStatus.New; }
    static get AuditPassed() { return recordingStatus.AuditPassed; }
    static get AuditFailed() { return recordingStatus.AuditFailed; }
    static get RejectedByCounty() { return recordingStatus.RejectedByCounty; }
    static get RejectionCorrectionPath() { return recordingStatus.RejectionCorrectionPath; }
    static get Recorded() { return recordingStatus.Recorded; }
    static get SentBackToCustomer() { return recordingStatus.SentBackToCustomer; }

    static get lookupItems() {
        return _.map(recordingStatus, val => ({ id: val, name: RecordingStatus.displayValue(val) }));
    }

    static displayValue(id) {
        switch (id) {
            case recordingStatus.AuditPassed: return "Audit Passed";
            case recordingStatus.AuditFailed: return "Audit Failed";
            case recordingStatus.RejectedByCounty: return "Rejected by County";
            case recordingStatus.RejectionCorrectionPath: return "Rejection Correction Path";
            case recordingStatus.Recorded: return "Recorded";
            case recordingStatus.SentBackToCustomer: return "Sent Back to Customer";
        }
        return "New";
    }
}