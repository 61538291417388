import { DocumentListFormat } from "@/shared/models/models";
import { StandardLanguageModel } from '../../documents/models';
import { AssignedLanguageListType } from './enums';
import { DateTimeHelper } from "@/shared/utilities";

export class AssignedLanguageSection{
    constructor(options, orderId=null, loanId=null){
        options = options || {};

        this.clientKey = _.uniqueId("assigned-language-section-");

        this.listType = Number(options.listType) === 0 || _.gt(options.listType, 0) ? Number(options.listType) : AssignedLanguageListType.CustomCategory;
        this.standardLanguageSectionID = _.parseNumber(options.standardLanguageSectionID, null);
        this.standardLanguageCategoryID = _.parseNumber(options.standardLanguageCategoryID, null);
        this.name = options.name || "";
        this.description = options.description || "";
        this.titleProdCustomTabID = options.titleProdCustomTabID || null;
        this.sectionLabel = options.sectionLabel || "NO LABEL PROVIDED";
        this.appliesToTypes = options.appliesToTypes || [];

        this.commitmentPolicyHeaderID = _.parseNumber(options.commitmentPolicyHeaderID, 0);
        this.commitmentPolicyDetailID = _.parseNumber(options.commitmentPolicyDetailID, 0);

        this.displayOrder = options.displayOrder || 1;
        this.display = _.parseBool(options.display);

        let optItems = options.items || [];
        this.items = _.map(optItems, item=>new AssignedLanguageViewModel(item, orderId, loanId));
        //this.originalItems = _.map(optItems, item=>new AssignedLanguage(item));

        this.isBusy = _.parseBool(options.isBusy);
        this.checkState = _.parseBool(options.checkState);
        this.sectionExpanded = _.parseBool(options.sectionExpanded, true);

        this.categories = options.categories || [];
    }

    get isCustomTabSection() {
        return this.listType === AssignedLanguageListType.CustomCategory && !_.isNil(this.standardLanguageSectionID);
    }

    get appliesToEditable(){
        return this.listType === AssignedLanguageListType.Exceptions || this.listType === AssignedLanguageListType.RestrictiveCovenants;
    }
}

export class AssignedLanguage {
    constructor(options) {
        options = options || {};
        this.commitmentPolicyHeaderID = _.parseNumber(options.commitmentPolicyHeaderID, 0);
        this.commitmentPolicyDetailID = _.parseNumber(options.commitmentPolicyDetailID, 0);
        this.assignedLanguageID = _.parseNumber(options.assignedLanguageID, 0) || -_.parseNumber(_.uniqueId());
        this.code = options.code || null;
        this.description = options.description || null;
        this.level = options.level || null;
        this.parentID = options.parentID || null;
        this.standardLanguageID = options.standardLanguageID || null;
        this.standardLanguagePackageID = options.standardLanguagePackageID || null;
        this.standardLanguagePackageDetailID = options.standardLanguagePackageDetailID || null;
        this.listType = isNaN(options.listType) ? 0 : Number(options.listType);
        this.appliesToType = _.gt(options.appliesToType, 0) ? options.appliesToType : 0;
        this.ordinal = options.ordinal || null;
        this.rtfText = options.rtfText || null;
        this.orderWorkflowTaskID = options.orderWorkflowTaskID || null;
        this.bulletCharacter = options.bulletCharacter || null;
        this.bulletSize = options.bulletSize || 0;
        this.charBeforeNumber = options.charBeforeNumber || null;
        this.charAfterNumber = options.charAfterNumber || null;
        this.firstNumber = !isNaN(options.firstNumber) ? options.firstNumber : null;
        this.formatCharacter = options.formatCharacter || null;
        this.hangingIndent = !isNaN(options.hangingIndent) ? options.hangingIndent : null;
        this.leftIndent = !isNaN(options.leftIndent) ? options.leftIndent : null;
        this.numberFormat = options.numberFormat || null;
        this.restartNumbering = _.isBoolean(options.restartNumbering) ? options.restartNumbering : false;
        this.textAfterNumber = options.textAfterNumber || null;
        this.textBeforeNumber = options.textBeforeNumber || null;
        this.textControlListType = options.textControlListType || null;
        this.overrideListFormat = _.isBoolean(options.overrideListFormat) ? options.overrideListFormat : false;
        this.overrideOption = options.overrideOption || null;
        this.standardLanguageSectionID = options.standardLanguageSectionID || null;
        this.overrideLinesBetweenClauses = _.isBoolean(options.overrideLinesBetweenClauses) ? options.overrideLinesBetweenClauses : false;
        this.linesBetweenClauses = _.gt(options.linesBetweenClauses, 0) ? options.linesBetweenClauses : 0;

        this.ordersID = options.ordersID || 0;
        this.loanID = options.loanID || 0;
        this.listPath = options.listPath || "";
        this.htmlText = options.htmlText || "";
        this.notes = options.notes || "";
        this.promptValues = _.isNil(options.promptValues) || !_.isObject(options.promptValues) ? {} : _.mapKeys(options.promptValues, (v,k) => _.startsWith(k, "@") ? k : `@${k}:`);
        this.refreshListStructure = _.isBoolean(options.refreshListStructure) ? options.refreshListStructure : false;
        this.applyPolicyOverride = _.isBoolean(options.applyPolicyOverride) ? options.applyPolicyOverride : false;

        this.isDeleted = _.parseBool(options.isDeleted);
        this.intentionallyDeleted = _.parseBool(options.intentionallyDeleted, false);
    }

    removeListFormatOverride(){
        this.setListFormat();
        this.refreshListStructure = true;
    }

    setListFormat(documentListStyle=null){
        documentListStyle = documentListStyle || DocumentListFormat.defaults;
        _.assignIn(this, documentListStyle);
    }

    getListFormat(){
        return new DocumentListFormat(this);
    }

    toDataObject() { return _.toPlainObject(this); }

    get hasParent() { return _.parseNumber(this.parentID, 0) > 0; }

    get contentLoaded() { return _.isEmpty(this.rtfText) || !_.isEmpty(this.htmlText); }

    get hasInlinePolicyExclusions() {
        if(_.isEmpty(this.htmlText)) return false;
        return _.includes(this.htmlText, "[O]")
            || _.includes(this.htmlText, "[M]")
            || _.includes(this.htmlText, "[N]");
    }

    static fromStandardLanguage(standardLanguage){
        //console.log("Mapping StandardLanguage to AssignedLanguage: ", standardLanguage);
        return new AssignedLanguage({
            assignedLanguageID: 0,
            standardLanguageID: _.parseNumber(standardLanguage.standardLanguageID, 0),
            code: standardLanguage.code,
            description: standardLanguage.description,
            htmlText: standardLanguage.html,
            rtfText: standardLanguage.text,
            promptValues: standardLanguage.promptValues,
            linesBetweenClauses: standardLanguage.linesBetweenClauses,
            notes: standardLanguage.notes,

            /* StandardLanguagePackage properties*/
            standardLanguagePackageID: standardLanguage.standardLanguagePackageID,
            standardLanguagePackageDetailID: standardLanguage.standardLanguagePackageDetailID,
            parentID: standardLanguage.parentID,
            ordinal: standardLanguage.ordinal,
            level: standardLanguage.level,
            bulletCharacter: standardLanguage.bulletCharacter,
            bulletSize: standardLanguage.bulletSize,
            charBeforeNumber: standardLanguage.charBeforeNumber,
            charAfterNumber: standardLanguage.charAfterNumber,
            firstNumber: standardLanguage.firstNumber,
            formatCharacter: standardLanguage.formatCharacter,
            hangingIndent: standardLanguage.hangingIndent,
            leftIndent: standardLanguage.leftIndent,
            numberFormat: standardLanguage.numberFormat,
            restartNumbering: standardLanguage.restartNumbering,
            textAfterNumber: standardLanguage.textAfterNumber,
            textBeforeNumber: standardLanguage.textBeforeNumber,
            textControlListType: standardLanguage.textControlListType,
            overrideListFormat: standardLanguage.overrideListFormat,
            overrideOption: standardLanguage.overrideOption,
            standardLanguageSectionID: standardLanguage.standardLanguageSectionID,
            overrideLinesBetweenClauses: standardLanguage.overrideLinesBetweenClauses
        });
    }

    static userDefined(html, rtf){
        return new AssignedLanguage({ assignedLanguageID: 0, standardLanguageID: 1, code: "Manual", description: "User Defined", htmlText: html || "", rtfText: rtf || "" });
    }

    static isEligibleForPolicyExclusions(listType, parentId=null) {
        return _.includes([
                AssignedLanguageListType.Exceptions,
                AssignedLanguageListType.RestrictiveCovenants
            ], listType)
            && _.isNil(parentId);
    }
}

export class AssignedLanguageViewModel extends AssignedLanguage {
    constructor(options, orderId=null, loanId=null) {
        options = options || {};

        if(orderId) options.ordersID = orderId;
        if(loanId) options.loanID = loanId;

        super(options);

        this.childCount = options.childCount || null;
        this.siblingCount = options.siblingCount || null;
        this.clientKey = options.clientKey || `assigned_language_item_${_.uniqueId()}`;
        this.actionEvent = `tp:${this.clientKey}:assigned_language_action`;
        this.globalIndex = options.globalIndex || 0;
        this.content = options.content || '';
        this.isEditing = _.parseBool(options.isEditing);
        this.isCollapsed = _.parseBool(options.isCollapsed);
        this.isCollapsible = _.parseBool(options.isCollapsible, true);
        this.isSelected = _.parseBool(options.isSelected);
        this.displayHeight = options.displayHeight || 75;
        this.originalValues = _.toPlainObject(options);

        this.children = options.children || [];

        this.listPath = options.listPath || "";

        this.scrollIntoView = _.parseBool(options.scrollIntoView);
    }

    destroy() {
        if(_.isNil(this.blob)) return;
        URL.revokeObjectURL(this.blob);
    }

    get editorFieldInfo() {
        return {
            key: this.clientKey,
            label: `${this.code} - ${this.description}`,
            value: this.htmlText,
            isRtf: false,
            changeEvent: `tp-field-change:${this.clientKey}`
        };
    }

    get appliesToEditable() { return AssignedLanguage.isEligibleForPolicyExclusions(this.listType, this.parentID); }

    get isDirty() { return this.originalValues.htmlText !== this.htmlText; }
    set isDirty(val) {
        if(val) return;
        this.originalValues.htmlText = this.htmlText;
    }

    matchesFilter(filterText) {
        return _.includes(this.code, filterText) || _.includes(this.description, filterText) || _.includes(this.htmlText, filterText);
    }

    data() { return new AssignedLanguage(this); }
}

export class AssignedLanguagePackage {
    constructor(options) {
        options = options || {};
        this.standardLanguagePackageID = _.parseNumber(options.standardLanguagePackageID, 0);
        this.ordersID = options.ordersID || null;
        this.loanID = options.loanID || null;
        this.commitmentPolicyHeaderID = options.commitmentPolicyHeaderID || null;
        this.commitmentPolicyDetailID = options.commitmentPolicyDetailID || null;
        this.listType = options.listType || null;
        this.parentID = options.parentID || null;
        this.level = options.level || null;
        this.ordinal = options.ordinal || null;
        this.refreshListStructure = _.isBoolean(options.refreshListStructure) ? options.refreshListStructure : false;
        this.applyPolicyOverride = _.isBoolean(options.applyPolicyOverride) ? options.applyPolicyOverride : false;
        this.packageItems = options.packageItems || [];
    }
}

export class StandardLanguageSection {
    constructor(options) {
        options = options || {};
        this.listType = options.listType || AssignedLanguageListType.CustomCategory;
        this.standardLanguageSectionID = _.parseNumber(options.standardLanguageSectionID, 0);
        this.name = options.name || "";
        this.description = options.description || "";
        this.titleProdCustomTabID = options.titleProdCustomTabID || null;
        this.sectionLabel = options.sectionLabel || "";
        this.displayOrder = options.displayOrder || 1;
        this.display = _.isBoolean(options.display) ? options.display : false;

        this.categories = options.categories || [];
    }
}

export class CommitmentPolicyHeaderDto {
    constructor(options) {
        options = options || {};

		this.commitmentPolicyHeaderID = _.parseNumber(options.commitmentPolicyHeaderID, 0);
		this.ordersID = _.parseNumber(options.ordersID, null);
		this.commitmentNumber = options.commitmentNumber || "";
		this.effectiveDate = options.effectiveDate || null;
		this.issueDate = options.issueDate || null;
		this.binderDate = options.binderDate || null;
		this.binderNumber = options.binderNumber || "";
		this.ownerInsured = options.ownerInsured || "";
		this.ownerInsuredText = options.ownerInsuredText || "";
		this.ownerInsuredOverride = _.parseBool(options.ownerInsuredOverride);
		this.currentHolders = options.currentHolders || "";
		this.currentHoldersText = options.currentHoldersText || "";
		this.currentHoldersOverride = _.parseBool(options.currentHoldersOverride);
        this.currentHoldersManualOverride = _.parseBool(options.currentHoldersManualOverride);
		this.interestInLand = options.interestInLand || "";
		this.interestInLandText = options.interestInLandText || "";
		this.interestInLandOverride = _.parseBool(options.interestInLandOverride);
		this.countyID = _.parseNumber(options.countyID, null);
		this.propertyTypeID = _.parseNumber(options.propertyTypeID, null);
		this.signatory = _.parseNumber(options.signatory, null);
		this.standbyYear = _.parseNumber(options.standbyYear, null);
		this.exceptions = options.interestInLand || "";
		this.ownerPolicyNumber = options.ownerPolicyNumber || "";
		this.ownerPolicyNumberPoolID = _.parseNumber(options.ownerPolicyNumberPoolID, null);
		this.ownerPolicyDate = options.ownerPolicyDate || null;
		this.ownerRemitDate = options.ownerRemitDate || null;
		this.ownerRemitDateLock = _.parseBool(options.ownerRemitDateLock);
		this.ownerScheduleB = options.ownerScheduleB || "";
		this.ownerScheduleBOverride = _.parseBool(options.ownerScheduleBOverride);
		this.ownerStrikeException2 = _.parseNumber(options.ownerStrikeException2, null);
		this.strikeO1 = _.parseBool(options.strikeO1);
		this.strikeO2 = _.parseBool(options.strikeO2);
		this.strikeO3 = _.parseBool(options.strikeO3);
		this.strikeO4 = _.parseBool(options.strikeO4);
		this.strikeO5 = _.parseBool(options.strikeO5);
		this.strikeO6 = _.parseBool(options.strikeO6);
		this.strikeO7 = _.parseBool(options.strikeO7);
		this.strikeO8 = _.parseBool(options.strikeO8);
		this.strikeO9 = _.parseBool(options.strikeO9);
		this.ownerSubmitDate = options.ownerSubmitDate || null;
        this.policyFormTypeID = _.parseNumber(options.policyFormTypeID, 0);
        this.salesPrice = _.parseNumber(options.salesPrice, 0);
        this.ownerPolicyLiability = _.parseNumber(options.ownerPolicyLiability, 0);
        this.syncDateTimeOffsetValues();
    }

    get ownerInsuredAmount() { return this.ownerPolicyLiability > 0 ? this.ownerPolicyLiability : this.salesPrice }
    set ownerInsuredAmount(val) {
        if(_.isNil(val)) return;
        if(val === this.salesPrice) this.ownerPolicyLiability = 0;
        else this.ownerPolicyLiability = val;
    }

    syncDateTimeOffsetValues() {
        this.ownerPolicyDateString = this.ownerPolicyDate;
        this.binderDateString = this.binderDate;
        this.effectiveDateString = this.effectiveDate;
        this.issueDateString = this.issueDate;
    }
}

export class CommitmentPolicyDetailDto {
    constructor(options) {
        options = options || {};

		this.commitmentPolicyDetailID = _.parseNumber(options.commitmentPolicyDetailID, 0);
		this.commitmentPolicyHeaderID = _.parseNumber(options.commitmentPolicyHeaderID, null);
		this.loanID = _.parseNumber(options.loanID, 0);
		this.remitDate = options.remitDate || null;
		this.remitDateLock = _.parseBool(options.remitDateLock);
		this.scheduleBItem2 = _.parseNumber(options.scheduleBItem2, 0);
		this.binderStrike1A = _.parseBool(options.binderStrike1A);
		this.binderB13Strike = _.parseBool(options.binderB13Strike);
		this.binderB13TaxLanguage = _.parseBool(options.binderB13TaxLanguage);
		this.mortgageInsured = options.mortgageInsured || "";
		this.mortgageInsuredText = options.mortgageInsuredText || "";
		this.mortgageInsuredOverride = _.parseBool(options.mortgageInsuredOverride);
		this.mortgageBorrower = options.mortgageBorrower || "";
		this.mortgageBorrowerText = options.mortgageBorrowerText || "";
		this.mortgageBorrowerOverride = _.parseBool(options.mortgageBorrowerOverride);
		this.constructionInsured = options.constructionInsured || "";
		this.constructionInsuredText = options.constructionInsuredText || "";
		this.constructionInsuredOverride = _.parseBool(options.constructionInsuredOverride);
		this.constructionBorrower = options.constructionBorrower || "";
		this.constructionBorrowerText = options.constructionBorrowerText || "";
		this.constructionBorrowerOverride = _.parseBool(options.constructionBorrowerOverride);
        this.lienDescription = options.lienDescription || "";
        this.lienDescriptionText = options.lienDescriptionText || "";
        this.restrictiveCovenants = options.restrictiveCovenants || "";
        this.subordinateLiens = options.subordinateLiens || "";
        this.requirements = options.requirements || "";
        this.officers = options.officers || "";
        this.mortgagePolicyNumber = options.mortgagePolicyNumber || "";
		this.mortgagePolicyNumberPoolID = _.parseNumber(options.mortgagePolicyNumberPoolID, null);
        this.mortgagePolicyDate = options.mortgagePolicyDate || null;
		this.mortgageScheduleB = options.mortgageScheduleB || "";
		this.mortgageScheduleBOverride = _.parseBool(options.mortgageScheduleBOverride);
		this.mortgageScheduleB2 = options.mortgageScheduleB2 || "";
		this.mortgageScheduleB2Override = _.parseBool(options.mortgageScheduleB2Override);
		this.insuredMortgage = options.insuredMortgage || "";
		this.insuredMortgageText = options.insuredMortgageText || "";
		this.insuredMortgageOverride = _.parseBool(options.insuredMortgageOverride);
		this.titleVestedIn = options.titleVestedIn || "";
		this.titleVestedInText = options.titleVestedInText || "";
		this.titleVestedInOverride = _.parseBool(options.titleVestedInOverride);
		this.legalDescription = options.legalDescription || "";
		this.legalDescriptionHtml = options.legalDescriptionHtml || "";
		this.legalDescriptionOverride = _.parseBool(options.legalDescriptionOverride);
		this.strikeMortgageB2 = _.parseBool(options.strikeMortgageB2);
        this.strikeEOMB3 = _.parseBool(options.strikeEOMB3, null);
        this.addStandyB3 = _.parseBool(options.addStandyB3);
        this.strikeMortgageB4 = _.parseBool(options.strikeMortgageB4);
		this.noSecondLien = _.parseBool(options.noSecondLien);
        this.strikeMortgageB42 = _.parseBool(options.strikeMortgageB42);
        this.commStrike1A = _.parseBool(options.commStrike1A);
        this.strikeMortgagee1 = _.parseBool(options.strikeMortgagee1);
        this.strikeMortgagee2 = _.parseBool(options.strikeMortgagee2);
        this.strikeMortgagee3 = _.parseBool(options.strikeMortgagee3);
        this.strikeMortgagee4 = _.parseBool(options.strikeMortgagee4);
        this.strikeMortgagee5 = _.parseBool(options.strikeMortgagee5);
        this.strikeMortgagee6 = _.parseBool(options.strikeMortgagee6);
        this.strikeMortgagee7 = _.parseBool(options.strikeMortgagee7);
        this.strikeMortgagee8 = _.parseBool(options.strikeMortgagee8);
        this.strikeMortgagee9 = _.parseBool(options.strikeMortgagee9);
        this.submitDate = options.submitDate || null;
		this.exceptionsOneTimePullDone = _.parseBool(options.exceptionsOneTimePullDone);
        this.requirementsOneTimePullDone = _.parseBool(options.requirementsOneTimePullDone);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.policyFormTypeID = _.parseNumber(options.policyFormTypeID, 0);
        this.loanAmount = _.parseNumber(options.loanAmount, 0);
        this.loanPolicyLiability = _.parseNumber(options.loanPolicyLiability, 0);

        this.syncDateTimeOffsetValues();
    }

    get mortgageInsuredAmount() { return this.loanPolicyLiability > 0 ? this.loanPolicyLiability : this.loanAmount }
    set mortgageInsuredAmount(val) {
        if(_.isNil(val)) return;
        if(val === this.salesPrice) this.loanPolicyLiability = 0;
        else this.loanPolicyLiability = val;
    }


    syncDateTimeOffsetValues() {
        this.mortgagePolicyDateString = this.mortgagePolicyDate;
    }
}

export class PolicyNumberDto {
    constructor(options) {
        options = options || {};

		this.poolId = _.parseNumber(options.poolId, null);
		this.pool = options.pool || "";
		this.prefix = options.prefix || "";
        this.suffix = options.suffix || "";
		this.numChars = _.parseNumber(options.numChars, 0);
		this.minPolicyNumber = options.minPolicyNumber || "";
		this.maxPolicyNumber = options.maxPolicyNumber || "";
		this.startNumber = _.parseNumber(options.startNumber, 0);
		this.endNumber = _.parseNumber(options.endNumber, 0);
    }
}

export class CommitmentDataDto {
    constructor(options) {
        options = options || {};
        this.header = new CommitmentPolicyHeaderDto(options.header);
        this.detail = new CommitmentPolicyDetailDto(options.detail);
        this.syncDateTimeOffsetValues();
    }

    syncDateTimeOffsetValues() {
        this.header.syncDateTimeOffsetValues();
        this.detail.syncDateTimeOffsetValues();
    }
}

export class PolicyListDto {
    constructor(options) {
        options = options || {};
		this.policyLabel = options.policyLabel || null;
		this.policyNumber = options.policyNumber || null;
        this.policyDate = options.policyDate || null;
        this.policyTime = options.policyDate || null;
        this.policyTz = options.policyDate || null;
        this.remitDate = options.remitDate || null;
        this.submitDate = options.submitDate || null;
        this.titleVestedIn = options.titleVestedIn || null;
        this.policyFormTypeID = _.parseNumber(options.policyFormTypeID, null);
        this.loanID = _.parseNumber(options.loanID, null);
        this.loanOrder = _.parseNumber(options.loanOrder, -1);
    }
}