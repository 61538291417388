export class ContactCardModel{
    constructor(options){
        this.clientKey = _.uniqueId("settlement-statement-contact-");
        this.settlementStatementContactID = _.parseNumber(options.settlementStatementContactID, 0);
        this.loanID = _.parseNumber(options.loanID, 0);
		this.rolesID = _.parseNumber(options.rolesID, null);
        this.roleTypeID = _.parseNumber(options.roleTypeID, 0);
        this.companyID = _.parseNumber(options.companyID, 0);
        this.contactID = _.parseNumber(options.contactID, null);
        this.regionID = _.parseNumber(options.regionID, null);
        this.isBuyerRealtor = _.parseBool(options.isBuyerRealtor, false);
        this.roleTypeName = options.roleTypeName;
        this.companyName = options.companyName;
        this.contactName = options.contactName;
        this.address1 = options.address1;
        this.address2 = options.address2;
        this.city = options.city;
        this.state = options.state;
        this.zip = options.zip;
        this.email = options.email;
        this.businessPhone = options.businessPhone;
        this.businessTollFree = options.businessTollFree;
        this.phone = options.phone;
        this.phoneExtension = options.phoneExtension;
        this.cellPhone = options.cellPhone;
        this.directPhone = options.directPhone;
        this.homePhone = options.homePhone;
        this.settlementStatementContactOverrideID = _.parseNumber(options.settlementStatementContactOverrideID, null);
        this.nmlsid = options.nmlsid;
        this.stateLicenseID = options.stateLicenseID;
        this.contactNMLSID = options.contactNMLSID;
        this.contactStateLicenseID = options.contactStateLicenseID;
        this.override = null;


        if(!_.isNil(options.override))
            this.override = new SettlementStatementContactOverrideModel(options.override);

        this.isDeleted = false;
    }

    get title(){
        if(this.roleTypeID !== 10) return this.roleTypeName;
        let suffix = this.isBuyerRealtor ? "(B)" : "(S)";
        return `${this.roleTypeName} ${suffix}`;
    }

    get address(){
        let addressParts = [this.address1, this.address2];
        let address = _.joinParts(addressParts, " ");
        let cityStateParts = [this.city, this.state];
        let cityState = _.joinParts(cityStateParts, ", ");
        let addressAndCityStateParts = [address, cityState];
        let addressAndCityState = _.joinParts(addressAndCityStateParts, ", ");
        let fullAddressParts = [addressAndCityState, this.zip];
        let fullAddress = _.joinParts(fullAddressParts, " ");
        return fullAddress;
    }

    get primaryPhone(){
        if(this.phone){
            return this.phoneExtension ? `${this.phone} Ext. ${this.phoneExtension}` : this.phone;
        }
        if(this.directPhone) return this.directPhone;
        if(this.cellPhone) return this.cellPhone;
        if(this.homePhone) return this.homePhone;
        if(this.businessPhone) return this.businessPhone;
        if(this.businessTollFree) return this.businessTollFree;
        return "";
    }
}

export class SettlementStatementContactOverrideModel{
    constructor(options){
        this.settlementStatementContactOverrideID = _.parseNumber(options.settlementStatementContactOverrideID, 0);
        this.companyID = _.parseNumber(options.companyID, null);
        this.contactID = _.parseNumber(options.contactID, null);
        this.description = options.description;
        this.title = options.title;
        this.name = options.name;
        this.address = options.address;
        this.nmlsid = options.nmlsid;
        this.licenseID = options.licenseID;
        this.contact = options.contact;
        this.contactNMLSID = options.contactNMLSID;
        this.contactLicenseID = options.contactLicenseID;
        this.email = options.email;
        this.phone = options.phone;
    }
}