import { ViewActions } from "@/router/actions/order-entry";
import BasicRouteMain from "../shared/BasicRouteMain.vue";
import AuditLogList from "../../audit-log/views/AuditLogList.vue";

export default {
    path: "audit-log",
    component: BasicRouteMain,
    meta: {
        label: "Audit Log",
        include: false
    },
    children: [{
        name: "file:audit-log",
        path: "",
        component: AuditLogList,
        props: route => ({
            orderId: _.getNumber(route, "params.orderId", 0)
        }),
        meta: {
            label: "Audit Log",
            actions: ViewActions.BASIC,
            skipSaveOnLeave: true,
            featureFlag: "auditlog"
        }
    }]
};