<template>
    <router-view
        @company-update-success="goToContactCards"
        @contact-cancel-add="goToContactCards"
        @view-settlement-contacts="goToContactCards"
        @view-company-detail="goToCompanyDetail"
        @contact-update-success="goToContactCards"
        @view-contact-detail="goToContactDetail">
    </router-view>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        name: "SettlementStatementContacts",
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId
            })
        },
        beforeRouteUpdate(to, from, next) {
            _.invoke(this, "$parent.scrollToTop");
            next();
        },
        methods: {
            goToContactCards(args) { this.goToRoute("settlement:contacts:contact-cards"); },
            goToCompanyDetail(args) { this.goToRoute("settlement:contacts:company", args); },
            goToContactDetail(args) { this.goToRoute("settlement:contacts:contact", args); },
            goToRoute(name, args) { this.$router.push({ name, params: { orderId: this.orderId, ...args } }); }
        }
    };
</script>