<template>
    <div class="card rq-contact-card">
        <div class="card-header" :class="{'card-header-warning': showWarning}" @click="onCardClick">
            <slot name="card-header">
                <span v-if="showWarning" class="icon"><FontAwesomeIcon icon="fas fa-exclamation-triangle" /></span>
                <span automation_id="lbl_contact_role" class="card-title">{{contact.roleDisplayValue}}</span>
            </slot>
        </div>
        <div class="card-body" @click="onCardClick">
            <div class="contact-avatar">
                <span v-if="contact.initial" class="contact-initial">{{contact.initial}}</span>
                <FontAwesomeIcon v-else icon="fas fa-building" />
            </div>
            <div class="contact-names">
                <div v-if="contact.companyName" automation_id="lbl_company_name" class="company-name" :title="contact.companyName" v-shave="{ height: 45, spaces: false }" >{{contact.companyName}}</div>
                <div v-if="contact.contactName" automation_id="lbl_contact_name" class="contact-name" :title="contact.contactName" v-shave="{ height: 45, spaces: false }" >{{contact.contactName}}</div>
                <div v-if="contact.address && !contact.forwardingAddress" automation_id="lbl_contact_address" class="contact-address" :title="contact.address" v-shave="{ height: 65, spaces: false }" >{{contact.address}}</div>
                <div v-if="contact.forwardingAddress" automation_id="lbl_contact_forwarding_address" class="contact-forwarding-address" :title="contact.forwardingAddress" v-shave="{ height: 65, spaces: false }" >{{contact.forwardingAddress}}</div>
            </div>
            <div class="contact-detail">
                <div v-if="contact.email" automation_id="lbl_contact_email" class="contact-email text-truncate" :title="contact.email"> <a :id="elementIDs.contactEmailLink" :href="`mailto:${contact.email}`">{{contact.email}}</a></div>
                <div v-if="contact.primaryPhone" automation_id="lbl_contact_phone" class="contact-phone" >{{contact.primaryPhone}} </div>
            </div>
        </div>
        <div class="card-footer">
            <slot name="card-footer">
                <div class="row">
                    <div v-rq-tooltip.hover.top="{ html: true, title: deleteTooltip }" class="col">
                        <b-btn v-if="showDelete"
                            :automation_id="actionAutomationId.delete"
                            variant="icon"
                            class="w-100"
                            :disabled="deleteDisabled"
                            @click="onDeleteClick">
                            <FontAwesomeIcon :icon="actionIcon.delete" />
                        </b-btn>
                    </div>

                    <template v-if="contact.isBuyerSeller">
                        <div class="col col-empty"></div>
                        <div v-rq-tooltip.html.hover.top="{ title: `View/Edit ${contact.role}` }" class="col">
                            <b-btn
                                :automation_id="actionAutomationId.buyerSeller"
                                variant="icon"
                                class="w-100"
                                @click="onBuyerSellerDetailClick">
                                <FontAwesomeIcon :icon="actionIcon.buyerSeller" />
                            </b-btn>
                        </div>
                    </template>
                    <template v-else>
                        <div v-rq-tooltip.html.hover.top="{ title: companyActionTooltip }" class="col">
                            <b-btn v-if="showCompanyAction"
                                :automation_id="actionAutomationId.company"
                                variant="icon"
                                class="w-100"
                                :disabled="companyActionDisabled"
                                @click="onCompanyActionClick">
                                <FontAwesomeIcon :icon="actionIcon.company" />
                            </b-btn>
                        </div>
                        <div v-rq-tooltip.html.hover.top="{ title: contactActionTooltip }" class="col">
                            <b-btn v-if="showContactAction"
                                :automation_id="actionAutomationId.contact"
                                variant="icon"
                                class="w-100"
                                :disabled="contactActionDisabled"
                                @click="onContactActionClick">
                                <FontAwesomeIcon :icon="actionIcon.contact" />
                            </b-btn>
                        </div>
                    </template>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ContactCardDto } from "../models";

    export default {
        name: "ContactCard",
        props: {
            contact: { type: ContactCardDto, default: () => ({}) },
            showAssign: { type: Boolean, default: false },
            showDelete: { type: Boolean, default: false },
            showWarning: { type: Boolean, default: false }
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                isOrderLocked: state => state.orders.orderSummary.isLocked,
                contacts: state => state.orders.contacts.contacts
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            readOnly() { return this.isOrderLocked || !this.userAccess.full; },
            roleTypeID() { return this.contact.roleTypeID; },
            roleObject() { return _.find(this.lookupHelpers.getRoles(), r => _.parseNumber(r.id) === _.parseNumber(this.roleTypeID)); },
            roleItems() { return _.filter(this.contacts, c => c.roleTypeID === this.roleTypeID); },
            canDelete() { return this.contact.isBuyerSeller || (!_.isNil(this.roleObject) && _.parseBool(this.roleObject.canDelete)); },
            canAssign() { return !this.readOnly && !_.isNil(this.roleObject) && _.parseBool(this.roleObject.canDelete); },
            showCompanyAction() { return this.contact.companyID || this.showAssign; },
            showContactAction() { return this.contact.companyID && this.showAssign; },
            deleteDisabled() { return !this.canDelete || this.readOnly || this.contact.hasOutstandingChecks;},
            companyActionDisabled() { return !this.contact.companyID && !this.canAssign; },
            contactActionDisabled() { return !this.contact.contactID && !this.canAssign; },
            requiredRoleAdditionalVerbiage() { return !(this.contact.roleExtension == "Buyer" || this.contact.roleExtension == "Seller") ? "File Information." : this.contact.roleExtension + "." },
            deleteTooltip() {
                if(!this.showDelete) return "";
                if(this.readOnly) return "Access Restricted";
                if(!this.canDelete) return "Cannot delete from File Contacts. Please delete from " + this.requiredRoleAdditionalVerbiage;
                if(this.contact.hasOutstandingChecks) return `Unable to delete this ${ this.contact.role.toLowerCase() }, due to outstanding checks`;
                return `Delete ${_.startCase(this.contact.role)}`;
            },
            companyActionTooltip() {
                if(!this.showCompanyAction) return "";
                if(this.contact.companyID) return "View/Edit Company";
                if(!this.canAssign) return "Company/contact cannot be<br/>assigned to this role from here.";
                if(this.readOnly) return "Access Restricted";
                return "Assign Company/Contact";
            },
            contactActionTooltip() {
                if(!this.showContactAction) return "";
                if(this.contact.contactID) return "View/Edit Contact";
                if(!this.canAssign) return "Company/contact cannot be<br/>assigned to this role from here.";
                if(this.readOnly) return "Access Restricted";
                return "Assign Contact";
            },
            actionIcon() {
                return {
                    delete: "far fa-trash-alt",
                    buyerSeller: "fas fa-user-edit",
                    company: this.contact.companyID ? "far fa-building" : "fas fa-plus",
                    contact: this.contact.contactID ? "fas fa-user-edit" : "fas fa-user-plus"
                };
            },
            actionAutomationId() {
                return {
                    delete: "btn_delete_contact",
                    buyerSeller: this.contact.isBuyer ? "btn_edit_buyer" : "btn_edit_seller",
                    company: this.contact.companyID ? "btn_edit_company" : "btn_assign_company",
                    contact: this.contact.contactID ? "btn_edit_contact" : "btn_assign_contact"
                };
            },

            contactRoleId() { return _.getNumber(this, "contact.rolesID", 0); },

            elementIDs() {
                const elementId = pfx => this.contactRoleId > 0 ? `${pfx}${this.contactRoleId}` : _.uniqueId(pfx);
                return {
                    contactEmailLink: elementId("lnk_contact_email_")
                };
            }
        },

        methods: {
            onBuyerSellerDetailClick(e) {
                this.$emit("detail-click", { type: "buyer-seller", data: this.contact });
            },

            onCardClick(e) {
                if(_.some(this.elementIDs, id => e.target.id === id)) return;
                let type = this.contact.isBuyerSeller ? "buyer-seller" : "company";
                this.$emit("detail-click", { type, data: this.contact });
            },

            onCompanyActionClick(e) {
                if(this.contact.companyID)
                    this.$emit("detail-click", { type: "company", data: this.contact });
                else
                    this.$emit("assign", { data: this.contact, type: "company" });
            },

            onContactActionClick(e) {
                if(this.contact.companyID && this.contact.contactID)
                    this.$emit("detail-click", { type: "contact", data: this.contact });
                else
                    this.$emit("assign", { data: this.contact, type: "contact" });
            },

            onDeleteClick(e) {
                this.$emit("delete", { data: this.contact });
            }
        }
    };
</script>
