<template>
    <rq-page-section ref="sectionComponent"
        v-show="calculationExists"
        title="Notes"
        class="premium-notes"
        collapsible
        borderless
        v-model:isExpanded="isNoteListExpanded"
        @shown="onListExpanded">
        <template #header-actions>
            <ul class="nav">
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_add_premium_note"
                        variant="theme"
                        :disabled="readOnly"
                        @click="onAddNote">Add Note
                    </b-btn>
                </li>
            </ul>
        </template>
        <rqdx-action-data-grid
            ref="dataGrid"
            :visible="!isLoading"
            :automation_id="elementName('tbl', itemTypeName)"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', itemTypeName, 'data')"
            v-model:search-value="searchTerm"
            search-mode="field"
            :read-only="readOnly"
            @delete="onDeleteNote"
            @edit="onEditNote"
            @rowDoubleClick="onEditNote"
            hide-show-column-chooser
        />
    </rq-page-section>
</template>

<script>
    import { mapState } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { OrderRateNoteDto } from "@settlement/models";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        props: {
            orderRateCalculationID: { type: Number, required: true, default: 0 },
            readOnly: { type: Boolean, default: false },
            expand: { type: Boolean, default: false }
        },

        data () {
            return {
                searchTerm: "",
                notes: [],
                isLoading: true,
                isNoteListExpanded: this.expand,
                addWhenExpanded: false,
                validationErrors: []
            };
        },

    computed: {
        ...mapState({
            user: state => state.authentication.session.user
        }),
        calculationExists () {return _.gt(this.orderRateCalculationID, 0);},
    },

    watch: {
        searchTerm(newValue, oldValue) {
            if(newValue === oldValue) return;
            this.searchNotes();
        }
    },

    created() {
        this.fetchData();
        this.initNonReactiveVariables();
        this.initGridConfig();
        this.initListeners();
    },

    beforeUnmount () {
        this.$events.off(this.addEventName, this.onAddNote);
    },

    methods: {
        elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        fetchData() {
            const self = this;
            let apiPromise = self.$api.OrderRateNotesApi.getNotes(self.orderRateCalculationID);
            self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.notes = _.map(result, n => new OrderRateNoteDto(n));
                })
                .catch(err => {
                })
                .finally(() => {
                    self.refresh();
                    self.isLoading = false;
                })
                ;
        },
        getGrid() {
            return _.get(this.$refs, "dataGrid.gridInstance", {});
        },
        initNonReactiveVariables() {
            const self = this;
            self.addEventName = "add:premium-note";
            self.itemKey = "orderRateNoteID";
            self.itemTypeName = "Premium Note";
            self.itemTypeNamePlural = "Premium Notes";
            self.selectionActions = [
                {
                    name: "edit",
                    text: "Edit",
                    eventName: "edit",
                    requireSelection: true,
                    tooltip: `Edit ${this.itemTypeName}`,
                    disabled(e) {
                        if(self.readOnly) return true;
                        return _.some(e.data, "readOnly");
                    }
                },
                {
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    tooltip: `Delete ${this.itemTypeName}`,
                    disabled(e) {
                        if (self.readOnly) return true;
                        return _.some(e.data, "readOnly");
                    }
                }
            ];
        },
        initGridConfig() {
            const self = this;
            self.gridConfig = {
                columns: [
                    {
                        dataField: "nDate",
                        dataType: "datetime",
                        caption: "Date / Time",
                        width: 140,
                        minWidth: 140,
                        sortIndex: 0,
                        sortOrder: "desc",
                    },
                    {
                        dataField: "notes",
                        dataType: "string",
                        caption: "Note",
                        width: "65%",
                        cellTemplate: DxGridUtils.truncateCellTemplate
                    },
                    {
                        dataField: "author",
                        dataType: "string",
                        minWidth: 140
                    }
                ],
                noDataText: "No notes found for this Premium.",
                paging: { enabled: false },
            };
            self.gridDataSource = {
                key: self.itemKey,
                loadMode: "raw",
                load () {
                    return Promise.resolve(self.notes);
                },
            };
        },
        initListeners(){
            this.$events.on(this.addEventName, this.onAddNote);
        },
        onAddNote() {
            this.clear();
            this.editNote(new OrderRateNoteDto({orderRateCalculationID: this.orderRateCalculationID, usersID: this.user.usersID, author: this.user.displayName, nDate: DateTimeHelper.nowTenant()}));
        },
        onDeleteNote(e) {
            if(!e || !e.data) return;
            const self = this;
            let items = e.data;
            let ok = function (args) {
                let toBeDeletedKeys = _.map(items, self.itemKey);
                let apiPromise = self.$api.OrderRateNotesApi.deleteNote(toBeDeletedKeys);
                self.$rqBusy.wait(apiPromise)
                    .then(key => {
                        self.removeGridItems(items);
                        let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                        self.$toast.success({ message: message });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: "No", okTitle: "Yes"});
        },
        onEditNote(e) {
            if(!e || !e.data || this.readOnly) return;
            if (!_.isEqual(e.data.usersID, this.user.usersID)) {
                this.$toast.error("You do not have permission to edit this note.");
                return;
            }
            this.clear();
            this.editNote(e.data);
        },
        onListExpanded(){
            this.refresh();
            if(!this.addWhenExpanded) return;
            this.onAddNote();
            this.addWhenExpanded = false;
        },
        searchNotes: _.debounce(function (e) {
            let grid = this.getGrid();
            if(_.isEmpty(grid)) return;
            grid.searchByText(this.searchTerm);
        }, 300, {trailing: true, leading: false}),
        clear() {
            let grid = this.getGrid();
            if (!_.isEmpty(grid)) {
                grid.clearSelection();
                grid.option("focusedRowIndex", -1);
            }
        },
        refresh() {
            let grid = this.getGrid();

            if (!_.isEmpty(grid)) {
                grid.clearSelection();
                grid.option("focusedRowIndex", -1);
                grid.refresh();
                grid.updateDimensions();
            }
        },
        removeGridItems(items) {
            _.pullAllBy(this.notes, items, this.itemKey);
        },
        editNote(note) {
            const self = this;
            self.$dialog.promptInput({
                title: "Premium: Note",
                label: "Note",
                value: note.notes,
                multiline: true,
                onOk (e) {
                    note.notes = e.value;
                    self.save(note);
                    return true;
                }
            });
        },
        addNote(note) {
            this.notes.push(new OrderRateNoteDto(note));
        },
        updateNote(note) {
            let originalNote = _.find(this.notes, (i) => {
                return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(note, this.itemKey, -1), -1);
            });
            _.assign(originalNote, note);
        },
        save(note){
            const self = this;
            let isNew = note.isNew ? true : false;
            let apiPromise = self.$api.OrderRateNotesApi.saveNote(note.toDataObject());
            self.$rqBusy.wait(apiPromise)
                .then(data => {
                    if (isNew) {
                        self.addNote(data);
                    } else {
                        self.updateNote(data);
                    }
                    self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                }).catch(e => {
                    self.$toast.error(e.errorMessage);
                }).finally(() => {
                    self.refresh();
                });
        },
    }
};
</script>