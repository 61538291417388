<template>
    <div :class="classAttr">

        <div ref="tagDisplay"
            class="rq-tbx-value"
            :tabindex="disabled ? -1 : 0"
            @click="onItemClick"
            @focus="onItemFocus">{{modelValue}}</div>

        <tagbox-item-input
            v-if="!disabled"
            ref="tagInput"
            v-model:is-focused="isFocused"
            :min-width="inputWidth"
            :valid-regex="validRegex"
            v-model:is-valid="isValid"
            v-model="boundValue"
        />

        <div v-if="!disabled"
            ref="tagDelete"
            v-rq-tooltip.hover
            :title="deleteTooltip"
            class="rq-tbx-action"
            @click="$emit('remove')">
            <FontAwesomeIcon icon="fas fa-times" />
        </div>
    </div>
</template>

<script>
    import TagboxItemInput from "./TagboxItemInput.vue";
    export default {
        props: {
            index: { default: 0 },
            modelValue: { type: String, default: "" },
            deleteTooltip: { type: String, default: null },
            minWidth: { type: Number, default: 75 },
            validRegex: { type: RegExp, default: /[\s\S]/ },
            disabled: { type: Boolean, default: false }
        },
        components: { TagboxItemInput },
        data() {
            return {
                boundValue: "",
                inputWidth: this.minWidth,
                isFocused: false,
                isValid: true,
                originalValue: ""
            };
        },
        computed: {
            classAttr() {
                return {
                    "rq-tbx-item": true,
                    "rq-focused": this.isFocused,
                    "has-error": this.isFocused && !this.isValid
                };
            }
        },
        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.boundValue) return;
                    this.boundValue = newValue;
                },
                immediate: true
            },
            boundValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("change", newValue);
            }
        },
        methods: {
            onItemClick(e) {
                if(this.disabled) return;
                this.activateInput();
            },

            onItemFocus(e) {
                if(this.disabled) return;
                this.activateInput();
            },

            activateInput() {
                let displayWidth = this.minWidth;
                if(this.$refs.tagDisplay)
                    displayWidth = this.$refs.tagDisplay.clientWidth + 18;
                if(displayWidth > this.minWidth)
                    this.inputWidth = displayWidth;
                this.setFocus();
            },

            setFocus(){
                this.$nextTick().then(() => {
                    this.$refs.tagInput.setFocus();
                });
            }
        }
    }
</script>
