<template>
    <tr>
        <td class="aal-due">
            <b-form-checkbox :disabled="disabled || escrowItemDetail.disabled || readOnly" v-model="escrowItemDetail.due"></b-form-checkbox>
        </td>
        <td class="aal-month">
            <span>{{ escrowItemDetail.monthName }}</span>
        </td>
        <td class="aal-amount">
            <span class="text-muted">{{formatMoney(escrowItemDetail.paymentAmountFromEscrow)}}</span>
        </td>
    </tr>
</template>

<script>
    export default {

        name: "agg-escrow-item-detail",

        props: {
            escrowItemDetail: { type: Object, default: () => ({}) },
            index: { type: Number, default: 0 },
            disabled: { type: Boolean, default: false }
        },

        computed: {
            readOnly() { return this.$store.state.isPageReadOnly; }
        },

        watch :{
            "escrowItemDetail.due"(newvalue, oldvalue) {
                if (oldvalue === newvalue) return;
                this.$emit('docalculate');
            }
        },

        methods:  {
            formatMoney(value){
                 return accounting.formatMoney(value);
            }
        }
    };
</script>

