<template>
  <div class="container smkt-question-container">
    <div class="row">
      <div class="question col-9">
        <label class="smkt-question form-group-label">{{ question.description }}</label>
      </div>
      <div class="col-3">
        <div v-if="question.data_type === 'bool'">
          <div class="row">
            <rq-radio-group :options="overrideOptions" v-model="question.answer" inline @change="handleAnswerChange"/>
          </div>
        </div>
        <div v-else-if="question.data_type === 'number'">
          <div>
            <input type="number" v-model.number="question.answer" @change="handleAnswerChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    question: null
  },
  data() {
    return {
      overrideOptions: [
        { automation_id: "rdo_yes", text: "Yes", value: "true" },
        { automation_id: "rdo_no", text: "No", value: "false" }
      ],
    };
  },
  methods: {
    handleAnswerChange() {
      this.$emit("answerChanged")
    }
  }
};
</script>