<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Policies"
            :automation_id="elementName('tbl')"
            :data-source="gridDataSource"
            :config="gridConfig"
            hide-search>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import { TimeZoneInfo, buildTimezoneLookup } from "@/shared/utilities/Timezones";
    import { DateTime } from "luxon";
    import { PolicyListDto } from "../models";

    export default {
        name: "Policies",
        mixins: [ GridSystemLookupMixin ],
        data: function() {
            return {
                gridDataSource: [],
                gridConfig: {},
                items: [],
            }
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary
            }),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
        },
        created () {
            this.initGridConfig();
            this.fetchData();
        },
        methods: {
            elementName(prefix = "", suffix = "") {
                return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "policyLabel",
                            dataType: "string",
                            caption: "Policy",
                            groupIndex: 0,
                            calculateSortValue: "loanOrder",
                            sortIndex: 0,
                            sortOrder: "desc",
                        },
                        {
                            dataField: "policyNumber",
                            dataType: "string",
                            caption: "Policy #",
                            groupIndex: -1,
                        },
                        {
                            dataField: "titleVestedIn",
                            dataType: "string",
                            caption: "Title Vested",
                            groupIndex: -1,
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "policyFormTypeID",
                                dataType: "number",
                                caption: "Form Type",
                                groupIndex: -1,
                            },
                            lookupKey: self.lookupItems.POLICY_FORM_TYPES
                        }),
                        {
                            dataField: "policyDate",
                            dataType: "string",
                            caption: "Policy Date",
                            groupIndex: -1,
                            cellTemplate: function(cellElement, cellInfo) {
                                if(_.isNullOrEmpty(cellInfo.value)) return null;
                                let dt = DateTime.fromISO(cellInfo.value, { setZone: true });
                                let val = dt.startOf("day").toFormat("MM/dd/yyyy");
                                $("<span />")
                                    .append(val)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "policyTime",
                            dataType: "string",
                            caption: "Policy Time",
                            groupIndex: -1,
                            cellTemplate: function(cellElement, cellInfo) {
                                if(_.isNullOrEmpty(cellInfo.value)) return null;
                                let dt = DateTime.fromISO(cellInfo.value, { setZone: true });
                                let val = dt.toLocaleString(DateTime.TIME_SIMPLE);
                                $("<span />")
                                    .append(val)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "policyTz",
                            dataType: "string",
                            caption: "Time Zone",
                            groupIndex: -1,
                            cellTemplate: function(cellElement, cellInfo) {
                                const timezones = buildTimezoneLookup();
                                if(_.isNullOrEmpty(cellInfo.value)) return null;
                                let dt = DateTime.fromISO(cellInfo.value, { setZone: true });
                                let z = dt.zoneName;
                                let tz = _.find(timezones, {utcOffset: z});
                                let val = tz.displayValues[0];
                                $("<span />")
                                    .append(val)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "remitDate",
                            dataType: "date",
                            caption: "Remit Date",
                            groupIndex: -1,
                        },
                        {
                            dataField: "submitDate",
                            dataType: "date",
                            caption: "Submit Date",
                            groupIndex: -1,
                        },
                    ],
                    selection: { mode: "none" },
                };
                self.gridDataSource = {
                    key: "loanOrder",
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },
            fetchData() {
                const self = this;
                let apiPromise = this.$api.CommitmentsApi.getPolicyList(self.orderId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new PolicyListDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                        self.$toast.error({ message: `Error loading Policy List.` });
                        return error;
                    });
            },
            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
        }
    }
</script>