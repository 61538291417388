// Binder fields
export const BINDER_DATE = { "key": "binder_date", "class": "col-12 col-sm-12 col-md-6 col-lg-4" };
export const BINDER_NUMBER = { "key": "binder_number", "class": "col-12 col-sm-12 col-md-3 col-lg-2" };
// combined into single binder split date component
// export const BINDER_TIME = { "key": "binder_time", "class": "col-12 col-sm-12 col-md-3 col-lg-2" };

// header fields
export const OWNER_INSURED = {
    "key": "owner_insured",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Owner Insured",
    "placeholder": "Owner Insured",
    "entity": "header",
    "rtf": "ownerInsured",
    "text": "ownerInsuredText",
    "override": "ownerInsuredOverride",
    "default": "BuyerNames",
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "ScheduleAOwnerInsured"
};

export const CURRENT_HOLDERS = {
    "key": "current_holders",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Current Title Holder/Title Vested",
    "placeholder": "Current Owners",
    "entity": "header",
    "rtf": "currentHolders",
    "text": "currentHoldersText",
    "override": "currentHoldersOverride",
    "default": "SellerNames",
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "ScheduleATitleHolder"
};

export const LAND_INTEREST = {
    "key": "land_interest",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Interest in Land",
    "placeholder": "Interest in Land",
    "entity": "header",
    "rtf": "interestInLand",
    "text": "interestInLandText",
    "override": "interestInLandOverride",
    "default": "InterestInLand",
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "ScheduleAInterestInLand"
};

// detail fields
export const CONSTRUCTION_BORROWER = {
    "key": "construction_borrower",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Construction Borrower",
    "placeholder": "Construction Borrower",
    "entity": "detail",
    "rtf": "constructionBorrower",
    "text": "constructionBorrowerText",
    "override": "constructionBorrowerOverride",
    "default": "BuyerNames",
    "customFields": [
        { "key": "BuyerNames", "label": "Buyer Names", "value": "" },
        { "key": "BuyerNamesWithVesting", "label": "Buyer Names with Vesting", "value": "" },
        { "key": "SellerNames", "label": "Seller Names", "value": "" },
        { "key": "SellerNamesWithVesting", "label": "Seller Names with Vesting", "value": "" }
    ],
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "ScheduleAConstructionBorrower"
};

export const CONSTRUCTION_INSURED = {
    "key": "construction_insured",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Construction Insured",
    "placeholder": "Construction Insured",
    "entity": "detail",
    "rtf": "constructionInsured",
    "text": "constructionInsuredText",
    "override": "constructionInsuredOverride",
    "default": "Lender",
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "ScheduleAConstructionInsured"
};

export const LIEN_DESCRIPTION = {
    "key": "lien_description",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Lien Description",
    "placeholder": "Lien Description",
    "entity": "detail",
    "rtf": "lienDescription",
    "text": "lienDescriptionText",
    "isAdvancedMode": true,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": false,
    "rows": 2,
    "sectionName": "ScheduleALienDescription"
};

export const MORTGAGE_BORROWER = {
    "key": "mortgage_borrower",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Mortgage Borrower",
    "placeholder": "Mortgage Borrower",
    "entity": "detail",
    "rtf": "mortgageBorrower",
    "text": "mortgageBorrowerText",
    "override": "mortgageBorrowerOverride",
    "default": "BuyerNames",
    "customFields": [
        { "key": "BuyerNames", "label": "Buyer Names", "value": "" },
        { "key": "BuyerNamesWithVesting", "label": "Buyer Names with Vesting", "value": "" },
        { "key": "SellerNames", "label": "Seller Names", "value": "" },
        { "key": "SellerNamesWithVesting", "label": "Seller Names with Vesting", "value": "" }
    ],
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "ScheduleAMortgageBorrower"
};

export const MORTGAGE_INSURED = {
    "key": "mortgage_insured",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Mortgage Insured",
    "placeholder": "Mortgage Insured",
    "entity": "detail",
    "rtf": "mortgageInsured",
    "text": "mortgageInsuredText",
    "override": "mortgageInsuredOverride",
    "default": "Lender",
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "ScheduleAMortgageInsured"
};

export const TITLE_HOLDER_VESTED = {
    "key": "title_holder_vested",
    "class": "col-12 col-sm-12 col-md-6",
    "component": "title-production-rtf-field",
    "label": "Title Vested",
    "placeholder": "Title Vested",
    "entity": "detail",
    "rtf": "titleVestedIn",
    "text": "titleVestedInText",
    "override": "titleVestedInOverride",
    "default": "BuyerNames",
    "customFields": [
        { "key": "BuyerNames", "label": "Buyer Names", "value": "" },
        { "key": "BuyerNamesWithVesting", "label": "Buyer Names with Vesting", "value": "" },
        { "key": "SellerNames", "label": "Seller Names", "value": "" },
        { "key": "SellerNamesWithVesting", "label": "Seller Names with Vesting", "value": "" }
    ],
    "isAdvancedMode": false,
    "includeStandardLanguage": true,
    "includePrompt": true,
    "includePromptValues": true,
    "hasOverride": true,
    "rows": 1,
    "sectionName": "OwnerPolicyTitleVested"
};

//RQO-5742 - moved to it's own section
// export const LEGAL_DESCRIPTION = {
//     "key": "legal_description",
//     "class": "col-12 col-sm-12 col-md-6",
//     "component": "title-production-rtf-field",
//     "label": "Legal Description",
//     "placeholder": "Legal Description",
//     "entity": "detail",
//     "rtf": "legalDescription",
//     "text": "legalDescriptionText",
//     "default": "LegalDescription",
//     "isAdvancedMode": false,
//     "includeStandardLanguage": true,
//     "hasOverride": false,
//     "rows": 1
// };