<template>
    <div class="row">
        <div class="col col-12 col-md-4 form-group">
            <rq-action-form-group
                :label="dateFieldLabel"
                :label-for="dateFieldId"
                :show-action="showDateAction"
                @action-click="onActionClick"
                :action-automation-id="dateActionAutomationId"
                :action-label="dateActionLabel">
                <template #default>
                    <rqdx-date-box
                        :id="dateFieldId"
                        :automation_id="dateFieldId"
                        :disabled="disabled"
                        type="date"
                        v-model="dateValue"
                    />
                </template>
            </rq-action-form-group>
        </div>
        <div class="col col-12 col-md-4 form-group">
            <label :for="timeFieldId">{{ timeFieldLabel }}</label>
            <rqdx-date-box
                :id="timeFieldId"
                :automation_id="timeFieldId"
                :disabled="!dateValue || disabled"
                type="time"
                display-format="hh:mm:ss a"
                :date-part="dateValue"
                v-model="timeValue"
                auto-open
            />
        </div>
        <div class="col col-12 col-md-4">
            <label :for="timezoneFieldId"></label>
            <dx-select-box
                :input-attr="{ automation_id: timezoneFieldId }"
                :items="timezones"
                :display-expr="selectedTimeZoneDisplay"
                value-expr="utcOffset"
                :search-expr="['display','aliases']"
                item-template="timezone-item"
                :search-enabled="true"
                :drop-down-options="{ minWidth: 400 }"
                :disabled="!dateValue || disabled"
                v-model="timeZoneValue">
                <template #timezone-item="{ data }">
                    <div>
                        <div><strong>{{data.utcOffset}} - {{data.display}}</strong></div>
                        <div class="text-muted fst-italic text-truncate" :title="data.aliases">{{data.aliases}}</div>
                    </div>
                </template>
            </dx-select-box>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watch } from "vue";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { TimeZoneInfo, buildTimezoneLookup } from "@/shared/utilities/Timezones";
    import { DateTime } from "luxon";

    const props = defineProps({
        modelValue: { type: String, default: null },
        fieldDescriptor: { type: String, default: () => _.uniqueId("splitDate") },
        defaultTime: { type: String, default: null },
        dateActionAutomationId: { type: String, default: null },
        dateActionLabel: { type: String, default: null },
        showDateAction: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    });

    const emit = defineEmits(["update:modelValue", "action-click"]);

    const onActionClick = e => emit("action-click", e);

    const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

    const dateValue = ref(null);
    const timeValue = ref(null);
    const timeZoneValue = ref(null);

    const modelValueProp = computed(() => props.modelValue);

    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const clientOffset = TimeZoneInfo.getOffset(clientTimeZone);
    const timezones = buildTimezoneLookup();

    const fieldId = (prefix, type) => _.join([prefix, props.fieldDescriptor, type], "_");
    const dateFieldId = computed(() => fieldId("dtp", "date"));
    const timeFieldId = computed(() => fieldId("dtp", "time"));
    const timezoneFieldId = computed(() => fieldId("drp", "timezone"));
    const dateFieldLabel = computed(() => `${_.startCase(props.fieldDescriptor)} Date`);
    const timeFieldLabel = computed(() => `${_.startCase(props.fieldDescriptor)} Time`);

    const combinedValue = computed(() => joinParts(dateValue.value, timeValue.value, timeZoneValue.value));

    watch(modelValueProp, newValue => {
        if(newValue === combinedValue.value) return;
        // console.log("Setting Value - \"%s\"", newValue);
        setLocalParts(newValue);
    }, { immediate: true });

    watch(combinedValue, (newValue, oldValue) => {
        if(newValue === modelValueProp.value) return;
        // console.log("Emitting value - \"%s\"", newValue);
        if(_.isNil(newValue) || _.isNil(oldValue)) {
            // ensure we clear out time/timezone when new value is null and default them when old value was null.
            // prior equality check to modelValue will prevent this from getting hit again when the watcher
            // gets triggered from these changes
            setLocalParts(newValue);
        }
        emit("update:modelValue", combinedValue.value);
    });

    const selectedTimeZoneDisplay = item => item && (item.offset === 0
        ? item.display
        : `${item.utcOffset} - ${item.display}`);

    function getParts(dtString) {
        if(_.isEmpty(dtString)) return { date: null, time: null, timezone: null };
        let dt = DateTime.fromISO(dtString, { setZone: true });
        return {
            date: dt.startOf("day").toFormat(DATETIME_FORMAT),
            time: dt.toFormat(DATETIME_FORMAT),
            timezone: dt.zoneName
        };
    }

    function joinParts(d, t, tz) {
        if(_.isEmpty(d)) return null;
        return DateTimeHelper.joinDateParts({
            date: d,
            time: _.isNil(t) ? props.defaultTime || DateTime.now().toFormat(DATETIME_FORMAT) : t,
            timezone: _.isNil(tz) ? TimeZoneInfo.getUtcOffset(clientOffset) : tz,
            format: "yyyy-MM-dd'T'HH:mm:ss.SSS0000ZZ"
        });
    }

    function setLocalParts(val) {
        let dtParts = getParts(val);
        dateValue.value = dtParts.date;
        timeValue.value = dtParts.time;
        timeZoneValue.value = dtParts.timezone;
    }

</script>