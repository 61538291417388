<template>
    <div class="subdivision-lookup">
        <subdivision-list
            v-show="subdivisionListActive"
            ref="subdivisionList"
            v-model:selected-id="selectedIdValue"
            @add="onComponentAction(actions.AddSubdivision, $event)"
            @edit="onComponentAction(actions.EditSubdivision, $event)"
            @select="onComponentAction(actions.SelectSubdivision, $event)"
            embedded-mode />

        <subdivision-form
            v-show="subdivisionActive"
            ref="subdivisionForm"
            :subdivision-data="currentSubdivision"
            embedded-mode
        />
        <Teleport
            v-if="dialogFooterAvailable"
            :to="`#${dialogFooterId}`">
            <div class="subdivision-lookup-footer">
                <section class="left-footer-section"></section>
                <section class="right-footer-section">
                    <button automation_id="btn_subdivision_lookup_back" type="button" class="btn btn-secondary" @click="back()">{{hasSubdivisionAccess() ? "Cancel" : "Back"}}</button>
                    <button automation_id="btn_subdivision_lookup_add_another" type="button" class="btn btn-primary" @click="save(true)" v-show="addAnotherSubdivisionButtonVisible" >Save &amp; Add Another</button>
                    <button automation_id="btn_subdivision_lookup_ok" type="button" class="btn btn-primary" @click="save()" v-show="hasSubdivisionAccess()" >{{subdivisionListActive ? "Ok" : "Save"}}</button>
                </section>
            </div>
        </Teleport>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import SubdivisionList from "./SubdivisionList.vue";
    import SubdivisionForm from "./SubdivisionForm.vue";
    import { LookupItem, SubdivisionDto, SystemLookupItemDto } from "@/shared/models/models";

    export default {
        name: "SubdivisionLookup",
        props: {
            selectedId: { type: Number, default: -1 }
        },
        components: { SubdivisionList, SubdivisionForm},
        inject: {
            dialogFooterId: { from: "footerId", default: null },
            dialogFooterAvailable: { from: "footerAvailable", default: false }
        },
        data () {
            return {
                currentSubdivision: {},
                currentView: "SubdivisionList",
                currentDialogTitle: null,
                addAnotherSubdivisionButton: false,
                allowExportSelectedData: false
            };
        },

        watch: {
            currentDialogTitle(newValue, oldValue) {
                if(newValue === oldValue) return;
                let title = newValue;
                if(_.isNil(newValue)) title = "Select Subdivision";
                this.$emit("update-dialog-options", { title });
            },
        },

        computed: {
            ...mapState({
                order: state => state.orders.order,
                orderRegion: state => state.orders.orderSummary.regionID,
                regions: state => state.authentication.session.regions
            }),
            subdivisionFormComponent() { return _.get(this, "$refs.subdivisionForm", null); },
            subdivisionListComponent() { return _.get(this, "$refs.subdivisionList", null); },
            subdivisionListActive() { return this.currentView === "SubdivisionList"; },
            subdivisionActive() { return this.currentView === "SubdivisionForm"; },
            selectedIdValue: {
                get() { return this.selectedId; },
                set(val) { this.$emit("update:selectedId", val); }
            },
            addAnotherSubdivisionButtonVisible () { return this.subdivisionActive && this.addAnotherSubdivisionButton && this.hasSubdivisionAccess(); }
        },

        created() {
            this.actions = {
                AddSubdivision: "addSubdivision",
                EditSubdivision: "editSubdivision",
                SelectSubdivision: "selectSubdivision",
            };

            this.viewNames = {
                subdivision: "SubdivisionForm",
                list: "SubdivisionList"
            };
        },

        methods: {

            executeAction(name, actionData = null) {
                const self = this;
                let nextView = "";
                if(name === self.actions.EditSubdivision && !actionData) return;

                switch(name) {
                    case self.actions.AddSubdivision:
                        nextView = self.viewNames.subdivision;
                        self.addAnotherSubdivisionButton = true;
                        self.currentDialogTitle = "Add Subdivision";
                        self.currentSubdivision = new SubdivisionDto({regionID: self.orderRegion});
                        break;
                    case self.actions.EditSubdivision:
                        nextView = self.viewNames.subdivision;
                        self.currentSubdivision = actionData;
                        self.addAnotherSubdivisionButton = false;
                        self.currentDialogTitle = "Edit Subdivision";
                        break;
                    case self.actions.SelectSubdivision:
                        self.$emit('ok');
                        return;
                }
                self.setView(nextView);
            },

            back () {
                const self = this;
                if(self.currentView === self.viewNames.subdivision){
                    self.setView(self.viewNames.list);
                    self.currentDialogTitle = "Add/Edit Subdivisions";
                    self.subdivisionListComponent.refresh();
                }
                else if(self.currentView === self.viewNames.list){
                    self.$emit('action', { lookupItems: self.lookupItems()});
                }
            },

            dialogResult (e) {
                return this.subdivisionListComponent.dialogResult();
            },

            hasSubdivisionAccess(obj) {
                let subdivision = obj || this.currentSubdivision;

                if(_.parseNumber(subdivision.subdivisionID, 0) === 0) return true;

                let subdivisionRegion = _.find(this.regions, r => _.parseNumber(r.regionID) === _.parseNumber(subdivision.regionID));
                let adminRegion = _.find(this.regions, r => r.isAdminRegion);
                let userHasAccessToRegion = !_.isNil(subdivisionRegion);
                let userHasAdminRegion = !_.isNil(adminRegion);

                return userHasAdminRegion || userHasAccessToRegion;
            },

            lookupItems (e) {
                this.subdivisionListComponent.gridInstance.searchByText("");
                let subdivisions  = this.subdivisionListComponent.gridInstance.getDataSource().items();
                let lookupItems = subdivisions ? _.map(subdivisions, subdivision => new SystemLookupItemDto({id: subdivision.subdivisionID, name: subdivision.name, regionID: subdivision.regionID, data: null})) : [];
                return lookupItems;
            },

            onComponentAction(name, e){
                this.executeAction(name, e && e.data);
            },

            save (addAnother=false) {
                const self = this;
                if(self.currentView === self.viewNames.subdivision){
                    self.saveSubdivision(addAnother);
                }
                else if(self.currentView === self.viewNames.list){
                    self.$emit('ok');
                }
            },

            saveSubdivision(addAnother=false) {
                const self = this;
                if(!self.subdivisionFormComponent) {
                    self.$toast.error({ message: "Subdivision component not initialized." });
                    return;
                }
                let isValid = self.subdivisionFormComponent.validate();
                if (isValid.success) {
                    self.subdivisionFormComponent.save()
                        .then(result => {
                            self.subdivisionListComponent.refresh();
                            if(result.dialogMode === "edit" || !(result.dialogMode === "add" && addAnother)) {
                                self.setView(self.viewNames.list);
                            }
                            if (addAnother) {
                                self.currentSubdivision = new SubdivisionDto({regionID: result.data.regionID});
                                self.subdivisionFormComponent.loadSubdivision(self.currentSubdivision);
                            }
                        })
                        .catch(err => {
                            console.log(err.errorMessage);
                        });
                } 
            },

            setView(viewName) {
                const self = this;
                if(self.currentView === viewName) return;
                self.currentView = viewName;
                self.$nextTick().then(() => {
                    if (self.subdivisionActive && self.subdivisionFormComponent) self.subdivisionFormComponent.loadSubdivision(self.currentSubdivision);
                    let parentElement = self.$el.parentElement;
                    if(!parentElement || !parentElement.scrollTop) return;
                    parentElement.scrollTop = 0;
                });
            },

            // subdivisionExport () {
            //     this.subdivisionListComponent.subdivisionGridInstance.exportToExcel(this.allowExportSelectedData);
            // }
        }
    };
</script>
<style lang="scss">
    .modal-header .modal-title .header-badge-message { vertical-align: top; line-height: 1.5; margin-left:1rem; }
    .subdivision-lookup { height: 100%; }
    .subdivision-lookup-footer { display:flex; flex-direction: column; width:100%;
        section { display:flex;
            button { margin: 0 2px; }
            &.left-footer-section { align-self:flex-start; }
            &.right-footer-section { align-self:flex-end; }
        }
    }
</style>
