<template>
    <tr>
        <td class="rq-col-checkbox col-checkbox">
            <b-form-checkbox
                automation_id="chk_item_select"
                v-model="viewModel.isSelected"
            />
        </td>
        <td class="rq-col-input col-description">
            <input class="form-control form-control-sm" :value="premiumTypeDisplay" :disabled="readOnly" readonly>
        </td>
        <td class="rq-col-input col-payee">
            <company-picker v-if="premiumRow"
                ref="payeeCompanyPicker"
                automation_id="pic_payee_company"
                dialogTitle="Select a Premium Payee"
                :disabled="readOnly || isCdfView"
                no-drop-down
                small
                v-model="payeeCompany"
            />
        </td>
        <td v-if="!hideBreakoutColumn" class="rq-col-input col-breakout">
            <input class="form-control form-control-sm" :value="breakoutRicDisplay" readonly :disabled="readOnly">
        </td>
        <td :class="applyOverrideClass" class="rq-col-input col-amount">
            <rq-input-number
                automation_id="txt_amount"
                :disabled="readOnly"
                format-type="money"
                default-value="0"
                decimals="2"
                value-event="input"
                prepend-icon="fas fa-dollar-sign"
                text-align="right"
                size="sm"
                v-model="viewModel.amount"
                @change="onAmountChange"
            />
        </td>
        <template v-if="!hideTaxColumn">
            <td class="rq-col-input col-percent">
                <input class="form-control form-control-sm" :value="`${taxRate}%`" :disabled="readOnly" readonly>
            </td>
            <td class="rq-col-input col-amount">
                <input class="form-control form-control-sm" :value="formatMoney(viewModel.taxAmount)" :disabled="readOnly" readonly>
            </td>
            <td class="rq-col-input col-amount">
                <input class="form-control form-control-sm" :value="formatMoney(viewModel.total)" :disabled="readOnly" readonly>
            </td>
        </template>
        <td :class="applyBuyerSellerOverrideClass" class="col-percent">
            <rq-input-number
                automation_id="txt_buyer_percent"
                :disabled="readOnly"
                suffix="%"
                default-value="0"
                min-value="0"
                max-value="100"
                decimals="2"
                value-event="input"
                text-align="right"
                size="sm"
                v-model="viewModel.buyerPercent"
                @focus="setBuyerSellerFocus('buyer')"
                @blur="setBuyerSellerFocus()"
                @change="onBuyerPercentChange"
            />
        </td>
        <td :class="applyBuyerSellerOverrideClass" class="col-amount">
            <rq-input-number
                automation_id="txt_buyer_amount"
                :disabled="readOnly"
                format-type="money"
                default-value="0"
                :min-value="minBuyerSellerAmount"
                :max-value="maxBuyerSellerAmount"
                value-event="input"
                text-align="right"
                decimals="2"
                prepend-icon="fas fa-dollar-sign"
                size="sm"
                v-model="viewModel.buyerAmount"
                @focus="setBuyerSellerFocus('buyer')"
                @blur="setBuyerSellerFocus()"
                @change="onBuyerAmountChange"
            />
        </td>
        <td :class="applyBuyerSellerOverrideClass" class="col-percent">
            <rq-input-number
                automation_id="txt_seller_percent"
                :disabled="readOnly"
                suffix="%"
                default-value="0"
                min-value="0"
                max-value="100"
                decimals="2"
                value-event="input"
                text-align="right"
                size="sm"
                v-model="viewModel.sellerPercent"
                @focus="setBuyerSellerFocus('seller')"
                @blur="setBuyerSellerFocus()"
                @change="onSellerPercentChange"
            />
        </td>
        <td :class="applyBuyerSellerOverrideClass" class="col-amount">
           <rq-input-number
                automation_id="txt_seller_amount"
                :disabled="readOnly"
                format-type="money"
                default-value="0"
                :min-value="minBuyerSellerAmount"
                :max-value="maxBuyerSellerAmount"
                value-event="input"
                decimals="2"
                prepend-icon="fas fa-dollar-sign"
                size="sm"
                v-model="viewModel.sellerAmount"
                @focus="setBuyerSellerFocus('seller')"
                @blur="setBuyerSellerFocus()"
                @change="onSellerAmountChange"
            />
        </td>
        <td v-rq-tooltip.hover.top="{ title: isCdfView ? 'Configured Split % displayed in Premium View' : 'Configured Split %' }"
            class="rq-col-input col-percent">
            <input class="form-control form-control-sm" :value="isCdfView ? '' : `${underwriterSplitPercentDisplay}%`" readonly :disabled="isCdfView || readOnly">
        </td>
        <td v-rq-tooltip.hover.top="{ title: isCdfView ? 'Split Amount displayed in Premium View' : 'Split Amount' }" :class="applyUnderwriterOverrideClass" class="col-amount">
            <input v-if="isCdfView" class="form-control form-control-sm" disabled>
            <rq-input-number v-else
                automation_id="txt_underwriter_amount"
                :disabled="readOnly || !underwriterEditable || isCdfView"
                format-type="money"
                default-value="0"
                :min-value="minBuyerSellerAmount"
                :max-value="maxBuyerSellerAmount"
                decimals="2"
                value-event="input"
                prepend-icon="fas fa-dollar-sign"
                size="sm"
                v-model="viewModel.underwriterSplit"
                @change="onUnderwriterAmountChange"
            />
        </td>
        <td v-if="is3PageHud || isCdf" class="rq-col-input col-cdf-section">
            <template  v-if="premiumRow">
                <dx-select-box v-if="is3PageHud"
                    :input-attr="{ automation_id: 'drp_gfe_item' }"
                    :element-attr="{ class: 'form-control form-control-sm' }"
                    value-expr="value"
                    display-expr="text"
                    :items="[
                        {text: '', value: null},
                        { text: 'Yes', value: false },
                        { text: 'No', value: true }
                    ]"
                    :disabled="readOnly || isCdfView"
                    v-model="viewModel.nonGfe"
                    @value-changed="onNonGfeChange"
                />
                <dx-select-box v-if="isCdf"
                    :input-attr="{ automation_id: 'drp_cdf_section' }"
                    :element-attr="{ class: 'form-control form-control-sm' }"
                    value-expr="id"
                    display-expr="name"
                    :items="cdfSections"
                    :disabled="readOnly || isCdfView"
                    v-model="viewModel.sectionID"
                    @value-changed="onCdfSectionChange"
                />
            </template>
        </td>
        <td class="rq-col-input col-amount">
            <rq-input-number
                automation_id="txt_poc_amount"
                formatType="money"
                defaultValue="0"
                decimals="2"
                value-event="input"
                :disabled="readOnly"
                size="sm"
                v-model="viewModel.pocAmount"
            />
        </td>
        <td class="rq-col-input col-poc-who">
            <dx-select-box
                :input-attr="$utils.idAttrs('drp_poc_who', true)"
                :element-attr="{ class: 'form-control form-control-sm' }"
                value-expr="id"
                display-expr="name"
                :items="pocWhoOptions"
                :disabled="readOnly"
                v-model="viewModel.pocWhom"
            />
        </td>
        <td class="rq-col-input col-net-fund">
            <dx-select-box
                :input-attr="$utils.idAttrs('drp_net_fund_', true)"
                :element-attr="{ class: 'form-control form-control-sm' }"
                value-expr="id"
                display-expr="name"
                :items="netFundOptions"
                :disabled="readOnly"
                v-model="viewModel.netFund"
            />
        </td>
        <td class="rq-col-input col-addition-remit-date">
            <rqdx-date-box v-if="premiumRow"
                id="dtp_addtl_remit_date"
                class="form-control-sm"
                :disabled="readOnly"
                v-model="viewModel.addtlRemitDate"
                default-button-display
            />
        </td>
    </tr>
</template>

<script>
    import { mapState } from "vuex";
    import OrderRateCalculation, { SimultaneousOptions } from '@settlement/models/OrderRateCalculation';
    import { PremiumLineItemModel, PremiumEndorsementTotal, SettlementTypeOption, POCWhoIntOptions, POCNetFundOptions } from "@settlement/models";
    import RateBalancer from '@settlement/util/RateBalancer';
    import { CompanyPicker } from '@/shared/components/rq';

    export default {
        name: "PremiumLineItem",
        props: {
            id: { type: String },
            label: { default: null },
            payeeContactID: { default: null },
            total: { default:0 },
            taxRate: { default:0 },
            taxAmount: { default:0 },
            underwriterSplitPercent: { default:0 },
            defaultAmount: { default: 0 },
            defaultUnderwriter: { default: 0 },
            hideBreakoutColumn: { type: Boolean, default: false },
            hideTaxColumn: { type: Boolean, default: false },
            hideUnderwriterField: { type: Boolean, default: false },
            underwriterEditable: { type: Boolean, default: false },
            premiumRow: { type: Boolean, default: false },
            reissueCreditRow: { type: Boolean, default: false },
            isSimultaneous: { type: Boolean, default: false },
            showTax: { type: Boolean, default: false },
            isCdfView: { type: Boolean, default:false },
            isLoanHolder: { type: Boolean, default:false },
            amount: { default:0 },
            buyerPercent: { type: Number, default:0 },
            buyerAmount: { default:0 },
            sellerPercent: { default:0 },
            sellerAmount: { default:0 },
            applyOverride: { type: Boolean, default:false },
            underwriterSplit: { default:0 },
            overrideUnderwriter: { type: Boolean, default: false },
            buyerSellerAmountApplyOverride: { type: Boolean, default: false },
            cdfLine: { default:null },
            nonGfe: { type: Boolean, default: false },
            payeeCompanyID: { default: null },
            payeeCompanyName: { default: null },
            addtlRemitDate: { default:null },
            sectionID: { default:null },
            pocAmount: { default:0 },
		    pocWhom: { default:null },
		    netFund: { default:null },
            readOnly: { type: Boolean, default: false },
            isSelected: { type: Boolean, default: false }
        },

        components: { CompanyPicker },

        data(){
            return {
                syncFields: [
                    "amount", "buyerPercent", "buyerAmount", "sellerPercent" , "sellerAmount", "applyOverride"
                    , "overrideUnderwriter", "underwriterSplit", "cdfLine", "payeeCompanyID" , "payeeCompanyName"
                    , "addtlRemitDate", "sectionID", "nonGfe", "buyerSellerAmountApplyOverride", "pocAmount"
                    , "pocWhom", "netFund"
                ],
                viewModel: new PremiumLineItemModel(),
                buyerFocused: false,
                sellerFocused: false
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                cdfSections: state => _.mapLookup(state.orders.orderLookups.cdfSections),
                rateOrderInfo: state => state.rateEngine.rateOrderInfo,
                settlementStatementType: state => state.orders.orderSummary.settlementStatementType
            }),
            cdfSectionsEmpty() { return _.isEmpty(this.cdfSections); },
            is3PageHud() { return this.settlementStatementType === SettlementTypeOption.HUD_2010; },
            isCdf() { return this.settlementStatementType === SettlementTypeOption.CDF; },
            premiumTypeDisplay() {
                return this.label
                    ? this.label
                    : this.isSimultaneous
                        ? "Simultaneous Fee"
                        : "Basic Premium";
            },
            breakoutRicDisplay() {
                return this.reissueCreditRow
                    ? this.rateOrderInfo.breakoutRIConHUD
                        ? "Yes"
                        : "No"
                    : "";
            },
            taxDisplay() { return this.showTax ? `${this.taxRate}% - ${this.formatMoney(this.viewModel.taxAmount)}` : ""; },
            underwriterSplitPercentDisplay() { return _.parseNumber(this.viewModel.underwriterSplitPercent, 0); },
            minBuyerSellerAmount() { return this.reissueCreditRow ? this.viewModel.total : null; },
            maxBuyerSellerAmount() { return this.reissueCreditRow ? 0 : this.viewModel.total; },
            payeeCompany: {
                get() {
                    return {
                        companyID: this.viewModel.payeeCompanyID,
                        companyName: this.viewModel.payeeCompanyName,
                        contactID: this.viewModel.payeeContactID
                    };
                },
                set(val) {
                    this.syncCompany(val);
                }
            },
            applyOverrideClass() {
                return {
                    "rq-col-input": true,
                    "overridden": this.viewModel.applyOverride
                }
            },
            applyUnderwriterOverrideClass() {
                return {
                    "rq-col-input": true,
                    "overridden": this.underwriterEditable && (this.viewModel.applyOverride || this.viewModel.overrideUnderwriter)
                }
            },
            applyBuyerSellerOverrideClass() {
                return {
                    "rq-col-input": true,
                    "overridden": this.viewModel.applyOverride || this.viewModel.buyerSellerAmountApplyOverride
                }
            },
            netFundOptions(){
                return POCNetFundOptions.lookupItems;
            },
            pocWhoOptions(){
                return POCWhoIntOptions.lookupItems;
            }
        },

        watch: {
            amount(newVal, oldVal) { this.updateField("amount", newVal, oldVal); },
            total(newVal, oldVal) { this.updateField("total", newVal, oldVal); },
            taxRate(newVal, oldVal) { this.updateField("taxRate", newVal, oldVal); },
            taxAmount(newVal, oldVal) { this.updateField("taxAmount", newVal, oldVal); },
            underwriterSplit(newVal, oldVal) { this.updateField("underwriterSplit", newVal, oldVal); },
            underwriterSplitPercent(newVal, oldVal) { this.updateField("underwriterSplitPercent", newVal, oldVal); },
            overrideUnderwriter(newVal, oldVal) { this.updateField("overrideUnderwriter", newVal, oldVal); },
            buyerSellerAmountApplyOverride(newVal, oldVal) { this.updateField("buyerSellerAmountApplyOverride", newVal, oldVal)},
            buyerPercent(newVal, oldVal) { this.updateField("buyerPercent", newVal, oldVal); },
            buyerAmount(newVal, oldVal) { this.updateField("buyerAmount", newVal, oldVal); },
            sellerPercent(newVal, oldVal) { this.updateField("sellerPercent", newVal, oldVal); },
            sellerAmount(newVal, oldVal) { this.updateField("sellerAmount", newVal, oldVal); },
            applyOverride(newVal, oldVal) { this.updateField("applyOverride", newVal, oldVal); },
            isSimultaneous(newVal, oldVal) { this.updateField("isSimultaneous", newVal, oldVal); },
            payeeCompanyID(newVal, oldVal) { this.updateField("payeeCompanyID", newVal, oldVal); },
            payeeCompanyName(newVal, oldVal) { this.updateField("payeeCompanyName", newVal, oldVal); },
            payeeContactID(newVal, oldVal) { this.updateField("payeeContactID", newVal, oldVal); },
            cdfLine(newVal, oldVal) { this.updateField("cdfLine", newVal, oldVal); },
            addtlRemitDate(newVal, oldVal) { this.updateField("addtlRemitDate", newVal, oldVal); },
            sectionID(newVal, oldVal) { this.updateField("sectionID", newVal, oldVal); },
            pocAmount(newVal, oldVal) { this.updateField("pocAmount", newVal, oldVal); },
            pocWhom(newVal, oldVal) { this.updateField("pocWhom", newVal, oldVal); },
            netFund(newVal, oldVal) { this.updateField("netFund", newVal, oldVal); },
            isSelected(newVal, oldVal) { this.updateField("isSelected", newVal, oldVal); },
            "viewModel.isSelected": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:isSelected", newValue);
            },
            "viewModel.applyOverride": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:applyOverride", newValue);
            },
            "viewModel.overrideUnderwriter": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:overrideUnderwriter", newValue);
            },
            "viewModel.buyerSellerAmountApplyOverride": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:buyerSellerAmountApplyOverride", newValue);
            },
            "viewModel.addtlRemitDate": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:addtlRemitDate", newValue);
            },
            "viewModel.pocAmount": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:pocAmount", newValue);
            },
            "viewModel.pocWhom": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:pocWhom", newValue);
            },
            "viewModel.netFund": function(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$emit("update:netFund", newValue);
            }
        },

        created() {
            this.refreshViewModel();
        },

        methods:{
            revertOverride(e) {
                this.viewModel.applyOverride = false;
                this.viewModel.buyerSellerAmountApplyOverride = false;
                this.viewModel.overrideUnderwriter = false;
                this.balancer.overrideUnderwriter = this.viewModel.overrideUnderwriter;
                this.balancer.amount = this.defaultAmount;
                if(this.isLoanHolder){
                    this.balancer.sellerPercent = this.rateOrderInfo.region.sellerPaysPercentageOwnersPremium;
                    this.balancer.balanceBySellerPercent();
                }
                else{
                    this.balancer.buyerPercent = this.rateOrderInfo.region.buyerPaysPercentageMortgagePremium;
                    this.balancer.balanceByBuyerPercent();
                }
                this.syncBalancerToViewModel();
            },
            onAmountChange(e) {
                this.balancer.amount = this.viewModel.amount;
                this.balancer.balanceByAmount();
                this.viewModel.applyOverride = true;
                this.syncBalancerToViewModel();
            },
            onCdfSectionChange(e) {
                if(_.isNil(e.event)) return;
                this.$emit("update:sectionID", this.viewModel.sectionID);
                this.$emit("values-changed");
            },
            onNonGfeChange(e) {
                if (_.isNil(e.event)) return;
                this.syncField("nonGfe");
            },
            onUnderwriterAmountChange(e) {
                // make sure that theres difference between the default
                // and the updated underwritersplit amount when user overrides value
                if(_.parseNumber(this.defaultUnderwriter, 0) !== _.parseNumber(this.viewModel.underwriterSplit, 0)) {
                    let underwriterSplit = this.balancer.parseAmount(this.viewModel.underwriterSplit);
                    this.balancer.uwSplitAmount = underwriterSplit;
                    this.balancer.overrideUnderwriter = true;
                    this.viewModel.underwriterSplit = this.balancer.uwSplitAmount;
                    this.viewModel.overrideUnderwriter = this.balancer.overrideUnderwriter;
                    this.syncUnderwriter();
                }
            },
            onBuyerPercentChange(e) {
                this.viewModel.buyerSellerAmountApplyOverride = true
                this.balancer.buyerPercent = this.viewModel.buyerPercent;
                this.balancer.balanceByBuyerPercent();
                this.syncBalancerToViewModel(["buyerPercent"]);
            },
            onSellerPercentChange(e) {
                this.viewModel.buyerSellerAmountApplyOverride = true
                this.balancer.sellerPercent = this.viewModel.sellerPercent;
                this.balancer.balanceBySellerPercent();
                this.syncBalancerToViewModel(["sellerPercent"]);
            },
            onBuyerAmountChange(e) {
                this.viewModel.buyerSellerAmountApplyOverride = true
                let buyerAmount = this.balancer.parseAmount(this.viewModel.buyerAmount);
                this.balancer.buyerAmount = buyerAmount;
                this.balancer.balanceByBuyerAmount();
                this.syncBalancerToViewModel(["buyerAmount"]);
            },
            onSellerAmountChange(e) {
                this.viewModel.buyerSellerAmountApplyOverride = true
                let sellerAmount = this.balancer.parseAmount(this.viewModel.sellerAmount);
                this.balancer.sellerAmount = sellerAmount;
                this.balancer.balanceBySellerAmount();
                this.syncBalancerToViewModel(["sellerAmount"]);
            },
            setBuyerSellerFocus(target = "none") {
                this.buyerFocused = target === "buyer";
                this.sellerFocused = target === "seller";
            },
            updateField(fieldName, newVal, oldVal) {
                if(newVal === oldVal || this.viewModel[fieldName] === newVal) return;
                this.viewModel[fieldName] = newVal;
                let balancerField = `_${fieldName}`;
                if(_.hasIn(this.balancer, balancerField)) {
                    this.balancer[balancerField] = newVal;
                }

                //RQO-19740 - Syncing overrides between basic premium and cdf premium for buyer and seller fields
                if (_.parseBool(this.buyerSellerAmountApplyOverride)) {
                    this.updateBalanceFromOverride(fieldName, newVal);
                }
            },
            updateBalanceFromOverride(fieldName, newVal) {
                let needSyncing = false;
                var args = [];
                if (fieldName === "buyerPercent") {
                    this.balancer.buyerPercent = newVal;
                    this.balancer.balanceByBuyerPercent();
                    needSyncing = true;
                    args = ["buyerPercent"];
                }

                if (fieldName === "buyerAmount") {
                    this.balancer.buyerAmount = newVal;
                    this.balancer.balanceByBuyerAmount();
                    needSyncing = true;
                    args = ["buyerAmount"];
                }

                if (fieldName === "sellerPercent") {
                    this.balancer.sellerPercent = newVal;
                    this.balancer.balanceBySellerPercent();
                    needSyncing = true;
                    args = ["sellerPercent"];
                }

                if (fieldName === "sellerAmount") {
                    this.balancer.sellerAmount = newVal;
                    this.balancer.balanceBySellerAmount();
                    needSyncing = true;
                    args = ["sellerAmount"];
                }

                if (needSyncing) {
                    this.syncBalancerToViewModel(args);
                }
            },
            syncCompany(companyObj) {
                this.viewModel.payeeCompanyID = _.get(companyObj, "companyID", null);
                this.viewModel.payeeCompanyName = _.get(companyObj, "companyName", null);
                this.viewModel.payeeContactID = _.get(companyObj, "contactID", null);
                this.syncField("payeeCompanyID");
                this.syncField("payeeCompanyName");
                this.syncField("payeeContactID");
            },
            syncBalancerToViewModel(args) {
                this.viewModel.amount = this.balancer.amount;
                this.viewModel.buyerPercent = this.balancer.buyerPercent;
                this.viewModel.buyerAmount = this.balancer.buyerAmount;
                this.viewModel.sellerPercent = this.balancer.sellerPercent;
                this.viewModel.sellerAmount = this.balancer.sellerAmount;
                this.viewModel.underwriterSplit = this.balancer.uwSplitAmount;
                if(_.gt(this.viewModel.taxRate, 0)) {
                    this.viewModel.taxAmount = this.balancer.taxAmount;
                }
                this.viewModel.total = this.balancer.taxTotal;
                this.syncAll(args);
            },
            refreshViewModel(){
                let vmKeys = _.keys(new PremiumLineItemModel);
                this.viewModel = new PremiumLineItemModel(_.pick(this, vmKeys));
                this.balancer = new RateBalancer(this.viewModel);
            },

            syncField(key, value) {
                let syncValue = _.isUndefined(value) ? this.viewModel[key] : value;
                if(!_.includes(this.syncFields, key) || this[key] === syncValue) return false;
                this.$emit(`update:${key}`, syncValue);
                return true;
            },

            syncViewModel() {
                let changed = false;
                _.each(this.viewModel, (v,k) => {
                    if(this.syncField(k)) changed = true;
                });
                return changed;
            },

            syncAll(args) {
                if(this.viewModel.applyOverride === true) {
                    this.viewModel.buyerSellerAmountApplyOverride = true;
                    this.viewModel.overrideUnderwriter = true;
                }
                let changed = this.syncViewModel();
                if(!changed) return;
                this.$emit("values-changed", args);
            },

            syncUnderwriter() {
                this.$emit("update:overrideUnderwriter", this.viewModel.overrideUnderwriter)
                this.$emit("update:underwriterSplit", this.viewModel.underwriterSplit);
                this.$emit("values-changed");
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } }); },
            clearSelection() {
                if(this.viewModel.isSelected) this.viewModel.isSelected = false;
            }
        }
    };
</script>
