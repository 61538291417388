<template>
    <section id="app-order-summary" :class="classAttr">
        <b-collapse
            id="app-order-summary-collapse"
            class="collapse-container"
            v-model="isExpanded"
            @shown="$events.emit(collapseToggleEvent, { isExpanded })"
            @hidden="$events.emit(collapseToggleEvent, { isExpanded })">
            <div class="row">
                <div class="col-12 col-lg-12 col-xl-9">
                    <div class="order-info-list">
                        <div class="col-info">
                            <div class="row">
                                <div class="col col-label"><span>Property:</span></div>
                                <div automation_id="spn_property" class="col col-value" :title="orderSummary.property">
                                    <router-link automation_id="lnk_property" :to="{ name:'oe:op:property', params: { propertyId: orderSummary.propertyID }}">{{orderSummary.property}}</router-link>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-label summary-label"><span>Buyer:</span></div>
                                <div automation_id="spn_buyer" class="col col-value" :title="orderSummary.buyer">
                                    <router-link automation_id="lnk_buyer" :to="{ name:'oe:oc:buyer', params: { buyerSellerId: orderSummary.buyerID } }">{{orderSummary.buyer}}</router-link>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-label"><span>Seller:</span></div>
                                <div automation_id="spn_seller" class="col col-value" :title="orderSummary.seller">
                                    <router-link automation_id="lnk_seller" :to="{ name:'oe:oc:seller', params: { buyerSellerId: orderSummary.sellerID } }">{{orderSummary.seller}}</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-info divider">
                            <div class="row">
                                <div class="col col-label">Region / Branch:</div>
                                <div automation_id="spn_region" class="col col-value" :title="orderSummary.region">
                                    <span>{{orderSummary.region}} / {{orderSummary.branch}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-label"><span>Underwriter:</span></div>
                                <div automation_id="spn_branch" class="col col-value" :title="orderSummary.branch">
                                    <span>{{orderSummary.underwriter}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-label"><span>Settlement Type:</span></div>
                                <div automation_id="spn_branch" class="col col-value" :title="orderSummary.branch">
                                    <span>{{settlementDescription}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-12 col-xl-3 mt-lg-0 mt-md-2 col-actions">
                    <div v-if="visibleActions.length > 0" class="row">
                        <template v-for="(action, index) in visibleActions">
                            <div v-if="action.key === 'action-spacer' || action.key === 'r2c-action-spacer'" :key="`${action.key}_${index}_spacer`" class="col col-4"></div>
                            <order-summary-action
                                v-else
                                :action="action"
                                :key="`${action.key}_${index}`"
                                :tooltip-placement="index > 2 ? 'bottom' : 'top'"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </b-collapse>
        <b-btn v-if="collapsible"
            v-rq-tooltip.hover.left="{ title: isExpanded ? 'Collapse Summary' : 'Expand Summary' }"
            variant="secondary"
            :class="{ 'btn-icon btn-collapse-toggle':true, 'summary-collapsed':!isExpanded }"
            @click="isExpanded=!isExpanded">
            <FontAwesomeIcon icon="fas fa-caret-up" :rotation="iconRotation" />
        </b-btn>
    </section>
</template>

<script>
    import { mapState } from "vuex";
    import OrderSummaryAction from "./OrderSummaryAction";
    import { SettlementTypeOption, SETTLEMENT_TYPE } from '@settlement/models';
    const MAX_VISIBLE_ACTIONS = 6;

    export default {
        name: "OrderSummary",
        components: { OrderSummaryAction },
        props: {
            hiddenActions: { default: () => [] },
            collapsible: { type: Boolean, default: false },
            collapse: { type: Boolean, default: false }
        },
        data() {
            return {
                collapseToggleEvent: "order-summary::collapse-toggled",
                isExpanded: !this.collapse
            };
        },
        computed: {
            ...mapState({
                orderSummary: state => state.orders.orderSummary,
                order: state => state.orders.order,
                readOnly: state => state.isPageReadOnly,
                fileInUsePrompt: state => state.fileInUsePrompt,
                user: state => state.authentication.session.user,
                settlementDescription: state => `${SettlementTypeOption.displayLabel(state.orders.orderSummary.settlementStatementType,
                                                    _.parseBool(state.orders.orderSummary.isWithOutSeller),
                                                    _.parseBool(state.orders.orderSummary.includeAlta),
                                                    _.parseBool(state.orders.orderSummary.useTemplatePage2A2B))}`
            }),
            visibleActions() {
                let routeActions = _.get(this, "$route.meta.actions", []) || [];
                let actions = _.isEmpty(this.hiddenActions)
                    ? routeActions
                    : _.filter(routeActions, a => !_.includes(this.hiddenActions, a.key));
                return this.arrangeActions(actions);
            },
            classAttr() {
                let theme = _.get(this, "$route.meta.theme", "default") || "default";
                return `order-info-header theme-${theme}`;
            },
            iconRotation() {
                if(!this.isExpanded) return 180;
                return 0;
            }
        },

        

        methods: {
            arrangeActions(actions) {
                if(actions.length <= MAX_VISIBLE_ACTIONS) return actions;
                let result = _.take(actions, MAX_VISIBLE_ACTIONS - 1);
                result.push({
                    key: "moreActions",
                    text: "More",
                    tooltip: "More",
                    children: _.takeRight(actions, actions.length - (MAX_VISIBLE_ACTIONS - 1)),
                    disableWhenReadOnly: true
                })
                return result;
            },
        }
    };
</script>
