<template>
    <div :class="classAttr">
        <template v-if="isCheckbox">
            <b-form-checkbox
                :automation_id="searchField.automationId"
                :name="searchField.fieldName"
                v-model="searchField.fieldValue"
                @change="onFieldValueChanged">
                {{searchField.fieldLabel}}
            </b-form-checkbox>
            <span v-if="searchField.isRequired && enableRequiredIndicator"
                class="required-indicator">*
            </span>
        </template>
        <template v-else>
            <label :for="searchField.fieldName">
                {{searchField.fieldLabel}}
            </label>
            <div v-if="isDateRange" class="input-group">
                <rqdx-date-box
                    :automation_id="`${searchField.automationId}1`"
                    :id="`${searchField.automationId}_rangeValue1`"
                    :class="formClass"
                    v-model="rangeValue1"
                    @valueChanged="onFieldValueChanged"
                    default-button-display
                />
                <span class="input-group-text">
                    <FontAwesomeIcon icon="fas fa-minus" />
                </span>
                <rqdx-date-box
                    :automation_id="`${searchField.automationId}2`"
                    :id="`${searchField.automationId}_rangeValue2`"
                    :class="formClass"
                    v-model="rangeValue2"
                    @valueChanged="onFieldValueChanged"
                    default-button-display
                />
            </div>

            <div v-else-if="isTextRange" class="input-group">
                <input :automation_id="`${searchField.automationId}1`"
                    :id="`${searchField.automationId}_rangeValue1`"
                    :name="searchField.fieldName"
                    :class="formClass"
                    type="text" v-model="rangeValue1"
                    @change="onFieldValueChanged"
                />
                <span class="input-group-text">
                    <FontAwesomeIcon icon="fas fa-minus" />
                </span>
                <input :automation_id="`${searchField.automationId}2`"
                    :id="`${searchField.automationId}_rangeValue2`"
                    :name="searchField.fieldName"
                    :class="formClass"
                    type="text"
                    v-model="rangeValue2"
                    @change="onFieldValueChanged"
                />
            </div>

            <div v-else-if="isNumRange" class="input-group">
                <rqInputNumber :automation_id="`${searchField.automationId}1`"
                    :id="`${searchField.automationId}_rangeValue2`"
                    :class="formClass"
                    defaultValue=""
                    :prefix="searchField.prefix"
                    :decimals="searchField.decimals"
                    :commas="searchField.commas"
                    v-model="rangeValue1"
                    @change="onFieldValueChanged"
                />
                <span class="input-group-text">
                    <FontAwesomeIcon icon="fas fa-minus" />
                </span>
                <rqInputNumber :automation_id="`${searchField.automationId}2`"
                    :id="`${searchField.automationId}_rangeValue2`"
                    :class="formClass"
                    defaultValue=""
                    :prefix="searchField.prefix"
                    :decimals="searchField.decimals"
                    :commas="searchField.commas"
                    v-model="rangeValue2"
                    @change="onFieldValueChanged"
                />
            </div>

            <textarea v-else-if="isTextArea"
                :automation_id="searchField.automationId"
                :name="searchField.fieldName"
                :class="formClass"
                v-model="searchField.fieldValue"
                @change="onFieldValueChanged">
            </textarea>

            <company-picker
                v-else-if="isCompanyPicker"
                :automation_id="searchField.automationId"
                :companyRoleName="searchField.pickerRoleName"
                :companyRoleId="searchField.pickerRoleId"
                :showContacts="false"
                :dialogTitle="searchField.pickerDialogTitle"
                v-model="fieldCompany"
            />

            <dx-select-box v-else-if="isSelectList"
                ref="selectBoxField"
                :input-attr="{ automation_id: searchField.automationId }"
                :items="searchField.fieldOptions"
                displayExpr="name"
                valueExpr="id"
                :ignoreUserAccess="true"
                v-model="searchField.fieldValue"
                @change="onFieldValueChanged"
            />

            <input v-else
                :automation_id="searchField.automationId"
                :name="searchField.fieldName"
                :class="formClass"
                type="text"
                v-model="searchField.fieldValue"
                @change="onFieldValueChanged"
            >

        </template>
        <div v-if="enableRemoval" class="field-actions">
            <button class="btn-icon" type="button" @click="removeThisField" v-rq-tooltip.hover.top="{ html: true, title: '<small>Remove Field</small>' }">
                <FontAwesomeIcon icon="fas fa-times-circle" />
            </button>
        </div>
    </div>
</template>
<script>
    import CompanyPicker from "@/shared/components/rq/CompanyPicker";
    import { SearchField } from "../models";

    export default {
        props: {
            searchField: { default: new SearchField() },
            enableRemoval: { type: Boolean, default: false },
            enableRequiredIndicator: { type: Boolean, default: false },
            layoutConfig: { default: () => { return {}; }  },
            size: {
                default: ""
            }
        },
        components: { CompanyPicker },
        data () {
            const self = this;
            return {
                rangeValue1: "",
                rangeValue2: "",
                fieldCompany: { companyID: null, companyName: null }
            };
        },
        watch: {
            "searchField.fieldValue" (fieldValue) {
                if (!fieldValue) {
                    this.rangeValue1 = "";
                    this.rangeValue2 = "";
                }
                this.$emit("fieldChanged", this.searchField);
            },

            rangeValue1 (fieldValue) {
                this.setRangeValue(fieldValue, this.rangeValue2);
            },

            rangeValue2 (fieldValue) {
                this.setRangeValue(this.rangeValue1, fieldValue);
            },

            fieldCompany:{
                handler(newVal, oldVal) {
                    if(newVal === oldVal || (!_.isNil(newVal) && !_.isNil(oldVal) && newVal.companyID === oldVal.companyID)) return;
                    this.searchField.fieldValue = newVal.companyID;
                },
                deep:true
            }
        },
        computed: {
            isTextBox () { return this.searchField.fieldType === "text"; },
            isNumBox () { return this.searchField.fieldType === "number"; },
            isSelectList () { return this.searchField.fieldType === "select"; },
            isCompanyPicker () { return this.searchField.fieldType === "picker"; },
            isCheckbox () { return this.searchField.fieldType === "checkbox"; },
            isTextArea () { return this.searchField.fieldType === "textarea"; },
            isTextRange () { return this.searchField.fieldType === "text" && this.searchField.isRange; },
            isNumRange () { return this.searchField.fieldType === "number" && this.searchField.isRange; },
            isDateRange () { return this.searchField.fieldType === "date" && this.searchField.isRange; },
            classAttr() {
                let classes = ["form-group", "form-group-sm", "search-field"];
                if(this.searchField.validated && !this.searchField.isValid) classes.push("has-error");
                if(this.searchField.isRequired && this.enableRequiredIndicator) classes.push("form-required");
                return classes;
            },
            formClass() {
                let classes = ["form-control"]
                if(this.size === "sm")
                    classes.push("form-control-sm");
                return  classes;
            },
            selectBoxClass() {
                let classes = "form-control"
                if(this.size === "sm")
                    classes = `${classes} form-control-sm`;
                return  classes;
            }
        },
        created() { _.assign(this.layoutClasses, this.layoutConfig); },
        methods: {
            removeThisField () {
                this.searchField.fieldValue = null;
                this.searchField.visible = false;
            },
            setRangeValue (lesserVal, greaterVal) {
                this.rangeValue1 = _.isNil(lesserVal) ? "" : lesserVal;
                this.rangeValue2 = _.isNil(greaterVal) ? "" : greaterVal;

                this.validateRange();

                if (!this.searchField.isValid) return;
                this.searchField.fieldValue = `${this.rangeValue1}|${this.rangeValue2}`;
            },
            validateRange () {
                const self = this;
                let isValid = true;
                let message = "";

                if (!this.rangeValue1 || !this.rangeValue2) {
                    self.searchField.isValid = isValid;
                    self.searchField.validationMessage = message;
                    return;
                }

                isValid = self.isDateRange
                    ? (new Date(this.rangeValue1) < new Date(this.rangeValue2))
                    : _.parseNumber(this.rangeValue1, 0) < _.parseNumber(this.rangeValue2, 0);

                if (!isValid) {message = `Invalid range for field, "${self.searchField.fieldLabel}".`;}

                self.searchField.isValid = isValid;
                self.searchField.validationMessage = message;
            },
            onFieldValueChanged (e) {
                const self = this;
                // self.$emit("fieldChanged", self.searchField);
                if(self.searchField.validated) self.searchField.validate();
            }
        }
    };
</script>
