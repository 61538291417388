<template>
    <div class="content-wrapper rq-container">
        <rq-banner
            variant="error"
            title="Please correct the error below to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row">
            <div class="col form-group form-required" :class="{ 'has-error': v$.dateValue.$error }">
                <label for="dtp_date">{{itemTypeName}}</label>
                <rqdx-date-box
                    id="dtp_date"
                    type="datetime"
                    v-model="v$.dateValue.$model"
                />
                <rq-validation-feedback>Date value is required.</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";

    export default {
        name: "ChangeDateForm",

        props: {
            itemTypeName: { type: String, default: null },
            items: { type: Array, default: null },
            orderId: { type: Number, default: null },
        },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                dateValue: "",
                altError: "",
                showBanner: false
            }
        },

        created(){
            const self = this;
            if (self.items.length !== 1) return;
            if (self.itemTypeName === "Due Date") {
                self.dateValue = self.items[0].dueDate;
            } else if (self.itemTypeName === "Start Date") {
                self.dateValue = self.items[0].startDate;
            }
        },

        validations() {
            return {
                dateValue: { required }
            };
        },

        methods: {
            isValid() {
                const self = this;
                self.v$.$touch();
                self.showBanner = true;
                return !self.v$.$error;
            },
            save() {
                const self = this;

                if(!self.isValid()) return Promise.resolve(false);

                let newDate = self.dateValue;
                _.forEach(self.items, (item) => {
                    if (self.itemTypeName === "Due Date") {
                        item.dueDate = newDate;
                    }else if (self.itemTypeName === "Start Date") {
                        item.startDate = newDate;
                    }
                });
                let saveRequest = { data: self.items };
                let apiPromise = {};
                if (self.itemTypeName === "Due Date") {
                    apiPromise = self.$api.OrderWorkflowApi.updateDueDates(saveRequest);
                }else if (self.itemTypeName === "Start Date") {
                    apiPromise = self.$api.OrderWorkflowApi.updateStartDates(saveRequest);
                }
                return self.$rqBusy
                    .wait(apiPromise)
                    .then(() => true)
                    .catch(err => err);
            }
        }
    }
</script>