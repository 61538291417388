<template>
    <div class="content-wrapper">
        <loan-terms-hud-2010 v-if="isHud"></loan-terms-hud-2010>
        <loan-terms v-if="isCdf"></loan-terms>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import LoanTermsHud2010 from "./LoanTermsHud2010";
import LoanTerms from "./LoanTerms";
import { LoanTermSectionHud2010DataModel, SETTLEMENT_TYPE } from '@settlement/models';
export default {
    components: { LoanTermsHud2010, LoanTerms },
    data() {
        return {

        }
    },
    computed:{
        ...mapState({
            readOnly: state => state.isPageReadOnly,
            orderId: state => state.orders.orderId,
            loans: state => state.orders.loans || [],
            settlementType: state => _.parseNumber(state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF)
        }),
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
        isCdf() { return this.settlementType === SETTLEMENT_TYPE.CDF; },
        isHud() { return this.isHud2010; },
        isHud1974() { return this.settlementType === SETTLEMENT_TYPE.HUD_1974; },
        isHud2010() { return this.settlementType === SETTLEMENT_TYPE.HUD_2010; },
    },
}
</script>