<template>
    <div class="content-wrapper">
        <rq-page-section class="premium-detail h-theme"
            :no-data="noData"
            no-data-text="No calculation details to display. Calculate to update details."
            borderless>

            <!-- Coverage Rounding -->
            <div id="coverage-rounding" v-if="coverageRoundingItems.length > 0" class="row">
                <div class="col">
                    <h5>Coverage Rounding</h5>
                    <table aria-busy="false" aria-colcount="4" aria-rowcount="-1" class="table table-striped table-fixed table-borderless table-sm table-responsive-sm">

                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Rule</th>
                                <th>To Nearest</th>
                                <th>Rounded Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in coverageRoundingItems" :key="item.clientKey">
                                <td>{{formatMoney(item.fromAmount)}}</td>
                                <td>{{formatMoney(item.toAmount)}}</td>
                                <td>{{item.rule}}</td>
                                <td>{{formatMoney(item.toNearest)}}</td>
                                <td>{{formatMoney(item.roundedAmount)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-auto">
                    <aside class="spacer"></aside>
                </div>
            </div>

            <!-- Insurance -->
            <div id="title-insurance" v-if="titleInsuranceItems.length > 0" class="row">
                <div class="col">
                    <span class="h5">Insurance</span>
                    <table aria-busy="false" aria-colcount="4" aria-rowcount="-1" class="table table-striped table-fixed table-borderless table-sm table-responsive-sm">

                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Rate</th>
                                <th>Per</th>
                                <th>Max</th>
                                <th>Premium</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in titleInsuranceItems" :key="item.clientKey">
                                <td>{{formatMoney(item.fromAmount)}}</td>
                                <td>{{formatMoney(item.toAmount)}}</td>
                                <td>{{formatMoney(item.rate)}}</td>
                                <td>{{formatMoney(item.per)}}</td>
                                <td>{{formatMoney(item.maxRate)}}</td>
                                <td>{{formatMoney(item.premium)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-auto">
                    <aside class="card">
                        <div class="card-header">Insurance Linked Premiums</div>
                        <div class="card-body">
                            <div>{{formatIndicator(calculationDetail.titleInsuranceAdjustmentPctIndicator)}} Percent: {{formatPercentage(calculationDetail.titleInsuranceAdjustmentPct)}}</div>
                            <div>{{formatIndicator(calculationDetail.titleInsuranceAdjustmentAmountIndicator)}} Amount: {{formatMoney(calculationDetail.titleInsuranceAdjustmentAmount)}}</div>
                            <div> Adjusted Rate: {{formatMoney(calculationDetail.titleInsuranceTotal)}}</div>
                        </div>
                    </aside>
                </div>
            </div>

            <!-- Re-issue Credit -->
            <div id="reissue-credit" v-if="reissueCreditItems.length > 0" class="row">
                <div class="col">
                    <span class="h5">Re-Issue Credit</span>
                    <table aria-busy="false" aria-colcount="4" aria-rowcount="-1" class="table table-striped table-fixed table-borderless table-sm table-responsive-sm">

                        <thead>
                            <tr>
                                <template v-if="!ricByAmount && !ricByPercentage">
                                    <th>From Year</th>
                                    <th>To Year</th>
                                </template>
                                <template v-if="!ricByYearAndRatePer && !ricByYearAndPercentage">
                                    <th>From Amount</th>
                                    <th>To Amount</th>
                                </template>
                                <template v-if="!ricByYearAndPercentage && !ricByPercentage">
                                    <th>Rate</th>
                                    <th>Per</th>
                                </template>
                                <th v-if="ricByAmount">Max</th>
                                <th v-if="ricByPercentage">Percent</th>
                                <th>Discount</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in reissueCreditItems" :key="item.clientKey">
                                <template v-if="!ricByAmount && !ricByPercentage">
                                    <td>{{item.fromYears}}</td>
                                    <td>{{item.toYears}}</td>
                                </template>
                                <template v-if="!ricByYearAndRatePer && !ricByYearAndPercentage">
                                    <td>{{formatMoney(item.fromAmount)}}</td>
                                    <td>{{formatMoney(item.toAmount)}}</td>
                                </template>
                                <template v-if="!ricByYearAndPercentage && !ricByPercentage">
                                    <td>{{formatMoney(item.ratePerThousand)}}</td>
                                    <td>{{formatMoney(item.per)}}</td>
                                </template>
                                <td v-if="ricByAmount">{{formatMoney(item.maxRate)}}</td>
                                <td v-if="ricByPercentage">{{formatPercentage(item.percentCredit)}}</td>
                                <td>{{formatMoney(item.discount)}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="col-auto">
                    <aside class="card">
                        <div class="card-header">Reissue Linked Premiums</div>
                        <div class="card-body">
                            <div>{{formatIndicator(calculationDetail.ricAdjustmentPctIndicator)}} Percent: {{formatPercentage(calculationDetail.ricAdjustmentPct)}}</div>
                            <div>{{formatIndicator(calculationDetail.ricAdjustmentAmountIndicator)}} Amount: {{formatMoney(calculationDetail.ricAdjustmentAmount)}}</div>
                            <div> Adjusted Credit: {{formatMoney(calculationDetail.ricTotal)}}</div>
                        </div>
                    </aside>
                </div>
            </div>

            <!-- Premium Rounding -->
            <div id="premium-rounding" v-if="premiumRoundingItems.length > 0" class="row">
                <div class="col">
                    <span class="h5">Premium Rounding</span>
                    <table aria-busy="false" aria-colcount="4" aria-rowcount="-1" class="table table-striped table-fixed table-borderless table-sm table-responsive-sm">

                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Rule</th>
                                <th>To Nearest</th>
                                <th>Rounded Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in premiumRoundingItems" :key="item.clientKey">
                                <td>{{formatMoney(item.fromAmount)}}</td>
                                <td>{{formatMoney(item.toAmount)}}</td>
                                <td>{{item.rule}}</td>
                                <td>{{formatMoney(item.toNearest)}}</td>
                                <td>{{formatMoney(item.roundedAmount)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-auto">
                    <aside class="spacer"></aside>
                </div>
            </div>

            <!-- Simultaneous -->
            <div id="simultaneous" v-if="simultaneousItems.length > 0" class="row">
                <div class="col">
                    <span class="h5">Simultaneous</span>
                    <table aria-busy="false" aria-colcount="4" aria-rowcount="-1" class="table table-striped table-fixed table-borderless table-sm table-responsive-sm">

                        <thead>
                            <tr>
                            <th>Loan Policy</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in simultaneousItems" :key="item.clientKey">
                                <td>{{item.policyType}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="col-auto">
                    <aside class="card">
                        <div class="card-header">Simultaneous Linked Premiums</div>
                        <div class="card-body">
                            <!-- <div class="w-100 text-muted text-center p-3">Additional Details</div> -->
                            <div>Amount: {{formatMoney(calculationDetail.simultaneousTotal)}}</div>
                        </div>
                    </aside>
                </div>
            </div>

            <!-- Underwriter Split -->
            <div id="underwriter-split" v-if="underwriterSplitItems.length > 0" class="row">
                <div class="col">
                    <span class="h5">Underwriter Split</span>
                    <table aria-busy="false" aria-colcount="4" aria-rowcount="-1" class="table table-striped table-fixed table-borderless table-sm table-responsive-sm">

                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Underwriter %</th>
                                <th>Underwriter Rate</th>
                                <th>Per</th>
                                <th>Premium Split</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in underwriterSplitItems" :key="item.clientKey">
                                <td>{{formatMoney(item.fromAmount)}}</td>
                                <td>{{formatMoney(item.toAmount)}}</td>
                                <td>{{formatPercentage(item.splitPercent)}}</td>
                                <td>{{formatMoney(item.rate)}}</td>
                                <td>{{formatMoney(item.per)}}</td>
                                <td>{{formatMoney(item.calculatedUnderwriterSplit)}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="col-auto">
                    <aside class="card">
                        <div class="card-header">Underwriter Premiums</div>
                        <div class="card-body">
                            <div>{{formatIndicator(calculationDetail.underwriterSplitAdjustmentPctIndicator)}} Percent: {{formatPercentage(calculationDetail.underwriterSplitAdjustmentPct)}}</div>
                            <div>{{formatIndicator(calculationDetail.underwriterSplitAdjustmentAmountIndicator)}} Amount: {{formatMoney(calculationDetail.underwriterSplitAdjustmentAmount)}}</div>
                            <div>Adjusted Split: {{formatMoney(calculationDetail.underwriterSplitTotal)}}</div>
                        </div>
                    </aside>
                </div>
            </div>

            <!-- CDF -->
            <div id="cdf-calculations" v-if="cdfCalculationItems.length > 0" class="row">
                <div class="col">
                    <span class="h5">CDF</span>
                    <table aria-busy="false" aria-colcount="4" aria-rowcount="-1" class="table table-striped table-fixed table-borderless table-sm table-responsive-sm">

                        <thead>
                            <tr>
                                <th>Owner's Full Rate</th>
                                <th>Simultaneous Loan Amount</th>
                                <th>Full Loan Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>{{formatMoney(cdfCalculatedItem.ownersFullRate)}}</td>
                                <td>{{formatMoney(cdfCalculatedItem.fullLoanRate)}}</td>
                                <td>{{formatMoney(cdfCalculatedItem.loanAmount)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-auto">
                    <aside class="spacer"></aside>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import {
        RateCalculationDetailDto,
        RateEngineCoverageRoundingDto,
        RateEngineTitleInsuranceRateDto,
        RateEnginePremiumRoundingDtoi,
        RateEnginePremiumRoundingDto,
        RateEngineReissueCreditDto,
        RateEngineUnderwriterSplitDto,
        RateEngineSimultaneousDto,
        CdfCalculationDetailDto,
        ReissueCreditCalculationMethod
    } from "@settlement/models";

    export default {
        name: "PremiumDetail",
        props: {
            orderRateCalculationID: { type: Number, default: 0 }
        },

        data() {
            return {
                calculationDetail: {},
                coverageRoundingItems: [],
                titleInsuranceItems: [],
                reissueCreditItems: [],
                premiumRoundingItems: [],
                underwriterSplitItems: [],
                simultaneousItems: [],
                cdfCalculationItems: [],
                cdfCalculatedItem: {}
            };
        },

        computed: {
            ...mapState({
                calculationDetails: state => state.rateEngine.calculationDetails
            }),
            noData() { return _.isEmpty(this.calculationDetail); },
            ricByAmount() { return this.calculationDetail.reissueCreditCalculationMethodID === ReissueCreditCalculationMethod.ByAmount; },
            ricByYearAndPercentage() { return this.calculationDetail.reissueCreditCalculationMethodID === ReissueCreditCalculationMethod.ByYearAndPercentage; },
            ricByYearAmount() { return this.calculationDetail.reissueCreditCalculationMethodID === ReissueCreditCalculationMethod.ByYearAmount; },
            ricByPercentage() { return this.calculationDetail.reissueCreditCalculationMethodID === ReissueCreditCalculationMethod.ByPercentage; },
            ricByYearAndRatePer() { return this.calculationDetail.reissueCreditCalculationMethodID === ReissueCreditCalculationMethod.ByYearAndRatePer; }
        },

        watch: {
            calculationDetails(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.loadCalculationDetail();
            },
            orderRateCalculationID(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.loadCalculationDetail();
            }
        },

        created() {
            this.loadCalculationDetail();
        },

        methods: {
            formatIndicator(v) { return `( ${v} )`; },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0)); },

            formatPercentage(v) { return `${accounting.formatNumber(v, 3)}%`; },

            loadCalculationDetail() {
                const self = this;
                let detail = _.find(self.calculationDetails, c => c.trackingId === self.orderRateCalculationID);
                if(_.isNil(detail)) return;

                //Calculated Detail
                self.calculationDetail = new RateCalculationDetailDto(detail);

                // Coverage Rounding
                self.coverageRoundingItems = _.map(detail.coverageRoundingRule, item => new RateEngineCoverageRoundingDto(item));

                // Title Insurance
                self.titleInsuranceItems = _.map(detail.rateRules, item => new RateEngineTitleInsuranceRateDto(item));

                // Re-issue credits
                self.reissueCreditItems = _.map(detail.ricRules, item => new RateEngineReissueCreditDto(item));

                // Premium Rounding
                self.premiumRoundingItems = _.map(detail.premiumRoundingRules, item => new RateEnginePremiumRoundingDto(item));

                // Underwriter Split
                self.underwriterSplitItems = _.map(detail.underwriterSplit, item => new RateEngineUnderwriterSplitDto(item));

                // Simultanous
                self.simultaneousItems = _.map(detail.simultaneousRules, item => new RateEngineSimultaneousDto(item));

                // CDF Calculations
                self.cdfCalculationItems = _.map(detail.cdfCalculationDetail, item => new CdfCalculationDetailDto(item));
                self.cdfCalculatedItem = new CdfCalculationDetailDto( detail.cdfCalculationDetail);
            }
        }
    };
</script>

<style lang="scss">
</style>