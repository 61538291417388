<template>
    <div v-if="isHud2010" class="content-wrapper gfe-section">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="GFE And HUD-1 Comparison" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button automation_id="btn_view_allocations"
                            ref="btn_view_allocations"
                            variant="theme"
                            @click="onShowBucketAllocations"
                            v-rq-tooltip.hover.top="{ ttile: `View Allocations` }"
                            v-focus>
                            View Allocations
                        </b-button>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="section5000Grid"
            automation_id="dg_section5000_grid"
            :actions="selectionGridActions"
            :config="gridSection5000Config"
            :data-source="gridSection5000DataSource"
            export-file-name="hud-gfe-cannot-change"
            v-model:validation-errors="validationErrors"
            hide-search
            :rq-editable="!readOnly"
            @editorPreparing="onEditorPreparing"
            @revert="onRevertItems"
            @delete="onDeleteSection5000Items"
            focus-after-insert="first-row"
            title="Charges That Cannot Increase">
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_section_8000"
                            variant="theme"
                            @click="onAddSection5000Action"
                            :disabled="readOnly">Add
                        </b-btn>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>

        <rqdx-action-data-grid
            ref="section6000Grid"
            automation_id="dg_section6000_grid"
            :actions="selectionGridActions"
            :config="gridSection6000Config"
            :data-source="gridSection6000DataSource"
            export-file-name="hud-gfe-cannot-increase-10"
            v-model:validation-errors="validationErrors"
            hide-search
            :rq-editable="!readOnly"
            @editorPreparing="onEditorPreparing"
            @revert="onRevertItems"
            @delete="onDeleteSection6000Items"
            focus-after-insert="first-row"
            title="Charges That Cannot Increase More Than 10%">
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_section_6000"
                            variant="theme"
                            @click="onAddSection6000Action"
                            :disabled="readOnly">Add
                        </b-btn>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>

        <rq-page-section borderless>
            <div class="row">
                <div class= "d-flex justify-content-end col">
                    <span class="column-title">INCREASE BETWEEN GFE AND HUD-1 CHANGES: {{gfeIncreaseFormat}} OR {{gfePercentage}}%</span>
                </div>
            </div>
        </rq-page-section>

        <rqdx-action-data-grid
            ref="section8000Grid"
            automation_id="dg_section8000_grid"
            :actions="selectionGridActions"
            :config="gridSection8000Config"
            :data-source="gridSection8000DataSource"
            export-file-name="hud-gfe-can-change"
            v-model:validation-errors="validationErrors"
            hide-search
            :rq-editable="!readOnly"
            @editorPreparing="onEditorPreparing"
            @revert="onRevertItems"
            @delete="onDeleteSection8000Items"
            focus-after-insert="first-row"
            title="Charges That Can Change">
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_section_8000"
                            variant="theme"
                            @click="onAddSection8000Action"
                            :disabled="readOnly">Add
                        </b-btn>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
    <div v-else class="content-wrapper">
        <rq-no-data text="GFE is only available for 3-Page HUD" />
    </div>
</template>

<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { GlobalEventManager } from "@/app.events";
    import { HudLine, HudLineTypes } from "@settlement/models";
    import { HudGFECanChangeChargeDto, HudGFECannotChangeChargeDto } from "./models";
    import BucketAllocation from "./BucketAllocation";
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        name: 'GoodFaithEstimate',

        mixins: [BaseSettlementMixin],

        data() {
            return {
                hudLines: [],
                hudUnallocationItems: [],
                section5000Key: "hudgfeChargesThatCannotChangeID",
                section6000Key: "hudgfeChargesThatCannotChangeID",
                section8000Key: "hudgfeChargesThatCanChangeID",
                hudSection5000Items: [],
                hudSection6000Items: [],
                hudSection8000Items: [],
                selectionGridActions: [],
                deletedSection5000Items: [],
                deletedSection6000Items: [],
                deletedSection8000Items: [],
                originalSection5000Items: [],
                originalSection6000Items: [],
                originalSection8000Items: [],
                gfeSection6000Total: 0,
                hudSection6000Total: 0,
                gfeIncreaseValue: 0,
                gfeIncreasePercentValue: 0
            };
        },

        watch: {
            selectedLoanId(newVal, oldVal) {
                if (newVal === oldVal || !this.isHud2010) return;
                this.mapSettlementStatement();
            },
            selectedView(newVal, oldVal) {
                if (newVal === oldVal || !this.isHud2010) return;
                this.mapSettlementStatement();
            }
        },

        computed: {
            HudLineTypes() { return HudLineTypes; },
            gridSection5000Instance () { return this.$refs.section5000Grid.gridInstance; },
            gridSection6000Instance () { return this.$refs.section6000Grid.gridInstance; },
            gridSection8000Instance () { return this.$refs.section8000Grid.gridInstance; },
            hudMainId() { return this.hudLines.length > 0 ? this.hudLines[0].hudMainID : 0; },
            summaryLine() {
                const self = this;
                return {
                    totalItems: [
                        {
                            column: "description",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data){
                                return "TOTALS";
                            }
                        },
                        {
                            column: "amount",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data){
                                return self.formatMoney(data.value);
                            }
                        },
                        {
                            column: "hudLineAmount",
                            summaryType: "sum",
                            valueFormat: "currency",
                            customizeText: function(data){
                                return self.formatMoney(data.value);
                            }
                        },
                    ],
                }
            },
            gfeIncreaseFormat() {
                return this.formatMoney(this.gfeIncreaseValue);
            },
            gfePercentage() {
                return this.gfeIncreasePercentValue;
            }
        },

        created() {
            const self = this;
            self.baseInit();
            self.selectionGridActions = [
                {
                    name: "Delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(_.some(e.data, item => item.requiredLine === true)) {
                            return "Unselect required lines to delete";
                        } else {
                            return false;
                        }
                    },
                    tooltip: `Delete Charge(s)`
                },
                {
                    name: "Revert",
                    text: "Revert",
                    eventName: "revert",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        if (self.readOnly) return self.readOnly;

                        if(_.some(e.data, item => !item.canRevert)) {
                            return "Unselect lines that are overridden";
                        }
                        else
                            return false;
                    },
                    tooltip: `Revert Charge(s)`
                }
            ];

            self.initSection5000GridConfig();
            self.initSection6000GridConfig();
            self.initSection8000GridConfig();
            self.fetchData();
        },

        methods: {
            onShowBucketAllocations(){
                const self = this;
                self.fetchHudUnallocationItems();

                self.$dialog.open({
                    title: "GFE Allocation",
                    height: "700",
                    width: "1000",
                    props: {
                        unallocatedItems: self.hudUnallocationItems,
                        loanId: self.selectedLoanId
                    },
                    component: BucketAllocation,
                    onOk: function(e) {
                        return e.component.save()
                            .then(result => {
                                return self.fetchHudLines(self.selectedLoanId)
                                    .then(() => { self.fetchData();})
                                    .then(() => { self.refreshGrids(); return true;});
                            });
                    },
                });
            },
            fetchHudLines() {
                const self = this;
                if(self.selectedLoanId === 0) return Promise.resolve(true);
                let getHudLinePromise = self.$api.HudAddendumsApi.getHudLines(self.selectedLoanId);

                return self.$rqBusy.wait(getHudLinePromise).then(results => {
                    self.hudLines = _.map(results, result => new HudLine(result));
                    self.fetchHudUnallocationItems();
                });
            },
            fetchHudUnallocationItems() {
                const self = this;
                self.hudUnallocationItems.length = 0;

                _.forEach(self.hudLines, (item) => {
                    if (_.parseNumber(item.gfeBucket, 0) !== 0)
                        return;

                    if (_.includes([801, 802, 803, 901, 903, 1001, 1104, 1203], item.line)) // exclude these defaulted lines
                        return;

                    if (_.includes([804, 805, 806, 807, 902, 1101, 1103], item.line) ||
                        (item.line >= 808 && item.line < 900 && item.lineType === 13) ||
                        (item.line >= 904 && item.line <= 999 && _.includes([13, 20], item.lineType)) ||
                        (item.line > 1300 && item.line < 1400 && item.lineType === 11) ||
                        (item.line > 700 && _.includes(["B", "S", "L"], item.pocWhom || "") && _.includes([8,9,10,11,13,14,15,16,17,18,20],item.lineType))) {

                        let derivedAmount = self.getDerivedHudAmount(item);
                        if (derivedAmount > 0) {
                            item.derivedAmount = derivedAmount;
                            self.hudUnallocationItems.push(item);
                        }
                    }
                });

                return self.hudUnallocationItems;
            },

            setSectionItems(results) {
                const self = this;
                self.hudSection5000Items = _.map(results.hudSection5000Items, result => new HudGFECannotChangeChargeDto(result, self.hudMainId));
                self.hudSection6000Items = _.map(results.hudSection6000Items, result => new HudGFECannotChangeChargeDto(result, self.hudMainId));
                self.hudSection8000Items = _.map(results.hudSection8000Items, result => new HudGFECanChangeChargeDto(result, self.hudMainId));
                self.originalSection5000Items = _.map(results.hudSection5000Items, result => new HudGFECannotChangeChargeDto(result, self.hudMainId));
                self.originalSection6000Items= _.map(results.hudSection6000Items, result => new HudGFECannotChangeChargeDto(result, self.hudMainId));
                self.originalSection8000Items = _.map(results.hudSection8000Items, result => new HudGFECanChangeChargeDto(result, self.hudMainId));
                self.updateIncreaseValues();
            },

            fetchData() {
                const self = this;

                self.deletedSection5000Items = [];
                self.deletedSection6000Items = [];
                self.deletedSection8000Items = [];

                if(self.selectedLoanId === 0) return Promise.resolve(true);

                let apiPromises = [
                    self.$api.HudGFEApi.getGFEs(self.selectedLoanId),
                    self.$api.HudAddendumsApi.getHudLines(self.selectedLoanId)
                ];
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(results => {
                        self.setSectionItems(results[0]);
                        self.hudLines = _.map(results[1], result => new HudLine(result));
                        self.refreshGrids();
                    });
            },
            getDerivedHudAmount(hudLine) {
                let getMiscPocAmount = (hudLine) => {
                    let amount = hudLine.misc_Dollar;
                    if (_.includes(["B", "S", "L"], hudLine.pocWhom))
                        amount += hudLine.amountPOC;
                    return amount;
                };

                let getPocAmount = (hudLine) => {
                    let amount = 0;
                    if (_.includes(["B", "S", "L"], hudLine.pocWhom))
                        amount += hudLine.amountPOC;
                    return amount;
                };

                let getBorrowerPocAmount = (hudLine) => {
                    let amount = hudLine.borrowerAmount;
                    if (_.includes(["B", "S", "L"], hudLine.pocWhom))
                        amount += hudLine.amountPOC;
                    return amount;
                };

                // Order of precedence to pull logic by specific line numbers first then line types
                // Pull from misc_Dollar
                if (_.includes([801, 802, 1107, 1108], hudLine.line))
                    return hudLine.misc_Dollar;

                // Pull from borrower amount
                if (_.includes([803, 901, 902, 1001, 1101, 1103, 1201, 1203, 1301], hudLine.line))
                    return hudLine.borrowerAmount;

                // Pull from poc amount
                if (_.includes([1202, 1204, 1205], hudLine.line))
                    return hudLine.amountPOC;

                // Pull from borrower amount, poc amount
                if (_.includes([804, 805, 806, 807, 903], hudLine.line)) {
                    return getBorrowerPocAmount(hudLine);
                }

                // Pull from misc_Dollar, poc amount
                if ((hudLine.line >= 1002 && hudLine.line <= 1011) || _.includes([1102, 1104], hudLine.line)) {
                    return getMiscPocAmount(hudLine);
                }

                // Section 800 line types
                if (hudLine.line > 800 && hudLine.line < 900) {
                    // GFE 1 - pull from misc_Dollar, poc amount
                    if (hudLine.lineType === HudLineTypes.GFE_1) {
                        return getMiscPocAmount(hudLine);
                    }

                    // GFE 3 - pull from borrower Amount, poc amount
                    if (hudLine.lineType === HudLineTypes.GFE_3) {
                        return getBorrowerPocAmount(hudLine);
                    }
                }

                // Section 900 line types
                if (hudLine.line > 900 && hudLine.line < 1000) {
                    // GFE 3, GFE 11 - pull from borrower amount, poc amount
                    if (_.includes([HudLineTypes.GFE_3, HudLineTypes.GFE_11], hudLine.lineType)) {
                        return getBorrowerPocAmount(hudLine);
                    }
                }

                // Section 1100 line types
                if (hudLine.line > 1100 && hudLine.line < 1200) {
                    // GFE 4, GFE 5 - pull from misc_Dollar, poc amount

                    if (_.includes([HudLineTypes.GFE_4_Outside, HudLineTypes.GFE_5_Outside], hudLine.lineType)) {
                        //hudlines in 1100s section have misc_dollar get rapped up into 1101 line
                        return getPocAmount(hudLine);
                    }
                }

                // Section 1200 line types
                if (hudLine.line > 1200 && hudLine.line < 1300) {
                    // GFE 7, GFE 8 - pull from misc_Dollar, poc amount
                    if (_.includes([HudLineTypes.GFE_7, HudLineTypes.GFE_8], hudLine.lineType)) {
                        return getMiscPocAmount(hudLine);
                    }
                }

                // Section 1300 line types
                if (hudLine.line > 1300 && hudLine.line < 1400) {
                    // GFE 6 - pull from misc_Dollar, poc amount
                    if (_.includes([HudLineTypes.GFE_6_Outside], hudLine.lineType)) {
                        //hudlines in 1300s section have misc_dollar get rapped up into 1301 line
                        return getPocAmount(hudLine);
                    }
                }

                return hudLine.borrowerAmount; // Default if doesn't fall in the scenarios above
            },
            onRevertItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    if (item.canRevert) {
                        // Revert data to current hud amounts
                        var hudLine = _.find(self.hudLines, hudItem => hudItem.line === item.hudLine);
                        item.overrideHudLine = false;

                        if (!_.isNil(hudLine)) {
                            let derivedAmount = _.parseNumber(self.getDerivedHudAmount(hudLine), 0);
                            item.hudLineAmount = derivedAmount;
                        }
                        else {
                            item.hudLineAmount = 0;
                        }
                    }
                });

                // On reverting we need to take care to calculation adjustments for section 6000
                self.updateIncreaseValues();
                self.refreshGrids();
            },
            refreshGrids() {
                const self = this;
                this.gridSection5000Instance.clearSelection();
                self.gridSection5000Instance.refresh();
                this.gridSection6000Instance.clearSelection();
                self.gridSection6000Instance.refresh();
                this.gridSection8000Instance.clearSelection();
                self.gridSection8000Instance.refresh();
            },
            reorderHudItems(hudSection) {
                const self = this;
                let hudItems = [];

                if (hudSection === 5000) {
                    hudItems = self.hudSection5000Items;
                }

                if (hudSection === 6000) {
                    hudItems = self.hudSection6000Items;
                }

                if (hudSection === 8000) {
                    hudItems = self.hudSection8000Items;
                }

                if (hudItems.length === 0) return; // no hud items to reorder
                let minLine = _.minBy(hudItems, function(i) { return i.gfeLineNumber; });
                let maxLine = _.maxBy(hudItems, function(i) { return i.gfeLineNumber; });

                let lineNumber = _.parseNumber(minLine.gfeLineNumber, hudSection + 1);
                _.forEach(_.sortBy(hudItems, h => h.gfeLineNumber), (gfeLine) => {
                    if (gfeLine.gfeLineNumber != lineNumber) {

                        if (hudSection === 5000) {
                            let itemIndex = _.findIndex(self.hudSection5000Items, item => item[self.section5000Key] === gfeLine.hudgfeChargesThatCannotChangeID);
                            self.hudSection5000Items[itemIndex].gfeLineNumber = lineNumber;
                        }

                        if (hudSection === 6000) {
                            let itemIndex = _.findIndex(self.hudSection6000Items, item => item[self.section6000Key] === gfeLine.hudgfeChargesThatCannotChangeID);
                            self.hudSection6000Items[itemIndex].gfeLineNumber = lineNumber;
                        }

                        if (hudSection === 8000) {
                            let itemIndex = _.findIndex(self.hudSection8000Items, item => item[self.section8000Key] === gfeLine.hudgfeChargesThatCanChangeID);
                            self.hudSection8000Items[itemIndex].gfeLineNumber = lineNumber;
                        }
                        gfeLine.gfeLineNumber = lineNumber;
                    }
                    lineNumber = lineNumber + 1;
                });
            },

            onEditorPreparing(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;

                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },
            isColumnDisabled(dataField, data) {
                switch (dataField) {
                    case 'description':
                        return data.requiredLine;
                    default:
                        return false;
                }
            },

            onSave(e){
                this.commitGridChanges();
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            commitGridChanges() {
                this.gridSection5000Instance.saveEditData();
                this.gridSection6000Instance.saveEditData();
                this.gridSection8000Instance.saveEditData();
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            hasChanges(){
                if (this.hudSection5000Items.length !== this.originalSection5000Items.length) return true;
                let sec5000Changes = this.getAuditChanges(this.originalSection5000Items, this.hudSection5000Items);
                if (sec5000Changes.length > 0) return true;

                if (this.hudSection6000Items.length !== this.originalSection6000Items.length) return true;
                let sec6000Changes = this.getAuditChanges(this.originalSection6000Items, this.hudSection6000Items);
                if (sec6000Changes.length > 0) return true;

                if (this.hudSection8000Items.length !== this.originalSection8000Items.length) return true;
                let sec8000Changes = this.getAuditChanges(this.originalSection8000Items, this.hudSection8000Items);
                if (sec8000Changes.length > 0) return true;

                return false;
            },
            save(refreshData=true){
                const self = this;
                if(!self.hasChanges()){
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                let savePromise = self.$api.HudGFEApi.saveDeleteRecords(
                    {
                        section5000Items: self.hudSection5000Items,
                        section6000Items: self.hudSection6000Items,
                        section8000Items: self.hudSection8000Items,
                    },
                    {
                        deleted5000Items: self.deletedSection5000Items,
                        deleted6000Items: self.deletedSection6000Items,
                        deleted8000Items: self.deletedSection8000Items,
                    },
                    {
                        loanId: this.selectedLoanId,
                        hudMainId: this.hudMainId,
                        gfeTotal: this.gfeSection6000Total,
                        hudTotal: this.hudSection6000Total,
                        increaseTotal: this.gfeIncreaseValue,
                        increasePercent: this.gfeIncreasePercentValue
                    });

                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("GFE Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving GFE");
                        console.error(err);
                    });
            },
            initSection8000GridConfig() {
                const self = this;

                self.gridSection8000Config = {
                    focusedRowEnabled: true,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            caption: "HUD Line",
                            dataField: "hudLine",
                            validationRules: [
                            ],
                            width: 75,
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxNumberBox({
                                    value: cellInfo.value,
                                    showClearButton: false,
                                    acceptCustomValue: true,
                                    valueChangeEvent: "blur focusout change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(newData, value, currentRowData) {
                                newData.hudLine = value;
                                let line = _.find(self.hudLines, x => x.line === _.parseNumber(value, 0));
                                if(line) {
                                    newData.description = line.description;

                                    // TODO: Business Logic to pull from what is needed
                                    let derivedValue = _.parseNumber(self.getDerivedHudAmount(line), 0);
                                    newData.hudLineAmount = derivedValue;
                                }
                            }
                        },
                        {
                            caption: "Description",
                            dataField: "description",
                            validationRules: [
                                { type: "required", message: "Description is required" }
                            ],
                        },
                        {
                            dataField: "amount",
                            caption: "Good Faith Estimate",
                            dataType: "number",
                            width: 150,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "hudLineAmount",
                            caption: "HUD-1 Amount",
                            dataType: "number",
                            width: 120,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate(cellElement, cellInfo) {
                                let displayText = cellInfo.text;
                                if(cellInfo.data.isHudLineAmountOverridden)
                                    cellElement.addClass("rq-overridden");

                                cellElement.append(accounting.formatMoney(displayText));
                            }
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    // summary: self.summaryLine
                }

                self.initSection8000GridDataSource();
            },
            initSection8000GridDataSource() {
                const self = this;
                let api = self.$api.HudGFEApi;
                self.gridSection8000DataSource = {
                    key: self.section8000Key,
                    load(){
                        return Promise.resolve(self.hudSection8000Items);
                    },
                    insert(values){
                        return self.onSection8000GridInsert(values);
                    },
                    update(key, values) {
                        return self.onSection8000GridUpdate(key, values);
                    }
                };
            },
            onSection8000GridInsert(values) {
                const self = this;
                let newItem = new HudGFECanChangeChargeDto(values);
                newItem.hudgfeChargesThatCanChangeID = -self.hudSection8000Items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.required = false;
                newItem.hudMainID = self.hudMainId;
                newItem.gfeLineNumber = self.getNextAvailableHudLine(8000);
                self.hudSection8000Items.push(newItem);
                return Promise.resolve(newItem);
            },
            getNextAvailableHudLine(hudSection) {
                const self = this;
                let hudItems = [];

                switch(hudSection) {
                    case 5000:
                        hudItems = self.hudSection5000Items;
                        break;
                    case 6000:
                        hudItems = self.hudSection6000Items;
                        break;
                    case 8000:
                        hudItems = self.hudSection8000Items;
                        break;
                    default:
                        break;
                }

                let maxHudLineItem = _.maxBy(hudItems, function(i) { return i.gfeLineNumber; });
                let nextAvailableLineNumber = _.parseNumber(maxHudLineItem.gfeLineNumber, hudSection) + 1;
                return nextAvailableLineNumber;
            },
            onSection8000GridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.hudSection8000Items, item => item.hudgfeChargesThatCanChangeID === key);
                if (itemIndex < 0) return self.onSection8000GridInsert(values);

                let newUpdateItem = new HudGFECanChangeChargeDto((_.assign({}, self.hudSection8000Items[itemIndex], values)));
                let isHudLineUpdated = _.parseBool(_.has(values, "hudLine"), false);
                let isHudLineAmountUpdated = _.parseBool(_.has(values, "hudLineAmount"), false);

                // If hudLine is being updated we reset the override
                if (isHudLineUpdated) {
                    newUpdateItem.overrideHudLine = false;
                }

                // If hudLine isn't updated and hudLineAmount is being edited on grid then we know this is an override state
                if (!isHudLineUpdated && isHudLineAmountUpdated) {
                    newUpdateItem.hudLineAmountValue = values.hudLineAmount;
                }

                self.hudSection8000Items[itemIndex] = newUpdateItem;
                return Promise.resolve(newUpdateItem);
            },
            onAddSection8000Action(e) {
                if(!this.gridSection8000Instance) return;
                this.gridSection8000Instance.addRow();
            },
            onDeleteSection8000Items(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteSection8000Items(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected charge(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            deleteSection8000Items(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.section8000Key);
                    self.removeSection8000Items(key);

                    if(key > 0){
                        self.deletedSection8000Items.push(item);
                    }
                });

                // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                self.reorderHudItems(8000);
            },

            removeSection8000Items(key) {
                const self = this;
                let itemIndex = _.findIndex(self.hudSection8000Items, item => item[self.section8000Key] === key);
                if(itemIndex >= 0) self.hudSection8000Items.splice(itemIndex, 1);
                this.gridSection8000Instance.clearSelection();
                this.gridSection8000Instance.refresh();
            },

            initSection5000GridConfig() {
                const self = this;

                self.gridSection5000Config = {
                    focusedRowEnabled: true,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            caption: "HUD Line",
                            dataField: "hudLine",
                            validationRules: [
                            ],
                            width: 75,
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxNumberBox({
                                    value: cellInfo.value,
                                    showClearButton: false,
                                    acceptCustomValue: true,
                                    valueChangeEvent: "blur focusout change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(newData, value, currentRowData) {
                                newData.hudLine = value;
                                let line = _.find(self.hudLines, x => x.line === _.parseNumber(value, 0));
                                if(line) {
                                    newData.description = line.description;

                                    // TODO: Business Logic to pull from what is needed
                                    let derivedValue = _.parseNumber(self.getDerivedHudAmount(line), 0);
                                    newData.hudLineAmount = derivedValue;
                                }
                            }
                        },
                        {
                            caption: "Description",
                            dataField: "description",
                            validationRules: [
                                { type: "required", message: "Description is required" }
                            ],
                        },
                        {
                            dataField: "amount",
                            caption: "Good Faith Estimate",
                            dataType: "number",
                            width: 150,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "hudLineAmount",
                            caption: "HUD-1 Amount",
                            dataType: "number",
                            width: 120,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate(cellElement, cellInfo) {
                                let displayText = cellInfo.text;
                                if(cellInfo.data.isHudLineAmountOverridden)
                                    cellElement.addClass("rq-overridden");

                                cellElement.append(accounting.formatMoney(displayText));
                            }
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    // summary: self.summaryLine
                }

                self.initSection5000GridDataSource();
            },
            initSection5000GridDataSource() {
                const self = this;
                let api = self.$api.HudGFEApi;
                self.gridSection5000DataSource = {
                    key: self.section5000Key,
                    load(){
                        return Promise.resolve(self.hudSection5000Items);
                    },
                    insert(values){
                        return self.onSection5000GridInsert(values);
                    },
                    update(key, values) {
                        return self.onSection5000GridUpdate(key, values);
                    }
                };
            },
            onSection5000GridInsert(values) {
                const self = this;
                let newItem = new HudGFECannotChangeChargeDto(values);
                newItem.hudgfeChargesThatCannotChangeID = -self.hudSection5000Items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.required = false;
                newItem.hudMainID = self.hudMainId;
                newItem.gfeLineNumber = self.getNextAvailableHudLine(5000);
                self.hudSection5000Items.push(newItem);
                return Promise.resolve(newItem);
            },

            onSection5000GridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.hudSection5000Items, item => item.hudgfeChargesThatCannotChangeID === key);
                if (itemIndex < 0) return self.onSection5000GridInsert(values);

                let newUpdateItem = new HudGFECannotChangeChargeDto((_.assign({}, self.hudSection5000Items[itemIndex], values)));
                let isHudLineUpdated = _.parseBool(_.has(values, "hudLine"), false);
                let isHudLineAmountUpdated = _.parseBool(_.has(values, "hudLineAmount"), false);

                // If hudLine is being updated we reset the override
                if (isHudLineUpdated) {
                    newUpdateItem.overrideHudLine = false;
                }

                // If hudLine isn't updated and hudLineAmount is being edited on grid then we know this is an override state
                if (!isHudLineUpdated && isHudLineAmountUpdated) {
                    newUpdateItem.hudLineAmountValue = values.hudLineAmount;
                }

                self.hudSection5000Items[itemIndex] = newUpdateItem;
                return Promise.resolve(newUpdateItem);
            },
            onAddSection5000Action(e) {
                if(!this.gridSection5000Instance) return;
                this.gridSection5000Instance.addRow();
            },
            onDeleteSection5000Items(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteSection5000Items(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected charge(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            deleteSection5000Items(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.section5000Key);
                    self.removeSection5000Items(key);

                    if(key > 0){
                        self.deletedSection5000Items.push(item);
                    }
                });

                // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                self.reorderHudItems(5000);
            },

            removeSection5000Items(key) {
                const self = this;
                let itemIndex = _.findIndex(self.hudSection5000Items, item => item[self.section5000Key] === key);
                if(itemIndex >= 0) self.hudSection5000Items.splice(itemIndex, 1);
                this.gridSection5000Instance.clearSelection();
                this.gridSection5000Instance.refresh();
            },

            initSection6000GridConfig() {
                const self = this;

                self.gridSection6000Config = {
                    focusedRowEnabled: true,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            caption: "HUD Line",
                            dataField: "hudLine",
                            validationRules: [
                            ],
                            width: 75,
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxNumberBox({
                                    value: cellInfo.value,
                                    showClearButton: false,
                                    valueChangeEvent: "blur focusout change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(newData, value, currentRowData) {
                                newData.hudLine = value;
                                let line = _.find(self.hudLines, x => x.line === _.parseNumber(value, 0));
                                if(line) {
                                    newData.description = line.description;

                                    // TODO: Business Logic to pull from what is needed
                                    let derivedValue = _.parseNumber(self.getDerivedHudAmount(line), 0);
                                    newData.hudLineAmount = derivedValue;
                                    newData.overrideHudLine = false;
                                }
                            }
                        },
                        {
                            caption: "Description",
                            dataField: "description",
                            validationRules: [
                                { type: "required", message: "Description is required" }
                            ],
                        },
                        {
                            dataField: "amount",
                            caption: "Good Faith Estimate",
                            dataType: "number",
                            width: 150,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "hudLineAmount",
                            caption: "HUD-1 Amount",
                            dataType: "number",
                            width: 120,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate(cellElement, cellInfo) {
                                let displayText = cellInfo.text;
                                if(cellInfo.data.isHudLineAmountOverridden)
                                    cellElement.addClass("rq-overridden");

                                cellElement.append(accounting.formatMoney(displayText));
                            }
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],

                    summary: self.summaryLine
                }

                self.initSection6000GridDataSource();
            },
            initSection6000GridDataSource() {
                const self = this;
                let api = self.$api.HudGFEApi;
                self.gridSection6000DataSource = {
                    key: self.section6000Key,
                    load(){
                        return Promise.resolve(self.hudSection6000Items);
                    },
                    insert(values){
                        return self.onSection6000GridInsert(values);
                    },
                    update(key, values) {
                        return self.onSection6000GridUpdate(key, values);
                    }
                };
            },
            onSection6000GridInsert(values) {
                const self = this;
                let newItem = new HudGFECannotChangeChargeDto(values);
                newItem.hudgfeChargesThatCannotChangeID = -self.hudSection6000Items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.required = false;
                newItem.hudMainID = self.hudMainId;
                newItem.gfeLineNumber = self.getNextAvailableHudLine(6000);
                self.hudSection6000Items.push(newItem);
                self.updateIncreaseValues();
                return Promise.resolve(newItem);
            },

            onSection6000GridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.hudSection6000Items, item => item.hudgfeChargesThatCannotChangeID === key);
                if (itemIndex < 0) return self.onSection6000GridInsert(values);

                let newUpdateItem = new HudGFECannotChangeChargeDto((_.assign({}, self.hudSection6000Items[itemIndex], values)));
                let isHudLineUpdated = _.parseBool(_.has(values, "hudLine"), false);
                let isHudLineAmountUpdated = _.parseBool(_.has(values, "hudLineAmount"), false);

                // If hudLine is being updated we reset the override
                if (isHudLineUpdated) {
                    newUpdateItem.overrideHudLine = false;
                }

                // If hudLine isn't updated and hudLineAmount is being edited on grid then we know this is an override state
                if (!isHudLineUpdated && isHudLineAmountUpdated) {
                    newUpdateItem.hudLineAmountValue = values.hudLineAmount;
                }

                self.hudSection6000Items[itemIndex] = newUpdateItem;
                self.updateIncreaseValues();
                return Promise.resolve(newUpdateItem);
            },
            onAddSection6000Action(e) {
                if(!this.gridSection6000Instance) return;
                this.gridSection6000Instance.addRow();
            },
            onDeleteSection6000Items(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteSection6000Items(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected charge(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            deleteSection6000Items(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.section6000Key);
                    self.removeSection6000Items(key);

                    if(key > 0){
                        self.deletedSection6000Items.push(item);
                    }
                });

                // Since we maintain HUD lines we also have to maintain the line ordering for UI purpose
                self.reorderHudItems(6000);

                // Calculation updates
                self.updateIncreaseValues();
            },

            removeSection6000Items(key) {
                const self = this;
                let itemIndex = _.findIndex(self.hudSection6000Items, item => item[self.section6000Key] === key);
                if(itemIndex >= 0) self.hudSection6000Items.splice(itemIndex, 1);
                this.gridSection6000Instance.clearSelection();
                this.gridSection6000Instance.refresh();
            },
            updateIncreaseValues() {
                const self = this;
                this.gfeSection6000Total = _.sumBy(self.hudSection6000Items, item => _.getNumber(item, "amount", 0));
                this.hudSection6000Total = _.sumBy(this.hudSection6000Items, item => _.getNumber(item, "hudLineAmount", 0));
                this.gfeIncreaseValue = _.parseNumber(this.hudSection6000Total - this.gfeSection6000Total);
                this.gfeIncreasePercentValue = this.gfeSection6000Total !== 0 ? this.parseFixed((this.gfeIncreaseValue / this.gfeSection6000Total) * 100, 2) : 0;
            }
        }
    };
</script>
