<template>
    <div class="reissue-credit-form">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                <label :for="getFieldId('number')">Prior {{loanPolicy ? 'Loan' : 'Owner' }} Policy Number</label>
                <input
                    :id="getFieldId('number')"
                    :automation_id="getFieldId('number')"
                    type="text"
                    class="form-control"
                    v-model="policyNumberValue">
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                <label :for="getFieldId('amount')">Prior {{loanPolicy ? 'Loan Amount' : 'Sales Price' }}</label>
                <rq-input-number
                    :id="getFieldId('amount')"
                    formatType="money"
                    defaultValue="0"
                    decimals="2"
                    value-event="input"
                    v-model="policyAmountValue" />
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                <label :for="getFieldId('date')">Policy Date</label>
                <span class="ric-year-display">{{policyYearsDisplay}}</span>
                <rqdx-date-box
                    :id="getFieldId('date')"
                    v-model="policyDateValue"
                    @value-changed="onPolicyDateChange"
                />
            </div>
        </div>
        <div class="row">
            <div v-rq-tooltip.html :title="emptyCdfSectionsTooltip" class="col-sm-12 col-md-6 col-lg-4 form-group">
                <label :for="getFieldId('cdf')">CDF Section</label>
                <dx-select-box
                    :id="getFieldId('cdf')"
                    display-expr="name"
                    value-expr="id"
                    :items="cdfSections"
                    :disabled="cdfSectionsEmpty"
                    v-model="cdfSectionValue"
                />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-8 form-group" :class="{ 'form-loading': premiumNamesLoading }">
                <label :for="getFieldId('premium')">Prior Premium</label>
                <dx-select-box
                    :id="getFieldId('premium')"
                    :items="availablePremiumNames"
                    display-expr="name"
                    value-expr="id"
                    :disabled="premiumNamesLoading"
                    v-model="priorRateIDValue"
                />
                <rq-loading-indicator :offset="40" for-input />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { RATE_ACTIONS } from '@/store/actions';

    export default {
        name:"ReissueCreditForm",

        props: {
            ownerPolicy: { type: Boolean, default: false },
            loanPolicy: { type: Boolean, default: false },
            policyNumber: { default: "" },
            policyAmount: { default: null },
            policyDate: { default: null },
            policyYears: { type: Number, default: 0 },
            priorRateID: { type: Number, default: null },
            cdfSection: { type: Number, default: null }
        },

        data(){
            return {
                ownerFieldIds: {
                    number: "txt_prior_owner_policy_number",
                    amount: "txt_prior_sales_price",
                    date: "dte_prior_owner_policy_date",
                    premium: "drp_prior_owner_premium_name",
                    cdf: "drp_owner_cdf_section"
                },
                loanFieldIds: {
                    number: "txt_prior_loan_policy_number",
                    amount: "txt_prior_loan_amount",
                    date: "dte_prior_loan_policy_date",
                    premium: "drp_prior_loan_premium_name",
                    cdf: "drp_loan_cdf_section"
                },
                premiumNamesLoading: false
            }
        },

        computed: {
            ...mapState({
                cdfSections: state => _.mapLookup(state.orders.orderLookups.cdfSections),
                properties: state => state.properties.properties,
            }),
            ...mapGetters([
                "premiumNames"
            ]),
            cdfSectionsEmpty() { return _.isEmpty(this.cdfSections); },
            emptyCdfSectionsTooltip() { return this.cdfSectionsEmpty ? "No CDF sections available<br/>on this order" : ""; },
            policyYearsDisplay() { return _.isNil(this.policyYears) ? "" : `${this.policyYears} Year${this.policyYears === 1 ? "" : "s"}`; },

            availablePremiumNames() {
                let propertyState = _.get(this, "properties[0].property.state", null);
                return _.filter(this.premiumNames, item => item.data === propertyState && !item.inactive);
            },

            policyNumberValue: {
                get() { return this.policyNumber; },
                set(val) {
                    if(this.policyNumber === val) return;
                    this.$emit("update:policyNumber", val);
                }
            },
            policyAmountValue: {
                get() { return this.policyAmount; },
                set(val) {
                    if(this.policyAmount === val) return;
                    this.$emit("update:policyAmount", val);
                }
            },
            policyDateValue: {
                get() { return this.policyDate; },
                set(val) {
                    if(this.policyDate === val) return;
                    this.$emit("update:policyDate", val);
                }
            },
            priorRateIDValue: {
                get() { return this.priorRateID; },
                set(val) {
                    if(this.priorRateID === val) return;
                    this.$emit("update:priorRateID", val);
                }
            },
            cdfSectionValue: {
                get() { return this.cdfSection; },
                set(val) {
                    if(this.cdfSection === val) return;
                    this.$emit("update:cdfSection", val);
                }
            }
        },

        created() {
            this.fetchPremiumNames(this.policyDateValue);
        },

        methods: {
            getFieldId(fieldKey){
                return this.loanPolicy ? this.loanFieldIds[fieldKey] : this.ownerFieldIds[fieldKey];
            },
            onPolicyDateChange(e) {
                if(_.isNil(e.event)) return;
                this.fetchPremiumNames(e.value);
            },
            fetchPremiumNames(dateValue=null) {
                const self = this;
                self.premiumNamesLoading = true;
                //TG - RQO-10493 - use the line below if premium names need to be based on a specific rate effective date (defaults to today)
                //self.$store.dispatch(RATE_ACTIONS.GET_RATES_BY_DATE, { effectiveDate: dateValue })
                self.$store.dispatch(RATE_ACTIONS.GET_RATES_BY_DATE)
                    .then(() => {
                        self.premiumNamesLoading = false;
                    });
            }
        }
    };
</script>
