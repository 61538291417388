<template>
    <div class="content-wrapper contact-override">
        <rq-page-section title="Company Override" headerSize="lg" borderless>
            <fieldset>
                <div class="row">
                    <div :class="{'col col-6 col-sm-12 col-md-6 col-lg-6 form-group':true, 'overridden': isCompanyNameOverridden}">
                        <label class="form-control-label" for="txt_company_name">Company Name</label>
                        <div class="row" v-show="isCompanyNameOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_company_name"
                                    automation_id="btn_revert_company_name"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isCompanyNameOverridden"
                                    @click="onRevertCompanyName">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_company_name" v-model="editData.name" type="text" class="form-control" id="txt_company_name" placeholder="Company Name" maxlength="150">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div :class="{'col col-12 col-sm-12 col-md-12 col-lg-12 form-group':true, 'overridden': isCompanyAddressOverridden}">
                        <label class="form-control-label" for="txt_company_address">Company Address</label>
                        <div class="row" v-show="isCompanyAddressOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_company_address"
                                    automation_id="btn_revert_company_address"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isCompanyAddressOverridden"
                                    @click="onRevertCompanyAddress">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_company_address" v-model="editData.address" type="text" class="form-control" id="txt_company_address" placeholder="Company Address" maxlength="250">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div :class="{'col col-4 col-sm-12 col-md-6 col-lg-4 form-group':true, 'overridden': isNMLSIDOverridden}">
                        <label class="form-control-label" for="txt_nmlsid">NMLSID</label>
                        <div class="row" v-show="isNMLSIDOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_nmlsid"
                                    automation_id="btn_revert_nmlsid"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isNMLSIDOverridden"
                                    @click="onRevertNMLSID">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_nmlsid" v-model="editData.nmlsid" type="text" class="form-control" id="txt_nmlsid" placeholder="NMLSID" maxlength="50">
                        </div>
                    </div>
                    <div :class="{'col col-4 col-sm-12 col-md-6 col-lg-4 form-group':true, 'overridden': isLicenseIDOverridden}">
                        <label class="form-control-label" for="txt_license_id">License ID</label>
                        <div class="row" v-show="isLicenseIDOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_license_id"
                                    automation_id="btn_revert_license_id"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isLicenseIDOverridden"
                                    @click="onRevertLicenseID">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_license_id" v-model="editData.licenseID" type="text" class="form-control" id="txt_license_id" placeholder="License ID" maxlength="150">
                        </div>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Contact Override" headerSize="lg" borderless>
            <fieldset>
                <div class="row">
                    <div :class="{'col col-6 col-sm-12 col-md-6 col-lg-6 form-group':true, 'overridden': isContactNameOverridden}">
                        <label class="form-control-label" for="txt_contact_name">Contact Name</label>
                        <div class="row" v-show="isContactNameOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_contact_name"
                                    automation_id="btn_revert_contact_name"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isContactNameOverridden"
                                    @click="onRevertContactName">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_contact_name" v-model="editData.contact" type="text" class="form-control" id="txt_contact_name" placeholder="Contact Name" maxlength="150">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div :class="{'col col-4 col-sm-12 col-md-6 col-lg-4 form-group':true, 'overridden': isContactNMLSIDOverridden}">
                        <label class="form-control-label" for="txt_contact_nmlsid">NMLSID</label>
                        <div class="row" v-show="isContactNMLSIDOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_contact_nmlsid"
                                    automation_id="btn_revert_contact_nmlsid"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isContactNMLSIDOverridden"
                                    @click="onRevertContactNMLSID">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_contact_nmlsid" v-model="editData.contactNMLSID" type="text" class="form-control" id="txt_contact_nmlsid" placeholder="NMLSID" maxlength="50">
                        </div>
                    </div>
                    <div :class="{'col col-4 col-sm-12 col-md-6 col-lg-4 form-group':true, 'overridden': isContactLicenseIDOverridden}">
                        <label class="form-control-label" for="txt_contact_licenseid">License ID</label>
                        <div class="row" v-show="isContactLicenseIDOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_contact_license_id"
                                    automation_id="btn_revert_contact_license_id"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isContactLicenseIDOverridden"
                                    @click="onRevertContactLicenseID">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_contact_licenseid" v-model="editData.contactLicenseID" type="text" class="form-control" id="txt_contact_licenseid" placeholder="License ID" maxlength="150">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div :class="{'col col-4 col-sm-12 col-md-6 col-lg-4 form-group':true, 'overridden': isEmailOverridden}">
                        <label class="form-control-label" for="txt_contact_email">Email</label>
                        <div class="row" v-show="isEmailOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_email"
                                    automation_id="btn_revert_email"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isEmailOverridden"
                                    @click="onRevertEmail">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_contact_email" v-model="editData.email" type="text" class="form-control" id="txt_contact_email" placeholder="NMLSID" maxlength="150">
                        </div>
                    </div>
                    <div :class="{'col col-4 col-sm-12 col-md-6 col-lg-4 form-group':true, 'overridden': isPhoneOverridden}">
                        <label class="form-control-label" for="txt_contact_phone">Phone</label>
                        <div class="row" v-show="isPhoneOverridden">
                            <div class="col col-auto form-group-action ms-auto mt-n4">
                                <b-btn
                                    id="btn_revert_phone"
                                    automation_id="btn_revert_phone"
                                    variant="link"
                                    size="sm"
                                    class="btn-theme btn-revert"
                                    v-show="isPhoneOverridden"
                                    @click="onRevertPhone">Revert</b-btn>
                            </div>
                        </div>
                        <div class="input-group">
                            <input automation_id="txt_contact_phone" v-model="editData.phone" type="text" class="form-control" id="txt_contact_phone" placeholder="License ID" maxlength="25">
                        </div>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>
<script>

    export default {
        name:"SettlementStatementContactOverrideDialog",
        props:{
            contact: {dataType: Object}
        },
        data(){
            return {
                editData: null
            }
        },
        created(){
            this.editData = _.cloneDeep(this.contact.override);
        },
        computed:{
            isCompanyNameOverridden(){
                return this.contact.companyName !== this.editData.name;
            },
            isCompanyAddressOverridden(){
                return this.contact.address !== this.editData.address;
            },
            isNMLSIDOverridden(){
                return this.contact.nmlsid !== this.editData.nmlsid;
            },
            isLicenseIDOverridden(){
                return this.contact.stateLicenseID !== this.editData.licenseID;
            },
            isContactNameOverridden(){
                return this.contact.contactName !== this.editData.contact;
            },
            isContactNMLSIDOverridden(){
                return this.contact.contactNMLSID !== this.editData.contactNMLSID;
            },
            isContactLicenseIDOverridden(){
                return this.contact.contactStateLicenseID !== this.editData.contactLicenseID;
            },
            isEmailOverridden(){
                return this.contact.email !== this.editData.email;
            },
            isPhoneOverridden(){
                return this.contact.primaryPhone !== this.editData.phone;
            }

        },
        methods:{
            save(){
                _.assign(this.contact.override, this.editData);
            },
            onRevertCompanyName(){
                this.editData.name = this.contact.companyName;
            },
            onRevertCompanyAddress(){
                this.editData.address = this.contact.address;
            },
            onRevertNMLSID(){
                this.editData.nmlsid = this.contact.nmlsid;
            },
            onRevertLicenseID(){
                this.editData.licenseID = this.contact.stateLicenseID;
            },
            onRevertContactName(){
                this.editData.contact = this.contact.contactName;
            },
            onRevertContactNMLSID(){
                this.editData.contactNMLSID = this.contact.contactNMLSID;
            },
            onRevertContactLicenseID(){
                this.editData.contactLicenseID = this.contact.contactStateLicenseID;
            },
            onRevertEmail(){
                this.editData.email = this.contact.email;
            },
            onRevertPhone(){
                this.editData.phone = this.contact.primaryPhone;
            }

        }
    }
</script>