import SuperSearchPage from './SearchIndex';
import Routes from './routes';

let SearchModule = {
    meta: { label: 'Search',  include: false },
    path: 'search',
    component: SuperSearchPage,
    children: Routes
};

export default SearchModule;
