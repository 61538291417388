import CommitmentMain from "./CommitmentMain";
import CommitmentView from "./CommitmentView";
import { TitleRoutes, PolicyRoutes } from "./routes";

export default {
    meta: { label: "Commitment", include: false },
    path: "commitment",
    component: CommitmentMain,
    children:[
        {
            path: "title",
            props: route => ({
                loanId: _.getNumber(route, "params.loanId", 0)
            }),
            component: CommitmentView,
            meta: { label: "Title Production", include: true },
            children: TitleRoutes
        },
        {
            path: "policy",
            props: route => ({
                loanId: _.getNumber(route, "params.loanId", 0)
            }),
            component: CommitmentView,
            meta: { label: "Policy", include: true },
            children: PolicyRoutes
        }
    ]
};