<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_file_scan_documents"
            title="Documents"
            :config="gridConfig"
            :data-source="gridDataSource"
            integrated-search
            >
        </rqdx-action-data-grid>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { FileScanDocumentDto } from '@document-mgmt/models';
import { DocumentFileType }  from "@documents/enums";

export default {
    name: "SmktEzJacketScheduleUpload",
    props: {
        id: { type: Number, default: 0 },
        policyId: { type: Number, default: 0 }
    },
    data: function() {
        return {
            items: [],
            docList: [],
            searchText: "",
        };
    },
    computed: {
        ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
        ...mapState({
            orderId: state => state.orders.orderId,
            order: state => state.orders.order,
            user: state => state.authentication.session.user
        }),
        gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
        documentFileTypes() { return DocumentFileType.lookupItems; },
        fileScanCategories() { return this.lookupHelpers.getAllLookupItems(this.lookupItems.FILE_SCAN_CATEGORIES) },
        // selectionActions() {
        //     return [
        //         { name: "upload", text: "Upload", eventName: "upload", allowMultiSelection: true, requireSelection: true, tooltip: "Upload Selected Documents" },
        //     ];
        // },
    },
    created() {
        this.initNonReactiveVariables();
        this.initGridConfig();
        this.fetchData();
    },
    methods:{
        addOrder(id) {
            const self = this;
            let apiPromise = self.$api.SmktEzJacketApi.addOrder(self.orderId, id);
            return self.$rqBusy.wait(apiPromise);
        },

        fetchData() {
            const self = this;
            let apiPromise = self.$api.FileScanApi.getFileScanDocuments(self.orderId);
            self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.items = _.uniqBy(_.map(_.filter(result, i => i.fileScanCategoryID !== 1000), i => new FileScanDocumentDto(i)), 'fileScanDocumentID');
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                })
                .finally(() => {
                    self.refresh();
                });
        },

        setScheduleData(ids) {
            const self = this;
            let apiPromise = self.$api.SmktEzJacketApi.setScheduleData(self.id, self.policyId, ids);
            return self.$rqBusy.wait(apiPromise);
        },

        initGridConfig(){
            const self = this;
            self.gridConfig = {
                focusedRowEnabled: false,
                paging: { enabled: true },
                pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                remoteOperations: { sorting: false, paging: false },
                columns: [
                    {
                        allowSearch: true,
                        dataField: "commonFileName",
                        dataType: "string",
                        caption: "File Name"
                    },
                    {   allowSearch: false, dataField: "fileScanCategoryID", dataType: "number", caption: "Category", calculateSortValue: "fileScanCategoryDisplay",
                        lookup: {
                            dataSource: self.fileScanCategories,
                            displayExpr: "name",
                            valueExpr: "id"
                        }
                    },
                    { allowSearch: false, dataField: "fileType", dataType: "number", caption: "Type", calculateSortValue: "fileTypeDisplay",
                        lookup: {
                            dataSource: self.documentFileTypes,
                            displayExpr: "name",
                            valueExpr: "id"
                        },
                    },
                    { dataField: "dateModified", dataType: "datetime", caption: "Date Modified", minWidth: 135, allowSearch: false, allowSorting: true},
                ],
            };
            self.gridDataSource = {
                loadMode: "raw",
                key: self.itemKey,
                load () {
                    return Promise.resolve(self.items);
                },
            };
        },

        initNonReactiveVariables() {
            const self = this;
            self.itemTypeName = "Document";
            self.itemTypeNamePlural = "Documents";
            self.itemKey = "fileScanDocumentID";
        },

        save() {
            const self = this;
            let ids = self.gridInstance.getSelectedRowKeys();
            if(_.size(ids) == 0 ) {
                self.$toast.info({ message: `No documents selected.` });
                return Promise.resolve();
            }
            return self.setScheduleData(ids)
                .then(data => {
                    self.$toast.success({ message: `Successfully Uploaded Document${_.size(ids) ? "s" : ""} to Policy.` });
                })
                .catch(error => {
                    console.error(error);
                });
        },

        refresh() {
            if(_.isNull(this.gridInstance)) return;
            this.gridInstance.clearSelection();
            this.gridInstance.refresh();
        },
    }
}
</script>
<style lang="scss">
.smkt-ezjacket-upload-iframe-container {
    flex: 1;
    overflow: hidden;
    background: #EFEFEF;
    height: 600px;
}
.smkt-ezjacket-upload-iframe {
    position: relative;
    height: 600px;
    width: 100%;
    border: none;
    margin: 0;
}
</style>