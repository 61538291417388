<template>
    <div class="content-wrapper">
        <order-summary />
        <router-view></router-view>
    </div>
</template>

<script>
    import OrderSummary from "../shared/components/OrderSummary";

    export default {
        components: { OrderSummary }
    };

</script>
