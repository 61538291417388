<template>
    <div id="invoice-summary" v-if="!propIsNull" :class="{ 'large-bottom-margin': largeBottomMargin }">
        <rq-page-section title="Summary" borderless>
            <table class="table table-left table-sm table-borderless" style="width:220px;">
                <tr>
                    <th>
                        <span title="Non-Taxable">Non-Taxable</span>
                    </th>
                    <td class="text-right">
                        <span :title="totalNonTaxable">{{totalNonTaxable}}</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span title="Taxable">Taxable</span>
                    </th>
                    <td class="text-right">
                        <span :title="totalTaxable">{{totalTaxable}}</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span title="Tax">Tax</span>
                    </th>
                    <td class="text-right">
                        <span :title="totalTax">{{totalTax}}</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span title="Total">Total</span>
                    </th>
                    <td class="text-right">
                        <span :title="grandTotal">{{grandTotal}}</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span title="Total Payments">Total Payments</span>
                    </th>
                    <td class="text-right">
                        <span :title="totalPayments">{{totalPayments}}</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span title="Total Credits">Total Credits</span>
                    </th>
                    <td class="text-right">
                        <span :title="totalCredits">{{totalCredits}}</span>
                    </td>
                </tr>
                <tr style="border-top: solid 2px black;">
                    <th>
                        <span title="Invoice Balance">Invoice Balance</span>
                    </th>
                    <td class="text-right">
                        <span :title="balance">{{balance}}</span>
                    </td>
                </tr>
            </table>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    export default {
        name:"InvoiceSummarySection",
        props:{
            invoiceDetail:{ type: Object, default: () => ({}) },
            largeBottomMargin: { type: Boolean, default: false }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                systemDefaults: state => state.system.systemDefaults,
                order: state => state.orders.order
            }),
            propIsNull(){return _.isNil(this.invoiceDetail)},
            readOnly() { return this.isReadOnly || this.isOrderLocked; },
            totalNonTaxable(){
                return this.formatMoney(this.invoiceDetail.totalNonTaxable);
            },
            totalTaxable(){
                return this.formatMoney(this.invoiceDetail.totalTaxable);
            },
            totalTax(){
                return this.formatMoney(this.invoiceDetail.taxes);
            },
            grandTotal(){
                return this.formatMoney(this.invoiceDetail.grandTotal);
            },
            totalPayments(){
                return this.formatMoney(this.invoiceDetail.payment);
            },
            totalCredits(){
                return this.formatMoney(this.invoiceDetail.creditTotal);
            },
            balance(){
                return this.formatMoney(this.invoiceDetail.balance);
            }
        },
        methods:{
            formatMoney(v) {
                return accounting.formatMoney(
                    _.parseNumber(v, 0),
                    { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }
                );
            },
        }
    }
</script>