import OrderTasksPage from './components/OrderTasks';
import { SPACER, ADD_FILE_GROUP } from "@/router/actions/global";

const noOWAccessMsg = "Access to order workflow is not authorized.  Please contact your administrator.";

let orderWorkflow = {
    name: 'ow:order-workflow',
    path: '',
    component: OrderTasksPage,
    meta: {
        label: 'File Workflow',
        logRouteAccess: true,
        icon: 'fa-tasks',
        accessKey:"UpdateWorkflow",
        unauthorizedMessage: noOWAccessMsg,
        skipSaveOnLeave: true,
        actions: [SPACER, SPACER, ADD_FILE_GROUP]
    }
}

let pages = [
    orderWorkflow
];

export default pages;
