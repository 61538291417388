import TitleProductionPage from "./views/TitleProduction";
import LoanSelectionPage from "../order-entry/loans/OrderLoans";
import PolicyMain from "./views/PolicyMain.vue";
import PolicyPage from "./views/Policy";
import PolicyList from "./views/PolicyList";
import PolicyJacketList from "./views/PolicyJacketList";
import VoidedPolicyPage from "./views/VoidedPolicy";
import SmktEzJacket from "../ezjacket/components/SmktEzJacket.vue";
import { TitleViewActions, PolicyViewActions } from "@/router/actions/title-policy";
import { OrderNoteCategory, PolicyType } from "@/shared/models/enums";
import Store from "@/store";

const noAccessMsg = "Access to title production is not authorized.  Please contact your administrator.";
const checkLoanRedirect = (from, next, name) => {
    let orderLoans = _.get(Store, "state.orders.loans", null) || [];
    next(orderLoans.length !== 1 || {
        name,
        params: {
            orderId: from.params.orderId,
            loanId: orderLoans[0].loanID
        }
    });
}

let TitleRoutes = [{
        name: "tp:loan:list",
        path: "",
        component: LoanSelectionPage,
        props: {
            showDelete: false
        },
        beforeEnter: (to, from, next) => checkLoanRedirect(from, next, "tp:loan:form"),
        meta: {
            entity: "Commitment",
            label: "Title Production Loans",
            selectActionLabel: "View Title Production",
            addEnabled: false,
            deleteEnabled: false,
            reorderEnabled: false,
            include: false,
            accessKey: "Commitment",
            unauthorizedMessage: noAccessMsg,
            actions: TitleViewActions.LOAN_SELECTION,
            skipSaveOnLeave: true
        }
    },
    {
        name: "tp:loan:form",
        path: "loan/:loanId?",
        props: route => ({
            loanId: _.getNumber(route, "params.loanId", 0)
        }),
        component: TitleProductionPage,
        meta: {
            entity: "Commitment",
            label: "Title Production",
            title: "[LOAN_INFO]",
            include: false,
            accessKey: "Commitment",
            unauthorizedMessage: noAccessMsg,
            actions: TitleViewActions.LOAN
        }
    }
];

let PolicyRoutes = [
    {
        name: "policy:main",
        path: "",
        component: PolicyMain,
        props: {  },
        meta: {
            entity: "Policy",
            label: "Policy",
            accessKey: "Policy",
            include: false,
            unauthorizedMessage: noAccessMsg,
            skipSaveOnLeave: true
        },
        children: [
            {
                name: "policy:list",
                path: "policyList",
                component: PolicyList,
                props: { },
                meta: {
                    entity: "Policy",
                    label: "Policies",
                    policyType: 1,
                    accessKey: "Policy",
                    include: false,
                    unauthorizedMessage: noAccessMsg,
                    actions: PolicyViewActions.OWNER,
                    noteCategory:OrderNoteCategory.Policy,
                    templateCategory: 'tp',
                    skipSaveOnLeave: true
                }
            },
            {
                name: "policy:owner:form",
                path: "owner",
                component: PolicyPage,
                props: { policyType: PolicyType.Owner },
                meta: {
                    entity: "Policy",
                    label: "Owner Policy",
                    policyType: 1,
                    accessKey: "Policy",
                    include: false,
                    unauthorizedMessage: noAccessMsg,
                    actions: PolicyViewActions.OWNER,
                    noteCategory:OrderNoteCategory.Policy,
                    templateCategory: 'tp',
                    skipSaveOnLeave: false
                }
            },
            {
                name: "policy:loan:form",
                path: "loan/:loanId",
                component: PolicyPage,
                props: route => ({
                    loanId: _.getNumber(route, "params.loanId", 0),
                    policyType: PolicyType.Loan
                }),
                meta: {
                    entity: "Policy",
                    label: "Loan Policy",
                    policyType: 2,
                    accessKey: "Policy",
                    include: false,
                    unauthorizedMessage: noAccessMsg,
                    actions: PolicyViewActions.LOAN,
                    noteCategory:OrderNoteCategory.Policy,
                    templateCategory: 'tp',
                    skipSaveOnLeave: false
                }
            },
            {
                name: "policy:voided:form",
                path: "voided",
                component: VoidedPolicyPage,
                meta: {
                    label: "Voided Policies",
                    accessKey: "Policy",
                    include: false,
                    unauthorizedMessage: noAccessMsg,
                    actions:[],
                    skipSaveOnLeave: true,
                    noteCategory:OrderNoteCategory.Policy,
                    templateCategory: 'tp'
                }
            },
            {
                name: "policy:jackets:form",
                path: "jackets",
                component: PolicyJacketList,
                meta: {
                    label: "Policy Jackets",
                    accessKey: "Policy",
                    include: false,
                    unauthorizedMessage: noAccessMsg,
                    actions:[],
                    skipSaveOnLeave: true,
                    noteCategory:OrderNoteCategory.Policy,
                    templateCategory: 'tp'
                }
            },
            {
                name: "policy:ez-jacket:form",
                path: "generate-ezjacket",
                component: SmktEzJacket,
                meta: {
                    label: "Generate Jacket",
                    accessKey: "Policy",
                    unauthorizedMessage: noAccessMsg,
                    actions:[],
                    skipSaveOnLeave: true,
                    noteCategory: OrderNoteCategory.Policy,
                    templateCategory: 'tp',
                    useScrollLayout: false
                }
            }
        ]
    },
];

_.forEach(TitleRoutes, r => {
    r.meta.noteCategory = OrderNoteCategory.TitleProduction;
    r.meta.templateCategory = "tp";
});
_.forEach(PolicyRoutes, r => {
    r.meta.noteCategory = OrderNoteCategory.Policy;
    r.meta.templateCategory = "tp";
});

export {
    TitleRoutes,
    PolicyRoutes
};
