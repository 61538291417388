<template>
    <div class="row">

        <div class="col-12 col-sm-3 col-md-3 col-lg-6 col-xl-3 form-group" :class="{'form-required' : firstLastRequired, 'has-error' : v$.firstName.$error && showValidationError}">
            <label :for="getAutomationId('txt', 'first_name')">First Name</label>
            <input type="text" class="form-control"
                :id="getAutomationId('txt', 'first_name')"
                :automation_id="getAutomationId('txt', 'first_name')"
                placeholder="First Name"
                :disabled="readOnly"
                maxlength="65"
                v-model="firstName"
                @change="onNameChange">
            <rq-validation-feedback>First Name is required if Entity Name is not provided</rq-validation-feedback>
        </div>

        <div class="col-12 col-sm-2 col-md-2 col-lg-6 col-xl-3 form-group">
            <label :for="getAutomationId('txt', 'middle_name')">Middle Initial</label>
            <input type="text" class="form-control"
                maxlength="4"
                :id="getAutomationId('txt', 'middle_name')"
                :automation_id="getAutomationId('txt', 'middle_name')"
                placeholder="MI"
                :disabled="readOnly"
                v-model="middleName"
                @change="onNameChange">
        </div>

        <div class="col-12 col-sm-3 col-md-3 col-lg-6 col-xl-3 form-group" :class="{'form-required' : firstLastRequired, 'has-error' : v$.lastName.$error && showValidationError}">
            <label :for="getAutomationId('txt', 'last_name')">Last Name</label>
            <input type="text" class="form-control"
                :id="getAutomationId('txt', 'last_name')"
                :automation_id="getAutomationId('txt', 'last_name')"
                placeholder="Last Name"
                :disabled="readOnly"
                maxlength="65"
                v-model="lastName"
                @change="onNameChange">
            <rq-validation-feedback>Last Name is required if Entity Name is not provided</rq-validation-feedback>
        </div>

        <div class="col-12 col-sm-2 col-md-2 col-lg-6 col-xl-3 form-group">
            <label :for="getAutomationId('txt', 'suffix')">Suffix</label>
            <input type="text" class="form-control"
                :id="getAutomationId('txt', 'suffix')"
                :automation_id="getAutomationId('txt', 'suffix')"
                placeholder="Suffix"
                :disabled="readOnly"
                maxlength="7"
                v-model="suffix"
                @change="onNameChange">
        </div>

        <div class="col-12 col-sm-2 col-md-2 col-lg-6 col-xl-3 form-group">
            <label :for="getAutomationId('drp', 'gender')">Gender</label>
            <dx-select-box
                :input-attr="$utils.idAttrs(getAutomationId('drp', 'gender'))"
                :items="genderOptions"
                value-expr="id"
                display-expr="name"
                v-model="gender"
                :disabled="readOnly"
                :show-clear-button="true"
            />
        </div>

        <div class="col-12 col-sm-3 col-md-3 col-lg-6 col-xl-3 form-group">
            <label :for="getAutomationId('txt', 'title')">Title</label>
            <input
                :id="getAutomationId('txt', 'title')"
                :automation_id="getAutomationId('txt', 'title')"
                type="text"
                class="form-control"
                placeholder="Title"
                :disabled="readOnly"
                maxlength="100"
                v-model="title"
            >
        </div>

        <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xl-3">
            <rq-action-form-group
                label="SSN/TAX ID"
                :labelFor="getAutomationId('txt', 'tax_id')"
                :show-action="true"
                :has-error="v$.taxId.$error || taxIdVerificationError"
            >
                <template #action>
                    <span v-if="superMarketDataVerificationEnabled && showTaxIdVerificationCheckmark" :class="{ 'm-auto font-lg inline': true, 'warning-check': taxIdVerificationIconStatus == 'warning', 'success-check': taxIdVerificationIconStatus == 'success' }">
                        <FontAwesomeIcon v-if="taxIdVerificationIconStatus == 'success'" icon="fas fa-check-circle" />
                        <FontAwesomeIcon v-if="taxIdVerificationIconStatus == 'warning'" icon="fas fa-exclamation-triangle" />
                    </span>
                    <button v-if="canEditSSN"
                        type="button" 
                        class="btn btn-link btn-theme"
                        @click="onEditSSNClick"
                        >{{editSSNButton}}
                    </button>
                    <b-form-checkbox v-if="isGdpr"
                        :id="getAutomationId('chk', 'is_gdpr')"
                        :automation_id="getAutomationId('chk', 'is_gdpr')"
                        :disabled="readOnly"
                        v-model="isGdpr"
                        >Subject to GDPR
                    </b-form-checkbox>
                </template>
                <template #default>
                    <div class="input-group">
                        <input type="text" class="form-control" v-if="!showSSNEditor"
                            :id="getAutomationId('txt', 'ssnviewer')"
                            :automation_id="getAutomationId('txt', 'ssnviewer')"
                            :disabled="true"
                            v-model="maskedTaxId"
                        >
                        <foreign-id-input v-else-if="tinType === 2"
                            ref="taxIdInput"
                            :id="getAutomationId('txt', 'tax_id')"
                            :disabled="!canEditSSN || readOnly"
                            v-model="taxId"
                            @change="clearTaxIdVerificationIconAndStatus"
                        />
                        <rq-masked-ssn-input v-else
                            ref="taxIdInput"
                            :id="getAutomationId('txt', 'tax_id')"
                            :mask="taxSsnMask"
                            :disabled="!canEditSSN || readOnly"
                            v-model="v$.taxId.$model"
                            always-refresh
                            @change="clearTaxIdVerificationIconAndStatus"
                        />
                        <rq-bsv-dropdown  v-if="renderComponent" 
                            :id="getAutomationId('drp', 'tax_id')"
                            :items="tinTypeOptions"
                            button-text-expr="selected"
                            :disabled="!canEditSSN || readOnly || !showSSNEditor"
                            behavior="select"
                            v-model="tinType"
                            @change="onTinTypeChange"
                        />
                    </div>
                    <rq-validation-feedback v-if="!taxIdVerificationError" :offset="ssnTaxIdInvalidMessageOffset">{{ ssnTaxIdInvalidMessage }}</rq-validation-feedback>
                    <rq-validation-feedback v-if="taxIdVerificationError" :offset="ssnTaxIdInvalidMessageOffset">Information cannot be verified</rq-validation-feedback>
                </template>
            </rq-action-form-group>
        </div>

        <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xl-3">
            <rq-action-form-group
                label="Email"
                :labelFor="getAutomationId('txt', 'email')"
                show-action
                :has-error="v$.email.$error"
            >
                <template #action>
                    <b-form-checkbox
                        :id="getAutomationId('chk','email_default')"
                        :automation_id="getAutomationId('chk','email_default')"
                        :disabled="readOnly"
                        v-model="emailDefault">Default
                    </b-form-checkbox>
                </template>
                <template #default>
                    <div class="input-group">
                        <input
                            :id="getAutomationId('txt', 'email')"
                            :automation_id="getAutomationId('txt', 'email')"
                            type="text"
                            class="form-control"
                            placeholder="Email"
                            maxlength="500"
                            :disabled="readOnly"
                            v-model="email"
                        >
                        <button :disabled="!email || !isValidEmail" class="btn btn-default" @click="showEmail"><FontAwesomeIcon icon="fa fa-envelope" aria-hidden="true" /></button>
                    </div>
                    <rq-validation-feedback :offset="40">Invalid email format / example@email.com</rq-validation-feedback>
                </template>
            </rq-action-form-group>
        </div>

        <div class="col-12 col-sm-3 col-md-3 col-lg-6 col-xl-3 form-group" :class="{ 'has-error': v$.mobilePhone.$error && showValidationError }">
            <label :for="getAutomationId('txt', 'mobile_phone')">Mobile Phone</label>
             <div class="input-group input-group-phone">
                <rq-masked-input
                    :id="getAutomationId('txt', 'mobile_phone_cc')"
                    :disabled="readOnly"
                    v-model="mobilePhoneCountryCode"
                    :isMasked="true"
                    customMask="###"
                />
                <rq-masked-input
                    :id="getAutomationId('txt', 'mobile_phone')"
                    placeholder="Mobile Phone"
                    :disabled="readOnly"
                    v-model="v$.mobilePhone.$model"
                    :isMasked="true"
                    maskType="phone"
                />
            </div>
            
            <rq-validation-feedback>Mobile Phone format is invalid.</rq-validation-feedback>
        </div>

        <div class="col-12 col-sm-3 col-md-3 col-lg-6 col-xl-3 form-group" :class="{ 'has-error': v$.businessPhone.$error && showValidationError }">
            <label :for="getAutomationId('txt', 'business_phone')">Business Phone</label>
             <div class="input-group input-group-phone">
                <rq-masked-input
                    :id="getAutomationId('txt', 'business_phone_cc')"
                    :disabled="readOnly"
                    v-model="businessPhoneCountryCode"
                    :isMasked="true"
                    customMask="###"
                />
                <rq-masked-input
                    :id="getAutomationId('txt', 'business_phone')"
                    placeholder="Business Phone"
                    :disabled="readOnly"
                    v-model="v$.businessPhone.$model"
                    :isMasked="true"
                    maskType="phone"
                />
            </div>
            <rq-validation-feedback>Business Phone format is invalid.</rq-validation-feedback>
        </div>

        <div class="col-12 col-sm-3 col-md-3 col-lg-6 col-xl-3 form-group" :class="{ 'has-error': v$.homePhone.$error && showValidationError }">
            <label :for="getAutomationId('txt', 'home_phone')">Home Phone</label>
            <div class="input-group input-group-phone">
                <rq-masked-input
                    :id="getAutomationId('txt', 'home_phone_cc')"
                    :disabled="readOnly"
                    v-model="homePhoneCountryCode"
                    :isMasked="true"
                    customMask="###"
                />
                <rq-masked-input
                    :id="getAutomationId('txt', 'home_phone')"
                    placeholder="Home Phone"
                    :disabled="readOnly"
                    v-model="v$.homePhone.$model"
                    :isMasked="true"
                    maskType="phone"
                />
            </div>
            <rq-validation-feedback>Home Phone format is invalid.</rq-validation-feedback>
        </div>

        <div class="col-12 col-sm-3 col-md-3 col-lg-6 col-xl-3 form-group" :class="{ 'has-error': v$.fax.$error && showValidationError }">
            <label :for="getAutomationId('txt', 'fax')">Fax</label>
            <div class="input-group input-group-phone">
                <rq-masked-input
                    :id="getAutomationId('txt', 'fax-cc')"
                    :disabled="readOnly"
                    v-model="faxCountryCode"
                    :isMasked="true"
                    customMask="###"
                />
                <rq-masked-input
                    :id="getAutomationId('txt', 'fax')"
                    placeholder="Fax"
                    :isMasked="true"
                    maskType="phone"
                    :disabled="readOnly"
                    v-model="v$.fax.$model"
                />
            </div>
            <rq-validation-feedback>Fax format is invalid.</rq-validation-feedback>
        </div>

    </div>
</template>

<script>
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";
    import RegEx from "@/shared/utilities/RegEx";
    import { GlobalEventManager } from "@/app.events";
    import ForeignIdInput from "./components/ForeignIdInput";
    import { useLicenseStore } from "@/store/modules/license";
    import { ref } from 'vue';
    const renderComponent = ref(true);
    export default {

        props: {
            buyerSeller: { type: Object, default: () => ({}) },
            originalBuyerSeller: { type: Object, default: () => ({}) },
            nameNumber: { type: Number, default: 1 },
            emailTitle: { type: String, default: "Email User" },
            emailSubject: { type: String, default: "" },
            readOnly: { type: Boolean, default: false },
            showValidationError: { type: Boolean, default: true },
            refreshContact: {type: Boolean, default: false}
        },

        components: { ForeignIdInput },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                renderComponent: true,
                masked: true,
                tinMasks: [
                    ["***-**-**********", "**-************", ""], // Do not show any of the value
                    ["***-**-##########", "**-***##########", ""], // Only show the last 4 of the value
                    ["###-##-##########", "##-#############", ""]  // Show the entire value
                ],
                tinTypeOptions: [
                    { id: 0, name: "Social Security Number", selected: "SSN" },
                    { id: 1, name: "Tax ID", selected: "Tax ID" },
                    { id: 2, name: "Foreign", selected: "Foreign" }
                ],
                genderOptions: [
                    { id:0, name:"Male" },
                    { id:1, name:"Female" }
                ],
                showSSNEditor: false,
                editSSNButton: "Change",
                maskedTaxId: '',
                originalTinType: 0,
                originalTaxIdVerificationDocumentId: '',
                originalTaxIdVerificationStatus: '',
                showTaxIdVerificationCheckmark: false,
                taxIdVerificationIconStatus: '',
                taxIdVerificationError: false,
                superMarketDataVerificationEnabled: false,
            };
        },
        computed: {
            localSecurity() { return this.securitySettings.findValues(["CanEditSSN","CanViewSSN"]); },
            canEditSSN(){ return _.getBool(this, "localSecurity.CanEditSSN"); },
            canViewSSN(){ return _.getNumber(this, "localSecurity.CanViewSSN"); },
            taxSsnMask () {
                let validValues = [0,1,2];
                if(!_.includes(validValues, this.canViewSSN) || !_.includes(validValues, this.tinType)) return "";
                return this.tinMasks[this.canViewSSN][this.tinType];
            },

            isValidEmail() { return _.isEmpty(this.email) || RegEx.Email.test(this.email); },

            automationIdPrefix () { return `name${this.nameNumber}`; },

            entityName() { return this.buyerSeller.businessName || ""; },

            showGdpr(){ return this.taxSsnToggleLabel === "Foreign ID"; },

            /*
                The following computed properties translate like properties to BuyerSeller
                object based on "this.nameNumber" (via this.getPropVal and this.setPropVal)
            */
            firstName: {
                get() { return this.getPropVal("firstName"); },
                set(val) { this.setPropVal("firstName", val); }
            },
            middleName: {
                get() { return this.getPropVal("middleInitial"); },
                set(val) { this.setPropVal("middleInitial", val); }
            },
            lastName: {
                get() { return this.getPropVal("lastName"); },
                set(val) { this.setPropVal("lastName", val); }
            },
            suffix: {
                get() { return this.getPropVal("extension"); },
                set(val) { this.setPropVal("extension", val); }
            },
            gender: {
                get() { return this.getPropVal("gender"); },
                set(val) { this.setPropVal("gender", val); }
            },
            title: {
                get() { return this.getPropVal("jobTitle"); },
                set(val) { this.setPropVal("jobTitle", val); }
            },
            taxId: {
                get() { return this.getPropVal("socialSecurityNumber"); },
                set(val) {this.setPropVal("socialSecurityNumber", val); }
            },
            taxIdVerificationDocumentId: {
                get() { return this.getPropVal("taxIdVerificationDocumentId"); },
                set(val) {this.setPropVal("taxIdVerificationDocumentId", val); }
            },
            taxIdVerificationStatus: {
                get() { return this.getPropVal("taxIdVerificationStatus"); },
                set(val) {this.setPropVal("taxIdVerificationStatus", val); }
            },
            email: {
                get() { return this.getPropVal("emailAddress"); },
                set(val) { this.setPropVal("emailAddress", val); }
            },
            emailDefault: {
                get() { return (this.nameNumber === 1 && this.buyerSeller.emailAddress1Default) || (this.nameNumber === 2 && !this.buyerSeller.emailAddress1Default); },
                set(val) { this.buyerSeller.emailAddress1Default = (this.nameNumber === 1 && val) || (this.nameNumber === 2 && !val); }
            },
            businessPhone: {
                get() { return this.getPropVal("workPhone"); },
                set(val) { this.setPropVal("workPhone", val); }
            },
            businessPhoneCountryCode: {
                get() { return this.getPropVal("workPhoneCountryCode"); },
                set(val) { this.setPropVal("workPhoneCountryCode", val); }
            },
            homePhone: {
                get() {
                    return this.nameNumber === 1
                        ? this.buyerSeller.homePhone
                        : this.buyerSeller.homePhone2;
                },
                set(val) {
                    if(this.nameNumber === 1)
                        this.buyerSeller.homePhone = val;
                    else
                        this.buyerSeller.homePhone2 = val;
                }
            },
            homePhoneCountryCode: {
                get() {
                    return this.nameNumber === 1
                        ? this.buyerSeller.homePhoneCountryCode
                        : this.buyerSeller.homePhoneCountryCode2;
                },
                set(val) {
                    if(this.nameNumber === 1)
                        this.buyerSeller.homePhoneCountryCode = val;
                    else
                        this.buyerSeller.homePhoneCountryCode2 = val;
                }
            },
            mobilePhone: {
                get() { return this.getPropVal("cellPhone"); },
                set(val) { this.setPropVal("cellPhone", val); }
            },
            mobilePhoneCountryCode: {
                get() { return this.getPropVal("cellPhoneCountryCode"); },
                set(val) { this.setPropVal("cellPhoneCountryCode", val); }
            },
            fax: {
                get() { return this.getPropVal("fax"); },
                set(val) { this.setPropVal("fax", val); }
            },
            faxCountryCode: {
                get() { return this.getPropVal("faxCountryCode"); },
                set(val) { this.setPropVal("faxCountryCode", val); }
            },
            isGdpr: {
                get() { return this.getPropVal("isGdpr"); },
                set(val) { this.setPropVal("isGdpr", val); }
            },
            tinType: {
                get(){ return this.getPropVal("tinType"); },
                set(val) { this.setPropVal("tinType", val); }
            },
            isForeignID: {
                get(){ return this.getPropVal("isForeignID"); },
                set(val) { this.setPropVal("isForeignID", val); }
            },

            ssnTaxIdInvalidMessage() {
                const ssnTax = _.find(this.tinTypeOptions, (s) => s.id === this.tinType);
                let invalidMessage = "";
                if (this.tinType === 0) {
                    invalidMessage = "Invalid " + ssnTax.name + " format / XXX-XX-XXXX";
                } else if (this.tinType === 1) {
                    invalidMessage = "Invalid " + ssnTax.name + " format / XX-XXXXXXX";
                }

               return invalidMessage;
            },

            ssnTaxIdInvalidMessageOffset() {
                if (this.tinType === 0)
                    return 60;
                else
                    return 80;
            },

            firstLastRequired() { 
                return (this.nameNumber === 1 && _.isEmpty(this.buyerSeller.businessName)) ||
                        (this.checkIfTaxIdChanged() && _.isEmpty(this.buyerSeller.businessName)); 
            },

            isValid() { return !this.v$.$error; }
        },

        validations() {
            const self = this;
            //Since the phone numbers are being masked to be "(xxx) xxx-xxxx" and limiting character input, we simply have to validate the value's length (14 characters)
            const phoneNumberValid = value => _.isEmpty(value) || value.length === 14;
            const emailValid = value => _.isEmpty(value) || RegEx.Email.test(value);
            const ssnTaxIdValid = value => (
                _.isEmpty(value)
                    || self.tinType === 2
                    || (self.tinType === 1
                        ? RegEx.TaxID.test(value) || RegEx.MaskedTaxID1.test(value) || RegEx.MaskedTaxID2.test(value)
                        : RegEx.SSN.test(value) || RegEx.MaskedSSN1.test(value) || RegEx.MaskedSSN2.test(value))
            );

            return {
                firstName: { required: requiredIf(() => self.firstLastRequired) },
                lastName: { required: requiredIf(() => self.firstLastRequired) },
                email: { emailValid },
                taxId: { ssnTaxIdValid },
                businessPhone: { phoneNumberValid },
                homePhone: { phoneNumberValid },
                mobilePhone: { phoneNumberValid },
                fax: { phoneNumberValid }
            };
        },
        watch: {
            "buyerSeller.businessName" (newValue, oldValue) {
                // Only modify the format when data has completely loaded and the taxId field is empty
                if (newValue === oldValue || this.taxId) return;

                if (!oldValue && newValue) {
                    // Entity name went from nothing to something so set the format to TAX.
                    this.tinType = 1;
                }
                if (oldValue && !newValue) {
                    // Entity name went from soemthing to nothing so set the format to SSN.
                    this.tinType = 0;
                }
            },
            "refreshContact" (newValue, oldValue) {
                this.showSSNEditor = false;
                this.editSSNButton = "Change";
                this.maskedTaxId = this.taxId ?? '';
                this.originalTinType = this.tinType ?? 0;
                this.originalTaxIdVerificationDocumentId = this.taxIdVerificationDocumentId;
                this.originalTaxIdVerificationStatus = this.taxIdVerificationStatus;
                this.adjustTaxIdVerificationIconAndStatus();
            },
        },

        created () {
            const self = this;
            const licenseStore = useLicenseStore();
            const featureFlag = 'superMarketAddressAndTaxIdVerification';
            self.superMarketDataVerificationEnabled = licenseStore.checkFeature(featureFlag);
        },
        methods: {
            async forceRender() {
                this.renderComponent = false;
                await this.$nextTick();
                this.renderComponent = true;
            },
            showEmail () {
                if (!this.email) return;
                GlobalEventManager.email({ title: this.emailTitle, subject: this.emailSubject, to: this.email });
            },

            getAutomationId (type, field) {return `${type}_${this.automationIdPrefix}_${field}`;},

            onNameChange(e) {
                this.v$.$touch();
                this.$emit("nameChange", _.pick(this.contactData, ["firstName", "middleName", "lastName", "suffix"]));
                this.taxIdVerificationDocumentId = '';
                this.taxIdVerificationStatus = '';
            },

            onTinTypeChange(e) {
                const self = this;

                if(e.selectedItem.id !== 2)
                    self.isGdpr = false;
                else if(!_.isEmpty(self.taxId))
                    self.taxId = _.replace(self.taxId, /-/g, "");

                self.$nextTick(() => {
                    _.invoke(self, "$refs.taxIdInput.focus");
                });
            },

            getPropVal(name) { return this.buyerSeller[`${name}${this.nameNumber}`]; },
            setPropVal(name, val) { this.buyerSeller[`${name}${this.nameNumber}`] = val; },
            touch(){ this.v$.$touch(); },
            reset(){ this.v$.$reset(); },
            onEditSSNClick() {
                const self = this;
                self.forceRender();
                self.showSSNEditor = !self.showSSNEditor;
                if(self.showSSNEditor) {
                    self.editSSNButton = 'Revert';
                    self.taxId = '';
                    self.taxIdVerificationDocumentId = '';
                    self.taxIdVerificationStatus = '';
                    self.clearTaxIdVerificationIconAndStatus();
                }
                else {
                    self.editSSNButton = 'Change';
                    self.taxId = self.maskedTaxId;
                    self.tinType = self.originalTinType;

                    let changes = self.getAuditChanges(self.originalBuyerSeller, self.buyerSeller);
                    if((!_.isEmpty(self.buyerSeller.businessName) && changes.some(change => change.name === 'businessName')) || 
                    (!_.isEmpty(self.firstName) && changes.some(change => change.name === `firstName${self.nameNumber}`)) ||
                    (!_.isEmpty(self.lastName) && changes.some(change => change.name === `lastName${self.nameNumber}`))) {
                        self.taxIdVerificationDocumentId = '';
                        self.taxIdVerificationStatus = '';
                    }
                    else {
                        self.taxIdVerificationDocumentId = self.originalTaxIdVerificationDocumentId;
                        self.taxIdVerificationStatus = self.originalTaxIdVerificationStatus;
                    }
                
                    self.adjustTaxIdVerificationIconAndStatus();
                }
            },
            adjustTaxIdVerificationIconAndStatus () {

                if(!_.isNil(this.taxIdVerificationStatus) && this.taxIdVerificationStatus != '') {
                    this.showTaxIdVerificationCheckmark = true;
                    this.taxIdVerificationIconStatus = this.taxIdVerificationStatus == 'VERIFIED' ? 'success' : 'warning';
                    this.taxIdVerificationError = false;
                }
                else {
                    this.clearTaxIdVerificationIconAndStatus();
                }
            },
            clearTaxIdVerificationIconAndStatus() {
                this.showTaxIdVerificationCheckmark = false;
                this.taxIdVerificationIconStatus = '';
                this.taxIdVerificationError = false;
            },
            setTaxIdVerificationError() {
                this.clearTaxIdVerificationIconAndStatus();
                this.taxIdVerificationError = true;
            },
            checkIfTaxIdChanged() {
                const self = this;
                return !_.isNil(self.taxId) && self.taxId != '' && (self.taxId.indexOf('*') < 0);
            }
        }
    };
</script>
<style lang="scss" scoped>
    .success-check { 
        color: #49B848;
        float: right;
        margin-right: 5px !important;
    }
    .warning-check {
        color: #E7B721;
        float: right;
        margin-right: 5px !important;
    }
    .inline {
        display: inline !important;
    }
</style>
