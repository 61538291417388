<template>
    <div class="content-wrapper endorsement-selection">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dg_endorsement_selector"
            :data-source="gridDataSource"
            :config="gridConfig"
            :search-value="initialSearchValue"
            @selectionChanged="onGridSelectionChanged"
            @rowDoubleClick="onGridRowDoubleClick"
            integrated-search
            hide-show-column-chooser
            hide-clear-filters
            hide-export
            fixed-header>
            <template v-if="showFilter" #toolbar>
                <div class="form-group mb-1 w-25 me-auto">
                    <rqSelectBox
                        automation_id="drp_type_filter"
                        :items="endorsementPolicyTypes"
                        :defaultItem="{ text:'Filter by type...' }"
                        :showClearValueIcon="true"
                        v-model="filterValue"
                    />
                </div>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import { EndorsementDto, EndorsementPolicy } from "../../models";
    import { BreakoutEndorsementOption } from "@settlement/models/enums";
    import { mapState } from "vuex";

    export default {
        name: "EndorsementSelection",
        mixins: [ GridInvokerMixin({ grid:"dataGrid" }) ],
        props: {
            orderId: { type: Number, default: null },
            loanId: { type: Number, default: null },
            settlmentFeesOnly: { type: Boolean, default: false },
            showFilter: { type: Boolean, default: true },
            initialFilterValue: { type: Number, default: null },
            initialSearchValue: { type: String, default: "" }
        },

        data() {
            const self = this;
            return {
                gridDataSource: [],
                selectedEndorsement: null,
                selectedEndorsementIds: [],
                filterValue: self.initialFilterValue
            };
        },

        computed: {
            hasSearchText() { return !_.isEmpty(this.searchText); },
            ...mapState({
                cdfSections: state => state.orders.orderLookups.cdfSections,
                lineTypes: state => state.system.lookups.settlementFeeLineTypes
            })
        },

        watch: {
            selectedEndorsement (newValue, oldValue) {
                if (!newValue) return;
                this.$emit("enable-ok");
            },
            filterValue(newValue, oldValue){
                if(newValue === oldValue) return;
                this.filterGrid(newValue);
            }
        },

        created() {
            const self = this;
            self.$emit("disable-ok");
            self.endorsementPolicyTypes = EndorsementPolicy.lookupItems;
            self.initGridConfig();
        },

        methods: {
            initGridConfig() {
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "code", dataType: "string" },
                        { dataField: "description", dataType: "string", caption: "Description" },
                        {
                            caption: "HUD Line Type",
                            dataField: "hudLineType",
                            dataType: "number",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: self.lineTypes
                            }
                        },
                        {
                            caption: "CDF Section",
                            dataField: "cdfLineType",
                            dataType: "number",
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: self.cdfSections
                            }
                        },
                        { dataField: "loan", dataType: "number", format:{ type:"currency", precision: 2 } },
                        { dataField: "owner", dataType: "number", format:{ type:"currency", precision: 2 } },
                        {
                            dataField: "endorsementPolicyTypeID",
                            dataType: "number",
                            caption: "Type",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: self.endorsementPolicyTypes
                            }
                        },
                        {
                            dataField: "showOutsideOfPremiums",
                            caption: "Show Outside Premium",
                            dataType: "boolean",
                            cellTemplate(cellElement, cellInfo) {
                                let hasEndorsementValue = _.parseNumber(cellInfo.data.owner, 0) > 0 || _.parseNumber(cellInfo.data.loan, 0) > 0;
                                cellElement.text(_.parseNumber(cellInfo.value, 1) === 1 && hasEndorsementValue ? "Yes" : "No" );
                            },
                        },
                        {
                            dataField: "breakoutOverride",
                            dataType: "number",
                            caption: 'Breakout',
                            cellTemplate(cellElement, cellInfo) {
                                let displayValue = BreakoutEndorsementOption.displayValue(_.parseNumber(cellInfo.value, 0));
                                cellElement.text(displayValue);
                            },
                            width: 75
                        },
                        { dataField: "accountCodeDescription", dataType: "string", caption:"Account Code" },
                        { dataField: "state", dataType: "string", width: 75 },
                        { dataField: "basedOnMinimum", dataType: "number", caption: "Minimum", format:{ type:"currency", precision: 2 } },
                        { dataField: "basedOnMaximum", dataType: "number", caption: "Maximum", format:{ type:"currency", precision: 2 } },
                        DxGridUtils.dateColumn({
                            dataField: 'importDate',
                            cellTemplate: DxGridUtils.dateOnlyTimeZoneDependentCellTemplate
                        }),
                        {
                            dataField: "docs",
                            dataType: "boolean",
                            width: 75,
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    noDataText: `No ${self.settlmentFeesOnly ? "settlement fees" : "endorsements"} found.`,
                    allowReordering: false
                };
                self.gridDataSource = { key: "endorsementID", load: self.fetchData };
            },

            fetchData () {
                const self = this;
                if (!self.orderId) return [];
                let apiPromise = self.settlmentFeesOnly
                    ? self.$api.SettlementFeesApi.getAvailableFees(self.orderId, self.loanId)
                    : self.$api.RateEngineApi.getEndorsements(self.orderId, self.loanId);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => _.map(response, d => new EndorsementDto(d)))
                    .catch(err => self.handleRequestError(err));
            },

            onGridSelectionChanged (e) {
                const self = this;
                self.selectedEndorsementIds = this.invokeGridMethod("getSelectedRowKeys");
                let emit = (self.selectedEndorsementIds.length > 0) ? "enable-ok" : "disable-ok";
                self.$emit(emit);
            },

            onGridRowDoubleClick (e) {
                const self = this;
                self.selectedEndorsementIds = this.invokeGridMethod("getSelectedRowKeys");
                if (e.data) {
                    self.$emit("action", { name: "selection", data: e.data });
                } else {
                    self.selectedEndorsement = null;
                }
            },

            filterGrid(val) {
                if(_.isNil(val)){
                    this.invokeGridMethod("clearFilter", "dataSource");
                    return;
                }
                this.invokeGridMethod("filter", ["endorsementPolicyTypeID", "=", val]);
            },

            updateDimensions: _.debounce(function(e) {
                this.invokeGridMethod("updateDimensions");
            }, 100),

            refresh () {
                this.invokeGridMethod("refresh");
            },

            handleRequestError (error) {
                this.$toast.error(`A problem was encountered while retrieving ${this.settlmentFeesOnly ? "settlement fees" : "endorsements"}.  Please contact your administrator.`);
                console.error(error);
                return error;
            }
         }
    };
</script>