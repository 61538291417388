<template>
    <div class="content-wrapper">
        <rq-page-section title="Transactions">
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item form-group ps-3">
                        <b-form-checkbox
                            automation_id="chk_point_of_sale"
                            v-model="taxInquiry.pointOfSale"
                        >
                            Point of Sale
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
            <fieldset :disabled="isOrderLocked">
                <div class="row">
                    <div class="col col-12 col-lg-6 col-xl-3">
                        <div class="mb-1 d-flex justify-content-center">
                            <span class="column-title">Transaction 1</span>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="dtp_trans1_date">Date</label>
                            <rqdx-date-box
                                id="dtp_trans1_date"
                                v-model="taxInquiry.recDate1"
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_trans1_stampfees">Stamp Fees</label>
                            <rqInputNumber
                                automation_id="txt_trans1_stampfees"
                                cssClass="form-control"
                                decimals="2"
                                input-group
                                no-prefix
                                prependIcon="fas fa-dollar-sign"
                                v-model="taxInquiry.recFees1"
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_trans1_volpage">Vol/Page</label>
                            <input
                                automation_id="txt_trans1_volpage"
                                class="form-control"
                                type="text"
                                maxlength="12"
                                v-model="taxInquiry.recVolPage1"
                            />
                        </div>
                    </div>
                    <div class="col col-12 col-lg-6 col-xl-3">
                        <div class="mb-1 d-flex justify-content-center">
                            <span class="column-title">Transaction 2</span>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="dtp_trans2_date">Date</label>
                            <rqdx-date-box
                                id="dtp_trans2_date"
                                v-model="taxInquiry.recDate2"
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_trans2_stampfees">Stamp Fees</label>
                            <rqInputNumber
                                automation_id="txt_trans2_stampfees"
                                cssClass="form-control"
                                decimals="2"
                                input-group
                                no-prefix
                                prependIcon="fas fa-dollar-sign"
                                v-model="taxInquiry.recFees2"
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_trans2_volpage">Vol/Page</label>
                            <input
                                automation_id="txt_trans2_volpage"
                                class="form-control"
                                type="text"
                                maxlength="12"
                                v-model="taxInquiry.recVolPage2"
                            />
                        </div>
                    </div>
                    <div class="col col-12 col-lg-6 col-xl-3">
                        <div class="mb-1">
                            <label>&nbsp;</label>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_originalFile">Original File</label>
                            <input
                                automation_id="txt_originalFile"
                                class="form-control"
                                type="text"
                                maxlength="20"
                                v-model="taxInquiry.originalGF"
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_for_year">For Year</label>
                            <rqInputNumber
                                automation_id="txt_for_year"
                                cssClass="form-control"
                                max-chars="4"
                                format-type="basic"
                                v-model="taxInquiry.ofYear"
                            />
                        </div>
                    </div>
                    <div class="col col-12 col-lg-6 col-xl-3">
                        <div class="mb-1">
                            <label>&nbsp;</label>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_listedInNameOf">Listed in Name Of</label>
                            <input
                                automation_id="txt_listedInNameOf"
                                class="form-control"
                                type="text"
                                maxlength="60"
                                v-model="taxInquiry.listedInName"
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="txt_for_which_half">For Which Half</label>
                            <dx-select-box
                                automation_id="txt_for_which_half"
                                :items="whichHalfList"
                                value-expr="id"
                                display-expr="name"
                                v-model="taxInquiry.whichHalf"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Valuation">
            <fieldset :disabled="isOrderLocked">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_land">Land</label>
                        <rqInputNumber
                            automation_id="txt_land"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.landValue"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_improvements">Improvements</label>
                        <rqInputNumber
                            automation_id="txt_improvements"
                            cssClass="form-control"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.improvedValue"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_homestead">Homestead</label>
                        <rqInputNumber
                            automation_id="txt_homestead"
                            cssClass="form-control"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.homeStead"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_total">Total</label>
                        <rqInputNumber
                            automation_id="txt_total"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.totalValue"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label class="form-control-label" for="txt_landnotes">Land Notes</label>
                        <input
                            automation_id="txt_landnotes"
                            class="form-control"
                            type="text"
                            maxlength="50"
                            v-model="taxInquiry.landNotes"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Current Tax Information">
            <fieldset :disabled="isOrderLocked">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="dtp_inquiry_date">Inquiry Date</label>
                        <rqdx-date-box
                            id="dtp_inquiry_date"
                            v-model="taxInquiry.inquiryDate"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_saleprice">Sale Price</label>
                        <rqInputNumber
                            automation_id="txt_saleprice"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.salesPrice"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_lotsize">Lot Size</label>
                        <input
                            automation_id="txt_lotsize"
                            class="form-control"
                            type="text"
                            maxlength="15"
                            v-model="taxInquiry.lotSize"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_currenttax">Current Tax</label>
                        <rqInputNumber
                            automation_id="txt_currenttax"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.currentTax"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_hbcredit">HB Credit</label>
                        <rqInputNumber
                            automation_id="txt_hbcredit"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.hbCredit"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_homesteadexemption">Homestead Exemption</label>
                        <rqInputNumber
                            automation_id="txt_homesteadexemption"
                            cssClass="form-control"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.homeSteadExemption"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_rollback">Roll Back</label>
                        <rqInputNumber
                            automation_id="txt_rollback"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.rollBack1"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_rollbacka">Roll Back A</label>
                        <rqInputNumber
                            automation_id="txt_rollbacka"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.rollBack2"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Assessments">
            <fieldset :disabled="isOrderLocked">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_oneAmount">Assessment 1</label>
                        <rqInputNumber
                            automation_id="txt_oneAmount"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.assess1"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 form-group">
                        <label class="form-control-label" for="txt_oneFunds">Assessment 1 Funds</label>
                        <input
                            automation_id="txt_oneFunds"
                            class="form-control"
                            type="text"
                            maxlength="30"
                            v-model="taxInquiry.fund1"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_twoAmount">Assessment 2</label>
                        <rqInputNumber
                            automation_id="txt_twoAmount"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.assess2"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 form-group">
                        <label class="form-control-label" for="txt_twoFunds">Assessment 2 Funds</label>
                        <input
                            automation_id="txt_twoFunds"
                            class="form-control"
                            type="text"
                            maxlength="30"
                            v-model="taxInquiry.fund2"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_threeAmount">Assessment 3</label>
                        <rqInputNumber
                            automation_id="txt_threeAmount"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.assess3"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 form-group">
                        <label class="form-control-label" for="txt_threeFunds">Assessment 3 Funds</label>
                        <input
                            automation_id="txt_threeFunds"
                            class="form-control"
                            type="text"
                            maxlength="30"
                            v-model="taxInquiry.fund3"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_funds">Funds</label>
                        <rqInputNumber
                            automation_id="txt_funds"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.fund4"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 form-group">
                        <label class="form-control-label" for="txt_originalDesc">Other Desc</label>
                        <input
                            automation_id="txt_originalDesc"
                            class="form-control"
                            type="text"
                            maxlength="15"
                            v-model="taxInquiry.other"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_amount">Amount</label>
                        <rqInputNumber
                            automation_id="txt_amount"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.otherAmount"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_delTax">Delinquent Tax</label>
                        <rqInputNumber
                            automation_id="txt_delTax"
                            cssClass="form-control"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.delinquentTax"
                        />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_initials">Initials</label>
                        <input
                            automation_id="txt_initials"
                            class="form-control"
                            type="text"
                            maxlength="5"
                            v-model="taxInquiry.initials"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_total">Total</label>
                        <rqInputNumber
                            automation_id="txt_total"
                            cssClass="form-control"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="taxInquiry.total"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section borderless>
            <div class="row">
                <div class="col-sm-12 col-md-12 form-group">
                    <label class="form-control-label" for="txt_brief_legal_description">Brief Legal Description</label>
                    <input
                        automation_id="txt_brief_legal_description"
                        type="text"
                        class="form-control"
                        disabled
                        v-model="currentProperty.legalDescription">
                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
export default {
    props: {
        taxInquiry: {
            type: Object,
            default: () => {}
         },
         currentProperty: {
            type: Object,
            default: () => {}
         },
        isOrderLocked: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            whichHalfList: [
                {
                    id: 0,
                    name: "1st"
                },
                {
                    id: 1,
                    name: "2nd"
                }
            ]
        }
    }
};
</script>