<template>
    <rq-page-section title="Note" borderless>
        <div v-if="!propIsNull" class="form-group">
            <textarea id="invoice_notes"
                automation_id="invoice_notes"
                placeholder="Enter notes here..."
                class="form-control"
                v-model="invoiceDetail.note1"
                :disabled="readOnly">
            </textarea>
        </div>
    </rq-page-section>
</template>

<script>
    import { mapState } from "vuex";

    export default{
        name:"NoteSection",
        props:{
            invoiceDetail:{type: Object}
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                systemDefaults: state => state.system.systemDefaults,
                order: state => state.orders.order
            }),
            propIsNull(){return _.isNil(this.invoiceDetail)},
            readOnly() { return this.isReadOnly || this.isOrderLocked; },
        },
    }
</script>