<template>
    <rqdx-action-data-grid
        v-show="shown"
        ref="grid"
        automation_id="grid_contacts"
        :actions="selectionActions"
        :data-source="gridData"
        :config="gridConfig"
        export-file-name="order_contacts"
        rq-filters
        search-mode="field"
        v-model:search-value="searchText"
        @selectionChanged="onGridSelectionChanged"
        @rowDoubleClick="onGridRowDoubleClick"
        @delete="onDelete"
        @view-edit="onViewEdit"
        @assign="onAssign"
        fixed-header
    />
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { RoleType } from "@/shared/models/enums";
    import { RegEx } from "@/shared/utilities";

    export default {
        props: {
            gridData: { type: Array, default: () => []},
            readOnly: { type: Boolean, default: false },
            shown: { type: Boolean, default: false }
        },
        data: function() {
            return {
                searchText: "",
                roles: []
            }
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.grid.gridInstance", null) || {}; },
            roleLookup() { return _.uniq(_.map(this.gridData, "role")); }
        },
        watch: {
            gridData(newValue, oldValue) {
                this.refreshGrid();
            },
            shown(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.refreshGrid();
                this.$nextTick(() => {
                    this.updateDimensions();
                });
            },
            searchText(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.gridSearch(newValue);
            }
        },
        created() {
            this.roles = this.lookupHelpers.getRoles();
            this.initNonReactiveVariables();
        },
        methods: {
            initNonReactiveVariables() {
                const self = this;
                self.gridConfig = {
                    keyExpr: "rolesID",
                    columns: [
                        {
                            dataField: "role",
                            dataType: "string",
                            caption: "Role",
                            alignment: "left",
                            calculateDisplayValue: "roleDisplayValue",
                            rqFilter: {
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.roleLookup.slice();
                                    }
                                }
                            }
                        },
                        { dataField: "companyName", dataType: "string", caption: "Company" },
                        { dataField: "contactName", dataType: "string", caption: "Contact" },
                        { dataField: "primaryPhone", dataType: "string", caption: "Phone" },
                        { dataField: "cell", dataType: "string", caption: "Mobile" },
                        {
                            dataField: "email",
                            dataType: "string",
                            cellTemplate(cellElement, cellInfo) {
                                if(_.isEmpty(cellInfo.value) || !RegEx.Email.test(cellInfo.value)) {
                                    cellElement.text(cellInfo.value);
                                    return;
                                }
                                $("<a/>")
                                    .attr("href",`mailto:${cellInfo.value}`)
                                    .text(cellInfo.value)
                                    .appendTo(cellElement);
                            }
                        },
                        { dataField: "address", dataType: "string"},
                        { dataField: "stateLicenseID", dataType: "string", caption: "State License", visible: false},
                        { dataField: "nmlsid", dataType: "string", caption: "NMLS ID", visible: false},
                        { dataField: "stateIssuedLicense", dataType: "string", caption: "State Issued", visible: false}
                    ],
                    remoteOperations: false
                };
                self.selectionActions = [
                    {
                        name: "edit", text: "View/Edit", eventName: "view-edit", requireSelection: true, allowMultiSelection: false, tooltip: "View/Edit Contact",
                        disabled: function(e) {

                            if (_.isNull(e.data.companyID) && _.isNull(e.data.contactID) && _.isNull(e.data.buyerSellerID))
                                return true
                            return  false;
                        }
                    },
                    {
                        name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true,
                        tooltip: function(e) {
                            let data = e.data;
                            if(data.length > 1)
                                return "Delete Contacts"
                            else
                                return `Delete ${_.startCase(data[0].role)}`;
                        },
                        disabled: function(e) {
                            let selectedContacts = e.data;
                            if(_.some(selectedContacts, i => i.hasOutstandingChecks)) {
                                if (selectedContacts.length > 1)
                                    return `One or more contact(s) selected have outstanding checks`;
                                else
                                    return `Unable to delete this ${ selectedContacts[0].role.toLowerCase() }, due to outstanding checks`;
                            }
                            if(self.readOnly) return "Access Restricted";
                            let canDelete = self.canDelete(selectedContacts);

                            if(!canDelete) {
                                if(selectedContacts.length > 1)
                                    return "One or more contact(s) selected is a required role and cannot be deleted";
                                else
                                    return "Cannot delete required roles";
                            }
                            return false;
                        }
                    },
                    {
                        name: "assign", text: "Assign", eventName: "assign", requireSelection: true, allowMultiSelection: false,
                        tooltip: "Assign Company/Contact",
                        disabled: function(e) {
                            let data = e.data;
                            if (self.readOnly) {
                                return "Access Restricted";
                            }
                            if (data.isBuyerSeller) {
                                return "Buyer/Seller cannot be assigned from here.";
                            }
                            if (!self.canDelete([data])) {
                                return "Company/contact cannot be assigned to this role from here.";
                            }
                            return false;
                        },
                    },
                ];
            },
            onGridRowDoubleClick(e){
                const self = this;
                let contact = e.data;
                if(contact.isBuyerSeller) {
                    self.navigateToBuyerSeller(contact);
                }
                else {
                    self.navigateToCompany(contact);
                }
            },
            onGridSelectionChanged(e){
                if (e.selectedRowKeys.length == 0) e.component.option("focusedRowIndex", -1);
            },
            onDelete(e) {
                this.$emit('delete-contact', e);
                this.clearGrid();
            },
            onViewEdit(e) {
                if (e.data.isBuyerSeller) {
                    this.navigateToBuyerSeller(e.data);
                } else if (!_.isNil(e.data.contactID)) {
                        this.navigateToContact(e.data);
                } else {
                        this.navigateToCompany(e.data);
                }
            },
            onAssign(e) {
                if (!e.data) return;
                this.$emit("assign", e);
            },

            navigateToBuyerSeller(contact){
                if(contact.roleTypeID === RoleType.Buyer) {
                    this.$router.push({ name:"oe:oc:buyer", params: { orderId: this.orderId, buyerSellerId: contact.buyerSellerID } });
                }
                else if(contact.roleTypeID === RoleType.Seller) {
                    this.$router.push({ name:"oe:oc:seller", params: { orderId: this.orderId, buyerSellerId: contact.buyerSellerID } });
                }
            },
            navigateToCompany(contact) {
                if(!contact || _.parseNumber(contact.companyID, 0) === 0) return;
                this.$router.push({ name:"oe:oc:company", params: { orderId: this.orderId, companyId: contact.companyID } });
            },
            navigateToContact(contact) {
                if(!contact || _.parseNumber(contact.companyID, 0) === 0 || _.parseNumber(contact.contactID, 0) === 0) return;
                this.$router.push({ name:"oe:oc:contact", params: { orderId: this.orderId, companyId: contact.companyID, contactId: contact.contactID } });
            },
            clearGrid() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },
            refreshGrid() {
                return _.invoke(this, "gridInstance.refresh");
            },
            updateDimensions() {
                return _.invoke(this, "gridInstance.updateDimensions");
            },
            canDelete(selectedItems) {
                let self = this;
                let hasCantDelete = _.some(selectedItems, x => {
                    let roleObject = _.find(self.roles, r => _.parseNumber(r.id) === _.parseNumber(x.roleTypeID));
                    let canDelete = x.isBuyerSeller || (!_.isNil(roleObject) && _.parseBool(roleObject.canDelete));
                    return !canDelete;
                });
                return !hasCantDelete;
            },
            gridSearch: _.debounce(function(searchTextValue) {
                return _.invoke(this, "gridInstance.searchByText", searchTextValue);
            }, 300)
        }
    }
</script>