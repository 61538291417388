<template>
    <div class="rq-container">
        <div class="row mt-3">
            <div class="col-12">
                <p>A Closing Disclosure Form (CDF), HUD Settlement Statement (HUD) and/or Settlement Statement (SS) exist on
                    this order.</p>
                <p>Choose which Settlement Statement from which to pull Receipts and Disbursements.</p>
            </div>
        </div>
        <div class="row">
            <div class="col col-12 form-group form-required">
                <label for="drp_default_statement">Default Settlement Statement</label>
                <dx-select-box :input-attr="{ id: 'drp_default_statement', automation_id: 'drp_default_statement' }"
                    value-expr="id" display-expr="name" :items="defaultOptions" :search-enabled="true" class="form-control"
                    v-model="autoRecreateSource" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "DefaultSettlementStatement",
        props: {
            originalValue: { type: Number, required: true },
            defaultOptions: { type: Object, required: true },
        },
        data() {
            return {
                autoRecreateSource: 0
            }
        },
        created() {
            this.autoRecreateSource = this.originalValue;
        },
    }
</script>