<template>
    <div class="content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <rq-banner
            :message="warnMessage"
            variant="warn"
            icon="fas fa-exclamation-triangle"
            :visible="warnMessage.length > 0"
        />
        <rq-page-section :title="title" borderless headerSize="md">
            <template #header-actions>
                <ul class="nav ms-auto" >
                    <li class="nav-item form-group">
                        <b-form-checkbox
                            automation_id="chk_simulate_print"
                            id="chk_simulate_print"
                            :disabled="readOnly || !localSecurity.AllowCheckPrintOnPaper || hasBankMismatch"
                            @change="onSimulatePrintChange"
                            v-model="item.simulatePrint">Do not print on paper
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item form-group" v-if="showCheckWritingPINFeature">
                        <b-form-checkbox
                            automation_id="chk_no_sig_print"
                            id="chk_no_sig_print"
                            :disabled="readOnly || hasBankMismatch"
                            @change="onNoSignaturePrintChange"
                            v-model="item.noSignaturePrint">Print without signature
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="col col-6 form-group">
                    <label for="txt_available_checks">Available Check Ranges</label>
                    <input id="txt_available_checks" automation_id="txt_available_checks" v-model="checkRange" type="text" class="form-control" disabled />
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-6 form-group form-required':true, 'has-error': v$.item.initialCheckNumber.$error }">
                    <label class="form-control-label" for="txt_initial_check_number">Starting Check Number</label>
                    <rqInputNumber
                        automation_id="txt_initial_check_number"
                        formatType="basic"
                        defaultValue="0"
                        :minValue="0"
                        :disabled="readOnly || !localSecurity.AllowCheckNumberChange || hasBankMismatch"
                        decimals="0"
                        input-group
                        no-prefix
                        v-model="item.initialCheckNumber" />
                    <rq-validation-feedback
                        :messages="{
                            'Starting Check Number is required': v$.item.initialCheckNumber.required.$invalid,
                            'Starting Check Number is out of range': v$.item.initialCheckNumber.isInRange.$invalid,
                            }"
                    />
                </div>
                <div :class="{ 'col col-6 form-group form-required':true, 'has-error': v$.item.checkDate.$error }">
                    <label for="dtp_check_date">Check Date</label>
                    <rqdx-date-box
                        id="dtp_check_date"
                        :disabled-dates="getDisabledCheckDates"
                        :disabled="readOnly || hasBankMismatch"
                        @valueChanged="onCheckDateChanged"
                        v-model="v$.item.checkDate.$model"
                    />
                    <rq-validation-feedback
                        :messages="{
                            'Check Date is required': v$.item.checkDate.required.$invalid,
                            'Check Date is not within range': !v$.item.checkDate.required.$invalid && v$.item.checkDate.isInRange.$invalid,
                            }"
                    />
                </div>
            </div>
            <div class="row" v-if="showCheckWritingPINFeature">
                <div :class="{ 'col col-6 form-group':true, 'form-required': !item.noSignaturePrint, 'has-error': v$.item.securityPin.$error }">
                    <label for="txt_available_checks">Enter PIN</label>
                    <input
                        id="txt_security_pin"
                        automation_id="txt_security_pin"
                        v-model="v$.item.securityPin.$model"
                        type="password"
                        class="form-control"
                        :disabled="item.noSignaturePrint"
                        autocomplete="off"
                        @keydown="onSecurityPinKeyDown"
                        @copy.prevent
                        @paste.prevent
                        @click.right.prevent
                        />
                    <rq-validation-feedback
                        :messages="{
                            'Security PIN is required': v$.item.securityPin.required.$invalid,
                            'Security PIN invalid': !v$.item.securityPin.required.$invalid && v$.item.securityPin.isValid.$invalid,
                            }"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import { CheckPrintRequestDto, CheckPrintDataDto }  from "../models";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import DocumentViewer from "@documents/views/DocumentViewer";
    import { required, requiredIf } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    const FINALIZED_RECON_WARNING = "Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.";

    export default {
        name: "CheckWritingPrintForm",
        props: {
            bank: { type: Object, required: true, default: () => ({}) },
            summary: { type: Object, default: () => ({}) },
            item: { type: Object, required: true, default: () => new CheckPrintRequestDto() },
            isConsolidatedCheck: { type: Boolean, default: false },
            lastFinalizedReconDate: {type: String, required: true},
            checkWritingPINEnabled: { type: Boolean, default: false },
        },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                errorMessage: "",
                warnMessage: "",
                printData: new CheckPrintDataDto(),
                checkPreviewList: [],
                autoGeneratedNumber: 0,
                hasBankMismatch: false,
                isLoading: false,
                isValidPIN: false
            }
        },
        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
            "item.securityPin":{
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.validatePIN(newValue);
                }
            },
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            checkRange() { return this.item.simulatePrint ? `${this.printData.checkStartSimulated} - ${this.printData.checkEndSimulated}` : `${this.printData.checkStart} - ${this.printData.checkEnd}`; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowCheckPrintOnPaper",
                    "AllowCheckNumberChange",
                    "AllowChecksWhenUnbalanced",
                    "AllowFinalizedReconModifications",
                    "AllowPrintChecks",
                    "AnticipatedDeposits",
                    "SendChecksToFileScan",
                    "CheckPreDateDays",
                    "CheckPostDateDays",
                    "SecurityPinRequired"
                ]);
            },
            readOnly() { return !this.isConsolidatedCheck && (this.isReadOnly || this.isFileLocked); },
            title() { return _.get(this, "bank.companyName", ""); },
            hasCheckTemplateFile() { return _.get(this, "bank.checkTemplateFile.length", 0) > 0; },
            checkOutOfRangeMessage(){ return `Check# ${this.item.initialCheckNumber} is not in Range.`;},
            showCheckWritingPINFeature() { return this.checkWritingPINEnabled && this.localSecurity.SecurityPinRequired }
        },
        created(){
            this.init();
            this.fetchData();
        },
        validations() {
            const self = this;
            return {
                item: {
                    checkDate: {
                        required,
                        isInRange: val => self.isCheckDateInRange(val)
                    },
                    initialCheckNumber:{
                        required,
                        isInRange: val => self.isCheckInRange(val)
                    },
                    securityPin:{
                        required: requiredIf(() => self.showCheckWritingPINFeature && !_.parseBool(self.item.noSignaturePrint, false)),
                        isValid: val => self.isValidPIN || !self.checkWritingPINEnabled,
                    }
                }
            };
        },
        methods: {
            fetchData() {
                const self = this;
                self.isLoading = true;
                let apiPromise = self.$api.CheckWritingApi.getCheckPrintData(self.item.ordersID, self.bank.companyID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.printData = new CheckPrintDataDto(result);
                        self.hasBankMismatch = self.bank.companyID !== self.printData.bankCompanyID;
                        self.item.initialCheckNumber = self.printData.nextCheckNumber;
                        self.autoGeneratedNumber = self.item.initialCheckNumber;
                        self.validate();
                        self.v$.$reset();
                        self.isLoading = false;
                    })
                    .catch(error => {
                        self.isLoading = false;
                        console.error(error);
                        self.$toast.error({ message: error.errorMessage });
                    });
            },

            getDisabledCheckDates(e) {
                //note: returning true disables the date, false enables it.  Can be confusing.
                let now = DateTime.now().startOf("day");
                let reconDate = DateTime.fromISO(this.lastFinalizedReconDate).plus({days: 1}).startOf("day");
                let reconDaysDiff = reconDate.diff(now, "days").days;
                let allowFinalizedReconModifications = _.parseBool(this.localSecurity.AllowFinalizedReconModifications, false);
                let checkPreDateDaysSetting = allowFinalizedReconModifications ? this.localSecurity.CheckPreDateDays : Math.abs(reconDaysDiff);
                let checkPostDateDaysSetting = this.localSecurity.CheckPostDateDays;
                //-1 === unlimited
                if(checkPreDateDaysSetting === -1 && checkPostDateDaysSetting === -1) return false;

                let currentDate = DateTime.fromJSDate(e.date).startOf("day").isValid ? DateTime.fromJSDate(e.date).startOf("day") : DateTime.fromISO(e.date).startOf("day");
                let daysDiff = currentDate.diff(now, "days").days;

                if(daysDiff === 0) return false; //today is always valid

                //predate is unlimited but not postdate
                if(checkPreDateDaysSetting === -1){
                    if(daysDiff <= 0) return false;
                    return daysDiff > checkPostDateDaysSetting;
                }
                //postdate is unlimited but not predate
                else if(checkPostDateDaysSetting === -1){
                    if(daysDiff >= 0) return false;
                    return Math.abs(daysDiff) > checkPreDateDaysSetting;
                }
                //neither is unlimited
                return (daysDiff < 0 && Math.abs(daysDiff) > checkPreDateDaysSetting) || (daysDiff > 0 && daysDiff > checkPostDateDaysSetting);
            },

            init() {
                this.$emit("disable-ok");
            },

            isCheckDateInRange(val){
                if (_.isNil(val)) return true;
                return !this.getDisabledCheckDates({date: val});
            },

            isCheckInRange(checkNumber) {
                // _.inRange is non-inclusive on the end value so need a + 1
                if (this.isLoading) return true;
                let inRange = this.item.simulatePrint ? _.inRange(checkNumber, this.printData.checkStartSimulated, this.printData.checkEndSimulated + 1) : _.inRange(checkNumber, this.printData.checkStart, this.printData.checkEnd + 1);
                this.errorMessage = inRange ? "" : this.checkOutOfRangeMessage;
                return inRange;
            },

            isValid(){
                this.v$.$touch();
                return !this.v$.$error;
            },

            onCheckDateChanged(e){
                this.errorMessage = "";
                this.validate();
            },

            onNoSignaturePrintChange(e){
                if (e) {
                    this.item.securityPin = null;
                    this.isValidPIN = true;
                }
                this.v$.$reset();
                this.validate();
            },

            onSecurityPinKeyDown(e){
                if(_.isNil(e) || _.isNil(e.key)) {
                    return;
                }

                const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Tab', 'ArrowLeft', 'ArrowRight', 'Backspace'];
                let key = e.key;
                if(_.includes(allowedKeys, key)) {
                    return;
                }

                e.preventDefault();
            },

            onSimulatePrintChange(e){
                this.validate();
            },

            printChecks(){
                const self = this;
                if(self.readOnly) return;
                if(self.simulatePrint && !self.localSecurity.AllowCheckPrintOnPaper) return;
                self.item.checkDocumentTemplateID = self.printData.checkDocumentTemplateID;
                self.item.isManualCheckNumber = self.autoGeneratedNumber !== self.item.initialCheckNumber;
                let apiPromise = self.isConsolidatedCheck
                    ? self.$api.ConsolidatedChecksApi.printChecks(self.item)
                    : self.$api.CheckWritingApi.printChecks(self.item.ordersID, self.item);

                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        if (!self.item.simulatePrint) {
                            self.checkPreviewList = _.isArray(result.checkPreviewList) ? result.checkPreviewList : [];
                            if (self.checkPreviewList.length > 0) {
                                self.$store.dispatch(DOCUMENT_ACTIONS.ADD_CHECKS_TO_QUEUE, {
                                    ordersID: self.item.ordersID,
                                    checksList: self.checkPreviewList,
                                    checkDocumentID: self.printData.checkDocumentTemplateID,
                                    noSignaturePrint: self.showCheckWritingPINFeature ? _.parseBool(self.item.noSignaturePrint) : null
                                })
                                .then(() => {
                                    self.launchDocumentViewer();
                                })
                            }
                        }
                        return Promise.resolve(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: error.message });
                        return Promise.reject(error)
                    });
            },

            launchDocumentViewer () {
                const self = this;
                let onOk = (e) => {
                    self.errorMessage = "";
                    //return e.component.confirmPrintedChecks();
                    let form = e.component;
                    //first save edited & printed Checks
                    return form.saveEditedChecks()
                        .then(() => {
                            let unPrintedChecksIDs = form.getUnprintedCheckIDs() || [];
                            if (unPrintedChecksIDs.length == 0) {
                                return true;
                            } else {
                                return new Promise(resolve => {
                                    let onYes = function (args) {
                                        self.$events.$emit('undo-checks', unPrintedChecksIDs);
                                        resolve(true);
                                    }
                                    let onNo = function (args) {
                                        if(self.checkPreviewList.length === unPrintedChecksIDs.length) {
                                            resolve(false);
                                            return;
                                        }

                                        //remove printed checks and resend
                                        self.checkPreviewList = _.filter(_.clone(self.checkPreviewList), i => _.includes(unPrintedChecksIDs, i.checksID));

                                        self.$store.dispatch(DOCUMENT_ACTIONS.ADD_CHECKS_TO_QUEUE, {
                                            ordersID: self.item.ordersID,
                                            checksList: self.checkPreviewList,
                                            checkDocumentID: self.printData.checkDocumentTemplateID,
                                            noSignaturePrint: self.showCheckWritingPINFeature ? _.parseBool(self.item.noSignaturePrint) : null
                                        })
                                        .then(() => {
                                            self.launchDocumentViewer();
                                            return true;
                                        });
                                    }
                                    let confirmMessage = `You have ${unPrintedChecksIDs.length} Unprinted Check${unPrintedChecksIDs.length > 1 ? "s" : ""}. Leaving without printing will Undo ${unPrintedChecksIDs.length > 1 ? "these" : "this"} Check${unPrintedChecksIDs.length > 1 ? "s" : ""}, do you want to continue?`;
                                    self.$dialog.confirm("Confirm Undo", confirmMessage, onYes, onNo, { cancelTitle: 'No', okTitle: 'Yes'});
                                    if(self.checkPreviewList.length === unPrintedChecksIDs.length) return;
                                    resolve(true);
                                });
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            self.$toast.error({ message: error.errorMessage });
                        });
                };
                self.$dialog.open({
                    title: "Preview Checks",
                    height: "95%",
                    width: "95%",
                    resizable: false,
                    component: DocumentViewer,
                    onOk: onOk,
                    okOnly: true,
                    okTitle: "Close"
                });
            },

            validate(){
                const self = this;
                self.errorMessage = "";
                self.v$.$touch();
                if (self.v$.item.initialCheckNumber.required.$invalid || self.v$.item.checkDate.required.$invalid) {
                    self.errorMessage = 'Please provide all required fields.';
                    return;
                }
                if(self.v$.item.initialCheckNumber.isInRange.$invalid){
                    self.errorMessage = self.checkOutOfRangeMessage;
                }
                //warn checks
                if (!self.isConsolidatedCheck && !self.summary.fileInSync) {
                    self.warnMessage = "Warning! The CDF, HUD or Commercial Settlement Statement has been updated since the last time \"Create Receipts and Disbursements\" was created.";
                }
                let checksRemaining = self.printData.checkEnd - _.parseNumber(self.item.initialCheckNumber, 0) + self.item.checksIDs.length;
                let checksRemainingSimulated = self.printData.checkEndSimulated - _.parseNumber(self.item.initialCheckNumber, 0) + self.item.checksIDs.length;
                if (!self.item.simulatePrint && (checksRemaining > 0 && checksRemaining <= 50)) {
                    self.warnMessage = `Warning! Only ${checksRemaining} checks remaining.`;
                }
                if (self.item.simulatePrint && (checksRemainingSimulated > 0 && checksRemainingSimulated <= 50)) {
                    self.warnMessage = `Warning! Only ${checksRemainingSimulated} checks remaining.`;
                }
                if(!self.isConsolidatedCheck) {
                    //error checks
                    let balance = 0;
                    let whichBalance = '';
                    if (self.localSecurity.AnticipatedDeposits == 1) {//Full
                        balance = self.summary.anticipatedBalance;
                        whichBalance = 'Anticipated';
                    } else {
                        balance = self.summary.preDisbursementBalance;
                        whichBalance = 'Pre-Disbursement';
                    }
                    if (self.hasBankMismatch) {
                        self.errorMessage = "The target bank does not match the Escrow Account for the target file.";
                    }
                    else if (balance != 0 && !self.localSecurity.AllowChecksWhenUnbalanced) {
                        self.errorMessage = `Unable to Print Check${self.item.checksIDs.length > 1 ? 's' : ''} - ${whichBalance} Balance Must be Zero.`;
                    }
                }
                else if (self.hasBankMismatch) {
                    self.errorMessage = "The target bank does not match the Escrow Account for the target consolidated file.";
                }
                if (!self.item.isValid) {
                    self.errorMessage = self.item.validationErrors[0];
                }
                if (!self.hasBankMismatch && (checksRemaining <=0 || checksRemainingSimulated <=0)) {
                    self.errorMessage = "Not enough checks to perform print.";
                }
                if (!self.hasCheckTemplateFile && !self.item.simulatePrint && self.localSecurity.SendChecksToFileScan) {
                    self.errorMessage = "This bank does not have a Check Template File.";
                }
                if (!self.localSecurity.AllowPrintChecks) {
                    self.errorMessage = "Insufficient Privileges to Print Checks.";
                }
                self.$emit(`${self.errorMessage.length == 0 ? 'enable' : 'disable'}-ok`);
                //this message is more of a warning, so it shouldn't stop you from printing
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isNil(self.item.checkDate) && DateTime.fromISO(self.item.checkDate).diff(DateTime.fromISO(self.lastFinalizedReconDate), "days").days <= 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            validatePIN: _.debounce(async function(pin) {
                const self = this;
                if (self.item.noSignaturePrint || !self.showCheckWritingPINFeature) {
                    self.isValidPIN = true;
                    return;
                }
                if (_.size(pin) < 4) {
                    self.isValidPIN = false;
                    return;
                }
                self.isValidPIN = await self.$rqBusy.wait(self.$api.UsersApi.validatePIN(self.user.usersID, pin));
                self.$emit(`${!self.isValidPIN ? "disable" : "enable"}-ok`);
            }, 350, { leading: false, trailing: true }),
        }
    }
</script>