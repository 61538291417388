<template>
    <div :class="{ 'rq-tbx-input': true, 'rq-tbx-with-cancel': cancelEnabled }">
        <input ref="tagInput"
            type="text"
            v-autowidth="{ minWidth, comfortZone: 0 }"
            v-model="boundValue"
            @blur="onInputBlur"
            @keydown="onInputKeyDown"
            maxlength="50">

        <div v-if="cancelEnabled"
            ref="cancelAction"
            v-rq-tooltip.hover
            :title="cancelTooltip"
            class="rq-tbx-action"
            @click="cancel">
            <FontAwesomeIcon :icon="cancelIcon" />
        </div>
    </div>
</template>

<script>
    export default {
        inheritAttrs: false,
        props: {
            modelValue: { type: String, default: "" },
            minWidth: { type: Number, default: 75 },
            validRegex: { type: RegExp, default: /[\s\S]/ },
            isValid: { type: Boolean, default: true },
            isFocused: { type: Boolean, default: false },
            required: { type: Boolean, default: false },
            cancelEnabled: { type: Boolean, default: false },
            cancelTooltip: { type: String, default: null },
            cancelIcon: { type: String, default: null }
        },
        data() {
            return {
                boundValue: "",
                originalValue: "",
                valueIsValid: true
            };
        },
        computed: {
            isEmpty() { return _.isEmpty(_.trim(this.boundValue)); }
        },
        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.boundValue) return;
                    this.setValue(newValue);
                },
                immediate: true
            },
            boundValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.valueIsValid = this.validate(newValue);
                this.$emit("update:isValid", this.valueIsValid);
                this.$emit("update:modelValue", newValue);
            }
        },
        methods: {

            onInputBlur(e) {
                //RQO-6107 until cancelEnabled is implemented, i'm removing the delay, doesn't play well if you start typing an ID and then click on save, the save event fires before the delay
                // if(this.cancelEnabled) {
                //     //delay in case blur event was to click "cancel"
                //     _.delay(() => {
                //         if(!this.isFocused) return;
                //         this.commit(true);
                //     }, 200);
                // }
                // else
                this.commit(true);
            },

            onInputKeyDown(e) {
                if(e.key === "Enter") this.commit(true);
                if(e.key === "Escape") this.cancel(true);
                if(e.key === "Tab") this.$emit("tab-keydown", e);
            },

            commit(emitEvent=false) {
                if(this.cancelled) {
                    this.cancelled = false;
                    return;
                }
                this.$emit("update:isFocused", false);
                if(this.valueIsValid && !this.isEmpty) {
                    if(!emitEvent) return;
                    this.$emit("commit", { value: this.boundValue });
                    return;
                }
                this.revert();
            },

            cancel(emitEvent=false) {
                this.cancelled = true;
                this.$emit("update:isFocused", false);
                this.revert();
                if(!emitEvent) return;
                this.$emit("cancel", { value: this.boundValue });
            },

            revert() {
                this.setValue();
            },

            setValue(val=null) {
                if(!_.isNil(val)) this.originalValue = this.validate(val) ? val : "";
                this.boundValue = this.originalValue;
            },

            setFocus() {
                this.$emit("update:isFocused", true);
                this.$nextTick().then(() => {
                    this.$refs.tagInput.focus();
                });
            },

            validate(val) {
                let valIsEmpty = _.isEmpty(_.trim(val));
                let regexValid = this.validRegex.test(val);
                return (this.required && !valIsEmpty && regexValid)
                    || (!this.required && valIsEmpty)
                    || regexValid;
            }
        }
    }
</script>
