import routes from "./routes";
import SigningsViewMain from "./SigningsViewMain";

export default {
    path: "signing",
    component: SigningsViewMain,
    meta: {
        label: "Signings",
        include: true
    },
    children: routes
};