
import PropertiesMain from "./PropertiesMain";
import ManagePropertiesPage from "./views/ManageProperties";
import PropertyPage from "./views/PropertyForm";
import { PropertiesViewActions } from "@/router/actions/order-entry";
import Store from "@/store";

const noAccessMsg = "Access to orders is not authorized.  Please contact your administrator.";

// export default { name: "oe:properties", path: "properties/:propertyId?", component: PropertyPage, meta: { entity: "Property", extension: false, label: "Property", actions: [Actions.SAVE, Actions.CANCEL, Actions.PRINT, Actions.ADD_NOTE], accessKey: "OrderEntry", unauthorizedMessage: noAccessMsg } }

export default {
    meta: {
        label: "Properties",
        include: true,
        accessKey: "OrderEntry"
    },
    path: "properties",
    component: PropertiesMain,
    children: [
        {
            name: "oe:op:properties",
            path: "",
            component: ManagePropertiesPage,
            beforeEnter: (to, from, next) => {
                let orderProperties = _.get(Store, "state.properties.properties", null) || [];
                let propertyId = _.getNumber(orderProperties[0], "property.propertyID", 0);
                next((orderProperties.length !== 1 && propertyId > 0) || {
                    name: "oe:op:property",
                    params: {
                        orderId: from.params.orderId,
                        propertyId,
                        skipSave: true
                    },
                    hash: to.hash || ""
                });
            },
            meta: {
                entity: "Property",
                extension: false,
                include: false,
                label: "Property",
                logRouteAccess: true,
                actions: PropertiesViewActions.LIST,
                accessKey: "OrderEntry",
                unauthorizedMessage: noAccessMsg,
                skipSaveOnLeave: true,
                disableBackToTop: true,
                templateCategory: "op"
            }
        },
        {
            name: "oe:op:property",
            path: "property/:propertyId?",
            component: PropertyPage,
            meta: {
                entity: "Property",
                extension: false,
                label: "Property",
                logRouteAccess: true,
                actions: PropertiesViewActions.FORM,
                accessKey: "OrderEntry",
                skipSaveOnLeave: false,
                unauthorizedMessage: noAccessMsg,
                templateCategory: "op"
            }
        }
    ]
};
