<template>
    <div class="content-wrapper">
        <order-summary :hiddenActions="hiddenActions"  />
        <rq-scroll-container>
            <router-view
                :key="$route.fullPath"
                @updateHiddenActions="onUpdateHiddenActions">
            </router-view>
        </rq-scroll-container>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { OrderSummary } from "../shared/components";
    import { SETTLEMENT_TYPE } from "@settlement/models";
    import Store from "@/store";
    import { INVOICE_ACTIONS } from '@/store/actions';

    const updateRoute = (toRoute, invoices, next) => {
        if(toRoute.name ==="o-inv:invoices" && invoices.length < 2) {
            let invoiceId = invoices?.[0]?.invoiceID || 0;
            next({ name: "o-inv:invoice", params: { invoiceId }, hash: toRoute.hash || "" });
        }
        else
            next();
    };
    export default {
        name: "InvoiceMain",
        components: { OrderSummary },
        data(){
            return {
                hiddenActions: [],
                invoices: []
            }
        },
        beforeRouteEnter(to, from, next){
            Store.dispatch(INVOICE_ACTIONS.GET_INVOICES)
                .then(result => {
                    updateRoute(to, result, next);
                });
        },
        beforeRouteUpdate(to, from, next) {
            updateRoute(to, this.orderInvoices, next);
        },
        computed: {
            ...mapState({
                orderSummary: state => state.orders.orderSummary,
                orderId: state => state.orders.orderId,
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                orderInvoices: state => state.orders.invoices
            }),
        },
        created() {
            this.mapData();
            this.$nextTick(() => {
                //registering in next tick in case unregistered in previous route's beforeUnmount (passing function as argument in "off" doesn't work)
                this.$events.on("apply-template-success", this.onApplyTemplate);
            });
        },
        beforeUnmount () {
            this.$events.off("apply-template-success");
        },
        methods: {
            mapData() {
                let self = this;
                let promise = self.$api.SettlementDashboardApi.mapSettlementData(self.selectedView, self.orderSummary.defaultLoanID);
                return self.$rqBusy
                    .wait(promise)
                    .catch(error => {
                        let message = "Error mapping Settlement data";
                        self.$log.fatal(`InvoiceMain - ${message}`, error);
                        self.$toast.error(message);
                        return error;
                    });
            },
            onUpdateHiddenActions(e) {
                let actionKeys = _.get(e, "actions", []) || [];
                this.hiddenActions = actionKeys;
            },
            async onApplyTemplate(e) {
                const self = this;
                await self.$store.dispatch(INVOICE_ACTIONS.GET_INVOICES);
                if(self.$route.name === "o-inv:invoices") return;
                self.$router.push({ name: "o-inv:invoices" });
            },
        }
    }
</script>
