<template>
    <div class="assign-to-tasks">
        <rqValidationSummary :dataSource="validationErrors" />
        <div class="row p-1 m-0 h-100">
            <div class="col-12">
                <div class="form-group">
                    <label class="form-control-label" for="drp_wftask_assigned_department">Assigned Department</label>
                    <rqSelectBox 
                        id="drp_wftask_assigned_department"
                        :items="workflowDepartments" 
                        :defaultItem="{ text: 'Not Assigned' }"
                        v-model="workflowDepartmentID"
                    />
                </div>
                <div class="form-group">
                    <div class="row">
                        <label class="col col-form-label" for="drp_wftask_assigned_user">Assigned Person</label>
                        <div v-show="isMyDepartment" class="col form-group-action ms-auto">
                            <b-btn 
                                v-show="isMyDepartment" 
                                automation_id="btn-theme" 
                                variant="link" 
                                class="btn-theme" 
                                @click="onAssignToMe">Assign To Me</b-btn>
                        </div>
                    </div>
                    <rqSelectBox 
                        id="drp_wftask_assigned_user"
                        :items="workflowDepartmentUsers.data"                        
                        data-text-field="fullName"
                        data-value-field="workflowDepartmentUserID" 
                        :defaultItem="{ text: 'Not Assigned' }"
                        :disabled="!workflowDepartmentID"
                        v-model="workflowDepartmentUserID"
                    />
                </div>
                <div v-if="orderId" class="form-group">
                    <label class="form-control-label" for="drp_wftask_assigned_outsideparty">Outside Party</label>
                    <outside-party-picker 
                        automation_id="drp_wftask_assigned_outsideparty"
                        :order-id="orderId"
                        v-model="outsidePartyRolesId"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { WorkflowDepartment, WorkflowDepartmentUser } from "../models";
    import { OutsidePartyPicker } from "@/shared/components/rq";
    
    export default {
        components: { OutsidePartyPicker },
        name: "AssignWorkflowTasks",
        props: { 
            taskIDs: { type: Array, default: () => [] },
            orderId: { type: Number, default: null },
            userId: { type: Number, default: null }, 
            departmentId: { type: Number, default: null }, 
            rolesId: { type: Number, default: null }
        },
        data () {
            const self = this;
            return {            
                validationErrors: [],
                orderWorkflowTaskIDs: _.uniq(self.taskIDs),
                workflowDepartmentID: self.departmentId,
                workflowDepartmentUserID: self.userId,
                outsidePartyRolesId: self.rolesId,
                workflowDepartmentUsers: {
                    workflowDepartmentID: 0,
                    data: []
                }
            };
        },

        watch: {
            workflowDepartmentID(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.workflowDepartmentUserID = null;
                this.fetchWorkflowDepartmentUsers();
            }
        },
    
        computed: {
            ...mapState({
                currentSession: state => state.authentication.session
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            workflowDepartments() { return this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_DEPARTMENTS); },
            isMyDepartment() { return _.some(this.workflowDepartmentUsers.data, item => item.usersID === this.currentSession.userId); }
        },

        created () {
            const self = this;
            self.fetchWorkflowDepartmentUsers();
        },

        methods: {

            fetchWorkflowDepartmentUsers () {
                const self = this;
                if (!self.workflowDepartmentID || self.workflowDepartmentUsers.workflowDepartmentID === self.workflowDepartmentID) {
                    self.workflowDepartmentUsers.data = [];
                    self.workflowDepartmentUsers.workflowDepartmentID = null;
                    return Promise.resolve([]);
                }

                let apiPromise = self.$api.OrderWorkflowApi.getDepartmentUsers(self.workflowDepartmentID)
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.workflowDepartmentUsers.workflowDepartmentID = self.workflowDepartmentID;
                        self.workflowDepartmentUsers.data = _.map(result, u => new WorkflowDepartmentUser(u));
                        return result;
                    })
                    .catch(err => {return err;});
            },

            setOkButtonState () {
                if (_.gt(this.workflowDepartmentID, 0)) {
                    this.$emit("enable-ok");
                } else {
                    this.$emit("disable-ok");
                }
            },

            submitAssignments() {
                const self = this;
                let request = {
                    orderWorkflowTaskIDs: self.taskIDs,
                    workflowDepartmentID: self.workflowDepartmentID,
                    workflowDepartmentUserID: self.workflowDepartmentUserID,
                    rolesId: self.outsidePartyRolesId
                };
                let apiPromise = self.$api.OrderWorkflowApi.assignToTasks(request);
                return self.$rqBusy
                    .wait(apiPromise)
                    .then(result => true)
                    .catch(err => err);
            },

            onAssignToMe () {
                const self = this;
                let wfdUser = _.find(self.workflowDepartmentUsers.data, { usersID: self.currentSession.userId });
                
                if(!wfdUser) return;

                self.workflowDepartmentUserID = wfdUser.workflowDepartmentUserID;
            }
        }
    };
</script>