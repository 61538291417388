
import { UserSecuritySettings } from '@/shared/models/models';
import { DocumentModel, DocumentDownloadModel, DocumentSaveRequest, DocumentSaveResult, DocumentUserPermissions } from '../../documents/models';
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

class FormDataModel {
    constructor () {
        this.isDirty = false;
    }
}

export class WorkflowTask {
    constructor (options) {
        options = options || {};
        this.workflowTaskID = options.workflowTaskID || null;
        this.taskName = options.taskName || null;
        this.workflowTaskBasedOnID = options.workflowTaskBasedOnID || null;
        this.startAfterWorkflowTaskID = options.startAfterWorkflowTaskID || null;
        this.durationDays = options.durationDays || null;
        this.workflowDepartmentID = options.workflowDepartmentID || null;
        this.taskClosedSystemEventID = options.taskClosedSystemEventID || null;
        this.closeTaskUserEventID = options.closeTaskUserEventID || null;
        this.autoEmailYN = options.autoEmailYN || null;
        this.publishYN = options.publishYN || null;
        this.lastModified = options.lastModified || null;
        this.lastModifiedBy = options.lastModifiedBy || null;
        this.startLagDays = options.startLagDays || null;
        this.workflowServiceCategoryID = options.workflowServiceCategoryID || null;
        this.workflowCompanyServiceID = options.workflowCompanyServiceID || null;
        this.regionID = options.regionID || null;
        this.assignedPerson = options.assignedPerson || null;
        this.sequence = options.sequence || null;
        this.cMEnterpriseServiceID = options.cMEnterpriseServiceID || null;
        this.cMEventCodeID = options.cMEventCodeID || null;
        this.cMAuto = options.cMAuto || null;
        this.cMOrderCategoryID = options.cMOrderCategoryID || null;
        this.isInActive = options.isInActive || null;
        this.durationHours = options.durationHours || null;
        this.startLagHours = options.startLagHours || null;
        this.isDirty = false;
    }
}

export class WorkflowTaskDto {
    constructor (options) {
        options = options || {};
        this.isMe = options.isMe || null;
        this.isMyDepartment = options.isMyDepartment || null;
        this.isOutsideParty = options.isOutsideParty || null;
        this.isOverdue = options.isOverdue || null;
        this.isDueToday = options.isDueToday || null;
        this.isDueWithin7 = options.isDueWithin7 || null;
        this.isDueWithin30 = options.isDueWithin30 || null;
        this.orderWorkflowTaskID = options.orderWorkflowTaskID || null;
        this.workflowTaskID = options.workflowTaskID || null;
        this.taskName = options.taskName || null;
        this.ordersID = options.ordersID || null;
        this.fileNumber = options.fileNumber || null;
        this.vendorServiceAssigned = options.vendorServiceAssigned || null;
        this.autoEmail = options.autoEmail || null;
        this.notApplicable = options.notApplicable || null;
        this.publish = options.publish || null;
        this.dueDate = options.dueDate || null;
        this.startDate = options.startDate || null;
        this.departmentID = options.departmentID || null;
        this.department = options.department || null;
        this.usersID = options.usersID || null;
        this.user = options.user || null;
        this.outsidePartyID = options.outsidePartyID || null;
        this.outsideParty = options.outsideParty || null;
        this.regionID = options.regionID || null;
        this.region = options.region || null;
        this.branchID = options.branchID || null;
        this.branch = options.branch || null;
        this.openDate = options.openDate || null;
        this.closeDate = options.closeDate || null;
        this.titleIssueDate = options.titleIssueDate || null;
        this.commitmentEffectiveDate = options.commitmentEffectiveDate || null;
        this.commitmentIssueDate = options.commitmentIssueDate || null;
        this.loanPolicyDate = options.loanPolicyDate || null;
        this.policyDate = options.policyDate || null;
        this.closingAgent = options.closingAgent || null;
        this.escrowOfficer = options.escrowOfficer || null;
        this.escrowProcessor = options.escrowProcessor || null;
        this.salesRep = options.salesRep || null;
        this.titleOfficer = options.titleOfficer || null;
        this.titleProcessor = options.titleProcessor || null;
        this.titleUnitID = options.titleUnitID || null;
        this.typeOfOrder = options.typeOfOrder || null;
        this.underwriter = options.underwriter || null;
        this.salesPrice = options.salesPrice || null;
        this.loanAmount = options.loanAmount || null;
        this.lenderID = options.lenderID || null;
        this.lender = options.lender || null;
        this.orderCategory = options.orderCategory || null;
        this.referral = options.referral || null;
        this.kindOfOrder = options.kindOfOrder || null;
        this.sourceOfBusiness = options.sourceOfBusiness || null;
        this.service = options.service || null;
        this.address = options.address || null;
        this.city = options.city || null;
        this.state = options.state || null;
        this.county = options.county || null;
        this.notes = options.notes || null;
    }
}

export class OrderWorkflowTaskNote {
    constructor (options) {
        options = options || {};
        this.orderWorkflowTaskNoteID = _.parseNumber(options.orderWorkflowTaskNoteID, 0);
        this.orderWorkflowTaskID = _.parseNumber(options.orderWorkflowTaskID, 0);
        this.usersID = _.parseNumber(options.usersID, null);
        this.paperlessCloserAccessID = options.paperlessCloserAccessID || null;
        this.publish = _.parseBool(options.publish);
        this.notes = options.notes || null;
        this.noteDate = options.noteDate || null;
        this.author = options.author || null;
    }
    get isNew() { return this.orderWorkflowTaskNoteID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() { 
        let errorList = [];
        if (_.isEmpty(this.notes)) {
            errorList.push("Note Text is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class WorkflowDepartment {
    constructor (options) {
        options = options || {};
        this.workflowDepartmentID = options.workflowDepartmentID || null;
        this.departmentName = options.departmentName || null;
        this.description = options.description || null;
        this.inactiveYN = _.parseBool(options.inactiveYN);
        this.lastModified = options.lastModified || null;
        this.lastModifiedBy = options.lastModifiedBy || null;
        this.excludeFromDashboard = _.parseBool(options.excludeFromDashboard);
    }
}

export class WorkflowDepartmentUser {
    constructor (options) {
        options = options || {};
        this.workflowDepartmentUserID = options.workflowDepartmentUserID || null;
        this.workflowDepartmentID = options.workflowDepartmentID || null;
        this.usersID = options.usersID || null;
        this.hasSupervisorPrivilegeYN = options.hasSupervisorPrivilegeYN || null;
        this.inactive = options.inactive || null;
        this.workflowDashboardLastViewDate = options.workflowDashboardLastViewDate || null;

        this.departmentName = options.departmentName || null;
        this.login = options.login || null;
        this.fullName = options.fullName || null;
    }
}

export class OrderWorkflowTask {
    constructor (options) {
        options = options || {};

        let wfTask = options.workflowTask || {};
        this.workflowTask = new WorkflowTask(wfTask);

        this.assignedLanguageID = _.parseNumber(options.assignedLanguageID, null);
        this.assignedYN = _.parseBool(options.assignedYN);
        this.autoEmailYN = _.parseBool(options.autoEmailYN);
        this.businessCode = options.businessCode || null;
        this.cMAuto = options.cMAuto || null;
        this.cMPendingDate = options.cMPendingDate || null;
        this.closeTaskUserEventID = _.parseNumber(options.closeTaskUserEventID, null);
        this.completedByName = options.completedByName || null;
        this.completedByPaperlessCloserAccessID = _.parseNumber(options.completedByPaperlessCloserAccessID, null);
        this.completedByUsersID = _.parseNumber(options.completedByUsersID, null);
        this.completedDate = options.completedDate || null;
        this.completedDuration = options.completedDuration || null;
        this.dueDate = options.dueDate || null;
        this.duration = options.duration || null;
        this.gfNo = options.gfNo || null;
        this.hUDLineID = _.parseNumber(options.hUDLineID, null);
        this.hasDocsYN = options.hasDocsYN || null;
        this.lastModified = options.lastModified || null;
        this.lastModifiedBy = options.lastModifiedBy || null;
        this.lastPushToHUDorSSDate = options.lastPushToHUDorSSDate || null;
        this.lastSendToVendorDate = options.lastSendToVendorDate || null;
        this.listPrice = options.listPrice || null;
        this.notApplicableYN = _.parseBool(options.notApplicableYN);
        this.notes = options.notes || null;
        this.orderWorkflowTaskID = _.parseNumber(options.orderWorkflowTaskID || this.workflowTask.workflowTaskID, 0);
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.priceCharged = options.priceCharged || null;
        this.publishYN = _.parseBool(options.publishYN);
        this.pushToHudYN = _.parseBool(options.pushToHudYN);
        this.pushedToHUDYN = _.parseBool(options.pushedToHUDYN);
        this.rolesID = _.parseNumber(options.rolesID, null);
        this.sendToVendorYN = _.parseBool(options.sendToVendorYN);
        this.sentToVendor = options.sentToVendor || null;
        this.sequence = options.sequence || null;
        this.serviceDescription = options.serviceDescription || null;
        this.serviceRequestDeliveryMethodID = _.parseNumber(options.serviceRequestDeliveryMethodID, null);
        this.startAfterWorkflowTaskID = _.parseNumber(options.startAfterWorkflowTaskID, null);
        this.taskClosedSystemEventID = _.parseNumber(options.taskClosedSystemEventID, null);
        this.taskName = options.taskName || null;
        this.vendorInstruction = options.vendorInstruction || null;
        this.vendorName = options.vendorName || null;
        this.workflowDepartmentID = _.parseNumber(options.workflowDepartmentID, null);
        this.workflowDepartmentUserID = _.parseNumber(options.workflowDepartmentUserID, null);
        this.workflowExternalCompanyCompanyServiceID = _.parseNumber(options.workflowExternalCompanyCompanyServiceID, null);
        this.workflowProcessTemplateID = _.parseNumber(options.workflowProcessTemplateID, null);
        this.workflowTaskBasedOnID = _.parseNumber(options.workflowTaskBasedOnID, null);
        this.workflowTaskID = _.parseNumber(options.workflowTaskID, null);
        this.createDate = options.createDate || null;
        
        this.startDate = options.startDate || null;
        if (_.isNil(this.startDate) && !_.gt(this.workflowTaskBasedOnID, 0)) {this.startDate = DateTimeHelper.now();}
    }

    get isNew() { return this.orderWorkflowTaskID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.ordersID) || _.parseNumber(this.ordersID, -1) == -1) {
            errorList.push("Orders ID is required.");
        }
        if (_.isEmpty(this.taskName)) {
            errorList.push("Task Name is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class TaskNotification {
    constructor (options) {
        options = options || {};
        this.orderWorkflowTaskID = options.orderWorkflowTaskID || null;
        this.workflowDepartmentID = options.workflowDepartmentID || null;
        this.workflowDepartmentUserID = options.workflowDepartmentUserID || null;
        this.taskName = options.taskName || null;
        this.departmentName = options.departmentName || null;
        this.assignedUser = options.assignedUser || null;
        this.usersID = options.usersID || null;
        this.rolesID = options.rolesID || null;
        this.startDate = options.dueDate || null;
        this.dueDate = options.dueDate || null;
        this.lastModified = options.lastModified || null;
    }

    get isOverDue () {
        return Date.now() > Date.parse(this.dueDate);
    }
}

export class TaskNotificationInfo {
    constructor (options) {
        options = options || {};

        let ut = options.userTasks || [];
        this.userTasks = _.isEmpty(ut) ? [] : _.map(ut, t => new TaskNotification(t));

        let dt = options.deptTasks || [];
        this.deptTasks = _.isEmpty(dt) ? [] : _.map(dt, t => new TaskNotification(t));

        this.userTaskCount = options.userTaskCount || this.userTasks.length;
        this.deptTaskCount = options.deptTaskCount || this.deptTasks.length;
        this.userTaskOverDueCount = options.userTaskOverDueCount ?? 0;
    }

    get totalCount () {return this.userTaskCount + this.deptTaskCount + this.userTaskOverDueCount;}
    get hasTasks () {return this.totalCount > 0;}
}

export class WorkflowPermissions {
    constructor (options) {
        options = options || {};
        this.AllowAddWorkflowTask = _.parseBool(options.AllowAddWorkflowTask, false);
        this.AllowAddPredefinedTasksOnly = _.parseBool(options.AllowAddPredefinedTasksOnly, false);
        this.AllowEditWorkflowTask = _.parseBool(options.AllowEditWorkflowTask, false);
        this.AllowDeleteWorkflowTask = _.parseBool(options.AllowDeleteWorkflowTask, false);
        this.AllowRemoveColumnInOrderWorkflow = _.parseBool(options.AllowRemoveColumnInOrderWorkflow, false);
    }

    static createFromSecuritySettings (settings) {
        const userSecuritySettings = new UserSecuritySettings(settings);
        const settingsList = _.map(new WorkflowPermissions(), (v, k) => k);
        const permissions = userSecuritySettings.findValues(settingsList);
        return new WorkflowPermissions(permissions);
    }
}

export { DocumentModel, DocumentDownloadModel, DocumentSaveRequest, DocumentSaveResult, DocumentUserPermissions };
