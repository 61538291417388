<template>
    <div class="ric-manager content-wrapper">
        <rq-page-section>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-if="showCreditAdd">
                        <span v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                            <b-btn
                                automation_id="btn_add_reissue_credit"
                                :disabled="showExcessForm"
                                variant="theme"
                                class="ms-0"
                                @click="onAddReissueCredit">Add
                            </b-btn>
                        </span>
                    </li>
                    <li class="nav-item" v-else>
                        <span v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                            <b-btn
                                automation_id="btn_remove_reissue_credit"
                                :disabled="readOnly"
                                variant="theme"
                                class="ms-0"
                                @click="onRemoveReissueCredit">Remove
                            </b-btn>
                        </span>
                    </li>
                </ul>
            </template>

            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-sm-12 col-md-4 form-group">
                        <label for="drp_policy_type">Policy Type</label>
                        <dx-select-box
                            id="drp_policy_type"
                            display-expr="name"
                            value-expr="id"
                            :items="policyTypes"
                            :search-enabled="false"
                            v-model="selectedPolicyType"
                            :disabled="showExcessForm"
                            @initialized="onPolicyTypeInitialized"
                        />
                    </div>
                    <div class="col-sm-12 col-md-4 form-group pt-4">
                        <b-form-checkbox
                            automation_id="chk_breakout_ric"
                            id="chk_breakout_ric"
                            v-model="viewModel.breakoutRIConHUD"
                            :disabled="showExcessForm"
                            >Breakout Reissue Credit?
                        </b-form-checkbox>
                    </div>
                </div>

                <!-- Prior Owner Policy Info -->
                <reissue-credit-form owner-policy
                    v-model:policyNumber="viewModel.priorOwnerPolicyNumber"
                    v-model:policyAmount="viewModel.priorSalesPrice"
                    v-model:policyDate="viewModel.priorOwnerPolicyDate"
                    :policyYears="viewModel.priorYearsNumForOwnerReissueCredit"
                    v-model:priorRateID="viewModel.priorRateIDForOwnerReissueCredit"
                    v-model:cdfSection="viewModel.ownerCDFSection"
                    v-if="isOwner"
                />

                <!-- Prior Loan Policy Info -->
                <reissue-credit-form loan-policy
                    v-model:policyNumber="viewModel.priorLoanPolicyNumber"
                    v-model:policyAmount="viewModel.priorLoanAmount"
                    v-model:policyDate="viewModel.priorLoanPolicyDate"
                    :policyYears="viewModel.priorYearsNumForLoanReissueCredit"
                    v-model:priorRateID="viewModel.priorRateIDForLoanReissueCredit"
                    v-model:cdfSection="viewModel.loanCDFSection"
                    v-if="isLoan"
                />
            </fieldset>
        </rq-page-section>

        <rq-page-section title="Manage Excess" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-if="showExcessAdd">
                        <span v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                            <b-btn
                                automation_id="btn_add_excess"
                                :disabled="disableExcessAdd"
                                variant="theme"
                                @click="onAddExcessAmount">Add
                            </b-btn>
                        </span>
                    </li>
                    <li class="nav-item" v-else>
                        <span v-rq-tooltip.hover :title="readOnly ? '(Access Restricted)' : ''">
                            <b-btn
                                automation_id="btn_remove_excess"
                                :disabled="readOnly"
                                variant="theme"
                                @click="onRemoveExcessAmount">Remove
                            </b-btn>
                        </span>
                    </li>
                </ul>
            </template>
            <fieldset v-if="showExcessForm" :disabled="readOnly">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                        <label for="drp_excess_policy">Policy</label>
                        <rqSelectBox
                            id="drp_excess_policy"
                            automation_id="drp_excess_policy"
                            :items="policyExcessValues"
                            v-model="selectedExcessPolicyId"
                            @change="onExcessPolicyChange"
                        />
                    </div>

                    <div v-if="selectedExcessPolicyId !== null" class="col-sm-12 col-md-6 col-lg-4 form-group">
                        <label for="txt_excess_amount">Excess Amount</label>
                        <rqInputNumber id="txt_excess_amount"
                            automation_id="txt_excess_amount"
                            formatType="money"
                            defaultValue="0"
                            decimals="2"
                            value-event="input"
                            v-model="selectedExcessPolicy.excessAmount"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>

<script>
    import { RateOrderInfoDto } from "@settlement/models";
    import ReissueCreditForm from "./ReissueCreditForm";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { DateTime } from "luxon";

    const POLICY_TYPE = {
        NONE: 0,
        OWNER: 1,
        LOAN: 2
    }

    export default {
        name:"ReissueCreditManager",

        props: {
            rateOrderInfo: { type: Object, default: () => {} },
            premiums: { type: Array, default: () => [] },
            readOnly: { type: Boolean, default: false }
        },

        components: { ReissueCreditForm },

        data(){
            return {
                viewModel: new RateOrderInfoDto(),
                selectedPolicyType: POLICY_TYPE.NONE,
                policyExcessValues: [],
                selectedExcessPolicyId: null,
                selectedExcessPolicy: {},
                policyTypes: [],
                addExcessAmount: false
            };
        },

        computed: {
            hasPolicyType() {
                return this.viewModel.reissueLoanCreditForRateCalculator  || this.viewModel.reissueOwnerCreditForRateCalculator;
            },
            showCreditAdd() {
                return !this.hasPolicyType && this.selectedPolicyType === POLICY_TYPE.NONE;
            },
            showExcessAdd() {
                return !this.hasExcessAmount() && !this.addExcessAmount;
            },
            showExcessForm() {
                return this.hasExcessAmount() || this.addExcessAmount;
            },
            isOwner() {
                return this.selectedPolicyType === POLICY_TYPE.OWNER;
            },
            isLoan() {
                return this.selectedPolicyType === POLICY_TYPE.LOAN;
            },
            disableCreditAdd() {
                return this.showExcessForm;
            },
            disableExcessAdd() {
                return this.isOwner || this.isLoan;
            }
        },

        watch: {
            selectedPolicyType(newVal, oldVal) {
                this.setPolicyTypeFlagValues();
                this.setPolicyTypes();
            },
            "viewModel.priorSalesPrice"(newVal, oldVal) {
                if(newVal === oldVal || this.selectedPolicyType === POLICY_TYPE.NONE) return;
                this.setPolicyTypeFlagValues();
            },
            "viewModel.priorLoanAmount"(newVal, oldVal){
                if(newVal === oldVal || this.selectedPolicyType === POLICY_TYPE.NONE) return;
                this.setPolicyTypeFlagValues();
            },
            "viewModel.priorOwnerPolicyDate"(newVal, oldVal) {
                if(newVal === oldVal || this.selectedPolicyType === POLICY_TYPE.NONE) return;
                this.calculateCreditYears(newVal, "priorYearsNumForOwnerReissueCredit");
            },
            "viewModel.priorLoanPolicyDate"(newVal, oldVal){
                if(newVal === oldVal || this.selectedPolicyType === POLICY_TYPE.NONE) return;
                this.calculateCreditYears(newVal, "priorYearsNumForLoanReissueCredit");
            }
        },

        created() {
            this.init();
        },

        methods:{
            init() {
                const self = this;
                self.viewModel = new RateOrderInfoDto(self.rateOrderInfo);

                self.selectedPolicyType = self.hasPolicyType ? (self.viewModel.reissueLoanCreditForRateCalculator ? POLICY_TYPE.LOAN : POLICY_TYPE.OWNER) : POLICY_TYPE.NONE;

                self.setPolicyTypes();

                self.policyExcessValues = _.map(self.premiums, p => {
                    return { id: p.orderRateCalculationID, name: p.description, excessAmount: p.previousAmount };
                });
            },
            setPolicyTypes() {
                let types = [ { id:1, name:"Owner" }, { id:2, name:"Loan" } ];
                if(this.selectedPolicyType === POLICY_TYPE.NONE)
                    types.unshift({ id: 0, name: "None" });
                this.policyTypes = types;
            },
            setPolicyTypeFlagValues() {
                this.viewModel.reissueOwnerCreditForRateCalculator = (this.selectedPolicyType === POLICY_TYPE.OWNER && _.gt(this.viewModel.priorSalesPrice, 0));
                this.viewModel.reissueLoanCreditForRateCalculator = (this.selectedPolicyType === POLICY_TYPE.LOAN && _.gt(this.viewModel.priorLoanAmount, 0));
            },
            calculateCreditYears(dateVal, yearPropName) {
                if(yearPropName !== "priorYearsNumForOwnerReissueCredit" && yearPropName !== "priorYearsNumForLoanReissueCredit") return;
                let newDate = DateTime.fromISO(dateVal);
                if(!newDate.isValid){
                    this.viewModel[yearPropName] = 0;
                    return;
                }
                let now = DateTime.now();
                this.viewModel[yearPropName] = Math.floor(now.diff(newDate, "years")?.years);
            },
            onExcessPolicyChange(e) {
                this.selectedExcessPolicy = _.find(this.policyExcessValues, v => v.id === e.selectedValue);
            },
            onAddReissueCredit() {
                this.selectedPolicyType = POLICY_TYPE.OWNER;
            },
            onAddExcessAmount() {
                this.addExcessAmount = true;
            },
            onRemoveExcessAmount() {
                this.addExcessAmount = false;
                const self = this;
                let okHandler = function (e) {
                    self.policyExcessValues = _.map(self.policyExcessValues, i => {
                        return { id: i.id, name: i.name, excessAmount: 0 };
                    });
                };
                self.$dialog.confirm('Confirm Remove Excess', 'Are you sure you want to remove Excess Amount for all Policies?', okHandler);
            },
            onRemoveReissueCredit() {
                this.selectedPolicyType = POLICY_TYPE.NONE;

                this.viewModel.priorLoanAmount = null;
                this.viewModel.priorLoanPolicyDate = null;
                this.viewModel.priorLoanPolicyNumber = null;
                this.viewModel.priorRateIDForLoanReissueCredit = null;
                this.viewModel.priorYearsNumForLoanReissueCredit = null;
                this.viewModel.loanCDFSection = null;

                this.viewModel.priorSalesPrice = null;
                this.viewModel.priorOwnerPolicyDate = null;
                this.viewModel.priorOwnerPolicyNumber = null;
                this.viewModel.priorRateIDForOwnerReissueCredit = null;
                this.viewModel.priorYearsNumForOwnerReissueCredit = null;
                this.viewModel.ownerCDFSection = null;
            },
            onPolicyTypeInitialized(e) {
                _.delay(() => { e.component.focus(); }, 100);
            },
            hasExcessAmount() {
                return _.some(this.policyExcessValues, i => {
                    return i.excessAmount > 0;
                });
            },
            updateButtons() {
                this.showCreditAdd = !this.hasPolicyType && this.selectedPolicyType === POLICY_TYPE.NONE;
                this.showExcessAdd = !this.hasExcessAmount() && !this.addExcessAmount;
            }
        }
    };
</script>

<style lang="scss">
    //Component-specific styles found in file, "~scss/rqo/components/settlement/_settlement.scss"
</style>
