<template>
    <router-view v-slot="{ Component }">
        <component
            :is="Component"
            :view-title="viewTitle"
            @loan-selected="$emit('loan-selected', $event)">
            <template v-if="showLoanNavElements" #loan-selection>
                <ul class="nav ms-auto">
                    <li class="nav-item loan-selection">
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_loan')"
                            :element-attr="{ class: 'form-control form-control-sm' }"
                            value-expr="loanID"
                            display-expr="displayValue"
                            :items="loanOptions"
                            v-model="selectedLoanId"
                            @value-changed="onLoanDropdownChange"
                        />
                    </li>
                </ul>
            </template>
            <template v-if="showLoanNavElements" #loan-list-link>
                <b-btn
                    automation_id="btn_view_order_loans"
                    :to="loanListRoute"
                    variant="link"
                    class="btn-theme">
                    {{loanListLinkText}}
                </b-btn>
            </template>
        </component>
    </router-view>
</template>
<script>
    import { mapGetters, mapState} from "vuex";

    export default {
        name: "CommitmentView",

        props: {
            loanId: { type: Number, default: 0 }
        },

        data () {
            return {
                selectedLoanId: 0
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                loans: state => state.orders.loans || []
            }),
            loanOptions() {
                return _.map(this.loans, (item,index,arr) => ({
                    displayValue: `Loan ${index + 1} of ${arr.length}: ${this.formatMoneyValue(item.amount)}`,
                    ...item
                }));
            },
            currentLoan() { return this.loanId === 0 ? {} : _.find(this.loans, l => l.loanID === this.loanId); },
            loanInfo() {
                let loanParts = [];
                if(_.isEmpty(this.currentLoan)) return "";
                if (!_.isNil(this.currentLoan.loanOrder)) loanParts.push(`Loan #${this.currentLoan.loanOrder}`);
                if (!_.isEmpty(this.currentLoan.lender)) loanParts.push(this.currentLoan.lender);
                return _.join(loanParts, ": ");
            },
            viewTitle() {
                let titleParts = [];
                if(_.startsWith(this.$route.name, "policy:")) titleParts.push(_.get(this, "$route.meta.label", ""));
                if(_.endsWith(this.$route.name, ":loan:form")) titleParts.push(this.loanInfo);
                return _.join(titleParts, ": ");
            },
            showLoanNavElements() {
                return this.loans.length > 1
                    && _.endsWith(this.$route.name, ":loan:form")
                    && _.startsWith(this.$route.name, "tp");
            },
            loanListRoute() {
                let name = _.startsWith(this.$route.name, "tp") ? "tp:loan:list" : "policy:loan:list";
                return { name, params: { orderId: this.orderId } };
            },
            loanListLinkText() {
                let sectionName = _.startsWith(this.$route.name, "tp") ? "Title Production" : "Policy";
                return `View ${sectionName} Loans`;
            }
        },

        watch: {
            loanId: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.selectedLoanId = newValue;
                },
                immediate: true
            }
        },

        methods: {
            onLoanDropdownChange(e) {
                if(_.isNil(e.event)) return;
                this.$emit("loan-selected", { data: e.value });
            },
            formatMoneyValue(v) {
                return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } });
            }
        }
    };
</script>