
<template>
    <div class="content-wrapper settlement-fees">
        <rqdx-action-data-grid
            title="Settlement Fees"
            ref="dataGrid"
            automation_id="tbl_settlement_fees"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            export-file-name="settlement_fees"
            @rowDoubleClick="onEditItem"
            @view-edit="onEditItem"
            @delete="onDeleteItems"
            integrated-search
            @netfund="onChangeNetFundItems">
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.html.left.hover :title="readOnly ? '(Access Restricted)' : ''">
                        <b-btn automation_id="btn_add_itemization" variant="theme" v-focus @click="onAddClick" :disabled="readOnly">Add</b-btn>
                        <rq-report-button
                            text="Print Report"
                            :disabled="readOnly || noSettlementFees"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
                <ul class="nav ms-auto me-3">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import EndorsementSelection from "@settlement/components/shared/EndorsementSelection";
    import SettlementFeeForm  from "./SettlementFeeForm.vue";
    import { POCWhoIntOptions, POCNetFundOptions, EndorsementPolicy, OrderSettlementFee, SETTLEMENT_TYPE, SettlementTypeOption, SsGridActions } from '@settlement/models';
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

    export default {
        name: "SettlementFees",
        mixins: [ BaseSettlementMixin, GridInvokerMixin({ grid: "dataGrid" }) ],
        data(){
            return {
                originalData: [],
                orderSettlementFees: [],
                deletedKeys: [],
                gridConfig: {}
            };
        },

        props: {
            displayMode: {type: String, default: "route"}
        },

        computed: {
            ...mapState({
                cdfSections: state => state.orders.orderLookups.cdfSections,
                lineTypes: state => state.system.lookups.settlementFeeLineTypes,
            }),
            gridStorageKey() { return `settlement_settlementfees_${this.selectedView}_${this.isWithOutSeller}`; },
            selectionActions() {
                const self = this;
                return [
                    { name: "view-edit", text: self.readOnly ? "View" : "Edit", eventName: "view-edit", requireSelection: true },
                    { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, disabled: self.readOnly },
                    {
                        name: "Apply-Net-Fund",
                        text: "Apply Net Fund",
                        eventName: "netfund",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: e => self.readOnly,
                        children: [
                            SsGridActions.NETFUND_NONE,
                            SsGridActions.NETFUND_POSITIVE,
                            SsGridActions.NETFUND_NEGATIVE
                        ]
                    },
                ];
            },
            reportOptions() {
                const self = this;
                return new ReportOptionsDto({
                    reportPath: "System Reports\\File Specific\\Settlement Fees",
                    disabled: true,
                    immediate: true,
                    parameters: {
                        p_OrdersID: self.orderId
                    },
                    preExecuteHook: () => self.save()
                });
            },
            noSettlementFees() {
                const self = this;
                return self.orderSettlementFees.length == 0;
            },
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },

        watch:{
            selectedLoanId(newValue, oldValue){
                if(newValue === oldValue || oldValue === 0) return;
                const self = this;
                if(self.isDirty()) {
                    self.save().then(()=>{
                        self.fetchData();
                    });
                }
                else {
                    self.refresh();
                }
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeSSViewSelected(newValue);
            },
            isWithOutSeller(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeWithOutSeller(newValue);
            }
        },

        created() {
            const self = this;
            self.baseInit();
            self.initDataGrid();
            self.fetchData();
        },

        methods:{
            initDataGrid() {
                const self = this;
                const cellOverridden = (cellElement, cellInfo, overriddenField) => {
                    if(cellInfo.data[overriddenField]) cellElement.addClass("rq-overridden");
                    cellElement.append(cellInfo.text);
                };
                const totalColumns = cols => _.map(cols, column => ({ column, summaryType: "sum", valueFormat: { type:"currency", precision: 2 }, displayFormat: "{0}" }));
                self.gridConfig = {
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        { dataField: "code", width: 125, dataType: "string" },
                        { dataField: "description", dataType: "string", width: 200, cellTemplate: (el, info) => cellOverridden(el, info, "descriptionOverridden") },
                        { dataField: "payeeCompanyName", dataType: "string", width: 200, caption: "Payee", cellTemplate: (el, info) => cellOverridden(el, info, "payeeOverridden") },
                        {
                            caption: "Borrower",
                            alignment: "center",
                            columns: [
                                {
                                    caption: "Amount",
                                    dataField: "buyerAmount",
                                    dataType: "number",
                                    format:{ type: "currency", precision: 2 },
                                    cellTemplate: (el, info) => cellOverridden(el, info, "buyerAmountOverridden"),
                                    visible: false
                                },
                                {
                                    caption: "Tax",
                                    dataField: "buyerTax",
                                    dataType: "number",
                                    format:{ type: "currency", precision: 2 },
                                    visible: false
                                },
                                {
                                    caption: "At Closing",
                                    width: 90,
                                    dataField: "buyerTotal",
                                    dataType: "number",
                                    format:{ type: "currency", precision: 2 },
                                    cellTemplate: (el, info) => cellOverridden(el, info, "buyerAmountOverridden")
                                },
                                {
                                    caption: "Before Closing",
                                    width: 105,
                                    dataField: "paidForBorrowerBeforeClosing",
                                    dataType: "number",
                                    format:{ type: "currency", precision: 2 },
                                    showInColumnChooser: self.isCdf,
                                    visible: self.isCdf
                                },
                            ]
                        },
                        {
                            caption: "Seller",
                            alignment: "center",
                            visible: !self.isWithOutSeller,
                            dataField: "",
                            columns: [
                                {
                                    caption: "Amount",
                                    dataField: "sellerAmount",
                                    dataType: "number",
                                    format:{ type: "currency", precision: 2 },
                                    cellTemplate: (el, info) => cellOverridden(el, info, "sellerAmountOverridden"),
                                    visible: false
                                },
                                {
                                    caption: "Tax",
                                    dataField: "sellerTax",
                                    dataType: "number",
                                    format:{ type: "currency", precision: 2 },
                                    allowEditing: false,
                                    visible: false
                                },
                                {
                                    caption: "At Closing",
                                    width: 90,
                                    dataField: "sellerTotal",
                                    dataType: "number",
                                    showInColumnChooser: !self.isWithOutSeller,
                                    visible: !self.isWithOutSeller,
                                    format:{ type: "currency", precision: 2 },
                                    cellTemplate: (el, info) => cellOverridden(el, info, "sellerAmountOverridden")
                                },
                                {
                                    caption: "Before Closing",
                                    width: 105,
                                    dataField: "paidForSellerBeforeClosing",
                                    dataType: "number",
                                    format:{ type: "currency", precision: 2 },
                                    showInColumnChooser: self.isCdf && !self.isWithOutSeller,
                                    visible: self.isCdf && !self.isWithOutSeller
                                }
                            ]
                        },
                        DxGridUtils.dateColumn({
                            dataField: 'overrideDate',
                            caption: 'O/R Date',
                            visible: false,
                        }),
                        {
                            caption: "POC Whom",
                            dataField: "pocWhom",
                            dataType: "number",
                            showInColumnChooser: self.isHud,
                            width: 75,
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: POCWhoIntOptions.lookupItems
                            },
                            visible: self.isHud
                        },
                        {
                            caption: "POC Amount",
                            width: 90,
                            dataField: "pocAmount",
                            dataType: "number",
                            showInColumnChooser: self.isHud,
                            format:{ type: "currency", precision: 2 },
                            visible: self.isHud
                        },
                        {
                            dataField: "netFund",
                            dataType: "number",
                            width: 60,
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: POCNetFundOptions.lookupItems
                            },
                        },
                        {
                            dataField: "lineType",
                            dataType: "number",
                            width: 100,
                            showInColumnChooser: self.isHud,
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: self.lineTypes
                            },
                            visible: self.isHud,
                        },
                        {
                            dataField: "applyToTitleServices",
                            dataType: "boolean",
                            width: 65,
                            caption: "Apply to Title Services",
                            alignment: "left",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: this.isHud2010
                        },
                        {
                            caption: "CDF Section",
                            dataField: "cdfSection",
                            dataType: "number",
                            showInColumnChooser: self.isCdf,
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: self.cdfSections
                            },
                            visible: self.isCdf
                        },
                    ],
                    storageKey: self.gridStorageKey,
                    summary: {
                        totalItems: [
                            { column: "code", customizeText: () => "TOTALS" },
                            ...totalColumns([
                                "buyerAmount",
                                "buyerTax",
                                "buyerTotal",
                                "paidForBorrowerBeforeClosing",
                                "sellerAmount",
                                "sellerTax",
                                "sellerTotal",
                                "paidForSellerBeforeClosing",
                                "pocAmount"
                            ])
                        ]
                    },
                    noDataText: "No settlement fees found for the selected loan."
                };
                self.gridDataSource = {
                    key: "orderSettlementFeeID",
                    load: () => _.filter(self.orderSettlementFees, item => item.loanPolicyID === self.selectedLoanId)
                };
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.SettlementFeesApi.getByOrderId(self.orderId);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let sortedResults = _.sortBy(result, item => item.code);
                        self.originalData = _.map(sortedResults, item => new OrderSettlementFee(item));
                        self.orderSettlementFees = _.map(sortedResults, item => new OrderSettlementFee(item));
                        self.deletedKeys = [];
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Settlement Fees request failed.");
                    });
            },

            onSave(e) {
                this.save(e)
                    .then(() => {
                        if(!_.getBool(e, "userInitiated")) return;
                        this.fetchData();
                    });
            },

            onCancel(e) {
                const self = this;
                if(!self.isDirty()) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                let okHandler = () => { self.reset(); };
                self.$dialog.confirm("Cancel Changes", "Are you sure you want to cancel and undo the current settlement fee changes?", okHandler);
            },

            onAddClick(e){
                const self = this;

                self.$dialog.open({
                    title: "Add Settlement Fee(s)",
                    height: "85%",
                    width: "85%",
                    minHeight: 700,
                    minWidth: 1200,
                    scrollable: false,
                    component: EndorsementSelection,
                    props: {
                        orderId: self.orderId || 0,
                        loanId: self.selectedLoanId || 0,
                        settlmentFeesOnly: true,
                        showFilter: false
                    },
                    onOk (e) {
                        self.addItems(e.component.selectedEndorsementIds);
                        return true;
                    },
                    onComponentAction (e) {
                        if (e.originalEvent.name === "selection" && e.originalEvent.data) {
                            self.addItems([e.originalEvent.data.endorsementID]);
                            return true;
                        }
                        return false;
                    }
                });
            },

            onEditItem(e) {
                const self = this;
                self.$dialog.open({
                    title: `Edit Settlement Fee: ${e.data.code} - ${e.data.description}`,
                    width: "90%",
                    adaptive: true,
                    closeOnEsc: true,
                    component: SettlementFeeForm,
                    props: {
                        orderSettlementFee: new OrderSettlementFee(e.data),
                        settlementType: self.selectedView,
                        isWithOutSeller: self.isWithOutSeller,
                        readOnly: self.readOnly
                    },
                    onOk(e) {
                        if(!e.component.isValid()) return false;
                        let updatedItem = e.component.fee;
                        let orderItemIndex = _.findIndex(self.orderSettlementFees, { orderSettlementFeeID: updatedItem.orderSettlementFeeID });
                        self.orderSettlementFees[orderItemIndex] = updatedItem;
                        self.refresh();
                        return true;
                    }
                });
            },

            onDeleteItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let ids = _.map(e.data, "orderSettlementFeeID");
                let okHandler = () => self.deleteItems(ids);
                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete the selected settlement fee(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onChangeNetFundItems(e) {
                const self = this;
                if (!e || !e.data) return;
                let selectedItems = e.data;

                _.forEach(selectedItems, (item) => {
                    item.netFund = _.getNumber(e.action, "key", 0);
                });

                self.refresh();
            },

            addItems(addedKeys) {
                const self = this;
                self.save({ addedKeys })
                    .then(result => {
                        self.fetchData();
                    });
            },

            deleteItems(keys) {
                const self = this;
                _.forEach(keys, orderSettlementFeeID => {
                    let feeIndex = _.findIndex(this.orderSettlementFees, { orderSettlementFeeID });
                    if(feeIndex < 0) return;
                    self.orderSettlementFees.splice(feeIndex, 1);
                    self.deletedKeys.push(orderSettlementFeeID);
                });
                self.refresh();
                return true;
            },

            save({ addedKeys=[], userInitiated=false }={ added:[], userInitiated:false }) {
                const self = this;

                let updated = [];
                let deletedKeys = self.deletedKeys.slice();
                let feeData = _.map(self.orderSettlementFees, fee => fee.toDataObject());
                let originlDataItems = _.map(self.originalData, item => item.toDataObject());

                _.each(feeData, fee => {
                    let originalItem = _.find(originlDataItems, oi => oi.orderSettlementFeeID === fee.orderSettlementFeeID);
                    if(!_.isNil(originalItem) && _.isEqual(originalItem, fee)) return;
                    updated.push(fee);
                });

                if(_.isEmpty(addedKeys) && _.isEmpty(deletedKeys) &&_.isEmpty(updated)) {
                    if(userInitiated) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve({ isValid: true });
                }

                let saveRequest = {
                    orderId: self.orderId,
                    loanId: self.selectedLoanId,
                    addedKeys,
                    updated,
                    deletedKeys
                };
                let savePromise = self.$api.SettlementFeesApi.save(saveRequest);
                return self.$rqBusy.wait(savePromise)
                    .then(() => {
                        self.$toast.success("Settlement Fee(s) updated successfully.");
                        GlobalEventManager.saveCompleted({success: true});
                        return { isValid: true };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Settlement Fee update failed.");
                        GlobalEventManager.saveCompleted({success: false});
                    });
            },

            refresh() {
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("refresh");
            },

            reset() {
                this.orderSettlementFees = _.map(this.originalData, item => new OrderSettlementFee(item));
                this.deletedKeys = []
                this.refresh();
            },

            isDirty() {
                const self = this;
                let activeDataItems = _.map(self.orderSettlementFees, item => item.toDataObject());
                let originlDataItems = _.map(self.originalData, item => item.toDataObject());
                let noChanges = _.every(activeDataItems, item => {
                    let originalItem = _.find(originlDataItems, si => si.orderSettlementFeeID === item.orderSettlementFeeID);
                    return !_.isNil(originalItem) && _.isEqual(originalItem, item);
                });
                return !noChanges || activeDataItems.length !== originlDataItems.length || !_.isEmpty(self.deletedKeys);
            },

            onChangeWithOutSeller(e) {
                const self = this;
                self.initDataGrid();
                self.$nextTick().then(() => {
                    _.invoke(self, "$refs.dataGrid.loadGrid");
                });
            },
            onChangeSSViewSelected(e) {
                // Following will set the proper columns per settlement view context
                let allowCdfOptions = _.parseBool(e === SETTLEMENT_TYPE.CDF);
                let allowHudOptions = !allowCdfOptions;

                // CDF fields
                // this.gridInstance.columnOption("cdfSection",  { visible: allowCdfOptions, showInColumnChooser: allowCdfOptions });
                // this.gridInstance.columnOption("netFund",  { visible: allowCdfOptions, showInColumnChooser: allowCdfOptions });

                // HUD fields
                // this.gridInstance.columnOption("pocWhom",  { visible: allowHudOptions, showInColumnChooser: allowHudOptions });
                // this.gridInstance.columnOption("pocAmount",  { visible: allowHudOptions, showInColumnChooser: allowHudOptions });
                // this.gridInstance.columnOption("lineType",  { visible: allowHudOptions, showInColumnChooser: allowHudOptions });
                // this.gridInstance.columnOption("applyToTitleServices",  { visible: allowHudOptions, showInColumnChooser: allowHudOptions });

                this.initDataGrid();
                this.$nextTick().then(() => {
                    _.invoke(this, "$refs.dataGrid.loadGrid");
                });
            }
        }
    };
</script>
