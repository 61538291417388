<template>
    <div class="content-wrapper add-edit-task">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
            dismissable
        />
        <div class="row">
            <div v-show="!showDocuments"
                :class="{
                    'col col-12': true,
                    'd-flex flex-column': !showDocuments,
                    'col-lg-8 pe-0': editMode,
                    'col-lg-12': !editMode
                }">
                <rq-scroll-container hide-top-button>
                    <rq-page-section :title="editMode ? 'Task Details' : null" :borderless="!editMode">
                        <template #header-actions>
                            <ul v-if="!editMode" class="nav">
                                <li class="nav-item">
                                    <span class="lead text-muted">Select a predefined task AND/OR edit the task settings below.</span>
                                </li>
                            </ul>
                            <ul class="nav ms-auto">
                                <li class="nav-item ms-3">
                                    <span v-rq-tooltip.hover.top="{  title: allowMarkTaskNA ? '' : 'Access Restricted', container: `#${$parent.dialogId}`, boundary: `#${$parent.dialogId}` }">
                                        <b-form-checkbox
                                            automation_id="chk_na_events"
                                            :disabled="readOnly || !allowMarkTaskNA"
                                            v-model="task.notApplicableYN">N/A
                                        </b-form-checkbox>
                                    </span>
                                </li>
                                <li class="nav-item ms-3">
                                    <b-form-checkbox
                                        automation_id="chk_pc_email_events"
                                        :disabled="readOnly"
                                        v-model="task.autoEmailYN">Auto Email
                                    </b-form-checkbox>
                                </li>
                                <!-- Not applicable currently - Post MVP
                                    <li class="nav-item ms-3">
                                        <b-form-checkbox
                                            automation_id="chk_pc_email_events"
                                            :disabled="readOnly"
                                            v-model="task.publishYN">Publish Task
                                        </b-form-checkbox>
                                    </li>
                                -->
                            </ul>
                        </template>
                        <div v-if="!editMode" class="form-group">
                            <label for="drp_wfpredefinedtask_name">Predefined Task</label>
                            <dx-select-box
                                :input-attr="$utils.idAttrs('drp_wfpredefinedtask')"
                                value-expr="id"
                                display-expr="name"
                                :items="workflowPredefinedTasks"
                                :show-clear-button="true"
                                v-model="workflowPredefinedTaskID"
                                @value-changed="onWorkflowPredefinedTaskChange"
                                v-focus
                            />
                        </div>
                        <div class="row">
                            <!--Task Name-->
                            <div :class="{ 'col-12 form-group form-required':true, 'has-error':v$.task.taskName.$error }">
                                <label for="txt_wftask_name">Task Name</label>
                                <input
                                    automation_id="txt_wftask_name"
                                    class="form-control"
                                    type="text"
                                    maxlength="50"
                                    :disabled="localSecurity.AllowAddPredefinedTasksOnly || basedOnTemplateValues || readOnly"
                                    v-model="task.taskName"
                                />
                                <rq-validation-feedback>Task Name is required</rq-validation-feedback>
                            </div>
                        </div>
                        <div class="row">
                            <!--Assigned Department-->
                            <div :class="{ 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group form-required':true, 'has-error':v$.task.workflowDepartmentID.$error }">
                                <label for="drp_wftask_assigned_department">Assigned Department</label>
                                <dx-select-box
                                    :input-attr="$utils.idAttrs('drp_wftask_assigned_department')"
                                    value-expr="id"
                                    display-expr="name"
                                    placeholder="Not Assigned"
                                    :items="workflowDepartments"
                                    :disabled="readOnly"
                                    v-model.number="task.workflowDepartmentID"
                                    @value-changed="onWorkflowDepartmentChange"
                                />
                                <rq-validation-feedback>Assigned Department is required</rq-validation-feedback>
                            </div>
                            <!--Assigned Person-->
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <rq-action-form-group
                                    label="Assigned Person"
                                    label-for="drp_wftask_assigned_user"
                                    action-automation-id="btn_assigntome"
                                    action-label="Assign To Me"
                                    @action-click="onAssignToMe"
                                    :show-action="isMyDepartment">
                                    <dx-select-box
                                        :input-attr="$utils.idAttrs('drp_wftask_assigned_user')"
                                        :items="workflowDepartmentUsers"
                                        value-expr="workflowDepartmentUserID"
                                        display-expr="fullName"
                                        placeholder="Not Assigned"
                                        :disabled="assignedUserDisabled || readOnly"
                                        v-model.number="task.workflowDepartmentUserID"
                                        @value-changed="onWorkflowDepartmentUserChange"
                                    />
                                </rq-action-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <rq-action-form-group
                                label="Start Date"
                                label-for="dtp_wftask_start_date"
                                action-automation-id="btn_wftask_start_date_today"
                                :action-label="startDateActionLabel"
                                class="col col-12 col-lg-6"
                                @action-click="setToToday('calcStartDate')"
                                show-action
                                >
                                <rqdx-date-box
                                    id="dtp_wftask_start_date"
                                    type="datetime"
                                    v-model="calcStartDate"
                                    :disabled="readOnly || startDateDisabled"
                                />
                            </rq-action-form-group>

                            <rq-action-form-group
                                label="Due Date"
                                label-for="dtp_wftask_due_date"
                                action-automation-id="btn_wftask_due_date_today"
                                :action-label="dueDateActionLabel"
                                class="col col-12 col-lg-6"
                                @action-click="setToToday('calcDueDate')"
                                show-action>
                                <rqdx-date-box
                                    id="dtp_wftask_due_date"
                                    type="datetime"
                                    :disabled="readOnly"
                                    v-model="calcDueDate"
                                />
                            </rq-action-form-group>

                            <rq-action-form-group
                                v-if="editMode"
                                label="Completed Date"
                                label-for="dtp_wftask_completed_date"
                                action-automation-id="btn_wftask_completed_date_today"
                                :action-label="completedDateActionLabel"
                                class="col col-12 col-lg-6"
                                @action-click="setToToday('calcCompletedDate')"
                                show-action>
                                <rqdx-date-box
                                    id="dtp_wftask_completed_date"
                                    type="datetime"
                                    :disabled="readOnly"
                                    v-model="calcCompletedDate"
                                />
                            </rq-action-form-group>

                            <div v-if="editMode" class="col col-12 col-lg-6 form-group">
                                <label for="dtp_wftask_create_date">Create Date</label>
                                <rqdx-date-box
                                    id="dtp_wftask_create_date"
                                    type="datetime"
                                    :show-drop-down-button="false"
                                    v-model="task.createDate"
                                    disabled
                                />
                            </div>

                            <div class="col col-12 col-lg-6 form-group">
                                <label for="drp_wftask_outsideparty">Outside Party</label>
                                <outside-party-picker
                                    automation_id="drp_wftask_outsideparty"
                                    :order-id="orderId"
                                    v-model="task.rolesID"
                                    :disabled="readOnly"
                                />
                            </div>
                        </div>
                    </rq-page-section>
                    <task-notes ref="taskNotesComponent" v-if="taskExists"
                        :task-id="task.orderWorkflowTaskID"
                        :task-name="task.taskName"
                        :readOnly="readOnly"
                        @change="onTaskNotesChange"
                    />
                </rq-scroll-container>
            </div>
            <div v-if="showDocuments && editMode && !readOnly" class="col col-12 col-lg-8 pe-0">
                <file-scan-document-list
                    ref="fileScanDocumentsComponent"
                    title="Available Documents"
                    :items="availableDocuments"
                    @assign-documents="onAssignDocuments"
                    keep-alive
                />
            </div>
            <div v-if="editMode" class="col col-12 col-lg-4 ps-0 col-task-docs">
                <task-documents ref="taskDocumentsComponent"
                    :allow-edit="!readOnly"
                    :orderId="orderId"
                    :orderWorkflowTaskID="task.orderWorkflowTaskID"
                    :items="documents"
                    :task-name="task.taskName"
                    @show-documents="onShowDocuments"
                    @add-document="onAddDocument"
                    @remove-documents="onRemoveDocuments"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { FileScanDocumentShortDto } from "../../../document-mgmt/models";
    import { OrderWorkflowTask, WorkflowDepartmentUser, WorkflowPermissions } from "../models";
    import { OutsidePartyPicker } from "@/shared/components/rq";
    import FileScanDocumentList from "@documents/components/selection/FileScanDocumentList";
    import TaskDocuments from "./TaskDocuments";
    import TaskNotes from "./TaskNotes";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { dateTimeCalculated } from "@/shared/utilities/VueHelpers";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {

        name: "AddEditTask",

        components: { FileScanDocumentList, TaskDocuments, TaskNotes, OutsidePartyPicker },

        props: {
            orderWorkflowTask: { type: Object, default: () => ({}) },
            isOrderLocked: { type: Boolean, default: false }
        },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            var self = this;
            return {
                orderId: 0,
                orderWorkflowTaskID: 0,
                originalData: new OrderWorkflowTask(),
                workflowPredefinedTaskID : null,
                task: {},
                predefinedTaskCache: [],
                workflowDepartments: [],
                workflowDepartmentUsers: [],
                workflowPredefinedTasks: [],
                validationErrors: [],
                validationMessage: "",
                documents: [],
                showDocuments: false,
                // databound: false,
                restrictToReadOnly: false,
                promptConfigOverride: false,
                allowMarkTaskNA: true,
                validated: false,
                showBanner: false,
                taskNotesChanged: false
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                order: state => state.orders.order,
                storedOrderId: state => state.orders.order.ordersID,
                userId: state => state.authentication.session.userId
            }),
            regionId() {
                return _.getNumber(this, "orderWorkflowTask.regionID", null)
                    || _.getNumber(this, "task.regionID", null)
                    || _.getNumber(this, "order.regionID", null)
                    || 0;
            },
            routeOrderId() { return _.getNumber(this, "$route.params.orderId", 0); },
            contextOrderId() { return this.routeOrderId || this.storedOrderId; },
            editMode () {return _.gt(this.orderWorkflowTaskID, 0);},
            isMyDepartment() {
                return _.some(this.workflowDepartmentUsers.data, item => item.usersID === this.userId);
            },
            readOnly () {return this.isOrderLocked || (!this.isNewTask && !this.localSecurity.AllowEditWorkflowTask) || this.localSecurity.UpdateWorkflow_ScreenAccess !== 2; },
            startDateDisabled () { return !this.localSecurity.EditTaskStartDate; },
            startDateActionLabel () { return this.startDateDisabled || this.readOnly ? '' : "Set date to today"; },
            dueDateActionLabel () { return this.readOnly ? '' : "Set date to today"; },
            completedDateActionLabel () { return this.readOnly ? '' : "Set date to today"; },
            hasErrors () {return !_.isEmpty(this.validationErrors);},
            taskExists() { return _.get(this, "task.orderWorkflowTaskID", 0) > 0; },
            workflowTaskId() { return _.getNumber(this, "task.workflowTaskID", 0); },
            workflowTaskBasedOnId() { return _.getNumber(this, "task.workflowTaskBasedOnID", 0); },
            basedOnTemplateValues () { return this.editMode && _.gt(this.workflowTaskId, 0) && _.gt(this.workflowTaskBasedOnId, 0); },
            assignedUserDisabled() { return _.parseNumber(this.task.workflowDepartmentID, 0) === 0; },
            availableDocuments() { return _.filter(this.documents, d => d.orderWorkflowTaskID === null && !_.some(this.taskDocuments, td => td.fileScanDocumentID === d.fileScanDocumentID)); },
            taskDocuments() { return _.filter(this.documents, ['orderWorkflowTaskID', this.orderWorkflowTaskID]); },
            localSecurity(){
                return this.securitySettings.findValues(["AllowAddPredefinedTasksOnly", "AllowEditWorkflowTask", "AllowMaskTaskNA", "UpdateWorkflow_ScreenAccess", "EditTaskStartDate", "AllowAddWorkflowTask"]);
            },
            isNewTask(){ return _.getNumber(this, "orderWorkflowTaskID", 0) === 0; },
            ...dateTimeCalculated({ field: { calcStartDate: 'task.startDate' }}),
            ...dateTimeCalculated({ field: { calcDueDate: 'task.dueDate' }}),
            ...dateTimeCalculated({ field: { calcCompletedDate: 'task.completedDate' }}),
        },

        watch: {
            orderWorkflowTask: {
                handler(newVal, oldVal) {
                    if(_.isEqual(newVal, oldVal)) return;
                    this.orderId = _.parseNumber(newVal.ordersID, 0) || this.contextOrderId;
                    this.orderWorkflowTaskID = _.parseNumber(newVal.orderWorkflowTaskID, 0);
                    this.originalData = new OrderWorkflowTask(newVal);
                    this.fetchData();
                },
                immediate: true
            },

            workflowPredefinedTaskID: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || this.editMode || !this.localSecurity.AllowAddPredefinedTasksOnly) return;
                    if(_.parseNumber(newValue, 0) > 0)
                        this.$emit("enable-ok");
                    else
                        this.disableOkButton("Only authorized to create predefined tasks");
                },
                immediate: true
            },

            "task.startDate" (newValue, oldValue) {
                if (newValue !== oldValue && !this.datesAreEqual(newValue, this.originalData.startDate) && this.basedOnTemplateValues) {
                    this.promptConfigOverride = true;
                }
            },

            "task.dueDate" (newValue, oldValue) {
                if (newValue !== oldValue && !this.datesAreEqual(newValue, this.originalData.dueDate) && this.basedOnTemplateValues) {
                    this.promptConfigOverride = true;
                }
            }
        },

        validations() {
            return {
                task:{
                    taskName: { required },
                    workflowDepartmentID: { required }
                }
            };
        },

        created() {
            this.applySecuritySettings();
        },

        methods: {

            fetchData () {
                const self = this;

                self.workflowDepartments = self.lookupHelpers.getLookupItems(self.lookupItems.WORKFLOW_DEPARTMENTS,null, self.task.workflowDepartmentID);
                self.workflowPredefinedTasks = self.lookupHelpers.getLookupItems(self.lookupItems.WORKFLOW_PREDEFINED_TASKS, self.regionId);
                if (self.orderWorkflowTaskID === 0) {
                    self.task = new OrderWorkflowTask(self.orderWorkflowTask);
                    self.originalData = new OrderWorkflowTask(self.orderWorkflowTask);
                    self.task.ordersID = self.orderId;
                    // self.task.isDirty = true;
                    return;
                }

                let apiPromise = self.$api.OrderWorkflowApi.getOrderWorkflowTaskData(self.orderWorkflowTaskID);
                const requestError = err => self.handleError(err, "An issue occurred while requesting data to view/edit the selected order workflow task.");
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        if (!result) {
                            requestError("Invalid task result.");
                            return;
                        }
                        let wfDeptId = _.getNumber(result, "task.workflowDepartmentID", 0);
                        let wfDeptUsers = _.get(result, "users", null) || [];
                        if(wfDeptId > 0 && !_.isEmpty(wfDeptUsers)) {
                            let wfDept = _.find(self.workflowDepartments, { id: wfDeptId });
                            if(wfDept)
                            {
                                wfDept.users = wfDeptUsers;
                            }
                            self.workflowDepartmentUsers = _.map(wfDeptUsers, u => new WorkflowDepartmentUser(u));
                        }
                        self.originalData = new OrderWorkflowTask(result.task);
                        if(_.isNil(result.task.startDate))  // If Startdate is null, keep it null.
                            self.originalData.startDate = null;
                        self.task = new OrderWorkflowTask(result.task);
                        if(_.isNil(result.task.startDate))   // If Startdate is null, keep it null.
                            self.task.startDate = null;
                        self.documents = _.map(result.documents, i => new FileScanDocumentShortDto(i));
                    })
                    .catch(requestError);
            },

            applySecuritySettings () {
                const self = this;

                self.allowMarkTaskNA = self.localSecurity.AllowMaskTaskNA;

                if (!self.editMode || self.localSecurity.AllowEditWorkflowTask) return;

                self.disableOkButton("Not authorized to edit tasks");
            },

            assignDocuments (fileScanDocumentIDs) {
                const self = this;
                self.$api.OrderWorkflowApi.assignDocuments(self.orderWorkflowTaskID, fileScanDocumentIDs)
                    .then(result => {
                        self.documents = _.map(result, i => new FileScanDocumentShortDto(i));
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error assigning Document${fileScanDocumentIDs.length == 1 ? '' : 's'}.` });
                    });
            },

            fetchWorkflowPredefinedTask(){
                const self = this;
                let cachedTask = _.find(self.predefinedTaskCache, t => t.workflowTaskID === self.workflowPredefinedTaskID);
                let apiPromise = _.isNil(cachedTask)
                    ? self.$api.OrderWorkflowApi.getOrderWorkflowPredefinedTask(self.orderId, self.workflowPredefinedTaskID)
                    : Promise.resolve(cachedTask);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        if(!_.some(self.predefinedTaskCache, t => t.workflowTaskID === result.workflowTaskID)) self.predefinedTaskCache.push(result);
                        self.task = new OrderWorkflowTask(result);
                        if(_.isNil(self.task.workflowTaskBasedOnID)) self.task.startDate = null;
                        return result;
                    })
                    .then(result => self.fetchWorkflowDepartmentUsers(result.workflowDepartmentID))
                    .catch(err => err);
            },

            fetchWorkflowDepartmentUsers (workflowDepartmentID=null) {
                const self = this;
                let id = _.isNil(workflowDepartmentID) ? self.task.workflowDepartmentID : workflowDepartmentID;

                if (_.isNil(id)) {
                    self.workflowDepartmentUsers = [];
                    return Promise.resolve([]);
                }

                let dept = _.find(self.workflowDepartments, { id });

                if(!_.isNil(dept) && !_.isEmpty(dept.users)) {
                    self.workflowDepartmentUsers = _.map(dept.users, u => new WorkflowDepartmentUser(u));
                    return Promise.resolve(self.workflowDepartmentUsers);
                }

                let apiPromise = self.$api.OrderWorkflowApi.getDepartmentUsers(id);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        dept.users = result;
                        self.workflowDepartmentUsers = _.map(result, u => new WorkflowDepartmentUser(u));
                        return result;
                    })
                    .catch(err => err);
            },

            onAddDocument (document) {
                if (document) {
                    this.documents.push(document);
                    let fileScanList = _.get(this, "$refs.fileScanDocumentsComponent", {});
                    if (!_.isEmpty(fileScanList)) fileScanList.refresh();
                    let taskDocs = _.get(this, "$refs.taskDocumentsComponent", {});
                    if (!_.isEmpty(taskDocs)) taskDocs.refresh();
                    // let newDoc = [];  //Move code to call asssignment of docs to workflow task to TaskDocuments.vue.  This is to eliminate Async issues between two forms.
                    // newDoc.push(document);
                    // this.onAssignDocuments(newDoc);   
                }
            },

            onAssignDocuments (documents) {
                let existingTaskDocumentIDs = _.map(this.taskDocuments, 'fileScanDocumentID');
                let newTaskDocumentIDs = _.map(documents, 'fileScanDocumentID');
                let fileScanDocumentIDs = _.uniq(_.concat(existingTaskDocumentIDs, newTaskDocumentIDs));
                this.assignDocuments(fileScanDocumentIDs);
            },

            onAssignToMe () {
                const self = this;
                let wfdUser = _.find(self.workflowDepartmentUsers, { usersID: self.userId });

                if(!wfdUser) return;

                self.task.workflowDepartmentUserID = wfdUser.workflowDepartmentUserID;
            },

            onWorkflowPredefinedTaskChange(e){
                if(_.isNil(e.event)) return;
                if(_.isNil(e.value)) {
                    this.task.workflowTaskID = null;
                    this.task.taskName = "";
                    return;
                }
                this.fetchWorkflowPredefinedTask();
            },

            onWorkflowDepartmentChange (e) {
                if(_.isNil(e.event)) return;
                this.task.workflowDepartmentUserID = null;
                this.task.assignedYN = false;
                this.fetchWorkflowDepartmentUsers();
            },

            onWorkflowDepartmentUserChange (e) {
                if(_.isNil(e.event)) return;
                this.task.assignedYN = this.task.workflowDepartmentUserID !== null;
            },

            onTaskNotesChange(e) {
                if(!this.editMode) return;
                this.taskNotesChanged = true;
            },

            onShowDocuments () {
                this.showDocuments = !this.showDocuments;
                if (this.showDocuments) {
                    this.$emit("update-dialog-options", { okTitle: "Back", okOnly: true });
                } else {
                    this.$emit("revert-dialog-options");
                }
            },

            onRemoveDocuments (documents) {
                let existingTaskDocumentIDs = _.map(this.taskDocuments, 'fileScanDocumentID');
                let removeTaskDocumentIDs = _.map(documents, 'fileScanDocumentID');
                let fileScanDocumentIDs = _.pullAll(existingTaskDocumentIDs, removeTaskDocumentIDs);
                this.assignDocuments(fileScanDocumentIDs);
            },

            setToToday(field) {
                if(!_.hasIn(this, field)) return;
                 if (field === 'calcStartDate' && this.startDateDisabled) return false;
                let nowValue = DateTimeHelper.now();
                this[field] = nowValue;
            },

            updateDimensions () {
                if (!this.editMode) return;
                _.invoke(this, "$refs.taskNotesComponent.updateDimensions");
                _.invoke(this, "$refs.taskDocumentsComponent.updateDimensions");
            },

            save () {
                const self = this;
                if (self.readOnly) return;                
                self.v$.$touch();
                self.showBanner = true;
                if (self.showDocuments) {
                    self.showDocuments = false;
                    self.$emit("revert-dialog-options");
                    return Promise.resolve(false);
                }

                if (self.v$.$error) return Promise.resolve(false);
                let changes = self.getAuditChanges(self.originalData.toDataObject(), self.task.toDataObject());
                if (changes.length == 0) {
                    return Promise.resolve(true);
                }

                if (self.task.ordersID <= 0) {
                    self.$toast.error({ message: "No OrdersID found to associate with this task.  Task cannot be saved." });
                    return Promise.resolve(true);
                }

                if (self.promptConfigOverride) {
                    let okHandler = e => {
                        self.task.workflowTaskBasedOnID = 0;
                        self.promptConfigOverride = false;
                        self.$emit("ok");
                        return true;
                    };
                    let cancelHandler = e => {
                        if (self.originalData) {
                            self.task.startDate = self.originalData.startDate;
                            self.task.dueDate = self.originalData.dueDate;
                            self.promptConfigOverride = false;
                        }
                        return true;
                    };
                    self.$dialog.confirm("Override Configuration", "Start Date and Due Date should be according to the Workflow Task configuration.  Do you wish to override?", okHandler, cancelHandler);
                    return Promise.resolve(false);
                }
                let apiPromise = self.$api.OrderWorkflowApi.saveTask(self.task);
                return self.$rqBusy.wait(apiPromise).then(() => true);
            },

            canCancel () {
                let allowCancel = !this.showDocuments;
                this.showDocuments = !this.showDocuments;
                return allowCancel;
            },

            disableOkButton (tooltipText) {
                this.$emit("disable-ok", { okTooltip: tooltipText });
            },

            datesAreEqual (date1, date2) {
                if(_.isEmpty(date1) && !_.isEmpty(date2)) return true;
                else if(!_.isEmpty(date1) || !_.isEmpty(date2)) return false;
                return DateTimeHelper.getDateFromString(date1).toFormat("yyyy-MM-dd'T'HH:mm:ss") === DateTimeHelper.getDateFromString(date2).toFormat("yyyy-MM-dd'T'HH:mm:ss");
            },

            handleError(ex, msg) {
                this.$toast.error(msg);
                console.error(ex);
                return ex;
            }
        }
    };
</script>
