<template>
    <div class="premiums-header">
        <div class="card list-items-card">
            <div class="card-body">
                <table class="table table-left table-sm table-borderless">
                    <tr>
                        <th>Sales Price:</th>
                        <td>{{formatMoney(salesPrice)}}</td>
                    </tr>
                    <tr>
                        <th>Owner Liability Override:</th>
                        <td>{{formatMoney(ownerPolicyLiabilityOverride)}}</td>
                    </tr>
                    <tr>
                        <th>Loan Amount:</th>
                        <td>{{formatMoney(loanAmount)}}</td>
                    </tr>
                    <tr>
                        <th>Loan Liability Override:</th>
                        <td>{{formatMoney(loanPolicyLiabilityOverride)}}</td>
                    </tr>
                </table>
                <table class="table table-right table-sm table-borderless">
                    <tr>
                        <th>Service:</th>
                        <td v-html="listItemTemplate(serviceName, 'Not Selected')"></td>
                    </tr>
                    <tr>
                        <th>Open Date:</th>
                        <td v-html="listItemTemplate(openDate)"></td>
                    </tr>
                    <tr>
                        <th>Close Date:</th>
                        <td v-html="listItemTemplate(closeDate)"></td>
                    </tr>
                    <tr>
                        <th>Fund/Disb Date:</th>
                        <td v-html="listItemTemplate(fundDate)"></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card totals-card">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="main-text title">Totals</div>
                    </div>
                    <div class="col">
                        <label>Agent</label>
                        <div class="main-text">{{formatMoney(agentTotal)}}</div>
                    </div>
                    <div class="col">
                        <label>Underwriter</label>
                        <div class="main-text">{{formatMoney(underwriterTotal)}}</div>
                    </div>
                    <div class="col">
                        <label>Outside Party</label>
                        <div class="main-text">{{formatMoney(outsidePartyTotal)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    export default {
        name: "PremiumsHeader",

        props: {
            premiums: { default:() => [] },
            agentTotal: { type: Number, default: 0 },
            underwriterTotal: { type: Number, default: 0 },
            outsidePartyTotal: { type: Number, default: 0 }
        },

        data() {
            return {
                salesPrice: 0,
                ownerPolicyLiabilityOverride: 0,
                loanAmount: 0,
                loanPolicyLiabilityOverride: 0
            }
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            orderEntity() { return _.get(this, "$store.state.orders.order", {}) || {}; },
            orderServices() { return this.lookupHelpers.getOrderServices(); },
            openDate() { return this.formatDate(this.orderEntity.openDate); },
            closeDate() { return this.formatDate(this.orderEntity.closeDate); },
            fundDate() { return this.formatDate(this.orderEntity.fundDate); },
            serviceName() {
                if(_.isNil(this.orderEntity.service)) return "";
                let orderService = _.find(this.orderServices, s => { return _.parseNumber(s.id, -1) === this.orderEntity.service; });
                return _.isNil(orderService) ? "" : orderService.name;
            }
        },

        watch: {
            premiums: {
                handler() {
                    this.refreshAmountValues();
                },
                immediate: true
            }
        },

        methods: {
            refreshAmountValues() {
                this.loanAmount = 0;
                this.loanPolicyLiabilityOverride = 0;
                this.salesPrice = 0;
                this.ownerPolicyLiabilityOverride = 0;

                _.each(this.premiums, p => {
                    if(p.isLoanHolder) {
                        this.salesPrice = p.saleAmount;
                        this.ownerPolicyLiabilityOverride = p.salesOverrideAmount;
                    }
                    else
                        this.loanAmount += _.parseNumber(p.loanAmount, 0);
                    this.loanPolicyLiabilityOverride += _.parseNumber(p.loanOverrideAmount, 0);
                });
            },
            formatDate(v) { return DateTimeHelper.toFormat(v, "MM/dd/yyyy"); },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } }); },
            listItemTemplate(val, defaultVal="Not Entered") { return _.isEmpty(val) ? `<span class="no-value">${defaultVal}</span>` : val; }
        }
    }
</script>

<style lang="scss">
    //Component-specific styles found in file, "~scss/rqo/app/settlement/_premiums-header.scss"
</style>