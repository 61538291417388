<template>
    <div class="content-wrapper debit-credits">
        <rq-page-section title="Debits/Credits" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <div class="rq-container debit-credits-body">
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <!-- CDF View -->
            <template v-if="isCdf">
                <div class="row justify-content-md-center">
                    <div class="col-12 col-xl-6">
                        <debit-credit-section
                            :section-data="borrowerDebitItems"
                            :title="CdfSectionTitle.K"
                            :isCdf="isCdf"
                            :sectionTotal="totalBorrowerDebits"
                            @open-detail="onOpenDetail">
                        </debit-credit-section>
                    </div>
                    <div class="col-12 col-xl-6">
                        <debit-credit-section
                            :section-data="sellerCreditItems"
                            :title="CdfSectionTitle.M"
                            :isCdf="isCdf"
                            :sectionTotal="totalSellerCredits"
                            @open-detail="onOpenDetail">
                        </debit-credit-section>
                    </div>
                </div>
                <div class="row justify-content-md-center mt-3">
                    <div class="col-12 col-xl-6">
                        <debit-credit-section
                            :section-data="borrowerCreditItems"
                            :isCdf="isCdf"
                            :title="CdfSectionTitle.L"
                            :sectionTotal="totalBorrowerCredits"
                            @open-detail="onOpenDetail">
                        </debit-credit-section>
                    </div>
                    <div class="col-12 col-xl-6">
                        <debit-credit-section
                            :section-data="sellerDebitItems"
                            :isCdf="isCdf"
                            :title="CdfSectionTitle.N"
                            :sectionTotal="totalSellerDebits"
                            @open-detail="onOpenDetail">
                        </debit-credit-section>
                    </div>
                </div>
                <div class="row justify-content-md-center mt-3 mb-3">
                    <div class="col-12 col-xl-6">
                        <debit-credit-calculations
                            :title-options="borrowerTitleOptions"
                            :totalUpperSection="totalBorrowerDebits"
                            :totalBottomSection="totalBorrowerCredits"
                            :isCdf="isCdf"
                            :netTotal="showCashToCloseFinalAmount ? cashToCloseFinalTotalAmount : netBorrowerTotal"
                        />
                    </div>
                    <div class="col-12 col-xl-6">
                        <debit-credit-calculations
                            :title-options="sellerTitleOptions"
                            :totalUpperSection="totalSellerCredits"
                            :totalBottomSection="totalSellerDebits"
                            :isCdf="isCdf"
                            :netTotal="netSellerTotal"
                        />
                    </div>
                </div>
            </template>

            <!-- HUD View -->
            <template v-if="isHud">
                <div class="row justify-content-md-center">
                    <div class="col-12 col-xl-6">
                        <debit-credit-hud-section
                            :section-data="hudBorrowerDebitItems"
                            :title="HudSectionTitle.BorrowerDebits"
                            :sectionTotal="totalBorrowerDebits"
                            @open-detail="onOpenDetail">
                        </debit-credit-hud-section>
                    </div>
                    <div class="col-12 col-xl-6">
                        <debit-credit-hud-section
                            :section-data="hudSellerCreditItems"
                            :title="HudSectionTitle.SellerCredits"
                            :sectionTotal="totalSellerCredits"
                            @open-detail="onOpenDetail">
                        </debit-credit-hud-section>
                    </div>
                </div>
                <div class="row justify-content-md-center mt-3">
                    <div class="col-12 col-xl-6">
                        <debit-credit-hud-section
                            :section-data="hudBorrowerCreditItems"
                            :title="HudSectionTitle.BorrowerCredits"
                            :sectionTotal="totalBorrowerCredits"
                            @open-detail="onOpenDetail">
                        </debit-credit-hud-section>
                    </div>
                    <div class="col-12 col-xl-6">
                        <debit-credit-hud-section
                            :section-data="hudSellerDebitItems"
                            :title="HudSectionTitle.SellerDebits"
                            :sectionTotal="totalSellerDebits"
                            @open-detail="onOpenDetail">
                        </debit-credit-hud-section>
                    </div>
                </div>

                <!-- HUD Calculations -->
                <div class="row justify-content-md-center mt-3 mb-3">
                    <div class="col-12 col-xl-6">
                        <debit-credit-calculations
                            :title-options="borrowerTitleOptions"
                            :totalUpperSection="totalBorrowerDebits"
                            :totalBottomSection="totalBorrowerCredits"
                            :netTotal="netBorrowerTotal"
                        />
                    </div>
                    <div class="col-12 col-xl-6">
                        <debit-credit-calculations
                            :title-options="sellerTitleOptions"
                            :totalUpperSection="totalSellerCredits"
                            :totalBottomSection="totalSellerDebits"
                            :netTotal="netSellerTotal"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { DebitCreditModel, SETTLEMENT_TYPE, SettlementTypeOption } from '@settlement/models';
    import DebitCreditSection from './components/DebitCreditSection';
    import DebitCreditHudSection from './components/DebitCreditHudSection';
    import DebitCreditCalculations from './components/DebitCreditCalculations';
    import { CdfSection, CdfSectionTitle, HudSectionTitle } from './models';
    import { ORDER_ACTIONS } from "@/store/actions";
    import BaseSettlementMixin from "../../BaseSettlementMixin";


    export default {
        name: "DebitsCredits",
        mixins: [BaseSettlementMixin],
        components: {
            DebitCreditSection,
            DebitCreditHudSection,
            DebitCreditCalculations
        },
        data(){
            return {
                items: [],
                cashToCloseFinalTotalAmount: 0,
                // CDF associated items
                CdfSectionTitle: CdfSectionTitle,
                borrowerDebitItems: [],
                borrowerCreditItems: [],
                sellerDebitItems: [],
                sellerCreditItems: [],
                // HUD associated items
                HudSectionTitle: HudSectionTitle,
                hudBorrowerDebitItems: [],
                hudBorrowerCreditItems: [],
                hudSellerDebitItems: [],
                hudSellerCreditItems: [],
                originalData: [],
                activeSection: null
            };
        },

        computed:{
            ...mapState({
                loans: state => state.orders.loans || []
            }),
            showCashToCloseFinalAmount() { return this.isCdf && this.orderSummary.cashToClosePopulateFrom === 1; },
            borrowerDebitTitle() {
                let textLabel = "";

                if (this.isCdf)
                    textLabel = "Total Due from Borrower at Closing (K)";

                if (this.isHud)
                    textLabel = "Gross Amount due from borrower (line 120)";

                return textLabel;
            },
            borrowerCreditTitle() {
                let textLabel = "";

                if (this.isCdf)
                    textLabel = "Total Paid Already by or on Behalf of Borrower at Closing (L)";

                if (this.isHud)
                    textLabel = "Less amounts paid by/for borrower (line 220)";

                return textLabel;
            },
            borrowerCashToCloseTitle() {
                let textLabel = "";

                if (this.totalBorrowerDebits > this.totalBorrowerCredits)
                    textLabel = "Cash From Borrower";
                else
                    textLabel = "Cash To Borrower";

                return textLabel;
            },
            totalBorrowerDebits() {
                let total = 0;

                // Sum of CDF section K
                if (this.isCdf) {
                    total = this.calculateTotal(this.borrowerDebitItems);
                }

                // Sum of HUD section 100
                if (this.isHud) {
                    total = this.calculateTotal(this.hudBorrowerDebitItems);
                }

                return _.parseNumber(total, 0);
            },
            totalBorrowerCredits() {
                let total = 0;

                // Sum of CDF section L
                if (this.isCdf) {
                    total = this.calculateTotal(this.borrowerCreditItems);
                }

                // Sum of HUD section 200
                if (this.isHud) {
                    total = this.calculateTotal(this.hudBorrowerCreditItems);
                }

                return total;
            },
            netBorrowerTotal() {
                return _.parseNumber(this.totalBorrowerDebits, 0) - _.parseNumber(this.totalBorrowerCredits, 0);
            },
            borrowerTitleOptions() {
                let sectionTitle = this.isHud ? "300. Cash at Settlement From/To Borrower" : "Calculation";
                 return {
                     sectionTitle: sectionTitle,
                     topTitle: this.borrowerDebitTitle,
                     bottomTitle: this.borrowerCreditTitle,
                     totalTitle: this.borrowerCashToCloseTitle
                };
            },
            sellerCreditTitle() {
                let textLabel = "";

                if (this.isCdf)
                    textLabel = "Total Due To Seller at Closing (M)";

                if (this.isHud)
                    textLabel = "Gross Amount due to seller (line 420)";

                return textLabel;
            },
            sellerDebitTitle() {
                let textLabel = "";

                if (this.isCdf)
                    textLabel = "Total Due From Seller at Closing (N)";

                if (this.isHud)
                    textLabel = "Less reductions in amt. due seller (line 520)";

                return textLabel;
            },
            sellerCashToCloseTitle() {
                let textLabel = "";

                if (this.totalSellerCredits > this.totalSellerDebits)
                    textLabel = "Cash To Seller";
                else
                    textLabel = "Cash From Seller";

                return textLabel;
            },
            totalSellerDebits() {
                let total = 0;

                if (this.isCdf) {
                    total = this.calculateTotal(this.sellerDebitItems);
                }

                if (this.isHud) {
                    total = this.calculateTotal(this.hudSellerDebitItems);
                }

                return total;
            },
            totalSellerCredits() {
                let total = 0;

                if (this.isCdf) {
                     total = this.calculateTotal(this.sellerCreditItems);
                }

                if (this.isHud) {
                    total = this.calculateTotal(this.hudSellerCreditItems);
                }

                return total;
            },
            netSellerTotal() {
                return _.parseNumber(this.totalSellerCredits, 0) - _.parseNumber(this.totalSellerDebits, 0);
            },
            sellerTitleOptions() {
                let sectionTitle = this.isHud ? "600.  Cash at Settlement From/To Seller" : "Calculation";
                return {
                    sectionTitle: sectionTitle,
                    topTitle: this.sellerCreditTitle,
                    bottomTitle: this.sellerDebitTitle,
                    totalTitle: this.sellerCashToCloseTitle
                };
            },
        },

        watch:{
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                self.fetchData();
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeSSViewSelected(newValue);
            }
        },

        created() {
            const self = this;
            self.baseInit();
            self.initEventListeners();
            self.fetchData();
        },

        beforeUnmount () {
            this.removeEventListeners();
        },

        methods:{
            onChangeSSViewSelected(e) {
                let selectedViewIsHud = _.includes([SETTLEMENT_TYPE.HUD_2010, SETTLEMENT_TYPE.HUD_1974], e);
                if (selectedViewIsHud)
                    this.refreshHudItems();
            },
            clearDebitCreditLine(chargeItem) {
                const self = this;

                if (chargeItem) {
                    chargeItem.description = null;
                    chargeItem.payeeCompanyID = null;
                    chargeItem.payeeCompanyName = null;
                    chargeItem.payeeContactID = null;
                    chargeItem.payeeContactName = null;
                    chargeItem.acctCode = null;
                    chargeItem.netFund = null;
                    chargeItem.amount = null;

                    if (chargeItem.isDebitCredit === true) {

                        let debitCreditItem = self.findDebitCreditItem(chargeItem);

                        if (!_.isNil(debitCreditItem)) {
                            debitCreditItem.description = null;
                            debitCreditItem.payeeCompanyID = null;
                            debitCreditItem.payeeCompanyName = null;
                            debitCreditItem.payeeContactID = null;
                            debitCreditItem.payeeContactName = null;
                            debitCreditItem.acctCode = null;
                            debitCreditItem.netFund = null;
                            debitCreditItem.amount = null;
                            debitCreditItem.isDebitCredit = null;
                        }
                    }

                    chargeItem.isDebitCredit = null;
                }
            },
            // Updates applied on client side for related lines to the earnest money field on the debit credits page
            // because we want the screen to be responsive to those changes
            // Similar code logic that is present on server side when earnest money values are changed form File Main page
            onUpdateEarnestMoneyHUD(args) {
                const self = this;

                let amount = args.item.amount;
                let holder = self.order.earnestMoneyHeldBy;
                let deductFromCommission = _.parseBool(self.order.deductEarnestMoneyFromCommission);
                let typeFundID = self.order.typeFundID;
                let line501_hud1974 = _.find(self.items, (i) => i.defaultedSettlementType === 1 && i.hudLine === 501);
                let line501_hud2010 = _.find(self.items, (i) => i.defaultedSettlementType === 2 && i.hudLine === 501);
                let line506_hud2010 = _.find(self.items, (i) => i.defaultedSettlementType === 2 && i.hudLine === 506);
                let line507_hud2010 = _.find(self.items, (i) => i.defaultedSettlementType === 2 && i.hudLine === 507);

                switch(holder) {
                    case 1: // Seller

                        self.clearDebitCreditLine(line501_hud1974);
                        self.updateDebitCreditLine(line501_hud1974, "Deposit", amount);
                        self.clearDebitCreditLine(line501_hud2010);
                        self.updateDebitCreditLine(line501_hud2010, "Deposit", amount);

                        // RQO-22695 - For seller holder we do not touch any corresponding debit credit line (506, 507)
                        break;
                    case 2: // Seller's Real Estate Agent
                    case 3: // Buyer's Real Estate Agent
                        self.clearDebitCreditLine(line501_hud1974);
                        self.updateDebitCreditLine(line501_hud1974, "Excess Deposit", 0);
                        self.clearDebitCreditLine(line501_hud2010);
                        self.updateDebitCreditLine(line501_hud2010, "Excess Deposit", 0);
                        if (deductFromCommission) {
                            self.clearDebitCreditLine(line506_hud2010);
                            self.updateDebitCreditLine(line506_hud2010, "Earnest Money Retained By Realtor", amount);
                            self.clearDebitCreditLine(line507_hud2010);
                        }
                        else {
                            // RQO-23175 - we do not touch any corresponding 506 debit credit line
                            self.clearDebitCreditLine(line507_hud2010);
                            self.updateDebitCreditLine(line507_hud2010, `EMD ${self.formatMoney(amount)} Disbursed as Proceeds`, 0);
                        }
                        break;
                    case 4: // Lender
                            self.clearDebitCreditLine(line501_hud1974);
                            self.updateDebitCreditLine(line501_hud1974, "Excess Deposit", 0);
                            self.clearDebitCreditLine(line501_hud2010);
                            self.updateDebitCreditLine(line501_hud2010, "Excess Deposit", 0);
                            // RQO-23175 - we do not touch any corresponding 506 debit credit line
                            self.clearDebitCreditLine(line507_hud2010);
                            self.updateDebitCreditLine(line507_hud2010, `EMD ${self.formatMoney(amount)} Disbursed as Proceeds"`, 0);
                        break;
                    case 0: // Title
                        self.clearDebitCreditLine(line501_hud1974);
                        self.updateDebitCreditLine(line501_hud1974, "Excess Deposit", 0);
                        self.clearDebitCreditLine(line501_hud2010);
                        self.updateDebitCreditLine(line501_hud2010, "Excess Deposit", 0);
                        // RQO-23175 - we do not touch any corresponding 506 debit credit line
                        if (typeFundID > 0) {
                            self.clearDebitCreditLine(line507_hud2010);
                            self.updateDebitCreditLine(line507_hud2010, `EMD ${self.formatMoney(amount)} Disbursed as Proceeds`, 0);
                        }
                        else {
                            self.clearDebitCreditLine(line507_hud2010);
                        }
                        break;
                    default:
                        // RQO-22695 - If no holder then we do not touch any corresponding debit credit line
                        break;
                }
            },
            onUpdateEarnestMoneyCDF(args) {
                const self = this;

                let amount = args.item.amount;
                let holder = self.order.earnestMoneyHeldBy;
                let lineL01_cdf = _.find(self.items, (i) => i.cdfSection === CdfSection.L && i.line === 1 );
                let lineN01_cdf = _.find(self.items, (i) => i.cdfSection === CdfSection.N && i.line === 1 );
                let loan = _.find(self.loans, l => l.loanID === self.selectedLoanId);

                switch(holder) {
                    case 1: // Seller
                        self.updateDebitCreditLine(lineL01_cdf, "Deposit", amount);
                        if(loan.loanOrder === 1){
                            self.updateDebitCreditLine(lineN01_cdf, "Excess Deposit", amount);
                        }
                        break;
                    default:

                    break;
                    }

            },
            updateDebitCreditLine(debitCredit, description, amount) {
                debitCredit.amount = _.parseNumber(amount, 0);
                debitCredit.description = description || "";
            },
            onToggleDebitCredit(args) {
                const self = this;

                let chargeItem = args.item;
                let section = self.isCdf ? chargeItem.cdfSection : chargeItem.hudSection;

                // This is used so the active section doesn't get cleared from a toggle
                if (args.activeSection && self.activeSection === null)
                    self.activeSection = args.activeSection;
                if (self.activeSection !== null && self.activeSection !== section && !chargeItem.isDebitCredit) return;

                let debitCreditItem = self.isCdf ? self.findDebitCreditItem(chargeItem) : self.findHudDebitCreditItem(chargeItem);

                if (!_.isNil(debitCreditItem)) {

                    debitCreditItem.isDebitCredit = chargeItem.isDebitCredit;

                    // DebitCredit set
                    if (chargeItem.isDebitCredit === true) {
                        chargeItem.debitCreditLineID = debitCreditItem.debitCreditSectionDataID;
                        debitCreditItem.description = chargeItem.description;
                        debitCreditItem.overrideDescription = chargeItem.overrideDescription;
                        debitCreditItem.amount = chargeItem.amount;
                        debitCreditItem.overrideAmount = chargeItem.overrideAmount;
                        debitCreditItem.debitCreditLineID = chargeItem.debitCreditSectionDataID;
                    }

                    // DebitCredit unset
                    // If debit credit line is an option fee line (isAdditionalDeposit) then we need not reset data and allow it to stick for now
                    if (chargeItem.isDebitCredit !== true && !chargeItem.isAdditionalDeposit) {
                        chargeItem.debitCreditLineID = null; //clear out the debitcredit associated line
                        debitCreditItem.description = null;
                        debitCreditItem.amount = null;
                        debitCreditItem.debitCreditLineID = null;
                    }
                }
            },
            findDebitCreditItem(lineItem) {
                const self = this;
                let debitCreditItem = null;

                // UI Mapping done for CDF lines here to reduce the save and map operations on each and every cdf line and refreshing data
                // UI logic manipulates the data up front and saves the data in bulk freeing us from performing save and mapping cdf line for synching
                if (self.isCdf) {
                    if (!_.isNil(lineItem.debitCreditLineID))
                        return debitCreditItem = _.find(self.items, function(i) { return i.debitCreditSectionDataID == lineItem.debitCreditLineID && i.defaultedSettlementType === self.selectedView; });

                    if (lineItem.cdfSection === CdfSection.K)
                    {
                        let lineNumber = lineItem.line;

                        if (lineItem.line >= 5)
                            lineNumber = lineItem.line + 1; // offset by 1 for mapping

                        debitCreditItem = _.find(self.items, function(i) { return i.cdfSection === CdfSection.M && i.line == lineNumber; });
                    }

                    if (lineItem.cdfSection === CdfSection.M)
                    {
                        let lineNumber = lineItem.line;

                        if (lineItem.line == 3 || lineItem.line == 5)
                            return null;

                        if (lineItem.line >= 6)
                            lineNumber = lineItem.line - 1; // offset by 1 for mapping

                        debitCreditItem = _.find(self.items, function(i) { return i.cdfSection === CdfSection.K && i.line == lineNumber; });
                    }

                    // RQO-31655: Sections L and N: update corresponding lines to match logic on the CDF Editor from RQO-20754
                    if (lineItem.cdfSection === CdfSection.L)
                    {
                        let lineNumber = lineItem.line;

                        if (lineItem.line >= 4)
                            lineNumber = lineItem.line + 2; // offset by 2 for mapping

                        debitCreditItem = _.find(self.items, function(i) { return i.cdfSection === CdfSection.N && i.line == lineNumber; });
                    }

                    if (lineItem.cdfSection === CdfSection.N)
                    {
                        var lineNumber = lineItem.line;

                        if (lineItem.line >= 6)
                            lineNumber = lineItem.line - 2; // offset by 2 for mapping

                        debitCreditItem = _.find(self.items, function(i) { return i.cdfSection === CdfSection.L && i.line == lineNumber; });
                    }
                }

                return debitCreditItem;
            },
            findHudDebitCreditItem(lineItem) {
                const self = this;
                let debitCreditItem = null;

                // UI Mapping done for HUD lines here to reduce the save and map operations on each and every hud line and refreshing data
                // UI logic manipulates the data up front and saves the data in bulk freeing us from performing save and mapping hud line for synching
                if (self.isHud) {
                    if (!_.isNil(lineItem.debitCreditLineID))
                        return debitCreditItem = _.find(self.items, function(i) { return i.debitCreditSectionDataID == lineItem.debitCreditLineID && i.defaultedSettlementType === self.selectedView; });

                    if (lineItem.hudSection === 100)
                    {
                        let lineNumber = lineItem.hudLine + 300;  // Offset

                        debitCreditItem = _.find(self.items, function(i) { return i.hudSection === 400 && i.hudLine == lineNumber && i.defaultedSettlementType === self.selectedView; });
                    }

                    if (lineItem.hudSection === 400)
                    {
                        let lineNumber = lineItem.hudLine - 300; // offset

                        debitCreditItem = _.find(self.items, function(i) { return i.hudSection === 100 && i.hudLine == lineNumber && i.defaultedSettlementType === self.selectedView; });
                    }

                    // For Section 200 we have to find the first available line for debit credit
                    if (lineItem.hudSection === 200)
                    {
                        let lineNumber = lineItem.hudLine + 300; // offset

                        debitCreditItem = _.find(self.items, function(i) { return i.hudSection === 500 && i.hudLine === lineNumber && i.defaultedSettlementType === self.selectedView; });
                    }

                    // For Section 500 we have to find the first available line for debit credit
                    if (lineItem.hudSection === 500)
                    {
                        var lineNumber = lineItem.hudLine - 300; // offset

                        debitCreditItem = _.find(self.items, function(i) { return i.hudSection === 200 && i.hudLine === lineNumber && i.defaultedSettlementType === self.selectedView; });
                    }
                }

                return debitCreditItem;
            },

            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            fetchData() {
                const self = this;
                if (self.selectedLoanId === 0) return Promise.resolve(true);
                let apiPromises = [
                    self.$api.DebitCreditsApi.getByLoanId(self.selectedLoanId)
                ];

                // IF Cdf and Borrower Amount Totals should reflect from Cash To Close table directly
                if (self.showCashToCloseFinalAmount)
                {
                    apiPromises.push(self.$api.CashToCloseApi.getFinalTotalAmount(self.selectedLoanId));
                }

                return self.$rqBusy.wait(Promise.all(apiPromises)).then(results=>{
                    self.items = _.map(results[0], result => new DebitCreditModel(result));
                    if (self.showCashToCloseFinalAmount)
                        self.cashToCloseFinalTotalAmount = results[1];

                    // CDF data
                    self.borrowerDebitItems = _.sortBy(_.filter(self.items, item => item.cdfSection === 'K'), "line");
                    self.borrowerCreditItems = _.sortBy(_.filter(self.items, item => item.cdfSection === 'L'), "line");
                    self.sellerCreditItems = _.sortBy(_.filter(self.items, item => item.cdfSection === 'M'), "line");
                    self.sellerDebitItems = _.sortBy(_.filter(self.items, item => item.cdfSection === 'N'), "line");

                    // HUD data
                    if (self.isHud) {
                        self.refreshHudItems();
                        self.hideHudDCCheckBox();
                    }

                    self.originalData = _.map(self.items, item => new DebitCreditModel(item));
                });
            },

            refreshHudItems() {
                const self = this;
                // HUD data
                if (self.isHud) {
                    let hudItems = _.filter(self.items, item => item.defaultedSettlementType === self.selectedView);
                    self.hudBorrowerDebitItems = _.sortBy(_.filter(hudItems, item => item.hudSection === 100), "hudLine");
                    self.hudBorrowerCreditItems = _.sortBy(_.filter(hudItems, item => item.hudSection === 200), "hudLine");
                    self.hudSellerCreditItems = _.sortBy(_.filter(hudItems, item => item.hudSection === 400), "hudLine");
                    self.hudSellerDebitItems = _.sortBy(_.filter(hudItems, item => item.hudSection === 500), "hudLine");
                }
            },

            hideHudDCCheckBox() {
                const self = this;
                let hudItems = _.filter(self.items, item => item.defaultedSettlementType === self.selectedView);
                _.forEach(hudItems, (currentItem) => {
                    let targetHudLines = [404,405,206,207,208,209];
                    if(!_.includes(targetHudLines, currentItem.hudLine)) return;
                    let dcItem = self.findHudDebitCreditItem(currentItem);
                    currentItem.hideDebitCredit = _.parseNumber(dcItem.payoffID, 0) > 0;
                });
            },

            syncUpdates() {
                const self = this;
                self.originalData = _.cloneDeep(self.items);
                //refresh the store
                let promise = self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, {orderId: self.orderId, refresh:true});
                self.$rqBusy.wait(promise);
                return promise;
            },

            save(){
                const self = this;
                if(!self.hasChanges()){
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                if (self.validationErrors.length > 0) return;

                let savePromise = self.$api.DebitCreditsApi.update(self.orderId, self.items);
                return self.$rqBusy.wait(savePromise).then(() =>{
                    // self.fetchData();

                    self.syncUpdates().then(() => {
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Debits/Credits Saved Successfully");
                        return true;
                    });
                }).catch(err => {
                    GlobalEventManager.saveCompleted({success: false});
                    self.$toast.error("An issue occurred while saving Debits/Credits.");
                    console.error(err);
                });

            },

            hasChanges(){
                //original data was empty and user added something
                if(this.originalData.length === 0 && this.items.length > 0){
                    return true;
                }
                //user deleted all records (already saved), or they never entered anything in the first place
                if(this.items.length === 0){
                    return false;
                }
                //if the arrays are different length, then there are changes.  Deletes have already been saved
                if(this.items.length !== this.originalData.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(this.originalData, this.items);
                return changes.length > 0;
            },
            onOpenDetail(section) {
                let self = this;

                let expandData = [];
                let modalTitle = "";
                self.activeSection = section;

                // Identify sections for CDF / HUD
                switch(section) {
                    case CdfSection.K:
                        modalTitle = CdfSectionTitle.K;
                        expandData = _.clone(this.borrowerDebitItems);
                        break;
                    case CdfSection.M:
                        modalTitle = CdfSectionTitle.M;
                        expandData = _.clone(this.sellerCreditItems);
                        break;
                    case CdfSection.L:
                        modalTitle = CdfSectionTitle.L;
                        expandData = _.clone(this.borrowerCreditItems);
                        break;
                    case CdfSection.N:
                        modalTitle = CdfSectionTitle.N;
                        expandData = _.clone(this.sellerDebitItems);
                        break;
                    case 100:
                        modalTitle = HudSectionTitle.BorrowerDebits;
                        expandData = _.clone(this.hudBorrowerDebitItems);
                        break;
                    case 200:
                        modalTitle = HudSectionTitle.BorrowerCredits;
                        expandData = _.clone(this.hudBorrowerCreditItems);
                        break;
                    case 400:
                        modalTitle = HudSectionTitle.SellerCredits;
                        expandData = _.clone(this.hudSellerCreditItems);
                        break;
                    case 500:
                        modalTitle = HudSectionTitle.SellerDebits;
                        expandData = _.clone(this.hudSellerDebitItems);
                        break;
                }

                let okHandler = (e) => {
                    self.activeSection = null;
                    return true;
                };

                self.$dialog.open({
                    width: 1600,
                    adaptive: true,
                    component: this.isCdf
                        ? DebitCreditSection
                        : DebitCreditHudSection,
                    title: modalTitle,
                    props: {
                        expandDetail: true,
                        sectionData: expandData,
                        isCdf: self.isCdf,
                        hideDebitCredit: false
                    },
                    okTitle: "OK",
                    okOnly: true,
                    onOk: okHandler
                });

            },
            initEventListeners() {
                this.$events.on("toggle-debit-credit", (e) => {
                    if (this.isCdf || this.isHud)
                        this.onToggleDebitCredit(e);
                });

                this.$events.on("update-earnest-money-hud", (e) => {
                    if (this.isHud)
                        this.onUpdateEarnestMoneyHUD(e);
                });
                this.$events.on("update-earnest-money-cdf", (e) => {
                    if (this.isCdf)
                        this.onUpdateEarnestMoneyCDF(e);
                });
            },
            removeEventListeners() {
                this.$events.remove("toggle-debit-credit")
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },
            calculateTotal(itemList) {

                return _.reduce(itemList, (sum, item) => {
                        let amountValue = !_.isNil(item.overrideAmount) ? _.parseNumber(item.overrideAmount) : _.parseNumber(item.amount, 0);
                        return sum + amountValue;
                    }, 0);
            }
        }
    }
</script>