//////////////
// Checks   //
//////////////

const checkAction = {
    ChangeMisc: 0, ChangeDate: 1, ChangePayeePayor: 2, Delete: 3, Void: 4, QuickCheck: 5, Print: 6, Undo: 7, Stop: 8, PrintNoPaper: 9,
    FundsReceived: 10, ChangedAmount: 11, RemovedDate: 12, ChangedTypeFunds: 13, ChangedCheckNumber: 14, UnVoided: 15, ChangedVoidDate: 16, ReceiptID: 17, FileScan: 18
};
export class CheckAction {
    static get ChangeMisc () {return checkAction.ChangeMisc;}
    static get ChangeDate () {return checkAction.ChangeDate;}
    static get ChangePayeePayor () {return checkAction.ChangePayeePayor;}
    static get Delete () {return checkAction.Delete;}
    static get Void () {return checkAction.Void;}
    static get QuickCheck () {return checkAction.QuickCheck;}
    static get Print () {return checkAction.Print;}
    static get Undo () {return checkAction.Undo;}
    static get Stop () {return checkAction.Stop;}
    static get PrintNoPaper () {return checkAction.PrintNoPaper;}
    static get FundsReceived () {return checkAction.FundsReceived;}
    static get ChangedAmount () {return checkAction.ChangedAmount;}
    static get RemovedDate () {return checkAction.RemovedDate;}
    static get ChangedTypeFunds () {return checkAction.ChangedTypeFunds;}
    static get ChangedCheckNumber () {return checkAction.ChangedCheckNumber;}
    static get UnVoided () {return checkAction.UnVoided;}
    static get ChangedVoidDate () {return checkAction.ChangedVoidDate;}
    static get ReceiptID () {return checkAction.ReceiptID;}
    static get FileScan () {return checkAction.FileScan;}

    static get lookupItems () {
        return _.map(checkAction, c => { return { id: c, name: CheckAction.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case checkAction.ChangeMisc:
                return "Changed Description";
            case checkAction.ChangeDate:
                return "Changed Date";
            case checkAction.ChangePayeePayor:
                return "Changed Payee/Payor";
            case checkAction.Delete:
                return "Deleted";
            case checkAction.Void:
                return "Voided";
            case checkAction.QuickCheck:
                return "Quick Check";
            case checkAction.Print:
                return "Printed";
            case checkAction.Undo:
                return "Undone";
            case checkAction.Stop:
                return "Stopped";
            case checkAction.PrintNoPaper:
                return "Print w/o Paper";
            case checkAction.FundsReceived:
                return "Funds Received";
            case checkAction.ChangedAmount:
                return "Changed Amount";
            case checkAction.RemovedDate:
                return "Removed Date";
            case checkAction.ChangedTypeFunds:
                return "Changed Type/Funds";
            case checkAction.ChangedCheckNumber:
                return "Changed Check #";
            case checkAction.UnVoided:
                return "Un Voided";
            case checkAction.ChangedVoidDate:
                return "Changed Void Date";
            case checkAction.ReceiptID:
                return "Receipt ID";
            case checkAction.FileScan:
                return "FileScan";
            default:
                return "Unknown";
        }
    }
}

const voidChecksAction = {
    LeaveBlankToUnvoid: 0, LeaveBlankToUndoStopPay: 1
};
export class VoidChecksAction {
    static get LeaveBlankToUnvoid () {return voidChecksAction.LeaveBlankToUnvoid;}
    static get LeaveBlankToUndoStopPay () {return voidChecksAction.LeaveBlankToUndoStopPay;}

    static get lookupItems () {
        return _.map(voidChecksAction, c => { return { id: c, name: VoidChecksAction.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case voidChecksAction.LeaveBlankToUnvoid:
                return "Leave blank to Unvoid";
            case voidChecksAction.LeaveBlankToUndoStopPay:
                return "Leave blank to undo Stop-Pay";
            default:
                return "Unknown";
        }
    }
}

const checksOrDeposits = {
    Checks: 0, Deposits: 1, DepositVerifiedDate: 2
};
export class ChecksOrDeposits {
    static get Checks () {return checksOrDeposits.Checks;}
    static get Deposits () {return checksOrDeposits.Deposits;}
    static get DepositVerifiedDate () {return checksOrDeposits.DepositVerifiedDate;}

    static get lookupItems () {
        return _.map(checksOrDeposits, c => { return { id: c, name: ChecksOrDeposits.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(checksOrDeposits, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const cashiersCheckStatus = {
    New: 1, InProgress: 2
};
export class CashiersCheckStatus {
    static get New () {return cashiersCheckStatus.New;}
    static get InProgress () {return cashiersCheckStatus.InProgress;}

    static get lookupItems () {
        return _.map(cashiersCheckStatus, c => { return { id: c, name: CashiersCheckStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(cashiersCheckStatus, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const checkStatus = {
    None: 0, Issued: 1, Hold: 2, Void: 3, StopPay: 4, Cleared: 5, Wire: 6, ExpectedWireOut: 7, PendingCashiersCheck: 8, PendingLossAdvance: 9,
    RejectedLossAdvance: 10, PendingApproval: 11, Approved: 12, Rejected: 13, PendingAdjustment: 14
};
export class CheckStatus {
    static get None () {return checkStatus.None;}
    static get Issued () {return checkStatus.Issued;}
    static get Hold () {return checkStatus.Hold;}
    static get Void () {return checkStatus.Void;}
    static get StopPay () {return checkStatus.StopPay;}
    static get Cleared () {return checkStatus.Cleared;}
    static get Wire () {return checkStatus.Wire;}
    static get ExpectedWireOut () {return checkStatus.ExpectedWireOut;}
    static get PendingCashiersCheck () {return checkStatus.PendingCashiersCheck;}
    static get PendingLossAdvance () {return checkStatus.PendingLossAdvance;}
    static get RejectedLossAdvance () {return checkStatus.RejectedLossAdvance;}
    static get PendingApproval () {return checkStatus.PendingApproval;}
    static get Approved () {return checkStatus.Approved;}
    static get Rejected () {return checkStatus.Rejected;}
    static get PendingAdjustment () {return checkStatus.PendingAdjustment;}

    static get consolidatedLookupItems () {
        let consolidatedCheckStatus = _.pullAllBy(CheckStatus.lookupItems, [{id: checkStatus.PendingLossAdvance},{id: checkStatus.PendingCashiersCheck},{id: checkStatus.RejectedLossAdvance},{id: checkStatus.PendingApproval},{id: checkStatus.Approved},{id: checkStatus.Rejected},{id: checkStatus.PendingAdjustment}], "id");
        return consolidatedCheckStatus;
    }

    static get lookupItems () {
        return _.map(checkStatus, c => { return { id: c, name: CheckStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case checkStatus.None:
                return "Anticipated";
            case checkStatus.Issued:
                return "Issued";
            case checkStatus.Hold:
                return "Hold";
            case checkStatus.Void:
                return "Void";
            case checkStatus.StopPay:
                return "Stop Pay";
            case checkStatus.Cleared:
                return "Cleared";
            case checkStatus.Wire:
                return "Wire";
            case checkStatus.ExpectedWireOut:
                return "Expected Wire Out";
            case checkStatus.PendingCashiersCheck:
                return "Pending Cashiers Check";
            case checkStatus.PendingLossAdvance:
                return "Pending";
            case checkStatus.RejectedLossAdvance:
                return "Rejected";
            case checkStatus.PendingApproval:
                return "Pending Approval";
            case checkStatus.Approved:
                return "Approved";
            case checkStatus.Rejected:
                return "Rejected";
            case checkStatus.PendingAdjustment:
                return "Pending Adjustment";
            default:
                return "Unknown";
        }
    }
}


const checkStatusFilter = {
    None: 0, Issued: 1, Hold: 2, Void: 3, StopPay: 4, Cleared: 5, Wire: 6, ExpectedWireOut: 7, PendingApproval: 11, Approved: 12, Rejected: 13,
    //PendingCashiersCheck: 8, PendingLossAdvance: 9, RejectedLossAdvance: 10, PendingAdjustment: 14
};
export class CheckStatusFilter {
    static get None () {return checkStatusFilter.None;}
    static get Issued () {return checkStatusFilter.Issued;}
    static get Hold () {return checkStatusFilter.Hold;}
    static get Void () {return checkStatusFilter.Void;}
    static get StopPay () {return checkStatusFilter.StopPay;}
    static get Cleared () {return checkStatusFilter.Cleared;}
    static get Wire () {return checkStatusFilter.Wire;}
    static get ExpectedWireOut () {return checkStatusFilter.ExpectedWireOut;}
    //static get PendingCashiersCheck () {return checkStatusFilter.PendingCashiersCheck;}
    //static get PendingLossAdvance () {return checkStatusFilter.PendingLossAdvance;}
    //static get RejectedLossAdvance () {return checkStatusFilter.RejectedLossAdvance;}
    static get PendingApproval () {return checkStatusFilter.PendingApproval;}
    static get Approved () {return checkStatusFilter.Approved;}
    static get Rejected () {return checkStatusFilter.Rejected;}
    //static get PendingAdjustment () {return checkStatusFilter.PendingAdjustment;}

    static get lookupItems () {
        return _.map(checkStatusFilter, c => { return { id: c, name: CheckStatusFilter.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case checkStatusFilter.None:
                return "Anticipated";
            case checkStatusFilter.Issued:
                return "Issued";
            case checkStatusFilter.Hold:
                return "Hold";
            case checkStatusFilter.Void:
                return "Void";
            case checkStatusFilter.StopPay:
                return "Stop Pay";
            case checkStatusFilter.Cleared:
                return "Cleared";
            case checkStatusFilter.Wire:
                return "Wire";
            case checkStatusFilter.ExpectedWireOut:
                return "Expected Wire Out";
            // case checkStatusFilter.PendingCashiersCheck:
            //     return "Pending Cashiers Check";
            // case checkStatusFilter.PendingLossAdvance:
            //     return "Pending Loss/Advance";
            //  case checkStatusFilter.RejectedLossAdvance:
            //     return "Rejected Loss/Advance";
            case checkStatusFilter.PendingApproval:
                return "Pending Approval";
            case checkStatusFilter.Approved:
                return "Approved";
            case checkStatusFilter.Rejected:
                return "Rejected";
            // case checkStatusFilter.PendingAdjustment:
            //     return "Pending Adjustment";
            default:
                return "Unknown";
        }
    }
}

const clearedStatus = {
    None: 0, Cleared: 5, Uncleared: 6, AllChecks: 7
};
export class ClearedStatus {
    static get None () {return clearedStatus.None;}
    static get Cleared () {return clearedStatus.Cleared;}
    static get Uncleared () {return clearedStatus.Uncleared;}
    static get AllChecks () {return clearedStatus.AllChecks;}

    static get lookupItems () {
        return _.map(clearedStatus, c => { return { id: c, name: ClearedStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case clearedStatus.None:
                return "";
            case clearedStatus.Cleared:
                return "Cleared Checks Only";
            case clearedStatus.Uncleared:
                return "Uncleared Checks Only";
            case clearedStatus.AllChecks:
                return "All Checks";
            default:
                return "Unknown";
        }
    }
}

const createdFromSource = {
    Manual: 0, HUD: 1, Orders: 2, Quick: 3, Exclude: 4, CDF: 5, CFER: 6
};
export class CreatedFromSource {
    static get Manual () {return createdFromSource.Manual;}
    static get HUD () {return createdFromSource.HUD;}
    static get Orders () {return createdFromSource.Orders;}
    static get Quick () {return createdFromSource.Quick;}
    static get Exclude () {return createdFromSource.Exclude;}
    static get CDF () {return createdFromSource.CDF;}
    static get CFER () {return createdFromSource.CFER;}

    static get lookupItems () {
        return _.map(createdFromSource, c => { return { id: c, name: CreatedFromSource.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(createdFromSource, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const wireOutStatus = {
    New: 1, InProgress: 2, NeedsApproval: 3, Pending: 4, Processed: 5
};
export class WireOutStatus {
    static get New () {return wireOutStatus.New;}
    static get InProgress () {return wireOutStatus.InProgress;}
    static get NeedsApproval () {return wireOutStatus.NeedsApproval;}
    static get Pending () {return wireOutStatus.Pending;}
    static get Processed () {return wireOutStatus.Processed;}

    static get lookupItems () {
        return _.map(wireOutStatus, c => { return { id: c, name: WireOutStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(wireOutStatus, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

//////////////
// Deposits //
//////////////
const depositStatus = {
    Anticipated: 51, Reconciled: 52, Booked: 53
};

const depositStatusExt = {...depositStatus, Verified: 1000, Deleted: 999};

export class DepositStatus {
    static get Anticipated () {return depositStatus.Anticipated;}
    static get Reconciled () {return depositStatus.Reconciled;}
    static get Booked () {return depositStatus.Booked;}

    static get lookupItems () {
        return _.map(depositStatus, c => { return { id: c, name: DepositStatus.displayValue(c) }; });
    }

    static get lookupItemsExt () {
        return _.map(depositStatusExt, c => { return { id: c, name: DepositStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(depositStatusExt, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}


const fundOption = {
    None: 0, Cash: 1, CashiersCheck: 2, CertifiedCheck: 3, Check: 4, DirectDeposit: 5, NSFCheck: 6, Wire: 7,
    Transfer: 8, EFER: 9, Adjustment: 10, Reversal: 11, AdvancedTransfer: 12, LossTransfer: 13, RecoveryTransfer: 14, CFER: 15
};
export class FundOption {
    static get None () {return fundOption.None;}
    static get Cash () {return fundOption.Cash;}
    static get CashiersCheck () {return fundOption.CashiersCheck;}
    static get CertifiedCheck () {return fundOption.CertifiedCheck;}
    static get Check () {return fundOption.Check;}
    static get DirectDeposit () {return fundOption.DirectDeposit;}
    static get NSFCheck () {return fundOption.NSFCheck;}
    static get Wire () {return fundOption.Wire;}
    static get Transfer () {return fundOption.Transfer;}
    static get EFER () {return fundOption.EFER;}
    static get Adjustment () {return fundOption.Adjustment;}
    static get Reversal () {return fundOption.Reversal;}
    static get AdvancedTransfer () {return fundOption.AdvancedTransfer;}
    static get LossTransfer () {return fundOption.LossTransfer;}
    static get RecoveryTransfer () {return fundOption.RecoveryTransfer;}
    static get CFER () {return fundOption.CFER;}

    static get lookupItems () {
        return _.map(fundOption, c => { return { id: c, name: FundOption.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(fundOption, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const sessionType = {
    Checks: 0, Deposits: 1
};
export class SessionType {
    static get Checks () {return sessionType.Checks;}
    static get Deposits () {return sessionType.Deposits;}

    static get lookupItems () {
        return _.map(sessionType, c => { return { id: c, name: SessionType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(sessionType, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const fileBalanceStatus = {
    Balanced: 0, BalancedAnticipated: 1, NotBalanced: 2
};
export class FileBalanceStatus {
    static get Balanced () {return fileBalanceStatus.Balanced;}
    static get BalancedAnticipated () {return fileBalanceStatus.BalancedAnticipated;}
    static get NotBalanced () {return fileBalanceStatus.NotBalanced;}

    static get lookupItems () {
        return _.map(fileBalanceStatus, c => { return { id: c, name: FileBalanceStatus.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(fileBalanceStatus, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const settlementStatementChoice = {
    None: 0, Commercial: 1, HUD: 2, CDF: 3
};
export class SettlementStatementChoice {
    static get None () {return settlementStatementChoice.Balanced;}
    static get Commercial () {return settlementStatementChoice.Commercial;}
    static get HUD () {return settlementStatementChoice.HUD;}
    static get CDF () {return settlementStatementChoice.CDF;}

    static get lookupItems () {
        return _.map(settlementStatementChoice, c => { return { id: c, name: SettlementStatementChoice.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case settlementStatementChoice.None:
                return "Choose Settlement Statement";
            case settlementStatementChoice.Commercial:
                return "Settlement Statement (SS)";
            case settlementStatementChoice.HUD:
                return "HUD Settlement Statement (HUD)";
            case settlementStatementChoice.CDF:
                return "Closing Disclosure Form (CDF)";
        }
        return "Unknown";
    }

    static displayShortValue (id) {
        switch (id) {
            case settlementStatementChoice.None:
                return "None";
            case settlementStatementChoice.Commercial:
                return "SS";
            case settlementStatementChoice.HUD:
                return "HUD";
            case settlementStatementChoice.CDF:
                return "CDF";
        }
        return "(Unknown Source)";
    }
}
