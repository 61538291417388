<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            fixed-header
            automation_id="dg_available"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="bucket-allocation-data"
            :strikethrough-if-true="['isInactive']"
            focus-after-insert="first-row"
            hide-show-column-chooser
            hide-clear-filters
            hide-search
            rq-editable
        />
    </div>
</template>

<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { SETTLEMENT_TYPE, HudLine, HudLineTypes } from "@settlement/models";
    import { HudGFECanChangeChargeDto, HudGFECannotChangeChargeDto } from "./models";

    export default {
        name: 'BucketAllocation',

        props: {
            unallocatedItems: { default: function () { return []; } },
            loanId: { type: Number, default: 0 }
        },

        components: { },

        data() {
            return {
                bucketOptions: [
                    { id: 1, name: "Cannot Change More Than 10%" },
                    { id: 2, name: "Can Change" }
                ],
            };
        },

        watch: { },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                loans: state => state.orders.loans || [],
                readOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                selectedView: state => _.parseNumber(state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF)
            }),
            HudLineTypes() { return HudLineTypes; },
            gridInstance() { return this.$refs.dataGrid.gridInstance; },
            allocatedItems() {
                return _.filter(this.unallocatedItems, item => _.parseNumber(item.gfeBucket, 0) !== 0);
            }
        },

        beforeCreate() { },

        created() {
            this.initGridConfig();
        },

        beforeMount() { },

        mounted() { },

        beforeUpdate() { },

        updated() { },

        beforeUnmount() { },

        unmounted() { },

        methods: {
            save(){
                const self = this;
                return self.gridInstance.saveEditData().then(() => {
                    if (self.allocatedItems.length === 0) return Promise.resolve(true);

                    let savePromise = self.$api.HudGFEApi.saveAllocatedItems(self.loanId, self.allocatedItems);
                    return self.$rqBusy.wait(savePromise).then(() =>{
                        self.$toast.success("GFE Allocated Successfully");
                    }).catch(err => {
                        self.$toast.error(err.errorMessage);
                    });
                });
            },
            refreshGrid() {
                this.gridInstance.refresh();
            },
            initGridConfig() {
                const self = this;

                self.gridConfig = {
                    columns: [
                        {
                            dataField: "line",
                            dataType: "number",
                            allowEditing: false,
                            caption: "HUD Line",
                            width: 75
                        },
                        {
                            caption: "Description",
                            allowEditing: false,
                            dataField: "description",
                            dataType: "string",
                        },
                        {
                            dataField: "gfeBucket",
                            dataType: "number",
                            caption: "Bucket",
                            width: 250,
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: self.bucketOptions,
                            },
                        },
                        {
                            dataField: "derivedAmount",
                            dataType: "number",
                            caption: "Amount",
                            allowEditing: false,
                            width: 150,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                    ],
                }

                self.initGridDataSource();
            },
            initGridDataSource() {
                const self = this;
                self.gridDataSource = {
                    key: "line",
                    load(){
                        return Promise.resolve(self.unallocatedItems);
                    },
                    update: self.onGridUpdate
                };
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.unallocatedItems, item => item.line === key);
                let newUpdateItem = new HudLine((_.assign({}, self.unallocatedItems[itemIndex], values)));
                self.unallocatedItems[itemIndex] = newUpdateItem;
                return Promise.resolve(newUpdateItem);
            },
         }
    };
</script>

<style lang="scss">

</style>
