import * as Fields from "./fields";
import { OrderServiceType } from "@/shared/models/enums";

export default [{
        "id": OrderServiceType.AltaConstructionLoan,
        "key": "ALTAConstructionLoan",
        "text": "Construction",
        "sections": [{
                "key": "binder",
                "text": "Binder",
                "showHeader": false,
                "fields": [Fields.BINDER_DATE, Fields.BINDER_NUMBER]
            },
            {
                "key": "main",
                "text": "Main",
                "showHeader": false,
                "fields": [Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST, Fields.CONSTRUCTION_INSURED, Fields.CONSTRUCTION_BORROWER]
            },
            {
                "key": "lien",
                "text": "Lien Description",
                "showHeader": false,
                "fields": [Fields.LIEN_DESCRIPTION]//, Fields.LEGAL_DESCRIPTION
            }
        ]
    },
    {
        "id": OrderServiceType.AltaMortgage,
        "key": "ALTAMortgage",
        "text": "Loan",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.MORTGAGE_INSURED, Fields.MORTGAGE_BORROWER, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.AltaOther,
        "key": "ALTAOther",
        "text": "Other",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.AltaOwner,
        "key": "ALTAOwner",
        "text": "Owner",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.OWNER_INSURED, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.AltaSimultaneous,
        "key": "ALTASimultaneous",
        "text": "Simultaneous",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.OWNER_INSURED, Fields.MORTGAGE_BORROWER, Fields.MORTGAGE_INSURED, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.AltaTitleGuard,
        "key": "ALTATitleGuard",
        "text": "Title Guarantee",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.OWNER_INSURED, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.TxBinder,
        "key": "TXBinder",
        "text": "Binder",
        "sections": [{
                "key": "binder",
                "text": "Binder",
                "showHeader": false,
                "fields": [Fields.BINDER_DATE, Fields.BINDER_NUMBER]
            },
            {
                "key": "main",
                "text": "Main",
                "showHeader": false,
                "fields": [Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST, Fields.CONSTRUCTION_INSURED, Fields.CONSTRUCTION_BORROWER]
            },
            {
                "key": "lien",
                "text": "Lien Description",
                "showHeader": false,
                "fields": [Fields.LIEN_DESCRIPTION]//, Fields.LEGAL_DESCRIPTION
            }
        ]
    },
    {
        "id": OrderServiceType.TxMortgage,
        "key": "TXMortgage",
        "text": "Loan",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.MORTGAGE_INSURED, Fields.MORTGAGE_BORROWER, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.TxOther,
        "key": "TXOther",
        "text": "Other",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.TxOwnerNonResident,
        "key": "TXOwnerNonResident",
        "text": "Owner (Non Res)",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.OWNER_INSURED, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.TxOwnerResident,
        "key": "TXOwnerResident",
        "text": "Owner (Res)",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.OWNER_INSURED, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.TxSimultaneousNonResident,
        "key": "TXSimultaneousNonResident",
        "text": "Simult. (Non Res)",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.OWNER_INSURED, Fields.MORTGAGE_INSURED, Fields.MORTGAGE_BORROWER, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    },
    {
        "id": OrderServiceType.TxSimultaneousResident,
        "key": "TXSimultaneousResident",
        "text": "Simult. (Res)",
        "sections": [{
            "key": "main",
            "text": "Main",
            "showHeader": false,
            "fields": [Fields.OWNER_INSURED, Fields.MORTGAGE_INSURED, Fields.MORTGAGE_BORROWER, Fields.CURRENT_HOLDERS, Fields.LAND_INTEREST]//, Fields.LEGAL_DESCRIPTION
        }]
    }
];