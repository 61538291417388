<template>
    <div class="rq-container loancost-table">
        <div class="rq-table-container rq-table-container-bordered rq-grid-multi-select">
            <rq-grid-action-bar
                class="rq-table-selection"
                :actions="selectionActions"
                :selected-items="selectedItems"
                :read-only="readOnly"
                @action-click="emitActionEvent"
            />
            <rq-scroll-container
                ref="psElement"
                ps-class-attr="rq-table-scroll-container multi-row-header"
                perfect-scrollbar
                update-on-resize>
                <table ref="tableElement" class="rq-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th v-if="includeAlta" colspan="1"></th> <!-- category -->
                            <th v-if="isHud"></th>
                            <th></th>
                            <th></th>
                            <th :colspan="isCdf ? 2 : 1" class="rq-col-bottom-border">Borrower</th>
                            <th v-if="!isWithOutSeller" :colspan="isCdf ? 2 : 1" class="rq-col-bottom-border">Seller</th>
                            <th v-if="isCdf"></th>
                            <th v-if="isCdf"></th>
                            <th v-if="isHud"></th>
                            <th v-if="isHud"></th>
                            <th></th>
                            <th></th>
                            <th v-if="isHud2010" colspan="1"></th> <!-- Line Type place holder -->
                            <th v-if="includeAlta" colspan="1"></th> <!-- do not print -->
                        </tr>
                        <tr>
                            <td class="rq-col-selection">
                                <rq-select-all-toggle
                                    :all-selected="allSelected"
                                    :some-selected="someSelected"
                                    @toggle="onSelectAllToggle"
                                />
                            </td>
                            <th v-if="includeAlta">Category</th>
                            <th v-if="isHud">HUD Line</th>
                            <th>Description</th>
                            <th>Payee</th>
                            <th class="rq-col-currency">At Closing</th>
                            <th v-if="isCdf" class="rq-col-currency">Before Closing</th>
                            <th v-if="!isWithOutSeller" class="rq-col-currency">At Closing</th>
                            <th v-if="isCdf && !isWithOutSeller" class="rq-col-currency">Before Closing</th>
                            <th v-if="isCdf" class="rq-col-currency">Paid By Others</th>
                            <th v-if="isCdf">CDF Section</th>
                            <th v-if="isHud">POC Whom</th>
                            <th v-if="isHud">POC Amount</th>
                            <th>Net Fund</th>
                            <th>Account Code</th>
                            <th v-if="isHud2010">Line Type</th>
                            <th v-if="includeAlta">Do Not Print</th>
                        </tr>
                    </thead>
                    <tbody>
                        <PrepaidSectionLine
                            v-for="line in prepaidSectionData"
                            ref="prepaidSectionLines"
                            :key="line.prepaidSectionDataID"
                            :line-data="line"
                            :read-only="readOnly"
                            :settlementType="settlementType"
                        />
                        <RqTableSpacerRow
                            :height="spacerHeight"
                            :col-count="colCount"
                        />
                    </tbody>
                    <tfoot>
                        <tr class="total-row">
                            <th :colspan="isCdf ? 3 : 4">TOTALS</th>
                            <th>{{formatMoney(sectionTotals.borrowerAtClosing)}}</th>
                            <th v-if="isCdf">{{formatMoney(sectionTotals.borrowerBeforeClosing)}}</th>
                            <th v-if="!isWithOutSeller">{{formatMoney(sectionTotals.sellerAtClosing)}}</th>
                            <th v-if="isCdf && !isWithOutSeller">{{formatMoney(sectionTotals.sellerBeforeClosing)}}</th>
                            <th v-if="isCdf">{{formatMoney(sectionTotals.paidByOthers)}}</th>
                            <th v-if="isHud"></th> <!-- POC Whom place holder -->
                            <th v-if="isHud">{{formatMoney(sectionTotals.pocAmount)}}</th>
                            <th v-if="isHud2010"></th>
                            <th :colspan="isCdf ? (includeAlta ? 5 : 3) : 2"></th>
                        </tr>
                    </tfoot>
                </table>
            </rq-scroll-container>
        </div>
    </div>
</template>
<script>
    import { ref } from "vue";
    import { mapState, mapGetters } from "vuex";
    import RqSelectAllToggle from "@/shared/components/rq/RqSelectAllToggle";
    import PrepaidSectionLine from "./PrepaidSectionLine";
    import { SETTLEMENT_TYPE, SsGridActions } from '@settlement/models';
    import GridAction from "@/shared/models/GridAction";
    import { RqTableSpacerRow, useRqTableSpacer } from "@/shared/components/rq/RqTableSpacerRow";

    const PREPAID_TABLE_ACTION = {
        DELETE: "delete",
        REVERT: "revert",
        NET_FUND: "netfund",
        SET_ADDENDUM: "setAddendum"
    }
    export default {
        name: "PrepaidSection",
        components: {
            RqSelectAllToggle,
            PrepaidSectionLine,
            RqTableSpacerRow
        },
        props: {
            title: { type: String },
            sectionData: { type: Array },
            settlementType: { type: Number, default: SETTLEMENT_TYPE.CDF }
        },
        setup(props) {
            const prepaidSectionData = ref(props.sectionData);
            return {
                prepaidSectionData,
                ...useRqTableSpacer()
            };
        },
        computed: {
            ...mapState({
                isWithOutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
                includeAltaValue: state => _.getBool(state, "orders.orderSummary.includeAlta"),
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            colCount() {
                let result = 0;
                if(this.isCdf && this.includeAlta) return 11;
                if(this.isHud1974 || this.isCdf) result = 9;
                if(this.isHud2010) result = 10;
                if(!this.isWithOutSeller) result += this.isCdf ? 2 : 1;
                return result;
            },
            includeAlta() { return this.isCdf && this.includeAltaValue; },
            isCdf() { return this.settlementType === SETTLEMENT_TYPE.CDF },
            isHud1974() { return this.settlementType === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.settlementType === SETTLEMENT_TYPE.HUD_2010; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            sectionTotals() {
                let secTotals = { borrowerAtClosing: 0, borrowerBeforeClosing: 0, sellerAtClosing: 0, sellerBeforeClosing: 0, paidByOthers: 0 };

                secTotals.borrowerAtClosing = _.sumBy(this.prepaidSectionData, item => item.borrowerAtClosingValue);
                secTotals.borrowerBeforeClosing = _.sumBy(this.prepaidSectionData, item => item.borrowerBeforeClosing);
                secTotals.sellerAtClosing = _.sumBy(this.prepaidSectionData, item => item.sellerAtClosing);
                secTotals.sellerBeforeClosing = _.sumBy(this.prepaidSectionData, item => item.sellerBeforeClosing);
                secTotals.paidByOthers = _.sumBy(this.prepaidSectionData, item => item.paidByOthers);
                secTotals.pocAmount = _.sumBy(this.prepaidSectionData, item => item.pocAmount);

                return secTotals;
            },
            allSelected() { return _.every(this.prepaidSectionData, line => line.isSelected); },
            noneSelected() { return _.every(this.prepaidSectionData, line => !line.isSelected); },
            someSelected() { return !this.allSelected && !this.noneSelected; },
            selectedItems() { return _.filter(this.prepaidSectionData, "isSelected"); },
            classAttr() {
                return {
                    "rq-table-container": true,
                    "rq-grid-multi-select": true,
                    [`theme-${ _.get(this, "$route.meta.theme", "default")}`]: true
                };
            },
            selectionActions() {
                const self = this;
                return [
                    new GridAction({
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: 'Delete Charge(s)',
                        disabled(e) {
                            if (self.readOnly) return true;
                            return !self.canDelete() ? 'Unselect required lines to delete' : false;
                        }
                    }),
                    new GridAction({
                        name: "revert",
                        text: "Revert",
                        eventName: "revert",
                        allowMultiSelection: false,
                        tooltip: "Revert Charge(s)",
                        disabled(e) {
                            if (self.readOnly) return true;
                            return !self.canRevert();
                        }
                    }),
                    new GridAction({
                        name: "Apply-Net-Fund",
                        text: "Apply Net Fund",
                        eventName: "netfund",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (self.readOnly) return self.readOnly;

                            if (self.isHud && _.some(e.data, item => item.isPaidByOther)) return true;

                            if (self.isHud2010 && _.find(e.data, item => item.isDisclosureLine)) return true;

                            return false;
                        },
                        children: [
                            SsGridActions.NETFUND_NONE,
                            SsGridActions.NETFUND_POSITIVE,
                            SsGridActions.NETFUND_NEGATIVE
                        ]
                    }),
                    new GridAction({
                        name: "setAddendum",
                        text: "Set as Addendum Line",
                        eventName: "setAddendum",
                        requireSelection: true,
                        allowMultiSelection: false,
                        tooltip: "Set as Addendum Line",
                        visible: function(e) {
                            if (!e || !e.data) return false;
                            let item = e.data;
                            if (_.parseBool(item.required)
                                || item.paidBySource > 0) return false;

                            return (self.isCdf && !_.parseBool(item.isAddendum)); // isCdf and elgible addendum item
                        },
                        disabled(e) {
                            if (_.isArray(e.data)) return true;
                            let item = e.data;
                            if (self.readOnly) return true;

                            return false;
                        }
                    }),
                    new GridAction({
                        name: "setAddendum",
                        text: "Remove Addendum Line",
                        eventName: "setAddendum",
                        requireSelection: true,
                        allowMultiSelection: false,
                        tooltip: "Remove Addendum Line",
                        visible: function(e) {
                            if (!e || !e.data) return false;
                            let item = e.data;
                            if (_.parseBool(item.required)
                                || item.paidBySource > 0) return false;

                            return (self.isCdf && _.parseBool(item.isAddendum)); // isCdf and elgible addendum item
                        },
                        disabled(e) {
                            if (_.isArray(e.data)) return true;
                            let item = e.data;
                            if (self.readOnly) return true;

                            return false;
                        }
                    }),
                ];
            },
        },
        watch: {
            sectionData: {
                handler: function(newValue, oldValue) {
                    this.prepaidSectionData = newValue;
                    this.updateSpacerHeight();
                },
                deep: true
            }
        },
        methods: {
            canDelete() {
                const self = this;
                if (_.isEmpty(this.selectedItems) || this.readOnly) return false;

                return !_.some(this.selectedItems, function(o) { return o.required || o.isPaidByOther });
            },
            canRevert() {
                if (_.isEmpty(this.selectedItems) || this.readOnly) return false;

                return _.some(this.selectedItems, function(o) {
                    return o.isOverridableLine && (o.isBorrowerAtClosingOverridden || o.isPrepaidIntFromDateOverridden || o.isPrepaidIntToDateOverridden || o.isPrepaidIntAmountOverridden);
                });
            },
            emitActionEvent(action) {
                switch(action.name) {
                    case PREPAID_TABLE_ACTION.DELETE:
                        this.$emit("delete");
                        break;
                    case PREPAID_TABLE_ACTION.REVERT:
                        this.$emit("revert");
                        break;
                    case PREPAID_TABLE_ACTION.NET_FUND:
                        this.$emit("netfund", action);
                        break;
                    case PREPAID_TABLE_ACTION.SET_ADDENDUM:
                    this.$emit("setAddendum", action);
                    break;
                }
            },
            onSelectAllToggle(newValue) {
                _.forEach(this.prepaidSectionData, line => { line.isSelected = newValue; });
            },
            formatMoney(amount) {
                return amount != null ? accounting.formatMoney(amount) : "";
            },
            onScrollUpdated(e) {
                this.updateSpacerHeight();
            }
        }
    }
</script>