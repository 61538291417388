<template>
    <div class="survey-lookup content-wrapper">
        <survey-list
            v-show="surveyListActive"
            ref="surveyList"
            v-model:selected-id="selectedIdValue"
            :property-state="propertyState"
            :read-only="readOnly"
            @add="onComponentAction(actions.AddSurvey, $event)"
            @edit="onComponentAction(actions.EditSurvey, $event)"
            @select="onComponentAction(actions.SelectSurvey, $event)"
            embedded-mode
        />

        <survey-form
            v-show="surveyActive"
            ref="surveyForm"
            :property-state="propertyState"
            :read-only="readOnly"
            @enable-save-button="onEnableSave"
            embedded-mode />


        <Teleport
            v-if="dialogFooterAvailable"
            :to="`#${dialogFooterId}`">
            <div class="survey-lookup-footer">
                <section class="left-footer-section"></section>
                <section class="right-footer-section">
                    <button automation_id="btn_survey_lookup_back" type="button" class="btn btn-secondary" @click="back()">{{readOnly ? "Back" : "Cancel"}}</button>
                    <button automation_id="btn_survey_lookup_add_another" type="button" class="btn btn-primary" @click="save(true)" v-show="addAnotherSurveyButtonVisible" :disabled="!enableSave">Save &amp; Add Another</button>
                    <button automation_id="btn_survey_lookup_ok" type="button" class="btn btn-primary" @click="save()" v-show="!readOnly" :disabled="!enableSave">{{readOnly || !surveyListActive ? "Save" : "Ok"}}</button>
                </section>
            </div>
        </Teleport>
    </div>
</template>

<script>

    import SurveyList from "./SurveyList.vue";
    import SurveyForm from "./SurveyForm.vue";
    import { LookupItem, SurveyDto, SystemLookupItemDto } from "@/shared/models/models";

    export default {
        name: "SurveyLookup",
        props: {
            selectedId: { type: Number, default: -1 },
            propertyState: { type: String, default: "" },
            readOnly: { type: Boolean, default: false }
        },
        components: { SurveyList, SurveyForm},
        inject: {
            dialogFooterId: { from: "footerId", default: null },
            dialogFooterAvailable: { from: "footerAvailable", default: false }
        },
        data () {
            return {
                currentSurvey: {},
                currentView: "SurveyList",
                currentDialogTitle: null,
                enableSave: true,
                addAnotherSurveyButton: false,
                allowExportSelectedData: false
            };
        },

        watch: {
            currentDialogTitle(newValue, oldValue) {
                if(newValue === oldValue) return;
                let title = newValue;
                if(_.isNil(newValue)) title = "Select Survey";
                this.$emit("update-dialog-options", { title });
            }
        },

        computed: {
            surveyFormComponent() { return _.get(this, "$refs.surveyForm", null); },
            surveyListComponent() { return _.get(this, "$refs.surveyList", null); },
            surveyListActive() { return this.currentView === "SurveyList"; },
            surveyActive() { return this.currentView === "SurveyForm"; },
            selectedIdValue: {
                get() { return this.selectedId; },
                set(val) { this.$emit("update:selectedId", val); }
            },
            addAnotherSurveyButtonVisible () { return this.surveyActive && this.addAnotherSurveyButton && !this.readOnly; }
        },

        created() {
            this.actions = {
                AddSurvey: "addSurvey",
                EditSurvey: "editSurvey",
                SelectSurvey: "selectSurvey",
            };

            this.viewNames = {
                survey: "SurveyForm",
                list: "SurveyList"
            };
        },

        methods: {

            executeAction(name, actionData = null) {
                const self = this;
                let nextView = "";
                if(name === self.actions.EditSurvey && !actionData) return;

                switch(name) {
                    case self.actions.AddSurvey:
                        nextView = self.viewNames.survey;
                        self.addAnotherSurveyButton = true;
                        self.currentDialogTitle = "Add Survey";
                        self.currentSurvey = new SurveyDto({state: self.propertyState});
                        break;
                    case self.actions.EditSurvey:
                        nextView = self.viewNames.survey;
                        self.currentSurvey = actionData;
                        self.addAnotherSurveyButton = false;
                        self.currentDialogTitle = "Edit Survey";
                        break;
                    case self.actions.SelectSurvey:
                        self.$emit('ok');
                        return;
                }
                self.setView(nextView);
            },

            back () {
                const self = this;
                if(self.currentView === self.viewNames.survey){
                    self.surveyFormComponent.reset();
                    self.enableSave = true;
                    self.currentDialogTitle = "Add/Edit Surveys";
                    self.setView(self.viewNames.list);
                    self.surveyListComponent.refresh();
                }
                else if(self.currentView === self.viewNames.list){
                    self.$emit('action', { lookupItems: self.lookupItems()});
                }
            },

            dialogResult (e) {
                return this.surveyListComponent.dialogResult();
            },

            lookupItems (e) {
                this.surveyListComponent.gridInstance.searchByText("");
                let surveys  = this.surveyListComponent.gridInstance.getDataSource().items();
                let lookupItems = surveys ? _.map(surveys, survey => new SystemLookupItemDto({id: survey.surveyID, name: survey.name, regionID: 0, data: null})) : [];
                return lookupItems;
            },

            onComponentAction(name, e){
                this.executeAction(name, e && e.data);
            },

            onEnableSave(enable){
                this.enableSave = enable || (this.currentView === this.viewNames.list);
            },

            save (addAnother=false) {
                const self = this;
                if(self.currentView === self.viewNames.survey){
                    self.saveSurvey(addAnother);
                }
                else if(self.currentView === self.viewNames.list){
                    self.$emit('ok');
                }
            },

            saveSurvey(addAnother=false) {
                const self = this;
                if(!self.surveyFormComponent) {
                    self.$toast.error({ message: "Survey component not initialized." });
                    return;
                }
                let isValid = self.surveyFormComponent.validate();
                if (isValid.success) {
                    self.surveyFormComponent.save()
                        .then(result => {
                            self.surveyListComponent.refresh();
                            if(result.dialogMode === "edit" || !(result.dialogMode === "add" && addAnother)) {
                                self.setView(self.viewNames.list);
                            }
                            if (addAnother) {
                                self.surveyFormComponent.reset();
                                self.currentSurvey = new SurveyDto({state: self.propertyState});
                                self.surveyFormComponent.loadSurvey(self.currentSurvey);
                            }
                        })
                        .catch(err => {
                            console.log(err.errorMessage);
                        });
                }
            },

            setView(viewName) {
                const self = this;
                if(self.currentView === viewName) return;
                self.currentView = viewName;
                self.$nextTick().then(() => {
                    if (self.surveyActive && self.surveyFormComponent) self.surveyFormComponent.loadSurvey(self.currentSurvey);
                    let parentElement = self.$el.parentElement;
                    if(!parentElement || !parentElement.scrollTop) return;
                    parentElement.scrollTop = 0;
                });
            }
        }
    };
</script>
<style lang="scss">
    .modal-header .modal-title .header-badge-message { vertical-align: top; line-height: 1.5; margin-left:1rem; }
    .survey-lookup { height: 100%; }
    .survey-lookup-footer { display:flex; flex-direction: column; width:100%;
        section { display:flex;
            button { margin: 0 2px; }
            &.left-footer-section { align-self:flex-start; }
            &.right-footer-section { align-self:flex-end; }
        }
    }
</style>
