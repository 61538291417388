
const reissueCreditCalculationMethod = { ByAmount: 1, ByYearAndPercentage: 2, ByYearAmount: 3, ByPercentage: 4, ByYearAndRatePer: 5 };
export class ReissueCreditCalculationMethod {
    static get ByAmount () {return reissueCreditCalculationMethod.ByAmount;}
    static get ByYearAndPercentage () {return reissueCreditCalculationMethod.ByYearAndPercentage;}
    static get ByYearAmount () {return reissueCreditCalculationMethod.ByYearAmount;}
    static get ByPercentage () {return reissueCreditCalculationMethod.ByPercentage;}
    static get ByYearAndRatePer () {return reissueCreditCalculationMethod.ByYearAndRatePer;}

    static get lookupItems () {
        return _.map(reissueCreditCalculationMethod, c => { return { id: c, name: ReissueCreditCalculationMethod.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(reissueCreditCalculationMethod, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}

const breakoutEndorsementOption = { UseDefault: 0, Yes: 1, No: 2, CombineEndorsements: 3 };
export class BreakoutEndorsementOption {
    static get UseDefault () {return breakoutEndorsementOption.UseDefault;}
    static get Yes () {return breakoutEndorsementOption.Yes;}
    static get No () {return breakoutEndorsementOption.No;}
    static get CombineEndorsements () {return breakoutEndorsementOption.CombineEndorsements;}
    static get lookupItems () {
        return _.map(breakoutEndorsementOption, c => { return { id: c, name: BreakoutEndorsementOption.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(breakoutEndorsementOption, val => val === id);
        switch(id) {
            case breakoutEndorsementOption.UseDefault:
                return 'Default';
            case breakoutEndorsementOption.CombineEndorsements:
                return 'Combine';
            default:
                return (enumKey && _.startCase(enumKey)) || "";
        }
    }
}

const endorsementBasedOn = { LoanAmount: 1, LoanCalculatedPremium: 2, LoanActualPremium: 3, SalesPrice: 4, OwnerCalculatedPremium: 5, OwnerActualPremium: 6, PremiumSelection: 7, CombinedCalculatedPremium: 9, CombinedActualPremium: 10 };
export class EndorsementBasedOn {
    static get LoanAmount () {return endorsementBasedOn.LoanAmount;}
    static get LoanCalculatedPremium () {return endorsementBasedOn.LoanCalculatedPremium;}
    static get LoanActualPremium () {return endorsementBasedOn.LoanActualPremium;}
    static get SalesPrice () {return endorsementBasedOn.SalesPrice;}
    static get OwnerCalculatedPremium () {return endorsementBasedOn.OwnerCalculatedPremium;}
    static get OwnerActualPremium () {return endorsementBasedOn.OwnerActualPremium;}
    static get PremiumSelection () {return endorsementBasedOn.PremiumSelection;}
    static get CombinedCalculatedPremium () {return endorsementBasedOn.CombinedCalculatedPremium;}
    static get CombinedActualPremium () {return endorsementBasedOn.CombinedActualPremium;}
    static get lookupItems () {
        return _.map(endorsementBasedOn, c => { return { id: c, name: EndorsementBasedOn.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(endorsementBasedOn, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const endorsementChargeType = { FlatFee: 1, Percentage: 2, BasedOnAmount: 3, Scalable: 4, SalesPriceSlashLoanAmount: 5 };
export class EndorsementChargeType {
    static get FlatFee () {return endorsementChargeType.UseDefault;}
    static get Percentage () {return endorsementChargeType.Percentage;}
    static get BasedOnAmount () {return endorsementChargeType.BasedOnAmount;}
    static get Scalable () {return endorsementChargeType.Scalable;}
    static get SalesPriceSlashLoanAmount () {return endorsementChargeType.SalesPriceSlashLoanAmount;}
    static get lookupItems () {
        return _.map(endorsementChargeType, c => { return { id: c, name: EndorsementChargeType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(endorsementChargeType, val => val === id);
//        return (enumKey && _.startCase(enumKey)) || "";
        return _.replace((enumKey && _.startCase(enumKey)) || "" ,'Slash','/');
}
}

const endorsementPolicy = { Owner: 1, Loan: 2, Binder: 3, OwnerAndLoan: 4, Commitment: 5, Fee: 6, Order: 7 };
export class EndorsementPolicy {
    static get Owner () { return endorsementPolicy.Owner; }
    static get Loan () { return endorsementPolicy.Loan; }
    static get Binder () { return endorsementPolicy.Binder; }
    static get OwnerAndLoan () { return endorsementPolicy.OwnerAndLoan; }
    static get Commitment () { return endorsementPolicy.Commitment; }
    static get Fee () { return endorsementPolicy.Fee; }
    static get Order () { return endorsementPolicy.Order; }
    static get lookupItems () {
        let list = _.map(endorsementPolicy, c => { return { id: c, name: EndorsementPolicy.displayValue(c) }; })
        return _.sortBy(list, i => i.name);
    }

    static displayValue (id) {
        let enumKey = _.findKey(endorsementPolicy, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const endorsementYesNo = { Yes: 1, No: 2 };
export class EndorsementYesNo {
    static get Yes () {return endorsementYesNo.Yes;}
    static get No () {return endorsementYesNo.No;}
    static get lookupItems () {
        return _.map(endorsementYesNo, c => { return { id: c, name: EndorsementYesNo.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(endorsementYesNo, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const underwriterSplit  = {  UseDefaultUnderwriterSplit: 0, NoUnderwriterSplit: 1, OverrideUnderwriterSplit: 2 };
export class UnderwriterSplitOpts {
    static get NoUnderwriterSplit () {return underwriterSplit.NoUnderwriterSplit;}
    static get OverrideUnderwriterSplit () {return underwriterSplit.OverrideUnderwriterSplit;}
    static get UseDefaultUnderwriterSplit () {return underwriterSplit.UseDefaultUnderwriterSplit;}
    static get lookupItems () {
        return _.map(underwriterSplit, c => { return { id: c, name: UnderwriterSplitOpts.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(underwriterSplit, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

/*

    public enum POC_Who
    {
        [Description("[Not selected]")]
        NotSelected = 0,
        [Description("Buyer")]
        Buyer = 1,
        [Description("Seller")]
        Seller = 2,
        [Description("Lender")]
        Lender = 3
    }
    public enum POC_NF
    {
        [Description("[Not selected]")]
        NotSelected = 0,
        [Description("+")]
        Increase = 1,
        [Description("-")]
        Decrease = 2,
    }
*/

// poc whom refers to string in database for HUD lines
const pocWhoOptions = { NotSelected: "", Buyer: "B", Seller: "S", Lender:"L" };
export class POCWhoOptions {
    static get NotSelected () { return pocWhoOptions.NotSelected; }
    static get Buyer () { return pocWhoOptions.Buyer; }
    static get Seller () { return pocWhoOptions.Seller; }
    static get Lender () { return pocWhoOptions.Lender; }
    static get lookupItems () { return _.map(pocWhoOptions, c => { return { id: c, name: POCWhoOptions.displayValue(c) }; }); }

    static displayValue (id) {
        if (id === "") return "";
        let enumKey = _.findKey(pocWhoOptions, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

// Specifically created because Commissions poc whom refers to int enumeration in database
const pocWhoIntOptions = { NotSelected: 0, Buyer: 1, Seller: 2, Lender: 3 };
export class POCWhoIntOptions {
    static get NotSelected () { return pocWhoIntOptions.NotSelected; }
    static get Buyer () { return pocWhoIntOptions.Buyer; }
    static get Seller () { return pocWhoIntOptions.Seller; }
    static get Lender () { return pocWhoIntOptions.Lender; }
    static get lookupItems () { return _.map(pocWhoIntOptions, c => { return { id: c, name: POCWhoIntOptions.displayValue(c) }; }); }

    static displayValue (id) {
        if (id === 0) return "";
        let enumKey = _.findKey(pocWhoIntOptions, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const pocNetFundOptions = { None: 0, Positive: 1, Negative: 2 };
export class POCNetFundOptions {
    static get NotSelected () { return pocNetFundOptions.Positive; }
    static get Buyer () { return pocNetFundOptions.Negative; }
    static get lookupItems () { return _.map(pocNetFundOptions, c => { return { id: c, name: POCNetFundOptions.displayValue(c) }; }); }

    static displayValue (id) {
        switch(id){
            case pocNetFundOptions.None:
                return "";
            case pocNetFundOptions.Positive:
                return "+";
            case pocNetFundOptions.Negative:
                return "-";
        }
    }
}

const payoffSectionOptions = { Seller: 500, Borrower: 100 };
export class PayoffSectionOptions {
    static get Seller () { return payoffSectionOptions.Seller; }
    static get Borrower () { return payoffSectionOptions.Borrower; }
    static get lookupItems () { return _.map(payoffSectionOptions, c => { return { id: c, name: PayoffSectionOptions.displayValue(c) }; }); }

    static displayValue (id) {
        switch(id){
            case payoffSectionOptions.Seller:
                return "Seller";
            case payoffSectionOptions.Borrower:
                return "Borrower";
        }
    }
}

export const PaymentDue = {
    NotYetDone: 0,
    Due_Debit_BuyerSeller: 1,
    Due_DebitCredit_Seller: 2,
    PaidInAdvance: 3,
    Due_DebitBuyerOnly: 4,
    Due_DebitSellerOnly: 5,
    PaidInAdvance_BuyerOnly: 7,
    PaidInAdvance_SellerOnly: 8,
}

export const ProrationMethod = {
    ToDayOf: 0,
    ThruDayBefore: 1,
    ThruDayOf: 2,
    ToDayAfter: 3
}

// Constant that represents RQ1 CDF section G override status
export const EscrowOverrideOptions = {
    None: 21,
    Partial: 20,
    Full: 19
};

export const hudLineTypes = {
    None: 0,
    General: 1,
    Disclosure: 2,
    GFE_1: 8,
    GFE_A: 12,
    GFE_2: 32,  // used for display only... constant does not correspond with RQ1
    GFE_3: 13,
    GFE_4: 14,
    GFE_4_Outside: 9,
    GFE_5: 15,
    GFE_5_Outside: 10,
    GFE_6: 16,
    GFE_6_Outside: 11,
    GFE_7: 17,
    GFE_8: 18,
    GFE_9: 19,
    Paid_By: 22,
    GFE_10: 33,  // used for display only... constant does not correspond with RQ1
    GFE_11: 20
}

export class HudLineTypes {
    static get None () { return hudLineTypes.None; }
    static get General () { return hudLineTypes.General; }
    static get Disclosure () { return hudLineTypes.Disclosure; }
    static get GFE_1 () { return hudLineTypes.GFE_1; }
    static get GFE_A () { return hudLineTypes.GFE_A; }
    static get GFE_2 () { return hudLineTypes.GFE_2; } // used for display only... constant does not correspond with RQ1
    static get GFE_3 () { return hudLineTypes.GFE_3; }
    static get GFE_4 () { return hudLineTypes.GFE_4; }
    static get GFE_4_Outside () { return hudLineTypes.GFE_4_Outside; }
    static get GFE_5 () { return hudLineTypes.GFE_5; }
    static get GFE_5_Outside () { return hudLineTypes.GFE_5_Outside; }
    static get GFE_6 () { return hudLineTypes.GFE_6; }
    static get GFE_6_Outside () { return hudLineTypes.GFE_6_Outside; }
    static get GFE_7 () { return hudLineTypes.GFE_7; }
    static get GFE_8 () { return hudLineTypes.GFE_8; }
    static get GFE_9 () { return hudLineTypes.GFE_9; }
    static get PaidBy () { return hudLineTypes.Paid_By}
    static get GFE_10 () { return hudLineTypes.GFE_10; } // used for display only... constant does not correspond with RQ1
    static get GFE_11 () { return hudLineTypes.GFE_11; }


    static get lookupItems () {
        return _.map(hudLineTypes, c => { return { id: c, name: HudLineTypes.displayValue(c) }; });
    }

    static displayValue (id) {
        switch(id){
            case hudLineTypes.None:
                return "";
            case hudLineTypes.General:
                return "General";
            case hudLineTypes.Disclosure:
                return "Disclosure";
            case hudLineTypes.GFE_1:
                return "GFE #1";
            case hudLineTypes.GFE_A:
                return "GFE A";
            case hudLineTypes.GFE_2: // used for display only... constant does not correspond with RQ1
                return "GFE #2";
            case hudLineTypes.GFE_3:
                return "GFE #3";
            case hudLineTypes.GFE_4:
                return "GFE #4";
            case hudLineTypes.GFE_4_Outside:
                return "GFE #4";
            case hudLineTypes.GFE_5:
                return "GFE #5";
            case hudLineTypes.GFE_5_Outside:
                return "GFE #5";
            case hudLineTypes.GFE_6:
                return "GFE #6";
            case hudLineTypes.GFE_6_Outside:
                return "GFE #6";
            case hudLineTypes.GFE_7:
                return "Recording Fees - GFE #7";
            case hudLineTypes.GFE_8:
                return "Transfer Taxes - GFE #8";
            case hudLineTypes.GFE_9:
                return "GFE #9";
            case hudLineTypes.Paid_By:
                return "Paid by Other"
            case hudLineTypes.GFE_10: // used for display only... constant does not correspond with RQ1
                    return "GFE #10";
            case hudLineTypes.GFE_11:
                return "GFE #11";

        }
    }
}

// const altaCategoryOptions = { 
//     Financial: "Financial", 
//     ProrationAdj: "Prorations/Adjustments",
//     LoanCharges: 'Loan Charges to Lender',
//     TitleCharges: 'Title Charges & Escrow/Settlement Charges',
//     Commission: 'Commission',
//     GovRecordingsTransferCharges: 'Government Recordings & Transfer Charges',
//     Payoffs: 'Payoff(s)',
//     Miscellaneous: 'Miscellaneous',    
//  };

const salePriceTypeOptions = {
    SalesPrice: 0,
    AppraisedPropertyValue: 1,
    EstimatedPropertyValue: 2
}

export class SalePriceType {
    static get SalesPrice () { return SalePriceType.displayValue(salePriceTypeOptions.SalesPrice); }
    static get AppraisedPropertyValue () { return SalePriceType.displayValue(salePriceTypeOptions.AppraisedPropertyValue); }
    static get EstimatedPropertyValue () { return SalePriceType.displayValue(salePriceTypeOptions.EstimatedPropertyValue); }

    static get lookupItems () { return _.map(salePriceTypeOptions, i => { return { id: i, name: SalePriceType.displayValue(i) }; }); }

    static displayValue (id) {
        switch(id){
            case salePriceTypeOptions.SalesPrice:
                return "Sales Price";
            case salePriceTypeOptions.AppraisedPropertyValue:
                return "Appraised Property Value";
            case salePriceTypeOptions.EstimatedPropertyValue:
                return "Estimated Property Value";
        }
    }
}

const altaCategoryOptions = { 
    Financial: 0, 
    ProrationAdj: 1,
    LoanCharges: 2,
    OtherLoanCharges: 9,
    TitleCharges: 3,
    Commission: 4,
    GovRecordingsTransferCharges: 5,
    Payoffs: 6,
    Miscellaneous: 7,
    Impounds: 8 
 };
export class AltaCategories {
    static get Financial () { return AltaCategories.displayValue(altaCategoryOptions.Financial); }
    static get ProrationAdj () { return AltaCategories.displayValue(altaCategoryOptions.ProrationAdj); }
    static get LoanCharges () { return AltaCategories.displayValue(altaCategoryOptions.LoanCharges); }
    static get OtherLoanCharges () { return AltaCategories.displayValue(altaCategoryOptions.OtherLoanCharges); } 
    static get TitleCharges () { return AltaCategories.displayValue(altaCategoryOptions.TitleCharges); }
    static get Commission () { return AltaCategories.displayValue(altaCategoryOptions.Commission); }
    static get GovRecordingsTransferCharges () { return AltaCategories.displayValue(altaCategoryOptions.GovRecordingsTransferCharges); }
    static get Payoffs () { return AltaCategories.displayValue(altaCategoryOptions.Payoffs); }
    static get Miscellaneous () { return AltaCategories.displayValue(altaCategoryOptions.Miscellaneous); }
    static get Impounds () { return AltaCategories.displayValue(altaCategoryOptions.Impounds); }
    
    static get lookupItems () { return _.map(altaCategoryOptions, c => { return { id: c, name: AltaCategories.displayValue(c) }; }); }

    static displayValue (id) {
        switch(id){
            case altaCategoryOptions.Financial:
                return "Financial";
            case altaCategoryOptions.ProrationAdj:
                return "Prorations/Adjustments";                
            case altaCategoryOptions.LoanCharges:
                return "Loan Charges to Lender";                
            case altaCategoryOptions.OtherLoanCharges:
                return "Other Loan Charges";
            case altaCategoryOptions.TitleCharges:
                return "Title Charges & Escrow/Settlement Charges";                
            case altaCategoryOptions.Commission:
                return "Commission";                
            case altaCategoryOptions.GovRecordingsTransferCharges:
                return "Government Recordings & Transfer Charges";                
            case altaCategoryOptions.Payoffs:
                return "Payoff(s)";                
            case altaCategoryOptions.Miscellaneous:
                return "Miscellaneous";
            case altaCategoryOptions.Impounds:
                return "Impounds";                
            default:
                return "Miscellaneous";               
        }
    }
}

const recordingFeeGridTypeOptions = { 
    Fee: 0, 
    Tax: 1
 };

export class RecordingFeeGridType {
    static get Fee () { return recordingFeeGridTypeOptions.Fee; }
    static get Tax () { return recordingFeeGridTypeOptions.Tax; }

    static get lookupItems () { return _.map(recordingFeeGridTypeOptions, t => { return { id: t, name: RecordingFeeGridType.displayValue(t) }; }); }

    static displayValue (id) {
        switch(id){
            case recordingFeeGridTypeOptions.Fee:
                return "Fee";
            case recordingFeeGridTypeOptions.Tax:
                return "Tax";                         
        }
    }
}