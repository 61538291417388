<template>
    <div class="content-wrapper">
        <rq-page-section title="Projected Payments" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <button v-if="canAdd" automation_id="btn_add_payoff" type="button" class="btn btn-default btn-theme" v-focus @click="onAddClicked" :disabled="readOnly">Add</button>
                    </li>
                    <li v-if="canDelete" class="nav-item">
                        <button automation_id="btn_calculate" type="button" class="btn btn-default btn-theme" @click="onDeleteClicked" :disabled="readOnly">Delete</button>
                    </li>
                </ul>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box v-model="selectedLoanId" />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <!-- Payment 1 -->
        <rq-page-section :title="paymentTitle(1)" collapsible>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all />
                    </li>
                </ul>
            </template>
            <fieldset :disabled="readOnly">
                <div v-if="!hideYearInputData(1)" class="row">
                    <!-- From Year -->
                    <div class="col-1 form-group">
                        <rqInputNumber
                            id="payment1_from_year"
                            automation_id="payment1_from_year"
                            :disabled="readOnly"
                            defaultValue="0"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedProjectedPayment.paymentCalculationYearFrom1"
                        />
                    </div>
                    <div class="col col-auto form-group pt-2">
                        <label class="">To Year</label>
                    </div>
                    <!-- To Year -->
                    <div class="col-1 form-group">
                        <rqInputNumber
                            id="payment1_to_year"
                            automation_id="payment1_to_year"
                            :disabled="readOnly"
                            defaultValue="0"
                            no-prefix
                            input-group
                            v-focus-select-all
                            v-model="selectedProjectedPayment.paymentCalculationYearTo1"
                        />
                    </div>
                </div>

                <div class="row">
                    <!-- Principal & Interest -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Principal & Interest"
                            labelFor="payment1_pi"
                            :show-action="isPay1PrincipalInterestOverridden"
                            @action-click="onRevertPay1PIOverride"
                            action-automation-id="btn_revert_pay1_principal_interest"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    id="payment1_pi"
                                    automation_id="payment1_pi"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay1PrincipalInterestValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <!-- Mortgage Insurance -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <div class="row">
                            <label class="col col-auto col-form-label" for="txt_payment1_mi">Mortgage Insurance</label>
                        </div>

                        <rqInputNumber
                            id="payment1_mi"
                            automation_id="payment1_mi"
                            defaultValue="0"
                            format-type="money"
                            decimals="2"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            @blur="onEditMortgageInsurance"
                            v-model="selectedProjectedPayment.mortgageInsurance"
                        />
                    </div>

                    <!-- Estimated Escrow -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Estimated Escrow"
                            labelFor="payment1_escrow"
                            :show-action="selectedProjectedPayment.isEstimatedEscrowAmount1Overridden"
                            @action-click="onRevertEstimatedEscrowAmount1Override"
                            action-automation-id="btn_revert_payment1_escrow"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    id="payment1_escrow"
                                    automation_id="payment1_escrow"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="selectedProjectedPayment.estimatedEscrowAmount1Value"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <!-- Estimated Total -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Estimated Total Payment"
                            labelFor="payment1_total"
                            :show-action="isEstimatedTotalMonthlyOverrideAmount1"
                            @action-click="onRevertEstimatedTotalOverride"
                            action-automation-id="btn_revert_estimated_total"
                            action-label="Revert"
                        >
                            <template #default>
                                <rqInputNumber
                                    id="payment1_total"
                                    automation_id="payment1_total"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay1EstimatedTotalValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>
                </div>
                <div v-if="numberOfPayments > 1" class="row">
                    <div class="col-3 form-group">
                        <b-form-checkbox
                            automation_id="chk_principal_interest_1"
                            id="chk_principal_interest_1"
                            v-model="selectedProjectedPayment.isInterestOnly1">Interest Only
                        </b-form-checkbox>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Payment 2 -->
        <rq-page-section v-if="numberOfPayments > 1" :title="paymentTitle(2)" collapsible>
            <fieldset :disabled="readOnly">
                <div v-if="!hideYearInputData(2)" class="row">
                    <!-- From Year -->
                    <div class="col-1 form-group">
                        <rqInputNumber
                        id="payment2_from_year"
                        automation_id="payment2_from_year"
                        :disabled="readOnly"
                        defaultValue="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        v-model="selectedProjectedPayment.paymentCalculationYearFrom2"
                        />
                    </div>

                    <div class="col col-auto form-group pt-2">
                        <label>To Year</label>
                    </div>

                    <!-- To Year -->
                    <div class="col-1 form-group">
                        <rqInputNumber
                        id="payment2_to_year"
                        automation_id="payment2_to_year"
                        :disabled="readOnly"
                        defaultValue="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        v-model="selectedProjectedPayment.paymentCalculationYearTo2"
                        />
                    </div>
                </div>

                <div class="row">
                    <!-- Principal & Interest -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment2_pi">{{principalInterestTitle}}</label>
                        <rqInputNumber
                        id="payment2_pi"
                        automation_id="payment2_pi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.principalInterestAmount2"
                        />
                    </div>

                    <!-- Max Principal & Interest -->
                    <div v-if="selectedProjectedPayment.canInterestIncrease" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment2_max_pi">{{`Max Principal & Interest`}}</label>
                        <rqInputNumber
                        id="payment2_max_pi"
                        automation_id="payment2_max_pi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.principalInterestAmount2Max"
                        />
                    </div>

                    <!-- Mortgage Insurance -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment2_mi">Mortgage Insurance</label>
                        <rqInputNumber
                        id="payment2_mi"
                        automation_id="payment2_mi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.mortgageInsuranceAmount2"
                        />
                    </div>

                    <!-- Estimated Escrow -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment2_mi">Estimated Escrow</label>
                        <rqInputNumber
                        id="payment2_escrow"
                        automation_id="payment2_escrow"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.estimatedEscrowAmount2"
                        />
                    </div>

                    <!-- Estimated Total 2 -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">                      
                        <rq-action-form-group
                            action-class="overridden"
                            :label="estimatedTotalTitle"
                            labelFor="payment2_total"
                            :show-action="isEstimatedTotalMonthlyOverrideAmount2"
                            @action-click="onRevertEstimatedTotalOverride2"
                            action-automation-id="btn_revert_estimated_total2"
                            action-label="Revert">
                            <template #default>
                                <rqInputNumber
                                    id="payment2_total"
                                    automation_id="payment2_total"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay2EstimatedTotalValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <!-- Estimated Max Total 2 -->
                    <div v-if="selectedProjectedPayment.canInterestIncrease" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Estimated Max Total"
                            labelFor="payment2_total_max"
                            :show-action="isEstimatedTotalMonthlyOverrideAmount2Max"
                            @action-click="onRevertEstimatedTotalOverride2Max"
                            action-automation-id="btn_revert_estimated_total2_max"
                            action-label="Revert">
                            <template #default>
                                <rqInputNumber
                                    id="payment2_total_max"
                                    automation_id="payment2_total_max"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay2EstimatedMaxTotalValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <div v-if="numberOfPayments > 2" class="col-3 form-group" :class="{'pt-4': selectedProjectedPayment.canInterestIncrease === true}">
                        <b-form-checkbox
                            automation_id="chk_principal_interest_2"
                            id="chk_principal_interest_2"
                            v-model="selectedProjectedPayment.isInterestOnly2">Interest Only
                        </b-form-checkbox>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Payment 3 -->
        <rq-page-section v-if="numberOfPayments > 2" :title="paymentTitle(3)" collapsible>
            <fieldset :disabled="readOnly">
                <div v-if="!hideYearInputData(3)" class="row">
                    <div class="col-1 form-group">
                        <rqInputNumber
                        id="payment3_from_year"
                        automation_id="payment3_from_year"
                        :disabled="readOnly"
                        defaultValue="0"
                        no-prefix
                        input-group
                        v-model="selectedProjectedPayment.paymentCalculationYearFrom3"
                        />
                    </div>

                    <div class="col col-auto form-group pt-2">
                        <label>To Year</label>
                    </div>

                    <!-- To Year -->
                    <div class="col-1 form-group">
                        <rqInputNumber
                        id="payment3_to_year"
                        automation_id="payment3_to_year"
                        :disabled="readOnly"
                        defaultValue="0"
                        no-prefix
                        input-group
                        v-model="selectedProjectedPayment.paymentCalculationYearTo3"
                        />
                    </div>
                </div>

                <div class="row">
                    <!-- Principal & Interest -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment3_pi">{{principalInterestTitle}}</label>
                        <rqInputNumber
                        id="payment3_pi"
                        automation_id="payment3_pi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.principalInterestAmount3"
                        />
                    </div>

                    <!-- Max Principal & Interest -->
                    <div v-if="selectedProjectedPayment.canInterestIncrease" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment3_max_pi">{{`Max Principal & Interest`}}</label>
                        <rqInputNumber
                        id="payment3_max_pi"
                        automation_id="payment3_max_pi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.principalInterestAmount3Max"
                        />
                    </div>

                    <!-- Mortgage Insurance -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment3_mi">Mortgage Insurance</label>
                        <rqInputNumber
                        id="payment3_mi"
                        automation_id="payment3_mi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.mortgageInsuranceAmount3"
                        />
                    </div>

                    <!-- Estimated Escrow -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment3_mi">Estimated Escrow</label>
                        <rqInputNumber
                        id="payment3_escrow"
                        automation_id="payment3_escrow"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.estimatedEscrowAmount3"
                        />
                    </div>

                    <!-- Estimated Total 3 -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <rq-action-form-group
                            action-class="overridden"
                            :label="estimatedTotalTitle"
                            labelFor="payment3_total"
                            :show-action="isEstimatedTotalMonthlyOverrideAmount3"
                            @action-click="onRevertEstimatedTotalOverride3"
                            action-automation-id="btn_revert_estimated_total3"
                            action-label="Revert">
                            <template #default>
                                <rqInputNumber
                                    id="payment3_total"
                                    automation_id="payment3_total"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay3EstimatedTotalValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <!-- Estimated Max Total 3 -->
                    <div v-if="selectedProjectedPayment.canInterestIncrease" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Estimated Max Total"
                            labelFor="payment3_total_max"
                            :show-action="isEstimatedTotalMonthlyOverrideAmount3Max"
                            @action-click="onRevertEstimatedTotalOverride3Max"
                            action-automation-id="btn_revert_estimated_total3_max"
                            action-label="Revert">
                            <template #default>
                                <rqInputNumber
                                    id="payment3_total_max"
                                    automation_id="payment3_total_max"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay3EstimatedMaxTotalValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <div v-if="numberOfPayments > 3" class="col-3 form-group" :class="{'pt-4': selectedProjectedPayment.canInterestIncrease === true}">
                        <b-form-checkbox
                            automation_id="chk_principal_interest_3"
                            id="chk_principal_interest_3"
                            v-model="selectedProjectedPayment.isInterestOnly3">Interest Only
                        </b-form-checkbox>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Payment 4 -->
        <rq-page-section v-if="numberOfPayments > 3" :title="paymentTitle(4)" collapsible>
            <fieldset :disabled="readOnly">
                <div v-if="!hideYearInputData(4)" class="row">
                    <!-- From Year -->
                    <div class="col-1 form-group">
                        <rqInputNumber
                        id="payment4_from_year"
                        automation_id="payment4_from_year"
                        :disabled="readOnly"
                        defaultValue="0"
                        no-prefix
                        input-group
                        v-model="selectedProjectedPayment.paymentCalculationYearFrom4"
                        />
                    </div>

                    <div class="col col-auto form-group pt-2">
                        <label>To Year</label>
                    </div>

                    <!-- To Year -->
                    <div class="col-1 form-group">
                        <rqInputNumber
                        id="payment4_to_year"
                        automation_id="payment4_to_year"
                        :disabled="readOnly"
                        defaultValue="0"
                        no-prefix
                        input-group
                        v-model="selectedProjectedPayment.paymentCalculationYearTo4"
                        />
                    </div>
                </div>
                <div class="row">
                    <!-- Principal & Interest -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment4_pi">{{principalInterestTitle}}</label>
                        <rqInputNumber
                        id="payment4_pi"
                        automation_id="payment4_pi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.principalInterestAmount4"
                        />
                    </div>

                    <!-- Max Principal & Interest -->
                    <div v-if="selectedProjectedPayment.canInterestIncrease" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment4_max_pi">{{`Max Principal & Interest`}}</label>
                        <rqInputNumber
                        id="payment4_max_pi"
                        automation_id="payment4_max_pi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.principalInterestAmount4Max"
                        />
                    </div>

                    <!-- Mortgage Insurance -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment4_mi">Mortgage Insurance</label>
                        <rqInputNumber
                        id="payment4_mi"
                        automation_id="payment4_mi"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.mortgageInsuranceAmount4"
                        />
                    </div>

                    <!-- Estimated Escrow -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payment4_mi">Estimated Escrow</label>
                        <rqInputNumber
                        id="payment4_escrow"
                        automation_id="payment4_escrow"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedProjectedPayment.estimatedEscrowAmount4"
                        />
                    </div>

                    <!-- Estimated Total 4 -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <rq-action-form-group
                            action-class="overridden"
                            :label="estimatedTotalTitle"
                            labelFor="payment4_total"
                            :show-action="isEstimatedTotalMonthlyOverrideAmount4"
                            @action-click="onRevertEstimatedTotalOverride4"
                            action-automation-id="btn_revert_estimated_total4"
                            action-label="Revert">
                            <template #default>
                                <rqInputNumber
                                    id="payment4_total"
                                    automation_id="payment4_total"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay4EstimatedTotalValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <!-- Estimated Max Total 4 -->
                    <div v-if="selectedProjectedPayment.canInterestIncrease" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <rq-action-form-group
                            action-class="overridden"
                            label="Estimated Max Total"
                            labelFor="payment4_total_max"
                            :show-action="isEstimatedTotalMonthlyOverrideAmount4Max"
                            @action-click="onRevertEstimatedTotalOverride4Max"
                            action-automation-id="btn_revert_estimated_total4_max"
                            action-label="Revert">
                            <template #default>
                                <rqInputNumber
                                    id="payment3_total_max"
                                    automation_id="payment4_total_max"
                                    defaultValue="0"
                                    format-type="money"
                                    decimals="2"
                                    no-prefix
                                    input-group
                                    v-focus-select-all
                                    prependIcon="fas fa-dollar-sign"
                                    v-model="pay4EstimatedMaxTotalValue"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <rq-page-section title="Estimated Taxes, Insurance and Assessments" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-3 form-group">
                        <label for="drp_estimated_total">Estimated Total Payment</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_estimated_total')"
                            :items="paymentPeriodOptions"
                            :search-enabled="false"
                            :disabled="readOnly"
                            value-expr="id"
                            display-expr="name"
                            v-model="selectedProjectedPayment.paymentFrequencyType"
                        />
                    </div>
                    <div class="col-3 form-group">
                        <!-- Is Property Tax -->
                        <!-- Is Homeowner's Insurance -->
                        <label for="drp_property_escrow">This estimate includes:</label>
                        <div class="pt-2">
                            <b-form-checkbox
                                automation_id="chk_property_tax"
                                id="chk_property_tax"
                                v-model="selectedProjectedPayment.isPropertyTax">Property Tax
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="col-3 form-group">
                        <label for="drp_property_escrow">In Escrow?</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_property_escrow')"
                            :items="escrowOptions"
                            :search-enabled="false"
                            :disabled="readOnly"
                            value-expr="id"
                            display-expr="name"
                            v-model="selectedProjectedPayment.isPropertyTaxEscrow"
                        />
                    </div>
                    <div class="col-3 form-group">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 form-group">
                        <!-- Estimated Monthly -->
                        <label for="txt_estimated_monthly">{{paymentFrequenceLabel}}</label>
                        <rqInputNumber
                            id="estimated_monthly"
                            automation_id="estimated_monthly"
                            :disabled="readOnly"
                            defaultValue="0"
                            format-type="money"
                            decimals="2"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedProjectedPayment.estimatedTaxInsuranceAssessmentAmount"
                        />
                    </div>
                    <div class="col-3 form-group">
                        <!-- Is Homeowner's Insurance -->
                        <!-- <label for="drp_property_escrow">Estimate Includes Homeowner's</label> -->
                        <div class="pt-4">
                            <b-form-checkbox
                                automation_id="chk_homeowners_ins"
                                id="chk_homeowners_ins"
                                v-model="selectedProjectedPayment.isHomeownerInsurance">Homeowner's Insurance
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="col-3 form-group">
                        <label for="drp_homeowner_ins_escrow"></label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_homeowner_ins_escrow')"
                            :items="escrowOptions"
                            :search-enabled="false"
                            :disabled="readOnly"
                            value-expr="id"
                            display-expr="name"
                            v-model="selectedProjectedPayment.isHomeownerInsuranceEscrow"
                        />
                    </div>
                    <div class="col-3 form-group">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 form-group">
                    </div>
                    <div class="col-3 form-group">
                        <label for="drp_other_text">Other</label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_other_text')"
                            :items="otherTextOptions"
                            :show-clear-button="true"
                            :disabled="readOnly"
                            value-expr="id"
                            display-expr="name"
                            v-model="selectedProjectedPayment.otherTextOptions"
                            @value-changed="onOtherTextOptionsChange"
                        />
                    </div>
                    <div class="col-3 form-group">
                        <label for="drp_other_escrow"></label>
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_other_escrow')"
                            :items="escrowOptions"
                            :search-enabled="false"
                            :disabled="readOnly"
                            value-expr="id"
                            display-expr="name"
                            v-model="selectedProjectedPayment.isOtherEscrow"
                        />
                    </div>
                    <div class="col-3 form-group">
                    </div>
                </div>
                <div v-show="selectedProjectedPayment.otherTextOptions === 'Other'" class="row">
                    <div class="col-3">&nbsp;</div>
                    <div class="col-3 form-group">
                        <input class="form-control line-text" automation_id="txt_other_text" v-model="selectedProjectedPayment.otherText" type="text" id="other_text">
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { ProjectedPaymentModel, SETTLEMENT_TYPE } from '../../models';
    import { ORDER_ACTIONS } from "@/store/actions";
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {

        name: 'ProjectedPayment',
        mixins: [BaseSettlementMixin],
        components: {
        },
        data(){
            return{
                selectedProjectedPayment: {},
                originalProjectedPayment:[],
                paymentPeriodOptions: [
                    { id: 0, name: 'Annual' },
                    { id: 1, name: 'At Maturity' },
                    { id: 2, name: 'Bi-Weekly' },
                    { id: 3, name: 'Monthly' },
                    { id: 4, name: 'Quarterly' },
                    { id: 5, name: 'Semi-Annual' },
                    { id: 6, name: 'Semi-Monthly' },
                    { id: 7, name: 'Weekly' }
                ],
                escrowOptions: [
                    { id: 0, name: 'No' },
                    { id: 1, name: 'Yes' },
                    { id: 2, name: 'Yes - Some' },
                    { id: 3, name: 'Some' },
                ],
                otherTextOptions: [
                    { id: 'Condominium Association Dues', name: 'Condominium Association Dues' },
                    { id: 'Condominium Association Special Assessments', name: 'Condominium Association Special Assessments' },
                    { id: 'Cooperative Association Dues', name: 'Cooperative Association Dues' },
                    { id: 'Cooperative Association Special Assessments', name: 'Cooperative Association Special Assessments' },
                    { id: 'Ground Rent', name: 'Ground Rent' },
                    { id: 'Homeowner Association Dues', name: 'Homeowner Association Dues' },
                    { id: 'Homeowner Association Special Assessments', name: 'Homeowner Association Special Assessments' },
                    { id: 'Leasehold Payment', name: 'Leasehold Payment' },
                    { id: 'Other', name: 'Other' },
                ]
            }
        },
        computed:{
            numberOfPayments() {
                return this.selectedProjectedPayment.numberOfColumn;
            },
            canAdd() {
                const self = this;
                return self.numberOfPayments < 4; // max is 4 based on CDF
            },
            canDelete() {
                const self = this;
                return self.numberOfPayments > 2; // default and minimum is 2
            },
            paymentFrequenceLabel() {
                if (this.selectedProjectedPayment.paymentFrequencyType)
                    return this.paymentPeriodOptions[this.selectedProjectedPayment.paymentFrequencyType].name;
                else return this.paymentPeriodOptions[3].name;
            },
            pay1PrincipalInterestValue:{
                get(){
                    if (!_.isNil(this.selectedProjectedPayment.monthlyPrincipalInterestOverride))
                        return this.selectedProjectedPayment.monthlyPrincipalInterestOverride;
                    else {
                        return this.pay1PrincipalInterest;
                    }
                },
                set(val){
                    if (val === this.pay1PrincipalInterest) {
                        this.selectedProjectedPayment.monthlyPrincipalInterestOverride = null;
                    }
                    else {
                        this.selectedProjectedPayment.monthlyPrincipalInterestOverride = val;
                    }
                }
            },
            isPay1PrincipalInterestOverridden() {
		        return !_.isNil(this.selectedProjectedPayment.monthlyPrincipalInterestOverride);
            },
            isEstimatedTotalMonthlyOverrideAmount1() {
		        return !_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount1);
            },
            isEstimatedTotalMonthlyOverrideAmount2() {
		        return !_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2);
            },
            isEstimatedTotalMonthlyOverrideAmount2Max() {
		        return !_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2Max);
            },
            isEstimatedTotalMonthlyOverrideAmount3() {
		        return !_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3);
            },
            isEstimatedTotalMonthlyOverrideAmount3Max() {
		        return !_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3Max);
            },
            isEstimatedTotalMonthlyOverrideAmount4() {
		        return !_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4);
            },
            isEstimatedTotalMonthlyOverrideAmount4Max() {
		        return !_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4Max);
            },
            pay1PrincipalInterest() {
                const self = this;
                // Calculate Monthly Principal & Interest from Loan data for Payment 1 calculation
                let selectedLoan = _.find(self.loans, function(l) { return l.loanID == self.selectedLoanId; });
                return selectedLoan.monthlyPrincipal + selectedLoan.monthlyInterest;
            },
            principalInterestTitle() {
                const self = this;
                return self.selectedProjectedPayment.canInterestIncrease === true ? 'Min Principal & Interest' : 'Principal & Interest'
            },
            pay1MortgageInsurance() {
                const self = this;
                // Retrieve Monthly Insurnance from Loan for Payment 1 calculation
                // let selectedLoan = _.find(self.loans, function(l) { return l.loanID == self.selectedLoanId; });
                // return selectedLoan.mortgageInsurance;

                return self.selectedProjectedPayment.mortgageInsurance;
            },
            estimatedTotalTitle() {
                const self = this;
                return self.selectedProjectedPayment.canInterestIncrease === true ? 'Min Estimated Total Payment' : 'Estimated Total Payment';
            },
            pay1EstimatedTotalValue: {
                get() {
                    if (!_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount1))
                        return this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount1;
                    else
                        return this.pay1EstimatedTotal;
                },
                set(val) {
                    if (val === this.pay1EstimatedTotal) {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount1 = null;
                    }
                    else {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount1 = val;
                    }
                }
            },
            pay1EstimatedTotal() {
                const self = this;
                return self.pay1PrincipalInterestValue + self.pay1MortgageInsurance + self.selectedProjectedPayment.estimatedEscrowAmount1Value;
            },
            pay2EstimatedTotalValue: {
                get() {
                    if (!_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2))
                        return this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2;
                    else
                        return this.pay2EstimatedTotal;
                },
                set(val) {
                    if (val === this.pay2EstimatedTotal) {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2 = null;
                    }
                    else {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2 = val;
                    }
                }
            },
            pay2EstimatedTotal() {
                const self = this;
                return self.selectedProjectedPayment.principalInterestAmount2 + self.selectedProjectedPayment.mortgageInsuranceAmount2 + self.selectedProjectedPayment.estimatedEscrowAmount2;
            },
            pay2EstimatedMaxTotalValue: {
                get() {
                    if (!_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2Max))
                        return this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2Max;
                    else
                        return this.pay2EstimatedMaxTotal;
                },
                set(val) {
                    if (val === this.pay2EstimatedMaxTotal) {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2Max = null;
                    }
                    else {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2Max = val;
                    }
                }
            },
            pay2EstimatedMaxTotal() {
                const self = this;
                return self.selectedProjectedPayment.principalInterestAmount2Max + self.selectedProjectedPayment.mortgageInsuranceAmount2 + self.selectedProjectedPayment.estimatedEscrowAmount2;
            },
            pay3EstimatedTotalValue: {
                get() {
                    if (!_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3))
                        return this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3;
                    else
                        return this.pay3EstimatedTotal;
                },
                set(val) {
                    if (val === this.pay3EstimatedTotal) {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3 = null;
                    }
                    else {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3 = val;
                    }
                }
            },
            pay3EstimatedTotal() {
                const self = this;
                return self.selectedProjectedPayment.principalInterestAmount3 + self.selectedProjectedPayment.mortgageInsuranceAmount3 + self.selectedProjectedPayment.estimatedEscrowAmount3;
            },
            pay3EstimatedMaxTotalValue: {
                get() {
                    if (!_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3Max))
                        return this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3Max;
                    else
                        return this.pay3EstimatedMaxTotal;
                },
                set(val) {
                    if (val === this.pay3EstimatedMaxTotal) {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3Max = null;
                    }
                    else {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3Max = val;
                    }
                }
            },
            pay3EstimatedMaxTotal() {
                const self = this;
                return self.selectedProjectedPayment.principalInterestAmount3Max + self.selectedProjectedPayment.mortgageInsuranceAmount3 + self.selectedProjectedPayment.estimatedEscrowAmount3;
            },
            pay4EstimatedTotalValue: {
                get() {
                    if (!_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4))
                        return this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4;
                    else
                        return this.pay4EstimatedTotal;
                },
                set(val) {
                    if (val === this.pay4EstimatedTotal) {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4 = null;
                    }
                    else {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4 = val;
                    }
                }
            },
            pay4EstimatedTotal() {
                const self = this;
                return self.selectedProjectedPayment.principalInterestAmount4 + self.selectedProjectedPayment.mortgageInsuranceAmount4 + self.selectedProjectedPayment.estimatedEscrowAmount4;
            },
            pay4EstimatedMaxTotalValue: {
                get() {
                    if (!_.isNil(this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4Max))
                        return this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4Max;
                    else
                        return this.pay4EstimatedMaxTotal;
                },
                set(val) {
                    if (val === this.pay4EstimatedMaxTotal) {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4Max = null;
                    }
                    else {
                        this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4Max = val;
                    }
                }
            },
            pay4EstimatedMaxTotal() {
                const self = this;
                return self.selectedProjectedPayment.principalInterestAmount4Max + self.selectedProjectedPayment.mortgageInsuranceAmount4 + self.selectedProjectedPayment.estimatedEscrowAmount4;
            }
        },
        watch:{
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;
                self.fetchData();
            },
        },
        created(){
            this.baseInit();
            this.fetchData();
        },
        methods:{
            onEditMortgageInsurance(e) {
                const self = this;
                let loan = self.selectedLoan(self.selectedLoanId);
                let newMIAmount = e.value;
                let origMIAmount = self.selectedProjectedPayment.mortgageInsurance;

                let handleOk = function(e) {
                    self.selectedProjectedPayment.mortgageInsurance = newMIAmount;
                    return true;
                }

                let handleCancel = function(e) {
                    self.selectedProjectedPayment.mortgageInsurance = origMIAmount;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newMIAmount !== origMIAmount) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Mortgage Insurance for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },
            onRevertEstimatedEscrowAmount1Override() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedEscrowAmount1Override = null;
            },
            onRevertEstimatedTotalOverride() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount1 = null;
            },
            onRevertEstimatedTotalOverride2() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2 = null;
            },
            onRevertEstimatedTotalOverride2Max() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount2Max = null;
            },
            onRevertEstimatedTotalOverride3() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3 = null;
            },
            onRevertEstimatedTotalOverride3Max() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount3Max = null;
            },
            onRevertEstimatedTotalOverride4() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4 = null;
            },
            onRevertEstimatedTotalOverride4Max() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.estimatedTotalMonthlyOverrideAmount4Max = null;
            },
            onRevertPay1PIOverride() {
                if(this.selectedProjectedPayment)
                    this.selectedProjectedPayment.monthlyPrincipalInterestOverride = null;
            },
            lastPayment(paymentNumber) {
                return paymentNumber == _.get(this, "selectedProjectedPayment.numberOfColumn", 1);
            },
            hideYearInputData(paymentNumber) {
                const self = this;
                return _.parseBool(self.lastPayment(paymentNumber), false) && _.parseBool(self.selectedProjectedPayment.isBalloonPayment, false);
            },
            paymentTitle(paymentNumber) {
                const self = this;

                if (self.numberOfPayments == paymentNumber && self.selectedProjectedPayment.isBalloonPayment) return "Final Payment";

                switch (paymentNumber) {
                    case 1:
                        return "Payment 1";
                    case 2:
                        return "Payment 2";
                    case 3:
                        return "Payment 3";
                    case 4:
                        return "Payment 4";
                    default:
                        break;
                }
            },
            selectedLoan(loanId){
                const self = this;
                let selected = _.find(self.loans, l => l.loanID === loanId);
                return selected;
            },

            onSave(e){
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel() {
                let changes = this.getChanges();
                if(_.isEmpty(changes)) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onAddClicked() {
                const self = this;
                self.selectedProjectedPayment.numberOfColumn = self.selectedProjectedPayment.numberOfColumn + 1;
            },

            onDeleteClicked() {
                const self = this;

                let okDeleteHandler = function() {
                    if (self.selectedProjectedPayment.numberOfColumn == 3)
                        self.clearPayment3();

                    if (self.selectedProjectedPayment.numberOfColumn == 4)
                        self.clearPayment4();

                    self.selectedProjectedPayment.numberOfColumn = self.selectedProjectedPayment.numberOfColumn - 1;
                }

                self.$dialog.confirm( "Delete Payment",
                    "Are you sure you want to delete last payment?",
                    okDeleteHandler,
                    null
                );
            },

            clearPayment3() {
                const self = this;
                self.selectedProjectedPayment.paymentCalculationYearFrom3 = null;
                self.selectedProjectedPayment.paymentCalculationYearTo3 = null;
                self.selectedProjectedPayment.principalInterestAmount3 = null;
                self.selectedProjectedPayment.principalInterestAmount3Max = null;
                self.selectedProjectedPayment.mortgageInsuranceAmount3 = null;
                self.selectedProjectedPayment.estimatedEscrowAmount3 = null;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount3 = null;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount3Max = null;
            },

            clearPayment4() {
                const self = this;
                self.selectedProjectedPayment.paymentCalculationYearFrom4 = null;
                self.selectedProjectedPayment.paymentCalculationYearTo4 = null;
                self.selectedProjectedPayment.principalInterestAmount4 = null;
                self.selectedProjectedPayment.principalInterestAmount4Max = null;
                self.selectedProjectedPayment.mortgageInsuranceAmount4 = null;
                self.selectedProjectedPayment.estimatedEscrowAmount4 = null;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount4 = null;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount4Max = null;
            },
            save(toastIfNoChanges=false){
                const self = this;
                if(_.isNil(self.originalProjectedPayment)) return Promise.resolve(false);

                let changes = self.getChanges();
                if(_.isEmpty(changes)) {
                    if(toastIfNoChanges)
                        self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                // Update total
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount1 = self.pay1EstimatedTotal;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount2 = self.pay2EstimatedTotal;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount3 = self.pay3EstimatedTotal;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount4 = self.pay4EstimatedTotal;

                self.selectedProjectedPayment.estimatedTotalMonthlyAmount2Max = self.pay2EstimatedMaxTotal;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount3Max = self.pay3EstimatedMaxTotal;
                self.selectedProjectedPayment.estimatedTotalMonthlyAmount4Max = self.pay4EstimatedMaxTotal;

                // Update isOther
                self.selectedProjectedPayment.isOther = !_.isNil(self.selectedProjectedPayment.otherText);

                let promise = self.$api.ProjectedPaymentApi.update(self.selectedProjectedPayment, changes).then(()=>{
                    self.syncUpdates().then(() => {
                        self.$toast.success("Projected Payment Saved.");
                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    });
                }).catch(err =>{
                    self.$toast.error(err.message);
                    GlobalEventManager.saveCompleted({success: false});
                    return false;
                });

                self.$rqBusy.wait(promise);
                return promise;
            },

            syncUpdates(){
                const self = this;
                //reset original data
                self.originalProjectedPayment = _.cloneDeep(self.selectedProjectedPayment);
                //refresh the store
                let promise = self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, {orderId: self.orderId, refresh:true});
                self.$rqBusy.wait(promise);
                return promise;
            },

            getChanges(){
                const self = this;
                return self.getAuditChanges(self.originalProjectedPayment, self.selectedProjectedPayment);
            },

            fetchData(){
                const self = this;
                let promise = self.$api.ProjectedPaymentApi.getByLoanId(self.selectedLoanId);
                self.$rqBusy.wait(promise).then(result => {
                    self.selectedProjectedPayment = new ProjectedPaymentModel(result);
                    self.originalProjectedPayment = _.cloneDeep(self.selectedProjectedPayment);

                    // Mortgage Insurance
                    let loan = self.selectedLoan(self.selectedLoanId);

                    // TODO OVERRIDE
                    // self.selectedProjectedPayment.mortgageInsurance = loan.mortgageInsurance;
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });
            },

            onOtherTextOptionsChange(e) {
                if(_.isNil(e.event) || e.value !== "Other") return;
                this.selectedProjectedPayment.otherText = "";
            }
        }
    }
</script>