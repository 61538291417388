
import { SearchFieldSet, SearchField, SearchLookupsModel } from "../models";

const rawFieldSetData = [
    { id: 1, label: "General", fields: [] },
    { id: 2, label: "Property Details", fields: [] }
];

const rawFieldData = [
    { fieldId: 0, fieldSetId: 1, fieldLabel: "Buyer First Name", fieldName: "buyerFirstName", fieldType: "text", dataType: "string" },
    { fieldId: 1, fieldSetId: 1, fieldLabel: "Buyer Last Name", fieldName: "buyerLastName", fieldType: "text", dataType: "string" },
    { fieldId: 2, fieldSetId: 1, fieldLabel: "Buyer Business Name", fieldName: "buyerBusinessName", fieldType: "text", dataType: "string" },
    { fieldId: 66, fieldSetId: 1, fieldLabel: "Buyer Formal Name", fieldName: "buyerFormalName", fieldType: "text", dataType: "string", maxLength: 300 },
    { fieldId: 67, fieldSetId: 1, fieldLabel: "Buyer Realtor Name", fieldName: "buyerRealtorName", fieldType: "text", dataType: "string" },
    { fieldId: 3, fieldSetId: 1, fieldLabel: "Buyer Tax ID", fieldName: "buyerTaxId", fieldType: "text", dataType: "string", maxLength: 35 },
    { fieldId: 4, fieldSetId: 1, fieldLabel: "Buyer Address", fieldName: "buyerAddress", fieldType: "text", dataType: "string" },
    { fieldId: 5, fieldSetId: 1, fieldLabel: "Buyer City", fieldName: "buyerCity", fieldType: "text", dataType: "string", maxLength: 30 },
    { fieldId: 6, fieldSetId: 1, fieldLabel: "Buyer State", fieldName: "buyerState", dataType: "string", fieldType: "select", lookupKey: "states", isRange: false, maxLength: 2 },
    { fieldId: 7, fieldSetId: 1, fieldLabel: "Buyer Zip", fieldName: "buyerZip", fieldType: "text", dataType: "string", isRange: true, maxLength: 10 },
    { fieldId: 8, fieldSetId: 1, fieldLabel: "Seller First Name", fieldName: "sellerFirstName", fieldType: "text", dataType: "string" },
    { fieldId: 9, fieldSetId: 1, fieldLabel: "Seller Last Name", fieldName: "sellerLastName", fieldType: "text", dataType: "string" },
    { fieldId: 10, fieldSetId: 1, fieldLabel: "Seller Business Name", fieldName: "sellerBusinessName", fieldType: "text", dataType: "string" },
    { fieldId: 68, fieldSetId: 1, fieldLabel: "Seller Formal Name", fieldName: "sellerFormalName", fieldType: "text", dataType: "string", maxLength: 300 },
    { fieldId: 69, fieldSetId: 1, fieldLabel: "Seller Realtor Name", fieldName: "sellerRealtorName", fieldType: "text", dataType: "string" },
    { fieldId: 11, fieldSetId: 1, fieldLabel: "Seller Tax ID", fieldName: "sellerTaxId", fieldType: "text", dataType: "string", maxLength: 35 },
    { fieldId: 12, fieldSetId: 1, fieldLabel: "Seller Address", fieldName: "sellerAddress", fieldType: "text", dataType: "string" },
    { fieldId: 13, fieldSetId: 1, fieldLabel: "Seller City", fieldName: "sellerCity", fieldType: "text", dataType: "string", maxLength: 30 },
    { fieldId: 14, fieldSetId: 1, fieldLabel: "Seller State", fieldName: "sellerState", dataType: "string", fieldType: "select", lookupKey: "states", isRange: false, maxLength: 2 },
    { fieldId: 15, fieldSetId: 1, fieldLabel: "Seller Zip", fieldName: "sellerZip", fieldType: "text", dataType: "string", isRange: true, maxLength: 10 },
    { fieldId: 16, fieldSetId: 2, fieldLabel: "Property Subdivision", fieldName: "propertySubDivCode", dataType: "int", fieldType: "select", lookupKey: "subdivisions", isRange: false, maxLength: null },
    { fieldId: 17, fieldSetId: 2, fieldLabel: "Property Block", fieldName: "propertyBlock", fieldType: "text", dataType: "string", maxLength: 6 },
    { fieldId: 18, fieldSetId: 2, fieldLabel: "Property Lot", fieldName: "propertyLot", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 19, fieldSetId: 2, fieldLabel: "Property Survey", fieldName: "propertySurveyNum", dataType: "int", fieldType: "select", lookupKey: "surveys", isRange: false, maxLength: null },
    { fieldId: 20, fieldSetId: 2, fieldLabel: "Property Acreage", fieldName: "propertyAcreage", fieldType: "number", dataType: "float", decimals: 2, commas: true, maxLength: null },
    { fieldId: 21, fieldSetId: 2, fieldLabel: "Property Municipality", fieldName: "propertyMunicipality", fieldType: "text", dataType: "string", maxLength: 50 },
    { fieldId: 22, fieldSetId: 2, fieldLabel: "Property Tax ID", fieldName: "propertyTaxId", fieldType: "text", dataType: "string", maxLength: 30 },
    { fieldId: 23, fieldSetId: 2, fieldLabel: "Property Unit", fieldName: "propertyUnit", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 24, fieldSetId: 2, fieldLabel: "Property Tract", fieldName: "propertyTract", fieldType: "text", dataType: "string" , maxLength: 10},
    { fieldId: 25, fieldSetId: 2, fieldLabel: "Property Book", fieldName: "propertyBook", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 26, fieldSetId: 2, fieldLabel: "Property Page", fieldName: "propertyPage", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 27, fieldSetId: 2, fieldLabel: "Property District", fieldName: "propertyDistrict", fieldType: "text", dataType: "string", maxLength: 15 },
    { fieldId: 28, fieldSetId: 2, fieldLabel: "Property Phase", fieldName: "propertyPhase", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 29, fieldSetId: 2, fieldLabel: "Property Part", fieldName: "propertyPart", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 30, fieldSetId: 2, fieldLabel: "Property Parcel", fieldName: "propertyParcel", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 31, fieldSetId: 2, fieldLabel: "Property Building", fieldName: "propertyBuilding", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 32, fieldSetId: 2, fieldLabel: "Property Instr Num", fieldName: "propertyInstrumentNum", fieldType: "text", dataType: "string", maxLength: 25 },
    { fieldId: 33, fieldSetId: 2, fieldLabel: "Property Plat Name", fieldName: "propertyPlatName", fieldType: "text", dataType: "string", maxLength: 50 },
    { fieldId: 34, fieldSetId: 2, fieldLabel: "Property ARB Num", fieldName: "propertyArbNum", fieldType: "text", dataType: "string", maxLength: 20 },
    { fieldId: 35, fieldSetId: 2, fieldLabel: "Property Out Lot", fieldName: "propertyOutLot", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 39, fieldSetId: 2, fieldLabel: "Property Range", fieldName: "propertyRange", fieldType: "text", dataType: "string", maxLength: 5 },
    { fieldId: 40, fieldSetId: 2, fieldLabel: "Property Township", fieldName: "propertyTownship", fieldType: "text", dataType: "string", maxLength: 5 },
    { fieldId: 41, fieldSetId: 2, fieldLabel: "Property Section", fieldName: "propertySection", fieldType: "text", dataType: "string", maxLength: 10 },
    { fieldId: 42, fieldSetId: 2, fieldLabel: "Property Qtr1", fieldName: "propertyQtr1", fieldType: "text", dataType: "string", maxLength: 2 },
    { fieldId: 43, fieldSetId: 2, fieldLabel: "Property Qtr2", fieldName: "propertyQtr2", fieldType: "text", dataType: "string", maxLength: 2 },
    { fieldId: 44, fieldSetId: 2, fieldLabel: "Property Qtr3", fieldName: "propertyQtr3", fieldType: "text", dataType: "string", maxLength: 2 },
    // { fieldId: 45, fieldSetId: 2, fieldLabel: "Property Street Num", fieldName: "propertyStreetNo", fieldType: "text", dataType: "string" },
    { fieldId: 46, fieldSetId: 2, fieldLabel: "Property Address", fieldName: "propertyAddress", fieldType: "text", dataType: "string", maxLength: 200 },
    { fieldId: 47, fieldSetId: 2, fieldLabel: "Property County", fieldName: "propertyCountyId", dataType: "int", fieldType: "select", lookupKey: "counties", isRange: false, maxLength: null },
    { fieldId: 48, fieldSetId: 2, fieldLabel: "Property City", fieldName: "propertyCity", fieldType: "text", dataType: "string", maxLength: 30 },
    { fieldId: 49, fieldSetId: 2, fieldLabel: "Property State", fieldName: "propertyState", dataType: "string", fieldType: "select", lookupKey: "states", isRange: false, maxLength: 2 },
    { fieldId: 50, fieldSetId: 2, fieldLabel: "Property Zip", fieldName: "propertyZip", fieldType: "text", dataType: "string", isRange: true, maxLength: 10 },
    { fieldId: 51, fieldSetId: 1, fieldLabel: "Lender", fieldName: "orderLender", fieldType: "text", dataType: "string" },
    { fieldId: 52, fieldSetId: 1, fieldLabel: "Referral", fieldName: "orderReferral", fieldType: "text", dataType: "string" },
    { fieldId: 53, fieldSetId: 1, fieldLabel: "Customer", fieldName: "orderCustomer", fieldType: "text", dataType: "string" },
    { fieldId: 54, fieldSetId: 1, fieldLabel: "Loan Number", fieldName: "orderLoanNumber", fieldType: "text", dataType: "string" },
    { fieldId: 55, fieldSetId: 1, fieldLabel: "Other Co File Num", fieldName: "orderOtherCoFileNumber", fieldType: "text", dataType: "string", maxLength: 50 },
    { fieldId: 56, fieldSetId: 1, fieldLabel: "Referral", fieldName: "orderReferralName", fieldType: "text", dataType: "string" },
    { fieldId: 64, fieldSetId: 1, fieldLabel: "Loan Amount", fieldName: "orderLoanAmount", fieldType: "text", dataType: "string" },
    { fieldId: 65, fieldSetId: 1, fieldLabel: "Escrow Bank Name", fieldName: "orderEscrowBankName", fieldType: "text", dataType: "string" },
    { fieldId: 57, fieldSetId: 1, fieldLabel: "Region ID", fieldName: "orderRegId", dataType: "int", fieldType: "select", lookupKey: "regions", isRange: false, maxLength: null },
    { fieldId: 58, fieldSetId: 1, fieldLabel: "Branch", fieldName: "orderBranch", dataType: "int", fieldType: "select", lookupKey: "branches", isRange: false, maxLength: null },
    { fieldId: 59, fieldSetId: 1, fieldLabel: "Title Unit", fieldName: "orderTuNum", fieldType: "text", dataType: "string" },
    { fieldId: 60, fieldSetId: 1, fieldLabel: "Escrow Unit", fieldName: "orderEuNum", fieldType: "text", dataType: "string" },
    { fieldId: 61, fieldSetId: 1, fieldLabel: "Title Company ID", fieldName: "orderTitleCo", fieldType: "text", dataType: "string" },
    { fieldId: 62, fieldSetId: 1, fieldLabel: "Underwriter Name", fieldName: "orderUnderwriter", fieldType: "text", dataType: "string" }
];

class SearchFieldData {
    static buildSearchFieldSet (setupDetails, lookups=null) {
        const rootFieldSet = new SearchFieldSet();
        let fields = _.map(setupDetails, d => {
            let field = _.assign({}, _.find(rawFieldData, { fieldId: d.field }), { sequence:d.sequence, isRequired:d.required, visible:true });

            if (!lookups || field.lookupKey) return field;

            field.fieldOptions = lookups[field.lookupKey];
            return field;
        });

        let fieldGroups = _.groupBy(fields, "fieldSetId");
        _.forEach(fieldGroups, (v,k) => {
            let fsId = _.parseNumber(k,0);
            let fieldSet = _.assign({},
                _.find(rawFieldSetData, { id: fsId }),
                { fields: _.sortBy(v, f => f.sequence) }
            );
            rootFieldSet.subsets.push(new SearchFieldSet(fieldSet));
        });
        return rootFieldSet;
    }
}

export default SearchFieldData;
