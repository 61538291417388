<template>
    <div v-if="isCdf && isWithOutSeller" class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="Payoffs &amp; Payments" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add_payoff" variant="theme" @click="onAddPayoff" :disabled="readOnly">Add</b-btn>
                    </li>
                </ul>
                <ul class="nav ms-auto grid-tools-offset">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                        />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="dataGrid"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            export-file-name="cdf-payoff-data"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['isInactive']"
            @delete="onDeleteItems"
            @editorPreparing="onEditorPreparing"
            focus-after-insert="first-row"
            hide-search
            :rq-editable="!readOnly"
        />
    </div>
    <div v-else class="content-wrapper">
        <rq-no-data text="CDF Payoff &amp; Payments only available for CDF without Seller" />
    </div>
</template>


<script>
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { GlobalEventManager } from "@/app.events";
    import { CdfPayoffPaymentDto } from "./models";
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        name: 'CdfPayoffPayments',
        mixins: [BaseSettlementMixin, GridCompanyPickerMixin],

        data() {
            return {
                itemKey: "payoffPaymentID",
                selectionActions: [],
                items: [],
                deletedItems: [],
                originalData: [],
            };
        },

        watch: {
            selectedLoanId(newVal, oldVal) {
                if (newVal === oldVal || _.parseNumber(oldVal, 0) === 0) return;
                this.fetchData();
            },
        },

        computed: {
            gridInstance () { return this.$refs.dataGrid.gridInstance; },
        },

        created() {
            const self = this;
            self.baseInit();
            self.selectionActions = self.readOnly ? [] : [
                {
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    allowMultiSelection: true,
                    tooltip: `Delete Payoff Payment`,
                    disabled: function(e) {
                        if(_.some(e.data, item => item.isGeneralPayoff)) {
                            return "Payoff(s) must be deleted from Payoff page.";
                        } else {
                            return false;
                        }
                    },
                }
            ];
            self.initGridConfig();
            self.fetchData();
        },

        methods: {
            onAddPayoff(lineType) {
                const self = this;
                if(!self.gridInstance) return;
                self.gridInstance.addRow();
            },
            fetchData() {
                const self = this;
                self.deletedItems = [];
                if(self.selectedLoanId === 0) return Promise.resolve(true);

                let getPromise = self.$api.PayoffsApi.getCdfPayoffData(self.selectedLoanId);
                return self.$rqBusy.wait(getPromise).then(results => {
                    self.items = _.map(results, result => new CdfPayoffPaymentDto(result));
                    self.originalData = _.map(results, result => new CdfPayoffPaymentDto(result));
                    self.gridInstance.refresh();
                });
            },

            onSave(e){
                this.gridInstance.saveEditData();
                let userInitiated = _.getBool(e, "userInitiated");
                this.save(userInitiated);
            },

            onCancel(){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            save(refreshData=true){
                const self = this;
                if(!self.hasChanges()) {
                    if(refreshData) self.fetchData();
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                self.showErrorBanner = self.hasErrors;
                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false, abort: true});
                    return;
                }

                let savePromise = self.$api.PayoffsApi.saveDeleteCdfPayoffs(self.items, self.deletedItems);
                return self.$rqBusy.wait(savePromise)
                    .then(() =>{
                        if(refreshData) self.fetchData();
                        GlobalEventManager.saveCompleted({success: true});
                        self.$toast.success("Payoff Payment Saved Successfully");
                    })
                    .catch(err => {
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error(err.errorMessage);
                    });
            },
            initGridConfig() {
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeID",
                    companyNameExpr: "companyName",
                    showContactPicker: false,
                };

                self.gridConfig = {
                    focusedRowEnabled: true,
                    sorting: { mode: 'single' },
                    selection: {
                        allowSelectAll: true,
                        selectAllMode: 'page',
                        mode: "multiple"
                    },
                    columns: [
                        {
                            caption: "Description",
                            dataField: "payoffDescription",
                            dataType: "string",
                            validationRules: [
                                { type: "required", message: "Description is required" }
                            ],
                        },
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "companyName",
                                dataType: "string",
                                caption: "Payee",
                                width: "20%"
                            },
                            disabled: cellInfo => {
                                return self.isPayeeColumnDisabled(cellInfo.data);
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "amount",
                            caption: "Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                valueChangeEvent: "input",
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        }
                    ],

                  summary:
                    {
                        totalItems: [
                            {
                                column: "payoffDescription",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return "TOTALS";
                                }
                            },

                            {
                                column: "amount",
                                summaryType: "sum",
                                valueFormat: "currency",
                                customizeText: function(data){
                                    return self.formatMoney(data.value);
                                }
                            },
                        ]
                    }
                }

                self.initGridDataSource();
            },
            initGridDataSource() {
                const self = this;
                self.gridDataSource = {
                    key: self.itemKey,
                    load(){
                        return Promise.resolve(self.items);
                    },
                    insert(values){
                        return self.onGridInsert(values);
                    },
                    update(key, values) {
                        return self.onGridUpdate(key, values);
                    }
                };
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new CdfPayoffPaymentDto(values);
                newItem.payoffPaymentID = -self.items.length;
                newItem.loanID = self.selectedLoanId;
                newItem.sequenceNumber = self.getNextAvailableSequence();

                self.items.push(newItem);
                return Promise.resolve(newItem);
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.payoffPaymentID === key);
                if (itemIndex < 0) return self.onGridInsert(values);
                let newUpdateItem = new CdfPayoffPaymentDto((_.assign({}, self.items[itemIndex], values)));
                self.items[itemIndex] = newUpdateItem;

                return Promise.resolve(newUpdateItem);
            },
            onDeleteItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let selectedItems = e.data;

                let okHandler = function (args) {
                    self.deleteItems(selectedItems);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    'Are you sure you wish to delete selected payoff(s)?',
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            deleteItems(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let key = _.get(item, self.itemKey);
                    self.removeItems(key);

                    if(key > 0){
                        self.deletedItems.push(item);
                    }
                });

                self.reorderSequence();
            },

            removeItems(key) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                self.gridRefresh();
            },

            getNextAvailableSequence() {
                const self = this;
                if (self.items.length === 0) return 1;
                let maxPayoffItem = _.maxBy(self.items, function(i) { return i.sequenceNumber; });
                let nextAvailableSequence = _.parseNumber(maxPayoffItem.sequenceNumber, 0) + 1;
                return nextAvailableSequence;
            },

            reorderSequence() {
                const self = this;

                if (self.items.length === 0) return;
                let payOffItems = [...self.items];
                let minLine = _.minBy(payOffItems, function(i) { return i.sequenceNumber; });
                let maxLine = _.maxBy(payOffItems, function(i) { return i.sequenceNumber; });

                let sequenceNumber = _.parseNumber(minLine.sequenceNumber, 0);
                _.forEach(_.sortBy(payOffItems, p => p.sequenceNumber), (payOffItem) => {
                    if (payOffItem.sequenceNumber != sequenceNumber) {
                        let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === payOffItem.payoffPaymentID);
                        self.items[itemIndex].sequenceNumber = sequenceNumber;
                    }
                    sequenceNumber = sequenceNumber + 1;
                });
            },

            onEditorPreparing(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;

                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },

            isColumnDisabled(dataField, data) {
                return data.isGeneralPayoff;
            },

            isPayeeColumnDisabled(lineData) {
                return lineData.isGeneralPayoff;
            },

            gridRefresh() { this.gridInstance.refresh(); },

            hasChanges(){
                //original data was empty and user added something
                if(this.originalData.length === 0 && this.items.length > 0){
                    return true;
                }
                //if the arrays are different length, then there are changes.  Items have been deleted and need to save
                if(this.items.length !== this.originalData.length){
                    return true;
                }
                //need to compare the two arrays for changes
                let changes = this.getAuditChanges(this.originalData, this.items);
                return changes.length > 0;
            },
        }
    };
</script>