<template>
    <div class="content-wrapper proration-update-selected">
        <div class="row">
            <div class="col-4 form-group">
                <label for="drp_payment_due">Payment Due</label>
                <rq-select-box 
                    id="drp_payment_due" 
                    :items="paymentDueTypes" 
                    v-model.number="paymentDue"
                />
            </div>
            <div class="col-4 form-group">
                <label for="drp_billing_period">Number of Billing Periods Per Year</label>
                <rq-select-box 
                    id="drp_billing_period" 
                    :items="billingPeriods"
                    v-model.number="periodsPerYear"
                />
            </div>
            <div class="col-4 form-group">
                <label for="drp_proration_type">Proration Type</label>
                <rq-select-box 
                    id="drp_proration_type" 
                    :items="prorationTypes"
                    v-model.number="daysInYear"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
    data: function() {
        return {
            daysInYear: -1,
            periodsPerYear: -1,
            paymentDue: -1
        }
    },
    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
        billingPeriods() {
            return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_BILLING_PERIODS);
        },
        paymentDueTypes() { 
            return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_PAYMENT_DUE_TYPES);
        },
        prorationTypes() {
            return this.lookupHelpers.getLookupItems(this.lookupItems.PRORATION_TYPES);
        },
    }
}
</script>